
























import { Component, Prop, Vue } from "vue-property-decorator";
import MainCalendar from "@/modules/TimeTracker/component/WorkingCalendar/MainCalendar.vue";
import WorkCalendarModule from "@/modules/TimeTracker/store/WorkCalendarModule";
import TimeStatisticItem from "@/modules/TimeTracker/component/TimeStatistic/TimeStatisticItem.vue";
import TimeStatisticList from "@/modules/TimeTracker/component/TimeStatistic/TimeStatisticList.vue";
import moment from "moment";

@Component({
  components: { TimeStatisticList, TimeStatisticItem, MainCalendar }
})
export default class TimeStatistic extends Vue {
  @Prop({ type: String, default: "2022-03" }) month!: string;
  attributes: Array<any> = [];

  handleClose() {
    this.$emit("handleClose");
  }

  async getTimeInfo() {
    this.attributes = this.userMonthTable.map((item) => ({
      customData: {
        ...item
      },
      dates: item.date
    }));
  }

  get userMonthTable() {
    return WorkCalendarModule.userMonthTable;
  }

  get userStatistic() {
    return WorkCalendarModule.userStatistic;
  }

  get user() {
    return this.userStatistic
      ? this.$store.getters["UsersModule/userById"](this.userStatistic.user_id)[0]
      : null;
  }

  get minDate() {
    return `${this.month}-01`;
  }

  get maxDate() {
    return moment(this.minDate).endOf("month").format("YYYY-MM-DD");
  }

  get role() {
    return this.user.roles.length ? `[${this.user.roles[0].name}]` : "";
  }
}
