














































import { Component, Prop, Vue } from "vue-property-decorator";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import moment, { Moment } from "moment";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import { EventBus } from "@/main";
import Tooltip from "@/modules/Calendar/components/CalendarComponents/Tooltip.vue";
import LabelTooltip from "@/aopV2/components/UIComponents/Tooltip/LabelTooltip.vue";

@Component({
  components: { LabelTooltip, Tooltip }
})
export default class EventCard extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  event!: any;
  @Prop({
    default: () => {},
    type: Number
  })
  cellHeight!: any;
  @Prop({
    default: 1,
    type: Number
  })
  count!: number;
  @Prop({
    default: -1,
    type: Number
  })
  index!: number;
  @Prop({
    default: dateFormat(new Date()),
    type: String
  })
  day!: string;
  dragStartY: number = 0;
  dragEndY: number = 0;
  bottom: number = 4;
  isShowBorderResize: boolean = false;
  zIndex: number = 1;
  eventEnd: Moment = moment();
  colorList = {
    0: "primary",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10"
  };
  timerId: number | undefined = undefined;
  timerIdLeave: number | undefined = undefined;

  mouseLeave() {
    CalendarModule2.toggleTooltip({
      tooltip: [],
      isShow: false,
      x: 0,
      y: 0,
      id: 0
    });
  }

  mouseOver() {
    if (this.tooltip.length)
      if (!this.isShowTooltip || this.event.id !== this.tooltipId) {
        const rect = this.$el.getBoundingClientRect();
        CalendarModule2.toggleTooltip({
          tooltip: this.tooltip,
          isShow: true,
          x: rect.x,
          y: rect.y,
          id: this.event.id
        });
      }
  }

  get eventId() {
    return `event_${this.event.id}`;
  }

  get tooltipId() {
    return CalendarModule2.tooltipId;
  }

  get isShowTooltip() {
    return CalendarModule2.isShowTooltip;
  }

  get isCreate() {
    return this.$router.currentRoute.name === "CreateEvent";
  }

  // It sets the z-index to 8, which makes the map appear on top of other maps.
  // It also sets isShowBorderResize to true which makes the border visible.
  // It also gets the background color of the event card and sets the border color
  // to a darker version of the background color.
  openEvent() {
    if (this.$route.query.event_id !== this.event.id && this.event.id && !this.notClickable) {
      if (!this.isCreate)
        this.$router.push({
          query: { event_id: this.event.id }
        });
      CalendarModule2.toggleViewModal(true);
      this.getEvent(this.event.id);
      this.zIndex = 8;
      this.isShowBorderResize = true;
      const getEventCard = this.$refs.eventCard as HTMLElement;
      let getStyleParent = window
        .getComputedStyle(getEventCard)
        .getPropertyValue("background-color");
      if (this.$refs.borderResize) {
        const borderResize = this.$refs.borderResize as HTMLElement;
        borderResize.style.backgroundColor = this.shaderColor(this.rgba2hex(getStyleParent), -20);
      }
    }
    if (this.notClickable) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Это приватное мероприятие и Вы в нем не состоите"
      });
    }
  }

  async getEvent(id) {
    CalendarModule2.setLoader(true);
    await CalendarModule2.getEvent({
      id: id,
      timezone: this.timezone
    }).then((error) => {
      if (error === 404 || error === 422 || error === 403 || error === 400) {
        this.$router.push({ name: "Calendar" });
        CalendarModule2.toggleViewModal(false);
      }
    });
    CalendarModule2.setLoader(false);
  }

  get notClickable() {
    return !this.event.is_my && this.event.is_private;
  }

  get currentEvent() {
    return CalendarModule2.currentEvent;
  }

  get timezone() {
    return CalendarModule2.timezone;
  }

  closeEvent() {
    this.zIndex = 1;
  }

  //todo_dm: переписать 2 функции в одну, либо удалить, если Вика предложит лучший вариант стилизации
  // It will convert rgba to hexadecimal.
  rgba2hex = (rgba) =>
    `#${rgba
      .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
      .slice(1)
      .map((n, i) =>
        (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
          .toString(16)
          .padStart(2, "0")
          .replace("NaN", "")
      )
      .join("")}`;

  // A function that takes a color and a percentage and returns a darker version of the color.
  shaderColor(color, percent) {
    const num = parseInt(color.slice(1), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  dragEvent(event: MouseEvent) {
    this.dragStartY = event.pageY;
    document.addEventListener("mousemove", this.dragMove);
    document.addEventListener("mouseup", this.dragStop);
  }

  dragMove(event: MouseEvent) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      const el = event.target as HTMLBaseElement;
      let started_at = el.id;
      this.dragEndY = event.pageY;
      const eventDuration =
        new Date(this.event.ended_at).getTime() - new Date(this.event.started_at).getTime();
      started_at = moment(started_at)
        .add(this.computedMinutes(started_at), "minute")
        .format("YYYY-MM-DD HH:mm:ss");
      let ended_at = moment(started_at)
        .add(eventDuration / 60000, "minute")
        .format("YYYY-MM-DD HH:mm:ss");
      CalendarModule2.editPreCreationEvent({
        event: { ...this.event, ended_at, started_at },
        oldId: undefined
      });
    }, 50);
  }

  dragStop(event: MouseEvent) {
    const el = event.target as HTMLBaseElement;
    let started_at = el.id;
    this.dragEndY = event.pageY;
    const eventDuration =
      new Date(this.event.ended_at).getTime() - new Date(this.event.started_at).getTime();
    started_at = moment(started_at)
      .add(this.computedMinutes(started_at), "minute")
      .format("YYYY-MM-DD HH:mm:ss");
    let ended_at = moment(started_at)
      .add(eventDuration / 60000, "minute")
      .format("YYYY-MM-DD HH:mm:ss");
    CalendarModule2.editPreCreationEvent({ ...this.event, ended_at, started_at });
    document.removeEventListener("mousemove", this.dragMove);
    document.removeEventListener("mouseup", this.dragStop);
  }

  subscribeMousemove(event: MouseEvent) {
    this.dragStartY = event.pageY;
    document.addEventListener("mousemove", this.handlerMouseMove);
    document.addEventListener("mouseup", this.unsubscribe);
  }

  computedMinutes(time) {
    const minuteInPixel = this.cellHeight / 60;
    const diff = this.dragEndY - this.dragStartY;
    const minute = Math.round(diff / minuteInPixel);
    const defMinute = new Date(time).getMinutes();
    const offset = minute >= 0 ? 15 - (defMinute % 15) : 0 - (defMinute % 15);
    return offset === 15 || offset === 0 ? Math.round(minute / 15) * 15 : offset;
  }

  handlerMouseMove(event: MouseEvent) {
    this.dragEndY = event.pageY;
    const minute = this.computedMinutes(this.event.ended_at);
    const borderResize = this.$refs.borderResize as HTMLElement;
    this.bottom = -(borderResize.clientTop + (this.cellHeight / 60) * minute);
  }

  unsubscribe() {
    const minute = this.computedMinutes(this.event.ended_at);
    const ended_at = moment(this.event.ended_at)
      .add(minute, "minute")
      .format("YYYY-MM-DD HH:mm:ss");
    if (!this.event.id) CalendarModule2.editPreCreationEvent({ ...this.event, ended_at });
    // this.event.ended_at = moment(this.event.ended_at).add(minute, "minute");
    document.removeEventListener("mousemove", this.handlerMouseMove);
    document.removeEventListener("mouseup", this.unsubscribe);
    this.bottom = 4;
  }

  get setHeight() {
    let dataStart = moment(this.event.started_at);
    let dataEnd = moment(this.event.ended_at);
    const datStartGrid = moment(`${this.day} ${this.startGridTime}:00`);
    const dateEndGrid = moment(`${this.day} ${this.stopGridTime}:00`);
    dataEnd = dateEndGrid < dataEnd ? dateEndGrid : dataEnd;
    dataStart = datStartGrid > dataStart ? datStartGrid : dataStart;
    const interval = dataEnd.diff(dataStart, "minutes");
    return (this.cellHeight * interval) / 60;
  }

  get stopGridTime() {
    return CalendarModule2.gridStopTime;
  }

  get startGridTime() {
    return CalendarModule2.gridStartTime;
  }

  get setTopPosition() {
    let mm = new Date(this.event.started_at).getMinutes();
    const dateStart = moment(this.event.started_at);
    const datStartGrid = moment(`${this.day} ${this.startGridTime}`);
    mm = datStartGrid > dateStart ? 0 : mm;
    return this.cellHeight * (+mm / 60);
  }

  get isLocation() {
    return this.event.location_id;
  }

  get setEventTime() {
    return `${moment(this.event.started_at).format("HH:mm")} – ${moment(this.event.ended_at).format(
      "HH:mm"
    )}`;
  }

  get locations() {
    return CalendarModule2.locations;
  }

  get eventLocation() {
    const location = this.locations.find((item) => item.id === this.event.location_id);
    return location ? location.name : "";
  }

  get setLeft() {
    switch (this.count) {
      case 2: {
        return this.index * 35;
      }
      case 3: {
        return this.index * 25;
      }
      case 4: {
        return this.index * 15;
      }
      default: {
        return this.index * 5;
      }
    }
  }

  get setWidth() {
    return 95 - this.setLeft;
  }

  get eventTypes() {
    return CalendarModule2.eventType;
  }

  get getEventType() {
    return this.eventTypes.find((item) => item.id === this.event.type_id);
  }

  get color() {
    return this.colorList[this.getEventType.color_number];
  }

  get setStyleClasses() {
    return [
      `event-card_${this.color}`,
      this.event.id === undefined ? "event-card_editable" : "",
      this.event.id === undefined && this.isCreate ? "event-card_editable-in-create" : "",
      this.isPassed ? "event-card_passed" : ""
    ];
  }

  get isPassed() {
    return new Date(this.event.ended_at) < new Date();
  }

  get name() {
    return this.isPrivate
      ? "Приватное мероприятие"
      : this.event.id
      ? this.event.name
      : this.event.name.length
      ? this.event.name
      : "(без названия)";
  }

  get userId() {
    return this.$store.getters.getUserId;
  }

  get isPrivate() {
    return !this.event.name && this.event.is_private && this.event.id;
  }

  get setCardStyle() {
    return {
      top: this.setTopPosition + "px",
      height: this.setHeight + "px",
      width: this.setWidth + "%",
      left: this.setLeft + "%",
      borderColor: this.index ? "#fff" : "",
      zIndex: this.zIndex
    };
  }

  get users() {
    return this.$store.getters["UsersModule/userList"].users;
  }

  get tooltip() {
    if (this.event.all_users) {
      return this.users
        .filter((user) => {
          return this.event.all_users
            .filter((item) => item.user_id !== this.userId)
            .some((item) => item.user_id === user.id);
        })
        .map((user) => user.full_name);
    } else {
      return [];
    }
  }
}
