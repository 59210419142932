<template>
  <div class="modal__container" @mousedown.self="closeCreateTask">
    <div class="modal">
      <h2 class="modal__title">Создать задачу</h2>
      <div class="modal__wrap">
        <Input
          v-model="name"
          :label="'Название задачи'"
          :placeholder="'Название задачи'"
          :minlength="6"
          :maxlength="250"
        />
      </div>
      <div class="modal__wrap">
        <p class="modal__label">Исполнители</p>
        <Select
          :showAll="false"
          :items="usersList"
          v-model="executors"
          :placeholder="'Выберите сотрудника'"
          :maxCount="5"
        />
      </div>
      <div class="modal__wrap">
        <p class="modal__label">Дедлайн</p>
        <DeadLineComponent
          :showLabel="false"
          @switchCalendar="switchCalendar"
          @closeCalendar="closeCalendar"
          @clearCalendar="pickCalendar"
          :showCalendar="showCalendar"
          :value="deadline"
        />
        <DeadLineCalendar :value="deadline" v-if="showCalendar" @closeCalendar="pickCalendar" />
      </div>
      <div class="modal__wrap modal__wrap_buttons">
        <Button :title="'Отменить'" :type="'cancel'" @handleButton="closeCreateTask" />
        <Button :title="'Создать'" @handleButton="createTask" />
      </div>
    </div>
    <ConfirmModal
      v-if="confirmClose"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="cancelClose"
      @accept="acceptClose"
    />
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button";
import Input from "@/modules/UiKit/components/Inputs/Input";
import { mapMutations, mapState, mapActions } from "vuex";
import ClickOutside from "vue-click-outside";
import moment from "moment";
import { format } from "date-fns";
import NotificationObserver from "@/helpers/UserNotification";
import DeadLineComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineComponent";
import DeadLineCalendar from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineCalendar";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "IspCreateTaskModal",
  mixins: [],
  props: [],
  components: { ConfirmModal, DeadLineComponent, Select, Button, Input, DeadLineCalendar },
  data() {
    return {
      name: "",
      executors: [],
      deadline: "",
      showCalendar: false,
      confirmClose: false
    };
  },
  mounted() {
    this.getItemSelect();
  },
  methods: {
    ...mapActions("IspModule", ["createNewTask"]),
    ...mapMutations("IspModule", ["SHOW_CREATE_TASK_MODAL", "SHOW_CURRENT_INFO_PROBLEM"]),
    closeModal() {
      this.SHOW_CREATE_TASK_MODAL(false);
      this.SHOW_CURRENT_INFO_PROBLEM(true);
    },
    createTask() {
      if (!this.checkValidation()) return false;
      this.createNewTask(this.taskParams);
    },
    closeCreateTask() {
      if (this.name.length || this.deadline?.length || this.executors.length) {
        this.confirmClose = true;
      } else {
        this.closeModal();
      }
    },
    cancelClose() {
      this.confirmClose = false;
    },
    acceptClose() {
      this.closeModal();
    },
    checkValidation() {
      if (!this.name) {
        NotificationObserver.notification({
          type: "error",
          message: "Название задачи должно быть введено!"
        });
        return false;
      }

      if (this.name.length < 6 || this.name.length > 250) {
        NotificationObserver.notification({
          type: "error",
          message: "Название задачи должно быть не менее 6 и не более 250 символов!"
        });
        return false;
      }

      if (this.deadline && moment(this.deadline).isSameOrBefore(format(new Date(), "yyyy-MM-dd"))) {
        NotificationObserver.notification({
          type: "error",
          message: "Дедлайн задачи должен быть позже сегодняшнего дня!"
        });
        return false;
      }

      if (
        this.deadline &&
        this.currentInfoProblem.deadline &&
        moment(this.deadline).isAfter(this.currentInfoProblem.deadline)
      ) {
        NotificationObserver.notification({
          type: "error",
          message: "Дедлайн задачи не должен быть позже дедлайна проблемы!"
        });
        return false;
      }

      if (
        this.deadline &&
        this.setPeriodDeadline &&
        moment(this.deadline).isAfter(this.setPeriodDeadline)
      ) {
        NotificationObserver.notification({
          type: "error",
          message: "Дедлайн задачи не должен быть позже окончания квартала!"
        });
        return false;
      }

      return true;
    },
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    pickCalendar(deadline) {
      this.closeCalendar();
      this.deadline = deadline;
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "usersList"]),
    taskParams() {
      return [
        {
          name: this.name,
          executor_users: this.executors.map((item) => {
            return item.id;
          }),
          deadline: this.deadline ? moment(this.deadline).format("YYYY-MM-DD") : undefined,
          problem_id: this.currentInfoProblem.id,
          is_complete: false
        }
      ];
    },
    setPeriodDeadline() {
      return this.currentInfoProblem
        ? this.currentInfoProblem.period
          ? this.currentInfoProblem.period.end_date
          : ""
        : "";
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.modal {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__title {
    margin: 0 0 56px;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__subtitle {
    margin: 40px 0 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: $text-primary;
  }

  &__wrap {
    margin: 0 0 56px;

    &_buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  &__label {
    margin: 0 0 32px;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #0a1417;
  }

  /deep/ {
    .input {
      margin: 0 0 24px;
    }

    .input__control {
      width: 100%;
    }

    .switch-widget {
      display: flex;
    }

    .switch-widget__label-wrap {
      margin: 0 32px 0 0;
    }

    .switch-widget__label {
      white-space: nowrap;
    }

    .form-control {
      width: 668px;
      height: 48px;
      background: #ffffff;
      border: 2px solid #5551f9;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .daterangepicker {
      display: block !important;
    }

    .drp-buttons {
      display: none;
    }

    .date-picker {
      width: 100%;
    }

    .date-picker__value {
      width: 100%;
    }

    .date-picker__calendar {
      position: relative;
      width: 100%;
      margin: 24px 0 0;

      .vc-container {
        width: 100%;
      }
    }
  }
}
</style>
