<template>
  <div>
    <h2 class="modal__title" v-if="editMode">
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-start-data'"
        :editValue="transferVacationOrder.new_date_from"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateStart"
      />
      —
      <Widget
        type="InputDatePicker"
        :readonly="true"
        :unique="'modal-request-vacation-end-data'"
        :editValue="transferVacationOrder.new_date_to"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateEnd"
      />
    </h2>
    <h2 class="modal__title" v-if="createMode && !editMode">
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-start-data'"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateStart"
      />
      —
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-end-data'"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateEnd"
      />
    </h2>
    <h2 class="modal__title_read" v-if="!createMode && !editMode">
      {{ setLocalDate(transferVacationOrder.new_date_from) }}
      —
      {{ setLocalDate(transferVacationOrder.new_date_to) }}
    </h2>
    <h3 class="modal__subtitle">
      {{ setLocalDate(transferVacationOrder.old_date_from) }}
      —
      {{ setLocalDate(transferVacationOrder.old_date_to) }}
    </h3>
    <p class="modal__content" v-if="isEdit">
      Создал(а) {{ user.full_name }}
      <!--      {{ getDataOfCreation(transferVacationOrder.date_of_creation) }}-->
    </p>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import moment from "moment";
export default {
  name: "ModalDateTransfer",
  mixins: [],
  props: {
    editMode: {
      type: Boolean,
      required: true,
      default: true
    },
    createMode: {
      type: Boolean,
      required: true,
      default: false
    },
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    transferVacationOrder: {
      type: Object,
      default() {
        return {};
      }
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { Widget },
  data() {
    return {
      dateStartVacation: this.vacation.date_from,
      date: new Date()
    };
  },
  mounted() {
    this.$root.$on("date:change_modal-request-vacation-start-data", (val) => {
      this.dateStartVacation = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("date:change_modal-request-vacation-start-data");
  },
  methods: {
    getDataOfCreation(date) {
      moment.locale("ru");
      return moment(date).fromNow();
    },
    setLocalDate(date) {
      moment.locale("ru");
      return moment(date).format("L");
    },
    checkDisabledDateStart(date) {
      const currentDate = moment().add(5, "day");
      return moment(date) < currentDate;
    },
    checkDisabledDateEnd(date) {
      if (this.dateStartVacation) {
        return date < moment(this.dateStartVacation) || date === moment(this.dateStartVacation);
      } else {
        const currentDate = this.date.setDate(this.date.getDate() + 5);
        return date < currentDate || date === currentDate;
      }
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isEdit() {
      return this.editMode || (!this.editMode && !this.createMode);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal {
  &__title {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $text-disabled;

    @media (max-width: $xs) {
      font-size: 20px;
    }

    &_read {
      margin: 0;
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: $text-default;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #6a67ce;
      animation-name: border;
      animation-timing-function: ease;
      animation-duration: 0.5s;
      transform: scale(1);
      transform-origin: center;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }

    @keyframes border {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    /deep/ {
      .data-picker {
        background: transparent;
        max-width: 200px;

        @media (max-width: $xs) {
          padding: 12px 10px;
        }
      }
      .vdpComponent.vdpWithInput {
        input {
          border: none;
          max-width: 180px;
          width: 100%;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: $text-disabled;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          @media (max-width: $xs) {
            font-size: 16px;
          }
        }
      }

      .vdpComponent.vdpWithInput .vdpClearInput {
        display: none;
      }

      .vdpOuterWrap .vdpInnerWrap {
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
        border-radius: 24px;
        max-width: 320px;
        min-width: 320px;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpHeader .vdpPeriodControls .vdpPeriodControl > button {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: $text-default;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpTable .vdpRow .vdpCell.selected .vdpCellContent {
        background: #6a67ce;
      }

      .vdpCell.outOfRange {
        font-size: 12px;
        line-height: 21px;
        color: #a0aec0;
      }

      .vdpOuterWrap {
        font-size: 12px;
        line-height: 21px;
        color: #2d3748;
      }
    }

    span {
      margin-left: 8px;
    }
  }
  &__subtitle {
    padding: 0;
    margin: 24px 0 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__content {
    margin: 0 0 40px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-disabled;
  }
}
</style>
