








import { Component, Prop, Vue } from "vue-property-decorator";
import MainCell from "@/components/MainTable/Cell/MainCell.vue";
import { IHeader, ITableData } from "@/components/MainTable/MainTable.vue";

@Component({
  components: { MainCell }
})
export default class MainRow extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  isHeader!: boolean;
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITableData;
  @Prop({
    default: () => [],
    type: Array
  })
  tableHeader!: Array<IHeader>;
}
