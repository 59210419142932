<template>
  <ul class="comments__files files">
    <FilesItem
      v-for="(file, index) in readerFilesList"
      :key="file.id"
      :file="file"
      @openImage="openImage"
      @deleteFile="deleteFile(file, index)"
      :isEdit="isEdit"
    />
    <transition name="fade">
      <FullScreenImage :imageInfo="imageInfo" v-if="showFullScreenImage" @closeImage="closeImage" />
    </transition>
  </ul>
</template>

<script>
import FilesItem from "@/modules/UiKit/components/Comments/CommentsFiles/FilesItem";
import FullScreenImage from "@/modules/TaskManager/components/Modal/TaskFileModal/FullScreenImage";
import taskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "FilesList",
  mixins: [],
  props: {
    fileList: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { FilesItem, FullScreenImage },
  data() {
    return {
      imageInfo: {},
      readerFilesList: [],
      showFullScreenImage: false
    };
  },
  mounted() {
    this.addingPathForFiles(this.fileList);
  },
  methods: {
    deleteFile(file, index) {
      this.readerFilesList.splice(index, 1);
      this.$emit("deleteFile", file);
    },
    openImage(data) {
      this.imageInfo = data;
      this.showFullScreenImage = true;
    },
    closeImage() {
      this.imageInfo = null;
      this.showFullScreenImage = false;
    },
    addingPathForFiles(fileList) {
      this.readerFilesList = [];
      fileList.forEach((item) => {
        if (item.file) {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = () => {
            this.readerFilesList.push({
              path: reader.result,
              name: item.name,
              id: item.id,
              size: item.size,
              ...item
            });
          };
        } else {
          this.readerFilesList.push(item);
        }
      });
    }
  },
  computed: {
    setFiles() {
      return this.readerFilesList;
    }
  },
  watch: {
    fileList() {
      this.addingPathForFiles(this.fileList);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.files {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
