























































import { Component, Vue } from "vue-property-decorator";
import TabsNavigationQuery from "@/components/Widgets/TabsWidget/TabsNavigationQuery";
import MainTable, { IHeader, typeCell } from "@/components/MainTable/MainTable.vue";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP.vue";
import NotificationSettingCellContent from "@/modules/Notification/components/NotificationSettingCellContent.vue";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS.vue";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";
import { ISettingNotifications } from "@/modules/Notification/interfaces/interface";

interface IItemTabs {
  label: string;
  path: string;
  index: number;
}
@Component({
  components: {
    SwitchIOS,
    NotificationSettingCellContent,
    CheckboxISP,
    Button,
    MainTable,
    TabsNavigationQuery,
    Spinner
  }
})
export default class NotificationSettings extends Vue {
  isEnableNotifications: boolean = false;
  tableHeader: Array<IHeader> = [
    {
      name: "Тип уведомления",
      field: "name",
      sort: false,
      isCenter: false,
      colspan: 4,
      type: typeCell.string
    },
    {
      name: "Описание",
      field: "description",
      sort: false,
      isCenter: false,
      colspan: 6,
      type: typeCell.string
    },
    {
      name: "АОП",
      field: "aop",
      sort: false,
      isCenter: true,
      colspan: 2,
      type: typeCell.boolean
    }
    //Сервис пуш-уведомлений реализовать в следующей версии (после реализации сервиса пользователя)
    // {
    //   name: "Push",
    //   field: "push",
    //   sort: false,
    //   isCenter: true,
    //   colspan: 2,
    //   type: typeCell.boolean
    // }
    //Сервис уведомлений в telegram реализовать в следующей версии (после реализации сервиса пользователя)
    // {
    //   name: "Telegram-уведомления",
    //   field: "telegram",
    //   sort: false,
    //   isCenter: true,
    //   colspan: 2,
    //   type: typeCell.boolean
    // }
  ];
  sort = { field: "aop", type: "desc" };

  getSettingNotification() {
    this.$store.dispatch("Notification/getUserSettingsAction");
  }
  mounted() {
    this.getSettingNotification();
    if (!this.$route.query.table) {
      this.$router.push({
        name: "Notification",
        params: {},
        query: {
          table: "task_manager"
        }
      });
    }
  }
  exitSetupMode() {
    this.$router.go(-1);
  }

  /**
   * Toggle notification enable switch
   */
  enableNotifications() {
    this.isEnableNotifications = !this.isEnableNotifications;
    this.$store.commit("Notification/toggleTheStatusOfNotifications", {
      service: this.tableData.service,
      state: this.isEnableNotifications
    });
    const body = {
      service: this.tableData.service,
      switch: this.isEnableNotifications
    };
    this.$store.dispatch("Notification/saveUserSettingsAction", body);
  }

  resetSettingsToDefault() {
    this.$store.dispatch("Notification/resetSettingsToDefaultAction", this.$route.query.table);
  }
  get setDescriptionSwitch() {
    return this.tableData.isEnableNotifications
      ? "Выключить уведомления по модулю"
      : "Включить уведомления по модулю";
  }
  get tabsList() {
    const notificationSettingsList: ISettingNotifications =
      this.$store.state.Notification.notificationSettingsList;
    let arrayOfTabs: Array<IItemTabs> = [];

    Object.entries(notificationSettingsList).forEach((e, index) => {
      const itemTabs = {
        label: e[1].serviceTitle,
        path: e[0],
        index: index
      };
      arrayOfTabs.push(itemTabs);
    });
    return arrayOfTabs;
  }
  get loader() {
    return this.$store.state.Notification.loader;
  }

  get tableData() {
    const notificationSettingsList = this.$store.state.Notification.notificationSettingsList;
    if (notificationSettingsList && notificationSettingsList[String(this.$route.query.table)]) {
      this.isEnableNotifications = JSON.parse(
        JSON.stringify(
          notificationSettingsList[String(this.$route.query.table)].isEnableNotifications
        )
      );
      return notificationSettingsList[String(this.$route.query.table)];
    } else return [];
  }
}
