import moment from "moment";

const dayMonth = (day) => {
  moment.locale("ru");
  return moment(day).format("MM");
};

const dayOnWeek = (weekDay, date) => {
  moment.locale("ru");
  return moment(date).isoWeekday(weekDay).format("YYYY-MM-DD");
};

export const weekDay = (currentDate, lastDay = "Пятница") => {
  moment.locale("ru");
  const weekDays: Array<string> = [
    "Воскресенье",
    "Суббота",
    "Четверг",
    "Среда",
    "Вторник",
    "Понедельник"
  ];
  const reversWeekDays: Array<string> = [
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
    "Воскресенье"
  ];

  const day = currentDate;
  const date = {
    startWeekDay: dayOnWeek("Понедельник", day),
    lastDayWeek: dayOnWeek(lastDay, day)
  };
  const monday_m = dayMonth(date.startWeekDay);
  const friday_m = dayMonth(date.lastDayWeek);
  if (monday_m === friday_m) {
    return date;
  } else {
    if (dayMonth(day) === monday_m) {
      const dayWeek = weekDays.filter(
        (item) => monday_m === moment(day).isoWeekday(item).format("MM")
      );
      date.lastDayWeek = dayOnWeek(`${dayWeek}`, day);
      return date;
    } else {
      const dayWeek = reversWeekDays.filter(
        (item) => friday_m === moment(day).isoWeekday(item).format("MM")
      );
      date.startWeekDay = dayOnWeek(`${dayWeek}`, day);
      return date;
    }
  }
};
