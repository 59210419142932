<template>
  <div class="vacation-requests">
    <VacationRequestTable
      :userId="getUserId"
      :isAdmin="isAdmin"
      :scrollLoading="scrollLoading"
      :absenceVacationRequest="absenceVacationRequest"
      :transferOrderRequest="transferOrderRequest"
    />
  </div>
</template>

<script>
import VacationRequestTable from "@/components/VacationRequests/VacationRequestTable/VacationRequestTable";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
import { mapGetters } from "vuex";
export default {
  name: "VacationRequests",
  mixins: [],
  props: [],
  components: { VacationRequestTable },
  data() {
    return {
      isAdmin: false,
      scrollLoading: false,
      absenceVacationRequest: [],
      transferOrderRequest: [],
      isShowConformationModal: false,
      isAvailableForEditing: true,
      isShowRequestConfirmationModal: false
    };
  },
  beforeDestroy() {
    this.$root.$off("goGetInfoUser");
  },
  mounted() {
    if (!this.isAdminVacation) {
      this.$router.push({ name: "Profile" });
      return;
    }
    this.getRequestPersonnelOfficer();
  },
  methods: {
    getRequestPersonnelOfficer() {
      this.scrollLoading = true;
      httpClient({
        method: "GET",
        url: `/VacationOrder/actionGetAdminOrders`
      }).then((response) => {
        this.absenceVacationRequest = response.data.data.data.absence_orders;
        this.transferOrderRequest = response.data.data.data.transfer_vacation_orders;
        this.scrollLoading = false;
      });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    ...mapGetters(["getEndpoints"]),
    isAdminVacation() {
      return this.getDataInfoUser.roles.some((item) => item === "vacation_admin");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
.vacation-requests {
  font-family: "SF", sans-serif !important;
  //padding: 24px 32px;
  height: 98%;
  width: 100%;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  @media (max-width: $md) {
    height: calc(100vh - 60px);
  }
  @media (max-width: 450px) {
    padding: 30px 0 0;
  }
  //@media (max-height: $xxs) {
  //  padding-bottom: 0;
  //}
}
</style>
