import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import moment from "moment";

const actionsIspReview = {
  [types.GET_QUARTER_LIST](state, payload) {
    state.quarterList = payload;
  },

  [types.FIND_CURRENT_QUARTER](state, payload) {
    if (payload.find((item) => item.status === "В работе")) {
      state.currentQuarter = payload.find((item) => item.status === "В работе");
    } else if (payload.find((item) => item.status === "Не начат")) {
      state.currentQuarter = payload.length
        ? payload
            .filter((item) => item.status === "Не начат")
            .sort((a, b) => moment(a.start_date) - moment(b.start_date))[0]
        : null;
    } else {
      state.currentQuarter = payload.length
        ? payload
            .filter((item) => item.status === "Завершен")
            .sort((a, b) => moment(b.end_date) - moment(a.end_date))[0]
        : null;
    }
  },

  [types.SET_CURRENT_QUARTER](state, payload) {
    state.currentQuarter = payload;
  },

  [types.SET_REVIEW_PROBLEM_PARAMS](state, payload) {
    state.problemsReviewParams = payload;
  },

  [types.GET_PROBLEM_LIST](state, payload) {
    state.problemsData = payload;
  },

  [types.GET_REVIEW_STATISTICS_LIST](state, payload) {
    state.quarterStatistics = payload;
  },

  [types.SET_REVIEW_PROGRESS](state, payload) {
    const reqExp = new RegExp("^99.[0-9]+");
    if (reqExp.test(payload)) {
      state.quarterProgress = 99;
    } else {
      state.quarterProgress = Math.round(payload);
    }
  },

  [types.GET_BACKLOG_PROBLEM_LIST](state, payload) {
    state.backlogProblemsData = payload;
  },

  [types.SET_REVIEW_CRITICALITY](state, payload) {
    state.reviewCriticalityValue = payload;
  },

  [types.SET_REVIEW_STATUS](state, payload) {
    state.reviewStatusValue = payload;
  },

  [types.SET_REVIEW_GROUPS](state, payload) {
    state.reviewGroupValue = payload;
  },

  [types.SET_REVIEW_CUSTOMER](state, payload) {
    state.reviewCustomersValue = payload;
  },

  [types.SET_REVIEW_RESPONSIBLE](state, payload) {
    state.reviewResponsibleValue = payload;
  },

  [types.SHOW_ADD_AND_EDIT_QUARTER](state, payload) {
    state.showCreateAndEditQuarter = payload;
  },

  [types.SET_EDIT_QUARTER](state, payload) {
    state.editQuarter = payload;
  },

  [types.SET_CRITICALITY_WEIGHT](state, payload) {
    state.criticalityWeight = payload;
  },

  [types.SET_CURRENT_QUARTER_CRITICALITY_WEIGHT](state, payload) {
    state.currentPeriodCriticalityWeight = payload;
  },

  [types.SET_SHOW_PROGRESS_MODAL](state, payload) {
    state.showProgressModal = payload;
  },

  [types.SET_CHANNEL_CENTRIFUGE](state, payload) {
    state.channelCentrifuge = payload;
  },

  [types.SHOW_DUPLICATE_MODAL](state, payload) {
    state.showDuplicateModal = payload;
  }
};

export default actionsIspReview;
