import { render, staticRenderFns } from "./TextLabel.vue?vue&type=template&id=1089a0ba&scoped=true&"
import script from "./TextLabel.vue?vue&type=script&lang=ts&"
export * from "./TextLabel.vue?vue&type=script&lang=ts&"
import style0 from "./TextLabel.vue?vue&type=style&index=0&id=1089a0ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1089a0ba",
  null
  
)

export default component.exports