<template>
  <div
    class="input-wrap"
    :class="[activeInput ? 'input-wrap_enabled' : 'input-wrap_disabled']"
    v-click-outside="closeEmojiModal"
  >
    <i :class="iconClass" v-if="iconClass.length" @click="handleIcon"></i>
    <input
      id="input"
      ref="input"
      v-if="!isTextarea"
      @focus="inputFocus"
      @blur="inputBlur"
      @input="$emit('changeText', { value, index })"
      :type="type"
      autocomplete="off"
      class="input-wrap__input"
      v-model.trim="value"
      :placeholder="placeholder"
      :maxlength="maxLengthInput"
      :minlength="minlength"
      :disabled="disabled"
      :class="classList"
    />
    <i v-if="isEmoji" class="icon-emoji" @click="showModalEmoji = !showModalEmoji"></i>
    <textarea
      id="textarea"
      v-if="isTextarea"
      @focus="inputFocus"
      @blur="inputBlur"
      :class="classList"
      type="text"
      autocomplete="off"
      class="input-wrap__input"
      v-model.trim="value"
      @input="mixin_autoResize_resize"
      @change="$emit('changeText', { value, index })"
      :placeholder="placeholder"
      ref="inputNewsTextContent"
      :maxlength="maxLengthTextarea"
      :minlength="minlength"
      :disabled="disabled"
      @paste="Past__TextArea"
    />
    <Picker v-if="showModalEmoji" set="apple" @select="addEmoji" :i18n="settings" />
  </div>
</template>

<script>
import ResizeMixin from "@/helpers/ResizeMixin";
import ClickOutside from "vue-click-outside";
import { Picker } from "emoji-mart-vue";
export default {
  name: "TextInput",
  mixins: [ResizeMixin],
  props: {
    value: {
      type: String,
      default: ""
    },
    editValue: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "text"
    },
    classList: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    iconClass: {
      type: String,
      default: ""
    },
    isTextarea: {
      type: Boolean,
      default: false
    },
    maxLengthInput: {
      type: Number,
      default: 128
    },
    maxLengthTextarea: {
      type: Number,
      default: 10000
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isEmoji: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: true
    },
    minlength: {
      type: Number,
      default: 0
    }
  },
  components: { ClickOutside, Picker },
  data() {
    return {
      showModalEmoji: false,
      activeInput: false,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {
    if (this.focus && this.isTextarea) {
      this.$refs.inputNewsTextContent.focus();
    }
    if (this.editValue) {
      this.value = this.editValue;
    }
    this.$nextTick(() => {
      if (this.$refs.inputNewsTextContent.scrollHeight) {
        this.$refs.inputNewsTextContent.style.height = `${this.$refs.inputNewsTextContent.scrollHeight}px`;
      } else {
        this.$refs.inputNewsTextContent.style.height = `24px`;
      }
    });
  },
  methods: {
    handleIcon() {
      this.$refs.input.focus();
    },
    inputFocus() {
      this.activeInput = true;
    },
    inputBlur() {
      this.activeInput = false;
    },
    closeEmojiModal() {
      this.showModalEmoji = false;
    },
    addEmoji(emoji) {
      // selectionStart - Начало
      // selectionEnd - Конец
      let cursorPosition = this.$refs.inputNewsTextContent.selectionStart;
      let FirstPart = "";
      if (cursorPosition) {
        FirstPart = this.value.substring(0, cursorPosition);
      }
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition + 1, this.value.length);
      this.value = FirstPart + Emoji + SecondPart;
      this.$emit("changeText", { value: this.value, index: this.index });
    },
    Past__TextArea(event) {
      this.$emit("pasteFile", event.clipboardData.files[0]);
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.input-wrap {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: 0 0 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: $text-disabled;
  .font24 {
    font-size: 24px;
    line-height: 28px;
  }
  .font16 {
    font-size: 16px;
    line-height: 24px;
  }
  .font13 {
    font-size: 14px;
    line-height: 20px;
  }
  .icon-emoji {
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .emoji-mart {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;

    height: 250px;
    width: 250px !important;

    /deep/ {
      .emoji-mart-bar {
        display: none;
      }

      .emoji-mart-search {
        display: none;
      }
    }
  }

  &__input {
    margin-bottom: 2px;
    border: none;
    outline: none;
    width: 95%;
    resize: none;
    color: $text-default;
    filter: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      //-webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &::placeholder {
      color: $text-disabled;
    }
    @media screen and (max-width: $xs) {
    }
  }
  textarea {
    padding-left: 10px;
    font-size: 14px;
    line-height: 20px;
    height: 24px;
    filter: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #fff;

      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  &_enabled {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #6a67ce;
      animation-name: border;
      animation-timing-function: ease;
      animation-duration: 0.5s;
      transform: scale(1);
      transform-origin: center;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }
  }
  &_disabled {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #d3d2e0;
      transform: scale(1);
      transform-origin: center;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }
  }
  @keyframes border {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}
</style>
