<template>
  <div class="plan-info">
    <div class="plan-info__wrap">
      <div class="plan-info__name">
        <p>Название</p>
        <Skeleton
          v-if="planInfoLoader && !(calculatingLoader && planInfoLoader)"
          border-radius="8px"
        />
        <Input
          v-else
          v-model="title"
          :placeholder="'Введите название'"
          :maxlength="64"
          @keydownEnter="handleKeydownEnterTitle"
          @input="changeTitle"
          v-click-outside="handleClickOutsideTitle"
        />
      </div>
      <div class="plan-info__content">
        <ul class="plan-info__list plan-info__list_first">
          <li>
            <p class="plan-info__item-title">
              Дата начала <Tooltip :text="'Плановая дата начала выполнения плана работ.'" />
            </p>
            <Skeleton
              v-if="planInfoLoader || calculatingLoader"
              class="skeleton_date"
              :borderRadius="'2px'"
            />
            <DateInput
              v-else
              :date="mode === 'create' ? null : plan.date_start"
              :disabled="disabled"
              @input="editDateStart"
            />
          </li>
          <li>
            <p class="plan-info__item-title">
              Дата окончания <Tooltip :text="'Плановая дата завершения плана работ.'" />
            </p>
            <Skeleton
              v-if="planInfoLoader || calculatingLoader"
              class="skeleton_date"
              :borderRadius="'2px'"
            />
            <DateInput
              v-else
              :date="mode === 'create' ? null : plan.date_deadline"
              :disabled="disabled"
              @input="editDateDeadline"
            />
          </li>
          <li>
            <p class="plan-info__item-title">
              Длительность (дн)
              <Tooltip :text="'Количество дней, необходимых для выполнения действия.'" />
            </p>
            <Skeleton v-if="planInfoLoader || calculatingLoader" class="skeleton_small" />
            <span v-else>
              {{ duration }}
            </span>
          </li>
        </ul>
        <ul class="plan-info__list plan-info__list_second">
          <li>
            <p class="plan-info__item-title">
              Поздняя дата начала<Tooltip
                :text="'Максимальное время, на которое можно передвинуть начало плана работ. Определяется как самая ранняя из всех установленных поздних дат начала действий.'"
              />
            </p>
            <Skeleton v-if="planInfoLoader || calculatingLoader" class="skeleton_text" />
            <span v-else>{{ dateStartLate }}</span>
          </li>
          <li>
            <p class="plan-info__item-title">
              Ранняя дата окончания<Tooltip
                :text="'Плановая дата окончания плана работ. Определяется как самая поздняя из всех установленных ранних дат окончания действий.'"
              />
            </p>
            <Skeleton v-if="planInfoLoader || calculatingLoader" class="skeleton_text" />
            <span v-else>{{ dateEnd }}</span>
          </li>
          <li>
            <p class="plan-info__item-title">
              Поздняя дата окончания<Tooltip
                :text="'Максимальное время, на которое можно увеличить продолжительность плана без нарушения сроков завершения плана работ в целом. '"
              />
            </p>
            <Skeleton v-if="planInfoLoader || calculatingLoader" class="skeleton_text" />
            <span v-else>{{ dateEndLate }}</span>
          </li>
          <li>
            <p class="plan-info__item-title">
              Резерв (дн)<Tooltip
                :text="'Количество рабочего времени, на которое может быть увеличена продолжительность плана или перенесено его начало без нарушения установленной поздней даты окончания плана. Если резерв будет отрицательным, поздние даты и резерв для действий не будут рассчитаны.'"
              />
            </p>
            <Skeleton v-if="planInfoLoader || calculatingLoader" class="skeleton_small" />
            <span v-else>{{ reserve }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";
import DateInput from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanInfo/DateInput";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
import ClickOutside from "vue-click-outside";
import NotificationObserver from "@/helpers/UserNotification";
import moment from "moment";

export default {
  name: "PlanInfo",
  mixins: [],
  props: {
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    mode: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { Skeleton, DateInput, Tooltip, Input },
  data() {
    return {
      title: "",
      originTitle: ""
    };
  },
  mounted() {
    if (this.mode === "create") {
      document.querySelector(".input__control").focus();
    }
    this.title = this.plan.name;
    this.originTitle = this.title;
  },
  methods: {
    async handleKeydownEnterTitle() {
      if (this.mode === "base-create" || this.mode === "base-edit" || !this.validateTitle()) return;
      if (this.mode === "create") {
        const id = await PlanningScheduleModule.createPlanAction({ name: this.title });
        await PlanningScheduleModule.getPlanAction(id);
        this.$router.push({ name: "Plan", query: { mode: "edit", id } });
      }
      await this.editPlanTitle();
    },
    async handleClickOutsideTitle() {
      if (this.mode === "base-create" || this.mode === "base-edit" || !this.validateTitle()) return;
      if (this.mode === "create") {
        const id = await PlanningScheduleModule.createPlanAction({ name: this.title });
        await PlanningScheduleModule.getPlanAction(id);
        this.$router.push({ name: "Plan", query: { mode: "edit", id } });
      }
      if (!this.title?.length) {
        this.title = this.originTitle;
        return;
      }
      this.editPlanTitle();
    },
    async editPlanTitle() {
      if (this.originTitle === this.title) return;
      const editedPlan = {
        id: this.plan.id,
        name: this.title
      };
      await PlanningScheduleModule.editPlanAction(editedPlan);
      this.originTitle = this.title;
    },
    validateTitle() {
      if (!this.title?.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название плана!"
        });
        return false;
      }
      if (this.title.length > 64) {
        NotificationObserver.notification({
          type: "error",
          message: "Название не может содержать более 64 символов!"
        });
        return false;
      }
      return true;
    },
    editDateStart(date) {
      const editedPlan = {
        id: this.plan.id,
        date_start: date
          ? moment(date).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD")
      };
      PlanningScheduleModule.editPlanAction(editedPlan);
    },
    editDateDeadline(date) {
      const editedPlan = {
        id: this.plan.id,
        date_deadline: date ? moment(date).format("YYYY-MM-DD") : null
      };
      PlanningScheduleModule.editPlanAction(editedPlan);
    },
    changeTitle(val) {
      this.$emit("changeTitle", val);
    }
  },
  computed: {
    duration() {
      return this.plan?.duration ?? "Auto";
    },
    dateStartLate() {
      return this.plan?.date_start_late?.split("-").reverse().join("/") ?? "Auto";
    },
    dateEnd() {
      return this.plan?.date_end?.split("-").reverse().join("/") ?? "Auto";
    },
    dateEndLate() {
      return this.plan?.date_end_late?.split("-").reverse().join("/") ?? "Auto";
    },
    reserve() {
      return this.plan?.reserve ?? "Auto";
    },
    planInfoLoader() {
      return PlanningScheduleModule.planInfoLoader;
    },
    calculatingLoader() {
      return PlanningScheduleModule.calculatingLoader;
    }
  },
  watch: {
    plan(val) {
      this.title = val.name;
      this.originTitle = val.title;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.plan-info {
  box-shadow: -1px -1px 0px rgba(166, 166, 166, 0.1), inset -1px -1px 0px rgba(166, 166, 166, 0.1);
  &__wrap {
    display: flex;
    padding: 16px 24px;
  }
  &__name {
    width: 100%;
    max-width: 585px;
    margin-right: 24px;
    p {
      margin-bottom: 12px;
    }
    /deep/ {
      .skeleton {
        height: 40px;
      }
      input {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    &_first {
      gap: 56px;
      margin-bottom: 16px;
    }
    &_second {
      gap: 48px;
    }
  }
  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $text-disabled;
  }
  &__item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #0a1417;
    display: flex;
    margin-right: 16px;
    text-align: right;
  }
  .skeleton {
    &_date {
      width: 125px;
      height: 40px;
    }
    &_text {
      width: 90px;
      height: 23px;
    }
    &_small {
      width: 20px;
      height: 23px;
    }
  }
}
</style>
