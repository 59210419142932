<template>
  <div class="vacation-table" ref="tableContainer">
    <div class="vacation-table__header">
      <nav class="vacation-table__nav">
        <ul class="vacation-table__nav-list">
          <TabRequest
            v-for="(tab, idx) in tabsAdmin"
            :tab="tab"
            :idx="idx"
            :key="`tab_${idx}`"
            :activeTab="activeTab"
            :isAdmin="isAdmin"
            :myVacationLength="myVacation.length"
            :vacationPendingRequestLength="setLength"
            :transferOrderRequestLength="transferOrderRequest.length"
            :absenceVacationRequestLength="absenceVacationRequest.length"
            @updateTab="updateTab"
          />
          <li
            class="vacation-table__nav-item_background"
            :style="{
              width: backgroundWidth + 'px',
              height: backgroundHeight + 'px',
              left: backgroundPosition + 'px'
            }"
          ></li>
        </ul>
      </nav>
    </div>
    <div class="vacation-table__table">
      <div class="vacation-table__wrap" v-if="activeTab === 0">
        <TransferOrderRequestTable
          :transferOrderRequest="transferOrderRequest"
          v-if="!scrollLoading"
        />
        <div class="vacation-table__loader" v-if="scrollLoading">
          <Loader />
        </div>
      </div>
      <div class="vacation-table__wrap" v-if="activeTab === 1">
        <AbsenceRequestTable
          :absenceVacationRequest="absenceVacationRequest"
          v-if="!scrollLoading"
        />
        <div class="vacation-table__loader" v-if="scrollLoading">
          <Loader />
        </div>
      </div>
    </div>
    <VacationRequestModal
      v-if="isShowVacationRequestModal"
      :createMode="createMode"
      :editMode="editMode"
      :vacation="vacation"
      :user="getDataInfoUser"
      :isUser="isUser"
      :request="request"
      :isAvailableForEditing="isAvailableForEditing"
    />
    <ConfirmationModal
      v-if="isShowConformationModal"
      :vacation="vacation"
      :createMode="createMode"
      :editMode="editMode"
      :isUser="isUser"
      :user="getDataInfoUser"
      :request="request"
      :isAvailableForEditing="isAvailableForEditing"
    />
    <MyConfirmationModal
      v-if="isShowMyConfirmationModal"
      :vacation="vacation"
      :createMode="createMode"
      :editMode="editMode"
      :isUser="isUser"
      :user="getDataInfoUser"
      :type="vacationType"
      :isAvailableForEditing="isAvailableForEditing"
    />
    <RequestConformationModal
      v-if="isShowRequestConfirmationModal"
      :vacation="vacation"
      :createMode="createMode"
      :editMode="editMode"
      :isUser="isUser"
      :user="getDataInfoUser"
      :type="vacationType"
      :isAvailableForEditing="isAvailableForEditing"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class"
  },
  buttonsStyling: false
});
import Widget from "@/components/Widgets/Widget";

import { domain } from "@/globalVariables";
import RequestTable from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/PendingRequestTable/RequestTable";
import MyRequestsTable from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/MyRequestTable/MyRequestsTable";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import VacationRequestModal from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/VacationRequestModal";
import MyConfirmationModal from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/MyConfirmationModal";
import ConfirmationModal from "@/components/GraphVacation/GraphVacationModals/ConfirmationModal";
import TabRequest from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestComponents/TabsRequest/TabRequest";
import TransferOrderRequestTable from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/TransferOrderRequestTable/TransferOrderRequestTable";
import AbsenceRequestTable from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/AbsenceRequestTable/AbsenceRequestTable";
import RequestConformationModal from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/RequestConformationModal";
import moment from "moment";
import httpClient from "@/api/client/httpClient";
export default {
  name: "VacationRequestTable",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: true
    },
    userId: {
      type: String,
      default: ""
    },
    scrollLoading: {
      type: Boolean,
      default: false
    },
    vacationPendingRequest: {
      type: Array,
      default: () => {
        return [];
      }
    },
    myVacation: {
      type: Array,
      default: () => {
        return [];
      }
    },
    absenceVacationRequest: {
      type: Array,
      default: () => {
        return [];
      }
    },
    transferOrderRequest: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    RequestConformationModal,
    AbsenceRequestTable,
    TransferOrderRequestTable,
    TabRequest,
    ConfirmationModal,
    VacationRequestModal,
    MyConfirmationModal,
    Loader,
    RequestTable,
    MyRequestsTable,
    Widget
  },
  data() {
    return {
      tabsUser: ["Мои запросы", "Запросы на рассмотрении"],
      tabsAdmin: ["Переносы", "Отпуска"],
      activeTab: 0,
      backgroundWidth: "",
      backgroundHeight: "",
      backgroundPosition: "",
      userCounter: 16,
      userTotalCount: 0,
      offsetSize: 0,
      changeSearch: "",
      scrollLoading: false,
      isShowVacationRequestModal: false,
      editMode: false,
      createMode: false,
      vacation: {},
      isUser: false,
      user: {},
      isShowConformationModal: false,
      request: false,
      isAvailableForEditing: true,
      isShowMyConfirmationModal: false,
      isShowRequestConfirmationModal: false,
      vacationType: "",
      year: moment(new Date()).year().toString()
    };
  },
  mounted() {
    this.checkCloseEditVacation();
    // TODO: вынести логику в рутах в функции
    this.$root.$on("openVacationRequestModal", (val) => {
      this.editMode = val.editMode;
      this.createMode = val.createMode;
      this.vacation = val.vacation;
      this.request = val.request;
      this.modalManager();
    });
    this.$root.$on("closeVacationRequestModal", () => {
      this.isShowVacationRequestModal = false;
      this.createMode = false;
      this.isShowConformationModal = false;
    });
    this.$root.$on("openMyConfirmations", (val) => {
      this.vacation = val.vacation;
      this.vacationType = val.type;
      this.isShowMyConfirmationModal = true;
    });

    this.$root.$on("closeMyConfirmations", (val) => {
      this.isShowMyConfirmationModal = false;
      this.createMode = false;
      this.isShowConformationModal = false;
    });
    this.$root.$on("openRequestConfirmation", (val) => {
      this.vacation = val.vacation;
      this.isShowRequestConfirmationModal = true;
    });
    this.$root.$on("closeRequestConfirmation", (val) => {
      this.isShowRequestConfirmationModal = false;
      this.createMode = false;
      this.isShowConformationModal = false;
    });
    this.$root.$on("deleteCurrentTransferVacationOrder", (val) => {
      if (val.not_accepted_transfer_vacation_order) {
        this.askDeleteTransferVacationOrder(val.not_accepted_transfer_vacation_order.id);
      } else {
        this.askDeleteVacation(val.id);
      }
    });
    this.$root.$on("openConformationModal", (val) => {
      this.editMode = val.editMode;
      this.createMode = val.createMode;
      this.vacation = val.vacation;
      this.modalManager();
    });
    this.$root.$on("deleteVacation", (val) => {
      this.askDeleteVacation(val);
    });
    this.$root.$on("hiddenGraphVacationModal", () => {
      this.hiddenConformationModal();
    });
    this.backgroundWidth = this.$refs.tab[this.activeTab].clientWidth;
    this.backgroundHeight = 2;
    this.backgroundPosition = this.$refs.tab[this.activeTab].offsetLeft;
  },
  beforeDestroy() {
    this.$root.$off("openVacationRequestModal");
    this.$root.$off("closeVacationRequestModal");
    this.$root.$off("deleteVacation");
    this.$root.$off("openConformationModal");
    this.$root.$off("hiddenGraphVacationModal");
    this.$root.$off("deleteCurrentTransferVacationOrder");
    this.$refs.tableContainer.removeEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    checkCloseEditVacation() {
      httpClient({
        method: "GET",
        url: `/VacationSchedule/actionGetItem`,
        params: {
          year: this.year
        }
      }).then((response) => {
        if (response.data.data.data.length) {
          this.isAvailableForEditing = response.data.data.data[0].is_available_for_editing;
          this.$root.$emit("isAvailableForEditing", this.isAvailableForEditing);
        }
      });
    },
    updateTab(i) {
      this.activeTab = i;
      this.backgroundWidth = this.$refs.tab[i].clientWidth;
      this.backgroundHeight = 2;
      this.backgroundPosition = this.$refs.tab[i].offsetLeft;
      this.$emit("update:tab_item", this.tabs[this.activeTab]);
      this.$emit("update:tab_index", this.activeTab);
      this.$root.$emit(`tab:update_vacationTable`, this.tabs[this.activeTab]);
    },
    getTableData() {
      this.getRequestPersonnelOfficer();
    },
    modalManager() {
      this.isUser =
        this.user.id === this.getDataInfoUser.id ||
        this.getDataInfoUser.roles.find((item) => item === "personnel_officer") !== undefined;
      if (this.vacation.not_accepted_transfer_vacation_order) {
        this.isShowVacationRequestModal = true;
      } else {
        if (!this.isAvailableForEditing && this.createMode) {
          this.isShowVacationRequestModal = true;
          this.isShowConformationModal = false;
        } else {
          this.isShowConformationModal = true;
        }
      }
    },
    //открытие модалки подтверждения удаления переноса
    askDeleteTransferVacationOrder(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить запрос на перенос отпуска?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteCurrentTransferVacationOrder(id);
          }
        });
    },
    deleteCurrentTransferVacationOrder(id) {
      httpClient({
        method: "DELETE",
        url: `/TransferVacationOrder/actionDelete/`,
        params: {
          id: id
        }
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на перенос отпуска успешно удален!"
          });
          this.isDeleteVacationModal = false;
          this.getTableData();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },

    askDeleteVacation(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить запрос на отпуск?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteCurrentVacation(id);
          }
        });
    },
    // удаление отпуска
    deleteCurrentVacation(id) {
      httpClient({
        method: "DELETE",
        url: `/VacationOrder/actionDelete/`,
        params: {
          id: id
        }
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на отпуск успешно удален!"
          });
          this.isDeleteVacationModal = false;
          this.getTableData();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    hiddenConformationModal() {
      this.isShowConformationModal = false;
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    setLength() {
      return this.vacationPendingRequest.filter((item) => item.confirmable).length;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.vacation-table {
  padding: 0 40px;
  display: flex;
  flex-flow: column;
  position: relative;
  color: $text-default;
  box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
  border-radius: 24px;
  background-color: $color-white;
  height: 100%;
  overflow: auto;

  @media (max-width: $xxl) {
    max-width: 90vw;
  }

  @media (max-width: $xs) {
    max-width: 100vw;
    border-radius: 24px 24px 0 0;
    padding: 0 0 24px;
  }

  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  &__header {
    top: 0;
    position: sticky;
    z-index: 20;
    background-color: $color-white;
    padding-bottom: 24px;

    @media (max-width: $md) {
      width: 100%;
      border-radius: 24px 24px 0 0;
      padding: 24px 18px;
    }
  }
  &__nav {
    position: sticky;
    top: 0;
    z-index: 10;

    padding-top: 30px;

    background-color: #fff;
    border-bottom: 1px solid $border-default;

    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: $text-subdued;

    @media (max-width: $sm) {
      padding: 0;
      font-size: 10px;
      line-height: 20px;
    }
  }
  &__nav-list {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-item {
    margin-left: 24px;
    padding-bottom: 16px;
    cursor: pointer;
    font-feature-settings: "salt" on;
    transition: color 0.3s ease-in-out;
    &:first-child {
      margin-left: 0;
    }
    &_active {
      position: relative;
      color: $text-highlight;
      transition: all 0.3s ease-in-out;
      animation-name: font;
      text-shadow: 0 0 0.65px $text-highlight, 0 0 0.65px $text-highlight,
        0 0 0.65px $text-highlight;
    }
    &_background {
      position: absolute;

      transition: all 0.3s ease;
      z-index: 2;
      bottom: 0;
      top: auto;

      background-color: $color-accent;
      border-radius: 0;
    }
  }

  @keyframes font {
    from {
      text-shadow: 0 0 0 transparent, 0 0 0 transparent;
    }
    to {
      text-shadow: 0 0 0.65px $text-highlight, 0 0 0.65px $text-highlight;
    }
  }

  /deep/ {
    .form-group__input {
      height: auto;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.2px;
      color: #92929d;

      padding: 12px 10px 11px 48px;

      border: 1px solid #e2e2ea;
      border-radius: 12px;

      &::placeholder {
        color: #92929d;
      }
    }
  }
}
</style>
