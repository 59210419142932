import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import Vue from "vue";
import store from "@/store";
import moment from "moment";
import CalendarService from "@/aopV2/modules/Calendar/service/calendarService";
import _ from "lodash";
import {
  dataToEventAdapter,
  dateFormat,
  isValidEvent
} from "@/aopV2/modules/Calendar/helper/helperFunc";
import { EventBus } from "@/main";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import { diffArray } from "@/aopV2/modules/Calendar/helper/diffArray";
import router from "@/router";
import {
  IChangeInvitationParams,
  ICreateData,
  ICurrentEventData,
  IEmployment,
  IEventData,
  IEventDataProfile,
  IGetEventForProfileParams,
  IGetEventParams,
  IGetOneEventParams,
  IGroup,
  ILocations
} from "@/aopV2/modules/Calendar/service/CalendarInterface";

const name = "calendarModule2";

export enum calendarMutationTypes {}

export enum gridType {
  week = "week",
  day = "day",
  month = "month"
}

type typeEventListSetting = {
  x: number;
  y: number;
  events: Array<any>;
  isShow: boolean;
  day: string;
};
if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class CalendarModule2 extends VuexModule {
  date: string = moment(new Date()).format("YYYY-MM-DD");
  type: string = "week" as gridType;
  isWeekends: boolean = false;
  eventTypeCategories: Array<any> = [];
  events: Array<any> = [];
  currentEvent: ICurrentEventData | null = null;
  workingEvents: Array<any> = [];
  activityEvents: Array<any> = [];
  eventsParams: IGetEventParams | null = null;
  eventType: Array<any> = [];
  eventForProfile: Array<IEventDataProfile> = [];
  locations: Array<ILocations> = [];
  currentLocation: ILocations | null = null;
  showLocationModal: boolean = false;
  modeLocationModal: string = "show";
  showEventModal: boolean = false;
  modeEventModal: string = "show";
  loader: boolean = false;
  isShowFullScreenEvent: boolean = false;
  showGroupModal: boolean = false;
  isShowCreateEvent: boolean = false;
  isCreateLoader: boolean = false;
  isShowViewEvent: boolean = false;
  modeGroupModal: string = "show";
  groups: Array<IGroup> = [];
  currentGroup: IGroup | null = null;
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  gridStartTime: string = "08:00";
  gridStopTime: string = "20:00";
  isMainLoader: boolean = true;
  isLoad: boolean = false;
  eventListSetting: null | typeEventListSetting = null;
  showUsersModal: boolean = false;
  justCurrent: boolean = true;
  eventIsEqual: boolean = true;
  users: Array<any> = [];
  saveGroups: Array<any> = [];
  editEventId: undefined | number = undefined;
  oldEventRepeat: any = null;
  createDataDef: ICreateData = {
    id: undefined,
    name: "",
    currentType: { id: 0, name: "default" },
    description: "",
    isCommonEvent: false,
    isPrivate: false,
    selectDate: dateFormat(new Date()),
    selectGroups: [],
    selectLocation: [{ id: 0, name: "Без локации" }],
    selectMembers: [],
    selectResponsible: [],
    timeEnd: "",
    timeStart: "",
    timezone: this.timezone,
    toggleLink: false,
    isDateValid: true,
    isStartValid: true,
    isEndValid: true,
    repeat: null,
    files: []
  };
  createData: ICreateData = {
    id: undefined,
    name: "",
    currentType: { id: 0, name: "default" },
    description: "",
    isCommonEvent: false,
    isPrivate: false,
    selectDate: dateFormat(new Date()),
    selectGroups: [],
    selectLocation: [{ id: 0, name: "Без локации" }],
    selectMembers: [],
    selectResponsible: [],
    timeEnd: "",
    timeStart: "",
    timezone: this.timezone,
    toggleLink: false,
    isDateValid: true,
    isStartValid: true,
    isEndValid: true,
    repeat: null,
    files: []
  };
  employment: IEmployment = {
    overlapping_by_users: [],
    overlapping_by_location: []
  };
  isShowEmployment: boolean = false;
  activeTab: string = "Person";
  tooltip: string[] = [];
  isShowTooltip: boolean = false;
  tooltipId: number = 0;
  noAcceptEvent: boolean = true;
  tooltipX: number = 0;
  tooltipY: number = 0;
  dateFrom: string = dateFormat(new Date());
  dateTo: string = dateFormat(new Date());
  selectedUsers: Array<any> = [];
  selectedGroups: Array<any> = [];
  selectedTypes: Array<any> = [];
  selectedLocations: Array<any> = [];

  @Mutation
  setType(type: gridType) {
    this.type = type;
  }

  @Mutation
  setDateFrom(date: string) {
    this.dateFrom = date;
  }

  @Mutation
  setDateTo(date: string) {
    this.dateTo = date;
  }

  @Mutation
  setDate(date: string) {
    this.date = date;
  }

  @Mutation
  switchIsEqual(isEqual: boolean) {
    this.eventIsEqual = isEqual;
  }

  @Mutation
  setLoader(val: boolean) {
    this.loader = val;
  }

  @Mutation
  setSaveGroups(val: Array<any>) {
    this.saveGroups = val;
  }

  @Mutation
  setIsWeekends() {
    this.isWeekends = !this.isWeekends;
  }

  @Mutation
  setGridStartTime(val: string) {
    this.gridStartTime = val;
  }

  @Mutation
  setGridStopTime(val: string) {
    this.gridStopTime = val;
  }

  @Mutation
  toggleShowGroupModal(options: { show: boolean; mode: string }) {
    this.showGroupModal = options.show;
    this.modeGroupModal = options.mode;
  }

  @Mutation
  toggleShowLocationModal(options: { show: boolean; mode: string }) {
    this.showLocationModal = options.show;
    this.modeLocationModal = options.mode;
  }

  @Mutation
  toggleShowEventModal(options: { show: boolean; mode: string }) {
    this.showEventModal = options.show;
    this.modeEventModal = options.mode;
  }

  @Mutation
  toggleShowUsersModal(options: boolean) {
    this.showUsersModal = options;
  }

  @Mutation
  toggleNoAcceptEvents(options: boolean) {
    this.noAcceptEvent = options;
  }

  @Mutation
  changeStateInvitation(data: IChangeInvitationParams) {
    if (this.currentEvent) {
      Vue.delete(this.currentEvent as ICurrentEventData, "is_accepted_invitation");
      Vue.set(
        this.currentEvent as ICurrentEventData,
        "is_accepted_invitation",
        data.is_accepted_invitation
      );
    }
    if (this.eventForProfile.length) {
      const findItem = this.eventForProfile.find((item) => item.id === data.event_id);
      Vue.delete(findItem as IEventDataProfile, "is_accepted_invitation");
      Vue.set(findItem as IEventDataProfile, "is_accepted_invitation", data.is_accepted_invitation);
    }
  }

  @Mutation
  changeStateInvitationInProfile(value: boolean | null) {
    if (this.eventForProfile.length) {
      Vue.delete(this.currentEvent as ICurrentEventData, "is_accepted_invitation");
      Vue.set(this.currentEvent as ICurrentEventData, "is_accepted_invitation", value);
    }
  }

  @Mutation
  setCreateSetting(isShow: boolean) {
    this.isShowCreateEvent = isShow;
  }

  @Mutation
  toggleCreateLoader(isShow: boolean) {
    this.isCreateLoader = isShow;
  }

  @Mutation
  toggleViewModal(isShow: boolean) {
    this.isShowViewEvent = isShow;
    if (!isShow) {
      this.justCurrent = true;
    }
  }

  @Mutation
  toggleFullScreenEvent(data: { event: any; isShow: boolean }) {
    this.isShowFullScreenEvent = data.isShow;
  }

  @Mutation
  async setEventParams(params: IGetEventParams) {
    this.eventsParams = params;
  }

  @Mutation
  clearCurrentEvent() {
    this.currentEvent = null;
  }

  @Mutation
  setCurrentLocation(location) {
    this.currentLocation = location;
  }

  @Mutation
  createNewLocation(location: any) {
    this.locations = this.locations.concat(location);
  }

  @Mutation
  editLocation(payload: { id: number; name: string }) {
    const current = this.locations.length
      ? this.locations.find((item: ILocations) => item.id === payload.id)
      : null;
    if (current) {
      const index = this.locations.findIndex((item: ILocations) => item.id === payload.id);
      this.currentLocation = payload;
      Vue.set(this.locations, index, this.currentLocation);
    }
  }

  @Mutation
  deleteCurrentLocation(id: number) {
    this.locations = this.locations.filter((item: ILocations) => item.id !== id);
  }

  @Mutation
  async setCurrentGroup(group: IGroup | null) {
    this.currentGroup = group;
  }

  @Mutation
  createNewGroup(group: any) {
    this.groups = this.groups.concat(group);
  }

  @Mutation
  editGroup(payload: {
    id: number;
    name: string;
    users_add: Array<number>;
    users_delete: Array<number>;
  }) {
    const current = this.groups.length
      ? this.groups.find((item: IGroup) => item.id === payload.id)
      : null;
    if (current) {
      const index = this.groups.findIndex((item: IGroup) => item.id === payload.id);
      const users_add = payload.users_add;
      let users = this.currentGroup && this.currentGroup.users?.concat(users_add);
      users = [...new Set(users)];
      const arrId = payload.users_delete;
      users = _.difference(users, arrId);
      this.currentGroup = { ...current, name: payload.name, users: users ? users : [] };
      Vue.set(this.groups, index, this.currentGroup);
    }
  }

  @Mutation
  deleteCurrentGroup(id: number) {
    this.groups = this.groups.filter((item: IGroup) => item.id !== id);
  }

  @Mutation
  setEvents(events) {
    this.events = events;
  }

  @Mutation
  setOldRepeat(oldRepeat) {
    this.oldEventRepeat = oldRepeat;
  }

  @Mutation
  setCurrentEvent(event) {
    this.currentEvent = event;
  }

  @Mutation
  setWorkingEvents(events: Array<any>) {
    this.workingEvents = events;
  }

  @Mutation
  setActivityEvents(events: Array<any>) {
    this.activityEvents = events;
  }

  @Mutation
  deleteEvent(event) {
    if (this.justCurrent) {
      if (this.activeTab === "Person") {
        this.events = this.events.filter((item: IEventData) => item.id !== event?.id);
      } else if (this.activeTab === "Work") {
        this.workingEvents = this.workingEvents.filter((item: IEventData) => item.id !== event?.id);
      } else {
        this.activityEvents = this.activityEvents.filter(
          (item: IEventData) => item.id !== event?.id
        );
      }
    } else {
      if (this.activeTab === "Person") {
        this.events = this.events.filter(
          (item: IEventData) =>
            !(
              item.repeat_id === event?.repeat_id &&
              (moment(item.started_at).isAfter(event?.started_at) || item.id === event?.id)
            )
        );
      } else if (this.activeTab === "Work") {
        this.workingEvents = this.workingEvents.filter(
          (item: IEventData) =>
            !(
              item.repeat_id === event?.repeat_id &&
              (moment(item.started_at).isAfter(event?.started_at) || item.id === event?.id)
            )
        );
      } else {
        this.activityEvents = this.activityEvents.filter(
          (item: IEventData) =>
            !(
              item.repeat_id === event?.repeat_id &&
              (moment(item.started_at).isAfter(event?.started_at) || item.id === event?.id)
            )
        );
      }
    }
  }

  @Action
  deleteCurrentEvent() {
    this.deleteEvent(this.currentEvent);
  }

  @Mutation
  toggleMainLoader(isLoader: boolean) {
    this.isMainLoader = isLoader;
  }

  @Mutation
  setIsLoadType(isLoad: boolean) {
    this.isLoad = isLoad;
  }

  @Mutation
  toggleEventList(setting: typeEventListSetting | null) {
    this.eventListSetting = setting;
  }

  @Mutation
  createPreCreationEvent(event: IEventData) {
    if (this.activeTab === "Person") {
      this.events.unshift(event);
      // this.events = setUniqueArray(this.events, "id");
    }
    // if (this.activeTab === "Work") {
    //   this.workingEvents.unshift(event);
    //   this.workingEvents = setUniqueArray(this.workingEvents, "id");
    // }
    // if (this.activeTab === "Events") {
    //   this.activityEvents.unshift(event);
    //   this.activityEvents = setUniqueArray(this.activityEvents, "id");
    // }
  }

  @Mutation
  editPreCreationEvent(data: { event: IEventData; oldId: any }) {
    if (this.activeTab === "Person") {
      const index = this.events.findIndex((item) => item.id === data.oldId);
      Vue.set(this.events, index, data.event);
    }
    // if (this.activeTab === "Work") {
    //   const index = this.workingEvents.findIndex((item) => item.id === data.oldId);
    //   Vue.set(this.workingEvents, index, data.event);
    // }
    // if (this.activeTab === "Events") {
    //   const index = this.activityEvents.findIndex((item) => item.id === data.oldId);
    //   Vue.set(this.activityEvents, index, data.event);
    // }
  }

  @Mutation
  setCreateData(event: ICreateData) {
    this.createData = event;
  }

  @Mutation
  deletePreCreationEvent() {
    if (this.activeTab === "Person") {
      this.events = this.events.filter((item) => item.id);
    }
    // if (this.activeTab === "Work") {
    //   this.workingEvents = this.workingEvents.filter((item) => item.id);
    // }
    // if (this.activeTab === "Events") {
    //   this.activityEvents = this.activityEvents.filter((item) => item.id);
    // }
  }

  @Mutation
  setEditEvent(id: number | undefined) {
    this.editEventId = id;
  }

  @Mutation
  setJustCurrent(justCurrent: boolean) {
    this.justCurrent = justCurrent;
  }

  @Mutation
  setEmployment(employment: IEmployment) {
    this.employment = employment;
  }

  @Mutation
  showEmployment(isShow: boolean) {
    this.isShowEmployment = isShow;
  }

  @Mutation
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  @Mutation
  toggleTooltip(data: {
    tooltip: Array<string>;
    isShow: boolean;
    x: number;
    y: number;
    id: number;
  }) {
    this.tooltip = data.tooltip;
    this.isShowTooltip = data.isShow;
    this.tooltipX = data.x;
    this.tooltipY = data.y;
    this.tooltipId = data.id;
  }

  @Mutation
  saveSelectedUsers(users: Array<any>) {
    this.selectedUsers = users;
  }

  @Mutation
  saveSelectedGroups(groups: Array<any>) {
    this.selectedGroups = groups;
  }

  @Mutation
  saveSelectedTypes(types: Array<any>) {
    this.selectedTypes = types;
  }

  @Mutation
  saveSelectedLocation(location: Array<any>) {
    this.selectedLocations = location;
  }

  @MutationAction
  async getEventTypeCategories() {
    const eventTypeCategories = await CalendarService.getEventTypeCategories();
    return { eventTypeCategories };
  }

  @MutationAction
  async getAllEventType() {
    const eventType = await CalendarService.getAllEventType();
    return { eventType };
  }

  @MutationAction
  async getAllLocation() {
    const locations = await CalendarService.getLocations();
    return { locations };
  }

  @MutationAction
  async getGroups() {
    const groups = await CalendarService.getMyGroups();
    return { groups };
  }

  @MutationAction
  async getCurrentGroup(params: { id: number }) {
    const currentGroup = await CalendarService.getGroup(params);
    return { currentGroup };
  }

  @Mutation
  getFilterUsers(users) {
    this.users = users;
  }

  @MutationAction
  async getEventForProfile(params: IGetEventForProfileParams) {
    const eventForProfile = await CalendarService.getEventForProfile(params);
    return { eventForProfile };
  }

  @Action
  toggleCreateModal(isShow: boolean) {
    this.setCreateSetting(isShow);
    if (!this.isShowCreateEvent) {
      this.deletePreCreationEvent();
    }
  }

  @Action
  async changeInvitation(data: IChangeInvitationParams) {
    this.changeStateInvitation(data);
    await CalendarService.changeInvitation(data);
  }

  @Action
  async createLocationAction(params: { name: string }) {
    let newLocation = await CalendarService.createLocation(params);
    newLocation = {
      ...newLocation,
      ...params
    };
    this.createNewLocation(newLocation);
    this.toggleShowLocationModal({ show: false, mode: "show" });
  }

  @Action
  async editLocationAction(params: { id: number; name: string }) {
    const edit = await CalendarService.updateLocation(params);
    if (edit) {
      this.editLocation(params);
      this.toggleShowLocationModal({ show: true, mode: "show" });
    }
  }

  @Action
  async deleteLocationAction(params: { id: number }) {
    await CalendarService.deleteLocation(params);
    this.toggleShowLocationModal({ show: false, mode: "show" });
    this.deleteCurrentLocation(params.id);
  }

  @Action
  async createGroupAction(params: { users: Array<number>; name: string; author_id: number }) {
    let newGroup = await CalendarService.createGroup(params);
    newGroup = {
      ...newGroup,
      ...params
    };
    this.createNewGroup(newGroup);
    this.toggleShowGroupModal({ show: false, mode: "show" });
  }

  @Action
  async editGroupAction(params: {
    id: number;
    name: string;
    users_add: Array<number>;
    users_delete: Array<number>;
  }) {
    const edit = await CalendarService.updateGroup(params);
    if (edit) {
      this.editGroup(params);
      this.toggleShowGroupModal({ show: true, mode: "show" });
    }
  }

  @Action
  async deleteGroupAction(params: { id: number }) {
    await CalendarService.deleteGroup(params);
    this.toggleShowGroupModal({ show: false, mode: "show" });
    this.deleteCurrentGroup(params.id);
  }

  @Action
  async createEvent(data: IEventData) {
    const event = data;
    if (data.is_for_all) {
      delete event.users;
    }
    const resp = await CalendarService.createEvent(event);
    if (resp) {
      this.editPreCreationEvent({ event: { ...data, ...resp }, oldId: undefined });
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: "Мероприятие успешно создано!"
      });
    }
    if (this.activeTab === "Person") {
      if (data.repeat) {
        await this.getEvents(this.eventsParams);
      }
    }
    if (this.activeTab === "Work") {
      await this.getWorkingEvents(this.eventsParams);
    }
    if (this.activeTab === "Events") {
      await this.getActivityEvents(this.eventsParams);
    }
  }

  @Action
  async updateEvent(data: IEventData) {
    const responsible = diffArray(
      this.currentEvent!.responsible_users.map((item) => item.user_id),
      data.responsible_users!
    );
    const responsible_add = responsible.added;
    const responsible_delete = responsible.deleted;
    const users = diffArray(
      this.currentEvent!.users.map((item) => item.user_id),
      data.users!
    );
    const users_add = users.added;
    const users_delete = users.deleted;
    const newData = data;
    delete newData.responsible_users;
    delete newData.users;
    const oldRepeat = this.oldEventRepeat;
    const isEqualRepeat = _.isEqual(data.repeat, oldRepeat);
    if (isEqualRepeat && !this.justCurrent) {
      delete newData.repeat;
    }
    if (isEqualRepeat && this.justCurrent) {
      newData.repeat = null;
    }
    if (!isEqualRepeat && this.justCurrent) {
      newData.repeat = data.repeat;
    }
    const resp = await CalendarService.updateEvent({
      ...newData,
      timezone: this.timezone,
      just_current: this.justCurrent,
      users_add,
      users_delete,
      responsible_add,
      responsible_delete
    });
    if (resp) {
      this.editPreCreationEvent({ event: { ...data, ...resp }, oldId: data.id });
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: "Мероприятие успешно отредактировано!"
      });
    }
  }

  @Action
  async getEvents(params: IGetEventParams | null) {
    let events = [];
    if (params) {
      if (params.users?.length) {
        events = await CalendarService.getEvents(params);
      }
    }
    if (events) {
      this.setEvents(events);
    }
  }

  @Action
  eventRequest(params: IGetOneEventParams) {
    return CalendarService.getEvent(params);
  }

  @Action
  async getEvent(params: IGetOneEventParams) {
    // @TODO AOP-4604
    const currentEvent = await this.eventRequest(params).catch((error) => {
      return error.response.data.code;
    });
    if (
      currentEvent === 403 ||
      currentEvent === 404 ||
      currentEvent === 422 ||
      currentEvent === 400
    ) {
      return currentEvent;
    } else {
      this.setCurrentEvent(currentEvent);
      this.setOldRepeat(currentEvent.repeat);
    }
  }

  @Action
  async getWorkingEvents(params: IGetEventParams | null) {
    const workingEvents = await CalendarService.getWorkingEvents(params);
    if (workingEvents) {
      this.setWorkingEvents(workingEvents);
    }
  }

  @Action
  async getActivityEvents(params: IGetEventParams | null) {
    const events = await CalendarService.getActivityEvents(params);
    if (events) {
      this.setActivityEvents(events);
    }
  }

  @Action
  async deleteEventAction() {
    await CalendarService.deleteEvent({
      id: this.currentEvent!.id,
      just_current: this.justCurrent
    });
    this.deleteCurrentEvent();
  }

  @Action
  async checkEmployment(event: IEventData) {
    const params = {
      started_at: event.started_at,
      ended_at: event.ended_at,
      timezone: event.timezone,
      location_id: event.location_id,
      users: [...event.responsible_users!, ...event.users!]
    };
    const employment = await CalendarService.checkEmployment(params);
    this.setEmployment(employment);
    return employment.overlapping_by_location.length || employment.overlapping_by_users.length;
  }

  @Action
  async changeEvent(data: ICreateData) {
    try {
      if (isValidEvent(data)) {
        const event = dataToEventAdapter(data);
        if (event.id) {
          if (this.oldEventRepeat) {
            ModalModule.setConfirmSetting({
              title: "Изменить повторяющееся мероприятие?",
              text: "Вы можете изменить как одно, так и несколько мероприятий.",
              component: () =>
                import(
                  "@/aopV2/modules/Calendar/components/Modal/ConfirmationRepeatModal/ConfirmationRepeatModal"
                ),
              cancelButton: "Отменить",
              handleCancel: () => {
                ModalModule.toggleConfirmation(false);
                this.toggleCreateLoader(false);
              },
              confirmButton: "Изменить",
              handleConfirm: async () => {
                ModalModule.toggleLoader(true);
                await this.updateEvent(event);
                ModalModule.toggleLoader(false);
                ModalModule.toggleConfirmation(false);
                await router.replace({ name: "Calendar" });
                this.toggleCreateLoader(false);
              }
            });
            ModalModule.toggleConfirmation(true);
          } else {
            await this.updateEvent(event);
            ModalModule.toggleConfirmation(false);
            await router.replace({ name: "Calendar" });
          }
        } else {
          await this.createEvent(event);
          this.toggleCreateModal(false);
          const oldPath = store.state.from;
          if (oldPath.name && oldPath.name !== "Login") {
            router.back();
            if (!window.history.state) {
              router.push({ name: "Calendar" }).finally();
            }
          } else {
            router.push({ name: "Calendar" }).finally();
          }
        }
      }
    } catch (e) {
      this.toggleCreateLoader(false);
    }
  }

  @Action
  async checkEventUpdate(data: {
    type: string;
    events?: Array<number>;
    eventId?: number;
    userId?: number;
    hasRepeat?: boolean;
    startedAt?: string;
  }) {
    const events: Array<IEventData> =
      this.activeTab === "Person"
        ? this.events
        : this.activeTab === "Work"
        ? this.workingEvents
        : this.activityEvents;
    switch (data.type) {
      case "events.delete": {
        this.setJustCurrent(true);
        if (data.events?.length) {
          for (let i = 0; i < data.events.length; i++) {
            const isUpdateEventList = isEventInArray(events, data.events[i]);
            if (isUpdateEventList) {
              this.deleteEvent({ id: data.events[i] });
            } else {
              break;
            }
          }
        }
        break;
      }
      case "events.create": {
        await this.socketEventCreateAndUpdate({
          eventId: data.eventId!,
          type: data.type,
          events,
          startedAt: data.startedAt!,
          hasRepeat: data.hasRepeat!
        });
        break;
      }
      case "events.update": {
        await this.socketEventCreateAndUpdate({
          eventId: data.eventId!,
          type: data.type,
          events,
          startedAt: data.startedAt!,
          hasRepeat: data.hasRepeat!
        });
        break;
      }
    }
  }

  @Action
  async socketEventCreateAndUpdate(data: {
    type: string;
    eventId: number;
    hasRepeat: boolean;
    startedAt: string;
    events: Array<IEventData>;
  }) {
    const date = new Date(data.startedAt! + " GMT");
    const dateFrom = new Date(this.dateFrom);
    const dateTo = new Date(this.dateTo);
    if (data.hasRepeat) {
      if (dateFrom.getTime() > date.getTime() || dateTo.getTime() > date.getTime()) {
        await this.getCalendarEvents();
      }
    } else {
      if (dateFrom.getTime() < date.getTime() || dateTo.getTime() > date.getTime()) {
        await this.getCalendarEvents();
      }
    }
    // } else {
    //   const event = await this.eventRequest({ timezone: this.timezone, id: data.eventId! });
    //   let index = data.events.findIndex((item) => item.id === event.id);
    //   index = index !== -1 ? index : data.events.length;
    //   Vue.set(data.events, index, event);
    //   if (this.activeTab === "Person") {
    //     this.setEvents(data.events);
    //   } else if (this.activeTab === "Work") {
    //     this.setWorkingEvents(data.events);
    //   } else {
    //     this.setActivityEvents(data.events);
    //   }
    // }
  }

  @Action
  async getCalendarEvents() {
    if (this.activeTab === "Person") {
      await this.getEvents(this.eventsParams);
    } else if (this.activeTab === "Work") {
      const checkLocations = !!this.eventsParams?.locations?.length;
      if (checkLocations) {
        await this.getWorkingEvents(this.eventsParams);
      } else {
        await this.setWorkingEvents([]);
      }
    } else if (this.activeTab === "Events") {
      await this.getActivityEvents(this.eventsParams);
    }
  }

  get preCreateEvent() {
    return this.editEventId
      ? this.currentEvent
      : this.events.find((item) => item.id === this.editEventId);
  }

  get isPersonCalendar() {
    return this.activeTab === "Person";
  }

  get isWorkingCalendar() {
    return this.activeTab === "Work";
  }

  get isEventsCalendar() {
    return this.activeTab === "Events";
  }
}

export default getModule(CalendarModule2);

const isEventInArray = (array: Array<IEventData>, eventId: number) =>
  array.some((event) => eventId === event.id);
