<template>
  <ul class="news-footer">
    <li class="news-footer__info">
      <span class="news-footer__author-title">Автор: </span>
      <router-link
        :to="{ name: 'UserInfo', params: { user_id: news.author.id } }"
        :target="isGlobalMobileMode ? '_self' : '_blank'"
        class="news-footer__author"
      >
        <span>{{ news.author.full_name }}</span>
      </router-link>
    </li>
    <NewsFooterItem
      :count="countComments"
      :icon="'comment'"
      :action="`comment${news.id}`"
      v-if="newsIncludeComments"
      @footer_item_comment="$emit('footer_item_comment')"
    />
    <NewsFooterItem
      :count="countLike"
      :news="news"
      :type="'news'"
      :icon="'heart'"
      :action="`heart${news.id}`"
      :currentUserLike="news.current_user_like"
      @footer_item_heart="$emit('footer_item_heart')"
    />
  </ul>
</template>

<script>
import NewsFooterItem from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsFooterItem";
import { mapState } from "vuex";

export default {
  name: "NewsFooter",
  mixins: [],
  props: {
    news: {
      type: Object,
      default: () => {}
    },
    countViews: {
      type: [Number, String],
      default: 0
    },
    showComment: {
      type: Boolean,
      default: false
    },
    countLike: {
      type: [Number, String],
      default: 0
    },
    countComments: {
      type: [Number, String],
      default: 0
    }
  },
  components: { NewsFooterItem },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  computed: {
    ...mapState("NewsModule", ["channelAllList"]),
    newsIncludeComments() {
      return this.news.channel.is_include_comments;
    },
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.news-footer {
  font-family: "SF", sans-serif;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  /deep/ {
    .news-footer__item {
    }
  }

  &__info {
    display: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    width: 100%;
    padding: 10px 0;
    color: #a7a9c0;

    @media (max-width: $xl) {
      display: inline-block;
    }
    @media (max-width: $md) {
      display: none;
    }
    @media screen and (max-width: $xs) {
      display: inline-block;
    }
  }

  &__author {
    color: inherit;
  }
}
</style>
