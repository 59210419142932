/**
 * It checks if the value of the prop matches the intended value.
 * @param {any} propValue - any - the value of the property we want to check
 * @param intendedValue - object with expected property values.
 */
export const validationProps = (propValue: any, intendedValue) => {
  const resultValidation = intendedValue[propValue.toLowerCase()];
  const lengthIntendedValue = Object.keys(intendedValue).length > 1;
  if (!resultValidation) {
    console.error(
      `${propValue} не соответствует ожидаем${lengthIntendedValue ? "ым" : "ому"} значени${
        lengthIntendedValue ? "ям" : "ю"
      }: ${Object.keys(intendedValue)}`
    );
  }
  return resultValidation;
};
