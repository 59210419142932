<template>
  <ul class="vacation-list">
    <Vacation :vacation="vacation" v-for="vacation in vacationOrders" />
  </ul>
</template>

<script>
import Vacation from "@/components/GraphVacation/TimeLine/Vacation";
export default {
  name: "VacationList",
  mixins: [],
  props: {
    vacationOrders: {
      type: Array,
      default: () => []
    }
  },
  components: { Vacation },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.vacation-list {
  all: unset;
  display: flex;
  list-style: none;
  width: 100%;
  height: 100%;
}
</style>
