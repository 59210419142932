




import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import { secToTime, timeToSec } from "@/modules/TimeTracker/helpers/timeFormat";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";

@Component
export default class DurationCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;
  @Prop({
    default: () => {},
    type: Object
  })
  data!: any;

  get duration() {
    const start = timeToSec(this.row.time_start);
    const stop = timeToSec(this.row.time_stop);
    return secToTime(stop - start);
  }
}
