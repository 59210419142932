


























import { Component, Prop, Vue } from "vue-property-decorator";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";

@Component({
  components: { ButtonIcon, TextLabel }
})
export default class InputNumber extends Vue {
  @Prop({
    type: String,
    required: true
  })
  id!: string;
  @Prop({
    type: String,
    default: ""
  })
  label!: string;
  @Prop({
    type: String,
    default: "1"
  })
  placeholder!: string;
  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;
  @Prop({
    type: Number,
    default: 1
  })
  propValue!: number;
  @Prop({
    type: Number,
    default: 1
  })
  min!: number;
  @Prop({
    type: Number,
    default: 100
  })
  max!: number;
  value: number = 1;

  mounted() {
    this.value = this.propValue;
  }

  increment() {
    this.value = +this.value + 1;
    this.$emit("change", this.value);
  }

  decrement() {
    if (this.value > 1) {
      this.value = +this.value - 1;
    } else {
      this.value = 1;
    }
    this.$emit("change", this.value);
  }
}
