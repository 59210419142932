import actions from "@/modules/TaskManager/store/actions/actions";
import mutations from "@/modules/TaskManager/store/mutations/mutations";
import getters from "@/modules/TaskManager/store/getters/getters";
import state from "@/modules/TaskManager/store/state/state";
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
};
