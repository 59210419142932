<template>
  <div class="confirmation-modal">
    <div class="confirmation-modal__wrap">
      <h3 class="confirmation-modal__title">{{ notificationData.title }}</h3>
      <p class="confirmation-modal__text">{{ notificationData.text }}</p>
      <div class="confirmation-modal__buttons">
        <ModalButton
          :type="'cancel'"
          :title="'Отменить'"
          v-if="notificationData.type !== 'kick'"
          @handleButton="handleCloseButton"
        />
        <ModalButton
          :title="notificationData.button"
          @handleButton="handleAcceptButton"
          :class="{ 'confirmation-modal__button': notificationData.type === 'kick' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalButton from "@/components/Widgets/Button/ModalButton";

export default {
  name: "ConfirmationModal",
  mixins: [],
  props: {},
  components: { ModalButton },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", [
      "closeConfirmModal",
      "deleteUserInChannel",
      "updateUserInChannel"
    ]),
    handleCloseButton() {
      this.closeConfirmModal();
    },
    handleAcceptButton() {
      switch (this.notificationData.type) {
        case "kick": {
          this.closeConfirmModal();
          break;
        }
        case "delete-member": {
          this.deleteUserInChannel(this.notificationAction);
          this.closeConfirmModal();
          break;
        }
        case "update-member": {
          this.updateUserInChannel(this.notificationAction);
          this.closeConfirmModal();
          break;
        }
        default: {
          this.closeConfirmModal();
          break;
        }
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", ["notificationData", "notificationAction"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.confirmation-modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrap {
    padding: 24px;
    border-radius: 24px;
    background-color: #fff;
    position: relative;
    width: 301px;
    display: flex;
    flex-flow: column;
  }
  &__title {
    padding: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: $text-default;
    margin: 0 0 16px;
  }
  &__text {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 16px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  &__buttons {
    border-top: 1px solid $border-subdued;
    padding-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button {
    margin-left: auto;
  }
}
</style>
