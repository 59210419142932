<template>
  <div class="create-task" @paste="onPaste">
    <div class="create-task__project field" v-if="!isSubtask">
      <Label :label="'Проект'" />
      <Select
        :key="`select_0`"
        v-model="parentProject"
        :items="projectList"
        :placeholder="'Выберите проект'"
        :multiple="false"
        :showAvatar="false"
        :disabled="currentProjectForCreation"
        :removeCross="!currentProjectForCreation"
        :isLoading="projectPaginationLoader"
        @input="handleChangeParentProject"
        @reachLastItem="getMoreProjects"
      />
    </div>
    <div class="create-task__stage field" v-if="!isSubtask">
      <Label :label="'Этап'" />
      <Select
        :key="`select_1`"
        :placeholder="'Выберите этап'"
        :items="setStageList"
        v-model="parentStage"
        :multiple="false"
        :showAvatar="false"
        :disabled="!setStageList.length || currentStageForCreation"
        :removeCross="!currentStageForCreation"
        :isLoading="stagePaginationLoader"
        @input="handleChangeStage"
        @reachLastItem="getMoreStages"
      />
    </div>
    <div class="create-task__parent-task field" v-if="isSubtask">
      <Label :label="'Родительская задача*'" />
      <Select
        :key="`select_2`"
        v-model="parentTask"
        :items="taskList"
        :placeholder="'Выбрать'"
        :multiple="false"
        :showAvatar="false"
        :disabled="currentTaskForCreation"
        :removeCross="!currentTaskForCreation"
        @input="handleChangeParentTask"
      />
    </div>
    <div class="create-task__title field">
      <Label :label="'Название подзадачи*'" v-if="isSubtask" />
      <Label :label="'Название задачи*'" v-else />
      <Input
        v-model="name"
        :placeholder="placeholderNameTask"
        :maxlength="255"
        :minlength="1"
        @input="handleChangeName"
      />
    </div>
    <div class="create-task__description field">
      <Label :label="'Описание'" />
      <Textarea
        v-model="description"
        :placeholder="'Добавьте описание'"
        :maxlength="32000"
        @input="handleChangeDescription"
      />
    </div>
    <div class="create-task__description field">
      <Label :label="'Чеклист'" />
      <CheckListCreate
        v-model="list"
        :value="list"
        @editItem="editItem"
        @input="handleChangeCheckList"
      />
    </div>
    <div class="create-task__info field-row" v-if="!isSubtask">
      <div class="field-row__item">
        <Label :label="'Приоритет'" />
        <Select
          :key="`select_3`"
          :items="priorityList"
          v-model="priority"
          :showSearchPanel="false"
          :multiple="false"
          :showAvatar="false"
          :placeholder="'Выбрать приоритет'"
          @input="handleChangePriority"
        />
      </div>
      <div class="field-row__item">
        <Label :label="'Вид задачи'" />
        <Select
          :key="`select_4`"
          :items="typeList"
          v-model="type"
          :showSearchPanel="false"
          :multiple="false"
          :showAvatar="false"
          :placeholder="'Выбрать вид'"
          @input="handleChangeType"
        />
      </div>
      <div class="field-row__item">
        <Label :label="'Статус'" />
        <Select
          :key="`select_5`"
          :items="setStatusesList"
          v-model="status"
          :showSearchPanel="false"
          :multiple="false"
          :showAvatar="false"
          :placeholder="'Выбрать статус'"
          :disabled="!setStatusesList.length"
          @input="handleChangeStatus"
        />
      </div>
    </div>
    <div class="create-task__deadline field-group">
      <Label :label="'Сроки выполнения'" />
      <div class="field-group__item field-row">
        <div class="field-row__item">
          <Label :label="'Временной период'" />
          <DateRangePicker
            v-model="range"
            :range="range"
            class="field-group__deadline"
            :isRequired="false"
            :columns="1"
            :showCross="true"
            @input="handleChangeDate"
          />
        </div>
        <div class="field-row__item">
          <Label :label="'Необходимое время'" :tooltip="setTooltip" />
          <TimeInput v-model="time" :placeholder="'Введите время'" @input="handleChangeTime" />
        </div>
      </div>
      <div class="field-group__item">
        <Label :label="'Исполнитель'" />
        <Select
          :key="`select_6`"
          v-model="user"
          :items="isUsersList"
          :multiple="false"
          :showButtonPanel="false"
          :placeholder="'Выберите сотрудника'"
          @input="handleChangeUser"
        />
      </div>
    </div>
    <div class="files">
      <div class="files__background">
        <file-upload
          v-if="filesList.length < 10"
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          post-action="/upload/post"
          class="file-uploader"
          input-id="createTask"
          :multiple="true"
          :drop="true"
          v-model="files"
          :drop-directory="true"
          @input="handleUploadFile"
          ref="upload"
          id="upload"
        >
          <div class="uploader-icon" v-show="!($refs.upload && $refs.upload.dropActive)">
            <p>Перетащите файл сюда или</p>
            <i class="icon-paper-clip-secondary"></i>
          </div>
        </file-upload>
        <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
        <FileList :fileList="filesList" :isEdit="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import Select from "@/modules/UiKit/components/Select/Select";
import TimeInput from "@/modules/UiKit/components/TimeInput/TimeInput";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";
import moment from "moment";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import { mapGetters } from "vuex";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import CheckListCreate from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckListCreate";
import FileList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileList";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import FileUpload from "vue-upload-component";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";

export default {
  name: "CreateTask",
  mixins: [uploadFilesMixin],
  model: {
    event: "fieldChanged"
  },
  props: {
    taskToCreate: {
      type: Object,
      default: () => {}
    },
    isSubtask: {
      type: Boolean,
      default: false
    },
    projectList: {
      type: Array,
      default: () => []
    },
    taskList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DropFileUpload,
    CheckListCreate,
    DateRangePicker,
    TimeInput,
    Input,
    Label,
    Textarea,
    Select,
    FileUpload,
    FileList
  },
  data() {
    return {
      name: "",
      description: [],
      priority: [],
      priorityList: [],
      type: [],
      typeList: [],
      status: [],
      parentProject: [],
      parentTask: [],
      stageList: [],
      parentStage: [],
      range: {
        start: null,
        end: null
      },
      time: null,
      user: [],
      list: [],
      text: "",
      showCheckListForm: true,
      file: [],
      files: []
    };
  },
  mounted() {
    this.initial();
  },
  methods: {
    handleUploadFile(val) {
      val.forEach((item) => {
        if (item.type === "") {
          item.type = item.name.split(".").slice(-1).join("").toLowerCase();
        }
      });
      TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("add");
      taskModule.openModalFileUpload(val);
      this.files = [];
    },
    onPaste(val) {
      if (val.clipboardData.files.length !== 0 && this.totalNumberFiles < 10) {
        TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("add");

        Array.from(val.clipboardData.files).forEach((file) => {
          const fileItem = {
            id: file.name + Date.now(),
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            fileObject: true,
            postAction: "/upload/post"
          };
          this.files.push(fileItem);
        });

        taskModule.openModalFileUpload(this.files);
        this.files = [];
      }
    },
    initial() {
      this.priorityList = TaskModule.prioritiesList;
      this.typeList = TaskModule.typesList;
      this.$nextTick(() => {
        if (this.currentProjectForCreation) {
          this.parentProject = [this.currentProjectForCreation];
          this.handleChangeParentProject(this.parentProject);
        }
        if (this.currentStageForCreation) {
          this.parentStage = [this.currentStageForCreation];
          this.handleChangeStage(this.parentStage);
        }
        if (this.currentTaskForCreation) {
          this.parentTask = [this.currentTaskForCreation];
          this.handleChangeParentTask(this.parentTask);
        }
      });
    },
    editItem(val) {
      this.list[val.idx] = val.val;
    },
    handleChangeCheckList(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.check_points = val.length ? val : undefined;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeParentProject(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.project_id = val[0]?.id;
      changedTask.alias = val[0]?.alias;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeParentTask(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.parent_id = val[0]?.id;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeStage(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.stage_id = val[0]?.id === null || val[0]?.id === 0 ? undefined : val[0]?.id;

      this.$emit("fieldChanged", changedTask);
    },
    handleChangeName(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.name = val.trim();
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeDescription(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.description = val.trim();
      this.$emit("fieldChanged", changedTask);
    },
    handleChangePriority(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.priority_id = val[0]?.id;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeType(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      this.type = val;
      changedTask.type_id = val[0]?.id;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeStatus(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.status_id = val[0]?.id;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeDate(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      if (val) {
        changedTask.date_from = this.range.start
          ? moment(this.range.start).format("YYYY-MM-DD")
          : null;
        changedTask.date_to = this.range.end ? moment(this.range.end).format("YYYY-MM-DD") : null;
        this.$emit("fieldChanged", changedTask);
      } else {
        delete this.taskToCreate.date_from;
        delete this.taskToCreate.date_to;
      }
    },
    handleChangeTime() {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.time_plan = this.time ? this.time : null;
      this.$emit("fieldChanged", changedTask);
    },
    handleChangeUser(val) {
      const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
      changedTask.executor_id = val[0]?.id;
      this.$emit("fieldChanged", changedTask);
    },
    setProjectParams() {
      if (!this.currentStageForCreation) {
        this.parentStage = [];
      }
      this.status = [];
      if (this.parentProject.length) {
        StageModule.getStageByProjectId({ project_id: this.parentProject[0]?.id });
        this.parentStage = isObjectEmptyChecker(this.currentStageForCreation)
          ? [
              {
                id: null,
                name: "Вне этапа"
              }
            ]
          : [this.currentStageForCreation];
        ProjectModule.getOwnerListActions(this.parentProject[0]?.id);
        ProjectModule.getMembersListActions(this.parentProject[0]?.id);
        TaskModule.getStatusesListAction({
          project_id: this.parentProject[0]?.id
        }).then(() => {
          if (this.taskToCreate) {
            const changedTask = JSON.parse(JSON.stringify(this.taskToCreate));
            this.type = [this.typeList.find((item) => item.id === 1)];
            this.priority = this.priorityList.find((item) => item.name === "Средний")
              ? [this.priorityList.find((item) => item.name === "Средний")]
              : [];
            this.status = this.setStatusesList.length
              ? [this.setStatusesList.find((item) => item.is_start === true)]
              : [];
            changedTask.project_id = this.parentProject[0]?.id;
            changedTask.alias = this.parentProject[0]?.alias;
            changedTask.priority_id = this.priority[0]?.id;
            changedTask.type_id = this.type[0]?.id;
            changedTask.status_id = this.status[0]?.id;
            this.$emit("fieldChanged", changedTask);
          }
        });
      }
    },
    getMoreProjects() {
      ProjectModule.getProjectByPagination();
    },
    getMoreStages() {
      StageModule.getStageByPagination({
        project_id: this.taskToCreate.project_id
      });
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    totalNumberFiles() {
      const countFilesUploadCurrentTask = taskModule.filesUploadCurrentTask
        ? taskModule.filesUploadCurrentTask.length
        : 0;
      const countFilesUploadModal = taskModule.filesUploadModal
        ? taskModule.filesUploadModal.length
        : 0;
      return countFilesUploadCurrentTask + countFilesUploadModal;
    },
    filesList() {
      return TaskModule.filesUploadCurrentTask ? TaskModule.filesUploadCurrentTask : [];
    },
    currentProjectForCreation() {
      return TaskManagerModuleV2.currentProjectForCreation;
    },
    currentTaskForCreation() {
      return TaskManagerModuleV2.currentTaskForCreation;
    },
    currentStageForCreation() {
      return TaskManagerModuleV2.currentStageForCreation;
    },
    setStatusesList() {
      return KanbanModule.statusList.length
        ? KanbanModule.statusList
        : TaskModule.statusesList.length
        ? TaskModule.statusesList
        : [];
    },
    setStageList() {
      return this.parentProject.length &&
        StageModule.stageList &&
        StageModule.stageList[`project_${this.parentProject[0].id}`]
        ? StageModule.stageList[`project_${this.parentProject[0].id}`].list
        : [];
    },
    projectPaginationLoader() {
      return ProjectModule.projectPaginationLoader;
    },
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    },
    placeholderNameTask() {
      return this.isSubtask ? "Введите название подзадачи" : "Введите название задачи";
    },
    ownerList() {
      return ProjectModule.ownerList;
    },
    isOwner() {
      return this.ownerList.some((item) => item.user_id === this.getUserId);
    },
    membersList() {
      return ProjectModule.membersList;
    },
    isUsersList() {
      if (
        this.parentProject.length ||
        (this.currentTaskForCreation && this.currentTaskForCreation.project_id)
      ) {
        return this.ownerList
          .map((item) => item.user_info)
          .concat(this.membersList.map((item) => item.user_info))
          .sort((x, y) => {
            if (x.full_name < y.full_name) {
              return -1;
            }
            if (x.full_name > y.full_name) {
              return 1;
            }
            return 0;
          });
      } else {
        return this.userList.users.filter((item) => item.is_active);
      }
    },
    isMember() {
      return this.membersList.some((item) => item.user_id === this.getUserId);
    },
    setTooltip() {
      return this.parentTask.length
        ? "Время, необходимое для выполнения подзадачи"
        : "Время, необходимое для выполнения задачи";
    }
  },
  watch: {
    parentProject() {
      this.setProjectParams();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";
.files {
  width: 100%;
  position: relative;
  height: auto;

  &__background {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;

    .file-list {
      margin-top: 15px;
    }
    .file-uploader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      z-index: 100;
      color: $text-subdued;
      white-space: nowrap;

      @media (max-height: $sm) {
        height: 50px;
      }

      .uploader-icon {
        width: 100%;
        height: 70px;
        display: flex;
        border: 1px dashed $border-disabled;
        justify-content: center;
        align-items: center;

        i {
          margin: 0 0 0 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          background: #fafbfc;
        }
      }

      &:hover {
        color: $text-highlight;
      }

      &:active {
        color: $text-highlight;
      }

      /deep/ {
        img {
          margin-top: 14px;
        }
        .attach-file {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          transition: color 0.3s ease;
        }
        label {
          cursor: pointer;
        }
      }
    }
    /deep/ {
      .drop-active {
        position: relative;
        height: 70px;
        padding: 5px 0;

        &__wrap {
          flex-flow: row;
        }

        .icon-cloud-upload {
          font-size: 50px;
        }
        &__title {
          display: none;
        }
        &__text {
          display: none;
        }
      }
    }
  }

  &__list {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    max-height: 150px;

    @media (max-height: $xxxl) {
      max-height: 120px;
    }

    @media (max-height: $sm) {
      max-height: 80px;
    }

    @media (max-height: 320px) {
      max-height: 40px;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: max-content;
      overflow-y: auto;
    }

    &_doc {
      @media (max-height: $xxxl) {
        max-height: 80px;
      }
    }
  }

  &__item {
    position: relative;
    width: 20%;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px;

    @media screen and (max-width: 850px) and (orientation: landscape) {
      width: 30%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__img {
    width: 100px;
    height: auto;
    display: block;

    @media (max-height: $sm), (max-width: $sm) {
      width: 200px;
    }
  }

  .icon-cross {
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &__info {
    padding-left: 5px;
    padding-right: 8px;
    height: 24px;
    width: 134px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    margin-bottom: 5px;
    background-color: $text-disabled;
    border-radius: 24px;
    cursor: pointer;

    i {
      margin-right: 4px;
      margin-left: 4px;
      color: $text-light;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-light;
  }
  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-left: auto;

    .icon-cross {
      margin-right: 0;
    }
  }
}
</style>
