import { debug } from "@/main";
import { notEmpty } from "@/helpers/helperFunction";

export default {
  methods: {
    /**
     * Обработка ошибок от запросов
     * @param error
     */
    errorAlert(error) {
      let msg = `undefined error from backend`;
      let code = error.name || "500";
      if (error.response) {
        msg = `${error.response.data.data.error.message}`;
        code = `${error.response.data.data.error.code}`;
      } else {
        msg = `${error.message || error}`;
      }
      if (code === "401") {
        this.$store.dispatch("logout");
        if (this.$route.path !== "/login") {
          this.$router.push("/login");
        }
      }
      // eslint-disable-next-line no-console
      if (debug) console.error(error);
      if (notEmpty(error.response.data.data.error.messages)) {
        let msgErrors = error.response.data.data.error.messages;
        for (let i in msgErrors) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `${msgErrors[i]}`
          });
        }
      } else {
        return this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `${msg}`
        });
      }
    },
    successAlert(success) {
      let msg = success;
      if (success.response) {
        msg = `${success.response.data.data.error.message}`;
      } else {
        msg = `${success.message || msg}`;
      }
      return this.$root.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: `${msg}`
      });
    },
    messageAnyType(message, type) {
      return this.$root.$emit("showNotification", {
        type: type,
        timeout: 5000,
        label: `${message}`
      });
    }
  }
};
