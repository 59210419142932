<template>
  <section class="project" :class="{ 'description-tab': currentTab === 'description' }">
    <ConfirmModal
      v-if="confirmDeleteProject"
      :title="'Удалить'"
      :description="'Проект будет удален со всеми этапами, задачами и вложенными проектами. Продолжить?'"
      @cancel="cancelDeleteProject"
      @accept="acceptDeleteProject"
    />
    <ConfirmModal
      v-if="confirmCompleteProject"
      :title="'Выполнить'"
      :description="'Проект может содержать невыполненные этапы. Данные этапы будут закрыты. Продолжить?'"
      @cancel="cancelComplete"
      @accept="acceptComplete"
    />
    <div class="project__header">
      <div class="project__tabs">
        <button class="project__menu" type="button" @click="openSidebar">
          <i class="icon-menu"></i>
        </button>
        <button class="project__back" type="button" @click="$router.push({ name: 'MyProjects' })">
          <i class="icon-arrow-back"></i>
        </button>
        <TabsContent :tabs="tabsList" v-model="currentTab" @input="changeTab" />
      </div>
      <div class="project__stages-header" v-if="currentTab === 'description'">
        <h2>Этапы проекта</h2>
        <Button
          type="simple"
          icon="plus"
          title="Добавить"
          @handleButton="addStage"
          v-if="!isReadonly"
        />
      </div>
      <div class="project__users-header" v-if="currentTab === 'description'">
        <h2>Участники</h2>
      </div>
      <div class="project__action-list">
        <ActionsList :actionList="actionList" @handleAction="handleAction" />
      </div>
    </div>
    <div class="project__container">
      <div class="project__tabs_adaptive">
        <TabsContent :tabs="tabsList" v-model="currentTab" @input="changeTab" />
      </div>
      <Spinner v-if="projectLoader" class="project__loader" />
      <div class="project__wrap" v-else>
        <div class="project__body">
          <div class="project__description" v-if="currentTab === 'description'">
            <ProjectDescriptionBody
              :isOnCurtain="false"
              :project="currentProject"
              :isReadonly="isReadonly"
            />
          </div>
          <div class="project__stages-and-tasks" v-if="currentTab === 'stages'">
            <StagesAndTasks :stages="stages" :projectId="currentProject.id" />
          </div>
          <div class="project__kanban-table" v-if="currentTab === 'kanban'">
            <KanbanMain :stages="stages" :columns="currentBoardColumns" />
          </div>
        </div>
        <div class="project__stages" v-if="currentTab === 'description'">
          <div class="project__stages-header project__stages-header_adaptive">
            <h2>Этапы проекта</h2>
            <Button
              type="white"
              icon="plus"
              title="Добавить этап"
              @handleButton="addStage"
              v-if="!isReadonly"
            />
          </div>
          <div class="project__content">
            <ProjectStagesBody
              :isOnCurtain="false"
              :stages="stages"
              :projectId="currentProject.id"
            />
          </div>
        </div>
        <div class="project__users" v-if="currentTab === 'description'">
          <h2 class="project__users-header project__users-header_adaptive">Участники</h2>
          <div class="project__content project__users-body">
            <UserGroup
              class="project__owners"
              :users="ownersList"
              :allUsers="allUsers"
              :isEditable="!isReadonly"
              :row="false"
              @updated="handleChangeOwners"
              label="ВЛАДЕЛЬЦЫ"
            />
            <UserGroup
              class="project__members"
              :users="membersList"
              :allUsers="allUsers"
              :isEditable="!isReadonly"
              :row="false"
              @updated="handleChangeMembers"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import ProjectDescriptionBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/BodyComponents/ProjectDescriptionBody";
import Button from "@/modules/UiKit/components/buttons/Button";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import ProjectStagesBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/BodyComponents/ProjectStagesBody";
import UserGroup from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/UserGroup";
import StagesAndTasks from "@/modules/TaskManager/components/MyProjects/ProjectPage/StagesAndTasks";
import { mapGetters, mapMutations } from "vuex";
import KanbanMain from "@/modules/TaskManager/components/Kanban/KanbanMain";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import copyToClipboard from "@/helpers/CopyToClipboard";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import NotificationObserver from "@/helpers/UserNotification";
import KanbanService from "@/modules/TaskManager/Model/Kanban/KanbanService";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "Project",
  mixins: [],
  props: {},
  components: {
    Spinner,
    KanbanMain,
    StagesAndTasks,
    UserGroup,
    ProjectStagesBody,
    ActionsList,
    Button,
    ProjectDescriptionBody,
    TabsContent,
    ConfirmModal
  },
  data() {
    return {
      currentTab: "description",
      confirmDeleteProject: false,
      confirmCompleteProject: false,
      tabsList: [
        {
          label: "Описание",
          value: "description"
        },
        {
          label: "Этапы и задачи",
          value: "stages"
        },
        {
          label: "Kanban доска",
          value: "kanban"
        }
      ]
    };
  },
  mounted() {
    this.initProject();
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    initProject() {
      if (this.$route.query.stage) {
        this.currentTab = "stages";
        if (this.$route.query.stage !== "default")
          StageModule.getCurrentStage(this.$route.query.stage);
      }
      if (this.projectId !== this.currentProject?.id) {
        ProjectModule.getProjectFullAction(this.$route.params.id);
      }
      if (!this.stages || this.stages.list.length === 0) {
        StageModule.getStageByProjectId({ project_id: this.projectId });
      }
      KanbanModule.getBoardAction({ project_id: this.projectId, fromProjectPage: true });
      KanbanModule.getStatusListAction(this.projectId);
    },
    addStage() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 2,
        currentProjectForCreation: this.currentProject
      });
    },
    changeTab() {
      if (this.$route.query.stage) {
        this.$router.replace({ name: "Project", params: { id: this.currentProject.id } });
      }
    },
    handleAction(item) {
      switch (item.actionType) {
        case "createTask":
          TaskManagerModuleV2.openCurtainConstructor({
            currentCreationTypeId: 3,
            currentProjectForCreation: this.currentProject
          });
          break;
        case "complete":
          this.confirmCompleteProject = true;
          break;
        case "reopen":
          this.reopenProject();
          break;
        case "business-setting":
          this.toFlowchart();
          break;
        case "kanban-setting":
          this.openKanbanSetting();
          break;
        case "copyLink":
          this.copyProjectLink();
          break;
        case "delete":
          this.confirmDeleteProject = true;
          break;
      }
    },
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    },
    cancelDeleteProject() {
      this.confirmDeleteProject = false;
    },
    acceptDeleteProject() {
      ProjectModule.deleteProjectAction(this.currentProject.id);
      this.confirmDeleteProject = false;
      this.$router.push({ name: "MyProjects" });
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно удален!"
      });
    },
    cancelComplete() {
      this.confirmCompleteProject = false;
    },
    acceptComplete() {
      const updatedProject = {
        id: this.currentProject.id,
        is_completed: true
      };
      ProjectModule.updateProjectAction(updatedProject).then(() => {
        StageModule.getStageByProjectId({ project_id: this.projectId });
      });
      this.confirmCompleteProject = false;
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно закрыт!"
      });
    },
    reopenProject() {
      const updatedProject = {
        id: this.currentProject.id,
        is_completed: false
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно возвращен в работу!"
      });
    },
    openKanbanSetting() {
      this.$router.push({ name: "KanbanSetting", params: { id: this.currentProject.id } });
    },
    toFlowchart() {
      this.$router.push({
        name: "Flowchart",
        params: { flow_id: this.currentProject.flow_id, project_id: this.currentProject.id }
      });
    },
    copyProjectLink() {
      copyToClipboard(window.location.href);
    },
    handleChangeOwners(val) {
      if (val.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "У проекта должен быть хотя бы один владелец!"
        });
        return;
      }
      const arrayDifference = this.arrayDifference(this.ownersList, val);
      if (!arrayDifference) return;
      const addedOwners = arrayDifference.added.map((item) => item.id);
      const deletedOwners = arrayDifference.deleted.map((item) => item.id);
      const params = {
        projectId: this.currentProject.id,
        ownersAdd: addedOwners.length ? addedOwners : undefined,
        ownersDelete: deletedOwners.length ? deletedOwners : undefined,
        changedOwners: val,
        changedMembers: [
          ...this.membersList,
          ...arrayDifference.deleted.filter(
            (item) => !this.membersList.some((el) => el.id === item.id)
          )
        ]
      };
      ProjectModule.updateProjectUsersAction(params);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    handleChangeMembers(val) {
      const arrayDifference = this.arrayDifference(this.membersList, val);
      if (!arrayDifference) return;
      const addedMembers = arrayDifference.added.map((item) => item.id);
      const deletedMembers = arrayDifference.deleted.map((item) => item.id);
      const params = {
        projectId: this.currentProject.id,
        membersAdd: addedMembers.length ? addedMembers : undefined,
        membersDelete: deletedMembers.length ? deletedMembers : undefined,
        changedMembers: val
      };
      ProjectModule.updateProjectUsersAction(params);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    arrayDifference(arr1, arr2) {
      const diff = {
        deleted: arr1.filter((item) => !arr2.find((el) => el.id === item.id)),
        added: arr2.filter((item) => !arr1.find((el) => el.id === item.id))
      };
      if (!diff.deleted.length && !diff.added.length) return false;
      return diff;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    projectId() {
      return this.$route.params.id;
    },
    currentProject() {
      return ProjectModule.currentProject?.projectFull;
    },
    projectLoader() {
      return ProjectModule.projectDescriptionLoader;
    },
    allUsers() {
      return this.userList.users.filter((item) => item.is_active !== false);
    },
    ownersList() {
      const ownersList = this.currentProject.owners.map((item) => {
        return this.allUsers.find((user) => user.id === item.user_id);
      });
      return ownersList.every((item) => !item) ? this.currentProject.owners : ownersList;
    },
    membersList() {
      const membersList = this.currentProject.members.map((item) => {
        return this.allUsers.find((user) => user.id === item.user_id);
      });
      return membersList.every((item) => !item) ? this.currentProject.members : membersList;
    },
    stages() {
      return StageModule.stages(this.projectId);
    },
    isReadonly() {
      return this.currentProject?.is_completed || !this.currentProject?.is_owner;
    },
    actionList() {
      return [
        {
          isShow: !this.isReadonly,
          iconType: "folder-check",
          actionText: "Выполнить проект",
          actionType: `complete`
        },
        {
          isShow: this.currentProject?.is_owner && this.currentProject?.is_completed,
          iconType: "reopen",
          actionText: "Вернуть в работу",
          actionType: `reopen`
        },
        {
          isShow: this.currentProject?.is_owner && !this.currentProject?.is_completed,
          iconType: "clipboard-ind",
          actionText: "Настроить бизнес-процесс",
          actionType: `business-setting`
        },
        {
          isShow: this.currentProject?.is_owner && !this.currentProject?.is_completed,
          iconType: "clipboard-secondary",
          actionText: "Настроить доску Канбан",
          actionType: `kanban-setting`
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          actionType: `copyLink`
        },
        {
          isShow: !this.currentProject?.is_completed,
          iconType: "circle-plus",
          actionText: "Добавить задачу",
          actionType: `createTask`
        },
        {
          isShow: this.currentProject?.is_owner,
          iconType: "delete",
          actionText: "Удалить проект",
          actionType: `delete`
        }
      ];
    },
    currentBoardColumns() {
      return KanbanModule.currentBoardColumns;
    }
  },
  watch: {
    $route(route, oldRoute) {
      if (route.query.stage) this.currentTab = "stages";
      if (route.params.id && route.params.id !== Number(oldRoute.params.id)) {
        this.initProject();
        // ProjectModule.getProjectFullAction(route.params.id);
        // StageModule.getStageByProjectId({ project_id: route.params.id });
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  @media (max-width: $sm) {
    font-size: 16px;
  }
}

.project {
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: $md) {
    height: calc(100% - 70px);
  }

  &__header {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #eae8f0;
    display: flex;
    position: relative;
  }

  &__menu,
  &__back {
    display: none;

    @media (max-width: $xxl) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 16px;
      cursor: pointer;
      outline: none;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__back {
    margin: 0;
  }

  &__tabs {
    flex: auto;
    padding: 20px 24px 24px 8px;
    overflow: auto;
    display: flex;
    @media (max-width: $xxl) {
      padding: 24px 24px 24px 8px;
    }
    @media (max-width: $xs) {
      /deep/ {
        .tabs {
          display: none;
        }
      }
    }

    &_adaptive {
      display: none;
      padding: 16px 16px 0 10px;

      /deep/ {
        .tabs__list {
          overflow-y: hidden;
        }
      }

      @media (max-width: $xs) {
        display: block;
      }
    }
  }

  &__action-list {
    position: absolute;
    top: 21px;
    right: 16px;
    /deep/ {
      .action-list__modal-list {
        z-index: 50;
        min-width: 255px;
      }
    }
  }

  &__container {
    width: 100%;
    height: calc(100% - 70px);
    position: relative;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__wrap {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__body {
    width: 100%;
  }

  &__stages-and-tasks {
    height: 100%;
  }

  &__kanban-table {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.description-tab {
  height: 100%;

  .project {
    &__body {
      width: 50%;
      border-right: 1px solid #eae8f0;
      @media (max-width: $lg) {
        width: 100%;
      }
    }

    &__container {
      @media (max-width: $lg) {
        overflow: auto;
      }
    }

    &__wrap {
      @media (max-width: $lg) {
        height: fit-content;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        position: relative;
      }
    }

    &__stages-header {
      flex: 0 0 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      padding: 20px 24px 24px 28px;
      border-bottom: 1px solid #eae8f0;
      border-left: 1px solid #eae8f0;
      @media (max-width: $lg) {
        display: none;
      }

      &_adaptive {
        display: none;
        @media (max-width: $lg) {
          display: flex;
          border: none;
          padding: 24px;
          height: unset;
        }
        @media (max-width: $sm) {
          padding: 16px;
        }
      }

      /deep/ .aop-button {
        margin-left: auto;
        @media (max-width: $sm) {
          justify-content: center;
          width: 40px;
          height: 40px;
          padding: 0;
        }

        i {
          @media (max-width: $sm) {
            margin: 0;
            font-size: 20px;
          }
        }

        span {
          @media (max-width: $sm) {
            display: none;
          }
        }
      }
    }

    &__users-header {
      flex: 0 0 20%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 24px 24px 28px;
      border-bottom: 1px solid #eae8f0;
      border-left: 1px solid #eae8f0;
      margin-right: 1px;
      @media (max-width: $lg) {
        display: none;
      }

      &_adaptive {
        display: none;
        @media (max-width: $lg) {
          display: flex;
          border: none;
          padding: 12px 24px 0 24px;
          height: unset;
        }
      }
    }

    &__description {
      display: flex;
      height: 100%;
      overflow: auto;
    }

    &__stages {
      width: 30%;
      border-right: 1px solid #eae8f0;
      height: 100%;
      overflow: hidden;
      @media (max-width: $lg) {
        width: 100%;
        /deep/ {
          .project-stages {
            display: flex;
            width: fit-content;
          }

          .project-stage {
            min-width: 307px;
            margin-right: 24px;
            @media (max-width: $xxs) {
              min-width: 279px;
            }

            b {
              display: none;
            }
          }

          .project-stage:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__users {
      width: 20%;
      height: 100%;
      overflow: auto;
      @media (max-width: $lg) {
        width: 100%;
        margin-bottom: 72px;
      }
      /deep/ {
        .select-bg__selected-list {
          padding-right: 4px;
        }
      }
    }
    &__users-body {
      overflow-x: hidden;
      @media (min-width: $xl) {
        /deep/ {
          .user-group {
            width: 100% !important;
            overflow-x: hidden;
          }
          .user__info {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &__content {
      overflow-y: auto;
      height: 100%;
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #eae8f0;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background: $action-primary-accent;
          cursor: pointer;
        }
      }

      &::-webkit-scrollbar-track {
        margin: 0;
        background: $surface-disabled;
      }
      @media (max-width: $lg) {
        width: 100%;
        display: flex;
        overflow-x: auto;
        /deep/ {
          .editable-user__user {
            margin-right: 24px;
            flex-shrink: 0;
          }

          .user-group {
            padding: 24px;
          }

          .user-group__content_column {
            display: flex;
          }

          .user-group__user_column {
            flex-shrink: 0;

            &:not(:last-child) {
              margin-right: 24px;
            }
          }
        }
      }
      @media (max-width: $xxs) {
        flex-direction: column;
        /deep/ {
          .editable-user {
            padding-bottom: 0;
          }

          .user-group__content_column {
            flex-direction: column;
          }
        }
      }
    }

    &__owners {
      padding: 24px 24px 0 24px;
    }

    &__members {
      padding: 0 24px;
    }
  }
}
</style>
