<template>
  <div class="month-picker-background" v-click-outside="hideModalMonth">
    <div class="month-picker-label">{{ label }}</div>
    <div class="button-month-picker" @click="showModalMonth" :class="showModal ? 'active' : ''">
      <span v-if="showIcon"><i class="icon-calendar-secondary"></i></span>
      <span class="button-month" v-if="showYear"
        >{{ month[choseMonth || 0].full }}
        <span class="year">{{ chosenYear }}</span>
      </span>
      <span v-if="!showYear">{{ month[currentMonth || 0].full }} </span>
    </div>
    <div v-show="showModal" id="month-picker">
      <div class="month_picker_container">
        <div class="year_select_area">
          <i class="icon-left" @click="clickYear(0)"></i>
          <input @input="checkInputResults" type="number" v-model="nowYear" />
          <i class="icon-right" @click="clickYear(1)"></i>
        </div>
        <div class="month_select_area">
          <ul class="month_list_area">
            <li
              v-for="(list, index) in month"
              class="month_list"
              @click="clickMonth(index)"
              :key="list"
              :class="{
                'chosen-month': choseMonth === index && chosenYear === nowYear,
                'disabled-month': disabledMonth(index)
              }"
            >
              <span>{{ list.val }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default {
  name: "MonthPickerTable",
  props: {
    minDate: {
      type: String,
      default: "2000-05"
    },
    maxDate: {
      type: String,
      default: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    },
    dateMax: {
      type: String,
      default: moment(new Date()).format("YYYY-MM")
    },
    dateMin: {
      type: String,
      default: "2000-01"
    },
    disabled: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showYear: {
      type: Boolean,
      default: true
    },
    currentMonth: {
      type: String,
      default: 0
    },
    filterMonth: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {
      nowYear: new Date().getFullYear(),
      month: [
        {
          full: "Январь",
          val: "Янв"
        },
        {
          full: "Февраль",
          val: "Фев"
        },
        {
          full: "Март",
          val: "Март"
        },
        {
          full: "Апрель",
          val: "Апр"
        },
        {
          full: "Май",
          val: "Май"
        },
        {
          full: "Июнь",
          val: "Июнь"
        },
        {
          full: "Июль",
          val: "Июль"
        },
        {
          full: "Август",
          val: "Авг"
        },
        {
          full: "Сентябрь",
          val: "Сен"
        },
        {
          full: "Октябрь",
          val: "Окт"
        },
        {
          full: "Ноябрь",
          val: "Ноя"
        },
        {
          full: "Декабрь",
          val: "Дек"
        }
      ],
      showModal: false,
      choseMonth: this.dateMax ? parseInt(this.dateMax.split("-")[1]) - 1 : new Date().getMonth(),
      chosenYear: this.dateMax ? parseInt(this.dateMax.split("-")[0]) : new Date().getFullYear(),
      timerId: 0,
      timerMonthId: 0
    };
  },
  beforeDestroy() {
    this.$root.$off("timeline_button_today_setToday");
  },
  mounted() {
    if (this.editValue) {
      this.choseMonth = new Date(this.editValue).getMonth();
      this.chosenYear = new Date(this.editValue).getFullYear();
    }
    if (this.currentMonth) {
      this.choseMonth = this.currentMonth;
    }
    if (this.filterMonth) {
      this.choseMonth = this.setCurrentMonth;
      this.chosenYear = this.setCurrentYear;
    }
    this.$root.$on("timeline_button_today_setToday", () => {
      this.choseMonth = new Date().getMonth();
      this.chosenYear = new Date().getFullYear();
    });
  },
  methods: {
    showModalMonth() {
      this.showModal = !this.showModal;
    },
    hideModalMonth() {
      this.showModal = false;
    },
    /**
     * Отвечает за переключение Годов.
     */
    clickYear(status) {
      if (status === 0) {
        if (this.nowYear > this.minDate.split("-")[0]) {
          this.nowYear--;
        }
      }
      if (status === 1) {
        if (this.nowYear < this.maxDate.split("-")[0]) {
          this.nowYear++;
        }
      }
    },
    checkInputResults() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.checkInputYear();
      }, 1000);
    },
    checkInputYear() {
      if (!this.nowYear) {
        this.nowYear = this.minDate.split("-")[0];
      }
      if (parseInt(this.nowYear) < this.minDate.split("-")[0]) {
        this.nowYear = this.minDate.split("-")[0];
      } else if (parseInt(this.nowYear) > this.maxDate.split("-")[0]) {
        this.nowYear = this.maxDate.split("-")[0];
      }
    },
    clickMonth(index) {
      if (this.disabled) {
        let dateMin = this.dateMin.split("-");
        let dateMax = this.dateMax
          ? this.dateMax.split("-")
          : moment(new Date()).format("YYYY-MM").split("-");

        if (parseInt(this.nowYear) >= dateMin[0] && parseInt(this.nowYear) <= dateMax[0]) {
          if (
            parseInt(this.nowYear) === parseInt(dateMin[0]) &&
            index + 1 >= parseInt(dateMin[1])
          ) {
            this.choseMonthPick(index);
          }
          if (
            parseInt(this.nowYear) === parseInt(dateMax[0]) &&
            index + 1 <= parseInt(dateMax[1])
          ) {
            this.choseMonthPick(index);
          }
          if (
            parseInt(this.nowYear) < parseInt(dateMax[0]) &&
            parseInt(this.nowYear) > parseInt(dateMin[0])
          ) {
            this.choseMonthPick(index);
          }
        }
      } else {
        this.choseMonthPick(index);
      }
    },
    choseMonthPick(index) {
      this.choseMonth = index;
      this.chosenYear = this.nowYear;
      this.validationDateEmit();
      this.hideModalMonth();
      // clearTimeout(this.timerMonthId)
      // this.timerMonthId = setTimeout(() => {
      //   this.validationDateEmit()
      //   this.hideModalMonth()
      // }, 500)
    },
    disabledMonth(index) {
      if (this.disabled) {
        let dateMin = this.dateMin.split("-");
        let dateMax = this.dateMax
          ? this.dateMax.split("-")
          : moment(new Date()).format("YYYY-MM").split("-");
        if (parseInt(this.nowYear) >= dateMin[0] && parseInt(this.nowYear) <= dateMax[0]) {
          if (
            parseInt(this.nowYear) === parseInt(dateMin[0]) &&
            index + 1 >= parseInt(dateMin[1])
          ) {
            return false;
          }
          if (
            parseInt(this.nowYear) === parseInt(dateMax[0]) &&
            index + 1 <= parseInt(dateMax[1])
          ) {
            return false;
          }
          if (
            parseInt(this.nowYear) < parseInt(dateMax[0]) &&
            parseInt(this.nowYear) > parseInt(dateMin[0])
          ) {
            return false;
          }
        }
        return true;
      } else return false;
    },
    validationDateEmit() {
      let month = this.choseMonth + 1 < 10 ? `0${this.choseMonth + 1}` : `${this.choseMonth + 1}`;
      this.$emit("changeMonthYear", `${this.nowYear}-${month}`);
    }
  },
  computed: {
    setCurrentMonth() {
      return moment(this.filterMonth).format("M") - 1;
    },
    setCurrentYear() {
      return moment(this.filterMonth).format("YYYY");
    }
  },
  watch: {
    currentMonth() {
      this.choseMonth = this.currentMonth;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.month-picker-background {
  cursor: pointer;
  max-width: 300px;
  width: 100%;
  position: relative;
  .month-picker-label {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
  }

  .icon-calendar {
    display: none;
  }

  .icon-calendar-secondary {
    color: $icon-subdued;
    font-size: 18px;
    margin-right: 8px;
    transition: 0.3s;
  }

  .button-month-picker {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    transition: 0.3s;
    border-radius: 4px;
    padding: 5px 6px;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 767px) {
      width: max-content;
      margin: 0;
    }

    span {
      margin: 0;
    }

    &:hover {
      background-color: $action-secondary-hovered;
    }

    &:active {
      background-color: $action-secondary-pressed;
    }

    .year {
      color: $text-subdued;
    }
  }

  .active {
    background-color: $action-secondary-hovered;
  }
  #month-picker {
    .month_picker_container {
      position: absolute;
      top: 110%;
      right: 0;
      height: auto;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #e7e7fb;
      user-select: none;
      z-index: 100;
      width: 240px;
      padding: 24px 16px;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 14px;
      @media (max-width: $sm) {
        left: unset;
        right: 0;
      }
      .year_select_area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;
        padding: 0 20px;

        input {
          width: 100px;
          outline: none;
          border: none;
          background: transparent;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
        }
        .icon-left,
        .icon-right {
          cursor: pointer;
          color: $icon-subdued;
          font-size: 20px;
        }

        .icon-left:hover,
        .icon-right:hover {
          color: $color-button-hover;
        }

        .icon-left:active,
        .icon-right:active {
          color: $color-button-click;
        }
      }

      .month_select_area {
        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .month_list_area {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;

          .month_list {
            width: 50px;
            height: 30px;
            border-radius: 4px;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $text-subdued;
          }

          .month_list:hover {
            background: #f1effa !important;
            border-radius: 4px;
          }
          .chosen-month {
            background: $action-primary !important;
            color: $action-secondary !important;
            border-radius: 4px;
          }
          .disabled-month {
            color: $text-disabled !important;
            background: white !important;
          }
        }
      }
    }
  }
}
</style>
