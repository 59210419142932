<template>
  <div class="project-task">
    <div class="project-task__header">
      <p class="project-task__deadline"><span>Срок окончания:</span>{{ setDate }}</p>
      <div class="project-task__attachment">
        <p class="project-task__attachment-item" v-if="!task.parent_task_id">
          <i class="icon-list"></i>
          <span class="project-task__count">{{ task.childs_count && task.childs_count }}</span>
          <span class="project-task__tooltip">Подзадачи</span>
        </p>
        <p class="project-task__attachment-item">
          <i class="icon-comment-empty"></i>
          <span class="project-task__count">{{ task.count_comments && task.count_comments }}</span>
          <span class="project-task__tooltip">Комментариии</span>
        </p>
        <p class="project-task__attachment-item">
          <i class="icon-paper-clip-secondary"></i>
          <span class="project-task__count">{{ task.count_files && task.count_files }}</span>
          <span class="project-task__tooltip">Файлы</span>
        </p>
      </div>
      <ActionsList :actionList="setActionList" @handleAction="handleAction" />
    </div>
    <p class="project-task__name" @click="closeModal">
      {{ task.name }}
    </p>
  </div>
</template>

<script>
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import copyText from "@/helpers/copyText";
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "ProjectTask",
  mixins: [copyText, formatDateAndTime],
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  components: { ActionsList },
  data() {
    return {
      isComplete: false
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      ProjectModule.closeCurtainShowAndEditProject();
      this.$router.push({
        name: "Task",
        params: {
          id: this.task.task_id
        },
        query: { alias: this.task.alias }
      });
    },
    handleAction(val) {
      switch (val.actionType) {
        case "createSubTask": {
          TaskManagerModuleV2.openCurtainConstructor({
            currentCreationTypeId: 4,
            currentTaskForCreation: this.task
          });
          break;
        }
        case "copyLink": {
          this.copyStringLink({
            id: this.task.task_id,
            alias: this.task.alias
          });
          break;
        }
      }
    }
  },
  computed: {
    priority() {
      return TaskModule.priority(this.task.priority_id);
    },
    status() {
      return TaskModule.status(this.task.status_id);
    },
    setDate() {
      return this.task.date_to ? this.setDateFormatFull(this.task.date_to) : "-";
    },
    setActionList() {
      let actionList = [];
      actionList.push(
        {
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить подзадачу",
          actionType: `createSubTask`
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          actionType: `copyLink`
        }
      );
      return actionList;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.project-task {
  padding: 16px;
  background-color: $bg-surface-light;
  border-top: 1px solid #eae8f0;

  @media (max-width: $sm) {
    padding: 16px 8px;
  }

  &__header {
    display: flex;
    align-items: center;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    /deep/ {
      .action-list {
        @media (max-width: $sm) {
          order: 2;
        }
      }

      .action-list__setting-button {
        background: transparent;

        &:hover {
          background: #f2f7fe;

          i {
            color: $icon-subdued;
          }
        }
      }
    }
  }

  &__deadline {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    cursor: default;

    @media (max-width: $sm) {
      order: 3;
      width: 100%;
      margin: 16px 0 0;
    }

    span {
      color: $text-subdued;
      margin-right: 8px;
    }
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: 100%;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: $surface-default;
    background: $text-default;
    border-radius: 2px;
  }

  &__attachment {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: $sm) {
      order: 1;
    }
  }

  &__attachment-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 16px;

    i {
      color: $icon-subdued;
      margin-right: 8px;
      font-size: 18px;
    }

    &:hover .project-task__tooltip {
      opacity: 1;
    }
  }

  &__count {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $text-default;
    cursor: default;
  }

  &__name {
    width: 100%;
    margin: 24px 0;
    color: $text-default;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    @include textEllipsis(2);

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
      order: 4;
    }
  }
}
</style>
