export interface IMenu {
  [key: string]: any;

  menu?: {};
}

abstract class BaseRouter {
  path: string;
  name: string;
  component: any;
  meta?: IMenu;

  protected constructor(path: string, name: string, component: () => {}, meta: IMenu | undefined) {
    this.path = path;
    this.name = name;
    this.component = component;
    this.meta = meta;
  }
}

export class ChildrenRouter extends BaseRouter {
  constructor(path: string, name: string, component: () => {}, meta?: IMenu) {
    super(path, name, component, meta);
  }

  get menu() {
    if (this.meta) {
      if (this.meta.menu) return this.meta.menu;
    }
    return false;
  }
}

export class RootRouter extends BaseRouter {
  redirect?: string;
  children?: Array<ChildrenRouter | RootRouter>;

  constructor(
    path: string,
    name: string,
    redirect: string,
    component: () => {},
    meta?: IMenu,
    children?: Array<ChildrenRouter | RootRouter>
  ) {
    super(path, name, component, meta);
    this.redirect = redirect;
    this.children = children;
  }

  get menu() {
    return {
      menu: this.meta ? this.meta.menu : undefined,
      deep: this.children
        ? this.children.map((item) => item.menu).filter((item) => !!item)
        : undefined
    };
  }
}
