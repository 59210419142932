











import { Component, Prop, Vue } from "vue-property-decorator";
import TrackerProject from "@/modules/TimeTracker/component/TimeTrackerWindow/TrackerProject.vue";
import { ITimeProject } from "@/modules/TimeTracker/interface/interface";

@Component({
  components: { TrackerProject }
})
export default class TrackerProjectList extends Vue {
  @Prop({ default: () => [], type: Array }) projects!: Array<ITimeProject>;
}
