import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import { IModalConfig } from "@/modules/TimeTracker/interface/interface";

const name = "ModalModule";

export enum ModalModuleMutationTypes {}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

export interface IFSConfig {
  component: () => Promise<any>;
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class ModalModule extends VuexModule {
  confirmSetting: null | IModalConfig = {
    title: "Закрыть окно?",
    text: "Все заполненные поля будут сброшены и вам придется заполнять данные заново.",
    component: null,
    confirmButton: "Закрыть",
    cancelButton: "Отменить",
    handleConfirm: () => console.log("confirm"),
    handleCancel: () => console.log("cancel")
  };
  isShowConfirmation: boolean = false;
  isLoader: boolean = false;

  @Mutation
  setConfirmSetting(setting: IModalConfig | null) {
    this.confirmSetting = setting;
  }

  @Mutation
  toggleConfirmation(isShow: boolean) {
    this.isShowConfirmation = isShow;
  }

  @Mutation
  toggleLoader(isShow: boolean) {
    this.isLoader = isShow;
  }
}

export default getModule(ModalModule);
