<template>
  <div class="curtain-constructor" @mousedown.self.left="checkFields">
    <ConfirmModal
      v-if="confirmCloseCurtain"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="cancelClose"
      @accept="acceptClose"
    />
    <div class="curtain-constructor__container">
      <div class="curtain-constructor__header">
        <i class="icon-arrow-back" @click="checkFields"></i>
        <h2>{{ "Создать" }}</h2>
      </div>
      <div class="curtain-constructor__body">
        <div class="curtain-constructor__type">
          <Label :label="'Тип создания'" />
          <Select
            :key="'select_main'"
            :items="items"
            v-model="currentType"
            :showAvatar="false"
            :multiple="false"
            :placeholder="'Тип создания'"
            :disabled="isChosenType"
            :removeCross="!isChosenType"
            :showSearchPanel="false"
            @input="resetEntities"
          />
        </div>
        <div class="curtain-constructor__fields">
          <CreateProject
            v-if="currentTypeId === 0"
            v-model="projectToCreate"
            :allUsers="allUsers"
          />
          <CreateProject
            v-if="currentTypeId === 1"
            v-model="projectToCreate"
            :isSubproject="true"
            :projectList="projectList"
            :allUsers="allUsers"
          />
          <CreateStage
            v-if="currentTypeId === 2"
            v-model="stageToCreate"
            :allUsers="allUsers"
            :projectList="projectList"
          />
          <CreateTask
            v-if="currentTypeId === 3"
            v-model="taskToCreate"
            :taskToCreate="taskToCreate"
            :allUsers="allUsers"
            :projectList="projectList"
          />
          <CreateTask
            v-if="currentTypeId === 4"
            v-model="taskToCreate"
            :taskToCreate="taskToCreate"
            :isSubtask="true"
            :allUsers="allUsers"
            :taskList="taskList"
          />
        </div>
      </div>
      <div class="curtain-constructor__footer">
        <Button :title="'Отменить'" :type="'cancel'" @handleButton="checkFields" />
        <Button :title="'Создать'" @handleButton="handleCreate" :loader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import { mapGetters } from "vuex";
import Select from "@/modules/UiKit/components/Select/Select";
import CreateProject from "@/modules/TaskManager/components/MyProjects/CurtainConstructor/CreateComponents/CreateProject";
import CreateStage from "@/modules/TaskManager/components/MyProjects/CurtainConstructor/CreateComponents/CreateStage";
import CreateTask from "@/modules/TaskManager/components/MyProjects/CurtainConstructor/CreateComponents/CreateTask";
import Label from "@/modules/Isp/components/IspProblem/Label";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import { isEmpty } from "@/helpers/helperFunction";
import uploadFilesTaskManager from "@/helpers/Files/uploadFilesTaskManager.mixin";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "CurtainConstructor",
  mixins: [uploadFilesTaskManager],
  props: {},
  components: { ConfirmModal, Label, CreateTask, CreateStage, CreateProject, Select, Button },
  data() {
    return {
      items: [
        {
          id: 0,
          name: "Проект",
          keyVariables: "fieldCreateProject",
          modalLabel: "Создать"
        },
        // {
        //   id: 1,
        //   name: "Вложенный проект",
        //   keyVariables: "fieldCreateSubproject",
        //   modalLabel: "Создать"
        // },
        // {
        //   id: 2,
        //   name: "Этап",
        //   keyVariables: "fieldCreateStage",
        //   modalLabel: "Добавить этап"
        // },
        {
          id: 3,
          name: "Задача",
          keyVariables: "fieldCreateTask",
          modalLabel: "Создать"
        }
        // {
        //   id: 4,
        //   name: "Подзадача",
        //   keyVariables: "fieldCreateSubtask",
        //   modalLabel: "Создать"
        // }
      ],
      currentType: [],
      projectToCreate: {},
      stageToCreate: {},
      taskToCreate: {},
      confirmCloseCurtain: false
    };
  },
  mounted() {
    if (!this.projectList.length) ProjectModule.getProjectListAction();
    if (!this.taskList.length) TaskModule.getTaskListAction(this.setParams);
    if (this.currentCreationTypeId !== null) {
      switch (this.currentCreationTypeId) {
        case 1:
          this.currentType = [
            {
              id: this.currentCreationTypeId,
              name: "Вложенный проект"
            }
          ];
          break;
        case 2:
          this.currentType = [
            {
              id: this.currentCreationTypeId,
              name: "Этап"
            }
          ];
          break;
        case 4:
          this.currentType = [
            {
              id: this.currentCreationTypeId,
              name: "Подзадача"
            }
          ];
          break;
        default:
          this.currentType = [this.items.find((item) => item.id === this.currentCreationTypeId)];
      }
    }
  },
  methods: {
    closeCurtain() {
      this.loading = false;
      TaskManagerModuleV2.closeCurtainConstructor();
      TaskModule[TaskMutationTypes.CLEAR_FILE_UPLOAD]();
    },
    checkFields() {
      if (
        !isEmpty(this.projectToCreate) ||
        !isEmpty(this.stageToCreate) ||
        !isEmpty(this.taskToCreate)
      ) {
        this.confirmCloseCurtain = true;
      } else {
        this.closeCurtain();
      }
    },
    cancelClose() {
      this.confirmCloseCurtain = false;
    },
    acceptClose() {
      this.confirmCloseCurtain = false;
      this.closeCurtain();
    },
    handleCreate() {
      switch (this.currentTypeId) {
        case 0:
          if (!this.validationProject(this.projectToCreate)) return;
          this.uploadFile(this.filesList, this.currentTypeId);
          break;
        case 1:
          if (!this.validationProject(this.projectToCreate)) return;
          this.uploadFile(this.filesList, this.currentTypeId);
          break;
        case 2:
          if (!this.validationStage(this.stageToCreate)) return;
          StageModule.createStageAction(this.stageToCreate);
          this.closeCurtain();
          break;
        case 3:
          if (!this.validationTask(this.taskToCreate)) return;
          this.uploadFile(this.filesList, this.currentTypeId);
          break;
        case 4:
          if (!this.validationSubTask(this.taskToCreate)) return;
          this.uploadFile(this.filesList, this.currentTypeId);
          break;
      }
    },
    resetEntities() {
      this.projectToCreate = {};
      this.stageToCreate = {};
      this.taskToCreate = {};
    },
    createProject() {
      this.loading = false;
      ProjectModule.createProjectAction(this.projectToCreate).then(() => {
        ProjectModule.getProjectListAction();
        NotificationObserver.notification({
          type: "success",
          message: "Проект успешно создан!"
        });
        if (this.projectToCreate.parent_id) {
          ProjectModule.updateChildProjectsList(this.projectToCreate.parent_id);
        }
        ProjectModule.getProjectTree();
        this.resetEntities();
        this.closeCurtain();
      });
    },
    filesUploaded() {
      this.taskToCreate.files = this.fileUploadingId;
      this.projectToCreate.files = this.fileUploadingId;
      switch (this.typeCreate) {
        case 0:
          this.createProject();
          break;
        case 1:
          this.createProject();
          break;
        case 3:
          this.createTask();
          break;
        case 4:
          this.createSubtask();
          break;
      }
    },
    createTask() {
      TaskModule.createTaskAction(this.taskToCreate).then(() => {
        NotificationObserver.notification({
          type: "success",
          message: "Задача успешно создана!"
        });
        KanbanModule.updateTasksByStageAction({
          stage_id: this.taskToCreate.stage_id,
          project_id: this.taskToCreate.project_id
        });
        this.resetEntities();
        this.closeCurtain();
      });
    },
    createSubtask() {
      TaskModule[TaskMutationTypes.SET_SUBTASK_LOADER](true);
      TaskModule.createTaskAction(this.taskToCreate).then(() => {
        NotificationObserver.notification({
          type: "success",
          message: "Подзадача успешно создана!"
        });
        this.resetEntities();
        this.closeCurtain();
      });
    },
    validationStage(val) {
      if (!val.project_id && val.project_id !== 0) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите проект!"
        });
        return false;
      }
      if (!val.name) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название этапа!"
        });
        return false;
      }
      if (val.name.length > 255) {
        NotificationObserver.notification({
          type: "error",
          message: "Название этапа не должно превышать 255 символов!"
        });
        return false;
      }
      return true;
    },
    validationProject(val) {
      if (this.currentTypeId === 1 && !val.parent_id) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите родительский проект!"
        });
        return false;
      }
      if (!val.name) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название проекта!"
        });
        return false;
      }
      if (val.name.length > 255) {
        NotificationObserver.notification({
          type: "error",
          message: "Название проекта не должно превышать 255 символов!"
        });
        return false;
      }
      if (!val.alias) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите короткое название проекта!"
        });
        return false;
      }
      if (val.alias.length > 5 || val.alias.length < 3) {
        NotificationObserver.notification({
          type: "error",
          message: "Короткое название проекта должно состоять из 3-5 символов!"
        });
        return false;
      }
      if (this.projectList.find((el) => el.alias === val.alias)) {
        NotificationObserver.notification({
          type: "error",
          message: "Такое краткое название уже используется!"
        });
        return false;
      }
      return true;
    },
    validationTask(val) {
      if (!val.name) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название задачи!"
        });
        return false;
      }
      if (val.name && val.name.length > 255) {
        NotificationObserver.notification({
          type: "error",
          message: "Название задачи не должно превышать 255 символов!"
        });
        return false;
      }
      if (!val.type_id) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите вид задачи!"
        });
        return false;
      }
      if (!val.priority_id) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите приоритет задачи!"
        });
        return false;
      }
      if (!val.status_id) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите статус задачи!"
        });
        return false;
      }
      return true;
    },
    validationSubTask(val) {
      if (!val.parent_id) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите родительскую задачу!"
        });
        return false;
      }
      if (!val.name.trim()) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название подзадачи!"
        });
        return false;
      }
      if (val.name && val.name.length > 255) {
        NotificationObserver.notification({
          type: "error",
          message: "Название подзадачи не должно превышать 255 символов!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setParams() {
      return {
        page: 1,
        per_page: 300,
        is_dashboard: 0
      };
    },
    filesList() {
      return taskModule.filesUploadCurrentTask;
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    currentTypeId() {
      return this.currentType[0]?.id;
    },
    currentCreationTypeId() {
      return TaskManagerModuleV2.currentCreationTypeId;
    },
    projectList() {
      return ProjectModule.projectList
        .map((item) => item.project)
        .filter((item) => !item.is_completed);
    },
    taskList() {
      return TaskModule.taskList.map((item) => item.task);
    },
    allUsers() {
      return this.userList.users.filter((item) => item.is_active !== false);
    },
    isChosenType() {
      return this.currentCreationTypeId === 0 || this.currentCreationTypeId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}

.curtain-constructor {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  &__container {
    position: fixed;
    width: 740px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    @media (max-width: $xxxl) {
      width: 494px;
    }
    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__header {
    padding: 32px 24px;
    display: flex;
    align-items: center;
    @media (max-width: $md) {
      border-bottom: 1px solid $system-grey-four;
      padding: 24px;
    }
    .icon-arrow-back {
      color: $icon-subdued;
      margin: 0 8px 0 0;
      display: none;
      font-size: 20px;
      cursor: pointer;
      @media (max-width: $md) {
        display: block;
      }
    }
  }
  &__body {
    padding: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 180px);
    width: 100%;
    @media (max-width: $xxs) {
      height: calc(100% - 210px);
    }
    /deep/ {
      .textarea__control {
        min-height: 120px;
        max-height: 350px;
      }
    }
  }
  &__type {
    margin-bottom: 56px;
  }
  /deep/ {
    .calendar__wrap {
      margin: 0;
    }
    .calendar .vc-container {
      margin-top: 8px;
    }
    .date-picker__calendar .vc-day-content,
    .calendar .vc-day-content {
      @media (max-width: $xxs) {
        width: 36px;
        height: 36px;
        &:hover {
          width: 36px;
          height: 36px;
        }
      }
    }
    .date-picker__calendar .vc-arrows-container,
    .calendar .vc-arrows-container {
      @media (max-width: $xxs) {
        width: 250px;
      }
    }
    .label label {
      @media (max-width: $xs) {
        font-size: 16px;
      }
    }
    .field {
      margin-bottom: 56px;
      width: 100%;
      input {
        width: 100%;
      }
      textarea {
        width: 100%;
      }
      .date-picker__value {
        width: 100%;
      }
      .time-picker {
        width: 100%;
      }
    }
    .field-row {
      display: flex;
      width: 100%;
      margin-bottom: 56px;
      @media (max-width: $xxxl) {
        flex-direction: column;
      }
    }
    .field-row__item {
      margin-right: 32px;
      width: 100%;
      @media (max-width: $xxxl) {
        margin-bottom: 56px;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
    .field-group {
      margin-bottom: 56px;
    }
    .field-group__item {
      margin-bottom: 56px;
      label {
        font-size: 16px;
        font-weight: 500;
        color: #756f86;
        @media (max-width: $xs) {
          font-size: 14px !important;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 40px;
    @media (max-width: $xxs) {
      flex-direction: column-reverse;
      justify-content: center;
      /deep/ {
        .aop-button {
          justify-content: center;
          &:last-child {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
</style>
