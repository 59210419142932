<template>
  <div class="input">
    <div class="input__label-wrap" v-if="label.length">
      <label class="input__label">{{ label }}</label>
      <i class="icon-info" v-if="showInfo"></i>
      <p class="input__tooltip">{{ tooltip }}</p>
    </div>
    <input
      class="input__control"
      :class="{ input__control_fill: value.length }"
      :id="id"
      :type="type"
      name="input"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :disabled="disabled"
      :autocomplete="autocomplete ? 'on' : 'off'"
      @input="$emit('input', $event.target.value)"
      @keydown.enter.prevent.exact="$emit('keydownEnter')"
      @keydown.esc.prevent.exact="$emit('keydownEsc')"
    />
  </div>
</template>

<script>
export default {
  name: "Input",
  mixins: [],
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "placeholder..."
    },
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: String || Number,
      default: ""
    },
    minlength: {
      type: String || Number,
      default: ""
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "input"
    },
    type: {
      type: String,
      default: "text"
    }
  },
  components: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.input {
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: $text-default;
  }

  &__label-wrap {
    position: relative;
    display: flex;
    margin: 0 0 24px;
    width: max-content;
  }

  .icon-info {
    align-self: flex-start;
    margin-left: 8px;
    font-size: 14px;
    line-height: 12px;
    color: $icon-subdued;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }

    &:active {
      color: $icon-pressed;
    }
  }

  &__control {
    //width: 300px;
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    padding: 8px 16px;
    background: #fafafe;
    border: 1px solid #fafafe;
    border-radius: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $text-default;
    outline: none;
    //box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);

    &:hover {
      background-color: #eae8f0;
    }

    &:hover + .switch-widget__tooltip {
      display: block;
    }

    &:focus {
      border: 2px solid #6a67ce;
      padding: 7px 15px;
    }

    &::placeholder {
      color: $text-disabled;
    }

    &_fill {
      border: 1px solid #eae8f0;
      background: #ffffff;
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    max-width: 530px;
    width: max-content;
    right: -100%;
    top: -30px;
    z-index: 2;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
  }
}
</style>
