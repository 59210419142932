<template>
  <div class="tabs-catalog">
    <label
      :class="['tabs-catalog__label', tabsMode === 'channels' ? 'tabs-catalog__active' : '']"
      @click="changeTabs('channels')"
      ref="channels"
    >
      Каналы
    </label>
    <label
      :class="['tabs-catalog__label', tabsMode === 'members' ? 'tabs-catalog__active' : '']"
      @click="changeTabs('members')"
      ref="members"
    >
      Сотрудники
    </label>
    <div
      class="tabs-catalog__underline"
      :style="{
        width: `${widthLine}px`,
        left: `${leftLine}px`
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TabsCatalog",
  mixins: [],
  props: {
    tabsMode: {
      type: String,
      default: () => {
        return "channels";
      }
    }
  },
  components: {},
  data() {
    return {
      leftLine: 0,
      widthLine: 0
    };
  },
  mounted() {
    this.changeTabs(this.tabsMode);
  },
  methods: {
    changeTabs(val) {
      this.$emit("changeModeTabs", val);
      this.leftLine = this.$refs[val].offsetLeft;
      this.widthLine = this.$refs[val].offsetWidth;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs-catalog {
  display: flex;
  align-items: start;
  position: relative;
  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    color: $border-default;
    margin-right: 30px;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: $lg) {
      margin-right: 10px;
      font-size: 17px;
    }
    @media screen and (max-width: $md) {
      margin-right: 30px;
      font-size: 18px;
    }
    @media screen and (max-width: $sm) {
      margin-right: 10px;
      font-size: 17px;
    }
    @media screen and (max-width: $xs) {
      margin-right: 5px;
      font-size: 15px;
    }
  }

  &__active {
    color: #0a1417;
  }
  &__underline {
    position: absolute;
    height: 3px;
    top: calc(100% - 1px);
    border-radius: 10px;
    background: #5551f9;
    transition: 0.3s;
  }
}
</style>
