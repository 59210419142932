import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import store from "@/store/index.js";
import versioningTimer from "@/helpers/checkVersion";
import { UiKitRoutes } from "@/modules/UiKit/routes/ui-kit-routes";
import LayoutAuth from "@/layout/LayoutAuth";
import LayoutTechnicallyWork from "@/layout/LayoutTechnicallyWork";
import { mainRouter } from "@/router/moduleRouters";
import MainSwitch from "@/layout/MainSwitch.vue";

Vue.use(Router);
Vue.use(Meta);
let routes = [
  {
    path: "/login/:reset_password_token",
    component: LayoutAuth
  },
  {
    path: "/login",
    name: "Login",
    component: LayoutAuth
  },
  {
    path: "/register",
    component: LayoutAuth
  },
  {
    path: "/works",
    component: LayoutTechnicallyWork
  },
  {
    // 404 Not Found page
    path: "*",
    component: () => import("@/views/NotFound")
  }
];

// Если сейчас dev-mode, то добавляются определенные роуты для просмотра UI kit'a
// @ts-ignore
if (window.env.APP_ENV === "dev") {
  // @ts-ignore
  routes = routes.concat(UiKitRoutes);
}
// @ts-ignore
routes.push(mainRouter);

const router = new Router({
  base: "/",
  mode: "history",
  routes
});

/**
 * Перебор текущих роутов с флагом 'meta: {requiresAuth: true} ' с проверкой на авторизацию пользователя
 */
router.beforeEach((to, from, next) => {
  // @ts-ignore
  if (window.swUpdate) {
    // @ts-ignore
    window.swUpdate = false;
    localStorage.setItem("isUpdated", "update");
    // @ts-ignore
    return (window.location = to.path);
  }
  versioningTimer.runTimersCheck();
  if (to.path !== "/login") {
    // @ts-ignore
    store.state.oldToPath = to;
    // @ts-ignore
    store.state.from = from;
  }
  if (to.path !== "/works" && store.getters.isServiceWork) {
    next("/works");
    return null;
  }
  // eslint-disable-next-line no-unused-expressions
  to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isLoggedIn
    ? next("/login")
    : next();

  if (to.path === "/works" && !store.getters.isServiceWork) next(from.path);

  if (to.path === "/login" && store.getters.isLoggedIn) {
    next(from.path);
  }
  if (to.path === "/register" && store.getters.isLoggedIn) {
    next(from.path);
  }
});
export default router;
