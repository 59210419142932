import API from "@/api/Api";

class Message extends API {
  constructor(prefix) {
    super("Message", prefix);
  }
  actionGetItems(callback, data) {
    this.get("actionGetItems", callback, data);
  }
  search(callback, data) {
    this.get("search", callback, data);
  }
  getMessagesWithLinksByChatId(callback, data) {
    this.get("getMessagesWithLinksByChatId", callback, data);
  }
  actionCreate(callback, data) {
    this.post("actionCreate", callback, data);
  }
  actionUpdate(callback, data) {
    this.update("actionUpdate", callback, data);
  }
  actionDelete(callback, data) {
    this.delete("actionDelete", callback, data);
  }
}

export default Message;
