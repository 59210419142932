<template>
  <ul class="project-list">
    <ProjectListItem v-for="item in projectTree" :key="item.id" :item="item" />
  </ul>
</template>

<script>
import ProjectListItem from "@/modules/TaskManager/components/ProjectList/ProjectListItem";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
export default {
  name: "ProjectList",
  mixins: [],
  props: {},
  components: { ProjectListItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    projectTree() {
      return ProjectModule.projectTree;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
.project-list {
  list-style: none;
  padding: 8px;
  margin: 0;
}
</style>
