var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-request-table__row",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.openInfoModal.apply(null, arguments)}}},[_c('div',{staticClass:"my-request-table__col my-request-table__col_type"},[_c('div',{staticClass:"my-request-table__type",style:(_vm.item.not_accepted_transfer_vacation_order
          ? 'background-color: #FFB901'
          : 'background-color: #6A67CE')}),_vm._v(" "+_vm._s(_vm.setType)+" ")]),_c('div',{staticClass:"my-request-table__col my-request-table__col_author user",on:{"click":_vm.openInfoModal}},[_c('img',{staticClass:"user__img",attrs:{"src":_vm.returnSrcFile(_vm.getDataInfoUser.avatar),"alt":""}}),_c('div',{staticClass:"user__wrap"},[_c('p',{staticClass:"user__name"},[_vm._v(_vm._s(_vm.getDataInfoUser.full_name))])])]),_c('div',{staticClass:"my-request-table__col my-request-table__col_date-request",on:{"click":_vm.openInfoModal}},[_c('p',{staticClass:"my-request-table__subtitle"},[_vm._v("Дата запроса")]),_vm._v(" "+_vm._s(_vm.setLocalDate(_vm.date_of_creation))+" ")]),_c('div',{staticClass:"my-request-table__col my-request-table__col_dates",on:{"click":_vm.openInfoModal}},[_c('p',{staticClass:"my-request-table__subtitle"},[_vm._v("Актуальные даты")]),_vm._v(" "+_vm._s(_vm.setLocalDate(_vm.date_from))+" - "+_vm._s(_vm.setLocalDate(_vm.date_to))+" ")]),_c('div',{staticClass:"my-request-table__col my-request-table__col_count-days",on:{"click":_vm.openInfoModal}},[_c('p',{staticClass:"my-request-table__subtitle"},[_vm._v("Количество дней")]),_vm._v(" "+_vm._s(_vm.count_days)+" ")]),_c('div',{staticClass:"my-request-table__col my-request-table__col_confirm",on:{"click":_vm.openInfoModal}},[_c('p',{staticClass:"my-request-table__subtitle"},[_vm._v("Подтверждающие лица")]),_vm._l((_vm.confirmations),function(user){return _c('ConfirmationsUsers',{key:user.user.id,attrs:{"user":user.user,"checkVacation":user.is_checked}})})],2),_c('div',{staticClass:"my-request-table__col my-request-table__col_status",on:{"click":_vm.openInfoModal}},[_c('p',{staticClass:"my-request-table__subtitle"},[_vm._v("Статус")]),_c('p',{staticClass:"my-request-table__status",class:{
        'my-request-table__status_pending':
          _vm.status === 'pending' || _vm.status === 'pendingByVacationAdmin',
        'my-request-table__status_approved': _vm.status === 'accepted',
        'my-request-table__status_rejected': _vm.status === 'rejected'
      }},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.setStatusVacation(_vm.status)))])])]),_c('div',{staticClass:"my-request-table__col my-request-table__col_actions"},[_c('ActionsList',{attrs:{"currentVacationData":_vm.item,"isAvailableForEditing":_vm.isAvailableForEditing,"showAllItem":true}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"my-request-table__status-icon"},[_c('i',{staticClass:"icon-check-mark"})])}]

export { render, staticRenderFns }