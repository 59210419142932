<template>
  <div class="move-modal" @mousedown.self="cancel">
    <div class="move-modal__window">
      <h2 class="move-modal__title">Переместить проблему?</h2>
      <div class="move-modal__wrap">
        <Label label="Команда" tooltip="Отдел, который должен решать проблему" />
        <Select
          :multiple="false"
          :showAvatar="false"
          v-model="selectedCommand"
          :items="itemsCommand"
          @input="handleSelectCommand"
          :placeholder="'Выберите команду'"
        />
      </div>
      <div class="move-modal__wrap">
        <Label
          :label="'Ответственный'"
          :tooltip="'Сотрудник, который разрабатывает план решения проблемы и принимает решение о составе команды для решения проблемы'"
        />
        <Select
          :multiple="false"
          v-model="selected"
          :items="itemsResponsible"
          :placeholder="'Выберите сотрудника'"
        />
      </div>
      <div class="move-modal__footer">
        <Button :type="'cancel'" title="Отмена" @handleButton="cancel" />
        <Button title="Переместить" @handleButton="accept" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { SWITCH_MOVE_MODAL } from "@/modules/Isp/store/mutations/mutationTypes";
import Button from "@/modules/UiKit/components/buttons/Button";
import Label from "@/modules/Isp/components/IspProblem/Label";
import ClickOutside from "vue-click-outside";
import Select from "@/modules/UiKit/components/Select/Select";
import axios from "axios";
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "IspMoveProblemModal",
  mixins: [],
  props: {},
  components: { Label, Button, Select },
  data() {
    return {
      editMode: false,
      selectedCommand: [],
      itemsCommand: [],
      selected: [],
      itemsResponsible: []
    };
  },
  created() {
    this.getTeam();
    this.selected = [this.currentInfoProblem.responsible_user];
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["getTeamsForCreateAction", "handleUpdateProblem"]),
    ...mapMutations("IspModule", { SWITCH_MOVE_MODAL }),
    cancel() {
      this.SWITCH_MOVE_MODAL(false);
    },
    getTeam() {
      this.getTeamsForCreateAction().then((resp) => {
        this.itemsCommand = resp.data.data.items;
        this.selectedCommand = this.itemsCommand.filter(
          (item) => item.id === this.currentInfoProblem.group.id
        );
        this.getTeamMembers(this.selectedCommand[0].id);
      });
    },
    getTeamMembers(id) {
      httpClient({
        url: `/ISPGroup/actionGetItem/${id}`,
        method: "GET",
        params: {
          _count: 500,
          _with: JSON.stringify(["member_users"])
        }
      }).then((res) => {
        this.itemsResponsible = res.data.data.member_users;
      });
    },
    handleSelectCommand() {
      this.getTeamMembers(this.selectedCommand[0].id);
      this.selected = [this.selectedCommand[0].leader_user];
    },
    accept() {
      this.handleUpdateProblem({
        data: {
          group_id: this.selectedCommand[0].id,
          group: this.selectedCommand[0],
          responsible_user_id: this.selected[0].id,
          responsible_user: this.selected[0]
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.move-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 55, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /deep/ {
    .label {
      margin-bottom: 12px;
      label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #343748;
      }
    }
  }
  &__wrap {
    margin-bottom: 28px;
  }
  &__window {
    overflow: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 100%;
    max-height: 80vh;
    background: $surface-default;
    border-radius: 16px;
    padding: 32px 24px 40px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0 0 60px 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }
}
</style>
