<template>
  <ul class="tooltip" ref="tooltip" id="tooltip" v-if="setTooltipUser.length">
    <li class="event-card__user" v-for="item in setTooltipUser" :key="item.id">
      {{ item.full_name }}
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Tooltip",
  mixins: [],
  props: {
    cellHeight: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 1
    }
  },
  components: {},
  data() {
    return {
      top: 0
    };
  },
  mounted() {
    this.setCardStyle();
  },
  beforeDestroy() {},
  methods: {
    setCardStyle() {
      return this.$nextTick(() => {
        const container = document.querySelector("#tooltip");
        container.style.top = this.tooltipY - container.clientHeight + "px";
        container.style.left = this.tooltipX + "px";
      });
    }
  },
  computed: {
    ...mapState("CalendarModule", ["tooltipX", "tooltipY", "event", "showTooltip"]),
    setTooltipUser() {
      let member = Array.from(new Set(this.event.filter_member_users));
      return member.filter((item) => item.id !== this.getUserId);
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.tooltip {
  position: absolute;
  z-index: 100;
  width: max-content;
  color: #fff;
  padding: 5px 9px;
  margin: 0;
  list-style: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  background-color: rgba($bg-surface-dark, 0.9);
}
</style>
