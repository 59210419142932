<template>
  <div class="graph-vacation__modal modal">
    <div class="modal__window">
      <ModalHeader
        :type="'Отпуск'"
        :editMode="editMode"
        :createMode="createMode"
        :vacation="vacation"
        :isUser="isUser"
        :request="request"
        :isAvailableForEditing="isAvailableForEditing"
      />
      <div class="modal__main">
        <ModalDate
          :editMode="editMode"
          :createMode="createMode"
          :vacation="vacation"
          :user="user"
        />
        <ModalInfo :editMode="editMode" :createMode="createMode" :vacation="vacation" />
      </div>
      <div class="modal__comments" v-if="comment.length || request">
        <h2 class="modal__heading">
          <i class="icon-comments"></i>Комментарии <span>({{ comment.length }})</span>
        </h2>
        <ModalComment :comment="item" v-for="(item, index) in comment" :key="index" />
        <CommentComponent v-if="request" />
      </div>
      <ModalFooter
        v-if="!request"
        :editMode="editMode"
        :createMode="createMode"
        :vacation="vacation"
        :isAdmin="isAdmin"
      />
      <ModalFooterConfirm :vacation="vacation" v-if="request" />
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/GraphVacation/ModalComponent/ModalHeader";
import ModalInfo from "@/components/GraphVacation/ModalComponent/ModalInfo";
import ModalComment from "@/components/GraphVacation/ModalComponent/ModalComment";
import ModalDate from "@/components/GraphVacation/ModalComponent/ModalDate";
import ModalFooter from "@/components/GraphVacation/ModalComponent/ModalFooter";
import ModalFooterConfirm from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalFooterConfirm";
import CommentComponent from "@/components/GraphVacation/Comments/CommentComponent";

export default {
  name: "ConfirmationModal",
  mixins: [],
  props: {
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    editMode: {
      type: Boolean,
      required: true,
      default() {
        return true;
      }
    },
    createMode: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    request: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    CommentComponent,
    ModalFooter,
    ModalDate,
    ModalInfo,
    ModalHeader,
    ModalComment,
    ModalFooterConfirm
  },
  data() {
    return { comment: [] };
  },
  mounted() {
    this.setComment();
  },
  beforeDestroy() {},
  methods: {
    setComment() {
      this.comment = this.vacation.confirmations
        .map((item) => {
          return { comment: item.comment, user: item.user };
        })
        .filter((item) => item.comment);
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 32, 44, 0.8);

  &__window {
    width: 798px;
    background: $bg-surface-primary;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 24px;

    @media (max-width: $md), (max-height: $xs) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      overflow-y: scroll;
    }
  }

  &__main {
    padding: 48px 42px;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #343748;

    span {
      margin-left: 8px;
    }

    i {
      margin-right: 8px;
    }
  }

  &__comments {
    padding: 42px 42px 18px;
    border-top: 1px solid #f2f2f7;
    @media screen and (max-width: $md) {
      padding-left: 20px;
      padding-right: 20px;
      max-height: 100%;
    }
  }

  .icon-comments {
    color: #a7a9c0;
    opacity: 0.3;
  }
}
</style>
