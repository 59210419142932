import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import AbsenceService from "@/modules/Absence/store/AbsenceService";
import {
  IAbsence,
  IAbsenceData,
  IAbsenceTypes,
  IParamsAbsenceTable
} from "@/modules/Absence/store/intefaces";
import { EventBus } from "@/main";
import { IModalConfig } from "@/modules/TimeTracker/interface/interface";
import { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import moment from "moment";
import Vue from "vue";
import centrifuge from "@/centrifuge";
import { domainS3, domainStorage } from "@/globalVariables";

const name = "AbsenceModule";

export enum AbsenceMutationTypes {
  SET_TYPES = "SET_TYPES",
  SET_USERS_FILTER = "SET_USERS_FILTER",
  SET_TYPES_FILTER = "SET_TYPES_FILTER",
  SET_DATE_FILTER = "SET_DATE_FILTER",
  DELETE_ABSENCE = "DELETE_ABSENCE",
  SET_MODAL_CONFIRM_DATA = "SET_MODAL_CONFIRM_DATA",
  TOGGLE_MODAL_CONFIRM = "TOGGLE_MODAL_CONFIRM",
  TOGGLE_MODAL_PICKER = "TOGGLE_MODAL_PICKER",
  LOADER_SALARY = "LOADER_SALARY",
  LOADER_PROJECT = "LOADER_PROJECT",
  TOGGLE_MODAL = "TOGGLE_MODAL",
  CREATE_ABSENCE = "CREATE_ABSENCE",
  UPDATE_ABSENCE = "UPDATE_ABSENCE",
  SET_ABSENCE_EDIT_DATA = "SET_ABSENCE_EDIT_DATA",
  SET_ABSENCES = "SET_ABSENCES"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class AbsenceModule extends VuexModule {
  absenceTypes: Array<IAbsenceTypes> = [];
  filterUsers: Array<any> = [];
  filterTypes: Array<IAbsenceTypes> = [];
  filterDate: any = null;
  absences: Array<IAbsence> = [];
  absenceCount: number = 0;
  page: number = 1;
  per_page: number = 15;
  isShowConfirmationModal: boolean = false;
  isShowAbsenceModal: boolean = false;
  isShowPicker: boolean = false;
  loaderProject: boolean = false;
  loaderSalary: boolean = false;
  modalData: any = null;
  modalConfirmData: null | IModalConfig = null;

  @Mutation
  [AbsenceMutationTypes.SET_MODAL_CONFIRM_DATA](payload: IModalConfig) {
    this.isShowConfirmationModal = true;
    this.modalConfirmData = payload;
  }

  @Mutation
  [AbsenceMutationTypes.TOGGLE_MODAL_PICKER](isShow: boolean) {
    this.isShowPicker = isShow;
  }

  @Mutation
  [AbsenceMutationTypes.LOADER_PROJECT](isShow: boolean) {
    this.loaderProject = isShow;
  }

  @Mutation
  [AbsenceMutationTypes.LOADER_SALARY](isShow: boolean) {
    this.loaderSalary = isShow;
  }

  @Mutation
  [AbsenceMutationTypes.TOGGLE_MODAL_CONFIRM](isShow: boolean) {
    this.isShowConfirmationModal = isShow;
  }

  @Mutation
  [AbsenceMutationTypes.TOGGLE_MODAL](isShow: boolean) {
    this.isShowAbsenceModal = isShow;
  }

  @Mutation
  [AbsenceMutationTypes.SET_USERS_FILTER](users) {
    this.filterUsers = users;
  }

  @Mutation
  [AbsenceMutationTypes.SET_TYPES_FILTER](types) {
    this.filterTypes = types;
  }

  @Mutation
  [AbsenceMutationTypes.SET_DATE_FILTER](date) {
    this.filterDate = date;
  }

  @Mutation
  [AbsenceMutationTypes.SET_TYPES](absences: Array<IAbsenceTypes>) {
    this.absenceTypes = absences;
  }

  @Mutation
  [AbsenceMutationTypes.SET_ABSENCE_EDIT_DATA](data) {
    this.modalData = data;
  }

  @Mutation
  [AbsenceMutationTypes.DELETE_ABSENCE](id: number) {
    this.absences = this.absences.filter((item) => item.id !== id);
  }

  @Mutation
  [AbsenceMutationTypes.CREATE_ABSENCE](data: any) {
    let isSelectUser = true;
    let isSelectType = true;
    if (this.filterUsers.length) {
      isSelectUser = this.filterUsers.some((item) => item.id === data.user_id);
    }
    if (this.filterTypes.length) {
      isSelectType = this.filterTypes.some((item) => item.id === data.user_id);
    }
    if (isSelectUser && isSelectType) {
      this.absences.push(data);
      this.absences = setUniqueArray(this.absences, "id");
      this.absenceCount = this.absenceCount + 1;
    }
  }

  @Mutation
  [AbsenceMutationTypes.UPDATE_ABSENCE](data: any) {
    const absence = this.absences.find((item) => item.id === data.id);
    if (absence) {
      const index = this.absences.findIndex((item) => item.id === data.id);
      Vue.set(this.absences, index, data);
    }
  }

  @Mutation
  [AbsenceMutationTypes.SET_ABSENCES](absences: IAbsenceData) {
    if (absences.page === 1) {
      this.absences = absences.list;
    } else {
      this.absences = [...this.absences, ...absences.list];
    }
    this.absences = setUniqueArray(this.absences, "id");
    this.absenceCount = absences.count;
    this.page = absences.page;
    this.per_page = absences.per_page;
  }

  @Action
  toggleModal(isShow: boolean) {
    this[AbsenceMutationTypes.TOGGLE_MODAL](isShow);
  }

  @Action
  async getAbsenceType() {
    const absencesType = await AbsenceService.getTypes();
    this[AbsenceMutationTypes.SET_TYPES](absencesType);
  }

  @Action
  async getAbsenceList(params?: IParamsAbsenceTable) {
    const absences = await AbsenceService.getAbsenceList(params);
    this[AbsenceMutationTypes.SET_ABSENCES](absences);
  }

  @Action
  setEdit(data) {
    this[AbsenceMutationTypes.SET_ABSENCE_EDIT_DATA](data);
    this.toggleModal(true);
  }

  @Action
  async deleteAbsence(id) {
    try {
      await AbsenceService.deleteAbsence({ id });
      this[AbsenceMutationTypes.DELETE_ABSENCE](id);
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: "Отсутсвие успешно удалено"
      });
      this[AbsenceMutationTypes.TOGGLE_MODAL_CONFIRM](false);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async createAbsence(data) {
    try {
      const id = await AbsenceService.createAbsence(data);
      let count_days: null | number = null;
      if (data.to_date) {
        // @ts-ignore
        count_days = moment(data.to_date) - moment(data.from_date);
        count_days = count_days / (1000 * 86400) + 1;
      }
      this[AbsenceMutationTypes.CREATE_ABSENCE]({ ...data, id, count_days: count_days });
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: "Отсутсвие успешно создано"
      });
      this[AbsenceMutationTypes.TOGGLE_MODAL](false);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async editAbsence(data) {
    try {
      await AbsenceService.updateAbsence(data);
      let count_days: null | number = null;
      if (data.to_date) {
        // @ts-ignore
        count_days = moment(data.to_date) - moment(data.from_date);
        count_days = count_days / (1000 * 86400) + 1;
      }
      this[AbsenceMutationTypes.UPDATE_ABSENCE]({ ...data, count_days: count_days });
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: "Отсутсвие успешно отредактировано"
      });
      this[AbsenceMutationTypes.SET_ABSENCE_EDIT_DATA](null);
      this[AbsenceMutationTypes.TOGGLE_MODAL](false);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async confirmDeleteAbsence(id) {
    const config: IModalConfig = {
      cancelButton: "Отменить",
      confirmButton: "Удалить",
      title: "Удалить отсутствие?",
      text: "Удаленную запись нельзя будет вернуть",
      handleCancel: () => {
        this[AbsenceMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      },
      handleConfirm: () => this.deleteAbsence(id)
    };
    this[AbsenceMutationTypes.SET_MODAL_CONFIRM_DATA](config);
  }

  get absenceType() {
    return (id) => this.absenceTypes.find((item) => item.id === id);
  }

  @Action
  async exportSalary(data: { date_from: string; date_to: string }) {
    const channel = await AbsenceService.exportSalary(data);
    this[AbsenceMutationTypes.LOADER_SALARY](true);
    const ws = centrifuge.subscribe(channel, (message) => {
      const url = `${domainS3}/${message.data.link}`;
      window.open(url, "_blank");
      ws.unsubscribe();
      this[AbsenceMutationTypes.LOADER_SALARY](false);
      this[AbsenceMutationTypes.TOGGLE_MODAL_PICKER](false);
    });
  }

  @Action
  async exportByProject(data: { month: string }) {
    const channel = await AbsenceService.exportByProject(data);
    this[AbsenceMutationTypes.LOADER_PROJECT](true);
    const ws = centrifuge.subscribe(channel, (message) => {
      const url = `${domainS3}/${message.data.link}`;
      window.open(url, "_blank");
      ws.unsubscribe();
      this[AbsenceMutationTypes.LOADER_PROJECT](false);
    });
  }
}

export default getModule(AbsenceModule);
