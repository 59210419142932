<template>
  <div class="calendar" v-click-outside="hideModal">
    <div class="calendar__wrap">
      <input
        @focus.stop="checkValue"
        v-mask="rangeMask"
        @input="setDateInput"
        type="text"
        v-model="dateRangeInput"
        :placeholder="setDateValue"
        :class="{ 'invalid-input': invalid }"
        ref="month"
      />
      <i
        class="icon-calendar-secondary"
        @click="toggleCalendar"
        v-if="!showCross || !showCalendar"
      ></i>
      <i class="icon-cross" v-else @click="deleteDate"></i>
    </div>
    <v-date-picker
      v-if="showCalendar"
      v-model="dateRange"
      is-range
      :columns="columns"
      :min-date="minDate"
      :max-date="maxDate"
      :rows="rows"
      :masks="masks"
      :attributes="attributes"
    >
      <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
        <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
      </div>
      <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";
import NotificationObserver from "@/helpers/UserNotification";
import ClickOutside from "vue-click-outside";

export default {
  name: "DateRangePicker",
  mixins: [],
  props: {
    range: {
      type: Object,
      default: () => {
        return {};
      }
    },
    columns: {
      type: Number,
      default: 2
    },
    rows: {
      type: Number,
      default: 1
    },
    showCross: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    }
  },
  components: [],
  data() {
    return {
      dateRange: {
        start: this.isRequired ? new Date() : null,
        end: this.isRequired ? new Date() : null
      },
      masks: {
        weekdays: "WWW"
      },
      rangeMask: {
        mask: "99.99.9999 по 99.99.9999",
        placeholder: "",
        showMaskOnHover: false
      },
      attributes: [],
      showCalendar: false,
      dateRangeInput: "",
      regExpDate: new RegExp(
        "^(?:(?:31(\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\.)(?:0?2)\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$"
      )
    };
  },
  mounted() {
    if (this.range.start || this.range.end) {
      this.dateRangeInput =
        moment(this.range.start).format("DD.MM.YYYY") +
        " по " +
        moment(this.range.end).format("DD.MM.YYYY");
      this.dateRange = this.range;
    }
  },
  methods: {
    hideModal() {
      this.showCalendar = false;
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    setDate() {
      this.$emit("input", this.dateRange);
      this.dateRangeInput =
        moment(this.dateRange.start).format("DD.MM.YYYY") +
        " по " +
        moment(this.dateRange.end).format("DD.MM.YYYY");
    },
    setUniqueDate() {
      this.$emit("inputUnique", this.dateRange);
      this.dateRangeInput =
        moment(this.dateRange.start).format("DD.MM.YYYY") +
        " по " +
        moment(this.dateRange.end).format("DD.MM.YYYY");
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    checkValue() {
      if (!this.dateRange.start && !this.dateRange.end) {
        this.dateRange.start = new Date();
        this.dateRange.end = new Date();
        this.dateRangeInput =
          moment(this.dateRange.start).format("DD.MM.YYYY") +
          " по " +
          moment(this.dateRange.end).format("DD.MM.YYYY");
      }
    },
    /**
     * Отвечает за связь модалки выбора месяцев и инпута.
     */
    setDateInput() {
      this.$emit("change", this.dateRangeInput);
    },
    setValueRange() {
      if (this.dateRangeInput.length >= 24) {
        let dateFirst = this.dateRangeInput.split(" ")[0];
        let dateSecond = this.dateRangeInput.split(" ")[2];
        if (this.regExpDate.test(dateFirst) && this.regExpDate.test(dateSecond)) {
          let dateFirstParse = dateFirst.split(".").reverse().join("-");
          let dateSecondParse = dateSecond.split(".").reverse().join("-");
          this.dateRange = {
            start: new Date(dateFirstParse),
            end: new Date(dateSecondParse)
          };
        } else {
          NotificationObserver.notification({
            type: "error",
            message: "Введите существующую дату!"
          });
          return false;
        }
      }
    },
    deleteDate() {
      this.toggleCalendar();
      this.dateRange.start = null;
      this.dateRange.end = null;
      this.dateRangeInput = "";
      this.$emit("inputUnique", this.dateRange);
      this.$emit("input", null);
    }
  },
  computed: {
    setDateValue() {
      if (!this.dateRange.start && !this.dateRange.end) {
        return "Выбрать";
      } else {
        return (
          moment(this.dateRange.start).format("DD.MM.YYYY") +
          " по " +
          moment(this.dateRange.end).format("DD.MM.YYYY")
        );
      }
    }
  },
  watch: {
    dateRange(val, old) {
      if (val.start > val.end) {
        this.dateRange.start = this.dateRange.end;
      }
      if (
        moment(val.end).format("YYYY-MM-DD") !== moment(old.end).format("YYYY-MM-DD") ||
        moment(val.start).format("YYYY-MM-DD") !== moment(old.start).format("YYYY-MM-DD")
      ) {
        this.setUniqueDate();
      }
      this.setDate();
    },
    dateRangeInput() {
      this.setValueRange();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.calendar {
  &__wrap {
    width: 100%;
    position: relative;
    margin: 0 0 24px;

    input {
      width: 100%;
      height: 40px;
      padding: 8px 16px;
      background: #ffffff;
      border: 2px solid #f2f2f7;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #343748;
      outline: none;

      &:focus {
        border: 2px solid $action-primary;
      }

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-disabled;
      }
    }

    .icon-calendar-secondary {
      position: absolute;
      top: 8px;
      right: 16px;
      color: $icon-subdued;
      font-size: 21px;
      cursor: pointer;
    }

    .icon-cross {
      position: absolute;
      top: 8px;
      right: 16px;
      color: $icon-subdued;
      font-size: 21px;
      cursor: pointer;
    }
  }

  /deep/ {
    .vc-container {
      border: 1px solid #eae8f0;
      border-radius: 8px;
      padding: 8px 2px;
      width: 100%;
    }

    .vc-arrows-container {
      padding: 0;
      margin: 0 0 24px;
    }

    .vc-arrow {
      height: 21px;
    }

    .vc-svg-icon {
      width: 21px;
      height: 21px;

      path {
        fill: $icon-subdued;
      }
    }

    .vc-header {
      padding: 0;
      margin: 0 0 24px;
    }

    .month {
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .vc-weekday {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $text-disabled;
    }

    .weekday-1 .vc-day-content,
    .weekday-7 .vc-day-content {
      color: $text-disabled;
    }

    .vc-day-content {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #000000;

      &:hover,
      &:focus {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
        color: #ffffff !important;
      }
    }

    .vc-highlight.vc-highlight-base-middle {
      width: 40px;
      height: 40px;
      background: #fafafe !important;
    }

    .vc-highlight,
    .vc-highlight.vc-highlight-base-start,
    .vc-highlight.vc-highlight-base-end {
      width: 40px;
      height: 40px;
      background: #5551f9 !important;
      border-radius: 4px !important;
    }

    .vc-day.is-not-in-month .vc-day-content {
      opacity: 1;
      color: #747474;
    }
  }
}
</style>
