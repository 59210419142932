<template>
  <div class="quarter__quarter-widgets quarter-widgets">
    <ChosenFilter
      v-model="groupValue"
      :icon="'group'"
      :placeholder="'Команды'"
      :search="true"
      :allValue="'Все команды'"
      :chosenList="groupsISP"
      @updateInfo="getProblemsList"
    />
    <ChosenFilter
      v-model="criticalityValue"
      :chosenList="criticalityList"
      :icon="'error-secondary'"
      :placeholder="'Критичность'"
      :multiple="true"
      @updateInfo="getProblemsList"
    />
    <ChosenFilter
      v-model="statusValue"
      :chosenList="statusesList"
      :icon="'circle-check'"
      :placeholder="'Статус'"
      :multiple="true"
      @updateInfo="getProblemsList"
    />
    <div class="quarter-widgets__position-right">
      <ChosenFilter
        v-model="customersValue"
        :icon="'user'"
        :placeholder="'Заказчик'"
        :multiple="true"
        :search="true"
        :chosenList="usersList"
        @updateInfo="getProblemsList"
      />
    </div>

    <div class="quarter-widgets__position-right">
      <ChosenFilter
        v-model="responsibleValue"
        :icon="'responsible'"
        :placeholder="'Ответственный'"
        :multiple="true"
        :search="true"
        :chosenList="usersList"
        @updateInfo="getProblemsList"
      />
    </div>
    <ActionList
      v-if="isISPManager || isAdminExportViewer"
      :actionList="setActionList"
      @handleAction="handleAction"
    />
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="hideConfirmModal"
      @accept="confirmParams.callbackName"
    />
    <IspReviewDuplicateProblemsModal v-if="showDuplicateModal" />
    <ProgressModal v-if="showProgressModal" />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ActionList from "@/modules/UiKit/components/ActionList/ActionList";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import ProgressModal from "@/modules/Isp/components/IspComponents/ProgressModal";
import IspReviewDuplicateProblemsModal from "@/modules/Isp/components/IspReview/IspReviewModal/IspReviewDuplicateProblemsModal";

export default {
  name: "ISPReviewFilters",
  mixins: [],
  props: {},
  components: {
    ProgressModal,
    ConfirmModal,
    ChosenFilter,
    ActionList,
    IspReviewDuplicateProblemsModal
  },
  data() {
    return {
      criticalityValue: [],
      statusValue: [],
      groupValue: [],
      customersValue: [],
      responsibleValue: [],
      confirmParams: null
    };
  },
  mounted() {
    // this.getStatuses();
    // this.getCriticality();
    this.getIspGroup();
    this.getUsers();
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  methods: {
    ...mapActions("IspModule", [
      "getStatuses",
      "getCriticality",
      "getIspGroup",
      "getUsers",
      "getProblemsList",
      "getQuarterList",
      "deleteISPQuarter",
      "addWholeQuarterToArchive",
      "exportStatisticQuarterIsp"
    ]),
    ...mapMutations("IspModule", [
      "SET_REVIEW_PROBLEM_PARAMS",
      "CLEAR_TABLE_DATA",
      "SHOW_ADD_AND_EDIT_QUARTER",
      "SET_EDIT_QUARTER",
      "SHOW_CONFIRM_MODALS",
      "SHOW_DUPLICATE_MODAL"
    ]),
    changeFilter() {
      this.CLEAR_TABLE_DATA();
      this.SET_REVIEW_PROBLEM_PARAMS(this.reviewsParams);
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("review-table-setting"));
      this.criticalityValue = setting.criticality ? setting.criticality : [];
      this.statusValue = setting.status ? setting.status : [];
      this.groupValue = setting.groups ? setting.groups : [];
      this.customersValue = setting.customer ? setting.customer : [];
      this.responsibleValue = setting.responsible ? setting.responsible : [];
    },
    setCookieValue() {
      const option = {
        criticality: this.criticalityValue,
        status: this.statusValue,
        groups: this.groupValue,
        customer: this.customersValue,
        responsible: this.responsibleValue
      };
      window.localStorage.setItem("review-table-setting", JSON.stringify(option));
    },
    handleAction(item) {
      if (item.actionType === "AddQuarter") {
        this.SHOW_ADD_AND_EDIT_QUARTER(true);
        this.SET_EDIT_QUARTER(false);
      }
      if (item.actionType === "EditQuarter") {
        this.SHOW_ADD_AND_EDIT_QUARTER(true);
        this.SET_EDIT_QUARTER(true);
      }
      if (item.actionType === "DeleteQuarter") {
        this.confirmParams = {
          title: "Удалить?",
          description: "Вы уверены, что хотите удалить выбранный квартал?",
          callbackName: () => this.deleteISPQuarter()
        };
        this.SHOW_CONFIRM_MODALS(true);
      }
      if (item.actionType === "DownLoadQuarter") {
        this.confirmParams = {
          title: "Выгрузить статистику?",
          description: "Вы уверены, что хотите выгрузить статистику по выбранному кварталу?",
          callbackName: () => this.exportStatisticQuarterIsp()
        };
        this.SHOW_CONFIRM_MODALS(true);
      }
      if (item.actionType === "ArchiveQuarter") {
        this.confirmParams = {
          title: "Архивировать?",
          description: "Вы уверены, что хотите архивировать выбранный квартал?",
          callbackName: () => this.addWholeQuarterToArchive(true)
        };
        this.SHOW_CONFIRM_MODALS(true);
      }
      if (item.actionType === "UnzipQuarter") {
        this.confirmParams = {
          title: "Разархивировать?",
          description: "Вы уверены, что хотите разахивировать выбранный квартал?",
          callbackName: () => this.addWholeQuarterToArchive(false)
        };
        this.SHOW_CONFIRM_MODALS(true);
      }
      if (item.actionType === "DuplicateQuarter") {
        this.SHOW_DUPLICATE_MODAL(true);
      }
    },
    hideConfirmModal() {
      this.SHOW_CONFIRM_MODALS(false);
      this.confirmParams = null;
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    ...mapState("IspModule", [
      "criticalityList",
      "statusesList",
      "groupsISP",
      "usersList",
      "valueSort",
      "showConfirmModal",
      "showDuplicateModal",
      "showProgressModal"
    ]),
    isISPManager() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("isp_admin")
        : false;
    },
    isAdminExportViewer() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("isp_export_viewer")
        : false;
    },
    reviewsParams() {
      return {
        _count: 300,
        _order: JSON.stringify({
          [this.valueSort.value]: this.valueSort.isAsc ? "asc" : "desc"
        }),
        flow_status_id: this.statusValue.length
          ? JSON.stringify(this.statusValue.map((item) => item.id))
          : undefined,
        group_id: this.groupValue.length
          ? JSON.stringify(this.groupValue.map((item) => item.id))
          : undefined,
        criticality_id: this.criticalityValue.length
          ? JSON.stringify(this.criticalityValue.map((item) => item.id))
          : undefined,
        responsible_user_id: this.responsibleValue.length
          ? JSON.stringify(this.responsibleValue.map((item) => item.id))
          : undefined,
        customer_user_id: this.customersValue.length
          ? JSON.stringify(this.customersValue.map((item) => item.id))
          : undefined,
        _with: JSON.stringify(["group", "responsible_user", "customer_user", "status"])
      };
    },
    setActionList() {
      let actionList = [];
      if (this.isISPManager) {
        actionList.push(
          {
            isShow: true,
            iconType: "circle-plus",
            actionText: "Добавить квартал",
            actionType: "AddQuarter"
          },
          {
            isShow: true,
            iconType: "edit",
            actionText: "Редактировать",
            actionType: "EditQuarter"
          },
          {
            isShow: true,
            iconType: "archive",
            actionText: "Архивировать",
            actionType: "ArchiveQuarter"
          },
          {
            isShow: true,
            iconType: "unarchive",
            actionText: "Разархивировать",
            actionType: "UnzipQuarter"
          },
          {
            isShow: true,
            iconType: "duplicate",
            actionText: "Дублировать",
            actionType: "DuplicateQuarter"
          },
          {
            isShow: true,
            iconType: "delete",
            actionText: "Удалить",
            actionType: "DeleteQuarter"
          }
        );
      }
      if (this.isAdminExportViewer || this.isISPManager) {
        actionList.push({
          isShow: true,
          iconType: "download",
          actionText: "Выгрузить",
          actionType: "DownLoadQuarter"
        });
      }
      return actionList;
    }
  },
  watch: {
    criticalityValue() {
      this.changeFilter();
    },
    statusValue() {
      this.changeFilter();
    },
    groupValue() {
      this.changeFilter();
    },
    customersValue() {
      this.changeFilter();
    },
    responsibleValue() {
      this.changeFilter();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/Scroll.mixin.scss";

.quarter-widgets {
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-items: center;
  }

  &__position-right {
    /deep/ .chosen-filter__list {
      left: unset;
      right: 0;
    }
  }
  /deep/ {
    .chosen-filter {
      @media (max-width: 400px) {
        height: auto;
      }
      &:nth-child(3) {
        &:after {
          content: "";
          width: 100%;
        }
      }

      &__label {
        @media (max-width: $sm) {
          margin-right: 8px;
        }
        @media (max-width: 400px) {
          margin-right: 0;
        }
        span {
          @media (max-width: $lg) {
            display: none;
          }
        }
        i {
          @media (max-width: 400px) {
            font-size: 25px;
            margin-right: 0;
          }
        }
      }
    }

    .action-list {
      margin-right: 10px;
      @media (max-width: 400px) {
        margin-right: 0;
      }
    }
  }
}
</style>
