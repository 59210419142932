import * as types from "@/modules/Isp/store/mutations/mutationTypes";

import { domain, domainStorage } from "@/globalVariables";
import router from "@/router";
import { EventBus } from "@/main";
import ErrorObserver from "@/api/Error";
import NotificationObserver from "@/helpers/UserNotification";
import httpClient from "@/api/client/httpClient";

const actionsIspReview = {
  /**
   * get a list of quarters
   */
  getQuarterList({ commit, dispatch, state }) {
    commit(types.SET_LOADER_QUARTER, true);

    httpClient({
      method: "GET",
      url: `/ISPPeriod/actionGetItems`,
      params: {
        _order: { start_date: "asc" },
        _count: 50
      }
    })
      .then((response) => {
        commit(types.GET_QUARTER_LIST, response.data.data.items);
        commit(types.FIND_CURRENT_QUARTER, response.data.data.items);
        commit(types.SET_LOADER_QUARTER, false);
        if (state.quarterList.length) {
          dispatch("setCurrentPeriodCriticalityWeight");
          if (router.app.$route.name === "IspMy") {
            dispatch("getMyIspProblemsList");
            return false;
          }
          if (router.app.$route.name === "IspReview") {
            dispatch("getProblemsList");
            return false;
          }
        }
      })
      .catch((error) => {
        ErrorObserver.notification(error);
        commit(types.SET_LOADER_QUARTER, false);
      });
  },

  setCurrentPeriodCriticalityWeight({ commit, state }) {
    httpClient({
      url: `/ISPWeightsByISPPeriods/actionGetItems`,
      method: "GET",
      params: {
        isp_period_id: state.currentQuarter.id,
        _with: JSON.stringify(["criticality"])
      }
    })
      .then((resp) => {
        commit(types.SET_CURRENT_QUARTER_CRITICALITY_WEIGHT, resp.data.data.items);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },

  /**
   * get a list of problems for a specific quarter
   */
  getProblemsList({ commit, state, dispatch }) {
    state.loaderTable = true;

    const params = {
      period_id: state.currentQuarter.id,
      ...state.problemsReviewParams
    };
    httpClient({
      method: "GET",
      url: `/ISPProblem/actionGetItems/`,
      params: params
    })
      .then((response) => {
        commit(types.GET_PROBLEM_LIST, response.data.data.items);
        commit(types.GET_REVIEW_STATISTICS_LIST, response.data.data.statistic);
        commit(types.SET_REVIEW_PROGRESS, response.data.data.progress);
        if (params.group_id) {
          dispatch("getBacklogProblemsList");
        } else {
          state.loaderTable = false;
        }
      })
      .catch((error) => ErrorObserver.notification(error));
  },

  /**
   * get a list of problems for a specific quarter
   */
  getBacklogProblemsList({ commit, state }) {
    const params = {
      period_id: JSON.stringify(null),
      ...state.problemsReviewParams
    };
    httpClient({
      method: "GET",
      url: `/ISPProblem/actionGetItems/`,
      params: params
    })
      .then((response) => {
        commit(types.GET_BACKLOG_PROBLEM_LIST, response.data.data.items);
        state.loaderTable = false;
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  closeAddAndEditQuarterModal({ commit }) {
    commit(types.SHOW_ADD_AND_EDIT_QUARTER, false);
  },
  createISPQuarter({ commit, state, dispatch }, payload) {
    httpClient({
      url: `/ISPPeriod/actionCreate`,
      method: "POST",
      data: [
        {
          name: payload.name.trim(),
          start_date: payload.start_date,
          end_date: payload.end_date,
          can_add_problems: payload.can_add_problems
        }
      ]
    })
      .then((resp) => {
        commit(types.SHOW_ADD_AND_EDIT_QUARTER, false);
        dispatch("changeWeightQuarter", resp.data.data.id);
        dispatch("getQuarterList");
      })
      .catch((error) => {
        console.error(error);
        ErrorObserver.notification(error);
      });
  },
  changeWeightQuarter({ dispatch }, payload) {
    httpClient({
      url: `/ISPWeightsByISPPeriods/actionGetItems`,
      method: "GET",
      params: {
        isp_period_id: payload,
        _with: JSON.stringify(["criticality"])
      }
    })
      .then((resp) => {
        dispatch("updateWeightCriticality", resp.data.data.items);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  updateWeightCriticality({ state, dispatch }, items) {
    httpClient({
      url: `/ISPWeightsByISPPeriods/actionUpdate/${items[0].id}`,
      method: "POST",
      data: [
        {
          weight: state.criticalityWeight.find((item) => item.label === items[0].criticality.label)
            .weight
        }
      ]
    })
      .then(() => {
        items.shift();
        if (items.length) {
          dispatch("updateWeightCriticality", items);
        } else {
          dispatch("closeAddAndEditQuarterModal");
          dispatch("getQuarterList");
          EventBus.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: state.editQuarter ? `Квартал успешно изменен!` : `Квартал успешно создан!`
          });
        }
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  editISPQuarter({ state, dispatch }, payload) {
    httpClient({
      url: `/ISPPeriod/actionUpdate/${state.currentQuarter.id}`,
      method: "PUT",
      data: [
        {
          name: payload.name.trim(),
          start_date: payload.start_date,
          end_date: payload.end_date,
          can_add_problems: payload.can_add_problems
        }
      ]
    })
      .then((resp) => {
        dispatch("changeWeightQuarter", resp.data.data.id);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  deleteISPQuarter({ state, commit, dispatch }) {
    httpClient({
      url: `/ISPPeriod/actionDelete/${state.currentQuarter.id}`,
      method: "DELETE"
    })
      .then(() => {
        NotificationObserver.notification({
          type: "success",
          message: "Квартал успешно удален!"
        });
        commit(types.SHOW_CONFIRM_MODALS, false);
        dispatch("getQuarterList");
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  addWholeQuarterToArchive({ state, commit, dispatch }, payload) {
    httpClient({
      url: `/ISPPeriod/actionArchiveAllProblems`,
      method: "PUT",
      data: [
        {
          period_id: state.currentQuarter.id,
          is_archived: payload
        }
      ]
    })
      .then((resp) => {
        commit(types.SET_CHANNEL_CENTRIFUGE, resp.data.data.channel);
        commit(types.SHOW_CONFIRM_MODALS, false);
        commit(types.SET_SHOW_PROGRESS_MODAL, true);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  duplicateAllQuarterProblems({ state, commit, dispatch }, payload) {
    httpClient({
      url: `/ISPProblem/actionDuplicationAllActiveISPProblems`,
      method: "POST",
      data: [payload]
    })
      .then((resp) => {
        commit(types.SET_CHANNEL_CENTRIFUGE, resp.data.data.channel);
        commit(types.SHOW_DUPLICATE_MODAL, false);
        commit(types.SET_SHOW_PROGRESS_MODAL, true);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  exportStatisticQuarterIsp({ state, commit }) {
    httpClient({
      url: `/ISPPeriod/export`,
      method: "GET",
      params: {
        period_id: state.currentQuarter.id
      }
    })
      .then((resp) => {
        commit(types.SHOW_CONFIRM_MODALS, false);
        let url = domainStorage + resp.data.data;
        window.open(url, "_blank");
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  }
};

export default actionsIspReview;
