










import { Component, Prop, Vue } from "vue-property-decorator";
import TextField from "@/aopV2/components/UIComponents/TextField/TextField.vue";
import { fontList } from "@/aopV2/assets/fontList";

@Component({
  components: { TextField }
})
export default class TabItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  tab!: any;
  @Prop({
    default: "",
    type: String
  })
  activeTabName!: string;
  @Prop({ type: Boolean, default: true }) isMainTab!: boolean;

  handleTab() {
    this.$emit("handleTab", this.tab);
  }

  get fontStyle() {
    return fontList.titleBoldMedium;
  }

  get isActive() {
    return this.tab.route === this.activeTabName;
  }
}
