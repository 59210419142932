<template>
  <div class="comments-body">
    <div class="comments-body__wrap-label wrap-label" v-if="showHeader">
      <div class="wrap-label__label">Комментарии</div>
    </div>
    <div class="comments-body__list" v-if="currentProblemComments.length">
      <CommentIsp v-for="comment in currentProblemComments" :key="comment.id" :comment="comment" />
    </div>
    <div class="comments-body__list-empty" v-else>
      <p>КОММЕНТАРИЕВ НЕТ</p>
    </div>
    <div class="comments-body__form" v-if="!currentInfoProblem.is_archived">
      <CommentsForm />
    </div>
  </div>
</template>

<script>
import CommentIsp from "@/modules/Isp/components/InfoProblem/BodyComponents/CommentsBody/CommentIsp";
import CommentsForm from "@/modules/Isp/components/InfoProblem/BodyComponents/CommentsBody/CommentsForm";
import { mapState } from "vuex";
export default {
  name: "CommentsBody",
  mixins: [],
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { CommentIsp, CommentsForm },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("IspModule", ["currentProblemComments", "currentInfoProblem"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.comments-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  .wrap-label {
    padding: 24px;
    &__label {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 26px;
      color: #343748;
    }
  }
  &__list {
    padding: 0 57px 0 36px;
    flex: 0 1 72%;
    //height: calc(100% - 255px);
    overflow: auto;
  }
  &__list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 72%;
    color: $text-default;
  }
  &__form {
    //flex: 1 0 20%;
    height: fit-content;
    padding: 12px 24px;
  }
}
</style>
