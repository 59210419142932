import moment from "moment";
import NotificationObserver from "@/helpers/UserNotification";
import { isString } from "@/helpers/helperFunction";
import {
  ICreateData,
  ICurrentEventData,
  IEventData
} from "@/aopV2/modules/Calendar/service/CalendarInterface";

export const dateFormat = (date) => moment(date).format("YYYY-MM-DD");

export const dataToEventAdapter = (data: ICreateData): IEventData => {
  const started_at = `${data.selectDate} ${data.timeStart}:00`;
  const ended_at = `${data.selectDate} ${data.timeEnd}:00`;
  let users = data.selectGroups.map((item) => item.users);
  users = Array.from(new Set([...users.flat(1), ...data.selectMembers.map((item) => item.id)]));
  const repeat = data.repeat;

  return {
    type_id: data.currentType.id,
    name: data.name.trim(),
    started_at,
    ended_at,
    users,
    id: data.id,
    is_for_all: data.isCommonEvent,
    is_private: data.isPrivate,
    is_need_jitsi_link: data.toggleLink,
    timezone: data.timezone,
    responsible_users: data.selectResponsible.map((item) => item.id),
    description: isString(data.description) ? data.description.trim() : "",
    location_id: data.selectLocation[0].id ? data.selectLocation[0].id : null,
    files: data.files,
    repeat
  };
};

export const eventToDataAdapter = (
  event: ICurrentEventData,
  eventTypes,
  locations,
  timezone
): ICreateData => {
  return {
    selectGroups: [],
    timezone,
    timeStart: moment(event.started_at).format("HH:mm"),
    timeEnd: moment(event.ended_at).format("HH:mm"),
    selectDate: moment(event.started_at).format("YYYY-MM-DD"),
    currentType: eventTypes.find((item) => item.id === event.type_id),
    name: event.name,
    isCommonEvent: event.is_for_all,
    isPrivate: event.is_private,
    toggleLink: !!event.jitsi_link,
    repeat: event.repeat
      ? {
          count: event.repeat.count,
          days: event.repeat.days,
          step: event.repeat.step,
          to_date: event.repeat.to_date,
          interval_type: event.repeat.interval_type
        }
      : null,
    selectResponsible: event.responsible_users!.map((item) => ({ id: item.user_id })),
    selectMembers: event.users!.map((item) => ({ id: item.user_id })),
    description: event.description!,
    selectLocation: event.location_id
      ? locations.filter((item) => item.id === event.location_id)
      : [
          {
            name: "Без кабинета",
            id: 0
          }
        ],
    id: event.id,
    isDateValid: true,
    isStartValid: true,
    isEndValid: true,
    files: event.files
  };
};

export const isValidEvent = (data: ICreateData): boolean => {
  const started_at = `${data.selectDate} ${data.timeStart}:00`;
  const ended_at = `${data.selectDate} ${data.timeEnd}:00`;
  if (!data.name!.length) {
    NotificationObserver.notification({
      type: "error",
      message: "Введите название мероприятия!"
    });
    return false;
  }
  if (!data.isDateValid) {
    NotificationObserver.notification({
      type: "error",
      message: "Заполните дату проведения!"
    });
    return false;
  }
  if (!data.isStartValid) {
    NotificationObserver.notification({
      type: "error",
      message: "Заполните время начала мероприятия!"
    });
    return false;
  }
  if (!data.isEndValid) {
    NotificationObserver.notification({
      type: "error",
      message: "Заполните время окончания мероприятия!"
    });
    return false;
  }
  if (moment(started_at) < moment()) {
    NotificationObserver.notification({
      type: "error",
      message: data.id
        ? "Нельзя отредактировать встречу на прошедшую дату!"
        : `Нельзя создать встречу на прошедшую дату!`
    });
    return false;
  }
  if (moment(started_at) > moment(ended_at)) {
    NotificationObserver.notification({
      type: "error",
      message: "Время начала не может быть позже времени конца!"
    });
    return false;
  }
  if (moment(ended_at).diff(moment(started_at)) < 900000) {
    NotificationObserver.notification({
      type: "error",
      message: "Длительность мероприятия должна быть не менее 15 минут!"
    });
    return false;
  }
  if (!data.selectResponsible!.length) {
    NotificationObserver.notification({
      type: "error",
      message: "Выберите ответственного!"
    });
    return false;
  }
  if (data.description && data.description!.trim().length > 500) {
    NotificationObserver.notification({
      type: "error",
      message: "Длина описания встречи не должна превышать 500 символов!"
    });
    return false;
  }
  if (data.name!.trim().length > 255) {
    NotificationObserver.notification({
      type: "error",
      message: "Длина названия встречи не должна превышать 255 символов!"
    });
    return false;
  }
  if (!moment(started_at, "YYYY-MM-DD HH:mm:ss").isValid()) {
    NotificationObserver.notification({
      type: "error",
      message: "Укажите дату проведения встречи!"
    });
    return false;
  }
  if (!moment(ended_at, "YYYY-MM-DD HH:mm:ss").isValid()) {
    NotificationObserver.notification({
      type: "error",
      message: "Укажите дату проведения встречи!"
    });
    return false;
  }
  return true;
};
