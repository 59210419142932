

























import { Component, Prop, Vue } from "vue-property-decorator";
import DropDownList from "@/aopV2/components/UIComponents/DropDownList/DropDownList.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList";

@Component<GroupBlock>({
  components: { SelectList, DropDownList },
  watch: {
    isSomeGroup() {
      if (!this.isSomeGroup) {
        this.selectGroupUsers = [];
      }
    }
  }
})
export default class GroupBlock extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  group!: any;
  @Prop({
    default: () => {},
    type: Array
  })
  selectedGroup!: Array<any>;
  selectGroupUsers: Array<any> = [];

  mounted() {
    const group = this.selectedGroup.find((item) => item.id === this.group.id);
    if (group.users.length) {
      this.selectGroupUsers = group.users.map((item) => ({ id: item }));
    }
  }

  handleChangeGroup(selected) {
    this.selectGroupUsers = selected;
    this.$emit("selectedGroupUser", {
      ...this.group,
      users: this.selectGroupUsers.map((item) => item.id)
    });
  }

  handleSelectedGroup() {
    this.$emit("selectedGroup", this.group);
    if (this.isSelected) {
      this.selectGroupUsers = [];
    } else {
      this.selectGroupUsers = this.users;
    }
  }

  get user() {
    return (id) => this.$store.getters["UsersModule/userById"](id)[0];
  }

  get users() {
    return this.group.users.map((item) => this.user(item));
  }

  get isSomeGroup() {
    return this.selectedGroup.some((item) => item.id === this.group.id);
  }

  get isSelected() {
    return this.isSomeGroup && this.selectGroupUsers.length !== 0;
  }
}
