<template>
  <tr class="table__row">
    <TableCell
      v-for="(cell, index) in tableHeadList"
      :key="index"
      :row="row"
      :cell="cell"
      :tableHead="tableHeadList"
    >
      <slot slot-scope="props" :row="props.row" :cell="props.cell"></slot>
    </TableCell>
  </tr>
</template>

<script>
import TableCell from "@/modules/Tables/components/Table/TableCell";
import { mapActions, mapState } from "vuex";

export default {
  name: "UsersTableRow",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    tableHeadList: {
      type: Array,
      default: () => []
    }
  },
  components: { TableCell },
  data() {
    return {};
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["getTableData"])
  },
  computed: {
    ...mapState("TablesModules", ["loaderTable", "tableParams"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.table {
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    width: fit-content;

    @media (max-height: 320px) {
      padding: 8px 24px;
    }

    &:nth-child(2n) {
      background: #fcfcfe;
    }
  }

  &__row:hover {
    background: #f1effa;
  }
}
</style>
