<template>
  <div class="modal-background">
    <div class="modal-content">
      <div class="modal-content__header">
        <div class="label">Восстановление пароля</div>
        <i @click="hideModalTask" class="icon-cross"></i>
      </div>
      <div class="modal-content__form">
        <label>Введите ваш email</label>
        <TextInput
          :isTextarea="false"
          :isEmoji="false"
          :type="'email'"
          :classList="'font18'"
          :placeholder="'Например, lastname_firstname@smartworld.team'"
          :editValue="editValue"
          @changeText="setEmailValue"
        />
      </div>
      <div class="modal-content__footer">
        <button class="button-cancel" @click="hideModalTask">Отменить</button>
        <button class="button-send" @click="sendMail" @>Отправить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { host } from "@/globalVariables";
import TextInput from "@/components/Widgets/Inputs/TextInput";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalReturnPassword",
  mixins: [],
  props: ["editValue"],
  components: { TextInput },
  data() {
    return {
      email: "",
      loader: false
    };
  },
  mounted() {
    if (this.editValue) {
      this.setEmailValue({ value: this.editValue });
    }
  },
  methods: {
    setEmailValue(val) {
      this.email = val.value;
    },
    hideModalTask() {
      this.clearModal();
      this.$root.$emit("hideModalReturnPassword", false);
    },
    clearModal() {
      this.email = null;
    },
    sendMail() {
      if (!this.email || this.loader) return false;
      this.loader = true;
      httpClient({
        url: `${host.authUrl}/User/sendResetPasswordMail`,
        method: "POST",
        data: [
          {
            email: this.email
          }
        ]
      })
        .then((resp) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `${resp.data.data.message}`
          });
          this.loader = false;
          this.hideModalTask();
        })
        .catch((error) => {
          this.loader = false;
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 716px;
    border-radius: 24px;
    user-select: none;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
    @media screen and (max-width: $md) {
      width: 90%;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 30px 30px 23px;
      border-bottom: 1px solid #f2f2f7;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 20px 20px 15px;
      }
      .label {
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        color: #000000;
        @media screen and (max-width: $sm) {
          font-size: 14px;
        }
      }
      i {
        font-size: 20px;
        cursor: pointer;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 38px 30px;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 20px 20px;
      }
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-disabled;
        margin-bottom: 24px;
      }
      /deep/ {
        .input-wrap {
          width: 100%;
          margin: 0;
          input {
            @media screen and (max-width: $sm) {
              font-size: 14px;
            }
          }
          input::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: $text-disabled;
            @media screen and (max-width: $sm) {
              font-size: 14px;
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 30px;
      border-top: 1px solid #f2f2f7;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 20px;
      }
      .button-cancel {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
        border: none;
        outline: none;
        background: none;
        @media screen and (max-width: $sm) {
          font-size: 12px;
        }
      }
      .button-send {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $text-on-interactive;
        background: $text-highlight;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 100px;
        padding: 12px 24px;
        border: none;
        outline: none;
        @media screen and (max-width: $sm) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
