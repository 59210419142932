<template>
  <div class="new-employee-widget" @click.self="$emit('hideEmployeeWidget')">
    <div class="new-employee-widget__content">
      <div class="new-employee-widget__title">
        <h2>Новые сотрудники</h2>
        <img :src="require('@/modules/News/assets/img/NewsImage/waving.svg')" alt="" />
      </div>
      <div class="new-employee-widget__loader" v-if="loading">
        <Spinner />
      </div>
      <div class="new-employee-widget__body">
        <NewEmployeeItem v-for="item in employeeItems" :key="item.id" :employeeItem="item" />
      </div>
    </div>
    <p v-if="!employeeItems.length && !loading" class="new-employee-widget__empty">Пусто :(</p>
  </div>
</template>

<script>
import NewEmployeeItem from "@/modules/News/components/NewEmployeeComponents/NewEmployeeItem";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "NewEmployeeWidget",
  mixins: [],
  props: {
    employeeItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean
    }
  },
  components: { Spinner, NewEmployeeItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.new-employee-widget {
  position: relative;
  width: 355px;
  max-height: 50vh;
  flex: 1 1 50%;
  border-left: 1px solid #eae8f0;

  &__item {
    /deep/ {
      .user__avatar {
        @media (max-width: $xl) {
          display: none;
        }
      }

      .user__name {
        max-width: 220px;

        @media (max-width: $xxl) {
          max-width: 170px;
        }

        @media (max-width: $lg) {
          max-width: 140px;
        }
      }

      .user__description {
        max-width: 220px;

        @media (max-width: $xxl) {
          max-width: 170px;
        }

        @media (max-width: $lg) {
          max-width: 140px;
        }
      }
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: $bg-surface-primary;
    height: 100%;
  }

  &__title {
    padding: 22px 24px;
    height: 70px;
    border-bottom: 1px solid #eae8f0;
    display: flex;
    align-items: center;
    @media (max-width: $lg) {
      text-align: center;
    }
    h2 {
      margin: 0 16px 0 0;
      padding: 0;
      font-weight: 500;
      font-size: 18px;
      color: $text-default;
    }
  }
  &__body {
    padding: 24px 28px;
    max-height: 450px;
    overflow: auto;
  }
  &__empty {
    padding: 77px 0 66px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: $text-subdued;
    user-select: none;
  }
}
</style>
