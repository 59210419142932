<template>
  <div class="create-project" @paste="onPaste">
    <div class="create-project__parent-project field" v-if="isSubproject">
      <Label :label="'Родительский проект'" />
      <Select
        :placeholder="'Выберите проект'"
        :items="projectList"
        v-model="parentProject"
        :multiple="false"
        :showAvatar="false"
        :disabled="currentProjectForCreation"
        :removeCross="!currentProjectForCreation"
        @input="handleChangeParentProject"
      />
    </div>
    <div class="create-project__title field">
      <Label :label="'Название проекта*'" />
      <Input
        v-model="title"
        :placeholder="'Введите название проекта'"
        :maxlength="255"
        @input="handleChangeTitle"
      />
    </div>
    <div class="create-project__alias field">
      <Label
        :label="'Краткое название проекта(ALIAS)*'"
        tooltip="ALIAS будет использоваться в номерах задач внутри проекта. ALIAS должен быть уникальным. Ограничения: 3-5 символов, A-Z, a-z, А-Я, а-я, 0-9"
      />
      <Input
        v-model="alias"
        :placeholder="'Введите краткое название (ALIAS)'"
        :minlength="3"
        :maxlength="5"
        @input="handleChangeAlias"
      />
    </div>
    <div class="create-project__description field">
      <Label :label="'Описание'" />
      <Textarea
        v-model="description"
        :placeholder="'Добавьте описание проекта'"
        :maxlength="32000"
        @input="handleChangeDescription"
      />
    </div>
    <div class="create-project__deadline field">
      <Label :label="'Сроки проекта'" />
      <DateRangePicker
        :placeholder="'Выбрать'"
        :columns="1"
        :showCross="true"
        :isRequired="false"
        @input="handleChangeDeadline"
      />
    </div>
    <div class="create-project__users field-group">
      <Label :label="'Владельцы и участники проекта'" />
      <div class="field-group__item">
        <Label :label="'Владелец*'" />
        <Select
          :placeholder="'Добавить'"
          :items="allUsers"
          v-model="owners"
          @input="handleChangeOwners"
        />
      </div>
      <div class="field-group__item">
        <Label :label="'Участники'" />
        <Select
          :placeholder="'Добавить'"
          :items="allUsers"
          v-model="members"
          @input="handleChangeMembers"
        />
      </div>
    </div>
    <div class="files">
      <div class="files__background">
        <file-upload
          v-if="filesList.length < 10"
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          post-action="/upload/post"
          class="file-uploader"
          input-id="createTask"
          :multiple="true"
          :drop="true"
          v-model="files"
          :drop-directory="true"
          @input="handleUploadFile"
          ref="upload"
          id="upload"
        >
          <div class="uploader-icon" v-show="!($refs.upload && $refs.upload.dropActive)">
            <p>Перетащите файл сюда или</p>
            <i class="icon-paper-clip-secondary"></i>
          </div>
        </file-upload>
        <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
        <FileList :fileList="filesList" :isEdit="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";
import Select from "@/modules/UiKit/components/Select/Select";
import moment from "moment";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import FileUpload from "vue-upload-component";
import FileList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileList";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
export default {
  name: "CreateProject",
  mixins: [],
  model: {
    prop: "projectToCreate",
    event: "fieldChanged"
  },
  props: {
    isSubproject: {
      type: Boolean,
      default: false
    },
    projectToCreate: {
      type: Object,
      default: () => {}
    },
    allUsers: {
      type: Array,
      default: () => {}
    },
    projectList: {
      type: Array,
      default: () => {}
    }
  },
  components: {
    Select,
    DateRangePicker,
    Textarea,
    Label,
    Input,
    FileUpload,
    DropFileUpload,
    FileList
  },
  data() {
    return {
      title: "",
      alias: "",
      description: "",
      owners: [],
      members: [],
      parentProject: [],
      file: [],
      files: []
    };
  },
  mounted() {
    if (this.currentProjectForCreation) {
      this.parentProject = [this.currentProjectForCreation];
      this.handleChangeParentProject(this.parentProject);
    }
  },
  methods: {
    handleUploadFile(val) {
      val.forEach((item) => {
        if (item.type === "") {
          item.type = item.name.split(".").slice(-1).join("").toLowerCase();
        }
      });
      TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("add");
      taskModule.openModalFileUpload(val);
      this.files = [];
    },
    onPaste(val) {
      if (val.clipboardData.files.length !== 0 && this.totalNumberFiles < 10) {
        TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("add");

        Array.from(val.clipboardData.files).forEach((file) => {
          const fileItem = {
            id: file.name + Date.now(),
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            fileObject: true,
            postAction: "/upload/post"
          };
          this.files.push(fileItem);
        });

        taskModule.openModalFileUpload(this.files);
        this.files = [];
      }
    },
    // @TODO Зарефакторить
    handleChangeParentProject(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.parent_id = val[0]?.id;
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeTitle(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.name = val.trim();
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeAlias(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.alias = val.trim();
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeDescription(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.description = val.trim().length ? val.trim() : undefined;
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeDeadline(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.date_from = val?.start ? moment(val.start, "YYYY:MM:DD") : null;
      changedProject.date_to = val?.end ? moment(val.end, "YYYY:MM:DD") : null;
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeOwners(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.owners = val.map((item) => item.id);
      this.$emit("fieldChanged", changedProject);
    },
    handleChangeMembers(val) {
      const changedProject = JSON.parse(JSON.stringify(this.projectToCreate));
      changedProject.members = val.map((item) => item.id);
      this.$emit("fieldChanged", changedProject);
    }
  },
  computed: {
    currentProjectForCreation() {
      return TaskManagerModuleV2.currentProjectForCreation;
    },
    filesList() {
      return TaskModule.filesUploadCurrentTask ? TaskModule.filesUploadCurrentTask : [];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";
.files {
  width: 100%;
  position: relative;
  height: auto;

  &__background {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;

    .file-list {
      margin-top: 15px;
    }
    .file-uploader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      z-index: 100;
      color: $text-subdued;
      white-space: nowrap;

      @media (max-height: $sm) {
        height: 50px;
      }

      .uploader-icon {
        width: 100%;
        height: 70px;
        display: flex;
        border: 1px dashed $border-disabled;
        justify-content: center;
        align-items: center;

        i {
          margin: 0 0 0 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          background: #fafbfc;
        }
      }

      &:hover {
        color: $text-highlight;
      }

      &:active {
        color: $text-highlight;
      }

      /deep/ {
        img {
          margin-top: 14px;
        }
        .attach-file {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          transition: color 0.3s ease;
        }
        label {
          cursor: pointer;
        }
      }
    }
    /deep/ {
      .drop-active {
        position: relative;
        height: 70px;
        padding: 5px 0;

        &__wrap {
          flex-flow: row;
        }

        .icon-cloud-upload {
          font-size: 50px;
        }
        &__title {
          display: none;
        }
        &__text {
          display: none;
        }
      }
    }
  }

  &__list {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    max-height: 150px;

    @media (max-height: $xxxl) {
      max-height: 120px;
    }

    @media (max-height: $sm) {
      max-height: 80px;
    }

    @media (max-height: 320px) {
      max-height: 40px;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: max-content;
      overflow-y: auto;
    }

    &_doc {
      @media (max-height: $xxxl) {
        max-height: 80px;
      }
    }
  }

  &__item {
    position: relative;
    width: 20%;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px;

    @media screen and (max-width: 850px) and (orientation: landscape) {
      width: 30%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__img {
    width: 100px;
    height: auto;
    display: block;

    @media (max-height: $sm), (max-width: $sm) {
      width: 200px;
    }
  }

  .icon-cross {
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &__info {
    padding-left: 5px;
    padding-right: 8px;
    height: 24px;
    width: 134px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    margin-bottom: 5px;
    background-color: $text-disabled;
    border-radius: 24px;
    cursor: pointer;

    i {
      margin-right: 4px;
      margin-left: 4px;
      color: $text-light;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-light;
  }
  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-left: auto;

    .icon-cross {
      margin-right: 0;
    }
  }
}
</style>
