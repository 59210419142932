<template>
  <div class="quarter__statistics">
    <TabsContent :tabs="tabsList" v-model="currentTab" />
    <div class="quarter__content">
      <IspStatisticsItem
        v-if="currentTab === 'All'"
        :statistics="statistics"
        :allStatistics="true"
      />
      <IspStatisticsItem v-if="currentTab === 'Critical'" :statistics="setCriticalityStatistics" />
      <IspStatisticsItem v-if="currentTab === 'Blocker'" :statistics="setBlockerStatistics" />
      <IspStatisticsItem v-if="currentTab === 'Large'" :statistics="setLargeStatistics" />
      <IspStatisticsItem v-if="currentTab === 'Comfort'" :statistics="setComfortStatistics" />
    </div>
  </div>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import IspStatisticsItem from "@/modules/Isp/components/IspComponents/Statistics/IspStatisticsItem";

export default {
  name: "IspStatistics",
  mixins: [],
  props: {
    statistics: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { IspStatisticsItem, TabsContent },
  data() {
    return {
      currentTab: "All",
      tabsList: [
        {
          label: "Все",
          value: "All"
        },
        {
          label: "Критические",
          value: "Critical"
        },
        {
          label: "Блокирующие",
          value: "Blocker"
        },
        {
          label: "Значительные",
          value: "Large"
        },
        {
          label: "Комфорт",
          value: "Comfort"
        }
      ]
    };
  },
  mounted() {},
  methods: {},
  computed: {
    setBlockerStatistics() {
      return this.statistics.filter((item) => item.criticality_id === 1);
    },
    setCriticalityStatistics() {
      return this.statistics.filter((item) => item.criticality_id === 2);
    },
    setLargeStatistics() {
      return this.statistics.filter((item) => item.criticality_id === 3);
    },
    setComfortStatistics() {
      return this.statistics.filter((item) => item.criticality_id === 4);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.quarter {
  &__statistics {
    width: 100%;
    margin: 24px 0 0;

    /deep/ {
      .tabs__item:first-child {
        margin-left: 0;
      }
    }
  }

  &__content {
    width: 100%;
    margin: 32px 0 0;
  }
}
</style>
