



















import TimeTaskContent from "@/modules/TimeTracker/component/TimeTaskContent/TimeTaskContent.vue";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITimeProject } from "@/modules/TimeTracker/interface/interface";
import TimeTracker from "@/modules/TimeTracker/store";

@Component({
  components: { ProjectTag, TimeTaskContent }
})
export default class TrackerProject extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  project!: ITimeProject;
  isOpen: boolean = false;

  getTaskByProject() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      TimeTracker.getTaskByProjectId({ project_id: this.project.id, page: 1, per_page: 50 });
    }
  }

  get isActiveProject() {
    const activeProject = TimeTracker.activeProject;
    if (activeProject) {
      return activeProject.id === this.project.id;
    } else {
      return false;
    }
  }

  get tag() {
    switch (this.project!.tracker_id) {
      case null: {
        return "AOP-TIME";
      }
      case 1: {
        return "JiraCloud";
      }
      case 2: {
        return "JiraServer";
      }
      case 3: {
        return "AOP-TASK";
      }
      default: {
        return "AOP";
      }
    }
  }
}
