import centrifuge from "@/centrifuge";

export const broadcastCentrifuge = (
  ids: Array<number>,
  type: string,
  data: { [key: string]: any }
) => {
  const arrayRequest = new Array(ids.length);
  for (let i = 0; i < ids.length; ++i) {
    arrayRequest[i] = centrifuge.publish(`$user@${ids[i]}`, {
      type,
      data
    });
  }
  Promise.all(arrayRequest).catch((e) => console.warn(e));
};
