<template>
  <div class="timeline-vacation__row">
    <div class="timeline-vacation__vacation" @click.self="$emit('openCreateVacationModal')">
      <TimeLineVacationItem
        v-for="(vacation, index) in user.vacationOrders"
        :key="index"
        :vacation="vacation"
        :refs="refs"
      />
    </div>
    <div class="timeline-vacation__col" v-for="(week, weekNumber) in date" :key="weekNumber">
      <TimeLineDay :week="week" :item="user.vacationOrders" :isShowWeekends="isShowWeekends" />
    </div>
  </div>
</template>

<script>
import TimeLineVacationItem from "@/components/GraphVacation/TimeLine/TimeLineVacationItem";
import TimeLineDay from "@/components/GraphVacation/TimeLine/TimeLineDay";
export default {
  name: "TimeLineVacationRow",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    date: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isShowWeekends: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    userLoading: {
      type: Boolean,
      default: true
    },
    refs: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { TimeLineVacationItem, TimeLineDay },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.timeline-vacation {
  &__vacation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    cursor: pointer;
    z-index: 1;
    &:hover {
      background-color: rgba($bg-gray-200, 0.5);
    }
  }

  &__day_start {
    content: "";
    position: absolute;
    top: 0;
    height: 48px;
    background-color: $action-primary;
    border: 1px solid $action-primary;
    border-radius: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      background-color: $action-primary-hovered;
      border: 1px solid $action-primary-hovered;
    }
  }
}
</style>
