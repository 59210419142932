<template>
  <div class="chat-files" ref="modal" @click.self="handleClose">
    <div class="chat-files__modal">
      <div class="chat-files__header">
        <h3 class="chat-files__title">Загрузить</h3>
        <i class="icon-cross" @click="handleClose"></i>
      </div>
      <ul class="chat-files__list chat-files__list_image">
        <li class="chat-files__item" v-for="(item, index) in imageList" :key="item.name + index">
          <FileItem :file="item" :index="index" />
        </li>
      </ul>
      <ul class="chat-files__list">
        <li
          class="chat-files__item chat-files__item_file"
          v-for="(item, index) in fileList"
          :key="item.name + index"
        >
          <FileItem :file="item" :index="index" />
        </li>
      </ul>
      <div class="chat-files__editor">
        <h4 class="chat-files__text">Комментарий</h4>
        <ModalTextEditor @changeModalTextEditor="textEditorValue" @sendFile="checkFileUpload" />
      </div>
      <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
      <div class="chat-files__footer">
        <file-upload
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          input-id="file2"
          key="modal"
          v-model="files"
          post-action="/upload/post"
          :multiple="true"
          :drop="true"
          :drop-directory="true"
          ref="upload"
          @input="handleUploadFile"
          v-if="files.length < 10"
        >
          <ModalButton :title="'Добавить еще'" :type="'cancel'" />
        </file-upload>
        <ModalButton
          @handleButton="checkFileUpload"
          :title="'Отправить'"
          :class="{ 'button-left': files.length === 10 }"
        />
      </div>
      <div class="chat-files__loader" v-if="loaderFiles"><Widget type="loader-new" /></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FileItem from "@/modules/Messenger/components/ChatModal/FileModal/FileItem";
import ModalTextEditor from "@/modules/Messenger/components/ChatModal/FileModal/ModalTextEditor";
import FileUpload from "vue-upload-component";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import uploadFileChat from "@/modules/Messenger/mixins/uploadFileChat.mixin";
import Widget from "@/components/Widgets/Widget";
export default {
  name: "ChatFileModal",
  mixins: [uploadFileChat],
  props: {},
  components: { Widget, DropFileUpload, ModalButton, ModalTextEditor, FileUpload, FileItem },
  data() {
    return {
      fileTypes: [
        "image/apng",
        "image/avif",
        "image/gif",
        "image/png",
        "image/svg+xml",
        "image/jpeg"
      ],
      whiteListFiles: [
        "image/png",
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/pdf",
        "application/pdf",
        "audio/mpeg",
        "video/mpeg",
        "video/mp4",
        "video/3gpp",
        "video/quicktime",
        "video/mpeg",
        "video/x-flv",
        "video/x-ms-wmv",
        "video/x-msvideo"
      ],
      valueEditor: "",
      imageList: [],
      linkMatch:
        /https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}\b([-a-zA-Zа-яА-Я0-9()!@:%_+.~#?&//=]*)/gi,
      fileList: [],
      loaderFiles: false
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyEvent);
    document.querySelector("#textEditor").blur();
    document.querySelector("#textEditorModal").focus();
    this.sortFiles(this.files);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyEvent);
    document.querySelector("#textEditor").focus();
  },
  methods: {
    ...mapActions("MessengerModule", [
      "closeFileModal",
      "setPasteFile",
      "setEditorValue",
      "sendFirstMessage",
      "sendMessage"
    ]),
    handleClose() {
      this.closeFileModal(false);
      document.querySelector("#textEditor").focus();
    },
    handleKeyEvent(event) {
      if (event.code === "Escape") {
        this.handleClose();
      }
    },
    checkFileUpload() {
      if (!this.files.length) {
        return null;
      }
      let sizeStop = 104857600;
      let validFiles = this.files
        .map((item) => item.file)
        .some((item) => item.size > sizeStop || !this.whiteListFiles.includes(item.type));
      if (validFiles) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Выбранный Вами файл не подходит по расширению или размеру!`
        });
        return false;
      }
      this.loaderFiles = true;
      this.fileUploader = this.files.map((item) => item);
      this.createPull(this.fileUploader);
    },
    textEditorValue(val) {
      this.valueEditor = val;
    },
    sendMessageBeforeUpload() {
      const links = Array.from(new Set(this.valueEditor.match(this.linkMatch)));
      if (!this.currentChat.channel.id) {
        this.sendFirstMessage({ text: this.valueEditor, links, id_files: this.fileUploadingId });
      } else {
        this.sendMessage({
          text: this.valueEditor,
          id: this.currentChat.channel.id,
          id_files: this.fileUploadingId,
          links
        });
      }

      this.loaderFiles = false;
      this.closeFileModal(false);
    },
    handleUploadFile(files) {
      this.closeFileModal(true);
      this.setPasteFile(files);
    },
    isImage(file) {
      return this.fileTypes.includes(file.type);
    },
    sortFiles(fileList) {
      this.imageList = [];
      this.fileList = [];
      fileList.forEach((file) => {
        if (this.isImage(file)) {
          this.imageList.push(file);
        } else {
          this.fileList.push(file);
        }
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", ["files", "currentChat"])
  },
  watch: {
    files: function (fileList) {
      this.sortFiles(fileList);
    },
    fileUploadingId(val) {
      if (this.files.length === val.length) {
        this.sendMessageBeforeUpload();
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.drop-active {
}
.chat-files {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &__modal {
    padding: 24px;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    max-width: 428px;
    position: relative;
    @media screen and (max-width: $xxs) {
      max-height: 77vh;
      margin-top: auto;
      border-radius: 4px 4px 0 0;
    }
    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: 77vh;
      max-width: 100%;
      margin-top: auto;
      overflow-y: auto;
      border-radius: 4px 4px 0 0;
    }
    /deep/ {
      .drop-active {
        top: 0;
        left: 0;
      }
      .file-image__image {
        @media screen and (max-width: $xs) {
          max-width: 100%;
        }
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    @media screen and (max-height: 380px) {
      margin-bottom: 10px;
    }
    .icon-cross {
      font-size: 16px;
      color: $icon-subdued;
      cursor: pointer;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on;
    color: #000000;
    @media screen and (max-width: $xxs) {
      font-size: 16px;
    }
  }
  &__editor {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  &__text {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__list {
    all: unset;
    list-style: none;
    display: flex;
    max-width: 490px;
    margin: 10px 0;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 200px;
    overflow: auto;
    @media screen and (max-width: $xxs) {
      max-height: 80px;
    }
    @media screen and (max-height: 380px) {
      max-height: 50px;
      max-width: 100%;
    }
    &_image {
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
      max-height: 100px;
      @media screen and (max-height: 380px) {
        max-width: 100%;
      }
    }
  }
  &__item {
    &_file {
      width: 100%;
      margin-bottom: 8px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    /deep/.file-item {
      width: 100%;
    }
    .file-image__image {
      height: 80px;
    }
  }
  &__footer {
    border-top: 1px solid $border-default;
    padding-top: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /deep/ {
      label {
        cursor: pointer;
      }
      .default-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
        &_cancel {
          color: #343748 !important;
          background: none !important;
          cursor: pointer;
        }
      }
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.6);
  }
  .button-left {
    margin-left: auto;
  }
}
</style>
