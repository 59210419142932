<template>
  <div
    class="event-type"
    :class="[
      isActive ? 'event-type_active' : '',
      editMode ? 'event-type_edit' : '',
      `${feedbackClass}`
    ]"
  >
    <span class="event-type__icon-wrap">
      <i :class="[setIconClass, classes]"></i>
    </span>
    <span>{{ event_type }}</span>
  </div>
</template>

<script>
export default {
  name: "EventType",
  mixins: [],
  props: {
    event_type: {
      type: String,
      default: ""
    },
    classes: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setIconClass() {
      switch (this.event_type) {
        case "Собрание": {
          return "icon-lightning1";
        }
        case "Тренинг": {
          return "icon-flash";
        }
        case "ISP": {
          return "icon-smile";
        }
        case "Собеседование": {
          return "icon-microphone";
        }
        default: {
          return false;
        }
      }
    },
    feedbackClass() {
      switch (this.event_type) {
        case "Баг": {
          return this.isActive ? "feedback-bug feedback-bug_active" : "feedback-bug";
        }
        case "Идея": {
          return this.isActive ? "feedback-idea feedback-idea_active" : "feedback-idea";
        }
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.event-type {
  align-self: flex-start;
  cursor: default;
  padding: 7px 8px;
  border-radius: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  background-color: $surface-primary-hovered;
  transition: all 0.3s ease;
  &_active {
    transition: all 0.3s ease;

    background-color: $surface-primary-pressed;
    i {
      transition: all 0.3s ease;

      color: $surface-primary-pressed;
    }
  }
  &_edit {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: $surface-primary-pressed;
    }
  }
  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 8px;
    i {
      transition: all 0.3s ease;

      font-size: 12px;
      flex-shrink: 0;
      color: $surface-primary-hovered;
    }
  }
}
</style>
