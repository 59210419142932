























import { Component, Prop, Vue } from "vue-property-decorator";
import { validationProps } from "@/aopV2/helpers/validationProps";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";
import MainLoader from "@/aopV2/components/UIComponents/Loader/MainLoader.vue";

enum ButtonSize {
  small = "small",
  medium = "medium",
  large = "large"
}

enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  triatry = "triatry",
  outline = "outline",
  disabled = "disabled"
}

enum ButtonDeviceType {
  pc = "pc",
  tablet = "tablet",
  mobile = "mobile"
}

enum ButtonPositionIcon {
  none = "none",
  left = "left",
  right = "right",
  "left-and-right" = "left-and-right",
  center = "center"
}

@Component({
  components: { MainLoader, Spinner }
})
export default class MainButtonNew extends Vue {
  @Prop({
    default: "primary",
    type: String,
    validator: (value) => validationProps(value, ButtonType)
  })
  type!: string;

  @Prop({
    default: "Кнопка",
    type: String
  })
  title!: string;

  @Prop({
    default: "",
    type: String
  })
  iconName!: string;

  @Prop({
    default: "medium",
    type: String,
    validator: (value) => validationProps(value, ButtonSize)
  })
  size!: string;

  @Prop({
    default: "pc",
    type: String,
    validator: (value) => validationProps(value, ButtonDeviceType)
  })
  deviceType!: string;

  @Prop({
    default: "none",
    type: String,
    validator: (value) => validationProps(value, ButtonPositionIcon)
  })
  positionIcon!: string;

  @Prop({
    default: true,
    type: Boolean
  })
  hasBackground!: string;
  @Prop({ default: false, type: Boolean }) loader!: boolean;

  mounted() {}

  get setSpinnerColor() {
    return "#fff";
  }

  get setSize() {
    return ButtonSize[this.size];
  }

  get setType() {
    return ButtonType[this.type];
  }

  get setDeviceType() {
    return ButtonDeviceType[this.deviceType];
  }

  get setPositionIcon() {
    return ButtonPositionIcon[this.positionIcon];
  }

  get setStyleClassesButton() {
    return [
      "main-button__button",
      `main-button__button_${this.setSize}`,
      `main-button__button_${this.setType}`,
      `main-button__button_${this.setDeviceType}`,
      `icon_${this.setPositionIcon}`,
      { "main-button__button_without-background": !this.hasBackground }
    ];
  }

  get setPositionLeftIcon() {
    return this.setPositionIcon === "left" || this.setPositionIcon === "left-and-right";
  }

  get setPositionRightIcon() {
    return this.setPositionIcon === "right" || this.setPositionIcon === "left-and-right";
  }

  get setPositionCenterIcon() {
    return this.setPositionIcon === "center";
  }
}
