<template>
  <li class="time__statistics-item" :style="{ background: setBackground(item[0]), color: setColor(item[0]) }">
    <p class="time__value" :style="{ color: setColor(item[0])}"><span class="time__round" :style="{ background: setColor(item[0])}"></span><span>{{setStatisticsName(item[0])}}:</span> {{ setStatisticsValue }}</p>
  </li>
</template>

<script>
export default {
  name: "TimeStatisticsItem",
  mixins: [],
  props: {
    item: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: [],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    setBackground(item) {
      switch (item) {
        case "total_tracked":
          return "#E8F7FC";
        case "total_normal":
          return "#F1EFFA";
        case "total_overwork":
          return "#EBFCF6";
        case "total_lesswork":
          return "#FEF0F0";
      }
    },
    setColor(item) {
      switch (item) {
        case "total_tracked":
          return "#127E95";
        case "total_normal":
          return "#504DA8";
        case "total_overwork":
          return "#238B6A";
        case "total_lesswork":
          return "#A22929";
      }
    },
    setStatisticsName(item) {
      switch (item) {
        case "total_tracked":
          return "Отработано";
        case "total_normal":
          return "Норма";
        case "total_overwork":
          return "Переработки";
        case "total_lesswork":
          return "Недоработки";
      }
    }
  },
  computed: {
    setStatisticsValue() {
      return this.item[1].charAt(0) === '-' ? this.item[1].slice(1) : this.item[1];
    }
  },
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.time {
  &__statistics-item {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    margin-right: 24px;
    width: max-content;
    height: 24px;
    border-radius: 40px;
  }

  &__value {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    span {
      margin-right: 8px;
    }
  }

  &__round {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}


</style>
