<template>
  <ul class="dashboard__list">
    <MyDashboardListItem
      v-for="task in tasks"
      :key="task.task.id"
      :task="task.task"
      :tasksData="tasks"
      :totalCount="totalCount"
      :page="page"
      @getInfo="getInfo"
    />
  </ul>
</template>

<script>
import MyDashboardListItem from "@/modules/TaskManager/components/MyDashboard/MyDashboardListItem";

export default {
  name: "MyDashboardList",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    tasks: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { MyDashboardListItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getInfo(value) {
      this.$emit("getDashboardInfo");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.dashboard {
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    list-style: none;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
