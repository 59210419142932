<template>
  <div
    class="comments-form"
    v-click-outside="hideModalEmoji"
    :class="{ 'comments-form_files': files.length }"
  >
    <div class="comments-form__container">
      <div class="comments-form__textarea-wrap">
        <textarea
          :id="setId"
          :ref="'textarea'"
          class="comments-form__textarea"
          maxlength="1024"
          type="text"
          v-model.trim="value"
          @input="mixin_autoResize_resize"
          @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
          @keydown.enter.prevent.exact="switchKeyEnter"
          :placeholder="placeholder"
        ></textarea>
      </div>
      <div class="comments-form__icons">
        <button
          class="comments-form__button"
          type="button"
          @click="showModalEmoji = !showModalEmoji"
        >
          <i class="icon-emoji" :style="showModalEmoji ? 'color: #5551f9' : 'color: #A7A9C0'"></i>
        </button>
        <file-upload
          v-if="haveFiles"
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          post-action="/upload/post"
          :input-id="`commentFiles`"
          :multiple="true"
          :drop="drop"
          :drop-directory="dropDirectory"
          v-model="files"
          @input="uploadFiles"
          class="file-button"
          :maximum="2"
          :ref="`commentFiles`"
          :name="`commentFiles`"
        >
          <i class="icon-paper-clip-secondary"></i>
        </file-upload>
        <Spinner class="comments-form__loader" v-if="formLoader || loading" :small="true" />
        <button
          class="comments-form__button"
          @click.stop="createChunksPoolFiles"
          type="button"
          v-else
        >
          <i class="icon-send"></i>
        </button>
      </div>
      <Picker
        v-if="showModalEmoji"
        set="apple"
        @select="addEmoji"
        title="Выберите Эмоджи"
        :i18n="settings"
      />
    </div>
    <div class="comments-form__files" v-if="files.length">
      <FilesItem
        v-for="(file, index) in files"
        :key="index"
        :file="file"
        :isEdit="true"
        @deleteFile="deleteFile(file, index)"
      />
    </div>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import ResizeMixin from "@/helpers/ResizeMixin";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import uploadCommentsFilesMixin from "@/helpers/Files/uploadCommentsFiles.mixin";
import FileUpload from "vue-upload-component";
import FilesItem from "@/modules/UiKit/components/Comments/CommentsFiles/FilesItem";
import { EventBus } from "@/main";

export default {
  name: "CommentsForm",
  mixins: [ResizeMixin, uploadCommentsFilesMixin],
  props: {
    modeAction: {
      type: String,
      default: ""
    },
    currentComment: {
      type: Object,
      default: () => null
    },
    id: {
      type: Number,
      default: null
    },
    parentId: {
      type: Number,
      default: null
    },
    typeComment: {
      type: [String, Number],
      default: ""
    },
    multiple: {
      type: [String, Boolean]
    },
    dropDirectory: {
      type: String
    },
    size: {
      type: Number,
      default: 48
    },
    placeholder: {
      type: String,
      default: "Добавить комментарий..."
    },
    drop: {
      type: String
    },
    value: {
      type: String,
      default: ""
    },
    formLoader: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ""
    },
    haveFiles: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FilesItem,
    Spinner,
    Picker,
    FileUpload
  },
  data() {
    return {
      files: [],
      showModalEmoji: false,
      commentsListHeight: 130,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  beforeDestroy() {
    EventBus.$off("offLoader");
  },
  mounted() {
    EventBus.$on("offLoader", () => {
      this.loading = false;
    });
  },
  methods: {
    uploadFiles(value) {
      this.$emit("setFiles", value);
      this.uploadFile(value);
    },
    createChunksPoolFiles() {
      this.loading = true;
      this.$emit("toggleFormLoader", true);
      if (!this.fileUploader.length) {
        this.sendComment();
        this.loading = false;
        return null;
      }
      if (this.deleteFiles.length) {
        this.$emit("updateAction", { files: [], files_delete: this.deleteFiles });
        this.deleteFiles = [];
        this.$emit("setValue", this.content);
      }
      this.createPull(this.fileUploader);
    },
    addEmoji(emoji) {
      let cursorPosition = this.$refs.textarea.selectionStart;
      let FirstPart = this.value.substring(0, cursorPosition);
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition, this.value.length);
      this.$emit("setValue", FirstPart + Emoji + SecondPart);
    },
    hideModalEmoji() {
      this.showModalEmoji = false;
    },
    sendComment() {
      this.loading = true;
      switch (this.modeAction) {
        case "edit":
          this.$emit("updateAction", { files: this.arrFiles, files_delete: this.deleteFiles });
          break;
        case "reply":
          this.$emit("createAction", this.arrFiles);
          break;
        default:
          this.$emit("createAction", this.arrFiles);
          break;
      }
      this.$refs.textarea.style.height = `57px`;
      const list = document.getElementById(`commentsList`);
      list.style = "max-height: calc(100% - 130px)";
      this.hideModalEmoji();
      this.files = [];
      this.fileUploader = [];
      this.arrFiles = [];
      this.changeModeAction("");
      this.setCurrentComment(null);
      this.loading = false;
    },
    filesUploaded() {
      this.sendComment();
    },
    deleteFile(index) {
      this.files = this.files.filter((item) => item.id !== index.id);
      this.fileUploader = this.fileUploader.length
        ? this.fileUploader.filter((item) => item.id !== index.id)
        : [];
      this.fileUploadingId = this.fileUploadingId.length
        ? this.fileUploadingId.filter((item) => item !== index.id)
        : [];
      if (typeof index.id === "number") {
        this.deleteFiles.push(index.id);
      }
    },
    switchKeyCtrlEnter(event) {
      this.newLine(event);
    },
    switchKeyEnter() {
      this.createChunksPoolFiles();
    },
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    },
    changeModeAction(val) {
      this.$emit("changeModeAction", val);
    },
    setCurrentComment(val) {
      this.$emit("setCurrentComment", val);
    }
  },
  computed: {
    setId() {
      return `textareaForm`;
    },
    setDisabledSend() {
      return (!this.content || !this.content.trim().length) && !this.files.length;
    }
  },
  watch: {
    currentComment() {
      if (this.currentComment && this.modeAction === "edit") {
        this.deleteFiles = [];
        this.files = this.currentComment.files;
        this.oldEditFiles = JSON.parse(
          JSON.stringify(this.currentComment.files.map((item) => item.id))
        );
      } else {
        this.files = [];
        this.fileUploader = [];
        this.oldEditFiles = [];
        this.deleteFiles = [];
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.comments-form {
  height: fit-content;
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 32px 24px;

  @media screen and (max-width: $sm) {
    padding: 16px;
  }

  &_files {
    padding-bottom: 0;
  }

  &__icons {
    position: absolute;
    right: 16px;
    top: 18px;
    width: max-content;
    display: flex;
    align-items: center;
  }

  &__container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .icon-emoji,
  .icon-send,
  .icon-paper-clip-secondary {
    cursor: pointer;
    font-size: 20px;
    color: $icon-subdued;

    &:hover {
      color: $icon-hovered;
    }
  }

  .icon-emoji,
  .icon-paper-clip-secondary {
    margin-right: 8px;
  }

  &__loader {
    z-index: 1000;
  }

  &__textarea {
    width: 100%;
    height: 57px;
    min-height: 57px;
    max-height: 180px;
    border: 2px solid transparent;
    padding: 16px 106px 16px 24px;
    mix-blend-mode: normal;
    border-radius: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    outline: none;
    resize: none;
    transition: 0.2s border ease;

    @media screen and (max-width: $sm) {
      padding: 16px 80px 16px 16px;
    }

    &:hover {
      background: #fafafe;
    }

    &:focus {
      border: 2px solid #5551f9;
    }
  }
  &__textarea-wrap {
    width: 100%;
    position: relative;
  }

  &__button {
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
  }

  &__list {
    display: flex;
    flex-flow: row;
    margin: 8px 0 0;
    padding: 0;
    list-style: none;
  }

  /deep/ {
    .file-uploads {
      label {
        cursor: pointer;
      }

      &:hover i {
        color: $icon-hovered;
      }
    }

    .emoji-mart {
      position: absolute;
      right: 0;
      bottom: 100%;
      width: 338px !important;

      @media (max-width: 850px) and (orientation: landscape) {
        height: 200px;
      }

      @media (max-width: $sm) {
        width: 250px !important;
        height: 350px;
      }
      @media (max-width: $xs) {
        width: 220px !important;
      }

      @media (max-width: $sm) and (orientation: landscape) {
        bottom: 73%;
        height: 155px;
      }

      .emoji-mart-preview {
        display: none !important;
      }

      .emoji-mart-title-label {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }

  textarea {
    font-family: "SF", sans-serif !important;

    &::placeholder {
      color: $text-disabled;
    }
  }

  &__files {
    display: flex;
    align-items: center;
    margin: 8px 0 0;
  }
}
</style>
