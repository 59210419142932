import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";

class FileService extends Service {
  private module: string;

  constructor(module: string) {
    super();
    this.module = module;
  }

  //Event Type Category
  createChunkPool(file_name: string): Response<any> {
    return httpClientV2
      .post<any>(this.module + "/files/create_chunk", { file_name })
      .then((resp) => {
        return resp.data.pool_name;
      });
  }

  uploadChunkPool(data: {
    pool_name: number | string;
    chunk_name: string;
    base_64_chunk: string;
  }): Response<any> {
    return httpClientV2.post<any>(this.module + "/files/upload_chunk", data).then((resp) => {
      return resp.data.success;
    });
  }

  collectFileFromPool(pool_name: string | number): Response<any> {
    return httpClientV2
      .post<any>(this.module + "/files/collect_pool", { pool_name })
      .then((resp) => {
        return resp.data.channel;
      });
  }
}

export default FileService;
