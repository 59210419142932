<template>
  <div class="working-calendar">
    <div class="working-calendar__loader" v-show="isLoading">
      <Loader />
    </div>
    <div class="working-calendar__content" v-show="!isLoading">
      <div class="working-calendar__all-hours">
        <span>Норма месяца:</span>
        <div>{{ countAllHours }}</div>
      </div>
      <MainCalendar
        @getTimeInfo="getTimeInfo"
        @updateTime="updateTime"
        @setEditDate="setEditDate"
        :attributes="attributes"
        :isAdmin="isAdmin"
        :editDay="editDay"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";
import WorkCalendarModule from "@/modules/TimeTracker/store/WorkCalendarModule";
import moment from "moment";
import MainCalendar from "@/modules/TimeTracker/component/WorkingCalendar/MainCalendar";
import Loader from "@/components/Widgets/Loader2.0/Loader";

export default {
  name: "WorkingCalendar",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: { MainCalendar, Loader },
  data() {
    return {
      attributes: [],
      date: moment(new Date()).format("YYYY-MM"),
      currentMonth: null,
      isLoading: false,
      monolitCalendar: [],
      editDay: null
    };
  },
  mounted() {},
  methods: {
    async updateTime(data) {
      const editDay = this.monolitCalendar.find((item) => item.date === data.date);
      await WorkCalendarModule.editDaysHour({ hours: data.hours, id: editDay.id, date: data.date });
      this.setAtr();
      this.setEditDate({ date: null });
      this.$emit("updateWorkingCalendar", this.date);
    },
    setEditDate(data) {
      this.editDay = data.date;
    },
    setAtr() {
      this.attributes = this.calendar.map((item) => ({
        customData: {
          hours: item.hours,
          id: item.id ? item.id : null,
          date: item.date
        },
        dates: item.date
      }));
    },
    /**
     * Получение данных по открытому в календаре месяцу
     */
    async getTimeInfo(data) {
      if (data.year) {
        this.currentMonth = `${data.year}-${data.month < 10 ? "0" + data.month : data.month}`;
      }
      const [_, resp] = await Promise.all([
        WorkCalendarModule.getCalendar({ month: this.currentMonth }),
        httpClient({
          url: `/WorkingCalendar`,
          method: "GET",
          params: {
            _count: 31,
            _search: `{"date":"${this.currentMonth}"}`,
            _order_by: "date",
            _order: { date: "asc" }
          }
        })
      ]);
      this.setAtr();
      this.monolitCalendar = resp.data.data.items;
    }
  },
  computed: {
    calendar() {
      return WorkCalendarModule.calendar;
    },
    countAllHours() {
      let countAllHours = 0;
      for (let i in this.attributes) {
        countAllHours += parseInt(this.attributes[i].customData.hours);
      }
      return countAllHours;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  display: none;
}

.working-calendar {
  position: relative;
  //height: max-content;
  background: $surface-default;
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  padding: 24px;
  border-radius: 8px;
  max-width: 772px;
  max-height: 754px;
  width: 100%;
  height: 100%;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__all-hours {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    background: #fafbfc;
    border-radius: 24px;
    z-index: 1;
    font-size: 14px;
    line-height: 24px;
    color: $text-default;

    span {
      margin-right: 8px;
      color: $text-subdued;
      opacity: 0.5;
    }
  }

  &__buttons {
    position: absolute;
    top: 32px;
    right: 48px;
    display: flex;
    align-items: center;
    z-index: 1;

    i {
      color: $icon-subdued;
      font-size: 24px;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 24px;
      }

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-check-mark {
      font-size: 13px;
    }
  }
}
</style>
