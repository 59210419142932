<template>
  <transition name="fade" appear>
    <div class="notification-message" @click="openChat">
      <button class="notification-message__close" v-if="!index" @click.prevent.stop="handleClose">
        Скрыть все
      </button>
      <div class="notification-message__title">
        <h4 class="notification-message__message">
          <i class="icon-email"></i>
          <span>Новое сообщение <span v-if="isThread">в треде</span></span>
        </h4>
        <span class="notification-message__time">{{ setTime }}</span>
      </div>
      <div class="notification-message__channel">
        <div class="notification-message__wrap">
          <h5 class="notification-message__channel-name">{{ newMessage.user.full_name }}</h5>
          <p class="notification-message__text">{{ setText }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "NotificationMessage",
  mixins: [],
  props: {
    newMessage: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    ...mapActions("MessengerModule", ["closeAllUnreadMessages", "openCurrentChat"]),
    handleClose() {
      this.closeAllUnreadMessages();
    },
    openChat() {
      this.$router
        .push({
          path: "/Messenger",
          query: { chat_id: this.newMessage.chat.id }
        })
        .then(this.onComplete);
    },
    onComplete() {
      this.closeAllUnreadMessages();
      this.openCurrentChat({
        chat: this.newMessage.chat,
        isSendMessage: false,
        isThread: this.isThread,
        message: this.newMessage.message
      });
    }
  },
  computed: {
    setText() {
      return this.newMessage.message.text.length ? this.newMessage.message.text : "file";
    },
    isThread() {
      return !!this.newMessage.parent_message_id;
    },
    setTime() {
      moment.locale("ru");
      return moment(this.newMessage.message.updated_at).format("LT");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(50%);
}

.notification-message {
  display: flex;
  flex-flow: column;
  padding: 16px;
  height: 97px;
  background: #ffffff;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 16px;
  position: relative;
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
  }
  &__channel {
    display: flex;
    align-items: center;
  }
  &__title {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
  &__message {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    color: $chat-editor;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    i {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  &__time {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: $text-disabled;
  }
  &__channel-name {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    color: $text-default;
    font-weight: 500;
    margin: 0 0 4px;
  }
  &__text {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $text-subdued;
    max-height: 28px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__close {
    all: unset;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    color: $text-disabled;
    transition: color 0.3s ease-in;
    position: absolute;
    bottom: 100%;
    right: 0;
    background: #ffffff;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 16px;
    padding: 4px 0;
    @media (hover: hover) {
      &:hover {
        color: $text-subdued;
      }
    }
    &:active {
      color: $text-default;
    }
  }
}
</style>
