var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    _vm.setLoaderClass,
    _vm.setClass,
    'aop-button',
    { 'aop-button_small': _vm.small },
    { 'aop-button_large': _vm.large },
    { 'aop-button_icon': !!_vm.icon },
    { 'aop-button_icon-right': _vm.iconRight }
  ],attrs:{"type":_vm.eventType,"disabled":_vm.type === 'disabled' || _vm.type === 'main-disabled' || _vm.loader},on:{"click":function($event){$event.stopPropagation();return _vm.handleButton.apply(null, arguments)}}},[(_vm.icon && !_vm.loader && !_vm.iconRight)?_c('i',{class:("icon-" + _vm.icon)}):_vm._e(),(_vm.title.length && !_vm.loader)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.count && !_vm.loader)?_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.count))]):_vm._e(),(_vm.icon && !_vm.loader && _vm.iconRight)?_c('i',{class:("icon-" + _vm.icon)}):_vm._e(),(_vm.loader)?_c('Spinner',{attrs:{"small":true}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }