var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('v-calendar',{staticClass:"calendar__custom",attrs:{"title-position":"left","masks":_vm.masks,"popover.visibility":"false","attributes":_vm.attributes,"title-transition":"","is-expanded":"","locale":"ru"},on:{"update:to-page":_vm.getTimeInfo},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
var monthLabel = ref.monthLabel;
var yearLabel = ref.yearLabel;
return _c('div',{},[_c('span',{staticClass:"month"},[_vm._v(_vm._s(_vm.upperFirstChar(monthLabel) + ", " + yearLabel))])])}},{key:"weekdays",fn:function(ref){
var day = ref.day;
return _c('div',{},[_vm._v(_vm._s(day.week))])}},{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
return _c('div',{on:{"click":function($event){return _vm.clickSelectDate(day)}}},[_c('div',{staticClass:"vc-h-full"},[_c('span',{class:[
            'vc-day-content',
            attributes.length ? (day.id === _vm.getToday ? 'today' : '') : '',
            _vm.setClassDependingAttributes(attributes),
            _vm.setClassDependingDay(attributes, day, _vm.getToday)
          ]},[_vm._v(_vm._s(day.day))])])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }