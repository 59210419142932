<template>
  <div
    :class="[
      'status',
      {
        status_isDropDown: isDropDown && statusList.length,
        status_open: showList && isDropDown && statusList.length
      }
    ]"
    :style="{ borderColor: status.color }"
    @click="showList = !showList"
    v-click-outside="hideList"
  >
    <p class="status__content">{{ status.name }}</p>
    <button
      class="status__button"
      v-if="isDropDown && statusList.length"
      :class="{
        status__button_open: showList
      }"
    >
      <i class="icon-down"></i>
    </button>
    <ul class="status__list" v-if="showList && isDropDown && statusList.length">
      <li class="status__item" v-for="item in statusList" :key="item.id" @click="setItem(item)">
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "StatusProblem",
  mixins: [],
  props: {
    status: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDropDown: {
      type: Boolean,
      default: false
    },
    statusList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    setItem(value) {
      this.$emit("input", value);
      this.hideList();
    },
    hideList() {
      this.showList = false;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.status {
  font-family: "Roboto", "Arial", sans-serif !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  width: max-content;
  height: 28px;
  padding: 4px 8px;
  border-radius: 24px;
  border: 2px solid #c3c7d2;
  transition: all 0.3s ease;
  &_open {
    border-color: #5551f9 !important;
  }
  &_isDropDown {
    border-radius: 8px;
    cursor: pointer;
  }

  &__content {
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    text-transform: uppercase;
    color: $text-default;
  }

  &_initial {
    border: 2px solid #c3c7d2;
  }

  &_work {
    border: 2px solid #5fa3e7;
  }

  &_examination {
    border: 2px solid #53b6e4;
  }

  &_revision {
    border: 2px solid #42d3e0;
  }

  &_done {
    border: 2px solid #3be5bd;
  }

  &_close {
    border: 2px solid #1ec993;
  }

  &_cancel {
    border: 2px solid #fb646d;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 0 0 8px;
    cursor: pointer;

    i {
      display: flex;
      color: $icon-subdued;
      transition: all 0.3s ease;
    }
    &_open {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: $surface-default;
    border: 1px solid $system-grey-four;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  &__item {
    padding: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $text-default;
    cursor: pointer;

    &:hover {
      background: $bg-surface-light;
    }
  }
}
</style>
