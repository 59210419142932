import CryptoJS from "crypto-js";
import { host } from "@/globalVariables";
import store from "@/store";
import NotificationObserver from "@/helpers/UserNotification";
import httpClient from "@/api/client/httpClient";

/**
 * Константа с найстройками для плагина CryptoJs
 * @type {{parse(*=): *, stringify(*): string}}
 */
const CryptoJSAesJson = {
  /**
   * Формирование объекта с данными зашифрованные в AES для отправки в backend
   * @param cipherParams
   * @return {string}
   */
  stringify(cipherParams) {
    let j = { data: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.initVector = cipherParams.iv.toString();
    if (cipherParams.salt) j.salt = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  /**
   * Расшифровка объекта зашифрованным в AES пришедшая с backend для валидации timestamp
   * @param jsonStr
   * @return {CryptoJS.encrypt}
   */
  parse(jsonStr) {
    let j = JSON.parse(jsonStr);
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.data)
    });
    if (j.initVector) cipherParams.iv = CryptoJS.enc.Hex.parse(j.initVector);
    if (j.salt) cipherParams.salt = CryptoJS.enc.Hex.parse(j.salt);
    return cipherParams;
  }
};

export default {
  data() {
    return {
      email: null,
      password: null,
      timeStamp: null,
      isReg: false,
      name: null,
      hash: null,
      showModalReturnPassword: false,
      showModalChangePassword: false
    };
  },
  mounted() {
    let self = this;
    if (this.$route.path === "/register") {
      this.switchReg();
    }
    this.$root.$on("hideModalReturnPassword", function (val) {
      self.showModalReturnPassword = val;
    });
    this.$root.$on("hideModalChangePassword", function (val) {
      self.showModalChangePassword = val;
    });
    if (this.$route.params.reset_password_token) {
      httpClient({
        url: `${host.authUrl}/User/isToken`,
        method: "POST",
        data: [
          {
            token: this.$route.params.reset_password_token
          }
        ]
      }).then((response) => {
        if (response.data.data.is_token) {
          this.showModalChangePassword = true;
        } else {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: "Вы перешли не по правильной ссылке!"
          });
        }
      });
    }
  },
  methods: {
    /**
     * Получение текущего unix-времени строкой
     * @return {string}
     */
    getNowTimeStamp() {
      let x = new Date();
      let y = x.toISOString();
      return Math.round(new Date(y).getTime() / 1000).toString();
    },

    /**
     * Создание hash'a в AES для отправление данных
     * @param key
     * @param msg
     * @return {string}
     */
    getHash(key, msg) {
      return CryptoJS.AES.encrypt(JSON.stringify(msg), key, { format: CryptoJSAesJson }).toString();
    },
    /**
     * Расшифровка хэша AES
     * @param key
     * @param msg
     * @return {any}
     */
    decrypt(key, msg) {
      return JSON.parse(
        CryptoJS.AES.decrypt(msg, key, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8)
      );
    },
    /**
     * Валидация unix-времени сервера и клиентского, должна быть точность до n-минут
     * @param data
     */
    checkTimeStamp(data) {
      let min = 5;
      let error = {
        message: `Разница во времени > ${min} минут. Сервер лежит.`,
        code: 500
      };
      let serverTime = this.decrypt(
        CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
        data.sessionKey.data
      );
      if (Number(serverTime) - this.timeStamp > min * 60) {
        this.errorAlert(error);
      } else {
        this.$store
          .dispatch("login", {
            data: data
          })
          .then(() => {
            this.successAlert("Поздравляю. Вы успешно авторизированы");
            this.$cookie.set(
              "mandate",
              JSON.stringify({
                email: this.email,
                data: this.hash
              })
            );
            if (this.$store.state.oldToPath) {
              this.$router.push(this.$store.state.oldToPath.fullPath);
            } else {
              this.$router.push("/");
            }
          });
      }
    },
    /**
     * Метод авторизации с получением клиентского Unix-времени и хеширование на основе пароля, вектора и соли
     * @return {checkTimeStamp}
     */
    auth() {
      if (!this.isInvalid) {
        this.timeStamp = this.getNowTimeStamp();
        this.hash = this.getHash(
          CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
          this.timeStamp
        );
        httpClient({
          url: `${host.authUrl}/User/login`,
          method: "POST",
          data: [
            {
              email: this.email.toLowerCase(),
              data: this.hash
            }
          ]
        })
          .then((response) => {
            return this.checkTimeStamp(
              response.data.data["original"] ? response.data.data.original[0] : response.data.data
            );
          })
          .catch((error) => this.errorAlert(error));
      }
    },
    /**
     * Выход из учеткой записи текущего сервиса
     * @return {Promise<logout>}
     */
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    /**
     * Смена флага для отображения формы регистрации/входа
     * @return {boolean}
     */
    switchReg() {
      this.isReg = !this.isReg;
    },
    /**
     * Создание аккаунт с шифрование пароля в SHA256 для защиты отправки
     */
    createAccount() {
      if (!this.isInvalidReg) {
        httpClient({
          url: `${host.authUrl}/User/register`,
          method: "POST",
          data: [
            this.name,
            this.email.toLowerCase(),
            CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString()
          ]
        })
          .then((response) => {
            if (response.data.data?.code && response.data.data.code === 400) {
              NotificationObserver.notification({
                type: "error",
                message: response.data.data.message
              });
              return false;
            }
            this.auth();
          })
          .catch((error) => this.errorAlert(error));
      }
    },
    returnOldPassword() {
      this.showModalReturnPassword = true;
    }
  },
  computed: {
    /**
     * Блокировки кнопки без заполнения данных (авторизация)
     * @return {boolean}
     */
    isInvalid() {
      return !(this.email && this.password);
    },
    /**
     * Блокировки кнопки без заполнения данных (регистрация)
     * @return {boolean}
     */
    isInvalidReg() {
      return !(this.email && this.password && this.name);
    },
    /**
     * Текст для футтера формы
     * @return {string}
     * @constructor
     */
    FooterText() {
      return this.isReg ? "Уже зарегистрированы на сайте?" : "Еще не зарегистрированы?";
    },
    /**
     * Текст для кнопка в футтере формы
     * @return {string}
     * @constructor
     */
    ButtonText() {
      return this.isReg ? "Авторизоваться" : "Зарегистрироваться";
    }
  }
};
