<template>
  <div class="textarea">
    <div class="textarea__label-wrap" v-if="label.length">
      <label class="textarea__label">{{ label }}</label>
      <i class="icon-info" v-if="showInfo" @click="showTooltip = !showTooltip"></i>
      <p class="textarea__tooltip" v-if="showTooltip">{{ tooltip }}</p>
    </div>
    <textarea
      class="textarea__control"
      :class="{ textarea__control_fill: value.length }"
      name="textarea"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :disabled="disabled"
      @input="changeTextarea"
      @paste="pastTextarea"
    />
  </div>
</template>

<script>
import ResizeMixin from "@/helpers/ResizeMixin";

export default {
  name: "Textarea",
  mixins: [ResizeMixin],
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "placeholder..."
    },
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: String,
      default: ""
    },
    minlength: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    unique: {
      type: String,
      default: ""
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sizeControl: {
      type: Number,
      default: 36
    }
  },
  components: [],
  data() {
    return {
      showTooltip: false
    };
  },
  mounted() {},
  methods: {
    pastTextarea(event) {
      this.$emit(`textarea:paste_${this.unique}`, event.clipboardData.files[0]);
    },
    changeTextarea(event) {
      this.$emit("input", event.target.value);
      this.size = this.sizeControl;
      this.mixin_autoResize_resize(event);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.textarea {
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: $text-default;
  }

  &__label-wrap {
    position: relative;
    display: flex;
    margin: 0 0 24px;
    width: max-content;
  }

  .icon-info {
    align-self: flex-start;
    margin-left: 8px;
    font-size: 14px;
    line-height: 12px;
    color: $icon-subdued;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }

    &:active {
      color: $icon-pressed;
    }
  }

  &__control {
    width: 600px;
    height: 120px;
    padding: 8px 12px;
    background: #fafafe;
    border: 2px solid #fafafe;
    border-radius: 6px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $text-default;
    outline: none;
    resize: none;
    //box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);

    &:hover {
      background-color: #eae8f0;
    }

    &:focus {
      border: 2px solid #6a67ce;
    }

    &::placeholder {
      color: $text-disabled;
    }

    &_fill {
      border: 1px solid #eae8f0;
      background: #ffffff;
    }
  }

  &__tooltip {
    display: block;
    position: absolute;
    max-width: 530px;
    width: max-content;
    right: -120%;
    top: -30px;
    z-index: 2;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
  }
}
</style>
