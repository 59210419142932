import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import FlowchartService from "@/modules/TaskManager/Model/Flowchart/FlowchartService";
import { IStatus } from "@/modules/TaskManager/Model/Kanban/interface/interface";
import NotificationObserver from "@/helpers/UserNotification";

const name = "FlowchartModule";

export enum FlowchartMutationTypes {
  SET_COORDINATES = "SET_COORDINATES",
  SET_ORIGIN_STATUS_LIST = "SET_ORIGIN_STATUS_LIST",
  SET_STATUS_LIST = "SET_STATUS_LIST",
  ADD_STATUS = "ADD_STATUS",
  DELETE_STATUS = "DELETE_STATUS",
  EDIT_STATUS = "EDIT_STATUS"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

type nodeType = {
  category?: string;
  id: string | number;
  name: string;
  loc: string;
  isForAll?: boolean;
};

type newStatusType = {
  id: number;
  name: string;
};

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class FlowchartModule extends VuexModule {
  flowchartCoordinates: string = "";
  originStatusList: Array<IStatus> = [];
  statusList: Array<IStatus> = [];

  @Mutation
  [FlowchartMutationTypes.SET_COORDINATES](payload: string) {
    this.flowchartCoordinates = payload;
  }
  @Mutation
  [FlowchartMutationTypes.SET_ORIGIN_STATUS_LIST](payload: Array<IStatus>) {
    this.originStatusList = payload;
  }
  @Mutation
  [FlowchartMutationTypes.SET_STATUS_LIST](payload: Array<IStatus>) {
    this.statusList = payload;
  }
  @Mutation
  [FlowchartMutationTypes.ADD_STATUS](status: IStatus) {
    this.statusList.push(status);
  }
  @Mutation
  [FlowchartMutationTypes.DELETE_STATUS](id: number) {
    this.statusList = this.statusList.filter((item) => item.id !== id);
  }
  @Mutation
  [FlowchartMutationTypes.EDIT_STATUS](status: IStatus) {
    const index = this.statusList.findIndex((item) => item.id === status.id);
    if (index !== -1) {
      for (const key in status) {
        this.statusList[index][key] = status[key];
      }
    }
  }
  @Action
  async getFlowchartAction(params: { flow_id; project_id }) {
    FlowchartService.getStatuses(params.project_id).then((statusList) => {
      this[FlowchartMutationTypes.SET_ORIGIN_STATUS_LIST](JSON.parse(JSON.stringify(statusList)));
      this[FlowchartMutationTypes.SET_STATUS_LIST](JSON.parse(JSON.stringify(statusList)));
    });
    const response = await FlowchartService.getCoordinates(params.flow_id);
    this[FlowchartMutationTypes.SET_COORDINATES](response.coordinates);
  }
  @Action
  async createStatusAction(newStatus: IStatus) {
    const { id } = await FlowchartService.createStatus(newStatus);
    return id;
  }

  @Action
  deleteStatusesAction(statusToDeleteList: Array<newStatusType>) {
    statusToDeleteList.forEach((status) => {
      FlowchartService.deleteStatus(status.id);
    });
  }

  @Action
  async saveFlowchartAction(params: {
    project_id;
    flow_id;
    coordinates;
    change_task_statuses;
    deletedStatusList;
    editedStatusList;
  }) {
    const paramsUpdateCoordinates = {
      flow_id: params.flow_id,
      coordinates: params.coordinates,
      change_task_statuses: params.change_task_statuses.length
        ? params.change_task_statuses
        : undefined
    };
    const requestUpdateStatus: Array<any> = [];
    params.editedStatusList.forEach((node) => {
      if (node.is_end === false) {
        delete node.is_end;
      }
      if (node.is_start === false) {
        delete node.is_start;
      }
      requestUpdateStatus.push(FlowchartService.updateStatus(node));
    });
    await Promise.all(requestUpdateStatus);

    const requestDeleteStatus: Array<any> = [];
    params.deletedStatusList.forEach((node) => {
      requestDeleteStatus.push(FlowchartService.deleteStatus(node.id));
    });
    await Promise.all(requestDeleteStatus);

    await FlowchartService.updateCoordinates(paramsUpdateCoordinates);
    this[FlowchartMutationTypes.SET_COORDINATES](params.coordinates);
    NotificationObserver.notification({
      type: "success",
      message: "Бизнес-процесс успешно изменен!"
    });
  }
}

export default getModule(FlowchartModule);
