<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'full_name'">
      <User :avatar="row.avatar" :name="row.full_name" :id="row.id" :showTooltip="false" />
    </div>
    <div class="table__content" v-if="cell.key === 'education_institution'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.education_institution.name }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
    <div class="table__content" v-if="cell.key === 'education_type'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.education_type.name }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
    <div class="table__content" v-if="cell.key === 'education_qualification'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.education_qualification.name }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
    <div class="table__content" v-if="cell.key === 'education_speciality'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.education_speciality.name }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
    <div class="table__content" v-if="cell.key === 'from_year'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.from_year }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
    <div class="table__content" v-if="cell.key === 'to_year'">
      <p
        class="table__text"
        v-for="education in row.educations"
        :key="education.id"
        :style="{ height: setHeightString }"
      >
        {{ education.to_year }}
      </p>
      <p class="table__text table__text_empty" v-if="!row.educations.length">-</p>
    </div>
  </div>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";
export default {
  name: "TableEducationCellContent",
  mixins: [formatDateAndTime],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setHeightString() {
      return this.row.educations.length ? 100 / this.row.educations.length + "%" : "auto";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  .table__wrap {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .table__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    /deep/ {
      .user__name {
        max-width: 220px;
      }
    }
  }

  .table__text {
    margin: 0 0 16px;
  }
}
</style>
