

























import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";

@Component({
  components: { ButtonIcon }
})
export default class CalendarControl extends Vue {
  @Prop({
    default: new Date(),
    type: String
  })
  value!: string;
  @Prop({
    default: false,
    type: Boolean
  })
  isShowDouble!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowDay!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowMonth!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowYear!: boolean;

  handleDoubleLeft() {
    this.$emit("handleDoubleLeft");
  }

  handleLeft() {
    this.$emit("handleLeft");
  }

  handleRight() {
    this.$emit("handleRight");
  }

  handleDoubleRight() {
    this.$emit("handleDoubleRight");
  }

  handleDay() {
    this.$emit("pickerType", "day");
  }

  handleMonth() {
    this.$emit("pickerType", "month");
  }

  handleYear() {
    this.$emit("pickerType", "year");
  }

  get date() {
    return {
      day: moment(this.value).format("DD"),
      month: moment(this.value).format("MMMM"),
      year: moment(this.value).format("YYYY")
    };
  }
}
