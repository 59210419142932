import { Action, Module, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import AuthService, { loginData } from "@/store/Auth/AuthService";
import httpClientV2 from "@/api/client/httpClientV2";

const name = "Auth";

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class Auth extends VuexModule {
  @Action
  async login(loginData: loginData) {
    const token = await AuthService.login(loginData);
    localStorage.setItem("access_token", `${token}`);
    httpClientV2.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  @Action
  async refresh() {
    try {
      const token = await AuthService.refresh();
      localStorage.setItem("access_token", `${token}`);
      return `${token}`;
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  logout() {
    store.commit("Notification/toggleModal", false);
    AuthService.logout()
      .then((resp) => {
        localStorage.removeItem("access_token");
        sessionStorage.clear();
        localStorage.clear();
        delete httpClientV2.defaults.headers["Authorization"];
        store.dispatch("logout");
        if (store.hasModule("TimeTracker")) {
          const time = require("@/modules/TimeTracker/store").default;
          time.SET_DEFAULT_DATA();
        }
      })
      .catch((e) => {
        localStorage.removeItem("access_token");
        sessionStorage.clear();
        localStorage.clear();
        delete httpClientV2.defaults.headers["Authorization"];
        store.dispatch("logout");
        if (store.hasModule("TimeTracker")) {
          const time = require("@/modules/TimeTracker/store").default;
          time.SET_DEFAULT_DATA();
        }
      });
  }
}

export default getModule(Auth);
