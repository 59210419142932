































import { Component, Prop, Vue } from "vue-property-decorator";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button";
import { IBaseUser } from "@/store/Users/interfaces/interfaces";
import PlanningScheduleModule, {
  PlanningScheduleMutationTypes
} from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import _ from "lodash";
import copyValue from "@/helpers/copyText";
import { domainV2 } from "@/globalVariables";
import { menuList } from "@/router/moduleRouters";

@Component({ components: { Input, Select, Button }, mixins: [copyValue] })
export default class ModalGiveAccess extends Vue {
  @Prop({
    type: Number,
    default: null
  })
  planId!: null;

  link: string = "";
  selectedUsers: Array<IBaseUser> | any = [];

  mounted() {
    this.link = window.location.href.includes("id")
      ? window.location.href
      : domainV2.substring(0, domainV2.length - 2) + "TaskManager/Plan/Graph?id=" + this.planId;
    this.resetAcceptUsers();
    this.getAccessUsers();
  }

  cancel() {
    this.$emit("cancel");
  }

  accept() {
    let addArr = this.selectedUsers.filter(
      (e) => this.accessUsers && this.accessUsers.findIndex((i) => i == e.id) === -1
    );
    addArr = [...new Set(addArr.map((item) => item.id))];
    const arrId = this.selectedUsers.map((item) => item.id);
    let deleteArr = _.difference(this.accessUsers, arrId);
    this.$emit("accept", {
      plan_id: this.planId,
      add: addArr.length ? addArr : undefined,
      delete: deleteArr.length ? deleteArr : undefined
    });
  }

  resetAcceptUsers() {
    PlanningScheduleModule[PlanningScheduleMutationTypes.SET_ACCESS_USERS]({
      count: 0,
      list: [],
      page: 1,
      per_page: 50
    });
  }

  getAccessUsers() {
    PlanningScheduleModule.getAccessUsersAction({
      plan_id: this.planId,
      per_page: 50,
      page: 1
    }).then(() => {
      if (this.accessUsers.length) {
        this.selectedUsers = this.accessUsers.map((id: any) => {
          return this.getCurrentMember(id);
        });
      }
    });
  }

  getCurrentMember(id) {
    return this.users.length ? this.users.find((item) => item.id === id) : null;
  }

  get users() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "id", "avatar", "is_active"])
      .filter((item) => item.is_active);
  }

  get accessUsers() {
    return PlanningScheduleModule.accessUsers
      ? PlanningScheduleModule.accessUsers?.list.map((item: any) => item.user_id)
      : [];
  }
}
