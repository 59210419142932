<template>
  <div class="confirmations">
    <img class="confirmations__avatar" :src="returnSrcFile(user.avatar)" alt="" />
    <p class="confirmations__name">{{ user.full_name }}</p>
    <IcoMoonWrap v-if="checkVacation" :name="'calendar-status-yes'" :pathCount="2" :fontSize="14" />
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";

export default {
  name: "ConfirmationsUsers",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    checkVacation: {
      type: Boolean,
      default: null
    }
  },
  components: { IcoMoonWrap },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.confirmations {
  position: relative;
  width: 36px;
  height: 36px;

  &:nth-child(3) {
    position: relative;
    left: -8px;
  }

  &:nth-child(4) {
    position: relative;
    left: -16px;
  }

  &:hover {
    .confirmations__name {
      display: block;
    }
  }

  &__avatar {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 24px;
  }

  &__name {
    display: none;
    position: absolute;
    top: -36px;
    left: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
  }

  i {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
</style>
