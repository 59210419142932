<template>
  <div class="modal-view-members">
    <div class="modal-view-members__container">
      <div class="modal-view-members__header">
        <h2 class="modal-view-members__title">Добавить участников</h2>
        <i class="icon-cross" @click="hideModal"></i>
      </div>
      <Widget
        :class="{ margin_none: !memberNewUsers.length }"
        type="select-new"
        :entity="'User'"
        :actionEntity="'getItems'"
        :itemName="'full_name'"
        :keyValue="'ModalAddMemberUsersCard'"
        :placeholder="'Выберите сотрудников'"
        :multiple="true"
        :hiddenItems="memberUsers"
      />
      <ul class="modal-view-members__list" :class="{ padding_none: !memberNewUsers.length }">
        <li class="modal-view-members__item" v-for="(item, index) in memberNewUsers" :key="index">
          <div class="modal-view-members__wrap">
            <img
              class="modal-view-members__photo"
              :src="returnSrcFile(item.avatar)"
              width="36"
              height="36"
              alt=" "
            />
          </div>
          <div class="modal-view-members__description">
            <p class="modal-view-members__full-name">{{ item.full_name }}</p>
          </div>
        </li>
      </ul>
      <button
        class="modal-view-members__add"
        @click="editCard(true)"
        type="button"
        :class="{ disabled: isDisabledMemberUsers }"
        :disabled="isDisabledMemberUsers"
      >
        Добавить участника
      </button>
    </div>
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
const Widget = () => import("@/components/Widgets/Widget");

export default {
  name: "AddMembersCard",
  mixins: [],
  props: ["currentCardMembers"],
  components: {
    Widget
  },
  data() {
    return {
      memberUsers: [],
      membersEdit: [],
      memberNewUsers: [],
      memberId: [],
      isDisabledMemberUsers: true
    };
  },
  beforeDestroy() {
    this.$root.$off(`changeSelectValue:ModalAddMemberUsersCard`);
  },
  mounted() {
    this.getMembersUsers();
    this.$root.$on(`changeSelectValue:ModalAddMemberUsersCard`, (val) => {
      this.memberNewUsers = val.map((item) => item);
      this.memberId = val.map((item) => item.id);
      this.toggleDisabledButton();
    });
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    toggleDisabledButton() {
      this.isDisabledMemberUsers = false;
    },
    editCard(hide) {
      let newArrUser = this.memberUsers.map((item) => item.id).concat(this.memberId);
      httpClient({
        url: `/OrgStructureItem/actionUpdate/${this.currentCardMembers.id}`,
        method: "PUT",
        data: [
          {
            responsible_user_id: this.currentCardMembers.responsible_user_id,
            assistant_user_id: this.currentCardMembers.assistant_user_id,
            users: this.memberNewUsers.length ? newArrUser : undefined
          }
        ]
      })
        .then(() => {
          if (hide) {
            this.hideModal();
          }
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Участники успешно добавлены!`
          });
          //@TODO Исправить счетчик участников группы, синхронизировать с бэкендом
          this.currentCardMembers.count_users = newArrUser.length;
          // this.$root.$emit("updateStructureChart");
          // this.getMembersUsers();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    getMembersUsers() {
      this.hoverIndex = null;
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _count: 300,
          org_structure_items: JSON.stringify([
            { "org_structure_items.id": this.currentCardMembers.id }
          ]),
          _with: JSON.stringify(["org_structure_items", "Roles"])
        }
      })
        .then((response) => {
          this.memberUsers = response.data.data.items;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    hideModal() {
      this.$emit("hideModalAddViewCardMembers");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";

::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
  overflow-x: auto;
  width: 5px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  background: $disabled-gray;
  border-radius: 10px;
  width: 5px;
  padding-right: 10px;
}

::-webkit-scrollbar-track {
  margin: 0;
}

.modal-view-members {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;

  background: rgba(0, 0, 0, 0.1);

  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    max-width: 360px;
    width: 100%;
    max-height: 80vh;
    padding: 20px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    border: 1px solid $color-contour;
    border-radius: 10px;
  }

  &__header {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-cross {
      color: $color-text-secondary;
      font-size: 20px;
      cursor: pointer;
      transition: 0.2s ease;

      &:hover {
        color: $color-button-hover;
      }

      &:active {
        color: $color-button-click;
      }
    }
  }

  &__title {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-accent;
  }

  & /deep/ .select-bg {
    width: 100%;
    margin-bottom: 20px;
  }

  & .margin_none {
    margin-bottom: 0;
  }

  & .padding_none {
    padding: 0;
  }

  &__list {
    width: 100%;
    max-height: 325px;
    overflow-y: scroll;
    list-style: none;
    padding: 0 0 10px;
    margin: 0 0 20px;
    border-bottom: 1px solid $color-contour;
    @media screen and (max-height: 480px) {
      max-height: 70px;
    }
  }

  &__item {
    display: flex;
    cursor: pointer;
    padding-right: 10px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover .modal-view-members__full-name,
    &:hover .modal-view-members__position {
      color: $color-charts-purple-1;
    }

    &:hover .modal-view-members__delete {
      display: block;
    }
  }

  &__wrap {
    position: relative;
    width: 40px;
    height: 34px;
    margin-right: 4px;
  }

  &__leader {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $color-charts-purple-1;
  }

  &__photo {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__full-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-accent;
    max-width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__position {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-primary-3;
    max-width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__add {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    height: 32px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-interactive;

    outline: none;
    border-radius: 4px;
    transition: all 0.2s ease;
    border: 1px solid $color-interactive;
    background-color: transparent;

    &:hover {
      background-color: $color-button-hover;
      color: $main-white;
    }

    &:active {
      background-color: $color-button-click;
      color: $main-white;
    }
  }

  .disabled,
  .disabled:hover {
    color: $color-disabled;
    background-color: transparent;
    border: 1px solid $color-disabled;
  }
}
</style>
