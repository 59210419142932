<template>
  <div class="modal-background">
    <div class="modal-content">
      <div class="modal-content__header">
        <div class="label">{{ dataModal.title }}</div>
        <i @click="hideModalBugs" class="icon-cross"></i>
      </div>
      <ul class="modal-content__feedback-type-list" v-if="!isFromLayoutAuth">
        <li
          class="modal-content__feedback-type"
          v-for="item in feedbackTypes"
          @click="handleType(item)"
        >
          <EventType
            :event_type="item.feedbackType"
            :isActive="currentFeedbackType === item.feedbackType"
            :classes="item.icon"
          />
        </li>
      </ul>
      <div class="modal-content__form">
        <label>{{ dataModal.placeholder }}</label>
        <TextInput
          :isTextarea="true"
          :isEmoji="false"
          :type="'email'"
          :classList="'font18'"
          :placeholder="`Введите текст`"
          @changeText="setTextValue"
          @pasteFile="pasteFile"
        />
        <FileUploaderBugs
          label="Прикрепить файл"
          v-bind="{
            multiple: true,
            drop: true,
            dropDirectory: true,
            fileDrop: true,
            lengthText: 7,
            showOnlyFiles: true
          }"
          @changeFilesFromUploader="changeFilesFromUploader"
        />
      </div>
      <div class="modal-content__footer">
        <ModalButton :type="'cancel'" :title="'Отменить'" @handleButton="hideModalBugs" />
        <ModalButton :title="'Отправить'" @handleButton="createChunksPoolFiles" />
      </div>
      <div class="modal-content__loader" v-if="loading">
        <Widget type="loader-new" />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/Widgets/Inputs/TextInput";
import EventType from "@/components/FeedbackPanel/EventType";
const FileUploaderBugs = () => import("@/components/FeedbackPanel/FileUploaderBugs");
import { domain } from "@/globalVariables";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import { mapActions, mapState, mapMutations } from "vuex";
import ModalButton from "@/components/Widgets/Button/ModalButton";

const Widget = () => import("@/components/Widgets/Widget");

export default {
  name: "ModalAddBugsAndIdea",
  mixins: [uploadFilesMixin],
  props: {
    dataModal: Object
  },
  components: { TextInput, FileUploaderBugs, Widget, EventType, ModalButton },
  data() {
    return {
      text: "",
      file: {},
      loading: false,
      currentFeedbackType: "Баг",
      feedbackTypes: [
        { feedbackType: "Баг", icon: "icon-bug" },
        { feedbackType: "Идея", icon: "icon-idea" }
      ]
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    ...mapActions("bugsAndIdeasModule", ["sendFeedback", "closeModal"]),
    ...mapMutations("bugsAndIdeasModule", ["CHANGE_IS_SHOW_LOADER_MODAL"]),
    changeFilesFromUploader(files) {
      this.fileUploader = files;
    },
    setTextValue(val) {
      this.text = val.value;
    },
    pasteFile(val) {
      this.file = {
        file: val,
        name: val.name,
        size: val.size,
        type: this.currentFeedbackType,
        fileObject: true,
        postAction: "/upload/post"
      };
      this.$root.$emit("pushFileToFilesBugs", this.file);
    },
    checkValidationData() {
      if (!this.text.trim().length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Поле 'Описание' обязательно для заполнения!`
        });
        return false;
      }
      return true;
    },
    cutterText(text, length) {
      if (text.length > length) {
        return text.substr(0, length) + "...";
      } else return text;
    },
    hideModalBugs() {
      this.text = "";
      this.fileUploader = [];
      this.fileUploadingId = [];
      this.chunksArr = [];
      this.pullName = "";
      this.closeModal();
    },
    sendBugsOrIdeasInfo() {
      const payload = {
        type: this.currentFeedbackType,
        description: this.text,
        attachment_files: this.fileUploadingId,
        from_request: `${domain}${this.$route.path}`
      };
      this.sendFeedback(payload)
        .then(() => {
          this.loading = false;
          this.hideModalBugs();
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Благодарим вас за помощь! Ваша проблема/пожелание будет обязательно рассмотрена!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      if (!this.fileUploader.length) {
        this.sendBugsOrIdeasInfo();
        return null;
      }
      this.createPull(this.fileUploader);
    },
    filesUploaded() {
      this.sendBugsOrIdeasInfo();
    },
    handleType(item) {
      this.currentFeedbackType = item.feedbackType;
    }
  },
  computed: {
    isFromLayoutAuth() {
      return this.$route.path === "/login" ? true : false;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 716px;
    border-radius: 24px;
    user-select: none;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);

    @media screen and (max-width: $md) {
      width: 90%;
    }
    @media (max-height: $xs) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      overflow: auto;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 30px 30px 23px;

      @media (max-width: $xxxl) {
        padding: 30px 30px 8px;
      }

      @media screen and (max-width: $lg) and (orientation: landscape) {
        padding: 20px 20px 15px;
      }

      @media (max-width: $sm) {
        padding: 16px;
      }

      .label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-disabled;
      }

      i {
        font-size: 20px;
        cursor: pointer;
        color: $system-grey-five;
        &:hover {
          color: $icon-hovered;
        }
      }
    }
    &__feedback-type-list {
      list-style: none;
      display: flex;
      width: 145px;
      justify-content: space-between;
      margin-left: 25px;
      padding: 0;

      @media screen and (max-width: $sm) {
        margin: 8px 0 8px 24px;
      }

      /deep/ {
        .event-type {
          cursor: pointer;
          &__icon-wrap {
            width: 16px;
            height: 16px;
          }
        }
        .feedback-bug {
          background-color: $system-green-second;
          opacity: 0.4;
          i {
            color: $system-green-second;
          }
          &_active,
          &:hover {
            opacity: 1;
          }
        }
        .feedback-idea {
          background-color: $system-orange-second;
          opacity: 0.4;
          i {
            color: $system-orange-second;
          }
          &_active,
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 38px 30px;

      @media (max-width: $xxxl) {
        padding: 20px 30px;
      }

      @media screen and (max-width: $lg) and (orientation: landscape) {
        padding: 20px 20px;
      }

      @media (max-width: $sm) {
        padding: 16px;
      }

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-disabled;
        margin-bottom: 24px;
      }

      /deep/ {
        .input-wrap {
          @media screen and (max-width: $sm) {
            margin-bottom: 16px;
          }

          textarea::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            color: $text-disabled;
            @media screen and (max-width: $sm) {
              font-size: 14px;
            }
          }
          textarea {
            max-height: 100px;
            @media screen and (max-width: $sm) {
              max-height: 50px;
              font-size: 14px;
            }
          }
        }
        .background-uploader {
          .files-background {
            @media screen and (max-width: 850px) and (orientation: landscape) {
              flex-direction: row;
              flex-wrap: nowrap;
            }
            .file-uploader {
              @media screen and (max-width: 850px) and (orientation: landscape) {
                flex-shrink: 0;
              }
            }
            .files-bg {
              @media screen and (max-width: 850px) and (orientation: landscape) {
                margin: 7px 0 0 10px;
                flex-wrap: nowrap;
                overflow-x: auto;
                .file-info {
                  flex-shrink: 0;
                }
              }
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 30px;
      border-top: 1px solid #f2f2f7;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 15px;
      }

      @media (max-width: $sm) {
        padding: 16px;
      }
    }
    &__loader {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 50;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
