<template>
  <section class="kanban-main">
    <ul class="kanban-main__stage-list" v-if="!currentKanbanStage">
      <KanbanStage :stage="defaultStage" @chooseStage="chooseStage" :project="currentProject" />
      <KanbanStage
        v-for="stage in stages.list"
        :stage="stage"
        :project="currentProject"
        :key="stage.id"
        @chooseStage="chooseStage"
      />
    </ul>
    <div class="kanban-main__chosen-stage" v-if="currentKanbanStage">
      <KanbanStage
        :stage="currentKanbanStage"
        :isChosen="true"
        :project="currentProject"
        @rollUp="returnToList"
        @chooseExecutor="chooseExecutor"
      />
    </div>
    <div class="kanban-main__table" v-if="currentKanbanStage">
      <KanbanTable
        :stage="currentKanbanStage"
        :columns="columns"
        :executor="executor"
        :project="currentProject"
      />
    </div>
  </section>
</template>

<script>
import KanbanStage from "@/modules/TaskManager/components/Kanban/KanbanStage";
import KanbanTable from "@/modules/TaskManager/components/Kanban/KanbanTable";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
export default {
  name: "KanbanMain",
  mixins: [],
  props: {
    stages: {
      type: Object,
      default: () => {
        return {};
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { KanbanStage, KanbanTable },
  data() {
    return {
      currentKanbanStage: null,
      executor: null,
      defaultStage: {
        id: null,
        name: "Вне этапа"
      }
    };
  },
  mounted() {},
  methods: {
    chooseStage(val) {
      this.currentKanbanStage = val;
      KanbanModule.getTasksByStageAction({ stage_id: val.id, project_id: this.currentProject.id });
    },
    returnToList() {
      this.currentKanbanStage = null;
      this.executor = null;
    },
    chooseExecutor(val) {
      this.executor = val;
    }
  },
  computed: {
    currentProject() {
      return ProjectModule.currentProject.projectFull;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
.kanban-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__stage-list {
    overflow: auto;
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__chosen-stage {
    flex: 1 1 10%;
  }
  &__table {
    flex: 1 1 90%;
    overflow: hidden;
  }
}
</style>
