













































import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/modules/UiKit/components/buttons/Button";
import User from "@/components/Widgets/User/User";
import StatusCritical from "@/modules/UiKit/components/statuses/StatusCritical.vue";
import StatusProblem from "@/modules/UiKit/components/statuses/StatusProblem.vue";

@Component({
  components: { StatusProblem, StatusCritical, Button, User, ProgressBar }
})
export default class IspCard extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  card;
  openInfoProblem() {
    this.$store.dispatch("IspModule/setCurrentInfoProblem", { data: this.card, show: true });
    this.$store.dispatch("IspModule/getFullDataOnProblem", this.card);
  }
}
