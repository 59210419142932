




export default {
  name: "ProjectTag",
  props: {
    tag: {
      type: String,
      default: "Jira"
    }
  }
};
