import * as types from "@/modules/Tables/store/mutations/mutationsTypes";

const mutations = {
  [types.SET_TABLE_DATA](state, payload) {
    state.tableData.push(...payload);
  },
  [types.SET_TABLE_PARAMS](state, payload) {
    state.tableParams = payload;
  },
  [types.CLEAR_TABLE_DATA](state) {
    state.tableData = [];
    state.tableDataAbsence = [];
    state.tableDataTime = [];
    state.tableDataBlackSphereStatistics = [];
    state.tableDataActivity = [];
  },
  [types.RESET_PAGINATION](state) {
    state.fromPag = 1;
  },
  [types.SET_SORT](state, payload) {
    state.valueSort = state.valueSortList.find((item) => item.value === payload);
    state.valueSort.isAsc = !state.valueSort.isAsc;
    state.tableParams.params._order = JSON.stringify({
      [state.valueSort.value]: state.valueSort.isAsc ? "asc" : "desc"
    });
  },
  [types.SET_SORT_USERS](state, payload) {
    state.valueSortUser = state.valueSortList.find((item) => item.value === payload);
    state.valueSortUser.isAsc = !state.valueSortUser.isAsc;
    state.tableParams.params._order = JSON.stringify({
      [state.valueSortUser.value]: state.valueSortUser.isAsc ? "asc" : "desc"
    });
  },
  [types.SET_SORT_BLACK_SPHERE_STATISTICS](state, payload) {
    state.valueSort = state.valueSortList.find((item) => item.value === payload);
    state.valueSort.isAsc = !state.valueSort.isAsc;
    state.tableParams.params._order = JSON.stringify({
      percent: state.valueSortList.find((item) => item.value === "percent").isAsc ? "asc" : "desc",
      count_marks: state.valueSortList.find((item) => item.value === "count_marks").isAsc
        ? "asc"
        : "desc",
      user_id: "asc"
    });
  },
  [types.RESET_SORT](state) {
    state.valueSort = { isAsc: true, value: "user_id" };
    state.valueSortUser = { isAsc: true, value: "full_name" };
  },
  // Time
  [types.SET_TIME_STATISTICS](state, payload) {
    state.timeStatistics = Object.entries(payload);
  },
  [types.ADD_DATES_COLUMN_IN_TABLE](state, payload) {
    let weekDays = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    let datesArr = Object.values(payload);

    state.datesColumn = datesArr.map((item, index) => {
      let day = new Date(item.date).getDay();
      let date = item.date.split("-").reverse().join(".").slice(0, 5);
      return {
        name: weekDays[day] + " " + date,
        sort: false,
        tracker: `tracked_day_${index + 1}`,
        key: `day_${index + 1}`,
        absence: `absence_${index + 1}`
      };
    });
  },
  [types.ADD_TIMES_RELATION](state, payload) {
    let items = payload.items;
    for (let i in items) {
      if (items[i].user_id) {
        for (let k in payload.relations.User) {
          if (items[i].user_id === parseInt(k)) {
            items[i].user_id = payload.relations.User[k];
          }
        }
      }
      if (items[i].roles.length) {
        for (let k in payload.relations.Roles) {
          if (items[i].roles[0] === parseInt(k)) {
            items[i].roles[0] = payload.relations.Roles[k];
          }
        }
      }
      if (items[i].department.length) {
        for (let k in payload.relations.Department) {
          if (items[i].department[0] === parseInt(k)) {
            items[i].department[0] = payload.relations.Department[k];
          }
        }
      }
    }
    state.tableDataTime.push(...items);
  },

  //Tests
  [types.SET_TESTS_LIST](state, payload) {
    state.tableDataTests = payload;
  },

  // Absence
  [types.ADD_ABSENCE_RELATION](state, payload) {
    let items = payload.items;
    for (let i in items) {
      if (items[i].user_id) {
        for (let k in payload.relations.User) {
          if (items[i].user_id === parseInt(k)) {
            items[i].user_id = payload.relations.User[k];
          }
        }
      }
      if (items[i].absence_type_id) {
        for (let k in payload.relations.AbsenceType) {
          if (items[i].absence_type_id === parseInt(k)) {
            items[i].absence_type_id = payload.relations.AbsenceType[k];
          }
        }
      }
    }
    state.tableDataAbsence.push(...items);
  },

  // Activity
  [types.ADD_USER_RELATION](state, payload) {
    let items = payload.items;
    for (let i in items) {
      if (items[i].user_id) {
        for (let k in payload.relations.User) {
          if (items[i].user_id === parseInt(k)) {
            items[i].user_id = payload.relations.User[k];
          }
        }
      }
    }
    state.tableDataActivity.push(...items);
  },

  //Users
  SET_IS_FILTER_ACTIVE_USERS(state, payload) {
    state.isFilterActiveUsers = payload;
  },
  [types.TOGGLE_SHOW_MODAL_CREATE_USER](state, payload) {
    state.showModalCreateUser = payload;
  },

  // Education
  SET_IS_FILTER_ACTIVE_EDUCATION(state, payload) {
    state.isActiveEducation = payload;
  }
};

export default mutations;
