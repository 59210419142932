<template>
  <div class="absence-card">
    <div class="absence-card__header">
      <div class="absence-card__icon">
        <i :class="`icon-${setIcon}`"></i>
      </div>
      <div class="absence-card__wrap">
        <h3 class="absence-card__title">{{ type }}</h3>
        <p class="absence-card__descr">
          Доступно {{ setLeftDays }}
          {{ setLabelCountDays(setLeftDays, "день", "дня", "дней") }}
        </p>
      </div>
    </div>
    <ul class="absence-card__list">
      <AbsenceItem
        v-for="item in setAbsenceListSort"
        :key="item.id"
        :item="item"
        :isAvailableForEditing="isAvailableForEditing"
      />
      <li class="absence-card__item absence-card__item_empty" v-if="!setAbsenceListSort.length">
        У вас нет запланированных отпусков :(
      </li>
    </ul>
  </div>
</template>

<script>
import { format } from "date-fns";
import moment from "moment";
import AbsenceSetting from "@/components/GraphVacation/Absence/AbsenceSetting";
import AbsenceItem from "@/components/GraphVacation/Absence/AbsenceItem";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "absenceCard",
  mixins: [vacationMixin],
  props: ["type", "absenceList"],
  components: { AbsenceItem, AbsenceSetting },
  data() {
    return {
      modalShow: false,
      isAvailableForEditing: true,
      year: moment(new Date()).year().toString()
    };
  },
  mounted() {
    this.checkCloseEditVacation();
  },
  beforeDestroy() {},
  methods: {
    checkCloseEditVacation() {
      httpClient({
        method: "GET",
        url: `/VacationSchedule/actionGetItem`,
        params: {
          year: this.year
        }
      }).then((response) => {
        if (response.data.data.data.length) {
          this.isAvailableForEditing = response.data.data.data[0].is_available_for_editing;
        }
      });
    },
    absenceListSort(val) {
      return val.sort((a, b) => moment(a.date_from).diff(moment(b.date_from)));
    }
  },
  computed: {
    setIcon() {
      switch (this.type) {
        case "Отпуск":
          return "sunset";
        default:
          return "";
      }
    },
    getToday() {
      return format(new Date(), "Y-MM-dd");
    },
    setLeftDays() {
      if (this.absenceList.length) {
        return this.absenceList[0].vacationStock[0].left_days;
      }
    },
    setAbsenceListSort() {
      if (this.absenceList.length) {
        return this.absenceListSort(this.absenceList[0].vacationOrders);
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/components/GraphVacation/GraphVacation.mixin.scss";
@import "~@/assets/base/color/colors.scss";

.absence-card {
  @include cardStyle();
  left: unset;
  right: 0;
  &__icon {
    border: 1px solid #a177ff;
    background: #f1effa;

    i {
      color: $action-primary;
    }
  }

  .absence-card__item_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    color: $text-disabled;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
