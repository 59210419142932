import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";
import {
  IChangeInvitationParams,
  IEventData,
  IGetEventForProfileParams,
  IGetEventParams,
  IGetOneEventParams,
  IGroup,
  typeEventRemove
} from "@/aopV2/modules/Calendar/service/CalendarInterface";

class calendarService extends Service {
  //Event Type Category
  createEventTypeCategory(data: { name: string }): Response<any> {
    return httpClientV2.post<any>("calendar/event_type_categories/create", data).then((resp) => {
      return resp.data;
    });
  }

  getEventTypeCategories(): Response<any> {
    return httpClientV2.get<any>("calendar/event_type_categories/get_many").then((resp) => {
      return resp.data;
    });
  }

  updateEventTypeCategories(data: { name: string }): Response<any> {
    return httpClientV2.patch<any>("calendar/event_type_categories/update", data).then((resp) => {
      return resp.data;
    });
  }

  deleteEventTypeCategories(data: { id: number }): Response<any> {
    return httpClientV2.patch<any>("calendar/event_type_categories/delete", data).then((resp) => {
      return resp.data;
    });
  }

  //Event Type
  createEventType(data: {
    name: string;
    category_id: number;
    color_number: number;
    is_default: boolean;
  }): Response<any> {
    return httpClientV2.post<any>("calendar/event_types/create", data).then((resp) => {
      return resp.data;
    });
  }

  getAllEventType(): Response<any> {
    return httpClientV2.get<any>("calendar/event_types/get_many").then((resp) => {
      return resp.data;
    });
  }

  // updateEventType(data: {
  //   id: number;
  //   name?: string;
  //   color_number?: number;
  //   is_default?: boolean;
  // }): Response<any> {
  //   return httpClientV2.patch<any>("calendar/event_types/update", data).then((resp) => {
  //     return resp.data;
  //   });
  // }

  deleteEventType(data: { id: number }): Response<any> {
    return httpClientV2.patch<any>("calendar/event_types/delete", data).then((resp) => {
      return resp.data;
    });
  }

  // Group
  createGroup(data: { name: string; users?: Array<number> }): Response<any> {
    return httpClientV2.post<any>("calendar/groups/create", data).then((resp) => {
      return resp.data;
    });
  }

  getMyGroups(): Response<any> {
    return httpClientV2.get<any>("calendar/groups/get_my").then((resp) => {
      return resp.data;
    });
  }

  getGroup(params: { id: number }): Response<IGroup> {
    return httpClientV2.get<IGroup>("calendar/groups/get_one", { params }).then((resp) => {
      return resp.data;
    });
  }

  updateGroup(data: {
    id: number;
    name: string;
    users_add?: Array<number>;
    users_delete?: Array<number>;
  }): Response<any> {
    return httpClientV2.patch<any>("calendar/groups/update", data).then((resp) => {
      return resp.data;
    });
  }

  deleteGroup(data: { id: number }): Response<any> {
    return httpClientV2.patch<any>("calendar/groups/delete", data).then((resp) => {
      return resp.data;
    });
  }

  // Location
  createLocation(data: { name: string }): Response<any> {
    return httpClientV2.post<any>("calendar/locations/create", data).then((resp) => {
      return resp.data;
    });
  }

  getLocations(): Response<any> {
    return httpClientV2.get<any>("calendar/locations/get_all").then((resp) => {
      return resp.data;
    });
  }

  updateLocation(data: { id: number; name: string }): Response<any> {
    return httpClientV2.patch<any>("calendar/locations/update", data).then((resp) => {
      return resp.data;
    });
  }

  deleteLocation(data: { id: number }): Response<any> {
    return httpClientV2.patch<any>("calendar/locations/delete", data).then((resp) => {
      return resp.data;
    });
  }

  // Event
  createEvent(data: IEventData): Response<any> {
    return httpClientV2.post<any>("calendar/events/create", data).then((resp) => {
      return resp.data;
    });
  }

  getEvents(params: IGetEventParams | null): Response<any> {
    return httpClientV2.get<any>("calendar/events/get_all", { params }).then((resp) => {
      return resp.data;
    });
  }

  getEventForProfile(params: IGetEventForProfileParams): Response<any> {
    return httpClientV2.get<any>("calendar/events/get_for_profile", { params }).then((resp) => {
      return resp.data;
    });
  }

  getEvent(params: IGetOneEventParams): Response<any> {
    return httpClientV2.get<any>("calendar/events/get_one", { params }).then((resp) => {
      return resp.data;
    });
  }

  getWorkingEvents(params: IGetEventParams | null): Response<any> {
    return httpClientV2.get<any>("calendar/events/get_working", { params }).then((resp) => {
      return resp.data;
    });
  }

  getActivityEvents(params: IGetEventParams | null): Response<any> {
    return httpClientV2.get<any>("calendar/events/get_by_categories", { params }).then((resp) => {
      return resp.data;
    });
  }

  checkEmployment(params): Response<any> {
    return httpClientV2.get<any>("calendar/events/check_employment", { params }).then((resp) => {
      return resp.data;
    });
  }

  updateEvent(data: IEventData): Response<any> {
    return httpClientV2.patch<any>("calendar/events/update", data).then((resp) => {
      return resp.data;
    });
  }

  deleteEvent(data: typeEventRemove): Response<any> {
    return httpClientV2.delete("calendar/events/delete", { data }).then((resp) => {
      return resp.data;
    });
  }

  changeInvitation(data: IChangeInvitationParams): Response<any> {
    return httpClientV2.post<any>("calendar/event_users/change_invitation", data).then((resp) => {
      return resp.data;
    });
  }
}

export default new calendarService();
