<template>
  <div class="regulations-page">
    <div v-if="!getCreateMode" class="regulations-page__tools">
      <div class="regulations-page__bread-crumb">
        <Widget type="bread-crumbs" :crumbs="crumbs" />
      </div>
      <div
        v-if="isAdmin"
        @click="$store.commit('changeCreateMode', true)"
        class="regulations-page__create-new"
      >
        <i class="icon-plus"></i>
        <span>Добавить документ</span>
      </div>
      <div class="regulations-page__filters">
        <Widget type="default-filter" :filterValue="filterValue" :items="filterSort" />
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div v-if="!getCreateMode && !loading" class="regulations-page__items" ref="mainContent">
      <div ref="emptyTopBlock" class="empty-block"></div>
      <RegulationComponent
        v-for="regulation in regulationsList"
        :key="regulation.id"
        :item="regulation"
        label
        @updateInfoRegulations="getRegulationsInfo"
      />
    </div>
    <div
      class="regulations-page__empty"
      v-if="!regulationsList.length && !loading && !getCreateMode"
    >
      В разделе пока нет памяток.
    </div>
    <div v-if="getCreateMode" class="regulations-page__add-container">
      <RegulationAddComponent @updateRegulationsPage="getRegulationsInfo" />
    </div>
    <div class="button-scroll-top" @click="goToTop" v-show="scrollTopButton">
      <i class="icon-up"></i>
    </div>
  </div>
</template>

<script>
import { Model } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";

import RegulationComponent from "@/components/KnowledgeBaseComponents/Regulation/RegulationComponent";
import Widget from "@/components/Widgets/Widget";
import { mapGetters } from "vuex";
const RegulationAddComponent = () =>
  import("@/components/KnowledgeBaseComponents/Regulation/RegulationAddComponent");

export default {
  name: "RegulationsPage",
  mixins: [],
  props: [],
  components: { RegulationAddComponent, Widget, RegulationComponent },
  data() {
    return {
      loading: true,
      scrollTopButton: false,
      regulationPage: 1,
      crumbs: [
        {
          title: "Главная",
          linkName: "Section"
        },
        {
          title: "Документы компании",
          linkName: "Regulations"
        }
      ],
      filterValue: {
        name: "Cначала новые",
        value: { datetime_of_creation: "desc" }
      },
      filterSort: [
        {
          name: "Cначала новые",
          value: { datetime_of_creation: "desc" }
        },
        {
          name: "Cначала старые",
          value: { datetime_of_creation: "asc" }
        },
        {
          name: "По популярности",
          value: { count_likes: "desc" }
        }
      ],
      regulationsList: []
    };
  },
  beforeDestroy() {
    this.$refs.mainContent.removeEventListener("scroll", this.handleScroll, false);
  },
  async mounted() {
    await this.getRegulationsInfo();
    this.setEndpointsRegulations();
    this.$root.$on("changeFilter", (item) => {
      this.filterValue = item;
    });
  },
  methods: {
    async getRegulationsInfo() {
      let array = [
        {
          name: "Гайды",
          type: "guide"
        },
        {
          name: "Памятки",
          type: "regulation"
        },
        {
          name: "Инструкции",
          type: "instruction"
        }
      ];
      const ModelChannel = new Model({
        model: "Regulation",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 15,
            _page: this.regulationPage,
            _order: this.filterValue.value,
            _with: JSON.stringify(["current_user_like"]),
            type: this.$route.params.category_name !== "Все" ? this.setType(array) : undefined
          }
        }
      });
      await ModelChannel.init();
      this.regulationsList = ModelChannel.allItems;
      this.$root.$emit("getMetaData");
    },
    handleScroll() {
      if (this.$refs.mainContent.scrollTop > 1000) {
        this.scrollTopButton = true;
      } else {
        this.scrollTopButton = false;
      }
    },
    setEndpointsRegulations() {
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.mainContent.addEventListener("scroll", this.handleScroll, false);
      });
    },
    goToTop() {
      this.$refs.emptyTopBlock.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    setType(arr) {
      let type = "";
      arr.forEach((item) => {
        if (item.name === this.$route.params.category_name) {
          type = item.type;
        }
      });
      return type;
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("knowledge_base_admin")
        : false;
    },
    getCreateMode() {
      return this.$store.getters.getCreateMode;
    }
  },
  watch: {
    filterValue() {
      this.getRegulationsInfo();
    },
    $route() {
      this.getRegulationsInfo();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.regulations-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;
  margin-right: 18px;
  max-height: calc(90vh - 10px);
  @media screen and (max-width: $lg) {
    max-height: 100%;
  }

  .loader-bg {
    margin: 20% auto;
  }

  &__tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }

  &__bread-crumb {
    @media screen and (max-width: $md) {
      order: 1;
    }
  }

  &__filters {
    order: 2;

    .default-filter {
      @media screen and (max-width: $xs) {
        width: 50px;
      }

      /deep/ .default-filter__content span {
        @media screen and (max-width: $xs) {
          display: none !important;
        }
      }

      /deep/ .default-filter__content .icon-filter {
        @media screen and (max-width: $xs) {
          font-size: 16px;
        }
      }
    }
  }

  &__create-new {
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    color: $color-interactive;
    transition: all 0.2s ease;

    @media screen and (max-width: $md) {
      width: 100%;
      justify-content: center;
      order: 3;
      margin-top: 20px;
    }

    .icon-plus {
      margin-right: 14px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  &__create-new {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  &__items {
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $lg) {
      justify-content: center;
    }

    .regulation-component {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $color-other-2;
    user-select: none;
  }

  .button-scroll-top {
    position: fixed;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddddff;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
  }

  .button-scroll-top:hover {
    box-shadow: 0 4px 5px rgba(221, 221, 255, 0.62);
  }

  .icon-up {
    display: block;
    color: $color-interactive;
    font-size: 20px;
  }
}
</style>
