export const { domain, domainStorage, domainS3 } = window.env.api;
export const { centrifugeUrl } = window.env.api;
export const { host } = window.env.api;
export const { dynamicRoute } = window.env.api;
export const { startRoutePage } = window.env.api;
export const { isNeedPutMethods } = window.env.api;
export const { DefaultValueSettings } = window.env.api;
export const { MicroServices } = window.env.api;
export const { fbConfig } = window.env.api;
export const { domainV2 } = window.env.api;
