<template>
  <transition name="show-modal">
    <div class="action-list">
      <div class="action-list__modal" v-click-outside="hideModal">
        <button class="action-list__setting-button" type="button" @click="handleClickIconDots">
          <i class="icon-dots-vertical" v-if="!avatarUser.length"></i>
          <img class="avatar-user" v-else :src="returnSrcFile(avatarUser)" alt="avatar" />
        </button>
        <div class="action-list__wrapper" v-if="modalShow" @click.self="hideModal">
          <ul class="action-list__modal-list" @click="hideModal">
            <ActionItem
              :item="item"
              v-for="(item, index) in actionList"
              :key="item.actionType + index"
              :module="module"
              @handleActionItem="handleActionItem"
            />
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ActionItem from "@/modules/UiKit/components/ActionList/ActionItem";
import { domainStorage } from "@/globalVariables";

export default {
  name: "ActionsList",
  mixins: [],
  props: {
    actionList: {
      type: Array,
      default: () => []
    },
    avatarUser: {
      type: String,
      default: ""
    },
    module: {
      type: String,
      default: "UikitModules"
    },
    unique: {
      type: String,
      default: "UikitModules"
    }
  },
  components: { ActionItem },
  data() {
    return {
      modalShow: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleClickIconDots() {
      this.modalShow = !this.modalShow;
    },
    hideModal() {
      this.modalShow = false;
    },
    handleActionItem(item) {
      this.$emit("handleAction", item);
      this.hideModal();
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.2s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.avatar-user {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;

  @media screen and (max-width: $md) {
    width: 24px;
    height: 24px;
  }
}

.action-list {
  position: relative;

  &__modal {
    position: relative;
    margin-left: auto;
  }

  &__setting-button {
    width: 24px;
    height: 24px;
    background: $surface-default;
    border-radius: 2px;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $icon-subdued;
      font-size: 18px;
      text-align: center;
    }

    @media (hover: hover) {
      &:hover {
        background: #f2f7fe;

        i {
          color: $icon-subdued;
        }
      }
    }

    &:active {
      background: #f2f7fe;
    }
  }
  &__wrapper {
    @media screen and (max-width: 850px) and (max-height: 850px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: rgba(33, 32, 44, 0.8);
    }
  }
  &__modal-list {
    position: absolute;
    z-index: 15;
    top: 36px;
    right: 0;
    padding: 16px;
    margin: 0;
    list-style: none;
    min-width: 220px;
    background-color: $surface-default;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    @media screen and (max-width: 850px) and (max-height: 850px) {
      position: fixed;
      max-height: 70vh;
      width: 100%;
      max-width: unset;
      top: unset;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      overflow-y: auto;
      z-index: 100;
    }
  }
}
</style>
