<template>
  <div class="status" :id="`${status.flow_status || status.id}`">
    <p class="status__skeleton" v-if="loader"></p>
    <p class="status__name" v-if="!loader">{{ status.name }}</p>
  </div>
</template>

<script>
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "KanbanStatus",
  mixins: [],
  props: {
    status: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    changeStatusColumn() {
      KanbanModule.deleteBoardColumnStatusAction({
        board_column_id: this.status.board_column_id,
        flow_status: this.status.flow_status
      });
      KanbanModule.createBoardColumnStatusAction({
        board_column_id: this.status.board_column_id,
        flow_status: this.status.flow_status
      });
    }
  },
  computed: {
    loader() {
      return KanbanModule.boardLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/Skeleton.mixin.scss";

.status {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  background: $surface-default;
  border: 1px solid $border-disabled;
  border-radius: 8px;
  cursor: pointer;

  &__skeleton {
    @include background-gradient();
    width: 100%;
    height: 100%;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $text-subdued;
  }
}
</style>
