<template>
  <div class="comments">
    <ul
      class="comments__list"
      id="commentsList"
      :style="`max-height: calc(100% - ${commentsListHeight}px)`"
    >
      <CommentsItem
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :modeAction="modeAction"
        :childList="childList"
        :listData="listData"
        :page="page"
        :totalCount="totalCount"
        :formLoader="formLoader"
        :currentComment="currentComment"
        :haveReply="haveReply"
        @deleteAction="deleteAction"
        @changeModeAction="changeModeAction"
        @setReplyComment="setReplyComment"
        @setCurrentComment="setCurrentComment"
        @getAnswer="getAnswer"
        @resetAnswer="resetAnswer"
        @paginationEventAction="paginationEventAction"
      />
      <li class="comments__empty" v-if="!comments.length">Нет комментариев</li>
    </ul>
    <div class="comments__form">
      <CommentsForm
        v-model="content"
        :value="content"
        :id="id"
        :parentId="parentId"
        :modeAction="modeAction"
        :currentComment="currentComment"
        :formLoader="formLoader"
        :content="content"
        :haveFiles="haveFiles"
        @setValue="setValue"
        @setFiles="setFiles"
        @createAction="createAction"
        @updateAction="updateAction"
        @changeModeAction="changeModeAction"
        @setCurrentComment="setCurrentComment"
        @toggleFormLoader="toggleFormLoader"
      />
    </div>
  </div>
</template>

<script>
import CommentsForm from "@/modules/UiKit/components/Comments/CommentsForm";
import CommentsItem from "@/modules/UiKit/components/Comments/CommentsItem";

export default {
  name: "Comments",
  mixins: [],
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    formLoader: {
      type: Boolean,
      default: false
    },
    currentComment: {
      type: Object,
      default: () => null
    },
    childList: {
      type: Object,
      default: () => null
    },
    id: {
      type: Number,
      default: null
    },
    parentId: {
      type: Number,
      default: null
    },
    listData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    },
    haveReply: {
      type: Boolean,
      default: true
    },
    haveFiles: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CommentsItem,
    CommentsForm
  },
  data() {
    return {
      content: "",
      files: [],
      modeAction: "",
      commentsListHeight: 130,
      windowWidth: 0
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    setValue(val) {
      this.content = val;
    },
    setFiles(val) {
      this.files = val;
    },
    createAction(files) {
      this.$emit("createAction", {
        text: this.content,
        files: files
      });
      this.content = "";
    },
    updateAction(files) {
      this.$emit("updateAction", {
        text: this.content,
        files: files.files,
        files_delete: files.files_delete
      });
      this.content = "";
    },
    deleteAction(body) {
      this.$emit("deleteAction", body);
    },
    setCurrentComment(body) {
      this.$emit("setCurrentComment", body);
    },
    getAnswer(body) {
      this.$emit("getAnswer", body);
    },
    paginationEventAction(params) {
      this.$emit("paginationEvent", params);
    },
    changeModeAction(mode) {
      this.modeAction = mode;
    },
    setReplyComment(val) {
      this.content = val;
    },
    resetAnswer(id) {
      this.$emit("resetAnswer", id);
    },
    commentFormHeight() {
      const textarea = document.getElementById(`textareaForm`);
      if (textarea.scrollHeight < 180) this.commentsListHeight = textarea.scrollHeight + 70;
    },
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    toggleFormLoader(val) {
      this.$emit("toggleFormLoader", val);
    }
  },
  computed: {},
  watch: {
    content() {
      if (this.modeAction === "reply" && !this.content.length) {
        this.changeModeAction("");
        this.setCurrentComment(null);
      }
      this.$nextTick(() => this.commentFormHeight());
    },
    windowWidth() {
      if (this.windowWidth < 580) {
        this.commentsListHeight = 85;
      } else {
        this.commentsListHeight = 135;
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

::-webkit-scrollbar {
  height: 0;
  width: 6px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.comments {
  height: 100%;

  &__list {
    margin: 0 4px 0 0;
    padding: 0 24px;
    list-style: none;
    height: calc(100% - 125px);
    overflow-y: scroll;

    @media screen and (max-width: $sm) {
      padding: 0 8px;
    }
  }

  &__empty {
    width: 100%;
    padding: 80px 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
  }
}
</style>
