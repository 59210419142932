import { EventBus } from "@/main";
import { domain } from "@/globalVariables";
import ErrorObserver from "@/api/Error";

export default {
  methods: {
    copyText(element) {
      this.selectText(element);
      document.execCommand("copy");
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 3000,
        label: "Скопировано в буфер обмена!"
      });
    },
    selectText(element) {
      let range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
    copyStringLink(params) {
      const newDomain = domain.replace("api", "");
      const id = params.id;
      const alias = params.alias ? `=${params.alias}` : "";
      const link = `${newDomain}TaskManager/Task/${id}?alias${alias}`;
      this.copyValue(link);
    },
    copyValue(value) {
      window.navigator.clipboard.writeText(value).then(() => {
        EventBus.$emit("showNotification", {
          type: "success",
          timeout: 3000,
          label: "Скопировано в буфер обмена!"
        });
      });
    }
  }
};
