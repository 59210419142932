import httpClient from "@/api/client/httpClient";

class UserService {
  constructor() {
    this.model = "User";
  }
  actionGetItem(id, params) {
    return httpClient.get(`${this.model}/actionGetItem/${id}`, { params });
  }
  currentRoles(params) {
    return httpClient.get(`${this.model}/currentRoles`, { params });
  }
  update(id, data) {
    return httpClient.put(`${this.model}/update/${id}`, data);
  }
  current() {
    return httpClient.get(`${this.model}/current`).then((resp) => {
      return resp.data.data;
    });
  }
}

export default new UserService();
