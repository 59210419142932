










import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList.vue";
import TimeTracker from "@/modules/TimeTracker/store";

interface ITimeProject {
  tracker_id: null | number;
  tracker_project_id: null | number;
  id: number;
  name: string;
}

@Component({
  components: { ActionsList, ProjectTag }
})
export default class TimeProject extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  project!: ITimeProject;
  list = [
    {
      isShow: true,
      iconType: "user",
      actionText: "Добавить пользователей",
      actionType: "add"
    },
    {
      isShow: true,
      iconType: "delete",
      actionText: "Удалить пользователей",
      actionType: "delete"
    }
  ];

  handleAction(val) {
    TimeTracker.actionProject({ type: val.actionType, project: this.project, isShow: true });
  }

  get tag() {
    switch (this.project!.tracker_id) {
      case null: {
        return "AOP-TIME";
      }
      case 1: {
        return "JiraCloud";
      }
      case 2: {
        return "JiraServer";
      }
      case 3: {
        return "AOP-TASK";
      }
      default: {
        return "AOP";
      }
    }
  }
}
