<template>
  <div class="black-sphere-modal" @mousedown.self="closeCurtain">
    <div class="black-sphere-modal__container">
      <div class="black-sphere-modal__header">
        <h2 class="black-sphere-modal__title">
          <i @click.self="closeCurtain" class="icon-arrow-back"></i>Поставить оценку
        </h2>
      </div>
      <div class="black-sphere-modal__body">
        <div class="black-sphere-modal__wrap">
          <Label :label="'Кто получает оценку'" />
          <Select
            v-model="gotUser"
            :items="setGotUserList"
            :multiple="isTask"
            :showButtonPanel="false"
          />
        </div>
        <div class="black-sphere-modal__wrap">
          <Label :label="'Оценка'" />
          <Select
            v-model="mark"
            :items="marks"
            :showAvatar="false"
            :multiple="false"
            :showSearchPanel="false"
            :showButtonPanel="false"
          />
        </div>
        <div class="black-sphere-modal__wrap" v-if="!isTask">
          <Label :label="'Дата получения оценки'" />
          <DatePickerWidget v-model="date" :deadline="false" :minDate="minDate" />
        </div>
        <div class="black-sphere-modal__wrap">
          <Label :label="'Комментарий'" />
          <Textarea
            v-model="comment"
            :placeholder="'Оставьте комментарий к оценке'"
            :maxlength="255"
          />
        </div>
      </div>
      <div class="black-sphere-modal__footer">
        <Button :type="'cancel'" :title="'Отменить'" @handleButton="closeCurtain" />
        <Button :title="'Поставить'" @handleButton="createMark" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Button from "@/modules/UiKit/components/buttons/Button";
import Select from "@/modules/UiKit/components/Select/Select";
import DatePickerWidget from "@/modules/UiKit/components/DatePicker/DatePickerWidget";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import NotificationObserver from "@/helpers/UserNotification";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import TaskBlackSphereModule from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";

export default {
  name: "BlackSphereModal",
  mixins: [],
  props: {
    gotUsers: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: null
    },
    subtaskId: {
      type: Number,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isTask: {
      type: Boolean,
      default: false
    }
  },
  components: { Textarea, DatePickerWidget, Select, Button, Label },
  data() {
    return {
      putUser: [],
      gotUser: [],
      date: new Date(),
      comment: "",
      mark: [],
      marks: [
        {
          id: 0,
          name: 0
        },
        {
          id: 1,
          name: 1
        },
        {
          id: 2,
          name: 2
        }
      ]
    };
  },
  mounted() {},
  methods: {
    closeCurtain() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        false
      );
    },
    createMark() {
      if (!this.validation()) return;
      const params = {
        got_users: this.gotUser.map((item) => item.id),
        mark: this.mark[0]?.id,
        marked_at: moment(this.date).format("YYYY-MM-DD"),
        comment: this.comment.length ? this.comment : undefined
      };
      if (this.currentTask) {
        params.id = this.currentTask ? this.currentTask.id : undefined;
        TaskBlackSphereModule.createTaskBlackSphereAction(params);
      } else {
        BlackSphere.createBlackSphereMarkForPersonAction(params);
        this.getTable();
      }
    },
    validation() {
      if (!this.gotUser.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите кто получает оценку!"
        });
        return false;
      }
      if (!this.mark.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите оценку!"
        });
        return false;
      }
      if (!this.comment) {
        NotificationObserver.notification({
          type: "error",
          message: "Оставьте комментарий к оценке!"
        });
        return false;
      }
      return true;
    },
    async getTable() {
      BlackSphere[BlackSphereMutationTypes.LOADER](true);
      await BlackSphere.getBlackSphereMarksForTableAction();
      BlackSphere[BlackSphereMutationTypes.LOADER](false);
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    currentTask() {
      return TaskModule.currentTask;
    },
    setGotUserList() {
      return this.userList ? this.userList.users.filter((item) => item.is_active) : null;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    minDate() {
      return this.isAdmin ? new Date(moment().subtract(60, "days")) : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere-modal {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__container {
    position: fixed;
    width: 768px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: $surface-default;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);

    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
    .icon-arrow-back {
      color: $icon-subdued;
      margin: 0 8px 0 0;
      display: none;
      font-size: 20px;
      cursor: pointer;
      @media (max-width: $md) {
        display: inline-flex;
      }
    }
  }

  &__body {
    overflow-y: scroll;
    height: calc(100% - 200px);
  }

  &__header,
  &__wrap,
  &__footer {
    padding: 32px 24px;
  }

  &__wrap {
    /deep/ {
      .date-picker,
      .date-picker__value,
      .date-picker__calendar,
      .vc-container {
        width: 100%;
      }

      .date-picker__calendar {
        z-index: 10;
        margin-top: 32px;
        position: relative;
      }

      textarea {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: #000000;
  }
}
</style>
