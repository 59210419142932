<template>
  <section class="time table-container">
    <TimeStatistics :timeStatistics="timeStatistics" v-if="timeStatistics.length && !isNewTable" />
    <div class="time__wrap">
      <TimeStatisticList
        :userStatistic="statistic"
        v-if="statistic && isNewTable"
        :isShowNormal="true"
      />
    </div>
    <TimeWidgets
      :isAdmin="isAdminTime"
      :isNewTable="isNewTable"
      :filterUser="filterUser"
      :filterDepartments="filterDepartments"
      :filterRoles="filterRoles"
      :usersList="usersList"
      :filterMonth="filterMonth"
      @changeMonthYear="changeMonthYear"
      @selectUsers="handleUser"
    />
    <TimeWidgetsMobile
      :isAdmin="isAdminTime"
      :isNewTable="isNewTable"
      :filterUser="filterUser"
      :filterDepartments="filterDepartments"
      :filterRoles="filterRoles"
      :usersList="usersList"
      :filterMonth="filterMonth"
      @changeMonthYear="changeMonthYear"
      @selectUsers="handleUser"
    />
    <div class="time__container" v-if="isNewTable">
      <Table
        :tableHead="tHead"
        :tableData="tableData"
        :isShowLoader="isShowLoader"
        :loader="loader"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <TimeContent
            :row="props.row"
            :cell="props.cell"
            :month="filterMonth"
            @showMonthStat="showMonthStat"
          />
        </template>
      </Table>
    </div>
    <div class="time__container" v-else>
      <Table
        :tableHead="setTableHead"
        :tableData="tableDataTime"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <TimeCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
    <TimeStatistic v-if="isShowModal" :month="filterMonth" @handleClose="closeModal" />
  </section>
</template>

<script>
import TimeStatistics from "@/modules/Tables/components/TablesStatistics/Time/TimeStatistics";
import TimeWidgets from "@/modules/Tables/components/TablesStatistics/Time/TimeWidgets";
import Table from "@/modules/Tables/components/Table/Table";
import TimeCellContent from "@/modules/Tables/components/TablesStatistics/Time/TimeCellContent";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { format } from "date-fns";
import TimeWidgetsMobile from "./TimeWidgetsMobile/TimeWidgetsMobile";
import moment from "moment";
import WorkCalendarModule, {
  WorkCalendarMutationTypes
} from "@/modules/TimeTracker/store/WorkCalendarModule";
import TimeContent from "@/modules/TimeTracker/component/TimeStatistic/TimeContent";
import TimeStatisticList from "@/modules/TimeTracker/component/TimeStatistic/TimeStatisticList";
import TimeStatistic from "@/modules/TimeTracker/component/TimeStatistic/TimeStatistic";

export default {
  name: "Time",
  mixins: [],
  props: [],
  components: {
    TimeStatistic,
    TimeStatisticList,
    TimeContent,
    TimeWidgetsMobile,
    TimeStatistics,
    TimeWidgets,
    Table,
    TimeCellContent
  },
  data() {
    return {
      tableHead: [
        { name: "Сотрудник", sort: true, valueSort: "user_id", key: "full_name" },
        { name: "Отдел", sort: false, key: "department" },
        { name: "Должность", sort: false, key: "roles" },
        { name: "Норма", sort: false, key: "normal_time" },
        { name: "Текущее время", sort: true, valueSort: "tracked_time", key: "tracked_time" },
        {
          name: "Всего время за месяц \nднем/будни (ставка 1)",
          sort: false,
          key: "tracked_time_rate_1"
        },
        {
          name: "Переработка за месяц \nднем/будни (ставка 1,5)",
          sort: false,
          key: "tracked_time_rate_2"
        },
        {
          name: "Переработка за месяц \nночью/ в выходные (ставка 2)",
          sort: false,
          key: "tracked_time_rate_3"
        },
        {
          name: "Переработки/Недоработки",
          sort: true,
          valueSort: "difference_time",
          key: "difference_time"
        },
        { name: "Больничный", sort: false, key: "sick_leave" }
      ],
      tHead: [
        { name: "Сотрудник", sort: false, key: "full_name" },
        { name: "Должность", sort: false, key: "roles" },
        { name: "Норма", sort: false, key: "current_standard_time" },
        {
          name: "Текущее время",
          sort: false,
          valueSort: "tracked_time",
          key: "total_tracked_time"
        },
        {
          name: "Всего время за месяц \nднем/будни (ставка 1)",
          sort: false,
          key: "rate_1"
        },
        {
          name: "Переработка за месяц \nднем/будни (ставка 1,5)",
          sort: false,
          key: "rate_1_5"
        },
        {
          name: "Переработка за месяц \nночью/ в выходные (ставка 2)",
          sort: false,
          key: "rate_2"
        },
        {
          name: "Переработки/Недоработки",
          sort: false,
          valueSort: "difference_time",
          key: "difference_time"
        }
      ],
      localStorage: null,
      loader: false,
      isShowLoader: true,
      isShowModal: false,
      filterMonth: format(new Date(), "yyyy-MM"),
      filterUser: [],
      filterDepartments: [],
      filterRoles: []
    };
  },
  beforeDestroy() {
    this.$root.$off("changeSelectValue:FilterUserInTimeTable");
    this.$root.$off("changeSelectValue:FilterDepartmentsInTimeTable");
    this.$root.$off("changeSelectValue:FilterRolesInATimeTable");
    this.setCookieValue();
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    this.initializeTable();
    this.setFilterTable();
  },
  methods: {
    ...mapActions("TablesModules", ["getTableData", "getTimeStats", "getDatesMonth"]),
    ...mapMutations("TablesModules", ["CLEAR_TABLE_DATA", "RESET_PAGINATION", "SET_TABLE_PARAMS"]),
    async showMonthStat(params) {
      await WorkCalendarModule.getMonthTable(params);
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    getTableDataWithPagination() {
      if (this.timePage && this.timeCount && this.isNewTable) {
        if (this.timeCount > this.tableData.length) {
          const page = this.timePage + 1;
          this.loader = true;
          this.getWorkTable({
            per_page: this.per_page,
            month: this.filterMonth,
            users: this.filterUser.length ? this.filterUser.map((item) => item.id) : null,
            page
          }).then(() => {
            this.loader = false;
          });
        }
      } else if (this.tableDataTime.length) {
        this.getTableData();
      }
    },
    handleUser(val) {
      this.filterUser = val;
      WorkCalendarModule[WorkCalendarMutationTypes.SET_USERS_FILTER](this.filterUser);
      this.getTableWithFilter();
    },
    async getTableWithFilter() {
      const users = this.filterUser.length ? this.filterUser.map((item) => item.id) : null;
      await this.getWorkTable({ users, month: this.filterMonth });
    },
    initializeTable() {
      this.getDatesMonth(this.filterMonth);
      this.getTimeStats(this.filterMonth);
      this.updateTablesTime();
    },
    async getWorkTable(params) {
      await WorkCalendarModule.getWorktable(params);
    },
    async updateTablesTime() {
      if (this.isNewTable) {
        this.isShowLoader = true;
        await this.getTableWithFilter();
        await WorkCalendarModule.getCurrentUserStat({
          month: this.filterMonth,
          user_id: this.userId
        });
        this.isShowLoader = false;
      } else {
        this.CLEAR_TABLE_DATA();
        this.RESET_PAGINATION();
        this.SET_TABLE_PARAMS({
          route: "ActivityByDay",
          params: {
            _count: 20,
            _order: JSON.stringify({
              [this.valueSort.value]: this.valueSort.isAsc ? "asc" : "desc"
            }),
            _search: { month: this.filterMonth },
            user_id: this.filterUser.length ? JSON.stringify(this.setFilterUser) : undefined,
            department: this.filterDepartments.length
              ? JSON.stringify(this.setFilterDepartment)
              : undefined,
            roles: this.filterRoles.length ? JSON.stringify(this.setFilterRole) : undefined,
            _with: JSON.stringify(["User", "Roles", "Department"])
          }
        });
        this.getTableData();
      }
    },
    setFilterTable() {
      this.$root.$on("changeSelectValue:FilterUserInTimeTable", (val) => {
        this.filterUser = val;
        this.updateTablesTime();
      });
      this.$root.$on("changeSelectValue:FilterDepartmentsInTimeTable", (val) => {
        this.filterDepartments = val;
        this.updateTablesTime();
      });
      this.$root.$on("changeSelectValue:FilterRolesInATimeTable", (val) => {
        this.filterRoles = val;
        this.updateTablesTime();
      });
    },
    changeMonthYear(val) {
      this.filterMonth = val;
      this.initializeTable();
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("Time-table-setting"));
      this.filterUser = setting.users;
      this.filterDepartments = setting.departments;
      this.filterRoles = setting.roles;
      this.filterMonth = setting.month;
    },
    setCookieValue() {
      const option = {
        users: this.filterUser,
        departments: this.filterDepartments,
        roles: this.filterRoles,
        month: this.filterMonth
      };
      window.localStorage.setItem("Time-table-setting", JSON.stringify(option));
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "tableDataTime",
      "tableParams",
      "totalCount",
      "timeStatistics",
      "datesColumn",
      "valueSort"
    ]),
    ...mapGetters(["getEndpoints"]),
    statistic() {
      return WorkCalendarModule.currentUserStat;
    },
    usersList() {
      return this.$store.getters["UsersModule/userList"]
        .filedList(["full_name", "avatar", "is_active"])
        .filter((item) => item.is_active);
    },
    timePage() {
      return WorkCalendarModule.page;
    },
    timeCount() {
      return WorkCalendarModule.count;
    },
    userId() {
      return this.$store.getters.getUserId;
    },
    tableData() {
      return WorkCalendarModule.tableData;
    },
    isNewTable() {
      return moment(`${this.filterMonth}-01`) > moment("2022-03-01");
    },
    setTableHead() {
      return this.tableHead.concat(this.datesColumn);
    },
    isAdminTime() {
      return this.getEndpoints("WorkingCalendar", "update");
    },
    setFilterUser() {
      return this.filterUser.map((item) => item.id);
    },
    setFilterDepartment() {
      return this.filterDepartments.map((item) => item.id);
    },
    setFilterRole() {
      return this.filterRoles.map((item) => item.id);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
  width: 0;
  background: $surface-subdued;
}

::-webkit-scrollbar-thumb {
  background: $surface-pressed;
  border-radius: 8px;
  width: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $action-primary;
  }
}

::-webkit-scrollbar-track {
  margin: 0;
}

.time {
  position: relative;
  height: 87%;

  @media (max-height: $md) {
    height: 75%;
  }

  @media (max-height: $sm) {
    height: 75%;
  }

  &__wrap {
    margin-left: 24px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__new-table {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__new-table-text {
    font-size: 18px;
    line-height: 24px;
    color: $text-disabled;
  }

  &__container {
    width: 100%;
    height: 82%;
    overflow: scroll;

    @media (max-height: 1366px) {
      height: 86%;
    }

    @media (max-height: $lg) {
      height: 83%;
    }

    @media (max-height: 812px) {
      height: 75%;
    }

    @media (max-height: $md) {
      height: 85%;
    }

    @media (max-height: $sm) {
      height: 75%;
    }

    @media (max-height: $xxs) {
      height: 50%;
    }
  }

  &__widgets-mobile {
    display: none;

    @media (max-width: 900px) {
      display: flex;
      align-items: center;
    }

    &_admin {
      @media (max-width: 1520px) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__widgets {
    @media (max-width: 900px) {
      display: none;
    }

    &_admin {
      @media (max-width: 1520px) {
        display: none;
      }
    }
  }

  /deep/ {
    .table {
      &__empty {
        .table__row {
          width: 100%;
        }
      }

      &__loader {
        width: 100%;
      }

      &__row {
        .table__cell {
          background: $surface-default;
        }

        &:hover {
          .table__cell {
            background: #f1effa;
          }
        }

        &:nth-child(2n) {
          .table__cell {
            background: #fcfcfe;
          }
        }

        &:nth-child(2n):hover {
          .table__cell {
            background: #f1effa;
          }
        }
      }

      &__head {
        .table__cell:first-child {
          background: $surface-default;
          padding: 8px 0 8px 24px;
        }
      }

      &__cell {
        min-width: 120px;
        padding: 0;

        &:first-child {
          left: 0;
          position: sticky;
          position: -webkit-sticky;
          z-index: 1;
          max-width: 300px;
          padding-left: 24px;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          min-width: 300px;
        }

        &:nth-child(4) {
          min-width: 120px;
        }

        &:nth-child(5) {
          min-width: 150px;
        }

        &:nth-child(6) {
          text-align: left;
          min-width: 220px;

          .table__sort {
            margin-right: 16px;
          }
        }

        &:nth-child(7) {
          text-align: left;
          min-width: 220px;

          .table__sort {
            margin-right: 16px;
          }
        }

        &:nth-child(8) {
          text-align: left;
          min-width: 220px;

          .table__sort {
            margin-right: 16px;
          }
        }

        &:nth-child(9) {
          min-width: 220px;
        }

        &:nth-child(10) {
          min-width: 135px;
        }
      }
    }
  }
}
</style>
