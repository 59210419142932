<template>
  <div class="date-interval">
    <div
      class="date-interval__header"
      :class="{ 'date-interval__header_current': indexOfTodayDate !== -1 }"
    >
      <p>C {{ intervalStart }} по {{ intervalEnd }}</p>
    </div>
    <ul class="date-interval__list list">
      <li
        class="list__item"
        v-for="(item, index) in interval"
        :key="index"
        :class="{ list__item_current: indexOfTodayDate === index }"
        :id="item.date"
      ></li>
    </ul>
  </div>
</template>

<script>
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import moment from "moment";

export default {
  name: "DateInterval",
  mixins: [],
  props: {
    interval: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    intervalStart() {
      return format(new Date(this.interval[0].date), "d MMMM", { locale: ruLocale });
    },
    intervalEnd() {
      return format(new Date(this.interval[this.interval.length - 1].date), "d MMMM", {
        locale: ruLocale
      });
    },
    indexOfTodayDate() {
      return this.interval.findIndex((item) => moment(item.date).isSame(moment(), "day"));
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.date-interval {
  height: 100%;
  &__header {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f7f7fd;
    border-collapse: collapse;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #343748;
    }
    &_current {
      p {
        color: $chat-editor;
      }
    }
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: fit-content;
    height: calc(100% - 46px);
    &__item {
      width: 52px;
      height: 100%;
      border-right: 1px solid #f7f7fd;
      &_current {
        border-right: 2px solid #5551f9;
        //background-color: #5551f9;
        //opacity: 0.2;
      }
    }
  }
}
</style>
