<template>
  <div class="time-widgets" :class="{ time__widgets_admin: isAdmin }">
    <div class="time-widgets__wrap">
      <ChosenFilter
        v-if="isNewTable"
        icon="user"
        :search="true"
        placeholder="Сотрудники"
        :multiple="true"
        :chosenList="usersList"
        v-model="currentResponsible"
        :allItem="true"
        @input="handleUser"
      />
      <Widget
        v-if="!isNewTable"
        type="select-new"
        :entity="'User'"
        :actionEntity="'getItems'"
        :itemName="'full_name'"
        :keyValue="'FilterUserInTimeTable'"
        :label="'Сотрудник'"
        :placeholder="'Все'"
        :multiple="true"
        :editValue="filterUser"
      />
      <Widget
        v-if="!isNewTable"
        type="select-new"
        :entity="'Department'"
        :actionEntity="'getItems'"
        :itemName="'name'"
        :keyValue="'FilterDepartmentsInTimeTable'"
        :label="'Отдел'"
        :placeholder="'Все'"
        :multiple="true"
        :class="'select-bg_users'"
        :editValue="filterDepartments"
      />
      <Widget
        v-if="!isNewTable"
        type="select-new"
        :entity="'Role'"
        :actionEntity="'getItems'"
        :itemName="'name'"
        :keyValue="'FilterRolesInATimeTable'"
        :label="'Должность'"
        :placeholder="'Все'"
        :multiple="true"
        :class="'select-bg_role'"
        :editValue="filterRoles"
      />
      <MonthPickerTable
        :currentMonth="setCurrentMonth"
        :filterMonth="filterMonth"
        @changeMonthYear="changeMonthYear"
        :maxDate="setMaxDate"
        :dateMax="setMaxDate"
        :showIcon="true"
      />
      <ModalButton
        v-if="isAdmin"
        :title="'Выгрузить зп'"
        :icon="'download-secondary'"
        :type="'outline'"
        :loader="loaderTimeReportByRatesExport"
        @handleButton="downloadSalary"
      />
      <ModalButton
        v-if="isAdmin"
        :title="'Выгрузить по проектам'"
        :icon="'download-secondary'"
        :type="'outline'"
        :loader="loaderTimeReportByProjectsExport || loaderProject"
        @handleButton="downloadByProject"
      />
    </div>

    <SalaryPicker v-if="isShowPicker" :date="getNewRecord" :loaderSalary="loaderSalary" />
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import {mapActions, mapState} from "vuex";
import {format, parseISO} from "date-fns";
import moment from "moment";
import AbsenceModule, {AbsenceMutationTypes} from "@/modules/Absence/store/AbsenceModule";
import SalaryPicker from "@/modules/Absence/component/AbsenceModal/SalaryPicker";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";

export default {
  name: "TimeWidgets",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isNewTable: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    }, usersList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterDepartments: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterRoles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterMonth: {
      type: String,
      default: ""
    }
  },
  components: {ChosenFilter, SalaryPicker, ModalButton, Widget, MonthPickerTable},
  data() {
    return {
      currentResponsible: []
    };
  },
  mounted() {
    this.currentResponsible = this.filterUser;
  },
  methods: {
    ...mapActions("TablesModules", ["downloadReportsTime"]),
    handleUser(val) {
      this.currentResponsible = val;
      this.$emit("selectUsers", this.currentResponsible);
    },
    changeMonthYear(val) {
      this.$emit("changeMonthYear", val);
    },
    downloadSalary() {
      if (this.isNewService) {
        AbsenceModule[AbsenceMutationTypes.TOGGLE_MODAL_PICKER](true);
      } else {
        this.downloadReportsTime({value: "TimeReportByRatesExport", month: this.getNewRecord});
      }
    },
    downloadByProject() {
      if (this.isNewService) {
        AbsenceModule.exportByProject({month: this.getNewRecord});
      } else {
        this.downloadReportsTime({value: "TimeReportByProjectsExport", month: this.getNewRecord});
      }
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "loaderTimeReportByRatesExport",
      "loaderTimeReportByProjectsExport"
    ]),
    isShowPicker() {
      return AbsenceModule.isShowPicker;
    },
    isNewService() {
      return moment(`${this.getNewRecord}-01`) > moment("2022-03-01");
    },
    getNewRecord() {
      return this.filterMonth ? this.filterMonth : this.$store.state.newRecord.month;
    },
    loaderProject() {
      return AbsenceModule.loaderProject;
    },
    loaderSalary() {
      return AbsenceModule.loaderSalary;
    },

    setCurrentMonth() {
      return format(parseISO(this.filterMonth), "M") - 1;
    },
    setCurrentYear() {
      return format(parseISO(this.filterMonth), "yyyy");
    },
    setMaxDate() {
      return `${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.time-widgets {
  display: flex;
  align-items: center;
  padding: 24px;
  border-top: 1px solid $border-subdued;
  border-bottom: 1px solid $border-subdued;

  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  /deep/ {
    .select-bg {
      width: 250px;
      margin-right: 24px;

      &_role,
      &_users {
        .select-content-bg {
          .select-content {
            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
        }
      }

      .select-content-bg {
        height: 28px;

        .select-content {
          background: #fafafe;
          border-radius: 4px;
          padding: 5px 8px;

          .select-content-value-bg {
            width: calc(100% - 140px);
          }
        }
      }
    }

    .switch-widget__label {
      font-size: 14px;
      line-height: 20px;
      color: $text-subdued;
    }

    .modal-items {
      background: #fafafe;
      max-height: 300px;

      @media (max-height: $xs) {
        max-height: 200px;
      }

      @media (max-height: $xxs) {
        max-height: 150px;
      }
    }
  }

  .month-picker-background {
    width: 145px;
  }
}

.default-button {
  margin-left: auto;
  min-width: 140px;

  &:last-child {
    margin-left: 8px;
    min-width: 212px;
  }
}
</style>
