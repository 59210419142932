




import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";

@Component
export default class TimeCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;

  get time() {
    if (this.row.time_stop) {
      return `${this.row.time_start} - ${this.row.time_stop} `;
    }
    return `${this.row.time_start} - н.в.`;
  }
}
