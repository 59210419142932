<template>
  <li
    class="vacation-item"
    ref="vacation"
    :style="{ width: vacation.count_days * 20 + 'px' }"
    @click.self.stop="openCurrentVacation"
  >
    <div class="vacation-item__dates" v-if="vacation.count_days > 6">
      {{ setFormatDate(vacation.date_from) }} - {{ setFormatDate(vacation.date_to) }}
    </div>
    <span class="vacation-item__count" v-if="vacation.count_days > 3">
      {{ vacation.count_days }}
      {{ setLabelCountDays(vacation.count_days, "день", "дня", "дней") }}
    </span>
  </li>
</template>

<script>
import moment from "moment";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";

export default {
  name: "Vacation",
  mixins: [vacationMixin],
  props: {
    vacation: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.day = document.getElementById(this.vacation.date_from);
      this.$refs.vacation.style.left = this.day.offsetLeft + "px";
    });
  },
  methods: {
    openCurrentVacation() {
      this.$root.$emit("openConformationModal", {
        vacation: this.vacation,
        editMode: false,
        createMode: false
      });
    },
    setFormatDate(date) {
      return date.split("-").reverse().join(".");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.vacation-item {
  position: absolute;
  background-color: $action-primary;
  height: 48px;
  border-radius: 14px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 8;
  &:hover {
    background-color: $action-primary-hovered;
  }
  &:active {
    background-color: $action-primary-pressed;
  }
  &__dates,
  &__count {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ebf2fa;
    padding-right: 24px;
    margin: 0;
  }

  &__dates {
    display: none;
    padding-right: 8px;
  }

  &__day_start {
    flex-wrap: wrap;
  }

  &__day_start:hover .timeline-vacation__dates {
    display: block;
  }
}
</style>
