import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import {
  IBasePlan,
  IPlan,
  IStep,
  IStepLink,
  IAccessUsers,
  ICommentPlan
} from "@/modules/TaskManager/Model/PlanningSchedule/interface/interface";
import PlanningScheduleService, {
  accessUsersBodyType,
  commentParamsType,
  planParamsType
} from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleService";
import Vue from "vue";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import { EventBus } from "@/main";
import moment from "moment";
import _ from "lodash";

const name = "PlanningScheduleModule";

export enum PlanningScheduleMutationTypes {
  SET_RESPONSE_PLAN_LIST = "SET_RESPONSE_PLAN_LIST",
  SET_PLAN_LIST = "SET_PLAN_LIST",
  SET_CURRENT_PLAN = "SET_CURRENT_PLAN",
  EDIT_CURRENT_PLAN = "EDIT_CURRENT_PLAN",
  DELETE_PLAN_FROM_LIST = "DELETE_PLAN_FROM_LIST",
  SET_STEP_LIST = "SET_STEP_LIST",
  ADD_STEP = "ADD_STEP",
  EDIT_STEP_IN_LIST = "EDIT_STEP_IN_LIST",
  DELETE_STEP_FROM_LIST = "DELETE_STEP_FROM_LIST",
  SET_STEP_LINKS_LIST = "SET_STEP_LINKS_LIST",
  TOGGLE_PLAN_LIST_LOADER = "TOGGLE_PLAN_LIST_LOADER",
  TOGGLE_PLAN_INFO_LOADER = "TOGGLE_PLAN_INFO_LOADER",
  TOGGLE_STEP_LIST_LOADER = "TOGGLE_STEP_LIST_LOADER",
  TOGGLE_STEP_LINKS_LOADER = "TOGGLE_STEP_LINKS_LOADER",
  TOGGLE_CALCULATING_LOADER = "TOGGLE_CALCULATING_LOADER",
  SET_ACCESS_USERS = "SET_ACCESS_USERS",
  ADD_ACCESS_USERS = "ADD_ACCESS_USERS",
  DELETE_ACCESS_USERS = "DELETE_ACCESS_USERS",
  SET_CRITICAL_PATH = "SET_CRITICAL_PATH",
  SET_CURRENT_BASE_PLAN = "SET_CURRENT_BASE_PLAN",
  SET_BASE_PLAN_TO_SHOW = "SET_BASE_PLAN_TO_SHOW",
  SET_BASE_PLAN_LIST = "SET_BASE_PLAN_LIST",
  ADD_TO_BASE_PLAN_LIST = "ADD_TO_BASE_PLAN_LIST",
  DELETE_FROM_BASE_PLAN_LIST = "DELETE_FROM_BASE_PLAN_LIST",
  EDIT_BASE_PLAN = "EDIT_BASE_PLAN",
  SET_BASE_STEP_LISTS = "SET_BASE_STEP_LISTS",
  SET_BASE_STEP_LINK_LISTS = "SET_BASE_STEP_LINK_LISTS",
  GET_PLAN_COMMENTS = "GET_PLAN_COMMENTS",
  RESET_PLAN_COMMENTS = "RESET_PLAN_COMMENTS",
  DELETE_PLAN_COMMENT = "DELETE_PLAN_COMMENT",
  SET_CURRENT_COMMENT = "SET_CURRENT_COMMENT",
  SET_COMMENT_PAGE = "SET_COMMENT_PAGE",
  SET_COMMENT_COUNT = "SET_COMMENT_COUNT",
  UPDATE_PLAN_COMMENT = "UPDATE_PLAN_COMMENT",
  TOGGLE_FORM_LOADER = "TOGGLE_FORM_LOADER",
  TOGGLE_SHOW_COMMENTS = "TOGGLE_SHOW_COMMENTS",
  SET_CHILDREN_COMMENT = "SET_CHILDREN_COMMENT",
  RESET_CURRENT_CHILDREN_COMMENT = "RESET_CURRENT_CHILDREN_COMMENT",
  UPDATE_CURRENT_ANSWER_COMMENTS = "UPDATE_CURRENT_ANSWER_COMMENTS",
  DELETE_CHILDREN_COMMENT = "DELETE_CHILDREN_COMMENT"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

type responsePlanListType = {
  count: number;
  list: Array<IPlan>;
  page: number;
  per_page: number;
};
type createPlanDataType = {
  name: string;
};
type editPlanDataType = {
  id: number;
  name?: string;
  date_start?: string;
  date_deadline?: string;
  count_comments?: number;
};
type stepParamsType = {
  plan_id: number;
  version_id?: number;
};
type stepLinksParamsType = {
  plan_id: number;
  version_id?: number;
};

type createStepDataType = {
  plan_id: number;
  name: string;
  duration?: number;
};

type newStepType = {
  plan_id: number;
  name: string;
  id: number;
  duration?: number;
  relative_number?: number;
};
type editStepDataType = {
  id: number;
  name?: string;
  duration?: number;
};
type createStepLinkDataType = {
  plan_id: number;
  step_from: number | null;
  step_to: number | null;
};
type createBasePlanType = {
  plan_id: number;
  name: string;
};
type basePlanItemType = {
  id: number;
  name: string;
};
type updateCommentType = {
  id: number;
  text: string;
  comment_id: number;
  parent_id?: number | null;
  count_comments?: number | null;
};
type deleteCommentType = {
  id: number;
  comment_id: number;
  parent_id?: number | null;
};

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class PlanningScheduleModule extends VuexModule {
  planList: Array<IPlan> = [];
  responsePlanList: responsePlanListType | null = null;
  currentPlan: IPlan | null = null;
  stepList: Array<IStep> = [];
  stepLinksList: Array<IStepLink> = [];
  planListLoader: Boolean = false;
  planInfoLoader: Boolean = false;
  stepListLoader: Boolean = false;
  stepLinksLoader: Boolean = false;
  calculatingLoader: Boolean = false;
  topMargin: number = 80;
  currentBasePlan: IBasePlan | null = null;
  basePlanToShow: basePlanItemType | null = null;
  criticalPath: Array<Array<IStepLink>> = [];
  basePlanList: Array<basePlanItemType> = [];
  baseStepLists: object = {};
  baseStepLinkLists: object = {};
  accessUsers: IAccessUsers | null = {
    count: 0,
    list: [],
    page: 1,
    per_page: 15
  };
  showComments: boolean = false;
  comments: Array<ICommentPlan> = [];
  commentPage: number = 1;
  commentCount: number = 0;
  currentComment: ICommentPlan | null = null;
  commentLoader: boolean = false;
  childCommentsList: any = {};

  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_SHOW_COMMENTS](payload: boolean) {
    this.showComments = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_RESPONSE_PLAN_LIST](payload: responsePlanListType) {
    this.responsePlanList = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_PLAN_LIST](payload: Array<IPlan>) {
    this.planList = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_CURRENT_PLAN](payload: IPlan | null) {
    this.currentPlan = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.EDIT_CURRENT_PLAN](payload: editPlanDataType) {
    if (!this.currentPlan) return;
    for (const key in payload) {
      if (key !== "id") this.currentPlan[key] = payload[key];
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_PLAN_FROM_LIST](planId: number) {
    this.planList = this.planList.filter((plan) => plan.id !== planId);
  }

  @Mutation
  [PlanningScheduleMutationTypes.SET_STEP_LIST](payload: Array<IStep>) {
    this.stepList = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.ADD_STEP](payload: IStep) {
    payload.relative_number = this.stepList.length + 2;
    this.stepList.push(payload);
  }
  @Mutation
  [PlanningScheduleMutationTypes.EDIT_STEP_IN_LIST](payload: editStepDataType) {
    const editableStep = this.stepList.find((item) => item.id === payload.id);
    if (editableStep) {
      for (const key in payload) {
        if (key !== "id") editableStep[key] = payload[key];
      }
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_STEP_FROM_LIST](stepId: number) {
    let deletedIndex;
    this.stepList = this.stepList.filter((step, index) => {
      if (step.id !== stepId) {
        return true;
      } else {
        deletedIndex = index;
        return false;
      }
    });
    this.stepList = this.stepList.map((step, index) => {
      if (index >= deletedIndex && step.relative_number) {
        step.relative_number -= 1;
      }
      return step;
    });
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_STEP_LINKS_LIST](payload: Array<IStepLink>) {
    this.stepLinksList = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_PLAN_LIST_LOADER](payload: boolean) {
    this.planListLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_PLAN_INFO_LOADER](payload: boolean) {
    this.planInfoLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_STEP_LIST_LOADER](payload: boolean) {
    this.stepListLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_STEP_LINKS_LOADER](payload: boolean) {
    this.stepLinksLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_CALCULATING_LOADER](payload: boolean) {
    this.calculatingLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_CRITICAL_PATH](payload: Array<Array<IStepLink>>) {
    this.criticalPath = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_CURRENT_BASE_PLAN](payload: IBasePlan | null) {
    this.currentBasePlan = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_BASE_PLAN_TO_SHOW](payload: basePlanItemType | null) {
    this.basePlanToShow = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_BASE_PLAN_LIST](payload: Array<basePlanItemType>) {
    this.basePlanList = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.ADD_TO_BASE_PLAN_LIST](payload: basePlanItemType) {
    this.basePlanList.push(payload);
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_FROM_BASE_PLAN_LIST](payload: number) {
    this.basePlanList = this.basePlanList.filter((item) => item.id !== payload);
  }
  @Mutation
  [PlanningScheduleMutationTypes.EDIT_BASE_PLAN](payload: basePlanItemType) {
    const editedBasePlan = this.basePlanList.find((plan) => plan.id === payload.id);
    if (editedBasePlan) editedBasePlan.name = payload.name;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_BASE_STEP_LISTS](payload: {
    versionId?: number;
    baseStepList: Array<IStep>;
  }) {
    Vue.set(this.baseStepLists, `version_${payload.versionId}`, payload.baseStepList);
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_BASE_STEP_LINK_LISTS](payload: {
    versionId?: number;
    baseStepLinkList: Array<IStep>;
  }) {
    Vue.set(this.baseStepLinkLists, `version_${payload.versionId}`, payload.baseStepLinkList);
  }

  // ACCESS USERS //

  @Mutation
  [PlanningScheduleMutationTypes.SET_ACCESS_USERS](payload: IAccessUsers) {
    this.accessUsers = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.ADD_ACCESS_USERS](payload: IAccessUsers | any) {
    if (this.accessUsers) {
      const arrUsersId = payload.list
        ? payload.list
        : payload.map((item) => {
            return { user_id: item };
          });
      const users = this.accessUsers?.list.concat(arrUsersId);
      Vue.set(this.accessUsers, "list", users);
      if (payload.page) {
        const page = payload.page;
        Vue.set(this.accessUsers, "page", page);
        Vue.set(this.accessUsers, "count", payload.count);
      }
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_ACCESS_USERS](payload: IAccessUsers | any) {
    if (this.accessUsers) {
      const arrId = payload.map((item) => {
        return { user_id: item };
      });
      const users = _.differenceBy(this.accessUsers?.list, arrId, "user_id");
      Vue.set(this.accessUsers, "list", users);
    }
  }

  // COMMENTS //

  @Mutation
  [PlanningScheduleMutationTypes.GET_PLAN_COMMENTS](payload: ICommentPlan) {
    this.comments = this.comments.concat(payload);
    this.comments = setUniqueArray(this.comments, "id").sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
  }
  @Mutation
  [PlanningScheduleMutationTypes.RESET_PLAN_COMMENTS]() {
    this.comments = [];
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_COMMENT_COUNT](payload: number) {
    this.commentCount = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_COMMENT_PAGE](payload: number) {
    this.commentPage = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_PLAN_COMMENT](id: number) {
    this.comments = this.comments.filter((item) => item.id !== id);
  }
  @Mutation
  [PlanningScheduleMutationTypes.UPDATE_PLAN_COMMENT](payload: {
    id: number;
    text: string;
    comment_id: number;
  }) {
    const comment = this.comments.length
      ? this.comments.find((item: ICommentPlan) => item.id === payload.comment_id)
      : false;
    if (comment) {
      const index = this.comments.findIndex((item: ICommentPlan) => item.id === payload.comment_id);
      Vue.set(this.comments, index, {
        ...comment,
        text: payload.text ? payload.text : undefined
      });
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_CURRENT_COMMENT](payload: ICommentPlan | null) {
    this.currentComment = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](payload: boolean) {
    this.commentLoader = payload;
  }
  @Mutation
  [PlanningScheduleMutationTypes.RESET_CURRENT_CHILDREN_COMMENT](id) {
    if (this.childCommentsList[`answer_${id}`]) delete this.childCommentsList[`answer_${id}`];
  }
  @Mutation
  [PlanningScheduleMutationTypes.SET_CHILDREN_COMMENT](payload) {
    if (this.childCommentsList[`answer_${payload.id}`]) {
      const newCurrentList = payload.comments.list ? payload.comments.list : [payload.comments];
      this.childCommentsList[`answer_${payload.id}`].list.push(...newCurrentList);
      Vue.set(
        this.childCommentsList[`answer_${payload.id}`],
        `list`,
        this.childCommentsList[`answer_${payload.id}`].list
      );
    } else {
      const newAnswerList = payload.comments.list
        ? payload.comments
        : { list: [payload.comments], page: 0 };
      Vue.set(this.childCommentsList, `answer_${payload.id}`, newAnswerList);
    }
    if (payload.comments.page) {
      Vue.set(this.childCommentsList[`answer_${payload.id}`], "page", payload.comments.page + 1);
    }
    const comment = this.comments?.find((item: ICommentPlan) => item.id === payload.id);
    if (comment) {
      const index = this.comments.findIndex((item: ICommentPlan) => item.id === payload.id);
      const countComments = payload.comments.count
        ? payload.comments.count
        : comment.count_comments + 1;
      Vue.set(this.comments, index, {
        ...comment,
        count_comments: countComments
      });
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.UPDATE_CURRENT_ANSWER_COMMENTS](payload) {
    const comment = this.childCommentsList[`answer_${payload.parent_id}`]!.list
      ? this.childCommentsList[`answer_${payload.parent_id}`].list.find(
          (item: ICommentPlan) => item.id === payload.comment_id
        )
      : false;
    if (comment) {
      const index = this.childCommentsList[`answer_${payload.parent_id}`].list.findIndex(
        (item: ICommentPlan) => item.id === payload.comment_id
      );
      Vue.set(this.childCommentsList[`answer_${payload.parent_id}`].list, index, {
        ...comment,
        text: payload.text ? payload.text : undefined
      });
    }
  }
  @Mutation
  [PlanningScheduleMutationTypes.DELETE_CHILDREN_COMMENT](payload) {
    const newCommentList = this.childCommentsList[`answer_${payload.parent_id}`]?.list.filter(
      (item: ICommentPlan) => item.id !== payload.comment_id
    );
    this.childCommentsList[`answer_${payload.parent_id}`].list = newCommentList;
    const comment = this.comments?.find((item: ICommentPlan) => item.id === payload.parent_id);
    if (comment) {
      const index = this.comments.findIndex((item: ICommentPlan) => item.id === payload.parent_id);
      Vue.set(this.comments, index, {
        ...comment,
        count_comments: newCommentList.length
      });
    }
  }

  @Action
  async getPlanListAction() {
    this[PlanningScheduleMutationTypes.TOGGLE_PLAN_LIST_LOADER](true);
    const response = await PlanningScheduleService.getPlanAll({
      page: 1,
      per_page: 50
    });
    this[PlanningScheduleMutationTypes.SET_RESPONSE_PLAN_LIST](response);
    this[PlanningScheduleMutationTypes.SET_PLAN_LIST](response.list);
    this[PlanningScheduleMutationTypes.TOGGLE_PLAN_LIST_LOADER](false);
  }
  @Action
  async getPlanAction(planId: number) {
    return new Promise((resolve, reject) => {
      this[PlanningScheduleMutationTypes.TOGGLE_PLAN_INFO_LOADER](true);
      PlanningScheduleService.getPlan(planId)
        .then((plan) => {
          this[PlanningScheduleMutationTypes.SET_CURRENT_PLAN](plan);
          this[PlanningScheduleMutationTypes.TOGGLE_PLAN_INFO_LOADER](false);
          resolve(plan);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  @Action
  async createPlanAction(data: createPlanDataType) {
    const { id } = await PlanningScheduleService.createPlan(data);
    return id;
  }
  @Action
  async editPlanAction(data: editPlanDataType) {
    await PlanningScheduleService.editPlan(data);
    this[PlanningScheduleMutationTypes.EDIT_CURRENT_PLAN](data);
  }
  @Action
  async deletePlanAction(planId: number) {
    await PlanningScheduleService.deletePlan(planId);
    this[PlanningScheduleMutationTypes.DELETE_PLAN_FROM_LIST](planId);
  }
  @Action
  async getStepListAction(params: stepParamsType) {
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LIST_LOADER](true);
    const stepList = await PlanningScheduleService.getSteps(params);
    this[PlanningScheduleMutationTypes.SET_STEP_LIST](stepList);
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LIST_LOADER](false);
  }
  @Action
  async createStepAction(data: IStep) {
    const { id } = await PlanningScheduleService.createStep(data);
    const newStep = {
      ...data,
      id
    };
    this[PlanningScheduleMutationTypes.ADD_STEP](newStep);
  }
  @Action
  async editStepAction(data: editStepDataType) {
    await PlanningScheduleService.updateStep(data);
    this[PlanningScheduleMutationTypes.EDIT_STEP_IN_LIST](data);
  }
  @Action
  async deleteStepAction(stepId: number) {
    await PlanningScheduleService.deleteStep(stepId);
    this[PlanningScheduleMutationTypes.DELETE_STEP_FROM_LIST](stepId);
  }
  @Action
  async getStepLinkListAction(params: stepLinksParamsType) {
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LINKS_LOADER](true);
    const stepLinksList = await PlanningScheduleService.getStepLinks(params);
    this[PlanningScheduleMutationTypes.SET_STEP_LINKS_LIST](stepLinksList);
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LINKS_LOADER](false);
  }
  @Action
  async setStepLinksAction(params: {
    newStepLinkList: Array<IStepLink>;
    stepLinkToDeleteList: Array<number>;
  }) {
    const requestsCreateStepLink: Array<any> = [];
    params.newStepLinkList.forEach((stepLink) => {
      requestsCreateStepLink.push(PlanningScheduleService.createStepLink(stepLink));
    });
    await Promise.all(requestsCreateStepLink);

    const requestDeleteStepLink: Array<any> = [];
    params.stepLinkToDeleteList.forEach((id) => {
      requestDeleteStepLink.push(PlanningScheduleService.deleteStepLink(id));
    });
    await Promise.all(requestDeleteStepLink);
    if (this.currentPlan) this.getStepLinkListAction({ plan_id: this.currentPlan.id });
  }
  @Action
  async calculatePlanAction(planId: number) {
    this[PlanningScheduleMutationTypes.TOGGLE_CALCULATING_LOADER](true);
    PlanningScheduleService.calculatePlan(planId)
      .then(async () => {
        await Promise.all([
          this.getPlanAction(planId),
          this.getStepListAction({ plan_id: planId }),
          this.getStepLinkListAction({ plan_id: planId })
        ]);
        this[PlanningScheduleMutationTypes.TOGGLE_CALCULATING_LOADER](false);
        this.findCriticalPath();
      })
      .catch(() => {
        this[PlanningScheduleMutationTypes.TOGGLE_CALCULATING_LOADER](false);
      });
  }
  @Action
  clearData() {
    this[PlanningScheduleMutationTypes.SET_CURRENT_PLAN](null);
    this[PlanningScheduleMutationTypes.SET_STEP_LIST]([]);
    this[PlanningScheduleMutationTypes.SET_STEP_LINKS_LIST]([]);
    this[PlanningScheduleMutationTypes.SET_BASE_PLAN_LIST]([]);
  }

  // ACCESS USERS //

  @Action
  async createAccessUsersAction(body: accessUsersBodyType) {
    await PlanningScheduleService.createAccessUsers(body);
    if (body.add) this[PlanningScheduleMutationTypes.ADD_ACCESS_USERS](body.add);
    if (body.delete) this[PlanningScheduleMutationTypes.DELETE_ACCESS_USERS](body.delete);
  }

  @Action
  async getAccessUsersAction(body: planParamsType) {
    const users = await PlanningScheduleService.getAccessUsers(body);
    this[PlanningScheduleMutationTypes.ADD_ACCESS_USERS](users);
    if (this.accessUsers && this.accessUsers?.list.length < users.count) {
      body.page = body.page + 1;
      await this.paginationAccessUsersAction(body);
    }
  }

  @Action
  async paginationAccessUsersAction(body: planParamsType) {
    const users = await PlanningScheduleService.getAccessUsers(body);
    this[PlanningScheduleMutationTypes.ADD_ACCESS_USERS](users);
    if (this.accessUsers && this.accessUsers?.list.length < users.count) {
      body.page = body.page + 1;
      await this.paginationAccessUsersAction(body);
    }
  }

  // CRITICAL PATH //

  @Action
  findCriticalPath() {
    let foundPath: Array<IStepLink> = [];
    const findPath = (currentId: number | null, path: Array<IStepLink>) => {
      const stepLinkWithoutReserveList: Array<IStepLink> = [];
      this.stepLinksList.forEach((stepLink) => {
        if (stepLink.step_to === currentId) {
          const step = this.stepList.find(
            (step) => step.id === stepLink.step_from && step.reserve === 0
          );
          if (step) stepLinkWithoutReserveList.push(stepLink);
          if (stepLink.step_from === null) {
            stepLinkWithoutReserveList.push(stepLink);
          }
        }
      });
      if (!stepLinkWithoutReserveList.length) return;
      stepLinkWithoutReserveList.forEach((item) => {
        if (item.step_from !== null) {
          findPath(item.step_from, [...path, item]);
        } else {
          path = [...path, item];
        }
      });
      foundPath = path.length > foundPath.length ? path : foundPath;
    };
    const stepLinksToFinalStepWithoutReserve: Array<IStepLink> = [];
    this.stepLinksList.forEach((stepLink) => {
      if (stepLink.step_to === null) {
        const step = this.stepList.find(
          (step) => step.id === stepLink.step_from && step.reserve === 0
        );
        if (step) stepLinksToFinalStepWithoutReserve.push(stepLink);
        if (stepLink.step_from === null) {
          stepLinksToFinalStepWithoutReserve.push(stepLink);
        }
      }
    });
    stepLinksToFinalStepWithoutReserve.forEach((stepLink) => {
      foundPath = [];
      findPath(stepLink.step_from, []);
      this[PlanningScheduleMutationTypes.SET_CRITICAL_PATH]([...this.criticalPath, foundPath]);
    });
  }

  // BASE PLAN //

  @Action
  async getBasePlanAllAction(planId: number) {
    const basePlanAllResponse = await PlanningScheduleService.getBasePlanAll({
      plan_id: planId,
      page: 1,
      per_page: 50
    });
    this[PlanningScheduleMutationTypes.SET_BASE_PLAN_LIST](basePlanAllResponse.list);
  }
  @Action
  async getBasePlanAction(planId: number) {
    this[PlanningScheduleMutationTypes.TOGGLE_PLAN_INFO_LOADER](true);
    const basePlan = await PlanningScheduleService.getBasePlan(planId);
    this[PlanningScheduleMutationTypes.SET_CURRENT_BASE_PLAN](basePlan);
    this[PlanningScheduleMutationTypes.TOGGLE_PLAN_INFO_LOADER](false);
  }
  @Action
  async createBasePlanAction(data: createBasePlanType) {
    const { id } = await PlanningScheduleService.createBasePlan(data);
    const newBasePlanItem = {
      id,
      name: data.name
    };
    this[PlanningScheduleMutationTypes.ADD_TO_BASE_PLAN_LIST](newBasePlanItem);
  }
  @Action
  async deleteBasePlanAction(basePlanId: number) {
    await PlanningScheduleService.deleteBasePlan(basePlanId);
    this[PlanningScheduleMutationTypes.DELETE_FROM_BASE_PLAN_LIST](basePlanId);
    if (this.basePlanToShow?.id === basePlanId) {
      this[PlanningScheduleMutationTypes.SET_BASE_PLAN_TO_SHOW](null);
    }
  }
  @Action
  async getBaseStepListAction(params: stepParamsType) {
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LIST_LOADER](true);
    const stepList = await PlanningScheduleService.getSteps(params);
    this[PlanningScheduleMutationTypes.SET_BASE_STEP_LISTS]({
      versionId: params.version_id,
      baseStepList: stepList
    });
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LIST_LOADER](false);
  }
  @Action
  async getBaseStepLinkListAction(params: stepLinksParamsType) {
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LINKS_LOADER](true);
    const stepLinkList = await PlanningScheduleService.getStepLinks(params);
    this[PlanningScheduleMutationTypes.SET_BASE_STEP_LINK_LISTS]({
      versionId: params.version_id,
      baseStepLinkList: stepLinkList
    });
    this[PlanningScheduleMutationTypes.TOGGLE_STEP_LINKS_LOADER](false);
  }
  get baseStepList() {
    return (version_id) => this.baseStepLists[`version_${version_id}`];
  }
  get baseStepLinkList() {
    return (version_id) => this.baseStepLinkLists[`version_${version_id}`];
  }
  @Action
  async showBasePlanAction(params: { planId: number; basePlanItem: basePlanItemType } | null) {
    if (!params) {
      this[PlanningScheduleMutationTypes.SET_BASE_PLAN_TO_SHOW](null);
      return;
    }
    this[PlanningScheduleMutationTypes.SET_BASE_PLAN_TO_SHOW](params.basePlanItem);
    if (!this.baseStepLists[`version_${params.basePlanItem.id}`]) {
      this.getBaseStepListAction({ plan_id: params.planId, version_id: params.basePlanItem.id });
    }
  }
  @Action
  async updateBasePlanAction(editedBasePlan: { id: number; name: string }) {
    await PlanningScheduleService.updateBasePlan(editedBasePlan);
    this[PlanningScheduleMutationTypes.EDIT_BASE_PLAN](editedBasePlan);
  }

  // COMMENTS //

  @Action
  async getPlanCommentsAction(id: number) {
    const comments = await PlanningScheduleService.getPlanComments({
      id: id,
      page: this.commentPage,
      per_page: 15
    });
    this[PlanningScheduleMutationTypes.GET_PLAN_COMMENTS](comments.list);
    this[PlanningScheduleMutationTypes.SET_COMMENT_PAGE](comments.page + 1);
    this[PlanningScheduleMutationTypes.SET_COMMENT_COUNT](comments.count);
  }
  @Action
  async getAnswerPlanCommentsAction(params: { id: number; parent_id: number }) {
    const comments = await PlanningScheduleService.getPlanComments({
      id: params.id,
      parent_id: params.parent_id ? params.parent_id : null,
      page: 1,
      per_page: 50
    });
    this[PlanningScheduleMutationTypes.SET_CHILDREN_COMMENT]({ id: params.parent_id, comments });
  }
  @Action
  async createPlanCommentAction(params: commentParamsType) {
    this[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](true);
    const comment = await PlanningScheduleService.createPlanComment({
      id: params.id,
      text: params.text,
      parent_id: params.parent_id
    }).catch(() => EventBus.$emit("offLoader"));
    const comments = {
      id: comment.id,
      text: params.text,
      parent_id: params.parent_id,
      user_id: params.author,
      count_comments: 0,
      count_likes: 0,
      files: [],
      created_at: moment().toISOString()
    };
    if (params.parent_id) {
      this[PlanningScheduleMutationTypes.SET_CHILDREN_COMMENT]({ id: params.parent_id, comments });
    } else {
      this[PlanningScheduleMutationTypes.GET_PLAN_COMMENTS](comments);
    }
    if (this.currentPlan)
      this[PlanningScheduleMutationTypes.EDIT_CURRENT_PLAN]({
        id: this.currentPlan?.id,
        count_comments: this.currentPlan?.count_comments + 1
      });
    this[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](false);
  }
  @Action
  async updatePlanCommentAction(params: updateCommentType) {
    this[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](true);
    const comment = await PlanningScheduleService.updatePlanComment(params).catch(() =>
      EventBus.$emit("offLoader")
    );
    if (comment) {
      if (params.parent_id) {
        this[PlanningScheduleMutationTypes.UPDATE_CURRENT_ANSWER_COMMENTS](params);
      } else {
        this[PlanningScheduleMutationTypes.UPDATE_PLAN_COMMENT](params);
      }
      this[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](false);
    }
  }
  @Action
  async deletePlanCommentAction(params: deleteCommentType) {
    const success = await PlanningScheduleService.deletePlanComment(params);
    if (success) {
      if (params.parent_id) {
        this[PlanningScheduleMutationTypes.DELETE_CHILDREN_COMMENT](params);
      } else {
        this[PlanningScheduleMutationTypes.DELETE_PLAN_COMMENT](params.comment_id);
      }
      if (this.currentPlan)
        this[PlanningScheduleMutationTypes.EDIT_CURRENT_PLAN]({
          id: this.currentPlan?.id,
          count_comments: this.currentPlan?.count_comments - 1
        });
      this[PlanningScheduleMutationTypes.SET_CURRENT_COMMENT](null);
    }
  }
}

export default getModule(PlanningScheduleModule);
