<template>
  <div
    class="message__wrap"
    :class="{
      message__wrap_self: isSelfMassage
    }"
    :id="setId"
  >
    <div
      class="message__container"
      @dblclick="replyMessage"
      :class="{
        message__container_picked: isPickedMessage
      }"
    >
      <Avatar :user="setAuthor" />
      <div class="message" @contextmenu="handleRightClick">
        <h4 class="message__author">
          <div class="message__container-author">
            <span class="message__author-name">{{ setAuthor.full_name }}</span>
            <span class="message__author-role">[{{ setAuthor.role_name }}]</span>
          </div>

          <span
            class="message__time"
            :style="{
              marginLeft: !isSelfMassage && !source.has_thread_id ? 'auto' : '',
              marginRight: isSelfMassage && !source.has_thread_id ? 'auto' : '0'
            }"
          >
            <span>{{ setTimeFormat }}</span>
            <i class="icon-check-all" :class="{ 'icon-check-all_read': isReadMessage }"></i>
          </span>
        </h4>
        <div class="message__thread" v-if="source.has_thread_id" @click="openThread">
          <span class="message__comment"> Комментарии </span>
          <span
            class="message__comment-new"
            v-if="source.thread && source.thread.count_unread_messages"
          ></span>
        </div>
        <div class="message__container-main">
          <h4
            class="message__author"
            :class="{ message__author_forwarding: isForwarding }"
            v-if="isForwarding"
          >
            <span class="message__forwarding">Переслано от </span>
            <span class="message__forwarding-name">{{ source.shareAuthorInfo.full_name }}</span>
            <span class="message__forwarding-role" v-if="source.shareAuthorInfo.role_name"
              >[{{ source.shareAuthorInfo.role_name }}]
            </span>
          </h4>
          <MessageReply v-if="isReply" :message="source.reply_message" />
          <div class="message__text-wrap">
            <p
              class="message__text"
              v-html="setHTMLText"
              v-if="source.text || !source.has_thread_id"
            ></p>
            <!--          <span class="message__time" v-if="!source.has_thread_id">-->
            <!--            <span>{{ setTimeFormat }}</span>-->
            <!--            <i-->
            <!--              class="icon-check-all"-->
            <!--              v-if="isSelfMassage"-->
            <!--              :class="{ 'icon-check-all_read': isReadMessage }"-->
            <!--            ></i>-->
            <!--          </span>-->
          </div>
          <ul class="message__image-list" v-if="imageList.length">
            <li
              v-for="(item, index) in imageList"
              :key="index"
              :class="`message__image-item_${index}`"
              class="message__image-item"
            >
              <MessageFile :file="item" :index="index" />
            </li>
          </ul>
          <ul
            class="message__file-list"
            v-if="fileList.length"
            :class="{
              'message__file-list_no-text': !source.text.length,
              'message__file-list_image': imageList.length
            }"
          >
            <li v-for="(item, index) in fileList" :key="index" class="message__file-item">
              <MessageFile :file="item" :index="index" />
            </li>
          </ul>
          <span class="message__check" v-if="isPickedMessage"><i class="icon-check"></i> </span>
        </div>
        <ul class="message__short-cut">
          <li class="message__short" @click="replyMessage" v-if="isReadonly">
            <i class="icon-forum"></i>
            Ответить
          </li>
          <li class="message__short" @click="resendMessage">
            <i class="icon-reply-arrow"></i>
            Переслать
          </li>
          <li class="message__short" @click="addReaction" v-if="!isPin">
            <i class="icon-emoji"></i>
            Отреагировать
          </li>
        </ul>
      </div>
    </div>
    <div
      class="message__reaction"
      v-if="setReaction && !isPin"
      :class="{ message__reaction_person: !showAvatar }"
    >
      <ReactionList :reactions="setReaction" :message="source" :isSelfMassage="isSelfMassage" />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";
import UrlMixin from "@/helpers/UrlMixin";
import elementInViewport from "@/modules/Messenger/mixins/elementInViewport.mixin";
import { mapActions, mapState } from "vuex";
import MessageReply from "@/modules/Messenger/components/Message/MessageReply";
import goToMessage from "@/modules/Messenger/mixins/goToMessage.mixin";
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import MessageFile from "@/modules/Messenger/components/Message/MessageFile";
import ReactionList from "@/modules/Messenger/components/Message/Reaction/ReactionList";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";

export default {
  name: "PersonMessage",
  mixins: [UrlMixin, elementInViewport, goToMessage],
  props: {
    source: {
      type: Object,
      default: () => {}
    },
    isPin: {
      type: Boolean,
      default: false
    }
  },
  components: { ReactionList, MessageFile, MessageReply, Avatar },
  data() {
    return {
      imageList: [],
      fileList: [],
      timerId: null,
      el: null,
      fileTypes: [
        "image/apng",
        "image/avif",
        "image/gif",
        "image/png",
        "image/svg+xml",
        "image/jpeg"
      ],
      list: [
        { name: "Выделить", val: "pick", icon: "circle-check" },
        { name: "Переслать", val: "send", icon: "reply-arrow" }
      ]
    };
  },
  created() {},
  mounted() {
    const idList = this.threadMode ? "#list-comment" : "#list-dynamic";
    const id = this.threadMode ? "#list-comment" : ".chat-main";
    this.setDefault(id, idList);
    this.sortFiles(this.source.files_in_message);
    this.el = document.querySelector(`#message_${this.source.id}`);
    this.observer.observe(this.el);
  },
  beforeDestroy() {
    this.observer.unobserve(this.el);
  },
  methods: {
    ...mapActions("MessengerModule", [
      "switchModal",
      "getMessage",
      "replyCurrentMessage",
      "resendCurrentMessage",
      "observeModalItem",
      "addMessageReaction",
      "threadOpen"
    ]),
    handleRightClick(event) {
      event.preventDefault();
      const selector = this.threadMode ? "#list-comment" : "#list-dynamic";
      const scrollContainer = document.querySelector(selector);
      const container = scrollContainer.parentNode;
      const windowInnerWidth = window.innerWidth;
      //учет бокового меню при определении позиции клика
      const clickPositionX = windowInnerWidth > 768 ? event.x - 99 : event.x;
      this.switchModal({
        show: !this.showModal,
        container: container,
        x: clickPositionX,
        y: event.y,
        message: this.source,
        isLeft: false,
        optionList: this.setList,
        scrollContainer,
        channelItem: null
      });
      //убираем выделение текста при долгом тапе в адаптивной версии
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else {
        // старый IE
        document.selection.empty();
      }
    },
    replyMessage() {
      this.replyCurrentMessage(this.source);
    },
    resendMessage() {
      this.resendCurrentMessage(this.source);
    },
    addReaction() {
      this.addMessageReaction(this.source);
    },
    handleView(isDownDirection) {
      const message = this.threadMode ? this.threadsMessages : this.messages;
      const index = message.findIndex((item) => item.id === this.source.id);
      const lastIndex = message.length - 10;
      const firstIndex = 9;
      if (firstIndex === index && this.isVisible && !isDownDirection) {
        const payload = {
          id: this.source.channel_id,
          direction: "up",
          last_message_id: message[0].id,
          push: true
        };
        this.getMessage(payload);
      }
      if (lastIndex === index && this.isVisible && isDownDirection) {
        const payload = {
          id: this.source.channel_id,
          direction: "down",
          last_message_id: message[message.length - 1].id,
          push: true
        };
        this.getMessage(payload);
      }
    },

    sortFiles(fileList) {
      this.imageList = [];
      this.fileList = [];
      fileList.forEach((file) => {
        if (this.isImage(file)) {
          this.imageList.push(file);
        } else {
          this.fileList.push(file);
        }
      });
    },
    openThread() {
      this.threadOpen(this.source);
    },
    isImage(file) {
      return file.type === "photo" || file.type === "gif";
    },
    setTime(time) {
      return this.isToday
        ? moment(time).format("LT")
        : `${moment(time).format("L")} ` + `${moment(time).format("LT")}`;
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "showModal",
      "users",
      "currentChat",
      "showPin",
      "pickMode",
      "threadMode",
      "threadsMessages",
      "messages",
      "pickedMessage"
    ]),
    showAvatar() {
      return this.isGroupChat || (this.showPin && this.isPin);
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isSelfMassage() {
      return this.source.user_id === this.getUserId;
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    setTimeFormat() {
      moment.locale("ru");
      return this.setTime(this.source.created_at);
    },
    isToday() {
      return moment().format("L") === moment(this.source.created_at).format("L");
    },
    isUpdateMessage() {
      return (
        moment(this.source.created_at).format("LTS") ===
        moment(this.source.updated_at).format("LTS")
      );
    },
    isReply() {
      return !!this.source.reply_message;
    },

    isReadonly() {
      if (
        this.currentChat.channel.type === 2 &&
        this.currentChat.channel.chat_settings.is_read_only
      ) {
        return this.currentChat.channel.is_moderator || this.isAdminChat;
      } else {
        return true;
      }
    },
    setReaction() {
      if (this.source.reactions) {
        let reaction = JSON.parse(this.source.reactions);
        Object.keys(reaction).forEach((item) => {
          if (reaction[item] === 0) {
            delete reaction[item];
          }
        });
        return isObjectEmptyChecker(reaction) ? null : reaction;
      } else {
        return null;
      }
    },
    setHTMLText() {
      return this.setTextUrl(this.source.text);
    },
    setAuthor() {
      return this.source.userInfo;
    },
    isGroupChat() {
      return this.currentChat.channel.type === 2;
    },
    isPickedMessage() {
      return this.pickedMessage.some((item) => item.id === this.source.id) && this.pickMode;
    },
    isReadMessage() {
      return this.source.id <= this.currentChat.channel.is_read_companion;
    },
    isForwarding() {
      return this.source.share_author_id;
    },
    isSystemMessage() {
      return this.source.type === "system";
    },
    setId() {
      if (this.isPin) {
        return "message_pin_" + this.source.id;
      } else {
        return "message_" + this.source.id;
      }
    },
    setList() {
      let list = this.list.map((item) => item);
      if (this.isPin) {
        list.unshift({ name: "Перейти к сообщению", val: "go-message", icon: "forum" });
      }
      if (
        (this.currentChat.channel.type === 2 &&
          this.currentChat.channel?.chat_settings.is_read_only &&
          (this.currentChat.channel.is_moderator || this.isAdminChat)) ||
        (this.currentChat.channel.type === 2 &&
          !this.currentChat.channel?.chat_settings.is_read_only) ||
        this.currentChat.channel.type === 1
      ) {
        list.push({ name: "Ответить", val: "reply", icon: "forum" });
      }
      if (
        !this.threadMode &&
        this.currentChat.channel.type === 2 &&
        !this.currentChat.channel?.chat_settings.is_read_only
      ) {
        list.push({ name: "Комментировать", val: "comment", icon: "message" });
      }
      if (
        !this.threadMode &&
        (this.currentChat.channel.type === 1 ||
          this.currentChat.channel.type === 3 ||
          (this.currentChat.channel.type === 2 &&
            !this.currentChat.channel?.chat_settings.is_read_only) ||
          (this.currentChat.channel.type === 2 &&
            (this.currentChat.channel.is_moderator || this.isAdminChat)))
      ) {
        if (this.source.is_pinned) {
          list.push({ name: "Открепить", val: "pin", icon: "pin" });
        } else {
          list.push({ name: "Закрепить", val: "pin", icon: "unpin" });
        }
      }
      if (this.source.user_id === this.getUserId && !this.source.share_author_id && !this.isPin) {
        list.push({ name: "Редактировать", val: "edit", icon: "edit" });
      }
      if (
        this.source.user_id === this.getUserId ||
        this.currentChat.channel.is_moderator ||
        this.isAdminChat
      ) {
        list.push({ name: "Удалить", val: "delete", icon: "delete" });
      }
      if (this.windowWidth <= 1366) {
        list.push({ name: "Отреагировать", val: "reaction", icon: "emoji", payload: this.source });
      }
      return list;
    }
  },
  watch: {
    source: function (source) {
      this.sortFiles(source.files_in_message);
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.message {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 4px 8px;
  color: $text-default;
  position: relative;
  transition: border-color 0.3s ease;
  @media screen and (max-width: 1376px) {
    max-width: 100%;
  }
  @media (hover: hover) {
    &:hover {
      border-color: $chat-editor;
      .message__short-cut {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__container-main {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #fafafe;
    border-radius: 0px 16px 16px 16px;
    padding: 8px;
  }
  &__container-author {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: $lg) {
      //flex-direction: column;
      //align-items: flex-start;
    }
  }
  &__wrap_self {
    align-items: flex-end;

    .message {
      &__container-author {
        margin-left: 5px;
        justify-content: end;
        align-items: flex-end;

        @media screen and (max-width: $lg) {
          align-items: flex-end;
        }
      }
    }
    .message__container-main {
      border-radius: 8px 0px 8px 8px;
    }
    .message__check {
      left: unset;
      right: calc(100% + 25px);
    }
    .message__author_forwarding {
      display: flex;
      flex-direction: row !important;
    }
    .message__container {
      display: flex;
      flex-direction: row-reverse;
    }
    .message__reaction {
      margin-left: 0;
      margin-right: 33px;
    }
    .message__short-cut {
      right: unset;
      @media screen and (max-width: $lg) {
        right: -20px;
      }
      @media screen and (max-width: $md) {
        right: unset;
      }
      @media screen and (max-width: $xs) {
        left: -65px;
      }
    }
    .message__author {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    .message__thread {
      margin-left: 8px;
      margin-right: auto;
    }
    .message__time {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 5px !important;
      padding-left: 0;
      margin-left: 0;
      i {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
  &__short-cut {
    all: unset;
    position: absolute;
    display: flex;
    top: 100%;
    right: 8px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    @media screen and (max-width: $lg) {
      right: unset;
    }
    @media screen and (max-width: $xs) {
      left: -40px;
    }
    i {
      font-size: 14px;
      color: $chat-editor;
    }
  }
  &__short {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #5551f9;
    margin-right: 16px;
    cursor: pointer;
    @media screen and (max-width: $xs) {
      margin-right: 5px;
    }
    i {
      margin-right: 4px;
    }
  }
  &__thread {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 8px;

    i {
      color: $text-default;
      font-size: 14px;
    }
  }
  &__comment-new {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $message-gradient;
    margin-left: 3px;
    transform: translate(2px, -4px);
  }
  &__comment {
    margin-right: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #5551f9;
    cursor: pointer;
    @media screen and (max-width: $lg) {
      line-height: 14px;
    }
  }
  &__forwarding {
    margin-right: 5px;
  }
  &__forwarding-name {
    margin-right: 5px;
  }
  &__forwarding-role {
    color: #a7a9c0;
    @media screen and (max-width: $lg) {
      display: none;
    }
    @media screen and (max-width: $md) {
      display: flex;
    }
    @media screen and (max-width: $xs) {
      display: none;
    }
  }
  &__check {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 25px);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $icon-success;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-check {
      font-size: 14px;
      color: #fff;
    }
  }
  &_only-file {
    padding: 0;
  }
  &__image-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-template-areas:
      "First First"
      "First First"
      "Last Last"
      "Last Last";
  }
  &__image-item {
    height: 120px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    /deep/ {
      img {
        width: 160px;
        height: 120px;
      }
    }
  }
  &__file-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 8px;
    flex-wrap: wrap;
    max-width: 100%;
    width: fit-content;
    align-items: flex-start;
    &_image {
      margin-top: 16px;
    }
    &_no-text {
      margin-bottom: 0;
    }
  }
  &__file-item {
    margin-right: 8px;
    margin-bottom: 8px;
    &:nth-child(2n) {
      margin-left: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__reaction {
    margin-left: 60px;
    margin-top: 12px;
    max-width: calc(60% - 45px);
    width: fit-content;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    position: relative;
    border: 1px solid transparent;
    padding: 8px;
    border-radius: 16px;
    width: 65%;
  }
  &__wrap {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    flex-flow: column;
    @media screen and (max-width: $lg) {
      margin-left: 0;
      margin-right: 0;
    }
    @media screen and (max-width: $md) {
      margin-left: 16px;
      margin-right: 16px;
    }
    @media screen and (max-width: $xs) {
      margin-left: 0;
      margin-right: 0;
    }
    &_padding {
      padding-bottom: 15px;
    }
    /deep/ {
      .avatar {
        height: 44px;
        margin-top: 6px;
        &__img {
          align-self: flex-end;
          width: 40px;
          height: 40px;
          border: 2px solid #fff;
          margin-right: 4px;
        }
      }
    }
  }
  &__text {
    max-width: 40vw;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #343748;
    @media screen and (max-width: $md) {
      max-width: 100vw;
    }

    /deep/ {
      * {
        all: unset;
        margin: 0 !important;
        padding: 0 !important;
        overflow-wrap: break-word !important;
        white-space: pre-wrap !important;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: 400 !important;
      }
    }
    /deep/ {
      a {
        color: $text-default;
        cursor: pointer;
      }
    }
  }
  &__author {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 21px;
    color: $text-default;
    letter-spacing: -0.3px;
    font-weight: 500;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: $lg) {
      line-height: 14px;
    }

    @media screen and (max-width: $xs) {
      align-items: center;
    }
    &_forwarding {
      color: $chat-editor;
      justify-content: flex-start !important;
    }
  }
  &__author-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #343748;
    white-space: nowrap;
    margin-right: 5px;
    @media screen and (max-width: $lg) {
      line-height: 15px;
    }
  }
  &__author-role {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #a7a9c0;
    white-space: nowrap;
    margin-right: 5px;
    @media screen and (max-width: 850px) {
      display: none;
    }
    @media screen and (max-width: $md) {
      display: flex;
    }
    @media screen and (max-width: $lg) {
      line-height: 15px;
    }
    @media screen and (max-width: $xs) {
      display: none;
    }
  }
  &__text-wrap {
    position: relative;
    margin-bottom: 6px;
  }
  &__time {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #a7a9c0;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    margin-left: 5px !important;
  }
  &__image {
    max-width: 380px;
    object-fit: cover;
    object-position: 50% 20%;
  }
  .icon-check-all {
    font-size: 14px;
    margin-left: 8px;
    color: #a7a9c0;
    transition: color 0.3s ease-in;
    &_read {
      color: $chat-editor;
    }
  }
}
</style>
