


































import { Component, Mixins, Watch } from "vue-property-decorator";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList.vue";
import draggableModals from "@/aopV2/modules/Calendar/helper/draggableModals";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal.vue";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({
  components: { ButtonNewDesign, MainModal, Skeleton, SelectList }
})
export default class CalendarUsers extends Mixins(draggableModals) {
  users: Array<number> = [];
  localStorage: any = null;

  mounted() {
    this.users = this.saveUsers ? this.saveUsers : [];
  }

  //methods
  hideModal() {
    this.$emit("hideModal");
  }

  handleChangeUser(selected) {
    this.users = selected;
  }

  setCookieValue(val) {
    this.localStorage = window.localStorage;
    const setting = JSON.parse(this.localStorage.getItem("calendar-filters"));
    const option = {
      ...(setting ? setting : {}),
      saveUsers: val
    };
    window.localStorage.setItem("calendar-filters", JSON.stringify(option));
  }

  acceptHandler() {
    this.setCookieValue(this.users);
    CalendarModule2.getFilterUsers(this.users);
    CalendarModule2.toggleShowUsersModal(false);
  }

  get getUsers() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "avatar", "id", "is_active", "roles"])
      .filter((item) => item.is_active);
  }

  get saveUsers() {
    return CalendarModule2.users;
  }
}
