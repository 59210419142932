





















import { Component, Prop, Vue } from "vue-property-decorator";
import Select from "@/modules/UiKit/components/Select/Select.vue";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput";
import { isString } from "@/helpers/helperFunction";

@Component<InputSelect>({
  components: { TextInput, ActionList, Select },
  watch: {
    select() {
      this.selected = isString(this.select) ? this.select : this.select.name;
    }
  }
})
export default class InputSelect extends Vue {
  @Prop({ default: "select", required: true, type: String }) unique!: string;
  @Prop({ default: "", type: String }) label!: string;
  @Prop({ default: () => [], required: true, type: Array }) list!: Array<any>;
  @Prop({
    default: () => {},
    type: Object
  })
  select!: any;
  isShowList: boolean = false;
  selected: string = "";

  mounted() {
    this.selected = isString(this.select) ? this.select : this.select.name;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  closeList() {
    this.isShowList = false;
  }

  handleItem(item) {
    this.selected = item.name;
    this.$emit("change", item);
    this.closeList();
  }

  get fieldId() {
    return `field_${this.unique}`;
  }

  get listId() {
    return `list_${this.unique}`;
  }
}
