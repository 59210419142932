<template>
  <div class="new-section-bg">
    <Widget v-if="loading" type="loader" />
    <div v-else class="new-section-bg__container">
      <div v-if="listAll.length" class="section news-section">
        <div class="section-header">
          <div class="label">Новое</div>
          <div class="new-slide-controller">
            <i class="icon-left" @click="prevTab"></i>
            <i class="icon-right" @click="nextTab"></i>
          </div>
        </div>
        <div class="content">
          <!--          @TODO: Понять почему item показывается в верстке-->
          <Widget
            v-for="item in listSlider[activeTab]"
            :key="item.id"
            :item="item"
            :label="true"
            :type="item.type"
          ></Widget>
        </div>
      </div>
      <div v-if="regulationsList.length" class="section regulations-section">
        <div class="section-header">
          <div class="label" @click="$router.push('Regulations')">Документы компании</div>
          <div class="show-all" @click="$router.push('Regulations')">Смотреть все</div>
        </div>
        <div class="content">
          <RegulationComponent
            @updateInfoRegulations="getRegulationsInfo"
            v-for="regulation in regulationsList"
            :key="regulation.id"
            :item="regulation"
            label
          />
          <div>...</div>
        </div>
      </div>
      <div v-if="articlesList.length" class="section articles-section">
        <div class="section-header">
          <div class="label" @click="$router.push('Articles')">Статьи</div>
          <div class="show-all" @click="$router.push('Articles')">Смотреть все</div>
        </div>
        <div class="content">
          <ArticleComponent
            @updateInfoArticles="getArticlesInfo"
            v-for="article in articlesList"
            :key="article.id"
            :item="article"
          />
        </div>
      </div>
      <div v-if="booksList.length" class="section books-section">
        <div class="section-header">
          <div class="label" @click="$router.push('Books')">Книги</div>
          <div class="show-all" @click="$router.push('Books')">Смотреть все</div>
        </div>
        <div class="content">
          <BookComponent
            @updateInfoBook="getBooksInfo"
            v-for="book in booksList"
            :key="book.id"
            :item="book"
          />
        </div>
      </div>
      <div
        v-if="
          !listAll.length && !booksList.length && !regulationsList.length && !articlesList.length
        "
        class="empty-new-bg"
      >
        <img
          :src="require('../../../assets/img/iconsNew/KnowledgeBaseIcons/empty.svg')"
          alt="123"
        />
        Еще ничего не публиковалось.
      </div>
    </div>
  </div>
</template>

<script>
import { Model } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";
import moment from "moment";

import BookComponent from "@/components/KnowledgeBaseComponents/Book/BookComponent";
import RegulationComponent from "@/components/KnowledgeBaseComponents/Regulation/RegulationComponent";
import ArticleComponent from "@/components/KnowledgeBaseComponents/Article/ArticleComponent";
import Widget from "@/components/Widgets/Widget";

export default {
  name: "NewSection",
  mixins: [],
  props: [],
  components: { Widget, RegulationComponent, BookComponent, ArticleComponent },
  data() {
    return {
      loading: true,
      activeTab: 0,
      booksList: [],
      regulationsList: [],
      articlesList: [],
      listAll: [],
      listSlider: []
    };
  },
  beforeDestroy() {
    this.$root.$off("updateLikeInfoRoot");
  },
  mounted() {
    this.getBooksInfo();
    this.$root.$on("updateLikeInfoRoot", () => {
      this.getBooksInfo();
      this.getArticlesInfo();
      this.getRegulationsInfo();
    });
  },
  methods: {
    async getBooksInfo() {
      const ModelChannel = new Model({
        model: "Book",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 5,
            _order: { datetime_of_creation: "desc" },
            _with: JSON.stringify(["current_user_like"])
          }
        }
      });
      await ModelChannel.init();
      this.booksList = ModelChannel.allItems;
      this.booksList.forEach((i) => {
        i.type = "book";
      });
      this.getArticlesInfo();
    },
    async getArticlesInfo() {
      const ModelChannel = new Model({
        model: "Article",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 5,
            _order: { datetime_of_creation: "desc" },
            _with: JSON.stringify(["current_user_like"])
          }
        }
      });
      await ModelChannel.init();
      this.articlesList = ModelChannel.allItems;
      this.articlesList.forEach((i) => {
        i.type = "article";
      });
      this.getRegulationsInfo();
    },
    async getRegulationsInfo() {
      const ModelChannel = new Model({
        model: "Regulation",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 5,
            _order: { datetime_of_creation: "desc" },
            _with: JSON.stringify(["current_user_like"])
          }
        }
      });
      await ModelChannel.init();
      this.regulationsList = ModelChannel.allItems;
      this.listAll = [];
      this.listAll = this.listAll.concat(this.booksList, this.regulationsList, this.articlesList);
      this.listAll.sort((a, b) => {
        return moment(b["datetime_of_creation"]) - moment(a["datetime_of_creation"]);
      });
      for (let i = 0; i < Math.ceil(this.listAll.length / 5); i++) {
        this.listSlider[i] = this.listAll.slice(i * 5, i * 5 + 5);
      }
      this.loading = false;
    },
    nextTab() {
      if (this.activeTab === this.listSlider.length - 1) {
        this.activeTab = 0;
      } else {
        this.activeTab++;
      }
    },
    prevTab() {
      if (this.activeTab <= 0) {
        this.activeTab = this.listSlider.length - 1;
      } else {
        this.activeTab--;
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";

.new-section-bg {
  .loader-bg {
    margin: 30vh auto 0 auto;
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-text-accent;
    cursor: pointer;

    transition: all 0.2s ease;
  }

  .label:hover {
    color: $color-button-hover;
  }

  .show-all {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: $color-charts-purple-1;
    cursor: pointer;
  }

  .new-slide-controller {
    display: flex;
    align-items: center;

    i {
      cursor: pointer;
      transition: all 0.2s ease;
      color: $color-interactive;
      font-size: 20px;

      &:first-child {
        margin-right: 19px;
      }

      &:hover {
        color: $color-button-hover;
      }

      &:active {
        color: $color-button-click;
      }
    }
  }
}

.new-section-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 30px;

  .section {
    .content {
      flex-wrap: wrap;
      margin-bottom: 32px;
      margin-top: 8px;
      display: flex;
      align-items: flex-end;
      .book-bg {
        margin-right: 24px;
        margin-top: 16px;
      }

      .article-component {
        margin-right: 24px;
        margin-top: 16px;
      }

      .regulation-component {
        margin-right: 24px;
        margin-top: 16px;
      }
    }
  }

  .news-section {
    .section-header {
      .label {
        color: $color-text-accent !important;
      }
    }
  }

  .empty-new-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: $color-text-primary-1;
    margin-top: 20%;

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
