<template>
  <div class="tabs">
    <ul class="tabs__list">
      <router-link
        v-for="(tab, idx) in tabs"
        :key="`tab_${idx}`"
        :to="{ name: tab.path }"
        class="tabs__item"
      >
        {{ tab.label }}
        <span v-if="tab.path === 'VacationRequests'">({{ count }})</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsRouter",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    count: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      activeTab: null
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs {
  font-family: "SF";

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    @media (max-width: $lg) {
      width: 100%;
      overflow-x: scroll;
    }
  }

  &__item {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $text-disabled;
    transition: all 0.3s ease;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: $sm) {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-right: 36px;

      @media (max-width: $sm) {
        margin-right: 16px;
      }
    }

    &:hover {
      color: $text-subdued;
    }
  }

  .router-link-active {
    color: $text-subdued;
  }
}
</style>
