


















import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import CheckboxInput from "@/aopV2/components/UIComponents/Input/Check/Checkbox/CheckboxInput";
import ActionList, { IActionItem } from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";

@Component({
  components: { ActionList, CheckboxInput }
})
export default class LocationItem extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  location!: any;
  @Prop({
    type: Number,
    default: 0
  })
  locationLength!: number;
  @Prop({
    type: Array,
    default: () => []
  })
  selectedLocations!: Array<any>;
  @Prop({
    type: Boolean,
    default: false
  })
  admin!: boolean;

  actionList: Array<IActionItem> = [
    // {
    //   name: "Просмотр",
    //   id: 1,
    //   type: "show"
    // },
    {
      name: "Редактировать",
      id: 2,
      type: "edit"
    },
    {
      name: "Удалить",
      id: 3,
      type: "delete"
    }
  ];
  currentType: IActionItem = {
    name: "Редактировать",
    id: 2,
    type: "edit"
  };
  isShowList: boolean = false;

  selectItem() {
    this.$emit("setActiveLocation", this.location);
  }

  setCurrentType(type) {
    this.currentType = type;
    // if (type.type === "show") {
    //   CalendarModule2.setCurrentLocation(this.location);
    //   CalendarModule2.toggleShowLocationModal({ show: true, mode: "show" });
    // }
    if (type.type === "edit") {
      CalendarModule2.setCurrentLocation(this.location);
      CalendarModule2.toggleShowLocationModal({ show: true, mode: "edit" });
    }
    if (type.type === "delete") {
      this.confirmDeleteLocation();
    }
    this.closeList();
  }

  confirmDeleteLocation() {
    ModalModule.setConfirmSetting({
      title: "Удалить кабинет?",
      text: "Все мероприятия проводимые в этом кабинете будут сохранены.",
      confirmButton: "Удалить",
      cancelButton: "Отменить",
      handleConfirm: () => this.deleteLocation(),
      handleCancel: () => this.cancelConfirm()
    });
    ModalModule.toggleConfirmation(true);
  }

  cancelConfirm() {
    ModalModule.setConfirmSetting(null);
    ModalModule.toggleConfirmation(false);
  }

  deleteLocation() {
    CalendarModule2.deleteLocationAction({
      id: this.location.id
    });
    this.cancelConfirm();
  }

  closeList() {
    this.isShowList = false;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  get isSelected() {
    return this.selectedLocations.some((item) => item.id === this.location.id);
  }
}
