import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import { domain } from "@/globalVariables";
import router from "@/router";

import ErrorObserver from "@/api/Error";
import ProblemDto from "@/dto/ProblemDto";
import store from "@/store/index";
import NotificationObserver from "@/helpers/UserNotification";
import TaskDto from "@/dto/TaskDto";
import httpClient from "@/api/client/httpClient";

const actionsIspProblem = {
  getProblemInfoData(_, payload) {
    return httpClient({
      url: `${domain}/monolit/ISPProblem/actionGetItem/${payload}`,
      method: "GET",
      params: {
        _with: JSON.stringify([
          "status",
          "period:id,name,end_date,can_add_problems",
          "group",
          "customerUser",
          "current_viewer_user",
          "creator",
          "viewer_users",
          "taskExecutors",
          "ResponsibleUser",
          "criticality",
          "attachmentFiles",
          "parentProblem",
          "childrenProblems"
        ])
      }
    });
  },
  getFullDataOnProblem({ commit }, payload) {
    return httpClient({
      url: `${domain}/monolit/ISPProblem/actionGetItem/${payload.id}`,
      method: "GET",
      params: {
        _with: JSON.stringify([
          "period:id,name,end_date,can_add_problems",
          "taskExecutors",
          "creator",
          "criticality",
          "viewer_users",
          "current_viewer_user",
          "attachmentFiles",
          "parentProblem",
          "childrenProblems"
        ])
      }
    }).then((res) => {
      commit(types.SET_CURRENT_INFO_PROBLEM, {
        data: { ...payload, ...res.data.data }
      });
      commit(types.SHOW_CURRENT_INFO_PROBLEM, true);
    });
  },
  getHistoryProblemAction({ commit, state }) {
    httpClient({
      url: `${domain}/monolit/HistoryChange/actionGetProblemHistory`,
      method: "GET",
      params: {
        problem_id: state.currentInfoProblem.id
      }
    }).then((response) => {
      commit("SET_CURRENT_PROBLEM_HISTORY_TOTAL_COUNT", response.data.data.total_count);
      commit("SET_CURRENT_PROBLEM_HISTORY_COUNTER", response.data.data.items.length);
      commit("SET_CURRENT_PROBLEM_HISTORY", response.data.data.items);
    });
  },
  scrollGetHistoryProblemAction({ commit, state }) {
    httpClient({
      url: `${domain}/monolit/HistoryChange/actionGetProblemHistory`,
      method: "GET",
      params: {
        problem_id: state.currentInfoProblem.id,
        _from: state.currentProblemHistoryCounter + 1
      }
    }).then((response) => {
      commit(
        "SET_CURRENT_PROBLEM_HISTORY_COUNTER",
        state.currentProblemHistoryCounter + response.data.data.items.length
      );
      commit("SET_CURRENT_PROBLEM_HISTORY", [
        ...state.currentProblemHistory,
        ...response.data.data.items
      ]);
    });
  },
  getProblemCommentsAction({ commit }, id) {
    return httpClient({
      url: `${domain}/monolit/Comment/actionGetItems`,
      method: "GET",
      params: {
        _with: JSON.stringify(["author", "replies", "attachment_files"]),
        commentable_type: "isp_problems",
        commentable_id: id,
        _count: 300,
        _order: { datetime_of_creation: "asc" }
      }
    }).then((response) => {
      commit("SET_CURRENT_PROBLEM_COMMENTS", response.data.data.items);
    });
  },
  createProblemCommentAction({ commit, dispatch }, { content, filesId, id }) {
    return httpClient({
      url: `${domain}/monolit/Comment/actionCreate`,
      method: "POST",
      data: [
        {
          commentable_type: "isp_problems",
          commentable_id: id,
          content: content,
          attachment_files: filesId
        }
      ]
    })
      .then((resp) => {
        dispatch("getHistoryProblemAction");
        return resp;
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  editProblemCommentAction({ commit, dispatch }, { content, id, commentableId, filesId }) {
    return httpClient({
      url: `${domain}/monolit/Comment/actionUpdate/${id}`,
      method: "PUT",
      data: [
        {
          commentable_type: "isp_problems",
          commentable_id: commentableId,
          content: content,
          attachment_files: filesId
        }
      ]
    })
      .then((resp) => {
        dispatch("getHistoryProblemAction");
        return resp;
      })
      .catch((error) => {
        commit("SET_CURRENT_EDITABLE_COMMENT", null);

        ErrorObserver.notification(error);
      });
  },
  replyProblemCommentAction({ commit }, { content, parentId, commentableId, filesId }) {
    return httpClient({
      url: `${domain}/monolit/Comment/actionCreate/`,
      method: "POST",
      data: [
        {
          parent_id: parentId,
          commentable_type: "isp_problems",
          commentable_id: commentableId,
          content: content,
          attachment_files: filesId
        }
      ]
    }).catch((error) => ErrorObserver.notification(error));
  },
  deleteProblemCommentAction(_, id) {
    return httpClient({
      url: `${domain}/monolit/Comment/actionDelete/${id}`,
      method: "DELETE"
    }).catch((error) => ErrorObserver.notification(error));
  },
  setCurrentInfoProblem({ commit }, payload) {
    commit(types.SET_CURRENT_INFO_PROBLEM, payload);
  },
  getTeamsForCreateAction() {
    return httpClient({
      method: "GET",
      url: `/ISPGroup/actionGetItems`,
      params: {
        _with: JSON.stringify(["leader_user"]),
        is_archived: false,
        is_deleted: false,
        _count: 300
      }
    }).catch((error) => {
      ErrorObserver.notification(error);
    });
  },
  getTeamForCreateAction(_, id) {
    return httpClient({
      method: "GET",
      url: `/ISPGroup/actionGetItem/${id}`,
      params: {
        _with: JSON.stringify(["member_users"])
      }
    }).catch((error) => {
      ErrorObserver.notification(error);
    });
  },
  createProblemAction({ dispatch }, newProblem) {
    return httpClient({
      method: "POST",
      url: `/ISPProblem/actionCreate`,
      data: [newProblem]
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  closeCurrentInfoProblem({ commit, state }) {
    commit(types.CLOSE_CURRENT_INFO_PROBLEM);
  },
  subscribeToProblem({ commit, dispatch, state }) {
    httpClient({
      url: `/ISPProblemViewerUser/actionCreate`,
      method: "POST",
      data: [
        {
          viewer_user_id: store.getters.getUserId,
          isp_problem_id: state.currentInfoProblem.id
        }
      ]
    }).then((res) => {
      commit(types.UPDATE_VIEWER_STATUS, res.data.data);
      dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
        commit(types.SET_CURRENT_INFO_PROBLEM, {
          data: { ...state.currentInfoProblem, ...response.data.data }
        });
      });
    });
  },
  unsubscribeFromProblem({ commit, dispatch, state }, { payload, isPage }) {
    httpClient({
      url: `/ISPProblemViewerUser/actionDelete/${payload}`,
      method: "DELETE"
    }).then((res) => {
      commit(types.UPDATE_VIEWER_STATUS, null);
      if (isPage) {
        dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...response.data.data }
          });
        });
      }
    });
  },
  handleUpdateProblem({ dispatch, commit, state }, payload) {
    dispatch("updateProblemsAction", payload.data)
      .then((resp) => {
        dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...payload.data, ...response.data.data }
          });
          if (payload.files) {
            commit(types.UPDATE_PROBLEM_FILES, payload.files);
            commit(types.SET_FILES_LOADER, false);
          }
          commit(types.UPDATE_PROBLEM, payload.oldPeriodId);
          commit(types.SWITCH_MOVE_MODAL, false);
        });
        dispatch("getHistoryProblemAction");
        if (
          state.statusesList.find((item) => item.type === "check").id ===
            resp.data.data.flow_status_id &&
          payload.data.reconciliation
        ) {
          NotificationObserver.notification({
            type: "success",
            message: "Проблема отправлена Заказчику на согласование!"
          });
          return false;
        } else if (
          state.statusesList.find((item) => item.type === "done").id ===
            resp.data.data.flow_status_id &&
          payload.data.reconciliation
        ) {
          NotificationObserver.notification({
            type: "success",
            message: "Проблема согласована!"
          });
          return false;
        } else if (
          state.statusesList.find((item) => item.type === "rework").id ===
            resp.data.data.flow_status_id &&
          payload.data.reconciliation
        ) {
          NotificationObserver.notification({
            type: "success",
            message: "Проблема отправлена на доработку!"
          });
          return false;
        } else {
          NotificationObserver.notification({
            type: "success",
            message: "Проблема успешно отредактирована!"
          });
        }
      })
      .catch((error) => {
        commit(types.SET_CURRENT_INFO_PROBLEM, {
          data: { ...state.currentInfoProblem }
        });
        commit(types.SET_FILES_LOADER, false);
        ErrorObserver.notification(error);
      });
  },
  getProblemAtUpdate({ commit }, id) {
    return httpClient({
      url: `${domain}/monolit/ISPProblem/actionGetItem/${id}`,
      method: "GET",
      params: {
        _with: JSON.stringify(["viewer_users", "status"])
      }
    });
  },
  setFilesLoader({ commit }, payload) {
    commit(types.SET_FILES_LOADER, payload);
  },
  updateProblemsAction({ commit, state, dispatch }, payload) {
    let problem = new ProblemDto({ ...state.currentInfoProblem, ...payload });
    if (payload.deadline !== undefined) {
      problem.deadline = payload.deadline;
    }

    return httpClient({
      method: "PUT",
      url: `/ISPProblem/actionUpdate/${state.currentInfoProblem.id}`,
      data: [{ ...problem }]
    });
  },
  getProblemTasks({ commit, state }) {
    httpClient({
      url: `/ISPTask/actionGetItems`,
      method: "GET",
      params: {
        _with: JSON.stringify(["executors", "attachment_files"]),
        problem_id: state.currentInfoProblem.id,
        _count: 100
      }
    })
      .then((response) => {
        commit(types.GET_TASK_LIST, response.data.data.items);
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  handleDeleteTask({ commit, state, dispatch }, payload) {
    dispatch("deleteTask", payload)
      .then(() => {
        commit(types.DELETE_TASK, {
          id: payload
        });
        NotificationObserver.notification({
          type: "success",
          message: "Задача успешно удалена!"
        });
        dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...response.data.data }
          });
        });
        dispatch("getHistoryProblemAction");
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  handleActionProblem({ commit, state, dispatch }, payload) {
    switch (payload) {
      case "move": {
        commit(types.SWITCH_MOVE_MODAL, true);
        break;
      }
      case "delete": {
        dispatch("deleteProblem", state.currentInfoProblem.id).then((resp) => {
          commit(types.DELETE_PROBLEM);
          if (router.app.$route.name === "IspProblem") router.push({ name: "IspReview" });
        });
        break;
      }
      case "copy": {
        dispatch("createProblemAction", { parent_id: state.currentInfoProblem.id }).then((resp) => {
          commit(types.SET_CHILDREN_PROBLEM, resp.data.data);
          let routeData = router.resolve({ name: "IspProblem", params: { id: resp.data.data.id } });
          window.open(routeData.href, "_blank");
        });
        break;
      }
      case "archive": {
        dispatch("updateProblemsAction", {
          is_archived: !state.currentInfoProblem.is_archived
        }).then((resp) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...resp.data.data }
          });
        });
        break;
      }
      case "backlog": {
        dispatch("updateProblemsAction", {
          period_id: null
        }).then((resp) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...resp.data.data }
          });
        });
        break;
      }
    }
  },
  handleUpdateTask({ commit, state, dispatch }, payload) {
    dispatch("updateTask", payload.data)
      .then((res) => {
        dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...response.data.data }
          });
          dispatch("getHistoryProblemAction");
          commit(types.UPDATE_TASK, {
            data: res.data.data,
            users: payload.data.users,
            files: payload.files
          });
          NotificationObserver.notification({
            type: "success",
            message: "Задача успешно отредактирована!"
          });
        });
      })
      .catch((error) => {
        ErrorObserver.notification(error);
        commit(types.UPDATE_TASK, {
          data: payload.data.info,
          users: payload.data.info.executors,
          files: payload.files
        });
      });
  },
  updateTask({ commit, state, dispatch }, payload) {
    const task = new TaskDto(payload.info);

    return httpClient({
      method: "PUT",
      url: `/ISPTask/actionUpdate/${task.id}`,
      data: [{ ...task, ...payload.change }]
    });
  },
  deleteProblem({ commit, state }, payload) {
    return httpClient({
      method: "DELETE",
      url: `/ISPProblem/actionDelete/${payload}`
    });
  },
  deleteTask({ commit, state }, payload) {
    return httpClient({
      method: "DELETE",
      url: `/ISPTask/actionDelete/${payload}`
    });
  },
  createNewTask({ commit, state, dispatch }, payload) {
    httpClient({
      url: `/ISPTask/actionCreate`,
      method: "POST",
      data: payload
    })
      .then(() => {
        NotificationObserver.notification({
          type: "success",
          message: "Задача успешно создана!"
        });
        dispatch("getProblemAtUpdate", state.currentInfoProblem.id).then((response) => {
          commit(types.SET_CURRENT_INFO_PROBLEM, {
            data: { ...state.currentInfoProblem, ...response.data.data }
          });
        });
        dispatch("getProblemTasks");
        dispatch("getHistoryProblemAction");
        commit(types.SHOW_CREATE_TASK_MODAL, false);
        commit(types.SHOW_CURRENT_INFO_PROBLEM, true);
      })
      .catch((error) => ErrorObserver.notification(error));
  }
};

export default actionsIspProblem;
