<template>
  <div class="isp-team-members" v-click-outside="editMembers">
    <ConfirmModal
      v-if="confirmDeleteUser"
      @cancel="cancelDeleteUser"
      @accept="deleteUser"
      :title="'Удалить'"
      :description="'Вы уверены, что хотите удалить участника?'"
    />
    <div class="isp-team-members__label">
      <h5>УЧАСТНИКИ</h5>
      <i
        class="icon-edit"
        @click="setEditMode"
        v-show="!editMode && !currentTeam.is_archived && isEditable"
      ></i>
    </div>
    <div class="isp-team-members__members-list" v-if="!editMode">
      <div class="isp-team-members__user" v-for="user in users" :key="user.id">
        <User
          :avatar="user.avatar"
          :name="user.full_name"
          :id="user.id"
          :showTooltip="false"
          :size="'60px'"
          :showCross="!currentTeam.is_archived && isEditable"
          :info="user.roles.length ? `[${user.roles[0].name}]` : ''"
          @crossHandler="openConfirmDeleteUser"
        />
      </div>
    </div>
    <div class="isp-team-members__edit" v-else>
      <Select :placeholder="'Выберите сотрудника'" v-model="selected" :items="allUsers" />
    </div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import User from "@/components/Widgets/User/User";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "IspTeamMembers",
  mixins: [],
  props: {},
  components: { ConfirmModal, Select, User },
  data() {
    return {
      editMode: false,
      selected: [],
      confirmDeleteUser: false,
      userToDelete: 0
    };
  },
  mounted() {
    this.selected = this.currentTeam.member_users;
  },
  methods: {
    ...mapActions("IspModule", ["editTeamAction"]),
    setEditMode() {
      this.editMode = true;
    },
    editMembers(e) {
      // const parentNode = e.path.find((item) => item.className === "isp-team-info");
      if (this.editMode) {
        this.editMode = false;
        const editedTeam = {
          leader_user_id: this.currentTeam.leader_user_id,
          member_users: this.selected.map((item) => item.id)
        };
        this.editTeamAction({ editedTeam, id: this.currentTeam.id }).then((response) => {
          this.currentTeam.member_users = this.selected;
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Список участников команды успешно изменён!`
          });
        });
      }
    },
    openConfirmDeleteUser(userId) {
      this.userToDelete = userId;
      this.confirmDeleteUser = true;
    },

    deleteUser() {
      this.confirmDeleteUser = false;
      const editedMembers = this.currentTeam.member_users.filter(
        (item) => item.id !== this.userToDelete
      );
      const editedTeam = {
        member_users: editedMembers.map((item) => item.id),
        leader_user_id: this.currentTeam.leader_user_id
      };
      this.editTeamAction({
        editedTeam,
        id: this.currentTeam.id
      }).then(() => {
        this.editMode = false;
        this.currentTeam.member_users = editedMembers;
        this.selected = editedMembers;
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Пользователь успешно удален!`
        });
      });
    },
    cancelDeleteUser() {
      this.confirmDeleteUser = false;
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeam", "allUsers"]),
    users() {
      return this.currentTeam.member_users;
    },
    isEditable() {
      const currentUser = this.$store.getters.getDataInfoUser;
      return (
        currentUser.roles.includes("isp_admin") ||
        currentUser.id === this.currentTeam.leader_user_id
      );
    }
  },
  watch: {
    currentTeam() {
      this.selected = this.currentTeam.member_users;
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.isp-team-members {
  height: calc(100% - 150px);
  @media screen and (max-width: 850px) and (orientation: landscape) {
    height: 100%;
  }
  &__label {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    &:hover .icon-edit {
      opacity: 1;
    }
    h5 {
      margin: 0 5px 0 0;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
    }
    i {
      font-size: 20px;
      color: #a7a9c0;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s all ease;
      &:hover {
        color: $icon-highlight;
      }
    }
  }
  &__user {
    display: flex;
    align-items: center;
  }
  &__members-list {
    max-height: calc(100% - 30px);
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
  /deep/ {
    .user__info {
      @media (max-width: $xxxl) {
        max-width: 200px;
      }
    }
    .user__avatar {
      @media (max-width: $xxxl) {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .user__name {
      font-weight: 500;
    }
  }
}
</style>
