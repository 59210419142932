<template>
  <li
    class="chosen-filter__item"
    @click="choseFilter(item)"
    v-if="isSearchingName"
    ref="chosenFilterItem"
  >
    <span
      class="checkbox_custom"
      :class="{
        checkbox_custom_chosen: isSelected || choseAll,
        checkbox_custom_multiple: multiple
      }"
    >
      <i class="icon-check" v-if="isSelected || choseAll"></i>
    </span>
    <span>{{ setName }}</span>
  </li>
</template>

<script>
export default {
  name: "ChosenFilterItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    chosenList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    choseAll: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchValue: {
      type: String,
      default: ""
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  },
  components: [],
  data() {
    return {};
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.chosenFilterItem);
  },
  mounted() {
    if (this.isLastItem) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.$emit("reachLastItem");
            this.observer.unobserve(this.$refs.chosenFilterItem);
          }
        });
      });
      this.observer.observe(this.$refs.chosenFilterItem);
    }
  },
  methods: {
    isChosenFilter(item) {
      this.$emit("isChosenFilter", item);
    },
    choseFilter(item) {
      if (this.multiple || (!this.isSelected && !this.multiple)) {
        this.$emit("choseFilter", item);
      }
    }
  },
  computed: {
    isSelected() {
      return this.value.some((item) => item.id === this.item.id);
    },
    isSearchingName() {
      return this.searchValue ? !!this.setName.match(new RegExp(this.searchValue, "gi")) : true;
    },
    setName() {
      return this.item["full_name"]
        ? this.item["full_name"]
        : this.item["label"] || this.item["name"];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.chosen-filter {
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 18px;
    }

    &:hover {
      background: $surface-selected-default;
      border-radius: 4px;
    }

    &_last {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 24px;
      border-top: 1px solid $border-disabled;

      &:hover {
        background: #ffffff;
      }
    }

    span {
      max-width: 210px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.checkbox_custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid #d3d2e0;
  box-sizing: border-box;
  margin-right: 8px;
  flex-shrink: 0;
  border-radius: 50%;

  .icon-check {
    margin-right: 0;
    color: #ffffff;
    font-size: 14px;
  }

  &_chosen {
    background-color: $action-primary-accent;
    border: 2px solid $action-primary-accent;
  }

  &_multiple {
    border-radius: 2px;
  }
}
</style>
