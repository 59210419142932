<template>
  <div
    class="members"
    :style="{ width: editMode ? '100%' : 'fit-content' }"
    v-click-outside="hideEditMode"
  >
    <Label
      :label="'ЗАКАЗЧИК'"
      :tooltip="'Сотрудник, который может проверить результат решения проблемы'"
    />
    <div
      class="members__content"
      @click="setEdit"
      v-show="!editMode"
      :class="{ 'members__content-edit': isEdit }"
    >
      <img
        class="members__avatar"
        v-if="user.customer_user && user.customer_user.avatar"
        :src="returnSrcFile(user.customer_user.avatar)"
        alt="avatar"
      />
      <div class="members__info" v-if="user.customer_user">
        <div class="members__name">{{ user.customer_user.full_name }}</div>
      </div>
      <i class="icon-edit" v-if="isEdit"></i>
    </div>
    <Select
      v-if="editMode"
      :multiple="false"
      v-model="selected"
      :items="usersList"
      :placeholder="'Выберите сотрудника'"
    />
  </div>
</template>

<script>
import Label from "@/modules/Isp/components/IspProblem/Label";
import { domain, domainStorage } from "@/globalVariables";
import Select from "@/modules/UiKit/components/Select/Select";
import ClickOutside from "vue-click-outside";

import { mapState } from "vuex";

export default {
  name: "CustomerComponent",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: { Select, Label },
  data() {
    return {
      editMode: false,
      selected: []
    };
  },
  created() {
    this.selected = [this.user.customer_user];
  },
  mounted() {},
  methods: {
    setEdit() {
      if (this.isEdit) this.editMode = true;
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    hideEditMode() {
      this.editMode = false;
      if (this.selected[0].id !== this.user.customer_user.id)
        this.$emit("editCustomer", this.selected[0]);
    }
  },
  computed: {
    ...mapState("IspModule", ["usersList"])
  },
  watch: {
    user() {
      this.selected = [this.user.customer_user];
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.members {
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-bottom: 24px;
  /deep/ {
    .label {
      margin-bottom: 12px;
      label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #343748;
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 7px 9px;

    i {
      visibility: hidden;
      color: #a7a9c0;
      margin-left: 8px;
    }
  }
  &__content-edit {
    cursor: pointer;
    &:hover {
      background: #fafafe;
      i {
        visibility: visible;
      }
    }
  }
  &__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fafafe;
    margin-right: 8px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343748;
    word-break: break-word;
    max-width: 100px;
  }
}
</style>
