import { domain } from "@/globalVariables";
import router from "@/router";
import httpClient from "@/api/client/httpClient";

export default {
  namespaced: true,
  state: {
    isOpenModal: false
  },
  mutations: {
    CHANGE_IS_OPEN_MODAL(state, payload) {
      state.isOpenModal = payload;
    }
  },
  actions: {
    openModal({ commit }) {
      commit("CHANGE_IS_OPEN_MODAL", true);
    },
    closeModal({ commit }) {
      commit("CHANGE_IS_OPEN_MODAL", false);
    },
    sendFeedback({ state, dispatch, commit }, payload) {
      return httpClient({
        url: `/Feedback/create`,
        method: "POST",
        data: [payload]
      });
    }
  },
  getters: {}
};
