<template>
  <div class="table__mark" :style="{ background: setBackground(mark), color: setColor(mark)}">
    <i class="icon-cross" v-if="mark === 0"></i>
    <i class="icon-check" v-if="mark !== 0"></i>
    {{ mark }}
  </div>
</template>

<script>
export default {
  name: "Mark",
  mixins: [],
  props: {
    mark: {
      type: [String, Number],
      default: 0
    }
  },
  components: [],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    /** Функция установки фона в зависимости от оценки
     *
     * @param mark - оценка сотрудника
     * @returns {string} - цвет фона
     */
    setBackground(mark) {
      switch (mark) {
        case 0:
          return "#FEF0F0";
        case 1:
          return "#FFF8E5";
        default:
          return "#EBFCF6";
      }
    },
    /** Функция установки цвета текста в зависимости от оценки
     *
     * @param mark - оценка сотрудника
     * @returns {string} - цвет
     */
    setColor(mark) {
      switch (mark) {
        case 0:
          return "#A22929";
        case 1:
          return "#CA7B00";
        default:
          return "#238B6A";
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table__mark {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: max-content;
  height: 24px;
  background: #ebfcf6;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #027a48;

  i {
    margin-right: 4px;
    font-size: 16px;
  }
}

</style>
