<template>
  <div class="modal__header">
    <p class="modal__type" :style="{ backgroundColor: background }">{{ checkType(type) }}</p>
    <ul class="modal__date">
      <ModalCheckboxDate
        :item="item"
        :valueData="valueData"
        v-if="isAdmin"
        v-for="item in listData"
      />
    </ul>

    <div class="modal__setting setting" v-click-outside="closeSettingsList">
      <i class="icon-dots-vertical" v-if="isUpdate" @click="openSettingsList"></i>
      <ul class="setting__list" v-if="isUpdate && isOpenSettingsList">
        <li class="setting__item">
          <button class="setting__button" type="button" @click="updateVacationOrder">
            <i class="icon-edit"></i>
            Редактировать
          </button>
        </li>
        <li class="setting__item">
          <button class="setting__button" type="button" @click="deleteVacation(vacation.id)">
            <i class="icon-delete"></i>Удалить
          </button>
        </li>
      </ul>
    </div>
    <i class="icon-cross" @click="hideGraphVacationModal"></i>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
import ModalCheckboxDate from "@/components/GraphVacation/ModalComponent/ModalCheckboxDate";
import ClickOutside from "vue-click-outside";
export default {
  name: "ModalHeader",
  mixins: [],
  props: {
    type: {
      type: String,
      default() {
        return "Отпуск";
      }
    },
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return true;
      }
    },
    createMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isUser: {
      type: Boolean
    },
    request: {
      type: Boolean
    },
    isAdmin: {
      type: Boolean
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    }
  },
  components: { ModalCheckboxDate, IcoMoonWrap },
  data() {
    return {
      background: "",
      listData: [
        { name: "План", value: "plane_date", isChecked: true },
        { name: "Факт", value: "fact_date", isChecked: false }
      ],
      valueData: {
        name: "План",
        value: "plane_date"
      },
      isOpenSettingsList: false
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.$root.$emit("chooseDateCheckboxValue", this.valueData);
    }
    this.$root.$on("chooseDateCheckboxValue", (val) => {
      this.isChosenFilter(val);
    });
  },
  beforeDestroy() {
    this.$root.$off("chooseDateCheckboxValue");
  },
  methods: {
    updateVacationOrder() {
      if (this.isAvailableForEditing) {
        this.$root.$emit("openConformationModal", {
          vacation: this.vacation,
          editMode: true,
          createMode: false
        });
      } else {
        this.$root.$emit("openVacationRequestModal", {
          vacation: this.vacation,
          editMode: false,
          createMode: true
        });
      }
    },
    isChosenFilter(val) {
      this.listData = this.listData.map((item) => {
        if (item.value === val.value) {
          item.isChecked = true;
          return item;
        } else {
          item.isChecked = false;
          return item;
        }
      });
    },
    deleteVacation(id) {
      this.$root.$emit("deleteVacation", id);
      this.hideGraphVacationModal();
    },
    hideGraphVacationModal() {
      if (this.isAdmin) {
        this.$root.$emit("closeConfirmationModalPersonnelOfficer");
      } else {
        this.$root.$emit("hiddenGraphVacationModal");
      }
    },
    checkType(type) {
      switch (type) {
        case "Отпуск": {
          this.background = "#a177ff";
          return "Отпуск";
        }
        case "Перенос": {
          this.background = "#FFB901";
          return "Перенос";
        }
      }
    },
    openSettingsList() {
      this.isOpenSettingsList = true;
    },
    closeSettingsList() {
      this.isOpenSettingsList = false;
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isUpdate() {
      return !this.editMode && !this.createMode && this.isUser && !this.request;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.modal {
  &__type {
    padding: 4px 12px;
    height: 24px;
    background: #a177ff;
    border-radius: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-on-interactive;
  }
  &__date {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 0 40px;
    @media (max-width: $xs) {
      margin: 0 0 0 15px;
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 48px 30px 42px;
    border-bottom: 1px solid #f2f2f7;
    flex-wrap: wrap;

    @media (max-width: $md) {
      padding: 30px;
    }
  }

  &__back-button {
    display: none;

    @media (max-width: $md) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px 7px 8px;
      background-color: $action-secondary;
      border: none;
      border-radius: 16px;
      font-weight: normal;
      font-size: 21px;
      line-height: 28px;
      color: $text-highlight;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      margin-bottom: 50px;
    }

    .icon-left {
      font-size: 20px;
      color: $icon-subdued;
    }

    &:hover .icon-left {
      color: $icon-hovered;
    }
  }

  &__setting {
    margin-left: auto;
    margin-top: 3px;

    .icon-edit,
    .icon-delete {
      color: $icon-subdued;
      font-size: 20px;
    }
  }

  .icon-cross,
  .icon-dots-vertical {
    font-size: 24px;
    color: $icon-subdued;
    cursor: pointer;
    margin-left: 16px;

    &:hover {
      color: $icon-hovered;
    }
  }

  .icon-cross {
    @media (max-width: $md) {
      display: block;
    }
  }

  .icon-dots-vertical {
    font-size: 18px;
  }
}
.setting {
  position: relative;

  &__list {
    display: block;
    position: absolute;
    z-index: 15;
    top: -10px;
    right: 18px;
    padding: 8px 0;
    list-style: none;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;

      i {
        color: $icon-hovered;
      }
    }
  }
}
</style>
