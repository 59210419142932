<template>
  <section class="my-projects">
    <div class="my-projects__header">
      <button class="my-projects__menu" type="button" @click="toggleSidebar">
        <i class="icon-menu"></i>
      </button>
      <div class="my-projects__tabs">
        <TabsContent :tabs="tabsList" v-model="activeTab" />
      </div>
      <div class="my-projects__filters">
        <ChosenFilter
          v-model="status"
          :icon="'eye'"
          :placeholder="'Статус'"
          :chosenList="statusList"
          :allItem="false"
        />
        <ChosenFilter
          v-model="sortBy"
          :multiple="false"
          :icon="'swap-vert'"
          :placeholder="'Сортировать'"
          :chosenList="sortList"
          :allItem="false"
          :modifier="'last'"
          @input="changeSortingType"
        />
      </div>
    </div>
    <Spinner v-if="projectListLoader" class="my-projects__loader" />
    <div class="my-projects__body" v-else>
      <div class="my-projects__list" :style="showActionList ? 'padding-bottom: 200px' : ''">
        <ProjectCard
          v-for="(card, index) in projectListByFilter"
          :key="card"
          :project="card.project"
          :isLastItem="projectListByFilter.length - 1 === index"
        />
      </div>
      <div class="my-projects__pagination-loader" v-if="projectPaginationLoader">
        <Spinner />
      </div>
      <p class="my-projects__empty" v-if="!projectListByFilter.length">СПИСОК ПУСТ</p>
    </div>
  </section>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import ProjectCard from "@/modules/TaskManager/components/MyProjects/ProjectCard";
import { mapMutations, mapState } from "vuex";
import ProjectModule, {
  ProjectMutationTypes
} from "@/modules/TaskManager/Model/Project/ProjectModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "MyProjects",
  mixins: [],
  props: {},
  components: {
    Spinner,
    ProjectCard,
    ChosenFilter,
    TabsContent
  },
  data() {
    return {
      showActionList: false,
      activeTab: "all",
      status: [
        {
          name: "Активные",
          id: 1
        }
      ],
      sortBy: [
        {
          name: "По дате создания",
          id: 0
        }
      ],
      statusList: [
        {
          name: "Все",
          id: 0
        },
        {
          name: "Активные",
          id: 1
        },
        {
          name: "Закрытые",
          id: 2
        }
      ],
      sortList: [
        {
          name: "По дате создания",
          id: 0
        },
        {
          name: "По алфавиту",
          id: 1
        }
      ]
    };
  },
  mounted() {
    ProjectModule.getProjectListAction();
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    toggleSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(!this.showSidebar);
    },
    toggleShowActionList(val) {
      this.showActionList = val;
    },
    changeSortingType(val) {
      if (val[0].id === 0) {
        ProjectModule[ProjectMutationTypes.SET_CURRENT_SORTING_TYPE]({
          "orders[created_at]": "desc"
        });
      } else {
        ProjectModule[ProjectMutationTypes.SET_CURRENT_SORTING_TYPE]({ "orders[name]": "asc" });
      }
      ProjectModule.getProjectListAction();
    }
  },
  computed: {
    ...mapState("TaskManagerModule", ["showSidebar"]),
    tabsList() {
      return [
        {
          label: "Все проекты",
          value: "all"
        },
        {
          label: "Созданные",
          value: "created"
        }
      ];
    },
    projectList() {
      if (this.activeTab === "created") {
        return ProjectModule.projectList.filter(
          (item) => item.project.author_id === this.$store.getters.getUserId
        );
      } else {
        return ProjectModule.projectList;
      }
    },
    projectListByFilter() {
      switch (this.status[0].id) {
        case 0:
          return this.projectList;
        case 1:
          return this.projectList.filter((item) => !item.project.is_completed);
        case 2:
          return this.projectList.filter((item) => item.project.is_completed);
        default:
          return this.projectList;
      }
    },
    projectListLoader() {
      return ProjectModule.projectListLoader;
    },
    projectPaginationLoader() {
      return ProjectModule.projectPaginationLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.my-projects {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &__header {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 8px;
    border-bottom: 1px solid $icon-disabled;

    @media (max-width: $xxl) {
      justify-content: flex-start;
    }
  }

  &__menu {
    display: none;

    @media (max-width: $xxl) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__filters {
    display: flex;

    @media (max-width: $xxl) {
      margin-left: auto;
    }

    /deep/ {
      .chosen-filter {
        .chosen-filter__list {
          @media (max-width: $md) {
            left: unset;
            right: 10px;
            z-index: 60;
          }
        }

        &_last {
          .chosen-filter__list {
            left: unset;
            right: 10px;
            z-index: 60;
          }
        }
      }
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__body {
    overflow: auto;
    height: 100%;
  }
  &__list {
    padding: 40px 32px 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 44px;
    min-height: 100%;
    @media (max-width: $xl) {
      /deep/ {
        .project-card__title {
          font-size: 12px;
        }
        .action-list {
          &__modal-list {
            @media screen and (max-width: 850px) and (orientation: landscape) {
              min-width: 200px;
            }
          }
          &__modal-button {
            font-size: 13px;
          }
        }
      }
    }
    @media (max-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
      padding: 40px 32px 70px;

      .project-card {
        height: 216px;
      }
    }
    @media (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
      padding: 40px 32px 70px;

      .project-card {
        height: 216px;
      }
    }
    @media (max-width: $sm) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  &__empty {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 45%;
    color: $text-default;
  }
  &__pagination-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}
</style>
