




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FullScreen extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  config!: any;
}
