













































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TimeTracker from "@/modules/TimeTracker/store";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Select from "@/modules/UiKit/components/Select/Select";
import DatePickerWidget from "@/modules/News/components/NewsCreateAndEdit/NewsCreateAndEditComponent/DateTimePicker.vue";
import TimePicker from "@/modules/UiKit/components/TimePicker/TimePicker";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import { ITimeProject } from "@/modules/TimeTracker/interface/interface";
import { EventBus } from "@/main";
import moment from "moment";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmationModal from "@/modules/TimeTracker/component/Modal/ConfirmationModal";

@Component({
  components: { ConfirmationModal, Button, DatePickerWidget, Select, Label, TimePicker }
})
export default class TimeModal extends Vue {
  @Prop({ type: Array, default: () => [] }) reasons!: Array<any>;
  @Prop({ type: Boolean, default: false }) isTimeAdmin!: boolean;
  currentUser = [];
  currentProject: Array<ITimeProject> = [];
  currentTask: Array<any> = [];
  currentReason: Array<any> = [];
  date: null | string = moment(new Date()).format("YYYY-MM-DD");
  isDisabledUser: boolean = false;
  startTime: null | string = null;
  endTime: null | string = null;
  regExpDate: any = new RegExp("[0-9]{2}:[0-9]{2}:[0-9]{2}");

  handleClose() {
    if (this.checkClose) {
      TimeTracker.confirmClose("Закрыть?");
    } else {
      TimeTracker.toggleShowModal({ isShow: false, data: null });
    }
  }

  created() {
    document.addEventListener("keydown", this.onEscape);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscape);
  }

  onEscape(e) {
    if (e.keyCode === 27) {
      this.handleClose();
    }
  }

  mounted() {
    if (this.dataModal) {
      this.isDisabledUser = true;
      this.currentUser = this.users.filter((item) => item.id === this.dataModal.user_id);
      if (this.project) {
        this.currentProject = [this.project];
        this.getTaskByProjectId(this.currentProject[0].id);
      }
      if (this.dataModal.task_id) {
        this.currentTask = [{ id: this.dataModal.task_id, name: this.dataModal.task_name }];
      }
      this.date = this.dataModal.date;
      this.startTime = this.dataModal.time_start;
      this.endTime = this.dataModal.time_stop;
      if (this.dataModal.reason_id) {
        this.currentReason = this.reasons.filter((item) => item.id === this.dataModal.reason_id);
      }
    }
  }

  handleDate(val) {
    this.date = val ? moment(val).format("YYYY-MM-DD") : val;
  }

  handleUser(val) {
    if (val.length === 0) {
      this.currentProject = [];
      this.currentTask = [];
    }
  }

  handleProject(val) {
    this.currentTask = [];
    if (val.length === 0) {
      this.currentProject = [];
    } else {
      this.getTaskByProjectId(val[0].id);
    }
  }

  get minDate() {
    return this.isTimeAdmin ? null : moment().subtract(3, "days").format("YYYY-MM-DD");
  }

  get maxDate() {
    return moment().format("YYYY-MM-DD");
  }

  get checkClose() {
    if (this.dataModal) {
      return (
        this.dataModal.date !== this.date ||
        this.dataModal.project_id !== this.currentProject[0]?.id ||
        this.dataModal.task_id !== this.currentTask[0]?.id ||
        !!this.dataModal.reason_id !== !!this.currentReason[0]?.id ||
        this.dataModal.time_start !== this.startTime ||
        this.dataModal.time_stop !== this.endTime
      );
    } else {
      return (
        this.currentProject.length ||
        this.currentTask.length ||
        this.currentReason.length ||
        this.date !== moment(new Date()).format("YYYY-MM-DD") ||
        this.startTime ||
        this.endTime
      );
    }
  }

  handleConfirm() {
    if (this.checkValidation) {
      const data = {
        id: this.dataModal ? this.dataModal.id : null,
        project_id: this.currentProject[0].id,
        project_name: this.currentProject[0].name,
        task_id: this.currentTask[0].id,
        task_name: this.currentTask[0].name,
        date: moment(this.date!).format("YYYY-MM-DD"),
        time_start: this.startTime!,
        time_stop: this.endTime!,
        reason_id: this.currentReason[0].id,
        reason_name: this.currentReason[0].name
      };
      if (this.dataModal) {
        TimeTracker.editTime(data);
      } else {
        TimeTracker.addTime(data);
      }
    }
  }

  async getTaskByProjectId(project_id) {
    await TimeTracker.getTaskByProjectId({ project_id, page: 1, per_page: 15 });
  }

  get dataModal() {
    return TimeTracker.data;
  }

  get checkValidation() {
    if (!this.currentProject.length) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Выберите проект"
      });
      return false;
    }
    if (!this.currentTask.length) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Выберите задачу"
      });
      return false;
    }
    if (!moment(this.date, "YYYY-MM-DD").isValid()) {
      NotificationObserver.notification({
        type: "error",
        message: "Укажите дату"
      });
      return false;
    }
    if (
      this.startTime &&
      (this.startTime.length < 5 || this.startTime.split("").some((item) => item === "_"))
    ) {
      NotificationObserver.notification({
        type: "error",
        message: "Укажите время начала"
      });
      return false;
    }
    if (
      this.endTime &&
      (this.endTime.length < 5 || this.endTime.split("").some((item) => item === "_"))
    ) {
      NotificationObserver.notification({
        type: "error",
        message: "Укажите время окончания"
      });
      return false;
    }
    if (!moment(`${this.date} ${this.startTime}`).isValid()) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Время начала имеет не верный формат"
      });
      return false;
    }
    if (!moment(`${this.date} ${this.endTime}`).isValid()) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Время окончания имеет не верный формат"
      });
      return false;
    }
    if (moment(`${this.date} ${this.startTime}`) > moment(`${this.date} ${this.endTime}`)) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Время начала не может быть больше времени окончания"
      });
      return false;
    }
    if (!this.startTime) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Укажите время начала"
      });
      return false;
    }
    if (!this.endTime) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Укажите время окончания"
      });
      return false;
    }
    if (!this.currentReason.length) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: "Выберите причину"
      });
      return false;
    }
    return true;
  }

  get title() {
    return this.dataModal ? "Редактировать время" : "Добавить время";
  }

  get users() {
    return this.$store.getters["UsersModule/userList"].filedList(["full_name", "avatar"]);
  }

  get projects() {
    return TimeTracker.projects;
  }

  get project() {
    return TimeTracker.project(this.dataModal.project_id);
  }

  get tasks() {
    if (this.currentProject.length) {
      const tasks = TimeTracker.tasks({ project_id: this.currentProject[0].id, search: "" });
      return tasks;
    } else {
      return [];
    }
  }

  get buttonTitle() {
    return this.dataModal ? "Сохранить" : "Добавить";
  }

  get isAdmin() {
    return false;
  }

  get isDisabledProject() {
    if (this.isAdmin) {
      return !this.currentUser.length;
    }
    return false;
  }

  get isDisabledTask() {
    return !this.currentProject.length;
  }
}
