<template>
  <div class="show-edit-channel" @mousedown.self.stop="handleClose">
    <ConfirmModal
      v-if="confirmCloseModal"
      :title="'Закрыть'"
      :description="'Измененные данные не будут сохранены. Закрыть?'"
      @cancel="confirmCloseModal = false"
      @accept="closeCurtain"
    />
    <div class="show-edit-channel__container">
      <Spinner v-if="curtainChannelLoader" class="show-edit-channel__loader" />
      <div class="show-edit-channel__header" v-if="!curtainChannelLoader">
        <button v-if="isMobileWindowSize" class="modal__hide" type="button" @click="handleClose">
          <i class="icon-arrow-short"></i>
        </button>
        <ChannelTitle
          :isEditable="isEditable"
          :iconChannel="iconChannel"
          @setEditMode="editModeTitleEntered = true"
          @cancelEditMode="editModeTitleEntered = false"
        />
        <i class="icon-exit-to-app" @click="unsubscribeChannel" v-if="notAuthor"></i>
      </div>
      <div class="show-edit-channel__body" v-if="!curtainChannelLoader">
        <div class="show-edit-channel__settings">
          <h5>НАСТРОЙКИ</h5>
          <div class="create-channel__open-channel switchers">
            <p>Открытый канал</p>
            <SwitchIOS v-model="isPublic" :key="1" :disabled="!isEditable" />
          </div>
          <div class="create-channel__enable-comments switchers">
            <p>Включить комментирование</p>
            <SwitchIOS v-model="isComments" :key="2" :disabled="!isEditable" />
          </div>
          <div class="create-channel__default-access switchers">
            <p>Доступ по умолчанию</p>
            <SwitchIOS :key="3" v-model="isDefault" :disabled="!isEditable" />
          </div>
          <div class="create-channel__creation-access switchers">
            <p>Публиковать новости могут только модераторы</p>
            <SwitchIOS :key="4" v-model="isPublicationRight" :disabled="!isEditable" />
          </div>
        </div>
        <div class="show-edit-channel__moderators">
          <ChannelModerators
            :isEditable="isEditable"
            @editModeModeratorsEntered="editModeModeratorsEntered = true"
            @closeEditModeModerators="editModeModeratorsEntered = false"
          />
        </div>
        <div class="show-edit-channel__members">
          <ChannelMembers
            :isEditable="isEditable"
            @editModeMembersEntered="editModeMembersEntered = true"
            @closeEditModeMembers="editModeMembersEntered = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Button from "@/modules/UiKit/components/buttons/Button";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import ChannelTitle from "@/modules/News/components/Channel/ShowAndEditChannel/ChannelTitle";
import ChannelMembers from "@/modules/News/components/Channel/ShowAndEditChannel/ChannelMembers";
import ChannelModerators from "@/modules/News/components/Channel/ShowAndEditChannel/ChannelModerators";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "CurtainShowAndEditChannel",
  mixins: [],
  props: {},
  components: { Spinner, ConfirmModal, ChannelMembers, ChannelTitle, ChannelModerators, SwitchIOS },
  data() {
    return {
      isPublic: false,
      isComments: true,
      isDefault: false,
      isPublicationRight: false,
      iconChannel: "",
      confirmCloseModal: false,
      editModeTitleEntered: false,
      editModeMembersEntered: false,
      editModeModeratorsEntered: false,
      firstOccurrence: true,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.getAllUsersAction();
    window.addEventListener("resize", this.showArrowHide);
  },
  updated() {
    if (this.firstOccurrence) {
      this.setSettings();
    }
    this.$nextTick(() => {
      this.firstOccurrence = false;
    });
  },
  methods: {
    ...mapActions("NewsModule", [
      "editChannelAction",
      "unsubscribeChannelAction",
      "getChannelInfo",
      "setActiveChannel",
      "getNewsItems"
    ]),
    ...mapActions("IspModule", ["getAllUsersAction"]),
    ...mapMutations("NewsModule", [
      types.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL,
      types.TOGGLE_IS_CERTAIN_NEWS
    ]),
    handleClose() {
      if (
        this.editModeTitleEntered ||
        this.editModeModeratorsEntered ||
        this.editModeMembersEntered
      ) {
        this.confirmCloseModal = true;
      } else {
        this.closeCurtain();
      }
    },
    closeCurtain() {
      this[types.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL](false);
    },
    setSettings() {
      if (this.editChannelValue) {
        this.isPublic = this.editChannelValue.is_public;
        this.isComments = this.editChannelValue.is_include_comments;
        this.isDefault = this.editChannelValue.is_user_auto_connect;
        this.isPublicationRight = !this.editChannelValue.can_post;
        this.iconChannel = this.editChannelValue.icon;
      }
    },
    unsubscribeChannel() {
      this.closeCurtain();
      this.unsubscribeChannelAction(this.editChannelValue.current_channel_user.id)
        .then(() => {
          if (this.activeChannel === this.editChannelValue.id) {
            this.getChannelInfo().then(() => this.setActiveChannel(""));
          } else {
            this.getChannelInfo().then(() => this.getNewsItems());
          }
          this[types.TOGGLE_IS_CERTAIN_NEWS](false);
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: "Вы успешно отписались от канала."
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    showArrowHide() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapState("NewsModule", ["editChannelValue", "curtainChannelLoader", "activeChannel"]),
    ...mapGetters(["getUserId"]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    notAuthor() {
      return this.getUserId !== this.editChannelValue.author_user_id;
    },
    isEditable() {
      const currentUser = this.$store.getters.getDataInfoUser;
      return (
        currentUser.roles.includes("admin") ||
        currentUser.id === this.editChannelValue.author_user_id ||
        this.editChannelValue.admin_users.find((user) => currentUser.id === user.id)
      );
    }
  },
  watch: {
    isPublic(val) {
      if (this.firstOccurrence) return;
      const editedChannel = {
        is_public: val
      };
      this.editChannelAction({ editedChannel, channelId: this.editChannelValue.id }).then(() => {
        this.editChannelValue.is_public = val;
      });
    },
    isComments(val) {
      if (this.firstOccurrence) return;
      const editedChannel = {
        is_include_comments: val
      };
      this.editChannelAction({ editedChannel, channelId: this.editChannelValue.id }).then(() => {
        this.editChannelValue.is_include_comments = val;
      });
    },
    isDefault(val) {
      if (this.firstOccurrence) return;
      const editedChannel = {
        is_user_auto_connect: val
      };
      this.editChannelAction({ editedChannel, channelId: this.editChannelValue.id }).then(() => {
        this.editChannelValue.is_user_auto_connect = val;
      });
    },
    isPublicationRight(val) {
      if (this.firstOccurrence) return;
      const editedChannel = {
        can_post: !val
      };
      this.editChannelAction({ editedChannel, channelId: this.editChannelValue.id }).then(() => {
        this.editChannelValue.can_post = !val;
      });
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.show-edit-channel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  &__container {
    position: fixed;
    width: 711px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__header {
    padding: 32px;
    display: flex;

    i {
      cursor: pointer;
      font-size: 20px;
      color: $icon-subdued;
    }

    .modal__hide {
      padding: 0;
      border: none;
      background: transparent;
      outline: none;
      transform: rotate(-90deg);
      margin-right: 15px;

      i {
        color: $icon-subdued;
        font-size: 20px;
      }

      &:hover i {
        color: $icon-hovered;
      }

      &:active i {
        color: $icon-pressed;
      }
    }
    /deep/ {
      .uploader__img-empty {
        @media (max-width: $xxs) {
          width: 70px;
          height: 70px;
        }
      }

      .uploader__mask {
        @media (max-width: $xxs) {
          width: 70px;
          height: 70px;
        }
      }
      .uploader__container {
        @media (max-width: $sm) {
          left: 0;
        }
      }
      .uploader__main {
        @media (max-width: $sm) {
          padding-bottom: 15px;
        }
      }

      .uploader__footer {
        @media (max-width: $sm) {
          flex-direction: column-reverse;
          padding-top: 0;
        }
      }
    }
  }
  &__body {
    padding: 32px;
    height: calc(100% - 165px);
    overflow: auto;
    /deep/ {
      .select-bg__selected-list {
        max-height: 180px !important;
        overflow: auto;
        align-items: flex-start;
      }
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__settings {
    width: 100%;
    h5 {
      margin: 0 5px 24px 0;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
    }
    .switchers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
      p {
        font-size: 18px;
        color: $text-default;
        @media (max-width: $xs) {
          font-size: 16px;
        }
      }
    }
    /deep/ {
      .switch-widget {
        width: unset;
      }
    }
  }
  &__moderators {
    margin-bottom: 48px;
    /deep/ {
      .channel-moderators {
        &__members-list {
          @media (max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }

        .user {
          &__name {
            font-weight: 500;
            white-space: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          &__description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
}
</style>
