



































































































import { Component, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import DropDownList from "@/aopV2/components/UIComponents/DropDownList/DropDownList.vue";
import GridControl from "@/aopV2/modules/Calendar/components/GridControl/GridControl.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList";
import GroupList from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarGroup/GroupList.vue";
import ActionsList from "@/components/Widgets/ActionList/ActionList.vue";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import LocationList from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarLocation/LocationList.vue";
import CalendarTypes from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarTypes/CalendarTypes.vue";
import DefaultSwitch from "@/aopV2/components/UIComponents/Input/Check/Switch/DefaultSwitch.vue";
import InputTimeRange from "@/aopV2/components/UIComponents/Input/Time/InputTimeRange.vue";

@Component<CalendarControlPanel>({
  components: {
    InputTimeRange,
    DefaultSwitch,
    CalendarTypes,
    LocationList,
    ButtonIcon,
    GroupList,
    SelectList,
    ActionsList,
    GridControl,
    DropDownList
  },
  watch: {
    calendarTypeWeek() {
      if (!this.calendarTypeWeek) {
        this.handleResetMultipleUsersFilters();
      }
    }
  }
})
export default class CalendarControlPanel extends Vue {
  /**
   * Work time filter
   * @param time - start time for time grid
   */

  setGridStartTime(time) {
    CalendarModule2.setGridStartTime(time);
  }

  /**
   * Work time filter
   * @param time - end time for time grid
   */

  setGridStopTime(time) {
    CalendarModule2.setGridStopTime(time);
  }

  /**
   *  Add new filters
   */

  addGroup() {
    CalendarModule2.toggleShowGroupModal({ show: true, mode: "create" });
  }

  addLocation() {
    CalendarModule2.toggleShowLocationModal({ show: true, mode: "create" });
  }

  addUsers() {
    CalendarModule2.toggleShowUsersModal(true);
  }

  /**
   * User Filter
   */
  get users() {
    return CalendarModule2.users?.length
      ? [
          {
            id: this.getUserId,
            full_name: "Мой"
          }
        ].concat(CalendarModule2.users.filter((item) => item.id !== this.getUserId))
      : [];
  }

  get selectedUsers() {
    return CalendarModule2.selectedUsers;
  }

  get checkAllUsers() {
    return this.users.length === this.selectedUsers.length;
  }

  handleChangeUser(selected) {
    CalendarModule2.saveSelectedUsers(selected);
  }

  handleCheckAllUsers() {
    CalendarModule2.saveSelectedUsers(this.checkAllUsers ? [] : this.users);
  }

  handleUserDelete(selected) {
    CalendarModule2.getFilterUsers(selected.filter((item) => item.id !== this.getUserId));
  }

  handleResetMultipleUsersFilters() {
    CalendarModule2.saveSelectedGroups([]);
    CalendarModule2.saveSelectedUsers([
      {
        id: this.getUserId,
        full_name: "Мой"
      }
    ]);
  }

  //
  /**
   * Group Filter
   */

  handleChangeGroups(selected) {
    CalendarModule2.saveSelectedGroups(selected.new);
  }

  get selectedGroups() {
    return CalendarModule2.selectedGroups;
  }

  get checkAllGroups() {
    return this.groups.length === this.selectedGroups.length;
  }

  handleCheckAllGroups() {
    CalendarModule2.saveSelectedGroups(this.checkAllGroups ? [] : this.groups);
  }

  handleDeleteGroup(id) {
    CalendarModule2.saveSelectedGroups(this.selectedGroups.filter((item) => item.id !== id));
  }

  /**
   * Location Filter
   */
  get selectedLocations() {
    return CalendarModule2.selectedLocations;
  }

  get checkAllLocations() {
    return this.locations.length === this.selectedLocations.length;
  }

  get locations() {
    return CalendarModule2.locations;
  }

  handleChangeLocations(val) {
    CalendarModule2.saveSelectedLocation(val);
  }

  handleCheckAllLocations() {
    CalendarModule2.saveSelectedLocation(this.checkAllLocations ? [] : this.locations);
  }

  /**
   * Types Filter
   */
  get selectedTypes() {
    return CalendarModule2.selectedTypes;
  }

  get types() {
    return this.isEventsCalendar
      ? CalendarModule2.eventType.filter((item) => item.category_id === this.activityCategories.id)
      : CalendarModule2.eventType;
  }

  get checkAllTypes() {
    return this.types.length === this.selectedTypes.length;
  }

  handleChangeTypes(val) {
    CalendarModule2.saveSelectedTypes(val);
  }

  handleCheckAllTypes() {
    CalendarModule2.saveSelectedTypes(this.checkAllTypes ? [] : this.types);
  }

  get noAcceptEvent() {
    return CalendarModule2.noAcceptEvent;
  }

  showNoAcceptEvents() {
    CalendarModule2.toggleNoAcceptEvents(!this.noAcceptEvent);
  }

  /**
   * Weekends Filter
   */

  showWeekends() {
    CalendarModule2.setIsWeekends();
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }

  get groups() {
    return CalendarModule2.groups;
  }

  get getUserId() {
    return this.$store.getters.getUserId;
  }

  get userInfo() {
    return this.$store.getters.getDataInfoUser;
  }

  get calendarAdmin() {
    return !!this.userInfo.roles.includes("admin");
  }

  get eventsParams() {
    return CalendarModule2.eventsParams;
  }

  get activityCategories() {
    return CalendarModule2.eventTypeCategories.length
      ? CalendarModule2.eventTypeCategories.find((item) => item.name === "Активность")
      : null;
  }

  get getUsers() {
    return this.$store.getters["UsersModule/userList"].users.filter((item) => item.is_active);
  }

  get activeTab() {
    return this.$store.getters["MainLayoutSetting/activeTab"];
  }

  get isPersonCalendar() {
    return CalendarModule2.isPersonCalendar;
  }

  get isWorkingCalendar() {
    return CalendarModule2.isWorkingCalendar;
  }

  get isEventsCalendar() {
    return CalendarModule2.isEventsCalendar;
  }

  get isWeekends() {
    return CalendarModule2.isWeekends;
  }

  get gridStartTime() {
    return CalendarModule2.gridStartTime;
  }

  get gridStopTime() {
    return CalendarModule2.gridStopTime === "24:00" ? "00:00" : CalendarModule2.gridStopTime;
  }

  get gridTime() {
    return {
      start: this.gridStartTime,
      end: this.gridStopTime
    };
  }

  get calendarTypeWeek() {
    return CalendarModule2.type === "week";
  }
}
