



















































import { Component, Prop, Vue } from "vue-property-decorator";
import MainLink from "@/aopV2/layout/LayoutMain/MainSidebar/MainLink.vue";
import draggable from "vuedraggable";
import { IRef } from "@/aopV2/modules/Calendar/service/CalendarInterface";

@Component({
  components: { MainLink, draggable }
})
export default class MainModules extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  settingMenuList!: Array<any>;

  @Prop({
    type: Boolean,
    default: false
  })
  editMode!: Array<any>;

  ref: IRef | any = null;
  refHeight: number = 0;
  modulesList: Array<any> = [];
  localStorage: any = null;

  mounted() {
    this.ref = this.$refs.list;
    this.refHeight = this.ref.clientHeight;
    this.modulesList = this.settingMenuList;
  }

  toggleEditMode(val) {
    this.$emit("toggleEditMode", val);
    this.refHeight = this.ref.clientHeight;
  }

  startNavigation(event, drag) {
    if (this.editMode) {
      drag = true;
    }
  }

  changeNavigation(event, drag) {
    drag = false;
    this.$emit("setSettingList", this.modulesList);
  }

  get lisHeight() {
    return this.refHeight > 800 ? "" : "";
  }

  get setTitle() {
    return this.editMode ? "Редактирование" : "Все модули";
  }
}
