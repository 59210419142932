<template>
  <div class="project-description" :class="{ page: !isOnCurtain }">
    <div class="project-description__header">
      <div class="project-description__title">
        <EditField
          classSize="h2"
          keyUser="name"
          :maxLength="255"
          :user="project"
          :isReadonly="isReadonly"
          @editDone="handleEditTitle"
          placeholder="Максимум 255 символов"
        />
      </div>
      <div class="project-description__date-and-author">
        <span>Создано: {{ createdAt }}</span>
        <span>Автор: {{ projectAuthor.full_name }}</span>
      </div>
      <div class="project-description__deadlines">
        <EditableDeadline
          v-model="range"
          @deadlineChange="handleEditDeadline"
          :isReadonly="isReadonly"
        />
      </div>
    </div>
    <div class="project-description__others">
      <details open>
        <summary>
          <h3 class="project-description__label">
            <i class="icon-down-secondary"></i>
            Описание проекта
          </h3>
        </summary>
        <div class="project-description__body">
          <div
            class="project-description__parent-project"
            v-if="isOnCurtain && hasParentProject"
            :class="{
              'project-description__parent-project_disabled': isParentLocked
            }"
          >
            <h4 class="project-description__caption">Главный проект</h4>
            <p @click="goToParentProject">
              <i class="icon-lock-secondary"></i>{{ project.parent_name }}
            </p>
          </div>
          <EditField
            classSize="text"
            keyUser="description"
            :maxLength="32000"
            :user="project"
            :isReadonly="isReadonly"
            @editDone="handleEditDescription"
            placeholder="Добавьте описание проекта"
            class="project-description__text"
          />
        </div>
      </details>
    </div>
    <div class="project-description__files">
      <details open>
        <summary
          class="project-description__wrap"
          :style="!filesList.length ? 'border-bottom: none;' : ''"
        >
          <h3 class="project-description__label">
            <i class="icon-down-secondary"></i>
            Прикрепленные документы
          </h3>

          <file-upload
            v-if="filesList.length < 10 && !isReadonly"
            :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
            post-action="/upload/post"
            class="file-uploader"
            input-id="descriptionBodyTask"
            :multiple="true"
            :drop="true"
            v-model="files"
            :drop-directory="true"
            @input="handleUploadFile"
            ref="upload"
            id="upload"
          >
            <Button
              v-if="filesList.length < 10 && !isReadonly"
              class="project-description__button"
              :type="'simple'"
              :icon="'plus'"
              :title="'Добавить'"
            />
          </file-upload>
        </summary>
        <div
          class="project-description__body project-description__body_files"
          v-if="filesList.length"
        >
          <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
          <FileList :fileList="filesList" :isEdit="!isReadonly" :task="false" />
        </div>
      </details>
    </div>
    <div class="project-description__users">
      <details open>
        <summary>
          <h3 class="project-description__label">
            <i class="icon-down-secondary"></i>
            Участники проекта
          </h3>
        </summary>
        <div class="project-description__body project-description__body_row">
          <UserGroup
            class="project-description__owners"
            :users="ownersList"
            :allUsers="allUsers"
            :isEditable="!isReadonly"
            @clickCount="goToProject"
            @updated="handleChangeOwners"
            label="ВЛАДЕЛЬЦЫ"
          />
          <UserGroup
            :users="membersList"
            :allUsers="allUsers"
            :isEditable="!isReadonly"
            @clickCount="goToProject"
            @updated="handleChangeMembers"
          />
        </div>
      </details>
    </div>

    <div class="project-description__child-projects">
      <div
        class="project-description__parent-project"
        v-if="!isOnCurtain && hasParentProject"
        :class="{
          'project-description__parent-project_disabled': isParentLocked
        }"
      >
        <h4 class="project-description__caption">Главный проект</h4>
        <p @click="goToParentProject">
          <i class="icon-lock-secondary"></i>{{ project.parent_name }}
        </p>
      </div>

      <details open>
        <summary class="project-description__wrap">
          <h3 class="project-description__label">
            <i class="icon-down-secondary"></i>
            Вложенные проекты
          </h3>
          <div
            :title="
              nestingLimit
                ? 'Невозможно создать проект! Вложенность не должна быть больше 5!'
                : null
            "
            class="project-description__create-subproject"
          >
            <Button
              :type="nestingLimit ? 'disabled' : 'simple'"
              icon="plus"
              title="Добавить"
              @handleButton="addSubproject"
              v-if="!isReadonly"
            />
          </div>
        </summary>
        <div class="project-description__body child-projects">
          <ChildProject
            v-for="project in project.child_projects"
            :project="project.project"
            :key="project.project.id"
          />
          <p class="child-projects__get-more" @click="getMoreChildProjects" v-if="areThereMore">
            Показать ещё
          </p>
        </div>
      </details>
    </div>
  </div>
</template>

<script>
import EditField from "@/modules/Isp/components/EditField/EditField";
import Button from "@/modules/UiKit/components/buttons/Button";
import moment from "moment";
import ChildProject from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/ChildProject";
import UserGroup from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/UserGroup";
import EditableDeadline from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/EditableDeadline";
import { mapGetters } from "vuex";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import NotificationObserver from "@/helpers/UserNotification";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import FileList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileList";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
const FileUpload = () => import("vue-upload-component");

export default {
  name: "ProjectDescriptionBody",
  mixins: [],
  props: {
    isOnCurtain: {
      type: Boolean,
      default: true
    },
    project: {
      type: Object,
      default: () => {}
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditableDeadline,
    UserGroup,
    ChildProject,
    EditField,
    Button,
    DropFileUpload,
    FileUpload,
    FileList
  },
  data() {
    return {
      range: {
        start: null,
        end: null
      },
      allFiles: [],
      isEditDeadline: false,
      members: [],
      owners: [],
      files: []
    };
  },
  mounted() {
    this.setFields();
  },
  methods: {
    setFields() {
      this.range.start = this.project.date_from;
      this.range.end = this.project.date_to;
    },
    getMoreChildProjects() {
      ProjectModule.getChildProjectsByPagination(this.project.id);
    },
    handleEditTitle(val) {
      const updatedProject = {
        id: this.project.id,
        name: val
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    handleEditDescription(val) {
      const updatedProject = {
        id: this.project.id,
        description: val
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    handleEditDeadline(val) {
      const updatedProject = {
        id: this.project.id,
        date_from: val?.start ? moment(val.start, "YYYY:MM:DD") : null,
        date_to: val?.end ? moment(val.end, "YYYY:MM:DD") : null
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    handleChangeOwners(val) {
      if (val.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "У проекта должен быть хотя бы один владелец!"
        });
        return;
      }
      const arrayDifference = this.arrayDifference(this.ownersList, val);
      if (!arrayDifference) return;
      const addedOwners = arrayDifference.added.map((item) => item.id);
      const deletedOwners = arrayDifference.deleted.map((item) => item.id);
      const params = {
        projectId: this.project.id,
        ownersAdd: addedOwners.length ? addedOwners : undefined,
        ownersDelete: deletedOwners.length ? deletedOwners : undefined,
        changedOwners: val,
        changedMembers: [
          ...this.membersList,
          ...arrayDifference.deleted.filter(
            (item) => !this.membersList.some((el) => el.id === item.id)
          )
        ]
      };
      ProjectModule.updateProjectUsersAction(params);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    handleChangeMembers(val) {
      const membersArrayDifference = this.arrayDifference(this.membersList, val);
      if (!membersArrayDifference) return;
      const addedMembers = membersArrayDifference.added.map((item) => item.id);
      const deletedMembers = membersArrayDifference.deleted.map((item) => item.id);
      const params = {
        projectId: this.project.id,
        membersAdd: addedMembers.length ? addedMembers : undefined,
        membersDelete: deletedMembers.length ? deletedMembers : undefined,
        changedMembers: val
      };
      ProjectModule.updateProjectUsersAction(params);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно изменен!"
      });
    },
    arrayDifference(arr1, arr2) {
      const diff = {
        deleted: arr1.filter((item) => !arr2.find((el) => el.id === item.id)),
        added: arr2.filter((item) => !arr1.find((el) => el.id === item.id))
      };
      if (!diff.deleted.length && !diff.added.length) return false;
      return diff;
    },
    filesSplice(val) {
      this.allFiles.splice(val, 1);
    },
    setEditModeOfDeadline() {
      this.isEditDeadline = true;
    },
    editDeadlines() {
      this.isEditDeadline = false;
    },
    cancelEditDeadline() {
      this.isEditDeadline = false;
    },
    addSubproject() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 1,
        currentProjectForCreation: this.project
      });
    },
    goToProject() {
      ProjectModule.closeCurtainShowAndEditProject();
      this.$router.push({
        name: "Project",
        params: { id: this.project.id }
      });
    },
    goToParentProject() {
      ProjectModule.closeCurtainShowAndEditProject();
      this.$router.push({
        name: "Project",
        params: { id: this.project.parent_id }
      });
    },
    handleUploadFile(val) {
      val.forEach((item) => {
        if (item.type === "") {
          item.type = item.name.split(".").slice(-1).join("").toLowerCase();
        }
      });
      TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("upload");
      taskModule.openModalFileUpload(val);
      this.files = [];
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setDateValue() {
      return (
        moment(this.range.start).format("DD.MM.YYYY") +
        " по " +
        moment(this.range.end).format("DD.MM.YYYY")
      );
    },
    allUsers() {
      return this.userList.users.filter((item) => item.is_active !== false);
    },
    ownersList() {
      const ownersList = this.project.owners.map((item) => {
        return this.allUsers.find((user) => user.id === item.user_id);
      });
      return ownersList.every((item) => !item) ? this.project.owners : ownersList;
    },
    membersList() {
      let membersList = this.project.members.map((item) => {
        return this.allUsers.find((user) => user.id === item.user_id);
      });
      return membersList.every((item) => !item) ? this.project.members : membersList;
    },
    createdAt() {
      return moment(this.project.created_at).format("DD.MM.YYYY");
    },
    projectAuthor() {
      return this.project.author_info;
    },
    nestingLimit() {
      return this.project.level > 3;
    },
    areThereMore() {
      return ProjectModule.childProjectsResponse.count > this.project.child_projects.length;
    },
    hasParentProject() {
      return !!this.project.parent_id;
    },
    isParentLocked() {
      return this.project.parent_is_owner === null;
    },
    filesList() {
      return ProjectModule.filesList;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.project-description {
  width: 100%;
  details > summary {
    list-style: none;
    outline: none;
    cursor: pointer;
    border-top: 1px solid #eae8f0;
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }

    i {
      transform: rotate(-90deg);
    }
  }

  details[open] > summary {
    border-bottom: 1px solid #eae8f0;
    i {
      transform: rotate(0);
    }
  }

  &__header {
    padding: 24px 24px 24px 16px;

    @media (max-width: $sm) {
      padding: 16px;
    }
  }

  &__title {
    margin: 0 0 16px;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    color: $text-default;
    /deep/ {
      .textarea__control {
        font-size: 22px;
        line-height: 28px;

        @media (max-width: $sm) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }

  &__date-and-author {
    font-size: 12px;
    font-weight: 400;
    color: $text-subdued;
    padding-left: 8px;
    margin-bottom: 24px;

    @media (max-width: $sm) {
      display: flex;
      flex-direction: column;
    }

    span {
      margin-right: 16px;

      @media (max-width: $sm) {
        margin: 0 0 8px;
      }
    }
  }

  &__deadlines {
    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #0a1417;

    @media (max-width: $sm) {
      font-size: 16px;
      line-height: 20px;
    }

    i {
      font-size: 6px;
      color: $icon-subdued;
      margin-right: 16px;
    }
  }
  &__parent-project {
    padding: 0 24px;
    margin-bottom: 24px;
    h4 {
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      color: #5551f9;
      cursor: pointer;
      word-break: break-word;
      &:hover {
        color: $link-hovered;
      }
      i {
        display: none;
      }
    }
    &_disabled {
      cursor: default;
      pointer-events: none;
      p {
        display: flex;
        align-items: center;
        color: $icon-subdued;
        i {
          display: block;
          color: $icon-subdued;
          margin-right: 6px;
        }
      }
    }
  }
  &__caption {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__body {
    padding: 24px;
    position: relative;

    @media (max-width: $sm) {
      padding: 16px;
    }

    &_row {
      display: flex;
      flex-wrap: wrap;
      gap: 56px;
      row-gap: 24px;
    }

    &_files {
      min-height: 250px;
    }

    /deep/ {
      .drop-active {
        left: 0;
        top: 0;
      }

      i.icon-cloud-upload {
        font-size: 120px;
      }
    }
  }

  &__child-projects {
    @media (max-width: $sm) {
      padding-top: 16px;
    }

    .project-description__body {
      margin: 0;
    }
  }
  &__text {
    margin: 16px 0;

    /deep/ {
      .textarea__control {
        @media (max-width: $sm) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &__docs-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    /deep/ {
      label {
        cursor: pointer;
      }

      .aop-button {
        margin-left: auto;

        @media (max-width: $sm) {
          width: 40px;
          height: 40px;
          padding: 6px 10px;
        }

        i {
          @media (max-width: $sm) {
            margin: 0;
            font-size: 20px;
          }
        }

        span {
          @media (max-width: $sm) {
            display: none;
          }
        }
      }
    }
  }
  &__docs-body {
    padding-top: 32px;
  }
  &__owners {
    @media (max-width: $sm) {
      margin: 0 0 16px;
    }
  }
  &__create-subproject {
    margin-left: auto;
  }
  &__child-projects {
    /deep/ .aop-button {
      @media (max-width: $sm) {
        width: 40px;
        height: 40px;
        padding: 8px 9px;
      }

      i {
        @media (max-width: $sm) {
          margin: 0;
          font-size: 20px;
        }
      }

      span {
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }

  &__parent-project {
    border-top: 1px solid $secondary;
    padding-top: 24px;
  }

  .child-projects {
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }

    &__get-more {
      font-size: 16px;
      color: $link-default;
      cursor: pointer;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &_on-page {
      display: none;
    }
  }

  .file-uploads {
    /deep/ label {
      cursor: pointer;
    }

    &:hover .aop-button {
      filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
        drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
        drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
    }
  }
}
.page {
  .project-description {
    &__header {
      border-bottom: none;
      padding: 24px 24px 12px 16px;
      @media (max-width: $sm) {
        padding: 16px 16px 16px 8px;
      }
    }

    &__others {
      details > summary {
        display: none;
      }
    }
    &__users {
      display: none;
    }
    &__wrap_on-page {
      display: flex;
    }
  }
}
</style>
