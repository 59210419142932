<template>
  <div
    class="comment"
    :class="parentComment ? 'comment-child' : 'comment'"
    :id="`comment-row_${comment.id}`"
  >
    <div class="comment__header">
      <User
        :avatar="setAuthor.avatar"
        :name="setAuthor.full_name"
        :info="setRole"
        :showTooltip="false"
        :size="'40px'"
        :row="true"
        :id="setAuthor.id"
      />
      <div class="comment__date">
        {{ setDateFormatWithoutLetterT(comment.created_at) }}
        {{ setTimeFormatWithoutLetterT(comment.created_at) }}
      </div>
      <div
        class="comment__replies-button"
        v-if="comment.count_comments > 0"
        @click="showHideReplies"
      >
        <p v-if="!showReplies" @click="getAnswer" :style="'white-space: nowrap;'">
          Ответы ({{ comment.count_comments }})
        </p>
        <p v-if="showReplies" @click="resetAnswer">Скрыть</p>
      </div>
    </div>
    <div class="comment__body">
      <Skeleton v-if="isEditStatus" :borderRadius="'0 16px 16px 16px'" />
      <p
        class="comment__content"
        v-if="comment.text"
        ref="content"
        :style="
          bigTextTrigger
            ? `display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;`
            : ''
        "
        v-html="setURL(`<span>${comment.text}</span>`)"
      ></p>

      <p class="comment__empty" v-else></p>
      <span v-if="showTrigger">
        <span
          class="comment__plain-button"
          @click="showBigText(comment.text)"
          v-if="bigTextTrigger"
        >
          <span>Показать полностью</span>
        </span>
        <span class="comment__plain-button" @click="hideBigText" v-if="!bigTextTrigger">
          <span>Свернуть</span>
        </span>
      </span>
      <FilesList :fileList="comment.files" />
    </div>
    <div :class="parentComment ? 'comment__footer-child' : 'comment__footer'">
      <p
        class="comment__reply"
        v-if="!mode.length && !parentComment && haveReply"
        @click="replyHandler"
      >
        <i class="icon-reply-arrow"></i>Ответить
      </p>
      <p class="comment__edit" @click.stop="editHandler" v-if="canEdit">
        <i class="icon-edit"></i>Редактировать
      </p>
      <p
        class="comment__delete"
        @click.stop="deleteComment({ id: comment.id, parent: comment.parent_id })"
        v-if="canDelete"
      >
        <i class="icon-delete"></i>Удалить
      </p>
      <p class="comment__cancel" v-if="mode.length" @click.stop="cancelMode">
        <i class="icon-cross"></i>Отменить
      </p>
    </div>

    <slide-up-down class="comment__replies" :active="showReplies && comment.count_comments > 0">
      <CommentsItem
        v-for="reply in answerList.list"
        :key="`answer_${reply.id}`"
        :comment="reply"
        :listData="answerList.list"
        :page="answerList.page"
        :totalCount="answerList.count"
        :modeAction="mode"
        :parentComment="comment"
        @deleteAction="deleteComment({ id: reply.id, parent: reply.parent_id })"
        @setReplyComment="setReplyComment"
        @setCurrentComment="setCurrentComment(reply)"
        @changeModeAction="changeModeAction"
        @cancelMode="cancelMode"
        @paginationEventAction="paginationEventAction"
      />
      <div class="comment__spinner" v-if="showReplies && !answerList.list.length">
        <Spinner :small="true" />
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import SlideUpDown from "vue-slide-up-down";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import { format, parseISO } from "date-fns";
import UrlMixin from "@/helpers/UrlMixin";
import { mapGetters } from "vuex";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import FilesList from "@/modules/UiKit/components/Comments/CommentsFiles/FilesList";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
import uploadCommentsFilesMixin from "@/helpers/Files/uploadCommentsFiles.mixin";

export default {
  name: "CommentsItem",
  mixins: [formatDateAndTime, UrlMixin, uploadCommentsFilesMixin],
  props: {
    comment: {
      type: Object,
      default: null
    },
    childList: {
      type: Object,
      default: null
    },
    listData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    },
    parentComment: {
      type: Object,
      default: null
    },
    modeAction: {
      type: String,
      default: ""
    },
    formLoader: {
      type: Boolean,
      default: false
    },
    currentComment: {
      type: Object,
      default: () => null
    },
    haveReply: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Skeleton,
    FilesList,
    Spinner,
    User,
    SlideUpDown
  },
  data() {
    return {
      mode: "",
      showReplies: false,
      bigTextTrigger: true,
      contentHeight: 0,
      content: "",
      target: null,
      inViewModel: true
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.contentHeight = this.$refs.content.offsetHeight;
    if (this.parentComment) {
      this.$nextTick(() => {
        this.contentHeight = this.getHeight(this.$refs.content);
      });
    }
    this.target = document.querySelector(`#comment-row_${this.comment.id}`);
    const taskList = JSON.parse(JSON.stringify(this.listData)).reverse();
    if (taskList[1]?.id === this.comment.id) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.totalCount !== this.listData.length) {
              this.paginationEventAction({
                page: this.page,
                parent_id: this.comment.parent_id ? this.comment.parent_id : undefined
              });
              this.observer.unobserve(this.target);
            }
          }
        });
      });
      this.observer.observe(this.target);
    }
  },
  methods: {
    getAnswer() {
      this.$emit("getAnswer", this.comment);
    },
    resetAnswer() {
      this.$emit("resetAnswer", this.comment.id);
    },
    replyHandler() {
      this.mode = "reply";
      this.changeModeAction(this.mode);
      this.$emit("setCurrentComment", this.comment);
      this.$emit(
        "setReplyComment",
        this.setAuthor ? this.setAuthor.full_name.split(" ")[1] + ", " : ""
      );
      document.getElementById(`textareaForm`).focus();
    },
    editHandler() {
      this.mode = "edit";
      this.changeModeAction(this.mode);
      this.setReplyComment(this.comment.text);
      this.setCurrentComment(this.comment);
      const textarea = document.getElementById(`textareaForm`);
      textarea.focus();
      const list = document.getElementById(`commentsList`);
      this.$nextTick(() => {
        textarea.style.height = `${textarea.scrollHeight}px`;
        if (textarea.scrollHeight < 250) {
          list.style["max-height"] = `calc(100% - ${textarea.scrollHeight}px)`;
        } else {
          list.style["max-height"] = `calc(100% - 250px)`;
        }
      });
    },
    setReplyComment(val) {
      this.$emit("setReplyComment", val);
    },
    setCurrentComment(val) {
      this.$emit("setCurrentComment", val);
    },
    changeModeAction(val) {
      this.$emit("changeModeAction", val);
    },
    deleteComment(val) {
      this.setCurrentComment(this.comment);
      this.$emit("deleteAction", val);
    },
    cancelMode() {
      this.mode = "";
      this.content = "";
      document.getElementById(`textareaForm`).style.height = `57px`;
      document.getElementById(`commentsList`).style["max-height"] = `calc(100% - 130px)`;
      this.changeModeAction(this.mode);
      this.setReplyComment("");
      this.setCurrentComment(null);
    },
    showHideReplies() {
      this.showReplies = !this.showReplies;
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    hideBigText() {
      if (this.contentHeight > 70) {
        this.bigTextTrigger = true;
      }
    },
    getHeight(element) {
      const elementClone = element.cloneNode(true);
      elementClone.style.visibility = "hidden";
      document.body.appendChild(elementClone);
      const height = elementClone.offsetHeight + 0;
      document.body.removeChild(elementClone);
      elementClone.style.visibility = "visible";
      return height;
    },
    paginationEventAction(params) {
      this.$emit("paginationEventAction", params);
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.comment.user_id);
    },
    showTrigger() {
      return this.contentHeight > 70;
    },
    canDelete() {
      return (
        this.comment.user_id === this.$store.getters.getDataInfoUser.id &&
        !this.mode &&
        (this.comment.text || this.comment.files.length)
      );
    },
    canEdit() {
      const timeDif =
        new Date().getTime() / 3600000 - format(parseISO(this.comment.created_at), "T") / 3600000 <
        1;
      return this.canDelete && timeDif;
    },
    setRole() {
      return this.setAuthor
        ? this.setAuthor.roles.length
          ? `[${this.setAuthor.roles[0].name}]`
          : ""
        : "";
    },
    answerList() {
      return this.childList
        ? this.childList[`answer_${this.comment.id}`] !== undefined
          ? this.childList[`answer_${this.comment.id}`]
          : { list: [], count: 0, page: 1 }
        : { list: [], count: 0, page: 1 };
    },
    isEditStatus() {
      return (
        this.mode === "edit" &&
        (this.formLoader || this.loading) &&
        this.currentComment &&
        this.currentComment.id === this.comment.id
      );
    }
  },
  watch: {
    modeAction() {
      this.mode = this.modeAction;
    },
    comment() {
      this.$nextTick(() => (this.contentHeight = this.$refs.content.offsetHeight));
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.comment {
  width: 100%;

  &:hover &__footer {
    opacity: 1;
  }

  &__spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }

  &__header {
    display: flex;
    align-items: center;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    /deep/ {
      .user {
        align-items: center !important;
      }

      .user__main-info {
        flex-wrap: wrap;
      }

      .user__name {
        max-width: 220px;
        width: max-content;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $text-default;
        letter-spacing: -0.3px;
      }

      .user__description {
        max-width: 220px;
        width: max-content;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: $text-default;
        margin-right: 8px;
      }
    }
  }

  &__replies-button {
    font-size: 12px;
    color: #5551f9;
    margin-left: auto;
    cursor: pointer;

    @media (max-width: $sm) {
      margin: 8px 0 0 auto;
    }
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $text-default;
    white-space: nowrap;
    margin-right: 8px;

    @media (max-width: $sm) {
      margin: 8px 0 0 48px;
    }
  }

  &__body {
    position: relative;
    max-width: 92%;
    width: max-content;
    margin: 16px 0 6px 48px;
    word-break: break-word;
    padding: 8px;
    background: #f2f7fe;
    border-radius: 0 16px 16px 16px;

    @media screen and (max-width: $sm) {
      max-width: 80%;
    }

    p {
      color: $text-default;
      font-size: 14px;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }

  &__plain-button {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $link-default;
  }

  &__empty {
    color: $text-disabled !important;
  }

  &__files {
    padding: 0;
    display: flex;
  }

  &__footer {
    width: fit-content;
    margin-left: auto;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    opacity: 0;
    padding: 0 0 12px 40px;

    p {
      margin: 0 6px;
      color: #5551f9;
      display: flex;
      align-items: center;

      i {
        margin-right: 2px;
        font-size: 14px;
      }
    }

    @media (max-width: $lg) {
      opacity: 1;
    }
  }

  &__footer-child {
    width: fit-content;
    margin-left: auto;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    opacity: 0;
    padding: 0 0 12px 40px;

    p {
      margin: 0 6px;
      color: #5551f9;
      display: flex;
      align-items: center;

      i {
        margin-right: 2px;
        font-size: 14px;
      }
    }
  }

  &__replies {
    padding: 0 0 0 40px;
  }
}
.comment-child {
  &:hover .comment__footer-child {
    opacity: 1;
  }
}
</style>
