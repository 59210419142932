<template>
  <ul class="time__statistics">
    <TimeStatisticsItem v-for="(item, index) in timeStatistics" :key="index" :item="item" />
    <li class="time__statistics-item" :style="{ background: '#FFF8E5', color: '#CA7B00' }">
      <p class="time__value" :style="{ color: '#CA7B00' }">
        <span class="time__round" :style="{ background: '#CA7B00' }"></span>Больничный
      </p>
    </li>
    <li class="time__statistics-item" :style="{ background: '#F2F2F7', color: '#6D7175' }">
      <p class="time__value" :style="{ color: '#6D7175' }">
        <span class="time__round" :style="{ background: '#6D7175' }"></span>Отпуск
      </p>
    </li>
  </ul>
</template>

<script>
import TimeStatisticsItem from "@/modules/Tables/components/TablesStatistics/Time/TimeStatisticsItem";

export default {
  name: "TimeStatistics",
  mixins: [],
  props: {
    timeStatistics: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { TimeStatisticsItem },
  data() {
    return {
      statisticsList: []
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  height: 0 !important;
}

.time {
  &__statistics {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 24px;
    border-top: 1px solid $border-subdued;

    @media(max-width: $xxl) {
      overflow-x: scroll;
    }

    @media (max-height: $sm) {
      padding: 8px 24px;
    }
  }

  &__statistics-item {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    margin-right: 24px;
    width: max-content;
    height: 24px;
    border-radius: 40px;
  }

  &__value {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    span {
      margin-right: 8px;
    }
  }

  &__round {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
