


















































import {Component, Prop, Vue} from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import EventCardMonth from "@/aopV2/modules/Calendar/components/CalendarEventCard/EventCardMonth.vue";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";

@Component({
  components: {MainButtonNew, UserCard, EventCardMonth}
})
export default class ModalEmployment extends Vue {
  handleBack() {
    CalendarModule2.showEmployment(false);
  }

  handleContinue() {
    CalendarModule2.changeEvent(this.createData);
    this.handleBack();
  }

  handleFS() {
    this.$router.push({name: "CreateEvent"});
    this.handleBack();
  }

  get createData() {
    return CalendarModule2.createData;
  }

  get employment() {
    return CalendarModule2.employment;
  }

  get users() {
    return this.employment.overlapping_by_users.length
      ? Array.from(
        new Set(
          this.employment.overlapping_by_users
            .map((item) => item.all_users!.map((user) => user.user_id))
            .flat(1)
        )
      ).map((item) => ({id: item}))
      : [];
  }

  get isLocation() {
    return this.employment.overlapping_by_location.length;
  }

  get isUser() {
    return this.employment.overlapping_by_users.length;
  }

  get title() {
    if (this.isLocation && this.isUser) {
      return "Все что могло пойти не так — пошло не так...";
    } else {
      if (this.isLocation) {
        return "Упс! Кабинет занят";
      }
      if (this.isUser) {
        return "Упс! Что-то пошло не так...";
      }
      return "";
    }
  }

  get text() {
    if (this.isLocation && this.isUser) {
      return (
        "Так получилось, что и кабинет занят, и кто-то из участников не сможет присутствовать на мероприятии. " +
        "Рекомендуем перейти в полноэкранный режим создания мероприятия для точной настройки."
      );
    } else {
      if (this.isLocation) {
        return (
          "Возможно в это время кабинет будет занят. " +
          "Выберите другой или перейдите к полноэкранному режиму создания мероприятия для точной настройки."
        );
      }
      if (this.isUser) {
        return (
          "Возможно кто-то из участников не сможет присуствовать на мероприятии в это время. " +
          "Перейдите к полноэкранному режиму создания мероприятия для точной настройки."
        );
      }
      return "";
    }
  }
}
