var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"feedback"},[_c('li',{staticClass:"feedback__item",class:{ feedback__item_active: _vm.isClosed },on:{"click":function($event){return _vm.$root.$emit('openModalBugsAndIdea', {
        title: 'Предложить идею',
        label: 'Идея',
        placeholder: 'Опишите идею',
        type: 'Идея'
      })}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 2V13H10V22L17 10H13L17 2H7Z","fill":"#E7E9ED"}})]),_c('p',[_vm._v("Предложить идею")])]),_c('li',{staticClass:"feedback__item",class:{ feedback__item_active: _vm.isClosed },on:{"click":function($event){return _vm.$root.$emit('openModalBugsAndIdea', {
        title: 'Сообщить о проблеме',
        label: 'Проблема',
        placeholder: 'Опишите проблему',
        type: 'Баг'
      })}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 21H23L12 2L1 21Z","fill":"#E7E9ED"}}),_c('path',{attrs:{"d":"M13 14H11V8H13V14Z","fill":"#B0B7C3"}}),_c('path',{attrs:{"d":"M13 18H11V16H13V18Z","fill":"#B0B7C3"}})]),_c('p',[_vm._v("Сообщить о проблеме")])])])}
var staticRenderFns = []

export { render, staticRenderFns }