<template>
  <div class="modal__footer">
    <button class="modal__button modal__button_cancel" type="button" @click="closeModal">
      Отменить
    </button>
    <button
      class="modal__button"
      type="button"
      v-if="!editMode && createMode"
      @click="createVacationOrder"
    >
      Создать
    </button>
    <button class="modal__button" type="button" v-if="editMode" @click="updateVacationOrder">
      Сохранить
    </button>
    <button
      class="modal__button modal__button_disabled"
      type="button"
      v-if="!createMode && !editMode"
      @click="updateVacationOrder"
      :disabled="true"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import moment from "moment";
import { format } from "date-fns";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalFooter",
  mixins: [],
  props: {
    editMode: {
      type: Boolean,
      default: true
    },
    createMode: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    vacation: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {},
  data() {
    return {
      dataConfirmUser: this.vacation.confirmations || [],
      dateStartVacation: this.vacation.date_from || format(new Date("2022-01-10"), "Y-MM-dd"),
      dateEndVacation: this.vacation.date_to || format(new Date("2022-01-10"), "Y-MM-dd"),
      selectUser: {},
      dataType: {
        name: "План",
        value: "plane_date"
      }
    };
  },
  mounted() {
    this.$root.$on("setDataModal", (val) => {
      this.dataConfirmUser = val.dataConfirmUser;
    });
    this.$root.$on("date:change_modal-request-vacation-start-data", (val) => {
      this.dateStartVacation = val;
    });
    this.$root.$on("date:change_modal-request-vacation-end-data", (val) => {
      this.dateEndVacation = val;
    });
    this.$root.$on("chooseDateCheckboxValue", (val) => {
      this.dataType = val;
    });
    this.$root.$on("changeUserVacationOrder", (val) => {
      this.selectUser = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("setDataModal");
    this.$root.$off("changeUserVacationOrder");
    this.$root.$off("chooseDateCheckboxValue");
    this.$root.$off("date:change_modal-request-vacation-start-data");
    this.$root.$off("date:change_modal-request-vacation-end-data");
  },
  methods: {
    setData() {
      let data = [
        {
          user_id: this.getUserId,
          date_from: this.dateStartVacation,
          date_to: this.dateEndVacation,
          confirmations: JSON.parse(JSON.stringify(this.dataConfirmUser))
        }
      ];
      // Проверка на кадровика
      if (this.isAdmin) {
        // Проверка на тип даты
        if (this.dataType.value === "plane_date") {
          if (this.dataConfirmUser.length) {
            return (data = [
              {
                user_id: this.selectUser.id,
                plane_date_from: this.dateStartVacation,
                plane_date_to: this.dateEndVacation,
                date_from: this.dateStartVacation,
                date_to: this.dateEndVacation,
                confirmations: JSON.parse(JSON.stringify(this.dataConfirmUser))
              }
            ]);
          } else {
            // Проверка если нет подтверждающих лиц отправляется статус accepted
            return (data = [
              {
                user_id: this.selectUser.id,
                plane_date_from: this.dateStartVacation,
                plane_date_to: this.dateEndVacation,
                date_from: this.dateStartVacation,
                date_to: this.dateEndVacation,
                status: "accepted"
              }
            ]);
          }
        } else {
          // Проверка на тип даты
          if (this.dataConfirmUser.length) {
            return (data = [
              {
                user_id: this.selectUser.id,
                date_from: this.dateStartVacation,
                date_to: this.dateEndVacation,
                confirmations: JSON.parse(JSON.stringify(this.dataConfirmUser))
              }
            ]);
          } else {
            // Проверка если нет подтверждающих лиц отправляется статус accepted
            return (data = [
              {
                user_id: this.selectUser.id,
                date_from: this.dateStartVacation,
                date_to: this.dateEndVacation,
                status: "accepted"
              }
            ]);
          }
        }
      } else {
        return data;
      }
    },
    createVacationOrder() {
      if (!this.checkValidationData()) return null;
      httpClient({
        url: `/VacationOrder/actionCreate`,
        method: "POST",
        data: this.setData()
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на отпуск успешно создан!"
          });
          this.closeModal();

          this.$root.$emit("updateVacationList");
          this.$root.$emit("updateAbsenceList");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    updateVacationOrder() {
      if (!this.checkValidationData()) return null;
      let dataUpdate = this.setData();
      dataUpdate[0].id = this.vacation.id;
      httpClient({
        url: `/VacationOrder/actionUpdate?id=${this.vacation.id}`,
        method: "PUT",
        data: dataUpdate
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на отпуск отредактирован!"
          });
          this.closeModal();
          this.$root.$emit("updateVacationList");
          this.$root.$emit("updateAbsenceList");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          if (error.response.data.data.error.message.date_from) {
            const msg = error.response.data.data.error.message.date_from[0];
            return this.$root.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `${msg}`
            });
          }
          this.errorAlert(error);
        });
    },
    checkValidationData() {
      if (!moment(this.dateStartVacation, "YYYY-MM-DD").isValid()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите дату начала отпуска!"
        });
        return false;
      }
      if (!moment(this.dateEndVacation, "YYYY-MM-DD").isValid()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите дату конца отпуска!"
        });
        return false;
      }

      if (!this.dataConfirmUser.length && !this.isAdmin) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Укажите подтверждающих лиц!"
        });
        return false;
      }
      if (this.selectUser.id === undefined && this.isAdmin && !this.editMode) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Выберите пользователя!"
        });
        return false;
      }

      if (this.dataConfirmUser.length > 3) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Подтверждающих лиц не может быть больше 3!"
        });
        return false;
      }

      if (
        moment(this.dateStartVacation, "YYYY-MM-DD") > moment(this.dateEndVacation, "YYYY-MM-DD")
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Дата начала должна быть раньше или равняться Дате окончания!"
        });
        return false;
      }

      if (
        moment(this.dateStartVacation).diff(moment().add(5, "day").format("YYYY-MM-DD"), "days") <=
        -5
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Дата начала должна быть позже ${moment().add(5, "day").format("YYYY-MM-DD")}!`
        });
        return false;
      }

      return true;
    },
    closeModal() {
      this.$root.$emit("closeConfirmationModalPersonnelOfficer");
      this.$root.$emit("hiddenGraphVacationModal");
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.modal {
  &__footer {
    border-top: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 20px 42px 30px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }
    &_disabled {
      background: $action-critical-disabled;
      &:hover {
        background: $action-critical-disabled;
      }
    }
    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
