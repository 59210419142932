<template>
  <div class="pagination-widget">
    <div class="pagination-widget__container">
      <div class="pagination-widget__paginate" v-if="countPages">
        <button
          class="pagination-widget__button"
          :class="[
            'pagination-widget__button_prev',
            activeTab === 0 ? 'pagination-widget__button_disabled' : ''
          ]"
          type="button"
          @click="prevPage"
        >
          <i class="icon-left"></i>
        </button>
        <div class="pagination-widget__items">
          <div
            v-for="(page, idx) in countPages"
            :key="`${page}_${idx}`"
            :class="[
              'pagination-widget__item',
              idx === activeTab ? 'pagination-widget__item_active' : ''
            ]"
            @click="updatePage(idx)"
            v-if="
              idx === 0 ||
              idx === activeTab ||
              idx === activeTab + 1 ||
              (activeTab === 2 ? idx === activeTab - 1 : false) ||
              idx === activeTab + 2 ||
              (activeTab === countPages - 1
                ? idx === activeTab - 1 || idx === activeTab - 2
                : false) ||
              (activeTab === countPages - 2 ? idx === activeTab - 1 : false) ||
              (activeTab === countPages - 3 ? idx === activeTab - 1 : false) ||
              idx === countPages - 1
            "
          >
            <span
              class="pagination-widget__points pagination-widget__points_next"
              v-if="page === countPages && activeTab <= countPages - 4"
            >
              ...
            </span>
            {{ page }}
            <span
              class="pagination-widget__points pagination-widget__points_prev"
              v-if="page === 1 && activeTab >= 3"
            >
              ...
            </span>
          </div>
        </div>
        <button
          class="pagination-widget__button"
          :class="[
            'pagination-widget__button_next',
            activeTab === countPages - 1 ? 'pagination-widget__button_disabled' : ''
          ]"
          type="button"
          @click="nextPage"
        >
          <i class="icon-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationWidget",
  mixins: [],
  props: {
    totalCount: {
      type: Number,
      default: 0
    },
    currentPerPage: {
      type: Number,
      default: 0
    },
    dontScrollTop: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      activeTab: 0
    };
  },
  mounted() {
    this.$root.$on("resetPaginationValue", () => {
      this.activeTab = 0;
    });
  },
  methods: {
    updatePage(idx) {
      this.activeTab = idx;
    },
    prevPage() {
      this.activeTab === 0 ? (this.activeTab = 0) : this.activeTab--;
    },
    nextPage() {
      this.activeTab === this.countPages - 1
        ? (this.activeTab = this.countPages - 1)
        : this.activeTab++;
    }
  },
  computed: {
    countPages() {
      return Math.ceil(this.totalCount / this.currentPerPage);
    }
  },
  watch: {
    activeTab() {
      if (!this.dontScrollTop) {
        document.getElementsByClassName("b-table-sticky-header")[0].scrollTop = 0;
      }
      let from = this.activeTab * this.currentPerPage + 1;
      this.$emit("changePagPage", from);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.pagination-widget {
  user-select: none;
  &__container {
    user-select: none;
  }

  &__paginate {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: max-content;
    border: 0;
    padding: 0;
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $text-subdued;
    margin-left: 32px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;

    &_prev {
      margin-right: 8px;
    }

    &_disabled {
      cursor: auto;
      color: $text-disabled;
    }

    i {
      display: block;
      font-size: 16px;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__points {
    padding: 10px 16px;

    color: #787996;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    &_prev {
      padding-right: 0;
    }

    &_next {
      padding-left: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-self: center;
    margin-right: 8px;
    padding: 0 8px;
    height: 24px;
    color: #787996;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    border-radius: 4px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;

    &_active {
      color: $action-primary;
      background-color: #f1effa;
    }
  }
}
</style>
