<template>
  <li class="task" v-click-outside="closeTask">
    <i class="icon-delete" @click.stop="deleteTask" v-if="isDeleteTask"></i>
    <div class="task__header" @click.self="showDetail = !showDetail">
      <CheckboxISP
        v-model="task.is_complete"
        :isEdit="isEditTask && task.report && task.report.length"
        :isComplete="task.executors.length"
        errorMessage="Необходимо добавить исполнителя задачи!"
        @input="editComplete"
        @fillReport="fillReport"
      />
      <DeadLineComponent
        v-model="selectDate"
        :value="task.deadline"
        :showLabel="false"
        @clearCalendar="pickCalendar"
        @switchCalendar="switchCalendar"
        @closeCalendar="closeCalendar"
        :showCalendar="showCalendar"
        :isEdit="isEditTask && !task.is_complete"
      />
      <TaskExecutorsComponent
        :users="task.executors"
        @editExecutors="handleEditExecutors"
        :isEdit="isEditTask && !task.is_complete"
      />
      <DeadLineCalendar v-model="selectDate" v-show="showCalendar" @closeCalendar="pickCalendar" />
    </div>
    <div class="task__description">
      <EditField
        classSize="h3"
        keyUser="name"
        :maxLength="250"
        :user="task"
        :isReadonly="!isEditTask || task.is_complete"
        @editDone="handleEditName"
        placeholder="Максимум 250 символов"
        :editableTitle="task.name"
      />
    </div>
    <div v-if="showDetail" class="task__details details">
      <EditField
        keyUser="report"
        classSize="text"
        :maxLength="3500"
        @editDone="handleEditReport"
        :user="task"
        :dataInput="setAttribute"
        :isReadonly="!isEditTask || task.is_complete"
        label="Отчет по решению задачи"
        placeholder="Добавьте отчет"
      />
      <div class="details__files-wrap">
        <div class="details__label">Прикрепленные документы</div>
        <file-upload
          class="file-uploader"
          post-action="/upload/post"
          :multiple="true"
          v-model="files"
          :input-id="'task' + task.id"
          ref="upload"
          :maximum="5"
          acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
          :disabled="loading || setMaximum || !isEditTask || task.is_complete"
          @input="uploadFile"
        >
          <Button :type="setType" icon="add-file" title="Прикрепить" :loader="loading" />
        </file-upload>
      </div>
      <DocsComponent
        :isEdit="isEditTask"
        :uploadFiles="task"
        v-if="task.attachment_files"
        @editFiles="handleEditFiles"
      />
    </div>
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="hideConfirmModal"
      @accept="confirmParams.callbackName"
    />
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";

const FileUpload = () => import("vue-upload-component");
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import DeadLineComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineComponent";
import TaskExecutorsComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/TasksBody/members/TaskExecutorsComponent";
import DeadLineCalendar from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineCalendar";
import EditField from "@/modules/Isp/components/EditField/EditField";
import ClickOutside from "vue-click-outside";

import DocsComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/Documents/DocsComponent";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import Button from "@/modules/UiKit/components/buttons/Button";
import moment from "moment";
import { EventBus } from "@/main";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "TaskItem",
  mixins: [uploadFilesMixin],
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    ConfirmModal,
    DeadLineCalendar,
    TaskExecutorsComponent,
    DeadLineComponent,
    CheckboxISP,
    EditField,
    DocsComponent,
    Button,
    FileUpload
  },
  data() {
    return {
      showDetail: false,
      showCalendar: false,
      loading: false,
      selectDate: null,
      showConfirmModal: false,
      files: [],
      confirmParams: {
        title: "Удалить?",
        description: "Вы уверены, что хотите удалить выбранную задачу?",
        callbackName: () => this.handleDeleteTask(this.task.id)
      },

      temporaryFiles: []
    };
  },
  mounted() {
    this.selectDate = this.task.deadline;
    this.files = this.task.attachment_files;
  },
  methods: {
    ...mapActions("IspModule", ["handleUpdateTask", "handleDeleteTask"]),
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    fillReport() {
      if (this.isEditTask) {
        if (!this.task.report) {
          NotificationObserver.notification({
            type: "error",
            message: "Необходимо добавить отчет!"
          });
        }
        this.showDetail = true;
        this.$nextTick(() => {
          const $input = document.querySelector(`[data-input="${this.setAttribute}"]`);
          $input.focus();
        });
      }
    },
    editComplete(is_complete) {
      if (this.isEditTask) {
        this.handleUpdateTask({
          data: {
            info: this.task,
            change: {
              is_complete
            },
            users: this.task.executors
          },
          files: this.task.attachment_files
        });
      }
    },
    closeTask(e) {
      const path = e.path || e.composedPath();
      const isClose = path.every(
        (item) => item.className !== "file-uploader file-uploads file-uploads-html5"
      );
      if (isClose) this.showDetail = false;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    handleErrorUpload() {
      this.files = this.task.attachment_files;
    },
    deleteTask() {
      this.showConfirmModal = true;
    },
    hideConfirmModal() {
      this.showConfirmModal = false;
    },
    handleEditFiles(attachment_files) {
      this.files = attachment_files.files;
      this.handleUpdateTask({
        data: {
          info: this.task,
          change: {
            attachment_files: attachment_files.ids
          },
          users: this.task.executors
        },
        files: attachment_files.files
      });
    },
    pickCalendar(deadline) {
      this.closeCalendar();
      this.selectDate = deadline;
      const date = deadline ? moment(deadline).format("YYYY-MM-DD") : null;
      if (
        deadline &&
        this.currentInfoProblem.deadline &&
        moment(deadline) > moment(this.currentInfoProblem.deadline)
      ) {
        NotificationObserver.notification({
          type: "error",
          message: "Дедлайн задачи не может быть позже дедлайна проблемы!"
        });
        this.selectDate = this.task.deadline;
      } else {
        this.handleUpdateTask({
          data: {
            info: this.task,
            change: {
              deadline: date
            },
            users: this.task.executors
          },
          files: this.task.attachment_files
        });
      }
    },
    handleEditReport(report) {
      this.handleUpdateTask({
        data: {
          info: this.task,
          change: {
            report
          },
          users: this.task.executors
        },
        files: this.task.attachment_files
      });
    },
    handleEditExecutors(executor_users) {
      this.handleUpdateTask({
        data: {
          info: this.task,
          change: {
            executor_users: executor_users.ids
          },
          users: executor_users.users
        },
        files: this.task.attachment_files
      });
    },
    handleEditName(name) {
      this.handleUpdateTask({
        data: {
          info: this.task,
          change: {
            name
          },
          users: this.task.executors
        },
        files: this.task.attachment_files
      });
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem"]),
    setAttribute() {
      return "taskReport" + this.task.id;
    },
    setDeadline() {
      return new Date(this.task.deadline);
    },
    setMaximum() {
      return this.task.attachment_files.length === 5;
    },
    setType() {
      return this.setMaximum || !this.isEditTask || this.task.is_complete ? "disabled" : "white";
    },
    isEditTask() {
      return (
        (this.isGroupLeader || this.isResponsible || this.isExecutor || this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isDeleteTask() {
      return (
        (this.isGroupLeader || this.isResponsible || this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    // Права редактирования
    isEditableStatus() {
      return (
        this.currentInfoProblem.status.type !== "done" &&
        this.currentInfoProblem.status.type !== "final"
      );
    },
    isArchived() {
      return this.currentInfoProblem.is_archived;
    },
    isGroupLeader() {
      return this.currentInfoProblem.group.leader_user_id === this.getUserId;
    },
    isExecutor() {
      return this.currentInfoProblem.task_executors.some((item) => item.id === this.getUserId);
    },
    isCustomer() {
      return this.currentInfoProblem.customer_user.id === this.getUserId;
    },
    isResponsible() {
      return this.currentInfoProblem.responsible_user.id === this.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
    // Права редактирования
  },
  watch: {
    task() {
      this.selectDate = this.task.deadline;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.slide-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 1500px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.task {
  width: 100%;
  padding: 24px 0 0;
  border-bottom: 1px solid #eae8f0;
  position: relative;
  &:hover {
    .icon-delete {
      visibility: visible;
      opacity: 1;
    }
  }
  .icon-delete {
    visibility: hidden;
    opacity: 0;
    display: flex;
    position: absolute;
    right: 24px;
    top: 32px;
    font-size: 24px;
    color: $icon-subdued;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: $icon-default;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 24px;
    margin: 0 0 16px;
    cursor: pointer;
    /deep/ {
      .checkbox-bg {
        margin-right: 24px;
      }
      .date-picker__calendar {
        padding: 0;
      }

      .members {
        margin-left: 16px;
      }
    }
  }
  &__deadline {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6a67ce;
    margin-left: 10px;
  }
  &__members {
    display: flex;
    align-items: center;
    margin-left: 16px;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__attached {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #a7a9c0;
    margin-left: 24px;
  }
  &__description {
    padding: 0 16px;
    margin: 0 0 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #343748;
    transition: 0.7s;
    cursor: pointer;
  }
  &__details {
    display: flex;
    flex-direction: column;
    padding: 0px 16px 24px;

    .details {
      &__files-wrap {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
      }

      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #000000;
        padding-left: 8px;
      }
    }

    /deep/ {
      .textarea__wrap {
        margin: 0 0 16px;
      }

      .textarea__label {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        margin-left: 8px;
      }

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
