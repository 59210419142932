<template>
  <section class="task">
    <div class="task__loader" v-if="loader">
      <Spinner />
    </div>
    <TaskBody
      v-if="currentTask && !loader"
      :task="currentTask"
      :showBody="showBody"
      @handleEdit="handleEdit"
      :isOwner="isOwner"
      :isMember="isMember"
      :isAuthor="isAuthor"
      :isExecutor="isExecutor"
      :isParentEditUser="isParentEditUser"
      @changeCurrentTab="changeCurrentTab"
    />
    <SubtaskList
      v-if="showSubtaskBody"
      :subtasks="subtaskList"
      :task="currentTask"
      :isOwner="isOwner"
      :isMember="isMember"
      :isAuthor="isAuthor"
      :isExecutor="isExecutor"
      @handleEdit="handleEdit"
      @getSubtasksList="getSubtasksList"
    />
    <MembersBody
      v-if="showMembersBody"
      :author="setAuthor"
      :executor="setExecutor"
      :isOwner="isOwner"
      :isMember="isMember"
      :isAuthor="isAuthor"
      :isExecutor="isExecutor"
      :userList="isUsersList"
      @handleEdit="handleEdit"
    />
    <CommentsBodyTask v-if="showCommentsBody" @getTask="updateCommentsCountTask" />
    <HistoryTask v-if="showHistoryBody" />
  </section>
</template>

<script>
import TaskBody from "@/modules/TaskManager/components/Task/TaskBody";
import SubtaskList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/SubtaskList";
import MembersBody from "@/modules/TaskManager/components/Task/TaskComponents/MembersBody";
import { mapGetters, mapMutations } from "vuex";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import { EventBus } from "@/main";
import CommentsBodyTask from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CommentsBodyTask";
import HistoryTask from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/HistoryTask/HistoryTask";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";

export default {
  name: "Task",
  mixins: [],
  props: {},
  components: {
    HistoryTask,
    CommentsBodyTask,
    Spinner,
    MembersBody,
    SubtaskList,
    TaskBody
  },
  data() {
    return {
      currentTab: "description",
      windowWidth: 0
    };
  },
  beforeDestroy() {
    EventBus.$off("updateData");
    TaskModule[TaskMutationTypes.SET_CURRENT_TASK](null);
    TaskModule[TaskMutationTypes.GET_CURRENT_TASK_FILES]("reset");
  },
  mounted() {
    TaskModule[TaskMutationTypes.SET_TASK_LOADER](true);
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.resizeWindow);
    EventBus.$on("updateData", () => {
      this.getTask();
    });
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    },
    getTask() {
      TaskModule.getCurrentTaskAction({
        task_id: this.$route.params.id,
        alias: this.$route.query.alias
      }).then((error) => {
        if (error === 404 || error === 422 || error === 403) {
          this.$router.push({ name: "MyDashboard" });
        }
      });
    },
    updateCommentsCountTask() {
      TaskModule.updateCommentsCountTaskAction({
        task_id: this.$route.params.id,
        alias: this.$route.query.alias
      });
    },
    getSubtasksList() {
      TaskModule.getSubtaskListAction(this.setSubtaskParams);
    },
    handleEdit(val) {
      let isChanged = false;
      Object.entries(val).forEach(([key, value]) => {
        if (this.currentTask[key] !== value) {
          isChanged = true;
        }
      });
      if (isChanged) {
        TaskModule.updateCurrentTaskAction({
          id: this.currentTask.id,
          ...val
        });
      }
    },
    closeCurtain() {
      TaskManagerModuleV2.closeCurtainShowAndEditTask();
    },
    changeCurrentTab(val) {
      this.currentTab = val;
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    loader() {
      return TaskModule.taskLoader;
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    subtaskList() {
      return TaskModule.subtasksByTask(this.currentTask.id);
    },
    setSubtaskParams() {
      return {
        id: this.currentTask.id,
        page: TaskModule.subtaskPage,
        per_page: 15
      };
    },
    isSubTask() {
      return this.currentTask.parent_id;
    },
    ownerList() {
      return ProjectModule.ownerList;
    },
    isOwner() {
      return this.ownerList.some((item) => item.user_id === this.getUserId);
    },
    membersList() {
      return ProjectModule.membersList;
    },
    isUsersList() {
      if (this.currentTask.project_id) {
        return this.ownerList
          .map((item) => item.user_info)
          .concat(this.membersList.map((item) => item.user_info))
          .sort((x, y) => {
            if (x.full_name < y.full_name) {
              return -1;
            }
            if (x.full_name > y.full_name) {
              return 1;
            }
            return 0;
          });
      } else {
        return this.userList.users.filter((item) => item.is_active);
      }
    },
    isMember() {
      return this.membersList.some((item) => item.user_id === this.getUserId);
    },
    isAuthor() {
      return this.currentTask.author_id === this.getUserId;
    },
    isExecutor() {
      return this.currentTask.executor_id === this.getUserId;
    },
    isParentEditUser() {
      return (
        this.currentTask.parent_author_id === this.getUserId ||
        this.currentTask.parent_executor_id === this.getUserId
      );
    },
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.currentTask.author_id);
    },
    setExecutor() {
      return this.currentTask.executor_id
        ? this.userList.users.find((item) => item.id === this.currentTask.executor_id)
        : [];
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    showBody() {
      if (this.windowWidth < 1200) {
        return this.currentTask && !this.loader && this.currentTab === "description";
      } else {
        return this.currentTask && !this.loader;
      }
    },
    showSubtaskBody() {
      return (
        this.currentTask && !this.isSubTask && !this.loader && this.currentTab === "description"
      );
    },
    showMembersBody() {
      return this.currentTask && !this.loader && this.currentTab === "description";
    },
    showCommentsBody() {
      return this.currentTab === "comments";
    },
    showHistoryBody() {
      return this.currentTab === "history";
    }
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(route, oldRoute) {
        TaskModule[TaskMutationTypes.SET_TASK_LOADER](true);
        this.getTask();
        this.closeCurtain();
        this.currentTab = "description";
        this.prevRoute = oldRoute;
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.task {
  width: 100%;
  display: flex;

  @media (max-width: $xl) {
    flex-direction: column;
    overflow-y: scroll;
    height: 100vh;
  }

  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0;
  }

  /deep/ {
    .subtask-item__link {
      max-width: 300px;

      @media (max-width: $xl) {
        max-width: 90%;
      }
    }

    .subtasks {
      max-width: 400px;
      width: 400px;

      @media (max-width: 1740px) {
        min-width: 364px;
        width: 364px;
      }

      @media (max-width: $xl) {
        width: 100%;
        min-width: 100%;
      }
    }

    .task-comment,
    .task-history {
      max-width: 700px;
      width: 100%;

      @media (max-width: 1550px) {
        max-width: 550px;
      }

      @media (max-width: $xl) {
        max-width: 100%;
      }

      @media (max-width: $sm) {
        height: calc(100% - 90px);
      }
    }

    .task-comment__header,
    .task-history__header {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 24px;
      border-bottom: 1px solid $system-grey-four;

      @media (max-width: $xxxl) {
        padding: 16px 8px;
      }

      @media (max-width: $xl) {
        display: none;
      }
    }

    .members__header {
      @media (max-width: $xl) {
        border-top: 1px solid $system-grey-four;
      }
    }

    .task-comment__title,
    .task-history__title {
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      color: $text-default;
      white-space: nowrap;
    }

    .comments__list,
    .task-history__list {
      padding-top: 32px;

      @media (max-width: $sm) {
        padding: 24px;
      }
    }

    .comments__form {
      @media (max-width: $sm) {
        min-height: max-content !important;
      }
    }
  }
}
</style>
