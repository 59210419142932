













import { Component, Prop, Vue } from "vue-property-decorator";
import { domainStorage } from "@/globalVariables";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";

@Component({
  components: { ButtonIcon }
})
export default class SelectedItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  item!: any;
  @Prop({
    default: true,
    type: Boolean
  })
  isUser!: boolean;

  handleDelete() {
    this.$emit("handleDelete", this.item.id);
  }

  get src() {
    return domainStorage + this.option.avatar;
  }

  get role() {
    const roles = this.item.roles;
    if (Array.isArray(roles)) {
      return roles.length ? roles[0].name : "";
    } else {
      return "";
    }
  }

  get name() {
    return this.isUser ? this.option.full_name : this.option.name;
  }

  get option() {
    let user = this.item;
    if (this.isUser) {
      const userArr = this.$store.getters["UsersModule/userById"](this.item.id);
      user = userArr[0];
    }
    return user;
  }
}
