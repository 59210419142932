<template>
  <div class="structure-bg">
    <div class="structure-bg__graph" :style="`width: ${widthGraph}px !important;`" id="structureBg">
      <div class="zoom-bg" id="zoom">
        <StructureCard
          v-for="(card, index) in structureList"
          :key="card.id"
          :childs="structureList"
          :index="index"
          :item="card"
          :isAdmin="isAdmin"
          :reRender="reRender"
        />
      </div>
    </div>
    <ModalCreateChangeCard
      v-if="dataModalCreateUpdate"
      :editMode="dataModalCreateUpdate.type"
      :data="dataModalCreateUpdate.data"
      @updateStructure="updateStructure"
    />
    <ModalViewCardMembers
      v-if="currentCardMembers && isShowModalViewCardMembers"
      :isAdmin="isAdmin"
      :currentCardData="currentCardMembers"
      @openModalAddViewCardMembers="openModalAddViewCardMembers(structureList)"
      @hideModalViewCardMembers="currentCardMembers = null"
    />
    <AddMembersCard
      v-if="isAddCardMembers"
      :currentCardMembers="currentCardMembers"
      @hideModalAddViewCardMembers="hideModalAddViewCardMembers"
    />
    <ViewResponsibilitiesCard
      v-if="responsibilitiesCard"
      :data="responsibilitiesCard"
      :isAdminResponsibility="isAdminResponsibility"
    />
    <ModalCreateChangeResponsibilities
      v-if="dataModalResponsibilitiesCreateUpdate"
      :editMode="dataModalResponsibilitiesCreateUpdate.type"
      :data="dataModalResponsibilitiesCreateUpdate.data"
    />
    <ModalViewCard
      v-if="isShowModalViewCard"
      :isAdmin="isAdmin"
      @hideModalViewCard="hideModalViewCard"
      :cardData="cardData"
    />
  </div>
</template>

<script>
import Panzoom from "@panzoom/panzoom";
import axios from "axios";
import { domain } from "@/globalVariables";

import StructureCard from "@/components/Structure/StructureCard";
const ModalCreateChangeCard = () =>
  import("@/components/Structure/ModalStructure/ModalCreateChangeCard");
const ModalViewCardMembers = () =>
  import("@/components/Structure/ModalStructure/ModalViewCardMembers");
import AddMembersCard from "@/components/Structure/ModalStructure/AddMembersCard";
import ViewResponsibilitiesCard from "@/components/Structure/ModalStructure/ViewResponsibilitiesCard";
import ModalViewCard from "@/components/Structure/ModalStructure/ModalViewCard";
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";
const ModalCreateChangeResponsibilities = () =>
  import("@/components/Structure/ModalStructure/ModalCreateChangeResponsibilities");

export default {
  name: "Structure",
  mixins: [],
  props: [],
  components: {
    ModalViewCard,
    ModalCreateChangeResponsibilities,
    ViewResponsibilitiesCard,
    AddMembersCard,
    ModalViewCardMembers,
    ModalCreateChangeCard,
    StructureCard
  },
  data() {
    return {
      structureList: [],
      panzoom: null,
      dataModalCreateUpdate: null,
      reRender: 0,
      isAddCardMembers: false,
      isShowModalViewCardMembers: false,
      currentCardMembers: null,
      responsibilitiesCard: null,
      dataModalResponsibilitiesCreateUpdate: null,
      cardData: null,
      widthGraph: 0,
      resizeObs: null,
      resizeGraph: null,
      lastPosition: 0,
      isShowModalViewCard: false,
      scale: 0.3
    };
  },
  beforeDestroy() {
    this.$root.$off("goReRender");
    this.$root.$off("showCreateChangeCardModal");
    this.$root.$off("showCreateChangeResponsibilitiesModal");
    this.$root.$off("openModalViewCardMembers");
    this.$root.$off("updateStructureChart");
    this.$root.$off("showResponsibilitiesCard");
    this.$root.$off("hideSlide");
    this.$root.$off("showViewCardModal");
    delete this.resizeObs;
    delete this.resizeGraph;
  },
  mounted() {
    let elem = document.getElementById("zoom");
    this.panzoom = Panzoom(elem, {
      maxScale: 1,
      canvas: true
    });
    this.panzoom.zoom(this.scale, { animate: true });
    this.setZoomArea();
    this.$root.$on("goReRender", () => {
      this.reRender++;
    });
    this.$root.$on("showCreateChangeCardModal", (item) => {
      this.dataModalCreateUpdate = item;
    });
    this.$root.$on("showCreateChangeResponsibilitiesModal", (item) => {
      this.dataModalResponsibilitiesCreateUpdate = item;
    });
    this.$root.$on("openModalViewCardMembers", (item) => {
      this.currentCardMembers = item;
      this.isShowModalViewCardMembers = true;
    });
    this.$root.$on("showViewCardModal", (item) => {
      this.cardData = item;
      this.isShowModalViewCard = true;
    });
    this.$root.$on("updateStructureChart", () => {
      this.updateStructure();
    });
    this.$root.$on("showResponsibilitiesCard", (item) => {
      this.responsibilitiesCard = item;
    });
    // this.$root.$on("hideSlide", () => {
    //   this.getWidthGraph();
    // });
    // this.$nextTick(() => {
    //   this.getWidthGraph();
    // });
    // @TODO: Подумать над другим решением
    setTimeout(() => {
      this.resizeObs = new ResizeObserver(this.getWidthGraph).observe(
        document.querySelector(".structure-bg")
      );
    }, 100);
  },
  methods: {
    getWidthGraph() {
      if (this.setGlobalMobileMode) {
        this.widthGraph = document.querySelector(".structure-bg").clientWidth - 48;
      } else {
        this.widthGraph =
          document.querySelector(".structure-bg").clientWidth -
          48 -
          (document.querySelector(".app-sidebar").clientWidth / 2 - 40);
      }
    },
    getHeightGraph() {
      let elem = document.getElementById("zoom");
      this.lastPosition = elem.getBoundingClientRect().height;
      elem.style.height = this.lastPosition;
    },
    setZoomArea() {
      let elem = document.getElementById("zoom");
      this.resizeGraph = new ResizeObserver(this.getHeightGraph).observe(elem);

      document.getElementById("structureBg").addEventListener("wheel", (event) => {
        this.panzoom.zoomWithWheel(event);
      });

      this.getStructureInfo();
    },
    updateStructure() {
      this.setZoomArea();
    },
    getStructureInfo() {
      httpClient({
        url: `/OrgStructureItem/actionGetTree`,
        method: "GET",
        params: {
          _count: 300,
          parent_item_id: "null",
          _with: JSON.stringify([
            "responsibilities.users",
            "responsibilities.regulation",
            "responsibilities.assistant_user",
            "responsibilities.responsible_user",
            "users",
            "nested_children_items",
            "responsible_user",
            "assistant_user"
          ])
        }
      })
        .then((response) => {
          this.structureList = [response.data.data];
          this.$nextTick(() => {
            this.$root.$emit("goReRender");
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    setZoomIn() {
      this.panzoom.zoomIn();
    },
    setZoomOut() {
      this.panzoom.zoomOut();
    },
    openModalAddViewCardMembers() {
      this.isAddCardMembers = true;
      this.isShowModalViewCardMembers = false;
    },
    hideModalAddViewCardMembers() {
      this.isAddCardMembers = false;
      this.isShowModalViewCardMembers = true;
    },
    hideModalViewCard() {
      this.isShowModalViewCard = false;
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    isAdmin() {
      return this.getEndpoints("OrgStructureItem", "actionUpdate");
    },
    isAdminResponsibility() {
      return this.getEndpoints("Responsibility", "actionUpdate");
    },
    setGlobalMobileMode() {
      return this.$root.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.structure-bg {
  width: 100%;
  height: 92%;
  padding: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
  padding-top: 40px;
  background: #fcfcfe;
  border-top: 1px solid $border-disabled;
  border-radius: 0 0 24px 24px;
  box-sizing: border-box;

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-text-accent;
  }

  &__graph {
    height: calc(90vh - 60px) !important;
    margin: 0 auto;
  }
}
</style>
