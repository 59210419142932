<template>
  <section class="isp-view" :class="{ 'isp-view__problem': isProblem }">
    <div class="isp-view__header">
      <TabsList :tabs="tabsListRouter" v-if="!isProblem" />
      <ProblemNavigate v-else />
      <i class="icon-search" @click.stop="curtainSearch"></i>
      <Button
        class="isp-view__button"
        :title="'Вынести проблему'"
        @handleButton="curtainCreateProblem"
      />
      <Button
        class="isp-view__button_circle"
        icon="plus"
        :title="''"
        @handleButton="curtainCreateProblem"
      />
    </div>
    <router-view></router-view>
    <transition name="slide">
      <IspReviewAddAndCreateQuarter v-if="showCreateAndEditQuarter" />
      <InfoProblem v-if="showCurrentInfoProblem && !isProblem" />
      <CurtainCreateTeam v-if="curtainCreateTeam" />
      <IspTeamInfo v-if="showIspTeamInfo" />
      <CurtainSearch v-if="showCurtainSearch" />
      <CurtainCreateProblem v-if="showCurtainCreateProblem" />
      <IspCreateTaskModal v-if="showIspCreateTaskModal" />
    </transition>
    <IspMoveProblemModal v-if="showMoveProblem" />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import InfoProblem from "@/modules/Isp/components/InfoProblem/InfoProblem";
import TabsList from "@/modules/UiKit/components/Tabs/TabsList";
import IspReviewAddAndCreateQuarter from "@/modules/Isp/components/IspReview/IspReviewModal/IspReviewAddAndCreateQuarter";
import IspCreateTaskModal from "@/modules/Isp/components/InfoProblem/BodyComponents/TasksBody/TaskModals/IspCreateTaskModal";
import CurtainCreateTeam from "@/modules/Isp/components/IspTeams/CurtainCreateTeam";
import IspTeamInfo from "@/modules/Isp/components/IspTeams/IspTeamInfo/IspTeamInfo";
import CurtainSearch from "@/modules/Isp/components/Search/CurtainSearch";
import CurtainCreateProblem from "@/modules/Isp/components/IspProblem/CurtainCreateProblem";
import ProblemNavigate from "@/modules/Isp/components/IspProblem/IspProblemPage/ProblemNavigate";
import Button from "@/modules/UiKit/components/buttons/Button";

import { domain } from "@/globalVariables";
import IspMoveProblemModal from "@/modules/Isp/components/IspProblem/IspMoveProblemModal";
import httpClient from "@/api/client/httpClient";
export default {
  name: "IspView",
  mixins: [],
  props: {},
  components: {
    IspMoveProblemModal,
    ProblemNavigate,
    InfoProblem,
    TabsList,
    IspReviewAddAndCreateQuarter,
    CurtainCreateTeam,
    IspTeamInfo,
    CurtainSearch,
    CurtainCreateProblem,
    Button,
    IspCreateTaskModal
  },
  data() {
    return {
      test: false,
      tabsListRouter: [
        {
          label: "Обзор",
          path: "IspReview",
          index: 0
        },
        {
          label: "Моe ISP",
          path: "IspMy",
          index: 1
        },
        {
          label: "Команды",
          path: "IspTeams",
          index: 2
        }
      ]
    };
  },
  created() {
    this.getStatuses();
    this.getCriticality();
    this.getUsers();
    this.getQuarterList();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions("IspModule", [
      "changeInfoModalMode",
      "getUsers",
      "getQuarterList",
      "getStatuses",
      "getCriticality"
    ]),
    ...mapMutations("IspModule", [
      "TOGGLE_SHOW_CURTAIN_SEARCH",
      "TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM",
      "SHOW_CONFIRM_MODALS"
    ]),
    curtainSearch() {
      this.TOGGLE_SHOW_CURTAIN_SEARCH(true);
    },
    curtainCreateProblem() {
      this.TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM(true);
    },
    hideConfirmModal() {
      this.SHOW_CONFIRM_MODALS(false);
    },
    getItemSelect() {
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          is_active: JSON.stringify([1]),
          _count: 500
        }
      }).then((res) => {
        this.itemsSelect = res.data.data.items;
      });
    }
  },
  computed: {
    ...mapState("IspModule", [
      "infoModalMode",
      "curtainCreateTeam",
      "showIspTeamInfo",
      "showCurtainSearch",
      "showMoveProblem",
      "showCurtainCreateProblem",
      "showCurrentInfoProblem",
      "currentInfoProblem",
      "showCreateAndEditQuarter",
      "showConfirmModal",
      "showIspCreateTaskModal"
    ]),
    ...mapGetters("IspModule", []),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isProblem() {
      return this.$route.name === "IspProblem";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
.isp-view {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: $surface-default;
  overflow: hidden;
  @media (max-width: $md) {
    padding: 10px 10px 70px 10px;
  }
  /deep/ *:not(i) {
    font-family: "Roboto", sans-serif !important;
  }

  &__problem {
    padding: 0;
    overflow-y: auto;
    .isp-view__header {
      padding: 24px 24px 0;
      max-height: 100vh;
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    margin: 0 0 24px;
    @media (max-width: $xs) {
      margin: 0 0 10px;
    }

    i {
      margin-left: auto;
      color: #a7a9c0;
      font-size: 20px;
      cursor: pointer;
      margin-right: 24px;
      &:hover {
        color: $icon-highlight;
      }
    }
  }
  &__button {
    @media (max-width: $md) {
      display: none;
    }
    &_circle {
      display: none;
      @media (max-width: $md) {
        display: block;
        position: absolute;
        bottom: 80px;
        right: 40px;
        height: 48px;
        width: 48px;
        justify-content: center;
        z-index: 8;
        padding: 0;
        margin: 0;
      }
    }
  }
  /deep/.aop-button {
    i {
      margin: 0;
    }
  }
  .tabs {
    margin: 0;
    /deep/ {
      .tabs {
        &__item {
          @media (max-width: $sm) {
            margin-right: 10px;
          }
          @media (max-width: $xs) {
            margin: 0 25px 0 0;
          }
          .tabs__link {
            @media (max-width: $xs) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
</style>
