<template>
  <div
    class="task-manager__container"
    v-if="showSidebar"
    id="taskSidebar"
    @mousedown.self="closeSidebar"
  >
    <div class="task-manager__sidebar">
      <div class="task-manager__header">
        <button class="task-manager__back" type="button" @click="closeSidebar">
          <i class="icon-arrow-back"></i>
        </button>
        <i class="icon-search"></i>
        <input
          class="search-module"
          type="text"
          placeholder="Поиск"
          @input="search"
          v-model.trim="searchValue"
          maxlength="255"
        />
        <i class="icon-cross" @click="clearSearchValue" v-if="searchValue.length"></i>
      </div>
      <div class="task-manager__content main-content" v-if="isSearchInputEmpty">
        <ul class="main-content__link-list">
          <li @click="closeSidebar" class="main-content__link">
            <router-link :to="{ name: 'MyDashboard' }">
              <i class="icon-list-check" />Мой Task Manager
            </router-link>
          </li>
          <li @click="closeSidebar" class="main-content__link">
            <router-link :to="{ name: 'MyProjects' }">
              <i class="icon-folder" />Мои проекты
            </router-link>
          </li>
          <li @click="closeSidebar" class="main-content__link">
            <router-link :to="{ name: 'MyTasks' }">
              <i class="icon-add-task" />Мои задачи
            </router-link>
          </li>
          <li @click="closeSidebar" class="main-content__link">
            <router-link :to="{ name: 'WorkPlans' }">
              <i class="icon-work-plans" />Графики планирования
            </router-link>
          </li>
        </ul>
        <div class="main-content__projects projects-content">
          <div class="projects-content__header">
            <p>Активные проекты</p>
          </div>
          <div class="projects-content__content">
            <ProjectList />
          </div>
        </div>
      </div>
      <div class="task-manager__content search" v-else>
        <SearchColumn />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectList from "@/modules/TaskManager/components/ProjectList/ProjectList";
import { mapState, mapMutations } from "vuex";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import SearchColumn from "@/modules/TaskManager/components/SearchComponents/SearchColumn";

export default {
  name: "TaskManagerSidebar",
  mixins: [],
  props: {},
  components: { SearchColumn, ProjectList },
  data() {
    return {
      timeoutId: null,
      searchValue: ""
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    resizeHandler() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1400) {
        this.TOGGLE_SHOW_SIDEBAR(false);
      } else {
        this.TOGGLE_SHOW_SIDEBAR(true);
      }
    },
    closeSidebar() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1400) {
        this.TOGGLE_SHOW_SIDEBAR(false);
      }
    },
    search() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if (!this.isSearchInputEmpty) TaskManagerModuleV2.searchAction({ query: this.searchValue });
      }, 300);
    },
    clearSearchValue() {
      this.searchValue = "";
    }
  },
  computed: {
    ...mapState("TaskManagerModule", ["showSidebar"]),
    isSearchInputEmpty() {
      return !this.searchValue.length;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.task-manager {
  &__container {
    @media (max-width: $xxl) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      z-index: 10000000;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 355px;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid $secondary;
    background: $surface-default;

    @media (max-width: 1740px) {
      width: 270px;
    }

    @media (max-width: $xxl) {
      width: 540px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    height: 70px;
    flex-shrink: 0;
    padding: 0 24px;

    i {
      font-size: 24px;
      color: #a7a9c0;
      margin-right: 10px;

      @media (max-width: $sm) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .search-module {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343748;
      border: none;
      outline: none;
      width: 100%;

      &::placeholder {
        color: #343748;
      }
    }
    .icon-cross {
      cursor: pointer;
      font-size: 20px;
      transition: 0.3s all ease;
      &:hover {
        color: $link-default;
      }
    }
  }

  &__back {
    display: none;

    @media (max-width: $sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 8px 0 0;
      cursor: pointer;
      outline: none;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);

    @media (max-height: $sm) {
      overflow-y: scroll;
    }

    .main-content {
      &__link-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 8px 24px 24px;
        margin: 0;

        @media (max-width: $sm) {
          padding: 16px;
        }
      }

      &__link {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          a {
            color: #5551f9;
          }
          i {
            color: #5551f9;
          }
        }
        a {
          display: flex;
          align-items: center;
          min-width: 100%;
          min-height: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #343748;
          transition: 0.1s;
        }
        i {
          font-size: 24px;
          color: #a7a9c0;
          margin-right: 10px;
          transition: 0.1s;
        }
        .router-link-active {
          i {
            color: #5551f9;
          }
          filter: drop-shadow(0px 8px 10px rgba(122, 124, 255, 0.14))
            drop-shadow(0px 3px 14px rgba(122, 124, 255, 0.12))
            drop-shadow(0px 4px 5px rgba(122, 124, 255, 0.2));
        }
      }

      &__projects {
        height: calc(100% - 210px);
        .projects-content {
          height: 100%;

          &__header {
            border-top: 1px solid $secondary;
            border-bottom: 1px solid $secondary;
            padding: 16px 24px;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #343748;
            }
          }
          &__content {
            height: calc(100% - 70px);
            overflow: auto;

            @media (max-height: $sm) {
              min-height: 500px;
            }
          }
        }
      }
    }
  }
  .search {
    height: calc(100% - 70px);
  }
}
</style>
