<template>
  <div class="modal-menu" @click.self="closeMenuChannels">
    <div class="modal-menu__window">
      <div class="modal-menu__header">
        <div class="modal-menu__title">
          <i class="icon-arrow-back" @click="closeMenuChannels"></i>
          <h2 class="modal-menu__title-name">Дни рождения</h2>
          <img :src="require('@/modules/News/assets/img/NewsImage/pieceOfCake.svg')" alt="" />
        </div>
      </div>
      <div class="modal-menu__body">
        <div class="birthday-widget__loader" v-if="loading">
          <Spinner />
        </div>
        <div class="birthday-widget__body" v-else>
          <NewsBirthdayItem v-for="item in birthdayItems" :key="item.id" :birthdayItem="item" />
          <p v-if="!birthdayItems.length && !loading" class="birthday-widget__empty">Пусто :(</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import NewsBirthdayItem from "@/modules/News/components/BirthdayComponents/NewsBirthdayItem";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "NewsBirthdayModal",
  mixins: [],
  props: {
    birthdayItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean
    }
  },
  components: { Spinner, NewsBirthdayItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["getNewsItemInfoAction", "changeModalAddChannel"]),
    ...mapMutations("NewsModule", [types.SHOW_NEWS_BIRTHDAY_MODAL, types.SHOW_CHANNEL_MODAL_MENU]),
    closeMenuChannels() {
      this.SHOW_NEWS_BIRTHDAY_MODAL(false);
      this.SHOW_CHANNEL_MODAL_MENU(true);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal-menu {
  display: flex;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  @media screen and (max-width: $sm) and (orientation: landscape) {
    top: 50px;
  }
  &__window {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    width: 350px;
    border-right: 1px solid #eae8f0;
    max-height: 100%;
    background: white;
    overflow-y: auto;
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding-bottom: 35px;
    }
    @media (max-width: $xs) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    border-bottom: 1px solid #eae8f0;
    padding: 20px;
    justify-content: space-between;

    @media (max-width: $xs) {
      padding: 20px;
    }
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding: 10px 20px;
      height: 40px;
    }
    /deep/ {
      .chosen-filter {
        &__name {
          font-size: 14px;
        }
      }
    }
    i {
      color: $text-disabled;
      margin-right: 8px;
      font-size: 20px;
      cursor: pointer;
      transition: 0.3s color ease;
      &:hover {
        color: $icon-highlight;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__title-name {
    margin: 0 8px 0 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    @media (max-width: $xxxl) {
      font-size: 16px;
    }
    @media (max-width: $md) {
      font-size: 18px;
    }
  }
  &__body {
    padding: 26px;
    overflow: auto;
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding-bottom: 50px;
    }
    /deep/ {
      .user__info {
        max-width: 230px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    p {
      font-size: 14px;
      line-height: 20px;
      color: #343748;
    }

    i {
      font-size: 14px;
      margin-right: 8px;
      color: #a7a9c0;
    }
  }
}

/deep/.user__avatar {
  @media (max-width: $xl) {
    display: block !important;
  }
}
</style>
