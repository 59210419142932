const setListPosition = (container, list, x, y) => {
  const rectC = container.getBoundingClientRect();
  const bottomC = rectC.bottom;
  const rightC = rectC.right;
  const listWidth = list.offsetWidth;
  const listHeight = list.offsetHeight;
  let position = {};
  if (listWidth + x > rightC) {
    list.style.right = window.innerWidth - x + "px";
    position.right = true;
  } else {
    list.style.left = x + "px";
    position.left = true;
  }
  if (listHeight + y > bottomC) {
    list.style.bottom = window.innerHeight - y + "px";
    position.bottom = true;
  } else {
    list.style.top = y + "px";
    position.top = true;
  }
  const [posX, posY] = Object.keys(position);
  list.style.transformOrigin = `${posX} ${posY}`;
  list.classList.add("open");
};

export default setListPosition;
