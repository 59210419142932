<template>
  <div class="base-plan" v-click-outside="hideModal">
    <p @click="toggleModal">
      Показать Базовый план<Tooltip
        :text="'Сохранение данных с целью фиксации и последующим отслеживанием отклонений по срокам. '"
      />
    </p>
    <ModalChooseBasePlan
      v-if="showModal"
      :planId="planId"
      :basePlanList="basePlanList"
      :basePlanToShow="basePlanToShow"
      :isAuthor="isAuthor"
      @handleBasePlan="handleBasePlan"
    />
  </div>
</template>

<script>
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";
import ModalChooseBasePlan from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/BasePlan/ModalChooseBasePlan/ModalChooseBasePlan";

export default {
  name: "BasePlan",
  mixins: [],
  props: {
    planId: {
      type: Number,
      default: null
    },
    basePlanList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    basePlanToShow: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isAuthor: {
      type: Boolean,
      default: true
    }
  },
  components: { ModalChooseBasePlan, Tooltip },
  data() {
    return {
      showModal: false
    };
  },
  mounted() {},
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    hideModal() {
      this.showModal = false;
    },
    handleBasePlan() {
      this.$emit("handleBasePlan");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.base-plan {
  display: flex;
  position: relative;
  p {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $link-default;
    cursor: pointer;
  }
}
</style>
