import { Vue, Component } from "vue-property-decorator";

@Component
export default class draggableModals extends Vue {
  cordX: number = 0;
  cordY: number = 0;
  cordX2: number = 0;
  cordY2: number = 0;
  tempTop: number = 0;
  tempLeft: number = 0;
  modal: any | null = null;
  drag: any | null = null;

  dragHandler() {
    this.drag.onmousedown = this.dragMouseDown;
  }

  dragMouseDown(event) {
    event = event || window.event;
    event.preventDefault();
    this.cordX2 = event.clientX;
    this.cordY2 = event.clientY;
    document.onmouseup = this.closeDragElement;
    document.onmousemove = this.elementDrag;
  }

  elementDrag(event) {
    event = event || window.event;
    event.preventDefault();
    this.cordX = this.cordX2 - event.clientX;
    this.cordY = this.cordY2 - event.clientY;
    this.cordX2 = event.clientX;
    this.cordY2 = event.clientY;
    this.$nextTick(() => {
      this.modal.style.bottom = "auto";
      this.modal.style.right = "auto";
    });
    this.modal.style.top = this.modal.offsetTop - this.cordY + "px";
    this.tempTop = window.innerHeight;
    this.modal.style.left = this.modal.offsetLeft - this.cordX + "px";
    this.tempLeft = window.innerWidth;
    if (parseInt(this.modal.style.top, 10) + this.modal.offsetHeight > this.tempTop) {
      this.modal.style.top = this.tempTop - this.modal.offsetHeight + "px";
    }
    if (parseInt(this.modal.style.top, 10) <= 0) {
      this.modal.style.top = 0 + "px";
    }
    if (parseInt(this.modal.style.left, 10) + this.modal.offsetWidth > this.tempLeft) {
      this.modal.style.left = this.tempLeft - this.modal.offsetWidth + "px";
    }
    if (parseInt(this.modal.style.left, 10) <= 0) {
      this.modal.style.left = 0 + "px";
    }
  }

  closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
