import * as types from "@/modules/Messenger/store/mutations/mutationTypes";

import { domain } from "@/globalVariables";
import router from "@/router";
import audio from "@/modules/Messenger/assets/audio/message.mp3";
import store from "@/store";

import Message from "@/api/Message";
import UserSettings from "@/api/UserSetting";
import MessageReactions from "@/api/MessageReactions";
import PersonalChat from "@/api/PersonalChat";
import httpClient from "@/api/client/httpClient";

const PersonalChatModel = new PersonalChat("monolit");
const MessageModel = new Message("monolit");
const UserSettingsModel = new UserSettings("monolit");
const MessageReactionsModel = new MessageReactions("monolit");

const scroll = (id) => {
  const $message = document.querySelector(`#message_${id}`);
  const $container = document.querySelector(`#list-dynamic`);
  $container.scrollTo(0, $message.offsetTop - 100);
  const $parentMessage = $message.parentNode;
  $parentMessage.classList.add("active");
  let timerId = setTimeout(() => {
    $parentMessage.classList.remove("active");
  }, 3000);
};

const actions = {
  getChats({ commit, dispatch, getters }, payload) {
    httpClient({
      method: "GET",
      url: `/Chat/getAllChatsByUserId`
    })
      .then((response) => {
        commit(types.SET_CHATS, response.data.data.data);
        if (payload) {
          dispatch("openCurrentChat", {
            chat: getters.getChatFromQuery(payload).channel,
            isSendMessage: false
          });
        }
      })
      .catch((err) => {
        new Error(err);
      });
  },
  searchUser({ commit, dispatch }, payload) {
    if (payload.length) {
      const text = payload.replace("@", "").replace("#", "");
      dispatch("getPersonalChat", { text: text }).then((resp) => {
        commit(types.SEARCH_RESULT_PERSON, {
          data: resp.data.data.data,
          show: true,
          text: payload
        });
        dispatch("findChannel", {
          where: JSON.stringify([{ type: 2, chatSettings: { is_open: false } }]),
          search: { "chatSettings.name": text, "chatSettings.tag": text }
        }).then((response) => {
          commit(types.SEARCH_RESULT_CLOSE, response.data.data.data);
          dispatch("findChannel", {
            where: JSON.stringify([{ type: 2, chatSettings: { is_open: true } }]),
            search: { "chatSettings.name": text, "chatSettings.tag": text }
          }).then((chat) => {
            commit(types.SEARCH_RESULT_OPEN, chat.data.data.data);
          });
        });
      });
    } else {
      commit(types.SEARCH_RESULT_PERSON, {
        data: { chats: [], users: [], show: false, text: payload }
      });
      commit(types.SEARCH_RESULT_CLOSE, { items: [] });
      commit(types.SEARCH_RESULT_OPEN, { items: [] });
    }
  },
  searchMessage({ commit }, payload) {
    if (payload.text.length || payload.from_date.length) {
      httpClient({
        url: `/Message/search`,
        method: "GET",
        params: {
          channel_id: payload.channel_id,
          _search_by_nickname_or_full_name: payload.text,
          _search: JSON.stringify({ text: payload.text }),
          from_date: payload.from_date.length ? payload.from_date : undefined
        }
      })
        .then((response) => {
          commit(types.SET_SEARCH_RESULT, {
            data: response.data.data.data.items,
            text: payload.text
          });
        })
        .catch((error) => {
          new Error(error);
        });
    } else {
      commit(types.SET_SEARCH_RESULT, {
        data: [],
        text: payload.text
      });
      commit(types.SEARCH_USER_RESULT, { users: [], text: payload.text });
    }
  },
  setSearchMessageMode({ commit }, payload) {
    commit(types.SEARCH_MESSAGE_MODE, payload);
  },
  setNewChat({ commit, state, dispatch }, payload) {
    commit(types.SWITCH_PIN_MESSAGES, false);
    if (payload.type === 1) {
      if (payload.id) {
        const chat = state.chatList.find((item) => item.channel.id === payload.id);

        dispatch("openCurrentChat", { chat: chat.channel, isSendMessage: false });
        commit(types.OPEN_CHAT_IN_SEARCH, payload.id);
      } else {
        const chat = state.chatList.find((item) => {
          return item.channel.companion?.id === payload.companion.id;
        });
        if (chat) {
          dispatch("openCurrentChat", { chat: chat.channel, isSendMessage: false });
          commit(types.OPEN_CHAT_IN_SEARCH, payload.id);
        } else {
          commit(types.SET_NEW_CHAT, payload.companion);
          commit(types.OPEN_CHAT, true);
          commit(types.OPEN_CHAT_IN_SEARCH, payload.id);
        }
      }
    } else {
      const isMyChat = payload.users.some((item) => {
        return item.user_id === store.getters.getUserId;
      });
      if (isMyChat) {
        const chat = state.chatList.find((item) => item.channel.id === payload.id);
        dispatch("openCurrentChat", { chat: chat.channel, isSendMessage: false });
        commit(types.OPEN_CHAT_IN_SEARCH, payload.id);
      } else {
        commit(types.OPEN_GROUP_CHAT, payload);
        commit(types.OPEN_CHAT, true);
        commit(types.OPEN_CHAT_IN_SEARCH, null);
      }
    }
  },
  openChatFromQuery({ commit }, payload) {
    commit(types.OPEN_CHAT, payload);
  },
  getChat({ commit }, payload) {
    return httpClient({
      method: "GET",
      url: `/PersonalChat/actionGetItem`,
      params: {
        id: payload.chat.id
      }
    });
  },
  openChatFromCatalog({ commit, state, dispatch }, payload) {
    commit(types.SWITCH_PIN_MESSAGES, false);
    const isMyChat = payload.my_settings.length;
    if (isMyChat) {
      const chat = state.chatList.find((item) => item.channel.id === payload.id);
      dispatch("openCurrentChat", { chat: chat.channel, isSendMessage: false });
      commit(types.OPEN_CHAT_IN_SEARCH, payload.id);
    } else {
      state.showReactionList = false;
      state.showMenu = false;
      commit(types.OPEN_GROUP_CHAT, payload);
      commit(types.OPEN_CHAT, true);
      commit(types.OPEN_CHAT_IN_SEARCH, null);
    }
    commit(types.CHANGE_SHOW_CATALOG, false);
    commit(types.SWITCH_SETTINGS_MENU, false);
  },
  openCurrentChat({ dispatch, state, commit }, payload) {
    commit(types.SWITCH_PIN_MESSAGES, false);
    if (payload.sendInsideChat) {
      if (state.showSendModal) {
        commit(types.SET_SEND_MODE);
      }
      dispatch("readMessageWithScroll", {
        user_id: state.currentChat.channel.chat_user_id,
        count_unread_messages: state.currentChat.channel.count_unread_messages,
        last_message_id: state.currentChat.channel.last_message_id
      });
    } else {
      dispatch("getChat", payload)
        .then((response) => {
          const callback = (res) => {
            commit(types.SET_CURRENT_CHAT, {
              chat: response.data.data.data[0],
              messages: res.data.data.data.messages,
              users: response.data.data.data[0].users
            });
            if (payload.isThread) {
              const message = res.data.data.data.messages.find(
                (item) => item.has_thread_id === payload.message.thread_id
              );
              if (message) {
                setTimeout(() => {
                  dispatch("threadOpen", message);
                }, 350);
              }
            }
            if (state.showSendModal) {
              commit(types.SET_SEND_MODE);
            } else {
              dispatch("closePickMessage");
            }
            dispatch("readMessageWithScroll", {
              user_id: state.currentChat.channel.chat_user_id,
              count_unread_messages: state.currentChat.channel.count_unread_messages,
              last_message_id: state.currentChat.channel.last_message_id
            });
          };
          commit(types.SWITCH_PIN, false);
          commit(types.SWITCH_COMMENT, false);
          MessageModel.actionGetItems(callback, {
            channel_id: response.data.data.data[0].id,
            id: response.data.data.data[0].last_message_id
          });
          commit(types.SWITCH_PIN, true);
          commit(types.SWITCH_COMMENT, true);
        })
        .catch((err) => {
          new Error(err);
        });
    }
  },

  messageGetItems({ commit, dispatch, state }, payload) {
    return httpClient({
      method: "GET",
      url: `/Message/actionGetItems`,
      params: {
        channel_id: payload.id,
        message_direction: payload.direction,
        id: payload.last_message_id,
        "_filters[is_pinned]": payload.is_pinned
      }
    });
  },
  setMessageFromSearch({ commit, dispatch, state }, payload) {
    commit(types.SET_MESSAGE_FROM_SEARCH, payload);
  },
  getMessage({ commit, dispatch, state }, payload) {
    const callback = (response) => {
      if (payload.push) {
        commit(types.UPDATE_MESSAGE_LIST, {
          messages: response.data.data.data.messages,
          direction: payload.direction
        });
      } else {
        commit(types.SET_CURRENT_CHAT, {
          chat: payload,
          messages: response.data.data.data.messages,
          users: payload.users
        });
        if (state.showSendModal) {
          commit(types.SET_SEND_MODE);
        } else {
          dispatch("closePickMessage");
        }
        dispatch("readMessageWithScroll", {
          user_id: state.currentChat.channel.chat_user_id,
          count_unread_messages: state.currentChat.channel.count_unread_messages,
          last_message_id: state.currentChat.channel.last_message_id
        });
      }
    };
    MessageModel.actionGetItems(callback, {
      channel_id: payload.id,
      message_direction: payload.direction,
      id: payload.last_message_id,
      "_filters[thread_id]": state.threadMode ? state.threadMainMessage.has_thread_id : undefined,
      "_filters[is_pinned]": payload.is_pinned
    });
  },
  updateChat({ commit, getters, dispatch, state }, payload) {
    const chat = getters.getChatFromQuery(payload.chat.id);
    let notification = chat.channel.notification_enabled;
    let is_pinned_chat = chat.channel.is_pinned_chat;
    if (payload.notification_enabled !== undefined) {
      notification = payload.notification_enabled;
    }
    if (payload.is_pinned_chat !== undefined) {
      is_pinned_chat = payload.is_pinned_chat;
    }
    httpClient({
      method: "GET",
      url: `/ChatUsers/actionUpdate`,
      params: {
        update_type: "user",
        id: state.channelItem ? state.channelItem.channel.chat_user_id : chat.channel.chat_user_id,
        last_read_message_id: chat.channel.last_read_message_id,
        notification_enabled: notification ? 1 : 0,
        is_pinned_chat: is_pinned_chat ? 1 : 0
      }
    })
      .then((response) => {
        commit(types.UPDATE_CHAT_SETTING, response.data.data.data);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  readChat({ commit }, payload) {
    return httpClient({
      method: "GET",
      url: `/ChatUsers/actionUpdate`,
      params: {
        update_type: "user",
        id: payload.user_id,
        last_read_message_id: payload.last_read_message_id,
        notification_enabled: payload.notification_enabled ? 1 : 0,
        is_pinned_chat: payload.is_pinned_chat ? 1 : 0
      }
    });
  },
  closeCurrentChat({ commit }, payload) {
    commit(types.CLOSE_CURRENT_CHAT, payload);
  },
  setEditorValue({ commit }, payload) {
    commit(types.SET_EDITOR_VALUE, payload);
  },
  setPasteFile({ commit }, payload = []) {
    let link = "";
    payload.forEach((file = {}) => {
      if (Object.prototype.hasOwnProperty.call(file, ["link"])) {
        link = file.link;
        commit(types.SET_PASTE_FILE, { link, ...file, id: file.name + Date.now() });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          link = e.target.result;
          commit(types.SET_PASTE_FILE, { link, ...file, id: file.name + Date.now() });
        };
        reader.readAsDataURL(file.file);
      }
    });
  },
  closeFileModal({ commit }, payload) {
    commit(types.CLOSE_FILE_MODAL, payload);
  },
  deleteCurrentFile({ commit }, payload) {
    commit(types.DELETE_CURRENT_FILE, payload);
  },
  replaceFile({ commit }, payload) {
    let link = "";
    let obj = {};
    if (Object.prototype.hasOwnProperty.call(payload.currentFile, ["link"])) {
      link = payload.currentFile.link;
      obj = { link, ...payload.currentFile, id: payload.currentFile.name + Date.now() };
      commit(types.REPLACE_CURRENT_FILE, { obj, replaceFileId: payload.replaceFileId });
    } else {
      let reader = new FileReader();
      reader.onload = (e) => {
        link = e.target.result;
        obj = { link, ...payload.currentFile, id: payload.currentFile.name + Date.now() };
        commit(types.REPLACE_CURRENT_FILE, { obj, replaceFileId: payload.replaceFileId });
      };
      reader.readAsDataURL(payload.currentFile.file);
    }
  },
  sendMessage({ commit, state }, payload) {
    const replyCallback = () => {
      commit(types.RESET_EDIT);
    };
    const editCallback = () => {
      commit(types.CLOSE_EDIT_MESSAGE);
    };
    if (state.replyMode) {
      MessageModel.actionCreate(replyCallback, [
        {
          channel_id: payload.id,
          text: payload.text,
          links: payload.links.length ? payload.links : undefined,
          reply_message_id: state.currentMessage.id,
          message_id: state.threadMainMessage ? state.threadMainMessage.id : undefined
        }
      ]);
    } else {
      if (state.editMode) {
        const filesId = state.currentMessage.files_in_message.length
          ? state.currentMessage.files_in_message.map((item) => item.id)
          : undefined;
        MessageModel.actionUpdate(editCallback, [
          {
            id: state.currentMessage.id,
            links: payload.links.length ? payload.links : undefined,
            text: payload.text,
            id_files: filesId
          }
        ]);
      } else {
        MessageModel.actionCreate(() => {}, [
          {
            channel_id: payload.id,
            text: payload.text,
            links: payload.links && payload.links.length ? payload.links : undefined,
            id_files: payload.id_files ? payload.id_files : undefined,
            message_id: state.threadMainMessage ? state.threadMainMessage.id : undefined
          }
        ]);
      }
    }
  },
  sendFirstMessage({ commit, state, dispatch }, payload) {
    httpClient({
      method: "POST",
      url: `/Chat/actionCreate`,
      data: [
        {
          type: 1,
          user_id: state.users[0].id
        }
      ]
    }).then((response) => {
      const chat = response.data.data.data;
      commit(types.SET_CURRENT_CHAT, {
        chat: chat[0],
        messages: state.messages,
        users: chat[0].users
      });
      dispatch("sendMessage", {
        chat: chat[0],
        id: chat[0].id,
        text: payload.text,
        links: payload.links,
        id_files: payload.id_files
      });
    });
  },
  switchModal({ commit }, payload) {
    commit(types.SWITCH_MODAL, payload);
  },
  deleteMessage({ commit }, payload) {
    return httpClient({
      method: "POST",
      url: `/Message/actionDelete`,
      data: [
        {
          ids: payload.ids,
          channel_id: payload.chat_id
        }
      ]
    });
  },
  deleteMessages({ commit, state, dispatch }, isAdmin) {
    let ids = state.pickedMessage;
    if (isAdmin) {
      ids = ids.map((item) => item.id);
    } else {
      ids = ids.filter((item) => item.user_id === store.getters.getUserId).map((item) => item.id);
    }
    dispatch("deleteMessage", { ids, chat_id: state.currentChat.channel.id });
    commit(types.CLOSE_PICKED_MESSAGE);
  },
  addMessageReaction({ commit, state, dispatch }, payload) {
    commit(types.SET_CURRENT_MESSAGE, payload);
    // const data = {
    //   component: "ReactionContent",
    //   name: "Реакции",
    //   val: "reaction"
    // };
    // dispatch("setAttachedModalValue", data);
    commit(types.SWITCH_REACTION_MODE, true);
  },
  closeReaction({ commit }) {
    commit(types.SWITCH_REACTION_MODE, false);
  },
  replyCurrentMessage({ commit, state, dispatch }, payload) {
    commit(types.SET_CURRENT_MESSAGE, payload);
    commit(types.REPLY_EDIT_MESSAGE, state.currentMessage);
  },
  resendCurrentMessage({ commit, state, dispatch }, payload) {
    commit(types.SET_CURRENT_MESSAGE, payload);
    const modalSetting = { title: "Переслать сообщение", isSend: true };
    commit(types.OPEN_SEND_MESSAGE_MODAL, modalSetting);
  },
  observeModalItem({ commit, state, dispatch }, payload) {
    let chat;
    if (state.currentChat) {
      chat = state.currentChat;
    }
    if (state.channelItem) {
      chat = state.channelItem;
    }
    switch (payload) {
      case "edit": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        commit(types.EDIT_CURRENT_MESSAGE, state.currentMessage);
        break;
      }
      case "pick": {
        commit(types.SET_PICKED_MESSAGE, state.selectedMessage);
        break;
      }
      case "delete": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        dispatch("deleteMessage", {
          ids: [state.currentMessage.id],
          chat_id: state.currentChat.channel.id
        });
        break;
      }
      case "reply": {
        dispatch("replyCurrentMessage", state.selectedMessage);
        break;
      }
      case "send": {
        dispatch("resendCurrentMessage", state.selectedMessage);
        break;
      }
      case "comment": {
        dispatch("threadOpen", state.selectedMessage);
        break;
      }
      case "pin": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        httpClient({
          method: "PUT",
          url: `/Message/actionUpdate`,
          data: [
            {
              id: state.currentMessage.id,
              is_pinned: state.currentMessage.is_pinned ? 0 : 1
            }
          ]
        }).catch((err) => {
          new Error(err);
        });
        break;
      }
      case "go-message": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        const include = state.messages.some((item) => item.id === state.currentMessage.id);
        if (include) {
          scroll(state.currentMessage.id);
          commit(types.SWITCH_TO_PINNED);
        } else {
          dispatch("messageGetItems", {
            id: state.currentChat.channel.id,
            direction: "around",
            last_message_id: state.currentMessage.id
          }).then((response) => {
            commit(types.SET_MESSAGE_FROM_SEARCH, response.data.data.data.messages);
            // @TODO Не нравится это решение нужно придумать лучше если это возможно
            setTimeout(() => {
              scroll(state.currentMessage.id);
              commit(types.SWITCH_TO_PINNED);
            }, 100);
          });
        }
        break;
      }
      case "copy": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        navigator.clipboard
          .writeText(state.currentMessage.text)
          .then(commit(types.SWITCH_MODAL, { show: false, message: null }))
          .catch((err) => {
            new Error(err);
          });
        break;
      }
      case "notification": {
        dispatch("updateChat", {
          chat: { id: state.channelItem.channel.id },
          notification_enabled: !state.channelItem.channel.notification_enabled
        });
        break;
      }
      case "pin-channel": {
        dispatch("updateChat", {
          chat: { id: state.channelItem.channel.id },
          is_pinned_chat: !state.channelItem.channel.is_pinned_chat
        });
        break;
      }
      case "leave": {
        dispatch("leaveChannel", { type: "leave", id: state.channelItem.channel.id })
          .then((response) => {
            if (response.data.data.success) {
              commit(types.LEAVE_CHANNEL, state.channelItem.channel.id);
            } else {
              new Error();
            }
          })
          .catch((err) => {
            new Error(err);
          });
        break;
      }
      case "edit-channel": {
        dispatch("getChat", { chat: chat.channel }).then((response) => {
          commit(types.SET_EDIT_CURRENT_CHAT, {
            ...chat,
            users: response.data.data.data[0].users
          });
          commit(types.CHANGE_CREATE_EDIT_CHANNEL, true);
        });

        break;
      }
      case "add-member": {
        commit(types.SET_CHAT_ITEM);
        commit(types.SWITCH_MEMBER_LOADER, true);
        const modalSetting = { title: "Добавить участников", isSend: false };
        dispatch("getChat", { chat: chat.channel }).then((resp) => {
          const users = resp.data.data.data[0].users;
          dispatch("getPersonalChat", { text: "" })
            .then((response) => {
              commit(types.SET_USERS, { data: response.data.data.data, users });
              commit(types.OPEN_SEND_MESSAGE_MODAL, modalSetting);
              commit(types.SWITCH_MEMBER_LOADER, false);
            })
            .catch((e) => {
              commit(types.SWITCH_MEMBER_LOADER, false);
            });
        });
        break;
      }
      case "delete-channel": {
        dispatch("deleteChannel", { id: state.channelItem.channel.id })
          .then((response) => {
            if (response.data.data.success) {
              commit(types.LEAVE_CHANNEL);
            } else {
              new Error(response.data.data.data);
            }
          })
          .catch((err) => {
            new Error(err);
          });
        break;
      }
      case "reaction": {
        commit(types.SET_CURRENT_MESSAGE, state.selectedMessage);
        commit(types.SWITCH_REACTION_MODE, true);
        break;
      }
    }
  },
  getPersonalChat({ commit }, payload) {
    return httpClient({
      method: "GET",
      url: `/Chat/getPersonalChats`,
      params: {
        _with: JSON.stringify(["users"]),
        _search_by_nickname_or_full_name: payload.text
      }
    });
  },
  searchUsers({ commit, dispatch }, payload) {
    dispatch("getPersonalChat", payload).then((response) => {
      commit(types.SET_USERS, { data: response.data.data.data });
    });
  },
  deleteChannel({ commit }, payload) {
    return httpClient({
      method: "DELETE",
      url: `/Chat/actionDelete`,
      data: [
        {
          id: payload.id
        }
      ]
    });
  },

  closeEditAndReply({ commit, state }) {
    commit(types.CLOSE_EDIT_MESSAGE, state.currentMessage);
  },
  switchChatMenu({ commit }, payload) {
    commit(types.SWITCH_CHAT_MENU, payload);
  },
  closeSendModal({ commit }) {
    const modalSetting = null;
    commit(types.OPEN_SEND_MESSAGE_MODAL, modalSetting);
  },
  sendMessageInChat({ commit, state }, payload) {
    const data = {
      channel_id: payload.id,
      share_message_ids: payload.share_message_ids,
      text: payload.text
    };

    if (payload.links.length) {
      data.links = payload.links;
    }
    httpClient({
      method: "PUT",
      url: `/Message/actionCreate`,
      data: [data]
    })
      .then((response) => {
        commit(types.CLOSE_EDIT_MESSAGE);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  readMessageWithScroll({ commit, state, dispatch, getters }, payload) {
    commit(types.READ_MESSAGE_WITH_SCROLL);
    if (payload.count_unread_messages)
      dispatch("readChat", {
        user_id: payload.user_id,
        is_pinned_chat: state.currentChat.channel.is_pinned_chat,
        notification_enabled: state.currentChat.channel.notification_enabled,
        last_read_message_id: payload.last_message_id
      }).then((response) => {
        dispatch("getSetting").then((res) => {
          dispatch("getUserSetting", res);
        });
        if (response.data.data.success)
          commit(types.UPDATE_UNREAD_MESSAGE_COUNT, {
            data: response.data.data.data,
            count: payload.count_unread_messages
          });
      });
  },
  responseMessage({ commit, state, dispatch, getters }, payload) {
    const isCurrent =
      state.currentChat &&
      (state.currentChat.channel.id === payload.chat?.id ||
        state.currentChat.channel.id === payload.chat_id);
    let isVisible = false;

    const update = () => {
      let includeChatList = false;
      if (state.chatList.length) {
        includeChatList = state.chatList.some(
          (item) => item.channel.id === payload.chat?.id || item.channel.id === payload.chat_id
        );
        const messages = state.threadMode ? state.threadsMessages : state.messages;
        if (messages.length) {
          const lastMessageId = messages[messages.length - 1].id;
          const el = document.querySelector(`#message_${lastMessageId}`);
          const selector = state.threadMode ? "#list-comment" : "#list-dynamic";
          const container = document.querySelector(selector);
          if (el && container) {
            const rectC = container.parentNode.getBoundingClientRect();
            const rect = el.getBoundingClientRect();
            isVisible =
              rectC.top < rect.top &&
              rect.top < rectC.bottom &&
              (state.currentChat.channel.id === payload.chat?.id ||
                state.currentChat.channel.id === payload?.chat_id);
          }
        }
      }
      if (!payload.parent_message_id)
        if (includeChatList) {
          commit(types.UPDATE_CHAT, {
            action: payload,
            chatList: state.chatList,
            isVisible
          });
        } else {
          dispatch("getChat", {
            chat: { id: payload.chat?.id || payload.chat_id },
            isSendMessage: false
          })
            .then((response) => {
              commit(types.ADD_CHAT, { chat: response.data.data.data[0], data: payload });
            })
            .catch((err) => {
              new Error(err);
            });
        }
    };
    const messageCreate = (message) => {
      dispatch("messageNotification", { ...payload, message });
      if (isCurrent) {
        if (message.user_id !== store.getters.getUserId && isVisible) {
          dispatch("readChat", {
            user_id: state.currentChat.channel.chat_user_id,
            is_pinned_chat: state.currentChat.channel.is_pinned_chat,
            notification_enabled: state.currentChat.channel.notification_enabled,
            last_read_message_id: message.id
          }).then((response) => {
            if (response.data.data.success)
              commit(types.UPDATE_UNREAD_MESSAGE_COUNT, response.data.data.data);
            dispatch("getSetting").then((res) => {
              dispatch("getUserSetting", res);
            });
          });
        }
      }
    };

    switch (payload.action) {
      case "create": {
        update();
        messageCreate(payload.message);
        commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        break;
      }
      case "create_many": {
        if (!isCurrent) {
          dispatch("getSetting").then((res) => {
            dispatch("getUserSetting", res);
          });
        }
        const length = payload.messages.length;
        const message = payload.messages[length - 1];
        if (payload.parent_message_id !== undefined) {
          update();
          commit(types.SEND_MESSAGES_IN_THREAD, { payload, isVisible });
          dispatch("messageNotification", { ...payload, message });
        } else {
          update();

          messageCreate(message);
          if (isCurrent) {
            commit(types.SEND_MESSAGES, {
              messages: payload.messages,
              isVisible
            });
          }
        }
        break;
      }
      case "pinned": {
        if (isCurrent) {
          commit(types.PIN_CURRENT_MESSAGE, payload.message);
        }
        break;
      }
      case "update": {
        if (isCurrent) {
          commit(types.UPDATE_MESSAGE, payload.message);
        }
        update();
        break;
      }
      case "delete": {
        if (isCurrent) {
          commit(types.DELETE_CURRENT_MESSAGE, payload);
        }
        if (state.threadMode && state.threadMainMessage.id === payload.message.id) {
          commit(types.DELETE_OPEN_THREAD);
          commit(types.SHOW_NOTIFICATION_MODAL, {
            show: true,
            title: "Тред недоступен",
            text: "Тред удален(",
            button: "Ок",
            type: "kick"
          });
        }
        update();
        break;
      }
      case "reaction": {
        commit(types.UPDATE_MESSAGE_REACTIONS, payload);
        break;
      }
      case "read": {
        commit(types.UPDATE_READ_STATUS, payload);
        update();
        dispatch("getSetting").then((res) => {
          dispatch("getUserSetting", res);
        });
        break;
      }
      case "readed": {
        commit(types.UPDATE_READ_USER_STATUS, payload);
        break;
      }
      case "tagged": {
        if (!isCurrent) {
          commit(types.ADD_TAG, payload);
        }
        break;
      }
      case "moderatorDeleteUser": {
        update();
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        if (payload.user_id === store.getters.getUserId) {
          if (isCurrent) {
            commit(types.SHOW_NOTIFICATION_MODAL, {
              show: true,
              title: "Канал недоступен",
              text: "Администратор удалил вас из канала(",
              button: "Ок",
              type: "kick"
            });
          }
          commit(types.DELETE_USER, { data: payload, isCurrent });
        }

        break;
      }
      case "moderatorAddUser": {
        update();
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "userCreatePublicChat": {
        if (payload.user_id === store.getters.getUserId) {
          dispatch("getChat", {
            chat: { id: payload.chat?.id || payload.chat_id },
            isSendMessage: false
          })
            .then((response) => {
              commit(types.ADD_CHAT, { chat: response.data.data.data[0], data: payload });
              commit(types.SWITCH_PIN, false);
              commit(types.SWITCH_COMMENT, false);
              dispatch("getMessage", ...response.data.data.data);
              commit(types.SWITCH_PIN, true);
              commit(types.SWITCH_COMMENT, true);
            })
            .catch((err) => {
              new Error(err);
            });
        } else {
          update();
        }
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "userDeleteChat": {
        if (isCurrent) {
          commit(types.SHOW_NOTIFICATION_MODAL, {
            show: true,
            title: "Канал недоступен",
            text: "Администратор удалил канал(",
            button: "Ок",
            type: "kick"
          });
        }
        commit(types.DELETE_ADMIN_CHANNEL, { id: payload.chat_id, isCurrent });

        break;
      }
      case "selfUserLeave": {
        update();
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "moderatorChangeRole": {
        update();
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "userCreatePersonalChat": {
        // update();
        if (isCurrent) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "selfUserJoin": {
        update();
        if (isCurrent && payload.user_id !== store.getters.getUserId) {
          commit(types.SEND_MESSAGE, { message: payload.message, isVisible });
        }
        break;
      }
      case "writing": {
        if (state.chatList.length) {
          commit(types.WRITING_ACTION, payload);
        }
        break;
      }
    }
  },
  messageNotification({ commit, state }, payload) {
    const id = store.getters.getUserId;
    if (
      ((payload.thread_notification_enabled && payload.parent_message_id) ||
        !payload.parent_message_id) &&
      state.isNotification &&
      payload.message.user_id !== id &&
      payload.message.type === "message" &&
      payload.notification_enabled &&
      (!state.currentChat || payload.chat.id !== state.currentChat.channel.id)
    ) {
      commit(types.SHOW_NOTIFICATION, { show: true, newMessage: payload });

      let audioFile = new Audio(audio);
      audioFile.play().catch((err) => {
        new Error(err);
      });
    }
  },
  closeAllUnreadMessages({ commit }) {
    commit(types.SHOW_NOTIFICATION, { show: false, newMessage: null });
  },
  closeConfirmModal({ commit }) {
    commit(types.SHOW_NOTIFICATION_MODAL, {
      show: false
    });
    commit(types.SHOW_NOTIFICATION_ACTION, null);
  },
  switchPinMessages({ commit, state, dispatch }, payload) {
    if (payload.show) {
      dispatch("messageGetItems", {
        id: state.currentChat.channel.id,
        is_pinned: 1
      }).then((response) => {
        commit(types.SET_PINNED_MESSAGE, response.data.data.data.messages);
        commit(types.CLOSE_PICKED_MESSAGE);
        commit(types.SWITCH_PIN_MESSAGES, payload);
      });
    } else {
      commit(types.CLOSE_PICKED_MESSAGE);
      commit(types.SWITCH_PIN_MESSAGES, payload);
    }
  },
  changeCreateEditChannelModal({ commit }, payload) {
    commit(types.SET_EDIT_CURRENT_CHAT, null);
    commit(types.CHANGE_CREATE_EDIT_CHANNEL, payload);
  },
  changeShowCatalog({ commit }, payload) {
    commit(types.CHANGE_SHOW_CATALOG, payload);
  },
  createEditChatChannel({ commit }, payload) {
    return httpClient({
      url: `/Chat/${payload.editMode ? "actionUpdate" : "actionCreate"}`,
      method: payload.editMode ? "PUT" : "POST",
      data: [payload.data]
    });
  },
  resetAll({ commit }) {
    commit(types.RESET_CURRENT_CHAT);
  },
  switchPopupImage({ commit }, payload) {
    commit(types.SWITCH_IMAGE, payload);
  },
  pickMessage({ commit }, payload) {
    commit(types.SET_PICKED_MESSAGE, payload);
  },
  closePickMessage({ commit }) {
    commit(types.CLOSE_PICKED_MESSAGE);
  },
  sendPickMessage({ commit }) {
    const modalSetting = { title: "Переслать сообщение", isSend: true };
    commit(types.OPEN_SEND_MESSAGE_MODAL, modalSetting);
  },
  switchSettingMenu({ commit }, payload) {
    commit(types.SWITCH_SETTINGS_MENU, payload);
  },

  updateChatList({ commit, dispatch, state }, payload) {
    let data;
    if (payload.editMode) {
      data = { id: payload.data.chat_id };
    } else {
      data = payload.data;
    }
    dispatch("messageGetItems", data).then((response) => {
      const include = state.chatList.some((item) => item.channel.id === payload.data.chat_id);
      if (include) {
        commit(types.UPDATE_CHAT_LIST, {
          messages: response.data.data.data.messages,
          setting: payload.data
        });
      }
    });
  },
  updateUsers({ commit, dispatch, state }, payload) {
    commit(types.UPDATE_USERS, payload);
  },
  setFavoriteChat({ commit, state, dispatch }) {
    const chat = state.chatList.find((item) => item.channel.type === 3);
    if (chat) {
      dispatch("openCurrentChat", {
        chat: chat.channel,
        isSendMessage: false
      });
      commit(types.SWITCH_SETTINGS_MENU, false);
    } else {
      httpClient({
        method: "POST",
        url: `/Chat/actionCreate`,
        data: [
          {
            type: 3,
            user_id: store.getters.getUserId
          }
        ]
      }).then((response) => {
        const chat = response.data.data.data;
        commit(types.SET_CURRENT_CHAT, {
          chat: chat[0],
          messages: [],
          users: chat[0].users
        });
        commit(types.SWITCH_SETTINGS_MENU, false);
      });
    }
  },
  addMembers({ commit }, payload) {
    commit(types.ADD_MEMBER, payload);
  },
  findChannel({ commit }, payload) {
    return httpClient({
      url: `/Chat/actionGetItems`,
      method: "GET",
      params: {
        page: 1,
        per_page: 15,
        _where: payload.where,
        _search: payload.search,
        _with: JSON.stringify(["users", "chatSettings.Avatar"])
      }
    });
  },
  clearCatalogChannel({ commit }) {
    commit(types.CLEAR_CATALOG_CHANNELS);
  },
  findCatalogGeneralChannels({ commit, state }, payload) {
    commit(types.CHANGE_CATALOG_LOADER, true);

    httpClient({
      url: `/Chat/getAllGeneralChats`,
      method: "GET",
      params: {
        page: payload.page,
        per_page: 15,
        _where: payload.where,
        _search: payload.search,
        _with: JSON.stringify(["mySettings", "chatSettings.Avatar"])
      }
    })
      .then((response) => {
        commit(types.CHANGE_CATALOG_CHANNELS, response.data.data.data);
        commit(types.CHANGE_CATALOG_LOADER, false);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  findCatalogChannels({ commit, state }, payload) {
    commit(types.CHANGE_CATALOG_LOADER, true);

    httpClient({
      url: `/Chat/actionGetItems`,
      method: "GET",
      params: {
        page: payload.page,
        per_page: 15,
        _where: payload.where,
        _search: payload.search,
        _with: JSON.stringify(["mySettings", "chatSettings.Avatar"])
      }
    })
      .then((response) => {
        commit(types.CHANGE_CATALOG_CHANNELS, response.data.data.data);
        commit(types.CHANGE_CATALOG_LOADER, false);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  findCatalogChannelsModerate({ commit }, payload) {
    commit(types.CHANGE_CATALOG_LOADER, true);

    httpClient({
      url: `/Chat/getAllModeratedChats`,
      method: "GET",
      params: {
        page: payload.page,
        per_page: 15,
        _search: payload.search,
        _with: JSON.stringify(["mySettings", "chatSettings.Avatar"])
      }
    })
      .then((response) => {
        commit(types.CHANGE_CATALOG_CHANNELS, response.data.data.data);
        commit(types.CHANGE_CATALOG_LOADER, false);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  findCatalogMembers({ commit }, payload) {
    commit(types.CHANGE_CATALOG_LOADER, true);

    return httpClient({
      url: `/User/getItems`,
      method: "GET",
      params: {
        _count: 300,
        is_active: JSON.stringify([1]),
        _with: JSON.stringify(["Roles"]),
        _search: payload
      }
    });
  },
  forMountCatalogMembers({ commit, dispatch }) {
    dispatch("findCatalogMembers")
      .then((response) => {
        commit(types.CHANGE_CATALOG_MEMBERS, response.data.data.items);
        commit(types.CHANGE_CATALOG_LOADER, false);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  forSearchCatalogMembers({ commit, dispatch }, payload) {
    Promise.all([
      dispatch("findCatalogMembers", {
        full_name: payload
      }),
      dispatch("findCatalogMembers", {
        nickname: payload.replace("@", "")
      })
    ])
      .then((value) => {
        commit(
          types.CHANGE_CATALOG_MEMBERS,
          value
            .reduce(function (accumulator, currentValue) {
              return accumulator.concat(currentValue.data.data.items);
            }, [])
            .filter(
              (member, index, arr) => index === arr.findIndex((item) => item.id === member.id)
            )
        );
        commit(types.CHANGE_CATALOG_LOADER, false);
      })
      .catch((err) => {
        new Error(err);
      });
  },
  setAttachedModalValue({ commit }, payload) {
    commit(types.CHANGE_ATTACHED_MODAL_VALUE, payload);
  },
  setAttachedFiles({ commit }, payload) {
    commit(types.CHANGE_ATTACHED_FILES, payload);
  },
  findAttachedFiles({ commit, state, dispatch }, payload) {
    switch (payload.type) {
      case "members": {
        httpClient({
          url: `/ChatUsers/getUsersByChatId`,
          method: "GET",
          params: {
            chat_id: payload.chat_id
          }
        })
          .then((resp) => {
            dispatch("setAttachedFiles", resp.data.data.data);
          })
          .catch((err) => {
            new Error(err);
          });
        break;
      }
      case "link": {
        httpClient({
          url: `/Message/getMessagesWithLinksByChatId`,
          method: "GET",
          params: {
            channel_id: payload.chat_id,
            per_page: 300,
            page: 1
          }
        })
          .then((resp) => {
            dispatch("setAttachedFiles", resp.data.data.data.items);
          })
          .catch((err) => {
            new Error(err);
          });
        break;
      }
      case "comment": {
        break;
      }
      default: {
        httpClient({
          url: `/FileManager/actionGetItems`,
          method: "GET",
          params: payload
        })
          .then((resp) => {
            dispatch("setAttachedFiles", resp.data.data);
          })
          .catch((err) => {
            new Error(err);
          });
        break;
      }
    }
  },
  setVideoPlayerData({ commit }, payload) {
    commit(types.CHANGE_VIDEO_PLAYER_DATA, payload);
  },
  getUserSetting({ commit }, resp) {
    const setting = resp.data.data.data;
    commit(types.SET_NOTIFICATION_SETTING, setting.enable_notification);
    commit(types.SET_GROUP_SETTING, setting.enable_group_by_type);
    commit(types.SET_UNREAD_MESSAGE, setting.count_unread_messages);
    if (setting.enable_sending_messages_to_enter) {
      commit(types.SET_SEND_SETTING, "Enter");
    } else {
      commit(types.SET_SEND_SETTING, "CtrlEnter");
    }
  },

  getSetting() {
    return httpClient({
      method: "GET",
      url: `/UserSettings/actionGetItem`
    });
  },
  updateUserSetting({ commit }, payload) {
    const updateSetting = (resp) => {
      const setting = resp.data.data.data;
      commit(types.SET_NOTIFICATION_SETTING, setting.enable_notification);
      commit(types.SET_GROUP_SETTING, setting.enable_group_by_type);
      if (setting.enable_sending_messages_to_enter) {
        commit(types.SET_SEND_SETTING, "Enter");
      } else {
        commit(types.SET_SEND_SETTING, "CtrlEnter");
      }
    };
    UserSettingsModel.actionUpdate(updateSetting, [
      {
        enable_notification: payload.notification,
        enable_group_by_type: payload.group,
        enable_sending_messages_to_enter: payload.enter
      }
    ]);
  },
  createChatUser({ commit }, payload) {
    return httpClient({
      url: `/ChatUsers/actionCreate`,
      method: "POST",
      data: [
        {
          create_type: payload.type,
          chat_id: payload.id,
          users: payload.users
        }
      ]
    });
  },
  updateJoinChannel({ commit, state, dispatch }, payload) {
    dispatch("joinChannel", payload).then((resp) => {
      dispatch("getChat", { chat: { id: state.currentChat.channel.id } }).then((chat) => {
        dispatch("messageGetItems", { id: state.currentChat.channel.id }).then((messages) => {
          if (chat.data.data.data.length) {
            commit(types.SET_CURRENT_CHAT, {
              chat: chat.data.data.data[0],
              messages: messages.data.data.data.messages,
              users: chat.data.data.data[0].users
            });
            commit(types.UPDATE_CHAT_LIST, {
              messages: messages.data.data.data.messages,
              setting: chat.data.data.data[0].chat_settings
            });
          }
        });
      });
    });
  },
  joinChannel({ commit }, payload) {
    const data = { create_type: payload.type, chat_id: payload.id };
    return httpClient({
      method: "POST",
      url: `/ChatUsers/actionCreate`,
      data: [data]
    });
  },
  leaveChannel({ commit }, payload) {
    const data = { delete_type: payload.type, chat_id: payload.id };
    if (payload.type === "delete") {
      data.user_id = payload.user_id;
    }
    return httpClient({
      method: "DELETE",
      url: `/ChatUsers/actionDelete`,
      data: [data]
    });
  },
  deleteUserInChannel({ commit, dispatch }, payload) {
    dispatch("leaveChannel", payload).then(() => {
      commit(types.UPDATE_USERS_LIST, { data: payload, isDelete: true });
    });
  },
  leaveChannelInCatalog({ commit }, payload) {
    commit(types.LEAVE_CHANNEL, payload);
  },
  updateModeratorStatus({ commit, dispatch }, payload) {
    return httpClient({
      url: `/ChatUsers/actionUpdate`,
      method: "POST",
      data: [
        {
          update_type: payload.type,
          id: payload.id,
          is_moderator: payload.is_moderator
        }
      ]
    });
  },
  updateUserInChannel({ commit, dispatch }, payload) {
    dispatch("updateModeratorStatus", payload).then(() => {
      commit(types.UPDATE_USERS_LIST, { data: payload, isDelete: false });
    });
  },
  confirmAction({ commit, dispatch }, payload) {
    commit(types.SHOW_NOTIFICATION_MODAL, payload.data);
    commit(types.SHOW_NOTIFICATION_ACTION, payload.action);
  },
  toggleChatNotification({ commit, state, dispatch }) {
    commit(types.SET_CHAT_ITEM);
    dispatch("updateChat", {
      chat: { id: state.channelItem.channel.id },
      notification_enabled: !state.channelItem.channel.notification_enabled
    });
  },
  resetAllSetting({ commit }) {
    commit(types.RESET_CHAT);
  },
  threadOpen({ commit, state, dispatch }, payload) {
    let user;
    const result = (res) => {
      commit(types.SET_CURRENT_MESSAGE, payload);
      commit(types.SET_THREAD_MODE, { messages: res.data.data.data.messages, show: true });
      if (user && state.threadsMessages.length)
        dispatch("readChat", {
          user_id: user.id,
          is_pinned_chat: state.currentChat.channel.is_pinned_chat,
          notification_enabled: state.currentChat.channel.notification_enabled,
          last_read_message_id: state.threadsMessages[state.threadsMessages.length - 1].id
        }).then((res) => {
          commit(types.READ_THREAD_MESSAGE, {
            data: res.data.data.data,
            count: payload.thread.count_unread_messages
          });
          dispatch("getSetting").then((res) => {
            dispatch("getUserSetting", res);
          });
        });
    };
    const data = {
      channel_id: state.currentChat.channel.id,
      "_filters[thread_id]": payload.has_thread_id
    };
    const getThread = (response) => {
      if (response.data.data.data.length) {
        user = response.data.data.data[0].users.find(
          (item) => item.user_id === store.getters.getUserId
        );
      }
      MessageModel.actionGetItems(result, data);
    };
    if (payload.has_thread_id) {
      PersonalChatModel.actionGetItem(getThread, {
        id: payload.has_thread_id
      });
    } else {
      commit(types.SET_CURRENT_MESSAGE, payload);
      commit(types.SET_THREAD_MODE, { messages: [], show: true });
    }
  },
  closeThread({ commit }) {
    commit(types.CLOSE_THREAD_MODE);
  },
  openThreads({ commit, dispatch, state }) {
    const open = (res) => {
      commit(types.SWITCH_THREADS_LIST, { show: true, threads: res.data.data.data.messages });
      const data = {
        component: "ThreadsModal",
        count: res.data.data.data.messages.length,
        name: "Тредов с комментариями",
        val: "comment"
      };

      dispatch("setAttachedModalValue", data);
    };

    const req = {
      channel_id: state.currentChat.channel.id,
      "_filters[has_thread]": 1
    };
    MessageModel.actionGetItems(open, req);
  },
  searchThreads({ commit, dispatch, state }, payload) {
    const open = (res) => {
      commit(types.SWITCH_THREADS_LIST, { show: true, threads: res.data.data.data.items });
    };
    const params = {
      channel_id: state.currentChat.channel.id,
      _search_by_nickname_or_full_name: payload.text,
      _search: JSON.stringify({ text: payload.text }),
      "_filters[has_thread]": 1,
      _with: JSON.stringify(["thread"])
    };
    MessageModel.search(open, params);
  },
  getMessageWithScroll({ commit, dispatch, state }, payload) {
    const open = (res) => {
      commit(types.COMPARE_THREADS_MESSAGE, res.data.data.data.messages);
    };
    const req = {
      channel_id: state.currentChat.channel.id,
      message_direction: "up",
      id: payload,
      "_filters[has_thread]": 1
    };
    MessageModel.actionGetItems(open, req);
  },
  closeThreads({ commit }) {
    commit(types.SWITCH_THREADS_LIST, { show: false, threads: [] });
  },
  sendReaction({ commit, state }, payload) {
    const result = (res) => {
      let message = state.messages.find((item) => item.id === res.data.data.data.message_id);
      let pinMessage = state.pinned_messages.find(
        (item) => item.id === res.data.data.data.message_id
      );
      message = message
        ? message
        : state.threadsMessages.find((item) => item.id === res.data.data.data.message_id);
      if (message) {
        message.my_reactions.push(res.data.data.data);
      }
      if (pinMessage) {
        pinMessage.my_reactions.push(res.data.data.data);
      }
    };
    const data = {
      message_id: payload.id,
      reaction: payload.reaction
    };
    MessageReactionsModel.actionCreate(result, [data]);
  },
  deleteReaction({ commit, state }, payload) {
    const result = (res) => {
      let message = state.messages.find((item) => item.id === payload.id);
      let pinMessage = state.pinned_messages.find((item) => item.id === payload.id);
      message = message ? message : state.threadsMessages.find((item) => item.id === payload.id);
      if (message) {
        message.my_reactions = message.my_reactions.filter(
          (item) => item.reaction !== payload.reaction
        );
      }
      if (pinMessage) {
        pinMessage.my_reactions = pinMessage.my_reactions.filter(
          (item) => item.reaction !== payload.reaction
        );
      }
    };
    const data = {
      message_id: payload.id,
      reaction: payload.reaction
    };
    MessageReactionsModel.actionDelete(result, [data]);
  },

  getAllUserReaction({ commit, dispatch, state }, payload) {
    const result = (res) => {
      commit(types.SET_USERS_REACTION, {
        show: true,
        reactionList: res.data.data.data.items,
        count: res.data.data.data.count,
        message_id: payload.id
      });
      const data = {
        component: "ReactionListContent",
        count: 0,
        name: "Пользовательские реакции",
        val: "reaction"
      };
      dispatch("setAttachedModalValue", data);
    };
    const params = {
      message_id: payload.id,
      per_page: 20,
      page: 1
    };
    MessageReactionsModel.getAllReactions(result, params);
  },
  getUserReactionWithScroll({ commit, dispatch, state }, payload) {
    const result = (res) => {
      commit(types.ADD_USERS_REACTION, {
        reactionList: res.data.data.data.items,
        count: res.data.data.data.count
      });
    };
    const params = {
      message_id: payload.id,
      per_page: 20,
      page: payload.page
    };
    MessageReactionsModel.getAllReactions(result, params);
  },
  closeReactionList({ commit }) {
    commit(types.SET_USERS_REACTION, { show: false, reactionList: [] });
  },
  showChatTooltip({ commit }, payload) {
    commit(types.SWITCH_TOOLTIP, payload);
  }
};

export default actions;
