import centrifuge from "@/centrifuge";

const writingStatus = (id, is_writing, chat) =>
  centrifuge
    .publish(`$chat@user.${id}`, {
      action: "writing",
      is_writing: is_writing,
      chat: chat
    })
    .then((res) => {})
    .catch((err) => {
      new Error(err);
    });
export default writingStatus;
