<template>
  <div class="project-stage" ref="projectStage">
    <details>
      <summary @click="handleClick">
        <div
          class="project-stage__header"
          :class="{ 'project-stage__header_border': stage.id === null }"
        >
          <div @click.prevent.stop v-if="showCheckbox">
            <CheckboxISP @input="completeStage" :value="isComplete" :isEdit="isEdit" />
          </div>
          <p v-if="stage.id !== null">
            <b>ЭТАП<span v-if="setDateValue">:</span></b>
            <span v-if="setDateValue"> {{ setDateValue }}</span>
          </p>
          <p v-else>
            <b>{{ stage.name }}</b>
          </p>
        </div>
        <div class="project-stage__body" v-if="stage.id !== null">
          <div class="project-stage__title">
            <p>{{ stage.name }}</p>
          </div>
          <!--          <TasksCounter :items="infoItems" />-->
        </div>
      </summary>
      <div class="project-stage__tasks">
        <div class="project-stage__tasks-list" v-if="taskList && taskList.count !== 0">
          <ProjectTask v-for="task in taskList.list.slice(0, 3)" :key="task" :task="task" />
        </div>
      </div>
      <p
        class="project-stage__show-more"
        v-if="taskList && taskList.count - 3 > 0"
        @click="goToStage"
      >
        Ещё {{ taskList.count - 3 }} задач
      </p>
    </details>
  </div>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import moment from "moment";
import ProjectTask from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/ProjectTask";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";

export default {
  name: "ProjectStage",
  mixins: [],
  props: {
    isOnCurtain: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: true
    },
    projectId: {
      type: String,
      default: "0"
    },
    stage: {
      type: Object,
      default: () => {}
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  },
  components: { ProjectTask, CheckboxISP },
  data() {
    return {
      isComplete: false,
      isOpen: false
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.projectStage);
  },
  mounted() {
    this.isComplete = this.stage.is_completed;
    if (this.isLastItem) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            StageModule.getStageByPagination({
              project_id: this.currentProject.id
            });
            this.observer.unobserve(this.$refs.projectStage);
          }
        });
      });
      this.observer.observe(this.$refs.projectStage);
    }
  },
  methods: {
    async completeStage(val) {
      const isComplete = await StageModule.completeStage({
        stage_id: this.stage.id,
        isComplete: val,
        stage: this.stage
      });
      if (isComplete !== null) {
        this.isComplete = isComplete;
      }
    },
    handleClick(e) {
      if (!this.isOnCurtain) {
        e.preventDefault();
        this.goToStage();
      } else {
        if (!this.isOpen && (this.taskList.count === 0 || this.stage.id === 0))
          TaskModule.getTaskListByStageIdAction({
            stage_id: this.stage.id === 0 ? null : this.stage.id,
            per_page: 3,
            project_id: this.projectId,
            toReset: true
          });

        this.isOpen = !this.isOpen;
      }
    },
    goToStage() {
      StageModule.goToStagePage({
        project_id: this.projectId,
        stage: this.stage
      });

      this.$router.push({
        name: "Project",
        params: { id: this.projectId },
        query: { stage: `${this.stage.id === 0 ? "default" : this.stage.id}` }
      });
    }
  },
  computed: {
    currentStage() {
      return StageModule.stage;
    },
    // params() {
    //   return this.stage.id === 0
    //     ? {
    //         page: TaskModule.tasksByStagePage,
    //         per_page: 10,
    //         project_id: this.projectId
    //       }
    //     : {
    //         page: TaskModule.tasksByStagePage,
    //         stage_id: this.stage.id,
    //         per_page: 10,
    //         project_id: this.projectId
    //       };
    // },
    setDateValue() {
      if (this.stage.date_from && this.stage.date_to) {
        return (
          moment(this.stage.date_from).format("DD.MM.YYYY") +
          " по " +
          moment(this.stage.date_to).format("DD.MM.YYYY")
        );
      }
      return false;
    },
    taskList() {
      return TaskModule.tasksByStage(this.stage.id === 0 ? null : this.stage.id);
    },
    // tasks() {
    //   return TaskModule.tasksByStage(this.stage.id);
    // },
    currentProject() {
      return ProjectModule.currentProject?.projectFull;
    },
    isEdit() {
      return this.currentProject.is_owner && !this.currentProject.is_completed;
    }
  },
  watch: {
    stage() {
      this.isComplete = this.stage.is_completed;
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.project-stage {
  border: 1px solid #eae8f0;
  border-radius: 8px;
  margin-bottom: 24px;
  cursor: pointer;

  details > summary {
    list-style: none;
    outline: none;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid #eae8f0;
    margin-bottom: 8px;

    &_border {
      border-bottom: none;
    }

    p {
      font-size: 18px;
      margin-left: 8px;
      line-height: 21px;
      color: $text-default;

      @media (max-width: $sm) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__body {
    padding: 16px;

    @media (max-width: $sm) {
      padding: 16px 8px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    color: $text-default;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__show-more {
    margin: 0;
    padding: 8px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $link-default;
    cursor: pointer;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
