import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import Vue from "vue";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import {
  DELETE_PROBLEM,
  DELETE_TASK,
  SET_CHILDREN_PROBLEM,
  SWITCH_MOVE_MODAL
} from "@/modules/Isp/store/mutations/mutationTypes";

const mutationsIspProblem = {
  TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM(state, payload) {
    state.showCurtainCreateProblem = payload;
  },
  [types.SET_CHILDREN_PROBLEM](state, payload) {
    state.currentInfoProblem.children_problems.push({
      id: payload.id,
      group: state.currentInfoProblem.group
    });
  },
  [types.SET_CURRENT_INFO_PROBLEM](state, payload) {
    state.currentInfoProblem = payload.data;
  },
  [types.DELETE_PROBLEM](state) {
    if (state.problemsData.length) {
      state.problemsData = state.problemsData.filter(
        (item) => item.id !== state.currentInfoProblem.id
      );
    }
    if (state.backlogProblemsData.length) {
      state.backlogProblemsData = state.backlogProblemsData.filter(
        (item) => item.id !== state.currentInfoProblem.id
      );
    }
    state.showCurrentInfoProblem = false;
    state.currentInfoProblem = null;
  },
  [types.SWITCH_MOVE_MODAL](state, payload) {
    state.showMoveProblem = payload;
  },
  [types.SHOW_CURRENT_INFO_PROBLEM](state, payload) {
    state.showCurrentInfoProblem = payload;
  },
  [types.CLOSE_CURRENT_INFO_PROBLEM](state) {
    // state.currentInfoProblem = null;
    state.showCurrentInfoProblem = false;
  },
  SET_CURRENT_PROBLEM_HISTORY(state, payload) {
    state.currentProblemHistory = payload;
  },
  SET_CURRENT_PROBLEM_HISTORY_TOTAL_COUNT(state, payload) {
    state.currentProblemHistoryTotalCount = payload;
  },
  SET_CURRENT_PROBLEM_HISTORY_COUNTER(state, payload) {
    state.currentProblemHistoryCounter = payload;
  },
  SET_CURRENT_PROBLEM_COMMENTS(state, payload) {
    state.currentProblemComments = payload;
  },
  SET_CURRENT_REPLYABLE_COMMENTS(state, payload) {
    state.currentReplyableComment = payload;
  },
  SET_CURRENT_EDITABLE_COMMENT(state, payload) {
    state.currentEditableComment = payload;
  },
  [types.UPDATE_VIEWER_STATUS](state, payload) {
    if (payload) {
      state.currentInfoProblem.current_viewer_user = payload;
    }
  },
  [types.UPDATE_PROBLEM_FILES](state, payload) {
    state.currentInfoProblem.attachment_files = payload;
  },
  [types.SET_FILES_LOADER](state, payload) {
    state.filesLoader = payload;
  },
  [types.UPDATE_PROBLEM](state, oldPeriodId) {
    if (oldPeriodId !== null) {
      let problemIndex = state.problemsData.findIndex(
        (item) => item.id === state.currentInfoProblem.id
      );
      if (problemIndex !== -1) {
        Vue.set(state.problemsData, problemIndex, state.currentInfoProblem);
        state.problemsData = state.problemsData.filter(
          (item) => item.period_id === state.currentQuarter.id
        );
      }
    } else {
      let problemIndex = state.backlogProblemsData.findIndex(
        (item) => item.id === state.currentInfoProblem.id
      );
      if (problemIndex !== -1) {
        Vue.set(state.backlogProblemsData, problemIndex, state.currentInfoProblem);
        state.backlogProblemsData = state.backlogProblemsData.filter(
          (item) => item.period_id === null
        );
      }
    }
    if (state.currentInfoProblem.period_id === null) {
      state.backlogProblemsData.unshift(state.currentInfoProblem);
      state.backlogProblemsData = Array.from(new Set(state.backlogProblemsData));
    } else {
      if (state.currentInfoProblem.period_id === state.currentQuarter?.id) {
        state.problemsData.unshift(state.currentInfoProblem);
        state.problemsData = Array.from(new Set(state.problemsData));
      }
    }
  },
  [types.GET_TASK_LIST](state, payload) {
    state.taskList = payload;
    let members = [];
    if (state.taskList.length) {
      members = state.taskList.map((item) => item.executors).flat();
    }
    state.currentInfoProblem.task_executors = setUniqueArray(members, "id");
  },
  [types.DELETE_TASK](state, payload) {
    state.taskList = state.taskList.filter((item) => item.id !== payload.id);
  },
  [types.UPDATE_TASK](state, payload) {
    let task = state.taskList.find((item) => item.id === payload.data.id);
    task = payload.data;
    if (payload.users) {
      task.executors = payload.users;
    }
    if (payload.files) {
      task.attachment_files = payload.files;
    }
    const index = state.taskList.findIndex((item) => item.id === payload.data.id);
    if (index !== -1) {
      Vue.set(state.taskList, index, task);
    }

    let members = [];
    if (state.taskList.length) {
      members = state.taskList.map((item) => item.executors).flat();
    }
    state.currentInfoProblem.task_executors = setUniqueArray(members, "id");
  },
  [types.SHOW_CREATE_TASK_MODAL](state, payload) {
    state.showIspCreateTaskModal = payload;
  }
};

export default mutationsIspProblem;
