import moment from "moment";

const round = (n, to) => n - (n % to);

export const yearGenerator = (year: string) => {
  const now = new Date(year);
  const start: any = new Date(round(now.getFullYear(), 10), 0, 1);
  const length = 10;
  const result = Array(length);
  for (let i = 0; i < length; i++) {
    result[i] = moment(new Date().setFullYear(start.getFullYear() + i)).format("YYYY");
  }
  return result;
};
