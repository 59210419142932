













import TaskContentItem from "@/modules/TimeTracker/component/TaskContentList/TaskContentItem";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProject, ITask } from "@/modules/TimeTracker/interface/interface";

@Component({
  components: { TaskContentItem }
})
export default class TaskContentList extends Vue {
  @Prop({
    type: Array,
    default: []
  })
  tasks!: Array<ITask>;
  @Prop({
    type: Object,
    default: () => {}
  })
  project!: IProject;
  activeTask: null | ITask = null;

  editCurrentTask(task) {
    this.activeTask = task;
  }
}
