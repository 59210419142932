















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CalendarTimeGrid from "@/aopV2/modules/Calendar/components/CalendarTime/CalendarTimeGrid.vue";
import moment from "moment";
import { timeList } from "@/modules/Calendar/components/CalendarWorkTime";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import CalendarTimeLine from "@/aopV2/modules/Calendar/components/CalendarTime/CalendarTimeLine.vue";

@Component({
  components: { CalendarTimeGrid, CalendarTimeLine }
})
export default class CalendarDayWeek extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  day!: any;
  @Prop({
    default: "",
    type: String
  })
  scrollTime!: string;
  @Prop({
    default: 1,
    type: Number
  })
  index!: number;
  @Prop({
    default: true,
    type: Boolean
  })
  eventLoader!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowHeader!: boolean;
  timeList: Array<any> = [];

  mounted() {
    this.setCalendarTime();
  }

  /**
   * Set work time in calendar.
   */
  setCalendarTime() {
    const times = JSON.parse(JSON.stringify(timeList));
    const startIndex = times.findIndex((item) => item.start_time === this.start);
    const endIndex = times.findIndex((item) => item.start_time === this.stop);
    this.timeList = times.slice(startIndex, endIndex);
  }

  get timeGrid() {
    const [hh] = this.start.split(":");
    return this.timeList.map((item) => {
      const events = this.day.events.filter((event) => {
        let hourS = new Date(event.started_at).getHours();
        let hourE = new Date(event.ended_at).getHours();
        hourS = +hh > hourS && hourE > +hh ? +hh : hourS;
        const hourFormat = hourS <= 9 ? `0${hourS}:00` : `${hourS}:00`;
        return hourFormat === item.start_time;
      });
      return { ...item, events };
    });
  }

  get headerFormat() {
    if (this.contentWidth !== null) {
      if (this.contentWidth > 1200) {
        return `${moment(new Date(this.day.date)).format("dddd")} ${moment(
          new Date(this.day.date)
        ).format("DD.MM")}`;
      } else {
        return `${moment(new Date(this.day.date)).format("dd")}. ${moment(
          new Date(this.day.date)
        ).format("DD.MM")}`;
      }
    } else {
      return `${moment(new Date(this.day.date)).format("dddd")} ${moment(
        new Date(this.day.date)
      ).format("DD.MM")}`;
    }
  }

  get start() {
    return CalendarModule2.gridStartTime;
  }

  get stop() {
    return CalendarModule2.gridStopTime;
  }

  get contentWidth() {
    return this.$store.getters["MainLayoutSetting/getContentWidth"];
  }

  get isToday() {
    return moment(this.day.date).format("L") === moment().format("L");
  }

  get showWeekends() {
    return this.isWeekends ? true : !(this.day.weekDay === 5 || this.day.weekDay === 6);
  }

  get isWeekends() {
    return CalendarModule2.isWeekends;
  }

  @Watch("start") changeStartCalendarTime() {
    this.setCalendarTime();
  }

  @Watch("stop") changeStopCalendarTime() {
    this.setCalendarTime();
  }
}
