<template>
  <div class="modal-background">
    <div class="modal-content">
      <div class="modal-content__header">
        <div class="label">Восстановление пароля</div>
      </div>
      <div class="modal-content__form">
        <label>Введите новый пароль</label>
        <div class="form-password">
          <TextInput
            :isTextarea="false"
            :isEmoji="false"
            :type="showPassword ? 'text' : 'password'"
            :classList="'font18'"
            :placeholder="'Например, ILOVEAOP143'"
            @changeText="setPasswordValue"
          />
          <i class="icon-eye" v-show="!showPassword" @click="showPassword = !showPassword"></i>
          <i
            class="icon-eye-closed"
            v-show="showPassword"
            @click="showPassword = !showPassword"
          ></i>
        </div>
      </div>
      <div class="modal-content__footer">
        <button class="button-send" @click="changePassword">Восстановить</button>
      </div>
    </div>
  </div>
</template>
<script>
import { host } from "@/globalVariables";
import CryptoJS from "crypto-js";
import TextInput from "@/components/Widgets/Inputs/TextInput";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalChangePassword",
  mixins: [],
  props: [],
  components: { TextInput },
  data() {
    return {
      showPassword: false,
      password: null,
      loader: false
    };
  },
  mounted() {},
  methods: {
    setPasswordValue(val) {
      this.password = val.value;
    },
    hideModalTask() {
      this.clearModal();
      this.$root.$emit("hideModalChangePassword", false);
    },
    clearModal() {
      this.password = null;
    },
    changePassword() {
      if (!this.password || this.loader) return false;
      this.loader = true;
      httpClient({
        url: `${host.authUrl}/User/resetPassword`,
        method: "POST",
        data: [
          {
            reset_password_token: this.$route.params.reset_password_token,
            new_password: CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString()
          }
        ]
      })
        .then((resp) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `${resp.data.data.message}`
          });
          this.loader = false;
          this.hideModalTask();
        })
        .catch((error) => {
          this.loader = false;
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 716px;
    border-radius: 24px;
    user-select: none;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
    @media screen and (max-width: $md) {
      width: 90%;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 30px 30px 23px;
      border-bottom: 1px solid #f2f2f7;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 20px 20px 15px;
      }
      .label {
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        color: #000000;
        @media screen and (max-width: $sm) {
          font-size: 14px;
        }
      }
      i {
        font-size: 20px;
        cursor: pointer;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 38px 30px;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 15px 20px;
      }
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-disabled;
        margin-bottom: 24px;
      }
      .form-password {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        /deep/ {
          .input-wrap {
            width: 100%;
            margin: 0;
            input {
              @media screen and (max-width: $sm) {
                font-size: 14px;
              }
            }
            input::placeholder {
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 28px;
              color: $text-disabled;
              @media screen and (max-width: $sm) {
                font-size: 14px;
              }
            }
          }
        }
      }
      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
      }
    }
    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 30px;
      border-top: 1px solid #f2f2f7;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 20px;
      }
      .button-send {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $text-on-interactive;
        background: $text-highlight;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 100px;
        padding: 12px 200px;
        border: none;
        outline: none;
        @media screen and (max-width: $md) {
          padding: 12px 70px;
        }
        @media screen and (max-width: $sm) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
