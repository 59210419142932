<template>
  <div class="graph-vacation">
    <TimeLineVacation
      :graphVacationData="graphVacationData"
      :absenceList="absenceList"
      :isAdmin="isAdmin"
    />
    <ConfirmationModal
      v-if="isShowConformationModal"
      :vacation="vacation"
      :createMode="createMode"
      :editMode="editMode"
      :isUser="isUser"
      :user="user"
      :isAdmin="isAdmin"
      timeline-vacation__date-header
      :isAvailableForEditing="isAvailableForEditing"
    />
    <VacationRequestModal
      v-if="isShowVacationRequestModal"
      :createMode="createMode"
      :editMode="editMode"
      :vacation="vacation"
      :user="user"
      :isUser="isUser"
      :isAvailableForEditing="isAvailableForEditing"
    />
    <ConfirmationModalPersonnelOfficer
      v-if="isShowConfirmationModalPersonnelOfficer"
      :isAdmin="isAdmin"
    />
    <div class="graph-vacation__modal modal" v-if="loading">
      <Loader />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

// TODO: создать и использовать миксин, так как эта настройка используется во множестве компонентов
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class"
  },
  buttonsStyling: false
});

import TimeLineVacation from "@/components/GraphVacation/TimeLine/TimeLineVacation";
import axios from "axios";
import { domain } from "@/globalVariables";
import ConfirmationModal from "@/components/GraphVacation/GraphVacationModals/ConfirmationModal";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import ConfirmationModalPersonnelOfficer from "@/components/GraphVacation/GraphVacationModals/ConfirmationModalPersonnelOfficer";
import VacationRequestModal from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/VacationRequestModal";
import moment from "moment";
import httpClient from "@/api/client/httpClient";
export default {
  name: "GraphVacation",
  mixins: [],
  props: [],
  components: {
    ConfirmationModalPersonnelOfficer,
    Loader,
    ConfirmationModal,
    VacationRequestModal,
    TimeLineVacation
  },
  data() {
    return {
      absenceList: [],
      graphVacationData: [],
      currentGraphVacationData: null,
      isShowCreateAndEditGraphVacationModal: false,
      isShowGraphVacationModal: false,
      editMode: false,
      createMode: false,
      isShowConformationModal: false,
      vacation: null,
      loading: false,
      user: {},
      isUser: false,
      isAdmin: false,
      isPersonnelOfficer: false,
      isShowConfirmationModalPersonnelOfficer: false,
      request: false,
      isShowVacationRequestModal: false,
      isAvailableForEditing: false,
      year: moment(new Date()).year().toString()
    };
  },
  beforeDestroy() {
    this.$root.$off("goGetInfoUser");
    this.$root.$off("deleteVacation");
    this.$root.$off("openConformationModal");
    this.$root.$off("hiddenGraphVacationModal");
    this.$root.$off("isAvailableForEditing");
    this.$root.$off("openConfirmationModalPersonnelOfficer");
    this.$root.$off("closeConfirmationModalPersonnelOfficer");
    this.$root.$off("openVacationRequestModal");
    this.$root.$off("closeVacationRequestModal");
    this.$root.$off("changeMyVacationYear");
    this.$root.$off("updateAbsenceList");
  },
  mounted() {
    this.$root.$on("openVacationRequestModal", (val) => {
      if (val.absence) {
        this.getVacation(val.vacation, val.absence);
      } else {
        this.vacation = val.vacation;
        this.isShowVacationRequestModal = true;
      }
      this.isShowConformationModal = false;
      this.editMode = val.editMode;
      this.createMode = val.createMode;
      this.request = val.request;
    });
    this.$root.$on("updateAbsenceList", () => {
      this.getVacationUserList(this.getDataInfoUser.id);
    });
    this.$root.$on("changeMyVacationYear", (val) => {
      this.year = val;
      this.getVacationUserList();
    });
    this.$root.$on("closeVacationRequestModal", () => {
      this.isShowVacationRequestModal = false;
    });
    this.$root.$on("isAvailableForEditing", (val) => {
      this.isAvailableForEditing = val;
    });
    this.$root.$on("goGetInfoUser", () => {
      this.getUserData();
    });
    this.$root.$on("openConformationModal", (val) => {
      this.editMode = val.editMode;
      this.createMode = val.createMode;

      if ((val.vacation && !this.editMode) || (val.vacation && val.absence)) {
        // Выполняется в случае просмотра запроса на отпуск или вызова редактирования из виджита отпуск
        this.getVacation(val.vacation);
        this.loading = true;
      } else if (val.vacation && !val.createMode) {
        // Выполняется в случае вызова редактирования из модального окна просмотра
        // данные для заполнения подставляются из модалки просмотра
        this.vacation = val.vacation;
        this.loading = true;
        this.getUser();
      } else {
        // Создание нового запроса на отпуск

        this.vacation = val.vacation;

        this.showConformationModal();
      }
    });
    this.$root.$on("openConfirmationModalPersonnelOfficer", () => {
      this.isShowConfirmationModalPersonnelOfficer = true;
    });
    this.$root.$on("closeConfirmationModalPersonnelOfficer", () => {
      this.isShowConfirmationModalPersonnelOfficer = false;
    });
    this.$root.$on("deleteVacation", (val) => {
      this.askDeleteVacation(val);
    });
    this.$root.$on("hiddenGraphVacationModal", () => {
      this.hiddenConformationModal();
    });
    this.$root.$emit("goGetInfoUser");
  },
  methods: {
    getUserData() {
      this.isAdmin = this.getDataInfoUser.roles.some((item) => item === "vacation_admin");
      this.getVacationUserList(this.getDataInfoUser.id);
    },
    getVacationUserList() {
      httpClient({
        method: "GET",
        url: `/User/getItems/`,
        params: {
          id: this.getUserId,
          year: this.year,
          _service_with: JSON.stringify(["vacation_stock", "vacation_orders"])
        }
      })
        .then((response) => {
          this.absenceList = response.data.data.items;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    getUser() {
      httpClient({
        method: "GET",
        url: `/User/getItems`,
        params: {
          id: JSON.stringify(this.vacation.user_id),
          _with: JSON.stringify(["Roles", "Departments"])
        }
      }).then((response) => {
        this.user = response.data.data.items[0];
        this.isPersonnelOfficer = this.getDataInfoUser.roles.some(
          (item) => item === "vacation_admin"
        );
        this.isUser = this.user.id === this.getDataInfoUser.id || this.isPersonnelOfficer;
        this.loading = false;
        this.showConformationModal();
      });
    },
    getVacation(vacation, absence) {
      httpClient({
        url: `/VacationOrder/actionGetItem`,
        method: "GET",
        params: {
          id: JSON.stringify(vacation.id),
          _with: "confirmations"
        }
      })
        .then((response) => {
          this.vacation = response.data.data.data;
          if (absence) {
            this.isShowVacationRequestModal = true;
            this.isShowConformationModal = false;
          } else {
            this.getUser();
          }
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    //открытие модалки подтверждения удаления отпуска
    askDeleteVacation(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить запрос на отпуск?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteCurrentVacation(id);
          }
        });
    },
    // удаление отпуска
    deleteCurrentVacation(id) {
      httpClient({
        method: "DELETE",
        url: `/VacationOrder/actionDelete/`,
        params: {
          id: id
        }
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на отпуск успешно удален!"
          });
          this.$root.$emit("goGetInfoUser");
          this.$root.$emit("updateVacationList");
          this.$root.$emit("updateAbsenceList");
          this.isDeleteVacationModal = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    showConformationModal() {
      this.isShowConformationModal = true;
    },
    hiddenConformationModal() {
      this.user = {};
      this.isShowConformationModal = false;
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.graph-vacation {
  font-family: "SF", sans-serif !important;
  font-weight: 400;
  width: calc(100% - 20px);
  height: 100%;
  //padding: 20px 16px 0;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  @media (max-width: $md) {
    width: 100%;
  }
  &__header-widget {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    margin-right: 20px;
    margin-bottom: 30px;
    max-height: 328px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(33, 32, 44, 0.8);
  }
}

/deep/ {
  .icon-class {
    display: none;
  }
}
</style>
