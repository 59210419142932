<template>
  <div class="knowledge-base-bg">
    <NavSectionColumn v-show="!getCreateMode && !getEditMode" />
    <router-view />
  </div>
</template>

<script>
import NavSectionColumn from "@/components/KnowledgeBaseComponents/NavSection/NavSectionColumn";

export default {
  name: "KnowledgeBase",
  mixins: [],
  props: [],
  components: { NavSectionColumn },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    getCreateMode() {
      return this.$store.getters.getCreateMode;
    },
    getEditMode() {
      return this.$store.getters.getEditMode;
    }
  },
  watch: {
    $route() {
      this.$store.commit("changeCreateMode", false);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.knowledge-base-bg {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $bg-surface-gradient;
  padding: 24px;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: $lg) {
    flex-flow: column;
    align-items: center;
  }

  .nav-section-bg {
    @media screen and (max-width: $lg) {
      position: relative;
      top: 0;
      width: 100%;
      margin: 0 0 20px;
    }
  }
}
</style>
