<template>
  <li class="doc" :class="{ doc_edit: isEdit }">
    <component :is="setFile" />
    <a class="doc__link" :href="returnSrcFile" target="_blank">
      <div class="doc__wrap">
        <span class="doc__name"> {{ file.label || file.name }} </span>
        <span class="doc__size">{{ sizeFloor }}</span>
      </div>
    </a>

    <div class="doc__wrap-icon" v-if="isEdit">
      <i class="icon-delete" @click.stop="handleDelete"></i>
    </div>
  </li>
</template>

<script>
import { domainStorage } from "@/globalVariables";

const FileUpload = () => import("vue-upload-component");

export default {
  name: "Docs",
  mixins: [],
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { FileUpload },
  data() {
    return {
      formats: {
        "image/jpg": () => import(`@/modules/Isp/assets/iconFiles/JPG`),
        "image/jpeg": () => import(`@/modules/Isp/assets/iconFiles/JPEG`),
        "image/png": () => import(`@/modules/Isp/assets/iconFiles/PNG`),
        "application/pdf": () => import(`@/modules/Isp/assets/iconFiles/PDF`),
        "application/msword": () => import(`@/modules/Isp/assets/iconFiles/DOC`),
        "video/x-flv": () => import(`@/modules/Isp/assets/iconFiles/FLV`),
        "video/quicktime": () => import(`@/modules/Isp/assets/iconFiles/MOV`),
        "video/x-ms-wmv": () => import(`@/modules/Isp/assets/iconFiles/WMV`),
        "video/mp4": () => import(`@/modules/Isp/assets/iconFiles/MP4`),
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": () =>
          import(`@/modules/Isp/assets/iconFiles/DOCX`),
        "application/vnd.ms-excel": () => import(`@/modules/Isp/assets/iconFiles/XLS`),
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": () =>
          import(`@/modules/Isp/assets/iconFiles/XLSX`),
        "application/vnd.ms-powerpoint": () => import(`@/modules/Isp/assets/iconFiles/PPT`),
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": () =>
          import(`@/modules/Isp/assets/iconFiles/PPTX`),
        "video/x-msvideo": () => import(`@/modules/Isp/assets/iconFiles/AVI`),
        "video/3gpp": () => import(`@/modules/Isp/assets/iconFiles/3GP`),
        "video/mpeg": () => import(`@/modules/Isp/assets/iconFiles/MPEG`),
        "audio/mpeg": () => import(`@/modules/Isp/assets/iconFiles/MP3`)
      }
    };
  },
  mounted() {},
  methods: {
    handleDelete() {
      this.$emit("deleteFile", this.file.id);
    }
  },
  computed: {
    sizeFloor() {
      const size = this.file.size;
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "Кб";
      } else {
        return size + "Б";
      }
    },
    setFile() {
      return this.formats[this.file.extension] || this.formats[this.file.type]
        ? this.formats[this.file.extension] || this.formats[this.file.type]
        : () => import(`@/modules/Isp/assets/iconFiles/DEF`);
    },
    returnSrcFile() {
      return domainStorage + this.file.link;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.doc {
  padding: 2px 4px;
  border-radius: 2px;
  width: 168px;
  height: 32px;
  display: flex;
  margin-bottom: 16px;
  &_edit {
    background-color: $surface-selected-default;
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
  &__name {
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 16px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: $text-default;
  }
  &__link {
    display: flex;
    width: 100%;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
  }
  &__wrap-icon {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: auto;
  }
  &__size {
    font-size: 9px;
    line-height: 9px;
    font-weight: 400;
    color: $text-disabled;
  }
  i {
    margin-left: 4px;
    font-size: 16px;
    color: $icon-subdued;
    cursor: pointer;
  }
  /deep/ {
    label {
      cursor: pointer;
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
    }
    svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}
</style>
