<template>
  <li class="drop-down__item" @click="setQuarter(item)">
    <div class="checkbox_custom" :class="{ checkbox_custom_chosen: item.id === currentQuarter.id }">
      <i class="icon-check" v-if="item.id === currentQuarter.id"></i>
    </div>
    <span>{{ item.name }}</span>
  </li>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";

export default {
  name: "QuarterListDropDownItem",
  mixins: [],
  props: {
    item: {
      type: String,
      default: ""
    }
  },
  components: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", [
      "getProblemsList",
      "getMyIspProblemsListWithFilters",
      "getMyIspProblemsList",
      "setCurrentPeriodCriticalityWeight"
    ]),
    ...mapMutations("IspModule", ["SET_CURRENT_QUARTER"]),
    setQuarter(val) {
      this.SET_CURRENT_QUARTER(val);
      this.setCurrentPeriodCriticalityWeight();
      if (this.$route.name === "IspMy") {
        this.getMyIspProblemsList();
      } else {
        this.getProblemsList();
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentQuarter", "isMyIspFilters"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.drop-down {
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    background: transparent;
    border: none;
    outline: none;

    &:hover {
      background: $surface-selected-default;
      border-radius: 4px;
    }

    span {
      margin-right: 8px;
      max-width: 170px;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .checkbox_custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 2px solid $border-default;
    margin-right: 8px;
    flex-shrink: 0;
    border-radius: 50%;

    .icon-check {
      margin-right: 0;
      color: $surface-default;
      font-size: 14px;
    }

    &_chosen {
      background-color: $action-primary-accent;
      border: 2px solid $action-primary-accent;
    }
  }
}
</style>
