<template>
  <div class="confirmation-footer">
    <p class="confirmation-footer__title">{{ setTitle }}</p>
    <Button
      v-if="!setStatusCheck"
      :title="'Отправить'"
      :type="'white'"
      :small="true"
      @handleButton="$emit('confirm')"
    />
    <Button v-if="setStatusCheck" :title="'Отменить'" :small="true" @handleButton="rejectProblem" />
    <Button
      v-if="setStatusCheck"
      :title="'Принять'"
      :small="true"
      @handleButton="$emit('confirm')"
    />
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import { mapState } from "vuex";

export default {
  name: "ISPProblemConfirmationFooter",
  mixins: [],
  props: {},
  components: { Button },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    rejectProblem() {
      this.$emit("reject");
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "statusesList"]),
    setTitle() {
      return this.setStatusCheck
        ? "Согласовать проблему в текущем квартале?"
        : "Отправить проблему на согласование?";
    },
    setStatusCheck() {
      return this.currentInfoProblem.status
        ? this.currentInfoProblem.status.type === "check"
        : false;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.confirmation-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);

  &__title {
    margin: 0;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: $surface-default;
  }

  /deep/ {
    .aop-button {
      margin-left: 16px;

      &:hover {
        background: $surface-default;
        color: $text-highlight;
      }
    }
  }
}
</style>
