import * as types from "@/modules/Calendar/store/mutations/mutationTypes";

const mutations = {
  [types.GET_USERS_LIST](state, payload) {
    state.usersList = payload;
  },
  [types.SHOW_CONFIRM_MODALS](state, payload) {
    state.showConfirmModal = payload;
  },

  // Grid

  [types.SET_WEEKS](state, payload) {
    state.week = payload;
  },
  [types.SET_CALENDAR_PARAMS](state, payload) {
    state.calendarParams = payload;
  },
  [types.SET_WEEK_NUMBER](state, payload) {
    state.weekNumber = payload;
  },
  [types.SET_YEARS](state, payload) {
    state.year = payload;
  },
  [types.SHOW_WEEKENDS](state, payload) {
    state.isShowWeekends = payload;
  },
  [types.SET_WORKING_TIME](state, payload) {
    state.workingTime = payload;
  },
  [types.SHOW_PERSONAL_CALENDAR](state, payload) {
    state.isPerson = payload;
  },
  [types.SHOW_WORKING_CALENDAR](state, payload) {
    state.isWorking = payload;
  },
  [types.SHOW_WORKING_TIME_MODAL](state, payload) {
    state.showWorkingTimeModal = payload;
  },
  [types.SHOW_MOBILE_LOCATION_MODAL](state, payload) {
    state.showMobileLocationModal = payload;
  },
  [types.SHOW_MOBILE_MEMBERS_MODAL](state, payload) {
    state.showMobileMembersModal = payload;
  },
  [types.SHOW_MOBILE_EVENTS_MODAL](state, payload) {
    state.showMobileEventsModal = payload;
  },
  [types.SET_TIMES_LIST](state, payload) {
    state.timeList = payload;
  },

  //Events
  [types.SET_CURRENT_EVENT](state, payload) {
    state.currentEvent = payload;
  },
  [types.SET_RESPONSE_ID_CURRENT_EVENT](state, payload) {
    state.eventResponseId = payload;
  },
  [types.SHOW_EVENT_INFO_MODAL](state, payload) {
    state.showCalendarEventModal = payload;
  },
  [types.SHOW_CREATE_EVENT_MODAL](state, payload) {
    state.showCalendarCreateEventModal = payload;
  },
  [types.SHOW_CROSS_EVENT_MODAL](state, payload) {
    state.showCalendarCrossEventModal = payload;
  },
  [types.SHOW_REPEAT_CONFIRM_MODAL](state, payload) {
    state.showRepeatConfirmModal = payload;
  },
  [types.GET_CROSS_MEMBERS_USER](state, payload) {
    state.crossMembersUsers = payload;
  },
  [types.GET_CROSS_EVENTS](state, payload) {
    state.crossEvents = payload;
  },
  [types.SET_EVENT_PARAMS](state, payload) {
    state.eventParams = payload;
  },
  [types.SET_CROSS_EVENT_RESPONSE](state, payload) {
    state.crossResponse = payload;
  },
  [types.SET_TIME_START](state, payload) {
    state.timeDefaultStart = payload;
  },
  [types.SET_TIME_END](state, payload) {
    state.timeDefaultEnd = payload;
  },
  [types.SET_DATE_EVENT](state, payload) {
    state.dateEventDefault = payload;
  },
  [types.SET_LOADER_CREATE_EVENT](state, payload) {
    state.loaderEvent = payload;
  },
  [types.SET_LOADER_REPEAT_EVENT](state, payload) {
    state.loaderRepeat = payload;
  },
  [types.SET_DATA_INFO_MEMBER_USER](state, payload) {
    state.dataInfoMemberUser = payload;
  },

  // Location
  [types.GET_LOCATION_LIST](state, payload) {
    state.locationList = payload;
  },
  [types.SHOW_LOCATION_MODAL](state, payload) {
    state.showLocationModal = payload;
  },
  [types.SET_EDIT_LOCATION](state, payload) {
    state.location = payload;
  }
};

export default mutations;
