<template>
  <div class="time__widgets-mobile" :class="{ 'time__widgets-mobile_admin': isAdmin }">
    <Widget
      v-show="valueFilter === 'Сотрудник'"
      v-if="!isNewTable"
      type="select-new"
      :entity="'User'"
      :actionEntity="'getItems'"
      :itemName="'full_name'"
      :keyValue="'FilterUserInTimeTable'"
      :label="'Сотрудник'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterUser"
    />
    <ChosenFilter
      v-show="valueFilter === 'Сотрудник'"
      v-if="isNewTable"
      icon="user"
      :search="true"
      placeholder="Сотрудники"
      :multiple="true"
      :chosenList="usersList"
      v-model="currentResponsible"
      :allItem="true"
      @input="handleUser"
    />
    <Widget
      v-show="valueFilter === 'Отдел'"
      type="select-new"
      :entity="'Department'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterDepartmentsInTimeTable'"
      :label="'Отдел'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterDepartments"
    />
    <Widget
      v-show="valueFilter === 'Должность'"
      type="select-new"
      :entity="'Role'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterRolesInATimeTable'"
      :label="'Должность'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterRoles"
    />
    <div class="time__actions actions" v-click-outside="hideModalList">
      <button
        class="actions__toggle"
        type="button"
        @click="openList"
        :class="{ actions__toggle_active: showFilterList }"
      >
        <i class="icon-dots-vertical"></i>
      </button>
      <div class="actions__settings" v-show="showFilterList">
        <FiltersList
          :filtersList="filtersList"
          :valueFilter="valueFilter"
          @choseFilter="choseFilter"
        />
        <div class="actions__switches">
          <MonthPickerTable
            :currentMonth="setCurrentMonth"
            :filterMonth="filterMonth"
            @changeMonthYear="changeMonthYear"
            :maxDate="setMaxDate"
            :dateMax="setMaxDate"
            :showIcon="true"
          />
        </div>
        <div class="actions__buttons" v-if="isAdmin">
          <ModalButton
            v-if="isAdmin"
            :title="'Выгрузить зп'"
            :icon="'download-secondary'"
            :type="'outline'"
            :loader="loaderTimeReportByRatesExport"
            @handleButton="downloadSalary"
          />
          <ModalButton
            v-if="isAdmin"
            :title="'Выгрузить по проектам'"
            :icon="'download-secondary'"
            :type="'outline'"
            :loader="loaderTimeReportByProjectsExport || loaderProject"
            @handleButton="downloadByProject"
          />
        </div>
      </div>
    </div>
    <SalaryPicker v-if="isShowPicker" :date="getNewRecord" :loaderSalary="loaderSalary" />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Widget from "@/components/Widgets/Widget";
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { mapActions, mapState } from "vuex";
import FiltersList from "@/modules/Tables/components/TablesStatistics/Time/TimeWidgetsMobile/Filters/FiltersList";
import { format, parseISO } from "date-fns";
import AbsenceModule, { AbsenceMutationTypes } from "@/modules/Absence/store/AbsenceModule";
import moment from "moment";
import SalaryPicker from "@/modules/Absence/component/AbsenceModal/SalaryPicker";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";

export default {
  name: "TimeWidgetsMobile",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isNewTable: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterDepartments: {
      type: Array,
      default: () => {
        return [];
      }
    },
    usersList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterRoles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterMonth: {
      type: String,
      default: ""
    }
  },
  components: { ChosenFilter, SalaryPicker, FiltersList, Widget, ModalButton, MonthPickerTable },
  data() {
    return {
      currentResponsible: [],
      showFilterList: false,
      filtersList: ["Сотрудник", "Отдел", "Должность"],
      valueFilter: "Сотрудник"
    };
  },
  mounted() {
    this.currentResponsible = this.filterUser;
  },
  methods: {
    ...mapActions("TablesModules", ["downloadReportsTime"]),
    handleUser(val) {
      this.currentResponsible = val;
      this.$emit("selectUsers", this.currentResponsible);
    },
    openList() {
      this.showFilterList = !this.showFilterList;
    },
    hideModalList() {
      this.showFilterList = false;
    },
    choseFilter(item) {
      this.valueFilter = item;
    },
    changeMonthYear(val) {
      this.$emit("changeMonthYear", val);
    },
    downloadSalary() {
      if (this.isNewService) {
        AbsenceModule[AbsenceMutationTypes.TOGGLE_MODAL_PICKER](true);
      } else {
        this.downloadReportsTime({ value: "TimeReportByRatesExport", month: this.getNewRecord });
      }
    },
    downloadByProject() {
      if (this.isNewService) {
        AbsenceModule.exportByProject({ month: this.getNewRecord });
      } else {
        this.downloadReportsTime({ value: "TimeReportByProjectsExport", month: this.getNewRecord });
      }
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "isActiveEducation",
      "loaderTimeReportByRatesExport",
      "loaderTimeReportByProjectsExport"
    ]),
    isShowPicker() {
      return AbsenceModule.isShowPicker;
    },
    getNewRecord() {
      return this.filterMonth ? this.filterMonth : this.$store.state.newRecord.month;
    },
    isNewService() {
      return moment(`${this.getNewRecord}-01`) > moment("2022-03-01");
    },
    loaderProject() {
      return AbsenceModule.loaderProject;
    },
    loaderSalary() {
      return AbsenceModule.loaderSalary;
    },
    setCurrentMonth() {
      return format(parseISO(this.filterMonth), "M") - 1;
    },
    setMaxDate() {
      return `${new Date().getFullYear() + 1}-${new Date().getMonth() + 1}`;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.time {
  &__widgets-mobile {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    @media (max-height: $sm) {
      padding: 8px 24px;
    }

    /deep/ {
      .select-bg {
        width: 250px;
        margin-right: 8px;

        .select-content-bg {
          height: 28px;

          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
        }
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      .month-picker-background #month-picker .month_picker_container {
        @media (max-height: $xs) {
          right: -250px;
          top: -20px;
        }

        @media (max-height: $xxs) {
          right: -250px;
          top: -60px;
        }
      }

      .modal-items {
        background: #fafafe;
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }
    }
  }

  .actions {
    position: relative;
    margin-left: 0;

    &__toggle {
      width: 32px;
      height: 32px;
      background: $action-secondary;
      border-radius: 4px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: $icon-subdued;
        font-size: 24px;
        text-align: center;
      }

      &:hover {
        background: $action-secondary-hovered;

        i {
          color: $icon-subdued;
        }
      }

      &:active,
      &:focus {
        background: $action-secondary-pressed;
      }
    }

    &__settings {
      position: absolute;
      z-index: 15;
      top: 34px;
      right: 0;
      padding: 8px 0;
      list-style: none;
      min-width: 180px;
      width: max-content;
      background-color: $surface-default;
      cursor: pointer;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 14px;

      @media (max-height: 812px) {
        top: 0;
        right: 34px;
      }

      @media (max-height: $xs) {
        top: -60px;
        right: 34px;
      }
    }

    &__switches {
      padding: 8px 16px;
      border-top: 1px solid $border-disabled;

      /deep/ {
        .switch-widget__label {
          margin: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
        }

        .switch-widget__indicator {
          width: 18px;
          height: 10px;
          background-color: $icon-disabled;
          margin-right: 8px;

          &::before {
            top: 2px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }

        .switch-widget__input:checked + .switch-widget__indicator {
          background-color: $action-primary;

          &:before {
            top: 2px;
            left: 10px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }
      }
    }

    &__buttons {
      padding: 8px;
      border-top: 1px solid $border-disabled;
    }
  }
}
</style>
