























import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import Input from "@/modules/UiKit/components/Inputs/Input";

@Component({
  components: { Button, Input }
})
export default class AddProject extends Vue {
  @Prop({ default: false, type: Boolean }) isCentre!: boolean;
  @Prop({ default: "", type: String }) title!: string;
  @Prop({ default: "", type: String }) placeholder!: string;
  value = "";

  handleAdd() {
    this.$emit("addProject", this.value);
  }

  handleClose() {
    this.$emit("handleClose", false);
  }
}
