<template>
  <section class="profile">
    <transition name="slide">
      <TimeTrackerWindow v-if="isShowTimeTrackerWindow" />
    </transition>
    <h1 class="profile__title visually-hidden">Личный кабинет</h1>
    <TabsRouter :tabs="tabsList" :count="vacationPendingRequestCount" />
    <router-view />
  </section>
</template>

<script>
import TabsRouter from "@/components/Widgets/TabsWidget/TabsRouter";
import httpClient from "@/api/client/httpClient";
import TimeTrackerWindow from "@/modules/TimeTracker/component/TimeTrackerWindow/TimeTrackerWindow";
import TimeTracker from "@/modules/TimeTracker/store";

export default {
  name: "Profile",
  mixins: [],
  props: [],
  components: { TimeTrackerWindow, TabsRouter },
  data() {
    return {
      vacationPendingRequestCount: 0,
      tabsList: [
        {
          label: "Профиль сотрудника",
          path: "Info"
        },
        {
          label: "Должностные папки",
          path: "Folders"
        },
        {
          label: "Образование",
          path: "Education"
        },
        {
          label: "Путь в компании",
          path: "History"
        },
        {
          label: "Запросы",
          path: "VacationRequests"
        }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off("updateMyConfirmation");
  },
  mounted() {
    if (this.$route.params.user_id) {
      this.getVacationWithMyConfirmation(this.$route.params.user_id);
    } else {
      this.getVacationWithMyConfirmation(this.getDataInfoUser.id);
    }
    this.$root.$on("updateMyConfirmation", () => {
      if (this.$route.params.user_id) {
        this.getVacationWithMyConfirmation(this.$route.params.user_id);
      } else {
        this.getVacationWithMyConfirmation(this.getDataInfoUser.id);
      }
    });
  },
  methods: {
    getVacationWithMyConfirmation(id) {
      httpClient({
        method: "GET",
        url: `/Confirmation/actionGetItems`,
        params: {
          _count: 300,
          user_id: id,
          is_current: true,
          _with: JSON.stringify(["confirmable.confirmations"]),
          is_null: JSON.stringify(["is_checked"])
        }
      }).then((response) => {
        this.vacationPendingRequestCount = response.data.data.data.filter(
          (item) => item.confirmable
        ).length;
      });
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isShowTimeTrackerWindow() {
      return TimeTracker.isShowTimeTrackerWindow;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}

.profile {
  font-family: "SF", sans-serif !important;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
  padding: 24px 40px;
  background: $background-gradient;

  @media screen and (max-width: $xxxl) {
    padding: 24px 24px;
  }
  @media screen and (max-width: $md) {
    padding: 24px 16px;
  }
  @media screen and (max-width: $sm) {
    padding: 16px;
  }

  .tabs {
    margin-bottom: 24px;

    @media screen and (max-width: $sm) {
      margin-bottom: 8px;
    }
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
</style>
