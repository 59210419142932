import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import { IAccessUsersParams } from "@/modules/TaskManager/Model/PlanningSchedule/interface/interface";

type defaultParamsType = {
  page: number;
  per_page: number;
};

type createPlanDataType = {
  name: string;
};
type editPlanDataType = {
  id: number;
  name?: string;
  duration?: number;
};
type stepParamsType = {
  plan_id: number;
  version_id?: number;
};
type stepLinksParamsType = {
  plan_id: number;
  version_id?: number;
};
type createStepDataType = {
  plan_id: number;
  name: string;
  duration?: number;
};
type editStepType = {
  id: number;
  name?: string;
  duration?: number;
};

type createStepLinkDataType = {
  plan_id: number;
  step_from: number | null;
  step_to: number | null;
};
export type accessUsersBodyType = {
  plan_id: number;
  add?: Array<number>;
  delete?: Array<number>;
};

export type planParamsType = {
  plan_id?: number | null;
  parent_id?: number | null;
  id?: number | null;
  page: number;
  per_page: number;
};
type getBasePlanAllType = {
  plan_id: number;
  per_page?: number;
  page?: number;
};

type createBasePlanType = {
  plan_id: number;
  name: string;
};
type updateBasePlanType = {
  id: number;
  name: string;
};
export type commentParamsType = {
  id: number | null;
  text: string;
  parent_id?: number | null;
  author?: number;
  comment_id?: number;
};

class PlanningScheduleService extends Service {
  getPlanAll(params: defaultParamsType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_plan_all`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }
  getPlan(planId: number): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_plan`,
      params: {
        id: planId
      }
    }).then((response) => {
      return response.data;
    });
  }
  createPlan(data: createPlanDataType): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/create_plan`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  deletePlan(planId: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/project_networks/delete_plan`,
      data: {
        id: planId
      }
    }).then((response) => {
      return response.data;
    });
  }
  editPlan(data: editPlanDataType): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/project_networks/update_plan`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  getSteps(params: stepParamsType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_steps`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  createStep(data: createStepDataType): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/create_step`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  updateStep(data: editStepType): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/project_networks/update_step`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  deleteStep(stepId: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/project_networks/delete_step`,
      data: {
        id: stepId
      }
    }).then((response) => {
      return response.data;
    });
  }
  getStepLinks(params: stepLinksParamsType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_step_links`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  createStepLink(data: createStepLinkDataType): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/create_step_link`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  deleteStepLink(linkId: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/project_networks/delete_step_link`,
      data: {
        id: linkId
      }
    }).then((response) => {
      return response.data;
    });
  }
  calculatePlan(planId: number): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/calculated`,
      data: {
        plan_id: planId
      }
    }).then((response) => {
      return response.data;
    });
  }
  getAccessUsers(params: planParamsType) {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_users_access`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  createAccessUsers(body: accessUsersBodyType) {
    return httpClient({
      method: "POST",
      url: `task_manager/project_networks/user_access`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }
  getBasePlanAll(params: getBasePlanAllType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_version_all`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  getBasePlan(id: number): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_version`,
      params: {
        id
      }
    }).then((response) => {
      return response.data;
    });
  }
  createBasePlan(data: createBasePlanType): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/save_version`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  updateBasePlan(data: updateBasePlanType): Response<any> {
    return httpClient({
      method: "PUT",
      url: `/task_manager/project_networks/update_version`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  deleteBasePlan(basePlanId: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/project_networks/delete_version`,
      data: {
        id: basePlanId
      }
    }).then((response) => {
      return response.data;
    });
  }
  getPlanComments(params: planParamsType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/project_networks/get_comments`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  createPlanComment(params: commentParamsType): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/project_networks/create_comment`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  updatePlanComment(params: commentParamsType): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/project_networks/update_comment`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  deletePlanComment(params: { id: number; comment_id: number }): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/project_networks/delete_comment`,
      params
    }).then((response) => {
      return response.data;
    });
  }
}

export default new PlanningScheduleService();
