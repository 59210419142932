














































import { Component, Prop, Vue } from "vue-property-decorator";
import WorkingCalendarDay from "@/modules/TimeTracker/component/WorkingCalendar/WorkingCalendarDay.vue";

@Component({
  components: { WorkingCalendarDay }
})
export default class MainCalendar extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  attributes!: Array<any>;
  @Prop({
    default: false,
    type: Boolean
  })
  isAdmin!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  showTime!: boolean;
  @Prop({
    default: null,
    type: String
  })
  editDay!: string | null;
  @Prop({
    default: null,
    type: String
  })
  minDate!: string | null;
  @Prop({
    default: null,
    type: String
  })
  maxDate!: string | null;

  masks = {
    weekdays: "WWW"
  };

  setEditDate(data) {
    this.$emit("setEditDate", data);
  }

  upperFirstChar(string) {
    let temp = string[0].toUpperCase();
    return (string = temp + string.slice(1));
  }

  getTimeInfo(data) {
    this.$emit("getTimeInfo", data);
  }

  updateTime(data) {
    this.$emit("updateTime", data);
  }

  get isEdit() {
    return (day) => this.editDay === day;
  }
}
