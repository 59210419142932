import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { EventBus } from "@/main";
import FileService from "@/aopV2/helpers/File/FileUpload";
import centrifuge from "@/centrifuge";
import { Subscription } from "centrifuge";

interface FileType extends File {
  id?: number;
  link?: string;
  label?: string;
}

interface uploadFile {
  id: number;
  size: number;
  type: string;
  lastModified: number;
  link?: string;
  label?: string;
  path?: string;
}

@Component
export default class uploadFilesMixin extends Vue {
  @Prop({ type: Array, default: () => [] }) editFiles!: Array<any>;
  @Prop({ type: Number, default: -1 }) limit!: number;
  pullName: string = "";
  loading: boolean = false;
  index: number = 0;
  formats: string[] = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/x-flv",
    "video/quicktime",
    "video/x-ms-wmv",
    "video/mp4",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "video/x-msvideo",
    "video/3gpp",
    "video/mpeg",
    "audio/mpeg"
  ];
  files: FileType[] = [];
  fileUploader: FileType[] = [];
  oldEditFiles: uploadFile[] = [];
  uploadedFiles: uploadFile[] = [];
  module: string = "Calendar";
  poolName: string | number = 0;
  Service: FileService | null = null;
  base64: string = "";
  chunksArr: RegExpMatchArray | null = null;
  centrifuge: Subscription | null = null;

  mounted() {
    this.Service = new FileService(this.module);
    this.uploadedFiles = this.editFiles;
  }

  @Watch("editFiles")
  onEditFiles() {
    this.uploadedFiles = this.editFiles;
    this.index = this.editFiles.length;
  }

  changeLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  get isUploadDisabled() {
    return this.uploadedFiles.length === this.limit;
  }

  uploadFile() {
    const input = this.$refs.fileUploader as HTMLInputElement;
    let value = [...(input.files as FileList)];
    let size = 0;
    if (this.limit !== -1 && value.length + this.uploadedFiles.length > this.limit) {
      const countSplice = this.limit - this.uploadedFiles.length;
      value = value.slice(0, countSplice > 0 ? countSplice : 0);
      EventBus.$emit("showNotification", {
        type: "warning",
        timeout: 5000,
        label: `Максимальное количество файлов ${this.limit}`
      });
    }
    this.files = value.filter((item) => {
      if (item.size > 5242880) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Размер файла ${item.name} превышает 5 мб`
        });
        return false;
      }
      size += item.size;
      if (this.formats.includes(item.type)) {
        return true;
      } else {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Формат файла ${item.type} не поддерживается`
        });
        this.changeLoading(false);
        return false;
      }
    });
    if (size > 5242880) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Общий размер файлов превышает 5 мб`
      });
      this.changeLoading(false);
    }
    this.uploadHandler(this.files).catch((err) => console.error(err));
  }

  async uploadHandler(files: FileType[]) {
    this.fileUploader = files;
    this.oldEditFiles = [];
    // @ts-ignore
    this.uploadedFiles = this.uploadedFiles.concat(this.files);
    if (this.fileUploader.length) {
      this.changeLoading(true);
      await this.createChunkPool(this.fileUploader);
    }
  }

  async createChunkPool(files: FileType[]) {
    if (!files.length) {
      this.filesUploaded();
      return null;
    }
    const file = files[0];
    try {
      this.poolName = await this.Service?.createChunkPool(file.name);
      this.base64 = await this.getBase64(file);
      const string64 = this.base64.replace(
        this.base64.substring(0, this.base64.search(",") + 1),
        ""
      );
      if (file.size < 50000000) {
        this.chunksArr = string64.match(/.{1,500000}/g);
      } else {
        this.chunksArr = string64.match(/.{1,5000000}/g);
      }
      const promise = this.chunksArr!.map((item, i) => {
        const nulls =
          Array.from(this.chunksArr!.length.toString())
            .map(() => "0")
            .join("") + "0";
        const chunkName = nulls.substr(0, nulls.length - i.toString().length) + i;
        return this.Service?.uploadChunkPool({
          pool_name: this.poolName,
          base_64_chunk: item,
          chunk_name: chunkName
        });
      });
      Promise.all(promise).then((resp) => {
        if (resp.every((item) => item === true)) {
          this.collectFileFromPool(file);
        }
      });
    } catch (err) {
      this.changeLoading(false);
    }
  }

  filesUploaded() {
    this.changeLoading(false);
    this.handleEditFiles({
      ids: [...this.uploadedFiles.map((item) => item.id)] as number[],
      files: [...this.uploadedFiles]
    });
  }

  handleEditFiles(data: { ids: number[]; files: uploadFile[] }) {
    this.$emit("uploadFile", data.ids);
  }

  getBase64(file: FileType): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async collectFileFromPool(file: FileType) {
    const channel = await this.Service?.collectFileFromPool(this.poolName);
    this.centrifuge = centrifuge.subscribe(channel, (message) => {
      if (message.data.code === 422) {
        this.loading = false;
        this.$emit("toggleFormLoader", false);
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выбранный Вами файл не подходит по расширению или размеру!`
        });
        return false;
      }
      this.saveId(+message.data.message, file);
    });
    const history = await this.centrifuge.history();
    history.publications.forEach((item) => {
      if (item.data.type === "collectChunkPollFinish") {
        this.saveId(+item.data.message, file);
      }
      if (item.data.type === "collectChunkPollError") {
        this.errorPoll(file);
      }
    });
  }

  errorPoll(file) {
    this.uploadedFiles = this.uploadedFiles.filter(
      (item) => item.lastModified !== file.lastModified
    );
    EventBus.$emit("showNotification", {
      type: "error",
      timeout: 5000,
      label: `При загрузке файла ${
        file.name || file.label
      } произошла ошибка, попробуйте снова или выберите другой файл`
    });
  }

  saveId(id: number, file: FileType) {
    const uploadFile: uploadFile = {
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      id,
      path: this.base64,
      label: file.label || file.name
    };
    const index =
      this.uploadedFiles.length > 0 ? this.uploadedFiles.length - this.fileUploader.length : 0;
    Vue.set(this.uploadedFiles, index, uploadFile);
    this.fileUploader.splice(0, 1);
    this.createChunkPool(this.fileUploader).catch((err) => console.error(err));
    this.centrifuge!.unsubscribe();
  }
}
