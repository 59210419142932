const defaultState = {
  showChat: false,
  memberLoader: false,
  showMenu: false,
  showFileModal: false,
  editMode: false,
  replyMode: false,
  pinInChat: false,
  threadInChat: false,
  sendMode: false,
  showModal: false,
  threadMode: false,
  pickMode: false,
  showPin: false,
  modalSetting: null,
  showCreateEditChannel: false,
  showCatalog: false,
  showSendModal: false,
  showFullScreenImage: false,
  showNotification: false,
  showSetting: false,
  isLeft: false,
  editorValue: "",
  search: "",
  currentChat: null,
  editCurrentChat: null,
  threadMainMessage: null,
  catalogChannels: {
    count: 0,
    items: []
  },
  catalogMembers: [],
  searchMessages: [],
  sortMessages: [],
  pinnedChats: [],
  catalogLoader: false,
  searchMessageMode: false,
  showNotificationModal: false,
  showReaction: false,
  notificationData: {},
  files: [],
  messages: [],
  threads: [],
  threadsMessages: [],
  pickedMessage: [],
  selectUser: [],
  modalX: 0,
  modalY: 0,
  currentMessage: null,
  lastThreadMessage: null,
  lastSelfMessage: null,
  selectedMessage: null,
  selectedSettingsEditor: "Enter",
  textTooltip: "",
  isGroupChat: false,
  joinMode: false,
  showComment: false,
  scrollNewMessage: false,
  searchMode: false,
  isShowTooltip: false,
  isNotification: true,
  image: null,
  channelItem: null,
  scrollContainer: null,
  timerUpdate: null,
  timerId: null,
  container: null,
  pinnedCount: 0,
  countUnreadMessage: 0,
  attachedCount: 0,
  reactionId: 0,
  showReactionList: false,
  reactionList: [],
  pinned_messages: [],
  userListResult: [],
  optionList: [],
  chatList: [],
  users: [],
  usersReaction: [],
  chats: [],
  mainList: [],
  chatUsers: [],
  unreadMessages: [],
  personChat: [],
  person: [],
  closeChat: [],
  openChat: [],
  userList: [
    {
      nickname: "@all"
    }
  ],
  selectGroup: [],
  attachedModalValue: null,
  notificationAction: null,
  attachedFiles: [],
  videoPlayerData: null
};

export default defaultState;
