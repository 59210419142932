const mutationsSearch = {
  TOGGLE_SHOW_CURTAIN_SEARCH(state, payload) {
    state.showCurtainSearch = payload;
  },
  SET_SEARCH_RESULTS(state, payload) {
    state.searchResults = payload;
  },
  TOGGLE_LOADER_SEARCH(state, payload) {
    state.loaderSearch = payload;
  }
};

export default mutationsSearch;
