







import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import moment from "moment";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component
export default class CalendarTimeLine extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  day!: string;

  nowTime = moment().format("LT");
  showTimeline: boolean = false;
  timeoutId: number = 0;
  timeline: any = null;
  columnHeight: number = 0;
  hour: string = "";
  timeCell: any = null;

  mounted() {
    this.showTimeline = false;
    this.$nextTick(() => {
      this.setTimeLinePosition()
    })
  }

  /**
   * set line position percent.
   * @return {string} - set the line position in hour
   */

  setTimeLinePosition() {
    if (this.isToday) {
      this.showTimeline = true;
      clearTimeout(this.timeoutId);
      this.nowTime = moment().format("HH:mm");
      const [hour, minute] = this.nowTime.split(":");
      this.hour = hour;
      this.$nextTick(() => {
        const name = `${hour}:00`;
        this.timeCell = document.querySelector(`[data-start="${name}"]`);
        if (this.timeCell) {
          this.timeline = this.$refs.timeline;
          this.columnHeight = this.timeCell.clientHeight;
          let line = this.linePosition(+minute);
          if (this.timeline) this.timeline.style.top = `${
            this.timeCell?.offsetTop + line * this.columnHeight
          }px`;
          this.timeoutId = setTimeout(this.setTimeLinePosition, 60000);
        } else {
          this.showTimeline = false;
        }
      })
    }
  }

  /**
   * Line position percent.
   * @param {number} minute -  current minute.
   * @return {number} - line position in hour
   */
  linePosition(minute = 0) {
    return minute / 60;
  }

  get isToday() {
    return moment(this.day).format("L") === moment().format("L");
  }

  get start() {
    return CalendarModule2.gridStartTime;
  }

  get stop() {
    return CalendarModule2.gridStopTime;
  }

  @Watch("start") changeStartCalendarTime() {
    this.setTimeLinePosition();
  }

  @Watch("stop") changeStopCalendarTime() {
    this.setTimeLinePosition();
  }
}
