import sanitizeHtml from "sanitize-html";
import { Component, Vue } from "vue-property-decorator";

@Component
export class UrlClassMixin extends Vue {
  userMatch = /(^|\s)(@[^ ]*)/gi;
  groupMatch = /(^|\s)(#[^ ]*)/gi;
  linkMatch =
    /https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}(\.[a-zA-Zа-яА-Я0-9()]{1,6})?\b([-a-zA-Zа-яА-Я0-9()!@:%_+.~#?&//=]*)/gi;

  setURL(
    text,
    urlRegex = /https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}(\.[a-zA-Zа-яА-Я0-9()]{1,6})?\b([-a-zA-Zа-яА-Я0-9()!@:%_+.~#?&//=]*)/gi,
    type = "link"
  ) {
    if (type === "link") {
      return sanitizeHtml(
        text.replace(urlRegex, function (url) {
          return `<a href="${url}" target="_blank" class="${type}">${url}</a>`;
        })
      );
    } else {
      return text.replace(urlRegex, function (url) {
        return `<a href="javascript:void(0)" ref="${type}" data-link="${url}" class="${type}">${url}</a>`;
      });
    }
  }

  setTextUrl(
    text,
    matches = [
      { reg: this.linkMatch, type: "link" },
      { reg: this.userMatch, type: "userTagChat" },
      { reg: this.groupMatch, type: "group" }
    ]
  ) {
    let textUrl = text;
    matches.forEach((reg) => {
      textUrl = this.setURL(textUrl, reg.reg, reg.type);
    });
    return textUrl;
  }
}
