import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      timerId: null
    };
  },
  methods: {
    ...mapActions("MessengerModule", ["messageGetItems", "setMessageFromSearch"]),
    scroll($message, container) {
      const $parentMessage = $message.parentNode;
      $parentMessage.classList.add("active");
      this.timerId = setTimeout(() => {
        $parentMessage.classList.remove("active");
      }, 5000);
      this.$scrollTo($message, 500, {
        container: container,
        easing: "ease-in",
        lazy: true,
        offset: -100,
        force: true,
        cancelable: true,
        x: false,
        y: true
      });
    },
    goToReplyMessage(id, container) {
      clearTimeout(this.timerId);
      const $message = document.querySelector(`#message_${id}`);
      if ($message) {
        this.scroll($message, container);
      } else {
        this.messageGetItems({
          id: this.currentChat.channel.id,
          direction: "around",
          last_message_id: id
        }).then((resp) => {
          this.setMessageFromSearch(resp.data.data.data.messages);
          this.$nextTick(() => {
            const $message = document.querySelector(`#message_${id}`);
            this.scroll($message, container);
          });
        });
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", ["currentChat"])
  }
};
