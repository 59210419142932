<template>
  <div class="plan-users" v-click-outside="closeUsersModal">
    <ul class="plan-users__list" v-if="!loader">
      <li v-for="id in clipMembers" :key="id">
        <router-link
          class="modal__user user"
          :to="{ name: 'UserInfo', params: { user_id: id } }"
          :target="'_blank'"
        >
          <UserCard :item="getCurrentMember(id)" :isRole="false" :isName="false" />
        </router-link>
      </li>
      <li @click="toggleUsersModal" v-if="users.length > 4">
        +{{ users.length - clipMembers.length }}
      </li>
    </ul>
    <div class="plan-users__modal modal" v-if="showUsersModal && isAuthor">
      <div class="modal__header">Просмотр участников</div>
      <ul class="modal__list">
        <li class="modal__item" v-for="id in users" :key="id">
          <UserCard :item="getCurrentMember(id)" :isRole="false" :isName="true" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard";

export default {
  name: "PlanUsers",
  mixins: [],
  props: {
    users: {
      type: Array,
      default: () => []
    },
    loader: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserCard
  },
  data() {
    return {
      showUsersModal: false
    };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    toggleUsersModal() {
      this.showUsersModal = !this.showUsersModal;
    },
    closeUsersModal() {
      this.showUsersModal = false;
    },
    getCurrentMember(id) {
      return this.allUsers.length ? this.allUsers.find((item) => item.id === id) : null;
    }
  },
  computed: {
    allUsers() {
      return this.$store.getters["UsersModule/userList"]
        .filedList(["full_name", "id", "avatar", "is_active"])
        .filter((item) => item.is_active);
    },
    clipMembers() {
      return this.users.length ? this.users.slice(0, 4) : [];
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.plan-users {
  position: relative;
  &__list {
    display: flex;
    li {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 2px solid #ffffff;
      &:not(:first-child) {
        margin-left: -10px;
      }
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eae8f0;
        font-size: 10px;
        cursor: pointer;
      }
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
  .modal {
    border-radius: 4px;
    width: 250px;
    height: fit-content;
    max-height: 300px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    background-color: #ffffff;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    padding: 16px 8px 16px 16px;
    overflow: hidden;
    z-index: 100;
    &__header {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 16px;
    }
    &__list {
      max-height: 240px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 8px;
    }
    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 8px;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #343748;
      }
    }
  }
}
</style>
