


























import { Component, Mixins, Prop } from "vue-property-decorator";
import fileVisualGeneratorMixin from "@/aopV2/helpers/File/fileVisualGenerator.mixin";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import MainLoader from "@/aopV2/components/UIComponents/Loader/MainLoader.vue";
import { domainS3 } from "@/globalVariables";

@Component({
  components: { MainLoader, ButtonIcon }
})
export default class FileItem extends Mixins(fileVisualGeneratorMixin) {
  @Prop({ type: Boolean, default: true }) isEdit!: Boolean;

  deleteFile() {
    this.$emit("deleteFile", this.file);
  }

  get returnSrcFile() {
    const src = this.file.link || this.file.path;
    if (src && src.includes("base64")) {
      return src;
    } else return `${domainS3}/${src}`;
  }

  get isGlobalMobileMode() {
    return this.$store.getters.getGlobalMobileMode;
  }

  get isLoad() {
    return !!this.file.id;
  }

  get title() {
    return this.file.name
      ? this.cutterText(this.file.name, 10)
      : this.cutterText(this.file.label, 10);
  }
}
