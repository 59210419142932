export const generateTimeArr = (interval: number, maxCount: number): string[] => {
  const arr: string[] = [];
  let i = 0;
  let temp = 0;
  for (i; i <= maxCount; i++) {
    if (String(i).length === 1) {
      arr.push("0" + i + ":00");
      for (let k = 0; k < Math.floor(60 / interval); k++) {
        temp += interval;
        if (temp === 60) {
          break;
        }
        arr.push("0" + i + ":" + temp);
      }
      temp = 0;
      continue;
    }
    arr.push(i + ":00");
    for (let k = 0; k < Math.floor(60 / interval); k++) {
      temp += interval;
      if (temp === 60) {
        break;
      }
      arr.push(i + ":" + temp);
    }
    temp = 0;
  }
  return arr;
};
