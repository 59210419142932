<template>
  <div class="modal-links">
    <div class="modal-links__wrap">
      <div class="modal-links__header">
        <h2>Установить связь</h2>
      </div>
      <div class="modal-links__table table">
        <div class="table__fields fields">
          <div class="fields__number cell-modal">Номер</div>
          <div class="fields__action cell-modal">Действие</div>
        </div>
        <div class="table__body">
          <LinksTableRow
            v-for="step in sortedSelectedStepList"
            :key="step.id"
            :stepLinksItems="stepLinksItems"
            :selectedStep="step"
            @deleteSelectedStep="deleteSelectedStep"
          />
          <div class="table__row row row_create" v-if="stepLinksItems.length">
            <div class="row__number cell-modal"></div>
            <div class="row__action cell-modal">
              <Select
                :items="stepLinksItems"
                v-model="newSelectedStep"
                :showAvatar="false"
                :multiple="false"
                :placeholder="'Выберите'"
                :removeCross="false"
                :showButtonPanel="false"
                @input="addNewSelectedStep"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-links__footer">
        <Button :type="'cancel'" :title="'Отменить'" @handleButton="cancel" />
        <Button :title="'Установить'" @handleButton="setLinks" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import Select from "@/modules/UiKit/components/Select/Select";
import LinksTableRow from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/StepLinks/ModalLinks/LinksTableRow";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
export default {
  name: "ModalLinks",
  mixins: [],
  props: {
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    stepLinksList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    linksRelativeList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { LinksTableRow, Select, Button },
  data() {
    return {
      selectedStepList: [],
      newSelectedStep: []
    };
  },
  mounted() {
    this.selectedStepList = JSON.parse(JSON.stringify(this.linksRelativeList));
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    setLinks() {
      const newStepList = this.selectedStepList.filter(
        (item) => !this.linksRelativeList.some((step) => step.id === item.id)
      );
      const stepToDeleteList = this.linksRelativeList.filter(
        (item) => !this.selectedStepList.some((step) => step.id === item.id)
      );
      const newStepLinkList = [];
      newStepList.forEach((step) => {
        newStepLinkList.push({
          plan_id: this.plan.id,
          step_from: step.id,
          step_to: this.step.id
        });
      });
      const stepLinkToDeleteList = [];
      stepToDeleteList.forEach((step) => {
        const stepLink = this.stepLinksList.find(
          (stepLink) => stepLink.step_from === step.id && stepLink.step_to === this.step.id
        );
        if (stepLink) stepLinkToDeleteList.push(stepLink.id);
      });

      PlanningScheduleModule.setStepLinksAction({ newStepLinkList, stepLinkToDeleteList });
      this.$emit("setLinks");
    },
    addNewSelectedStep() {
      this.selectedStepList.push(this.newSelectedStep[0]);
      this.newSelectedStep = [];
    },
    deleteSelectedStep(id) {
      this.selectedStepList = this.selectedStepList.filter((item) => item.id !== id);
    }
  },
  computed: {
    stepLinksItems() {
      return this.stepList.filter(
        (item) =>
          item.relative_number < this.step.relative_number &&
          !this.selectedStepList.some((step) => step.id === item.id)
      );
    },
    sortedSelectedStepList() {
      const sorted = this.selectedStepList;
      return sorted.sort((a, b) => a.relative_number - b.relative_number);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal-links {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &__header {
    padding: 32px 24px 48px;
  }
  h2 {
    padding: 0;
    margin: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: #343748;
  }
  &__wrap {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 577px;
    width: 100%;
    max-height: 80vh;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 16px;
  }
  .table {
    padding: 0 24px 24px;
    height: calc(100% - 182px);
    overflow: scroll;
    position: relative;
    &__fields {
      position: sticky;
      z-index: 1;
      top: 0;
    }
  }
  .fields {
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;
    .cell-modal {
      background: #e4f0ff;
      border-right: 1px solid #f7f7fd;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $text-default;
      padding: 8px;
      &:nth-child(1) {
        width: 12%;
      }
      &:nth-child(2) {
        width: 88%;
      }
    }
  }
  .row {
    display: flex;
    .cell-modal {
      height: 100%;
      box-shadow: -1px -1px 0px rgba(166, 166, 166, 0.1),
        inset -1px -1px 0px rgba(166, 166, 166, 0.1);
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $text-default;
      padding: 10px;
      &:nth-child(1) {
        width: 12%;
        height: unset;
      }
      &:nth-child(2) {
        width: 76%;
      }
      &:nth-child(3) {
        width: 12%;
      }
      i {
        color: $icon-subdued;
        font-size: 24px;
        cursor: pointer;
        transition: 0.2s all ease;
        &:hover {
          color: $link-default;
        }
      }
      /deep/ {
        .input {
          width: 100%;
          border-radius: 2px;
          input {
            border-radius: 2px;
          }
        }
      }
    }
  }
  .cell-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px 32px;
  }
}
</style>
