export default {
  state: {
    globalMobileMode: false
  },

  mutations: {
    setGlobalMobileMode(state) {
      state.globalMobileMode = true;
    },

    unsetGlobalMobileMode(state) {
      state.globalMobileMode = false;
    }
  },
  actions: {
    setGlobalMobileMode({ commit }) {
      commit("setGlobalMobileMode");
    },

    unsetGlobalMobileMode({ commit }) {
      commit("unsetGlobalMobileMode");
    }
  },

  getters: {
    getGlobalMobileMode: (s) => s.globalMobileMode
  }
};
