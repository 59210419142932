<template>
  <transition name="show-modal">
    <div class="absence-card__modal" v-click-outside="hideModal">
      <i class="icon-dots-vertical" @click="handleClickIconDots"></i>
      <ul class="absence-card__modal-list" v-show="modalShow">
        <li class="absence-card__modal-item">
          <button class="absence-card__modal-button" type="button" @click="handleMore">
            <i class="icon-details"></i>
            <p>Подробнее</p>
          </button>
        </li>
        <li class="absence-card__modal-item">
          <button class="absence-card__modal-button" type="button" @click="handleUpdate">
            <i class="icon-edit"></i>
            <p>Редактировать</p>
          </button>
        </li>
        <li class="absence-card__modal-item">
          <button
            class="absence-card__modal-button"
            type="button"
            @click="$root.$emit('deleteVacation', currentVacationData.id)"
          >
            <i class="icon-delete"></i>
            <p>Удалить</p>
          </button>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";

export default {
  name: "AbsenceSetting",
  mixins: [],
  props: ["currentVacationData", "isAvailableForEditing"],
  components: { IcoMoonWrap },
  data() {
    return {
      modalShow: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleClickIconDots() {
      this.modalShow = !this.modalShow;
    },
    hideModal() {
      this.modalShow = false;
    },
    handleUpdate() {
      if (this.isAvailableForEditing) {
        this.$root.$emit("openConformationModal", {
          vacation: this.currentVacationData,
          editMode: true,
          createMode: false,
          absence: true
        });
      } else {
        this.$root.$emit("openVacationRequestModal", {
          vacation: this.currentVacationData,
          editMode: false,
          createMode: true,
          absence: true
        });
      }
      this.hideModal();
    },
    handleMore() {
      this.$root.$emit("openConformationModal", {
        vacation: this.currentVacationData,
        editMode: false,
        createMode: false
      });
      this.hideModal();
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.2s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.absence-card {
  &__modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__modal-list {
    position: absolute;
    z-index: 15;
    top: 0;
    right: 20px;
    padding: 8px 0;
    list-style: none;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;
    @media (max-height: $sm), (max-width: $sm) {
      bottom: auto;
      top: 0 !important;
      padding: 5px 0 5px 8px;
      display: flex;
    }
  }

  &__modal-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    @media (max-height: $sm), (max-width: $sm) {
      padding: 0;
      p {
        display: none;
      }
    }

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;
    }
  }
}
</style>
