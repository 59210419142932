








import { Component, Prop, Vue } from "vue-property-decorator";
import { isString } from "@/helpers/helperFunction";

@Component
export default class ActionItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  item!: any;
  @Prop({
    default: () => {},
    type: Object
  })
  activeItem!: any;

  handleItem() {
    this.$emit("handleItem", this.item);
  }

  get isString() {
    return isString(this.item) && isString(this.activeItem);
  }

  get isActive() {
    if (this.isString) {
      return this.item === this.activeItem;
    } else {
      return this.item.type
        ? this.item.type === this.activeItem.type
        : this.item.id === this.activeItem.id;
    }
  }

  get name() {
    return this.isString ? this.item : this.item.name;
  }

  get id() {
    return this.isString ? `action-item_${this.item}` : `action-item_${this.item.id}`;
  }
}
