<template>
  <div class="profile__statistics">
    <div class="statistic-block" :class="{ 'statistic-block_no-time': !isShowTimeTracker }">
      <ProfileStat
        :dataUser="dataUser"
        :statistic="statistic"
        :sphereList="sphereList"
        :isAdmin="isAdmin"
        :currentStatisticMonth="currentStatisticMonth"
        :isShowTimeTracker="isShowTimeTracker"
      />
      <TimeWidget v-if="isShowTimeTracker" />
    </div>
  </div>
</template>

<script>
import ProfileStat from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/Statistic/ProfileStat";
import TimeWidget from "@/modules/TimeTracker/component/TimeWidget";
import BlackSphere from "@/modules/BlackSphere/store";

export default {
  name: "ProfileStatistics",
  mixins: [],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    sphereList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    statistic: {
      type: Object,
      default: () => {}
    },
    currentStatisticMonth: {
      type: String,
      default: null
    }
  },
  components: {
    TimeWidget,
    ProfileStat
  },
  data() {
    return {};
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {
    isShowTimeTracker() {
      return this.$route.name === "Info";
    },
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles.includes("timemanager_admin");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.statistic-block {
  display: flex;
  height: 100%;
  min-height: 370px;
  background: $surface-default;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 24px;

  @media (max-width: $xxxl) {
    min-height: 400px;
  }
  @media (max-width: 1300px) {
    flex-flow: column;
    overflow: scroll;
  }

  &_no-time {
    min-height: auto;
  }
}
</style>
