<template>
  <div class="profile__personal-area">
    <div class="profile__loader" v-if="loader">
      <Loader />
    </div>
    <div class="profile__content" v-show="!loader">
      <ProfileUserData
        :dataUser="dataUser"
        :userCard="userCard"
        :isAdmin="isAdmin"
        @goGetInfoUser="getUser"
      />
      <ProfileStatistics
        v-if="!loader"
        :dataUser="dataUser"
        :statistic="statistic"
        :currentStatisticMonth="currentStatisticMonth"
        :sphereList="blackSphereListForProfile"
      />
      <ProfileEvents
        :eventsList="getEventListForProfile"
        :currentEventDate="currentEventDate"
        :userId="getUserId"
        :dataUser="dataUser"
        :isAdmin="isAdmin"
        :isAdminCalendar="isAdminCalendar"
        @updateWorkingCalendar="updateWorkingCalendar"
      />
      <ProfileISP />
    </div>
  </div>
</template>

<script>
import ProfileStatistics from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileStatistics";
import ProfileUserData from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileUserData/ProfileUserData";
import ProfileEvents from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileEvents/ProfileEvents";
import ProfileISP from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileISP/ProfileISP";
import { format } from "date-fns";

import formatDateAndTime from "@/helpers/formatDateAndTime";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import { mapGetters } from "vuex";
import moment from "moment";
import UserService from "@/model/User/UserService";
import TimeTracker, { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

export default {
  name: "ProfilePersonalArea",
  mixins: [formatDateAndTime],
  props: {},
  components: { Loader, ProfileISP, ProfileEvents, ProfileUserData, ProfileStatistics },
  data() {
    return {
      loader: true,
      dataUser: [],
      eventsList: [],
      sphereList: [],
      currentEventDate: moment().format("YYYY-MM-DD"),
      currentStatisticMonth: moment().format("YYYY-MM"),
      currentSphereMonth: moment().format("YYYY-MM"),
      userCard: false
    };
  },
  beforeDestroy() {
    this.$root.$off("goGetInfoUser");
    this.$root.$off("getProfileUserEventInfo");
    this.$root.$off("date:change_profileDatePicker");
    this.$root.$off("changeStatisticsMonthPicker");
  },
  mounted() {
    if (this.getUserId) {
      this.getUser();
    } else {
      this.$root.$on("goGetInfoUser", () => {
        this.getUser();
      });
    }
    if (this.$route.params.user_id) {
      CalendarModule2.getEventForProfile({
        user_id: this.$route.params.user_id,
        timezone: this.getTimezone,
        date: this.currentEventDate
      });
    } else {
      CalendarModule2.getEventForProfile({
        user_id: this.getUserId,
        timezone: this.getTimezone,
        date: this.currentEventDate
      });
    }

    CalendarModule2.getAllLocation();

    this.$root.$on("date:change_profileDatePicker", (val) => {
      this.currentEventDate = val;
      if (this.$route.params.user_id) {
        CalendarModule2.getEventForProfile({
          user_id: this.$route.params.user_id,
          timezone: this.getTimezone,
          date: val
        });
      } else {
        CalendarModule2.getEventForProfile({
          user_id: this.getUserId,
          timezone: this.getTimezone,
          date: val
        });
      }
    });
    this.$root.$on("changeStatisticsMonthPicker", (val) => {
      this.currentStatisticMonth = val;
      this.currentSphereMonth = val;
      BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_MONTH_FOR_PROFILE](val);
      this.getBlackSphereInfo();
      if (this.$route.params.user_id) {
        this.getUserPageInfo(this.$route.params.user_id);
      } else {
        this.getUserPageInfo(this.getUserId);
      }
    });
    this.$root.$on(`date:today_profileDatePicker`, () => {
      this.currentEventDate = format(new Date(), "Y-MM-dd");
      this.$root.$emit("date:change_profileDatePicker", this.currentEventDate);
      this.$root.$emit("date:set_calendar_value", this.currentEventDate);
    });
    this.$root.$on(`date:right_profileDatePicker`, () => {
      let d = new Date(this.currentEventDate);
      this.currentEventDate = format(new Date(d.setDate(d.getDate() + 1)), "Y-MM-dd");
      this.$root.$emit("date:change_profileDatePicker", this.currentEventDate);
      this.$root.$emit("date:set_calendar_value", this.currentEventDate);
    });
    this.$root.$on(`date:left_profileDatePicker`, () => {
      let d = new Date(this.currentEventDate);
      this.currentEventDate = format(new Date(d.setDate(d.getDate() - 1)), "Y-MM-dd");
      this.$root.$emit("date:change_profileDatePicker", this.currentEventDate);
      this.$root.$emit("date:set_calendar_value", this.currentEventDate);
    });
  },
  methods: {
    updateWorkingCalendar(date) {
      let uid = this.$route.params.user_id || this.getUserId;
      if (this.isCurrentMonth && date === this.currentStatisticMonth) {
        TimeTracker.getMonthStatistics({ user_id: uid, month: this.currentStatisticMonth });
      }
    },
    getBlackSphereInfo() {
      BlackSphere.getBlackSphereMarksForProfileAction({
        month: this.currentSphereMonth,
        got_user_id: this.getUserId
      });
    },
    getUser() {
      this.loader = true;
      if (this.$route.params.user_id) {
        this.getInfoDismissalUser(this.$route.params.user_id);
        this.userCard = true;
      } else {
        this.getInfoDismissalUser(this.getUserId);
      }
      this.$root.$emit("changeLoaderProfile", false);
    },
    getInfoDismissalUser(id) {
      if (id === this.getUserId || this.$route.params.user_id === this.getUserId) {
        if (!this.getDataInfoUser.is_active) {
          this.currentStatisticMonth = moment(this.getDataInfoUser.date_of_dismissal).format(
            "YYYY-MM"
          );
          BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_MONTH_FOR_PROFILE](
            this.currentStatisticMonth
          );
        }
        this.getUserPageInfo(this.getUserId);
      } else {
        const uid = id || this.$route.params.user_id;
        UserService.actionGetItem(uid).then((response) => {
          if (!response.data.data.is_active) {
            this.currentStatisticMonth = moment(response.data.data.date_of_dismissal).format(
              "YYYY-MM"
            );
            BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_MONTH_FOR_PROFILE](
              this.currentStatisticMonth
            );
          }
          this.getUserPageInfo(id);
        });
      }
    },
    /** Function for getting data of a specific user
     * @param id - id of a specific user
     * @returns dataUser - information about a current user
     */
    async getUserPageInfo(id) {
      this.loader = true;
      const uid = id || this.dataUser.id;
      await TimeTracker.getMonthStatistics({ user_id: uid, month: this.currentStatisticMonth });
      const params = {
        work_time_statistics: JSON.stringify([
          { start_week_1: `${this.currentStatisticMonth}-01` }
        ]),
        black_spheres_statistics: JSON.stringify([{ month: this.currentStatisticMonth }]),
        _with: JSON.stringify([
          "departments",
          "roles",
          "levels",
          "work_time_statistics",
          "black_spheres_statistics"
        ])
      };
      UserService.actionGetItem(uid, params)
        .then((response) => {
          this.dataUser = response.data.data;
          if (!this.isCurrentMonth) {
            const blackSphereStatistic = {
              black_sphere_count_marks: Object(this.dataUser.black_spheres_statistics[0])
                .count_marks,
              black_sphere_percent:
                Object(this.dataUser.black_spheres_statistics[0]).percent === "null"
                  ? 0
                  : Math.floor(+Object(this.dataUser.black_spheres_statistics[0]).percent)
            };
            const statistic = {
              ...this.dataUser.work_time_statistics[0],
              ...blackSphereStatistic
            };
            TimeTracker[TimeTrackerMutationTypes.SET_STATISTIC](statistic);
          }
          this.loader = false;
          this.$nextTick(() => {
            this.$root.$emit("setCanvasTimeProgress");
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    getEventListForProfile() {
      return CalendarModule2.eventForProfile.sort((a, b) => {
        return moment(a.started_at).valueOf() - moment(b.started_at).valueOf();
      });
    },
    isCurrentMonth() {
      return moment(`${this.currentStatisticMonth}-01`) > moment("2022-03-01");
    },
    getTimezone() {
      return CalendarModule2.timezone;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isAdmin() {
      return this.getEndpoints("User", "update");
    },
    statistic() {
      return TimeTracker.statistic;
    },
    isAdminCalendar() {
      return this.getEndpoints("WorkingCalendar", "update");
    },
    blackSphereListForProfile() {
      return BlackSphere.blackSphereListForProfile;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.profile {
  &__personal-area {
    width: 100%;
    height: 98%;
    overflow-y: scroll;
    border-radius: 24px;
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35vh 0;
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    height: calc(100% - 10px);

    @media (max-width: $xxxl) {
      grid-template-rows: 400px 1fr 1fr;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
    }

    @media (max-width: $xl) {
      grid-template-rows: repeat(4, max-content);
      grid-template-columns: repeat(1, 100%);
      grid-gap: 16px;
      overflow-y: scroll;
      border-radius: 24px;
    }
    @media (max-width: $md) {
      height: calc(100% - 30px);
    }
  }

  &__user-data {
    grid-column: 1/2;
    grid-row: 1/3;

    @media (max-width: $xxxl) {
      grid-row: 1/4;
    }

    @media (max-width: $xl) {
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

  &__statistics {
    grid-column: 2/4;
    grid-row: 1/2;

    @media (max-width: $xl) {
      grid-row: 2/3;
      grid-column: 1/2;
    }
  }

  &__events {
    grid-column: 2/4;
    grid-row: 2/2;

    @media (max-width: $xxxl) {
      grid-row: 2/4;
    }

    @media (max-width: $xl) {
      grid-row: 3/4;
      grid-column: 1/2;
    }
  }

  &__profile-isp {
    grid-column: 4/4;
    grid-row: 1/3;

    @media (max-width: $xxxl) {
      grid-row: 1/4;
    }

    @media (max-width: $xl) {
      grid-row: 4/5;
      grid-column: 1/2;
    }
  }
}
</style>
