<template>
  <div class="article-page">
    <Widget type="loader" v-if="loading" />
    <div v-if="!getCreateMode && !loading" class="article-page__container">
      <div class="article-page__header" ref="headerContent">
        <Widget type="bread-crumbs" :crumbs="crumbs" />
      </div>
      <div class="article-page__grid">
        <div class="article-page__item">
          <div class="article-page__item__toolbar">
            <div class="article-page__item__tags">
              <Widget v-for="tag in articleData.tags" type="tag" :text="tag.name" :key="tag.id" />
            </div>
            <div
              v-if="isAdmin || getUserId === articleData.creator_user_id"
              class="article-page__item__buttons"
            >
              <i class="icon-delete" @click="askDeleteArticle"></i>
              <i class="icon-edit" @click="$store.commit('changeCreateMode', true)"></i>
            </div>
          </div>
          <div class="article-page__item__info">
            <div class="article-page__item__info__container">
              <div class="article-page__item__info__people">
                <div class="article-page__item__info__people__img">
                  <img :src="returnSrcFile(articleData.author.avatar)" />
                  <img
                    :src="returnSrcFile(co_author.avatar)"
                    v-for="co_author in articleData.co_authors"
                    :key="co_author.id"
                  />
                </div>
                <div class="article-page__item__info__people__names">
                  <span class="article-page__item__info__people__names__author">{{
                    articleData.author.full_name
                  }}</span>
                  <div class="article-page__item__info__people__names__co-authors">
                    <span
                      class="article-page__item__info__people__names__co-author"
                      v-for="(coAuthor, idx) in articleData.co_authors"
                      :key="coAuthor.id"
                    >
                      {{
                        `${coAuthor.full_name}${
                          idx !== articleData.co_authors.length - 1 ? ", " : ""
                        }`
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="article-page__item__info__wrapper">
                <div class="article-page__item__info__likes">
                  <LikeComponent
                    :parentLike="articleData"
                    :likeableType="'articles'"
                    @updateLikeInfo="updateLink"
                  />
                </div>
                <div class="article-page__item__info__views">
                  <i class="icon-eye"></i>
                  <span>{{ articleData.count_views }}</span>
                </div>
              </div>
            </div>
            <div class="article-page__item__info__cover">
              <img :src="returnSrcFile(articleData.cover)" :alt="articleData.name" />
            </div>
            <div class="article-page__item__info__title">{{ articleData.name }}</div>
          </div>
          <div
            ref="pageContent"
            class="article-page__item__content"
            v-html="articleData.content"
          ></div>
          <div class="article-page__item__decoration-line"></div>
          <div class="article-page__item__comments">
            <div class="article-page__item__comments__count">Комментарии ({{ countComments }})</div>
            <div class="article-page__item__comments__content">
              <div
                class="article-page__item__comments__bg"
                v-for="comment in commentsList.allItems"
                :key="comment.id"
              >
                <Comment
                  @setReplyComment="setReplyComment"
                  @updateCommentsList="getCommentsArticleInfo"
                  :comment="comment"
                  :admin="isAdmin"
                  :articleCreator="articleData.creator.id === getUserId"
                  :reply="true"
                />
              </div>
              <div
                class="article-page__item__comments__view-all"
                v-if="commentsList.response.total_count > commentsList.response.per_page"
                @click="expendCommentLength"
              >
                Показать все комментарии
              </div>
              <div class="article-page__item__comments__empty" v-if="!commentsList.allItems.length">
                Будь первым, кто оставит комментарий :)
              </div>
            </div>
            <CommentComponent
              v-bind="{
                typeComment: 'articles',
                commentableId: articleData.id,
                parent: replyComment
              }"
              @updateCommentsList="getCommentsArticleInfo"
              @cancelReply="replyComment = null"
            />
          </div>
        </div>
        <div
          v-if="pageHeaders.length || articleData.files.length || articleData.links.length"
          class="article-page__apps"
        >
          <div
            v-if="pageHeaders.length"
            class="article-page__apps__item article-page__apps__table-of-contents"
          >
            <h3>Содержание</h3>
            <div class="article-page__apps__table-of-contents__items">
              <div
                v-for="(header, idx) in pageHeaders"
                @click="goScrollContent(header, idx)"
                :key="header"
                :class="idx === activeHeader ? 'active' : ''"
              >
                {{ header.innerText }}
              </div>
            </div>
          </div>
          <div
            v-if="articleData.files.length || articleData.links.length"
            class="article-page__apps__item article-page__apps__docs"
          >
            <h3 v-if="articleData.files.length">Документы</h3>
            <div v-if="articleData.files.length" class="article-page__apps__docs__files">
              <a
                v-for="file in articleData.files"
                :href="returnSrcFile(file.link)"
                target="_blank"
                :key="file.id"
                class="article-page__apps__docs__files__item"
              >
                <IcoMoonWrap
                  :name="setExtensionIcon(file)['name']"
                  :fontSize="18"
                  :pathCount="setExtensionIcon(file)['path']"
                />
                <span class="article-page__apps__docs__files__item__title">{{
                  cutterText(file.label)
                }}</span>
                <span class="article-page__apps__docs__files__item__size">{{
                  sizeFloor(file.size)
                }}</span>
              </a>
            </div>
            <div
              v-if="articleData.files.length && articleData.links.length"
              class="article-page__apps__docs__decoration-line"
            ></div>
            <div v-if="articleData.links.length" class="article-page__apps__docs__links">
              <h4>Ссылки</h4>
              <div
                v-for="link in articleData.links"
                :key="link.id"
                class="article-page__apps__docs__links__item"
              >
                <i class="icon-chain"></i>
                <a target="_blank" :href="link.link">{{ link.label }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getCreateMode" class="article-page__edit-container">
      <ArticleEditComponent @updateArticlePage="updateArticleInfo" :articleData="articleData" />
    </div>
    <div class="button-scroll-top" @click="goToTop" v-show="scrollTopButton">
      <i class="icon-up"></i>
    </div>
  </div>
</template>

<script>
import { HTTPRequest, Model } from "@frontend-modules/fsql";
import { domain, domainStorage } from "@/globalVariables";
import Swal from "sweetalert2";
import axios from "axios";

import Widget from "@/components/Widgets/Widget";
const ArticleEditComponent = () =>
  import("@/components/KnowledgeBaseComponents/Article/ArticleEditComponent");
import LikeComponent from "@/components/KnowledgeBaseComponents/LikeComponents/LikeComponent";
import CommentComponent from "@/components/KnowledgeBaseComponents/CommentComponents/CommentComponent";
import Comment from "@/components/KnowledgeBaseComponents/CommentComponents/Comment";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "ArticlePage",
  mixins: [],
  props: [],
  components: {
    LikeComponent,
    ArticleEditComponent,
    Widget,
    CommentComponent,
    Comment,
    IcoMoonWrap
  },
  data() {
    return {
      loading: true,
      activeHeader: null,
      pageHeaders: [],
      commentsList: [],
      replyComment: null,
      headersList: ["h1", "h2", "h3", "h4", "h5", "h6"],
      articleData: {},
      commentsLength: 1,
      countComments: 0,
      scrollTopButton: false,
      crumbs: [
        {
          title: "Главная",
          linkName: "Section"
        },
        {
          title: "Статьи",
          linkName: "Articles"
        }
      ]
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  mounted() {
    this.setView();
    this.getArticleInfo();
    window.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleScroll() {
      if (window.scrollY > 1000) {
        this.scrollTopButton = true;
      } else {
        this.scrollTopButton = false;
      }
    },
    setView() {
      httpClient({
        url: `/View/actionCreate`,
        method: "POST",
        data: [
          {
            viewable_id: this.$route.params.article_id,
            viewable_type: "articles"
          }
        ]
      });
    },
    updateLink() {
      const Article = new HTTPRequest();
      Article.send(
        `${domain}/monolit/Article/actionGetItem/${
          this.$route.params["article_id"]
        }?_with=${JSON.stringify([
          "categories",
          "tags",
          "creator",
          "likes",
          "author",
          "co_authors",
          "links",
          "files",
          "current_user_like"
        ])}`,
        "GET"
      )
        .then((resp) => {
          this.articleData = resp.data.data;
          setTimeout(() => {
            this.getHeadersIntoHtml();
          }, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    updateArticleInfo() {
      this.loading = true;
      this.crumbs.pop();
      this.getArticleInfo();
    },
    deleteArticle() {
      httpClient({
        url: `/Article/actionDelete/${this.$route.params.article_id}`,
        method: "DELETE"
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Статья успешно удалена!`
          });
          this.$router.push({ name: "Articles" });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    goScrollContent(header, idx) {
      this.setActiveHeader(idx);
      header.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    getHeadersIntoHtml() {
      this.pageHeaders = [];
      for (let i in this.$refs.pageContent.children) {
        if (
          this.headersList.findIndex((h) => h === this.$refs.pageContent.children[i].localName) !==
          -1
        ) {
          this.pageHeaders.push(this.$refs.pageContent.children[i]);
        }
      }
    },
    getArticleInfo() {
      const Article = new HTTPRequest();
      Article.send(
        `${domain}/monolit/Article/actionGetItem/${
          this.$route.params["article_id"]
        }?_with=${JSON.stringify([
          "categories",
          "tags",
          "creator",
          "likes",
          "author",
          "co_authors",
          "links",
          "files",
          "current_user_like"
        ])}`,
        "GET"
      )
        .then((resp) => {
          this.articleData = resp.data.data;
          this.crumbs.push({ title: this.articleData.name, linkName: "ArticlePage" });
          this.getCommentsArticleInfo();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async getCommentsArticleInfo() {
      const Comments = new Model({
        model: "Comment",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            commentable_id: this.articleData.id,
            commentable_type: "articles",
            _order: { datetime_of_creation: "asc" },
            _count: this.commentsLength,
            _with: JSON.stringify(["replies", "likes", "author", "attachment_files"])
          }
        }
      });
      await Comments.init();
      let count = Comments.allItems.reduce(
        (accumulator, item) => accumulator + item["count_comments"],
        0
      );
      this.countComments = Comments.response.total_count + count;
      this.commentsList = Comments;
      this.loader = false;
      setTimeout(() => {
        this.getHeadersIntoHtml();
      }, 0);
    },
    expendCommentLength() {
      this.commentsLength = 300;
      this.getCommentsArticleInfo();
    },
    setReplyComment(comment) {
      this.replyComment = comment;
      document
        .getElementsByClassName("article-page__item__comments__bg")[0]
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    goToTop() {
      this.$refs.headerContent.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    setExtensionIcon(file) {
      switch (file.extension) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return { name: "doc", path: 7 };
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return { name: "ppt", path: 7 };
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return { name: "xls", path: 7 };
        case "application/pdf":
          return { name: "pdf", path: 7 };
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return { name: "picture", path: 3 };
        case "video/mp4":
        case "audio/mpeg":
        case "video/quicktime":
        case "video/3gpp":
        case "video/x-ms-wmv":
        case "video/mpeg":
        case "video/x-flv":
        case "video/x-msvideo":
          return { name: "video-player", path: 11 };
        default:
          break;
      }
    },
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + " Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + " Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + " Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + " Кб";
      } else {
        return size + " байт";
      }
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= 15) {
        return text;
      } else return text.substr(0, 13) + "..." + extension[extension.length - 1];
    },
    setActiveHeader(idx) {
      this.activeHeader = idx;
    },
    askDeleteArticle() {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить статью?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteArticle();
          }
        });
    }
  },
  computed: {
    getCreateMode() {
      return this.$store.getters.getCreateMode;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("knowledge_base_admin")
        : false;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.article-page {
  width: 100%;
  margin-left: 30px;

  @media (max-width: $lg) {
    margin-left: 0;
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 20%;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__grid {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-start;

    @media (max-width: calc(768px - 1px)) {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 100%;
    min-width: 320px;
    margin-right: 30px;
    padding: 30px 30px 50px 30px;

    border: 1px solid $color-contour;
    border-radius: 10px;
    background-color: $color-white;

    @media (max-width: calc(768px - 1px)) {
      order: 2;
      margin: 0 0 30px;
    }

    @media (max-width: $sm) {
      min-width: 100%;
      padding: 20px 20px 50px 20px;
    }

    &__toolbar {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 500px;

      .tag {
        margin-bottom: 5px;

        @media (max-width: $xs) {
          max-width: 165px;
          word-break: break-word;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      .icon-delete,
      .icon-edit {
        cursor: pointer;
        transition: all 0.2s ease;
        color: $color-interactive;
        font-size: 22px;

        @media screen and (max-width: $sm) {
          font-size: 18px;
        }

        &:not(:last-child) {
          margin-right: 15px;

          @media screen and (max-width: $sm) {
            margin-right: 10px;
          }
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__people {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        &__img {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 10px;

          img {
            width: 30px;
            height: 30px;

            object-fit: cover;
            border-radius: 50%;

            &:not(:first-child) {
              margin-left: -15px;
            }
          }
        }

        &__names {
          display: flex;
          flex-direction: column;

          &__author {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: $color-text-primary-1;
          }

          &__co-authors {
            display: flex;
            align-items: center;
          }

          &__co-author {
            margin-right: 5px;

            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: $color-text-secondary;
          }
        }
      }

      &__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        user-select: none;
      }

      &__likes {
        display: flex;
        align-items: center;
        margin-right: 20px;

        svg {
          margin-right: 8px;

          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            fill: $color-button-hover;
            transform: scale(1.1);
          }

          &:active {
            fill: $color-button-click;
            transform: scale(0.9);
          }
        }

        span {
          font-size: 14px;
          line-height: 13px;
          font-weight: 400;
          color: $color-other-1;
        }
      }

      &__views {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        vertical-align: middle;
        font-weight: 400;
        color: $color-other-1;

        .icon-eye {
          margin-right: 8px;
          margin-bottom: 3px;
          font-size: 22px;
        }
      }

      &__cover {
        margin: 0 auto 20px auto;

        img {
          object-fit: cover;
          width: 100%;
        }
      }

      &__title {
        word-break: break-word;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
      }
    }

    &__content {
      word-break: break-word;
      max-width: 100% !important;
      width: 100% !important;

      /deep/ {
        h1,
        h2,
        h3,
        h4 {
          margin-bottom: 16px;
          color: $text-default;
        }

        h1 {
          @media (max-width: $sm) {
            font-size: 26px !important;
          }
        }
        h2 {
          @media (max-width: $sm) {
            font-size: 24px !important;
          }
        }
        h3 {
          @media (max-width: $sm) {
            font-size: 20px !important;
          }
        }
        h4 {
          @media (max-width: $sm) {
            font-size: 18px !important;
          }
        }

        p,
        span,
        li,
        blockquote {
          margin-bottom: 8px;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: $text-default !important;
        }

        blockquote {
          font-style: italic;
          margin: 8px 0 8px 16px;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        table,
        pre,
        ul,
        ol,
        li {
          max-width: 100% !important;
          width: 100% !important;
        }

        table {
          border-collapse: collapse;
        }

        td {
          border: 1px solid transparent;
        }

        a {
          color: $link-default;
        }

        a:hover,
        a:focus {
          color: $link-hovered;
        }
      }
    }

    &__decoration-line {
      width: 100%;
      height: 1px;
      margin-top: 30px;
      margin-bottom: 30px;

      background-color: $color-contour;
    }

    &__comments {
      display: flex;
      flex-direction: column;

      &__count {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
        margin-bottom: 30px;
      }

      &__content {
        display: flex;
        flex-direction: column;
      }

      &__bg {
        display: flex;
        flex-direction: column;
      }

      &__replies {
        display: flex;
        flex-direction: column;
        padding-left: 40px;
      }
      &__view-all {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: $color-interactive;
        user-select: none;
        cursor: pointer;
      }
      &__empty {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-other-2;
        margin-bottom: 15px;
      }
    }
  }

  &__apps {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    z-index: 300;

    @media (max-width: calc(768px - 1px)) {
      order: 1;
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      top: 0;
    }

    &__item {
      width: 240px;
      min-height: 410px;
      padding: 20px;

      @media (max-width: calc(768px - 1px)) {
        max-width: 100%;
        width: 100%;
        min-height: fit-content;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      border: 1px solid $color-contour;
      border-radius: 10px;
      background-color: $color-white;

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-accent;
      }
    }

    &__table-of-contents {
      h3 {
        margin: 0 0 20px;
      }

      &__items {
        display: flex;
        flex-direction: column;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;

          color: $color-text-primary-2;
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;

          cursor: pointer;
          transition: all 0.2s ease;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &.active {
            color: $color-interactive;
          }
        }
      }
    }

    &__docs {
      h3 {
        margin: 0 0 15px;
      }

      &__files {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          i {
            margin-right: 10px;
          }

          &__title {
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $color-text-accent;
          }

          &__size {
            margin-top: 3px;
            display: block;
            margin-left: auto;

            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $color-text-primary-2;

            @media screen and (max-width: $xxl) {
              padding-left: 5px;
            }
          }
        }
      }

      &__decoration-line {
        width: 100%;
        height: 1px;
        margin: 20px 0;

        background-color: $color-contour;
      }

      &__links {
        h4 {
          color: $color-text-accent;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-top: 0;
        }

        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .icon-chain {
            margin-right: 10px;
            font-size: 20px;
            color: $color-text-secondary;
          }

          a {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            color: $color-interactive;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;

            transition: all 0.2s ease;

            &:hover {
              color: $color-button-hover;
            }

            &:active {
              color: $color-button-click;
            }
          }
        }
      }
    }
  }

  .button-scroll-top {
    position: fixed;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddddff;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .button-scroll-top:hover {
    box-shadow: 0 4px 5px rgba(221, 221, 255, 0.62);
  }
  .icon-up {
    display: block;
    color: $color-interactive;
    font-size: 20px;
  }
}
</style>
