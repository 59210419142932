<template>
  <div class="chat-main" :class="{ 'chat-main_is-pinned': pinned_messages.length }">
    <div class="list-container">
      <MessageList :isPin="true" :messageList="pinned_messages" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import MessageList from "@/modules/Messenger/components/Chat/MessageList/MessageList";
export default {
  name: "Chatroom",
  mixins: [],
  data() {
    return {};
  },
  components: { MessageList },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions("MessengerModule", ["setPasteFile", "getMessage"]),

    handleUploadFile(event) {
      this.setPasteFile(event);
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "messages",
      "showModal",
      "showPin",
      "pinned_messages",
      "files",
      "showFileModal",
      "currentChat"
    ]),
    ...mapGetters("MessengerModule", ["getOldMessage"]),
    setMessage() {
      return this.showPin ? this.pinned_messages : this.messages;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/Scroll.mixin.scss";
::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: $bg-gray-500;
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $surface-disabled;
}
.chat-main {
  //max-height: calc(100vh);
  height: 100%;
  background-color: #edf4fe;
  display: flex;
  flex-flow: column;
  flex-shrink: 1;
  overflow: auto;
  //position: relative;
}
.list-container {
  height: 100%;
  margin-top: auto;
}
/deep/ {
  .list-dynamic {
    max-height: 100vh;
  }
}
</style>
