































import { Component, Mixins, Prop } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import TransitionFade from "@/aopV2/components/UIComponents/Animation/TransitionFade.vue";
import { dataToEventAdapter, dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import EventCard from "@/aopV2/modules/Calendar/components/CalendarEventCard/EventCard.vue";
import LabelTooltip from "@/aopV2/components/UIComponents/Tooltip/LabelTooltip.vue";
import moment from "moment";
import FSEventMixin from "@/aopV2/modules/Calendar/helper/FSevent.mixin";

@Component({
  components: { LabelTooltip, EventCard, TransitionFade }
})
export default class CalendarCell extends Mixins(FSEventMixin) {
  @Prop({
    default: () => {}
  })
  time!: any;
  @Prop({
    default: 1
  })
  indexDay!: number;
  @Prop({
    default: dateFormat(new Date()),
    type: String
  })
  day!: string;
  @Prop({
    default: true,
    type: Boolean
  })
  eventLoader!: boolean;
  @Prop({
    default: "",
    type: String
  })
  scrollTime!: string;
  cellHeight = 0;
  cellWidth = 0;
  windowWidth: number = 0;

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  mounted() {
    this.cellHeight = (this.$refs.calendarCell as HTMLElement).offsetHeight;
    this.cellWidth = (this.$refs.calendarCell as HTMLElement).offsetWidth;
    this.resizeHandler();
    if (this.scrollTime) {
      this.$nextTick(() => {
        const isPreCreateEvent = this.time.events.some(
          (item) => item.id === this.preCreateEvent.id
        );
        if (isPreCreateEvent) {
          const [hh, mm] = this.scrollTime.split(":");
          const item = this.$el.parentNode!.querySelector(`[data-start="${hh}:00"]`);
          item!.scrollIntoView({ block: "center" });
        }
      });
    }
    window.addEventListener("resize", this.resizeHandler);
  }

  timeFormat(time) {
    return time > 9 ? time : `0${time}`;
  }

  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get isMobileWindowSize() {
    return this.getWidthWindow < 1024;
  }

  createEvent(e) {
    const height = this.$el.getBoundingClientRect().height;
    let start_time = this.time.start_time;
    let end_time = this.time.end_time;
    const [startH, startM] = this.time.start_time.split(":");
    const [endH, endM] = this.time.end_time.split(":");
    if (e.offsetY < height / 4) {
      if (+startH === 0) {
        start_time = `00:00`;
        end_time = `01:00`;
      } else {
        start_time = `${this.timeFormat(+startH - 1 > 0 ? +startH - 1 : 0)}:30`;
        end_time = `${this.timeFormat(+endH - 1)}:30`;
      }
    } else if (e.offsetY > height * 0.75) {
      start_time = `${this.timeFormat(+endH - 1)}:30`;
      end_time = `${endH}:30`;
    }

    if (!this.isShowCreateEvent) {
      CalendarModule2.toggleCreateModal(true);
      CalendarModule2.setCreateData({
        ...this.createData,
        selectDate: this.day,
        timeStart: start_time,
        timeEnd: end_time,
        selectResponsible: [{ id: this.userId }],
        selectGroups: this.selectedGroups,
        selectMembers: this.users.filter((item) => item.id !== this.userId)
      });
      CalendarModule2.createPreCreationEvent(dataToEventAdapter(this.createData));
      this.$emit("show");
      if (this.isMobileWindowSize) {
        this.handleFullScreenEvent();
      }
    }
  }

  get showGroupModal() {
    return CalendarModule2.showGroupModal;
  }

  get showLocationModal() {
    return CalendarModule2.showLocationModal;
  }

  get showUsersModal() {
    return CalendarModule2.showUsersModal;
  }

  get isNotActive() {
    return (
      this.isShowViewEvent || this.showGroupModal || this.showLocationModal || this.showUsersModal
    );
  }

  get isShowViewEvent() {
    return CalendarModule2.isShowViewEvent;
  }

  resizeHandler() {
    this.windowWidth = window.innerWidth;
  }

  get users() {
    return CalendarModule2.selectedUsers;
  }

  get selectedGroups() {
    return CalendarModule2.saveGroups;
  }

  get userId() {
    return this.$store.getters.getUserId;
  }

  get createData() {
    return CalendarModule2.createDataDef;
  }

  get isShowCreateEvent() {
    return CalendarModule2.isShowCreateEvent;
  }

  get cellId() {
    return `${this.day} ${this.time.start_time}:00`;
  }

  get isFirst() {
    return this.indexDay === 0;
  }

  get showTime() {
    return this.windowWidth < 580 || this.isFirst;
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }

  get isCreate() {
    return this.$route.name === "CreateEvent";
  }

  get preCreateEvent() {
    return CalendarModule2.preCreateEvent;
  }

  get eventsSort() {
    if (this.isCreate) {
      const event = this.time.events.find((item) => item.id === this.preCreateEvent.id);
      const events = this.time.events.filter((item) => item.id !== this.preCreateEvent.id);
      if (event) {
        events.push(event);
      }
      return events;
    } else {
      return this.time.events;
    }
  }

  get isActiveHour() {
    return this.windowWidth < 580
      ? moment().format("YYYY-MM-DD") === this.day &&
          Number(this.time?.start_time.split(":")[0]) === Number(new Date().getHours())
      : Number(this.time?.start_time.split(":")[0]) === Number(new Date().getHours());
  }
}
