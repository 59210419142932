<template>
  <li class="sort-table__value__item modal__date-item" @click="handleCheckboxClick(item)">
    <span class="checkbox_custom" :class="{ checkbox_custom_chosen: item.isChecked }">
      <i class="icon-check" v-if="item.isChecked"></i>
    </span>
    <span>{{ item.name }}</span>
  </li>
</template>

<script>
export default {
  name: "ModalCheckboxDate",
  mixins: [],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    valueData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleCheckboxClick(item) {
      this.$root.$emit("chooseDateCheckboxValue", item);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.modal {
  &__date {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 0 40px;
  }
  &__date-item {
    cursor: pointer;
    display: flex;
    &:first-child {
      margin-right: 31px;
      @media (max-width: $xs) {
        margin-right: 10px;
      }
    }
    span {
      line-height: 18px;
    }
  }
}
.checkbox_custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid #d3d2e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;

  .icon-check {
    color: #ffffff;
  }

  &_chosen {
    background-color: $action-primary;
    border: 2px solid $action-primary;
  }
}
</style>
