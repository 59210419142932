






































import AbsenceWidgets from "@/modules/Tables/components/TablesStatistics/Absence/AbsenceWidgets";
import AbsenceCellContent from "@/modules/Tables/components/TablesStatistics/Absence/AbsenceCellContent";
// import AbsenceCreateAndEditModal from "@/modules/Tables/components/TablesStatistics/Absence/AbsenceModal/AbsenceCreateAndEditModal";
import Table from "@/modules/Tables/components/Table/Table";
// import AbsenceWidgetsMobile from "@/modules/Tables/components/TablesStatistics/Absence/AbsenceModal/AbsenceWidgetsMobile";
import AbsenceModule, { AbsenceMutationTypes } from "@/modules/Absence/store/AbsenceModule";
import { Component, Vue } from "vue-property-decorator";
import ConfirmationModal from "@/modules/TimeTracker/component/Modal/ConfirmationModal";
import AbsenceModal from "@/modules/Absence/component/AbsenceModal/AbsenceModal.vue";
import { IAbsenceTypes } from "@/modules/Absence/store/intefaces";
import moment from "moment";

@Component({
  components: {
    AbsenceModal,
    // AbsenceWidgetsMobile,
    Table,
    ConfirmationModal,
    AbsenceWidgets,
    AbsenceCellContent
    // AbsenceCreateAndEditModal
  }
})
export default class Absence extends Vue {
  tableHead = [
    { name: "Сотрудник", sort: false, valueSort: "user_id", key: "full_name" },
    { name: "С какой даты", sort: false, valueSort: "from_date", key: "from_date" },
    { name: "По какую дату", sort: false, valueSort: "to_date", key: "to_date" },
    { name: "Тип отсутствия", sort: false, valueSort: "absence_type_id", key: "absence_type" },
    { name: "Количество дней", sort: false, valueSort: "count_days", key: "count_days" },
    { name: "", sort: false, key: "table_control" }
  ];
  users: Array<any> = [];
  types: Array<IAbsenceTypes> = [];
  loader = false;
  date: any = { start: null, end: null };
  isShowLoader = false;

  mounted() {
    if (!this.isAdminAbsence) {
      this.$router.push({ name: "Profile" });
    }
    this.getTable();
    AbsenceModule.getAbsenceType();
  }

  async getTable() {
    this.isShowLoader = true;
    await AbsenceModule.getAbsenceList();
    this.isShowLoader = false;
  }

  getTableWithFilter() {
    const absence_types = this.types.length ? this.types.map((item) => item.id) : null;
    const users = this.users.length ? this.users.map((item) => item.id) : null;
    const from_date = this.date.start ? moment(this.date.start).format("YYYY-MM-DD") : null;
    const to_date = this.date.end ? moment(this.date.end).format("YYYY-MM-DD") : null;
    this.getTableList({ users, absence_types, from_date, to_date });
  }

  handleUser(val) {
    this.users = val;
    AbsenceModule[AbsenceMutationTypes.SET_USERS_FILTER](this.users);
    this.getTableWithFilter();
  }

  handleAbsenceType(val) {
    this.types = val;
    AbsenceModule[AbsenceMutationTypes.SET_TYPES_FILTER](this.types);
    this.getTableWithFilter();
  }

  handleDate(val) {
    this.date = val;
    AbsenceModule[AbsenceMutationTypes.SET_DATE_FILTER](this.date);
    this.getTableWithFilter();
  }

  getTableDataWithPagination() {
    if (this.absencePage && this.absenceCount) {
      if (this.absenceCount > this.absences.length) {
        const page = this.absencePage + 1;
        this.loader = true;
        const from_date = this.date.start ? moment(this.date.start).format("YYYY-MM-DD") : null;
        const to_date = this.date.end ? moment(this.date.end).format("YYYY-MM-DD") : null;
        this.getTableList({
          per_page: this.per_page,
          users: this.users.length ? this.users.map((item) => item.id) : null,
          absence_types: this.types.length ? this.types.map((item) => item.id) : null,
          from_date,
          to_date,
          page
        }).then(() => {
          this.loader = false;
        });
      }
    }
  }

  get modalConfig() {
    return AbsenceModule.modalConfirmData;
  }

  get isShowConfirmationModal() {
    return AbsenceModule.isShowConfirmationModal;
  }

  get isShowAbsenceModal() {
    return AbsenceModule.isShowAbsenceModal;
  }

  async getTableList(params) {
    await AbsenceModule.getAbsenceList(params);
  }

  get absenceCount() {
    return AbsenceModule.absenceCount;
  }

  get absencePage() {
    return AbsenceModule.page;
  }

  get per_page() {
    return AbsenceModule.per_page;
  }

  get absences() {
    return AbsenceModule.absences;
  }

  get isAdminAbsence() {
    return this.$store.getters.getEndpoints("WorkingCalendar", "update");
  }
}
