const state = {
  fromPag: 1,
  totalCount: 0,
  tableData: [],
  tableParams: null,
  isDeleteElement: false,
  valueSortList: [
    { isAsc: true, value: "user_id" },
    { isAsc: true, value: "full_name" },
    { isAsc: true, value: "from_date" },
    { isAsc: true, value: "to_date" },
    { isAsc: true, value: "absence_type_id" },
    { isAsc: true, value: "count_days" },
    { isAsc: true, value: "tracked_time" },
    { isAsc: true, value: "tracked_time_rate_1" },
    { isAsc: true, value: "tracked_time_rate_2" },
    { isAsc: true, value: "tracked_time_rate_3" },
    { isAsc: true, value: "difference_time" },
    { isAsc: true, value: "got_user_id" },
    { isAsc: true, value: "put_user_id" },
    { isAsc: true, value: "mark" },
    { isAsc: true, value: "created_at" },
    { isAsc: true, value: "marked_at" },
    { isAsc: false, value: "count_marks" },
    { isAsc: false, value: "percent" },
    { isAsc: true, value: "levels" },
    { isAsc: true, value: "experience" }
  ],
  valueSort: { isAsc: true, value: "user_id" },
  valueSortUser: { isAsc: true, value: "full_name" },

  loaderTable: false,
  loaderReport: false,
  loaderTimeReportByRatesExport: false,
  loaderTimeReportByProjectsExport: false,

  // Education
  isAdminEducation: false,
  isActiveEducation: false,

  // BlackSphereStatistics
  tableDataBlackSphereStatistics: [],

  isAdminBlackSphereStatistics: false,

  // Time
  tableDataTime: [],

  isAdminTime: false,
  timeStatistics: [],
  datesColumn: [],
  reportSocketId: 0,

  //Tests
  isAdminTests: false,
  tableDataTests: [],

  //Absence
  tableDataAbsence: [],

  isAdminAbsence: false,
  isAbsenceAddAndEdit: false,
  editValueAbsence: null,

  //Activity
  tableDataActivity: [],

  isAdminActivity: false,

  //Users
  isFilterActiveUsers: false,
  isAdminUsers: false,
  isAdminReportUsers: false,
  showModalCreateUser: false,
  //Roles
  isAdminRoles: false
};

export default state;
