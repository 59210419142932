<template>
  <div ref="newsComponent" class="news-component" :id="setId">
    <Spinner class="loader" v-if="loading && newsEditMode" />
    <NewsEdit
      v-if="newsEditMode && !loading"
      :news="newsCopy"
      @setNewsLoading="loading = true"
      @closeNewsLoading="loading = false"
      @closeNewsEditMode="closeNewsEditMode"
    />
    <ConfirmModal
      :title="'Удалить?'"
      :description="'Вы уверены, что хотите удалить новость?'"
      v-if="confirmDeleteNews"
      @cancel="closeConfirmModal"
      @accept="newsDelete"
    />
    <div class="news-component__container" v-if="!newsEditMode">
      <NewsHeader
        :editMode="editMode"
        :news="newsCopy"
        :actionList="actionList"
        :countViews="news.count_views"
        @action_item_Delete="openConfirmModal"
        @action_item_Edit="setNewsEditMode"
      />
      <NewsContent :news="newsCopy" :quiz="newsCopy.poll || {}" @updateQuiz="updateQuiz" />
      <NewsFooter
        :news="newsCopy"
        :countComments="countComments"
        :countLike="countLike"
        :countViews="news.count_views"
        :showComment="showComment"
        @footer_item_comment="showCommentsList"
        @footer_item_heart="postLike"
      />
    </div>
    <slide-up-down
      :active="newsCopy.channel.is_include_comments && showComment && !newsEditMode"
      class="news-component__comments"
    >
      <div class="loader-new__bg" v-if="loading">
        <Spinner class="loader" />
      </div>
      <div v-if="!loading" class="news-component__comments__content">
        <div
          class="news-component__comments__bg"
          v-for="comment in commentsList.items"
          :key="comment.id"
        >
          <CommentNew
            @setReplyComment="setReplyComment"
            @updateCommentsList="getCommentsNewsInfo"
            :comment="comment"
            :creator="news.author_user_id === getUserId"
            :reply="true"
            typeComment="news"
            :commentableId="news.id"
            :admin="commentsAdmin"
            :globalCommentEditMode="globalCommentEditMode"
            :news="news"
            @setGlobalCommentEditMode="globalCommentEditMode = true"
            @closeGlobalCommentEditMode="globalCommentEditMode = false"
          />
        </div>
        <div
          class="news-component__view-all"
          v-if="commentsList.total_count > commentsList.per_page"
          @click="expendCommentLength"
        >
          {{ "Показать все комментарии (" + commentsList.total_count + ")" }}
        </div>
      </div>
      <CommentComponent
        v-bind="{
          typeComment: 'news',
          commentableId: news.id,
          parent: replyComment,
          userInfo: getDataInfoUser
        }"
        :news="news"
        @set_loading_in_comments="changeLoading(true)"
        @stop_loading_in_comments="changeLoading(false)"
        @updateCommentsList="getCommentsNewsInfo"
        @cancelReply="replyComment = null"
      />
      <!--      </div>-->
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import ClickOutside from "vue-click-outside";
import CommentComponent from "@/modules/News/components/CommentComponents/CommentComponent";
import NewsHeader from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsHeader";
import NewsContent from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsContent";
import NewsFooter from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsFooter";
import CommentNew from "@/modules/News/components/CommentComponents/CommentNew";
import NewsEdit from "@/modules/News/components/NewsCreateAndEdit/NewsEdit";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import { format, parseISO } from "date-fns";
import { mapActions, mapState } from "vuex";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "NewsComponent",
  mixins: [uploadFilesMixin],
  props: {
    news: {
      type: Object,
      default: () => {
        return {};
      }
    },
    globalEditMode: {
      type: Boolean
    },
    commentsAdmin: {
      type: Boolean
    },
    newsAdmin: {
      type: Boolean
    }
  },
  components: {
    ConfirmModal,
    Spinner,
    NewsEdit,
    CommentNew,
    NewsFooter,
    NewsContent,
    NewsHeader,
    CommentComponent,
    SlideUpDown
  },
  data() {
    return {
      images: [],
      otherFiles: [],
      showSettingsModal: false,
      showComment: false,
      bigTextTrigger: false,
      newsCopy: {},
      countComments: 0,
      commentsList: [],
      commentsLength: 0,
      replyComment: null,
      quiz: [],
      isViews: false,
      timerViewsId: null,
      newsEditMode: false,
      globalCommentEditMode: "",
      isAdmin: false,
      countLike: this.news.count_likes,
      formatContentText: "",
      confirmDeleteNews: false,
      actionList: [
        {
          isShow: this.hoursBeforeNews,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `Edit`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `Delete`
        }
      ]
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.countComments = this.news.count_comments;
    this.actionList[0].isShow = this.hoursBeforeNews;
    this.newsCopy = this.news;
    this.hideBigText(this.newsCopy.content);
    this.$nextTick(() => {
      this.target = document.querySelector(`#news-component-${this.news.id}`);
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (
              entry.isIntersecting &&
              this.newsItems.length > 1 &&
              this.news.id === this.newsItems[this.newsItems.length - 2].id
            ) {
              this.scrollGetNewsItems();
              this.observer.unobserve(this.target);
            } else if (entry.isIntersecting && !this.isViews) {
              this.timerViewsId = setTimeout(() => {
                this.createView();
              }, 3000);
            } else {
              clearTimeout(this.timerViewsId);
            }
          });
        },
        { root: document.querySelector(".news"), rootMargin: "0px", threshold: 0 }
      );
      this.observer.observe(this.target);
    });
  },
  methods: {
    ...mapActions("NewsModule", [
      "createViewAction",
      "getCommentsInfoAction",
      "like",
      "removeLike",
      "getNewsItemInfoAction",
      "deleteNewsAction",
      "setNewsToUpdate",
      "setReplyCommentData",
      "scrollGetNewsItems",
      "getQuizAction"
    ]),
    // Подтверждение удаления записи
    openConfirmModal() {
      this.confirmDeleteNews = true;
    },
    closeConfirmModal() {
      this.confirmDeleteNews = false;
    },
    // Получение одной новости для обновления
    getNewsItemInfo() {
      this.changeLoading(true);
      this.getNewsItemInfoAction(this.news.id)
        .then((response) => {
          this.newsCopy = response.data.data;
          this.closeLoadingByChild();
          this.changeLoading(false);
        })
        .catch((error) => {
          this.closeLoadingByChild();
          this.errorAlert(error);
          this.changeLoading(false);
        });
    },
    showCommentsList() {
      this.showComment = !this.showComment;
      if (this.showComment && !this.commentsList.total_count && this.countComments !== 0) {
        this.getCommentsNewsInfo();
      }
    },
    // Запрос на удаление записи
    newsDelete() {
      this.deleteNewsAction(this.news.id)
        .then(() => {
          this.$emit("updateAllNews");
          if (this.$route.query.news) this.$router.push({ name: "News" });
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Новость успешно удалена!`
          });
        })
        .catch((error) => {
          this.errorAlert(error);
          this.changeLoading(false);
        });
    },

    // Запрос на лайк
    postLike() {
      if (this.newsCopy.current_user_like) {
        this.removeLike(this.newsCopy.current_user_like.id)
          .then(() => {
            this.newsCopy.current_user_like = null;
            this.countLike = this.countLike - 1;
          })
          .catch((error) => this.errorAlert(error));
      } else {
        this.like({ type: "news", id: this.news.id })
          .then((response) => {
            this.countLike = this.countLike + 1;
            this.newsCopy.current_user_like = response.data.data;
          })
          .catch((error) => this.errorAlert(error));
      }
    },
    updateQuiz(newsId) {
      this.getQuizAction(newsId)
        .then((response) => {
          this.newsCopy.poll = response.data.data.poll;
        })
        .catch((error) => this.errorAlert(error));
    },
    // Скрываем большое количество текста
    hideBigText(text) {
      if (text.length >= 350) {
        this.bigTextTrigger = true;
      }
    },

    // Показываем большое количество текста
    showBigText(text) {
      this.bigTextTrigger = false;
    },

    // Работа с комментами
    async getCommentsNewsInfo() {
      this.changeLoading(true);
      this.getCommentsInfoAction({
        newsId: this.news.id,
        commentsLength: this.commentsLength
      })
        .then((response) => {
          this.countComments =
            response.data.data.items.length +
            response.data.data.items.reduce((totalCount, comment) => {
              return totalCount + comment.count_comments;
            }, 0);
          this.commentsList = response.data.data;
          this.changeLoading(false);
        })
        .catch((error) => {
          this.changeLoading(false);
          this.errorAlert(error);
        });
    },
    expendCommentLength() {
      this.commentsLength = 300;
      this.getCommentsNewsInfo();
    },
    setReplyComment(comment) {
      this.replyComment = comment;
    },

    // Получение опроса

    // Добавление просмотра для новости
    createView() {
      this.createViewAction(this.news.id).then(() => {
        this.isViews = true;
      });
    },

    // Включение мода редактирования новости
    setNewsEditMode() {
      this.newsEditMode = true;
      this.showSettingsModal = false;
      this.$emit("setGlobalEditMode");
    },

    // Отключение мода редактирования новости
    closeNewsEditMode() {
      this.newsEditMode = false;
      this.$emit("closeGlobalEditMode");
    },

    // Отключение loader
    closeLoadingByChild() {
      this.newsEditMode = false;
      this.changeLoading(false);
      this.$emit("closeGlobalEditMode");
    }
  },
  computed: {
    ...mapState("NewsModule", ["newsToUpdate", "currentReplyableComment", "newsItems"]),
    setId() {
      return `news-component-${this.news.id}`;
    },
    // Получения ID текущего пользователя в системе
    getUserId() {
      return this.$store.getters.getUserId;
    },

    // Получаем инфу о юзере в системе
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    editMode() {
      return (
        !this.globalEditMode && (this.newsAdmin || this.news.author_user_id === this.getUserId)
      );
    },

    // Проверка на время после создания новости
    hoursBeforeNews() {
      if (
        new Date().getTime() / 3600000 -
          format(parseISO(this.news.datetime_of_creation), "T") / 3600000 >=
        3
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    newsToUpdate(val) {
      if (this.news.id === val) {
        this.getNewsItemInfo();
        this.setNewsToUpdate(0);
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.loader {
  margin: auto;
}
.news-component {
  display: flex;
  flex-flow: column;
  width: 617px;
  padding: 25px 0 40px 0;
  margin: 0 auto 8px;
  background: #ffffff;
  border: 1px solid #eae8f0;
  @media screen and (max-width: $md) {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  &__container {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-flow: column;
    @media screen and (max-width: $md) {
      padding-bottom: 60px;
    }
    @media screen and (max-width: $xs) {
      padding: 0 16px 60px;
    }
    /deep/ {
      .quiz-component {
        @media screen and (max-width: $xs) {
          padding: 8px;
        }
        &__users-footer {
          @media screen and (max-width: $xl) {
            flex-direction: column;
            align-items: flex-start;
          }
          @media screen and (max-width: $md) {
            flex-direction: row;
            align-items: center;
          }
          @media screen and (max-width: $sm) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        &__users-footer-count {
          @media screen and (max-width: $xl) {
            margin-left: 0;
          }
        }
      }
    }
  }
  &__comments {
    padding-left: 24px;
    padding-right: 24px;
    @media screen and (max-width: $xs) {
      padding-right: 16px;
      padding-left: 16px;
    }
    .loader-new {
      &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__view-all {
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-subdued;
    text-align: center;
    @media screen and (max-width: $xs) {
      font-size: 10px;
      line-height: 16px;
    }
  }
}
</style>
