<template>
  <div class="timeline-vacation__member-item"></div>
</template>

<script>
export default {
  name: "EmptyRowMemberList",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.timeline-vacation {
  &__member-item {
    display: flex;
    align-items: center;
    min-height: 48px;
    margin-bottom: 24px;
    padding-right: 8px;
  }
  &__member-list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    width: 260px;
    border-right: 1px solid $border-default;
    background-color: $bg-gray-75;

    @media (max-width: $sm) {
      max-width: 160px;
    }
  }

  &__member-item_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    color: $text-disabled;
    font-size: 14px;
    line-height: 20px;
    width: 260px;
    min-height: 67vh;
  }
  &__member-item_one-member {
    display: flex;
    width: 260px;
    height: 59vh;
  }
}
</style>
