var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination-widget"},[_c('div',{staticClass:"pagination-widget__container"},[(_vm.countPages)?_c('div',{staticClass:"pagination-widget__paginate"},[_c('button',{staticClass:"pagination-widget__button",class:[
          'pagination-widget__button_prev',
          _vm.activeTab === 0 ? 'pagination-widget__button_disabled' : ''
        ],attrs:{"type":"button"},on:{"click":_vm.prevPage}},[_c('i',{staticClass:"icon-left"})]),_c('div',{staticClass:"pagination-widget__items"},_vm._l((_vm.countPages),function(page,idx){return (
            idx === 0 ||
            idx === _vm.activeTab ||
            idx === _vm.activeTab + 1 ||
            (_vm.activeTab === 2 ? idx === _vm.activeTab - 1 : false) ||
            idx === _vm.activeTab + 2 ||
            (_vm.activeTab === _vm.countPages - 1
              ? idx === _vm.activeTab - 1 || idx === _vm.activeTab - 2
              : false) ||
            (_vm.activeTab === _vm.countPages - 2 ? idx === _vm.activeTab - 1 : false) ||
            (_vm.activeTab === _vm.countPages - 3 ? idx === _vm.activeTab - 1 : false) ||
            idx === _vm.countPages - 1
          )?_c('div',{key:(page + "_" + idx),class:[
            'pagination-widget__item',
            idx === _vm.activeTab ? 'pagination-widget__item_active' : ''
          ],on:{"click":function($event){return _vm.updatePage(idx)}}},[(page === _vm.countPages && _vm.activeTab <= _vm.countPages - 4)?_c('span',{staticClass:"pagination-widget__points pagination-widget__points_next"},[_vm._v(" ... ")]):_vm._e(),_vm._v(" "+_vm._s(page)+" "),(page === 1 && _vm.activeTab >= 3)?_c('span',{staticClass:"pagination-widget__points pagination-widget__points_prev"},[_vm._v(" ... ")]):_vm._e()]):_vm._e()}),0),_c('button',{staticClass:"pagination-widget__button",class:[
          'pagination-widget__button_next',
          _vm.activeTab === _vm.countPages - 1 ? 'pagination-widget__button_disabled' : ''
        ],attrs:{"type":"button"},on:{"click":_vm.nextPage}},[_c('i',{staticClass:"icon-right"})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }