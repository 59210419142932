<template>
  <ul class="statistics__list">
    <li class="statistics__item">
      <IconWidgets :name="'sunset'" :fontSize="27" :color="'#A177FF'" :background="'#F1EFFA'" />
      <div class="statistics__wrap">
        <p class="statistics__subtitle">Отпуск</p>
        <p class="statistics__content">{{ setTimeFormatHours(statistic.vacations) }} часов</p>
      </div>
    </li>
    <li class="statistics__item">
      <IconWidgets :name="'bandage'" :fontSize="27" :color="'#07B6AB'" :background="'#E8FFFD'" />
      <div class="statistics__wrap">
        <p class="statistics__subtitle">Больничный</p>
        <p class="statistics__content">{{ setTimeFormatHours(statistic.sick_leaves) }} часов</p>
      </div>
    </li>
    <li
      class="statistics__item statistics__item_black-sphere"
      :class="{ 'statistics__item_no-time': !isShowTimeTracker }"
      @click="$emit('showBlackSphereModal')"
    >
      <BlackSphereWidgetNew :blackSphere="setBlackSphere" />
    </li>
  </ul>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import IconWidgets from "@/assets/aop-icons/IconWidgets";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import BlackSphereWidgetNew from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereWidget/BlackSphereWidgetNew";
import BlackSphere from "@/modules/BlackSphere/store";

export default {
  name: "StatisticsList",
  mixins: [formatDateAndTime],
  props: {
    blackSphere: {
      type: Array,
      default: () => {
        return [];
      }
    },
    statistic: {
      type: Object,
      default: () => {}
    },
    isShowTimeTracker: {
      type: Boolean,
      default: false
    }
  },
  components: { BlackSphereWidgetNew, IconWidgets },
  data() {
    return {
      showTooltip: false
    };
  },
  mounted() {},
  methods: {
    hideTooltip() {
      this.showTooltip = false;
    }
  },
  computed: {
    setBlackSphere() {
      return this.blackSphere.length ? this.blackSphere[0] : null;
    },
    setDateAfterChangeBSM() {
      return moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").isAfter(
        moment(new Date("2021-11-01"))
      );
    },
    setDateSameChangeBSM() {
      return (
        moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").format(
          "YYYY-MM-DD"
        ) === moment(new Date("2021-11-01")).format("YYYY-MM-DD")
      );
    },
    setMathRoundPercent() {
      const reqExp = new RegExp("^99.\d+");
      if (reqExp.test(this.blackSphere[0].percent)) {
        return "99";
      } else {
        return Math.round(this.blackSphere[0].percent);
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.statistics {
  &__list {
    margin: 0;
    padding: 0 28px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1700px) {
      padding: 0 24px;
      justify-content: flex-start;
    }
    @media (max-width: 1300px) {
      padding: 0;
    }
    @media (max-width: $xxxl) {
      padding: 0 24px;
    }

    @media (max-width: $xl) {
      //width: 45%;
      flex-wrap: wrap;
      margin-bottom: 24px;
    }

    @media (max-width: 950px) {
      //width: 36%;
    }

    @media (max-width: 820px) {
      //width: 30%;
      //padding: 16px 24px 0 0;
      //margin-bottom: 16px;
    }

    @media (max-width: $md) {
      //width: 36%;
      //padding: 24px 24px 0 0;
      //margin-bottom: 24px;
    }

    @media (max-width: 685px) {
      //width: 30%;
      //padding: 16px 24px 0 0;
      //margin-bottom: 16px;
    }

    @media (max-width: 620px) {
      //width: 25%;
    }

    @media (max-width: $sm) {
      width: 100%;
      padding: 0 16px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @media (max-width: 1700px) {
      margin-right: 16px;
    }

    &_black-sphere {
      cursor: pointer;
      @media (max-width: 1700px) {
        margin-right: 0;
        margin-top: 16px;
      }
      @media (max-width: 1300px) {
        margin-top: 0;
      }
      @media (max-width: 423px) {
        margin-top: 16px;
      }
    }

    &_no-time {
      @media (max-width: 1700px) {
        margin-right: 0;
        margin-top: 0;
      }
      @media (max-width: 409px) {
        margin-top: 16px;
      }
    }

    /deep/ {
      .icon-widget {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $xxxl) {
          width: 34px;
          height: 34px;
          border-radius: 8px;

          i {
            font-size: 16px !important;
          }
        }
      }

      .icon-info {
        margin-left: 4px;
        font-size: 12px;
        color: $text-body-muted;
        cursor: pointer;

        &:hover {
          color: $icon-hovered;
        }
      }

      .black-sphere__tooltip {
        @media (max-width: 1480px) {
          white-space: normal;
          width: 310px;
          left: 110%;
        }

        @media (max-width: $xxl) {
          width: 255px;
          left: 110%;
        }

        @media (max-width: $xl) {
          width: 255px;
          left: unset;
          right: 0;
          top: 120%;
        }

        @media (max-width: $sm) {
          width: 190px;
        }

        @media (max-width: $xs) {
          width: 155px;
          left: 110%;
          right: unset;
          top: -20px;
        }

        @media (max-width: $xxs) {
          width: 120px;
        }

        @media screen and (max-width: 568px) and (max-height: 450px) {
          white-space: normal;
          width: 255px;
          left: unset;
          right: 0;
          top: 150%;
        }
      }
    }
  }

  &__subtitle {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: $text-body-dark;

    @media (max-width: $xxxl) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__tooltip {
    position: absolute;
    padding: 5px 8px;
    color: $surface-default;
    transition: all 0.3s ease;
    top: -28px;
    left: 99%;
    font-size: 14px;
    line-height: 20px;
    background-color: $bg-surface-dark;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
    white-space: nowrap;
    z-index: 10;

    @media (max-width: 1480px) {
      white-space: normal;
      width: 310px;
      left: 110%;
    }

    @media (max-width: $xxl) {
      white-space: normal;
      width: 255px;
      left: 110%;
    }

    @media (max-width: $xl) {
      white-space: normal;
      width: 255px;
      left: unset;
      right: 0;
      top: 99%;
    }

    @media (max-width: $sm) {
      white-space: normal;
      width: 190px;
      left: 110%;
      right: unset;
      top: -20px;
    }

    @media (max-width: $xxs) {
      white-space: normal;
      width: 155px;
      left: 110%;
      right: unset;
      top: -20px;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      white-space: normal;
      width: 255px;
      left: unset;
      right: 0;
      top: 150%;
    }

    a {
      color: $action-secondary;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $action-secondary-hovered;
      }

      &:active {
        color: $action-secondary-pressed;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: $text-body-muted;

    span {
      display: block;
      margin-left: auto;
    }

    @media (max-width: $xxxl) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  .icon-info {
    margin-left: 4px;
    font-size: 12px;
    color: $text-body-muted;

    &:hover {
      color: $icon-hovered;
    }
  }
}
</style>
