<template>
  <div
    class="attached-modal"
    :style="showAttachModal ? 'transform: translateX(0px)' : 'transform: translateX(100%)'"
  >
    <div
      class="attached-modal__header"
      :class="[activeInput ? 'attached-modal__header_enabled' : 'attached-modal__header_disabled']"
    >
      <i class="icon-arrow-short" @click="hideAttachModal"></i>
      <label v-if="attachedModalValue && !showSearch">
        {{ attachedModalValue.name }}:
        <span>{{ setCount }}</span>
      </label>
      <input
        class="attached-modal__search"
        type="text"
        v-if="attachedModalValue && showSearch"
        @focus="inputFocus"
        @blur="inputBlur"
        @input="changeInput"
        ref="input"
        v-model="value"
      />
      <span
        class="attached-modal__container"
        v-if="attachedModalValue.val === 'members' || attachedModalValue.val === 'comment'"
      >
        <i class="icon-search" @click="handleShowSearch"></i>
        <i
          class="icon-user-add"
          @click="addMember"
          v-if="isAdmin && attachedModalValue.val === 'members'"
        ></i>
      </span>
    </div>
    <component v-if="showAttachModal" :is="returnComponent" :files="setFiles"></component>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AttachedModal",
  mixins: [],
  props: {
    showAttachModal: {
      type: Boolean,
      require: true
    }
  },
  components: {},
  data() {
    return {
      showComponent: false,
      showSearch: false,
      activeInput: false,
      searchTimer: null,
      usersMember: [],
      value: ""
    };
  },
  mounted() {
    this.usersMember = this.users
      .map((item) => item)
      .sort(function (a, b) {
        if (a.is_moderator === b.is_moderator) {
          return a.info.full_name.localeCompare(b.info.full_name);
        } else {
          return a.is_moderator ? -1 : 1;
        }
      });
  },
  methods: {
    ...mapActions("MessengerModule", [
      "findAttachedFiles",
      "setAttachedFiles",
      "closeReactionList",
      "observeModalItem",
      "observeModalItem",
      "searchThreads",
      "closeThreads"
    ]),
    hideAttachModal() {
      this.$emit("changeShowAttachModal", false);
      this.activeInput = false;
      this.showSearch = false;
      this.usersMember = this.users
        .map((item) => item)
        .sort(function (a, b) {
          if (a.is_moderator === b.is_moderator) {
            return a.info.full_name.localeCompare(b.info.full_name);
          } else {
            return a.is_moderator ? -1 : 1;
          }
        });
      if (this.attachedModalValue.val === "comment") {
        this.closeThreads();
      }
      if (this.attachedModalValue.val === "reaction") {
        this.closeReactionList();
      }
    },
    addMember() {
      this.observeModalItem("add-member");
    },
    handleShowSearch() {
      this.showSearch = !this.showSearch;
      this.value = "";
      if (this.showSearch) {
        this.$nextTick(() => {
          document.querySelector(".attached-modal__search").focus();
        });
      }
    },

    inputFocus() {
      this.activeInput = true;
    },
    inputBlur() {
      this.activeInput = false;
    },
    changeInput() {
      if (this.attachedModalValue.val === "members") {
        const text = this.value.replace("@", "");
        this.usersMember = Array.from(
          new Set(
            this.searchUser(text.toLowerCase(), "users", "full_name").concat(
              this.searchUser(text.toLowerCase(), "users", "nickname")
            )
          )
        )
          .map((item) => item)
          .sort(function (a, b) {
            if (a.is_moderator === b.is_moderator) {
              return a.info.full_name.localeCompare(b.info.full_name);
            } else {
              return a.is_moderator ? -1 : 1;
            }
          });
      } else {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          this.searchThreads({ text: this.value });
        }, 200);
      }
    }
  },
  computed: {
    ...mapGetters("MessengerModule", ["searchUser"]),
    ...mapState("MessengerModule", [
      "attachedModalValue",
      "currentChat",
      "users",
      "attachedFiles",
      "attachedCount",
      "reactionList",
      "threads"
    ]),
    setCount() {
      return this.attachedModalValue.val === "reaction" ? this.attachedCount : this.setFiles.length;
    },
    returnComponent() {
      return this.attachedModalValue
        ? () =>
            import(
              `@/modules/Messenger/components/ChatModal/AttachedModal/${this.attachedModalValue.component}.vue`
            )
        : "";
    },
    setFiles() {
      switch (this.attachedModalValue.val) {
        case "members": {
          return this.usersMember;
        }
        case "comment": {
          return this.threads;
        }
        case "reaction": {
          return this.reactionList;
        }
        default: {
          return this.attachedFiles;
        }
      }
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isAdmin() {
      return (
        this.users.find((item) => item.user_id === this.getUserId).is_moderator || this.isAdminChat
      );
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    }
  },
  watch: {
    users() {
      this.usersMember = this.users
        .map((item) => item)
        .sort(function (a, b) {
          if (a.is_moderator === b.is_moderator) {
            return a.info.full_name.localeCompare(b.info.full_name);
          } else {
            return a.is_moderator ? -1 : 1;
          }
        });
    },
    showAttachModal(val) {
      if (val) {
        this.findAttachedFiles({
          type: this.attachedModalValue.val,
          chat_id: this.currentChat.channel.id
        });
      } else {
        this.setAttachedFiles([]);
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.attached-modal {
  position: absolute;
  top: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 344px;
  height: 100%;
  min-height: 100%;
  background: white;
  transition: 0.5s;
  z-index: 10;
  @media screen and (max-width: $xxxl) {
    max-width: 260px;
  }
  @media screen and (max-width: $lg) {
    width: 100%;
    max-width: 375px;
  }
  @media screen and (max-width: $xs) {
    top: 0;
    overflow-y: auto;
  }
  .icon-arrow-short {
    color: $icon-subdued;
    transform: rotate(-90deg);
    margin-right: 16px;
    cursor: pointer;
  }
  &__wrap {
    display: flex;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 24px;
    width: 100%;
    height: 58px;
    position: relative;
    &_enabled {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 2;
        background: #6a67ce;
        animation-name: border;
        animation-timing-function: ease;
        animation-duration: 0.5s;
        transform: scale(1);
        transform-origin: center;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        background: #d3d2e0;
        z-index: 1;
      }
    }
    &_disabled {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 2;
        background: #eae8f0;
        transform: scale(1);
        transform-origin: center;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        background: #eae8f0;
        z-index: 1;
      }
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #343748;
    }
  }
  &__container {
    display: flex;
    margin-left: auto;
  }
  .icon-search,
  .icon-user-add {
    transform: rotate(0) scale(-1, 1);
    color: $icon-subdued;
    border-radius: 4px;
    transition: all 0.3s ease;
    margin-right: 16px;
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        color: $icon-hovered;
      }
    }
  }
  .icon-search {
    transform: scale(1, 1);
    margin-right: 8px;
  }
  &__search {
    all: unset;
    margin-bottom: 1px;
  }
  @keyframes border {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}
</style>
