<template>
  <div class="time-input" v-click-outside="saveTime">
    <div
      class="time-input__wrap"
      :class="[
        value ? 'time-input__wrap_complete' : '',
        isFocus ? 'time-input__wrap_focus' : '',
        readonly ? 'time-input__wrap_readonly' : ''
      ]"
    >
      <input
        ref="input"
        @click="handleClick"
        type="text"
        :readonly="readonly"
        v-model="timeString"
        v-mask="'##д ##ч ##м'"
        class="time-input__input"
        :placeholder="placeholder"
        spellcheck="false"
      />
      <i class="icon-clock"></i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";

export default {
  name: "TimeInput",
  mixins: [],
  model: {
    event: "update:time"
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: "00д 00ч 00м"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      timeString: "",
      isFocus: false
    };
  },
  mounted() {
    if (this.value > 0) {
      this.convertSeconds(this.value);
    }
  },
  methods: {
    handleClick() {
      this.isFocus = true;
    },
    convertSeconds(seconds) {
      let days = String(Math.floor(seconds / 86400));
      let hours = String(Math.floor((seconds - days * 86400) / 3600));
      let minutes = String(Math.floor((seconds - days * 86400 - hours * 3600) / 60));
      if (days.length === 1) days = "0" + days;
      if (hours.length === 1) hours = "0" + hours;
      if (minutes.length === 1) minutes = "0" + minutes;
      this.timeString = `${days}д ${hours}ч ${minutes}м`;
    },
    saveTime() {
      if (this.isFocus && this.timeString.length) {
        let timeArr = this.timeString.split(" ");
        for (let i in timeArr) {
          let el = timeArr[i].split("");
          el.pop();
          const index = el.findIndex((item) => item === "_");
          if (index !== -1 && el.some((item) => item !== "_")) {
            el.splice(index, 1);
          }
          el = el.map((item) => (item === "_" ? 0 : item));
          timeArr[i] = Number(el.join(""));
        }
        let [days, hours, minutes] = timeArr;
        if (hours > 23) hours = 23;
        if (minutes > 59) minutes = 59;
        const seconds = days * 86400 + hours * 3600 + minutes * 60;
        this.$emit("update:time", seconds);
        this.$emit("input", seconds);
        this.convertSeconds(seconds);
        this.isFocus = false;
      }
    }
  },
  computed: {},
  watch: {
    value() {
      if (this.value > 0) {
        this.convertSeconds(this.value);
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.time-input {
  position: relative;
  width: 100%;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafe;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;

    &_complete {
      background: $surface-default;
      border: 2px solid $border-disabled;
    }

    &_focus {
      background: $surface-default;
      border: 2px solid $action-primary-accent;
    }
  }

  .icon-clock {
    margin-right: 4px;
    font-size: 20px;
    color: $icon-subdued;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #a7a9c0;
    outline: none;
    transition: all 0.2s ease;
    height: 40px;
    background: transparent;
    border-radius: 8px;
    border: 2px solid transparent;

    &::placeholder {
      color: $text-subdued;
    }
  }

  &:hover {
    .time-input__wrap {
      background: #fafafe;
      border: 2px solid $action-primary-accent;
    }
  }

  &:focus {
    .time-input__wrap {
      background: $surface-default;
      border: 2px solid $action-primary-accent;
    }
  }
}
</style>
