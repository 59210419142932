import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";

class BlackSphereService extends Service {
  getBlackSphereMarksForTable(params): Response<any> {
    return httpClientV2.get<any>("timemanager/black_spheres/table", { params }).then((resp) => {
      return resp.data;
    });
  }

  getBlackSphereMarksForStatistic(params): Response<any> {
    return httpClientV2.get<any>("timemanager/black_spheres/statistic", { params }).then((resp) => {
      return resp.data;
    });
  }

  getBlackSphereMarksForProfile(params): Response<any> {
    return httpClientV2
      .get<any>("timemanager/black_spheres/for_profile", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  createBlackSphereMarkForPerson(data): Response<any> {
    return httpClientV2.post<any>("timemanager/black_spheres/create", data).then((resp) => {
      return resp.data;
    });
  }

  createBlackSphereMarkForBot(data): Response<any> {
    return httpClientV2.post<any>("timemanager/black_spheres/create_for_bot", data).then((resp) => {
      return resp.data;
    });
  }

  deleteBlackSphereMark(data): Response<any> {
    return httpClientV2.delete("timemanager/black_spheres/delete", { data }).then((resp) => {
      return resp.data;
    });
  }
}

export default new BlackSphereService();
