














import { Component, Prop, Vue } from "vue-property-decorator";
import ActionItem from "@/aopV2/components/UIComponents/ActionList/ActionItem";

export interface IActionItem {
  name: string;
  type?: string;
  id: number;
  category_name?: string;
}

@Component<ActionList>({
  components: { ActionItem }
})
export default class ActionList extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  list!: Array<IActionItem> | Array<string>;
  @Prop({
    default: () => {},
    type: Object
  })
  activeItem!: IActionItem | string;
  @Prop({
    default: "",
    type: String
  })
  listId!: string;

  handleItem(type) {
    this.$emit("handleItem", type);
  }

  closeList() {
    this.$emit("closeList");
  }
}
