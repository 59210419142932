<template>
  <div class="create-channel" @mousedown.self="confirmCloseCurtain">
    <ConfirmModal
      v-if="confirmCloseModal"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="confirmCloseModal = false"
      @accept="closeCurtain"
    />
    <div class="create-channel__container">
      <div class="create-channel__header">
        <button
          v-if="isMobileWindowSize"
          class="modal__hide"
          type="button"
          @click="confirmCloseCurtain"
        >
          <i class="icon-arrow-short"></i>
        </button>
        <h2>Создать канал</h2>
      </div>
      <div class="create-channel__body">
        <div class="create-channel__title">
          <FileUploadChannelAvatar
            v-bind="{
              multiple: false,
              drop: false,
              dropDirectory: false,
              fileDrop: false
            }"
            :isEditable="true"
            :editValue="iconChannel ? returnSrcFile(iconChannel) : ''"
            @changeIconChanel="changeIconChanel"
          />
          <Input
            placeholder="Название канала"
            label="Название канала"
            maxlength="64"
            v-model.trim="nameChannel"
          />
        </div>
        <div class="create-channel__users">
          <div class="create-channel__moderators">
            <p>Модераторы</p>
            <Select
              placeholder="Выберите сотрудника"
              v-model="adminsChannel"
              :items="allUsers"
              :maxCount="4"
              :showButtonPanel="false"
            />
          </div>
          <div class="create-channel__members">
            <p>Участники</p>
            <Select placeholder="Выберите сотрудника" v-model="membersChannel" :items="allUsers" />
          </div>
        </div>
        <div class="create-channel__settings">
          <div class="create-channel__open-channel switchers">
            <p>Открытый канал</p>
            <SwitchIOS v-model="isPublic" :key="1" />
          </div>
          <div class="create-channel__enable-comments switchers">
            <p>Включить комментирование</p>
            <SwitchIOS v-model="isComments" :key="2" />
          </div>
          <div class="create-channel__default-access switchers">
            <Label
              :label="'Доступ по умолчанию'"
              :tooltip="'Новые сотрудники становятся участниками канала'"
            />
            <SwitchIOS :key="3" v-model="isDefault" />
          </div>
          <div class="create-channel__creation-access switchers">
            <p>Публиковать новости могут только модераторы</p>
            <SwitchIOS :key="4" v-model="isPublicationRight" />
          </div>
        </div>
      </div>
      <div class="create-channel__footer">
        <Button :title="'Отменить'" :type="'cancel'" @handleButton="confirmCloseCurtain" />
        <Button :title="'Создать'" @handleButton="createChannel" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import InputText from "@/components/Widgets/InputText/InputText";

import { domain, domainStorage } from "@/globalVariables";
import FileUploadChannelAvatar from "@/modules/News/components/Channel/FileUploadChannelAvatar";
import SelectPicker from "@/components/Widgets/Select/SelectPicker/SelectPicker";
import AddingMembersList from "@/components/Widgets/User/AddingMembers/AddingMembersList";
import ColorPicker from "@/modules/News/components/Channel/ColorPicker/ColorPicker";
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Select from "@/modules/UiKit/components/Select/Select";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import Button from "@/modules/UiKit/components/buttons/Button";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import Label from "@/modules/Isp/components/IspProblem/Label";
import httpClient from "@/api/client/httpClient";

export default {
  name: "CurtainCreateChannel",
  mixins: [uploadFilesMixin],
  props: {
    editValue: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {
    Label,
    ConfirmModal,
    SwitchIOS,
    Select,
    Input,
    ColorPicker,
    AddingMembersList,
    SelectPicker,
    FileUploadChannelAvatar,
    InputText,
    Button
  },
  data() {
    return {
      nameChannel: "",
      adminsChannel: [],
      membersChannel: [],
      iconChannel: "",
      isPublic: false,
      isComments: true,
      isDefault: false,
      isPublicationRight: false,
      fileUploader: [],
      pullName: [],
      confirmCloseModal: false,
      publicOptionData: [
        {
          id: true,
          name: "Открытый"
        },
        {
          id: false,
          name: "Закрытый"
        }
      ],
      commentOptionData: [
        {
          id: true,
          name: "Разрешить"
        },
        {
          id: false,
          name: "Запретить"
        }
      ],
      publicationRightOptionData: [
        {
          id: true,
          name: "Все участники"
        },
        {
          id: false,
          name: "Администратор"
        }
      ],
      accessOptionData: [
        {
          id: true,
          name: "Да"
        },
        {
          id: false,
          name: "Нет"
        }
      ],
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.getAllUsersAction();
    window.addEventListener("resize", this.showArrowHide);
  },
  methods: {
    ...mapActions("NewsModule", [
      "changeModalAddChannel",
      "openSettingsModal",
      "getChannelInfo",
      "getNewsItems",
      "createChannelAction",
      "editChannelAction"
    ]),
    ...mapActions("IspModule", ["getAllUsersAction"]),

    ...mapMutations("NewsModule", [types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL]),
    closeCurtain() {
      this[types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL](false);
    },
    confirmCloseCurtain() {
      if (
        this.iconChannel.length ||
        this.nameChannel.length ||
        this.adminsChannel.length ||
        this.membersChannel.length
      ) {
        this.confirmCloseModal = true;
      } else {
        this.closeCurtain();
      }
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    createChannel() {
      if (!this.checkValidation()) return false;
      const newChannel = {
        icon: this.iconChannel,
        name: this.nameChannel,
        is_public: this.isPublic,
        is_include_comments: this.isComments,
        is_user_auto_connect: this.isDefault,
        can_post: !this.isPublicationRight,
        admin_users: this.adminsChannel.map((item) => item.id),
        member_users: this.membersChannel.map((item) => item.id)
      };
      this.createChannelAction(newChannel)
        .then(() => {
          this.getChannelInfo().then(() => this.getNewsItems());
          this.closeCurtain();
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: "Канал успешно создан!"
          });
        })
        .catch((error) => this.errorAlert(error));
    },
    checkValidation() {
      if (!this.nameChannel) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите имя Канала!`
        });
        return false;
      }
      if (this.nameChannel.length > 64) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Имя Канала не должно содержать более 64 символов!`
        });
        return false;
      }
      if (this.adminsChannel.length > 4) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Админов не может быть больше 4!`
        });
        return false;
      }
      return true;
    },
    changeIconChanel(file) {
      this.fileUploader = file;
      this.createChunksPoolFiles();
    },
    async createChunksPoolFiles() {
      if (!this.fileUploader.length) {
        return null;
      }
      this.createPull(this.fileUploader);
    },
    createPull(arr) {
      if (!arr.length) {
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].file_name
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.chunk_pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      let base64 = arr[0].file.replace(arr[0].file.substring(0, arr[0].file.search(",") + 1), "");
      this.chunksArr = base64.match(/.{1,500000}/g);

      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: "POST",
        data: [this.pullName]
      })
        .then((response) => {
          this.iconChannel = response.data.data.path;
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    showArrowHide() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapState("NewsModule", ["showCurtainCreateChannel"]),
    ...mapState("IspModule", ["allUsers"]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
  @media (max-width: $xxs) {
    font-size: 25px;
  }
}
.create-channel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__header {
    display: flex;
    margin-bottom: 56px;

    .modal__hide {
      padding: 0;
      border: none;
      background: transparent;
      outline: none;
      transform: rotate(-90deg);
      margin-right: 15px;

      i {
        color: $icon-subdued;
        font-size: 20px;
      }

      &:hover i {
        color: $icon-hovered;
      }

      &:active i {
        color: $icon-pressed;
      }
    }
  }

  &__container {
    position: fixed;
    width: 711px;
    height: 100%;
    right: 0;
    top: 0;
    padding: 32px;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__body {
    /deep/ {
      .uploader__img-empty {
        @media (max-width: $xxs) {
          width: 70px;
          height: 70px;
        }
      }

      .uploader__mask {
        @media (max-width: $xxs) {
          width: 70px;
          height: 70px;
        }
      }
      .uploader__container {
        @media (max-width: $sm) {
          left: 0;
        }
      }
      .uploader__main {
        @media (max-width: $sm) {
          padding-bottom: 15px;
        }
      }

      .uploader__footer {
        @media (max-width: $sm) {
          flex-direction: column-reverse;
          padding-top: 0;
        }
      }

      .select-bg__selected-list {
        max-height: 180px !important;
        overflow: auto;
        align-items: flex-start;
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    /deep/ {
      .input {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }
  &__users {
    margin-bottom: 56px;
    p {
      font-size: 18px;
      color: $text-primary;
      margin-bottom: 24px;
      @media (max-width: $xs) {
        font-size: 16px;
      }
    }
  }
  &__moderators {
    margin-bottom: 32px;
  }
  &__settings {
    width: 100%;
    .switchers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
      p {
        font-size: 18px;
        color: $text-default;
        @media (max-width: $xs) {
          font-size: 16px;
        }
      }
    }
    /deep/ {
      .switch-widget {
        width: unset;
      }
      .label {
        margin-bottom: 0;
        label {
          color: $text-default;
          @media (max-width: $xs) {
            font-size: 16px;
          }
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
