<template>
  <router-link
    class="user-info"
    :to="{ name: 'UserInfo', params: { user_id: user.id } }"
    target="_blank"
  >
    <img class="user-info__avatar" :src="returnSrcFile(user.avatar)" alt="Аватарка пользователя" />
    <div class="user-info__wrap">
      <p class="user-info__name">{{ user.full_name }}</p>
      <p class="user-info__role">{{ setRolesUser }}</p>
    </div>
  </router-link>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "UserInfo",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    setRolesUser() {
      return this.user.roles.length ? this.user.roles[0].name : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.user-info {
  display: flex;
  align-items: center;

  &__avatar {
    display: block;
    width: 120px;
    height: 120px;
    margin-right: 24px;
    border-radius: 16px;
    object-fit: cover;
    @media (max-width: $xxl) {
      width: 80px;
      height: 80px;
      margin-right: 12px;
    }
    @media (max-width: $xs), (max-height: $sm) {
      width: 40px;
      height: 40px;
    }
  }

  &__name {
    margin: 0 0 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__role {
    display: -webkit-box;
    max-width: 220px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;


    @media (max-width: $xs), (max-height: $sm) {
      font-size: 12px;
      max-width: 280px;
    }
  }
}
</style>
