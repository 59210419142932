<template>
  <section class="black-sphere table-container">
    <BlackSphereMarksWidgets
      @changeMonthYear="changeMonthYear"
      :isAdmin="isAdminBlackSphereMarks"
      @selectGotUsers="handleGotUser"
      @selectPutUsers="handlePutUser"
      @selectMarks="handleMark"
    />
    <div class="black-sphere__container">
      <Table
        :tableHead="tableHead"
        :tableData="tableDataBlackSphereMarks"
        :loader="loader"
        :params="setTableDataParams"
        :isShowLoader="isShowLoader"
        :isScrollObserver="true"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <BlackSphereMarksCellContent
            :row="props.row"
            :cell="props.cell"
            :isAdminBlackSphereMarks="isAdminBlackSphereMarks"
            @askDeleteBlackSphereMarks="askDeleteBlackSphereMarks"
          />
        </template>
      </Table>
    </div>
    <BlackSphereMarksCommentsModal v-if="showBlackSphereModalComment" />
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="showConfirmModal = false"
      @accept="confirmParams.callback()"
    />
    <transition name="slide">
      <BlackSphereModal v-if="showBlackSphereModal" :isAdmin="isAdminBlackSphereMarks" />
    </transition>
  </section>
</template>

<script>
import BlackSphereMarksWidgets from "@/modules/Tables/components/TablesStatistics/BlackSphereMarks/BlackSphereMarksWidgets";
import Table from "@/modules/Tables/components/Table/Table";
import BlackSphereMarksCellContent from "@/modules/Tables/components/TablesStatistics/BlackSphereMarks/BlackSphereMarksCellContent";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import BlackSphereMarksCommentsModal from "./BlackSphereMarksModal/BlackSphereMarksCommentsModal";
import BlackSphereModal from "@/modules/TaskManager/components/Modal/BlackSphereModal/BlackSphereModal";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import BlackSphereService from "@/modules/BlackSphere/Service/BlackSphereService";
import NotificationObserver from "@/helpers/UserNotification";
import moment from "moment";

export default {
  name: "BlackSphereMarks",
  mixins: [],
  props: [],
  components: {
    BlackSphereMarksCommentsModal,
    BlackSphereMarksCellContent,
    Table,
    BlackSphereMarksWidgets,
    BlackSphereModal,
    ConfirmModal
  },
  data() {
    return {
      tableHead: [
        { name: "Сотрудник", sort: false, key: "got_user" },
        { name: "Выставил оценку", sort: false, key: "put_user" },
        { name: "Оценка", sort: true, valueSort: "mark", key: "mark" },
        { name: "Дата получения", sort: true, valueSort: "marked_at", key: "marked_at" },
        { name: "Дата добавления", sort: true, valueSort: "created_at", key: "created_at" },
        { name: "Ссылка/Комментарий", sort: false, key: "task_link" },
        { name: "", sort: false, key: "control" }
      ],
      timerId: 0,
      isShowLoader: false,
      showConfirmModal: false,
      confirmParams: {
        title: "Удалить оценку",
        description: `Вы уверены, что хотите удалить оценку?`,
        callback: () => this.deleteMark()
      },
      currentMarkId: Number,
      loader: false
    };
  },
  mounted() {
    this.getTable();
  },
  methods: {
    getTableDataWithPagination() {
      if (this.pageBlackSphereList && this.countBlackSphereList) {
        if (this.countBlackSphereList > this.tableDataBlackSphereMarks.length) {
          const page = this.pageBlackSphereList + 1;
          BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE](page);
          this.loader = true;
          this.getTable().then(() => {
            this.loader = false;
          });
        }
      }
    },
    handleGotUser(val) {
      BlackSphere[BlackSphereMutationTypes.SET_GOT_USER_FILTER](val);
      this.getTable(true);
    },
    handlePutUser(val) {
      BlackSphere[BlackSphereMutationTypes.SET_PUT_USER_FILTER](val);
      this.getTable(true);
    },
    handleMark(val) {
      BlackSphere[BlackSphereMutationTypes.SET_MARK_FILTER](val);
      this.getTable(true);
    },
    deleteMark() {
      BlackSphere[BlackSphereMutationTypes.DELETE_BLACK_SPHERE_MARK](this.currentMarkId);
      BlackSphereService.deleteBlackSphereMark({ id: this.currentMarkId }).then(() => {
        this.showConfirmModal = false;
        NotificationObserver.notification({
          type: "success",
          message: "Оценка успешно удалена!"
        });
      });
    },
    async getTable(isLoader) {
      if (isLoader) {
        this.isShowLoader = true;
      }
      await BlackSphere.getBlackSphereMarksForTableAction();
      if (isLoader) {
        this.isShowLoader = false;
      }
    },

    changeMonthYear(val) {
      BlackSphere[BlackSphereMutationTypes.SET_DATE_FILTER](val);
      this.getTable(true);
    },
    askDeleteBlackSphereMarks(id) {
      this.showConfirmModal = true;
      this.currentMarkId = id;
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "tableDataBlackSphereMarks",
      "tableParams",
      "totalCount",
      "valueSort",
      "isBlackSphereMarksCommentsModal"
    ]),
    ...mapGetters(["getEndpoints"]),
    valueSort() {
      return BlackSphere.valueSort;
    },
    tableDataBlackSphereMarks() {
      return BlackSphere.blackSphereListForTableList;
    },
    showBlackSphereModal() {
      return TaskManagerModuleV2.showBlackSphereModal;
    },
    showBlackSphereModalComment() {
      return BlackSphere.isShowBlackSphereCommentModal;
    },
    perPageBlackSphereList() {
      return BlackSphere.blackSphereListForTablePerPage;
    },
    pageBlackSphereList() {
      return BlackSphere.blackSphereListForTablePage;
    },
    countBlackSphereList() {
      return BlackSphere.blackSphereListForTableCount;
    },
    isAdminBlackSphereMarks() {
      return this.getEndpoints("BlackSphere", "update");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
  width: 0;
  background: $surface-subdued;
}

::-webkit-scrollbar-thumb {
  background: $surface-pressed;
  border-radius: 8px;
  width: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $action-primary;
  }
}

::-webkit-scrollbar-track {
  margin: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}
.black-sphere {
  height: 88%;

  @media (height: 1366px) {
    height: 95%;
  }

  @media (max-height: 850px) {
    height: 84%;
  }

  @media (max-height: 600px) {
    height: 82%;
  }

  @media (max-height: 320px) {
    height: 68%;
  }
  .black-sphere__container {
    width: 100%;
    height: 90%;
    overflow: scroll;

    @media (max-height: $md) {
      height: 82%;
    }

    @media (max-height: 450px) {
      height: 78%;
    }
    @media (max-height: 320px) {
      height: 68%;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  /deep/ {
    .table__row,
    .table__head {
      width: 100%;
    }

    .table__empty {
      .table__row {
        width: 100%;
      }
    }

    .table__cell {
      @media (max-height: 320px) {
        padding: 8px 0;
      }

      &:first-child,
      &:nth-child(2) {
        width: 70%;
        min-width: 300px;
      }

      &:nth-child(3) {
        width: 20%;
        min-width: 120px;
      }

      &:nth-child(4) {
        width: 35%;
        min-width: 180px;
      }

      &:nth-child(5) {
        width: 35%;
        min-width: 220px;
      }

      &:nth-child(6) {
        min-width: 300px;
      }

      &:last-child {
        width: 100%;
        min-width: 50px;
        padding: 0;
      }
    }
  }
}
</style>
