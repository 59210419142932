




















import { Component, Prop, Vue } from "vue-property-decorator";
import { domainStorage } from "@/globalVariables";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";

@Component({ components: { ButtonIcon, Skeleton } })
export default class UserCard extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  item!: any;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowAvatar!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isName!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isDelete!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isRole!: boolean;
  @Prop({
    default: "default",
    type: String
  })
  imageSize!: string;
  @Prop({
    default: "div",
    type: String
  })
  tag!: string;
  @Prop({
    default: false,
    type: Boolean
  })
  loader!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isIdOnly!: boolean;

  handleDelete() {
    this.$emit("delete", this.user);
  }

  get name() {
    return this.user.full_name && this.isName ? this.user.full_name : "";
  }

  get avatar() {
    return this.user.avatar && this.isShowAvatar ? domainStorage + this.user.avatar : "";
  }

  get role() {
    return this.isRole && this.user?.roles.length ? this.user.roles[0]?.name : "";
  }

  get sizeClass() {
    return `user-card__img_${this.imageSize}`;
  }

  get user() {
    return this.isIdOnly ? this.currentUser : this.item;
  }

  get currentUser() {
    const user = this.$store.getters["UsersModule/userById"](this.item.id) as Array<any>;
    return user.length ? user[0] : null;
  }
}
