<template>
  <div
    class="kanban-setting__unattached-statuses"
    :class="{ 'kanban-setting__unattached-statuses_close': !showUnattachedStatuses }"
  >
    <div class="kanban-setting__caption">
      <Label :label="'Непривязанные статусы'" :tooltip="tooltip" />
    </div>
    <draggable
      class="kanban-setting__list"
      :list="unusedStatuses"
      group="statuses"
      @start="drag = true"
      @end="changeStatusColumn($event, drag)"
    >
      <KanbanStatus v-for="status in unusedStatuses" :key="status.id" :status="status" />
    </draggable>
    <button class="kanban-setting__slide" type="button" @click="toggleShowUnattachedStatuses">
      <i class="icon-double-right"></i>
    </button>
  </div>
</template>

<script>
import Label from "@/modules/UiKit/components/Label/Label";
import KanbanStatus from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanStatus";
import draggable from "vuedraggable";
import KanbanModule, { KanbanMutationTypes } from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "KanbanUnattachedStatuses",
  mixins: [],
  props: {
    statuses: {
      type: Array,
      default: () => []
    },
    showUnattachedStatuses: {
      type: Boolean,
      default: true
    }
  },
  components: {
    KanbanStatus,
    Label,
    draggable
  },
  data() {
    return {
      tooltip:
        "Список статусов, которые не привязаны к столбцам таблицы. Данные статусы не будут отображены на доске."
    };
  },
  mounted() {},
  methods: {
    toggleShowUnattachedStatuses() {
      this.$emit("toggleShow");
    },
    changeStatusColumn(event, drag) {
      drag = false;
      const newBoardColumnId = Number(event.to.parentElement.id);
      const statusId = event.item.id;
      if (newBoardColumnId) {
        KanbanModule.createBoardColumnStatusAction({
          board_column_id: newBoardColumnId,
          flow_status: statusId
        });
      }
    }
  },
  computed: {
    unusedStatuses() {
      return KanbanModule.unusedStatuses;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";

::-webkit-scrollbar {
  height: 0;
  width: 6px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.kanban-setting {
  &__unattached-statuses {
    max-width: 400px;
    width: 400px;
    min-height: 100%;
    background: $surface-default;

    @media (max-width: $md) {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(0);
      transition: all 0.3s ease;
    }

    @media (max-width: $sm) {
      max-width: 200px;
      width: 200px;
    }

    &_close {
      @media (max-width: $md) {
        transform: translateX(390px);
        transform-origin: right;
      }

      @media (max-width: $sm) {
        transform: translateX(calc(200px - 10px));
      }

      .kanban-setting__slide i {
        transform: rotate(180deg);
      }
    }
  }

  &__slide {
    display: none;

    @media (max-width: $md) {
      position: absolute;
      top: 0;
      left: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 100%;
      padding: 0;
      border-radius: 4px;
      background: $surface-default;
      transition: all 0.3s ease;
      outline: none;
      border: none;
      border-right: 1px solid #f1eeff;
      border-left: 1px solid #f1eeff;
      box-sizing: border-box;
      box-shadow: 0 0 4px rgba(167, 169, 192, 0.2);
    }

    i {
      color: $link-default;
      font-size: 20px;
    }
  }

  &__caption {
    width: 100%;
    margin: 0;
    padding: 24px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      padding: 24px 8px 24px 16px;
    }

    /deep/ {
      .label label {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: $text-default;
        word-break: break-all;

        @media (max-width: $sm) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .icon-info {
        @media (max-width: $sm) {
          margin-left: 4px;
        }
      }

      .label__tooltip {
        right: 10px !important;
        @media (max-width: $sm) {
          max-width: 200px;
        }
      }
    }
  }

  &__list {
    margin: 0;
    padding: 24px;
    list-style: none;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 110px);

    @media (max-width: $sm) {
      padding: 24px 8px 24px 16px;
    }
  }
}
</style>
