<template>
  <ul class="quarter__stat-list">
    <li class="quarter__stat-item">
      Всего проблем <span>{{ sumStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      Создано <span>{{ setCreateStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      В работе <span>{{ setInWorkStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      Проверка <span>{{ setExaminationStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      Доработка <span>{{ setRevisionStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      Готово <span>{{ setDoneStatus }}</span>
    </li>
    <li class="quarter__stat-item">
      Закрыто <span>{{ setCloseStatus }}</span>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "IspReviewStatisticsItem",
  mixins: [],
  props: {
    statistics: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allStatistics: {
      type: Boolean,
      default: false
    }
  },
  components: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getStatusId(type) {
      return this.statusesList.find((item) => item.type === type).id;
    }
  },
  computed: {
    ...mapState("IspModule", ["statusesList"]),
    sumStatus() {
      return (
        this.setCreateStatus +
        this.setInWorkStatus +
        this.setExaminationStatus +
        this.setRevisionStatus +
        this.setDoneStatus +
        this.setCloseStatus
      );
    },
    setCreateStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("initial"))
            .length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("initial"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("initial"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("initial")).count
        : 0;
    },
    setInWorkStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("in_work"))
            .length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("in_work"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("in_work"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("in_work")).count
        : 0;
    },
    setExaminationStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("check")).length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("check"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("check"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("check")).count
        : 0;
    },
    setRevisionStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("rework"))
            .length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("rework"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("rework"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("rework")).count
        : 0;
    },
    setDoneStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("done")).length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("done"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("done"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("done")).count
        : 0;
    },
    setCloseStatus() {
      return this.allStatistics
        ? this.statistics.filter((item) => item.flow_status_id === this.getStatusId("final")).length
          ? this.statistics
              .filter((item) => item.flow_status_id === this.getStatusId("final"))
              .reduce((a, b) => a + b.count, 0)
          : 0
        : this.statistics.find((item) => item.flow_status_id === this.getStatusId("final"))
        ? this.statistics.find((item) => item.flow_status_id === this.getStatusId("final")).count
        : 0;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.quarter {
  &__stat-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: repeat(auto-fit, 178px);
    @media (max-width: $md) {
    }
  }

  &__stat-item {
    padding-left: 24px;
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: $text-default;

    &:before {
      position: absolute;
      content: "";
      top: 6px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #5551f9;
    }

    span {
      float: right;
    }
  }
}
</style>
