<template>
  <details class="body-row">
    <summary @click="openSubtaskList">
      <MyTasksTableTask
        :task="task"
        :tasksData="tasksData"
        :totalCount="totalCount"
        :fromPag="fromPag"
        @paginationEvent="getTaskTableData"
      />
    </summary>
    <MyTasksTableSubtask
      :task="task"
      v-if="subtask.length && !isSubtask"
      :tasksData="subtask"
      :params="params"
      :totalCount="countSub"
      :fromPag="pageSub"
      @getSubtask="getSubtaskList"
    />
  </details>
</template>

<script>
import MyTasksTableTask from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableTask";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import MyTasksTableSubtask from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableSubtask";

export default {
  name: "MyTasksTableRow",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    tasksData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    fromPag: {
      type: Number,
      default: 1
    },
    isSubtask: {
      type: Boolean,
      default: false
    }
  },
  components: { MyTasksTableSubtask, MyTasksTableTask },
  data() {
    return {
      openSubtask: false
    };
  },
  mounted() {},
  methods: {
    getTaskTableData(params) {
      this.$emit("paginationEvent", params);
    },
    getSubtaskList() {
      TaskModule.getSubtaskListAction({
        page: 1,
        per_page: 6,
        id: this.task.id
      });
    },
    openSubtaskList() {
      if (this.haveSubtasks) {
        if (!this.openSubtask && !this.subtask.length) {
          TaskModule.getSubtaskListAction({
            page: 1,
            per_page: 6,
            id: this.task.id
          });
        }
        this.openSubtask = !this.openSubtask;
        if (!this.openSubtask) TaskModule[TaskMutationTypes.RESET_SUBTASK_BY_TASK]();
      }
    }
  },
  computed: {
    subtask() {
      return TaskModule.subtasksByTask(this.task.id);
    },
    countSub() {
      return TaskModule.subtaskCount;
    },
    pageSub() {
      return TaskModule.subtaskPage;
    },
    valueSort() {
      return TaskModule.valueSort;
    },
    haveSubtasks() {
      return !!(this.task.childs_count && this.task.childs_count !== 0);
    },
    setId() {
      return this.isSubtask ? `subtask-row_${this.task.id}` : `task-row_${this.task.id}`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.body-row {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: $lg) {
    width: 1065px;
  }

  @media (max-width: $lg) {
    width: 100%;
  }

  summary {
    display: flex;
    width: 100%;
    outline: none;
  }

  &__subtasks {
    padding: 24px 0;
    background: $surface-selected-default;
    box-shadow: inset 0 4px 9px rgba(167, 169, 192, 0.3);

    @media (max-width: $lg) {
      width: 1065px;
      padding: 16px 0 16px 16px;
    }

    /deep/ {
      .task-row {
        padding: 12px 0;
      }

      .task-row__cell_name {
        max-width: 30%;
        width: 30%;

        @media (max-width: $lg) {
          max-width: calc(300px - 12px);
          width: calc(300px - 12px);
        }
      }
    }
  }
}
</style>
