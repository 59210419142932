<template>
  <div class="curtain-likes" v-click-outside="closeCurtain">
    <div class="curtain-likes__header">
      <i class="icon-arrow-back" @click="closeCurtain"></i>
      <h2>Лайкнули</h2>
    </div>
    <Spinner v-if="curtainLikesLoader" class="curtain-likes__loader" />
    <div class="curtain-likes__body" v-else>
      <User
        class="curtain-likes__user"
        v-for="like in currentNewsLikes"
        :avatar="like.user.avatar"
        :name="like.user.full_name"
        :id="like.user.id"
        :showTooltip="false"
        :key="like.user.id"
        :info="like.user.roles.length ? `[${like.user.roles[0].name}]` : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import User from "@/components/Widgets/User/User";
import ClickOutside from "vue-click-outside";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "CurtainNewsLikes",
  mixins: [],
  props: {},
  components: { Spinner, User },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["closeNewsLikes"]),
    closeCurtain() {
      this.closeNewsLikes();
    }
  },
  computed: {
    ...mapState("NewsModule", ["currentNewsLikes", "curtainLikesLoader"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.curtain-likes {
  position: fixed;
  width: 355px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 51;
  border-left: 1px solid #eae8f0;
  background: #ffffff;

  @media (max-width: $xxxl) {
    width: 260px;
  }
  @media (max-width: $md) {
    top: 70px;
  }
  @media screen and (max-width: $md) and (orientation: landscape) {
    height: calc(100% - 140px);
  }
  @media (max-width: $xs) {
    width: 100%;
  }
  @media screen and (max-width: $sm) and (orientation: landscape) {
    top: 50px;
    height: calc(100% - 120px);
  }

  &__header {
    height: 70px;
    border-bottom: 1px solid $icon-disabled;
    display: flex;
    align-items: center;
    padding-left: 16px;

    @media screen and (max-width: $sm) and (orientation: landscape) {
      height: 50px;
    }

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: $text-default;
    }
    i {
      font-size: 22px;
      margin-right: 5px;
      color: $icon-subdued;
      cursor: pointer;
    }
  }
  &__body {
    padding: 26px;
    height: calc(100% - 70px);
    overflow: auto;

    @media (max-width: $xs) {
      height: calc(100% - 110px);
    }

    @media screen and (max-width: $sm) and (orientation: landscape) {
      top: 50px;
      height: calc(100% - 49px);
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__user {
    margin-bottom: 24px;
    /deep/ {
      .user__name {
        font-weight: 500;
        max-width: 200px;
        width: max-content;
      }

      .user__description {
        max-width: 200px;
        width: max-content;
      }
    }
  }
}
</style>
