var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message__wrap",class:{
    message__wrap_self: _vm.isSelfMassage
  },attrs:{"id":_vm.setId}},[_c('div',{staticClass:"message__container",class:{
      message__container_picked: _vm.isPickedMessage
    },on:{"dblclick":_vm.replyMessage}},[_c('Avatar',{attrs:{"user":_vm.setAuthor}}),_c('div',{staticClass:"message",on:{"contextmenu":_vm.handleRightClick}},[_c('h4',{staticClass:"message__author"},[_c('div',{staticClass:"message__container-author"},[_c('span',{staticClass:"message__author-name"},[_vm._v(_vm._s(_vm.setAuthor.full_name))]),_c('span',{staticClass:"message__author-role"},[_vm._v("["+_vm._s(_vm.setAuthor.role_name)+"]")])]),_c('span',{staticClass:"message__time",style:({
            marginLeft: !_vm.isSelfMassage && !_vm.source.has_thread_id ? 'auto' : '',
            marginRight: _vm.isSelfMassage && !_vm.source.has_thread_id ? 'auto' : '0'
          })},[_c('span',[_vm._v(_vm._s(_vm.setTimeFormat))]),_c('i',{staticClass:"icon-check-all",class:{ 'icon-check-all_read': _vm.isReadMessage }})])]),(_vm.source.has_thread_id)?_c('div',{staticClass:"message__thread",on:{"click":_vm.openThread}},[_c('span',{staticClass:"message__comment"},[_vm._v(" Комментарии ")]),(_vm.source.thread && _vm.source.thread.count_unread_messages)?_c('span',{staticClass:"message__comment-new"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"message__container-main"},[(_vm.isForwarding)?_c('h4',{staticClass:"message__author",class:{ message__author_forwarding: _vm.isForwarding }},[_c('span',{staticClass:"message__forwarding"},[_vm._v("Переслано от ")]),_c('span',{staticClass:"message__forwarding-name"},[_vm._v(_vm._s(_vm.source.shareAuthorInfo.full_name))]),(_vm.source.shareAuthorInfo.role_name)?_c('span',{staticClass:"message__forwarding-role"},[_vm._v("["+_vm._s(_vm.source.shareAuthorInfo.role_name)+"] ")]):_vm._e()]):_vm._e(),(_vm.isReply)?_c('MessageReply',{attrs:{"message":_vm.source.reply_message}}):_vm._e(),_c('div',{staticClass:"message__text-wrap"},[(_vm.source.text || !_vm.source.has_thread_id)?_c('p',{staticClass:"message__text",domProps:{"innerHTML":_vm._s(_vm.setHTMLText)}}):_vm._e()]),(_vm.imageList.length)?_c('ul',{staticClass:"message__image-list"},_vm._l((_vm.imageList),function(item,index){return _c('li',{key:index,staticClass:"message__image-item",class:("message__image-item_" + index)},[_c('MessageFile',{attrs:{"file":item,"index":index}})],1)}),0):_vm._e(),(_vm.fileList.length)?_c('ul',{staticClass:"message__file-list",class:{
            'message__file-list_no-text': !_vm.source.text.length,
            'message__file-list_image': _vm.imageList.length
          }},_vm._l((_vm.fileList),function(item,index){return _c('li',{key:index,staticClass:"message__file-item"},[_c('MessageFile',{attrs:{"file":item,"index":index}})],1)}),0):_vm._e(),(_vm.isPickedMessage)?_c('span',{staticClass:"message__check"},[_c('i',{staticClass:"icon-check"})]):_vm._e()],1),_c('ul',{staticClass:"message__short-cut"},[(_vm.isReadonly)?_c('li',{staticClass:"message__short",on:{"click":_vm.replyMessage}},[_c('i',{staticClass:"icon-forum"}),_vm._v(" Ответить ")]):_vm._e(),_c('li',{staticClass:"message__short",on:{"click":_vm.resendMessage}},[_c('i',{staticClass:"icon-reply-arrow"}),_vm._v(" Переслать ")]),(!_vm.isPin)?_c('li',{staticClass:"message__short",on:{"click":_vm.addReaction}},[_c('i',{staticClass:"icon-emoji"}),_vm._v(" Отреагировать ")]):_vm._e()])])],1),(_vm.setReaction && !_vm.isPin)?_c('div',{staticClass:"message__reaction",class:{ message__reaction_person: !_vm.showAvatar }},[_c('ReactionList',{attrs:{"reactions":_vm.setReaction,"message":_vm.source,"isSelfMassage":_vm.isSelfMassage}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }