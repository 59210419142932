const stateIspProblem = {
  showCurtainCreateProblem: false,
  showCurrentInfoProblem: false,
  currentInfoProblem: null,
  currentProblemHistory: [],
  currentProblemHistoryTotalCount: 0,
  currentProblemHistoryCounter: 0,
  currentProblemComments: [],
  currentReplyableComment: null,
  currentEditableComment: null,
  showMoveProblem: false,
  filesLoader: false,

  // TASK //
  taskList: [],
  showIspCreateTaskModal: false
};

export default stateIspProblem;
