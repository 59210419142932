<template>
  <section class="company">
    <TabsNavigation :tabs="setTabList" />
    <router-view />
  </section>
</template>

<script>
import TabsNavigation from "@/components/Widgets/TabsWidget/TabsNavigation";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Tables",
  mixins: [],
  props: [],
  components: { TabsNavigation },
  data() {
    return {
      tabsList: [
        {
          label: "Сотрудники",
          path: "UserInfoTable",
          index: 0
        },
        {
          label: "Структура компании",
          path: "OrgStructure",
          index: 1
        },
        {
          label: "Образование",
          path: "UserEducationTable",
          index: 2
        },
        // {
        //   label: "Должности",
        //   path: "UserRolesTable",
        //   index: 3
        // }
        // {
        //   label: "Тестирование",
        //   path: "UserTestsTable",
        //   index: 3
        // }
      ]
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(["getEndpoints"]),
    isAdminEducation() {
      return this.getEndpoints("UserEducation", "update");
    },
    isAdminRoles() {
      return this.getEndpoints("Role", "update");
    },
    isAdminTests() {
      return this.getEndpoints("Test", "actionUpdate");
    },

    setTabList() {
      let filterTabsList = this.tabsList;
      if (!this.isAdminEducation) {
        filterTabsList = filterTabsList
          .filter((item) => item.path !== "UserEducationTable")
          .map((item, index) => {
            return {
              label: item.label,
              path: item.path,
              index: index
            };
          });
      }
      if (!this.isAdminRoles) {
        filterTabsList = filterTabsList
          .filter((item) => item.path !== "UserRolesTable")
          .map((item, index) => {
            return {
              label: item.label,
              path: item.path,
              index: index
            };
          });
      }
      // if (!this.isAdminTests) {
      //   filterTabsList = filterTabsList
      //     .filter((item) => item.path !== "UserTestsTable")
      //     .map((item, index) => {
      //       return {
      //         label: item.label,
      //         path: item.path,
      //         index: index
      //       };
      //     });
      // }
      return filterTabsList;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.company {
  height: calc(100% - 40px);
  width: calc(100% - 24px);
  margin: 24px 0 0;
  background: $surface-default;
  border: 1px solid $border-disabled;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 24px;

  @media (max-width: $md) {
    width: calc(100% - 32px);
    margin: 16px 16px 0;
  }

  @media (max-width: $sm) {
    width: 100%;
    margin: 16px 0 0;
  }

  @media (max-height: 450px) {
    height: 90%;
  }

  @media (max-height: $sm) {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 16px;
  }
}
</style>
