<template>
  <div class="curtain-create-team__container" @mousedown.self="closeCurtainCreateTeam">
    <div class="curtain-create-team">
      <ConfirmModal
        v-if="confirmClose"
        :title="'Закрыть'"
        :description="'Введенные данные не будут сохранены. Закрыть?'"
        @cancel="cancelClose"
        @accept="acceptClose"
      />
      <div class="curtain-create-team__header">
        <button class="curtain-create-team__hide-btn" type="button" @click="closeCurtainCreateTeam">
          <i class="icon-arrow-short"></i>
        </button>
        <h2>Создать команду</h2>
      </div>
      <div class="curtain-create-team__body">
        <div class="curtain-create-team__title">
          <Input
            placeholder="Название команды"
            label="Название команды"
            v-model="title"
            maxlength="70"
            :autocomplete="false"
          />
        </div>
        <div class="curtain-create-team__short-title">
          <Input
            placeholder="От 2 до 5 символов, латиницей"
            label="Сокращенное название команды"
            maxlength="5"
            minlength="2"
            v-model="alias"
            :autocomplete="false"
          />
        </div>
        <div class="curtain-create-team__users users">
          <div class="users__label">
            <label>Участники команды</label>
          </div>
          <div class="users__responsible">
            <p>Руководитель</p>
            <Select
              :placeholder="'Выберите сотрудника'"
              :multiple="false"
              v-model="leader"
              :items="allUsers"
            />
          </div>
          <div class="users__members">
            <p>Участники</p>
            <Select :placeholder="'Выберите сотрудника'" v-model="members" :items="allUsers" />
          </div>
        </div>
      </div>
      <div class="curtain-create-team__footer">
        <ModalButton :type="'cancel'" :title="'Отмена'" @handleButton="closeCurtainCreateTeam" />
        <Button :title="'Создать'" @handleButton="createTeam" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalButton from "@/components/Widgets/Button/ModalButton";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button";
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "CurtainCreateTeam",
  mixins: [],
  props: {},
  components: { ConfirmModal, ModalButton, Input, Select, Button },
  data() {
    return {
      title: "",
      alias: "",
      leader: [],
      members: [],
      confirmClose: false
    };
  },
  mounted() {
    this.getAllUsersAction();
  },
  methods: {
    ...mapActions("IspModule", ["getAllUsersAction", "createTeamAction", "getTeamsAction"]),
    ...mapMutations("IspModule", ["TOGGLE_CURTAIN_CREATE_TEAM"]),
    closeCurtainCreateTeam() {
      if (this.title || this.alias || this.leader.length || this.members.length) {
        this.confirmClose = true;
      } else {
        this.TOGGLE_CURTAIN_CREATE_TEAM(false);
      }
    },
    createTeam() {
      if (!this.checkValidation()) return false;
      let membersToAdd = this.members.map((item) => item.id);
      if (!membersToAdd.includes(this.leader.find((item) => item.id))) {
        if (this.leader.length) {
          membersToAdd.push(this.leader.find((item) => item.id).id);
        }
      }
      const newTeam = {
        name: this.title,
        alias: this.alias,
        leader_user_id: this.leader.length ? this.leader.find((item) => item.id).id : undefined,
        member_users: membersToAdd.length ? membersToAdd : undefined
      };
      this.createTeamAction(newTeam).then(() => {
        this.TOGGLE_CURTAIN_CREATE_TEAM(false);
        this.getTeamsAction(false);
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Команда создана`
        });
      });
    },
    checkValidation() {
      if (this.title.length > 70 || !this.title.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Название должно быть не менее 1 и не более 70 символов!"
        });
        return false;
      }
      if (this.alias.length > 5 || this.alias.length < 2) {
        NotificationObserver.notification({
          type: "error",
          message: "Сокращенное название команды должно быть не менее 2 и не более 5 символов!"
        });
        return false;
      }
      if (!this.leader.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Поле Руководитель обязательно для заполнения!"
        });
        return false;
      }
      if (!this.members.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Поле Участники обязательно для заполнения!"
        });
        return false;
      }
      return true;
    },
    cancelClose() {
      this.confirmClose = false;
    },
    acceptClose() {
      this.TOGGLE_CURTAIN_CREATE_TEAM(false);
    }
  },
  computed: {
    ...mapState("IspModule", ["allUsers"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
}
.curtain-create-team {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__header {
    display: flex;
    padding: 32px 24px;
    h2 {
      @media (max-width: $xs) {
        font-size: 25px;
      }
    }
  }
  &__hide-btn {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    @media (max-width: $md) {
      display: inline-block;
    }
    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__body {
    padding: 24px;
    height: calc(100% - 200px);
    overflow: auto;
    /deep/ {
      input {
        width: 100%;
      }
    }
  }
  &__title {
    margin-bottom: 32px;
  }
  &__short-title {
    margin-bottom: 56px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;
    /deep/button {
      font-weight: 600;
    }
  }
}
.users {
  &__label {
    font-size: 18px;
    position: relative;
    display: flex;
    margin: 0 0 32px;
    width: max-content;
  }
  &__responsible {
    margin-bottom: 32px;
    p {
      font-size: 16px;
      color: #756f86;
      margin-bottom: 24px;
    }
  }
  &__members {
    p {
      font-size: 16px;
      color: #756f86;
      margin-bottom: 24px;
    }
  }
  /deep/ {
    .select-bg__selected-list {
      max-height: 180px;
      overflow: auto;
      align-items: flex-start;
    }
  }
}
</style>
