<template>
  <div class="black-sphere__widgets">
    <ChosenFilter
      icon="user"
      :search="true"
      placeholder="Сотрудник"
      :multiple="true"
      :chosenList="usersList"
      v-model="users"
      :allItem="true"
      @input="handleUser"
    />
    <MonthPickerTable
      :currentMonth="setCurrentMonth"
      :filterMonth="filterMonth"
      @changeMonthYear="changeMonthYear"
      :showIcon="true"
    />
  </div>
</template>

<script>
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import moment from "moment";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";

export default {
  name: "BlackSphereStatisticsWidgets",
  mixins: [],
  props: {
    filterUser: {
      type: [],
      default: () => {
        return [];
      }
    },
    filterMonth: {
      type: String,
      default: ""
    }
  },
  components: {
    MonthPickerTable,
    ChosenFilter
  },
  data() {
    return {
      users: []
    };
  },
  mounted() {
    this.users = this.filterGotUser;
  },
  methods: {
    changeMonthYear(val) {
      this.resetDataForTable();
      this.$emit("changeMonthYear", val);
    },
    handleUser(val) {
      this.users = val;
      this.resetDataForTable();
      this.$emit("selectUsers", this.users);
    },
    resetDataForTable() {
      BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE_STATISTIC](1);
      BlackSphere[BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_STATISTIC_LIST]();
    }
  },
  computed: {
    setCurrentMonth() {
      return moment(this.filterMonth).format("M") - 1;
    },
    usersList() {
      return this.$store.getters["UsersModule/userList"]
        .filedList(["full_name", "avatar", "is_active"])
        .filter((item) => item.is_active);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  &__widgets {
    display: flex;
    align-items: center;
    padding: 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    @media (max-width: $sm) {
      padding: 16px;
    }

    @media (max-height: 450px) {
      padding: 8px 24px;
    }

    /deep/ {
      .select-bg {
        width: 300px;
        margin-right: 24px;

        @media (max-width: $sm) {
          margin-right: 4px;
          width: 250px;
        }

        .select-content-bg {
          height: 30px;
          max-width: 100%;

          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
        }
      }

      .month-picker-background {
        width: 140px;
        margin-right: 15px;

        @media (max-width: $sm) {
          width: max-content;
          margin-right: 0;
          margin-left: auto;
        }
      }

      .month-picker-background .button-month-picker .button-month {
        @media (max-width: $sm) {
          display: none;
        }
      }

      .month-picker-background .button-month-picker span i {
        @media (max-width: $sm) {
          margin: 0;
        }
      }

      .modal-items {
        background: #fafafe;
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }
    }
  }
}
</style>
