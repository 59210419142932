const stateIspTeams = {
  curtainCreateTeam: false,
  showIspTeamInfo: false,
  currentTeams: [],
  loaderTeams: false,
  // loaderTeamsScroll: false,
  // totalCount: 0,
  // teamsCounter: 0,
  currentTeam: null,
  allUsers: []
};

export default stateIspTeams;
