






import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";

@Component({
  components: { ButtonNewDesign }
})
export default class Month extends Vue {
  @Prop({
    default: 0,
    type: String
  })
  number!: string;
  @Prop({
    default: 0,
    type: Number
  })
  activeItem!: number;
  @Prop({
    default: "month",
    type: String
  })
  type!: string;

  handleItem() {
    this.$emit("handleItem", { type: this.type, date: this.number });
  }

  get isActive() {
    return this.type === "month"
      ? +this.number === this.activeItem - 1
      : +moment(this.number).format("YYYY") === this.activeItem;
  }

  get item() {
    return this.type === "month"
      ? moment().month(this.number).format("MMMM")
      : moment(this.number).format("YYYY");
  }
}
