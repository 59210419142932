<template>
  <div class="app-bg">
    <ContextMenu v-if="isShowContextMenu" />
    <LayoutSideNav :userInfo="userInfo" :isNewDesign="isNewDesign" />
    <main :class="isNewDesign ? 'app-bg__module-container-isp' : 'app-bg__module-container'">
      <router-view />
      <ChatNotification v-if="showNotification" />
    </main>
    <transition name="slide">
      <NotificationModal v-if="isShowNotificationModal" />
    </transition>
    <div v-if="showModalNewVersion" class="update-modal">
      <p class="update-modal__text">Доступна новая версия.</p>
      <Button type="white" @handleButton="getIndex" title="Обновить" />
    </div>
  </div>
</template>

<script>
import LayoutSideNav from "@/layout/SideNav/LayoutSidenav";
import { mapActions, mapState } from "vuex";
import ChatNotification from "@/modules/Messenger/components/Notification/ChatNotification";

import ContextMenu from "@/modules/UiKit/components/ContextMenu/ContextMenu";
import Button from "@/modules/UiKit/components/buttons/Button";
import { EventBus } from "@/main";
import { updateTimer } from "@/modules/TimeTracker/helpers/createBroadcast";
import NotificationModal from "@/modules/Notification/components/NotificationModal";

const ModalAddBugsAndIdea = () => import("@/components/FeedbackPanel/ModalAddBugsAndIdea");
export default {
  name: "LayoutMain",
  components: {
    NotificationModal,
    Button,
    ContextMenu,
    ChatNotification,
    ModalAddBugsAndIdea,
    LayoutSideNav
  },
  data() {
    return {
      windowWidth: 0,
      loaderUserInfo: true,
      userInfo: null
    };
  },
  props: {
    loaderUserInfo: {
      type: Boolean,
      default: true
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    getIndex() {
      this.$emit("getIndex");
    }
  },
  computed: {
    ...mapState(["isShowContextMenu", "showModalNewVersion"]),
    ...mapState("MessengerModule", ["showNotification"]),
    ...mapState("Notification", ["isShowNotificationModal"]),
    isNewDesign() {
      return this.$route.matched.some((item) =>
        [
          "ISP",
          "IspProblem",
          "News",
          "Messenger",
          "Calendar",
          "TaskManager",
          "WorkTime",
          "Notification",
          "Plan",
          "ProjectNetwork",
          "Requests"
        ].includes(item.name)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(-100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}

.check-button {
  border: none;
  width: 160px;
  height: 40px;
  border-radius: 16px;
}

.update-modal {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 57px;
  background: $status-work;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  z-index: 10000;
  @media screen and (max-width: $md) {
    height: 70px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    margin-right: 30px;
    font-family: "Roboto", serif !important;
    @media screen and (max-width: 370px) {
      margin-right: 0;
    }
  }

  .aop-button {
    @media screen and (max-width: 370px) {
      height: 0;
    }
  }
}

.loader {
  margin: auto;
}

.app-bg {
  display: flex;
  width: 100%;
  height: 100%;
  background: $bg-surface-gradient;
  @media (max-width: $md) {
    flex-direction: column-reverse;
  }

  &__module-container {
    position: relative;
    width: calc(100% - 100px);
    height: 100%;
    padding-left: 16px;

    @media (max-width: $md) {
      width: 100%;
      z-index: 1;
      padding-left: 0;
      height: 100%;
    }
  }

  &__module-container-isp {
    width: calc(100% - 100px);
    height: 100%;
    display: flex;

    @media (max-width: $md) {
      width: 100%;
      padding-left: 0;
      height: 100%;
    }
  }
}
</style>
