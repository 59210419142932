export const GET_USERS_LIST = "GET_USERS_LIST";
export const SHOW_CONFIRM_MODALS = "SHOW_CONFIRM_MODALS";

// Grid
export const SET_CALENDAR_PARAMS = "SET_CALENDAR_PARAMS";
export const SET_WEEKS = "SET_WEEKS";
export const SET_WEEK_NUMBER = "SET_WEEK_NUMBER";
export const SET_YEARS = "SET_YEARS";
export const SHOW_WEEKENDS = "SHOW_WEEKENDS";
export const SET_WORKING_TIME = "SET_WORKING_TIME";
export const SHOW_PERSONAL_CALENDAR = "SHOW_PERSONAL_CALENDAR";
export const SHOW_WORKING_CALENDAR = "SHOW_WORKING_CALENDAR";
export const SHOW_WORKING_TIME_MODAL = "SHOW_WORKING_TIME_MODAL";
export const SHOW_MOBILE_LOCATION_MODAL = "SHOW_MOBILE_LOCATION_MODAL";
export const SHOW_MOBILE_EVENTS_MODAL = "SHOW_MOBILE_EVENTS_MODAL";
export const SHOW_MOBILE_MEMBERS_MODAL = "SHOW_MOBILE_MEMBERS_MODAL";
export const SET_TIMES_LIST = "SET_TIMES_LIST";

// Events
export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
export const SET_RESPONSE_ID_CURRENT_EVENT = "SET_RESPONSE_ID_CURRENT_EVENT";
export const SHOW_CREATE_EVENT_MODAL = "SHOW_CREATE_EVENT_MODAL";
export const SHOW_CROSS_EVENT_MODAL = "SHOW_CROSS_EVENT_MODAL";
export const SHOW_EVENT_INFO_MODAL = "SHOW_EVENT_INFO_MODAL";
export const SHOW_REPEAT_CONFIRM_MODAL = "SHOW_REPEAT_CONFIRM_MODAL";
export const GET_CROSS_MEMBERS_USER = "GET_CROSS_MEMBERS_USER";
export const GET_CROSS_EVENTS = "GET_CROSS_EVENTS";
export const SET_EVENT_PARAMS = "SET_EVENT_PARAMS";
export const SET_CROSS_EVENT_RESPONSE = "SET_CROSS_EVENT_RESPONSE";
export const SET_TIME_START = "SET_TIME_START";
export const SET_TIME_END = "SET_TIME_END";
export const SET_DATE_EVENT = "SET_DATE_EVENT";
export const SET_LOADER_CREATE_EVENT = "SET_LOADER_CREATE_EVENT";
export const SET_LOADER_REPEAT_EVENT = "SET_LOADER_REPEAT_EVENT";
export const SET_DATA_INFO_MEMBER_USER = "SET_DATA_INFO_MEMBER_USER";

// Location

export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const SHOW_LOCATION_MODAL = "SHOW_LOCATION_MODAL";
export const SET_EDIT_LOCATION = "SET_EDIT_LOCATION";
