import { format } from "date-fns";
import { ru } from "date-fns/locale";
import moment from "moment";

export default {
  methods: {
    wordsYears: ["год", "года", "лет"],
    wordsMonths: ["месяц", "месяца", "месяцев"],
    wordsDays: ["день", "дня", "дней"],
    wordsHours: ["час", "часа", "часов"],
    wordsMinutes: ["минута", "минуты", "минут"],

    /**
     * function of adding hours and minutes
     * @param number - numeric time value
     * @param words - array of declension options
     * @returns {*} - hour or minute in the desired declension
     */
    declOfNum(number, words) {
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
      ];
    },

    /** Function to convert date to seconds to sort events in order
     * @param item - start time of the event
     * @returns temp - event start time in seconds
     */
    getSeconds(item) {
      let temp = item.split(":");
      temp = Number(temp[0]) * 3600 + Number(temp[1]) * 60 + Number(temp[2]);
      return temp;
    },
    /**
     * @description Time rounding function to minutes
     * @param time - time in 00:00:00 format
     * @returns {string|*} - time in 00 format
     */
    setTimeFormatHours(time) {
      return time.split(":")[0] === "00" ? "0" : time.split(":")[0];
    },

    /**
     * @description Time rounding function to minutes
     * @param time - time in 00:00:00 format
     * @returns {*} - time in 00:00 format
     */
    setTimeFormatMinutes(time) {
      return time.slice(0, 5);
    },

    /**
     * @description Function for converting date to DD.MM.YYYY format and trimming time
     * @param date date in YYYY-MM-DD TT:TT:TT format
     * @returns {*} time in 00:00  format
     */
    setTimeFormatWithoutDate(date) {
      let temp = date.split(" ")[1].split(":");
      return temp[0] + ":" + temp[1];
    },

    /**
     * @description Converting the time from the format with the letter T to the format like 00:00, taking into account the time zone
     * @param date ate in YYYY-MM-DDT00:00:00Z format
     * @returns {*} time in 00:00 format
     */
    setTimeFormatWithoutLetterT(date) {
      if (date.includes("Z")) {
        moment.locale("ru");
        return moment(date).format("LT");
      } else {
        let temp = date.split("T")[1].split(":");
        return temp[0] + ":" + temp[1];
      }
    },

    /**
     * @description Time conversion function, rounding to minutes and adding full names of units
     * @param time - time in 00:00:00 format
     * @returns {string} - time in 00 hours 00 minutes format
     */
    setTimeFormatWithFullTimUnits(time) {
      let timeArr = time.split(":");
      if (timeArr[0] === "00") {
        return timeArr[1] + " " + this.declOfNum(Number(timeArr[1]), ["минута", "минуты", "минут"]);
      } else {
        return (
          timeArr[0] +
          " " +
          this.declOfNum(Number(timeArr[0]), ["час", "часа", "часов"]) +
          " " +
          timeArr[1] +
          " " +
          this.declOfNum(Number(timeArr[1]), ["минута", "минуты", "минут"])
        );
      }
    },

    /**
     * @description Function for converting date to DD.MM.YYYY
     * @param date - date in YYYY-MM-DD format
     * @returns {*} - date in DD.MM.YYYY format
     */
    setDateFormatFull(date) {
      return date.split("-").reverse().join(".");
    },

    /**
     * @description Function for converting date to DD.MM.YYYY
     * @param date - date in DD-MM-YYYY format
     * @returns {*} - date in YYYY-MM-DD format
     */
    setDateReverse(date) {
      return date.split("-").reverse().join("-");
    },

    /**
     * @description Function for converting date to DD.MM.YYYY
     * @param date - date in DD-MM-YYYY format
     * @returns {*} - date in DD.MM.YYYY format
     */
    setDateFormatFullWithoutReverse(date) {
      return date.split("-").join(".");
    },

    /**
     * @description The function of converting the date into the format with the full Russian name of the month
     * @param date - date in YYYY-MM-DD format
     * @returns {string} date in DD `month` YYYY format
     */
    setDateFormatWidthMonthName(date) {
      return format(new Date(date), "dd MMMM yyyy", {
        locale: ru
      });
    },

    /**
     * @description The function of converting the date into the format with the full Russian name of the month
     * @param date - date in YYYY-MM-DD format
     * @returns {string} date in DD `mon` YYYY format
     */
    setDateFormatWidthShortMonthName(date) {
      return format(new Date(date), "dd MMM yyyy", {
        locale: ru
      });
    },

    /**
     * @description Function for converting date to DD.MM.YYYY format and trimming time
     * @param date date in YYYY-MM-DD TT:TT:TT format
     * @returns {*} date in DD.MM.YYYY format
     */
    setDateFormatWithoutTime(date) {
      return date.split(" ")[0].split("-").reverse().join(".");
    },

    /**
     * @description Function for converting date to DD.MM.YYYY TT:TT format and trimming time
     * @param date date in YYYY-MM-DD TT:TT:TT format
     * @returns {*} date in DD.MM.YYYY TT:TT format
     */
    setDateFormatWithTime(date) {
      return date.split(" ")[0].split("-").join(".") + " " + date.split(" ")[1].slice(0, 5);
    },

    /**
     * @description Conversion of the date from the format with the letter T to the format of the type DD.MM.YYYYY
     * @param date date in YYYY-MM-DDT00:00:00 format
     * @returns {*} date in DD.MM.YYYY format
     */
    setDateFormatWithoutLetterT(date) {
      return date.split("T")[0].split("-").reverse().join(".");
    },

    /**
     * @description Function that sets the date format in the form of a word designation of the month plus year
     * @param value date in YYYY-MM  format
     * @returns {*} date in LLLL, YYYY format
     */
    setWrittenFormatData(value) {
      let date = `${format(new Date(), "yyyy")}-${value.split("-")[1]}`;
      let monthName = format(new Date(date), "LLLL", { locale: ru });
      let firstLetter = monthName[0].toUpperCase();
      let newMonthName = firstLetter + monthName.slice(1);
      let year = value.split("-")[0];
      return newMonthName + ", " + year;
    },

    /**
     * @description Function that sets the date format in the form of a word designation of the month plus year
     * @param date date in MM-YYYY  format
     * @returns {*} date in LLLL, YYYY format
     */
    setWrittenFormatDataSecondary(date) {
      let newDate = `${format(new Date(), "yyyy")}-${date.split("-")[0]}`;
      let monthName = format(new Date(newDate), "LLLL", { locale: ru });
      let firstLetter = monthName[0].toUpperCase();
      let newMonthName = firstLetter + monthName.slice(1);
      let year = date.split("-")[1];
      return newMonthName + ", " + year;
    }
  }
};
