<template>
  <div class="message-file">
    <div class="message-file__item" v-if="isImage" @click="openImage">
      <div class="chat-loader" v-show="load"></div>
      <img class="message-file__image" :src="returnSrcFile(file.path)" alt="" v-show="!load" />
    </div>
    <div class="message-file__item message-file__item_file" v-else>
      <a class="message-file__link" :href="returnSrcFile(file.path)">
        <i class="icon-document-text"></i>
        <div class="message-file__text-wrap">
          <p class="message-file__name">
            {{ setName }}
          </p>
          <p class="message-file__size">{{ sizeFloor }}</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { domainStorage } from "@/globalVariables";

export default {
  name: "MessageFile",
  mixins: [],
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      load: true
    };
  },
  mounted() {
    this.timeId = setTimeout(() => {
      this.load = false;
    }, 100);
  },
  methods: {
    ...mapActions("MessengerModule", ["switchPopupImage"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    },
    openImage() {
      this.switchPopupImage({ file: this.file, show: true });
    }
  },
  computed: {
    ...mapState("MessengerModule", ["path"]),
    isImage() {
      return this.file.type === "photo" || this.file.type === "gif";
    },
    setName() {
      const length = this.file.name.length;
      if (length > 15) {
        return this.file.name.slice(0, 5) + "..." + this.file.name.slice(length - 7, length);
      } else {
        return this.file.name;
      }
    },
    sizeFloor() {
      const size = this.file.size;
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "Кб";
      } else {
        return size + "Б";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

@keyframes placeHolderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.animated-background {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 500px;
  height: 100%;
  position: relative;
}
.chat-loader {
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  @extend .animated-background;
}

.message-file {
  display: flex;
  width: 100%;
  height: 100%;
  &__image {
    display: flex;
    border-radius: 4px;
    object-fit: cover;
    object-position: 50% 20%;
    width: 160px;
    height: 120px;
  }
  .icon-document-text {
    font-size: 20px;
    color: $icon-highlight;
    margin-left: 9px;
  }
  &__item {
    display: flex;
    background-color: $reply-message;
    height: 100%;
    width: 100%;
    &_file {
      width: 140px;
      align-items: center;
      padding: 2px 4px 2px 2px;
      border-radius: 4px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
  }
  &__text-wrap {
    margin-left: 12px;
  }
  &__name {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    padding: 0;
  }
  &__size {
    margin: 0;
    padding: 1px 0 0;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: $text-disabled;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
}
</style>
