







import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";
import TimeTracker from "@/modules/TimeTracker/store";
import moment from "moment";

@Component
export default class ActionCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;

  handleEdit() {
    TimeTracker.toggleShowModal({ isShow: true, data: this.row });
  }

  handleDelete() {
    TimeTracker.confirmDelete(this.row.id);
  }

  get date() {
    return TimeTracker.date;
  }

  get isEditDate() {
    return moment(this.date) > moment().subtract(4, "days");
  }

  get userId() {
    return TimeTracker.user_id;
  }

  get currentUserId() {
    return this.$store.getters.getUserId;
  }

  get isSelf() {
    return this.userId === this.currentUserId;
  }

  get isDisabled() {
    return this.row.disabled || !this.isSelf || !this.isEditDate;
  }
}
