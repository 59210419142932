import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import WorkCalendarService from "@/modules/TimeTracker/Service/WorkCalendarService";
import Vue from "vue";
import moment from "moment";
import { EventBus } from "@/main";
import {
  ICalendarItem,
  ITableMonthData,
  IUserStatistics
} from "@/modules/TimeTracker/interface/interface";
import { secToTime } from "@/modules/TimeTracker/helpers/timeFormat";
import { setUniqueArray } from "@/helpers/setUniqueArray";

const name = "WorkCalendarModule";

export enum WorkCalendarMutationTypes {
  SET_TABLE_DATA = "SET_TABLE_DATA",
  SET_USERS_FILTER = "SET_USERS_FILTER",
  EDIT_DAY = "EDIT_DAY"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class WorkCalendarModule extends VuexModule {
  calendar: Array<ICalendarItem> = [];
  userMonthTable: Array<ITableMonthData> = [];
  tableData: Array<any> = [];
  filterUsers: Array<number> = [];
  userStatistic: IUserStatistics | null = null;
  currentUserStat: IUserStatistics | null = null;
  count: number = 0;
  page: number = 1;
  per_page: number = 15;

  @Mutation
  [WorkCalendarMutationTypes.EDIT_DAY](data: { hours: number; date: string }) {
    if (this.calendar.length) {
      const day = this.calendar.find((item) => item.date === data.date);
      if (day) {
        const index = this.calendar.findIndex((item) => item.date === data.date);
        Vue.set(this.calendar, index, { ...day, hours: data.hours });
      }
    }
  }

  @Mutation
  [WorkCalendarMutationTypes.SET_USERS_FILTER](users) {
    this.filterUsers = users;
  }

  @Mutation
  [WorkCalendarMutationTypes.SET_TABLE_DATA](data: {
    count: number;
    list: Array<any>;
    page: number;
    per_page: number;
  }) {
    if (data.page === 1) {
      this.tableData = data.list;
    } else {
      this.tableData = setUniqueArray([...this.tableData, ...data.list], "user_id");
    }
    this.count = data.count;
    this.page = data.page;
    this.per_page = data.per_page;
  }

  @MutationAction
  async getCalendar(params: { month: string }) {
    try {
      let calendar = await WorkCalendarService.getCalendar(params);
      calendar = calendar.map((item) => ({
        hours: item.hours,
        date: moment(item.date).format("YYYY-MM-DD")
      }));
      return { calendar };
    } catch (e) {
      console.warn(e);
    }
  }

  @Action
  async editDaysHour(data: { hours: number; id: number; date: string }) {
    try {
      await Promise.all([
        WorkCalendarService.editDay(data),
        WorkCalendarService.editMonolitDay({
          count_hours: data.hours,
          id: data.id,
          date: data.date
        })
      ]);
      this[WorkCalendarMutationTypes.EDIT_DAY](data);
      EventBus.$emit("showNotification", {
        type: "success",
        timeout: 5000,
        label: `Рабочие часы успешно отредактированы!`
      });
    } catch (e) {
      console.warn(e);
    }
  }

  @MutationAction
  async getMonthTable(params: { month: string; user_id: number }) {
    const data = await WorkCalendarService.getUserMonthWorkTime(params);
    const by_days = data.by_days.map((item) => ({
      ...item,
      hours: secToTime(item.total_tracked_time)
    }));
    return { userMonthTable: by_days, userStatistic: data.total };
  }

  @Action
  async getWorktable(params: {
    month: string;
    per_page: number;
    page: number;
    users?: Array<number>;
  }) {
    const data = await WorkCalendarService.getWorktable(params);
    this[WorkCalendarMutationTypes.SET_TABLE_DATA](data);
  }

  @MutationAction
  async getCurrentUserStat(params: { user_id: number; month: string }) {
    const currentUserStat = await WorkCalendarService.getCurrentUserStat(params);
    return { currentUserStat };
  }
}

export default getModule(WorkCalendarModule);
