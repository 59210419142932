<template>
  <li class="action-list__modal-item" v-if="item.isShow">
    <button class="action-list__modal-button" type="button" @click="handleActionItem">
      <i :class="`icon-${item.iconType}`"></i>
      {{ item.actionText }}
    </button>
  </li>
</template>

<script>
export default {
  name: "ActionItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => ({
        isShow: true,
        iconType: "close",
        actionText: "Закрыть",
        actionType: "close"
      })
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleActionItem() {
      this.$root.$emit(`actionItem:handle${this.item.actionType}`);
      this.$emit(`action_item_handle`, this.item.actionType); //тоже самое, не используя $root
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.action-list {
  &__modal-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;
    }
  }
}
</style>
