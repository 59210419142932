<template>
  <div class="users__widgets" :class="{ users__widgets_admin: isAdmin || isAdminReportUsers }">
    <Widget
      type="select-new"
      :entity="'User'"
      :actionEntity="'getItems'"
      :itemName="'full_name'"
      :keyValue="'FilterUserListInUsersTable'"
      :label="'Сотрудник'"
      :placeholder="'Все'"
      :multiple="true"
      :class="'select-bg_users'"
      :editValue="filterUser"
      :filterActive="!filterActive"
    />
    <Widget
      type="select-new"
      :entity="'Department'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterDepartmentListInUsersTable'"
      :label="'Отдел'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterDepartment"
    />
    <Widget
      type="select-new"
      :entity="'Role'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterRoleListInUsersTable'"
      :label="'Должность'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterRole"
      :class="'select-bg_role'"
    />
    <Widget
      type="select-new"
      :entity="'Level'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterLevelListInUsersTable'"
      :label="'Грейд'"
      :placeholder="'Все'"
      :editValue="filterLevel"
      :multiple="true"
    />
    <Widget
      v-if="isAdmin || isAdminReportUsers"
      class="table__switch"
      type="Switch"
      :unique="'FilterActiveListInUsersTable'"
      :label="'Уволен'"
      :editValue="filterActive"
    />
    <div class="users__widgets-admin">
      <ModalButton
        :icon="'plus'"
        :title="'Сотрудник'"
        @handleButton="openModalCreateUser"
        class="users__add"
        v-if="isRightsAdmin"
      />
      <ModalButton
        :title="'Выгрузить'"
        :icon="'download'"
        :type="'outline'"
        :loader="loaderReport"
        @handleButton="uploadReport"
        v-if="isAdmin || isAdminReportUsers"
      />
    </div>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import * as types from "@/modules/Tables/store/mutations/mutationsTypes";

export default {
  name: "UsersWidgets",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isAdminReportUsers: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterRole: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterDepartment: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterLevel: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterActive: {
      type: Boolean,
      default: false
    }
  },
  components: { Widget, ModalButton },
  data() {
    return {
      showList: false,
      filters: ["Сотрудник", "Отдел", "Роль", "Грейд"],
      valueSort: "Сотрудник"
    };
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["uploadUsersReport"]),
    ...mapMutations("TablesModules", [types.TOGGLE_SHOW_MODAL_CREATE_USER]),
    // Выгрузка отчета
    uploadReport() {
      this.uploadUsersReport(this.filterActive);
    },
    openList() {
      this.showList = !this.showList;
    },
    hideModalList() {
      this.showList = false;
    },
    choseFilter(item) {
      this.valueSort = item;
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    openModalCreateUser() {
      this[types.TOGGLE_SHOW_MODAL_CREATE_USER](true);
    }
  },
  computed: {
    ...mapState("TablesModules", ["loaderReport"]),
    isRightsAdmin() {
      return this.$store.getters.getDataInfoUser.roles.includes("rights_admin");
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.users {
  &__widgets {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
    /deep/ {
      .select-bg {
        margin-right: 16px;

        @media (max-width: $lg) {
          margin-right: 8px;
          width: 24%;
        }

        .select-content-bg {
          height: 28px;

          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-placeholder {
              @media (max-width: $xs), (max-height: $sm) {
                font-size: 12px;
              }
            }

            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
          @media (max-width: $lg) {
            margin-bottom: 10px;
          }
          @media (max-width: $md) {
            margin-bottom: 0;
          }
          @media (max-width: 812px) and (max-height: 480px) {
            margin-bottom: 0;
          }
        }

        &_users,
        &_role {
          .select-content-bg {
            .select-content {
              .select-content-value-bg {
                width: calc(100% - 140px);
              }
            }
          }
        }
      }

      .default-button {
        margin-right: 12px;
        @media (max-width: $lg) {
          margin-left: unset;
        }
      }

      .modal-items {
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }
    }
    &-admin {
      display: flex;
      margin-left: auto;
    }
  }
  &__add {
    padding: 6px 16px;
    font-weight: 500;
    /deep/i {
      margin-right: 4px;
    }
  }
}
.table__switch {
  margin-right: 10px;
  @media (max-width: $lg) {
    margin-right: 110px;
  }
}
.users-menu {
  position: relative;
  padding: 0px 4px;
  display: flex;
  &__button {
    background-color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0 4px;
    i {
      color: $icon-subdued;
      font-size: 20px;
    }
    &_active {
      background-color: $background-active;
    }
  }
  &__wrapper {
    position: relative;
  }
  &__list {
    list-style: none;
    position: absolute;
    width: max-content;
    z-index: 10;
    right: 0;
    top: 30px;
    background: $color-white;
    margin: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    @media (max-height: 360px) {
      overflow: auto;
      max-height: 165px;
    }
  }
  &__filters {
    padding: 8px;
    margin: 0;
    border-bottom: 1px solid #f2f2f7;
  }
  &__switches {
    padding: 8px;
    border-bottom: 1px solid #f2f2f7;
  }
  &__buttons {
    padding: 8px;
  }
  .filters {
    &__item {
      display: flex;
      align-items: center;
      width: 150px;
      cursor: pointer;
      padding: 7px;
      border-radius: 4px;
      font-size: 14px;
      color: #202223;
      &_active {
        background-color: $background-active;
      }
    }
    &__checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border: 2px solid #d3d2e0;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 8px;
      flex-shrink: 0;
      .icon-check {
        color: #ffffff;
      }
      &_active {
        background-color: $action-primary;
        border: 2px solid $action-primary;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  /deep/ {
    .select-bg {
      .select-content-bg {
        height: 28px;

        .select-content {
          background: #fafafe;
          border-radius: 4px;
          .select-content-placeholder {
            @media (max-width: $xs), (max-height: $sm) {
              font-size: 12px;
            }
          }
        }
        .select-label {
          @media (max-width: $xs), (max-height: $sm) {
            font-size: 12px;
          }
        }
      }
    }
    .default-button_outline {
      border: none;
      box-shadow: none;
      filter: none;
      width: 100%;
      justify-content: start;
      color: #202223;
      font-weight: 400;
    }
    .switch-widget {
      padding-left: 9px;
      &__indicator {
        height: 10px;
        width: 20px;
        &:before {
          top: 1px;
          left: 2px;
          width: 8px;
          height: 8px;
        }
      }
      &__label {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-default;
      }
      &__input {
        &:checked + .switch-widget__indicator {
          &::before {
            top: 1px;
            left: 10px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
}
</style>
