<template>
  <div class="show-edit-stage" @mousedown.self="closeStage">
    <div class="show-edit-stage__container">
      <div class="show-edit-stage__header">
        <i class="icon-arrow-back" @click="closeStage"></i>
        <TabsContent :tabs="tabsList" v-model="currentTab" />
        <div class="show-edit-stage__control">
          <i class="icon-launch" @click="goToStage"></i>
          <ActionsList
            v-if="isShowActionList"
            :actionList="setActionList"
            @handleAction="handleAction"
          />
        </div>
      </div>
      <div class="show-edit-stage__body">
        <StageDescriptionBody
          v-if="currentTab === 'description'"
          :currentStage="currentStage"
          :project="currentProject"
        />
        <StageTasksBody v-if="currentTab === 'tasks'" :tasks="tasks" @closeStage="closeStage" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import StageDescriptionBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditStage/BodyComponents/StageDescriptionBody";
import StageTasksBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditStage/BodyComponents/StageTasksBody";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule.ts";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";

export default {
  name: "CurtainShowAndEditStage",
  mixins: [],
  props: {},
  components: { StageTasksBody, StageDescriptionBody, ActionsList, TabsContent },
  data() {
    return {
      currentTab: "description",
      tabsList: [
        {
          label: "Описание",
          value: "description"
        },
        {
          label: "Задачи",
          value: "tasks",
          count: 0
        }
      ],
      actionList: [
        {
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить задачу",
          actionType: `createProject`
        },
        {
          isShow: true,
          iconType: "folder-check",
          actionText: "Выполнить",
          actionType: `execute`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ]
    };
  },
  mounted() {},
  methods: {
    goToStage() {
      StageModule.goToStagePage({
        project_id: this.currentProject.id,
        stage: this.currentStage
      });
      this.$router.push({
        name: "Project",
        params: { id: this.currentProject.id },
        query: { stage: `${this.currentStage.id}` }
      });
    },
    closeStage() {
      StageModule.toggleShowEditModal(false);
    },
    handleAction(val) {
      StageModule.handleActionList({
        action: val.actionType,
        stage: this.currentStage,
        currentProject: this.currentProject
      });
    }
  },
  computed: {
    currentStage() {
      return StageModule.openStage;
    },
    currentProject() {
      return ProjectModule.currentProject.projectFull;
    },
    tasks() {
      return TaskModule.tasksByStage(this.currentStage.id);
    },
    setActionList() {
      return StageModule.actionsList(this.currentStage, this.currentProject);
    },
    isShowActionList() {
      return (
        !this.currentProject.is_completed &&
        (this.currentProject.is_owner || !this.currentStage.is_completed)
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.show-edit-stage {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__container {
    position: fixed;
    width: 735px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);

    @media (max-width: $xxxl) {
      width: 550px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }

  &__header {
    height: 70px;
    padding: 20px 24px 24px 8px;
    border-bottom: 1px solid #eae8f0;
    display: flex;

    .icon-arrow-back {
      color: $icon-subdued;
      margin: 0 8px 0 0;
      display: none;
      font-size: 20px;

      @media (max-width: $md) {
        display: block;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 12px;
      cursor: pointer;
      transition: 0.3s all ease;

      &:hover {
        color: $link-default;
      }
    }
  }

  &__body {
    height: calc(100% - 70px);
    overflow: auto;
  }
}
</style>
