












import {Component, Prop, Vue} from "vue-property-decorator";
import BreadcrumbsItem from "@/aopV2/layout/LayoutMain/MainBreadcrumbs/BreadcrumbsItem.vue";
import router from "@/router";
@Component({
  components: {BreadcrumbsItem}
})
export default class MainBreadcrumbs extends Vue {
  @Prop({ type: Array, default: () => [] }) breadcrumbs!: Array<any>;
  @Prop({ type: String, default: "" }) activeBreadcrumbs!: string;

  handleBreadcrumb(item: { route: string }) {
    this.$store.commit("MainLayoutSetting/updateSettingParams", {
      paramName: "activeBreadcrumbs",
      value: item.route
    })
  }

}
