<template>
  <div class="menu-item" @click="openModalInfo">
    <div class="menu-item__wrap">
      <i :class="item.icon"></i>
      <p class="menu-item__text">{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MenuItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setAttachedModalValue"]),
    openModalInfo() {
      // if (!+this.item.count) return false;
      this.setAttachedModalValue(this.item);
      this.$emit("changeShowAttachModal", true);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.menu-item {
  display: flex;
  align-items: center;
  color: $text-default;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  i {
    color: $text-subdued;
    font-size: 24px;
    margin-right: 16px;
  }
}
</style>
