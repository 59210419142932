<template>
  <div class="uploader">
    <img
      class="uploader__img"
      :src="editValue ? editValue : require('@/assets/img/NewsImage/avatar-default.svg')"
      ref="iconChannel"
      alt="avatar"
      @click="showModal"
    />
    <div class="uploader__mask" @click="showModal"><i class="icon-plus"></i></div>
    <div class="uploader__container" v-show="show">
      <i class="icon-cross" @click="toggleShow"></i>
      <div class="uploader__main">
        <div class="uploader__wrap">
          <vue-avatar
            class="uploader__canvas"
            :width="500"
            :height="500"
            :scale="scale"
            :borderRadius="50"
            input-id="uploadConstructorChannel"
            ref="uploadConstructorChannel"
            @vue-avatar-editor:image-ready="onImageReady"
          >
          </vue-avatar>
        </div>
        <div class="uploader__range">
          <input type="range" min="1" max="3" step="0.02" v-model="scale" />
        </div>
      </div>
      <div class="uploader__footer">
        <button class="uploader__cancel" @click="toggleShow">Отменить</button>
        <button class="uploader__button" @click="getUrlFile">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAvatar } from "vue-avatar-editor-improved";

export default {
  name: "FileUploadChannelAvatar",
  mixins: [],
  props: [
    "multiple",
    "drop",
    "dropDirectory",
    "fileDrop",
    "deep",
    "lengthText",
    "showOnlyFiles",
    "editValue"
  ],
  components: {
    VueAvatar
  },
  data() {
    return {
      avatar: null,
      show: false,
      scale: 1,
      borderRadius: 50,
      imgDataUrl: ""
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.editValue) {
        this.avatar = this.editValue;
      }
    }, 0);
  },
  methods: {
    showModal() {
      this.newCanvas();
      this.show = true;
    },
    toggleShow() {
      this.show = !this.show;
    },
    newCanvas() {
      const canvas = this.$refs.uploadConstructorChannel.$refs.avatarEditorCanvas;
      const context = canvas.getContext("2d");
      let img = new Image();
      context.beginPath();
      context.moveTo(0, 0);
      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, 550, 550);

      function drawSVGBackground() {
        context.drawImage(img, 25, 25, 500, 500);
      }
      img.onload = function () {
        drawSVGBackground();
      };
      img.src = this.avatar;
    },
    getUrlFile() {
      let img = this.$refs.uploadConstructorChannel.getImageScaled();
      let imgBase24 = img.toDataURL();
      this.$refs.iconChannel.src = imgBase24;
      let file = [
        {
          file: imgBase24,
          file_name: "avatar.png"
        }
      ];
      this.$emit("changeIconChanel", file);
      this.show = false;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.uploader {
  position: relative;

  /deep/ {
    .uploader__img {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      margin-right: 16px;
    }

    .uploader__mask {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      border-radius: 50%;
      cursor: pointer;

      i {
        font-size: 20px;
        color: $surface-default;
      }
    }

    .uploader__container {
      position: absolute;
      z-index: 100000;
      top: 69px;
      left: 0;
      width: 434px;
      height: 346px;
      padding: 32px 0 0;
      background: #ffffff;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 24px;

      @media screen and (max-width: $sm) {
        left: -100px;
        width: 250px;
        height: 380px;
      }
    }

    .uploader__canvas canvas {
      width: 215px;
      height: 192px;
      background: #ffffff;
      border: 2px dashed #d3d2e0;
      border-radius: 24px;

      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }

    .uploader__main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 32px 24px;
    }

    .uploader__wrap {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $text-disabled;

      @media screen and (max-width: $sm) {
        width: 100%;
      }

      &:not(:last-child) {
        @media screen and (max-width: $sm) {
          margin-bottom: 20px;
        }
      }
    }

    .uploader__range {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }

    .uploader__range input {
      width: 100%;
      height: 24px;
    }

    input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }

    /deep/ input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      cursor: pointer;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      background-color: $surface-default !important;
      border: 1px solid $border-default !important;
      border-radius: 34px;
    }

    .icon-cross {
      color: $icon-subdued;
      font-size: 20px;
      position: absolute;
      top: 32px;
      right: 32px;
      padding: 0;

      @media screen and (max-width: $sm) {
        top: 15px;
        right: 15px;
      }

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $color-button-click;
      }
    }

    .uploader__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 32px 32px;
      border-top: 1px solid $border-disabled;
    }

    .uploader__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border: none;
      padding: 5px 12px;
      color: $surface-default;
      background-color: $action-primary;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 100px !important;
      outline: none;
    }

    @media (hover: hover) {
      .uploader__button:hover {
        background-color: $action-primary-hovered;
      }
    }

    .uploader__cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border: none;
      padding: 5px 12px;
      color: $text-subdued;
      background-color: $surface-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 100px !important;
      outline: none;
    }

    @media (hover: hover) {
      .uploader__cancel:hover {
        background-color: $surface-hovered;
      }
    }

    .avatar {
      position: relative;

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
      }

      img {
        width: 102px;
        height: 102px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .avatar-bg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar {
        img {
          width: 102px;
          height: 102px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .bg-plus {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;

        .icon-plus {
          color: $main-white;
          font-size: 23px;
        }
      }
    }
  }
}
</style>
