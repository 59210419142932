<template>
  <div
    class="task-row"
    :id="setId"
    :style="task.childs_count && task.childs_count > 0 ? 'cursor: pointer' : ''"
  >
    <div class="task-row__cell task-row__cell_name">
      <i class="icon-down-secondary" v-if="haveSubtasks"></i>
      <p class="task-row__name" @click.prevent="openCurtainTask" @click.middle="openCurtainTask">
        {{ setAlias }}-{{ task.task_id }} {{ task.name }}
      </p>
    </div>
    <div class="task-row__cell_priority task-row__cell">
      <p class="task-row__priority" :style="setStylePriority">{{ setPriority.name }}</p>
    </div>
    <div class="task-row__cell_project task-row__cell">
      <p
        :class="['task-row__project', { 'task-row__link': task.project_id && task.project_id }]"
        @click.prevent="openProjectPage"
      >
        {{ setProjectName }}
      </p>
    </div>
    <div class="task-row__cell_deadline task-row__cell">{{ getDateFormat }}</div>
    <div class="task-row__cell_stage task-row__cell">
      <p
        :class="['task-row__stage', { 'task-row__link': task.stage_id && task.stage_id }]"
        @click.prevent="openStagePage"
      >
        {{ setStageName }}
      </p>
    </div>
    <div class="task-row__cell_author task-row__cell">
      <User
        v-if="task.author_id"
        :avatar="setAuthor.avatar"
        :name="setAuthor.full_name"
        :id="setAuthor.id"
        :showInfo="false"
        :size="'36px'"
        :show-tooltip="true"
      />
    </div>
    <div class="task-row_status task-row__cell">
      <p class="task-row__status">
        <span>{{ setStatus }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import { EventBus } from "@/main";
import User from "@/components/Widgets/User/User";

export default {
  name: "MyTasksTableTask",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    isSubtask: {
      type: Boolean,
      default: false
    },
    tasksData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    fromPag: {
      type: Number,
      default: 1
    }
  },
  components: {
    User
  },
  data() {
    return {
      target: null,
      inViewModel: true
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#task-row_${this.task.id}`);
    const taskList = JSON.parse(JSON.stringify(this.tasksData)).reverse();
    if (taskList[1]?._task.id === this.task.id) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.totalCount !== this.tasksData.length) {
              EventBus.$emit("paginationEvent");
              this.observer.unobserve(this.target);
            }
          }
        });
      });
      this.observer.observe(this.target);
    }
  },
  methods: {
    ...mapActions("TaskManagerModule", ["openCurtainShowAndEditTask"]),
    openCurtainTask() {
      TaskModule.getCurrentTaskAction({
        task_id: this.task.task_id,
        alias: this.task.alias
      });
      this.$nextTick(() => {
        TaskManagerModuleV2.openCurtainShowAndEditTask({
          currentTaskForCreation: this.task
        });
      });
      TaskModule.getFilesListAction({
        task_id: this.task.id,
        per_page: 100
      });
    },
    openProjectPage() {
      if (this.task.project_id) {
        this.$router.push({ name: "Project", params: { id: this.task.project_id } });
      }
    },
    openStagePage() {
      if (this.task.stage_id) {
        this.$router.push({
          name: "Project",
          params: { id: this.task.project_id },
          query: { stage: `${this.task.stage_id}` }
        });
      }
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    haveSubtasks() {
      return this.task.childs_count && this.task.childs_count !== 0;
    },
    getDateFormat() {
      let date_from = this.task.date_from ? moment(this.task.date_from).format("DD.MM.YYYY") : "-";
      let date_to = this.task.date_to ? moment(this.task.date_to).format("DD.MM.YYYY") : "-";
      if (!this.task.date_from && !this.task.date_to) {
        return "-";
      } else {
        return date_from + " " + date_to;
      }
    },
    setProjectName() {
      return this.task.project_name ? this.task.project_name : "-";
    },
    setAlias() {
      return this.task.alias ? this.task.alias : "WAP";
    },
    setStageName() {
      return this.task.stage_name ? this.task.stage_name : "-";
    },
    setPriority() {
      return TaskModule.prioritiesList.length
        ? TaskModule.prioritiesList.find((item) => item.id === this.task.priority_id)
        : {};
    },
    setStatus() {
      return this.task.status_name ? this.task.status_name : "НЕТ";
    },
    setStylePriority() {
      return this.setPriority.name
        ? `color: ${this.setPriorityColor}; border: 1px solid ${this.setPriorityColor}; background: ${this.setPriorityBackground}`
        : "color: #FFB901; border: 1px solid #FFB901; background: #fef2be;";
    },
    setPriorityColor() {
      if (this.setPriority.name === "Высокий") {
        return "#FB646D";
      } else if (this.setPriority.name === "Средний") {
        return "#EE7200";
      } else {
        return "#FFB901";
      }
    },
    setPriorityBackground() {
      if (this.setPriority.name === "Высокий") {
        return "#fed1d3";
      } else if (this.setPriority.name === "Средний") {
        return "#fed3b3";
      } else {
        return "#fef2be";
      }
    },
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.task.author_id);
    },
    setId() {
      return this.isSubtask ? `subtask-row_${this.task.id}` : `task-row_${this.task.id}`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.task-row {
  display: flex;
  align-items: center;
  padding: 16px 0;
  width: 100%;

  &:hover {
    background-color: $surface-selected-hovered;
    border-radius: 2px;
  }

  @media (max-width: $lg) {
    width: 1065px;
  }

  &__cell {
    display: flex;
    width: 100%;
    flex-shrink: 1;
    padding-right: 20px;

    i {
      color: $icon-subdued;
      font-size: 8px;
      margin-right: 8px;
    }

    &_name {
      display: flex;
      align-items: center;
      width: 30%;
      max-width: 30%;
      flex-shrink: 0;
      padding-left: 24px;

      @media (max-width: $lg) {
        max-width: 300px;
        width: 300px;
      }

      i {
        margin-right: 8px;
      }

      .arrow-slidedown {
        flex-shrink: 0;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:last-child {
      width: 20%;

      @media (max-width: $lg) {
        width: 115px;
      }
    }

    &:nth-child(3) {
      width: 20%;

      @media (max-width: $lg) {
        width: 150px;
      }
    }

    &:nth-child(5) {
      width: 20%;

      @media (max-width: $lg) {
        width: 150px;
      }
    }
  }

  &__name,
  &__stage,
  &__project {
    @include textEllipsis(2);
    word-break: break-word;
  }

  &__link,
  &__name {
    cursor: pointer;

    &:hover {
      color: $link-hovered;
    }
  }

  &__priority,
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 90px;
    height: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    span {
      padding: 0 4px;
      @include textEllipsis(2);
    }
  }

  &__status {
    min-height: 30px;
    height: max-content;
    border: 1px solid #eae8f0;
    color: #a7a9c0;
    background: #f2f7fe;

    span {
      padding: 0 4px;
      @include textEllipsis(2);
    }
  }
}
</style>
