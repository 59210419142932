<template>
  <ul class="files-component">
    <Docs
      v-for="file in files"
      :key="file.id"
      :file="file"
      :isEdit="editFiles"
      @deleteFile="deleteFile"
    />
  </ul>
</template>

<script>
import Docs from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/Documents/Docs";
export default {
  name: "FilesComponent",
  mixins: [],
  props: {
    files: {
      type: Array,
      default: () => {
        return [];
      }
    },
    news: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showCross: {
      type: Boolean,
      default: false
    },
    editFiles: {
      type: Boolean,
      default: false
    }
  },
  components: { Docs },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    deleteFile(id) {
      const ids = this.files.findIndex((item) => item.id === id);
      this.$emit("files_splice", ids);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.files-component {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $xs) {
    margin-bottom: 4px;
  }
}
</style>
