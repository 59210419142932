<template>
  <div class="textarea">
    <div
      class="textarea__wrap"
      v-if="label.length"
      :class="{ textarea__wrap_field: !value.length && isReadonly }"
    >
      <label class="textarea__label">{{ label }}</label>
      <div class="textarea__icons" v-if="editMode">
        <i class="icon-done" @click.stop="handleDone"></i>
        <i class="icon-cross" @click.stop="handleCancel"></i>
      </div>
    </div>
    <textarea
      v-if="!isReadonly"
      class="textarea__control"
      :class="'textarea__control_' + classSize"
      v-model.trim="value"
      :placeholder="placeholder"
      @input="mixin_autoResize_resize"
      @focus="setEditMode"
      :maxlength="maxLength"
      ref="textarea"
      :data-input="dataInput"
      :rows="1"
      :disabled="isReadonly"
      v-click-outside="closeEditMode"
    ></textarea>
    <p
      v-if="value.length && isReadonly"
      class="textarea__control"
      :class="[
        'textarea__control_' + classSize,
        { textarea__control_disabled: isReadonly },
        'textarea__title'
      ]"
      v-html="setURL(value)"
    ></p>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import UrlMixin from "@/helpers/UrlMixin";
export default {
  name: "EditField",
  mixins: [UrlMixin],
  props: {
    isTextArea: {
      type: Boolean,
      default: true
    },
    classSize: {
      type: String,
      default: "h2"
    },
    dataInput: {
      type: String,
      default: ""
    },
    keyUser: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: "Добавьте описание"
    },
    label: {
      type: String,
      default: ""
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      editMode: false,
      defSize: 24,
      value: ""
    };
  },
  mounted() {
    this.setDefValue();
    this.setSize();
    window.addEventListener("resize", this.autoResizeTextarea);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.autoResizeTextarea);
  },
  methods: {
    autoResizeTextarea() {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 4 + "px";
    },
    setDefValue() {
      this.value = this.user[this.keyUser] !== null ? this.user[this.keyUser] : "";
    },
    closeEditMode(e) {
      const path = e.path || e.composedPath();
      const isIcons = path.some((item) => item.className === "textarea__icons");
      if (!isIcons) {
        if (!this.value.length) {
          this.setSize();
          this.editMode = false;
          return false;
        }
        this.handleDone();
      }
      this.autoResizeTextarea();
    },
    handleDone() {
      this.editMode = false;
      if (this.value === this.user[this.keyUser]) {
        this.$refs.textarea.style.height = this.defSize + 4 + "px";
      }
      if (this.value !== this.user[this.keyUser] && this.value.length)
        this.$emit("editDone", this.value.replace(/\n+/g, "\n"), this.keyUser);
      this.autoResizeTextarea();
    },
    handleCancel() {
      this.value = this.user[this.keyUser] ? this.user[this.keyUser] : "";
      this.$refs.textarea.style.height = this.defSize + "px";
      this.editMode = false;
    },
    setEditMode() {
      this.editMode = true;
    },
    mixin_autoResize_resize(event) {
      event.target.style.height = `${this.defSize}px`;
      const height =
        event.target.scrollHeight < this.defSize ? this.defSize : event.target.scrollHeight;
      event.target.style.height = `${height + 4}px`;
    },
    setSize() {
      this.$nextTick(() => {
        this.defSize = this.$refs.textarea.scrollHeight;
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 4 + "px";
      });
    }
  },
  computed: {},
  watch: {
    user() {
      this.setDefValue();
      this.$refs.textarea.style.height = this.defSize + "px";
      this.setSize();
    },
    isReadonly() {
      this.setSize();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $text-highlight;
  }
}

::-webkit-scrollbar-track {
  margin-left: 5px;
  background: #fafafe;
  cursor: pointer;
}
.textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  &__label {
    font-size: 18px;
    font-weight: 500;
    line-height: 26.5px;
  }
  &__title {
    word-break: break-word;
  }
  &__icons {
    display: flex;
    align-items: center;
    .icon-done {
      margin-right: 32px;
    }
    i {
      font-size: 24px;
      color: $icon-subdued;
      cursor: pointer;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-left: 8px;
    min-height: 24px;
    &_field {
      margin-bottom: 0;
    }
  }
  &__control {
    width: 100%;
    padding: 8px;
    //max-height: 44px;
    min-height: 46px;
    max-height: 350px;
    border-radius: 6px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $text-default;
    outline: none;
    resize: none;
    border: 2px solid transparent;
    transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
    white-space: pre-line;
    &_h2 {
      font-size: 22px;
      font-weight: 900;
    }

    &_h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }

    &_text {
      font-size: 16px;
      font-weight: 400;
      overflow: auto;
    }
    &:hover {
      background-color: #fafafe;
      box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    }

    &:focus {
      border: 2px solid #6a67ce;
    }
    &_disabled:hover,
    &:disabled {
      background-color: transparent;
      border: 2px solid transparent;
      box-shadow: none;
    }
    &_fill {
      border: 1px solid #eae8f0;
      background: #ffffff;
    }
  }
}
</style>
