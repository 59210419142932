<template>
  <div class="comment">
    <img :src="returnSrcFile(comment.author.avatar)" alt="avatar" />
    <div class="comment-info">
      <div class="author-name">
        {{ comment.author.full_name }}
        <i
          class="icon-cross"
          @click="deleteComment"
          v-if="admin || bookCreator || articleCreator || comment.author.id === getUserId"
        ></i>
      </div>
      <div v-if="!comment.is_deleted" class="content" v-html="setURL(comment.content)"></div>
      <div v-else class="content deleted">Комментарий был удален</div>
      <div class="comment-footer">
        <div class="comment-date-time">
          {{ setCreateTime(comment.datetime_of_creation) }}
          <div>
            {{ comment.datetime_of_creation.split(" ")[1].split(":").splice(0, 2).join(":") }}
          </div>
        </div>
        <div v-if="reply" @click="$emit('setReplyComment', comment)" class="comment-reply">
          Ответить
        </div>
      </div>
      <div class="button-show-comments" @click="showList = !showList" v-if="comment.replies.length">
        {{ !showList ? `Показать ответы (${comment.replies.length})` : "Скрыть ответы" }}
      </div>
      <slide-up-down :active="showList" v-if="comment.replies.length">
        <Comment
          v-for="reply in comment.replies"
          :key="reply.id"
          :comment="reply"
          :reply="false"
          :admin="admin"
          :creator="creator"
          @updateCommentsList="$emit('updateCommentsList')"
        >
        </Comment>
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment-precise-range-plugin";
import { formatDistance } from "date-fns";
import { ru } from "date-fns/locale";
import { HTTPRequest } from "@frontend-modules/fsql";
import { domain, domainStorage } from "@/globalVariables";
import SlideUpDown from "vue-slide-up-down";
import UrlMixin from "@/helpers/UrlMixin";

export default {
  name: "Comment",
  mixins: [UrlMixin],
  props: ["comment", "reply", "admin", "creator", "articleCreator"],
  components: { SlideUpDown },
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    setCreateTime(date) {
      let countDays = moment.preciseDiff(new Date(), new Date(date), true).days;
      if (!countDays) {
        return "Сегодня";
      } else if (countDays === 1) {
        return "Вчера";
      } else {
        return `${formatDistance(new Date(), new Date(date), {
          locale: ru
        })} назад`;
      }
    },
    deleteComment() {
      const Comment = new HTTPRequest();
      Comment.send(`${domain}/monolit/Comment/actionDelete/${this.comment.id}`, "DELETE").then(
        () => {
          this.$emit("updateCommentsList");
        }
      );
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.comment {
  display: flex;
  margin-bottom: 15px;

  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }

  .comment-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .author-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $color-text-accent;

      .icon-cross {
        display: none;
        cursor: pointer;
        color: $color-text-secondary;
        font-size: 10px;

        @media (max-width: $sm) {
          display: block;
        }
      }
      .icon-cross:hover {
        color: $color-button-hover;
      }
    }

    .content {
      position: relative;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: $color-text-primary-1;
      word-break: break-word;
      white-space: pre-wrap;

      /deep/ {
        a {
          color: $link-default;
        }

        a:hover,
        a:focus {
          color: $link-hovered;
        }
      }
    }
    .deleted {
      color: $color-text-secondary;
    }
    .comment-footer {
      display: flex;
      align-items: center;

      .comment-date-time {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: $color-text-primary-2;

        div {
          margin-left: 3px;
        }
      }

      .comment-reply {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: $color-interactive;
        margin-left: 6px;
        cursor: pointer;
        user-select: none;
      }
    }
    .button-show-comments {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
      margin: 10px 0;
      color: $text-subdued;
    }
  }
}
.comment:hover {
  .comment-info {
    .author-name {
      .icon-cross {
        display: block;
      }
    }
  }
}
</style>
