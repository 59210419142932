<template>
  <div class="channel-members" v-click-outside="editMembers">
    <ConfirmModal
      v-if="confirmDeleteUser"
      @cancel="cancelDeleteUser"
      @accept="deleteUser"
      :title="'Удалить'"
      :description="'Вы уверены, что хотите удалить участника?'"
    />
    <div class="channel-members__label">
      <h5>УЧАСТНИКИ</h5>
      <i class="icon-edit" @click="setEditMode" v-show="!editMode && isEditable"></i>
    </div>
    <div class="channel-members__members-list" v-if="!editMode">
      <div class="channel-members__user" v-for="user in users" :key="user.id">
        <User
          :avatar="user.avatar"
          :name="user.full_name"
          :id="user.id"
          :showTooltip="false"
          :size="'60px'"
          :showCross="isEditable"
          @crossHandler="openConfirmDeleteUser"
          :info="user.roles.length ? `[${user.roles[0].name}]` : ''"
        />
      </div>
    </div>
    <div class="channel-members__edit" v-else>
      <Select :placeholder="'Выберите сотрудника'" v-model="selected" :items="allUsers" />
    </div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import User from "@/components/Widgets/User/User";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "ChannelMembers",
  mixins: [],
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  components: { ConfirmModal, Select, User },
  data() {
    return {
      editMode: false,
      selected: [],
      confirmDeleteUser: false,
      userToDelete: 0
    };
  },
  mounted() {
    this.selected = this.editChannelValue.member_users;
  },
  methods: {
    ...mapActions("NewsModule", ["editChannelAction"]),
    setEditMode() {
      this.editMode = true;
      this.$emit("editModeMembersEntered");
    },
    editMembers(e) {
      // const parentNode = e.path.find((item) => item.className === "show-edit-channel");
      if (this.editMode) {
        this.editMode = false;
        const editedChannel = {
          member_users: this.selected.map((item) => item.id)
        };
        this.editChannelAction({ editedChannel, channelId: this.editChannelValue.id }).then(() => {
          this.editChannelValue.member_users = this.selected;
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Список участников канала успешно изменён!`
          });
          this.$emit("closeEditModeMembers");
        });
      }
    },
    openConfirmDeleteUser(userId) {
      this.userToDelete = userId;
      this.confirmDeleteUser = true;
    },

    deleteUser() {
      this.confirmDeleteUser = false;
      const editedMembers = this.editChannelValue.member_users.filter(
        (item) => item.id !== this.userToDelete
      );
      const editedChannel = {
        member_users: editedMembers.map((item) => item.id)
      };
      this.editChannelAction({
        editedChannel,
        channelId: this.editChannelValue.id
      }).then(() => {
        this.editMode = false;
        this.editChannelValue.member_users = editedMembers;
        this.selected = editedMembers;
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Пользователь успешно удален!`
        });
      });
    },
    cancelDeleteUser() {
      this.confirmDeleteUser = false;
    }
  },
  computed: {
    ...mapState("NewsModule", ["editChannelValue"]),
    ...mapState("IspModule", ["allUsers"]),
    users() {
      return this.editChannelValue.member_users;
    }
  },
  watch: {
    currentTeam() {
      this.selected = this.editChannelValue.member_users;
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.channel-members {
  height: calc(100% - 150px);

  &__label {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    &:hover .icon-edit {
      opacity: 1;
    }
    h5 {
      margin: 0 5px 0 0;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
    }
    i {
      font-size: 20px;
      color: #a7a9c0;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s all ease;
      &:hover {
        color: $icon-highlight;
      }
    }
  }
  &__user {
    display: flex;
    align-items: center;
    word-break: break-word;
  }
  &__members-list {
    max-height: calc(100% - 30px);
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media (max-width: $xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  /deep/ {
    .user {
      &__name {
        font-weight: 500;
        white-space: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &__description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
