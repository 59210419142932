<template>
  <div class="modal-menu" @click.self="closeMenuChannels">
    <div class="modal-menu__window">
      <div class="modal-menu__header">
        <div class="modal-menu__title">
          <i class="icon-arrow-back" @click="closeMenuChannels"></i>
          <h2 class="modal-menu__title-name">Каналы</h2>
        </div>
        <ChosenFilter
          :chosenList="selectChannelsList"
          v-model="currentChannels"
          :icon="'view-day-small'"
          :placeholder="currentChannels[0].name"
          :allItem="false"
          @input="changeChannelsList"
        />
      </div>
      <Spinner v-if="loadingChannels" class="loader" />
      <div
        v-if="(channelList.length || channelAllList.length) && !loadingChannels"
        ref="channel_list"
        class="channels__channel-list channel-list"
        :class="windowWidth < 768 ? 'channels__channel-list_hide' : ''"
      >
        <ul class="channel-list__wrap channel-list__wrap_all" v-if="isAllChannel">
          <ChannelComponent
            @click="closeAllMenu"
            v-for="channel in channelAllList"
            :key="channel.id"
            :channel="channel"
            :showTooltip="false"
            :showName="true"
            :activeChannel="activeChannel"
            @transferActiveChannel="setActiveChannel"
            :isAdmin="isAdmin"
            :memberInfoChannel="memberInfoChannel"
          />
          <li v-if="!channelAllList.length" class="channel__empty">Пусто :(</li>
        </ul>
        <ul class="channel-list__wrap" v-if="isMyChannel">
          <ChannelComponent
            @click="closeAllMenu"
            v-for="channel in channelList"
            :key="channel.id"
            :channel="channel"
            :showName="true"
            :activeChannel="activeChannel"
            @transferActiveChannel="setActiveChannel"
            :isAdmin="isAdmin"
            :memberInfoChannel="memberInfoChannel"
            :showUnsubscribe="!isMyChannel"
          />
          <li v-if="!channelList.length" class="channel__empty">Пусто :(</li>
        </ul>
        <div class="channel-list__create">
          <Button
            :type="'white'"
            :title="'Создать канал'"
            :icon="'playlist-add'"
            @handleButton="openModalAddChanel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import Vue from "vue";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import Button from "@/modules/UiKit/components/buttons/Button";
import ChannelComponent from "@/modules/News/components/Channel/ChannelComponent";

export default {
  name: "ChannelModalMenu",
  mixins: [],
  props: [
    "activeChannel",
    "channelList",
    "loadingChannels",
    "isAdmin",
    "memberInfoChannel",
    "filtersChannelList",
    "windowWidth"
  ],
  components: {
    Spinner,
    ChosenFilter,
    Button,
    ChannelComponent
  },
  data() {
    return {
      isAllChannel: false,
      isMyChannel: true,
      selectChannelsList: [
        { id: 1, name: "Мои каналы" },
        { id: 2, name: "Все каналы" }
      ],
      currentChannels: [{ id: 1, name: "Мои каналы" }]
    };
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["getNewsItemInfoAction", "changeModalAddChannel"]),
    ...mapMutations("NewsModule", [
      types.SHOW_CHANNEL_MODAL_CHANNELS,
      types.SHOW_CHANNEL_MODAL_MENU,
      types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL
    ]),
    closeMenuChannels() {
      this.SHOW_CHANNEL_MODAL_CHANNELS(false);
      this.SHOW_CHANNEL_MODAL_MENU(true);
    },
    closeAllMenu() {
      this.SHOW_CHANNEL_MODAL_CHANNELS(false);
      this.SHOW_CHANNEL_MODAL_MENU(false);
    },
    setActiveChannel(value) {
      this.$emit("setActiveChannel", value);
      this.$refs.channel_list.classList.add("channels__channel-list_open");
      if (this.windowWidth < 768) {
        this.closeAllMenu();
      }
    },
    changeChannelsList() {
      switch (this.currentChannels[0].id) {
        case 1:
          this.isMyChannel = true;
          this.isAllChannel = false;
          this.$refs.channel_list.classList.add("channels__channel-list_open");
          break;
        case 2:
          this.isAllChannel = true;
          this.isMyChannel = false;
          this.$refs.channel_list.classList.add("channels__channel-list_open");
          break;
      }
    },
    hideChannelList() {
      if (this.windowWidth <= 768) {
        this.$refs.channel_list.classList.remove("channels__channel-list_open");
      }
    },
    openModalAddChanel() {
      this[types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL](true);
    }
  },
  computed: {
    ...mapState("NewsModule", ["channelAllList"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    showPrivateChannel() {
      return this.channelList.filter((item) => !item.is_public);
    },
    showPublicChannel() {
      return this.channelList.filter((item) => item.is_public);
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal-menu {
  display: flex;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  @media screen and (max-width: $sm) and (orientation: landscape) {
    top: 50px;
  }
  &__window {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    width: 350px;
    border-right: 1px solid #eae8f0;
    max-height: 100%;
    background: white;
    overflow-y: auto;
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding-bottom: 35px;
    }
    @media (max-width: $xs) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    border-bottom: 1px solid #eae8f0;
    padding: 20px;
    justify-content: space-between;

    @media (max-width: $xs) {
      padding: 20px;
    }
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding: 10px 20px;
      height: 40px;
    }

    /deep/ {
      .chosen-filter {
        &__name {
          font-size: 14px;
        }
      }
    }
    i {
      color: $text-disabled;
      margin-right: 8px;
      font-size: 20px;
      cursor: pointer;
      transition: 0.3s color ease;
      &:hover {
        color: $icon-highlight;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__title-name {
    margin: 0 8px 0 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    @media (max-width: $xxxl) {
      font-size: 16px;
    }
    @media (max-width: $md) {
      font-size: 18px;
    }
  }
  &__body {
    padding: 26px;
    overflow: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    p {
      font-size: 14px;
      line-height: 20px;
      color: #343748;
    }

    i {
      font-size: 14px;
      margin-right: 8px;
      color: #a7a9c0;
    }
  }
}
/deep/ {
  .chosen-filter {
    &__label {
      span {
        display: block;
        @media (max-width: $md) {
          display: block;
        }
      }
    }

    &__list {
      @media (max-width: $md) {
        left: 0;
        right: auto;
        //top: 24px;

        min-width: 150px;
      }

      @media (max-width: $sm) {
        position: absolute;
        width: max-content;
        top: 0;
        bottom: 0;
        height: fit-content;
        border-radius: 4px;
      }
    }

    &__wrapper {
      @media (max-width: $sm) {
        position: fixed;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        z-index: 100;
        background: none;
      }
    }
  }
}

.channel__empty {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: $text-disabled;
  user-select: none;
}

/deep/.channel {
  &__empty {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: $text-disabled;
    user-select: none;
  }

  &__subscribe {
    span {
      @media (max-width: $xxxl) {
        display: none;
      }
    }
  }
  &__subscribed {
    i {
      margin: 0;
    }
    span {
      @media (max-width: $xxxl) {
        display: none;
      }
    }
  }
}

.channels__channel-list {
  display: flex;
}

.channel-list {
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  &__wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    list-style: none;
    padding: 24px;
    overflow: auto;
    height: calc(100% - 60px);
    /deep/ .channel {
      margin-bottom: 24px;
      @media (max-width: $xs) {
        margin-bottom: 13px;
      }
    }
  }
  &__create {
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: start;
    /deep/ {
      .aop-button_white {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 0;
    margin: 0 24px 0 0;
    width: 24px;
    height: 32px;
    border: none;
    background: transparent;
    outline: none;
    @media (max-width: $md) {
      padding: unset;
    }
    i {
      color: $icon-subdued;
      font-size: 24px;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }
  }
}
</style>
