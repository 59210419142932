<template>
  <div class="modal-item" @click="handleSelectUser">
    <div class="modal-item__container">
      <div class="modal-item__avatar">
        <img class="modal-item__logo" :src="returnSrcFile(user.avatar)" alt="" />
        <span class="modal-item__status"></span>
      </div>
      <div class="modal-item__wrap">
        <span class="modal-item__name"> {{ user.full_name }} </span>
        <span class="modal-item__text">@{{ user.nickname }}</span>
      </div>
    </div>
    <button class="modal-item__button" @click.stop.prevent="handleSelectUser">
      <i class="icon-plus" :class="{ 'icon-plus_selected': isSelected }"></i>
    </button>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalItem",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["addMembers", "createChatUser", "leaveChannel"]),
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleSelectUser() {
      const user = {
        chat_id: this.channelItem.channel.id,
        info: this.user,
        is_moderator: false,
        user_id: this.user.id
      };
      if (this.isSelected) {
        this.leaveChannel({
          type: "delete",
          id: this.channelItem.channel.id,
          user_id: this.user.id
        }).then((resp) => {
          this.addMembers(user);
        });
      } else {
        this.createChatUser({
          type: "add",
          id: this.channelItem.channel.id,
          users: [{ user_id: this.user.id, is_moderator: false }]
        }).then((resp) => {
          this.addMembers(user);
        });
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", ["selectUser", "users", "channelItem"]),
    isSelected() {
      return this.users.some((item) => item.user_id === this.user.id);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal-item {
  display: flex;
  padding: 8px;
  background: #fff;
  transition: background 0.2s ease;
  border-radius: 8px;
  min-height: 58px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .icon-plus {
    display: flex;
    font-size: 20px;
    color: $icon-subdued;
    transition: all 0.3s ease;
    &_selected {
      color: $icon-default;
      transform: rotate(-45deg);
    }
  }
  &__container {
    display: flex;
  }
  &__avatar {
    margin-right: 8px;
    height: 40px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    object-fit: cover;
    background-color: $message;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $system-grey-seven;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    padding: 0;
    margin: 0 0 11px;
  }
  &__button {
    width: 32px;
    height: 32px;
    background: $action-secondary;
    border-radius: 4px;
    border: none;
    outline: none;

    @media (hover: hover) {
      &:hover {
        background: $action-secondary-hovered;

        i {
          color: $icon-hovered;
        }
      }
    }

    &:active {
      background: $action-secondary-pressed;
      i {
        color: $icon-hovered;
      }
    }
  }
}
</style>
