



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ControlPanel extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  isLeft!: boolean;
  @Prop({
    default: null,
    type: Function
  })
  controlComponent!: any;
  isClose: boolean = true;

  swipeHandlerOpen(e) {
    if (!this.isLeft && e === "left") {
      this.handleClose();
    }
    if (this.isLeft && e === "right") {
      this.handleClose();
    }
  }

  swipeHandler(e) {
    if (this.isLeft && e === "left") {
      this.handleClose();
    }
    if (!this.isLeft && e === "right") {
      this.handleClose();
    }
  }

  handleButton() {
    if (this.width > 576 && this.height > 576) {
      this.handleClose();
    }
  }

  handleClose() {
    this.isClose = !this.isClose;
    this.$emit("togglePanel", this.isClose);
  }

  updateInfo() {
    this.$emit("updateInfo");
  }

  updateType() {
    this.$emit("updateType");
  }

  get width() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get height() {
    return this.$store.getters["MainLayoutSetting/height"];
  }
}
