import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import * as workerInterval from "worker-interval";
import Vue from "vue";
import {
  ILimit,
  IModalConfig,
  IProject,
  IStatistic,
  ISyncProject,
  ITableData,
  ITask,
  ITaskList,
  ITimeModalData,
  ITimeProject,
  IToggleTaskPayload,
  TimeDataDto
} from "@/modules/TimeTracker/interface/interface";
import TimeProjectService from "@/modules/TimeTracker/Service/TimeProjectService";
import moment from "moment";
import { secToTime, timeToSec } from "@/modules/TimeTracker/helpers/timeFormat";
import { EventBus } from "@/main";
import centrifuge from "@/centrifuge";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import WorkCalendarService from "@/modules/TimeTracker/Service/WorkCalendarService";
import { weekDay } from "@/modules/TimeTracker/helpers/weekDay";
import { domainS3 } from "@/globalVariables";
import { broadcastCentrifuge } from "@/modules/TimeTracker/helpers/broadcastChannel";

const name = "TimeTracker";

export enum TimeTrackerMutationTypes {
  SET_DEFAULT_DATA = "SET_DEFAULT_DATA",
  TOGGLE_TIME = "TOGGLE_TIME",
  TOGGLE_PROJECT_MODAL = "TOGGLE_PROJECT_MODAL",
  SET_TABLE_DATE = "SET_TABLE_DATE",
  SET_TIMER_ID = "SET_TIMER_ID",
  TOGGLE_MODAL = "TOGGLE_MODAL",
  SET_MODAL_DATA = "SET_MODAL_DATA",
  SET_ACTIVE_TASK = "SET_ACTIVE_TASK",
  SET_STATISTIC = "SET_STATISTIC",
  SET_USER_LIMITS = "SET_USER_LIMITS",
  SET_MY_PROJECT = "SET_MY_PROJECT",
  SET_ALL_PROJECT = "SET_ALL_PROJECT",
  SET_PROJECT_SYNC_LIST = "SET_PROJECT_SYNC_LIST",
  SET_TASK_BY_PROJECT_ID = "SET_TASK_BY_PROJECT_ID",
  SET_TABLE_DATA = "SET_TABLE_DATA",
  SET_WEEK_LIST = "SET_WEEK_LIST",
  TOGGLE_ACTION_PROJECT_MODAL = "TOGGLE_ACTION_PROJECT_MODAL",
  SET_PROJECT_MEMBERS = "SET_PROJECT_MEMBERS",
  LOADER_TASK = "LOADER_TASK",
  TOGGLE_MODAL_PICKER = "TOGGLE_MODAL_PICKER",
  ADD_PROJECT = "ADD_PROJECT",
  ADD_TASK = "ADD_TASK",
  EDIT_TASK = "EDIT_TASK",
  DELETE_TASK = "DELETE_TASK",
  SET_TASKS_PROJECT = "SET_TASKS_PROJECT",
  DELETE_PROJECT_MEMBER = "DELETE_PROJECT_MEMBER",
  SET_WEEK_TIME = "SET_WEEK_TIME",
  SET_USER_WORK_TIME = "SET_USER_WORK_TIME",
  SET_CURRENT_DAY_TIME = "SET_CURRENT_DAY_TIME",
  SET_TODAY_TIME = "SET_TODAY_TIME",
  UPDATE_TABLE_TIME = "UPDATE_TABLE_TIME",
  DELETE_TIME = "DELETE_TIME",
  SET_MODAL_CONFIRM_DATA = "SET_MODAL_CONFIRM_DATA",
  TOGGLE_MODAL_CONFIRM = "TOGGLE_MODAL_CONFIRM",
  SET_NEED_SYNC = "SET_NEED_SYNC",
  ADD_TIME_TABLE = "ADD_TIME_TABLE",
  SET_MY_PROJECT_PAGE = "SET_MY_PROJECT_PAGE",
  EDIT_WEEK_LIMIT = "EDIT_WEEK_LIMIT",
  EDIT_USER_LIMIT = "EDIT_USER_LIMIT",
  TOGGLE_SYNC = "TOGGLE_SYNC",
  SET_TIME = "SET_TIME"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

function getObject<T>(json: string | null): T | null {
  return json ? JSON.parse(json) : null;
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class TimeTracker extends VuexModule {
  isActive: boolean = false;
  isSync: boolean = false;
  isShowProjectModal: boolean = false;
  loaderTask: boolean = false;
  isShowExportModal: boolean = false;
  isShowModal: boolean = false;
  isShowConfirmationModal: boolean = false;
  isShowTimeTrackerWindow: boolean = false;
  time: number = 0;
  timerId: string | null = null;
  todayTime: string = "00:00:00";
  statistic: IStatistic | null = null;
  date: string = sessionStorage.getItem("filterDate")
    ? sessionStorage.getItem("filterDate")!
    : moment(new Date()).format("YYYY-MM-DD");
  weekTime: string = "00:00:00";
  userWeekTime: string = "00:00:00";
  modalData: any | null = null;
  reasons: Array<any> = [];
  tasksList: ITaskList = {};
  activeTask: ITask | null = getObject<ITask>(localStorage.getItem("activeTask"));
  activeProject: IProject | null = getObject<IProject>(localStorage.getItem("activeProject"));
  editProject: IProject | null = null;
  projectsAll: Array<ITimeProject> = [];
  projectSyncList: Array<ISyncProject> = [];
  projects: Array<ITimeProject> = [];
  userLimits: Array<ILimit> = [];
  projectPage: number = 1;
  limit: string = "40:00";
  tasksProject: IProject | null = null;
  userLimit: number = 0;
  user_id: number | null = getObject<number>(sessionStorage.getItem(`filterUser`));
  taskList: object = {};
  weekList: Array<ILimit> = [];
  projectMembers: Array<any> = [];
  modalConfirmData: null | IModalConfig = null;
  startDayWeek = weekDay(new Date()).startWeekDay;
  tableData: ITableData = { list: [], count: 0 };
  currentDayTime: string = "00:00:00";
  modalType: string = "add";

  @Mutation
  [TimeTrackerMutationTypes.SET_DEFAULT_DATA]() {
    this.isActive = false;
    if (this.timerId) workerInterval.clearInterval(this.timerId);
    this.time = 0;
    this.todayTime = "00:00:00";
    this.currentDayTime = "00:00:00";
    this.weekTime = "00:00:00";
    this.user_id = null;
    this.date = moment(new Date()).format("YYYY-MM-DD");
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_TIME](isActive: boolean) {
    this.isActive = isActive;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TIME]() {
    this.time += 1;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TABLE_DATE](date) {
    this.date = date;
    sessionStorage.setItem(
      `filterDate`,
      this.date ? this.date : moment(new Date()).format("YYYY-MM-DD")
    );
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_PROJECT_MODAL](isShow) {
    this.isShowTimeTrackerWindow = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_MODAL](isShow) {
    this.isShowModal = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_ACTION_PROJECT_MODAL](payload) {
    this.isShowProjectModal = payload.isShow;
    this.editProject = payload.project;
    this.modalType = payload.type;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_PROJECT_MEMBERS](members) {
    this.projectMembers = members;
  }

  @Mutation
  [TimeTrackerMutationTypes.LOADER_TASK](isShow: boolean) {
    this.loaderTask = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_MODAL_PICKER](isShow: boolean) {
    this.isShowExportModal = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.DELETE_PROJECT_MEMBER](user_id) {
    this.projectMembers = this.projectMembers.filter((item) => item !== user_id);
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TIMER_ID](id: null | string) {
    this.timerId = id;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_MODAL_DATA](data) {
    this.modalData = data;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_MY_PROJECT](projects) {
    this.projects = projects;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_ALL_PROJECT](projects) {
    this.projectsAll = projects;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_PROJECT_SYNC_LIST](list: Array<ISyncProject>) {
    this.projectSyncList = list;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_NEED_SYNC](provider_identity: number) {
    const project = this.projectSyncList.find(
      (item) => item.provider_identity === provider_identity
    );
    if (project) {
      const index = this.projectSyncList.findIndex(
        (item) => item.provider_identity === provider_identity
      );
      const newProject = { ...project, need_to_sync: false };
      Vue.set(this.projectSyncList, index, newProject);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_ACTIVE_TASK](payload) {
    if (payload) {
      this.activeTask = payload.task;
      this.activeProject = payload.project;
    } else {
      this.activeTask = null;
      this.activeProject = null;
    }
    localStorage.setItem("activeTask", JSON.stringify(this.activeTask));
    localStorage.setItem("activeProject", JSON.stringify(this.activeProject));
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_MY_PROJECT_PAGE](page: number) {
    this.projectPage = page + 1;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TASK_BY_PROJECT_ID](payload) {
    const data = this.tasksList[payload.project_id];
    if (data) {
      const list = setUniqueArray([...payload.data.list, ...data.list], "id");
      const newData = { ...payload.data, list };
      Vue.set(this.tasksList, `${payload.project_id}`, newData);
    } else {
      Vue.set(this.tasksList, `${payload.project_id}`, payload.data);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TODAY_TIME](time) {
    this.todayTime = time;
    const [hh, mm, ss] = this.todayTime.split(":");
    this.time = +hh * 3600 + +mm * 60 + +ss;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_WEEK_TIME](time) {
    this.weekTime = time;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_USER_WORK_TIME](time) {
    this.userWeekTime = time;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_CURRENT_DAY_TIME](time) {
    this.currentDayTime = time;
  }

  @Mutation
  [TimeTrackerMutationTypes.ADD_TIME_TABLE](data) {
    if (this.date === data.date && this.user_id === store.getters.getUserId) {
      const list = [...this.tableData.list, data];
      // @ts-ignore
      list.sort((a, b) => moment(`${a.date} ${a.time_start}`) - moment(`${b.date} ${b.time_stop}`));
      Vue.set(this.tableData, "list", list);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.UPDATE_TABLE_TIME](data: ITimeModalData) {
    const itemTable = this.tableData.list.find((item) => item.id === data.id);
    if (itemTable) {
      const itemTableIndex = this.tableData.list.findIndex((item) => item.id === data.id);
      const newData = { ...itemTable, ...data };
      Vue.set(this.tableData.list, itemTableIndex, newData);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.DELETE_TIME](id: number) {
    if (this.tableData.list.length) {
      const list = this.tableData.list.filter((item) => item.id !== id);
      Vue.set(this.tableData, "list", list);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_MODAL_CONFIRM_DATA](payload: IModalConfig) {
    this.isShowConfirmationModal = true;
    this.modalConfirmData = payload;
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](isShow: boolean) {
    this.isShowConfirmationModal = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.ADD_PROJECT](project) {
    this.projectsAll.push(project);
  }

  @Mutation
  [TimeTrackerMutationTypes.ADD_TASK](task: any) {
    const tasksList = this.tasksList[task.project_id];
    if (tasksList && task) {
      const list = [...tasksList.list, task];
      const newTaskList = { ...tasksList, list, count: tasksList.count + 1 };
      Vue.set(this.tasksList, task.project_id, newTaskList);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.EDIT_TASK](data: { name: string; id: number; project_id: number }) {
    const tasksList = this.tasksList[data.project_id];
    if (tasksList && data) {
      const task = tasksList.list.find((item) => item.id === data.id);
      const index = tasksList.list.findIndex((item) => item.id === data.id);
      Vue.set(this.tasksList[data.project_id].list, index, { ...task, name: data.name });
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.DELETE_TASK](data: { id: number; project_id: number }) {
    const tasksList = this.tasksList[data.project_id];
    if (tasksList && data) {
      const tasks = tasksList.list.filter((item) => item.id !== data.id);
      Vue.set(this.tasksList[data.project_id], "list", tasks);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TASKS_PROJECT](project) {
    this.tasksProject = project;
  }

  @Mutation
  [TimeTrackerMutationTypes.TOGGLE_SYNC](isShow: boolean) {
    this.isSync = isShow;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_WEEK_LIST](weekList: Array<ILimit>) {
    this.weekList = weekList;
    this.userLimits = weekList;
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_USER_LIMITS](limits: Array<ILimit>) {
    this.userLimits = limits;
  }

  @Mutation
  [TimeTrackerMutationTypes.EDIT_WEEK_LIMIT](data) {
    const week = this.weekList.find((item) => item.id === data.id);
    const [hh, mm] = data.limit.split(":");
    if (week) week.limit = `${hh}:${mm}`;
  }

  @Mutation
  [TimeTrackerMutationTypes.EDIT_USER_LIMIT](data) {
    const week = this.userLimits.find((item) => item.week === data.week);
    if (week) {
      const index = this.userLimits.findIndex((item) => item.week === data.week);
      const newWeek = { ...week, limit: data.limit };
      Vue.set(this.userLimits, index, newWeek);
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_TABLE_DATA](tableData: ITableData) {
    const list = [...this.tableData.list, ...tableData.list];
    this.tableData = { ...tableData, list };
  }

  @Action
  async toggleTime(payload: IToggleTaskPayload) {
    await this.toggleTask({
      isActive: payload.isActive,
      task: payload.task,
      project: payload.project
    });
    await centrifuge.publish(`$user@${store.getters.getUserId}`, {
      type: "time",
      data: payload,
      limit: this.limit
    });
  }

  @Action
  activeTimer(payload: IToggleTaskPayload) {
    if (payload.isActive === this.isActive && !this.isActive) {
      if (this.timerId) workerInterval.clearInterval(this.timerId);
      this[TimeTrackerMutationTypes.SET_TIMER_ID](null);
      return;
    }
    const date_stop =
      this.startDayWeek === moment(new Date()).format("YYYY-MM-DD")
        ? null
        : moment(new Date()).format("YYYY-MM-DD");
    this.getMyWeekWorkTime({ date_start: this.startDayWeek, date_stop, is_week: true });
    if (this.timerId) workerInterval.clearInterval(this.timerId);
    this[TimeTrackerMutationTypes.TOGGLE_TIME](payload.isActive);
    if (this.isActive) {
      const timerId: null | string = workerInterval.setInterval(async () => {
        this[TimeTrackerMutationTypes.SET_TIME]();
        if (this.isWeekLimit) {
          centrifuge.publish(`$user@${store.getters.getUserId}`, {
            type: "limit",
            data: payload
          });
        }
        if (this.halfLimit) {
          await centrifuge.publish(`$user@${store.getters.getUserId}`, {
            type: "half-limit"
          });
        }
      }, 1000);
      this[TimeTrackerMutationTypes.SET_TIMER_ID](timerId);
    } else {
      if (this.timerId) workerInterval.clearInterval(this.timerId);
      this[TimeTrackerMutationTypes.SET_TIMER_ID](null);
    }
  }

  @Action
  setActiveTask(payload) {
    this[TimeTrackerMutationTypes.SET_ACTIVE_TASK](payload);
    if (payload.isActive === this.isActive && !this.isActive) {
      return;
    }
    this.getMyTodayWorkTime();
    // this[TimeTrackerMutationTypes.SET_TODAY_TIME](this.workTime);
  }

  @Action
  async syncData(showNotification: boolean) {
    this[TimeTrackerMutationTypes.TOGGLE_SYNC](true);
    await TimeProjectService.sync();
    this[TimeTrackerMutationTypes.TOGGLE_SYNC](false);
    if (showNotification) {
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Задачи синхронизированы"
      });
    }
  }

  @MutationAction
  async getMyWeekLimit(params?) {
    const limit = await TimeProjectService.getMyWeekLimit(params);
    const [hh, mm] = limit.split(":");
    const userId = store.getters.getUserId;
    broadcastCentrifuge([userId], "set-limit", { limit: `${hh}:${mm}` });
    return { limit: `${hh}:${mm}` };
  }

  @Mutation
  updateUserLimit(data: { limit: string }) {
    const [hh, mm] = data.limit.split(":");
    this.limit = `${hh}:${mm}`;
  }

  @Mutation
  setCurrentUserId(id: number | null) {
    this.user_id = id;
    sessionStorage.setItem(`filterUser`, this.user_id ? `${this.user_id}` : "");
  }

  @Action
  async editTime(data: ITimeModalData) {
    try {
      await TimeProjectService.editTime(new TimeDataDto(data));
      this[TimeTrackerMutationTypes.UPDATE_TABLE_TIME](data);
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Запись успешно измененна"
      });
      await this.updateWorkTime();
      this.toggleShowModal({ isShow: false, data: null });
    } catch (e) {
      // @TODO добавить обработку ошибок на фронте
      if (e.response.status === 409) {
        if (e.response.data.data[0] === "timesheet.time_has_run_out") {
          EventBus.$emit("showNotification", {
            timeout: 5000,
            type: "error",
            label: "Добавленное время превышает недельный лимит"
          });
        } else if (e.response.data.data[0] === "timesheet.overlay") {
          EventBus.$emit("showNotification", {
            timeout: 5000,
            type: "error",
            label: "Временной промежуток пересекается по времени с уже существующим"
          });
        }
      }
    }
  }

  @Action
  async updateWorkTime() {
    const days = weekDay(this.date, "Воскресенье");
    await Promise.all([
      this.getMyWeekWorkTime({
        date_start: this.date,
        date_stop: moment(this.date).add(1, "days").format("YYYY-MM-DD")
      }),
      this.getUserWeekWorkTime({
        date_start: days.startWeekDay,
        date_stop: moment(days.lastDayWeek).add(1, "days").format("YYYY-MM-DD"),
        user_id: this.user_id
      })
    ]);
    const userId: number = store.getters.getUserId;
    const isCurrentWeek =
      days.startWeekDay ===
      weekDay(moment(new Date()).format("YYYY-MM-DD"), "Воскресенье").startWeekDay;
    if (isCurrentWeek) {
      const today =
        moment(this.date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")
          ? this.currentDayTime
          : null;
      broadcastCentrifuge([userId], "set-time", { weekTime: this.userWeekTime, dayTime: today });
    }
  }

  @Action
  updateTimeCentrifuge(data: { weekTime: string; dayTime: string | null }) {
    if (data.dayTime) {
      this[TimeTrackerMutationTypes.SET_TODAY_TIME](data.dayTime);
    }
    const weekTime = secToTime(timeToSec(data.weekTime) - this.time);
    this[TimeTrackerMutationTypes.SET_WEEK_TIME](weekTime);
  }

  @Action
  async deleteTime(id: number) {
    try {
      await TimeProjectService.deleteTime({ id });
      this[TimeTrackerMutationTypes.DELETE_TIME](id);
      this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      await this.updateWorkTime();
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async addTime(data) {
    try {
      const resp = await TimeProjectService.addTime(new TimeDataDto(data));
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Запись успешно создана"
      });
      this[TimeTrackerMutationTypes.ADD_TIME_TABLE]({ ...data, id: resp.id });
      await this.updateWorkTime();
      this.toggleShowModal({ isShow: false, data: null });
    } catch (e) {
      if (e.response.status === 409) {
        if (e.response.data.data[0] === "timesheet.time_has_run_out") {
          EventBus.$emit("showNotification", {
            timeout: 5000,
            type: "error",
            label: "Добавленное время превышает недельный лимит"
          });
        } else if (e.response.data.data[0] === "timesheet.overlay") {
          EventBus.$emit("showNotification", {
            timeout: 5000,
            type: "error",
            label: "Временной промежуток пересекается по времени с уже существующим"
          });
        }
      }
    }
  }

  @Action
  async confirmDelete(id) {
    const config: IModalConfig = {
      cancelButton: "Отменить",
      confirmButton: "Удалить",
      title: "Удалить запись?",
      text: "Удаленную запись нельзя будет вернуть",
      handleCancel: () => {
        this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      },
      handleConfirm: () => this.deleteTime(id)
    };
    this[TimeTrackerMutationTypes.SET_MODAL_CONFIRM_DATA](config);
  }

  @Action
  async confirmTaskDelete(data: { id: number; project_id: number }) {
    const config: IModalConfig = {
      cancelButton: "Отменить",
      confirmButton: "Удалить",
      title: "Удалить задачу?",
      text: "Удаленную запись нельзя будет вернуть",
      handleCancel: () => {
        this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      },
      handleConfirm: () => this.deleteTask(data)
    };
    this[TimeTrackerMutationTypes.SET_MODAL_CONFIRM_DATA](config);
  }

  @Action
  confirmClose(title) {
    const config: IModalConfig = {
      cancelButton: "Отменить",
      confirmButton: "Да",
      title,
      text: "Введенные данные будут потеряны",
      handleCancel: () => {
        this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      },
      handleConfirm: () => {
        this.toggleShowModal({ isShow: false, data: null });
        this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      }
    };
    this[TimeTrackerMutationTypes.SET_MODAL_CONFIRM_DATA](config);
  }

  @Action
  async getMyTodayWorkTime(isStartTask?: boolean) {
    const workTime = await TimeProjectService.getMyWorkTime({
      user_id: this.user_id,
      date_start: moment().format("YYYY-MM-DD")
    });
    const time = workTime.time || "00:00:00";
    if (this.isWeekLimit && !!workTime.current_task) {
      await this.stopTask();
      const workTime = await TimeProjectService.getMyWorkTime({
        user_id: this.user_id,
        date_start: moment().format("YYYY-MM-DD")
      });
      this[TimeTrackerMutationTypes.SET_TODAY_TIME](workTime.time);
    } else {
      this[TimeTrackerMutationTypes.SET_TODAY_TIME](time);
      if (isStartTask) {
        const isActive = !!workTime.current_task;

        if (isActive) {
          const task = {
            name: workTime.current_task.task_name,
            id: workTime.current_task.task_id,
            project_id: workTime.current_task.project_id,
            project_name: workTime.current_task.project_name
          };
          const project = {
            name: workTime.current_task.project_name,
            id: workTime.current_task.project_id
          };
          this.activeTimer({ isActive, task, project });
          this[TimeTrackerMutationTypes.SET_ACTIVE_TASK]({ task, project });
        }
      }
    }
  }

  @Mutation
  [TimeTrackerMutationTypes.SET_STATISTIC](statistic: IStatistic) {
    this.statistic = statistic;
  }

  @MutationAction
  async getMonthStatistics(data) {
    const stat = await WorkCalendarService.getCurrentUserStat(data);
    const statistic = {
      black_sphere_percent: Math.floor(+stat.black_sphere_percent),
      black_sphere_count_marks: stat.black_sphere_count_marks,
      current_normal_time: secToTime(stat.current_standard_time),
      difference_time:
        stat.difference_time < 0
          ? `-${secToTime(Math.abs(stat.difference_time))}`
          : secToTime(Math.abs(stat.difference_time)),
      left_to_work: stat.left_to_work < 0 ? `00:00:00` : secToTime(Math.abs(stat.left_to_work)),
      month_normal_time: secToTime(stat.month_standard_time),
      sick_leaves:
        stat.sick_in_hours > 9 ? `${stat.sick_in_hours}:00:00` : `0${stat.sick_in_hours}:00:00`,
      tracked_time: secToTime(stat.total_tracked_time),
      vacations:
        stat.absence_in_hours > 9
          ? `${stat.absence_in_hours}:00:00`
          : `0${stat.absence_in_hours}:00:00`
    };
    return { statistic };
  }

  @Action
  async getMyWeekWorkTime(payload) {
    let workTime = { time: "00:00:00" };
    if (payload.date_stop) {
      workTime = await TimeProjectService.getMyWorkTime({
        user_id: this.user_id,
        date_start: payload.date_start,
        date_stop: payload.date_stop
      });
    }
    const time = workTime.time;
    if (!payload.is_week) {
      this[TimeTrackerMutationTypes.SET_CURRENT_DAY_TIME](time);
    } else {
      this[TimeTrackerMutationTypes.SET_WEEK_TIME](time);
    }
  }

  @Action
  async getUserWeekWorkTime(payload) {
    const workTime = await TimeProjectService.getMyWorkTime({
      user_id: this.user_id,
      date_start: payload.date_start,
      date_stop: payload.date_stop
    });
    this[TimeTrackerMutationTypes.SET_USER_WORK_TIME](workTime.time);
  }

  @Action
  async getUserLimit(payload: { user_id: number; year: string | number }) {
    const limits = await TimeProjectService.getUserLimit({
      user_id: payload.user_id,
      year: payload.year
    });
    this[TimeTrackerMutationTypes.SET_USER_LIMITS](limits);
  }

  @Action
  toggleShowModal(payload) {
    this[TimeTrackerMutationTypes.TOGGLE_MODAL](payload.isShow);
    this[TimeTrackerMutationTypes.SET_MODAL_DATA](payload.data);
  }

  @Action
  async memberAddToProject(payload: { user_id: Array<number>; project_id: number }) {
    try {
      await TimeProjectService.memberAddToProject(payload);
      this[TimeTrackerMutationTypes.SET_PROJECT_MEMBERS](
        Array.from(new Set([...payload.user_id, ...this.projectMembers]))
      );
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Пользователи успешно добавлены!"
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @Action
  async memberDeleteToProject(payload: { user_id: number; project_id: number }) {
    try {
      await TimeProjectService.memberDeleteToProject(payload);
      this[TimeTrackerMutationTypes.DELETE_PROJECT_MEMBER](payload.user_id);
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Пользователь успешно удален!"
      });
    } catch (e) {
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "error",
        label: "Пользователь уже удален!"
      });
    }
  }

  @Action
  playTask(payload: { task: ITask; project: IProject }) {
    TimeProjectService.startTime(payload.task.id)
      .then(() => {
        // this.setActiveTask(payload);
      })
      .catch(() => {
        this[TimeTrackerMutationTypes.TOGGLE_TIME](false);
        if (this.timerId) workerInterval.clearInterval(this.timerId);
        this[TimeTrackerMutationTypes.SET_TIMER_ID](null);
      });
  }

  @Action
  async stopTask() {
    try {
      await TimeProjectService.stopTime().then(() => {
        this[TimeTrackerMutationTypes.SET_TODAY_TIME](this.workTime);
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async toggleTask(payload: IToggleTaskPayload) {
    if (payload.isActive && payload.task && payload.project) {
      await this.playTask({ task: payload.task, project: payload.project });
    } else {
      await this.stopTask();
    }
  }

  @Action
  async getMyProject() {
    const projectsData = await TimeProjectService.getMyProject({
      page: this.projectPage,
      per_page: 50
    });

    if (projectsData.count) {
      this[TimeTrackerMutationTypes.SET_MY_PROJECT](projectsData.list);
      if (projectsData.count > projectsData.per_page * projectsData.page)
        this[TimeTrackerMutationTypes.SET_MY_PROJECT_PAGE](projectsData.page);
    }
  }

  @Action
  async getAllProject() {
    const projectsData = await TimeProjectService.getAllProject();
    this[TimeTrackerMutationTypes.SET_ALL_PROJECT](projectsData.list);
  }

  @Action
  async needToSync() {
    const list = await TimeProjectService.needToSync();
    this[TimeTrackerMutationTypes.SET_PROJECT_SYNC_LIST](list);
  }

  @Action
  async syncRequest(params: { provider_identity: number }) {
    const url = await TimeProjectService.requestJiraToken(params);
    window.open(url, "_blank");
    const config: IModalConfig = {
      cancelButton: "",
      confirmButton: "Продолжить",
      title: "Запрос на синхронизацию",
      text: "Нажмите продолжить, для завершения синхронизации",
      handleCancel: () => {},
      handleConfirm: () => this.saveAccessJiraToken(params)
    };
    this[TimeTrackerMutationTypes.SET_MODAL_CONFIRM_DATA](config);
  }

  @Action
  async saveAccessJiraToken(params: { provider_identity: number }) {
    try {
      await TimeProjectService.saveJiraToken(params);
      this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      this[TimeTrackerMutationTypes.SET_NEED_SYNC](params.provider_identity);
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Синхронизация успешно завершена"
      });
    } catch (e) {
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "error",
        label: "Не удалось выполнить синхронизацию"
      });
      this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
    }
  }

  @Action
  async editWeekLimit(data) {
    await TimeProjectService.editWeekLimit(data);
    // broadcastCentrifuge(this.usersId, "set-limit", { limit: data.limit });
    EventBus.$emit("showNotification", {
      timeout: 5000,
      type: "success",
      label: "Общий лимит успешно отредактирован!"
    });
    this[TimeTrackerMutationTypes.EDIT_WEEK_LIMIT](data);
  }

  @Action
  async editUserWeekLimit(data: {
    user_id: number;
    year: number | string;
    week: number | string;
    limit: string;
  }) {
    broadcastCentrifuge([data.user_id], "set-limit", { limit: data.limit });
    await TimeProjectService.editUserWeekLimit(data);
    EventBus.$emit("showNotification", {
      timeout: 5000,
      type: "success",
      label: "Лимит сотрудника успешно отредактирован!"
    });
    this[TimeTrackerMutationTypes.EDIT_USER_LIMIT](data);
  }

  get usersId() {
    const users = store.getters["UsersModule/userList"].filedList(["id", "is_active"]);
    if (users.length) {
      return users.filter((item) => item.is_active).map((item) => item.id);
    } else {
      return [];
    }
  }

  @MutationAction
  async getReason() {
    const reasons = await TimeProjectService.getReason();
    return { reasons };
  }

  @Action
  async getTaskByProjectId(payload) {
    const tasksData = await TimeProjectService.getTaskByProjectId({
      page: payload.page,
      per_page: payload.per_page,
      project_id: payload.project_id
    });
    this[TimeTrackerMutationTypes.SET_TASK_BY_PROJECT_ID]({
      data: tasksData,
      project_id: payload.project_id
    });
    const data = this.tasksList[payload.project_id];
    if (tasksData.count > data.list.length) {
      this.getTaskByProjectId({
        page: payload.page + 1,
        per_page: payload.per_page,
        project_id: payload.project_id
      });
    }
  }

  @MutationAction
  async getTimeTable(data) {
    let tableData: ITableData;
    if (data.isAdmin) {
      tableData = await TimeProjectService.getTimeTableCurrentUser(data.params);
      return { tableData };
    }
    tableData = await TimeProjectService.getTimeTable(data.params);
    return { tableData };
  }

  @Action
  async getTimeTableWithPagination(data) {
    let tableData: ITableData;
    if (data.isAdmin) {
      tableData = await TimeProjectService.getTimeTableCurrentUser(data.params);
      this[TimeTrackerMutationTypes.SET_TABLE_DATA](tableData);
      return;
    }
    tableData = await TimeProjectService.getTimeTable(data.params);
    this[TimeTrackerMutationTypes.SET_TABLE_DATA](tableData);
  }

  @Action
  async getWeekListLimit(params) {
    try {
      const weekLimits = await TimeProjectService.getWeekListLimit(params);
      this[TimeTrackerMutationTypes.SET_WEEK_LIST](weekLimits.list);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async createProject(params) {
    const id = await TimeProjectService.createProject(params);
    this[TimeTrackerMutationTypes.ADD_PROJECT]({
      id,
      name: params.name,
      tracker_project_id: null,
      tracker_id: null
    });
    EventBus.$emit("showNotification", {
      timeout: 5000,
      type: "success",
      label: "Проект успешно создан!"
    });
  }

  @Action
  async createTask(params) {
    if (this.tasksProject) {
      const id = await TimeProjectService.createTask({
        ...params,
        project_id: this.tasksProject.id
      });
      this[TimeTrackerMutationTypes.ADD_TASK]({
        id,
        name: params.name,
        project_id: this.tasksProject.id,
        project_name: this.tasksProject.name
      });
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Задача успешно создана!"
      });
    }
  }

  @Action
  async editTask(data: { id: number; name: string; project_id: number }) {
    try {
      await TimeProjectService.editTask({ name: data.name, id: data.id });
      this[TimeTrackerMutationTypes.EDIT_TASK](data);
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Задача успешно отредактирована!"
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async deleteTask(data: { id: number; project_id: number }) {
    try {
      await TimeProjectService.deleteTask(data.id);
      this[TimeTrackerMutationTypes.DELETE_TASK](data);
      this[TimeTrackerMutationTypes.TOGGLE_MODAL_CONFIRM](false);
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "success",
        label: "Задача успешно удалена!"
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async actionProject(payload) {
    if (payload.project.id !== this.editProject?.id) {
      const members = await TimeProjectService.getProjectMember({ project_id: payload.project.id });
      this[TimeTrackerMutationTypes.SET_PROJECT_MEMBERS](members);
    }
    this[TimeTrackerMutationTypes.TOGGLE_ACTION_PROJECT_MODAL](payload);
  }

  @Action
  async exportTask(data: { date_from: string; date_to: string; user_id: number }) {
    const channel = await TimeProjectService.exportTask(data);
    this[TimeTrackerMutationTypes.LOADER_TASK](true);
    const ws = centrifuge.subscribe(channel, (message) => {
      const url = `${domainS3}/${message.data.link}`;
      window.open(url, "_blank");
      ws.unsubscribe();
      this[TimeTrackerMutationTypes.LOADER_TASK](false);
      this[TimeTrackerMutationTypes.TOGGLE_MODAL_PICKER](false);
    });
  }

  get hasActiveTimeTracker() {
    return this.isActive;
  }

  get workTime() {
    return secToTime(this.time);
  }

  get projectTime() {
    return (project_id) => {
      const project = this.projects.find((item) => item.id === project_id);
      if (project && project.time) {
        return secToTime(project.time);
      }
      return null;
    };
  }

  get tasks() {
    return (payload) => {
      const tasks = this.tasksList[`${payload.project_id}`];
      if (tasks && tasks.count) {
        const search = payload.search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        return tasks.list.filter((item) =>
          payload.search.length
            ? new RegExp(`${search}`, "g").test(item.name.toLowerCase()) ||
              new RegExp(`${search}`, "g").test(item.alias ? item.alias.toLowerCase() : "")
            : true
        );
      }
      return [];
    };
  }

  get projectList() {
    return this.projects;
  }

  get projectAllList() {
    return this.projectsAll;
  }

  get isActiveTask() {
    return (task_id) => (this.activeTask ? this.activeTask.id === task_id && this.isActive : false);
  }

  get project() {
    return (project_id) => this.projects.find((item) => item.id === project_id);
  }

  get projectInAll() {
    return (project_id) => this.projectsAll.find((item) => item.id === project_id);
  }

  get data() {
    return this.modalData;
  }

  get weekLimit() {
    return (date_start) => this.weekList.find((item) => item.date_start === date_start);
  }

  get userWeekLimit() {
    return (date_start) => this.userLimits.find((item) => item.date_start === date_start);
  }

  get reason() {
    return (id) => this.reasons.find((item) => item.id === id);
  }

  get totalWeekTime() {
    return secToTime(timeToSec(this.weekTime) + this.time);
  }

  get isLimited() {
    const [hh] = this.totalWeekTime.split(":");
    const [lhh] = this.limit.split(":");
    return +lhh === +hh;
  }

  get isWeekLimit() {
    return timeToSec(`${this.limit}:00`) - timeToSec(this.totalWeekTime) < 0;
  }

  get halfLimit() {
    return timeToSec(`${this.limit}:00`) - timeToSec(this.totalWeekTime) === 1800;
  }
}

export default getModule(TimeTracker);
