<template>
  <ul class="menu-list">
    <li class="menu-list__item" v-for="item in menuList" :key="item.name">
      <MenuItem :item="item" @changeShowAttachModal="$emit('changeShowAttachModal', true)" />
    </li>
  </ul>
</template>

<script>
import MenuItem from "@/modules/Messenger/components/ChatMenu/MenuItem";
export default {
  name: "MenuList",
  mixins: [],
  props: {
    menuList: {
      type: Array,
      default: () => []
    }
  },
  components: { MenuItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.menu-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  &__item {
    cursor: pointer;
    margin-bottom: 24px;
  }
}
</style>
