













import { Component, Prop, Vue } from "vue-property-decorator";
import { timeList } from "@/modules/Calendar/components/CalendarWorkTime";
import CalendarCell from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridWeek/CalendarCell";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";

@Component({
  components: { CalendarCell }
})
export default class CalendarTimeGrid extends Vue {
  @Prop({
    default: 1,
    type: Number
  })
  index!: number;
  @Prop({
    default: () => [],
    type: Array
  })
  timeList!: Array<any>;
  @Prop({
    default: dateFormat(new Date()),
    type: String
  })
  day!: string;
  @Prop({
    default: true,
    type: Boolean
  })
  eventLoader!: boolean;
  @Prop({
    default: "",
    type: String
  })
  scrollTime!: string;
}
