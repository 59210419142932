<template>
  <div class="modal__header">
    <p class="modal__type" :style="{ backgroundColor: background }">{{ checkType(type) }}</p>
    <div class="modal__setting setting">
      <i class="icon-dots-vertical" v-if="isUpdate"></i>
      <ul class="setting__list" v-if="isUpdate">
        <li class="setting__item">
          <button class="setting__button" type="button" @click="updateVacationOrder">
            <i class="icon-edit"></i>
            Редактировать
          </button>
        </li>
        <li class="setting__item">
          <button class="setting__button" type="button" @click="deleteVacation(vacation)">
            <i class="icon-delete"></i>Удалить
          </button>
        </li>
      </ul>
    </div>
    <i class="icon-cross" @click="hideModal"></i>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
export default {
  name: "ModalHeader",
  mixins: [],
  props: {
    type: {
      type: String,
      default() {
        return "Отпуск";
      }
    },
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return true;
      }
    },
    createMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isUser: {
      type: Boolean
    },
    request: {
      type: Boolean
    }
  },
  components: { IcoMoonWrap },
  data() {
    return {
      background: ""
    };
  },
  mounted() {},
  methods: {
    updateVacationOrder() {
      this.$root.$emit("openVacationRequestModal", {
        editMode: true,
        createMode: false,
        vacation: this.vacation
      });
    },
    deleteVacation(vacation) {
      this.$root.$emit("deleteCurrentTransferVacationOrder", vacation);
      this.hideModal();
    },
    hideModal() {
      if (this.request) {
        if (this.vacation.hasOwnProperty("is_checked")) {
          this.$root.$emit("closeRequestConfirmation");
        } else {
          this.$root.$emit("closeMyConfirmations");
        }
      } else {
        this.$root.$emit("closeVacationRequestModal");
      }
    },
    checkType(type) {
      if (this.vacation.hasOwnProperty("is_checked")) {
        switch (this.vacation.order_type) {
          case "AbsenceOrder": {
            this.background = "#a177ff";
            return "Отпуск";
          }
          case "VacationOrder": {
            this.background = "#a177ff";
            return "Отпуск";
          }
          case "TransferVacationOrder": {
            this.background = "#FFB901";
            return "Перенос";
          }
        }
      } else {
        switch (type) {
          case "vacation": {
            this.background = "#a177ff";
            return "Отпуск";
          }
          case "transfer": {
            this.background = "#FFB901";
            return "Перенос";
          }
        }
      }
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isUpdate() {
      return !this.editMode && !this.createMode && this.isUser && !this.request;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.modal {
  &__type {
    padding: 4px 12px;
    height: 24px;
    background: #a177ff;
    border-radius: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-on-interactive;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 48px 30px 42px;
    border-bottom: 1px solid #f2f2f7;
    flex-wrap: wrap;
    @media (max-width: $md) {
      padding: 15px 20px;
    }
  }

  &__back-button {
    display: none;

    @media (max-width: $md) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px 7px 8px;
      background-color: $action-secondary;
      border: none;
      border-radius: 16px;
      font-weight: normal;
      font-size: 21px;
      line-height: 28px;
      color: $text-highlight;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      margin-bottom: 50px;
    }

    .icon-left {
      font-size: 20px;
      color: $icon-subdued;
    }

    &:hover .icon-left {
      color: $icon-hovered;
    }
  }

  &__setting {
    margin-left: auto;
    margin-top: 3px;

    .icon-edit,
    .icon-delete {
      color: $icon-subdued;
      font-size: 20px;
    }
  }

  .icon-cross,
  .icon-dots-vertical {
    font-size: 24px;
    color: $icon-subdued;
    cursor: pointer;
    margin-left: 16px;

    &:hover {
      color: $icon-hovered;
    }
  }

  //.icon-cross {
  //  @media (max-width: $md) {
  //    margin-right: 10px;
  //  }
  //}

  .icon-dots-vertical {
    font-size: 18px;
  }
}
.setting {
  position: relative;

  .icon-dots-vertical:hover + .setting__list {
    display: block;
  }

  &__list {
    display: none;
    position: absolute;
    z-index: 15;
    top: -10px;
    right: 18px;
    padding: 8px 0;
    list-style: none;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;

    &:hover {
      display: block;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;

      i {
        color: $icon-hovered;
      }
    }
  }
}
</style>
