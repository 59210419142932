<template>
  <div class="timeline-vacation__day-list">
    <div
      :class="[
        +day.count_hours === 0 && !header && isShowWeekends ? 'timeline-vacation__day_holiday' : '',
        header ? '' : 'timeline-vacation__day_col'
      ]"
      class="timeline-vacation__day"
      v-for="(day, index) in week"
      :key="index"
      :id="day.date"
      :ref="day.date"
    ></div>
  </div>
</template>

<script>
import moment from "moment";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";
export default {
  name: "TimeLineDay",
  mixins: [vacationMixin],
  props: {
    week: {
      type: Array,
      default: () => {
        return [];
      }
    },
    header: {
      type: Boolean
    },
    item: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isShowWeekends: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      isVacationBarWidths: []
    };
  },
  mounted() {},
  methods: {
    setArrVacationDays(params) {
      let dateArray = [];
      for (const date of this.item) {
        let currentDate = moment(date.plan_date_from);
        const stopDate = moment(date.plan_date_to);
        while (currentDate <= stopDate) {
          dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
          currentDate = moment(currentDate).add(1, "days");
        }
      }
      return dateArray.includes(params);
    },
    setVacationBorderRadius(params, value) {
      let dateStart = [];
      let dateEnd = [];
      let widths = [];
      for (const date of this.item) {
        dateStart.push(moment(date.plan_date_from).format("YYYY-MM-DD"));
        dateEnd.push(moment(date.plan_date_to).format("YYYY-MM-DD"));
        widths.push({
          width: date.count_days * 21,
          count_days: date.count_days
        });
      }
      if (value === "start") {
        this.isVacationBarWidths = widths;
        return dateStart.includes(params);
      }
      if (value === "end") {
        this.isVacationBarWidths = widths;
        return dateEnd.includes(params);
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.timeline-vacation {
  &__day-list {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
  &__day-interval {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $bg-gray-800;
    text-transform: uppercase;
  }

  &__day_start {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    background-color: $action-primary;
    border: 1px solid $action-primary;
    border-radius: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      background-color: $action-primary-hovered;
      border: 1px solid $action-primary-hovered;
    }
  }

  &__day {
    position: relative;
    width: 20px;

    &_col {
      border-right: 1px solid #f2f2f7;
      display: flex;
    }

    &_holiday {
      background-color: $bg-gray-200;
    }

    &_active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 48px;
        z-index: 2;
        background-color: $action-primary;
        border: 1px solid $action-primary;
        cursor: pointer;
      }

      &:hover::before {
        background-color: $action-primary-hovered;
        border: 1px solid $action-primary-hovered;
      }
    }

    &_pending {
      position: relative;
    }

    &_active-first {
      &::before {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    &_active-last {
      &::before {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }

  &__count {
    font-weight: 500;
    font-size: 10px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ebf2fa;
    padding-right: 24px;
  }
}
</style>
