<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: "Loader",
  mixins: [],
  props: [],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.loader {
  transition: all 0.5s ease;
  animation: rotate 1s linear infinite;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 3px solid $icon-highlight;
  border-right: 3px solid transparent;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
