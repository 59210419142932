



























































































































































import { Component, Vue } from "vue-property-decorator";
import MainHeader from "@/modules/TimeTracker/component/Header/MainHeader.vue";
import DateFilter from "@/modules/TimeTracker/component/Header/DateFilter.vue";
import moment from "moment";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter.vue";
import Table from "@/modules/Tables/components/Table/Table";
import WorkTimeCell from "@/modules/TimeTracker/component/WorkTImeCell/WorkTimeCell.vue";
import TimeModal from "@/modules/TimeTracker/component/TimeModal/TimeModal.vue";
import TimeTracker, { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import ConfirmationModal from "@/modules/TimeTracker/component/Modal/ConfirmationModal";
import ChangeMemberModal from "@/modules/TimeTracker/component/ChangeMemberModal/ChangeMemberModal.vue";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import TimeProject from "@/modules/TimeTracker/component/Project/TimeProject.vue";
import AddProject from "@/modules/TimeTracker/component/TimeModal/AddProject.vue";
import { EventBus } from "@/main";
import { weekDay } from "@/modules/TimeTracker/helpers/weekDay";
import MobileModal from "@/modules/TimeTracker/component/TimeModal/MobileModal.vue";
import TimerInputLimit from "@/modules/TimeTracker/component/TimerInputLimit/TimerInputLimit.vue";
import TaskExportModal from "@/modules/TimeTracker/component/Modal/TaskExportModal.vue";

export interface ICell {
  name: string;
  sort: boolean;
  key: string;
}

@Component({
  components: {
    TaskExportModal,
    TimerInputLimit,
    MobileModal,
    AddProject,
    TimeProject,
    ChangeMemberModal,
    ConfirmationModal,
    Button,
    TimeModal,
    Loader,
    WorkTimeCell,
    ChosenFilter,
    Table,
    DateFilter,
    MainHeader
  }
})
export default class WorkTime extends Vue {
  weekNumber: number = moment(new Date()).isoWeek();
  year: number | string = new Date().getFullYear();
  time: number = 40;
  tab: number = 0;
  editMode: boolean = false;
  isShowAddProject: boolean = false;
  exportLoader: boolean = false;
  limitSelect: boolean = false;
  mainLoader: boolean = false;
  loader: boolean = false;
  isShowSelect: boolean = false;
  isShowMobileModal: boolean = false;
  isShowLoader: boolean = false;
  editValue: any = "";
  date = moment().format("YYYY-MM-DD");
  currentResponsible = [
    {
      id: 0,
      full_name: "Все сотрудники",
      avatar: "/public/default/aop_monolit_default_user_avatar.svg"
    }
  ];
  tableHead: Array<ICell> = [
    { name: "Название проекта", sort: false, key: "project" },
    { name: "Длительность", sort: false, key: "duration" },
    { name: "Время", sort: false, key: "time" },
    { name: "Причина", sort: false, key: "reason" },
    { name: "Действия", sort: false, key: "action" }
  ];

  created() {
    TimeTracker.getMyProject();
  }

  mounted() {
    if (this.storeDate) {
      this.date = this.storeDate;
    }
    this.year = new Date(this.date).getFullYear();
    if (!this.isAdmin || this.userId) {
      this.getTableList({ date: this.date, per_page: 15, user_id: this.userId });
    }
    if (!this.isAdmin) {
      TimeTracker.setCurrentUserId(this.currentUserId);
    }
    if (this.userId) {
      this.currentResponsible = this.user;
      this.getUserWeekWorkTime();
      this.currentDayWorkTime({
        date_start: this.date,
        date_stop: moment(this.date).add(1, "days").format("YYYY-MM-DD")
      });
    }

    TimeTracker.getReason();
    if (this.isAdmin) {
      TimeTracker.getAllProject();
      this.getYearLimit();
    }
  }

  get currentUserId() {
    return this.$store.getters.getUserId;
  }

  get member() {
    if (this.currentResponsible.length) {
      return this.currentResponsible[0].id
        ? `Сотрудник: ${this.currentResponsible[0].full_name}`
        : "Все сотрудники";
    } else {
      return "Все сотрудники";
    }
  }

  showExportTask() {
    TimeTracker[TimeTrackerMutationTypes.TOGGLE_MODAL_PICKER](true);
  }

  closeExportModal() {
    TimeTracker[TimeTrackerMutationTypes.TOGGLE_MODAL_PICKER](false);
  }

  async exportTask(date) {
    await TimeTracker.exportTask({
      date_from: date.date_from,
      date_to: date.date_to,
      user_id: this.userId!
    });
  }

  timeNav(tab) {
    this.tab = tab;
  }

  async getYearLimit() {
    this.mainLoader = true;
    await TimeTracker.getWeekListLimit({ year: this.year });
    if (this.userId) {
      await TimeTracker.getUserLimit({
        user_id: this.userId!,
        year: this.year
      });
    }
    this.mainLoader = false;
  }

  setYears(year) {
    if (year !== this.year) {
      if (this.userId) {
        TimeTracker.getUserLimit({
          user_id: this.userId!,
          year
        });
      } else {
        TimeTracker.getWeekListLimit({ year });
      }
    }
    this.year = year;
  }

  changeShowAddProject(isShow) {
    this.isShowAddProject = isShow;
  }

  async addProject(name) {
    await TimeTracker.createProject({ name });
    this.changeShowAddProject(false);
  }

  getTableData() {
    if (this.tableData.page && this.tableData.count) {
      if (this.tableData.count > this.tableData.list.length) {
        const page = this.tableData.page + 1;
        this.loader = true;
        this.getTableListWithPagination({
          per_page: this.tableData.per_page,
          date: this.date,
          user_id: this.currentResponsible[0].id ? this.currentResponsible[0].id : null,
          page
        }).then(() => {
          this.loader = false;
        });
      }
    }
  }

  currentDayWorkTime(payload: { date_start: string; date_stop: string; user_id?: number }) {
    TimeTracker.getMyWeekWorkTime(payload);
  }

  getUserWeekWorkTime() {
    const days = weekDay(this.date, "Воскресенье");
    TimeTracker.getUserWeekWorkTime({
      date_start: days.startWeekDay,
      date_stop: moment(days.lastDayWeek).add(1, "days").format("YYYY-MM-DD"),
      user_id: this.userId
    });
  }

  closeSelect() {
    this.isShowSelect = false;
  }

  openMobileModal() {
    this.isShowMobileModal = true;
  }

  closeMobileModal() {
    this.isShowMobileModal = false;
  }

  setWeekNumber(weekNumber) {
    this.weekNumber = weekNumber;
  }

  handleEdit() {
    this.editMode = true;
    this.$nextTick(() => {
      const input = document.querySelector("#timeLimit");
      if (input) {
        // @ts-ignore
        input.focus();
      }
    });
  }

  timeRangeMask() {
    return ["##:##"];
  }

  handleUser(val) {
    this.currentResponsible = val;
    TimeTracker.setCurrentUserId(val[0].id ? val[0].id : null);
    if (this.userId) {
      this.getTableList({ date: this.date, per_page: 15, user_id: this.userId });
      this.getUserWeekWorkTime();
      TimeTracker.getUserLimit({
        user_id: this.userId!,
        year: this.year
      });
      this.currentDayWorkTime({
        date_start: this.date,
        date_stop: moment(this.date).add(1, "days").format("YYYY-MM-DD")
      });
    }
  }

  async getTableList(params?) {
    this.isShowLoader = true;
    await TimeTracker.getTimeTable({ params, isAdmin: this.isAdmin });
    this.isShowLoader = false;
  }

  async getTableListWithPagination(params?) {
    await TimeTracker.getTimeTableWithPagination({ params, isAdmin: this.isAdmin });
  }

  addTime() {
    TimeTracker.toggleShowModal({ isShow: true, data: null });
  }

  changeShowAddMember() {
    TimeTracker[TimeTrackerMutationTypes.TOGGLE_ACTION_PROJECT_MODAL]({
      isShow: false,
      project: null,
      type: null
    });
  }

  handleCancel() {
    this.editValue = "";
    this.editMode = false;
  }

  handleDone(value) {
    let val = value.target.value;
    const isError = Array.from(val).some((item) => item === "_");
    if (!isError && val.length) {
      const value = `${val}:00`;
      if (this.currentResponsible[0].id === 0) {
        TimeTracker.editWeekLimit({ id: this.weekLimit!.id, limit: value });
      } else {
        TimeTracker[TimeTrackerMutationTypes.EDIT_USER_LIMIT]({
          limit: value,
          week: this.weekLimit!.week
        });
        TimeTracker.editUserWeekLimit({
          user_id: this.currentResponsible[0].id,
          year: this.year,
          week: this.weekLimit!.week,
          limit: value
        });
      }
      this.editValue = "";
      this.editMode = false;
    } else {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Недельный лимит должен быть в формате HH:MM`
      });
    }
  }

  getCalendar(date) {
    this.date = moment(date).format("YYYY-MM-DD");
    TimeTracker[TimeTrackerMutationTypes.SET_TABLE_DATE](this.date);
    if (this.userId) {
      this.getTableList({ date, per_page: 15, user_id: this.userId });
      this.getUserWeekWorkTime();
      this.currentDayWorkTime({
        date_start: this.date,
        date_stop: moment(this.date).add(1, "days").format("YYYY-MM-DD")
      });
    }
  }

  get exportDate() {
    return moment(this.date).format("YYYY-MM");
  }

  get loaderTask() {
    return TimeTracker.loaderTask;
  }

  get isShowExportModal() {
    return TimeTracker.isShowExportModal;
  }

  get modalConfig() {
    return TimeTracker.modalConfirmData;
  }

  get isShowProjectModal() {
    return TimeTracker.isShowProjectModal;
  }

  get projects() {
    return TimeTracker.projectAllList;
  }

  get isAllMember() {
    return this.currentResponsible[0].id === 0 && this.isAdmin;
  }

  get userId() {
    return TimeTracker.user_id;
  }

  get limit() {
    return TimeTracker.limit;
  }

  get reasons() {
    return TimeTracker.reasons;
  }

  get setTitle() {
    return this.limitSelect ? "Лимит" : "Сотрудник";
  }

  get storeDate() {
    return TimeTracker.date;
  }

  get userWeekTime() {
    return this.userId ? TimeTracker.userWeekTime : null;
  }

  get weekDay() {
    return weekDay(this.date, "Пятница");
  }

  get weekLimit() {
    return this.userId
      ? TimeTracker.userWeekLimit(this.weekDay.startWeekDay)
      : TimeTracker.weekLimit(this.weekDay.startWeekDay);
  }

  get userLimit() {
    const weekLimit = this.weekLimit;
    if (weekLimit) {
      const [hh, mm] = weekLimit.limit.split(":");
      return `${hh}:${mm}`;
    } else {
      return "00:00";
    }
  }

  get limitText() {
    return this.userId ? "Лимит: " : "Лимит общий: ";
  }

  get setWeek() {
    return `${moment(this.weekDay.startWeekDay).format("DD MMMM")} - ${moment(
      this.weekDay.lastDayWeek
    ).format("DD MMMM")}`;
  }

  get isShowConfirmationModal() {
    return TimeTracker.isShowConfirmationModal;
  }

  get isEdit() {
    return true;
  }

  get isShowModal() {
    return TimeTracker.isShowModal;
  }

  get tableData() {
    return TimeTracker.tableData;
  }

  get isAdmin() {
    return this.$store.getters.getDataInfoUser.roles.includes("timemanager_admin");
  }

  get user() {
    if (this.userId) {
      return this.responsibleList.filter((item) => item.id === this.userId);
    } else {
      return [
        {
          id: 0,
          full_name: "Все сотрудники",
          avatar: "/public/default/aop_monolit_default_user_avatar.svg"
        }
      ];
    }
  }

  get responsibleList() {
    let users = this.$store.getters["UsersModule/userList"].filedList([
      "name",
      "full_name",
      "is_active",
      "avatar"
    ]);

    users = users.filter((item) => item.is_active);
    users.unshift({
      id: 0,
      full_name: "Все сотрудники",
      avatar: "/public/default/aop_monolit_default_user_avatar.svg"
    });
    return users;
  }

  get dayTime() {
    return TimeTracker.currentDayTime;
  }
}
