<template>
  <div class="send-modal" @click.self.stop="handleCancelButton">
    <Widget :type="'loader-new'" v-if="memberLoader" />
    <div class="send-modal__window" v-else>
      <h3 class="send-modal__title">{{ modalSetting.title }}</h3>
      <TextInput
        :iconClass="'icon-search'"
        :placeholder="'Поиск'"
        :classList="'font13'"
        @changeText="handleSearchInput"
      />
      <ul class="send-modal__list" v-if="modalSetting.isSend">
        <li class="send-modal__item" v-for="item in chatsList" :key="item.channel.id">
          <ChatItem
            :chat="item"
            :showTime="false"
            :showMessage="false"
            :isSendMessage="true"
            :showIcon="false"
          />
        </li>
      </ul>
      <ul class="send-modal__list" v-if="!modalSetting.isSend">
        <li>
          <ul class="send-modal__sub-list" v-if="chats.length">
            <li class="send-modal__item" v-for="item in chats" :key="item.id">
              <ModalItem :user="item.companion" />
            </li>
          </ul>
        </li>
        <li>
          <ul class="send-modal__sub-list" v-if="chatUsers.length">
            <li class="send-modal__item" v-for="item in chatUsers" :key="item.id">
              <ModalItem :user="item" />
            </li>
          </ul>
        </li>
        <li class="send-modal__item_not" v-if="!chatUsers.length && !chats.length">
          Результатов не найдено
        </li>
      </ul>
      <div class="send-modal__footer">
        <ModalButton title="Закрыть" :type="'cancel'" @handleButton="handleCancelButton" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { mapActions, mapGetters, mapState } from "vuex";
import ChatItem from "@/modules/Messenger/components/ChatItem/ChatItem";
import ModalItem from "@/modules/Messenger/components/ChatModal/SendModal/ModalItem";
import TextInput from "@/components/Widgets/Inputs/TextInput";
import Widget from "@/components/Widgets/Widget";
import ClickOutside from "vue-click-outside";

export default {
  name: "SendModal",
  mixins: [],
  props: {},
  components: { Widget, TextInput, ModalButton, ChatItem, ModalItem },
  data() {
    return {
      timerId: null,
      chatsList: []
    };
  },
  mounted() {
    this.chatsList = this.chatList
      .map((item) => item)
      .filter((item) => {
        if (item.channel.type === 2 && item.channel.chat_settings.is_read_only) {
          return item.channel.is_moderator;
        } else {
          return true;
        }
      });
  },
  methods: {
    ...mapActions("MessengerModule", [
      "closeSendModal",
      "createChatUser",
      "updateUsers",
      "searchUsers"
    ]),
    handleCancelButton() {
      this.closeSendModal();
    },
    handleSearchInput(val) {
      const text = val.value.replace("@", "").replace("#", "").toLowerCase();
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        if (this.modalSetting.isSend) {
          this.chatsList = Array.from(
            new Set(
              this.searchChats(text, "chatList", "full_name", "name").concat(
                this.searchChats(text, "chatList", "nickname", "tag")
              )
            )
          );
        } else {
          this.searchUsers({ text });
        }
      }, 300);
    }
  },
  computed: {
    ...mapGetters("MessengerModule", ["searchChats"]),
    ...mapState("MessengerModule", [
      "chatList",
      "modalSetting",
      "chats",
      "chatUsers",
      "currentChat",
      "memberLoader",
      "users",
      "selectUser"
    ]),
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.send-modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &__window {
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    max-width: 428px;
    position: relative;
    @media screen and (max-width: $xxs) {
      max-height: 77vh;
      margin-top: auto;
      border-radius: 4px 4px 0 0;
    }
    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: 77vh;
      max-width: 100%;
      margin-top: auto;
      overflow-y: auto;
      border-radius: 4px 4px 0 0;
    }
  }
  &__list {
    all: unset;
    list-style: none;
    display: flex;
    flex-flow: column;
    height: 380px;
    overflow: auto;
    padding: 0 24px;
    @media screen and (max-width: $xxs) {
      height: 180px;
    }
    @media screen and (max-height: 620px) {
      height: 180px;
    }
    @media screen and (max-height: 420px) {
      height: 80px;
    }
  }
  &__item {
    &_not {
      margin: auto;
      font-size: 16px;
      line-height: 20px;
      color: $text-subdued;
    }
    /deep/ {
      .modal-item {
        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 270px;

          @media screen and (max-width: $xxxl) {
            max-width: 250px;
          }
          @media screen and (max-width: $lg) {
            max-width: 440px;
          }
          @media screen and (max-width: 592px) {
            max-width: 270px;
          }
          @media screen and (max-width: $xs) {
            max-width: 170px;
          }
          @media screen and (max-width: $xxs) {
            max-width: 160px;
          }
        }
        &__text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 270px;

          @media screen and (max-width: $xxxl) {
            max-width: 250px;
          }
          @media screen and (max-width: $lg) {
            max-width: 440px;
          }
          @media screen and (max-width: 592px) {
            max-width: 270px;
          }
          @media screen and (max-width: $xs) {
            max-width: 170px;
          }
          @media screen and (max-width: $xxs) {
            max-width: 160px;
          }
        }
      }
    }
  }

  &__sub-list {
    all: unset;
    list-style: none;
  }
  &__footer {
    margin-top: 24px;
    padding: 24px;
    border-top: 1px solid $border-default;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-height: 420px) {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-top: 5px;
    }
    /deep/ {
      .default-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
        &_cancel {
          color: #343748 !important;
          background: none !important;
        }
      }
    }
  }
  &__title {
    margin: 0;
    font-size: 12px;
    line-height: 28px;
    color: $text-disabled;
    font-weight: 400;
    padding: 24px 24px 0;

    @media screen and (max-height: 420px) {
      padding-top: 12px;
    }
  }
  /deep/ {
    .input-wrap {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      @media screen and (max-height: 420px) {
        padding: 0 24px;
        margin-bottom: 5px;
      }
    }
    .icon-search {
      margin-bottom: 2px;
      font-size: 14px;
      margin-right: 8px;
    }
  }
}
</style>
