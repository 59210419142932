







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ChipsItem extends Vue {
  @Prop({
    default: "",
    type: String
  })
  name!: string;
  @Prop({
    default: "",
    type: String
  })
  icon!: string;
  @Prop({
    default: false,
    type: Boolean
  })
  isActive!: boolean;
  @Prop({
    default: "",
    type: String
  })
  activeItem!: string;
  @Prop({
    default: () => [],
    type: Array
  })
  currentItems!: Array<any>;
  @Prop({
    default: "",
    type: String
  })
  color!: string;

  colorList = {
    0: "primary",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10"
  };

  handleClick() {
    this.$emit("click", this.name);
  }

  get iconClass() {
    return [
      "chips-item__icon",
      `icon-${this.icon}`,
      this.isActive ? "chips-item__icon_active" : ""
    ];
  }

  get isActiveChips() {
    return (
      this.name == this.activeItem ||
      (this.currentItems.length && this.currentItems.find((item) => item.name === this.name))
    );
  }

  get setColor() {
    return this.colorList[this.color];
  }

  get setStyleClasses() {
    return [
      this.isActiveChips ? `chips-item_${this.setColor}` : "",
      { "chips-item_active": this.isActiveChips }
    ];
  }
}
