<template>
  <td class="table__cell">
    <slot :row="row" :cell="cell"></slot>
  </td>
</template>

<script>
export default {
  name: "TableCell",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    },
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.table {
  &__cell {
    display: flex;
    width: 100%;
    padding: 0 8px 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: $text-primary;
  }
}
</style>
