<template>
  <div class="task-history">
    <div class="task-history__header">
      <h3 class="task-history__title">История</h3>
    </div>
    <div class="task-history__loader" v-if="loader">
      <Spinner />
    </div>
    <ul class="task-history__list" v-if="!loader">
      <HistoryTaskItem
        v-for="item in historyList"
        :key="item.id"
        :historyItem="item"
        :listData="historyList"
        :page="page"
        :totalCount="count"
        @paginationEvent="getHistory"
      />
      <li class="task-history__empty" v-if="!historyList.length">Нет истории</li>
    </ul>
  </div>
</template>

<script>
import HistoryTaskItem from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/HistoryTask/HistoryTaskItem";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "HistoryTask",
  mixins: [],
  props: [],
  components: { HistoryTaskItem, Spinner },
  data() {
    return {
      loader: false
    };
  },
  beforeDestroy() {
    this.resetHistory();
  },
  mounted() {
    this.loader = true;
    this.getHistory();
  },
  methods: {
    getHistory() {
      TaskModule.getHistoryTaskAction({
        id: this.currentTask.id,
        page: this.page,
        per_page: 15
      }).then(() => (this.loader = false));
    },
    resetHistory() {
      TaskModule[TaskMutationTypes.RESET_HISTORY_TASK]();
      TaskModule[TaskMutationTypes.SET_HISTORY_COUNT](0);
      TaskModule[TaskMutationTypes.SET_HISTORY_PAGE](1);
    }
  },
  computed: {
    currentTask() {
      return TaskModule.currentTask;
    },
    historyList() {
      return TaskModule.historyList;
    },
    page() {
      return TaskModule.historyPage;
    },
    count() {
      return TaskModule.historyCount;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

::-webkit-scrollbar {
  height: 0;
  width: 6px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}
.task-history {
  height: calc(100% - 1px);
  position: relative;

  &__header {
    padding: 32px 24px;
    position: sticky;
  }

  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0;
  }

  &__title {
    margin: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__list {
    position: relative;
    display: block;
    margin: 0;
    overflow-y: scroll;
    max-height: calc(100% - 100px);

    @media (max-width: $sm) {
      padding: 24px;
    }
  }

  &__empty {
    width: 100%;
    padding: 80px 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
  }
}
</style>
