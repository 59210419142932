import IspReview from "@/modules/Isp/store/getters/IspReview/IspReview";
import IspMy from "@/modules/Isp/store/getters/IspMy/IspMy";
import IspTeams from "@/modules/Isp/store/getters/IspTeams/IspTeams";
import IspProblem from "@/modules/Isp/store/getters/IspProblem/IspProblem";
import Search from "@/modules/Isp/store/getters/Search/Search";
const getters = {
  ...IspReview,
  ...IspMy,
  ...IspTeams,
  ...IspProblem,
  ...Search
};
export default getters;
