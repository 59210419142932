<template>
  <div class="kanban-setting__header">
    <h2 class="kanban-setting__title">Режим настройки Kanban-доски</h2>
    <Button
      :title="'Выйти из режима настройки'"
      :type="'white'"
      :icon="'exit-to-app'"
      @handleButton="goBack"
    />
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "KanbanSettingHeader",
  mixins: [],
  props: {},
  components: { Button },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      if (this.setOldPath.name) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "Project",
          params: {
            id: this.$route.params.id
          }
        });
      }
    }
  },
  computed: {
    setOldPath() {
      return this.$store.state.from;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.kanban-setting {
  &__header {
    padding: 40px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      padding: 16px 8px;
    }

    /deep/ {
      .aop-button {
        @media (max-width: $sm) {
          width: 40px;
          height: 40px;
          padding: 8px;
        }

        i {
          display: none;

          @media (max-width: $sm) {
            display: block;
            font-size: 20px;
            margin: 0;
          }
        }

        span {
          @media (max-width: $sm) {
            display: none;
          }
        }
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: $text-default;

    @media (max-width: $sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
