<template>
  <div class="full-screen" @click.self="closeImage">
    <i class="icon-cross" @click="closeImage"></i>
    <img :src="returnSrcFile(pathImg)" alt="avatar" class="full-screen__image" />
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "FullScreenAvatar",
  mixins: [],
  props: {
    pathImg: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    closeImage() {
      this.$emit("closeImg", false);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $background-modal;
  display: flex;
  align-items: center;
  justify-content: center;
  &__image {
    max-width: 80vw;
    max-height: 80vh;
    border-radius: 8px;
  }
  .icon-cross {
    position: absolute;
    top: 5%;
    right: 5%;
    color: $icon-subdued;
    cursor: pointer;
    font-size: 24px;
    transition: all 0.3s ease;
    border-radius: 4px;
    @media (hover: hover) {
      &:hover {
        background-color: $text-accent;
      }
    }
  }
}
</style>
