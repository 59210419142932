<template>
  <div
    class="chat-item"
    @click="handleOpenChat"
    :class="{ 'chat-item_active': isActive && !isSendMessage }"
    @contextmenu="handleRightClick"
  >
    <div class="chat-item__wrap" v-if="chat.channel.type === 1">
      <div class="chat-item__avatar">
        <img class="chat-item__logo" :src="returnSrcFile(setChannel.avatar)" alt="" />
        <span class="chat-item__status"></span>
      </div>
      <div class="chat-item__content">
        <div class="chat-item__title">
          <div class="chat-item__name-wrap">
            <span class="chat-item__name"> {{ setChannel.full_name }} </span>
            <span v-if="showIcon">
              <i class="icon-pin" v-if="chat.channel.is_pinned_chat"></i>
              <i class="icon-mute" v-if="!chat.channel.notification_enabled"></i>
            </span>
          </div>
          <div class="chat-item__time-wrap" v-if="showTime">
            <i
              class="icon-check-all"
              :class="{ 'icon-check-all_read': isReadMessage }"
              v-if="messageAuthor"
            ></i>
            <span class="chat-item__time">
              {{ setTime }}
            </span>
          </div>
        </div>
        <div class="chat-item__text-wrap" v-if="showMessage">
          <p
            class="chat-item__message"
            v-if="chat.channel.last_message && !chat.channel.is_writing"
          >
            <span class="chat-item__author" v-if="messageAuthor">Вы:</span>
            <span class="chat-item__text">{{ setMessageText }}</span>
          </p>
          <p class="chat-item__message" v-if="chat.channel.is_writing">
            <span class="chat-item__text">Печатает...</span>
          </p>
          <span class="chat-item__message-wrap">
            <span class="chat-item__message-count" v-if="isTagged">
              <span>@</span>
            </span>
            <span class="chat-item__message-count" v-if="chat.channel.count_unread_messages">
              <span> {{ chat.channel.count_unread_messages }} </span>
            </span>
          </span>
        </div>
        <div class="chat-item__text-wrap" v-else>
          <p class="chat-item__message">
            <span class="chat-item__text">@{{ setChannel.nickname }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="chat-item__wrap" v-if="chat.channel.type === 2">
      <div class="chat-item__avatar">
        <img
          class="chat-item__logo"
          :src="returnSrcFileChat(chat.channel.chat_settings.avatar.path)"
          alt="avatar"
          v-if="chat.channel.chat_settings.avatar"
        />
        <span
          class="chat-item__logo"
          v-else
          :style="[
            chat.channel.chat_settings.color
              ? { backgroundColor: chat.channel.chat_settings.color }
              : ''
          ]"
        >
          <span>{{ setChannelName }}</span>
        </span>
      </div>
      <div class="chat-item__content">
        <div class="chat-item__title">
          <span class="chat-item__name"> {{ chat.channel.chat_settings.name }} </span>
          <span v-if="showIcon">
            <span class="chat-item__thread-message">
              <i class="icon-comment-empty"></i>
              <span
                class="chat-item__new-message"
                v-if="chat.channel.count_unread_thread_messages > 0"
              ></span>
            </span>
            <i class="icon-lock-secondary" v-if="!chat.channel.chat_settings.is_open"></i>
            <i class="icon-eye" v-if="chat.channel.chat_settings.is_read_only"></i>
            <i class="icon-pin" v-if="chat.channel.is_pinned_chat"></i>
            <i class="icon-mute" v-if="!chat.channel.notification_enabled"></i>
          </span>
          <div class="chat-item__time-wrap" v-if="showTime">
            <i
              class="icon-check-all"
              :class="{ 'icon-check-all_read': isReadMessage }"
              v-if="messageAuthor"
            ></i>
            <span class="chat-item__time">
              {{ setTime }}
            </span>
          </div>
        </div>
        <div class="chat-item__text-wrap" v-if="showMessage">
          <p
            class="chat-item__message"
            v-if="chat.channel.last_message && !chat.channel.is_writing"
          >
            <span class="chat-item__author" v-if="groupChatMessageAuthor.length">{{
              groupChatMessageAuthor
            }}</span>
            <span class="chat-item__text">{{ setMessageText }}</span>
          </p>
          <p class="chat-item__message" v-if="chat.channel.is_writing">
            <span class="chat-item__text">Печатает...</span>
          </p>
          <span class="chat-item__message-wrap">
            <span class="chat-item__message-count" v-if="isTagged">
              <span>@</span>
            </span>
            <span class="chat-item__message-count" v-if="chat.channel.count_unread_messages">
              <span> {{ chat.channel.count_unread_messages }} </span>
            </span>
          </span>
        </div>
        <div class="chat-item__text-wrap" v-else>
          <p class="chat-item__message">
            <span class="chat-item__text">#{{ chat.channel.chat_settings.tag }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="chat-item__wrap" v-if="chat.channel.type === 3">
      <div class="chat-item__avatar">
        <span class="chat-item__logo" :style="{ backgroundColor: '#504DA8' }">
          <span><i class="icon-favorites"></i></span>
        </span>
        <span class="chat-item__status"></span>
      </div>
      <div class="chat-item__content">
        <div class="chat-item__title">
          <div class="chat-item__name-wrap">
            <span class="chat-item__name"> Избранное </span>
            <span v-if="showIcon">
              <i class="icon-pin" v-if="chat.channel.is_pinned_chat"></i>
              <i class="icon-mute" v-if="!chat.channel.notification_enabled"></i>
            </span>
          </div>
          <div class="chat-item__time-wrap" v-if="showTime">
            <i
              class="icon-check-all"
              :class="{ 'icon-check-all_read': isReadMessage }"
              v-if="messageAuthor"
            ></i>
            <span class="chat-item__time">
              {{ setTime }}
            </span>
          </div>
        </div>
        <div class="chat-item__text-wrap" v-if="showMessage">
          <p
            class="chat-item__message"
            v-if="chat.channel.last_message && !chat.channel.is_writing"
          >
            <span class="chat-item__text">{{ setMessageText }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import { domainStorage } from "@/globalVariables";
import * as types from "@/modules/Messenger/store/mutations/mutationTypes";

export default {
  name: "ChatItem",
  mixins: [],
  props: {
    chat: {
      type: Object,
      default: () => {}
    },
    showTime: {
      type: Boolean,
      default: true
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    isSendMessage: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      messengerStatus: null,
      list: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", [
      "openCurrentChat",
      "sendMessageInChat",
      "switchModal",
      "resetAll"
    ]),
    ...mapMutations("MessengerModule", [types.SHOW_NOTIFICATION]),
    handleOpenChat() {
      if (+this.$route.query.chat_id !== this.chat.channel.id) {
        this.resetAll();
        this[types.SHOW_NOTIFICATION]({ show: false, newMessage: null });
        this.openCurrentChat({ chat: this.chat.channel, isSendMessage: this.isSendMessage });
        this.$router.replace({ query: { chat_id: this.chat.channel.id } });
      } else if (+this.$route.query.chat_id === this.chat.channel.id) {
        this.openCurrentChat({
          chat: this.chat.channel,
          isSendMessage: this.isSendMessage,
          sendInsideChat: true
        });
      } else {
        this.resetAll();
        if (this.currentChat.channel.id !== +this.$route.query.chat_id || this.isSendMessage)
          this.openCurrentChat({ chat: this.chat.channel, isSendMessage: this.isSendMessage });
      }
    },
    handleRightClick(event) {
      event.preventDefault();
      const container = document.querySelector(".messenger-view");
      const scrollContainer = document.querySelector(".chat-list");
      const windowInnerWidth = window.innerWidth;
      //учет бокового меню при определении позиции клика
      const clickPositionX = windowInnerWidth > 768 ? event.x - 99 : event.x;
      this.switchModal({
        show: !this.showModal,
        x: clickPositionX,
        y: event.y,
        container,
        scrollContainer,
        optionList: this.setList,
        isLeft: false,
        channelItem: this.chat
      });
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    returnSrcFileChat(src) {
      return domainStorage + "/chat/files/" + src;
    }
  },
  computed: {
    ...mapState("MessengerModule", ["currentChat", "showModal"]),
    setTime() {
      if (this.chat.channel.last_message) {
        moment.locale("ru");
        if (
          moment(this.chat.channel.last_message.created_at).format("L") === moment().format("L")
        ) {
          return moment(this.chat.channel.last_message.created_at).format("LT");
        } else {
          return moment(this.chat.channel.last_message.created_at).format("L");
        }
      } else {
        return "";
      }
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    isReadMessage() {
      return this.chat.channel.last_message_id <= this.chat.channel.is_read_companion;
    },
    setList() {
      const list = this.list.map((item) => item);
      if (this.chat.channel.is_pinned_chat) {
        list.push({ name: "Открепить", val: "pin-channel", icon: "pin" });
      } else {
        list.push({ name: "Закрепить", val: "pin-channel", icon: "unpin" });
      }
      // if (this.chat.channel.type === 2) {
      //   list.unshift({ name: "Покинуть канал", val: "leave", icon: "entrance" });
      // }
      if (this.chat.channel.type === 2 && (this.chat.channel.is_moderator || this.isAdminChat)) {
        list.unshift({ name: "Добавить участников", val: "add-member", icon: "members-add" });
        list.unshift({ name: "Редактировать  канал", val: "edit-channel", icon: "edit" });
        list.push({ name: "Удалить канал", val: "delete-channel", icon: "delete" });
      }
      if (this.chat.channel.type !== 3) {
        if (this.chat.channel.notification_enabled) {
          list.unshift({ name: "Отключить уведомления", val: "notification", icon: "bell" });
        } else {
          list.unshift({ name: "Включить уведомления", val: "notification", icon: "bell" });
        }
      }
      return list;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setChannel() {
      if (this.chat.channel.type === 1) {
        return this.chat.users.find((item) => {
          if (!item.user_id) {
            return item.id !== this.getUserId;
          } else {
            return item.user_id !== this.getUserId;
          }
        });
      } else {
        return null;
      }
    },
    setChannelName() {
      return this.chat.channel.chat_settings.name[0].toUpperCase();
    },
    isActive() {
      if (this.$route.query.chat_id) {
        return this.chat.channel.id === +this.$route.query.chat_id;
      } else {
        return false;
      }
    },
    messageAuthor() {
      if (this.chat.channel.last_message) {
        return this.chat.channel.last_message.user_id === this.getUserId;
      } else {
        return false;
      }
    },
    setMessageText() {
      return this.chat.channel.last_message.text.length
        ? this.chat.channel.last_message.text
        : "file";
    },
    groupChatMessageAuthor() {
      if (this.chat.channel.last_message.user_id === this.getUserId) {
        return "Вы:";
      } else {
        if (this.chat.channel.last_message.userInfo?.full_name) {
          return `${this.chat.channel.last_message.userInfo.full_name}`;
        } else {
          return "";
        }
      }
    },
    isTagged() {
      return this.chat.channel.last_read_message_id < this.chat.channel.tagged_last_message_id;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.chat-item {
  display: flex;
  padding: 8px;
  cursor: pointer;
  background: #fff;
  transition: background 0.2s ease;
  border-radius: 4px;
  min-height: 58px;
  &__thread-message {
    position: relative;
  }
  &__new-message {
    box-sizing: content-box;
    position: absolute;
    width: 4px;
    height: 4px;
    background: $message-gradient;
    border-radius: 50%;
    right: 2px;
    top: 1px;
  }
  @media (hover: hover) {
    &:hover {
      background: $message;
      .chat-item {
        &__time {
          color: $text-disabled;
        }
        &__title {
          color: $text-default;
        }
        &__author {
          color: $icon-highlight;
        }
        &__text {
          color: $system-grey-seven;
        }
      }
    }
  }
  .icon-favorites {
    color: #fff;
  }

  &_active {
    background: #343748;

    transition: background 0.2s ease;
    @media (hover: hover) {
      &:hover {
        background: #343748;

        .chat-item {
          &__time {
            color: $text-light;
          }
          &__title {
            color: $text-light;
            i {
              color: $text-light;
            }
            .icon-check-all {
              &_read {
                color: $icon-success;
              }
            }
          }
          &__author {
            color: $decorative-surface-two;
          }
          &__text {
            color: $text-light;
          }
        }
      }
    }
    .chat-item {
      &__new-message {
        background: #fff;
      }
      &__time {
        color: $text-light;
      }
      &__title {
        color: $text-light;
        i {
          color: $text-light;
        }
        .icon-check-all {
          &_read {
            color: $icon-success;
          }
        }
      }
      &__time-wrap {
        i {
          font-size: 14px;
          margin: 0 2px;
          color: $text-light;
        }
      }
      &__author {
        color: $decorative-surface-two;
      }
      &__text {
        color: $text-light;
      }
      &__message-count {
        background: $surface-primary-pressed;
      }
    }
  }
  &__time {
    margin-left: auto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__avatar {
    margin-right: 8px;
    height: 40px;
  }
  .icon-comment-empty {
    margin-right: 2px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    background-color: $message;
    object-fit: cover;
  }
  &__content {
    display: flex;
    flex-flow: column;
    width: calc(100% - 50px);
  }
  &__message {
    padding: 0;
    margin: 0 10px 0 0;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    i {
      color: $icon-subdued;
    }
  }
  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
  }

  &__name-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
  }

  &__role {
    color: $text-subdued;
  }
  &__text-wrap {
    display: flex;
    padding: 0;
    margin: 6px 0 0;
  }
  &__time-wrap {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    i {
      font-size: 14px;
      margin: 0 2px;
      color: #a7a9c0;
    }
  }
  &__author {
    font-size: 12px;
    line-height: 16px;
    color: $icon-highlight;
    font-weight: 400;
    margin-right: 4px;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $system-grey-seven;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__message-wrap {
    display: flex;
    margin-left: auto;
  }
  &__message-count {
    height: 16px;
    min-width: 16px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $message-gradient;
    border-radius: 16px;
    &:last-child {
      margin-left: 2px;
    }
  }

  .icon-check-all {
    font-size: 14px;
    color: $icon-subdued;
    margin: 0 2px;
    &_read {
      color: $chat-editor;
    }
  }
}
</style>
