











import { Component, Prop, Vue } from "vue-property-decorator";
import { IHeader, ITableData } from "@/components/MainTable/MainTable.vue";

@Component
export default class MainCell extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  isHeader!: boolean;
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITableData;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: IHeader;
}
