<template>
  <div class="comment-bg" v-click-outside="hideModalEmoji">
    <div class="comment-bg__container">
      <div class="comment-bg__textarea-wrap">
        <div v-if="parent" class="reply">
          <i class="icon-cross" @click="cancelReply"></i>
          {{ declinationName(parent.author.full_name) }}
        </div>
        <textarea
          :id="setId"
          :ref="'textarea'"
          v-show="showTextarea"
          :class="parent ? 'comment-bg__textarea-reply' : 'comment-bg__textarea'"
          maxlength="255"
          type="text"
          v-model.trim="value"
          @input="mixin_autoResize_resize"
          @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
          @keydown.enter.prevent.exact="switchKeyEnter"
          @paste="handlePast"
          @drop="dropPast($event)"
          :placeholder="'Введите текст сообщения'"
        ></textarea>
      </div>
      <div class="comment-bg__icons">
        <file-upload
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          :input-id="`eventFiles${commentableIdData}`"
          post-action="/upload/post"
          :multiple="true"
          :drop="drop"
          :drop-directory="dropDirectory"
          v-model="files"
          @input="uploadFile"
          :ref="`eventFiles${commentableIdData}`"
          :name="`eventFiles${commentableIdData}`"
          class="file-button"
          :maximum="2"
        >
          <i class="icon-paper-clip-secondary"></i>
        </file-upload>
        <i
          class="icon-emoji"
          @click="showModalEmoji = !showModalEmoji"
          :style="showModalEmoji ? 'color: #8D96EC' : 'color: #B8B8CB'"
        ></i>
        <i class="icon-send" @click="createChunksPoolFiles"></i>
      </div>
      <Picker
        v-if="showModalEmoji"
        set="apple"
        @select="addEmoji"
        title="Выберите Эмоджи"
        :i18n="settings"
      />
    </div>
    <div v-if="files.length" class="comment-bg__files">
      <ul class="comment-bg__list">
        <CommentFileItem
          v-for="(file, idx) in documents"
          :key="file.id"
          :idx="idx"
          :file="file"
          :currentId="commentableIdData"
          :fileName="file.name || file.label"
          @filesSplice="deleteFileDoc"
          :showCross="true"
          :isMain="true"
        />
      </ul>
      <ul class="comment-bg__list" v-if="files.length">
        <li
          class="comment-bg__cover files-draggable"
          v-for="(img, index) in cover"
          :key="img.id"
          draggable="true"
        >
          <img class="comment-bg__img" :src="img.src" :alt="img.name" />
          <i class="icon-cross" @click="deleteFileItem(index)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import { domain, domainStorage } from "@/globalVariables";
import { incline } from "lvovich";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import FileUpload from "vue-upload-component";
import CommentFileItem from "@/components/GraphVacation/Comments/CommentFileItem";
import axios from "axios";
import ResizeMixin from "@/helpers/ResizeMixin";
import { mapActions, mapState } from "vuex";

export default {
  name: "CommentComponent",
  mixins: [uploadFilesMixin, ResizeMixin],
  props: {
    typeComment: {
      type: [String, Number],
      default: ""
    },
    commentableId: {
      type: Number,
      default: undefined
    },
    parent: {
      type: Object,
      default: undefined
    },
    multiple: {
      type: [String, Boolean]
    },
    dropDirectory: {
      type: Boolean
    },
    size: {
      type: Number,
      default: 48
    },
    placeholder: {
      type: String,
      default: "Добавить комментарий..."
    },
    drop: {
      type: Boolean
    },
    userInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    news: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { CommentFileItem, Picker, FileUpload },
  data() {
    return {
      files: [],
      value: "",
      valueCopy: "",
      commentId: 0,
      commentableIdData: 0,
      selectedSettingsEditor: "Enter",
      showModalEmoji: false,
      showTextarea: true,
      editMode: false,
      oldEditFiles: [],
      cover: null,
      documents: null,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {
    this.commentableIdData = this.commentableId;
  },
  methods: {
    ...mapActions("NewsModule", [
      "setReplyCommentData",
      "sendCommentAction",
      "updateCommentAction",
      "setEditCommentData"
    ]),
    handlePast(event) {
      const val = event.clipboardData.files[0];
      const file = {
        file: val,
        name: val.name,
        size: val.size,
        type: val.type,
        fileObject: true,
        postAction: "/upload/post"
      };
      if (this.files.length < 2) {
        this.files.push(file);
        this.uploadFile(this.files);
      }
    },
    dropPast(event) {
      event.preventDefault();
      const val = event.dataTransfer.files[0];
      const file = {
        file: val,
        name: val.name,
        size: val.size,
        type: val.type,
        fileObject: true,
        postAction: "/upload/post"
      };
      if (this.files.length < 2) {
        this.files.push(file);
        this.uploadFile(this.files);
      }
    },
    deleteFileItem(val) {
      this.cover.splice(val, 1);
      this.oldEditFiles = [];
      this.files = this.documents.concat(this.cover);
      this.fileUploader = this.files;
      this.uploadFile(this.files);
    },
    deleteFileDoc(val) {
      this.documents.splice(val, 1);
      this.oldEditFiles = [];
      this.files = this.documents.concat(this.cover);
      this.fileUploader = this.files;
      this.uploadFile(this.files);
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    getUrlFile() {
      this.cover = [];
      this.documents = [];
      this.files.forEach((item) => {
        if (item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png") {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = () => {
            this.cover.push({
              src: reader.result,
              name: item.name,
              id: item.id,
              type: item.type,
              file: item.file
            });
          };
        } else {
          this.documents.push(item);
        }
      });
    },
    uploadFile(value) {
      this.files = value;
      this.fileUploader = [];
      this.oldEditFiles = [];
      this.getUrlFile();
      if (this.files.length) {
        this.files.forEach((item) => {
          if (!item.link) {
            this.fileUploader.push(item);
          }
          if (item.link) {
            this.oldEditFiles.push(item);
          }
        });
      }
    },
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      if (!this.fileUploader.length) {
        this.valueCopy = this.value;

        if (this.editMode) {
          await this.updateComment();
        } else {
          await this.sendComment();
        }
        return null;
      }
      this.valueCopy = this.value;
      // this.$root.$emit(`setLoadingInComments_${this.commentableIdData}`);
      this.$emit("set_loading_in_comments");
      this.value = "";
      this.createPull(this.fileUploader);
      this.files = [];
    },
    checkValidationData() {
      if (!this.value && !this.files.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите текст комментария или прикрепите файл!`
        });
        return false;
      }
      if (this.value.length > 255) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Комментарий не может быть больше 255 символов!`
        });
        return false;
      }

      // Проверка общего размера пула файлов
      if (this.fileUploader.length) {
        let sumSize = 0;
        this.fileUploader.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 52428800) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 50мб!`
          });
          return false;
        }
      }

      return true;
    },
    addEmoji(emoji) {
      let cursorPosition = this.$refs.textarea.selectionStart;
      let FirstPart = this.value.substring(0, cursorPosition);
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition + 1, this.value.length);
      this.value = FirstPart + Emoji + SecondPart;
    },
    hideModalEmoji() {
      this.showModalEmoji = false;
    },
    filesUploaded() {
      if (this.editMode) {
        this.updateComment();
      } else {
        this.sendComment();
      }
    },
    async sendComment() {
      this.$emit("set_loading_in_comments");
      const newComment = {
        content: this.valueCopy,
        commentable_type: this.typeComment,
        commentable_id: this.commentableIdData,
        attachment_files: this.fileUploadingId,
        parent_id: this.parent ? this.parent.id : undefined
      };
      this.sendCommentAction(newComment)
        .then(() => {
          this.setReplyCommentData(null);

          this.$emit("updateCommentsList");
          this.$emit("cancelReply");
          this.files = [];
          this.fileUploadingId = [];

          switch (true) {
            case window.innerWidth > 768 && window.innerWidth <= 895:
              this.$refs.textarea.style.paddingLeft = "5px";
              break;
            case window.innerWidth > 480 && window.innerWidth <= 768:
              this.$refs.textarea.style.paddingLeft = "24px";
              break;
            case window.innerWidth <= 480:
              this.$refs.textarea.style.paddingLeft = "5px";
              break;
            default:
              this.$refs.textarea.style.paddingLeft = "24px";
          }
          this.$refs.textarea.style.height = "48px";
        })
        .catch((error) => {
          this.$emit(`stop_loading_in_comments`);
          this.value = this.valueCopy;
          this.errorAlert(error);
        });
      setTimeout(() => {
        this.value = "";
      }, 0);
    },
    updateComment() {
      const editedComment = {
        content: this.valueCopy,
        commentable_type: this.typeComment,
        commentable_id: this.commentableIdData,
        attachment_files: this.fileUploadingId.concat(this.oldEditFiles.map((item) => item.id))
      };
      this.updateCommentAction({ editedComment, commentId: this.commentId })
        .then(() => {
          this.$emit("updateCommentsList");
          this.$emit("cancelReply");
          this.files = [];
          this.fileUploadingId = [];
          this.oldEditFiles = [];
          switch (true) {
            case window.innerWidth > 768 && window.innerWidth <= 895:
              this.$refs.textarea.style.paddingLeft = "5px";
              break;
            case window.innerWidth > 480 && window.innerWidth <= 768:
              this.$refs.textarea.style.paddingLeft = "24px";
              break;
            case window.innerWidth <= 480:
              this.$refs.textarea.style.paddingLeft = "5px";
              break;
            default:
              this.$refs.textarea.style.paddingLeft = "24px";
          }
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Комментарий успешно отредактирован!`
          });
          this.loading = false;
          this.setReplyCommentData(null);
          this.setEditCommentData(null);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },

    cancelReply() {
      this.setReplyCommentData(null);
      this.$emit("cancelReply");
      this.$refs.textarea.style.paddingLeft = "24px";
    },
    declinationName(name) {
      return incline({ first: name.split(" ")[1] }, "dative").first;
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= 6) {
        return text;
      } else return text.substr(0, 6) + "..." + extension[extension.length - 1];
    },
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    },
    switchKeyEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.createChunksPoolFiles();
      } else {
        this.newLine(event);
      }
    },
    switchKeyCtrlEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.newLine(event);
      } else {
        this.createChunksPoolFiles();
      }
    }
  },
  computed: {
    ...mapState("NewsModule", ["currentEditableComment", "currentReplyableComment"]),
    setId() {
      return `textarea${this.news.id}`;
    }
  },
  watch: {
    currentEditableComment(val) {
      if (!val || this.commentableIdData !== val.commentableId) {
        this.value = "";
        this.commentId = 0;
        this.files = [];
        this.editMode = false;
        // this.parent = null;
        this.$refs.textarea.style.height = `${this.size}px`;
      } else {
        if (val.editMode) {
          this.value = val.comment.content;
          this.commentId = val.comment.id;
          this.commentableIdData = val.commentableId;
          this.editMode = val.editMode;
          this.parent = null;
          this.$refs.textarea.style.paddingLeft = "24px";

          this.uploadFile(JSON.parse(JSON.stringify(val.comment.attachment_files)));
          this.$nextTick(() => {
            this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
          });
        } else {
          this.value = "";
          this.commentId = 0;
          this.files = [];
          this.editMode = false;
          this.cancelReply();
          this.$refs.textarea.style.height = `${this.size}px`;
        }
      }
    },
    currentReplyableComment(val) {
      if (val.commentableId === this.commentableIdData) {
        this.setEditCommentData(null);
        this.parent = val.comment;
        this.$nextTick(() => {
          let paddingLeft = document.querySelector(".reply").clientWidth + 30;
          switch (true) {
            case window.innerWidth > 768 && window.innerWidth <= 895:
              paddingLeft = document.querySelector(".reply").clientWidth + 10;
              break;
            case window.innerWidth <= 480:
              paddingLeft = document.querySelector(".reply").clientWidth + 20;
              break;
            default:
              paddingLeft = document.querySelector(".reply").clientWidth + 30;
          }
          this.$refs.textarea.style.paddingLeft = paddingLeft + "px";
        });
      } else {
        switch (true) {
          case window.innerWidth > 768 && window.innerWidth <= 895:
            this.$refs.textarea.style.paddingLeft = "5px";
            break;
          case window.innerWidth > 480 && window.innerWidth <= 768:
            this.$refs.textarea.style.paddingLeft = "24px";
            break;
          case window.innerWidth <= 480:
            this.$refs.textarea.style.paddingLeft = "5px";
            break;
          default:
            this.$refs.textarea.style.paddingLeft = "24px";
        }
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.comment-bg {
  position: relative;
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  @media screen and (max-width: $xs) {
    margin-top: 10px;
  }
  &__icons {
    display: flex;
    align-items: center;
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .user-avatar {
    margin-right: 16px;
    width: 60px;
    height: 60px;
    @media screen and (max-width: 580px) {
      width: 34px;
      height: 34px;
    }
    @media screen and (max-width: $xs) {
      display: none;
    }
    img {
      width: 60px;
      height: 60px;
      @media screen and (max-width: 580px) {
        width: 34px;
        height: 34px;
      }
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .file-button {
    position: absolute;
    right: 53px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    @media (max-width: 895px) {
      right: 27px;
    }
    @media (max-width: $md) {
      font-size: 16px;
      width: 16px;
      height: 16px;
      right: 53px;
    }
    @media (max-width: $xs) {
      right: 27px;
    }
    /deep/ {
      label {
        cursor: pointer;
        margin-bottom: 0;
        width: 16px;
        height: 16px;
        @media screen and (max-width: 580px) {
          font-size: 10px;
          width: 12px;
          height: 12px;
        }
      }
    }

    .icon-paper-clip-secondary {
      cursor: pointer;
      z-index: 10;
      font-size: 16px;
      color: $icon-subdued;
      @media screen and (max-width: 580px) {
        font-size: 10px;
      }
    }
  }
  .icon-emoji,
  .icon-send {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 16px;
    color: $icon-subdued;

    @media (hover: hover) {
      &:hover {
        color: $icon-hovered;
      }
    }
  }

  .icon-emoji {
    right: 80px;
    @media (max-width: 895px) {
      right: 48px;
    }
    @media (max-width: $md) {
      font-size: 16px;
      width: 16px;
      height: 16px;
      right: 80px;
    }
    @media (max-width: $xs) {
      right: 48px;
    }
  }

  .icon-send {
    right: 24px;
    @media (max-width: 895px) {
      right: 5px;
    }
    @media (max-width: $md) {
      font-size: 16px;
      width: 16px;
      height: 16px;
      right: 24px;
    }
    @media (max-width: $xs) {
      right: 5px;
    }
  }

  &__textarea {
    width: 100%;
    height: 48px;
    padding: 12px 106px 12px 24px;
    border: 1px solid #fff;
    mix-blend-mode: normal;
    border-radius: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    outline: none;
    resize: none;
    transition: 0.3s border ease;
    &:focus {
      border: 1px solid $link-default;
    }
    @media (max-width: 895px) {
      padding-right: 70px;
      padding-left: 5px;
      font-size: 13px;
    }
    @media (max-width: $md) {
      padding-left: 24px;
      padding-right: 100px;
      font-size: 14px;
    }
    @media (max-width: $xs) {
      padding-right: 70px;
      padding-left: 5px;
      font-size: 14px;
    }
    @media (max-width: 362px) {
      padding-right: 70px;
      font-size: 13px;
    }
  }
  &__textarea-reply {
    width: 100%;
    height: 48px;
    padding: 12px 106px 12px 100px;
    border: 1px solid #fff;
    mix-blend-mode: normal;
    border-radius: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    outline: none;
    resize: none;
    transition: 0.3s border ease;
    &:focus {
      border: 1px solid $link-default;
    }
    @media (max-width: $xl) {
      font-size: 13px;
    }
    @media (max-width: 895px) {
      padding-right: 70px;
      font-size: 10px;
      line-height: 10px;
    }
    @media (max-width: $md) {
      line-height: 20px;
      font-size: 14px;
      padding-right: 105px;
    }
    @media screen and (max-width: 580px) {
      line-height: 10px;
      font-size: 10px;
    }
    @media (max-width: $xs) {
      padding-right: 70px;
    }
  }
  &__textarea-wrap {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__list {
    display: flex;
    flex-flow: row;
    margin: 8px 0 0;
    padding: 0;
    list-style: none;
  }

  &__cover {
    display: flex;
    width: 48%;
    max-height: 150px;
    overflow: hidden;
    align-items: center;

    i {
      align-self: flex-start;
    }
  }

  &__img {
    display: block;
    width: 90%;
    height: max-content;
  }

  .reply {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 50%;
    transform: translateY(40%);
    padding: 2px 10px;
    background-color: $text-disabled;
    border-radius: 24px;
    left: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #fafafe;
    @media screen and (max-width: 895px) {
      left: 3px;
      font-size: 10px;
      line-height: 16px;
    }
    @media screen and (max-width: 580px) {
      left: 16px;
      font-size: 10px;
      line-height: 16px;
    }
    .icon-cross {
      cursor: pointer;
      font-size: 15px;
      @media screen and (max-width: 580px) {
        font-size: 10px;
      }
    }
  }
  /deep/ {
    .emoji-mart {
      position: absolute;
      right: 0;
      bottom: 50%;
      z-index: 10;
      width: 338px !important;

      @media (max-width: $sm) {
        width: 250px !important;
      }
      @media (max-width: $xs) {
        width: 220px !important;
      }

      .emoji-mart-preview {
        display: none !important;
      }

      .emoji-mart-title-label {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
