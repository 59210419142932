export default {
  methods: {
    dragElement(el, id = "#draggable-area") {
      let header = document.querySelector(id);
      header.style.cursor = "grabbing";
      // header.style.backgroundColor = "#F4F4FF";
      header.style.height = "32px";
      // header.style.borderRadius = "10px 10px 0 0";
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0,
        tempTop = 0,
        tempLeft = 0;
      header.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        el.style.top = el.offsetTop - pos2 + "px";
        tempTop = window.innerHeight;
        el.style.left = el.offsetLeft - pos1 + "px";
        tempLeft = window.innerWidth;
        if (parseInt(el.style.top, 10) + el.offsetHeight > tempTop) {
          el.style.top = tempTop - el.offsetHeight + "px";
        }
        if (parseInt(el.style.top, 10) <= 0) {
          el.style.top = 0 + "px";
        }
        if (parseInt(el.style.left, 10) + el.offsetWidth > tempLeft) {
          el.style.left = tempLeft - el.offsetWidth + "px";
        }
        if (parseInt(el.style.left, 10) <= 0) {
          el.style.left = 0 + "px";
        }
      }

      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }
};
