import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import TaskManagerService from "@/modules/TaskManager/store/TaskManagerService";
import TaskBlackSphereModule, {
  TaskBlackSphereMutationTypes
} from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";

const name = "TaskManagerModuleV2";

interface CurtainParams {
  currentCreationTypeId?: number;
  currentProjectForCreation: object;
  currentStageForCreation?: object;
  currentTaskForCreation?: object;
}

export interface IModalConfig {
  confirmButton: string;
  cancelButton: string;
  title: string;
  text: string;
  handleConfirm: () => void;
  handleCancel: () => void;
}

interface IModalClass extends IModalConfig {
  cancel: () => void;
  confirm: () => void;
}
type searchType = {
  search_by?: string;
  query: string;
  page?: number | string;
};
type searchResultsType = {
  count: number;
  list: Array<object>;
  per_page: number;
  page: number | string;
};

type foundProjectType = {
  alias: string;
  id: number;
  name: string;
};
type foundTaskType = {
  id: number;
  task_id: number;
  name: string;
  alias: string;
};
type activeFoundItemType = {
  type: string;
  id: number | string;
};
class ModalConfig implements IModalClass {
  confirmButton: string;
  cancelButton: string;
  title: string;
  text: string;
  handleConfirm: () => void;
  handleCancel: () => void;

  constructor(
    confirmButton: string,
    cancelButton: string,
    title: string,
    text: string,
    handleConfirm: () => void,
    handleCancel: () => void
  ) {
    this.confirmButton = confirmButton;
    this.cancelButton = cancelButton;
    this.title = title;
    this.text = text;
    this.handleConfirm = handleConfirm;
    this.handleCancel = handleCancel;
  }

  cancel() {
    this.handleCancel();
  }

  confirm() {
    this.handleConfirm();
  }
}

export enum TaskManagerModuleV2MutationTypes {
  TOGGLE_SHOW_CURTAIN_CONSTRUCTOR = "TOGGLE_SHOW_CURTAIN_CONSTRUCTOR",
  TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK = "TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK",
  TOGGLE_SHOW_FULL_SCREEN_IMAGE = "TOGGLE_SHOW_FULL_SCREEN_IMAGE",
  TOGGLE_SHOW_SIDEBAR = "TOGGLE_SHOW_SIDEBAR",
  TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL = "TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL",
  SET_CURRENT_CREATION_TYPE_ID = "SET_CURRENT_CREATION_TYPE_ID",
  SET_CURRENT_PROJECT_FOR_CREATION = "SET_CURRENT_PROJECT_FOR_CREATION",
  SET_CURRENT_STAGE_FOR_CREATION = "SET_CURRENT_STAGE_FOR_CREATION",
  SET_CURRENT_TASK_FOR_CREATION = "SET_CURRENT_TASK_FOR_CREATION",
  SET_CONFIRM_CONFIG = "SET_CONFIRM_CONFIG",
  TOGGLE_CONFIRMATION_MODAL = "TOGGLE_CONFIRMATION_MODAL",
  SET_SEARCH_VALUE = "SET_SEARCH_VALUE",
  SET_PROJECT_SEARCH_RESULTS = "SET_PROJECT_SEARCH_RESULTS",
  SET_TASK_SEARCH_RESULTS = "SET_TASK_SEARCH_RESULTS",
  TOGGLE_SEARCH_LOADER = "TOGGLE_SEARCH_LOADER",
  SET_FOUND_PROJECT_LIST = "SET_FOUND_PROJECT_LIST",
  SET_FOUND_TASK_LIST = "SET_FOUND_TASK_LIST",
  SET_ACTIVE_FOUND_ITEM = "SET_ACTIVE_FOUND_ITEM"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class TaskManagerModuleV2 extends VuexModule {
  showCurtainConstructor: boolean = false;
  showCurtainShowAndEditTask: boolean = false;
  showBlackSphereModal: boolean = false;
  showFullScreenImage: boolean = false;
  showSidebar: boolean = true;
  showConfirmationModal: boolean = false;
  currentCreationTypeId: object | null = null;
  currentProjectForCreation: object | null = null;
  currentStageForCreation: object | null = null;
  currentTaskForCreation: object | null = null;
  searchValue: string = "";
  projectSearchResult: searchResultsType | null = null;
  taskSearchResult: searchResultsType | null = null;
  foundProjectList: Array<foundProjectType> = [];
  foundTaskList: Array<foundTaskType> = [];
  searchLoader: boolean = false;
  activeFoundItem: activeFoundItemType | null = null;
  modalConfig: IModalClass = new ModalConfig(
    "Удалить",
    "Отменить",
    "Удалить этап?",
    "Восстановить его будет невозможно. Все задачи этапа будут удалены. Продолжить?",
    () => {
      console.log("confirm");
    },
    () => {
      console.log("cancel");
    }
  );

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_CONSTRUCTOR](payload) {
    this.showCurtainConstructor = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](payload) {
    this.showBlackSphereModal = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK](payload) {
    this.showCurtainShowAndEditTask = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_FULL_SCREEN_IMAGE](payload) {
    this.showFullScreenImage = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_CONFIRM_CONFIG](payload: IModalConfig) {
    this.showConfirmationModal = true;
    this.modalConfig = new ModalConfig(
      payload.confirmButton,
      payload.cancelButton,
      payload.title,
      payload.text,
      payload.handleConfirm,
      payload.handleCancel
    );
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_CONFIRMATION_MODAL](payload: boolean) {
    this.showConfirmationModal = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_SIDEBAR](payload) {
    this.showSidebar = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_CURRENT_CREATION_TYPE_ID](payload) {
    this.currentCreationTypeId = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_CURRENT_PROJECT_FOR_CREATION](payload) {
    this.currentProjectForCreation = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_CURRENT_STAGE_FOR_CREATION](payload) {
    this.currentStageForCreation = payload;
  }

  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_CURRENT_TASK_FOR_CREATION](payload) {
    this.currentTaskForCreation = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_SEARCH_VALUE](payload) {
    this.searchValue = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_PROJECT_SEARCH_RESULTS](payload) {
    this.projectSearchResult = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_TASK_SEARCH_RESULTS](payload) {
    this.taskSearchResult = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.TOGGLE_SEARCH_LOADER](payload) {
    this.searchLoader = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_FOUND_PROJECT_LIST](payload) {
    this.foundProjectList = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_FOUND_TASK_LIST](payload) {
    this.foundTaskList = payload;
  }
  @Mutation
  [TaskManagerModuleV2MutationTypes.SET_ACTIVE_FOUND_ITEM](payload: activeFoundItemType) {
    this.activeFoundItem = payload;
  }
  @Action
  openCurtainConstructor(params: CurtainParams) {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_CONSTRUCTOR](true);
    this[TaskManagerModuleV2MutationTypes.SET_CURRENT_CREATION_TYPE_ID](
      params?.currentCreationTypeId !== undefined ? params.currentCreationTypeId : null
    );
    this[TaskManagerModuleV2MutationTypes.SET_CURRENT_PROJECT_FOR_CREATION](
      params?.currentProjectForCreation ? params.currentProjectForCreation : null
    );
    this[TaskManagerModuleV2MutationTypes.SET_CURRENT_STAGE_FOR_CREATION](
      params?.currentStageForCreation ? params.currentStageForCreation : null
    );
    this[TaskManagerModuleV2MutationTypes.SET_CURRENT_TASK_FOR_CREATION](
      params?.currentTaskForCreation ? params.currentTaskForCreation : null
    );
  }

  @Action
  openCurtainShowAndEditTask() {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK](true);
  }

  @Action
  toggleConfirmModal(isShow) {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_CONFIRMATION_MODAL](isShow);
  }

  @Action
  closeCurtainConstructor() {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_CONSTRUCTOR](false);
  }

  @Action
  openFullScreenImage() {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_FULL_SCREEN_IMAGE](true);
  }

  @Action
  closeFullScreenImage() {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_FULL_SCREEN_IMAGE](false);
  }

  @Action
  closeCurtainShowAndEditTask() {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK](false);
    TaskModule[TaskMutationTypes.GET_CURRENT_TASK_FILES]("reset");
    TaskBlackSphereModule[TaskBlackSphereMutationTypes.SET_BLACK_SPHERE]([]);
    TaskModule[TaskMutationTypes.SET_CURRENT_TASK](null);
  }
  @Action
  async searchAction(searchParams: searchType) {
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SEARCH_LOADER](true);
    const searchResults = await TaskManagerService.search(searchParams);
    this[TaskManagerModuleV2MutationTypes.SET_SEARCH_VALUE](searchParams.query);
    this[TaskManagerModuleV2MutationTypes.SET_PROJECT_SEARCH_RESULTS](searchResults.projects);
    this[TaskManagerModuleV2MutationTypes.SET_TASK_SEARCH_RESULTS](searchResults.tasks);
    this[TaskManagerModuleV2MutationTypes.SET_FOUND_PROJECT_LIST](searchResults.projects.list);
    this[TaskManagerModuleV2MutationTypes.SET_FOUND_TASK_LIST](searchResults.tasks.list);
    this[TaskManagerModuleV2MutationTypes.TOGGLE_SEARCH_LOADER](false);
  }
  @Action
  async getMoreEntitiesInSearch(searchParams: searchType) {
    const searchResults = await TaskManagerService.search(searchParams);
    if (searchParams.search_by === "projects") {
      this[TaskManagerModuleV2MutationTypes.SET_PROJECT_SEARCH_RESULTS](searchResults);
      this[TaskManagerModuleV2MutationTypes.SET_FOUND_PROJECT_LIST]([
        ...this.foundProjectList,
        ...searchResults.list
      ]);
    }
    if (searchParams.search_by === "tasks") {
      this[TaskManagerModuleV2MutationTypes.SET_TASK_SEARCH_RESULTS](searchResults);
      this[TaskManagerModuleV2MutationTypes.SET_FOUND_TASK_LIST]([
        ...this.foundTaskList,
        ...searchResults.list
      ]);
    }
  }
}

export default getModule(TaskManagerModuleV2);
