<template>
  <li
    :class="[
      'check-list__item',
      { 'check-list__item_complete': complete },
      { 'check-list__item_hover': !isEdit }
    ]"
  >
    <div class="check-list__wrap" v-if="!isEdit" @dblclick="toggleEdit(true)">
      <CheckboxISP :value="complete" :isEdit="true" @input="toggleComplete" v-if="!isEdit" />
      <p class="check-list__text">{{ item.text }}</p>
      <button
        class="check-list__button"
        v-if="!complete && isEditRoles"
        type="button"
        aria-label="Удалить пункт из чек листа"
        @click="deleteItem"
      >
        <i class="icon-delete"></i>
      </button>
    </div>
    <CheckListForm
      v-if="isEdit"
      :value="text"
      :showAccept="false"
      :id="item.id"
      @handleCancel="toggleEdit(false)"
      @handleAccept="handleAccept"
    />
  </li>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import ClickOutside from "vue-click-outside";
import CheckListForm from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckListForm";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "CheckListItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => null
    },
    showAccept: {
      type: Boolean,
      default: true
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    isEditRoles: {
      type: Boolean,
      default: false
    }
  },
  components: { CheckListForm, CheckboxISP },
  data() {
    return {
      text: "",
      complete: false,
      isEdit: false
    };
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.text = this.item && this.item.text;
      this.complete = this.item && this.item.is_completed;
      this.isEdit = this.isEditMode;
    },
    toggleEdit(val) {
      if (!this.complete && this.isEditRoles) {
        this.isEdit = val;
      }
    },
    toggleComplete(val) {
      if (this.isEditRoles) {
        this.complete = val;
        TaskModule.updateTaskCheckListItemAction({
          id: this.item.id,
          is_completed: val
        });
      }
    },
    handleAccept(val) {
      if (!this.checkListValidation(val)) return false;
      TaskModule.updateTaskCheckListItemAction({
        id: this.item.id,
        text: val
      });
      this.text = val;
      this.isEdit = false;
    },
    deleteItem() {
      TaskModule.deleteTaskCheckListItemAction(this.item.id);
    },
    checkListValidation(val) {
      if (!val.trim().length) {
        NotificationObserver.notification({
          type: "error",
          message: "Пункт не может быть пустым!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.check-list {
  &__item {
    display: flex;
    padding: 8px 4px;
    margin-bottom: 16px;
    border-radius: 2px;

    &:hover .check-list__button {
      display: flex;
    }

    &_hover:hover {
      background: #fafafe;
    }

    &_complete .check-list__text {
      text-decoration: line-through;
    }
  }

  &__wrap {
    display: flex;
    width: 100%;
  }

  &__text {
    width: 90%;
    margin-left: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #343748;
    word-break: break-word;
  }

  &__button {
    display: none;
    padding: 0;
    margin-left: auto;
    background: transparent;
    border: none;
    outline: none;

    i {
      font-size: 20px;
      line-height: 20px;
      color: $icon-subdued;
    }

    &:hover i {
      color: $icon-hovered;
    }

    &:active i {
      color: $icon-pressed;
    }
  }

  &__form {
    width: 100%;
  }
}
</style>
