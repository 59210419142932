<template>
  <div class="modal__info">
    <div class="modal__wrapper">
      <p class="modal__subtitle">Количество дней</p>
      <p class="modal__days">
        {{ getCountDaysVacation }}
        {{ setLabelCountDays(getCountDaysVacation, "день", "дня", "дней") }}
      </p>
    </div>
    <div class="modal__wrapper" v-click-outside="hideConformationUser">
      <p class="modal__subtitle">Подтверждающее лицо(а)</p>
      <ul class="modal__user-list">
        <draggable
          @change="changeDragUserItem"
          :list="confirmationsPerson"
          class="modal__user-list_draggable"
          draggable=".user"
          ghost-class="modal__user_ghost"
          :disabled="!createMode && !editMode"
        >
          <!--          TODO: вынести в отдельный компонент-->
          <li
            class="modal__user user user_draggable"
            v-for="(item, index) in confirmationsPerson"
            :data-index="index"
            :data-userid="item.user_id"
            :key="index"
          >
            <img
              class="user__avatar"
              :src="returnSrcFile(item.user.avatar)"
              alt="Avatar"
              width="32"
              height="32"
            />
            <IcoMoonWrap
              v-if="item.is_checked"
              :name="'calendar-status-yes'"
              :font-size="14"
              :pathCount="2"
            />
            <p class="user__name">
              {{ item.user.full_name }}
              <span v-if="createMode || editMode" @click="deleteUser(item.user.id)">Удалить</span>
            </p>
          </li>
        </draggable>

        <li
          class="modal__user modal__user_add"
          @click="isConfirmationsPersonModal = true"
          v-if="createMode || editMode"
        >
          <i class="icon-plus"></i>
        </li>
      </ul>
      <ConfirmationsUsersModal
        v-if="isConfirmationsPersonModal"
        :keyValue="'Confirmation_User'"
        :maxCount="3"
        :buttonsPanel="false"
        :editConfirmationsPerson="confirmationsPerson"
        @hideModal="isConfirmationsPersonModal = false"
        :title="'Добавить подтверждающие лица'"
      />
    </div>
    <div class="modal__wrapper">
      <p class="modal__subtitle">Статус</p>
      <p class="modal__approved approved" :style="{ color: color, backgroundColor: bgcolor }">
        <i class="icon-check-mark" :style="{ color: color, backgroundColor: bgcolor }"></i>
        {{ setStatusVacation(vacation.status) }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ConfirmationsUsersModal from "@/components/Widgets/ConfirmationsUsersModal/ConfirmationsUsersModal";
import ClickOutside from "vue-click-outside";
import draggable from "vuedraggable";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";
import { domainStorage } from "@/globalVariables";
import IcoMoonWrap from "../../../assets/aop-icons/IcoMoonWrap";

export default {
  name: "ModalInfo",
  mixins: [vacationMixin],
  props: {
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return true;
      }
    },
    createMode: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: { IcoMoonWrap, draggable, ConfirmationsUsersModal },
  data() {
    return {
      isConfirmationsPersonModal: false,
      confirmationsPerson: this.setConfirmationPerson(),
      dataConfirmUser: [],
      dateStartVacation: this.vacation.date_to,
      dateEndVacation: this.vacation.date_from,
      holidays: [
        "YYYY-01-01",
        "YYYY-01-02",
        "YYYY-01-03",
        "YYYY-01-04",
        "YYYY-01-05",
        "YYYY-01-06",
        "YYYY-01-07",
        "YYYY-01-08",
        "YYYY-02-23",
        "YYYY-03-08",
        "YYYY-05-01",
        "YYYY-05-09",
        "YYYY-06-12",
        "YYYY-11-04"
      ],
      year: moment(new Date()).year().toString()
    };
  },
  mounted() {
    this.$root.$on("changeSelectValue:Confirmation_User", (val) => {
      this.confirmationsPerson = val.map((item, index) => {
        return {
          user: item.user ? item.user : item,
          user_id: item.user ? item.user.id : item.id,
          index: index + 1,
          is_checked: item.is_checked ? item.is_checked : false
        };
      });
      this.dataConfirmUser = this.confirmationsPerson.map((item, index) => {
        return {
          user_id: item.user ? item.user.id : item.id,
          index: index + 1
        };
      });
      this.$root.$emit("setDataModal", { dataConfirmUser: this.dataConfirmUser });
    });
    this.$root.$on("date:change_modal-request-vacation-start-data", (val) => {
      this.dateStartVacation = val;
    });
    this.$root.$on("date:change_modal-request-vacation-end-data", (val) => {
      this.dateEndVacation = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("changeSelectValue:Confirmation_User");
    this.$root.$off("date:change_modal-request-vacation-start-data");
    this.$root.$off("date:change_modal-request-vacation-end-data");
  },
  methods: {
    hideConformationUser() {
      this.isConfirmationsPersonModal = false;
    },
    setHoliday(year) {
      return this.holidays.map((item) => {
        const res = item.split("YYYY");
        return year + res[1];
      });
    },
    //Отслеживание изменений index у юзеров при drag and drop
    changeDragUserItem() {
      //Поиск всех элементов с селектором .user_draggable
      const nodeList = document.querySelectorAll(".user_draggable");
      //Преобразование NodeList в array
      this.dataConfirmUser = Array.prototype.slice.call(nodeList).map((item) => {
        //формирование нового массива
        return {
          user_id: +item.dataset.userid,
          index: +item.dataset.index + 1
        };
      });
      this.$root.$emit("setDataModal", { dataConfirmUser: this.dataConfirmUser });
    },
    // TODO: Описать функцию

    deleteUser(id) {
      const index = this.confirmationsPerson.findIndex((item) => item.user_id === id);
      this.confirmationsPerson.splice(index, 1);
      this.dataConfirmUser = this.confirmationsPerson.map((item, index) => {
        return { user_id: item.user ? item.user.id : item.id, index: index + 1 };
      });
      this.$root.$emit("setDataModal", { dataConfirmUser: this.dataConfirmUser });
    },
    setConfirmationPerson() {
      if (this.vacation.confirmations) {
        return this.vacation.confirmations
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((item) => ({
            ...item,
            index: item.index
          }));
      } else {
        return this.confirmationsPerson;
      }
    },
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    getCountDaysVacation() {
      let date = 1;
      if (this.vacation.count_days) {
        date = this.vacation.count_days;
      } else if (this.vacation.vacationOrder) {
        date = this.vacation.vacationOrder.count_days;
      }
      if (moment(this.dateEndVacation) > moment(this.dateStartVacation)) {
        date = moment(this.dateEndVacation).diff(moment(this.dateStartVacation), "days") + 1;
      }
      const holidays = Array.from(
        new Set([
          ...this.setHoliday(moment(this.dateStartVacation).format("YYYY")),
          ...this.setHoliday(moment(this.dateEndVacation).format("YYYY"))
        ])
      );
      holidays.forEach((item) => {
        if (moment(item).isBetween(this.dateStartVacation, this.dateEndVacation, "days", "[]")) {
          date = date - 1;
        }
      });
      return date;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.modal {
  &__days {
    width: max-content;
    padding: 4px 12px;
    height: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: #f1effa;
    border-radius: 12px;

    i {
      margin-right: 8px;
      color: $text-subdued;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;

    @media (max-width: $md) {
      flex-direction: column;
    }

    /deep/ {
      .confirmation-user {
        width: 300px;
        @media (max-height: $md) {
          top: unset;
          bottom: 35px;
        }

        &__list {
          max-height: 145px;
          @media (max-width: $md) {
            max-height: calc(100vh - 200px);
          }
        }
      }
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__user-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    &_draggable {
      display: flex;
      flex-shrink: 0;
    }
  }

  &__user {
    position: relative;
    cursor: pointer;

    .icon-calendar-status-yes {
      position: absolute;
      display: block;
      z-index: 2;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
    }

    &_ghost {
      width: 32px;
      height: 32px;
    }

    &_add {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $background-active;
      border: 1px dashed $bg-gray-400;
      border-radius: 50%;
      z-index: 5;
      cursor: pointer;

      &:hover {
        background: $background-hovered;
      }

      .icon-plus {
        color: $icon-subdued;
      }
    }
  }

  &__wrapper {
    color: #a7a9c0;
    position: relative;
    @media (max-width: $md) {
      margin-bottom: 40px;
    }

    &_flex {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .modal__content {
        margin: 2px 0 0;
        font-size: 14px;
        line-height: 20px;
      }
    }

    /deep/ {
      .vdpComponent.vdpWithInput input {
        border: none;
        width: 90px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: #a7a9c0;
      }

      .vdpComponent.vdpWithInput .vdpClearInput {
        display: none;
      }

      .vdpOuterWrap .vdpInnerWrap {
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
        border-radius: 24px;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpHeader .vdpPeriodControls .vdpPeriodControl > button {
        font-weight: bold;
        font-size: 16px;
        line-height: 29px;
        color: #2d3748;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpTable .vdpRow .vdpCell.selected .vdpCellContent {
        background: #6a67ce;
      }

      .vdpCell.outOfRange {
        font-size: 12px;
        line-height: 21px;
        color: #a0aec0;
      }

      .vdpOuterWrap {
        font-size: 12px;
        line-height: 21px;
        color: #2d3748;
        box-shadow: none !important;
      }
    }
  }
}

.approved {
  width: max-content;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background: $background-active;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $text-disabled;

  &_active {
    background: #ebfcf6;

    .icon-check-mark {
      background: rgba(55, 197, 151, 0.3);
      color: $interactive-success-hovered;
    }
  }

  .icon-check-mark {
    width: 11.5px;
    height: 11.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 6px;
    background: rgba(167, 169, 192, 0.3);
    color: $icon-subdued;
    border-radius: 50%;
  }
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #ffffff;

  &__avatar {
    flex-shrink: 0;

    display: block;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover {
    .user__name {
      display: block;
    }
  }

  &__name {
    display: none;
    position: absolute;
    top: -30px;
    left: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    span {
      cursor: pointer;
      margin-left: 8px;
      text-decoration: underline;
    }
  }
}
</style>
