<template>
  <li class="absence-card__item" ref="AbsenceSetting">
    <div class="absence-card__item-wrap">
      <div class="absence-card__text">
        <p class="absence-card__date">
          {{ setLocalDate(item.date_from) }} — {{ setLocalDate(item.date_to) }}
        </p>
        <div
          class="absence-card__days"
          :class="absenceComplete(item.date_to) ? 'absence-card__days_complete' : ''"
        >
          <div
            class="absence-card__complete-icon"
            :class="absenceComplete(item.date_to) ? 'absence-card__complete-icon_complete' : ''"
          >
            <i class="icon-check-mark"></i>
          </div>
          <span>{{ item.count_days }}</span>
        </div>
      </div>
    </div>
    <AbsenceSetting :currentVacationData="item" :isAvailableForEditing="isAvailableForEditing" />
  </li>
</template>

<script>
import AbsenceSetting from "@/components/GraphVacation/Absence/AbsenceSetting";
import moment from "moment";
export default {
  name: "AbsenceItem",
  mixins: [],
  props: ["item", "isAvailableForEditing"],
  components: { AbsenceSetting },
  data() {
    return {
      modalShow: false
    };
  },
  mounted() {},
  methods: {
    absenceComplete(item) {
      if (moment(this.getToday) >= moment(item)) {
        return true;
      } else {
        return false;
      }
    },
    setLocalDate(date) {
      moment.locale("ru");
      return moment(date).format("L");
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

.absence-card {
  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    /deep/.icon-dots-vertical {
      color: #b0b7c3;
      font-size: 18px;
      align-self: flex-start;
      cursor: pointer;
    }
  }

  .absence-card__item-wrap {
    max-width: 263px;
    width: 100%;
  }

  .absence-card__text {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    width: 230px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  &__days {
    padding-left: 14px;
    padding-right: 14px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    line-height: 29px;
    font-weight: 500;
    color: #a6b7d4;

    background: #f2f2f7;
    border-radius: 16px;

    &_complete {
      color: $action-primary;
      background-color: $action-primary-disabled;
    }

    i {
      font-size: 14px;
    }

    span {
      margin-top: 2px;
    }
  }

  &__complete-icon {
    position: relative;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    border-radius: 50%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a7a9c0;

    &_complete {
      background-color: $action-primary;
      border: none;
      color: $action-secondary;
    }
    .icon-check-mark {
      position: absolute;
      font-size: 6px;
    }
  }
}
</style>
