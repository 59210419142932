<template>
  <div class="problem-body">
    <div class="problem-body__wrap-label wrap-label" v-if="showHeader">
      <div class="wrap-label__label" v-if="currentInfoProblem">
        {{ currentInfoProblem.group.alias }}-{{ currentInfoProblem.id }}
      </div>
      <div class="problem-body__archive" v-if="currentInfoProblem.is_archived">В архиве</div>
      <div class="wrap-label__observe">
        Наблюдать
        <SwitchIOS :value="isObserve" @change="changeSwitch" />
      </div>
    </div>
    <div class="problem-body__wrap-members" v-if="showHeader">
      <CustomerComponent
        :user="currentInfoProblem"
        @editCustomer="handleEditCustomer"
        :isEdit="isEditCustomer"
      />
      <ResponsibleComponent
        :user="currentInfoProblem"
        @editResponsible="handleEditResponsible"
        :isEdit="isEditResponsible"
      />
      <ExecutorsComponent
        :user="currentInfoProblem.task_executors"
        :isProblemPage="false"
        label="ИСПОЛНИТЕЛИ"
      />
    </div>
    <div class="problem-body__wrap">
      <div class="problem-body__wrap-more">
        <CriticalComponent
          :user="currentInfoProblem"
          @editCritical="handleEditCritical"
          :isEdit="isEditCritical"
        />
        <StatusComponent
          :user="currentInfoProblem"
          @editStatus="handleEditStatus"
          :isEdit="isEditStatus"
        />
        <PeriodComponent
          :user="currentInfoProblem"
          @editPeriod="handleEditPeriod"
          :isEdit="isEditPeriod"
        />
        <DeadLineComponent
          :user="currentInfoProblem"
          :value="selectDate"
          :isEdit="isEditDeadline"
          @switchCalendar="switchCalendar"
          @closeCalendar="closeCalendar"
          @clearCalendar="pickCalendar"
          :showCalendar="showCalendar"
        />
      </div>
      <DeadLineCalendar :value="selectDate" v-if="showCalendar" @closeCalendar="pickCalendar" />
    </div>
    <div class="problem-body__wrap-name wrap-name" v-if="showHeader">
      <EditField
        classSize="h2"
        keyUser="name"
        :maxLength="250"
        :user="currentInfoProblem"
        :isReadonly="!isEditField"
        @editDone="handleEditName"
        placeholder="Максимум 250 символов"
        :editableTitle="currentInfoProblem.name"
      />
    </div>
    <div class="problem-body__wrap-description wrap-text">
      <EditField
        classSize="text"
        keyUser="description"
        :maxLength="2000"
        @editDone="handleEditDescription"
        :isReadonly="!isEditField"
        placeholder="Максимум 2000 символов"
        :user="currentInfoProblem"
        label="Описание проблемы"
      />
    </div>
    <div class="problem-body__wrap-expected wrap-text">
      <EditField
        classSize="text"
        :user="currentInfoProblem"
        :maxLength="1000"
        :isReadonly="!isEditField"
        @editDone="handleEditResult"
        keyUser="expected_result"
        placeholder="Максимум 1000 символов"
        label="Ожидаемый результат"
      />
    </div>
    <div class="problem-body__wrap-solving wrap-text">
      <EditField
        keyUser="report"
        classSize="text"
        :maxLength="3500"
        :isReadonly="!isEditReport"
        @editDone="handleEditReport"
        :user="currentInfoProblem"
        label="Отчет по решению проблемы"
        placeholder="Добавьте отчет"
      />
    </div>
    <div
      class="problem-body__wrap-docs wrap-docs"
      v-if="
        isEditReport ||
        (!isEditReport &&
          currentInfoProblem.attachment_files &&
          currentInfoProblem.attachment_files.length)
      "
    >
      <div class="wrap-docs__label-wrap">
        <div class="wrap-docs__label">Прикрепленные документы</div>
        <file-upload
          class="file-uploader"
          post-action="/upload/post"
          :multiple="true"
          :maximum="5"
          acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
          ref="upload"
          :input-id="'problem' + currentInfoProblem.id"
          v-model="files"
          v-if="isEditReport"
          :disabled="loading || setMaximum || !isEditReport"
          @input="uploadFile"
        >
          <Button :type="setType" icon="add-file" title="Прикрепить" :loader="loading" />
        </file-upload>
      </div>
      <DocsComponent
        :isEdit="isEditReport"
        :uploadFiles="currentInfoProblem"
        v-if="currentInfoProblem.attachment_files"
        @editFiles="handleEditFiles"
      />
    </div>
    <div class="problem-body__wrap-related" v-if="showRelated">
      <RelatedComponent />
    </div>
  </div>
</template>

<script>
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS";
import { mapActions, mapState } from "vuex";
import CustomerComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/CustomerComponent";
import ResponsibleComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/ResponsibleComponent";
import ExecutorsComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/ExecutorsComponent";
import CriticalComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/CriticalComponent";
import StatusComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/StatusComponent";
import PeriodComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/PeriodComponent";
import DeadLineComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineComponent";
import DeadLineCalendar from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineCalendar";
import EditField from "@/modules/Isp/components/EditField/EditField";
import ClickOutside from "vue-click-outside";
import moment from "moment";
const FileUpload = () => import("vue-upload-component");
import Button from "@/modules/UiKit/components/buttons/Button";

import DocsComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/Documents/DocsComponent";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import RelatedComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/RelatedComponent";
import { EventBus } from "@/main";
import NotificationObserver from "@/helpers/UserNotification";
export default {
  name: "ProblemBody",
  mixins: [uploadFilesMixin],
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    RelatedComponent,
    Button,
    FileUpload,
    DocsComponent,
    EditField,
    DeadLineCalendar,
    DeadLineComponent,
    PeriodComponent,
    StatusComponent,
    CriticalComponent,
    ExecutorsComponent,
    ResponsibleComponent,
    CustomerComponent,
    SwitchIOS
  },
  data() {
    return {
      isObserve: true,
      showCalendar: false,
      loading: false,
      selectDate: null,
      files: [],
      formats: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/x-flv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/mp4",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/x-msvideo",
        "video/3gpp",
        "video/mpeg",
        "audio/mpeg"
      ],
      temporaryFiles: []
    };
  },
  mounted() {
    if (this.currentInfoProblem) {
      this.isObserve = !!this.currentInfoProblem.current_viewer_user;
      if (this.currentInfoProblem.attachment_files) {
        this.files = this.currentInfoProblem.attachment_files;
      }
      this.selectDate = this.currentInfoProblem.deadline;
    }
  },
  methods: {
    ...mapActions("IspModule", [
      "closeCurrentInfoProblem",
      "handleUpdateProblem",
      "subscribeToProblem",
      "setFilesLoader",
      "unsubscribeFromProblem"
    ]),
    uploadFile(value) {
      this.files = value.filter((item) => {
        if (item.size > 5242880) {
          EventBus.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Размер файла ${item.name} привышает 5 мб`
          });
          return false;
        }
        if (item.extension) {
          return true;
        }
        if (item.file.type) {
          if (this.formats.includes(item.file.type)) {
            return true;
          } else {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Формат файла ${item.file.type} не поддерживается`
            });
            return false;
          }
        }
        return false;
      });
      this.uploadHandler(this.files);
    },
    filesUploaded() {
      this.loading = false;
      this.handleEditFiles({
        ids: [...this.oldEditFiles.map((item) => item.id), ...this.fileUploadingId],
        files: [...this.oldEditFiles, ...this.uploadedFiles]
      });
    },

    handleErrorUpload() {
      this.files = this.currentInfoProblem.attachment_files;
    },
    uploadHandler(files) {
      this.fileUploader = [];
      this.oldEditFiles = [];
      this.fileUploadingId = [];
      this.uploadedFiles = [];
      files.forEach((item) => {
        if (!item.link) {
          item.label = item.name;
          this.fileUploader.push(item);
        }
        if (item.link) {
          this.oldEditFiles.push(item);
        }
      });
      if (this.fileUploader.length) {
        this.loading = true;
        this.createPull(this.fileUploader);
      }
    },
    changeSwitch(isObserve) {
      this.isObserve = isObserve;
      if (this.isObserve) {
        this.subscribeToProblem();
      } else {
        this.unsubscribeFromProblem({
          payload: this.currentInfoProblem.current_viewer_user.id,
          isPage: false
        });
      }
    },
    handleEditCustomer(customer_user) {
      this.handleUpdateProblem({
        data: {
          customer_user_id: customer_user.id,
          customer_user
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditResponsible(responsible_user) {
      this.handleUpdateProblem({
        data: {
          responsible_user_id: responsible_user.id,
          responsible_user
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditCritical(criticality) {
      this.handleUpdateProblem({
        data: {
          criticality_id: criticality.id,
          criticality
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditStatus(status) {
      this.handleUpdateProblem({
        data: {
          flow_status_id: status.id,
          status
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditPeriod(period) {
      this.handleUpdateProblem({
        data: {
          period_id: period.id,
          period
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditName(name) {
      this.handleUpdateProblem({
        data: {
          name
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditDescription(description) {
      this.handleUpdateProblem({
        data: {
          description
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditResult(expected_result) {
      this.handleUpdateProblem({
        data: {
          expected_result
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditReport(report) {
      this.handleUpdateProblem({
        data: {
          report
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditFiles(attachment_files) {
      this.handleUpdateProblem({
        data: {
          attachment_files: attachment_files.ids
        },
        files: attachment_files.files,
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    pickCalendar(deadline) {
      this.closeCalendar();
      this.selectDate = deadline;
      const date = deadline ? moment(deadline).format("YYYY-MM-DD") : null;
      if (!this.checkValidation(date)) return false;
      this.handleUpdateProblem({
        data: {
          deadline: date
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    checkValidation(deadline) {
      if (deadline) {
        if (deadline && moment(deadline).isSameOrBefore(moment(new Date(), "YYYY-MM-DD"))) {
          NotificationObserver.notification({
            type: "error",
            message: "Дата дедлайна должна быть позже сегодняшнего дня!"
          });
          this.selectDate = this.currentInfoProblem.deadline;
          return false;
        }
      }
      return true;
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "filesLoader"]),
    isEditField() {
      return (
        (this.isCustomer ||
          this.isResponsible ||
          this.isISPManager ||
          this.isGroupLeader ||
          this.isAuthorProblem) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },

    isEditDeadline() {
      return (
        (this.isResponsible || this.isISPManager || this.isGroupLeader) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isEditReport() {
      return (
        (this.isResponsible || this.isISPManager || this.isExecutor || this.isGroupLeader) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isEditCustomer() {
      return (
        ((this.isGroupLeader && this.currentInfoProblem.status.type === "initial") ||
          this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isEditResponsible() {
      return (this.isGroupLeader || this.isISPManager) && !this.isArchived && this.isEditableStatus;
    },
    isEditCritical() {
      return (
        (this.isGroupLeader || this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus &&
        (this.currentInfoProblem.period === null ||
          (this.currentInfoProblem.period && this.currentInfoProblem.period.can_add_problems))
      );
    },
    isEditStatus() {
      return this.isISPManager && !this.isArchived;
    },
    isEditPeriod() {
      return (this.isGroupLeader || this.isISPManager) && !this.isArchived && this.isEditableStatus;
    },
    isArchived() {
      return this.currentInfoProblem.is_archived;
    },
    isEditableStatus() {
      return (
        this.currentInfoProblem.status.type !== "done" &&
        this.currentInfoProblem.status.type !== "final"
      );
    },
    // Права редактирования
    isAuthorProblem() {
      return this.currentInfoProblem.creator_user_id === this.getUserId;
    },
    isGroupLeader() {
      return this.currentInfoProblem.group.leader_user_id === this.getUserId;
    },
    isExecutor() {
      return this.currentInfoProblem.task_executors.some((item) => item.id === this.getUserId);
    },
    isCustomer() {
      return this.currentInfoProblem.customer_user.id === this.getUserId;
    },
    isResponsible() {
      return this.currentInfoProblem.responsible_user.id === this.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    // Права редактирования
    setMaximum() {
      return this.currentInfoProblem.attachment_files.length === 5;
    },
    showRelated() {
      return (
        this.currentInfoProblem.children_problems.length || this.currentInfoProblem.parent_problem
      );
    },
    setType() {
      return this.setMaximum || !this.isEditReport ? "disabled" : "white";
    }
  },
  watch: {
    currentInfoProblem() {
      this.isObserve = !!this.currentInfoProblem.current_viewer_user;
      this.selectDate = this.currentInfoProblem.deadline;
      if (this.currentInfoProblem.attachment_files) {
        this.files = this.currentInfoProblem.attachment_files;
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.problem-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  &__archive {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 4px 16px;
    color: $icon-subdued;
    border-radius: 26px;
    border: 1px solid $icon-subdued;
    margin-left: 12px;
    user-select: none;
  }
  &__wrap-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #eae8f0;
    .wrap-label {
      &__label {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 26px;
        color: #343748;
      }
      &__observe {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        /deep/ {
          .switch-widget {
            margin-left: 16px;
          }
        }
      }
    }
  }
  &__wrap-members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 24px 24px 0;
  }
  &__wrap-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 24px 0;
  }
  &__wrap {
    padding-bottom: 24px;
    border-bottom: 1px solid #eae8f0;
  }
  &__wrap-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 16px 0;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .wrap-text {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0;
    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 12px;
    }
    &__content {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #343748;
    }
  }
  &__wrap-solving {
    padding-bottom: 24px !important;
    border-bottom: 1px solid #eae8f0;
  }
  &__wrap-docs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    .wrap-docs {
      &__label-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #000000;
      }
    }

    /deep/ {
      label,
      input {
        cursor: pointer;
      }
    }
  }
  &__wrap-related {
    border-top: 1px solid #eae8f0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
  }
}
</style>
