<template>
  <div class="tasks__table-body">
    <MyTasksTableRow
      v-for="task in tasksData"
      :key="task.task.id"
      :task="task.task"
      :tasksData="tasksData"
      :params="params"
      :totalCount="totalCount"
      :fromPag="fromPag"
      @paginationEvent="paginationEvent"
    />
  </div>
</template>

<script>
import MyTasksTableRow from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableRow";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
export default {
  name: "MyTasksTableBody",
  mixins: [],
  props: {
    tasksData: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  components: { MyTasksTableRow },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    paginationEvent(params) {
      this.$emit('paginationEvent', params)
    }
  },
  computed: {
    totalCount() {
      return TaskModule.totalCount;
    },
    fromPag() {
      return TaskModule.fromPag;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.tasks {
  &__table-body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
</style>
