<template>
  <div class="status-component">
    <div class="label">
      <label>ПЕРИОД</label>
    </div>
    <div class="status-component__title" v-show="!isEdit">
      {{ period.name }}
    </div>
    <DropDown v-model="period" v-show="isEdit" :items="setPeriod" @input="handleInput" />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DropDown from "@/modules/UiKit/components/DropDown/DropDown";
import { mapState } from "vuex";

export default {
  name: "PeriodComponent",
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  components: { DropDown },
  data() {
    return {
      period: { name: "Не задано", id: null },
      editMode: true,
      showTooltip: false
    };
  },
  created() {
    if (this.currentInfoProblem.period_id) {
      this.period = this.currentInfoProblem.period;
    }
  },
  mounted() {},
  methods: {
    toggleEdit() {
      // this.editMode = true;
    },
    handleInput() {
      this.$emit("editPeriod", this.period);
    },
    hideEditMode() {
      // this.editMode = false;
    },
    hideTooltip() {
      this.showTooltip = false;
    }
  },
  computed: {
    ...mapState("IspModule", ["quarterList", "currentInfoProblem"]),
    setPeriod() {
      let period = this.quarterList.map((item) => item);
      if (period.length) {
        period = period.filter((item) => item.status !== "Завершен");
      }
      period.unshift({ name: "Не задано", id: null });
      return period;
    }
  },
  watch: {
    currentInfoProblem() {
      if (this.currentInfoProblem.period_id) {
        this.period = this.currentInfoProblem.period;
      } else {
        this.period = { name: "Не задано", id: null };
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.status-component {
  display: flex;
  flex-direction: column;
  user-select: none;
  //width: fit-content;
  width: 110px;
  &__title {
    font-size: 12px;
    line-height: 24px;
    padding: 2px 8px;
    border-radius: 16px;
    text-align: center;
    font-weight: 500;
    border: 2px solid $system-grey-four;
    color: $text-default;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .label {
    position: relative;
    display: flex;
    margin-bottom: 12px;
    width: max-content;
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #343748;
      align-items: center;
    }
    .icon-info {
      align-self: flex-start;
      margin-left: 8px;
      font-size: 14px;
      line-height: 12px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }
    &__tooltip {
      display: block;
      position: absolute;
      max-width: 530px;
      width: max-content;
      left: 100%;
      bottom: 100%;
      z-index: 2;
      padding: 4px 8px;
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      background: #343748;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      word-break: break-word;

      p {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
