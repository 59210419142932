<template>
  <div>
    <div class="check-list__form" v-for="(item, index) in value" :key="index">
      <Input
        :value="item"
        :id="`item_${index}`"
        :maxlength="255"
        :minlength="1"
        :placeholder="'Добавьте пункт'"
        @input="
          changeItem({
            val: $event,
            idx: index
          })
        "
      />
      <div class="check-list__control">
        <button
          class="check-list__cancel"
          type="button"
          aria-label="Отменить создание пункта"
          @click="value.splice(index, 1)"
        >
          <i class="icon-cross"></i>
        </button>
      </div>
    </div>
    <div class="check-list__form" v-if="value.length < 50">
      <Input
        v-model.trim="text"
        id="check-list"
        :maxlength="255"
        :minlength="1"
        :placeholder="'Добавьте пункт'"
        @keydownEnter="handleAccept"
      />
      <div class="check-list__control">
        <button
          class="check-list__create"
          v-if="text.length"
          type="button"
          aria-label="Создать пункт"
          @click="handleAccept"
        >
          <i class="icon-check"></i>
        </button>
        <button
          class="check-list__cancel"
          type="button"
          v-if="text.length"
          aria-label="Отменить создание пункта"
          @click="handleCancel"
        >
          <i class="icon-cross"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "CheckListCreate",
  model: {
    event: "input"
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showAccept: {
      type: Boolean,
      default: true
    }
  },
  components: { Input },
  data() {
    return {
      text: "",
      currentText: "",
      defaultForm: true
    };
  },
  mounted() {},
  methods: {
    handleAccept() {
      const listArray = this.value;
      if (!this.checkListValidation(this.text)) return false;
      listArray.push(this.text);
      this.$emit("input", listArray);
      this.text = "";
      this.defaultForm = false;
    },
    handleCancel() {
      if (this.value.length) {
        this.defaultForm = false;
      }
      this.text = "";
    },
    changeItem(val) {
      this.$emit("editItem", val);
    },
    checkListValidation(val) {
      if (!val.trim().length) {
        NotificationObserver.notification({
          type: "error",
          message: "Пункт не может быть пустым!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.check-list {
  &__form {
    position: relative;
    margin: 0 0 16px;

    /deep/ {
      .input__control {
        padding: 8px 70px 8px 16px;
      }
    }
  }

  &__control {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
  }

  &__create,
  &__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;

    i {
      font-size: 23px;
      color: $icon-subdued;
    }

    &:hover i {
      color: $icon-hovered;
    }

    &:active i {
      color: $icon-pressed;
    }
  }

  &__cancel {
    margin-left: 8px;
  }
}
</style>
