<template>
  <div class="wrap-related">
    <div class="wrap-related__label-wrap">
      <div class="wrap-related__label">Связанные проблемы</div>
    </div>
    <div class="wrap-related__content">
      <div class="wrap-related__item" v-if="currentInfoProblem.parent_problem">
        <p class="wrap-related__text">Предыдущая проблема</p>
        <router-link
          class="info-problem__link"
          :to="{ name: 'IspProblem', params: { id: currentInfoProblem.parent_problem.id } }"
        >
          <span class="wrap-related__link">
            {{ currentInfoProblem.parent_problem.group.alias }}-{{
              currentInfoProblem.parent_problem.id
            }}</span
          >
        </router-link>
      </div>
      <div v-if="currentInfoProblem.children_problems.length">
        <div
          class="wrap-related__item"
          v-for="item in currentInfoProblem.children_problems"
          :key="item.id"
        >
          <p class="wrap-related__text">Следующая проблема</p>
          <router-link
            class="info-problem__link"
            :to="{ name: 'IspProblem', params: { id: item.id } }"
          >
            <span class="wrap-related__link"> {{ item.group.alias }}-{{ item.id }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RelatedComponent",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "filesLoader"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.wrap-related {
  &__label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  &__content {
    margin-top: 16px;
  }
  &__item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    margin-right: 16px;
  }
  &__link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $action-primary-accent;
  }
}
</style>
