import API from "@/api/Api";

class MessageReactions extends API {
  constructor(prefix) {
    super("MessageReactions", prefix);
  }
  actionCreate(callback, data) {
    this.post("actionCreate", callback, data);
  }
  actionDelete(callback, data) {
    this.delete("actionDelete", callback, data);
  }
  actionGetItem(callback, data) {
    this.get("actionGetItem", callback, data);
  }
  getAllReactions(callback, data) {
    this.get("getAllMessageReactionsByMessageId", callback, data);
  }
}

export default MessageReactions;
