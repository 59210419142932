



























import { Component, Mixins, Prop } from "vue-property-decorator";
import uploadFilesMixin from "@/aopV2/helpers/uploadFiles.mixin";
import FileItem from "@/aopV2/components/UIComponents/File/FileItem";

@Component({ components: { FileItem } })
export default class InputFile extends Mixins(uploadFilesMixin) {
  @Prop({
    required: true,
    type: String
  })
  id!: string;
  @Prop({
    type: String,
    default: "Прикрепить файл"
  })
  title!: string;
  @Prop({
    type: Boolean,
    default: true
  })
  multiple!: boolean;

  deleteFile(file) {
    this.uploadedFiles = this.uploadedFiles.filter((item) => item.id !== file.id);
    this.$emit(
      "uploadFile",
      this.uploadedFiles.map((item) => item.id)
    );
  }
}
