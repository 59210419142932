var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideList),expression:"hideList"}],class:[
    'status',
    {
      status_isDropDown: _vm.isDropDown && _vm.statusList.length,
      status_open: _vm.showList && _vm.isDropDown && _vm.statusList.length
    }
  ],style:({ borderColor: _vm.status.color }),on:{"click":function($event){_vm.showList = !_vm.showList}}},[_c('p',{staticClass:"status__content"},[_vm._v(_vm._s(_vm.status.name))]),(_vm.isDropDown && _vm.statusList.length)?_c('button',{staticClass:"status__button",class:{
      status__button_open: _vm.showList
    }},[_c('i',{staticClass:"icon-down"})]):_vm._e(),(_vm.showList && _vm.isDropDown && _vm.statusList.length)?_c('ul',{staticClass:"status__list"},_vm._l((_vm.statusList),function(item){return _c('li',{key:item.id,staticClass:"status__item",on:{"click":function($event){return _vm.setItem(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }