<template>
  <div
    class="timeline-vacation__row timeline-vacation__row_empty"
    :style="'min-height: ' + setLength"
  >
    <div class="timeline-vacation__col" v-for="(week, weekNumber) in date" :key="weekNumber">
      <TimeLineDay :week="week" :isShowWeekends="isShowWeekends" />
    </div>
  </div>
</template>

<script>
import TimeLineDay from "@/components/GraphVacation/TimeLine/TimeLineDay";
export default {
  name: "EmptyRow",
  mixins: [],
  props: {
    date: {
      type: Object,
      default: () => {
        return {};
      }
    },
    length: {
      type: Number,
      default: 0
    },
    isShowWeekends: {
      type: Boolean,
      default: false
    }
  },
  components: { TimeLineDay },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setLength() {
      switch (this.length) {
        case 1:
          return "60vh";
        case 2:
          return "53vh";
        case 3:
          return "46vh";
        case 4:
          return "39vh";
        case 5:
          return "32vh";
        case 6:
          return "26vh";
        case 7:
          return "19vh";
        case 8:
          return "12vh";
        case 9:
          return "5vh";
        default:
          "0px";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.timeline-vacation {
  &__row {
    position: relative;
    display: flex;
    height: 72px;

    &:nth-child(2) {
      height: 42px;
      position: sticky;
      top: 96px;
      background-color: $bg-gray-75;
    }
  }
}
</style>
