<template>
  <div class="modal">
    <div class="modal__window">
      <h2 class="modal__title">Прогресс. Дождитесь окончания!</h2>
      <ProgressBar :percent="setPercent" />
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
import centrifuge from "@/centrifuge";
import { mapMutations, mapState } from "vuex";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "ProgressModal",
  mixins: [],
  props: {
    title: {
      type: String,
      default: "дублирования"
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  components: {
    ProgressBar
  },
  data() {
    return {
      socketStatus: null,
      progressInfo: null
    };
  },
  beforeDestroy() {
    if (this.socketStatus) {
      this.socketStatus.unsubscribe();
    }
  },
  mounted() {
    this.subscribeProgress();
  },
  methods: {
    ...mapMutations("IspModule", ["SET_SHOW_PROGRESS_MODAL"]),
    subscribeProgress() {
      this.socketStatus = centrifuge.subscribe(this.channelCentrifuge, (message) => {
        this.progressInfo = message.data;
        if (this.progressInfo.message) {
          if (this.progressInfo.message === "Finish") {
            this.SET_SHOW_PROGRESS_MODAL(false);
            if (this.progressInfo.notification) {
              NotificationObserver.notification({
                type: "success",
                message: this.progressInfo.notification
              });
            }
          }
        }
        if (
          this.progressInfo.type === "archiveIspProblemsError" ||
          this.progressInfo.type === "unarchiveIspProblemsError" ||
          this.progressInfo.type === "duplicateIspError"
        ) {
          this.SET_SHOW_PROGRESS_MODAL(false);
          NotificationObserver.notification({
            type: "error",
            message: this.progressInfo.message
          });
        }
      });
    }
  },
  computed: {
    ...mapState("IspModule", ["channelCentrifuge"]),
    setPercent() {
      if (this.progressInfo) {
        if (this.progressInfo.message) {
          if (this.progressInfo.message === "Finish") {
            return 100;
          } else {
            return 0;
          }
        } else if (this.progressInfo.percent) {
          return this.progressInfo.percent;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 55, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &__window {
    padding: 32px 24px 40px;
    width: 716px;
    background: $surface-default;
    border-radius: 16px;
  }

  &__title {
    margin: 0 0 24px;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $text-accent;
  }

  /deep/ {
    .progress {
      width: 100%;
      margin: 0 0 16px;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;

      &__percent {
        margin: 0 0 0 16px;
        order: 2;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: $text-accent;
      }

      &__wrap {
        order: 1;
      }
    }

    .aop-button {
      margin-left: auto;
    }
  }
}
</style>
