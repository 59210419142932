<template>
  <li class="timeline-vacation__member-user">
    <router-link
      class="timeline-vacation__user"
      :to="{ name: 'UserInfo', params: { user_id: user.id } }"
      target="_blank"
    >
      <img
        :src="returnSrcFile(user.avatar)"
        alt=""
        class="timeline-vacation__user-img"
        width="46"
        height="46"
      />
      <div class="timeline-vacation__user-wrap">
        <p class="timeline-vacation__user-name">
          {{ user.full_name }}
        </p>
        <p class="timeline-vacation__user-role" v-if="user.roles.length">
          {{ user.roles[0].name }}
        </p>
      </div>
    </router-link>
  </li>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "TimeLineMembers",
  mixins: [],
  props: {
    user: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.timeline-vacation {
  &__user {
    display: flex;
    align-items: center;
    min-height: 48px;
    margin-bottom: 24px;
    padding-right: 8px;
  }
  &__user-wrap {
    display: flex;
    flex-flow: column;
    align-self: flex-start;
  }
  &__user-img {
    object-fit: cover;
    border-radius: 50%;
    margin-right: 24px;
    margin-left: 24px;

    @media (max-width: $sm) {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      align-self: flex-start;
    }
    @media (max-width: $xs) {
      margin-left: 10px;
    }
  }
  &__user-name {
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: $text-default;
    max-width: 156px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $sm) {
      font-size: 13px;
      line-height: 20px;
      max-width: 100px;
    }
  }
  &__user-role {
    margin: 0 !important;
    font-size: 12px;
    line-height: 24px;
    color: $text-disabled;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;

    @media (max-width: $sm) {
      font-size: 10px;
      line-height: 20px;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
