<template>
  <div class="education__widgets-mobile">
    <Widget
      v-show="valueFilter === 'Сотрудник'"
      type="select-new"
      :entity="'User'"
      :actionEntity="'getItems'"
      :itemName="'full_name'"
      :keyValue="'FilterUserListInEducationTable'"
      :label="'Сотрудник'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterUser"
      :filterActive="!filterActive"
    />
    <Widget
      v-show="valueFilter === 'Вид образования'"
      type="select-new"
      :entity="'EducationType'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterTypeListInEducationTable'"
      :label="'Вид образования:'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterTypeEducation"
      :class="'select-bg_type'"
    />
    <Widget
      v-show="valueFilter === 'Учебное заведение'"
      type="select-new"
      :entity="'EducationInstitution'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterInstitutionListInEducationTable'"
      :label="'Учебное заведение:'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterInstitution"
      :class="'select-bg_institution'"
    />
    <div class="education__actions actions" v-click-outside="hideModalList">
      <button
        class="actions__toggle"
        type="button"
        @click="openList"
        :class="{ actions__toggle_active: showFilterList }"
      >
        <i class="icon-dots-vertical"></i>
      </button>
      <div class="actions__settings" v-if="showFilterList">
        <FiltersList
          :filtersList="filtersList"
          :valueFilter="valueFilter"
          @choseFilter="choseFilter"
        />
        <div class="actions__switches">
          <Widget
            class="actions__switch"
            type="Switch"
            :unique="'FilterActiveInEducationTable'"
            :label="'Уволен'"
            :editValue="filterActive"
          />
        </div>
        <div class="actions__buttons" v-if="isAdmin">
          <ModalButton
            :title="'Выгрузить'"
            :icon="'download-secondary'"
            :type="'filter-list'"
            @handleButton="
              downloadReports({ value: 'downloadReportEduction', record: getNewRecord })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Widget from "@/components/Widgets/Widget";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { mapActions, mapState } from "vuex";
import FiltersList from "@/modules/Tables/components/TablesCompany/Education/EducationWidgetsMobile/Filters/FiltersList";

export default {
  name: "EducationWidgetsMobile",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    filterActive: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterTypeEducation: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterInstitution: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { FiltersList, Widget, ModalButton },
  data() {
    return {
      showFilterList: false,
      filtersList: ["Сотрудник", "Вид образования", "Учебное заведение"],
      valueFilter: "Сотрудник"
    };
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["downloadReports"]),
    openList() {
      this.showFilterList = !this.showFilterList;
    },
    hideModalList() {
      this.showFilterList = false;
    },
    choseFilter(item) {
      this.valueFilter = item;
    }
  },
  computed: {
    getNewRecord() {
      return this.$store.state.newRecord.month;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.education {
  &__widgets-mobile {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    @media (max-width: $xs) {
      padding: 8px 16px;
    }

    @media (max-height: $xs) {
      padding: 8px 16px;
    }

    /deep/ {
      .select-bg {
        width: 250px;
        max-width: 250px;
        margin-right: 8px;

        .select-label {
          white-space: nowrap;
        }

        .select-content-bg {
          height: 28px;
          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
        }

        &_type .select-content-bg .select-content .select-content-value-bg {
          width: calc(100% - 185px);
        }
        &_institution .select-content-bg .select-content .select-content-value-bg {
          width: calc(100% - 200px);
        }
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      .modal-items {
        background: #fafafe;
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }
    }
  }

  .actions {
    position: relative;
    margin-left: 0;

    &__toggle {
      width: 32px;
      height: 32px;
      background: $action-secondary;
      border-radius: 4px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: $icon-subdued;
        font-size: 24px;
        text-align: center;
      }
      &:hover {
        background: $action-secondary-hovered;

        i {
          color: $icon-subdued;
        }
      }

      &:active,
      &:focus {
        background: $action-secondary-pressed;
      }
    }

    &__settings {
      position: absolute;
      z-index: 15;
      top: 34px;
      right: 0;
      padding: 8px 0;
      list-style: none;
      min-width: 180px;
      width: max-content;
      background-color: $surface-default;
      cursor: pointer;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 14px;

      @media (max-height: $xxs) {
        top: -12px;
        right: -192px;
      }
    }

    &__switches {
      padding: 8px 16px;
      border-top: 1px solid $border-disabled;

      /deep/ {
        .switch-widget__label {
          margin: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
        }

        .switch-widget__indicator {
          width: 18px;
          height: 10px;
          background-color: $icon-disabled;
          margin-right: 8px;

          &::before {
            top: 2px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }

        .switch-widget__input:checked + .switch-widget__indicator {
          background-color: $action-primary;

          &:before {
            top: 2px;
            left: 10px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }
      }
    }

    &__buttons {
      padding: 8px;
      border-top: 1px solid $border-disabled;
    }
  }
}
</style>
