<template>
  <div class="plan-table__fields fields">
    <div class="cell" v-for="field in tableFields" :key="field.id">
      {{ field.name }}
      <Tooltip v-if="field.tooltip" :text="field.tooltip" />
    </div>
  </div>
</template>

<script>
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";
export default {
  name: "PlanTableFields",
  mixins: [],
  props: {
    tableFields: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Tooltip },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.fields {
  width: 100%;
  display: flex;
  height: 46px;
  .cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4f0ff;
    border-right: 1px solid #f7f7fd;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $text-default;
    padding: 8px;
  }
}
</style>
