<template>
  <div class="modal-create-user">
    <div class="modal-create-user__wrap">
      <ModalHeader title="Добавить сотрудника" @hideModal="hideModal" />
      <div class="modal-create-user__body">
        <Input v-model="surname" :placeholder="'Фамилия'" :maxlength="64" />
        <Input v-model="name" :placeholder="'Имя'" :maxlength="64" />
        <Input v-model="email" :placeholder="'Email'" :maxlength="64" />
        <DatePickerWidget v-model="date" :deadline="false" />
      </div>
      <ModalFooter @hideModal="hideModal" @create="addUser" />
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/Widgets/ModalsWidgets/ModalHeader";
import ModalFooter from "@/components/Widgets/ModalsWidgets/ModalFooter";
import * as types from "@/modules/Tables/store/mutations/mutationsTypes";
import { mapActions, mapMutations } from "vuex";
import Input from "@/modules/UiKit/components/Inputs/Input";
import DatePickerWidget from "@/modules/UiKit/components/DatePicker/DatePickerWidget";
import moment from "moment";

export default {
  name: "ModalCreateUser",
  mixins: [],
  props: {},

  components: { DatePickerWidget, Input, ModalFooter, ModalHeader },
  data() {
    return {
      surname: "",
      name: "",
      email: "",
      date: new Date()
    };
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["createUserAction"]),
    ...mapMutations("TablesModules", [types.TOGGLE_SHOW_MODAL_CREATE_USER]),
    hideModal() {
      this[types.TOGGLE_SHOW_MODAL_CREATE_USER](false);
    },
    addUser() {
      const newUser = {
        surname: this.surname,
        name: this.name,
        email: this.email,
        date_of_employment: this.date
          ? moment(this.date).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD")
      };
      const callback = () => {
        this.hideModal();
        this.$emit("updateTable");
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Сотрудник добавлен`
        });
      };
      this.createUserAction({ newUser, callback });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal-create-user {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: $background-modal;
  &__wrap {
    overflow: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
    max-height: 80vh;
    background: $surface-default;
    border-radius: 4px;
    @media (max-width: $sm), (max-height: $xs) {
      max-width: unset;
      max-height: unset;
      border-radius: unset;
      width: 100vw;
      height: 100vh;
    }
  }
  &__body {
    padding: 24px 24px;
    overflow: auto;
    height: calc(100% - 140px);
    /deep/ {
      .input {
        margin-bottom: 24px;
        input {
          width: 100%;
        }
      }
      .date-picker {
        width: 100%;

        &__value {
          width: 100%;
          margin-bottom: 5px;
        }
        &__calendar {
          position: relative;
          .vc-container {
            width: 100%;
          }
        }
      }
    }
  }
}
/deep/.modal__footer {
  padding: 16px 24px;
}
</style>
