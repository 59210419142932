<template>
  <li class="found-task" :class="{ 'found-task_active': isActive }" @click="goToTask">
    <p>{{ foundTask.alias || "WAP" }}-{{ foundTask.task_id }} {{ foundTask.name }}</p>
  </li>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "FoundTask",
  mixins: [],
  props: {
    foundTask: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToTask() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.SET_ACTIVE_FOUND_ITEM]({
        type: "task",
        id: this.foundTask.id
      });
      this.$router.push({
        name: "Task",
        params: { id: this.foundTask.task_id },
        query: {
          alias: this.foundTask.alias
        }
      });
    }
  },
  computed: {
    currentTask() {
      return TaskModule.currentTask;
    },
    isActive() {
      return (
        TaskManagerModuleV2.activeFoundItem?.type === "task" &&
        TaskManagerModuleV2.activeFoundItem?.id === this.foundTask.id
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.found-task {
  font-size: 14px;
  padding: 12px 0;
  word-break: break-word;
  cursor: pointer;
  &_active {
    color: $link-default;
  }
}
</style>
