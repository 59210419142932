<template>
  <div
    :class="[
      'task-priority',
      { 'task-priority_isDropDown': isDropDown, 'task-priority_open': showList && isDropDown },
      setClass
    ]"
    v-click-outside="hideList"
    @click="showList = !showList"
  >
    <div class="task-priority__wrap">
      <p class="task-priority__label">{{ priority.name }}</p>
      <button class="task-priority__button" v-if="isDropDown">
        <i class="icon-down"></i>
      </button>
    </div>
    <ul class="task-priority__list" v-if="showList && isDropDown">
      <li
        class="task-priority__item"
        v-for="item in priorityList"
        :key="item.id"
        @click="setItem(item)"
      >
        {{ item.name || item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "TaskPriority",
  mixins: [],
  props: {
    priority: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDropDown: {
      type: Boolean,
      default: false
    },
    priorityList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    setItem(value) {
      this.$emit("input", value);
    },
    hideList() {
      this.showList = false;
    }
  },
  computed: {
    setClass() {
      switch (this.priority.id) {
        case 1:
          return "task-priority_low";
        case 2:
          return "task-priority_medium";
        case 3:
          return "task-priority_high";
        default:
          return "";
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.task-priority {
  position: relative;
  font-family: "Roboto", "Arial", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 90px;
  height: 30px;
  font-size: 16px;
  line-height: 19px;

  &_open {
    i {
      transform: rotate(180deg);
    }
  }
  &_isDropDown {
    cursor: pointer;
    border-radius: 8px;
    padding-left: 10px;
  }
  &_high {
    background: rgba(251, 100, 109, 0.3);
    border: 1px solid #fb646d;
    border-radius: 4px;
    p {
      color: $system-red;
    }
  }
  &_medium {
    background: rgba(255, 107, 0, 0.3);
    border: 1px solid #ee7200;
    border-radius: 4px;
    p {
      color: #ee7200;
    }
  }
  &_low {
    background: rgba(255, 212, 38, 0.3);
    border: 1px solid #ffd426;
    border-radius: 4px;
    p {
      color: $system-orange;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 0 0 8px;
    cursor: pointer;

    i {
      display: flex;
      transition: all 0.3s ease;
      color: $surface-default;
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: $surface-default;
    border: 1px solid $system-grey-four;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  &__item {
    padding: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $text-default;
    cursor: pointer;

    &:hover {
      background: $bg-surface-light;
    }
  }
}
</style>
