



























import { Component, Prop, Vue } from "vue-property-decorator";
import CheckboxInput from "@/aopV2/components/UIComponents/Input/Check/Checkbox/CheckboxInput.vue";

@Component({
  components: { CheckboxInput }
})
export default class DropDownList extends Vue {
  @Prop({
    default: "",
    type: String
  })
  title!: string;

  @Prop({
    default: false,
    type: Boolean
  })
  add!: boolean;

  @Prop({
    default: true,
    type: Boolean
  })
  isShowIcon!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isDefOpen!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isCheck!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isShowCheck!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  disabled!: boolean;

  mounted() {
    this.isOpen = this.isDefOpen;
  }

  isOpen = true;

  toggleList() {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  handleCheck() {
    this.$emit("handleCheck");
  }

  handleAdd() {
    this.$emit("handleAdd");
  }
}
