




import { Component, Vue, Watch } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import centrifuge from "@/centrifuge";
import { Subscription } from "centrifuge";

@Component
export default class Calendar extends Vue {
  subscribe: null | Subscription = null;

  mounted() {
    CalendarModule2.getGroups();
    this.subscribe = centrifuge.subscribe("calendar", (message) => {
      CalendarModule2.checkEventUpdate(message.data);
    });
  }

  beforeDestroy() {
    if (this.subscribe) this.subscribe.unsubscribe();
  }
}
