import { IFSConfig } from "@/aopV2/layout/LayoutMain/Modal";
import { importFunction } from "@/aopV2/layout/LayoutMain/store";

export interface ITimeProject {
  id: number;
  tracker_id: number;
  tracker_project_id: number;
  time?: number;
  name: string;
}

export interface ISyncProject {
  provider_identity: number;
  tracker_id: number;
  tracker_name: string;
  config_name: string;
  email: string;
  need_to_sync: boolean;
}

export interface ITimeRow {
  date: string;
  project_name: string;
  task_name: string;
  disabled: boolean;
  id: number;
  is_night: boolean;
  project_id: number;
  reason_id: number | null;
  reason_name: string | null;
  task_id: number;
  time_start: string;
  time_stop: string;
  user_id: number;
}

export interface ITimeModalData {
  id?: number;
  task_id: number;
  project_id: number;
  reason_id: number;
  date: string;
  time_start: string;
  time_stop: string;
}

export interface ITableData {
  list: Array<ITimeRow>;
  count: number;
  page?: number;
  per_page?: number;
}

export class TimeDataDto implements ITimeModalData {
  id?: number;
  task_id: number;
  project_id: number;
  reason_id: number;
  date: string;
  time_start: string;
  time_stop: string;

  constructor(data) {
    this.id = data.id ? data.id : null;
    this.task_id = data.task_id;
    this.project_id = data.project_id;
    this.reason_id = data.reason_id;
    this.date = data.date;
    this.time_start = data.time_start;
    this.time_stop = data.time_stop;
  }
}

export interface IModalConfig {
  confirmButton: string;
  cancelButton: string;
  title: string;
  component?: importFunction | null;
  text: string;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export interface IMember {
  id: string | number;
  name: string;
  full_name: string;
  surname: string;
  avatar: string;
  is_active: boolean;
}

export interface IProject {
  name: string;
  id: string | number;
  tag?: string;
  time?: number;
}

export interface ICalendarItem {
  date: string;
  id?: number;
  hours: number;
}

export interface ITableMonthData {
  date: string;
  time: number;
  has_absence: boolean;
  has_sick: boolean;
}

export interface IUserStatistics {
  user_id: number;
  month_standard_time: number;
  total_tracked_time: number;
  absence_tracked_time: number;
  sick_tracked_time: number;
  difference_time: number;
}

export interface IStatistic {
  tracked_time: string;
  month_normal_time: string;
  current_normal_time: string;
  difference_time: string;
  vacations: string;
  sick_leaves: string;
  left_to_work: string;
  black_sphere_percent: number;
  black_sphere_count_marks: number;
}

export interface ITask {
  name: string;
  id: string | number;
  alias?: string | null;
  link?: string | null;
  deadline?: string | null;
  project_id: string | number;
}

export interface ITaskList {
  [key: string]: {
    list: Array<ITask> | [];
    count: number;
    per_page: number;
    page: number;
  };
}

export interface IToggleTaskPayload {
  isActive: boolean;
  task: ITask | null;
  project: IProject | null;
}

export interface ILimit {
  id: number;
  year: string;
  week: string;
  date_start: string;
  date_stop: string;
  limit: string;
}
