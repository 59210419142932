<template>
  <section class="problem-bg" v-if="currentInfoProblem">
    <div class="problem-bg__header">
      <div class="problem-bg__wrap">
        <EditField
          classSize="h2"
          keyUser="name"
          :maxLength="250"
          :isReadonly="!isEditField"
          :user="currentInfoProblem"
          @editDone="handleEditName"
          placeholder="Максимум 250 символов"
          :editableTitle="currentInfoProblem.name"
        />
        <div class="problem-bg__observe">
          <span> Наблюдать </span>
          <SwitchIOS :value="isObserve" @change="changeSwitch" />
        </div>
        <div class="problem-bg__settings" v-if="showList">
          <ActionsList :actionList="setActionList" @handleAction="handleAction" />
        </div>
      </div>
      <div class="problem-bg__info">
        <div class="problem-bg__date-create">Создано: {{ formatDateCreation }}</div>
        <div class="problem-bg__author">Автор: {{ currentInfoProblem.creator.full_name }}</div>
        <div class="problem-bg__closed" v-if="currentInfoProblem.datetime_of_completion">
          Завершена: {{ formatDateCompleted }}
        </div>
      </div>
    </div>
    <div class="problem-bg__body">
      <div class="problem-bg__problem">
        <div
          class="problem-bg__tabs"
          :class="{ 'problem-bg__tabs_border': currentTab === 'comments' }"
        >
          <TabsContent :tabs="tabsList" v-model="currentTab" />
        </div>
        <div class="problem-bg__problem-body">
          <ProblemBody :showHeader="false" />
        </div>
      </div>
      <div class="problem-bg__container">
        <h3 class="problem-bg__title" v-if="currentTab !== 'problem'">{{ setTitle }}</h3>
        <div class="problem-bg__tabs-content">
          <TasksBody v-if="currentTab === 'problem'" :showHeader="false" />
          <CommentsBody v-if="currentTab === 'comments'" :showHeader="false" />
          <HistoryBody v-if="currentTab === 'history'" :showHeader="false" />
        </div>
      </div>
      <MembersContainer :problem="currentInfoProblem" v-if="currentTab === 'problem'" />
    </div>
    <ISPProblemConfirmationFooter
      @confirm="sendProblemToConfirm"
      @reject="showRejectProblemModal(true)"
      v-if="showConfirmFooter || showConfirmFooterCheck"
    />
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="showConfirmModal = false"
      @accept="confirmParams.callbackName()"
    />
    <ISPProblemRejectModal v-if="showRejectModal" @close="showRejectProblemModal(false)" />
  </section>
</template>

<script>
import { domain } from "@/globalVariables";
import moment from "moment";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import MembersContainer from "@/modules/Isp/components/IspProblem/IspProblemPage/MembersContainer";
import { mapActions, mapMutations, mapState } from "vuex";
import ProblemBody from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/ProblemBody";
import EditField from "@/modules/Isp/components/EditField/EditField";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import { CLOSE_CURRENT_INFO_PROBLEM } from "@/modules/Isp/store/mutations/mutationTypes";
import TasksBody from "@/modules/Isp/components/InfoProblem/BodyComponents/TasksBody/TasksBody";
import CommentsBody from "@/modules/Isp/components/InfoProblem/BodyComponents/CommentsBody/CommentsBody";
import HistoryBody from "@/modules/Isp/components/InfoProblem/BodyComponents/HistoryBody/HistoryBody";
import ISPProblemConfirmationFooter from "@/modules/Isp/components/IspProblem/IspProblemPage/ISPProblemConfirmationFooter";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import ISPProblemRejectModal from "@/modules/Isp/components/IspProblem/IspProblemPage/ISPProblemRejectModal";
import { EventBus } from "@/main";

export default {
  name: "IspProblem",
  mixins: [],
  props: {},
  components: {
    ISPProblemRejectModal,
    ConfirmModal,
    ISPProblemConfirmationFooter,
    HistoryBody,
    CommentsBody,
    TasksBody,
    ProblemBody,
    TabsContent,
    EditField,
    ActionsList,
    SwitchIOS,
    MembersContainer
  },
  data() {
    return {
      showConfirmModal: false,
      showRejectModal: false,
      confirmParams: null,
      isObserve: true,
      currentTab: "problem",
      actionList: []
    };
  },
  created() {
    this.CLOSE_CURRENT_INFO_PROBLEM();
  },
  mounted() {
    this.getInfoProblem();
  },
  methods: {
    ...mapMutations("IspModule", [CLOSE_CURRENT_INFO_PROBLEM]),
    ...mapActions("IspModule", [
      "closeCurrentInfoProblem",
      "handleUpdateProblem",
      "handleActionProblem",
      "subscribeToProblem",
      "getProblemInfoData",
      "getHistoryProblemAction",
      "getProblemCommentsAction",
      "setCurrentInfoProblem",
      "unsubscribeFromProblem",
      "handleUpdateProblem"
    ]),
    handleEditName(name) {
      this.handleUpdateProblem({
        data: {
          name
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleAction(event) {
      this.handleActionProblem(event.actionType);
    },
    changeSwitch(isObserve) {
      this.isObserve = isObserve;
      if (this.isObserve) {
        this.subscribeToProblem();
      } else {
        this.unsubscribeFromProblem({
          payload: this.currentInfoProblem.current_viewer_user.id,
          isPage: true
        });
      }
    },
    getInfoProblem() {
      if (!this.currentInfoProblem || this.currentInfoProblem.id !== this.$route.params.id) {
        this.getProblemInfoData(this.$route.params.id)
          .then((res) => {
            this.setCurrentInfoProblem({ data: res.data.data });
            this.getHistoryProblemAction();
            this.getProblemCommentsAction(this.currentInfoProblem.id);
            this.isObserve = !!this.currentInfoProblem.current_viewer_user;
          })
          .catch((error) => {
            if (error.response?.data?.data?.error?.code === 404) {
              this.$router.replace({ name: "IspReview" });
              EventBus.$emit("showNotification", {
                type: "error",
                timeout: 5000,
                label: `Проблема не найдена!`
              });
            }
          });
        return false;
      }
      this.isObserve = !!this.currentInfoProblem.current_viewer_user;
      this.getHistoryProblemAction();
      this.getProblemCommentsAction(this.currentInfoProblem.id);
    },
    sendProblemToConfirm() {
      this.showConfirmModal = true;
      if (this.currentInfoProblem.status.type === "check") {
        this.confirmParams = {
          title: "Принять проблему?",
          description: "Вы уверены, что хотите принять проблему?",
          callbackName: () => {
            this.handleUpdateProblem({
              data: {
                flow_status_id: this.statusesList.find((item) => item.type === "done").id,
                status: this.statusesList.find((item) => item.type === "done"),
                reconciliation: true
              },
              oldPeriodId: this.currentInfoProblem.period_id
            });
            this.showConfirmModal = false;
          }
        };
      } else {
        this.confirmParams = {
          title: "Отправить проблему на согласование?",
          description: "Вы уверены, что хотите отправить проблему на согласование?",
          callbackName: () => {
            this.handleUpdateProblem({
              data: {
                flow_status_id: this.statusesList.find((item) => item.type === "check").id,
                status: this.statusesList.find((item) => item.type === "check"),
                reconciliation: true
              },
              oldPeriodId: this.currentInfoProblem.period_id
            });
            this.showConfirmModal = false;
          }
        };
      }
    },
    showRejectProblemModal(val) {
      this.showRejectModal = val;
    }
  },
  computed: {
    ...mapState("IspModule", [
      "currentInfoProblem",
      "filesLoader",
      "taskList",
      "statusesList",
      "currentProblemComments"
    ]),
    isEditField() {
      return (
        (this.isCustomer ||
          this.isResponsible ||
          this.isISPManager ||
          this.isGroupLeader ||
          this.isAuthorProblem) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    tabsList() {
      return [
        {
          label: "Проблема",
          value: "problem"
        },

        {
          label: "Комментарии",
          value: "comments",
          count: this.currentProblemComments.length ? this.currentProblemComments.length : ""
        },
        {
          label: "История",
          value: "history"
        }
      ];
    },
    isEditableStatus() {
      return (
        this.currentInfoProblem.status.type !== "done" &&
        this.currentInfoProblem.status.type !== "final"
      );
    },
    isArchived() {
      return this.currentInfoProblem.is_archived;
    },
    isAuthorProblem() {
      return this.currentInfoProblem.creator_user_id === this.getUserId;
    },
    isGroupLeader() {
      return this.currentInfoProblem.group.leader_user_id === this.getUserId;
    },
    isExecutor() {
      return this.currentInfoProblem.task_executors.some((item) => item.id === this.getUserId);
    },
    isCustomer() {
      return this.currentInfoProblem.customer_user.id === this.getUserId;
    },
    isResponsible() {
      return this.currentInfoProblem.responsible_user.id === this.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    showConfirmFooter() {
      return (
        this.currentInfoProblem.report &&
        this.taskList.length &&
        this.taskList.every((item) => item.is_complete === true) &&
        (this.currentInfoProblem.status.type === "in_work" ||
          this.currentInfoProblem.status.type === "rework") &&
        (this.isResponsible || this.isISPManager || this.isGroupLeader)
      );
    },
    showConfirmFooterCheck() {
      return (
        this.currentInfoProblem.report &&
        this.taskList.length &&
        this.taskList.every((item) => item.is_complete === true) &&
        this.currentInfoProblem.status.type === "check" &&
        (this.isCustomer || this.isISPManager)
      );
    },
    formatDateCreation() {
      return moment(this.currentInfoProblem.datetime_of_creation).format("DD.MM.YYYY");
    },
    formatDateCompleted() {
      return moment(this.currentInfoProblem.datetime_of_completion).format("DD.MM.YYYY");
    },
    setTitle() {
      return this.currentTab === "comments" ? "" : "Все изменения";
    },
    setActionList() {
      const list = this.actionList.map((item) => item);
      list.push({
        isShow: (this.isGroupLeader || this.isISPManager) && !this.currentInfoProblem.is_archived,
        iconType: "compare-arrows",
        actionText: "Переместить",
        actionType: "move"
      });
      list.push({
        isShow:
          (this.isGroupLeader || this.isISPManager) &&
          !this.currentInfoProblem.children_problems.length,
        iconType: "copy",
        actionText: "Дублировать",
        actionType: "copy"
      });

      if (this.currentInfoProblem.is_archived) {
        list.push({
          isShow: this.isISPManager,
          iconType: "archive",
          actionText: "Разархивировать",
          actionType: "archive"
        });
      } else {
        list.push({
          isShow: this.isISPManager,
          iconType: "archive",
          actionText: "Архивировать",
          actionType: "archive"
        });
      }
      if (this.currentInfoProblem.period_id) {
        list.push({
          isShow: this.isGroupLeader || this.isISPManager,
          iconType: "arrow-enter",
          actionText: "Вернуть в бэклог",
          actionType: "backlog"
        });
      }
      list.push({
        isShow: this.isISPManager && !this.currentInfoProblem.children_problems.length,
        iconType: "delete",
        actionText: "Удалить проблему",
        actionType: "delete"
      });

      return list;
    },
    showList() {
      return this.setActionList.some((item) => item.isShow === true);
    }
  },
  watch: {
    $route() {
      this.getInfoProblem();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.problem-bg {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $surface-default;
  height: calc(100% - 90px);
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px;
    border-bottom: 1px solid #eae8f0;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__container {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    width: 100%;
  }
  &__title {
    margin: 0;
    font-weight: 900;
    font-size: 26px;
    line-height: 26px;
    color: $text-default;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 22px 24px 21px;
    min-height: 70px;
    border-bottom: 1px solid #eae8f0;
  }
  &__observe {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    margin-left: 16px;
    span {
      margin-right: 10px;
    }
  }
  &__settings {
    margin-left: 48px;
    /deep/ {
      .action-list {
        &__modal-list {
          min-width: 220px;
          top: 25px;
          border-radius: 4px;
        }
      }
    }
  }
  &__problem-body {
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid #eae8f0;
  }
  &__date-create {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #a7a9c0;
    margin-right: 32px;
  }
  &__author {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #a7a9c0;
    margin-right: 24px;
  }
  &__closed {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #a7a9c0;
  }
  &__info {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    height: 100%;
    overflow-y: hidden;
  }
  &__tabs {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eae8f0;
    user-select: none;
    border-right: 1px solid #eae8f0;
    &_border {
      border-right: none;
    }
  }
  &__tabs {
  }
  &__tabs-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-right: 1px solid #eae8f0;
  }
  &__problem {
    flex-basis: 40%;
    flex-shrink: 0;
  }
  /deep/ {
    .tasks-body {
      height: 100%;
    }
    .tasks-body__wrap-header {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 100;
    }
    .history-body__list {
      height: 100%;
    }
    .comments-body__list {
      padding-top: 10px;
      height: 100%;
      flex-basis: 100%;
    }
    .comments-form {
      padding-bottom: 0;
    }
    .comments-body__form {
      background-color: #fff;
      margin-top: auto;
    }
  }
}
</style>
