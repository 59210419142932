<template>
  <div class="salary-picker" @click.self="handleClose">
    <div class="salary-picker__modal">
      <h2 class="salary-picker__title">{{ title }}</h2>
      <div class="salary-picker__body">
        <Label label="Дата" />
        <DateRangePicker
          v-model="range"
          :range="range"
          :columns="1"
          :minDate="minDate"
          :maxDate="maxDate"
        />
      </div>
      <div class="salary-picker__footer">
        <Button title="Отмена" type="cancel" @handleButton="handleClose" />
        <Button title="Выгрузить" @handleButton="handleAdd" :loader="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";
import Label from "@/modules/Isp/components/IspProblem/Label";
import moment from "moment";
import { EventBus } from "@/main";

export default {
  name: "TaskExportModal",
  mixins: [],
  props: {
    date: {
      type: String,
      default: moment().format("YYYY-MM")
    },
    loader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  components: { DateRangePicker, Label, Button },
  data() {
    return {
      range: {
        start: null,
        end: null
      }
    };
  },
  mounted() {
    window.addEventListener("keydown", this.keyEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyEvent);
  },
  methods: {
    keyEvent(e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
    handleAdd() {
      if (this.checkValidation)
        this.$emit("handleAdd", {
          date_from: moment(this.range.start).format("YYYY-MM-DD"),
          date_to: moment(this.range.end).format("YYYY-MM-DD")
        });
    }
  },
  computed: {
    checkValidation() {
      if (!moment(this.range.start).isValid()) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Дата начала не указана или не верна"
        });
        return false;
      }
      if (!moment(this.range.end).isValid()) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Дата окончания не указана или не верна"
        });
        return false;
      }
      return true;
    },
    minDate() {
      return new Date(moment(`${this.date}-01`).subtract(1, "months").format("YYYY-MM-DD"));
    },
    maxDate() {
      return this.date ? new Date(moment().endOf(this.date).format("YYYY-MM-DD")) : null;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.salary-picker {
  position: fixed;
  background-color: $background-modal;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    max-width: 504px;
    width: 100%;
    display: flex;
    background-color: #fff;
    box-shadow: 3px 0 24px rgba(120, 121, 150, 0.6);
    border-radius: 16px;
    padding: 32px 24px 40px;
    z-index: 100;
    flex-flow: column;
  }

  &__title {
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
    padding: 0;
    margin: 0;
  }

  &__body {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  /deep/ {
    .calendar__wrap {
      margin-bottom: 0;

      input {
        padding-right: 24px;
      }
    }

    .calendar .vc-container {
      //position: absolute;
      z-index: 10;
      //max-width: 576px;
    }
  }
}
</style>
