


























import { Component, Prop, Vue } from "vue-property-decorator";
import { generateTimeArr } from "@/aopV2/helpers/timeGenerate";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput";
import { isNumber } from "@/helpers/helperFunction";

@Component<InputTime>({
  components: { TextInput, ActionList },
  watch: {
    value() {
      this.selected = this.value;
    }
  }
})
export default class InputTime extends Vue {
  @Prop({ type: Boolean, default: false }) isSecond!: boolean;
  @Prop({ type: String, default: "time-list", required: true }) unique!: string;
  @Prop({ type: String, default: "", required: true }) value!: string;
  @Prop({ type: Array, default: ["##:##"] }) mask!: Array<string>;
  @Prop({ type: RegExp, default: new RegExp("[0-9]{2}:[0-9]{2}") }) regExpDate!: RegExp;
  selected: string = "";
  isOpenSelectList: boolean = false;
  times: string[] = [];

  container: null | Element = null;

  mounted() {
    this.timeGenerate();
    this.selected = this.value;
  }

  get isValid() {
    return this.selected.length === 5 && !this.selected.includes("_");
  }

  inputTime(event) {
    if (event.target.value.length) {
      this.selected = this.timeValidate(event.target.value);
      this.$emit("handleValid", this.isValid);
      if (this.isValid) {
        this.$emit("change", this.selected);
        this.scrollToSelectTime();
      }
    } else {
      this.selected = "";
    }
  }

  selectOpen() {
    this.isOpenSelectList = !this.isOpenSelectList;
    if (this.isOpenSelectList) {
      this.scrollToSelectTime();
    }
  }

  closeSelectList() {
    this.isOpenSelectList = false;
  }

  handleItem(item) {
    this.selected = item;
    this.closeSelectList();
    this.$emit("change", this.selected);
    this.$emit("handleValid", this.isValid);
  }

  isValidTime(time) {
    const [hh, mm] = time.split(":");
    return isNumber(+mm) && isNumber(+hh);
  }

  timeValidate(time) {
    let [hh, mm] = time.split(":");
    if (+hh > 23) {
      hh = 23;
    }
    if (+mm > 59) {
      mm = 59;
    }
    return `${hh}:${mm}`;
  }

  scrollToSelectTime() {
    if (this.isValidTime(this.selected)) {
      const [hh, mm] = this.selected.split(":");
      let time = `${hh}:${mm}`;
      if (+mm > 0 && +mm < 30) {
        time = `${hh}:00`;
      } else if (+mm > 30 && +mm < 60) {
        time = `${hh}:30`;
      }
      this.container = this.$el.querySelector(`#${this.unique}`);
      const items = [...this.container?.children!];
      const el = items.find((item) => item.id === `action-item_${time}`);
      if (el)
        this.$nextTick(() => {
          this.$scrollTo(el!, 0, {
            container: this.container!,
            easing: "ease-in",
            offset: 0,
            force: true,
            cancelable: true,
            x: false,
            y: true
          });
        });
    }
  }

  timeGenerate() {
    this.times = generateTimeArr(30, 23);
  }
}
