<template>
  <div class="kanban-setting__column column" :id="`column_${column.id}`">
    <div class="column__skeleton" v-if="loader"></div>
    <div
      v-if="!loader"
      class="column__header"
      v-click-outside="confirmEditColumn"
      :style="isEdit ? 'padding: 0;' : ''"
    >
      <h4 class="column__name" v-if="!isEdit">{{ column.name }}</h4>
      <Input
        v-if="isEdit"
        v-model="name"
        :minlength="1"
        :maxlength="20"
        :id="`input_${column.id}`"
        :placeholder="'Введите название столбца'"
      />
      <div class="column__control" v-if="!isEdit">
        <button
          class="column__button column__button_edit"
          type="button"
          :id="`button_${column.id}`"
          aria-label="Редактировать название колонки"
          @click="toggleEditColumn"
        >
          <i class="icon-edit"></i>
        </button>
        <button
          class="column__button column__button_delete"
          type="button"
          aria-label="Удалить колонку"
          @click="confirmDeleteColumn"
        >
          <i class="icon-delete"></i>
        </button>
      </div>
      <div class="column__control column__control_edit" v-if="isEdit">
        <button
          class="column__button column__button_check"
          type="button"
          aria-label="Подтвердить редактирование названия колонки"
          @click="editColumn"
        >
          <i class="icon-check"></i>
        </button>
        <button
          class="column__button column__button_clear"
          type="button"
          aria-label="Отменить редактирование"
          @click="confirmCloseColumn"
        >
          <i class="icon-cross"></i>
        </button>
      </div>
    </div>
    <ul class="column__list" :id="column.id">
      <li class="column__empty" v-if="!column.status_links.length">
        Этот столбец не будет отображаться на доске без статусов
      </li>
      <draggable
        class="column__draggable"
        :list="column.status_links"
        :style="!column.status_links.length ? 'height: calc(100% - 30px)' : ''"
        group="statuses"
        @start="drag = true"
        @end="changeStatusColumn($event, drag)"
      >
        <KanbanStatus
          v-for="status in column.status_links"
          :key="status.flow_status"
          :status="status"
        />
      </draggable>
    </ul>
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="hideConfirmModal"
      @accept="confirmParams.callbackName"
    />
  </div>
</template>

<script>
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import KanbanStatus from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanStatus";
import draggable from "vuedraggable";
import Input from "@/modules/UiKit/components/Inputs/Input";
import ClickOutside from "vue-click-outside";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "KanbanSettingColumn",
  mixins: [],
  props: {
    column: {
      type: Object,
      default: () => {}
    },
    createMode: {
      type: Boolean,
      default: false
    }
  },
  components: { Input, KanbanStatus, draggable, ConfirmModal },
  data() {
    return {
      name: "",
      showConfirmModal: false,
      confirmParams: null,
      isEdit: false,
      oldBoardColumnId: 0
    };
  },
  mounted() {},
  methods: {
    deleteColumn() {
      KanbanModule.deleteBoardColumnAction(this.column);
      this.hideConfirmModal();
    },
    editColumn() {
      if (!this.validationName(this.name.trim())) return;
      if (this.createMode) {
        this.$emit("createColumn", this.name);
        this.hideEdit();
      } else {
        KanbanModule.updateBoardColumnAction({
          name: this.name.trim(),
          id: this.column.id
        }).then(() => {
          this.hideEdit();
        });
      }
    },
    toggleEditColumn() {
      this.isEdit = true;
      this.setColumnName(this.column.name);
      this.$nextTick(() => {
        const input = document.getElementById(`input_${this.column.id}`);
        if (input) input.focus();
      });
    },
    confirmEditColumn(evt) {
      if (this.isChange) {
        this.showConfirmModal = true;
        this.confirmParams = {
          title: "Продолжить?",
          description: "Введенные данные не будут сохранены",
          callbackName: () => this.hideEdit()
        };
      } else {
        this.hideEditColumn(evt);
      }
    },
    hideEditColumn(evt) {
      const path = evt.path || evt.composedPath();
      const isEdit = path.some((item) => {
        if (item.id) {
          return item.id === `column_${this.column.id}`;
        }
      });
      if (!isEdit) {
        this.hideEdit();
      }
    },
    confirmCloseColumn() {
      if (this.isChange) {
        this.showConfirmModal = true;
        this.confirmParams = {
          title: "Продолжить?",
          description: "Введенные данные не будут сохранены",
          callbackName: () => this.hideEdit()
        };
      } else {
        this.hideEdit();
      }
    },
    hideEdit() {
      this.isEdit = false;
      this.setColumnName("");
      this.hideConfirmModal();
      if (this.createMode) this.$emit("hideCreate");
    },
    setColumnName(val) {
      this.name = val;
    },
    hideConfirmModal() {
      this.showConfirmModal = false;
      this.confirmParams = null;
    },
    confirmDeleteColumn() {
      this.confirmParams = {
        title: "Удалить столбец?",
        description: "Вы уверены, что хотите удалить выбранный столбец?",
        callbackName: () => this.deleteColumn()
      };
      this.showConfirmModal = true;
    },
    validationName(val) {
      if (!val.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название столбца!"
        });
        return false;
      }
      if (val.length > 20) {
        NotificationObserver.notification({
          type: "error",
          message: "Название столбца не должно превышать 20 символов!"
        });
        return false;
      }
      return true;
    },
    changeStatusColumn(event, drag) {
      drag = false;
      const newBoardColumnId = Number(event.to.parentElement.id);
      const oldBoardColumnId = Number(event.from.parentElement.id);
      const statusId = event.item.id;
      if (oldBoardColumnId !== newBoardColumnId) {
        KanbanModule.deleteBoardColumnStatusAction({
          board_column_id: this.column.id,
          flow_status: statusId
        });
      }
      if (newBoardColumnId && newBoardColumnId !== this.column.id) {
        KanbanModule.createBoardColumnStatusAction({
          board_column_id: newBoardColumnId,
          flow_status: statusId
        });
      }
    }
  },
  computed: {
    loader() {
      return KanbanModule.boardLoader;
    },
    isChange() {
      return this.name.trim() !== this.column.name && this.isEdit;
    }
  },
  watch: {
    createMode() {
      if (this.column.id === null) this.isEdit = this.createMode;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/Skeleton.mixin.scss";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.column {
  min-width: 320px;
  width: 320px;
  height: 100%;

  &:hover .column__list {
    background: $background-selected;
  }

  &__skeleton {
    width: 100%;
    height: 40px;
    margin: 0 0 24px;
    border-radius: 4px;
    @include background-gradient();
  }

  &__header {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 0 0 24px;
    padding: 8px;
    display: flex;
    align-items: center;
    background: $surface-default;
    border: 2px solid $border-disabled;
    border-radius: 4px;
  }

  /deep/ {
    .input {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .input__control {
      height: 100%;
      padding: 5px 50px 6px 6px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $text-default;
      border: 2px solid #fafafe;

      &:focus {
        border: 2px solid #6a67ce;
      }
    }
  }

  &__name {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-default;
    @include textEllipsis(1);
  }

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;

    &_edit {
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 2;
    }
  }

  &__button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;

    &_edit {
      margin-left: auto;
    }

    &_delete {
      margin-left: 8px;
    }

    i {
      color: $icon-subdued;
      font-size: 20px;

      &:hover,
      &:focus {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    &_check,
    &_clear {
      i {
        font-size: 23px;
      }
    }
  }

  &__list {
    width: 100%;
    height: calc(100% - 100px);
    background: $secondary;
    border-radius: 8px;
    padding: 24px;
    margin: 0;
    overflow-y: scroll;
    list-style: none;
    transition: all 0.3s ease;
  }

  &__draggable {
    height: 100%;
  }

  &__empty {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $text-disabled;
  }
}
</style>
