const mutationsIspTeams = {
  TOGGLE_CURTAIN_CREATE_TEAM(state, payload) {
    state.curtainCreateTeam = payload;
  },
  TOGGLE_SHOW_ISP_TEAM_INFO(state, payload) {
    state.showIspTeamInfo = payload;
  },
  SET_CURRENT_TEAMS(state, payload) {
    state.currentTeams = payload;
  },
  // PUSH_TO_CURRENT_TEAMS(state, payload) {
  //   state.currentTeams = [...state.currentTeams, ...payload];
  // },
  // SET_TOTAL_COUNT(state, payload) {
  //   state.totalCount = payload;
  // },
  // SET_TEAMS_COUNTER(state, payload) {
  //   state.teamsCounter = payload;
  // },
  TOGGLE_LOADER_TEAMS(state, payload) {
    state.loaderTeams = payload;
  },
  // TOGGLE_LOADER_TEAMS_SCROLL(state, payload) {
  //   state.loaderTeamsScroll = payload;
  // },
  SET_CURRENT_TEAM(state, payload) {
    state.currentTeam = payload;
  },
  SET_ALL_USERS(state, payload) {
    state.allUsers = payload;
  }
};

export default mutationsIspTeams;
