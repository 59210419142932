<template>
  <div class="data-picker" :class="alt ? 'data-picker_alt' : ''">
    <i class="icon-calendar-secondary" v-if="showIcon"></i>
    <div class="data-picker__label">{{ label }}</div>
    <date-pick
      v-model="selectedDate"
      :inputAttributes="{ placeholder: getNowDate, readonly: readonly }"
      :isDateDisabled="isDateDisabled"
      :editable="editable"
      v-bind="{
        mobileBreakpointWidth: mobileBreakpointWidth,
        displayFormat: displayFormat,
        format: format,
        weekdays: weekdays,
        months: months,
        nextMonthCaption: nextMonthCaption,
        prevMonthCaption: prevMonthCaption
      }"
    >
      <template v-slot:default="{ toggle, inputValue }" v-if="isIcon">
        <button @click="toggle" class="data-picker__buttons">
          <i class="icon-calendar-secondary"></i>
        </button>
      </template>
    </date-pick>
  </div>
</template>

<script>
const DatePick = () => import("vue-date-pick");
import "vue-date-pick/dist/vueDatePick.css";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "InputDatePicker",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: String,
      default: null
    },
    clearAll: {
      type: Boolean,
      default: false
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isDateDisabled: {
      type: [String, Number]
    },
    mobileBreakpointWidth: {
      type: Number,
      default: 1920
    },
    label: {
      type: [String, Number],
      default: ""
    },
    format: {
      type: [String, Number],
      default: "YYYY-MM-DD"
    },
    displayFormat: {
      type: [String, Number],
      default: "YYYY-MM-DD"
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      default: false
    }
  },
  components: { DatePick },
  data() {
    return {
      value: "",
      selectedDate: format(new Date(), "Y-MM-dd"),
      weekdays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      months: [
        "Январь, ",
        "Февраль, ",
        "Март, ",
        "Апрель, ",
        "Май, ",
        "Июнь, ",
        "Июль, ",
        "Август, ",
        "Сентябрь, ",
        "Октябрь, ",
        "Ноябрь, ",
        "Декабрь, "
      ],
      nextMonthCaption: "Следующий месяц",
      prevMonthCaption: "Предыдущий месяц"
    };
  },
  beforeDestroy() {
    this.$root.$off(`date:child-change_${this.unique}`);
  },
  mounted() {
    if (this.editValue) {
      this.selectedDate = this.editValue;
      this.value = this.editValue;
    }
    if (this.clearAll) {
      this.selectedDate = "";
    }
    this.$root.$on(`date:child-change_${this.unique}`, (value) => {
      this.value = value;
      this.selectedDate = value;
    });
  },
  methods: {
    Change__Input__Date() {
      this.value = this.selectedDate;
      const data = {
        eventName: "Change__Input__Date",
        data: {
          value: this.value,
          unique: this.unique
        }
      };
      this.$emit("changeDate", this.value);
      this.$root.$emit(`date:change_${this.unique}`, this.value);
    }
  },
  computed: {
    getNowDate() {
      return format(new Date(), "dd MMM yyyy", {
        locale: ru
      });
    }
  },
  watch: {
    selectedDate() {
      this.Change__Input__Date();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.data-picker {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 12px 18px;
  border-radius: 24px;
  cursor: pointer;
  background: #fafbfc;
  &_alt {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    height: 28px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    outline: none;
    transition: all 0.2s ease;
    max-width: 118px;
    padding: 0;
    .vdpComponent.vdpWithInput {
      /deep/ {
        .vdpOuterWrap.vdpFloating {
          top: 35px;
          z-index: 10;
        }
        .vdpOuterWrap.vdpFloating.vdpPositionFixed {
          top: 0;
        }
        input {
          max-width: 118px;
          width: 100%;
          height: 28px;
          border-radius: 4px;
          padding: 4px 4px 3px;
          color: $text-default;
          border-bottom: 1px solid transparent;
          transition: border-bottom-color 0.3s ease;
          &:hover {
            background-color: $system-grey-three;
            border-bottom: 1px solid $bg-gray-400;
          }
          &:focus {
            background: $system-grey-three;
            border-bottom: 1px solid $action-primary;
          }
        }
        input[readonly] {
          &:focus,
          &:hover {
            background: transparent;
            border-bottom: 1px solid transparent;
          }
        }
        .vdpClearInput {
          display: none;
          height: 28px;
          width: 28px;
          outline: none;
          background: transparent;
          &::before {
            background-color: transparent;
          }
        }
      }
    }
  }
  i {
    color: $text-disabled;
    margin-right: 8px;
  }

  &__label {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-subdued;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.vdpComponent {
  width: 100%;
}

.vdpComponent.vdpWithInput {
  /deep/ {
    input {
      padding: 7px 7px 4px 7px;
      font-weight: 400;
      background-color: transparent;
      width: 100%;
      font-size: 14px;
      line-height: 28px;
      color: $text-disabled;
      border: none;
      outline: none;
    }

    .vdpClearInput {
      outline: none;
      background: #fafbfc;

      &:hover {
        &::before {
          box-shadow: none;
          transform: scale(0.95);
        }
      }

      &::before {
        content: "";
        width: 15px;
        height: 15px;
        box-shadow: none;
        background-color: #fafbfc;
        top: 50% !important;
        left: 70% !important;
        background-image: url("~@/assets/img/icons/clearInputDate.svg");
        border-radius: unset;
        border: none;
        transition: all 0.2s ease;
      }
    }
  }
}
/deep/ {
  input {
    &::placeholder {
      color: $text-subdued;
    }
  }

  .vdpOuterWrap {
    color: $text-subdued;
    box-shadow: 2px 4px 4px 0 rgba(236, 236, 249, 0.5);
    &.vdpPositionFixed {
      z-index: 1000;
    }
    &.vdpFloating {
      padding: 0;
    }

    .vdpInnerWrap {
      width: 100%;
      min-width: 290px;
      max-width: 290px;
      background: $surface-default;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 14px;

      .vdpHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $surface-default;

        .vdpArrow {
          &::before {
            display: none;
          }
        }

        .vdpArrowPrev {
          &::after {
            left: 70%;
            top: 38%;
            border: none;
            width: 2em;
            height: 2em;
            background-image: url(~@/assets/img/icons/arrowDataPickerPrev.svg);
            background-repeat: no-repeat;
          }
        }

        .vdpArrowNext {
          &::after {
            left: 35%;
            top: 38%;
            border: none;
            width: 2em;
            height: 2em;
            background-image: url(~@/assets/img/icons/arrowDataPickerNext.svg);
            background-repeat: no-repeat;
          }
        }

        .vdpPeriodControls {
          margin-bottom: 31px;
          .vdpPeriodControl {
            > button {
              margin-top: 23px;
              padding: 0;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: $text-default;
            }

            > select {
              color: $text-subdued;
              background-color: $color-elements-background;
              font-family: SF, sans-serif;
              font-size: 13px;
              border: 1px solid $color-contour;
              //вот тут
              option {
                font-family: SF, sans-serif;
              }
            }
          }
        }
      }

      .vdpTable {
        thead {
          border: none;

          tr {
            th {
            }
          }

          .vdpHeadCell {
            padding: 0;

            .vdpHeadCellContent {
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: $text-subdued;
            }
          }
        }

        .vdpRow {
          .vdpCell {
            @media (max-height: $xs) {
              padding: 0.3em 0;
            }
            &:hover {
              .vdpCellContent {
                color: $surface-default;
                background-color: $action-primary-hovered;
              }
            }

            &.today {
              .vdpCellContent {
                color: $text-default;
                background-color: $surface-default;

                &:hover {
                  color: $surface-default;
                }
              }
            }

            &.selected {
              &.today {
                .vdpCellContent {
                  color: $surface-default;
                }
              }

              .vdpCellContent {
                color: $surface-default;
                background-color: $action-primary;
              }
            }

            .vdpCellContent {
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: $text-default;

              @media screen and (max-width: 850px) and (max-height: 450px) {
                width: 30px;
                height: 30px;
              }

              @media screen and (max-width: 568px) and (max-height: 450px) {
                width: 25px;
                height: 25px;
              }

              &:hover {
                color: $surface-default;
                background-color: $action-primary-hovered;
              }
            }
          }

          .vdpCell.outOfRange .vdpCellContent {
            color: $text-disabled !important;
          }
        }
      }
    }
  }
}
</style>
