<template>
  <div class="profile__education">
    <EducationCard
      v-for="item in dataUserEducation"
      :key="item.id"
      :item="item"
      :userCard="userCard"
      :isAdmin="isAdmin"
      @goGetInfoUser="initialEducation"
    />
    <div class="profile__add-card" v-if="isAdmin && userCard">
      <button
        class="profile__create"
        type="button"
        @click="changeEducationCreateAndEditModal(true, null)"
      >
        <i class="icon-plus"></i>Добавить образование
      </button>
    </div>
    <div class="empty-education" v-if="!dataUserEducation.length && !isAdmin && !loader">
      Не заполнено :( <br />
      Обратитесь к специалистам из<br />
      отдела кадров
    </div>
    <EducationCreateAndEditModal
      v-if="isEducationCreateAndEditModal"
      :editValue="editValue"
      @goGetInfoUser="initialEducation"
      @hideModal="changeEducationCreateAndEditModal"
    />
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import EducationCard from "@/components/ProfileComponents/ProfileEducation/EducationCard";
import EducationCreateAndEditModal from "@/components/ProfileComponents/ProfileEducation/EducationCreateAndEditModal";
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ProfileEducation",
  mixins: [],
  props: [],
  components: { EducationCreateAndEditModal, EducationCard },
  data() {
    return {
      dataUserEducation: [],
      userCard: false,
      isEducationCreateAndEditModal: false,
      editValue: null,
      loader: true
    };
  },
  beforeDestroy() {
    this.$root.$off("goGetInfoUser");
  },
  mounted() {
    this.$root.$on("changeEducationUser", (val) => {
      this.changeEducationCreateAndEditModal(true, val);
      this.editValue = val;
    });
  },
  methods: {
    initialEducation() {
      if (!this.$route.params.user_id && this.getUserId) {
        this.getUserEducationInfo(this.getUserId);
        this.userCard = false;
      } else if (this.getUserId) {
        this.getUserEducationInfo(this.$route.params.user_id);
        this.userCard = true;
      }
    },
    /**
     * Getting a list of user Educations
     * @param id - User ID by which we receive information
     */
    getUserEducationInfo(id) {
      httpClient({
        method: "GET",
        url: `/UserEducation/getItems`,
        params: {
          user_id: id,
          _order: { to_year: "desc" }
        }
      })
        .then((response) => {
          this.dataUserEducation = response.data.data.items;
          this.loader = false;
        })
        .catch((error) => this.errorAlert(error));
    },
    changeEducationCreateAndEditModal(show, value) {
      this.isEducationCreateAndEditModal = show;
      this.editValue = value;
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isAdmin() {
      return this.getEndpoints("User", "update");
    }
  },
  watch: {
    getUserId: {
      handler() {
        this.initialEducation();
      },
      immediate: true
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.profile {
  &__education {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 20px);
    overflow: scroll;
    @media screen and (max-width: $md) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__add-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 509px;
    height: 313px;
    border: 2px dashed $border-default;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.6);

    @media screen and (max-width: $md) {
      width: 100%;
    }

    @media screen and (max-width: $sm) {
      min-height: 250px;
    }
  }

  &__create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 40px;
    border: 1px solid $border-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    outline: none;
    padding: 5px 12px;
    color: $text-subdued;
    box-shadow: none;

    &:hover {
      color: $text-default;
      background: $action-secondary-hovered;
    }

    &:active {
      color: $text-default;
      background: $action-secondary-pressed;
    }

    i {
      margin-right: 8px;
    }
  }

  .empty-education {
    display: flex;
    width: 100%;
    height: 70vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-subdued;
  }
}
</style>
