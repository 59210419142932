import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";

class AbsenceService extends Service {
  getTypes(): Response<any> {
    return httpClient.get<any>("timemanager/absence_types/get_all").then((resp) => {
      return resp.data;
    });
  }

  getAbsenceList(params): Response<any> {
    return httpClient.get<any>("timemanager/absences/get_all", { params }).then((resp) => {
      return resp.data;
    });
  }

  deleteAbsence(params): Response<any> {
    return httpClient.delete<any>("timemanager/absences/delete", { params }).then((resp) => {
      return resp.data;
    });
  }

  createAbsence(data): Response<any> {
    return httpClient.post<any>("timemanager/absences/create", data).then((resp) => {
      return resp.data.id;
    });
  }

  updateAbsence(data): Response<any> {
    return httpClient.patch<any>("timemanager/absences/update", data).then((resp) => {
      return resp.data;
    });
  }

  exportSalary(data: { date_from: string; date_to: string }): Response<any> {
    return httpClient.post<any>("timemanager/exports/for_salary", data).then((resp) => {
      return resp.data.channel;
    });
  }

  exportByProject(data: { month: string }): Response<any> {
    return httpClient.post<any>("timemanager/exports/by_projects", data).then((resp) => {
      return resp.data.channel;
    });
  }
}

export default new AbsenceService();
