





































import { Component, Prop, Vue } from "vue-property-decorator";
import NotificationObserver from "@/helpers/UserNotification";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput.vue";

interface ITime {
  start: string;
  end: string;
}

@Component({
  components: {
    TextInput
  }
})
export default class InputTimeRange extends Vue {
  @Prop({ type: Object, default: () => null }) time!: ITime | null;
  @Prop({ type: Number, default: 360 }) step!: number;
  @Prop({ type: String, default: "time-list", required: true }) unique!: string;
  @Prop({ type: Array, default: ["##:##"] }) mask!: Array<string>;

  start: string = "";
  end: string = "";
  regExp: RegExp = new RegExp("^[0-1][0-9]|2[0-3]:[0-5][0-9]$")

  mounted() {
    if (this.time) {
      this.start = this.time.start;
      this.end = this.time.end;
    }
  }

  setStartTime(evt) {
    this.$emit("setStartTime", this.setTime(evt, this.time?.start));
    this.start = this.setTime(evt, this.time?.start);
  }

  setStopTime(evt) {
    this.$emit("setEndTime", this.setTime(evt, this.time?.end));
    this.end = this.setTime(evt, this.time?.end);
  }

  setTime(evt: any, oldTime: string | undefined) {
    let time: string | undefined;
      const selectTime = this.timeValidate(evt.target.value);
      let [hh, mm] = selectTime.split(":");
      if (Number(mm) > 30) {
        const hours = Number(hh) + 1;
        time = hours > 10 ? hours + ":00" : "0" + hours + ":00";
      } else {
        time = hh + ":00";
      }
      if (!this.validation() && oldTime) {
        evt.target.value = oldTime;
        time = oldTime;
      } else {
        evt.target.value = time;
      }
      return time;
  }

  timeValidate(time) {
    let [hh, mm] = time.split(":");
    if (+hh > 23) {
      hh = 23;
    }
    if (+mm > 59) {
      mm = 59;
    }
    return `${hh}:${mm}`;
  }

  validation() {
    if (!this.start.length) {
      NotificationObserver.notification({
        type: "error",
        message: "Введите время начала!"
      });
      return false;
    }
    if (!this.end.length) {
      NotificationObserver.notification({
        type: "error",
        message: "Введите время окончания!"
      });
      return false;
    }
    if (!this.regExp.test(this.start) || !this.regExp.test(this.end)) {
      NotificationObserver.notification({
        type: "error",
        message: "Не верный формат времени!"
      });
      return false;
    }
    const start = this.start.split(":");
    const end = this.end === "00:00" ? ["24", "00"] : this.end.split(":");
    const startHours = new Date().setHours(Number(start[0]), Number(start[1]), 0);
    const endHours = new Date().setHours(Number(end[0]), Number(end[1]), 0);
    if (endHours < startHours) {
      NotificationObserver.notification({
        type: "error",
        message: "Время начала должно быть превышать время окончания!"
      });
      return false;
    }
    return true;
  }
}
