import firebase from "firebase/compat";
import "firebase/messaging";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAKWo64zYBBKChNSPEx8Z9jG1ZlqO1cS2Y",
  authDomain: "sw-aop.firebaseapp.com",
  projectId: "sw-aop",
  storageBucket: "sw-aop.appspot.com",
  messagingSenderId: "574586356139",
  appId: "1:574586356139:web:3cec88103517c70b9a12ee",
  measurementId: "G-J2LNJ779BB"
});
const messaging = app.messaging();
export default messaging;
