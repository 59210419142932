import moment from "moment";

const state = {
  usersList: [],
  showConfirmModal: false,
  isWorking: false,
  isPerson: true,
  dataCalendarEvent: null,
  createModeCalendarEvent: false,
  event: null,
  tooltipX: 0,
  tooltipY: 0,
  showTooltip: false,
  loaderEvent: false,
  loaderRepeat: false,

  // Calendar params
  calendarParams: {
    _event_type: null,
    _user: null,
    _calendar_location_id: null
  },
  year: new Date().getFullYear(),
  weekNumber: moment(new Date()).isoWeek(),
  monthNumber: 1,
  date: moment(new Date()).format("YYYY-MM-DD"),
  lastYear: new Date().getFullYear(),
  weekCount: moment().weeksInYear(),

  // Grid
  week: [],
  isShowWeekends: false,
  workingTime: {
    start_time: "08:00:00",
    end_time: "24:00:00"
  },
  showWorkingTimeModal: false,
  showMobileLocationModal: false,
  showMobileEventsModal: false,
  showMobileMembersModal: false,
  // Events
  currentEvent: null,
  eventResponseId: 0,
  showCalendarEventModal: false,
  showCalendarCreateEventModal: false,
  showCalendarCrossEventModal: false,
  showRepeatConfirmModal: false,
  crossResponse: null,
  crossMembersUsers: [],
  crossEvents: [],
  eventParams: null,
  timeDefaultStart: moment().format("HH:mm"),
  timeDefaultEnd: moment().add(30, "minutes").format("HH:mm"),
  dateEventDefault: moment().format("YYYY-MM-DD"),
  dataInfoMemberUser: null,

  // Location
  location: null,
  locationList: [],
  showLocationModal: false,

  timeList: [
    { start_time: "00:00:00", end_time: "01:00:00" },
    { start_time: "01:00:00", end_time: "02:00:00" },
    { start_time: "02:00:00", end_time: "03:00:00" },
    { start_time: "03:00:00", end_time: "04:00:00" },
    { start_time: "04:00:00", end_time: "05:00:00" },
    { start_time: "05:00:00", end_time: "06:00:00" },
    { start_time: "06:00:00", end_time: "07:00:00" },
    { start_time: "07:00:00", end_time: "08:00:00" },
    { start_time: "08:00:00", end_time: "09:00:00" },
    { start_time: "09:00:00", end_time: "10:00:00" },
    { start_time: "10:00:00", end_time: "11:00:00" },
    { start_time: "11:00:00", end_time: "12:00:00" },
    { start_time: "12:00:00", end_time: "13:00:00" },
    { start_time: "13:00:00", end_time: "14:00:00" },
    { start_time: "14:00:00", end_time: "15:00:00" },
    { start_time: "15:00:00", end_time: "16:00:00" },
    { start_time: "16:00:00", end_time: "17:00:00" },
    { start_time: "17:00:00", end_time: "18:00:00" },
    { start_time: "18:00:00", end_time: "19:00:00" },
    { start_time: "19:00:00", end_time: "20:00:00" },
    { start_time: "20:00:00", end_time: "21:00:00" },
    { start_time: "21:00:00", end_time: "22:00:00" },
    { start_time: "22:00:00", end_time: "23:00:00" },
    { start_time: "23:00:00", end_time: "00:00:00" },
    { start_time: "24:00:00", end_time: "00:00:00" }
  ]
};

export default state;
