var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-background"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-content__header"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.dataModal.title))]),_c('i',{staticClass:"icon-cross",on:{"click":_vm.hideModalBugs}})]),(!_vm.isFromLayoutAuth)?_c('ul',{staticClass:"modal-content__feedback-type-list"},_vm._l((_vm.feedbackTypes),function(item){return _c('li',{staticClass:"modal-content__feedback-type",on:{"click":function($event){return _vm.handleType(item)}}},[_c('EventType',{attrs:{"event_type":item.feedbackType,"isActive":_vm.currentFeedbackType === item.feedbackType,"classes":item.icon}})],1)}),0):_vm._e(),_c('div',{staticClass:"modal-content__form"},[_c('label',[_vm._v(_vm._s(_vm.dataModal.placeholder))]),_c('TextInput',{attrs:{"isTextarea":true,"isEmoji":false,"type":'email',"classList":'font18',"placeholder":"Введите текст"},on:{"changeText":_vm.setTextValue,"pasteFile":_vm.pasteFile}}),_c('FileUploaderBugs',_vm._b({attrs:{"label":"Прикрепить файл"},on:{"changeFilesFromUploader":_vm.changeFilesFromUploader}},'FileUploaderBugs',{
          multiple: true,
          drop: true,
          dropDirectory: true,
          fileDrop: true,
          lengthText: 7,
          showOnlyFiles: true
        },false))],1),_c('div',{staticClass:"modal-content__footer"},[_c('ModalButton',{attrs:{"type":'cancel',"title":'Отменить'},on:{"handleButton":_vm.hideModalBugs}}),_c('ModalButton',{attrs:{"title":'Отправить'},on:{"handleButton":_vm.createChunksPoolFiles}})],1),(_vm.loading)?_c('div',{staticClass:"modal-content__loader"},[_c('Widget',{attrs:{"type":"loader-new"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }