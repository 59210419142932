












import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TimeSearch extends Vue {
  @Prop({
    type: String,
    default: "Поиск"
  })
  placeholder!: string;

  value = "";
}
