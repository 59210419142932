<template>
  <div class="year-picker-widget">
    <input
      @input.trim="Change__Date__Year($event)"
      :value="value"
      type="text"
      v-mask="'####'"
      :class="['year-picker-widget__input', value ? 'year-picker-widget__input_complete' : '']"
      placeholder="Год"
    />
    <i class="icon-cross" @click="clearInput"></i>
  </div>
</template>

<script>
export default {
  name: "YearPickerWidget",
  mixins: [],
  props: {
    editValue: {
      type: String,
      default: ""
    },
    unique: {
      type: String,
      default: ""
    },
    dateRange: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {
      value: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("setYear");
  },
  mounted() {
    this.$root.$on("setYear", () => {
      this.value = this.editValue;
    });
    if (this.editValue) {
      this.$root.$emit("setYear");
    }
  },
  methods: {
    Change__Date__Year(event) {
      this.value = event.target.value;
      let data = {
        eventName: "Change__Date__Year",
        data: {
          event: event,
          value: this.value,
          unique: this.unique
        }
      };
      this.$root.$emit(`year:change_${this.unique}`, this.value);
    },
    clearInput() {
      this.value = "";
      this.$root.$emit(`year:change_${this.unique}`, this.value);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.year-picker-widget {
  position: relative;
  width: 243px;
  height: 48px;
  background: #fafbfc;
  border-radius: 24px;

  &__input {
    width: 100%;
    height: 48px;
    padding-left: 15px;
    padding-right: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-default;
    background: transparent;
    outline: none;
    border: none;
    transition: all 0.2s ease;

    &::placeholder {
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-disabled;
    }
  }

  .icon-cross {
    position: absolute;
    color: $icon-subdued;
    font-size: 20px;
    top: 30%;
    right: 15px;
    cursor: pointer;
  }
}
</style>
