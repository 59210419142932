



















































































































import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList";
import ChipsItem from "@/aopV2/components/UIComponents/Chips/ChipsItem.vue";
import InputDate from "@/aopV2/components/UIComponents/Input/Date/InputDate.vue";
import InputTime from "@/aopV2/components/UIComponents/Input/Time/InputTime.vue";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import InputSelect from "@/aopV2/components/UIComponents/Input/Select/InputSelect.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import MemberPicker from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/FSBlocks/MemberPicker.vue";
import createEventMixin from "@/aopV2/modules/Calendar/helper/CreateEventMixin";
import { dataToEventAdapter, isValidEvent } from "@/aopV2/modules/Calendar/helper/helperFunc";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";

@Component<CreateEvent>({
  components: {
    MainButtonNew,
    MemberPicker,
    InputSelect,
    TextLabel,
    InputTime,
    InputDate,
    ChipsItem,
    ActionList,
    ButtonNewDesign,
    TextInput,
    ButtonIcon,
    MainModal
  }
})
export default class CreateEvent extends Mixins(createEventMixin) {
  mounted() {
    CalendarModule2.createPreCreationEvent(dataToEventAdapter(this.createData));
    this.$nextTick(() => {
      this.setPositionEvent();
    });
  }

  positionEvent = { y: 0, x: 0 };

  setPositionEvent(id: string = "#event_undefined", width: number = 580, height: number = 428) {
    const event = document.querySelector(id);
    event!.scrollIntoView({ block: "center" });
    const rect = event!.getBoundingClientRect();
    let y = rect.top - height / 2 + rect.height / 2;
    y = this.windowHeight - y - height > 20 ? y : this.windowHeight - height - 20;
    const x = rect.left - width > 0 ? rect.left - width : rect.left + rect.width;
    this.positionEvent = { y, x };
  }

  get isCenter() {
    return this.positionEvent.x === 0 && this.positionEvent.y === 0;
  }

  get windowHeight() {
    return this.$store.getters["MainLayoutSetting/height"];
  }

  async handleCreateEvent() {
    if (isValidEvent(this.createData)) {
      const event = dataToEventAdapter(this.createData);
      const isEmployment = await CalendarModule2.checkEmployment(event);
      if (!isEmployment) {
        CalendarModule2.toggleCreateLoader(true);
        await CalendarModule2.createEvent(event);
        CalendarModule2.toggleCreateLoader(false);
        this.$emit("hideModal");
      } else {
        CalendarModule2.showEmployment(true);
      }
    }
  }

  get isCreateLoader() {
    return CalendarModule2.isCreateLoader;
  }

  get eventIsEqual() {
    return CalendarModule2.eventIsEqual;
  }

  hideModal(e) {
    const paths = e.path || e.composedPath();
    const isClose = !paths.some(
      (item) => item.className === "confirmation-modal" || item.className === "modal-employment"
    );
    if (isClose)
      if (this.eventIsEqual) {
        this.$emit("hideModal");
      } else {
        ModalModule.toggleConfirmation(true);
        ModalModule.setConfirmSetting({
          title: "Закрыть окно?",
          text: "Все веденные данные будут сброшены. Закрыть?",
          handleCancel: () => ModalModule.toggleConfirmation(false),
          cancelButton: "Отменить",
          confirmButton: "Закрыть",
          handleConfirm: () => {
            this.$emit("hideModal");
            ModalModule.toggleConfirmation(false);
          }
        });
      }
  }
}
