<template>
  <div class="modal__container" @mousedown.self="closeCreateQuarter">
    <div class="modal">
      <div class="modal__header">
        <button class="modal__hide-btn" type="button" @click="closeCreateQuarter">
          <i class="icon-arrow-short"></i>
        </button>

        <h2 class="modal__title">{{ setTitle }}</h2>
      </div>

      <Input
        v-model="name"
        :label="'Название периода'"
        :maxlength="70"
        :placeholder="'Название периода'"
      />
      <div class="modal__wrap">
        <h3 class="modal__date-lable">Временной период</h3>
        <DateRangePicker v-model="range" :range="range" :columns="1" />
      </div>
      <div class="modal__wrap">
        <SwitchIOS
          v-model="addProblem"
          :value="addProblem"
          :label="'Добавление проблем в квартал'"
          :showInfo="true"
          @change="changeSwitch"
          :tooltip="tooltipSwitch"
        />
      </div>
      <h3 class="modal__subtitle">Установите вес критичности для проблем в текущем периоде:</h3>
      <div class="modal__wrap">
        <h4 class="modal__label">Блокирующая</h4>
        <InputRange v-model="blockedWeight" :value="blockedWeight" />
      </div>
      <div class="modal__wrap">
        <h4 class="modal__label">критическая</h4>
        <InputRange v-model="criticalityWeight" :value="criticalityWeight" />
      </div>
      <div class="modal__wrap">
        <h4 class="modal__label">значительная</h4>
        <InputRange v-model="largeWeight" :value="largeWeight" />
      </div>
      <div class="modal__wrap">
        <h4 class="modal__label">Комфорт</h4>
        <InputRange v-model="comfortWeight" :value="comfortWeight" />
      </div>
      <div class="modal__wrap modal__wrap_buttons">
        <Button :title="'Отменить'" :type="'cancel'" @handleButton="closeCreateQuarter" />
        <Button :title="setConfirmTitle" @handleButton="confirmActions" />
      </div>
    </div>
    <ConfirmModal
      v-if="confirmClose"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="cancelClose"
      @accept="acceptClose"
    />
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS";
import InputRange from "@/modules/UiKit/components/Inputs/InputRange";
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";
import { mapActions, mapMutations, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import moment from "moment";
import Button from "@/modules/UiKit/components/buttons/Button";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "IspReviewAddAndCreateQuarter",
  mixins: [],
  props: {},
  components: { ConfirmModal, Button, InputRange, SwitchIOS, Input, DateRangePicker },
  data() {
    return {
      name: "",
      addProblem: false,
      blockedWeight: 1,
      criticalityWeight: 1,
      largeWeight: 1,
      comfortWeight: 1,
      editValue: null,
      confirmClose: false,
      range: {
        start: new Date(),
        end: new Date()
      },
      tooltipSwitch:
        "При включенном тумблере можно добавлять проблемы в квартал. При выключенном — нельзя добавлять проблемы в квартал и изменять их критичность"
    };
  },
  mounted() {
    if (this.editQuarter) {
      this.editValue = this.currentQuarter;
      this.setEditValue();
    }
  },
  methods: {
    ...mapActions("IspModule", [
      "closeAddAndEditQuarterModal",
      "createISPQuarter",
      "editISPQuarter"
    ]),
    ...mapMutations("IspModule", ["SET_CRITICALITY_WEIGHT"]),
    setEditValue() {
      this.name = this.editValue.name ? this.editValue.name : "";
      this.addProblem = this.editValue.can_add_problems ? this.editValue.can_add_problems : false;
      this.range.start = this.editValue.start_date
        ? new Date(this.editValue.start_date)
        : new Date();
      this.range.end = this.editValue.end_date ? new Date(this.editValue.end_date) : new Date();
      this.blockedWeight = this.currentPeriodCriticalityWeight.find(
        (item) => item.criticality.label === "Блокирующая"
      ).weight;
      this.largeWeight = this.currentPeriodCriticalityWeight.find(
        (item) => item.criticality.label === "Значительная"
      ).weight;
      this.comfortWeight = this.currentPeriodCriticalityWeight.find(
        (item) => item.criticality.label === "Комфорт"
      ).weight;
      this.criticalityWeight = this.currentPeriodCriticalityWeight.find(
        (item) => item.criticality.label === "Критическая"
      ).weight;
    },
    closeModal() {
      this.closeAddAndEditQuarterModal();
    },
    cancelClose() {
      this.confirmClose = false;
    },
    acceptClose() {
      this.closeModal();
    },
    changeSwitch(val) {
      this.addProblem = val;
    },
    closeCreateQuarter() {
      if (this.showConfirmModal) {
        this.confirmClose = true;
      } else {
        this.closeModal();
      }
    },
    confirmActions() {
      this.SET_CRITICALITY_WEIGHT([
        {
          label: "Блокирующая",
          weight: this.blockedWeight
        },
        {
          label: "Критическая",
          weight: this.criticalityWeight
        },
        {
          label: "Значительная",
          weight: this.largeWeight
        },
        {
          label: "Комфорт",
          weight: this.comfortWeight
        }
      ]);
      if (!this.checkValidValue()) return false;
      if (this.editValue) {
        this.editISPQuarter(this.createParams);
      } else {
        this.createISPQuarter(this.createParams);
      }
    },
    checkValidValue() {
      if (!this.name.trim()) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название квартала!"
        });
        return false;
      }
      if (this.name.trim().length > 70) {
        NotificationObserver.notification({
          type: "error",
          message: "Длина названия квартала не должна превышать 70 символов!"
        });
        return false;
      }
      if (!moment(this.range.start, "YYYY-MM-DD").isValid()) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите дату начала квартала!"
        });
        return false;
      }
      if (!moment(this.range.end, "YYYY-MM-DD").isValid()) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите дату конца квартала!"
        });
        return false;
      }
      if (this.range.start > this.range.end) {
        NotificationObserver.notification({
          type: "error",
          message: "Дата начала квартала не может быть позже даты окончания квартала!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapState("IspModule", ["currentQuarter", "editQuarter", "currentPeriodCriticalityWeight"]),
    createParams() {
      return {
        name: this.name,
        start_date: moment(this.range.start).format("YYYY-MM-DD"),
        end_date: moment(this.range.end).format("YYYY-MM-DD"),
        can_add_problems: this.addProblem
      };
    },
    setTitle() {
      return this.editValue ? "Редактировать квартал" : "Создать квартал";
    },
    setConfirmTitle() {
      return this.editValue ? "Сохранить" : "Создать";
    },
    showConfirmModal() {
      return (
        this.name.length ||
        this.blockedWeight > 1 ||
        this.criticalityWeight > 1 ||
        this.largeWeight > 1 ||
        this.comfortWeight > 1
      );
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  @media (max-width: $xs) {
    padding: 16px;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__hide-btn {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    @media (max-width: $md) {
      display: inline-block;
    }
    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__title {
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
    @media (max-width: $md) {
      font-size: 25px;
    }
  }

  &__subtitle {
    margin: 40px 0 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: $text-primary;
    @media (max-width: $md) {
      font-size: 16px;
    }
  }

  &__wrap {
    margin: 0 0 48px;

    &_buttons {
      display: flex;
      justify-content: space-between;
    }
    /deep/ {
      .switch-widget {
        &__label {
          @media (max-width: $md) {
            font-size: 16px;
          }
          @media (max-width: $xs) {
            font-size: 14px;
          }
          &-wrap {
            margin-right: 16px !important;
          }
        }
      }
      .vue-slider {
        @media (max-width: $xs) {
          padding-left: 10px !important;
        }
      }
    }
  }

  &__label {
    margin: 0 0 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $text-default;
    @media (max-width: $md) {
      font-size: 12px;
    }
  }

  &__date-lable {
    margin: 0 0 32px;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $text-accent;
    @media (max-width: $md) {
      font-size: 16px;
    }
    @media (max-width: $xs) {
      font-size: 14px;
    }
  }

  /deep/ {
    .input {
      margin: 0 0 24px;
    }

    .input__label-wrap {
      margin: 0 0 32px;
    }

    .input__control {
      width: 100%;
    }

    .switch-widget {
      display: flex;
    }

    .switch-widget__label-wrap {
      margin: 0 32px 0 0;
    }

    .switch-widget__label {
      white-space: nowrap;
    }
    .switch-widget {
      &__tooltip {
        top: 25px;
        @media (max-width: $xxl) {
          right: auto;
          max-width: 470px;
        }
        @media (max-width: $md) {
          width: 93vw;
          left: auto;
          max-width: max-content;
        }
      }
    }

    .form-control {
      width: 668px;
      height: 48px;
      background: #ffffff;
      border: 2px solid #5551f9;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .daterangepicker {
      display: block !important;
    }

    .drp-buttons {
      display: none;
    }
  }
}
</style>
