<template>
  <div class="education-card">
    <div class="education-card__card">
      <div class="education-card__icon-name education-wrap">
        <IconWidgets
          :name="'graduate-cap'"
          :color="'#519DF6'"
          :background="'#E8F7FC'"
          :fontSize="27"
        />
        <div class="education-wrap__name">{{ item.education_institution.name }}</div>
        <ActionsList v-if="isAdmin && userCard" :actionList="actionList" />
      </div>
      <div class="education-card__education-direction education-wrap">
        <div class="education-wrap__education">
          <div class="education-wrap__label">ОБРАЗОВАНИЕ</div>
          <div class="education-wrap__content">
            {{ item.education_type ? item.education_type.name : "-" }}
          </div>
        </div>
        <div class="education-wrap__direction">
          <div class="education-wrap__label">НАПРАВЛЕНИЕ</div>
          <div class="education-wrap__content">
            {{ item.education_speciality ? item.education_speciality.name : "-" }}
          </div>
        </div>
      </div>
      <div class="education-card__speciality-time education-wrap">
        <div class="education-wrap__speciality">
          <div class="education-wrap__label">СПЕЦИАЛЬНОСТЬ</div>
          <div class="education-wrap__content">
            {{ item.education_qualification ? item.education_qualification.name : "-" }}
          </div>
        </div>
        <div class="education-wrap__time">
          <div class="education-wrap__label">ВРЕМЯ ОБУЧЕНИЯ</div>
          <div class="education-wrap__content">
            {{ item.from_year }}-{{ item.to_year ? item.to_year : new Date().getFullYear() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconWidgets from "@/assets/aop-icons/IconWidgets";
import ActionsList from "@/components/Widgets/ActionList/ActionList";
import Swal from "sweetalert2";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "EducationCard",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    userCard: {
      type: Boolean,
      default: false
    }
  },
  components: { IconWidgets, ActionsList },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `EditEducationUser_${this.item.id}`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `DeleteEducationUser_${this.item.id}`
        }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off(`actionItem:handleEditEducationUser_${this.item.id}`);
    this.$root.$off(`actionItem:handleDeleteEducationUser_${this.item.id}`);
  },
  mounted() {
    this.$root.$on(`actionItem:handleEditEducationUser_${this.item.id}`, () => {
      this.$root.$emit("changeEducationUser", this.item);
    });
    this.$root.$on(`actionItem:handleDeleteEducationUser_${this.item.id}`, () => {
      this.askDeleteEducationItem(this.item.id);
    });
  },
  methods: {
    askDeleteEducationItem(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить образование сотрудника?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteEducationItem(id);
          }
        });
    },
    deleteEducationItem(id) {
      httpClient({
        url: `/UserEducation/delete/${id}`,
        method: "DELETE"
      })
        .then((response) => {
          // this.$root.$emit("goGetInfoUser");
          this.$emit("goGetInfoUser");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Образование успешно удалено!`
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.education-card {
  &__card {
    height: 313px;
    width: 509px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 24px;
    padding: 30px;
    margin: 0 25px 25px 0;
    @media screen and (max-width: $md) {
      width: 100%;
      margin-right: 0;
    }
    @media screen and (max-width: $sm) {
      height: auto;
    }
  }
  &__icon-name {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: $sm) {
      margin-bottom: 18px;
    }
  }
  &__education-direction {
    display: flex;
    align-items: start;
    width: 100%;
    margin-bottom: 40px;
    @media screen and (max-width: $sm) {
      flex-direction: column;
      margin-bottom: 28px;
    }
  }
  &__speciality-time {
    display: flex;
    align-items: start;
    width: 100%;
  }
  .education-wrap {
    .icon-widget {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $text-disabled;
      margin-bottom: 15px;
      word-break: break-word;
      @media screen and (max-width: $sm) {
        font-weight: 500;
        font-size: 9px;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
    &__content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $text-default;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
    .icon {
      display: flex;
      height: 55px;
      width: 55px;
      flex-shrink: 0;
      margin-right: 22px;
      /deep/ {
        .icon-graduate-cap {
          font-size: 32px !important;
        }
      }
      @media screen and (max-width: $sm) {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        margin-right: 8px;
        /deep/ {
          .icon-graduate-cap {
            font-size: 18px !important;
          }
        }
      }
    }
    &__name {
      width: 90%;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $text-accent;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (max-width: $sm) {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
      }
    }
    &__education {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 5px;
      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }
    &__direction {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 5px;
      @media screen and (max-width: $sm) {
        width: 100%;
        margin-top: 24px;
      }
    }
    &__speciality {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 5px;
    }
    &__time {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 5px;
    }

    &__add-card {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 386px;
      min-height: 277px;
      border: 2px dashed $border-default;
      border-radius: 24px;
      background: transparent;
      z-index: 5;

      &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        left: -30px;
        top: 147px;
        border-bottom: 3px dashed #d3d2e0;
      }
    }
    &__create {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 203px;
      height: 30px;
      border-radius: 40px;
      border: 1px solid $border-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $text-disabled;
      background: transparent;
      outline: none;

      &:hover {
        color: $text-default;
        background: $action-secondary-hovered;
      }

      &:active {
        color: $text-default;
        background: $action-secondary-pressed;
      }

      i {
        margin-right: 8px;
      }
    }
  }
}
</style>
