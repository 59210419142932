<template>
  <div class="profile__history-modal history-modal" @click.self="$emit('hideModal')">
    <div class="history-modal__window">
      <div class="history-modal__header">
        <i class="icon-sort-arrow" @click="$emit('hideModal', false, null)"></i>
        <h2 class="history-modal__title">
          {{ editValue ? "Редактировать путь в компании" : "Добавить путь в компании" }}
        </h2>
        <i class="icon-cross" @click="$emit('hideModal', false, null)"></i>
      </div>
      <div class="history-modal__main">
        <div class="history-modal__wrap">
          <Widget
            v-if="!editValue"
            :type="'select-new'"
            :label="'Должность:'"
            :entity="'Role'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editRoleHistoryUser'"
            :editValue="editRole"
            :placeholder="'Должность'"
            :multiple="false"
            createEntity="create"
            :createMode="true"
          />
          <div class="history-modal__grade" v-if="editValue">
            <div class="history-modal__label">Роль</div>
            <div class="history-modal__content">{{ setRole }}</div>
          </div>
        </div>
        <div class="history-modal__wrap">
          <Widget
            v-if="!editValue"
            :type="'select-new'"
            :label="'Отдел:'"
            :entity="'Department'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editDepartmentHistoryUser'"
            :editValue="editDepartment"
            :placeholder="'Отдел'"
            :multiple="false"
            createEntity="create"
            :createMode="true"
          />
          <div class="history-modal__grade" v-if="editValue">
            <div class="history-modal__label">Отдел</div>
            <div class="history-modal__content">{{ setDepartment }}</div>
          </div>
        </div>
        <div class="history-modal__wrap">
          <Widget
            v-if="!editValue"
            :type="'select-new'"
            :label="'Грейд:'"
            :entity="'Level'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editLevelHistoryUser'"
            :editValue="editLevel"
            :placeholder="'Уровень'"
            :multiple="false"
          />
          <div class="history-modal__grade" v-if="editValue">
            <div class="history-modal__label">Грейд</div>
            <Level :level="setGrade" />
          </div>
        </div>
        <div class="history-modal__wrap history-modal__wrap_date">
          <div class="history-modal__date">
            <Widget
              :type="'InputDatePicker'"
              :editValue="editStartDate"
              :displayFormat="'DD.MM.YYYY'"
              :unique="'editStartDateHistoryUser'"
              :showIcon="true"
              :label="'Дата начала работы:'"
            />
          </div>
          <div class="history-modal__date">
            <Widget
              :type="'InputDatePicker'"
              :displayFormat="'DD.MM.YYYY'"
              :editValue="editEndDate"
              :unique="'editEndDateHistoryUser'"
              :showIcon="true"
              :label="'Дата завершения работы:'"
            />
          </div>
        </div>
      </div>
      <div class="history-modal__footer">
        <button
          class="history-modal__button history-modal__button_cancel"
          type="button"
          @click="$emit('hideModal', false, null)"
        >
          Отменить
        </button>
        <button
          class="history-modal__button"
          type="button"
          v-if="!editValue"
          @click="addNewHistoryItem"
        >
          Создать
        </button>
        <button
          class="history-modal__button"
          type="button"
          v-if="editValue"
          @click="editHistoryItem(editValue.id)"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";

import { domain } from "@/globalVariables";
import moment from "moment";
import Level from "../../Widgets/Level/Level";
import httpClient from "@/api/client/httpClient";
export default {
  name: "HistoryCreateAndEditModal",
  mixins: [],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editValue: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  components: { Level, Widget },
  data() {
    return {
      editStartDate: moment(new Date()).format("YYYY-MM-DD"),
      editEndDate: "",
      editDepartment: "",
      editRole: "",
      editLevel: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("date:change_editStartDateHistoryUser");
    this.$root.$off("date:change_editEndDateHistoryUser");
    this.$root.$off("changeSelectValue:editDepartmentHistoryUser");
    this.$root.$off("changeSelectValue:editRoleHistoryUser");
    this.$root.$off("changeSelectValue:editLevelHistoryUser");
  },
  mounted() {
    this.setEditData();
    this.$root.$on("date:change_editStartDateHistoryUser", (value) => {
      this.editStartDate = value;
    });
    this.$root.$on("date:change_editEndDateHistoryUser", (value) => {
      this.editEndDate = value;
    });
    this.$root.$on("changeSelectValue:editDepartmentHistoryUser", (value) => {
      this.editDepartment = value;
    });
    this.$root.$on("changeSelectValue:editRoleHistoryUser", (value) => {
      this.editRole = value;
    });
    this.$root.$on("changeSelectValue:editLevelHistoryUser", (value) => {
      this.editLevel = value;
    });
  },
  methods: {
    /**
     * function of creating a new history card in a company
     */
    addNewHistoryItem() {
      if (!this.checkValidation()) return null;
      this.loading = true;
      httpClient({
        url: `/UserAffiliation/create`,
        method: "POST",
        data: [
          {
            user_id: this.$route.params.user_id ? this.$route.params.user_id : this.getUserId,
            department_id: this.editDepartment.length ? this.editDepartment[0].id : "",
            role_id: this.editRole.length ? this.editRole[0].id : "",
            level_id: this.editLevel.length ? this.editLevel[0].id : null,
            from_date: this.editStartDate,
            to_date: this.editEndDate.length ? this.editEndDate : null
          }
        ]
      })
        .then(() => {
          this.$emit("goGetHistoryInfoUser");
          this.$emit("hideModal", false);
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Путь сотрудника успешно добавлен!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    /**
     * User history card validation function
     */
    checkValidation() {
      if (!this.editDepartment.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите отдел!`
        });
        return false;
      } else if (!this.editRole.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите роль!`
        });
        return false;
      } else if (!this.editStartDate.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите дату начала пути!`
        });
        return false;
      }
      return true;
    },
    /**
     * Setting existing user data for editing
     */
    setEditData() {
      if (this.editValue) {
        this.editStartDate = this.editValue.from_date;
        this.editEndDate =
          this.editValue.to_date === null
            ? moment(new Date()).format("YYYY-MM-DD")
            : this.editValue.to_date;
        this.editRole = this.editValue.roles[0];
        this.editDepartment = this.editValue.departments[0];
        this.editLevel = this.editValue.levels.length ? this.editValue.levels[0] : null;
      }
    },
    /**
     * function of editing the history card in the company
     * @param id - id history card
     */
    editHistoryItem(id) {
      if (!this.editStartDate) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите дату начала пути сотрудника!`
        });
        return false;
      }
      httpClient({
        url: `/UserAffiliation/update/${id}`,
        method: "PUT",
        data: [
          {
            user_id: this.editValue.user_id,
            department_id: this.editDepartment ? this.editDepartment.id : undefined,
            role_id: this.editRole ? this.editRole.id : undefined,
            level_id: this.editLevel ? this.editLevel.id : undefined,
            from_date: this.editStartDate,
            to_date: this.editEndDate === "" ? null : this.editEndDate
          }
        ]
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Путь успешно изменен!`
          });
          this.$emit("goGetHistoryInfoUser");
          this.$emit("hideModal", false);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setGrade() {
      return this.editValue.levels.length ? this.editValue.levels[0].name : "Без уровня";
    },
    setRole() {
      return this.editValue.roles.length ? this.editValue.roles[0].name : "-";
    },
    setDepartment() {
      return this.editValue.departments.length ? this.editValue.departments[0].name : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.history-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 100;

  &__window {
    position: relative;
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    width: 786px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      width: 100vw;
      height: 100vh;
      background: $surface-default;
      z-index: 1000;
      border-radius: 0;
    }

    @media (max-width: $sm) {
      width: 100vw;
      height: 100vh;
      background: $surface-default;
      z-index: 1000;
      border-radius: 0;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 24px;
    border-bottom: 1px solid $border-disabled;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      justify-content: flex-start;
      padding: 16px 16px 8px;
    }

    @media (max-width: $sm) {
      justify-content: flex-start;
      padding: 16px 16px 8px;
    }

    i {
      font-size: 22px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-sort-arrow {
      display: none;
      transform: rotate(90deg);
      margin-right: 20px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        display: block;
      }

      @media (max-width: $sm) {
        display: block;
      }
    }
    .icon-cross {
      @media screen and (max-width: 850px) and (max-height: 450px) {
        display: none;
      }

      @media (max-width: $sm) {
        display: none;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    color: #000000;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      font-size: 14px;
      line-height: 32px;
      color: $text-body-dark;
    }

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 32px;
      color: $text-body-dark;
    }
  }

  &__main {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 32px 72px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 60vh;
    }

    @media (max-width: $sm) {
      overflow-y: scroll;
      max-height: 90vh;
      padding: 16px 16px 0;
      width: 100%;
      flex-direction: column;
    }

    @media (max-width: 320px) {
      overflow-y: scroll;
      max-height: 75vh;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 55vh;
      flex-direction: row;
    }
  }

  &__wrap {
    width: 48%;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      width: 100%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      width: 100%;
    }

    &_date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        flex-direction: column;
      }

      @media (max-width: $sm) {
        flex-direction: column;
      }

      @media screen and (max-width: 568px) and (max-height: 450px) {
        flex-direction: column;
      }
    }

    &:not(:last-child) {
      margin: 0 0 54px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        margin: 0 0 24px;
      }

      @media (max-width: $sm) {
        margin: 0 0 24px;
      }
    }

    /deep/ {
      .select-bg {
        width: 100%;
      }

      .select-content-value-bg {
        width: 70% !important;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          width: 55% !important;
        }

        @media (max-width: $sm) {
          width: 55% !important;
        }
      }

      .select-content-placeholder {
        width: 70% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          width: 34% !important;
        }

        @media (max-width: $sm) {
          width: 34% !important;
        }
      }

      .level {
        width: max-content;
      }
    }
  }

  &__date {
    width: 48%;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      width: 100%;
      margin-bottom: 16px;
    }

    @media (max-width: $sm) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__label {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: $text-subdued;
    margin-bottom: 14px;
    text-transform: uppercase;
  }

  &__user {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    width: 100%;

    img {
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  &__name {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
  }

  &__active {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__content {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }

  &__black-spheres-date {
    margin: 0;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: $bg-surface-light;
    border-radius: 12px;
  }

  &__footer {
    border-top: 1px solid $border-disabled;
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 32px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      padding: 16px;
    }

    @media (max-width: $sm) {
      padding: 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }

    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
