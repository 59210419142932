import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";

class TaskManagerService extends Service {
  search(params): Response<any> {
    return httpClientV2
      .get("/task_manager/search/search", {
        params
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new TaskManagerService();
