<template>
  <div class="switch-widget">
    <label class="switch-widget__container">
      <input
        class="switch-widget__input"
        type="checkbox"
        :checked="value"
        @change="Change__Switch($event)"
        :disabled="readonly"
      />
      <span class="switch-widget__indicator"></span>
      <span class="switch-widget__label" v-if="label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchWidget",
  model: {
    prop: "checked",
    event: "change"
  },
  mixins: [],
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      value: false
    };
  },
  mounted() {
    if (this.editValue) {
      this.value = this.editValue;
    }
  },
  methods: {
    Change__Switch(event) {
      this.value = event.target.checked;
      const data = {
        eventName: "Change__Switch",
        data: {
          value: this.value,
          unique: this.unique
        }
      };
      this.$root.$emit(`switch:change_${this.unique}`, this.value);
    }
  },
  computed: {},
  watch: {
    editValue() {
      this.value = this.editValue;
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.switch-widget {
  cursor: pointer;
  &__container {
    width: max-content;
    display: flex;
    align-items: center;
  }

  &__input {
    display: none;
    font-size: 0;
    width: 0;
    height: 0;

    &:checked + .switch-widget__indicator {
      border-color: $action-primary;
      background: $action-primary;

      &::before {
        top: 2px;
        left: 30px;
        width: 22px;
        height: 22px;
        background-color: #fafafe;
      }
    }
  }

  &__indicator {
    position: relative;
    width: 54px;
    height: 26px;
    background: $icon-disabled;
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 3px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s ease;
    }
  }

  &__label {
    cursor: pointer;
    margin-left: 3px;
  }
}
</style>
