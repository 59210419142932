<template>
  <div class="date-picker" v-click-outside="hideModal">
    <div
      class="date-picker__value"
      :class="{ 'date-picker__value_active': showModal }"
      @click="toggleModal"
    >
      <p>{{ valueDateFormat ? valueDateFormat : placeholder }}</p>
      <i class="icon-calendar-secondary" v-if="!showModal"></i>
      <i class="icon-cross" v-else @click.stop="deleteDate"></i>
    </div>
    <div class="date-picker__calendar" v-if="showModal">
      <v-date-picker
        v-model="selectDate"
        :masks="masks"
        :disabled="false"
        :attributes="attributes"
        :mode="mode"
        :min-date="minDate"
        :max-date="maxDate"
        is24hr
      >
        <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
          <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
        </div>
        <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
      </v-date-picker>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";

export default {
  name: "DatePickerWidget",
  mixins: [],
  props: {
    value: {
      type: String,
      default: () => {
        return "";
      }
    },
    mode: {
      type: String,
      default: "dateTime"
    },
    placeholder: {
      type: String,
      default: "Не ограничено"
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    isClose: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      showModal: false,
      selectDate: null,
      masks: {
        weekdays: "WWW"
      },
      attributes: []
    };
  },
  mounted() {
    if (this.value) {
      this.selectDate = this.value;
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.selectDate && !this.isClose) {
        this.selectDate = new Date();
      }
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    hideModal() {
      this.showModal = false;
    },
    deleteDate() {
      this.showModal = false;
      this.selectDate = null;
      this.$emit("input", null);
    }
  },
  computed: {
    valueDateFormat() {
      if (this.mode === "dateTime") {
        return this.value ? moment(this.value).format("DD.MM.YYYY HH:mm") : "";
      } else {
        return this.value ? moment(this.value).format("DD.MM.YYYY") : "";
      }
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.selectDate = this.value;
      }
    },
    selectDate(val) {
      this.$emit("input", val);
      if (val !== null) {
        if (this.isClose) {
          this.showModal = false;
        }
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    //height: 32px;
    background: #fafafe;
    border: 2px solid #fafafe;
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;

    &_active {
      border: 2px solid #5551f9;
      background: #fff;
      margin-bottom: 36px;

      p {
        color: $text-default !important;
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $text-disabled;
      margin-top: 2px;
    }

    i {
      color: #a7a9c0;
    }
  }

  &__calendar {
    top: calc(100% + 1px);

    /deep/ {
      .vc-container {
        border: 1px solid #eae8f0;
        border-radius: 8px;
        padding: 20px 2px 0;
        width: 100%;
      }

      .vc-pane-layout {
        padding: 0 8px 16px;
      }

      .vc-arrows-container {
        width: 300px;
        padding: 0;
        margin: 0 0 24px;
        left: 50%;
        transform: translateX(-50%);
      }

      .vc-date {
        display: none;
      }

      .vc-time-picker {
        width: 100%;
        justify-content: center;
      }

      .vc-svg-icon {
        width: 21px;
        height: 21px;

        path {
          fill: $icon-subdued;
        }
      }

      .vc-header {
        padding: 0;
        margin: 0 0 24px;
      }

      .month {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .vc-weekday {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $text-disabled;
      }

      .weekday-1 .vc-day-content,
      .weekday-7 .vc-day-content {
        color: $text-disabled;
      }

      .vc-day-content {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #000000;

        &:hover,
        &:focus {
          width: 40px;
          height: 40px;
          background: #5551f9;
          border-radius: 4px !important;
          color: #ffffff;
        }
      }

      .vc-highlight.vc-highlight-base-middle {
        width: 40px;
        height: 40px;
        background: #fafafe !important;
      }

      .vc-highlight,
      .vc-highlight.vc-highlight-base-start,
      .vc-highlight.vc-highlight-base-end {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
      }

      .vc-day.is-not-in-month .vc-day-content {
        opacity: 1;
        color: #747474;
      }

      .is-disabled {
        color: #6a7e8e;
        pointer-events: none;
      }
    }
  }
}
</style>
