<template>
  <div class="task__members members">
    <div class="members__header">
      <TabsContent :tabs="usersTabsList" v-model="currentUserTab" />
    </div>
    <div class="members__container" v-if="currentUserTab === 'members'">
      <div class="members__wrap">
        <EditableUser :label="'Автор задачи'" :user="author" :isEditable="false" />
      </div>
      <div class="members__wrap">
        <EditableUser
          :label="'Исполнитель'"
          :user="executor"
          :isEditable="isOwner || isAuthor || isExecutor"
          :items="userList"
          @editUser="handleEditExecutors"
        />
      </div>
    </div>
    <div class="members__container members__container_scroll" v-if="currentUserTab === 'watch'">
      <div class="members__wrap" v-for="item in 10" :key="item">
        <EditableUser :label="''" :isEditable="false" />
      </div>
    </div>
  </div>
</template>

<script>
import EditableUser from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/EditableUser";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";

export default {
  name: "MembersBody",
  mixins: [],
  props: {
    author: {
      type: Object,
      default: () => {}
    },
    executor: {
      type: Object,
      default: () => {}
    },
    userList: {
      type: Array,
      default: () => []
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    }
  },
  components: { EditableUser, TabsContent },
  data() {
    return {
      currentUserTab: "members",
      usersTabsList: [
        {
          label: "Участники",
          value: "members"
        }
        // {
        //   label: "Наблюдатели",
        //   value: "watch"
        // }
      ]
    };
  },
  mounted() {},
  methods: {
    handleEditExecutors(val) {
      this.$emit("handleEdit", {
        executor_id: val
      });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.members {
  min-width: 355px;

  @media (max-width: 1740px) {
    min-width: 250px;
  }

  @media (max-width: $xl) {
    width: 100%;
  }

  &__header {
    min-width: 355px;
    padding: 14px 24px;
    height: 70px;
    border-bottom: 1px solid $system-grey-four;

    @media (max-width: 1740px) {
      min-width: 250px;
      padding: 14px 8px;
    }

    @media (max-width: $xl) {
      padding: 20px 8px 14px;
    }

    @media (max-width: $md) {
      padding: 16px 8px 0;
    }
  }

  &__container {
    height: calc(100vh - 70px);
    padding: 42px 24px;

    @media (max-width: 1740px) {
      padding: 16px 16px 70px;
    }

    @media (max-width: $xl) {
      height: max-content;
    }

    &_scroll {
      overflow-y: scroll;
      height: 100%;

      @media (max-width: $xl) {
        overflow-y: auto;
        height: max-content;
      }
    }
  }

  &__wrap {
    margin: 0 0 42px;

    /deep/ label {
      margin: 0 0 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: $text-default;
    }
  }
}

/deep/ {
  .editable-user {
    max-width: 250px;

    @media (max-width: $xxl) {
      max-width: 100%;
    }

    &__user {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .user__info {
        overflow: hidden;
      }
    }

    .user__name,
    .user__description {
      max-width: 125px;

      @media (max-width: $xxl) {
        max-width: 100%;
      }
    }

    .select-bg__placeholder {
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon-edit {
      @media (max-width: $md) {
        opacity: 1;
      }
    }
  }
  .tabs {
    &__item {
      min-height: 26px;
    }

    label {
      padding: 0 0 4px;
    }

    .active {
      &::before {
        @media (max-width: $sm) {
          bottom: 0;
          height: 2px;
        }
      }
    }
  }
}
</style>
