<template>
  <div class="news-header">
    <div class="news-header__info">
      <img
        class="news-header__logo"
        :src="returnSrcFile(news.channel.icon)"
        alt="channel-icon"
        v-if="news.channel.icon"
      />
      <div
        class="news-header__logo"
        v-else
        :style="
          news.channel.color
            ? { background: news.channel.color, borderColor: news.channel.color }
            : { background: '#D0BBFE', borderColor: '#D0BBFE' }
        "
      >
        <span>{{ setChannelName(news.channel.name) }}</span>
      </div>
      <div class="news-header__text-wrap">
        <p class="news-header__label">{{ news.channel.name }}</p>

        <div class="news-header__date-and-views">
          <p class="news-header__date">{{ setDate }}</p>
          <div class="news-header__views"><i class="icon-eye"></i> {{ countViews }}</div>
        </div>
      </div>
    </div>
    <div class="news-header__wrap">
      <router-link
        :to="{ name: 'UserInfo', params: { user_id: news.author.id } }"
        :target="isGlobalMobileMode ? '_self' : '_blank'"
        class="news-header__author"
      >
        <span>{{ news.author.full_name }}</span>
      </router-link>
      <ActionsList :actionList="actionList" v-if="editMode" @handleAction="handleAction" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { domainStorage } from "@/globalVariables";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
export default {
  name: "NewsHeader",
  mixins: [],
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    actionList: {
      type: Array,
      default: () => []
    },
    news: {
      type: Object,
      default: () => {}
    },
    countViews: {
      type: [Number, String],
      default: 0
    }
  },
  components: { ActionsList },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleAction(val) {
      switch (val.actionType) {
        case "Delete":
          this.$emit("action_item_Delete");
          break;
        case "Edit":
          this.$emit("action_item_Edit");
          break;
      }
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    setChannelName(str) {
      return str.charAt(0).toUpperCase();
    }
  },
  computed: {
    setDate() {
      return moment(this.news.datetime_of_creation).format("DD.MM.YYYY");
    },
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.news-header {
  font-family: "SF", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  @media screen and (max-width: $xs) {
    margin-bottom: 18px;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__text-wrap {
    display: flex;
    align-items: center;
    @media screen and (max-width: $xs) {
      margin-left: 8px;
    }
  }
  &__label {
    padding: 0;
    margin: 0 16px 0 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $text-default;
    max-width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (max-width: $lg) {
      margin-top: 0;
    }
    @media screen and (max-width: $xs) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__date-and-views {
    display: flex;
  }
  &__date {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $text-disabled;
    margin-right: 16px;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__logo {
    margin-right: 9px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #f1effa;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: $text-default;
      word-break: break-word;
    }
  }
  &__views {
    font-size: 12px;
    color: $icon-subdued;
    display: flex;
    align-items: center;
    i {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  &__author {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 4px;
    font-size: 12px;
    line-height: 24px;
    color: $text-subdued;
    @media (max-width: $xl) {
      display: none;
    }
    @media (max-width: $md) {
      display: flex;
    }
    @media screen and (max-width: $xs) {
      display: none;
    }
    .icon-user {
      margin-right: 4px;
      font-size: 24px;
      color: $icon-subdued;
      @media screen and (max-width: $xs) {
        width: 16px;
        height: 16px;
      }
    }
  }
  /deep/ {
    .action-list__setting-button {
      width: 20px;
      height: 20px;
      i {
        font-size: 16px;
      }
    }
    .action-list__modal-list {
      margin-top: 0;
      top: calc(100% + 10px);
      padding: 8px;
      @media screen and (max-width: 850px) and (orientation: landscape) {
        top: auto;
      }
    }
  }
}
</style>
