<template>
  <div class="editable-deadline">
    <div class="editable-deadline__wrap">
      <p class="editable-deadline__label">{{ label }}</p>
      <p
        @click="setEditModeOfDeadline"
        v-if="!isEditDeadline && !isReadonly"
        class="editable-deadline__date-range"
      >
        {{ setDateValue }}
      </p>
      <p class="editable-deadline__date-range_readonly" v-if="isReadonly">
        {{ setDateValue }}
      </p>
      <span class="editable-deadline__icons" v-if="isEditDeadline">
        <i class="icon-done" @click.stop="editDeadlines"></i>
        <i class="icon-cross" @click.stop="cancelEditDeadline"></i>
      </span>
    </div>
    <DateRangePicker
      v-model="range"
      :range="range"
      v-if="isEditDeadline"
      class="editable-deadline__edit"
      :isRequired="false"
      :columns="calendarColumns"
      :showCross="true"
    />
  </div>
</template>

<script>
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";
import moment from "moment";
import { isEmpty } from "@/helpers/helperFunction";

export default {
  name: "EditableDeadline",
  mixins: [],
  model: {
    prop: "deadline",
    event: "deadlineChange"
  },
  props: {
    label: {
      type: String,
      default: "Сроки проекта:"
    },
    deadline: {
      type: Object,
      default: () => {}
    },
    keyStart: {
      type: String,
      default: "start"
    },
    keyEnd: {
      type: String,
      default: "end"
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  components: { DateRangePicker },
  data() {
    return {
      isEditDeadline: false,
      range: {
        start: null,
        end: null
      },
      componentWidth: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.deadline) {
        this.range.start = this.deadline[this.keyStart];
        this.range.end = this.deadline[this.keyEnd];
      }
    });

    const resizeHandler = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.componentWidth = entry.contentRect.width;
      });
    });
    resizeHandler.observe(document.querySelector(".editable-deadline"));
  },
  methods: {
    setEditModeOfDeadline() {
      this.isEditDeadline = true;
    },
    editDeadlines() {
      this.isEditDeadline = false;
      this.$emit("deadlineChange", this.range);
    },
    cancelEditDeadline() {
      this.isEditDeadline = false;
    }
  },
  computed: {
    setDateValue() {
      return isEmpty(this.deadline) || !this.deadline[this.keyStart] || !this.deadline[this.keyEnd]
        ? "Не ограничено"
        : moment(this.deadline[this.keyStart]).format("DD.MM.YYYY") +
            " по " +
            moment(this.deadline[this.keyEnd]).format("DD.MM.YYYY");
    },
    calendarColumns() {
      return this.componentWidth > 600 ? 2 : 1;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.editable-deadline {
  &__wrap {
    padding-left: 8px;
    display: flex;
    align-items: center;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__label {
    margin-right: 8px;
    color: $text-primary;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__edit {
    margin-top: 16px;
  }
  &__icons {
    text-decoration: none;
    margin-left: auto;
    .icon-done {
      margin-right: 32px;
    }
    i {
      font-size: 24px;
      color: $icon-subdued;
      cursor: pointer;
    }
  }
  &__date-range {
    cursor: pointer;
    font-size: 16px;
    color: $text-default;
    line-height: 26px;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      text-decoration: underline;
    }
    &_readonly {
      cursor: unset;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
