<template>
  <div class="comments-form" v-click-outside="hideModalEmoji">
    <div class="comments-form__container">
      <div class="comments-form__wrap"></div>
      <div class="comments-form__textarea-wrap">
        <textarea
          :id="setId"
          :ref="'textarea'"
          class="comments-form__textarea"
          maxlength="1024"
          type="text"
          v-model.trim="value"
          @input="mixin_autoResize_resize"
          @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
          @keydown.enter.prevent.exact="switchKeyEnter"
          @paste="handlePast"
          @drop="dropPast($event)"
          :placeholder="placeholder"
        ></textarea>
      </div>
      <div class="comments-form__icons">
        <file-upload
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          post-action="/upload/post"
          :input-id="`eventFiles`"
          :multiple="true"
          :drop="drop"
          :drop-directory="dropDirectory"
          v-model="files"
          @input="uploadFile"
          class="file-button"
          :maximum="2"
          :ref="`eventFiles`"
          :name="`eventFiles`"
        >
          <i class="icon-paper-clip-secondary"></i>
        </file-upload>
        <i
          class="icon-emoji"
          @click="showModalEmoji = !showModalEmoji"
          :style="showModalEmoji ? 'color: #8D96EC' : 'color: #B8B8CB'"
        ></i>
        <Spinner class="comments-form__loader" v-if="loaderForm" :small="true" />
        <i class="icon-send" @click.stop="createChunksPoolFiles" v-else></i>
      </div>
      <Picker
        v-if="showModalEmoji"
        set="apple"
        @select="addEmoji"
        title="Выберите Эмоджи"
        :i18n="settings"
      />
    </div>
    <div v-if="files.length" class="comments-form__files">
      <ul class="files__list">
        <CommentFileItem
          v-for="(file, idx) in documents"
          :key="file.id"
          :idx="idx"
          :file="file"
          :fileName="file.name || file.label"
          @filesSplice="deleteFileDoc"
          :showCross="true"
          :isMain="true"
        />
      </ul>
      <ul class="files__list" v-if="files.length">
        <li
          class="comments-form__cover files-draggable"
          v-for="(img, index) in cover"
          :key="img.id"
          draggable="true"
        >
          <img class="comments-form__img" :src="img.src" :alt="img.name || img.label" />
          <i class="icon-cross" @click="deleteFileItem(index)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import FileUpload from "vue-upload-component";
import ResizeMixin from "@/helpers/ResizeMixin";
import { mapActions, mapMutations, mapState } from "vuex";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import pastAndDropFiles from "@/helpers/Files/pastAndDropFiles";
import CommentFileItem from "@/components/GraphVacation/Comments/CommentFileItem";

export default {
  name: "CommentsForm",
  mixins: [uploadFilesMixin, ResizeMixin, pastAndDropFiles],
  props: {
    typeComment: {
      type: [String, Number],
      default: ""
    },
    multiple: {
      type: [String, Boolean]
    },
    dropDirectory: {
      type: String
    },
    size: {
      type: Number,
      default: 48
    },
    placeholder: {
      type: String,
      default: "Добавить комментарий..."
    },
    drop: {
      type: String
    },
    userInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    // FilesItem,
    // FilesImgItem,
    CommentFileItem,
    Spinner,
    Picker,
    FileUpload
  },
  data() {
    return {
      files: [],
      value: "",
      showModalEmoji: false,
      loaderForm: false,
      oldEditFiles: [],
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", [
      "createProblemCommentAction",
      "getProblemCommentsAction",
      "editProblemCommentAction",
      "replyProblemCommentAction"
    ]),
    ...mapMutations("IspModule", [
      "SET_CURRENT_EDITABLE_COMMENT",
      "SET_CURRENT_REPLYABLE_COMMENTS",
      "SET_CURRENT_PROBLEM_COMMENTS"
    ]),
    uploadFile(value) {
      this.files = value;
      this.getUrlFile();
      this.fileUploader = [];
      this.oldEditFiles = [];
      if (this.files.length) {
        this.files.forEach((item) => {
          if (!item.link) {
            this.fileUploader.push(item);
          }
          if (item.link) {
            this.oldEditFiles.push(item);
          }
        });
      }
    },
    createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      if (!this.fileUploader.length) {
        this.sendComment();
        return null;
      }
      this.loaderForm = true;
      this.createPull(this.fileUploader);
      this.files = [];
    },
    deleteFileItem(val) {
      this.cover.splice(val, 1);
      this.oldEditFiles = [];
      this.files = this.documents.concat(this.cover);
      this.fileUploader = this.files;
      this.uploadFile(this.files);
    },
    deleteFileDoc(val) {
      this.documents.splice(val, 1);
      this.oldEditFiles = [];
      this.files = this.documents.concat(this.cover);
      this.fileUploader = this.files;
      this.uploadFile(this.files);
    },
    checkValidationData() {
      if (!this.value && !this.files.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите текст комментария или прикрепите файл!`
        });
        return false;
      }
      if (this.value.length > 1024) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Комментарий не может быть больше 1024 символов!`
        });
        return false;
      }

      // Проверка общего размера пула файлов
      if (this.fileUploader.length) {
        let sumSize = 0;
        this.fileUploader.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 52428800) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 50мб!`
          });
          return false;
        }
      }

      return true;
    },
    addEmoji(emoji) {
      // selectionStart - Начало
      // selectionEnd - Конец
      let cursorPosition = this.$refs.textarea.selectionStart;
      let FirstPart = this.value.substring(0, cursorPosition);
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition + 1, this.value.length);
      this.value = FirstPart + Emoji + SecondPart;
    },
    hideModalEmoji() {
      this.showModalEmoji = false;
    },
    filesUploaded() {
      this.sendComment();
    },
    sendComment() {
      switch (this.mode) {
        case "edit":
          this.editProblemCommentAction({
            content: this.value,
            id: this.currentEditableComment.id,
            commentableId: this.currentInfoProblem.id,
            filesId: this.fileUploadingId.concat(this.oldEditFiles.map((item) => item.id))
          }).then(() => {
            this.currentEditableComment.content = this.value;
            this.currentEditableComment.attachment_files = this.uploadedFiles.concat(
              this.oldEditFiles
            );
            this.fileUploadingId = [];
            this.uploadedFiles = [];
            this.loaderForm = false;
            this.SET_CURRENT_EDITABLE_COMMENT(null);
            this.$refs.textarea.style.height = "52px";
          });
          break;
        case "reply":
          this.replyProblemCommentAction({
            content: this.value,
            parentId: this.currentReplyableComment.id,
            commentableId: this.currentInfoProblem.id,
            filesId: this.fileUploadingId
          }).then((response) => {
            const newComment = response.data.data;
            newComment.author = newComment.author[0];
            newComment.replies = [];
            newComment.attachment_files = this.uploadedFiles;
            this.currentReplyableComment.replies.push(newComment);
            this.value = "";
            this.files = [];
            this.fileUploadingId = [];
            this.uploadedFiles = [];
            this.loaderForm = false;
            this.SET_CURRENT_REPLYABLE_COMMENTS(null);
            this.$refs.textarea.style.height = "52px";
          });
          break;
        default:
          this.createProblemCommentAction({
            content: this.value,
            filesId: this.fileUploadingId,
            id: this.currentInfoProblem.id
          }).then((response) => {
            const newComment = response.data.data;
            newComment.author = newComment.author[0];
            newComment.replies = [];
            newComment.attachment_files = this.uploadedFiles;
            this.currentProblemComments.push(newComment);
            this.value = "";
            this.files = [];
            this.fileUploadingId = [];
            this.uploadedFiles = [];
            this.loaderForm = false;
            this.$refs.textarea.style.height = "52px";
            this.$nextTick(() => {
              document.querySelector(".comments-body__list").scrollTop =
                document.querySelector(".comments-body__list").scrollHeight;
            });
          });
          break;
      }
    },
    switchKeyCtrlEnter(event) {
      this.newLine(event);
    },
    switchKeyEnter() {
      this.createChunksPoolFiles();
    },
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    }
  },
  computed: {
    ...mapState("IspModule", [
      "currentInfoProblem",
      "currentEditableComment",
      "currentReplyableComment",
      "currentProblemComments"
    ]),
    setId() {
      return `textareaForm`;
    }
  },
  watch: {
    currentEditableComment(val) {
      if (val) {
        this.value = val.content;
        this.files = JSON.parse(JSON.stringify(val.attachment_files));
        this.oldEditFiles = JSON.parse(JSON.stringify(val.attachment_files));
        this.getUrlFile();
        this.mode = "edit";
        this.$nextTick(() => {
          this.$refs.textarea.style.height = "52px";
          this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
        });
        this.$refs.textarea.focus();
      } else {
        this.value = "";
        this.mode = "";
        this.files = [];
        this.$nextTick(() => {
          this.$refs.textarea.style.height = "52px";

          this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
        });
      }
    },
    currentReplyableComment(val) {
      if (val) {
        this.value = `${val.author.full_name.split(" ")[1]}, `;
        this.mode = "reply";
        this.$refs.textarea.focus();
      } else {
        this.value = "";
        this.mode = "";
        this.files = [];
      }
    },
    value(val) {
      if (!val.length) {
        this.SET_CURRENT_REPLYABLE_COMMENTS(null);
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.comments-form {
  height: fit-content;
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 90px;
  &__icons {
    display: flex;
    align-items: center;
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 580px) {
      height: 36px;
    }
  }
  .file-button {
    position: absolute;
    right: 53px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    @media screen and (max-width: 580px) {
      font-size: 10px;
      width: 12px;
      height: 12px;
      right: 40px;
    }
    /deep/ {
      label {
        cursor: pointer;
        margin-bottom: 0;
        width: 16px;
        height: 16px;
        @media screen and (max-width: 580px) {
          font-size: 10px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .icon-paper-clip-secondary {
      cursor: pointer;
      z-index: 10;
      font-size: 20px;
      color: $icon-subdued;
      @media screen and (max-width: 580px) {
        font-size: 10px;
      }
    }
  }
  .icon-emoji,
  .icon-send {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 20px;
    color: $icon-subdued;
    &:hover {
      color: $icon-hovered;
    }
  }
  .icon-emoji {
    right: 80px;
    @media screen and (max-width: 580px) {
      font-size: 10px;
      right: 58px;
    }
  }
  .icon-send {
    right: 24px;
    @media screen and (max-width: 580px) {
      font-size: 10px;
    }
  }

  &__cover {
    width: 50%;
    display: flex;
    max-height: 120px;
    overflow: hidden;

    i {
      color: #a7a9c0;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;
    max-width: 83%;
    width: max-content;
    margin-right: 8px;
    align-self: flex-start;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__loader {
    position: absolute;
    right: 25px;
    z-index: 1000;
  }
  &__textarea {
    width: 100%;
    height: 52px;
    padding: 16px 106px 16px 24px;
    border: 1px solid #d3d2e0;
    mix-blend-mode: normal;
    border-radius: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    outline: none;
    resize: none;
    transition: 0.2s border ease;
    @media screen and (max-width: 580px) {
      padding-top: 6px;
      padding-bottom: 6px;
      height: 36px;
      font-size: 10px;
    }
    &:focus {
      border: 1px solid $action-primary;
    }
  }
  &__textarea-wrap {
    width: 100%;
    position: relative;
  }
  &__list {
    display: flex;
    flex-flow: row;
    margin: 8px 0 0;
    padding: 0;
    list-style: none;
  }
  /deep/ {
    .emoji-mart {
      position: absolute;
      right: 0;
      bottom: 50%;
      width: 338px !important;

      @media (max-width: $sm) {
        width: 250px !important;
      }
      @media (max-width: $xs) {
        width: 220px !important;
      }

      .emoji-mart-preview {
        display: none !important;
      }

      .emoji-mart-title-label {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
  textarea {
    font-family: "SF", sans-serif !important;
  }

  .files__list {
    display: flex;
    flex-flow: row;
    margin: 8px 0 0;
    padding: 0;
    list-style: none;
  }
}
</style>
