<template>
  <div
    class="news-edit"
    :class="[createMode ? 'news-edit__new-post' : '', showHeader ? 'news-edit_mobile' : '']"
  >
    <ConfirmModal
      v-if="confirmCloseModal"
      :title="'Закрыть?'"
      :description="'Измененные данные не будут сохранены. Закрыть?'"
      @cancel="confirmCloseModal = false"
      @accept="closeEditMode"
    />
    <div class="news-edit__header-mobile" v-if="showHeader">
      <button class="news-edit__hide" type="button" @click="handleClose">
        <i class="icon-arrow-short"></i>
      </button>
      <h2 class="news-edit__title-mobile">
        {{ createMode ? "Создать новость" : "Редактировать новость" }}
      </h2>
    </div>
    <div class="news-edit__title">
      <Input v-model.trim="editTitle" :placeholder="'Название поста'" :maxlength="128" />
    </div>
    <div class="news-edit__container">
      <NewsImageGrid
        :images="imagesLink"
        :editMode="true"
        @news_grid_delete_item="newsGridDeleteItem"
      />
    </div>
    <div class="news-edit__container">
      <FilesComponent
        :files="allFiles"
        :showCross="true"
        :news="news"
        @files_splice="filesSplice"
        :editFiles="true"
      />
    </div>
    <div class="news-edit__content">
      <Textarea
        v-model.trim="editContent"
        :placeholder="'Максимум 10000 символов'"
        :maxlength="10000"
      />
    </div>
    <div class="news-edit__attachments">
      <i class="icon-emoji" @click.stop="showModalEmoji = !showModalEmoji"></i>
      <file-upload
        :accept="'.jpg, .jpeg, .png'"
        post-action="/upload/post"
        :multiple="true"
        v-model="images"
        class="news-edit__images"
        :class="{ 'news-edit__images-active': images.length }"
        input-id="newsImages"
        name="newsImages"
      >
        <i class="icon-add-photo"></i>
      </file-upload>
      <file-upload
        :accept="'.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
        post-action="/upload/post"
        :multiple="true"
        v-model="allFiles"
        class="news-edit__files"
        :class="{ 'news-edit__files-active': allFiles.length }"
        input-id="newsFiles"
        name="newsFiles"
      >
        <i class="icon-add-file"></i>
      </file-upload>
      <i
        class="icon-schedule-add"
        @click="setQuizMode"
        :class="{ 'icon-schedule-add-active': addQuiz }"
      ></i>
      <Picker
        v-if="showModalEmoji"
        set="apple"
        @select="addEmoji"
        :i18n="settings"
        class="news-edit__emojis"
        v-click-outside="closeModalEmojis"
      />
    </div>
    <slide-up-down :active="addQuiz">
      <div class="news-edit__quiz quiz">
        <div class="quiz__title">
          <div class="quiz__title-label">
            <p>Название опроса</p>
            <i class="icon-cross" @click="closeQuizMode"></i>
          </div>
          <Input
            :placeholder="'Название опроса'"
            :classList="'font16'"
            v-model.trim="editQuizTitle"
            :disabled="isNotEditableQuiz"
            :maxlength="128"
          />
        </div>
        <div class="quiz__items">
          <div class="quiz__item" v-for="(item, idx) in editQuizAnswers" :key="idx">
            <div class="quiz__item_wrap">
              <div class="quiz__item-header">
                <p>Вариант ответа {{ idx + 1 }}</p>
                <div class="quiz__item-control">
                  <i
                    class="icon-copy"
                    v-if="isAddQuizAnswer"
                    @click="addQuizAnswer(item.content)"
                  ></i>
                  <i
                    class="icon-delete"
                    v-if="isDeleteQuizAnswer"
                    @click="editQuizAnswers.splice(idx, 1)"
                  ></i>
                </div>
              </div>
              <Input
                :placeholder="'Введите вариант ответа'"
                :classList="'font13'"
                v-model.trim="editQuizAnswers[idx].content"
                :disabled="isNotEditableQuiz"
                :maxlength="128"
              />
            </div>
          </div>
          <div class="quiz__add-answer" v-if="isAddQuizAnswer" @click="addQuizAnswer('')">
            <span> <i class="icon-plus"></i>Добавить вариант</span>
          </div>
        </div>
        <div class="quiz__settings">
          <DateTimePicker v-model="editQuizDate" />
        </div>
      </div>
    </slide-up-down>
    <div class="news-edit__channel">
      <p>Канал</p>
      <Select
        :items="availableChannelList"
        :placeholder="'Не выбрано'"
        :showAvatar="false"
        :multiple="false"
        v-model="editChannels"
      />
    </div>
    <div class="news-edit__footer">
      <Button type="cancel" title="Отменить" @handleButton="handleClose" />
      <Button title="Сохранить" @handleButton="editAndPostNewsHandler" />
    </div>
  </div>
</template>

<script>
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import ClickOutside from "vue-click-outside";
import NewsImageGrid from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsImageGrid";
import FilesComponent from "@/modules/News/components/NewsItemsComponents/FilesComponent";
const FileUpload = () => import("vue-upload-component");
import SlideUpDown from "vue-slide-up-down";
const DatePick = () => import("vue-date-pick");
import "vue-date-pick/dist/vueDatePick.css";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import { mapActions, mapState } from "vuex";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import { Picker } from "emoji-mart-vue";
import Select from "@/modules/UiKit/components/Select/Select";
import DateTimePicker from "@/modules/News/components/NewsCreateAndEdit/NewsCreateAndEditComponent/DateTimePicker";
import Button from "@/modules/UiKit/components/buttons/Button";
import moment from "moment";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
export default {
  name: "NewsEdit",
  mixins: [uploadFilesMixin],
  props: {
    news: {
      type: Object,
      default: () => {
        return {};
      }
    },
    createMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConfirmModal,
    Button,
    FilesComponent,
    NewsImageGrid,
    FileUpload,
    SlideUpDown,
    Input,
    Textarea,
    Picker,
    Select,
    DateTimePicker
  },
  data() {
    return {
      editTitle: "",
      editContent: "",
      editQuizTitle: "",
      editQuizAnswers: [{ content: "" }, { content: "" }],
      editQuizAnonymous: false,
      buttonLoader: false,
      count: 0,
      loadImage: false,
      editChannels: [],
      editQuizTime: null,
      tempQuizTime: null,
      editQuizDate: null,
      addQuiz: false,
      quizId: null,
      isSetTime: false,
      allFiles: [],
      images: [],
      files: [],
      oldFilesId: [],
      imagesLink: [],
      showModalEmoji: false,
      confirmCloseModal: false,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {
    if (!this.createMode) this.setDefaultEdit();
  },
  methods: {
    ...mapActions("NewsModule", [
      "openFullSizeImage",
      "postNewsAction",
      "editNewsAction",
      "setNewsToUpdate"
    ]),
    isDateDisabled(date) {
      const currentDate = new Date();
      return date < currentDate.setHours(0, 0, 0, 0) || date === currentDate.setHours(0, 0, 0, 0);
    },
    handleClose() {
      if (
        this.editTitle.length ||
        this.editContent.length ||
        this.allFiles.length ||
        this.images.length ||
        this.editQuizTitle.length ||
        this.editQuizDate ||
        this.editQuizAnswers.some((item) => item.content.length) ||
        this.editChannels.length
      ) {
        this.confirmCloseModal = true;
      } else {
        this.closeEditMode();
      }
    },
    closeEditMode() {
      if (this.createMode) {
        this.$emit("closeNews");
      } else {
        this.$emit("closeNewsEditMode");
      }
    },
    changeSelectChannels(val) {
      this.editChannels = val;
    },
    closeModalEmojis() {
      this.showModalEmoji = false;
    },
    addEmoji(emoji) {
      let cursorPosition = document.querySelector(".textarea__control").selectionStart;
      let FirstPart = "";
      if (cursorPosition) {
        FirstPart = this.editContent.substring(0, cursorPosition);
      }
      let Emoji = emoji.native;
      let SecondPart = this.editContent.substring(cursorPosition + 1, this.editContent.length);
      this.editContent = FirstPart + Emoji + SecondPart;
      this.$emit("changeText", { value: this.editContent, index: this.index });
    },
    changeText(content) {
      this.editQuizAnswers[content.index].content = content.value;
    },
    filesSplice(val) {
      this.allFiles.splice(val, 1);
    },
    newsGridDeleteItem(val) {
      this.imagesLink.splice(val, 1);
      this.images.splice(val, 1);
    },
    changeQuizTitle(content) {
      this.editQuizTitle = content.value;
    },
    // Контроллер запросов
    openImageModal(link) {
      this.openFullSizeImage(link);
    },
    editAndPostNewsHandler() {
      this.files = [];
      this.files.push(this.allFiles, this.imagesLink);
      this.files = this.files.flat();
      if (!this.checkValidation()) return false;
      this.buttonLoader = true;
      this.$emit("setNewsLoading");
      this.fileUploader = [];
      this.oldFilesId = [];
      this.files.forEach((item) => {
        if (item.fileObject) {
          this.fileUploader.push(item);
        } else {
          this.oldFilesId.push(item.id);
        }
      });
      if (this.fileUploader.length && !this.fileUploadingId.length) {
        this.createPull(this.fileUploader);
        return false;
      }
      this.editAndPostNews();
    },

    // Когда все файлы загружены
    filesUploaded() {
      this.editAndPostNewsHandler();
    },
    // Отмена добавления опроса
    closeQuizMode() {
      this.addQuiz = false;
      this.quizId = null;
      this.editQuizAnswers = [{ content: "" }, { content: "" }];
      this.editQuizTitle = "";
      this.editQuizTime = null;
      this.editQuizDate = null;
      this.isSetTime = false;
    },

    // Добавление опроса
    setQuizMode() {
      if (this.addQuiz) {
        this.closeQuizMode();
      } else {
        this.addQuiz = true;
        this.isSetTime = false;
      }
    },

    // Добавление ответа на опрос
    addQuizAnswer(text) {
      this.editQuizAnswers.push({ content: text });
    },

    // Проверить наличие опроса и изменились ли данные опроса
    checkForPool() {
      if (this.addQuiz || this.isSetTime) {
        if (this.news.poll_id) {
          return (
            this.editQuizTitle !== this.news.poll.name ||
            JSON.stringify(this.editQuizAnswers) !== JSON.stringify(this.news.poll.answers) ||
            this.editQuizAnonymous !== this.news.poll.is_anonymous
          );
        }
      }
    },

    // Проверить наличие и измененилась ли дата окончания опроса
    checkDatePoll() {
      if (this.news.poll_id) {
        if (this.news.poll.end_datetime) {
          return (
            this.editQuizDate !== this.news.poll.end_datetime ||
            this.editQuizTime !== this.news.poll.end_datetime.split(" ")[1] ||
            this.tempQuizTime !== this.news.poll.end_datetime.split(" ")[1]
          );
        }
      }
    },

    // Редактирование новости
    editAndPostNews() {
      const data = {
        name: this.editTitle,
        content: this.editContent ? this.editContent : undefined,
        channel_id: this.editChannels[0].id,
        poll_id: this.quizId,
        attachment_files: this.oldFilesId.concat(this.fileUploadingId)
      };
      // Если данные блока "опрос" изменились или при редактировании добавлен опрос формируем объект для отправки на сервер
      if (this.checkForPool() || this.checkDatePoll() || !this.news.poll_id) {
        if (this.addQuiz && !this.isNotEditableQuiz) {
          if (this.editQuizDate !== null) {
            this.editQuizDate = moment(this.editQuizDate).format("YYYY-MM-DD HH:mm:ss");
          }
          data.poll = {
            name: this.editQuizTitle,
            is_anonymous: false,
            end_datetime: this.editQuizDate,
            answers: this.editQuizAnswers.map((item) => {
              return item.content;
            })
          };
        } else if (this.addQuiz && this.editQuizDate !== null) {
          this.editQuizDate = moment(this.editQuizDate).format("YYYY-MM-DD HH:mm:ss");
          data.poll = {
            name: this.editQuizTitle,
            is_anonymous: false,
            end_datetime: this.editQuizDate
          };
        }
      }

      if (this.createMode) {
        this.postNewsAction(data)
          .then(() => {
            this.$emit("closeParentCreateMode");
            this.$root.$emit("showNotification", {
              type: "success",
              timeout: 3000,
              label: `Новость успешно создана.`
            });
            this.$emit("closeNewsCreateMode");
            this.$emit("closeNewsLoading");
            this.buttonLoader = false;
          })
          .catch((error) => {
            this.$emit("closeNewsLoading");
            this.errorAlert(error);
          });
      } else {
        this.editNewsAction({ news: data, newsId: this.news.id })
          .then(() => {
            this.$root.$emit("showNotification", {
              type: "success",
              timeout: 3000,
              label: `Новость успешно отредактирована!`
            });
            // this.$root.$emit(`updateNewsItem_${this.news.id}`);
            this.setNewsToUpdate(this.news.id);
            this.$emit("closeNewsLoading");
            this.buttonLoader = false;
          })
          .catch((error) => {
            this.$emit("closeNewsLoading");
            this.errorAlert(error);
          });
      }
    },

    // Проверка валидации
    checkValidation() {
      if (this.editQuizDate !== null) {
        if (new Date() > new Date(this.editQuizDate)) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя создать опрос на прошедшее время`
          });
          return false;
        }
      }
      if (!this.editTitle.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите заголовок новости!`
        });
        return false;
      }
      if (!this.editContent.length && !this.addQuiz) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите содержание новости!`
        });
        return false;
      }
      if (isObjectEmptyChecker(this.editChannels)) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Выберите канал!`
        });
        return false;
      }

      // Проверка общего размера пула файлов
      if (this.allFiles.length) {
        let sumSize = 0;
        this.allFiles.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 104857600) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 100мб!`
          });
          return false;
        }
      }
      if (this.files.length > 10) {
        this.files = this.files.slice(0, 10);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 2000,
          label: `Нельзя прикрепить более 10 файлов!`
        });
        return false;
      }
      if (this.images.length) {
        let sumSize = 0;
        this.images.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 104857600) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 100мб!`
          });
          return false;
        }
      }

      if (this.addQuiz) {
        if (!this.editQuizTitle.length) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Введите тему опроса!`
          });
          return false;
        }

        // Проверка на заполненность вариантов ответов опроса
        let quizCheck = true;
        this.editQuizAnswers.forEach((item) => {
          if (!item.content.length) {
            quizCheck = false;
          }
        });
        if (!quizCheck) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Заполните все варианты ответа!`
          });
          return false;
        }
      }

      return true;
    },

    // Установка существующих параметров для редактирования
    setDefaultEdit() {
      this.editTitle = this.news.name;
      this.editContent = this.news.content;
      this.editChannels = [this.news.channel];
      if (this.news.attachment_files.length) {
        this.allFiles = this.news.attachment_files.filter((file) => {
          if (
            file.type !== "image/jpeg" &&
            file.type !== "image/png" &&
            file.extension !== "image/jpeg" &&
            file.extension !== "image/png"
          ) {
            return file;
          }
        });
        this.images = this.news.attachment_files.filter((file) => {
          if (
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.extension === "image/jpeg" ||
            file.extension === "image/png"
          ) {
            return file;
          }
        });
      }
      if (this.news.poll_id) {
        this.addQuiz = true;
        this.editQuizTitle = this.news.poll.name;
        this.editQuizAnswers = JSON.parse(JSON.stringify(this.news.poll.answers));
        this.editQuizAnonymous = this.news.poll.is_anonymous;
        this.quizId = this.news.poll_id;

        if (this.news.poll.end_datetime) {
          this.isSetTime = true;
          this.editQuizDate = this.news.poll.end_datetime;
          this.editQuizTime = this.news.poll.end_datetime.split(" ")[1];
          this.tempQuizTime = this.news.poll.end_datetime.split(" ")[1];
        }
      }
    }
  },
  computed: {
    ...mapState("NewsModule", ["channelList"]),

    // Проверка на возможность редактировать опрос
    isNotEditableQuiz() {
      if (this.news.poll && this.news.poll.count_users > 0) {
        return true;
      } else {
        return false;
      }
    },
    isAddQuizAnswer() {
      return this.editQuizAnswers.length < 10 && !this.isNotEditableQuiz;
    },
    isDeleteQuizAnswer() {
      return this.editQuizAnswers.length > 2 && !this.isNotEditableQuiz;
    },
    showHeader() {
      return window.innerWidth <= 767;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isAdmin() {
      return this.getDataInfoUser.roles.includes("admin");
    },
    availableChannelList() {
      return this.channelList.filter((item) => {
        if (this.isAdmin) {
          return item;
        } else {
          if (item.is_public && item.can_post) {
            return item;
          } else if (!item.is_public && item.can_post && item.current_channel_user) {
            return item;
          } else if (item.current_channel_user.is_admin) {
            return item;
          }
        }
      });
    }
  },
  watch: {
    images() {
      let imagesLink = [];
      let link = "";
      this.images.forEach((file) => {
        if (file.hasOwnProperty("link")) {
          link = file.link;
          imagesLink.push({ link, ...file });
        } else {
          let reader = new FileReader();
          reader.onload = (e) => {
            link = e.target.result;
            imagesLink.push({ link, ...file });
          };
          reader.readAsDataURL(file.file);
        }
      });
      if (this.images.length > 3) {
        this.images = this.images.slice(0, 3);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Нельзя прикрепить более 3 изображений или видео-файлов!`
        });
      }
      this.imagesLink = imagesLink;
    },
    allFiles() {
      let count = 0;
      let videos = [];
      let otherFiles = [];
      let allFiles = [];
      if (this.allFiles.length && this.allFiles.length > 10) {
        this.allFiles = this.allFiles.slice(0, 10);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 2000,
          label: `Нельзя прикрепить более 10 файлов!`
        });
      }
      this.allFiles.forEach((file) => {
        if (
          file.type === "video/quicktime" ||
          file.type === "video/mp4" ||
          file.type === "video/3gpp" ||
          file.type === "video/x-msvideo" ||
          file.type === "video/x-flv" ||
          file.type === "video/x-ms-wmv" ||
          file.type === "video/mpeg" ||
          file.type === "audio/mpeg" ||
          file.extension === "video/quicktime" ||
          file.extension === "video/mp4" ||
          file.extension === "video/3gpp" ||
          file.extension === "video/x-msvideo" ||
          file.extension === "video/x-flv" ||
          file.extension === "video/x-ms-wmv" ||
          file.extension === "audio/mpeg" ||
          file.extension === "video/mpeg"
        ) {
          videos.push(file);
        } else {
          otherFiles.push(file);
        }
      });
      if (videos.length > 3) {
        videos = videos.slice(0, 3);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Нельзя прикрепить более 3 изображений или видео-файлов!`
        });
        count++;
      }
      allFiles.push(videos, otherFiles);
      allFiles = allFiles.flat();
      if (count) this.allFiles = allFiles;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.news-edit {
  &__hide {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover i {
      color: $icon-hovered;
    }

    &:active i {
      color: $icon-pressed;
    }

    @media (max-width: $md) {
      display: inline-block;
    }
  }
  /deep/ {
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
    }
  }

  /deep/ {
    .loader {
      border: 3px solid #fff;
      border-right: 3px solid transparent;
      height: 32px;
      width: 32px;
    }
  }
  &__new-post {
    background-color: #fff;
    border-radius: 24px;
    padding-top: 40px;
    padding-bottom: 26px;
    margin-top: 40px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  }
  &__title {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 16px;
  }
  &__title-mobile {
    margin: 0;
  }

  &__header-mobile {
    position: sticky;
    top: -8px;
    z-index: 9;
    display: flex;
    align-items: baseline;
    background: $surface-default;
    width: 100%;
    padding: 0 24px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $border-disabled;

    .icon-sort-arrow {
      display: block;
      font-size: 20px;
      transform: rotate(90deg);
      margin-right: 20px;
      cursor: pointer;
      color: $icon-subdued;

      &:hover {
        color: $icon-hovered;
      }
    }
  }
  &__content {
    padding: 0 24px;
    margin-bottom: 8px;
  }
  &__attachments {
    position: relative;
    padding: 0 24px;
    margin-bottom: 16px;
    i {
      font-size: 20px;
      color: $icon-subdued;
      padding: 12px;
      cursor: pointer;
      transition: 0.2s all ease;

      @media (hover: hover) {
        &:hover {
          color: #5551f9;
          filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
            drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
            drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
        }
      }
    }
    /deep/ {
      .emoji-mart {
        @media (max-width: 895px) {
          width: 255px !important;
        }
        @media (max-width: $md) {
          width: 338px !important;
        }
        @media (max-width: $xs) {
          width: 250px !important;
        }
      }
    }
  }
  .icon-schedule-add-active {
    color: #5551f9;
    filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
      drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
      drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
  }
  &__emojis {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 100;
  }
  &__images {
    transform: translate(2px, 4px);

    @media (hover: hover) {
      &:hover .icon-add-photo {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }

    &-active {
      .icon-add-photo {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }
  &__files {
    transform: translate(4px, 4px);

    @media (hover: hover) {
      &:hover .icon-add-file {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }

    &-active {
      .icon-add-file {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }
  &__container {
    padding-left: 32px;
    padding-right: 32px;
  }
  &__wrap {
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: start;
    flex-shrink: 0;
    margin-top: 56px;
    padding-bottom: 14px;
  }
  &__list {
    display: flex;
    flex-flow: row;
    padding: 0;
    list-style: none;
    margin: 4px 0 0 32px;
  }
  &__channel {
    padding: 0 24px;
    p {
      font-size: 18px;
      margin-bottom: 24px;
      color: $text-default;
    }
  }
  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 30px 32px 0;
    @media (max-width: 895px) {
      padding: 30px 24px 0;
    }
    @media (max-width: $md) {
      padding: 30px 32px 0;
    }
    @media (max-width: $xs) {
      padding: 30px 24px 0;
    }
    /deep/ {
      .aop-button {
        @media (max-width: 895px) {
          padding: 14px 25px;
        }
        @media (max-width: $md) {
          padding: 14px 30px;
        }
        @media (max-width: $xs) {
          padding: 14px 23px;
        }
      }
    }
  }
  &__item {
    width: 40px;
    height: 40px;
    margin-right: 24px;
    background-color: #fafbfc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    rect,
    svg,
    path {
      transition: all 0.3s ease;
    }
    &_image {
      @media (hover: hover) {
        &:hover {
          background-color: #ebfcf6;
          path:first-child {
            fill: #9efbe8;
          }
          path:last-child {
            fill: #fff;
          }
        }
      }
    }
    &_image-active {
      background-color: #ebfcf6;
      path:first-child {
        fill: #9efbe8;
      }
      path:last-child {
        fill: #fff;
      }
    }
    &_file {
      @media (hover: hover) {
        &:hover {
          background-color: #fff5f5;
          path {
            fill: #ffc8d5;
          }
        }
      }
    }
    &_file-active {
      background-color: #fff5f5;
      path {
        fill: #ffc8d5;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    /deep/ {
      .file-uploads {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      label {
        cursor: pointer;
      }
    }
    i {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .quiz {
    padding: 0 24px 24px 24px;
    &__title {
      font-size: 16px;
      color: #756f86;
      margin-bottom: 24px;
      font-weight: 500;
      &-label {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
      }
      i {
        font-size: 24px;
        color: $icon-subdued;
        margin-left: auto;
        cursor: pointer;
        transition: 0.2s all ease;

        &:active {
          color: #5551f9;
          filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
            drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
            drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
        }
        @media (hover: hover) {
          &:hover {
            color: #5551f9;
            filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
              drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
              drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
          }
        }
      }
    }
    &__item {
      margin-bottom: 24px;
      &-control {
        margin-left: auto;
        i {
          transition: 0.2s all ease;
          &:active {
            color: #5551f9;
            filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
              drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
              drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
          }
          @media (hover: hover) {
            &:hover {
              color: #5551f9;
              filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
                drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
                drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
            }
          }
        }
      }
    }
    &__item-header {
      color: #756f86;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 21px;
      i {
        font-size: 24px;
        color: $icon-subdued;
        cursor: pointer;
      }
      .icon-copy {
        margin-left: auto;
      }
      .icon-delete {
        margin-left: 16px;
      }
    }
    &__add-answer {
      font-size: 14px;
      color: $chat-editor;
      font-weight: 500;
      margin-bottom: 24px;
      cursor: pointer;
      i {
        margin-right: 8px;
      }
    }
    &__settings {
      &-label {
        font-size: 16px;
        color: #756f86;
        margin-bottom: 24px;
        font-weight: 500;
      }

      /deep/ {
        .date-picker {
          &__value {
            @media (max-width: 895px) {
              width: 100%;
            }
            @media (max-width: $md) {
              width: 50%;
            }
            @media (max-width: $xs) {
              width: 100%;
            }
          }

          &__calendar {
            .vc-day-content {
              @media (max-width: 895px) {
                width: 30px;
                height: 30px;
              }
              @media (max-width: $md) {
                width: 40px;
                height: 40px;
              }
              @media (max-width: $xs) {
                width: 30px;
                height: 30px;
              }
            }

            .vc-arrows-container {
              @media (max-width: 895px) {
                width: 200px;
              }
              @media (max-width: $md) {
                width: 300px;
              }
              @media (max-width: $xs) {
                width: 200px;
              }
            }
            .vc-pane-layout {
              @media (max-width: $xs) {
                padding: 0 0 16px;
              }
            }
          }
        }
      }
    }
  }
  .vdpComponent.vdpWithInput {
    display: inline-flex;
    align-items: center;
    /deep/ {
      .vdpClearInput {
        outline: none;
        background-color: transparent;

        @media (hover: hover) {
          &:hover {
            &::before {
              box-shadow: none;
              transform: scale(0.95);
            }
          }
        }

        &::before {
          content: "";
          top: 49%;
          width: 15px;
          height: 15px;
          background-color: transparent;

          background-image: url("~@/assets/img/icons/clearInputDate.svg");
          border-radius: unset;
          border: none;
          transition: all 0.2s ease;
        }
      }
      input {
        font-family: "SF", sans-serif;
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        background-color: $background-active;
        color: $text-subdued;
        max-width: 145px;
        width: 100%;
        outline: none;
        border: none;
        transition: all 0.2s ease-in-out;
      }

      input[type="number"] {
        padding-left: 0;
        background-color: transparent;
      }

      .vdpComponent.vdpWithInput .vdpClearInput {
        display: none;
      }
      .vdpOuterWrap.vdpFloating {
        z-index: 1000;
      }
      .vdpOuterWrap .vdpInnerWrap {
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
        border-radius: 24px;
        max-width: 320px;
        min-width: 320px;
        z-index: 1000;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpHeader .vdpPeriodControls .vdpPeriodControl > button {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: $text-default;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpTable .vdpRow .vdpCell.selected .vdpCellContent {
        background: #6a67ce;
      }

      .vdpCell.outOfRange {
        font-size: 12px;
        line-height: 21px;
        color: #a0aec0;
      }

      .vdpOuterWrap {
        font-size: 12px;
        line-height: 21px;
        color: #2d3748;
        box-shadow: none;
      }
    }
  }
}
</style>
