


















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import FeedbackPanelNew from "@/components/FeedbackPanel/FeedbackPanelNew";
import { domainStorage } from "@/globalVariables";
import LoaderAvatar from "@/layout/SideNav/LoaderAvatar";
import { menuList } from "@/router/moduleRouters";
import Auth from "@/store/Auth";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import MainLink from "@/aopV2/layout/LayoutMain/MainSidebar/MainLink.vue";
import MainModules from "@/aopV2/layout/LayoutMain/MainSidebar/MainModules.vue";
import draggable from "vuedraggable";
import { mapState } from "vuex";

@Component({
  components: {
    draggable,
    MainModules,
    UserCard,
    LoaderAvatar,
    FeedbackPanelNew,
    MainLink
  },
  computed: mapState("Notification", ["isShowNotificationModal"])
})
export default class MainSidebar extends Vue {
  @Prop({
    type: Object,
    default: true
  })
  userInfo!: any;
  @Prop({
    type: Boolean,
    default: false
  })
  isNewDesign!: boolean;

  menuList: Array<any> = [];
  currentMenuList: Array<any> = [];
  settingMenuList: Array<any> = [];
  notificationItem: any = { label: "Уведомления", icon: "Bell" };
  helpItem: any = { label: "Обратная связь", icon: "Help" };
  exitItem: any = { label: "Выход", icon: "Exit" };
  adminItem: any = { menu: { label: "Админ-панель", icon: "Admin", name: "admin" } };
  windowWidth: number = window.innerWidth;
  search: string = "";
  fullMenu: boolean = false;
  settingMenu: boolean = false;
  editMode: boolean = false;
  localStorage: any = null;
  timerId: number = 0;
  topTooltip: number = 0;

  created() {
    this.getCookieValue();
  }

  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  }

  startNavigation(drag) {
    if (this.editMode) {
      drag = true;
    }
  }

  changeNavigation(event, drag) {
    drag = false;
    this.setCookieValue();
  }

  setCookieValue() {
    const option = {
      modules: this.settingMenuList,
      current: this.currentMenuList
    };
    this.localStorage.setItem("navigation", JSON.stringify(option));
  }
  getCookieValue() {
    this.localStorage = window.localStorage;
    const setting = JSON.parse(this.localStorage.getItem("navigation"));
    if (setting) {
      this.currentMenuList = setting.current;
      this.settingMenuList = setting.modules;
    } else {
      this.currentMenuList = menuList.map((item, index) => {
        return {
          ...item,
          order: index
        };
      });
      this.settingMenuList = [];
    }
  }

  setSettingList(val) {
    this.settingMenuList = val;
    this.setCookieValue();
  }

  toggleEditMode(val) {
    this.editMode = val;
  }

  toggleFullMenu(val) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.fullMenu = val;
      if (!val) {
        if (this.settingMenu) {
          this.fullMenu = true;
        } else {
          this.fullMenu = val;
          this.settingMenu = false;
          this.editMode = false;
        }
      }
    }, 300);
  }

  toggleSettingMenu() {
    if (!this.isMobile) {
      setTimeout(() => {
        this.settingMenu = !this.settingMenu;
      }, 600);
    }
  }

  resizeHandler() {
    this.windowWidth = window.innerWidth;
  }

  changeSwitch() {
    this.$store.commit("MainLayoutSetting/toggleDarkMode");
  }

  returnSrcFile(src) {
    return domainStorage + src;
  }

  logout() {
    Auth.logout();
  }

  handleFeedback() {
    this.$store.dispatch("bugsAndIdeasModule/openModal", true);
  }

  handleNotification(val: boolean) {
    this.$store.commit("Notification/toggleModal", !val);
    this.$store.dispatch("Notification/resetNotificationsAction");
  }

  handleMobileMenu() {
    if (this.isMobile) {
      this.fullMenu = !this.fullMenu;
    }
  }

  setIcon(val) {
    switch (val) {
      case "Messenger":
        return "messenger";
      case "Calendar":
        return "calendar";
      case "Bell":
        return "bell";
      case "News":
        return "news";
      case "Task":
        return "task";
      case "Workforce":
        return "workforce";
      case "Isp":
        return "isp";
      case "Vacation":
        return "vacation";
      case "Statistics":
        return "statistics";
      case "Section":
        return "books";
      case "Help":
        return "question";
      case "Exit":
        return "exit-door";
      case "Admin":
        return "setting";
      case "Profile":
        return "profile";
      default:
        return "";
    }
  }

  get isDarkMode() {
    return this.$store.getters["MainLayoutSetting/isDarkMode"];
  }

  get isMobileWindowSize() {
    return this.windowWidth <= 768;
  }

  get adminPanel() {
    return this.$store.getters.getDataInfoUser.roles.find(
      (role) => role === "rights_admin" || role === "personnel_officer" || role === "admin"
    );
  }

  get showSidebar() {
    return !(this.$route.query.chat_id && this.isMobileWindowSize);
  }

  get showFullMenu() {
    return this.fullMenu || this.isMobile;
  }

  get countNotificationList() {
    return this.$store.state.Notification.notificationListCountUnread;
  }

  get isMobile() {
    return this.windowWidth < 568;
  }
}
