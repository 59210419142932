






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonIcon extends Vue {
  @Prop({
    default: "plus",
    type: String
  })
  icon!: string;
  @Prop({
    default: 0,
    type: Number
  })
  tabindex!: number;

  get iconClass() {
    return `icon-${this.icon}`;
  }
}
