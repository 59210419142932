<template>
  <div class="modal__comment comment">
    <div class="comment__header">
      <img :src="returnSrcFile(comment.user.avatar)" alt="" class="comment__avatar" />
      <div class="comment__text">
        <p class="comment__author">{{ comment.user.full_name }}</p>
        <p class="comment__body" v-html="setURL(comment.comment)"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import UrlMixin from "@/helpers/UrlMixin";

export default {
  name: "ModalComment",
  mixins: [UrlMixin],
  props: {
    comment: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.comment {
  display: flex;
  flex-flow: column;
  &__header {
    display: flex;
  }
  &__avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 18px;
  }
  &__author {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $text-default;
  }
  &__data {
    margin-top: 3px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__body {
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    max-width: 554px;
    margin-top: 12px;
    margin-bottom: 24px;
    white-space: pre-wrap;

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:active {
        color: $link-hovered;
      }
    }
  }
}
</style>
