
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LabelTooltip extends Vue {
  @Prop({
    default: "",
    type: String
  })
  tooltip!: string;
  @Prop({
    default: () => [],
    type: Array
  })
  tooltipList!: Array<string>;
  @Prop({
    default: true,
    type: Boolean
  })
  isList!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isSingleLine!: boolean;
}
