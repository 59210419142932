<template>
  <div class="isp-team-short-title" v-click-outside="editTitle">
    <p
      class="isp-team-short-title-editable"
      v-show="!editMode"
      @click="setEditMode"
      v-if="!currentTeam.is_archived && isEditable"
    >
      {{ currentTeam.alias }}
    </p>
    <p v-else>{{ currentTeam.alias }}</p>
    <div class="isp-team-info__edit" v-show="editMode">
      <Input
        :placeholder="'От 2 до 5 символов, латиницей'"
        maxlength="5"
        minlength="2"
        v-model="editableShortTitle"
        @keydownEnter="editTitle"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Input from "@/modules/UiKit/components/Inputs/Input";
import { mapActions, mapState } from "vuex";
export default {
  name: "IspTeamShortTitle",
  mixins: [],
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  components: { Input },
  data() {
    return {
      editMode: false,
      editableShortTitle: ""
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["editTeamAction"]),
    setEditMode() {
      this.editableShortTitle = this.currentTeam.alias;
      this.editMode = true;
    },
    editTitle(e) {
      // const parentNode = e ? e.path.find((item) => item.className === "isp-team-info") : null;
      if (this.editMode) {
        const editedTeam = {
          alias: this.editableShortTitle,
          member_users: this.currentTeam.member_users.map((item) => item.id),
          leader_user_id: this.currentTeam.leader_user_id
        };
        this.editTeamAction({ editedTeam, id: this.currentTeam.id }).then(() => {
          this.editMode = false;
          this.currentTeam.alias = this.editableShortTitle;
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Сокращенное название команды успешно изменено!`
          });
        });
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeam"])
  },
  watch: {
    currentTeam() {
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
.isp-team-short-title {
  font-size: 18px;
  height: 15px;
  color: #787996;
  width: 100%;
  p {
    width: fit-content;
    padding: 2px 8px;
    border-radius: 5px;
    border: 1px solid #fff;
  }
  &-editable {
    &:hover {
      border: 1px solid #d2d2d9;
      box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    }
  }
  /deep/ {
    input {
      width: 100%;
    }
  }
}
</style>
