<template>
  <div class="search-message" @click="handleClickMessage">
    <div class="search-message__avatar">
      <img class="search-message__logo" :src="returnSrcFile(message.userInfo.avatar)" alt="" />
    </div>
    <div class="search-message__wrap">
      <span class="search-message__name">
        <span>{{ message.userInfo.full_name }}</span>
        <span class="search-message__time">{{ setTime }}</span>
      </span>
      <span class="search-message__text"> {{ setText }} </span>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import moment from "moment";
import goToMessage from "@/modules/Messenger/mixins/goToMessage.mixin";

export default {
  name: "SearchMessageItem",
  mixins: [goToMessage],
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleClickMessage() {
      this.goToReplyMessage(this.message.id, "#list-dynamic");
    }
  },
  computed: {
    setText() {
      return this.message.text.length ? this.message.text : "file";
    },
    setTime() {
      moment.locale("ru");
      if (moment().format("L") === moment(this.message.created_at).format("L")) {
        return moment(this.message.created_at).format("LT");
      } else {
        return moment(this.message.created_at).format("L");
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.search-message {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  @media (hover: hover) {
    &:hover {
      background-color: $message;
    }
  }
  &__avatar {
    margin-right: 8px;
    height: 40px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    background-color: $message;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $system-grey-seven;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14vw;
  }
  &__name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    padding: 0;
    margin: 0 0 11px;
    width: 100%;
  }
  &__time {
    margin-left: auto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $text-disabled;
  }
}
</style>
