import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";
import { EventBus } from "@/main";

export type editDataType = {
  id: number;
  name?: string;
  description?: string;
};
export type completeType = {
  id: number | string;
  is_completed: boolean;
};

class StageService extends Service {
  createStage(stageToCreate): Response<any> {
    return httpClientV2({
      method: "POST",
      url: `/task_manager/stages/create`,
      data: stageToCreate
    }).then((response) => {
      return response.data;
    });
  }
  getAll(params): Response<any> {
    return httpClientV2
      .get("/task_manager/stages/index", {
        params
      })
      .then((resp) => {
        return resp.data;
      });
  }
  editStage(data: editDataType): Response<any> {
    return httpClientV2.patch("/task_manager/stages/update", { ...data }).then((resp) => {
      return resp.data.success;
    });
  }
  completeStage(data: completeType): Response<any> {
    return httpClientV2.patch("/task_manager/stages/complete", { ...data }).then((resp) => {
      if (resp.data.success)
        EventBus.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: data.is_completed ? `Этап успешно закрыт!` : "Статус этапа успешно изменен!"
        });
      return resp.data.success;
    });
  }
  deleteStage(id): Response<any> {
    return httpClientV2.delete("task_manager/stages/delete", {
      params: {
        id
      }
    });
  }
  checkTaskComplete(stage_id): Response<any> {
    return httpClientV2
      .get("task_manager/tasks/check_uncompleted_by_stage_id", {
        params: {
          stage_id
        }
      })
      .then((resp) => {
        return resp.data;
      });
  }
  getStage(id: string | number): Response<any> {
    return httpClientV2
      .get("task_manager/stages/show", {
        params: {
          id
        }
      })
      .then((resp) => {
        return resp.data;
      });
  }
}

export default new StageService();
