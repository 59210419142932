<template>
  <div class="book-bg">
    <div v-if="!loader && !getEditMode" class="book-page-bg">
      <div class="book-page-breadcrumbs" ref="headerContent">
        <Widget type="bread-crumbs" :crumbs="crumbs" />
      </div>
      <div class="book-page-content" v-if="!loader && !getEditMode">
        <div class="book-header">
          <div class="book-tags">
            <div class="tag" v-for="tag in bookData.tags" :key="tag.id">{{ tag.name }}</div>
          </div>
          <div class="header-buttons" v-if="isAdmin || bookData.creator.id === getUserId">
            <i class="icon-delete" @click="deleteBook"></i>
            <i class="icon-edit edit" @click="$store.commit('changeEditMode', true)"></i>
          </div>
        </div>
        <div class="book-content">
          <img :src="returnSrcFile(bookData.cover)" alt="cover" />
          <div class="book-info">
            <div class="book-info-name">{{ bookData.name }}</div>
            <div class="book-info-author">{{ bookData.authors }}</div>
            <LikeComponent
              :parentLike="bookData"
              :likeableType="'books'"
              @updateLikeInfo="getBookInfo"
            />
            <div class="book-info-description" v-html="setURL(bookData.description)"></div>
          </div>
        </div>
        <div class="book-comments">
          <div class="comments-count">Комментарии ({{ countComments }})</div>
          <div class="comments-content">
            <div class="comments-bg" v-for="comment in commentsList.allItems" :key="comment.id">
              <Comment
                @setReplyComment="setReplyComment"
                @updateCommentsList="getCommentsBookInfo"
                :comment="comment"
                :admin="isAdmin"
                :creator="bookData.creator.id === getUserId"
                :reply="true"
              />
            </div>
            <div
              class="comment-view-all"
              v-if="commentsList.response.total_count > commentsList.response.per_page"
              @click="expendCommentLength"
            >
              Показать все комментарии
            </div>
            <div class="comments-empty" v-if="!commentsList.allItems.length">
              Будь первым, кто оставит комментарий :)
            </div>
          </div>
          <CommentComponent
            v-bind="{
              typeComment: 'books',
              commentableId: bookData.id,
              parent: replyComment
            }"
            @updateCommentsList="getCommentsBookInfo"
            @cancelReply="replyComment = null"
          />
        </div>
      </div>
    </div>
    <div class="button-scroll-top" @click="goToTop" v-show="scrollTopButton">
      <i class="icon-up"></i>
    </div>
    <BookEditComponent
      @updateBookInfo="getBookInfo"
      :bookData="bookData"
      v-if="!loader && getEditMode"
    />
    <div class="loading-content" v-show="loader && !getEditMode">
      <Widget
        v-bind="{
          type: 'loader'
        }"
      />
    </div>
  </div>
</template>

<script>
import { HTTPRequest, Model } from "@frontend-modules/fsql";
import { domain, domainStorage } from "@/globalVariables";
import Swal from "sweetalert2";

import CommentComponent from "@/components/KnowledgeBaseComponents/CommentComponents/CommentComponent";
import Comment from "@/components/KnowledgeBaseComponents/CommentComponents/Comment";
import Widget from "@/components/Widgets/Widget";
const BookEditComponent = () =>
  import("@/components/KnowledgeBaseComponents/Book/BookEditComponent");
import LikeComponent from "@/components/KnowledgeBaseComponents/LikeComponents/LikeComponent";
import UrlMixin from "@/helpers/UrlMixin";
import { mapGetters } from "vuex";

const swal = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});
export default {
  name: "BookPage",
  mixins: [UrlMixin],
  props: [],
  components: { LikeComponent, BookEditComponent, Widget, Comment, CommentComponent },
  data() {
    return {
      loader: true,
      bookData: {},
      commentsList: [],
      replyComment: null,
      commentsLength: 1,
      countComments: 0,
      scrollTopButton: false,
      crumbs: [
        {
          title: "Главная",
          linkName: "Section"
        },
        {
          title: "Книги",
          linkName: "Books"
        }
      ]
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.getBookInfo();
    window.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleScroll() {
      if (window.scrollY > 1000) {
        this.scrollTopButton = true;
      } else {
        this.scrollTopButton = false;
      }
    },
    async getBookInfo() {
      const Book = new HTTPRequest();
      Book.send(
        `${domain}/monolit/Book/actionGetItem/${
          this.$route.params["book_id"]
        }?_with=${JSON.stringify([
          "categories",
          "tags",
          "creator",
          "comments",
          "likes",
          "current_user_like"
        ])}`,
        "GET"
      )
        .then((resp) => {
          this.bookData = resp.data.data;
          if (this.crumbs.length < 3) {
            this.crumbs.push({
              title: this.bookData.name
            });
          }
          this.getCommentsBookInfo();
        })
        .catch((error) => this.errorAlert(error));
    },
    async getCommentsBookInfo() {
      const Comments = new Model({
        model: "Comment",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            commentable_id: this.bookData.id,
            commentable_type: "books",
            _order: { datetime_of_creation: "asc" },
            _count: this.commentsLength,
            _with: JSON.stringify(["replies", "likes", "author", "attachment_files"])
          }
        }
      });
      await Comments.init();
      let count = Comments.allItems.reduce(
        (accumulator, item) => accumulator + item["count_comments"],
        0
      );
      this.countComments = Comments.response.total_count + count;
      this.commentsList = Comments;
      this.loader = false;
    },
    expendCommentLength() {
      this.commentsLength = 300;
      this.getCommentsBookInfo();
    },
    setReplyComment(comment) {
      this.replyComment = comment;
      document
        .getElementsByClassName("comment-bg")[0]
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    deleteBook() {
      swal
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить страницу?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            const Book = new HTTPRequest();
            Book.send(`${domain}/monolit/Book/actionDelete/${this.bookData.id}`, "DELETE").then(
              () => {
                this.$router.go(-1);
                this.$root.$emit("showNotification", {
                  type: "success",
                  timeout: 5000,
                  label: "Книга успешно удалена!"
                });
              }
            );
          }
        });
    },
    goToTop() {
      this.$refs.headerContent.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("knowledge_base_admin")
        : false;
    },
    getEditMode() {
      return this.$store.getters.getEditMode;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.book-bg {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;

  .book-page-bg {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    @media screen and (max-width: $lg) {
      margin-left: 13px;
    }

    .book-page-breadcrumbs {
      margin-bottom: 15px;
    }

    .book-page-content {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      padding: 30px;
      background: $color-charts-grey-3;
      border: 1px solid $color-contour;
      border-radius: 10px 10px 0 0;

      @media (max-width: $sm) {
        padding: 20px;
      }

      .book-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;

        .book-tags {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .tag {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 13px;
            color: $color-interactive;
            padding: 4px;
            border: 1px solid $color-interactive;
            border-radius: 3px;
            margin-right: 6px;
            flex-shrink: 0;
            margin-bottom: 10px;

            @media (max-width: $xs) {
              max-width: 165px;
              word-break: break-word;
            }
          }
        }

        .header-buttons {
          display: flex;

          .icon-edit,
          .icon-delete {
            cursor: pointer;
            transition: all 0.2s ease;
            color: $color-interactive;
            font-size: 22px;

            @media screen and (max-width: $sm) {
              font-size: 18px;
            }

            &:not(:last-child) {
              margin-right: 15px;

              @media screen and (max-width: $sm) {
                margin-right: 10px;
              }
            }

            @media (hover: hover) {
              &:hover {
                color: $color-button-hover;
              }
            }

            &:active {
              color: $color-button-click;
            }
          }
        }
      }

      .book-content {
        display: flex;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $color-contour;

        @media (max-width: $md) {
          flex-wrap: wrap;
          justify-content: center;
        }

        /deep/ {
          a {
            color: $text-default;
          }

          a:hover,
          a:focus {
            color: $text-subdued;
          }
        }

        img {
          max-width: 340px;
          max-height: 500px;
          object-fit: cover;
          border: 1px solid $color-contour;
          filter: drop-shadow(2px 5px 8px rgba(208, 208, 255, 0.2));
          border-radius: 10px;
          margin-right: 30px;
          flex-basis: 70%;

          @media (max-width: $md) {
            margin: 0 0 20px;
          }

          @media (max-width: $sm) {
            max-width: 100%;
          }
        }

        .book-info {
          display: flex;
          flex-direction: column;
          width: 100%;

          &-name {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $color-text-accent;
            margin-bottom: 10px;
            width: 100%;
            word-break: break-word;
          }

          &-author {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-text-primary-2;
            margin-bottom: 20px;
            width: 100%;
            word-break: break-word;
          }

          &-description {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: $color-text-primary-1;
            margin-top: 20px;
            width: 100%;
            word-break: break-word;
            white-space: pre-wrap;

            /deep/ {
              a {
                color: $link-default;
              }

              a:hover,
              a:focus {
                color: $link-hovered;
              }
            }
          }
        }
      }

      .book-comments {
        display: flex;
        flex-direction: column;

        .comments-count {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-text-accent;
          margin-bottom: 30px;
        }

        .comments-content {
          display: flex;
          flex-direction: column;

          .comments-bg {
            display: flex;
            flex-direction: column;
          }

          .replies {
            display: flex;
            flex-direction: column;
            padding-left: 40px;
          }
        }

        .comment-view-all {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: $color-interactive;
          user-select: none;
          cursor: pointer;
        }

        .comments-empty {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-other-2;
          margin-bottom: 15px;
        }
      }
    }
  }

  .button-scroll-top {
    position: fixed;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddddff;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
    color: $color-interactive;
    font-size: 20px;
  }

  @media (hover: hover) {
    .button-scroll-top:hover {
      box-shadow: 0 4px 5px rgba(221, 221, 255, 0.62);
      color: $color-button-hover;
    }
  }

  .loading-content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20%;
  }
}
</style>
