








import { Component, Prop, Mixins } from "vue-property-decorator";
import { UrlClassMixin } from "@/helpers/UrlClassMixin";

@Component
export default class TextField extends Mixins(UrlClassMixin) {
  @Prop({
    default: "p",
    type: String
  })
  tag!: string;
  @Prop({
    default: "",
    type: String
  })
  text!: string;
  @Prop({
    default: "display-bold-large",
    type: String
  })
  className!: string;
}
