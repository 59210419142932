
















import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import moment from "moment";

@Component
export default class EventCardMonth extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  event!: any;
  @Prop({
    default: false,
    type: Boolean
  })
  isFull!: boolean;
  colorList = {
    0: "primary",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10"
  };

  get timezone() {
    return CalendarModule2.timezone;
  }

  async getEvent(id) {
    CalendarModule2.setLoader(true);
    await CalendarModule2.getEvent({
      id: id,
      timezone: this.timezone
    }).then((error) => {
      if (error === 404 || error === 422 || error === 403 || error === 400) {
        this.$router.push({ name: "Calendar" });
        CalendarModule2.toggleViewModal(false);
      }
    });
    CalendarModule2.setLoader(false);
  }

  openEvent() {
    this.getEvent(this.event.id);
    CalendarModule2.toggleEventList(null);
    CalendarModule2.toggleViewModal(true);
  }

  get setEventTime() {
    return `${moment(this.event.started_at).format("HH:mm")} – ${moment(this.event.ended_at).format(
      "HH:mm"
    )}`;
  }

  get isLocation() {
    return this.event.location_id;
  }

  get getEventType() {
    return this.eventTypes.find((item) => item.id === this.event.type_id);
  }

  get eventTypes() {
    return CalendarModule2.eventType;
  }

  get color() {
    return this.colorList[this.getEventType.color_number];
  }

  get locations() {
    return CalendarModule2.locations;
  }

  get setStyleClasses() {
    return [`event-card-month_${this.color}`];
  }

  get eventLocation() {
    const location = this.locations.find((item) => item.id === this.event.location_id);
    return location ? location.name : "";
  }
}
