export default class ProblemDto {
  static problem;
  constructor(problem) {
    this.id = problem.id;
    this.name = problem.name;
    this.description = problem.description;
    this.expected_result = problem.expected_result;
    this.report = problem.report;
    this.group_id = problem.group_id;
    this.is_archived = problem.is_archived;
    this.attachment_files = problem.attachment_files.length
      ? problem.attachment_files.map((item) => (item.id ? item.id : item))
      : [];
    this.customer_user_id = problem.customer_user_id;
    this.responsible_user_id = problem.responsible_user_id;
    this.criticality_id = problem.criticality_id;
    this.flow_status_id = problem.flow_status_id;
    this.period_id = problem.period_id;
  }
}
