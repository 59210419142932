









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { domainStorage } from "@/globalVariables";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap.vue";

@Component({
  components: { IcoMoonWrap }
})
export default class UserCardList extends Vue {
  selected: Array<any> = [];
  itemsSelect: Array<any> = [];
  @Prop({
    default: () => {},
    type: Object
  })
  user!: any;

  @Prop({
    default: "",
    type: String
  })
  title!: string;
  @Prop({
    default: false,
    type: Number
  })
  countUsersDisplayed!: any;
  isMemberList: boolean = false;
  mounted() {
    this.selected = this.user;
  }
  returnSrcFile(src) {
    return domainStorage + src;
  }

  showMembers() {
    if (this.user?.length > 3) {
      this.isMemberList = !this.isMemberList;
    }
  }
  hideMembers() {
    this.isMemberList = false;
  }
  get getMembers() {
    return this.user ? this.user.filter((item, index) => index < this.countUsersDisplayed) : [];
  }
  get isGlobalMobileMode() {
    return this.$store.getters.getGlobalMobileMode;
  }
}
