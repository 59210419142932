import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import { IStatus } from "@/modules/TaskManager/Model/Kanban/interface/interface";

type paramsUpdateCoordinatesType = {
  flow_id: number;
  coordinates: string;
  change_task_statuses: Array<{ from: number; to: number }>;
};

class FlowchartService extends Service {
  getCoordinates(id: number | string): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/flows/get`,
      params: {
        id
      }
    }).then((response) => {
      return response.data;
    });
  }
  updateCoordinates(data: paramsUpdateCoordinatesType): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/flows/update_coordinates`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  getStatuses(project_id: number | string): Response<any> {
    return httpClient({
      method: "GET",
      url: "/task_manager/flows/get_flow",
      params: {
        project_id
      }
    }).then((response) => {
      return response.data;
    });
  }
  createStatus(data: IStatus): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/flows/create_status`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  updateStatus(data: IStatus): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/flows/update_status`,
      data
    }).then((response) => {
      return response.data;
    });
  }
  deleteStatus(id: number): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/flows/delete_status`,
      data: {
        id
      }
    }).then((response) => {
      return response.data;
    });
  }
}

export default new FlowchartService();
