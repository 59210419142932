









import { Component, Vue } from "vue-property-decorator";

@Component
export default class ModalWithBG extends Vue {
  isVisible: boolean = true;

  handlerClick() {
    this.isVisible = false;
  }
}
