<template>
  <div class="drop-active" v-show="isShow">
    <div class="drop-active__wrap">
      <i class="icon-cloud-upload"></i>
      <h3 class="drop-active__title">Загрузите ваши файлы,</h3>
      <p class="drop-active__text">просто перетащив их мышкой</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropFileUpload",
  mixins: [],
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.drop-active {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    117.04deg,
    rgba(241, 239, 250, 0.4) 3.57%,
    rgba(218, 209, 255, 0.4) 94.08%
  );
  color: #fff;
  border: 1px dashed #d3d2e0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  i.icon-cloud-upload {
    color: #fff;
    font-size: 168px;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
    font-weight: 600;
    margin: 16px 0 0;

    padding: 0;
  }
  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $text-default;
    margin: 0;
    padding: 0;
  }
}
</style>
