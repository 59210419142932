export default {
  state: {
    createMode: false,
    editMode: false
  },
  mutations: {
    changeCreateMode(state, payload) {
      state.createMode = payload
    },
    changeEditMode(state, payload) {
      state.editMode = payload
    }
  },
  actions: {},
  getters: {
    getCreateMode(state) {
      return state.createMode
    },
    getEditMode(state) {
      return state.editMode
    }
  }
}
