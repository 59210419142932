<template>
  <ul class="select-list" tabindex="0">
    <li
      class="select-list__item"
      v-for="(item, index) in searchList"
      :key="index"
      :class="{ 'select-list__item_focus': index === focus }"
      @click="setCheckUser(item.nickname)"
    >
      <Avatar
        v-if="item.full_name"
        :user="item"
        :showImg="true"
        :showInfo="true"
        :showTag="false"
      />
      <span v-else class="select-list__tag">
        {{ item.nickname }}
      </span>
    </li>
  </ul>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
export default {
  name: "SelectList",
  mixins: [],
  props: {
    searchList: {
      type: Array,
      default: () => []
    },
    focus: {
      type: Number,
      default: 0
    }
  },
  components: { Avatar },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setCheckUser(name) {
      this.$emit("setCheckUser", name);
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.select-list {
  display: flex;
  flex-flow: column;
  list-style: none;
  margin: 0;
  position: absolute;
  bottom: 100%;
  background-color: #fff;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  padding: 0;
  &__item {
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    border-radius: 8px;
    @media (hover: hover) {
      &:hover {
        background-color: $system-grey-two;
      }
    }
    &_focus {
      background-color: $system-grey-two;
    }
  }
  &__tag {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }
}
</style>
