<template>
  <div class="progress">
    <p class="progress__percent" v-if="showNumber">{{ percent }}%</p>
    <div class="progress__wrap">
      <div
        class="progress__bar"
        :style="`width: ${percent}%`"
        :class="{ 'progress__bar-full': isFull }"
      >
        <p class="progress__percent" v-if="showNumberBar && percent >= 5">{{ percent }}%</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  mixins: [],
  props: {
    percent: {
      type: String || Number,
      default: 0
    },
    showNumber: {
      type: Boolean,
      default: true
    },
    showNumberBar: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    isFull() {
      return +this.percent === 100;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.progress {
  width: 100px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;

  &__wrap {
    width: 100%;
    padding: 0;
    margin: 0;
    background: #f2f2f7;
    border-radius: 117px;
    height: 12px;

    &_large {
      height: 16px;
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      270deg,
      #1bc78f 0%,
      #3be6bb 32.19%,
      #3bdede 66.89%,
      #875ef1 137.24%
    );
    border-radius: 15px;
    height: 100%;
  }

  &__percent {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #a0a4ab;
  }
}
</style>
