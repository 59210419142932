import { Module, VuexModule, MutationAction } from "vuex-module-decorators";
import UserService from "@/store/Users/UserService";
import { UsersList } from "@/store/Users/UserModel";
import { IBaseUser } from "@/store/Users/interfaces/interfaces";

@Module({ namespaced: true })
class UsersModule extends VuexModule {
  usersList: UsersList<IBaseUser> | null = null;

  @MutationAction
  async getUsers() {
    const usersList = await UserService.getAll();
    return { usersList };
  }

  @MutationAction
  async getUsersWithFields(withArray: Array<string>) {
    let usersList = this.usersList;
    let missingWith: Array<string> = [];
    if (usersList) {
      missingWith = withArray.filter((field) =>
        usersList?.users.some((user) => user[field] === undefined)
      );
    } else {
      missingWith = withArray;
    }
    if (missingWith.length !== 0) {
      // @ts-ignore
      usersList = await UserService.getUsersWithFields(missingWith);
      return { usersList };
    }
    return { usersList };
  }

  // getter usersList
  get userList() {
    return this.usersList;
  }

  get userById() {
    return (id) => this.usersList?.filterList("id", id);
  }
}

export default UsersModule;
