<template>
  <div class="modal">
    <div class="modal__window">
      <h2 class="modal__title">Редактировать дату завершения</h2>
      <div class="modal__date">
        <Widget
          type="InputDatePicker"
          :unique="'modal-date-of-end-editing'"
          :displayFormat="'DD.MM.YYYY'"
          :editValue="serviceWiths.vacationSchedule.date_of_end_editing"
        />
      </div>
      <div class="modal__footer">
        <button class="modal__button modal__button_cancel" type="button" @click="hideModal">
          Отменить
        </button>
        <button class="modal__button" type="button" @click="updateEditingInhibitDate">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import ClickOutside from "vue-click-outside";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
export default {
  name: "EditingInhibitDateModal",
  mixins: [],
  props: ["serviceWiths"],
  components: {
    Widget
  },
  data() {
    return {
      dateOfEndEditing: ""
    };
  },
  mounted() {
    this.$root.$on("date:change_modal-date-of-end-editing", (val) => {
      this.dateOfEndEditing = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("date:change_modal-date-of-end-editing");
  },
  methods: {
    updateEditingInhibitDate() {
      httpClient({
        url: `/VacationSchedule/actionUpdate?id=${this.serviceWiths.vacationSchedule.id}`,
        method: "PUT",
        data: [
          {
            year: this.serviceWiths.vacationSchedule.year,
            date_of_end_editing: this.dateOfEndEditing
          }
        ]
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Дата завершения редактирования успешно отредактирована!"
          });
          this.hideModal();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    hideModal() {
      this.$emit("hideModalEditingInhibitDate");
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  &__window {
    width: 367px;
    padding: 24px;
    background: $action-secondary;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 24px;
  }

  &__title {
    margin: 0 0 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-disabled;
  }

  &__date {
    position: relative;
    margin: 0 0 35px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $text-disabled;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #6a67ce;
      animation-name: border;
      animation-timing-function: ease;
      animation-duration: 0.5s;
      transform: scale(1);
      transform-origin: center;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }

    @keyframes border {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }

  /deep/ {
    .data-picker {
      background: transparent;
      max-width: 200px;

      @media (max-width: $xs) {
        font-size: 16px;
        padding: 12px 8px;
      }
    }
    .vdpComponent.vdpWithInput {
      input {
        border: none;
        max-width: 200px;
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: $text-disabled;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }

    .vdpComponent.vdpWithInput .vdpClearInput {
      display: none;
    }

    .vdpOuterWrap .vdpInnerWrap {
      box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
      border-radius: 24px;
      max-width: 320px;
      min-width: 320px;
    }

    .vdpOuterWrap .vdpInnerWrap .vdpHeader .vdpPeriodControls .vdpPeriodControl > button {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $text-default;
    }

    .vdpOuterWrap .vdpInnerWrap .vdpTable .vdpRow .vdpCell.selected .vdpCellContent {
      background: #6a67ce;
    }

    .vdpCell.outOfRange {
      font-size: 12px;
      line-height: 21px;
      color: #a0aec0;
    }

    .vdpOuterWrap {
      font-size: 12px;
      line-height: 21px;
      color: #2d3748;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }
    &_disabled {
      background: $action-critical-disabled;
      &:hover {
        background: $action-critical-disabled;
      }
    }
    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
