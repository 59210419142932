<template>
  <div class="adding-members__user" :style="{ width: size, height: size }">
    <router-link
      class="tooltip-item"
      :to="{ name: 'UserInfo', params: { user_id: user.id } }"
      :target="isGlobalMobileMode ? '_self' : '_blank'"
      :key="user.id"
    >
      <img
        class="adding-members__avatar"
        :src="returnSrcFile(user.avatar)"
        alt="Avatar"
        :style="{ borderColor: setBusy, width: size, height: size }"
      />
      <span
        class="icon-wrap"
        v-if="setStatus"
        :style="setStatus ? { backgroundColor: setColor } : ''"
      >
        <i :class="setStatus"></i>
      </span>
      <!--      <div class="adding-members__active" v-if="true"></div>-->
    </router-link>
    <p class="adding-members__tooltip" v-if="showTooltip">
      {{ user.full_name || user.user_name }}
      <button
        class="adding-members__delete"
        v-if="addMember || deleteMember"
        type="button"
        @click.self="$emit('deleteUser', user.id)"
      >
        Удалить
      </button>
    </p>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "AddingMembersItem",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    addMember: {
      type: Boolean,
      default: true
    },
    deleteMember: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "45px"
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    },
    setBusy() {
      if (this.user["is_busy"]) {
        return "#FB646D";
      } else {
        return "#fff";
      }
    },
    setColor() {
      switch (this.user["is_accepted_invitation"]) {
        case "yes": {
          return "#3BE8B0";
        }
        case "no": {
          return "#FB646D";
        }
      }
    },
    setStatus() {
      switch (this.user["is_accepted_invitation"]) {
        case "maybe": {
          return false;
        }
        case "yes": {
          return "icon-check";
        }
        case "no": {
          return "icon-cross";
        }
        default: {
          return false;
        }
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.adding-members {
  &__user {
    position: relative;
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    &:not(:first-child) {
      margin-left: -16px;
    }
    i {
      font-size: 12px;
      color: #fff;
    }
    .icon-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
    &:hover {
      .adding-members__tooltip {
        display: block;
      }
    }
  }
  &__active {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $interactive-success;
  }
  &__tooltip {
    display: none;
    position: absolute;
    top: -30px;
    left: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    z-index: 10;
    line-height: 20px;
    color: $surface-default;
    background: #343748;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    &:hover {
      display: block;
    }
  }

  &__avatar {
    flex-shrink: 0;
    display: block;
    margin-right: 8px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #f1effa;
    border: 2px solid $interactive-success;
  }

  &__delete {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    margin-left: 8px;
    text-decoration: underline;
    color: $surface-default;
  }
}
</style>
