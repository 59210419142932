













import { Component, Prop, Vue } from "vue-property-decorator";
import TabControlItem from "@/aopV2/components/UIComponents/TabControl/TabControlItem.vue";

export interface ITabItem {
  name: string;
  type: string;
  disabled: boolean;
}

@Component({
  components: { TabControlItem }
})
export default class TabControl extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  tabs!: Array<ITabItem>;
  @Prop({
    default: "",
    type: String
  })
  activeTab!: string;

  handleTab(type) {
    this.$emit("change", type);
  }
}
