<template>
  <div class="edit-message">
    <div
      class="edit-message__file"
      v-if="
        currentMessage && currentMessage.files_in_message && currentMessage.files_in_message.length
      "
    >
      <img
        v-if="isImage"
        :src="returnSrcFile(currentMessage.files_in_message[0].path)"
        :alt="currentMessage.files_in_message[0].name"
      />
      <i class="icon-document-text" v-else></i>
    </div>
    <div class="edit-message__wrap" v-if="currentMessage && !pickedMessage.length">
      <h4 class="edit-message__title">
        <span>{{ setType }} [{{ currentMessage.userInfo.full_name }}]</span>
        <i class="icon-cross" @click="closeEdit"></i>
      </h4>
      <p class="edit-message__text">
        {{ setText }}
      </p>
    </div>
    <div class="edit-message__wrap" v-if="pickedMessage.length">
      <h4 class="edit-message__title">
        <span>{{ setAuthorsPickedMessage }}</span>
        <i class="icon-cross" @click="closeEdit"></i>
      </h4>
      <p class="edit-message__text">{{ pickedMessage.length }} Пересланных сообщений</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { domainStorage } from "@/globalVariables";

export default {
  name: "EditAndReplyMessage",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    if (this.threadMode) {
      const container = document.querySelector(".chat_comment");
      container.querySelector("#textEditor").focus();
    } else {
      document.querySelector("#textEditor").focus();
    }
  },
  methods: {
    ...mapActions("MessengerModule", ["closeEditAndReply"]),
    closeEdit() {
      this.closeEditAndReply();
    },
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "currentMessage",
      "currentChat",
      "replyMode",
      "editMode",
      "threadMode",
      "users",
      "pickedMessage"
    ]),
    setAuthor() {
      const user = this.users.find((item) => item.id === this.currentMessage.user_id);
      if (user) {
        return user;
      } else {
        return this.getDataInfoUser;
      }
    },
    setAuthorsPickedMessage() {
      const names = Array.from(new Set(this.pickedMessage.map((item) => item.userInfo.full_name)));
      return names.join(" и ");
    },
    setText() {
      if (this.currentMessage.text.length) {
        return this.currentMessage.text;
      } else if (!this.currentMessage.text && this.currentMessage.files_in_message.length) {
        return this.currentMessage.files_in_message[0].name;
      } else {
        return "";
      }
    },
    setType() {
      if (this.replyMode) {
        return "Ответить";
      }
      if (!this.replyMode && this.editMode) {
        return "Редактировать";
      } else {
        return "Переслать";
      }
    },
    isImage() {
      if (this.currentMessage.files_in_message.length) {
        return (
          this.currentMessage.files_in_message[0].type === "photo" ||
          this.currentMessage.files_in_message[0].type === "gif"
        );
      } else {
        return false;
      }
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.edit-message {
  position: relative;
  padding: 1px 9px 1px 1px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  max-height: 68px;
  &:before {
    content: "";
    position: absolute;
    width: 3px;
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    background-color: $chat-editor;
    border-radius: 4px 0px 0px 4px;
  }
  .icon-document-text {
    font-size: 36px;
    color: $chat-editor;
  }
  &__file {
    border-radius: 2px;
    margin-left: 13px;
    height: 66px;
    display: flex;
    align-items: center;
    img {
      width: 66px;
      height: 66px;
    }
  }
  &__wrap {
    width: 100%;
    margin-left: 9px;
  }
  .icon-cross {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        color: unset;
      }
    }
  }
  &__title {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $chat-editor;
    display: flex;
    justify-content: space-between;
    i {
      font-size: 16px;
      color: $text-disabled;
      margin-left: auto;
    }
  }
  &__text {
    display: -webkit-box;
    padding: 0;
    margin: 5px 0 0;
    font-size: 12px;
    line-height: 20px;
    color: $text-default;
    font-weight: 400;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
