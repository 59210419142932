<template>
  <div class="comment-bg" v-click-outside="hideModalEmoji">
    <textarea
      maxlength="255"
      ref="inputComment"
      type="text"
      v-model.trim="value"
      @input="mixin_autoResize_resize"
      @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
      @keydown.enter.prevent.exact="switchKeyEnter"
    ></textarea>
    <i
      class="icon-emoji"
      @click="showModalEmoji = !showModalEmoji"
      :style="showModalEmoji ? 'color: #8D96EC' : 'color: #B8B8CB'"
    ></i>
    <i class="icon-send" @click="sendComment"></i>
    <div v-if="parent" class="reply">
      Ответ {{ declinationName(parent.author.full_name) }}
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="cancelReply"
      >
        <path
          d="M10.0744 10.9109C10.0058 10.9795 9.92698 11.0172 9.83784 11.024C9.74869 11.0172 9.66983 10.9795 9.60126 10.9109L7.4618 8.77144L5.3532 10.88C5.28463 10.9486 5.20577 10.9863 5.11663 10.9932C5.02749 10.9863 4.94863 10.9486 4.88005 10.88L4.55091 10.5509C4.48233 10.4823 4.44805 10.4069 4.44805 10.3246C4.44805 10.2286 4.48233 10.1463 4.55091 10.0777L6.65951 7.96914L4.58176 5.8914C4.51319 5.82283 4.47891 5.7474 4.47891 5.66511C4.47891 5.56911 4.51319 5.48682 4.58176 5.41825L4.94177 5.05825C5.01034 4.98967 5.0892 4.95882 5.17834 4.96567C5.26749 4.95882 5.34635 4.98967 5.41492 5.05825L7.49266 7.13599L9.61155 5.0171C9.68012 4.94853 9.75898 4.91767 9.84812 4.92453C9.93727 4.91767 10.0161 4.94853 10.0847 5.0171L10.4138 5.34625C10.4824 5.41482 10.5167 5.49711 10.5167 5.59311C10.5167 5.6754 10.4824 5.75083 10.4138 5.8194L8.29496 7.93829L10.4344 10.0777C10.503 10.1463 10.5373 10.2286 10.5373 10.3246C10.5373 10.4069 10.503 10.4823 10.4344 10.5509L10.0744 10.9109Z"
          fill="#B8B8CB"
          stroke="#B8B8CB"
          stroke-width="0.5"
        />
      </svg>
    </div>
    <Picker
      v-show="showModalEmoji"
      set="apple"
      @select="addEmoji"
      title="Выберите Эмоджи"
      :i18n="settings"
    />
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import { HTTPRequest } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";
import { incline } from "lvovich";
import ResizeMixin from "@/helpers/ResizeMixin";

export default {
  name: "CommentComponent",
  mixins: [ResizeMixin],
  props: {
    typeComment: {
      type: [String, Number],
      default: ""
    },
    commentableId: {
      type: Number,
      default: undefined
    },
    attachmentFiles: {
      type: Array,
      default: []
    },
    parent: {
      type: Object,
      default: undefined
    }
  },
  components: { Picker },
  data() {
    return {
      value: "",
      showModalEmoji: false,
      selectedSettingsEditor: "Enter",
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {},
  methods: {
    addEmoji(emoji) {
      // selectionStart - Начало
      // selectionEnd - Конец
      let cursorPosition = this.$refs.inputComment.selectionStart;
      let FirstPart = this.value.substring(0, cursorPosition);
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition + 1, this.value.length);
      this.value = FirstPart + Emoji + SecondPart;
    },
    hideModalEmoji() {
      this.showModalEmoji = false;
    },
    async sendComment() {
      if (!this.value) return null;
      if (this.value.length > 255) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Комментарий не может быть больше 255 символов!`
        });
        return false;
      }
      const Comment = new HTTPRequest();
      Comment.send(`${domain}/monolit/Comment/actionCreate`, "POST", {
        content: this.value,
        commentable_type: this.typeComment,
        commentable_id: this.commentableId,
        attachment_files: this.attachmentFiles,
        parent_id: this.parent ? this.parent.id : undefined
      })
        .then(() => {
          this.value = "";
          this.$emit("updateCommentsList");
          this.$emit("cancelReply");
          document
            .getElementsByClassName("comment-bg")[0]
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$refs.inputComment.style.height = "30px";
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    cancelReply() {
      this.$emit("cancelReply");
    },
    declinationName(name) {
      return incline({ first: name.split(" ")[1] }, "dative").first;
    },
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    },
    switchKeyEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.sendComment();
      } else {
        this.newLine(event);
      }
    },
    switchKeyCtrlEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.newLine(event);
      } else {
        this.sendComment();
      }
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.comment-bg {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;
  .icon-emoji {
    position: absolute;
    right: 36px;
    cursor: pointer;
    font-size: 20px;
  }

  .icon-send {
    position: absolute;
    right: 6px;
    cursor: pointer;
    color: $color-interactive;
    font-size: 20px;
  }
  .icon-send:hover {
    color: $color-button-hover;
  }
  .icon-send:active {
    color: $color-button-click;
  }
  textarea {
    width: 100%;
    height: 30px;
    background: $color-button-light-1;
    border: 1px solid $color-contour;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    outline: none;
    padding: 0 63px 0 6px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-primary-1;
    resize: none;
  }
  .reply {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 100%;
    left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
    svg {
      cursor: pointer;
    }
  }
  /deep/ {
    .emoji-mart {
      position: absolute;
      right: 0;
      bottom: 100%;
      width: 338px !important;

      @media (max-width: $sm) {
        width: 250px !important;
      }
      @media (max-width: $xs) {
        width: 220px !important;
      }

      .emoji-mart-preview {
        display: none !important;
      }

      .emoji-mart-title-label {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
