<template>
  <transition name="show-modal">
    <div class="context-menu" ref="contextMenu" v-click-outside="hideModal">
      <ul class="context-menu__modal-list" @click="hideModal">
        <ContextMenuItem
          :item="item"
          v-for="(item, index) in contextMenuItemList"
          :key="item.actionType + index"
          :module="module"
        />
      </ul>
    </div>
  </transition>
</template>

<script>
import ContextMenuItem from "@/modules/UiKit/components/ContextMenu/ContextMenuItem";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";

export default {
  name: "ContextMenu",
  mixins: [],
  props: {},
  components: { ContextMenuItem },
  data() {
    return {};
  },
  mounted() {
    this.positionMenu(window.event);
    this.scrollContainerContextMenu.addEventListener("scroll", this.hideModal);
  },
  beforeDestroy() {
    this.scrollContainerContextMenu.removeEventListener("scroll", this.hideModal);
  },
  methods: {
    ...mapActions(["closeContextMenuAction"]),
    hideModal() {
      this.closeContextMenuAction();
    },
    positionMenu(e) {
      const clickCoordsX = e.clientX;
      const clickCoordsY = e.clientY;

      let menuPositionX = clickCoordsX + "px";
      let menuPositionY = clickCoordsY + "px";

      const menuWidth = this.$refs.contextMenu.offsetWidth + 4;
      const menuHeight = this.$refs.contextMenu.offsetHeight + 4;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (windowWidth - clickCoordsX < menuWidth) {
        menuPositionX = clickCoordsX - menuWidth + "px";
      }
      if (windowHeight - clickCoordsY < menuHeight) {
        menuPositionY = clickCoordsY - menuHeight + "px";
      }

      this.$refs.contextMenu.style.left = menuPositionX;
      this.$refs.contextMenu.style.top = menuPositionY;
    }
  },
  computed: {
    ...mapState(["contextMenuItemList", "scrollContainerContextMenu"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.1s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.context-menu {
  position: absolute;
  z-index: 15;
  padding: 16px;
  margin: 0;
  background-color: $surface-default;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 4px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
</style>
