import { render, staticRenderFns } from "./ReactionList.vue?vue&type=template&id=869ce80c&scoped=true&"
import script from "./ReactionList.vue?vue&type=script&lang=js&"
export * from "./ReactionList.vue?vue&type=script&lang=js&"
import style0 from "./ReactionList.vue?vue&type=style&index=0&id=869ce80c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "869ce80c",
  null
  
)

export default component.exports