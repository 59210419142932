<template>
  <div
    class="channels"
    v-closable="{
      exclude: [`channel-list`],
      handler: `hideChannelList`
    }"
  >
    <div class="channels__container">
      <div class="channels__header">
        <h2 class="channels__title">Каналы</h2>
        <ChosenFilter
          :chosenList="selectChannelsList"
          v-model="currentChannels"
          :icon="'view-day-small'"
          :placeholder="currentChannels[0].name"
          :allItem="false"
          @input="changeChannelsList"
        />
      </div>
      <Spinner v-if="loadingChannels" class="loader" />
      <div
        v-if="(channelList.length || channelAllList.length) && !loadingChannels"
        ref="channel_list"
        class="channels__channel-list channel-list"
        :class="windowWidth < 768 ? 'channels__channel-list_hide' : ''"
      >
        <ul class="channel-list__wrap channel-list__wrap_all" v-if="isAllChannel">
          <ChannelComponent
            v-for="channel in channelAllList"
            :key="channel.id"
            :channel="channel"
            :showTooltip="false"
            :showName="true"
            :activeChannel="activeChannel"
            @transferActiveChannel="setActiveChannel"
            :isAdmin="isAdmin"
            :memberInfoChannel="memberInfoChannel"
          />
          <li v-if="!channelAllList.length" class="channel__empty">Пусто :(</li>
        </ul>
        <ul class="channel-list__wrap" v-if="isMyChannel">
          <ChannelComponent
            v-for="channel in channelList"
            :key="channel.id"
            :channel="channel"
            :showName="true"
            :activeChannel="activeChannel"
            @transferActiveChannel="setActiveChannel"
            :isAdmin="isAdmin"
            :memberInfoChannel="memberInfoChannel"
            :showUnsubscribe="!isMyChannel"
          />
          <li v-if="!channelList.length" class="channel__empty">Пусто :(</li>
        </ul>
        <div class="channel-list__create">
          <Button
            :type="'white'"
            :title="'Создать канал'"
            :icon="'playlist-add'"
            @handleButton="openModalAddChanel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelComponent from "@/modules/News/components/Channel/ChannelComponent";
import ClickOutside from "vue-click-outside";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import Button from "@/modules/UiKit/components/buttons/Button";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

// Эта переменная будет содержать ссылку на обработчик кликов документа
let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    //Обработчик click / touchstart / contextmenu (он зарегистрирован ниже) - можно добавить другие события
    handleOutsideClick = (e) => {
      e.stopPropagation();

      //Эта директива принимает два значения: exclude и handler.
      // handler - указывает имя метода, который будет обрабатывать событие внешнего щелчка
      // exclude - массив ссылочных имен элементов, которые мы не хотим запускать событие внешнего щелчка (имя мы указываем в ref элемента
      const { handler, exclude } = binding.value;

      // Эта переменная указывает, исключен ли выбранный элемент
      let clickedOnExcludedEl = false;

      // Мы проверяем, не является ли выбранный элемент элементом диалога и не исключен
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // Если выбранный элемент находится за пределами диалогового окна, вызоваем обработчик внешних щелчков
        // из того же компонента, где эта директива используется
        vnode.context[handler]();
      }
    };

    // Зарегистрируем прослушиватели событий на всей странице
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
    document.addEventListener("contextmenu", handleOutsideClick);
  },
  unbind() {
    // Если элемент, имеющий v-closable, удален, то отключаем прослушиватели со всей страницы
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
    document.addEventListener("contextmenu", handleOutsideClick);
  }
});

export default {
  name: "ChannelsColumn",
  mixins: [],
  props: [
    "activeChannel",
    "channelList",
    "loadingChannels",
    "isAdmin",
    "memberInfoChannel",
    "filtersChannelList",
    "windowWidth"
  ],
  components: { Spinner, ChosenFilter, Button, ChannelComponent },
  data() {
    return {
      isAllChannel: false,
      isMyChannel: true,
      selectChannelsList: [
        { id: 1, name: "Мои каналы" },
        { id: 2, name: "Все каналы" }
      ],
      currentChannels: [{ id: 1, name: "Мои каналы" }]
    };
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["changeModalAddChannel"]),
    ...mapMutations("NewsModule", [types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL]),
    setActiveChannel(value) {
      this.$emit("setActiveChannel", value);
      this.$refs.channel_list.classList.add("channels__channel-list_open");
    },
    changeChannelsList() {
      switch (this.currentChannels[0].id) {
        case 1:
          this.isMyChannel = true;
          this.isAllChannel = false;
          this.$refs.channel_list.classList.add("channels__channel-list_open");
          break;
        case 2:
          this.isAllChannel = true;
          this.isMyChannel = false;
          this.$refs.channel_list.classList.add("channels__channel-list_open");
          break;
      }
    },
    hideChannelList() {
      if (this.windowWidth <= 768) {
        this.$refs.channel_list.classList.remove("channels__channel-list_open");
      }
    },
    openModalAddChanel() {
      this[types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL](true);
    }
  },
  computed: {
    ...mapState("NewsModule", ["channelAllList"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    showPrivateChannel() {
      return this.channelList.filter((item) => !item.is_public);
    },
    showPublicChannel() {
      return this.channelList.filter((item) => item.is_public);
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
  overflow-x: auto;
  width: 5px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  background: $disabled-gray;
  border-radius: 10px;
  width: 5px;
  padding-right: 10px;
}

::-webkit-scrollbar-track {
  margin: 0;
  background: $surface-disabled;
}

.channels {
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
  background: #ffffff;
  border-right: 1px solid #eae8f0;
  height: 100%;

  &__header {
    display: flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #eae8f0;
    padding: 18px 0 18px 24px;
    justify-content: space-between;

    /deep/ {
      .chosen-filter {
        width: 160px;
        @media (max-width: $xxxl) {
          width: 145px;
        }
        @media (max-width: $xl) {
          width: 50px;
        }
        &__name {
          font-size: 14px;
          @media (max-width: $xl) {
            display: none;
          }
        }
      }
    }
  }

  &__title {
    margin: 0 8px 0 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    @media (max-width: $xxxl) {
      font-size: 16px;
    }
    @media (max-width: $md) {
      font-size: 17px;
    }
  }

  /deep/ .form-group {
    margin: 0 36px 0 0;

    .icon-search {
      @media (max-width: $md) {
        top: 5px;
        font-size: 20px;
      }
    }

    &__container {
      margin: 0;
    }

    &__search {
      @media (max-width: $md) {
        height: 28px;
      }
    }

    &__input {
      @media (max-width: $md) {
        height: 28px;
        padding: 9px 10px 9px 40px;
        font-size: 9px;
        line-height: 11px;
      }
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
  &__list {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0 0 0 auto;
  }

  &__item {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: $text-disabled;
    cursor: pointer;

    @media (max-width: $md) {
      font-size: 13px;
    }

    &:hover {
      color: $text-subdued;
    }

    &_active {
      color: $text-default;
    }
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .channel__empty {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: $text-disabled;
    user-select: none;
  }

  /deep/.channel {
    &__empty {
      width: 80%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: $text-disabled;
      user-select: none;
    }

    &__subscribe {
      span {
        @media (max-width: $xxxl) {
          display: none;
        }
      }
    }
    &__subscribed {
      span {
        @media (max-width: $xxxl) {
          display: none;
        }
      }
    }
  }

  .channels__channel-list {
    display: flex;
  }
}

.channel-list {
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  &__wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    list-style: none;
    padding: 24px 0px 0 24px;
    overflow: auto;
    height: calc(100% - 60px);
    /deep/ .channel {
      margin-bottom: 24px;
    }
  }
  &__create {
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: start;
    /deep/ {
      .aop-button_white {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 0;
    margin: 0 24px 0 0;
    width: 24px;
    height: 32px;
    border: none;
    background: transparent;
    outline: none;
    @media (max-width: $md) {
      padding: unset;
    }
    i {
      color: $icon-subdued;
      font-size: 24px;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }
  }
}

@media (max-width: $xl) {
  .channels {
    .channels__channel-list {
      &__all-channels {
        font-size: 12px;
      }
      .channel-list {
        &-label {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
