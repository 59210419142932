



































import { Component, Prop, Vue } from "vue-property-decorator";
import MainRow from "@/components/MainTable/Row/MainRow.vue";
import CellContent from "@/components/MainTable/Cell/CellContent.vue";
import MainCell from "@/components/MainTable/Cell/MainCell.vue";
import MainCellHeader from "@/components/MainTable/Cell/MainCellHeader.vue";
import PaginationObserver from "@/components/PaginationObserver/PaginationObserver.vue";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";

export enum typeCell {
  string = "string",
  person = "person",
  date = "date",
  email = "email",
  number = "number",
  boolean = "boolean"
}

export interface IHeader {
  name: string;
  field: string;
  sort: boolean;
  isCenter: boolean;
  colspan: number;
  type: typeCell;
}

export interface ITableData {
  [key: string]: any;
}

@Component({
  components: { PaginationObserver, MainCellHeader, MainCell, CellContent, MainRow, Spinner }
})
export default class MainTable extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  tableHeader!: Array<IHeader>;
  @Prop({
    default: () => [],
    type: Array
  })
  tableData!: Array<ITableData>;
  @Prop({ type: Boolean, default: false }) loader!: boolean;

  intersected() {
    this.$emit("getTableDate");
  }

  handleSort(sort) {
    this.$emit("sortCell", sort);
  }

  get isEmptyTable() {
    return !this.tableData.length && !this.loader;
  }
  get isShowTableData() {
    return this.tableData.length && !this.loader;
  }
}
