<template>
  <div class="modal__footer">
    <button class="modal__button modal__button_cancel" type="button" @click="hideModal">
      Отменить
    </button>
    <button
      class="modal__button"
      type="button"
      v-if="!editMode && createMode"
      @click="createVacationOrder"
    >
      Создать
    </button>
    <button class="modal__button" type="button" v-if="editMode" @click="updateVacationOrder">
      Сохранить
    </button>
    <button
      class="modal__button modal__button_disabled"
      type="button"
      v-if="!createMode && !editMode"
      @click="updateVacationOrder"
      :disabled="true"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import moment from "moment";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalFooter",
  mixins: [],
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    request: {
      type: Boolean,
      required: true
    },
    createMode: {
      type: Boolean,
      required: true,
      default: false
    },
    vacation: {
      type: Object,
      default: {}
    }
  },
  components: {},
  data() {
    return {
      dataConfirmUser:
        this.vacation.confirmations.map((item) => ({
          index: item.index,
          user_id: item.user_id
        })) || [],
      dateStartVacation: this.vacation.date_from || "",
      dateEndVacation: this.vacation.date_to || "",
      reason: this.vacation.reason || ""
    };
  },
  mounted() {
    this.setRoots();
  },
  beforeDestroy() {
    this.disableRoots();
  },
  methods: {
    hideModal() {
      if (this.request) {
        if (this.vacation.hasOwnProperty("is_checked")) {
          this.$root.$emit("closeRequestConfirmation");
        } else {
          this.$root.$emit("closeMyConfirmations");
        }
      } else {
        this.$root.$emit("closeVacationRequestModal");
      }
    },
    setRoots() {
      this.$root.$on("setDataModal", (val) => {
        this.dataConfirmUser = val.dataConfirmUser;
      });
      this.$root.$on("date:change_modal-request-vacation-start-data", (val) => {
        this.dateStartVacation = val;
      });
      this.$root.$on("date:change_modal-request-vacation-end-data", (val) => {
        this.dateEndVacation = val;
      });
      this.$root.$on("vacationTransferReason", (val) => {
        this.reason = val;
      });
    },
    disableRoots() {
      this.$root.$off("setDataModal");
      this.$root.$off("vacationTransferReason");
      this.$root.$off("date:change_modal-request-vacation-start-data");
      this.$root.$off("date:change_modal-request-vacation-end-data");
    },
    createVacationOrder() {
      if (!this.checkValidationData()) return null;
      httpClient({
        url: `/TransferVacationOrder/actionCreate`,
        method: "POST",
        data: [
          {
            vacation_order_id: this.vacation.id,
            user_id: this.getUserId,
            new_date_from: this.dateStartVacation,
            new_date_to: this.dateEndVacation,
            reason: this.reason,
            confirmations: JSON.parse(JSON.stringify(this.dataConfirmUser))
          }
        ]
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на перенос отпуска успешно создан!"
          });
          this.$root.$emit("closeVacationRequestModal");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    updateVacationOrder() {
      if (!this.checkValidationData()) return null;
      httpClient({
        url: `/TransferVacationOrder/actionUpdate?id=${this.vacation.id}`,
        method: "PUT",
        data: [
          {
            id: this.vacation.id,
            user_id: this.getUserId,
            new_date_from: this.dateStartVacation,
            new_date_to: this.dateEndVacation,
            reason: this.reason,
            confirmations: JSON.parse(JSON.stringify(this.dataConfirmUser))
          }
        ]
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Запрос на отпуск отредактирован!"
          });
          this.$root.$emit("closeVacationRequestModal");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          if (error.response.data.data.error.message.date_from) {
            const msg = error.response.data.data.error.message.date_from[0];
            return this.$root.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `${msg}`
            });
          }
          this.errorAlert(error);
        });
    },
    checkValidationData() {
      if (!this.reason.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Укажите причину переноса!"
        });
        return false;
      }
      if (!moment(this.dateStartVacation, "YYYY-MM-DD").isValid()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите дату начала отпуска!"
        });
        return false;
      }
      if (!moment(this.dateEndVacation, "YYYY-MM-DD").isValid()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите дату конца отпуска!"
        });
        return false;
      }

      if (!this.dataConfirmUser.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Укажите подтверждающих лиц!"
        });
        return false;
      }

      if (this.dataConfirmUser.length > 3) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Подтверждающих лиц не может быть больше 3!"
        });
        return false;
      }

      if (
        moment(this.dateStartVacation, "YYYY-MM-DD") > moment(this.dateEndVacation, "YYYY-MM-DD")
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Дата начала должна быть раньше или равняться Дате окончания!"
        });
        return false;
      }

      if (
        moment(this.dateStartVacation).diff(moment().add(5, "day").format("YYYY-MM-DD"), "days") <=
        -5
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Дата начала должна быть позже ${moment().add(5, "day").format("YYYY-MM-DD")}!`
        });
        return false;
      }

      return true;
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

.modal {
  &__footer {
    border-top: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 30px 42px 42px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }
    &_disabled {
      background: $action-critical-disabled;
      &:hover {
        background: $action-critical-disabled;
      }
    }
    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
