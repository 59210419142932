<template>
  <div
    class="progress-widget"
    :style="{
      color: setProgressColor,
      background: setProgressBackground,
      border: `1px solid ${setProgressColor}`
    }"
  >
    {{ percentage }}%
  </div>
</template>

<script>
export default {
  name: "Progress",
  mixins: [],
  props: {
    percentage: {
      type: [String, Number],
      default: 0
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setProgressColor() {
      if (70 <= this.percentage) {
        return "#3BE8B0";
      } else if (50 <= this.percentage && this.percentage < 70) {
        return "#07B6AB";
      } else if (20 <= this.percentage && this.percentage < 50) {
        return "#FFB901";
      } else {
        return "#FB646D";
      }
    },
    setProgressBackground() {
      if (70 <= this.percentage) {
        return "#EBFCF6";
      } else if (50 <= this.percentage && this.percentage < 70) {
        return "#E8FFFD";
      } else if (20 <= this.percentage && this.percentage < 50) {
        return "#FFF8E5";
      } else {
        return "#FFECEC";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.progress-widget {
  display: block;
  width: max-content;
  height: 24px;
  padding: 4px 12px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}
</style>
