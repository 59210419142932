<template>
  <div class="education__widgets">
    <Widget
      type="select-new"
      :entity="'User'"
      :actionEntity="'getItems'"
      :itemName="'full_name'"
      :keyValue="'FilterUserListInEducationTable'"
      :label="'Сотрудник:'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterUser"
      :filterActive="!filterActive"
    />
    <Widget
      type="select-new"
      :entity="'EducationType'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterTypeListInEducationTable'"
      :label="'Вид образования:'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterTypeEducation"
      :class="'select-bg_type'"
    />
    <Widget
      type="select-new"
      :entity="'EducationInstitution'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterInstitutionListInEducationTable'"
      :label="'Учебное заведение:'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterInstitution"
      :class="'select-bg_institution'"
    />
    <Widget
      class="table__switch"
      type="Switch"
      :unique="'FilterActiveInEducationTable'"
      :label="'Уволен'"
      :editValue="filterActive"
    />
    <ModalButton
      v-if="isAdmin"
      :title="'Выгрузить'"
      :icon="'download-secondary'"
      :type="'outline'"
      :loader="loaderReport"
      @handleButton="downloadReports({ value: 'downloadReportEduction', record: getNewRecord })"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { mapActions, mapState } from "vuex";

export default {
  name: "EducationWidgets",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    filterActive: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterTypeEducation: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterInstitution: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Widget, ModalButton },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["downloadReports"])
  },
  computed: {
    ...mapState("TablesModules", ["loaderReport"]),
    getNewRecord() {
      return this.$store.state.newRecord.month;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.education {
  &__widgets {
    display: flex;
    align-items: center;
    padding: 16px 24px;

    @media (max-height: $xs) {
      padding: 8px 16px;
    }

    /deep/ {
      .select-bg {
        width: 350px;
        margin-right: 24px;

        @media (max-width: $xxl) {
          width: 300px;
        }

        .select-content-bg {
          height: 28px;
          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 145px);

              @media (max-width: $xxl) {
                width: calc(100% - 150px);
              }
            }
          }
        }

        &_type .select-content-bg .select-content .select-content-value-bg {
          width: calc(100% - 185px);

          @media (max-width: $xxl) {
            width: calc(100% - 180px);
          }
        }
        &_institution .select-content-bg .select-content .select-content-value-bg {
          width: calc(100% - 200px);

          @media (max-width: $xl) {
            width: calc(100% - 190px);
          }
        }

        .select-label {
          white-space: nowrap;
        }
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      .modal-items {
        background: #fafafe;
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }
    }
  }

  .default-button {
    margin-left: auto;
  }
}
</style>
