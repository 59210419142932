<template>
  <div class="kanban-column" :id="setId" v-if="hasStatuses">
    <div class="kanban-column__header">
      <h4 class="kanban-column__name">{{ column.name }}</h4>
    </div>
    <ul class="dropzone kanban-column__task-list" :id="setIdList">
      <KanbanTask
        :task="task"
        :index="index"
        v-for="(task, index) in sortedTaskList"
        :key="task.id"
      />
    </ul>
    <ul class="kanban-column__status-list" v-show="availableStatuses.length">
      <li
        class="dropzone kanban-column__status"
        v-for="status in availableStatuses"
        :key="status.flow_status"
        :data-status_id="status.flow_status"
      >
        <p>{{ status.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import KanbanTask from "@/modules/TaskManager/components/Kanban/KanbanTask";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import ClickOutside from "vue-click-outside";
export default {
  name: "KanbanColumn",
  mixins: [],
  props: {
    column: {
      type: Object,
      default: () => {
        return {};
      }
    },
    taskList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    KanbanTask
  },
  data() {
    return {
      name: ""
    };
  },
  mounted() {},
  methods: {},
  computed: {
    sortedTaskList() {
      let sortedTaskList = [];
      this.column.status_links.forEach((status) => {
        const tempArray = this.taskList.filter((task) => task.status_id === status.flow_status);
        tempArray.sort((a, b) => (a.id > b.id ? 1 : -1));
        sortedTaskList = [...sortedTaskList, ...tempArray];
      });
      return sortedTaskList;
    },
    setId() {
      return `kanban-column_${this.column.id}`;
    },
    setIdList() {
      return `kanban-column__task-list_${this.column.id}`;
    },
    availableStatuses() {
      const availableStatuses = this.column.status_links.filter((status) =>
        KanbanModule.availableStatuses.some((item) => item === status.flow_status)
      );
      return KanbanModule.availableStatuses.length ? availableStatuses : this.column.status_links;
    },
    hasStatuses() {
      return this.column.status_links.length;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.kanban-column {
  display: flex;
  flex-direction: column;
  width: 332px;
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
  &__header {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 2px solid $secondary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
    z-index: 2;
  }
  &__dropzone {
    height: 100%;
    width: 100%;
    position: relative;
  }
  &__task-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__status-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    width: 100%;
    height: calc(100% + 40px);
    min-height: 335px;
    border-radius: 8px;
    list-style: none;
    top: 32px;
    left: 0;
    margin: 0;
    padding: 32px 0 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(124, 169, 255, 0.2) 0%, rgba(67, 119, 220, 0.2) 100%);
  }
  &__status {
    flex: 1 1 auto;
    margin: 0;
    height: 100%;
    min-height: 300px;
    border: 1px dashed #5551f9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $chat-editor;
    position: relative;
    p {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
    }
  }
  &__input {
    margin-bottom: 24px;
  }
  &__create-task {
    align-self: start;
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    border: 2px dashed $secondary;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
//при наведении на колонку статуса
.draggable-container--over {
  background: linear-gradient(180deg, rgba(124, 169, 255, 0.3) 0%, rgba(67, 119, 220, 0.3) 100%);
}
.draggable-dropzone--occupied {
  background: linear-gradient(180deg, rgba(124, 169, 255, 0.3) 0%, rgba(67, 119, 220, 0.3) 100%);
}
</style>
