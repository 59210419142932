<template>
  <div class="like-bg">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      :fill="parentLike.current_user_like ? '#8793FF' : 'none'"
      xmlns="http://www.w3.org/2000/svg"
      @click.prevent="clickLike"
    >
      <path
        d="M2.34252 6.77795C2.56867 6.23198 2.90014 5.7359 3.31801 5.31804C3.73587 4.90017 4.23195 4.5687 4.77792 4.34255C5.32389 4.1164 5.90906 4 6.50001 4C7.09096 4 7.67613 4.1164 8.2221 4.34255C8.76807 4.5687 9.26414 4.90017 9.68201 5.31804L11 6.63604L12.318 5.31804C13.1619 4.47412 14.3065 4.00001 15.5 4.00001C16.6935 4.00001 17.8381 4.47412 18.682 5.31804C19.5259 6.16196 20 7.30656 20 8.50004C20 9.69352 19.5259 10.8381 18.682 11.682L11 19.364L3.31801 11.682C2.90014 11.2642 2.56867 10.7681 2.34252 10.2221C2.11637 9.67616 1.99997 9.09099 1.99997 8.50004C1.99997 7.90909 2.11637 7.32392 2.34252 6.77795Z"
        stroke="#8D96EC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    {{ parentLike.count_likes }}
  </div>
</template>

<script>
import { HTTPRequest } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";

export default {
  name: "LikeComponent",
  mixins: [],
  props: ["parentLike", "likeableType"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    async clickLike() {
      const Like = new HTTPRequest();
      if (!this.parentLike.current_user_like) {
        Like.send(`${domain}/monolit/Like/actionCreate`, "POST", {
          likeable_id: this.parentLike.id,
          likeable_type: this.likeableType
        }).then(() => {
          this.$emit("updateLikeInfo");
          this.$root.$emit("updateLikeInfoRoot");
        });
      } else {
        Like.send(
          `${domain}/monolit/Like/actionDelete/${this.parentLike.current_user_like.id}`,
          "DELETE"
        ).then(() => {
          this.$emit("updateLikeInfo");
          this.$root.$emit("updateLikeInfoRoot");
        });
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
.like-bg {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-other-1;
  user-select: none;
  transition: all 0.3s ease;

  svg {
    margin-right: 6px;
    margin-bottom: 4px;
    transition: all 0.3s ease;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);

        path {
          fill: $color-button-hover;
        }
      }
    }

    &:active {
      transform: scale(0.9);

      path {
        fill: $color-button-click;
      }
    }
  }
}
</style>
