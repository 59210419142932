
































































import { Component, Prop, Vue } from "vue-property-decorator";
import InputSelect from "@/aopV2/components/UIComponents/Input/Select/InputSelect.vue";
import InputNumber from "@/aopV2/components/UIComponents/Input/Number/InputNumber.vue";
import CalendarWeekList from "@/aopV2/modules/Calendar/components/CalendarWeekList/CalendarWeekList.vue";
import InputDate from "@/aopV2/components/UIComponents/Input/Date/InputDate.vue";
import { typeRepeat } from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/CreateEventFS.vue";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";

@Component<RepeatBlock>({
  components: { InputDate, CalendarWeekList, InputNumber, InputSelect },
  watch: {
    eventRepeat() {
      if (this.eventRepeat) {
        this.repeatCount = this.eventRepeat.count;
        this.selectRepeatInterval = this.repeatInterval.find(
          (item) => this.eventRepeat.interval_type === item.type
        );
        this.interval = this.eventRepeat.step;
        this.selectRepeatDate = this.eventRepeat.to_date;
        this.activeDays = this.eventRepeat.days ? this.eventRepeat.days : [this.currentWeekDay];
        this.selectEndRepeat = this.eventRepeat.to_date
          ? {
              name: "Дата",
              id: 2
            }
          : this.eventRepeat.count
          ? { name: "После повторов", id: 3 }
          : { name: "Никогда", id: 1 };
      }
    }
  }
})
export default class RepeatBlock extends Vue {
  @Prop({ type: String, default: dateFormat(new Date()) }) selectDate!: string;
  @Prop({
    type: Object,
    default: () => ({
      name: "Не повторять",
      id: "no-repeat"
    })
  })
  selectRepeat!: { name: string; id: string };
  @Prop({
    type: Object,
    default: null
  })
  eventRepeat!: any;
  @Prop({
    type: Date,
    default: new Date()
  })
  minDate!: Date;

  repeatList: Array<any> = [
    {
      name: "Не повторять",
      id: "no-repeat"
    },
    {
      name: "Ежедневно",
      id: "day"
    },
    {
      name: "По будням",
      id: "week"
    },
    {
      name: "Еженедельно",
      id: "week-repeat"
    },
    {
      name: "Ежемесячно",
      id: "month"
    },
    {
      name: "Ежегодно",
      id: "year"
    },
    {
      name: "Другое",
      id: "other"
    }
  ];
  repeatInterval: Array<any> = [
    { name: "День", id: 1, type: "day" },
    { name: "Неделя", id: 2, type: "week" },
    { name: "Месяц", id: 3, type: "month" }
  ];
  repeatEndList: Array<any> = [
    { name: "Никогда", id: 1 },
    { name: "Дата", id: 2 },
    { name: "После повторов", id: 3 }
  ];

  selectEndRepeat = {
    name: "Никогда",
    id: 1
  };

  selectRepeatInterval = {
    name: "Неделя",
    type: "week",
    id: 2
  };
  interval: number = 1;
  repeatCount: number = 1;
  selectRepeatDate: string | null = null;
  activeDays: Array<any> = [];
  repeat: null | typeRepeat = null;

  mounted() {
    this.activeDays = [this.currentWeekDay];
  }

  changeRepeatType(type) {
    this.$emit("changeRepeatType", type);
  }

  changeRepeatTypeEnd(type) {
    this.selectEndRepeat = type;
    this.$emit("setRepeat", this.repeatSetting);
  }

  changeRepeatInterval(type) {
    this.selectRepeatInterval = type;
    this.$emit("setRepeat", this.repeatSetting);
  }

  handleInterval(interval) {
    this.interval = interval;
    this.$emit("setRepeat", this.repeatSetting);
  }

  handleRepeatCount(count) {
    this.repeatCount = count;
    this.$emit("setRepeat", this.repeatSetting);
  }

  changeDateRepeat(date) {
    this.selectRepeatDate = date;
    this.$emit("setRepeat", this.repeatSetting);
  }

  setActiveDay(activeDays) {
    this.activeDays = activeDays;
    this.$emit("setRepeat", this.repeatSetting);
  }

  get isShowRepeatSetting() {
    return this.selectRepeat.id === "other";
  }

  get currentWeekDay() {
    return new Date(this.selectDate).getDay();
  }

  get repeatOtherSetting() {
    return {
      count: this.repeatCount ? this.repeatCount : null,
      to_date: this.selectRepeatDate,
      interval_type: this.selectRepeatInterval.type,
      step: this.interval,
      days: this.selectRepeatInterval.type === "week" ? this.activeDays : null
    };
  }

  get repeatSetting() {
    return this.selectRepeat.id === "other" ? this.repeatOtherSetting : this.eventRepeat;
  }
}
