export const CHANGE_INFO_MODAL_MODE = "CHANGE_INFO_MODAL_MODE";
export const SET_TABLE_PARAMS = "SET_TABLE_PARAMS";
export const CLEAR_TABLE_DATA = "CLEAR_TABLE_DATA";
export const RESET_PAGINATION = "RESET_PAGINATION";
export const SET_SORT = "SET_SORT";
export const GET_PROBLEM_LIST = "GET_PROBLEM_LIST";
export const GET_BACKLOG_PROBLEM_LIST = "GET_BACKLOG_PROBLEM_LIST";
export const SHOW_CONFIRM_MODALS = "SHOW_CONFIRM_MODALS";
export const SET_LOADER_TABLE = "SET_LOADER_TABLE";
export const SET_LOADER_QUARTER = "SET_LOADER_QUARTER";

// FILTERS
export const GET_STATUSES_LIST = "GET_STATUSES_LIST";
export const GET_CRITICALITY_LIST = "GET_CRITICALITY_LIST";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_GROUPS_ISP = "GET_GROUPS_ISP";

// QUARTER
export const GET_QUARTER_LIST = "GET_QUARTER_LIST";
export const SET_CURRENT_QUARTER = "SET_CURRENT_QUARTER";
export const SET_REVIEW_PROBLEM_PARAMS = "SET_REVIEW_PROBLEM_PARAMS";
export const FIND_CURRENT_QUARTER = "FIND_CURRENT_QUARTER";
export const GET_REVIEW_STATISTICS_LIST = "GET_REVIEW_STATISTICS_LIST";
export const SET_REVIEW_CRITICALITY = "SET_REVIEW_CRITICALITY";
export const SET_REVIEW_PROGRESS = "SET_REVIEW_PROGRESS";
export const SET_REVIEW_STATUS = "SET_REVIEW_STATUS";
export const SET_REVIEW_GROUPS = "SET_REVIEW_GROUPS";
export const SET_REVIEW_CUSTOMER = "SET_REVIEW_CUSTOMER";
export const SET_REVIEW_RESPONSIBLE = "SET_REVIEW_RESPONSIBLE";
export const SET_SHOW_PROGRESS_MODAL = "SET_SHOW_PROGRESS_MODAL";
export const SHOW_ADD_AND_EDIT_QUARTER = "SHOW_ADD_AND_EDIT_QUARTER";
export const SHOW_DUPLICATE_MODAL = "SHOW_DUPLICATE_MODAL";
export const SET_EDIT_QUARTER = "SET_EDIT_QUARTER";
export const SET_CRITICALITY_WEIGHT = "SET_CRITICALITY_WEIGHT";
export const SET_CURRENT_QUARTER_CRITICALITY_WEIGHT = "SET_CURRENT_QUARTER_CRITICALITY_WEIGHT";
export const SET_CHANNEL_CENTRIFUGE = "SET_CHANNEL_CENTRIFUGE";

// PROBLEM
export const SET_CURRENT_INFO_PROBLEM = "SET_CURRENT_INFO_PROBLEM";
export const SET_CHILDREN_PROBLEM = "SET_CHILDREN_PROBLEM";
export const SHOW_CURRENT_INFO_PROBLEM = "SHOW_CURRENT_INFO_PROBLEM";
export const CLOSE_CURRENT_INFO_PROBLEM = "CLOSE_CURRENT_INFO_PROBLEM";
export const UPDATE_PROBLEM = "UPDATE_PROBLEM";
export const UPDATE_PROBLEM_FILES = "UPDATE_PROBLEM_FILES";
export const SET_FILES_LOADER = "SET_FILES_LOADER";
export const UPDATE_VIEWER_STATUS = "UPDATE_VIEWER_STATUS";
export const SWITCH_MOVE_MODAL = "SWITCH_MOVE_MODAL";
export const DELETE_PROBLEM = "DELETE_PROBLEM";

// TASK
export const GET_TASK_LIST = "GET_TASK_LIST";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const SHOW_CREATE_TASK_MODAL = "SHOW_CREATE_TASK_MODAL";

// MY ISP
export const SET_MY_ISP_PROBLEM_PARAMS = "SET_MY_ISP_PROBLEM_PARAMS";
export const GET_MY_ISP_STATISTICS_LIST = "GET_MY_ISP_STATISTICS_LIST";
export const SET_MY_ISP_CRITICALITY = "SET_MY_ISP_CRITICALITY";
export const SET_MY_ISP_STATUS = "SET_MY_ISP_STATUS";
export const SET_MY_ISP_ROLES = "SET_MY_ISP_ROLES";
export const SET_MY_ISP_GROUPS = "SET_MY_ISP_GROUPS";
export const SET_MY_ISP_CUSTOMER = "SET_MY_ISP_CUSTOMER";
export const SET_MY_ISP_RESPONSIBLE = "SET_MY_ISP_RESPONSIBLE";
export const IS_MY_ISP_FILTERS = "IS_MY_ISP_FILTERS";
