




















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import Select from "@/modules/UiKit/components/Select/Select";
import MainTabs from "@/aopV2/layout/LayoutMain/MainTabs/MainTabs.vue";
import DropDownList from "@/aopV2/components/UIComponents/DropDownList/DropDownList.vue";
import GroupBlock from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/FSBlocks/GroupBlock.vue";

@Component({
  components: {
    GroupBlock,
    DropDownList,
    MainTabs,
    UserCard,
    ButtonIcon,
    TextLabel,
    ButtonNewDesign,
    SelectList,
    Select
  }
})
export default class MemberBlock extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  selectLocation!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  selectResponsible!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  selectMembers!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  selectGroups!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  responsible!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  locations!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  groups!: Array<any>;
  @Prop({ default: false, type: Boolean }) isDisabledMember!: boolean;
  tabs: Array<{ name: string; route: string }> = [
    { name: "Участники", route: "Участники" },
    {
      name: "Группы",
      route: "Группы"
    }
  ];
  activeTabs: string = "Участники";
  isShowSelect: boolean = false;
  type: string = "location";
  titleList = {
    location: "Выбрать кабинет",
    responsible: "Выбрать ответственных",
    members: "Выбрать участников"
  };

  closeEdit(e) {
    if (e.keyCode === 27) {
      this.isShowSelect = false;
    }
  }

  handleChangeGroup(group) {
    const deletedGroup = this.selectGroups.find((item) => item.id === group.id);
    let selected = this.selectGroups.map((item) => item);
    if (deletedGroup) {
      selected = selected.filter((item) => item.id !== group.id);
    } else {
      selected.push(group);
    }
    this.$emit("deleteGroups", deletedGroup);
    this.$emit("changeGroups", selected);
  }

  selectedGroupUser(group) {
    const chancedGroupIndex = this.selectGroups.findIndex((item) => item.id === group.id);
    let selected = this.selectGroups.map((item) => item);
    if (group.users.length === 0) {
      selected = this.selectGroups.filter((item) => item.id !== group.id);
    } else {
      if (chancedGroupIndex > -1) {
        selected[chancedGroupIndex] = group;
      } else {
        selected.push(group);
      }
    }
    this.$emit("changeGroups", selected);
  }

  deleteGroup(group) {
    const selected = this.selectGroups.filter((item) => item.id !== group.id);
    this.$emit("changeGroups", selected);
  }

  handleTab(tab) {
    this.activeTabs = tab.name;
  }

  changeLocation(location) {
    this.$emit("changeLocation", location);
  }

  deleteLocation() {
    this.$emit("changeLocation", [
      {
        name: "Без кабинета",
        id: 0
      }
    ]);
  }

  handleChangeUser(selected) {
    this.$emit("changeResponsible", selected);
  }

  deleteResponsible(user) {
    const selected = this.selectResponsible.filter((item) => item.id !== user.id);
    this.$emit("changeResponsible", selected);
  }

  handleDeleteMembers(deleted) {
    this.$emit("deleteMembers", deleted);
  }

  deleteMembers(user) {
    const members = this.selectMembers.filter((item) => item.id !== user.id);
    this.$emit("changeMembers", members);
  }

  changeMembers(members) {
    this.$emit("changeMembers", members);
  }

  toggleIsShow() {
    this.isShowSelect = !this.isShowSelect;
    if (this.isShowSelect) {
      window.addEventListener("keydown", this.closeEdit);
    } else {
      window.removeEventListener("keydown", this.closeEdit);
    }
  }

  toggleLocationSelect() {
    this.toggleIsShow();
    if (this.isShowSelect) {
      this.type = "location";
    }
  }

  closeSelect() {
    this.isShowSelect = false;
  }

  toggleResponsibleSelect() {
    this.toggleIsShow();

    if (this.isShowSelect) {
      this.type = "responsible";
    }
  }

  toggleMembersSelect() {
    this.toggleIsShow();

    if (this.isShowSelect) {
      this.type = "members";
    }
  }

  get location() {
    return this.selectLocation.length ? this.selectLocation[0] : "";
  }

  get isActiveMember() {
    return this.activeTabs === "Участники";
  }

  get isLocation() {
    return this.type === "location";
  }

  get isResponsible() {
    return this.type === "responsible";
  }

  get isMembers() {
    return this.type === "members";
  }

  get title() {
    return this.titleList[this.type] ? this.titleList[this.type] : "";
  }
}
