




















import { Component, Prop, Vue } from "vue-property-decorator";
import TaskContentList from "@/modules/TimeTracker/component/TaskContentList/TaskContentList.vue";
import TimeSearch from "@/modules/TimeTracker/component/TimeSearch/TimeSearch.vue";
import TimeTracker, { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import { ITimeProject } from "@/modules/TimeTracker/interface/interface";

@Component({
  components: { Spinner, Loader, TimeSearch, TaskContentList }
})
export default class TimeTaskContent extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  project!: ITimeProject;
  value = "";

  handleInput(event) {
    this.value = event.target.value;
  }

  addTask() {
    TimeTracker[TimeTrackerMutationTypes.SET_TASKS_PROJECT](this.project);
    this.$emit("openTaskModal");
  }

  get tasks() {
    return TimeTracker.tasks({ project_id: this.project.id, search: this.value.toLowerCase() });
  }

  get list() {
    return TimeTracker.tasksList[this.project.id];
  }

  get isLoader() {
    return !this.list;
  }

  get time() {
    return TimeTracker.projectTime(this.project.id);
  }
}
