<template>
  <div class="history-card">
    <div class="history-card__wrap">
      <div class="history-card__header history-row">
        <div class="history-card__title">
          <div class="history-row__name">{{ item.role_id ? item.role_id.name : "Нет Роли" }}</div>
          <div class="history-row__department">
            {{ item.department_id ? item.department_id.name : "Нет Отдела" }}
          </div>
        </div>
        <ActionsList v-if="isAdmin && userCard" :actionList="actionList" />
      </div>
      <div class="history-card__grade-exp history-row">
        <div class="history-row__grade">
          <div class="history-row__label">Грейд</div>
          <Level :level="setGrade" />
        </div>
        <div class="history-row__exp">
          <div class="history-row__label">Стаж</div>
          <div class="history-row__content history-exp">
            {{ getDateDistance(item.from_date, item.to_date) }}
          </div>
        </div>
      </div>
      <div class="history-card__dates history-row">
        <div class="history-row__start">
          <div class="history-row__label">Дата начала</div>
          <div class="history-row__content history-date">
            <i class="icon-calendar-secondary"></i>
            {{ item.from_date ? getDateFormat(item.from_date) : "Нет Даты Начала" }}
          </div>
        </div>
        <div class="history-row__end">
          <div class="history-row__label">Дата окончания</div>
          <div class="history-row__content history-date">
            <i class="icon-calendar-secondary"></i>
            {{ item.to_date ? getDateFormat(item.to_date) : "Работает" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconWidgets from "@/assets/aop-icons/IconWidgets";
import { format, formatDuration } from "date-fns";
import { ru } from "date-fns/locale";
import moment from "moment";
import "moment-precise-range-plugin";
import ActionsList from "@/components/Widgets/ActionList/ActionList";

import { domain } from "@/globalVariables";
import Level from "@/components/Widgets/Level/Level";
import Swal from "sweetalert2";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "HistoryCard",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    userCard: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: {}
    }
  },
  components: { Level, IconWidgets, ActionsList },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `EditHistoryUser_${this.item.id}`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `DeleteHistoryUser_${this.item.id}`
        }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off(`actionItem:handleEditHistoryUser_${this.item.id}`);
    this.$root.$off(`actionItem:handleDeleteHistoryUser_${this.item.id}`);
  },
  mounted() {
    this.$root.$on(`actionItem:handleEditHistoryUser_${this.item.id}`, () => {
      this.$root.$emit("EditUserHistory", this.item);
    });
    this.$root.$on(`actionItem:handleDeleteHistoryUser_${this.item.id}`, () => {
      this.askDeleteHistoryItem(this.item.id);
    });
  },
  methods: {
    /**
     * Setting the date format
     * @param date - date to format YYYY-MM-DD
     * @returns {string} - date to format dd MMMM yyyy
     */
    getDateFormat(date) {
      return format(new Date(date), "dd MMMM yyyy", {
        locale: ru
      });
    },
    /**
     * The function of calculating the length of service in the company
     * @param date - start date of work
     * @param endDate - date of end of work
     * @returns {string} - experience
     */
    getDateDistance(date, endDate) {
      let starts = moment(new Date(date));
      let ends = !endDate
        ? this.getDataInfoUser.date_of_dismissal
          ? moment(new Date(this.getDataInfoUser.date_of_dismissal))
          : moment()
        : moment(new Date(endDate));
      // Если дата назначения позже текущей, то стаж не выводится
      if (moment(starts).isAfter(moment())) {
        return "-";
      } else {
        return formatDuration(
          {
            years: moment.preciseDiff(starts, ends, true).years,
            months: moment.preciseDiff(starts, ends, true).months,
            days: moment.preciseDiff(starts, ends, true).days
          },
          {
            locale: ru
          }
        );
      }
    },
    /**
     * function of deleting a history card in a company
     * @param id - id history card
     */
    deleteHistoryItem(id) {
      httpClient({
        url: `/UserAffiliation/delete/${id}`,
        method: "DELETE"
      })
        .then((response) => {
          this.$emit("goGetHistoryInfoUser");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Путь успешно удален!`
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    /**
     * the function of calling the modal window for confirming the deletion of the history card in the company
     * @param id - id history card
     */
    askDeleteHistoryItem(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить путь сотрудника?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteHistoryItem(id);
          }
        });
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    setGrade() {
      return this.item.levels.length ? this.item.levels[0].name : "Без уровня";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.history-card {
  display: flex;

  &__wrap {
    position: relative;
    height: 277px;
    width: 386px;
    background: $text-on-interactive;
    box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 16px;
    padding: 18px 26px;
    margin-right: 30px;
    z-index: 5;
    @media screen and (max-width: $md) {
      margin-bottom: 20px;
      margin-right: 0;
      width: 100%;
    }
  }
  &__header {
    display: flex;
    margin-bottom: 35px;
  }

  &__title {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
  &__grade-exp {
    display: flex;
    align-items: start;
    margin-bottom: 30px;
  }
  &__dates {
    display: flex;
    align-items: start;
  }
  .history-row {
    &__label {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: $text-subdued;
      margin-bottom: 14px;
      text-transform: uppercase;

      @media screen and (max-width: $sm) {
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
      }
    }
    &__name {
      display: block;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: $text-default;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: $sm) {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #343748;
      }
    }
    &__department {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $text-disabled;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: $sm) {
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #a7a9c0;
      }
    }
    &__grade {
      display: flex;
      flex-direction: column;
      width: 50%;

      .level {
        width: max-content;
      }
    }
    &__exp {
      display: flex;
      flex-direction: column;
      width: 50%;
      .history-exp {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $text-subdued;
      }
    }
    &__start {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    &__end {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    .history-date {
      width: fit-content;
      padding: 4px 8px;
      background: #fafafe;
      border-radius: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $text-subdued;
      @media screen and (max-width: $sm) {
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 16px;
        color: $text-subdued;
      }
      i {
        margin-right: 5px;
      }
    }
  }

  &__add-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 386px;
    min-height: 277px;
    border: 2px dashed $border-default;
    border-radius: 24px;
    background: transparent;
    z-index: 5;

    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 2px;
      left: -30px;
      top: 147px;
      border-bottom: 3px dashed #d3d2e0;
    }
  }
  &__create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 203px;
    height: 30px;
    border-radius: 40px;
    border: 1px solid $border-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-disabled;
    background: transparent;
    outline: none;

    &:hover {
      color: $text-default;
      background: $action-secondary-hovered;
    }

    &:active {
      color: $text-default;
      background: $action-secondary-pressed;
    }

    i {
      margin-right: 8px;
    }
  }
}
.history-card:last-child {
  margin-right: 0;
}
</style>
