<template>
  <div class="job-folder">
    <div class="job-folder__container">
      <div class="job-folder__header">
        <h2 class="job-folder__title">Должностная папка</h2>
      </div>
      <div class="job-folder__wrapper">
        <JobProfession
          v-for="role in dataUser.roles"
          :key="role.id"
          :role="role"
          @updateProfileByLike="$emit('updateProfileByLike')"
          @openTestByButton="openTestByButton"
        />
        <JobResponsibilities :responsibilities="responsibilities" />
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

const JobProfession = () =>
  import("@/components/ProfileComponents/JobFolderComponents/JobProfession/JobProfession");
const JobResponsibilities = () =>
  import(
    "@/components/ProfileComponents/JobFolderComponents/JobResponsibilities/JobResponsibilities"
  );

export default {
  name: "JobFolder",
  mixins: [],
  props: {
    isRequiredTests: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  components: { JobResponsibilities, JobProfession },
  data() {
    return {
      showTest: false,
      testId: null,
      regulationId: null,
      responsibilities: [],
      dataUser: [],
      userCard: false
    };
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    this.$root.$off("goGetInfoUser");
  },
  mounted() {
    document.body.style.overflow = "hidden";
    this.$root.$on("goGetInfoUser", () => {
      if (this.getUserId) {
        if (this.$route.params.user_id) {
          this.getUserPageInfo(this.$route.params.user_id);
          this.userCard = true;
        } else {
          this.getUserPageInfo(this.getUserId);
        }
      }
    });
    this.$root.$emit("goGetInfoUser");
  },
  methods: {
    getUserPageInfo(id) {
      httpClient({
        method: "GET",
        url: `/User/actionGetItem/${id}`,
        params: {
          is_active: JSON.stringify([0, 1]),
          _with: JSON.stringify([
            "departments",
            "roles.regulations.likes",
            "roles.regulations.views",
            "roles.regulations.current_user_like",
            "roles.regulations.test_with_user_results",
            "roles",
            "levels",
            "org_structure_item_with_responsibilities.current_user_responsibilities",
            "org_structure_items",
            "user_test_status_which_required"
          ])
        }
      })
        .then((response) => {
          this.dataUser = response.data.data;
          const responsibilities = this.dataUser.org_structure_item_with_responsibilities;
          const org_structure_items = this.dataUser.org_structure_items;
          // Поиск совпадений в массивах org_structure_items и responsibilities, возвращается массив org_structure_items без элементов responsibilities
          this.responsibilities = org_structure_items.filter(
            (e) => responsibilities.findIndex((i) => i.id === e.id) === -1
          );
          // Добавление элементов массива responsibilities в отфильтрованный массив org_structure_items
          responsibilities.forEach((item) => {
            this.responsibilities.unshift(item);
          });

          // Сортируем массив памяток
          if (this.dataUser.roles.length) {
            this.dataUser.roles.forEach((item) => {
              if (item.regulations.length) {
                item.regulations.sort((a, b) => {
                  return a.id - b.id;
                });
              }
            });
          }
          if (this.dataUser.user_test_status_which_required.length) {
            this.$root.$emit("showNotification", {
              type: "info",
              timeout: 30000,
              label:
                "У вас есть тесты, обязательные к прохождению. Пройдите их, чтобы свободно пользоваться Личным Кабинетом."
            });
          }
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    openTestByButton(value) {
      this.$emit("openTestByButton", value);
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.job-folder {
  z-index: 25;
  display: flex;
  height: calc(100% - 30px);
  width: 100%;
  background: $surface-default;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 24px;

  &__wrapper {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
  }

  &__title {
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 16px;
    color: $text-default;
    line-height: 25px;
  }
}
</style>
