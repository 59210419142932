

























































import { Component, Prop, Vue } from "vue-property-decorator";
import MainTimer from "@/modules/TimeTracker/component/MainTimer.vue";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import TimeTracker, { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import moment from "moment";
import { EventBus } from "@/main";
import { weekDay } from "@/modules/TimeTracker/helpers/weekDay";
import ConfirmationModal from "@/modules/TimeTracker/component/Modal/ConfirmationModal";

@Component({
  components: { ConfirmationModal, ProjectTag, MainTimer }
})
export default class TimeWidget extends Vue {
  timerId: undefined | number = undefined;
  loader = true;

  openModal() {
    TimeTracker[TimeTrackerMutationTypes.TOGGLE_PROJECT_MODAL](true);
  }

  created() {
    TimeTracker.setCurrentUserId(this.currentUserId);
    TimeTracker.getMyProject();
    TimeTracker.needToSync();
    this.getMyWorkTime();
  }

  get currentUserId() {
    return this.$store.getters.getUserId;
  }

  async getMyWorkTime() {
    this.loader = true;
    const days = weekDay(new Date(), "Воскресенье");
    const date_stop =
      days.startWeekDay === moment(new Date()).format("YYYY-MM-DD")
        ? null
        : moment(new Date()).format("YYYY-MM-DD");
    await Promise.all([
      TimeTracker.getMyWeekLimit(),
      TimeTracker.getMyWeekWorkTime({
        date_start: days.startWeekDay,
        date_stop,
        is_week: true
      })
    ]);
    if (!this.isActive) await TimeTracker.getMyTodayWorkTime(true);
    this.loader = false;
  }

  handleSelectTask() {
    this.openModal();
  }

  handleTimerButton() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (this.isWeekLimit) {
        if (this.isActive) {
          TimeTracker.toggleTime({
            isActive: !this.isActive,
            task: this.activeTask,
            project: this.activeProject
          });
        } else {
          EventBus.$emit("showNotification", {
            type: "warning",
            timeout: 5000,
            label: "Достигнут недельный лимит!"
          });
        }
      } else {
        if (this.isActiveTask) {
          TimeTracker.toggleTime({
            isActive: !this.isActive,
            task: this.activeTask,
            project: this.activeProject
          });
        } else {
          EventBus.$emit("showNotification", {
            type: "warning",
            timeout: 5000,
            label: "Выберите задачу!"
          });
        }
      }
    }, 300);
  }

  get modalConfig() {
    return TimeTracker.modalConfirmData;
  }

  get isShowConfirmationModal() {
    return TimeTracker.isShowConfirmationModal;
  }

  get isActive() {
    return TimeTracker.hasActiveTimeTracker;
  }

  get tag() {
    switch (this.project!.tracker_id) {
      case null: {
        return "AOP-TIME";
      }
      case 1: {
        return "JiraCloud";
      }
      case 2: {
        return "JiraServer";
      }
      case 3: {
        return "AOP-TASK";
      }
      default: {
        return "AOP";
      }
    }
  }

  get limit() {
    return TimeTracker.limit;
  }

  get isActiveTask() {
    return !!this.activeTask && !!this.activeProject;
  }

  get isWeekLimit() {
    return TimeTracker.isWeekLimit;
  }

  get weekTime() {
    return `${this.weekWorkTime} / ${this.limit}`;
  }

  get activeTask() {
    return TimeTracker.activeTask;
  }

  get activeProject() {
    return TimeTracker.activeProject;
  }

  get project() {
    return TimeTracker.project(this.activeProject!.id);
  }

  get weekWorkTime() {
    const [hh, mm, ss] = TimeTracker.totalWeekTime.split(":");
    return `${hh}:${mm}`;
  }

  get workTime() {
    const [hh, mm, ss] = TimeTracker.workTime.split(":");
    return `${hh}:${mm}`;
  }
}
