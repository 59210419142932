<template>
  <div class="quarter" ref="reviewIsp">
    <QuarterHeader v-if="quarterList.length" />
    <div class="quarter__header-sort" v-if="showTable">
      <TableHeader :tableHeadList="tableHead" v-if="quarterList.length" />
    </div>
    <div class="quarter__spinner" v-if="loaderTable">
      <Spinner />
    </div>
    <div class="quarter__container" v-if="showTable">
      <Table :tableHead="tableHead" :tableData="problemsData" :showEmpty="!showBacklog">
        <template slot-scope="props">
          <ISPCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
      <h2 class="quarter__backlog" v-if="showBacklog">Бэклог</h2>

      <Table
        v-if="showBacklog"
        :tableHead="tableHead"
        :showHead="false"
        :tableData="backlogProblemsData"
      >
        <template slot-scope="props">
          <ISPCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
    <div class="quarter__container_cards">
      <IspCard v-for="item in problemsData" :key="item.id" :card="item" />
    </div>
    <div class="quarter__empty" v-if="!quarterList.length && !loaderQuarter">
      <p class="quarter__empty-title">Список периодов пуст</p>
      <Button
        v-if="isAdminIsp"
        :type="'white'"
        :icon="'add-task'"
        :title="'Создать период'"
        @handleButton="addQuarter"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import QuarterHeader from "@/modules/Isp/components/IspReview/IspReviewHeader";
import ISPCellContent from "@/modules/Isp/components/IspComponents/ISPCellContent";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import Table from "@/modules/Isp/components/Table/Table";
import TableHeader from "@/modules/Isp/components/Table/TableHeader";
import { EventBus } from "@/main";
import Button from "@/modules/UiKit/components/buttons/Button";
import IspCard from "@/modules/Isp/components/IspComponents/IspCard";

export default {
  name: "IspReview",
  mixins: [],
  props: [],
  components: {
    IspCard,
    Button,
    ISPCellContent,
    QuarterHeader,
    Table,
    Spinner,
    TableHeader
  },
  data() {
    return {
      tableHead: [
        { name: "Критичность", sort: true, valueSort: "criticality_id", key: "criticality" },
        { name: "Номер", sort: true, valueSort: "id", key: "number" },
        { name: "Проблема", sort: false, key: "problem" },
        { name: "Заказчик", sort: false, key: "customer_user" },
        {
          name: "Ответственный",
          sort: false,
          key: "responsible_user"
        },
        { name: "Прогресс", sort: true, valueSort: "completion_percentage", key: "progress" },
        { name: "Статус", sort: false, key: "status" }
      ],
      localStorage: ""
    };
  },
  beforeDestroy() {
    this.$refs.reviewIsp.removeEventListener("scroll", () => {
      EventBus.$emit("hideFilters");
    });
  },
  created() {
    this.getCookieValue();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.reviewIsp.addEventListener("scroll", () => {
        EventBus.$emit("hideFilters");
      });
    });
    // @TODO нужно это как-то исправить
    setTimeout(() => {
      this.initializeTable();
    }, 0);
  },
  methods: {
    ...mapActions("IspModule", ["getProblemsList"]),
    ...mapMutations("IspModule", [
      "SET_REVIEW_PROBLEM_PARAMS",
      "CLEAR_TABLE_DATA",
      "SET_REVIEW_CRITICALITY",
      "SET_REVIEW_STATUS",
      "SET_REVIEW_GROUPS",
      "SET_REVIEW_CUSTOMER",
      "SET_REVIEW_RESPONSIBLE",
      "SHOW_ADD_AND_EDIT_QUARTER",
      "SET_EDIT_QUARTER"
    ]),

    initializeTable() {
      this.CLEAR_TABLE_DATA();
      this.SET_REVIEW_PROBLEM_PARAMS(this.reviewsParams);
      if (this.currentQuarter) this.getProblemsList();
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("review-table-setting"));
      this.SET_REVIEW_CRITICALITY(setting.criticality ? setting.criticality : []);
      this.SET_REVIEW_STATUS(setting.status ? setting.status : []);
      this.SET_REVIEW_GROUPS(setting.groups ? setting.groups : []);
      this.SET_REVIEW_CUSTOMER(setting.customer ? setting.customer : []);
      this.SET_REVIEW_RESPONSIBLE(setting.responsible ? setting.responsible : []);
    },
    addQuarter() {
      this.SHOW_ADD_AND_EDIT_QUARTER(true);
      this.SET_EDIT_QUARTER(false);
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    ...mapState("IspModule", [
      "quarterList",
      "currentQuarter",
      "problemsData",
      "backlogProblemsData",
      "problemsReviewParams",
      "valueSortList",
      "valueSort",
      "loaderTable",
      "loaderQuarter",
      "reviewCriticalityValue",
      "reviewStatusValue",
      "reviewGroupValue",
      "reviewCustomersValue",
      "reviewResponsibleValue",
      "quarterList"
    ]),
    showTable() {
      return !this.loaderTable && this.quarterList.length;
    },
    reviewsParams() {
      return {
        _count: 300,
        _order: JSON.stringify({
          [this.valueSort.value]: this.valueSort.isAsc ? "asc" : "desc"
        }),
        flow_status_id: this.reviewStatusValue.length
          ? JSON.stringify(this.reviewStatusValue.map((item) => item.id))
          : undefined,
        group_id: this.reviewGroupValue.length
          ? JSON.stringify(this.reviewGroupValue.map((item) => item.id))
          : undefined,
        criticality_id: this.reviewCriticalityValue.length
          ? JSON.stringify(this.reviewCriticalityValue.map((item) => item.id))
          : undefined,
        responsible_user_id: this.reviewResponsibleValue.length
          ? JSON.stringify(this.reviewResponsibleValue.map((item) => item.id))
          : undefined,
        customer_user_id: this.reviewCustomersValue.length
          ? JSON.stringify(this.reviewCustomersValue.map((item) => item.id))
          : undefined,
        _with: JSON.stringify(["group", "responsible_user", "customer_user", "status"])
      };
    },
    showBacklog() {
      return this.problemsReviewParams ? this.problemsReviewParams.group_id !== undefined : false;
    },
    isAdminIsp() {
      return this.getEndpoints("ISPPeriod", "actionCreate");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $text-highlight;
  }
}

::-webkit-scrollbar-track {
  margin-left: 5px;
  background: #fafafe;
  cursor: pointer;
}

.quarter {
  position: relative;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  padding-right: 16px;
  @media (max-width: $md) {
    padding: 0 16px;
  }
  /deep/ {
    .table {
      width: 100%;
      height: auto;
      min-height: auto;

      .table__row {
        width: 100%;
      }
    }

    .table__head {
      width: 100%;
    }

    .table__cell {
      &:first-child {
        max-width: 180px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: 120px;
      }

      &:nth-child(5) {
        max-width: 140px;
      }

      &:nth-child(6),
      &:last-child {
        max-width: 150px;
      }
    }
  }

  &__header {
    position: relative;
  }
  &__header-sort {
    @media (max-width: $lg) {
      display: none;
    }
  }
  &__container {
    @media (max-width: $lg) {
      display: none;
    }
    &_cards {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }
    }
  }
  &__backlog {
    font-weight: bold;
    font-size: 25px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #343748;
    padding: 24px;
  }

  &__spinner {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0 0;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 350px 0 0;
  }

  &__empty-title {
    width: 100%;
    margin: 0 0 40px;
    font-weight: normal;
    font-size: 36px;
    line-height: 20px;
    text-align: center;
    color: $text-default;
  }
}
</style>
