<template>
  <section class="project-network">
    <ConfirmModal
      v-if="confirmDeleteModal"
      title="Удалить?"
      description="Вы действительно хотите удалить план?"
      @cancel="cancelDelete"
      @accept="acceptDelete"
    />
    <ConfirmationModal v-if="isShowConfirmModal" />

    <ModalGiveAccess
      v-if="showModalGiveAccess"
      :planId="planId"
      @cancel="closeModalGiveAccess"
      @accept="giveAccess"
    />
    <div class="project-network__header">
      <h2>
        <i class="icon-arrow-back" @click="goBack" />
        <Skeleton v-if="planInfoLoader" class="skeleton_name" :borderRadius="'2px'" />
        <span v-else>{{ currentPlan.name }}</span>
      </h2>
      <div class="project-network__control">
        <div class="project-network__checkbox">
          <CheckboxISP v-model="showCriticalWay" :isEdit="true" />
          <p class="project-network__critical-plan critical-plan">
            Показать критический путь<Tooltip
              :text="'Самая длинная по срокам последовательная цепочка работ.'"
            />
          </p>
        </div>
        <BasePlan
          :planId="planId"
          :basePlanList="basePlanList"
          :basePlanToShow="basePlanToShow"
          :isAuthor="isAuthor"
          @handleBasePlan="reRender"
        />
        <PlanUsers
          v-if="isAuthor"
          :users="accessUsers"
          :loader="usersLoader"
          :isAuthor="isAuthor"
        />
        <ActionList v-if="isAuthor" :actionList="actionList" @handleAction="handleAction" />
      </div>
    </div>
    <div class="project-network__legend">
      <ul>
        <li v-for="legend in legendList" :key="legend.id">
          <div :style="{ background: legend.color }"></div>
          {{ legend.text }}
        </li>
      </ul>
      <button class="project-network__comments" type="button" @click="toggleShowComments(true)">
        <i class="icon-comment-empty"></i>
        <span>Комментарии ({{ countComments }})</span>
      </button>
    </div>
    <div class="project-network__graph">
      <Graph
        :stepList="stepList"
        :stepLinksList="stepLinksList"
        :baseStepList="baseStepList"
        :showCriticalWay="showCriticalWay"
        v-if="tick"
      />
    </div>
    <transition name="slide">
      <PlanComments
        v-if="showPlanComments"
        :planId="planId"
        @hideModal="toggleShowComments(false)"
      />
    </transition>
  </section>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
import PlanningScheduleModule, {
  PlanningScheduleMutationTypes
} from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import ActionList from "@/modules/UiKit/components/ActionList/ActionList";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import ModalGiveAccess from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/ModalGiveAccess/ModalGiveAccess";
import PlanUsers from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/PlanUsers/PlanUsers";
import Graph from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/Graph/Graph";
import BasePlan from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/BasePlan/BasePlan";
import PlanComments from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/PlanComments/PlanComments";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ConfirmationModal from "@/modules/TaskManager/components/Modal/ConfirmationModal";

export default {
  name: "ProjectNetwork",
  mixins: [],
  props: {},
  components: {
    BasePlan,
    Graph,
    PlanUsers,
    ModalGiveAccess,
    ActionList,
    Skeleton,
    Tooltip,
    CheckboxISP,
    ConfirmModal,
    PlanComments,
    ConfirmationModal
  },
  data() {
    return {
      legendList: [
        {
          id: 0,
          color: "#E4F0FF",
          text: "Запланированное время"
        },
        {
          id: 1,
          color: "#F5F8FE",
          text: "Резервное время"
        }
      ],
      showCriticalWay: false,
      showBasePlan: false,
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `edit`
        },
        {
          isShow: true,
          iconType: "user-add",
          actionText: "Настроить доступ",
          actionType: `access`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ],
      confirmDeleteModal: false,
      showModalGiveAccess: false,
      fromRoute: null,
      tick: true,
      usersLoader: false
    };
  },
  async mounted() {
    this.usersLoader = true;
    if (this.currentPlan?.id !== this.planId) {
      try {
        await Promise.all([
          PlanningScheduleModule.getPlanAction(this.planId),
          PlanningScheduleModule.getStepListAction({ plan_id: this.planId }),
          PlanningScheduleModule.getStepLinkListAction({ plan_id: this.planId })
        ]);
      } catch {
        this.$router.push({ name: "WorkPlans" });
      }
    }
    PlanningScheduleModule.getBasePlanAllAction(this.planId);
    PlanningScheduleModule.findCriticalPath();

    this.resetAcceptUsers();
    if (this.isAuthor) this.getAccessUsers();
    this.reRender();
  },
  beforeDestroy() {
    PlanningScheduleModule.showBasePlanAction(null);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  methods: {
    goBack() {
      this.$router.push({ name: "WorkPlans" });
    },
    handleAction(val) {
      switch (val.actionType) {
        case "edit":
          this.$router.push({ name: "Plan", query: { id: this.planId, mode: "edit" } });
          break;
        case "access":
          this.showModalGiveAccess = true;
          break;
        case "delete":
          this.confirmDeleteModal = true;
          break;
      }
    },
    cancelDelete() {
      this.confirmDeleteModal = false;
    },
    async acceptDelete() {
      this.confirmDeleteModal = false;
      await PlanningScheduleModule.deletePlanAction(this.planId);
      this.$router.push({ name: "WorkPlans" });
      NotificationObserver.notification({
        type: "success",
        message: "План успешно удален!"
      });
    },
    closeModalGiveAccess() {
      this.showModalGiveAccess = false;
    },
    giveAccess(body) {
      PlanningScheduleModule.createAccessUsersAction(body);
      this.showModalGiveAccess = false;
    },
    toggleShowComments(val) {
      PlanningScheduleModule[PlanningScheduleMutationTypes.TOGGLE_SHOW_COMMENTS](val);
    },
    getAccessUsers() {
      PlanningScheduleModule.getAccessUsersAction({
        plan_id: this.planId,
        per_page: 50,
        page: 1
      })
        .then(() => (this.usersLoader = false))
        .catch(() => (this.usersLoader = false));
    },
    resetAcceptUsers() {
      PlanningScheduleModule[PlanningScheduleMutationTypes.SET_ACCESS_USERS]({
        count: 0,
        list: [],
        page: 1,
        per_page: 50
      });
    },
    reRender() {
      document.querySelector(".project-network__graph").scrollLeft = 0;
      this.tick = false;
      this.$nextTick(() => {
        this.tick = true;
      });
    }
  },
  computed: {
    planId() {
      return Number(this.$route.query.id);
    },
    planInfoLoader() {
      return PlanningScheduleModule.planInfoLoader;
    },
    currentPlan() {
      return PlanningScheduleModule.currentPlan;
    },
    stepList() {
      return PlanningScheduleModule.stepList;
    },
    stepLinksList() {
      return PlanningScheduleModule.stepLinksList;
    },
    basePlanList() {
      return PlanningScheduleModule.basePlanList;
    },
    basePlanToShow() {
      return PlanningScheduleModule.basePlanToShow;
    },
    baseStepList() {
      return PlanningScheduleModule.baseStepList(this.basePlanToShow?.id);
    },
    showPlanComments() {
      return PlanningScheduleModule.showComments;
    },
    countComments() {
      return this.currentPlan && this.currentPlan.count_comments
        ? this.currentPlan.count_comments
        : 0;
    },
    accessUsers() {
      return PlanningScheduleModule.accessUsers
        ? PlanningScheduleModule.accessUsers?.list.map((item) => item.user_id)
        : [];
    },
    isAuthor() {
      return this.currentPlan?.author_id === this.getUserId;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isShowConfirmModal() {
      return TaskManagerModuleV2.showConfirmationModal;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translate(100%, 0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.project-network {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  &__header {
    padding: 32px 24px;
    display: flex;
    h2 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #343748;
      display: flex;
      align-items: center;
      max-width: 500px;
      i {
        color: $icon-subdued;
        font-size: 24px;
        margin-right: 12px;
        transition: 0.2s all ease;
        cursor: pointer;
        &:hover {
          color: $link-default;
        }
      }
    }
  }

  &__control {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &__checkbox {
    display: flex;
    p {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #343748;
    }
    /deep/ {
      .checkbox-bg {
        margin-right: 8px;
      }
      .icon-info {
        margin-left: 2px;
      }
    }
  }
  &__legend {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 24px;
    ul {
      display: flex;
      gap: 32px;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 29px;
      color: #000000;
      div {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin-right: 4px;
      }
    }
  }
  &__comments {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    &:hover {
      i {
        color: $link-default;
      }
      span {
        color: $link-default;
      }
    }
    i {
      color: $icon-subdued;
      font-size: 24px;
      margin-right: 6px;
      transition: 0.2s all ease;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 29px;
      color: #000000;
      transition: 0.2s all ease;
    }
  }
  &__graph {
    height: calc(100% - 155px);
    overflow: auto;
  }

  /deep/ {
    .critical-plan .tooltip__content {
      max-width: 400px;
      padding: 4px;
      left: unset;
      right: -300px;
    }

    .base-plan .tooltip__content {
      max-width: 400px;
      padding: 4px;
    }
    .confirmation-modal__wrap {
      max-width: 640px;
      width: 100%;
    }
  }
}
.skeleton {
  &_name {
    height: 100%;
    width: 200px;
  }
}
</style>
