<template>
  <div class="confirmation-user__wrap">
    <img :src="setAvatar" alt="" class="confirmation-user__avatar" />
    <p class="confirmation-user__name">{{ item.full_name }}</p>
    <i class="icon-check" v-if="checkSelect"></i>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "ConfirmationUserItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    selectMembers: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setAvatar() {
      return domainStorage + this.item.avatar;
    },
    checkSelect() {
      return !!this.selectMembers.find((item) => item.id === this.item.id);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

.confirmation-user {
  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px;
  }
  &__avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: $text-body-dark;
  }

  .icon-check {
    display: block;
    margin-left: auto;
    font-size: 25px;
  }
}
</style>
