<template>
  <div class="video-player" @click.self="setVideoPlayerData(null)">
    <video id="player" playsinline preload controls autoplay>
      <source :src="returnSrcFile(item.path)" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";

export default {
  name: "VideoPlayerModal",
  mixins: [],
  props: {
    item: {
      type: Object
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setVideoPlayerData"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.video-player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  video {
    max-height: 90%;
    max-width: 50%;
    border-radius: 4px;
  }
}
</style>
