































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList.vue";
import draggableModals from "@/aopV2/modules/Calendar/helper/draggableModals";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal.vue";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import NotificationObserver from "@/helpers/UserNotification";
import _ from "lodash";

@Component({
  components: { ButtonNewDesign, MainModal, UserCard, Skeleton, SelectList, TextInput }
})
export default class CalendarGroup extends Mixins(draggableModals) {
  addUsers: boolean = false;
  name: string = "";
  users: Array<any> = [];
  usersAdd: Array<any> = [];
  usersDelete: Array<any> = [];

  beforeDestroy() {}

  mounted() {
    if (this.group && this.mode === "edit") {
      this.name = this.group.name;
      this.users =
        this.group.users && this.group.users.length
          ? this.group.users?.map((item) => {
              return { id: item };
            })
          : [];
    }
  }

  //methods
  hideModal() {
    this.$emit("hideModal");
  }

  handleCancel() {
    if (this.mode === "create") {
      this.$emit("hideModal");
    } else {
      CalendarModule2.toggleShowGroupModal({ show: true, mode: "show" });
    }
  }

  handleEdit() {
    CalendarModule2.toggleShowGroupModal({ show: true, mode: "edit" });
    if (this.group) {
      this.name = this.group.name;
      this.users =
        this.group.users && this.group.users.length
          ? this.group.users?.map((item) => {
              return { id: item };
            })
          : [];
    }
  }

  getMember(id) {
    return this.getUsers.length ? this.getUsers.find((item) => item.id === id) : [];
  }

  addUsersToggle(val) {
    this.addUsers = val;
  }

  addUserClose() {
    this.addUsers = false;
  }

  deleteUsers(id) {
    this.users = this.users.filter((item) => item.id !== id);
  }

  handleInput(event) {
    this.name = event.target.value;
  }

  acceptHandler() {
    if (this.mode === "create") {
      this.createGroup();
    } else {
      this.editGroup();
    }
  }

  handleChangeUser(selected) {
    this.users = selected;
  }

  createGroup() {
    if (!this.checkValidation()) return false;
    CalendarModule2.createGroupAction({
      name: this.name,
      users: this.users.length ? this.users.map((item) => item.id) : [],
      author_id: this.$store.getters.getUserId
    });
  }

  handleDelete(user) {
    this.users = this.users.filter((item) => item.id !== user.id);
  }

  deleteGroup() {
    if (this.group)
      CalendarModule2.deleteGroupAction({
        id: this.group?.id
      });
    this.cancelConfirm();
  }

  confirmDeleteGroup() {
    ModalModule.setConfirmSetting({
      title: "Удалить группу?",
      text: "Все выбранные участники будут удалены и вам придется создавать группу заново.",
      confirmButton: "Удалить",
      cancelButton: "Отменить",
      handleConfirm: () => this.deleteGroup(),
      handleCancel: () => this.cancelConfirm()
    });
    ModalModule.toggleConfirmation(true);
  }

  cancelConfirm() {
    ModalModule.setConfirmSetting(null);
    ModalModule.toggleConfirmation(false);
  }

  editGroup() {
    if (this.group) {
      if (!this.checkValidation()) return false;
      let addArr = this.users.filter(
        (e) => this.group?.users && this.group?.users.findIndex((i) => i == e.id) === -1
      );
      addArr = [...new Set(addArr.map((item) => item.id))];
      const arrId = this.users.map((item) => item.id);
      let deleteArr = _.difference(this.group?.users, arrId);
      CalendarModule2.editGroupAction({
        id: this.group?.id,
        name: this.name,
        users_add: addArr,
        users_delete: deleteArr
      });
      this.usersAdd = [];
      this.usersDelete = [];
    }
  }

  checkValidation() {
    if (!this.name.trim().length) {
      NotificationObserver.notification({
        type: "error",
        message: "Введите название группы!"
      });
      return false;
    }
    return true;
  }

  // computed getter function
  get group() {
    return CalendarModule2.currentGroup;
  }

  get mode() {
    return CalendarModule2.modeGroupModal;
  }

  get loader() {
    return CalendarModule2.loader;
  }

  get setTitle() {
    return this.mode === "create"
      ? "Создать группу"
      : this.mode === "edit"
      ? "Редактировать группу"
      : this.group && this.group.name;
  }

  get setAcceptTitle() {
    return this.mode === "create" ? "Создать" : this.mode === "edit" ? "Сохранить" : "";
  }

  get getUsers() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "avatar", "id", "is_active", "roles"])
      .filter((item) => item.is_active);
  }

  get showMode() {
    return this.mode !== "create" && this.mode !== "edit";
  }

  @Watch("mode")
  onResetGroupData() {
    if (this.mode === "create") {
      this.name = "";
      this.users = [];
      CalendarModule2.setCurrentGroup(null);
    }
  }
}
