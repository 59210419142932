export default {
  data() {
    return {
      size: 10
    };
  },
  methods: {
    mixin_autoResize_resize(event) {
      event.target.style.height = `${this.size}px`;
      const height = event.target.scrollHeight < this.size ? this.size : event.target.scrollHeight;
      event.target.style.height = `${height}px`;
      this.$emit("input", event.target.value);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const size = this.size > this.$el.scrollHeight ? this.size : this.$el.scrollHeight;
      this.$el.setAttribute("style", "height", `${size}px`);
    });
  }
};
