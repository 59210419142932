<template>
  <div class="table__wrap" @click.stop="openInfoProblem">
    <div class="table__content" v-if="cell.key === 'criticality'">
      <StatusCritical :critical="row.criticality_id" />
    </div>
    <div class="table__content" v-if="cell.key === 'number'">
      <p class="table__text">{{ row.group.alias }}-{{ row.id }}</p>
    </div>
    <div class="table__content" v-if="cell.key === 'problem'">
      <p class="table__text">{{ row.name }}</p>
    </div>
    <div class="table__content" v-if="cell.key === 'customer_user'">
      <User
        :avatar="row.customer_user.avatar"
        :name="row.customer_user.full_name"
        :id="row.customer_user.id"
        :size="'36px'"
        :showTooltip="false"
        :showInfo="false"
      />
    </div>
    <div class="table__content" v-if="cell.key === 'responsible_user'">
      <User
        :avatar="row.responsible_user.avatar"
        :name="row.responsible_user.full_name"
        :id="row.responsible_user.id"
        :size="'36px'"
        :showTooltip="false"
        :showInfo="false"
      />
    </div>
    <div class="table__content" v-if="cell.key === 'progress'">
      <ProgressBar :percent="row.completion_percentage" />
    </div>
    <div class="table__content" v-if="cell.key === 'status'">
      <StatusProblem :status="row.status" />
    </div>
  </div>
</template>

<script>
import StatusCritical from "@/modules/UiKit/components/statuses/StatusCritical";
import StatusProblem from "@/modules/UiKit/components/statuses/StatusProblem";
import User from "@/components/Widgets/User/User";
import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
import { mapActions } from "vuex";
export default {
  name: "ISPCellContent",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: { ProgressBar, User, StatusProblem, StatusCritical },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["setCurrentInfoProblem", "getFullDataOnProblem"]),
    openInfoProblem() {
      this.setCurrentInfoProblem({ data: this.row, show: true });
      this.getFullDataOnProblem(this.row);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  .table__wrap {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    padding: 8px 0;
  }

  .table__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .table__text {
    padding-right: 8px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
