





























































import { Component, Prop, Vue } from "vue-property-decorator";
import AbsenceModule, { AbsenceMutationTypes } from "@/modules/Absence/store/AbsenceModule";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Select from "@/modules/UiKit/components/Select/Select";
import DatePickerWidget from "@/modules/News/components/NewsCreateAndEdit/NewsCreateAndEditComponent/DateTimePicker.vue";
import moment from "moment";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import { EventBus } from "@/main";

@Component({ components: { Select, Label, DatePickerWidget, Button } })
export default class AbsenceModal extends Vue {
  currentUser: Array<any> = [];
  currentType: Array<any> = [];
  dateStop: string | null = null;
  dateStart: string = moment().format("YYYY-MM-DD");

  mounted() {
    if (this.modalData) {
      this.currentUser = this.user;
      this.currentType = this.absenceType;
      this.dateStart = this.modalData.from_date;
      this.dateStop = this.modalData.to_date;
    }
  }

  handleClose() {
    this.$emit("closeModal");
    AbsenceModule.toggleModal(false);
    AbsenceModule[AbsenceMutationTypes.SET_ABSENCE_EDIT_DATA](null);
  }

  handleCancel() {
    this.handleClose();
  }

  handleConfirm() {
    if (this.checkValidation) {
      if (this.modalData) {
        AbsenceModule.editAbsence({
          id: this.modalData.id,
          from_date: moment(this.dateStart).format("YYYY-MM-DD"),
          to_date: this.dateStop ? moment(this.dateStop).format("YYYY-MM-DD") : null,
          user_id: this.currentUser[0].id,
          absence_type_id: this.currentType[0].id
        });
      } else {
        AbsenceModule.createAbsence({
          from_date: moment(this.dateStart).format("YYYY-MM-DD"),
          to_date: this.dateStop ? moment(this.dateStop).format("YYYY-MM-DD") : null,
          user_id: this.currentUser[0].id,
          absence_type_id: this.currentType[0].id
        });
      }
    }
  }

  get checkValidation() {
    if (!this.currentUser.length) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Выберите сотрудника!`
      });
      return false;
    }
    if (!this.currentType.length) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Выберите тип отсутсвия!`
      });
      return false;
    }
    if (!moment(this.dateStart).isValid()) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Выберите дату начала!`
      });
      return false;
    }
    if (moment(this.dateStart) > moment(this.dateStop)) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Дата начала не может быть больше даты окончания!`
      });
      return false;
    }
    return true;
  }

  get modalData() {
    return AbsenceModule.modalData;
  }

  get title() {
    return this.modalData ? "Редактировать отсутсутвие" : "Добавить отсутствие";
  }

  get buttonTitle() {
    return this.modalData ? "Сохранить" : "Добавить";
  }

  get user() {
    return this.modalData
      ? this.$store.getters["UsersModule/userById"](this.modalData.user_id)
      : [];
  }

  get absenceType() {
    return this.modalData ? [AbsenceModule.absenceType(this.modalData.absence_type_id)] : [];
  }

  get types() {
    return AbsenceModule.absenceTypes;
  }

  get users() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "avatar", "is_active"])
      .filter((item) => item.is_active);
  }

  get isDisabledUser() {
    return false;
  }
}
