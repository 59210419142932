<template>
  <div class="statistics__black-sphere black-sphere" @click.self="$emit('hideModal')">
    <div class="black-sphere__window">
      <div class="black-sphere__header">
        <BlackSphereWidgetNew :blackSphere="setBlackSphere" />
        <i class="icon-cross" @click="$emit('hideModal')"></i>
      </div>
      <ul class="black-sphere__list">
        <BlackSphereItem v-for="(item, index) in sphereList" :key="index" :item="item" />
        <li class="black-sphere__item black-sphere__item_empty" v-if="!sphereList.length">
          Нет оценок :(
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconWidgets from "@/assets/aop-icons/IconWidgets";
import BlackSphereItem from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereItem";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import BlackSphereWidgetNew from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereWidget/BlackSphereWidgetNew";
export default {
  name: "BlackSphereModal",
  mixins: [],
  props: {
    blackSphere: {
      type: Array,
      default: () => {
        return [];
      }
    },
    sphereList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { BlackSphereWidgetNew, BlackSphereItem, IconWidgets },
  data() {
    return {
      showTooltip: false
    };
  },
  mounted() {},
  methods: {
    hideTooltip() {
      this.showTooltip = false;
    }
  },
  computed: {
    setDateAfterChangeBSM() {
      return moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").isAfter(
        moment(new Date("2021-11-01"))
      );
    },
    setDateSameChangeBSM() {
      return (
        moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").format(
          "YYYY-MM-DD"
        ) === moment(new Date("2021-11-01")).format("YYYY-MM-DD")
      );
    },
    setBlackSphere() {
      return this.blackSphere.length ? this.blackSphere[0] : null;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 10000000;

  @media (max-width: $sm) {
    align-items: flex-end;
  }
  @media (max-height: $xs) {
    padding: 0;
  }
  &__window {
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    max-width: 712px;
    width: 100%;
    height: 428px;
    padding: 32px;

    @media (max-width: $sm) {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 372px;
      border-radius: 24px 24px 0 0;
    }
    @media (max-height: $xs) {
      max-width: unset;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      overflow: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 32px;
    justify-content: space-between;
    .icon-widget {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $xxxl) {
        width: 34px;
        height: 34px;
      }
    }
    .icon-cross {
      font-size: 24px;
      line-height: 28px;
      cursor: pointer;
      color: $icon-subdued;
      transition: all 0.3s ease;
      &:hover {
        color: $icon-default;
      }
    }
  }

  &__icon-widget {
    display: flex;
    align-items: center;
  }

  &__subtitle {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    color: $text-body-dark;

    @media (max-width: $xxxl) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: $text-body-muted;

    span {
      display: block;
      margin-left: auto;
    }

    @media (max-width: $xxxl) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__tooltip {
    position: absolute;
    padding: 5px 8px;
    color: $surface-default;
    transition: all 0.3s ease;
    top: -28px;
    left: 99%;
    font-size: 14px;
    line-height: 20px;
    background-color: $bg-surface-dark;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
    white-space: nowrap;
    z-index: 10;

    @media (max-width: 1480px) {
      white-space: normal;
      width: 310px;
      left: 110%;
    }

    @media (max-width: $xxl) {
      white-space: normal;
      width: 255px;
      left: 110%;
    }

    @media (max-width: $xl) {
      white-space: normal;
      width: 255px;
      left: unset;
      right: 0;
      top: 99%;
    }

    @media (max-width: $sm) {
      white-space: normal;
      width: 190px;
      left: 110%;
      right: unset;
      top: -20px;
    }

    @media (max-width: $xxs) {
      white-space: normal;
      width: 155px;
      left: 110%;
      right: unset;
      top: -20px;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      white-space: normal;
      width: 255px;
      left: unset;
      right: 0;
      top: 150%;
    }

    a {
      color: $action-secondary;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $action-secondary-hovered;
      }

      &:active {
        color: $action-secondary-pressed;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 300px;
    overflow-y: scroll;
  }

  &__item {
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $text-disabled;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .icon-info {
    margin-left: 4px;
    font-size: 12px;
    color: $text-body-muted;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }
  }
}
</style>
