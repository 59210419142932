<template>
  <div class="search-column">
    <Spinner v-if="searchLoader" class="search-column__loader" />
    <div
      class="search-column__wrap"
      v-else-if="!searchLoader && (taskList.length || projectList.length)"
    >
      <div class="search-column__projects" v-if="projectList.length">
        <h5>Проекты</h5>
        <ul class="search-column__project-list list">
          <FoundProject v-for="project in projectList" :foundProject="project" :key="project.id" />
          <p v-if="projectSearchResult.count > projectList.length" @click="showMoreProjects">
            Показать ещё
          </p>
        </ul>
      </div>
      <div class="search-column__tasks" v-if="taskList.length">
        <h5>Задачи</h5>
        <ul class="search-column__task-list list">
          <FountTask v-for="task in taskList" :foundTask="task" :key="task.id" />
          <p v-if="taskSearchResult.count > taskList.length" @click="showMoreTasks">Показать ещё</p>
        </ul>
      </div>
    </div>
    <div class="search-column__empty" v-else>
      <p>НИЧЕГО НЕ НАЙДЕНО</p>
    </div>
  </div>
</template>

<script>
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import FoundProject from "@/modules/TaskManager/components/SearchComponents/FoundProject";
import FountTask from "@/modules/TaskManager/components/SearchComponents/FoundTask";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "SearchColumn",
  mixins: [],
  props: {},
  components: { Spinner, FountTask, FoundProject },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showMoreProjects() {
      TaskManagerModuleV2.getMoreEntitiesInSearch({
        search_by: "projects",
        query: this.searchValue,
        page: this.projectSearchResult.page + 1
      });
    },
    showMoreTasks() {
      TaskManagerModuleV2.getMoreEntitiesInSearch({
        search_by: "tasks",
        query: this.searchValue,
        page: this.taskSearchResult.page + 1
      });
    }
  },
  computed: {
    projectSearchResult() {
      return TaskManagerModuleV2.projectSearchResult;
    },
    taskSearchResult() {
      return TaskManagerModuleV2.taskSearchResult;
    },
    projectList() {
      return TaskManagerModuleV2.foundProjectList;
    },
    taskList() {
      return TaskManagerModuleV2.foundTaskList;
    },
    searchLoader() {
      return TaskManagerModuleV2.searchLoader;
    },
    searchValue() {
      return TaskManagerModuleV2.searchValue;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

h5 {
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}
::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: $bg-gray-400;
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $surface-disabled;
}
.search-column {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  &__loader {
    position: absolute;
    top: 50%;
    left: 45%;
  }
  &__wrap {
    width: 100%;
    height: fit-content;
    padding: 16px 24px;
  }
  .list {
    list-style: none;
    margin: 0 0 16px 0;
    padding: 0;
    height: 100%;
    p {
      font-size: 12px;
      cursor: pointer;
      color: $link-default;
      padding: 12px 0;
    }
  }
  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      display: block;
      height: fit-content;
      color: $text-default;
    }
  }
}
</style>
