<template>
  <transition name="show-modal">
    <div class="action-list__modal" v-click-outside="hideModal">
      <button class="action-list__setting-button" type="button" @click="handleClickIconDots">
        <i class="icon-dots-vertical" v-if="!avatarUser.length"></i>
        <img class="avatar-user" v-else :src="returnSrcFile(avatarUser)" alt="avatar" />
      </button>
      <ul class="action-list__modal-list" v-if="modalShow" @click="hideModal">
        <ActionItem
          :item="item"
          v-for="(item, index) in actionList"
          :key="item.actionType + index"
          @action_item_handle="actionItemHandle"
        />
      </ul>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ActionItem from "@/components/Widgets/ActionList/ActionItem";
import { domainStorage } from "@/globalVariables";

export default {
  name: "ActionsList",
  mixins: [],
  props: {
    actionList: {
      type: Array,
      default: () => []
    },
    avatarUser: {
      type: String,
      default: ""
    }
  },
  components: { ActionItem },
  data() {
    return {
      modalShow: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleClickIconDots() {
      this.modalShow = !this.modalShow;
    },
    hideModal() {
      this.modalShow = false;
    },
    actionItemHandle(val) {
      this.$emit(`action_item_${val}`); //нужно чтобы передать пользовательское событие дальше, не используя $root
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.2s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.avatar-user {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  @media screen and (max-width: $md) {
    width: 24px;
    height: 24px;
  }
}

.action-list {
  position: relative;
  &__modal {
    position: relative;
    margin-left: auto;
  }

  &__setting-button {
    width: 32px;
    height: 32px;
    background: $action-secondary;
    border-radius: 4px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: $icon-subdued;
      font-size: 24px;
      text-align: center;
    }
    &:hover {
      background: $action-secondary-hovered;

      i {
        color: $icon-subdued;
      }
    }

    &:active,
    &:focus {
      background: $action-secondary-pressed;
    }
  }

  &__modal-list {
    position: absolute;
    z-index: 15;
    top: 34px;
    right: 0;
    padding: 8px 0;
    list-style: none;
    min-width: 180px;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;
  }

  &__modal-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;
    }
  }
}
</style>
