import * as types from "@/modules/News/store/mutations/mutationTypes";
import {
  CHANGE_EMPLOYEE_LOADER,
  CHANGE_GLOBAL_EDIT_MODE,
  CHOOSE_NEWS_TO_UPDATE,
  SET_CURRENT_EDITABLE_COMMENT,
  SET_CURRENT_REPLYABLE_COMMENT,
  SET_EMPLOYEE_ITEMS,
  SET_EMPLOYEE_LOADER,
  SET_NEWS_ITEMS,
  SET_NEWS_TOTAL_ITEMS,
  SHOW_NEWS_BIRTHDAY_MODAL,
  TOGGLE_CURTAIN_CHANNEL_LOADER,
  TOGGLE_CURTAIN_LIKES_LOADER,
  TOGGLE_SHOW_CREATE_CURTAIN,
  TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL,
  TOGGLE_SHOW_CURTAIN_NEWS_LIKES,
  TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL
} from "@/modules/News/store/mutations/mutationTypes";

const mutations = {
  [types.CHANGE_IMAGE_LINK](state, payload) {
    state.imageLink = payload;
  },
  [types.CHANGE_SHOW_FULL_SIZE_IMAGE](state, payload) {
    state.showFullSizeImage = payload;
  },
  [types.CHANGE_SHOW_ADD_CHANNEL](state, payload) {
    state.showAddChannel = payload;
  },
  [types.CHANGE_EDIT_CHANNEL_VALUE](state, payload) {
    state.editChannelValue = payload;
  },
  [types.CHANGE_SHOW_CHANNEL](state, payload) {
    state.showChannel = payload;
  },
  [types.CHANGE_MEMBER_INFO_CHANNEL](state, payload) {
    state.memberInfoChannel = payload;
  },
  [types.CHANGE_MEMBER_USER](state, payload) {
    state.memberUser = payload;
  },
  [types.CHANGE_IS_ADMIN_LIKE](state, payload) {
    state.isAdminLike = payload;
  },
  [types.CHANGE_MEMBER_LIST_TITLE](state, payload) {
    state.memberListTitle = payload;
  },
  [types.CHANGE_ACTIVE_FILTER](state, payload) {
    state.activeFilter = payload;
  },
  [types.SET_BIRTHDAY_ITEMS](state, payload) {
    state.birthdayItems = payload;
  },
  [types.CHANGE_BIRTHDAY_LOADER](state, payload) {
    state.birthdayLoader = payload;
  },
  [types.SET_EMPLOYEE_ITEMS](state, payload) {
    state.employeeItems = payload;
  },
  [types.CHANGE_EMPLOYEE_LOADER](state, payload) {
    state.employeeLoader = payload;
  },
  [types.SET_CHANNEL_LIST](state, payload) {
    state.channelList = payload;
  },
  [types.CHANGE_LOADING_CHANNELS](state, payload) {
    state.loadingChannels = payload;
  },

  [types.CHANGE_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SET_NEWS_COUNTER](state, payload) {
    state.newsCounter = payload;
  },
  [types.CHANGE_ACTIVE_CHANNEL](state, payload) {
    state.activeChannel = payload;
  },
  [types.SET_NEWS_ITEMS](state, payload) {
    state.newsItems = payload;
  },
  [types.SET_NEWS_TOTAL_COUNT](state, payload) {
    state.newsTotalCount = payload;
  },
  [types.CHANGE_GLOBAL_EDIT_MODE](state, payload) {
    state.globalEditMode = payload;
  },
  [types.CHANGE_SCROLL_LOADING](state, payload) {
    state.scrollLoading = payload;
  },
  [types.SET_CHANNEL_ALL_LIST](state, payload) {
    state.channelAllList = payload;
  },
  [types.SET_CURRENT_EDITABLE_COMMENT](state, payload) {
    state.currentEditableComment = payload;
  },
  [types.SET_CURRENT_REPLYABLE_COMMENT](state, payload) {
    state.currentReplyableComment = payload;
  },
  [types.CHOOSE_NEWS_TO_UPDATE](state, payload) {
    state.newsToUpdate = payload;
  },
  [types.TOGGLE_SHOW_CREATE_CURTAIN](state, payload) {
    state.showCurtainCreateNews = payload;
  },
  [types.TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL](state, payload) {
    state.showCurtainCreateChannel = payload;
  },
  [types.TOGGLE_SHOW_CURTAIN_NEWS_LIKES](state, payload) {
    state.showCurtainNewsLikes = payload;
  },
  [types.SET_CURRENT_NEWS_LIKES](state, payload) {
    state.currentNewsLikes = payload;
  },
  [types.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL](state, payload) {
    state.showCurtainShowAndEditChannel = payload;
  },
  [types.TOGGLE_IS_CERTAIN_NEWS](state, payload) {
    state.isCertainNews = payload;
  },
  [types.TOGGLE_CURTAIN_LIKES_LOADER](state, payload) {
    state.curtainLikesLoader = payload;
  },
  [types.TOGGLE_CURTAIN_CHANNEL_LOADER](state, payload) {
    state.curtainChannelLoader = payload;
  },
  [types.SHOW_CHANNEL_MODAL_MENU](state, payload) {
    state.showChannelModalMenu = payload;
  },
  [types.SHOW_CHANNEL_MODAL_CHANNELS](state, payload) {
    state.showChannelModalChannels = payload;
  },

  [types.SHOW_NEWS_BIRTHDAY_MODAL](state, payload) {
    state.showNewsBirthdayModal = payload;
  },

  [types.SHOW_NEW_EMPLOYEE_MODAL](state, payload) {
    state.showNewEmployeeModal = payload;
  }
};

export default mutations;
