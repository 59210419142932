<template>
  <router-link
    class="article-component"
    :to="{ name: 'ArticlePage', params: { article_id: item.id } }"
  >
    <div v-if="label" class="article-component__label">Статьи</div>
    <div class="article-component__image">
      <img :src="returnSrcFile(item.cover)" :alt="item.name" />
    </div>
    <div class="article-component__content">
      <div class="article-component__header">
        <span class="article-component__date">{{ convertDate(item.datetime_of_creation) }}</span>
        <span class="article-component__views">
          <i class="icon-eye"></i>
          {{ item.count_views }}</span
        >
      </div>
      <div class="article-component__title">{{ item.name }}</div>
      <div v-if="item.preview" class="article-component__text" v-html="setURL(item.preview)"></div>
    </div>
    <div class="article-component__footer">
      <div class="article-component__likes">
        <LikeComponent
          :parentLike="item"
          :likeableType="'articles'"
          @updateLikeInfo="$emit('updateInfoArticles')"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import { format, parseISO } from "date-fns";
import { ru } from "date-fns/locale";
import { domainStorage } from "@/globalVariables";
import LikeComponent from "@/components/KnowledgeBaseComponents/LikeComponents/LikeComponent";
import UrlMixin from "@/helpers/UrlMixin";

export default {
  name: "ArticleComponent",
  mixins: [UrlMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    label: {
      type: Boolean,
      default: false
    }
  },
  components: { LikeComponent },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    convertDate(date) {
      const data = format(parseISO(date), "dd MMM yyyy HH:mm", {
        locale: ru
      });
      return data;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.article-component {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 234px;
  height: 330px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid $color-contour;
  border-radius: 5px;
  background-color: $color-white;
  cursor: pointer;

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97px;
    height: 26px;
    position: absolute;
    top: 13px;
    color: $color-white;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 0 5px 5px 0;
    background-color: rgba(141, 150, 236, 0.8);
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 139px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    text-align: center;
    border-bottom: 1px solid $color-contour;

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 11px 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  &__views {
    display: flex;
    align-items: center;

    .icon-eye {
      color: $color-text-secondary;
      font-size: 20px;
      margin-right: 8px;
      margin-bottom: 3px;
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px;
    color: $color-text-accent;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $color-text-primary-1;
    white-space: pre-wrap;
    word-break: break-word;

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-left: 11px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-primary-2;
  }

  &__likes {
    user-select: none;
    display: flex;
    align-items: center;

    &__img {
      margin-right: 6px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        fill: $color-button-hover;
        transform: scale(1.1);
      }

      &:active {
        fill: $color-button-click;
        transform: scale(0.9);
      }

      &_active {
      }
    }

    span {
      align-self: flex-end;
    }
  }
}
</style>
