<template>
  <li class="files__item">
    <!--    Image-->
    <div class="files__link" v-if="isImage && file" @click="openImage" :style="'cursor: default'">
      <div class="files__cover">
        <img
          class="files__img"
          v-if="file.path"
          :src="returnSrcFile(file.path)"
          :alt="file.label || file.name"
        />
        <component :is="setFile" v-else />
      </div>
      <div class="files__wrap">
        <div class="files__name">
          {{ file.label || file.name }}
        </div>
        <div class="files__size">{{ sizeFloor }}</div>
      </div>
    </div>
    <!--    Document-->
    <a
      class="files__link"
      v-if="!isImage && file && (file.link || file.path)"
      :href="returnSrcFile(file.link || file.path)"
      :target="isGlobalMobileMode ? '_self' : '_blank'"
      :download="file.label || file.name"
    >
      <div class="files__cover">
        <component :is="setFile" />
      </div>
      <div class="files__wrap">
        <div class="files__name">
          {{ file.label || file.name }}
        </div>
        <div class="files__size">{{ sizeFloor }}</div>
      </div>
    </a>
    <div
      class="files__link"
      v-if="!isImage && file && !file.link && !file.path"
      :style="'cursor: default'"
    >
      <div class="files__cover">
        <component :is="setFile" />
      </div>
      <div class="files__wrap">
        <div class="files__name">
          {{ file.label || file.name }}
        </div>
        <div class="files__size">{{ sizeFloor }}</div>
      </div>
    </div>
    <div class="files__delete" v-if="isEdit">
      <i class="icon-delete" @click.stop="handleDelete"></i>
    </div>
  </li>
</template>

<script>
import { domainS3 } from "@/globalVariables";

export default {
  name: "FilesItem",
  mixins: [],
  props: {
    file: {
      type: Object,
      default: () => {
        return null;
      }
    },
    imgPreview: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      formats: {
        "image/jpg": () => import(`@/modules/Isp/assets/iconFiles/JPG`),
        "image/jpeg": () => import(`@/modules/Isp/assets/iconFiles/JPEG`),
        "image/png": () => import(`@/modules/Isp/assets/iconFiles/PNG`),
        "application/pdf": () => import(`@/modules/Isp/assets/iconFiles/PDF`),
        "application/msword": () => import(`@/modules/Isp/assets/iconFiles/DOC`),
        "video/x-flv": () => import(`@/modules/Isp/assets/iconFiles/FLV`),
        "video/quicktime": () => import(`@/modules/Isp/assets/iconFiles/MOV`),
        "video/x-ms-wmv": () => import(`@/modules/Isp/assets/iconFiles/WMV`),
        "video/mp4": () => import(`@/modules/Isp/assets/iconFiles/MP4`),
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": () =>
          import(`@/modules/Isp/assets/iconFiles/DOCX`),
        "application/vnd.ms-excel": () => import(`@/modules/Isp/assets/iconFiles/XLS`),
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": () =>
          import(`@/modules/Isp/assets/iconFiles/XLSX`),
        "application/vnd.ms-powerpoint": () => import(`@/modules/Isp/assets/iconFiles/PPT`),
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": () =>
          import(`@/modules/Isp/assets/iconFiles/PPTX`),
        "video/x-msvideo": () => import(`@/modules/Isp/assets/iconFiles/AVI`),
        "video/3gpp": () => import(`@/modules/Isp/assets/iconFiles/3GP`),
        "video/mpeg": () => import(`@/modules/Isp/assets/iconFiles/MPEG`),
        "audio/mpeg": () => import(`@/modules/Isp/assets/iconFiles/MP3`),
        doc: () => import(`@/modules/Isp/assets/iconFiles/DOC`),
        docx: () => import(`@/modules/Isp/assets/iconFiles/DOCX`),
        xls: () => import(`@/modules/Isp/assets/iconFiles/XLS`),
        xlsx: () => import(`@/modules/Isp/assets/iconFiles/XLSX`),
        jpeg: () => import(`@/modules/Isp/assets/iconFiles/JPEG`),
        jpg: () => import(`@/modules/Isp/assets/iconFiles/JPG`),
        pdf: () => import(`@/modules/Isp/assets/iconFiles/PDF`),
        png: () => import(`@/modules/Isp/assets/iconFiles/PNG`),
        flv: () => import(`@/modules/Isp/assets/iconFiles/FLV`),
        mp3: () => import(`@/modules/Isp/assets/iconFiles/MP3`),
        mp4: () => import(`@/modules/Isp/assets/iconFiles/MP4`),
        ppt: () => import(`@/modules/Isp/assets/iconFiles/PPT`),
        pptx: () => import(`@/modules/Isp/assets/iconFiles/PPTX`),
        avi: () => import(`@/modules/Isp/assets/iconFiles/AVI`),
        mov: () => import(`@/modules/Isp/assets/iconFiles/MOV`),
        wmv: () => import(`@/modules/Isp/assets/iconFiles/WMV`),
        "3gp": () => import(`@/modules/Isp/assets/iconFiles/3GP`)
      }
    };
  },
  mounted() {
    this.$nextTick(() => (this.path = this.file && this.file.path));
  },
  methods: {
    handleDelete() {
      this.$emit("deleteFile", this.file.id);
    },
    openImage() {
      if (this.file.path_preview || this.file.path_preview === null) {
        const image = {
          path: this.file.path_preview,
          storage: "server",
          name: this.file.name
        };
        this.$emit("openImage", image);
      } else {
        const image = {
          path: this.file.path,
          storage: "local",
          name: this.file.name
        };
        this.$emit("openImage", image);
      }
    },
    returnSrcFile(src) {
      if (src && src.includes("base64")) {
        return src;
      } else return `${domainS3}/${src}`;
    }
  },
  computed: {
    clarifyTypeFile() {
      return this.file.type === "" || !this.file.type
        ? this.file.name.split(".")[1].toLowerCase()
        : this.file.type;
    },
    isImage() {
      return (
        this.file.ext === "jpeg" ||
        this.file.ext === "png" ||
        this.file.ext === "jpg" ||
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png"
      );
    },
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    },
    setFile() {
      return this.formats[this.file.extension] ||
        this.formats[this.clarifyTypeFile] ||
        this.formats[this.file.ext]
        ? this.formats[this.file.extension] ||
            this.formats[this.clarifyTypeFile] ||
            this.formats[this.file.ext]
        : () => import(`@/modules/Isp/assets/iconFiles/DEF`);
    },
    sizeFloor() {
      const size = this.file.size;
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "Кб";
      } else {
        return size + "Б";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.files {
  &__cover {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;

    img {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 16px 0 0;
  }

  &__link {
    display: flex;
    align-items: center;
    min-width: 140px;
  }

  i {
    margin-left: 4px;
    font-size: 16px;
    color: $icon-subdued;
    cursor: pointer;
  }

  &__name {
    display: block;
    width: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__size {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: $text-disabled;
  }
}
</style>
