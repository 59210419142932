import * as types from "@/modules/Calendar/store/mutations/mutationTypes";
import axios from "axios";
import { domain } from "@/globalVariables";
import router from "@/router";
import NotificationObserver from "@/helpers/UserNotification";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

const events = {
  getUserCrossEvent({ commit }, payload) {
    httpClient({
      method: "GET",
      url: `/User/getItems`,
      params: {
        _count: 200,
        is_active: true,
        id: JSON.stringify(payload),
        _with: JSON.stringify(["roles", "departments"])
      }
    }).then((response) => {
      commit(types.GET_CROSS_MEMBERS_USER, response.data.data.items);
    });
  },
  getCurrentEvent({ commit, dispatch }, payload) {
    httpClient({
      url: `/CalendarEvent/actionGetItem/${payload}`,
      method: "GET",
      params: {
        _with: JSON.stringify([
          "responsible_users",
          "member_users",
          "location",
          "absence",
          "attachment_files",
          "currentMemberUser"
        ])
      }
    })
      .then((response) => {
        if (response.data.data.current_member_user) {
          commit(
            types.SET_RESPONSE_ID_CURRENT_EVENT,
            response.data.data.current_member_user.calendar_event_member_user_id
          );
        }
        commit(types.SET_CURRENT_EVENT, response.data.data);
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  /**
   * create calendar event
   */
  createEvent({ commit, dispatch, state }, payload) {
    commit(types.SET_LOADER_CREATE_EVENT, true);
    httpClient({
      url: `/CalendarEvent/actionCreate`,
      method: "POST",
      data: [payload]
    })
      .then((response) => {
        commit(types.SET_LOADER_CREATE_EVENT, false);
        const resp = response.data.data;
        if (
          (resp.has_intersections_by_location || resp.has_intersections_by_members) &&
          !payload.is_ignore_intersections
        ) {
          commit(types.SET_CROSS_EVENT_RESPONSE, {
            has_intersections_by_location: resp.has_intersections_by_location,
            has_intersections_by_members: resp.has_intersections_by_members
          });
          if (resp.has_intersections_by_members) {
            dispatch("getUserCrossEvent", resp.intersections_by_members);
          }
          if (resp.has_intersections_by_location) {
            commit(types.GET_CROSS_EVENTS, resp.intersections_by_location);
          }
          commit(types.SHOW_CROSS_EVENT_MODAL, true);
        } else {
          NotificationObserver.notification({
            type: "success",
            message: "Встреча успешно создана!"
          });
          dispatch("getCalendar", state.calendarParams);
          commit(types.SHOW_CREATE_EVENT_MODAL, false);
          commit(types.SHOW_CROSS_EVENT_MODAL, false);
        }
      })
      .catch((error) => {
        commit(types.SET_LOADER_CREATE_EVENT, false);
        ErrorObserver.notification(error);
      });
  },
  editEvent({ commit, dispatch, state }, payload) {
    commit(types.SET_LOADER_CREATE_EVENT, true);
    if (payload.params.repeat) commit(types.SET_LOADER_REPEAT_EVENT, true);
    httpClient({
      url: `/CalendarEvent/actionUpdate/${payload.id}`,
      method: "PUT",
      data: [payload.params]
    })
      .then((resp) => {
        commit(types.SET_LOADER_CREATE_EVENT, false);
        commit(types.SET_LOADER_REPEAT_EVENT, false);
        if (
          (resp.data.data.has_intersections_by_location ||
            resp.data.data.has_intersections_by_members) &&
          !payload.params.is_ignore_intersections
        ) {
          commit(types.SET_CROSS_EVENT_RESPONSE, {
            has_intersections_by_location: resp.data.data.has_intersections_by_location,
            has_intersections_by_members: resp.data.data.has_intersections_by_members
          });
          if (resp.data.data.has_intersections_by_members) {
            dispatch("getUserCrossEvent", resp.data.data.intersections_by_members);
          }
          if (resp.data.data.has_intersections_by_location) {
            commit(types.GET_CROSS_EVENTS, resp.data.data.intersections_by_location);
          }
          commit(types.SHOW_CROSS_EVENT_MODAL, true);
        } else {
          NotificationObserver.notification({
            type: "success",
            message: "Встреча успешно изменена!"
          });
          commit(types.SHOW_CROSS_EVENT_MODAL, false);
          commit(types.SET_LOADER_REPEAT_EVENT, false);
          dispatch("getCurrentEvent", payload.id);
          dispatch("getCalendar", state.calendarParams);
        }
      })
      .catch((error) => {
        commit(types.SET_LOADER_REPEAT_EVENT, false);
        commit(types.SET_LOADER_CREATE_EVENT, false);
        ErrorObserver.notification(error);
      });
  },
  deleteCurrentEvent({ commit, dispatch, state }, payload) {
    commit(types.SET_LOADER_CREATE_EVENT, true);
    httpClient({
      url: `/CalendarEvent/actionDelete/${payload.id}`,
      method: "DELETE",
      data: [
        {
          type: payload.type
        }
      ]
    })
      .then((response) => {
        NotificationObserver.notification({
          type: "success",
          message: "Встреча успешно удалена!"
        });
        dispatch("getCalendar", state.calendarParams);
        commit(types.SET_LOADER_CREATE_EVENT, false);
        commit(types.SHOW_EVENT_INFO_MODAL, false);
        if (router.app.$route.query.event_id) {
          router.app.$router.replace({ query: {} }).then(() => {});
        }
      })
      .catch((error) => {
        commit(types.SET_LOADER_CREATE_EVENT, false);
        ErrorObserver.notification(error);
      });
  },
  /**
   * Function for changing the status of the event attendance
   */
  changeEventStatus({ state, dispatch, commit }, payload) {
    if (state.currentEvent.current_member_user.is_accepted_invitation !== payload.status) {
      commit(types.SET_LOADER_CREATE_EVENT, true);

      httpClient({
        url: `/CalendarEventMemberUser/actionUpdate/${payload.id}`,
        method: "PUT",
        data: [
          {
            is_accepted_invitation: payload.status,
            type: payload.type
          }
        ]
      })
        .then((response) => {
          commit(types.SET_LOADER_CREATE_EVENT, false);
          NotificationObserver.notification({
            type: "success",
            message: "Статус посещения встречи успешно изменен!"
          });
          dispatch("getCurrentEvent", response.data.data.calendar_event_id);
        })
        .catch((error) => {
          commit(types.SET_LOADER_CREATE_EVENT, false);
          ErrorObserver.notification(error);
        });
    }
  },
  changeDataInfoMemberUser(state, payload) {
    state.memberUser = payload;
  }
};

export default events;
