<template>
  <div class="modal" @click.self="hideModal">
    <div class="modal__window">
      <ModalHeader :title="'Комментарий'" @hideModal="hideModal" />
      <div class="modal__main">
        {{ setComment }}
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from "../../../../../../components/Widgets/ModalsWidgets/ModalHeader";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";

export default {
  name: "BlackSphereMarksCommentsModal",
  mixins: [],
  props: [],
  components: { ModalHeader },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    hideModal() {
      BlackSphere[BlackSphereMutationTypes.TOGGLE_MODAL_BLACK_SPHERE_COMMENT](false);
    }
  },
  computed: {
    setComment() {
      return BlackSphere.modalData;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 100;

  &__window {
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    width: 509px;

    @media (max-width: $sm) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  }

  /deep/ {
    .modal__header {
      border-bottom: none;
      padding: 32px;
    }

    .modal__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $text-default;

      @media (max-width: $sm) {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__main {
    padding: 0 32px 32px;
    max-height: 500px;
    overflow-y: scroll;
    word-break: break-all;

    @media (max-width: $sm) {
      padding: 0 24px 24px;
    }

    @media (max-height: 812px) {
      max-height: 90%;
      height: 90%;
    }
  }
}
</style>
