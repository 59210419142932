<template>
  <router-link :to="{ name: 'UserInfo', params: { user_id: member.id } }" class="member-item">
    <div class="member-item__avatar">
      <img :src="returnAvatar" alt="avatar" />
    </div>
    <div class="member-item__wrap wrap-info">
      <div class="wrap-info__header">
        <div class="wrap-info__label-and-role">
          <h3 class="wrap-info__label">{{ member.full_name }}</h3>
          <p class="wrap-info__role">[{{ returnRole }}]</p>
        </div>

        <i class="icon-email" @click.stop.prevent="openChat"></i>
      </div>
      <p class="wrap-info__nickname">@{{ member.nickname }}</p>
    </div>
  </router-link>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";

export default {
  name: "MemberItemCatalog",
  mixins: [],
  props: {
    member: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setNewChat", "changeShowCatalog", "switchSettingMenu"]),
    openChat() {
      this.setNewChat({ companion: this.member, id: null, type: 1 });
      this.changeShowCatalog(false);
      this.switchSettingMenu(false);
      this.$router.push({ query: { chat_id: "new_chat" } });
    }
  },
  computed: {
    returnAvatar() {
      return domainStorage + this.member.avatar;
    },
    returnRole() {
      return this.member.roles.length ? this.member.roles[0].name : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.member-item {
  display: flex;
  align-items: center;
  width: 300px;
  height: fit-content;
  flex-shrink: 0;
  margin: 0 40px 40px 0;

  @media screen and (max-width: $md) {
    width: 280px;
  }
  @media screen and (max-width: $xs) {
    margin-right: 0;
  }

  &__avatar {
    margin-right: 16px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
      @media screen and (max-width: $xs) {
        width: 42px;
        height: 42px;
      }
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    padding-right: 10px;

    .wrap-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        @media screen and (max-width: $xs) {
          justify-content: space-between;
        }
        i {
          color: $text-disabled;
          cursor: pointer;
        }
      }
      &__label-and-role {
        display: flex;
        overflow: hidden;
      }
      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $text-default;
        margin: 0 4px 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__role {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $text-disabled;
        margin-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__nickname {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: $border-default;
        margin: 0;
      }
    }
  }
}
</style>
