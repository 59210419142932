<template>
  <div class="my-tasks-header">
    <button class="my-tasks-header__menu" type="button" @click="openSidebar">
      <i class="icon-menu"></i>
    </button>
    <div class="my-tasks-header__label">Мои задачи</div>
    <div class="my-tasks-header__filters">
      <ChosenFilter
        v-model="roleValue"
        :icon="'clipboard-ind'"
        :placeholder="'Роли'"
        :search="false"
        :allValue="null"
        :chosenList="rolesArray"
        @updateInfo="updateTaskTable"
      />
      <ChosenFilter
        v-model="projectValue"
        :icon="'folder'"
        :placeholder="'Проекты'"
        :search="true"
        :multiple="true"
        :allValue="'Все проекты'"
        :modifier="'middle'"
        :chosenList="projectList"
        :isLoading="projectPaginationLoader"
        @updateInfo="updateTaskTable"
        @reachLastItem="getMoreProjects"
      />
      <ChosenFilter
        v-model="statusValue"
        :icon="'circle-check'"
        :placeholder="'Статус'"
        :search="false"
        :allValue="null"
        :chosenList="statusArray"
        :modifier="'last'"
        @updateInfo="updateTaskTable"
      />
    </div>
  </div>
</template>

<script>
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import { mapMutations } from "vuex";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";

export default {
  name: "MyTasksHeader",
  mixins: [],
  props: {},
  components: { ChosenFilter },
  data() {
    return {
      roleValue: [
        {
          id: "executor",
          name: "Исполнитель"
        }
      ],
      rolesArray: [
        {
          id: "author",
          name: "Автор"
        },
        {
          id: "executor",
          name: "Исполнитель"
        }
      ],
      statusValue: [
        {
          id: "all",
          name: "Все статусы"
        }
      ],
      statusArray: [
        {
          id: "all",
          name: "Все статусы"
        },
        {
          id: "active",
          name: "Активные"
        },
        {
          id: "closed",
          name: "Закрытые"
        }
      ],
      projectValue: []
    };
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    ProjectModule.getProjectListAction();
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    },
    updateTaskTable() {
      this.$emit("updateParams", this.setParams);
      this.$emit("updateInfo", this.setParams);
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("my-task-table"));
      this.projectValue = setting.projects ? setting.projects : [];
      this.statusValue = setting.status ? setting.status : [];
      this.roleValue = setting.roles ? setting.roles : [];
      const params = {
        page: 1,
        per_page: 15,
        is_dashboard: 0,
        status: setting.status[0]?.id,
        role: setting.roles[0]?.id,
        projects: setting.projects
      };
      TaskModule[TaskMutationTypes.SET_PARAMS](params);
    },
    setCookieValue() {
      const option = {
        projects: this.projectValue,
        status: this.statusValue,
        roles: this.roleValue
      };
      window.localStorage.setItem("my-task-table", JSON.stringify(option));
    },
    getMoreProjects() {
      ProjectModule.getProjectByPagination();
    }
  },
  computed: {
    projectList() {
      return ProjectModule.projectList.map((item) => item.project);
    },
    valueSort() {
      return TaskModule.valueSort;
    },
    setParams() {
      return {
        page: 1,
        per_page: 15,
        projects: this.projectValue.length ? this.projectValue.map((item) => item.id) : undefined,
        status: this.statusValue.length ? this.statusValue[0].id : undefined,
        role: this.roleValue.length ? this.roleValue[0].id : undefined,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
    },
    projectPaginationLoader() {
      return ProjectModule.projectPaginationLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.my-tasks-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 24px;
  flex-shrink: 0;

  @media (max-width: $xxl) {
    justify-content: flex-start;
  }

  @media (max-width: $sm) {
    padding: 0 16px;
  }

  &__menu {
    display: none;

    @media (max-width: $xxl) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      margin-right: 16px;
      cursor: pointer;
      outline: none;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #000000;

    @media (max-width: $xxl) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__filters {
    display: flex;
    align-items: center;

    @media (max-width: $xxl) {
      margin-left: auto;
    }

    /deep/ {
      .chosen-filter {
        &__list {
          @media (max-width: $sm) {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      .chosen-filter:last-child {
        .chosen-filter__list {
          right: 0;
          left: unset;
        }
      }

      .chosen-filter_middle {
        .chosen-filter__list {
          right: 0;
          left: unset;
        }
      }

      .chosen-filter__label {
        @media (max-width: $sm) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
