






















import SwitchThreeStateWidget from "@/components/Widgets/SwitchWidget/SwitchThreeStateWidget";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import { EventBus } from "@/main";
import { IEventDataProfile } from "@/aopV2/modules/Calendar/service/CalendarInterface";

export interface IActionItem {
  value: string;
  size: number;
  name: string;
}

@Component({
  components: {
    SwitchThreeStateWidget
  }
})
export default class ProfileEventItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  event!: IEventDataProfile;
  arrayRadio: Array<IActionItem> = [
    { name: "Не приду", value: "false", size: 12 },
    { name: "Возможно", value: "null", size: 12 },
    { name: "Приду", value: "true", size: 12 }
  ];
  prohibitionChangeStatus: boolean = false;

  beforeDestroy() {}

  mounted() {
    if (this.$route.params.user_id && this.$route.params.user_id !== this.userId) {
      this.prohibitionChangeStatus = true;
    }
  }
  async handleChangeInvitation(value) {
    if (this.event.is_accepted_invitation !== value && !this.eventPassed) {
      const payload = {
        event_id: this.event.id!,
        is_accepted_invitation: JSON.parse(value),
        just_current: true
      };
      await CalendarModule2.changeInvitation(payload).then(() => {
        EventBus.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: "Мероприятие успешно отредактировано!"
        });
      });
      ModalModule.toggleConfirmation(false);
      CalendarModule2.setJustCurrent(true);
    }
  }

  get userId() {
    return this.$store.getters.getUserId;
  }
  get eventPassed() {
    return moment(this.event.started_at).isBefore(moment());
  }

  get currentInvitation() {
    return this.arrayRadio.find((item) => item.value === String(this.event.is_accepted_invitation));
  }

  redirectCalendarEvent(event) {
    this.$router.push({ name: "Calendar", query: { event_id: event.id } });
  }

  get setTimeEvent() {
    return `${moment(this.event.started_at).format("HH:mm")}-${moment(this.event.ended_at).format(
      "HH:mm"
    )}`;
  }

  get locations() {
    return CalendarModule2.locations;
  }

  get location() {
    const locations = this.locations.map((item) => item);
    locations.unshift({
      name: "Без кабинета",
      id: 0
    });

    return locations.find((item) =>
      this.event.location_id ? item.id === this.event.location_id : item.id === 0
    );
  }
}
