















import { Component, Vue } from "vue-property-decorator";
import MainTable, { IHeader, typeCell } from "@/components/MainTable/MainTable.vue";
import CellContent from "@/components/MainTable/Cell/CellContent.vue";
import firebase from "firebase/compat";
import onLog = firebase.onLog;

@Component({
  components: { CellContent, MainTable }
})
export default class NewTable extends Vue {
  tableHeader: Array<IHeader> = [
    {
      name: "Id",
      field: "id",
      sort: true,
      isCenter: true,
      colspan: 1,
      type: typeCell.number
    },
    {
      name: "Имя",
      field: "full_name",
      sort: true,
      isCenter: false,
      colspan: 5,
      type: typeCell.string
    },
    {
      name: "Дата рождения",
      field: "date_of_birth",
      sort: false,
      isCenter: true,
      colspan: 2,
      type: typeCell.date
    },
    {
      name: "Email",
      field: "email",
      sort: false,
      isCenter: false,
      colspan: 4,
      type: typeCell.email
    }
  ];
  sort = { field: "email", type: "desc" };

  sortCell(sort) {
    this.sort = sort;
  }

  getTableDate() {
    console.log("get data");
  }

  get users() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "avatar", "date_of_birth", "email", "is_active"])
      .splice(0, 45);
  }

  get usersSort() {
    return this.users.sort((a, b) => {
      if (this.sort.type === "asd") {
        return a[this.sort.field] - b[this.sort.field];
      } else {
        return b[this.sort.field] - a[this.sort.field];
      }
    });
  }
}
