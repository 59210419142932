<template>
  <div class="task-body__params">
    <ul class="task-body__list">
      <li class="task-body__item">
        <DeadLineComponent
          v-model="dateStart"
          :value="dateStart"
          :label="'Дата начала'"
          :deadline="false"
          :isEdit="isEdit"
          :showCalendar="showCalendar"
          @switchCalendar="switchCalendar"
          @closeCalendar="closeCalendar"
          @clearCalendar="editDateStart"
        />
      </li>
      <li class="task-body__item">
        <DeadLineComponent
          v-model="deadline"
          :value="deadline"
          :label="'Срок окончания'"
          :deadline="true"
          :isEdit="isEdit"
          :showCalendar="showDeadlineCalendar"
          @switchCalendar="switchDeadlineCalendar"
          @closeCalendar="closeCalendar"
          @clearCalendar="editDeadline"
        />
      </li>
      <li class="task-body__item">
        <h3 class="task-body__label">Необх. время</h3>
        <TimeInput
          v-model="timePlan"
          :value="timePlan"
          @input="editPlanTime"
          :readonly="!(isExecutor || isAuthor || isOwner)"
        />
      </li>
      <li class="task-body__item">
        <h3 class="task-body__label">Факт. время</h3>
        <TimeInput
          v-model="timeFact"
          :value="timeFact"
          @input="editFactTime"
          :readonly="!(isOwner || isExecutor)"
        />
      </li>
    </ul>
    <DeadLineCalendar
      v-model="dateStart"
      :value="dateStart"
      :amountDaysForMinDate="0"
      v-if="showCalendar"
      @closeCalendar="editDateStart"
    />
    <DeadLineCalendar
      v-model="deadline"
      :value="deadline"
      :amountDaysForMinDate="0"
      v-if="showDeadlineCalendar"
      @closeCalendar="editDeadline"
    />
  </div>
</template>

<script>
import moment from "moment";
import DeadLineComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineComponent";
import DeadLineCalendar from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineCalendar";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import TimeInput from "@/modules/UiKit/components/TimeInput/TimeInput";

export default {
  name: "TaskDataTimeParams",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    setStatus: {
      type: Object,
      default: () => {}
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DeadLineCalendar,
    DeadLineComponent,
    TimeInput
  },
  data() {
    return {
      deadline: new Date(),
      dateStart: new Date(),
      timePlan: 0,
      timeFact: 0,
      type: null,
      priority: null,
      status: null,
      isEditDeadline: false,
      showCalendar: false,
      showDeadlineCalendar: false
    };
  },
  mounted() {
    if (this.task) {
      this.deadline = this.setDeadlineDate;
      this.dateStart = this.setDateStart;
      this.timePlan = this.task.time_plan;
      this.timeFact = this.task.time_fact;
    }
  },
  methods: {
    setEditModeOfDeadline() {
      this.isEditDeadline = true;
    },
    editDeadlines() {
      this.isEditDeadline = false;
    },
    cancelEditDeadline() {
      this.isEditDeadline = false;
    },
    switchCalendar() {
      this.showDeadlineCalendar = false;
      this.showCalendar = !this.showCalendar;
    },
    switchDeadlineCalendar() {
      this.showCalendar = false;
      this.showDeadlineCalendar = !this.showDeadlineCalendar;
    },
    closeCalendar() {
      this.showCalendar = false;
      this.showDeadlineCalendar = false;
    },
    editDeadline(deadline) {
      this.deadline = deadline;
      if (new Date(deadline) !== new Date(this.task.date_to) || deadline === null) {
        this.$emit("handleEditDone", {
          date_to: deadline === null ? null : moment(deadline).format("YYYY-MM-DD")
        });
      }
      this.closeCalendar();
    },
    editDateStart(deadline) {
      if (new Date(deadline) !== new Date(this.task.date_from) || deadline === null) {
        this.dateStart = deadline === null ? null : moment(deadline).format("YYYY-MM-DD");
        this.deadline = this.task.date_to ? this.task.date_to : this.dateStart;
        this.$emit("handleEditDone", {
          date_from: this.dateStart,
          date_to: this.deadline
        });
        this.closeCalendar();
      }
    },
    editPlanTime(val) {
      if (val !== this.task.time_plan) {
        this.$emit("handleEditDone", {
          time_plan: val
        });
      }
    },
    editFactTime(val) {
      if (val !== this.task.time_fact) {
        this.$emit("handleEditDone", {
          time_fact: val
        });
      }
    }
  },
  computed: {
    isSubTask() {
      return TaskModule.currentTask.parent_id;
    },
    setDeadlineDate() {
      return this.task.date_to ? moment(this.task.date_to) : null;
    },
    setDateStart() {
      return this.task.date_from ? moment(this.task.date_from) : null;
    },
    setPlanTime() {
      return this.task.time_plan ? this.task.time_plan : "-";
    },
    setFactTime() {
      return this.task.time_fact ? this.task.time_fact : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.task-body {
  &__params {
    width: 100%;
    padding: 0 0 8px;

    @media (max-width: 1740px) {
      padding: 0 24px 0;
    }

    @media (max-width: $xxxl) {
      padding: 0 0 16px;
    }

    @media (max-width: $xl) {
      padding: 0 0 24px;
    }

    @media (max-width: $md) {
      padding: 0 16px 0;
    }

    @media (max-width: $sm) {
      padding: 0 0 32px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    /deep/ {
      .label {
        margin: 0;
      }

      .label label {
        margin: 0 8px 8px 0;
        text-transform: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $text-subdued;
      }

      .time-input__wrap {
        max-width: 130px;
        height: 30px;
        border-radius: 4px;
      }

      .time-input__input {
        max-width: 100px;
        width: max-content;
        max-height: 30px;
        min-height: 30px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #323232;
      }
    }
  }

  &__item {
    width: 25%;
    padding-left: 8px;

    @media (max-width: 1570px) {
      width: 50%;
      margin: 16px 0 0;
    }

    @media (max-width: $xl) {
      width: 25%;
      margin: 0;
    }

    @media (max-width: $sm) {
      width: 50%;
      margin: 16px 0 0;
    }
  }

  &__label {
    margin: 0 8px 8px 0;
    text-transform: none;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: $text-subdued;
  }

  &__fact {
    max-width: 110px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $system-grey-four;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: $text-default;
    border-radius: 32px;
  }
}

/deep/ {
  .deadline {
    display: flex;
    flex-direction: column;
  }

  .date-picker__value {
    width: 123px;
    border-radius: 4px;
  }

  .date-picker__calendar {
    padding: 24px 24px 0;

    @media (max-width: $md) {
      padding: 24px 16px 0;
    }

    @media (max-width: $sm) {
      padding: 24px 0 0;
    }
  }

  .date-picker__calendar .vc-arrows-container {
    width: 100%;
  }
}
</style>
