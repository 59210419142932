





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CheckboxInput extends Vue {
  @Prop({
    type: String,
    required: true
  })
  id!: string;
  @Prop({
    type: String,
    default: ""
  })
  label!: string;
  @Prop({
    type: String,
    default: "0"
  })
  paddingSize!: string;
  @Prop({
    type: Boolean,
    default: false
  })
  isLeftPosition!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  checked!: boolean;

  change() {
    this.$emit("change");
  }
}
