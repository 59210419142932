<template>
  <li class="project-list-item">
    <details>
      <summary
        class="project-list-item__title"
        :class="{
          'project-list-item__title_top': isTopLevel,
          'project-list-item__title_locked': isLocked
        }"
        @click="handleClick"
      >
        <i class="icon-down-secondary" :style="{ opacity: item.children ? '1' : '0' }"></i>
        <p>
          <i v-if="isLocked" class="icon-lock-secondary"></i>
          {{ item.name }}
        </p>
      </summary>
      <ul class="project-list-item__subprojects" v-if="item.children">
        <ProjectListItem v-for="child in item.children" :key="child.id" :item="child" />
      </ul>
    </details>
  </li>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ProjectListItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),

    handleClick(e) {
      const path = e.path || e.composedPath();
      if (!path.some((item) => item.className === "icon-down-secondary")) {
        e.preventDefault();
        if (this.$route.params.id === this.item.id || this.isLocked) return;
        this.$router.push({
          name: "Project",
          params: { id: this.item.id }
        });
      }
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1400) {
        this.TOGGLE_SHOW_SIDEBAR(false);
      }
    }
  },
  computed: {
    isTopLevel() {
      return this.item.parent_id === null ? true : false;
    },
    isLocked() {
      return this.item.in_project ? false : true;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.project-list-item {
  list-style: none;
  padding: 0;
  cursor: pointer;
  details > summary {
    list-style: none;
    outline: none;
    .icon-down-secondary {
      transform: rotate(-90deg);
      padding-top: 0;
      padding-right: 6px;
    }
  }
  details[open] > summary {
    .icon-down-secondary {
      transform: rotate(0);
      padding-top: 2px;
    }
  }
  .icon-down-secondary {
    font-size: 6px;
    color: $icon-subdued;
    align-self: start;
  }

  &__title {
    display: flex;
    padding: 12px 8px 12px 0;
    align-items: center;
    word-break: break-word;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $system-grey-eight;
    p {
      display: flex;
      align-items: center;
      margin-left: 8px;

      i {
        margin-right: 6px;
      }
    }
    &_top {
      font-weight: 500;
      color: $text-default;
    }
    &_locked {
      color: $icon-subdued;
    }
  }
  &__subprojects {
    margin: 0;
    padding-left: 12px;
  }
}
</style>
