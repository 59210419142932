import Vue from "vue";
import Vuex from "vuex";
import Cookies from "vue-cookie";
import axios from "axios";
import SectionModule from "@/store/sectionModule";
import DataUserModule from "@/store/dataUserModule";
import KnowledgeBaseModule from "@/store/knowledgeBaseModule";
import calendarModule from "@/store/calendarModule";
import MessengerModule from "@/modules/Messenger/store/MessengerModule";
import TaskManagerModule from "@/modules/TaskManager/store/TaskManagerModule";
import NewsModule from "@/modules/News/store/NewsModule";
import IspModule from "@/modules/Isp/store/IspModule";
import CalendarModule from "@/modules/Calendar/store/CalendarModule";
import UikitModules from "@/modules/UiKit/store/UikitModules";
import TablesModules from "@/modules/Tables/store/TablesModules";
import bugsAndIdeasModule from "@/store/bugsAndIdeasModule";
import DeviceModule from "@/store/DeviceModule";
import { domain, MicroServices } from "@/globalVariables";
import { isEmpty } from "@/helpers/helperFunction";
import httpClient from "@/api/client/httpClient";
import router from "@/router";
import UsersModule from "@/store/Users";
import Notification from "@/modules/Notification/store";
import MainLayoutSetting from "@/aopV2/layout/LayoutMain/store";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const NEW_RECORD = "newRec";
const RELOAD_STORE = "ReloadStore";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    metaProjectData: {},
    metaProjectFilter: {},
    metaProjectDataEdit: {},
    metaProjectMenu: [],
    relationsData: {},
    metaProjectMultiEdit: {},
    newRecord: {},
    menu: [],
    filterRec: {},
    pagination: {
      from: null,
      count: null,
      query: null,
      queryRelation: null,
      queryGlobal: null,
      queryThree: null,
      querySort: null,
      queryExact: null
    },
    auth: {
      authorisedStatus: Cookies.get("mandate"),
      mandate: {},
      endpoints: {}
    },
    breadcrumbs: [],
    viewBreadCrumbs: false,
    multiEditIds: null,
    settingTable: {
      dark: Cookies.get("dark") || false,
      bordered: Cookies.get("bordered") || false,
      borderless: Cookies.get("borderless") || false,
      fixed: Cookies.get("fixed") || false,
      footClone: Cookies.get("footClone") || false,
      hover: Cookies.get("hover") || false,
      outlined: Cookies.get("outlined") || false,
      small: Cookies.get("small") || false,
      stickyHeader: Cookies.get("stickyHeader") || false,
      theme: Cookies.get("themeName") || null
    },
    userId: null,
    isLoadUser: false,
    serviceWork: false,
    oldToPath: null,
    from: null,
    isShowContextMenu: false,
    showModalNewVersion: false,
    contextMenuItemList: [],
    scrollContainerContextMenu: null
  },
  mutations: {
    [NEW_RECORD](state, data) {
      if (data.type === "tree" && data.keyAdd === "tree") {
        this.$set(state.newRecord, `_rel_model`, data.treeData.model);
        this.$set(state.newRecord, `_rel_id`, data.treeData.id);
        this.$set(state.newRecord, `value`, data.value);
      } else if (data.type !== "tree" && (data.value || data.value === false || data.value === 0)) {
        this.$set(state.newRecord, data.keyAdd, data.value);
      } else if (state.newRecord[data.keyAdd] && !data.value) {
        this.$set(state.newRecord, data.keyAdd, null);
      }
    },
    [RELOAD_STORE](state) {
      state.newRecord = {};
      state.pagination.from = null;
      state.pagination.count = null;
      state.pagination.sortQuery = null;
      state.pagination.query = null;
      state.pagination.querySort = null;
      state.pagination.queryExact = null;
      state.pagination.rangeQuery = null;
      state.pagination.queryGlobal = null;
    },
    [LOGIN_SUCCESS](state) {
      state.auth.authorisedStatus = true;
    },
    [LOGOUT](state) {
      state.auth.authorisedStatus = false;
      router.push("/login");
    },
    setStatusServiceWork(state, payload) {
      state.serviceWork = payload;
    },
    changeUserId(state, payload) {
      state.userId = payload;
      state.isLoadUser = true;
    },
    SET_CONTEXT_MENU_ITEM_LIST(state, payload) {
      state.contextMenuItemList = payload;
    },
    TOGGLE_SHOW_CONTEXT_MENU(state, payload) {
      state.isShowContextMenu = payload;
    },
    SET_SCROLL_CONTAINER_CONTEXT_MENU(state, payload) {
      state.scrollContainerContextMenu = payload;
    },
    SHOW_MODAL_NEW_VERSION(state, payload) {
      state.showModalNewVersion = payload;
    }
  },
  actions: {
    login({ commit }, data) {
      commit(LOGIN_SUCCESS);
      return new Promise((resolve) => {
        this.state.mandate = data.data;
        resolve();
      });
    },
    logout({ commit, state }) {
      commit(LOGOUT);
      const token = localStorage.getItem("device_token");
      if (token) {
        httpClient({
          url: "/UserDevice/actionDelete",
          method: "DELETE",
          data: [
            {
              device_token: token,
              user_id: state.userId
            }
          ]
        }).then((resp) => {
          localStorage.removeItem("device_token");
          Cookies.delete("mandate");
          delete axios.defaults.headers["Authorization"];
        });
      } else {
        Cookies.delete("mandate");
        delete axios.defaults.headers["Authorization"];
      }
    },
    reloadStore({ commit }) {
      commit("ReloadStore");
    },
    /**
     * Получение метаДанных всего приложения для построения страниц
     * Метаданные получаются для каждого микросервиса в отдельности с указанием его префикса в храналище проекта
     * Одинаковая дата - но разные записи стора нужны для того, чтобы их можно было изменять без зависимостей
     */
    getMeta({ state, dispatch }) {
      state.auth.endpoints = {};
      state.auth.metaProjectMenu = [];
      this.microLen = MicroServices.length;
      for (let i in MicroServices) {
        axios.post(`${domain}/${MicroServices[i].prefix}/Metadata/getAll`).then((response) => {
          let prefix = MicroServices[i].prefix;
          state.relationsData[prefix] = {};
          if (isEmpty(state.auth.endpoints[prefix])) {
            state.auth.endpoints[prefix] = response.data.data.endpoints;
          }
        });
      }
    },
    handleContextMenuAction({ state, commit }, { itemList, scrollContainer }) {
      commit("SET_CONTEXT_MENU_ITEM_LIST", itemList);
      commit("TOGGLE_SHOW_CONTEXT_MENU", !state.isShowContextMenu);
      commit("SET_SCROLL_CONTAINER_CONTEXT_MENU", scrollContainer);
    },
    closeContextMenuAction({ commit }) {
      commit("TOGGLE_SHOW_CONTEXT_MENU", false);
      commit("SET_CONTEXT_MENU_ITEM_LIST", []);
    },
    newVersionAvailable({ commit }, isNewVersion) {
      commit("SHOW_MODAL_NEW_VERSION", isNewVersion);
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.auth.authorisedStatus;
    },
    isServiceWork: (state) => {
      return state.serviceWork;
    },
    getUserId: (state) => {
      return state.userId;
    },
    getEndpoints: (state) => (endpoint, action) => {
      const endpoints = state.auth.endpoints["monolit"][endpoint];
      return endpoints ? endpoints.includes(action) : false;
    }
  },
  modules: {
    SectionModule,
    DataUserModule,
    KnowledgeBaseModule,
    calendarModule,
    MessengerModule,
    TaskManagerModule,
    IspModule,
    UsersModule,
    Notification,
    MainLayoutSetting,
    NewsModule,
    CalendarModule,
    DeviceModule,
    TablesModules,
    bugsAndIdeasModule,
    UikitModules
  }
});
