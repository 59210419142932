<template>
  <section class="statistics">
    <TabsNavigation :tabs="setTabList" />
    <router-view />
  </section>
</template>

<script>
import TabsNavigation from "@/components/Widgets/TabsWidget/TabsNavigation";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Tables",
  mixins: [],
  props: [],
  components: { TabsNavigation },
  data() {
    return {
      tabsList: [
        {
          label: "Оценки ЧШ",
          path: "BlackSphereMarksTable",
          index: 0
        },
        {
          label: "Черный шар",
          path: "BlackSphereStatisticsTable",
          index: 1
        },
        {
          label: "Время",
          path: "UsersTimeTable",
          index: 2
        },
        {
          label: "Отсутствия",
          path: "UsersAbsenceTable",
          index: 3
        }
        //todo_dm: закомментировано по причине ненадобности страницы (компоненты не удалены)
        // {
        //   label: "Активность",
        //   path: "UsersActivityTable",
        //   index: 4
        // }
      ]
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(["getEndpoints"]),

    isAdminAbsence() {
      return this.getEndpoints("WorkingCalendar", "update");
    },
    isAdminActivity() {
      return this.getEndpoints("WorkingCalendar", "update");
    },

    setTabList() {
      let filterTabsList = this.tabsList;
      if (!this.isAdminActivity) {
        filterTabsList = filterTabsList
          .filter((item) => item.path !== "UsersActivityTable")
          .map((item, index) => {
            return {
              label: item.label,
              path: item.path,
              index: index
            };
          });
      }
      if (!this.isAdminAbsence) {
        filterTabsList = filterTabsList
          .filter((item) => item.path !== "UsersAbsenceTable")
          .map((item, index) => {
            return {
              label: item.label,
              path: item.path,
              index: index
            };
          });
      }
      return filterTabsList;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.statistics {
  height: 96%;
  width: calc(100% - 24px);
  margin: 24px 0 0;
  background: $surface-default;
  border: 1px solid $border-disabled;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 24px;

  @media (max-width: $md) {
    width: calc(100% - 32px);
    margin: 16px 16px 0;
  }

  @media (max-width: $sm) {
    width: 100%;
    margin: 16px 0 0;
  }

  @media (max-height: 450px) {
    height: 90%;
  }

  @media (max-height: 420px) {
    margin: 0;
    height: 100%;
  }

  &_other-size {
    width: calc(100% - 124px);

    @media (max-width: $md) {
      width: calc(100% - 32px);
      margin: 16px 16px 0;
    }

    @media (max-width: $sm) {
      width: 100%;
      margin: 16px 0 0;
    }

    @media (max-height: 450px) {
      height: 90%;
    }

    @media (max-height: 420px) {
      margin: 0;
      height: 100%;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;

    @media (max-width: $md) {
      margin: 24px;
    }

    @media (max-width: $sm) {
      margin: 24px 16px;
    }

    @media (max-height: 420px) {
      margin: 8px 16px;
    }

    /deep/ {
      .tabs__item {
        @media (max-height: 420px) {
          padding: 0 20px 8px;
        }
      }

      .tabs__link {
        @media (max-height: 420px) {
          font-size: 12px;
          padding: 0 20px 4px;
        }
      }
    }
  }
}
</style>
