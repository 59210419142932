<template>
  <div class="calendar-mobile" v-click-outside="hideCalendar">
    <div class="calendar-mobile__control-panel">
      <button class="calendar-mobile__button" v-if="isShowDoubleArrows" @click="handleDoubleLeft">
        <i class="icon-double-left"></i>
      </button>
      <button class="calendar-mobile__button" v-if="isShowArrows" @click="handleLeft">
        <i class="icon-left"></i>
      </button>
      <button class="calendar-mobile__button calendar-mobile__button_today" @click="handleToday">
        {{ setTodayButton }}
      </button>
      <button class="calendar-mobile__button" v-if="isShowArrows" @click="handleRight">
        <i class="icon-right"></i>
      </button>
      <button class="calendar-mobile__button" v-if="isShowDoubleArrows" @click="handleDoubleRight">
        <i class="icon-double-right"></i>
      </button>
      <div class="calendar-mobile__widget">
        <button
          class="calendar-mobile__button calendar-mobile__button_month"
          @click="switchCalendar"
        >
          {{ setCurrentMonth }}
          <i class="icon-down" :style="showCalendar ? 'transform: rotate(180deg)' : ''"></i>
        </button>
        <div
          class="calendar-mobile__container"
          v-show="showCalendar"
          @click.self="showCalendar = false"
        >
          <v-calendar
            title-position="center"
            class="calendar__custom"
            :masks="masks"
            ref="calendar"
            id="calendar"
            popover.visibility="false"
            :attributes="attributes"
            @update:to-page="getTimeInfo"
            title-transition
            is-expanded
          >
            <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
              <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
            </div>
            <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
            <div slot="day-content" slot-scope="{ day, attributes }" @click="clickSelectDate(day)">
              <div class="vc-h-full">
                <span
                  :class="[
                    'vc-day-content',
                    attributes.length ? (day.id === getToday ? 'today' : '') : '',
                    setClassDependingAttributes(attributes),
                    setClassDependingDay(attributes, day, getToday)
                  ]"
                  >{{ day.day }}</span
                >
              </div>
            </div>
          </v-calendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import axios from "axios";
import { format, parseISO } from "date-fns";
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default {
  name: "CalendarPickerMobile",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    isFullName: {
      type: Boolean,
      default: true
    },
    isShowArrows: {
      type: Boolean,
      default: true
    },
    isShowDoubleArrows: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data() {
    return {
      masks: {
        weekdays: "WWW"
      },
      value: moment().format("YYYY-MM-DD"),
      attributes: [],
      showCalendar: false,
      currentMonth: null,
      monthNumber: new Date().getMonth(),
      month: [
        {
          full: "Январь",
          val: "Янв"
        },
        {
          full: "Февраль",
          val: "Фев"
        },
        {
          full: "Март",
          val: "Март"
        },
        {
          full: "Апрель",
          val: "Апр"
        },
        {
          full: "Май",
          val: "Май"
        },
        {
          full: "Июнь",
          val: "Июнь"
        },
        {
          full: "Июль",
          val: "Июль"
        },
        {
          full: "Август",
          val: "Авг"
        },
        {
          full: "Сентябрь",
          val: "Сен"
        },
        {
          full: "Октябрь",
          val: "Окт"
        },
        {
          full: "Ноябрь",
          val: "Ноя"
        },
        {
          full: "Декабрь",
          val: "Дек"
        }
      ]
    };
  },
  mounted() {
    this.$root.$on("date:set_calendar_value", (val) => {
      this.value = val;
      this.monthNumber = new Date(val).getMonth();
      const calendar = this.$refs.calendar;
      calendar.move(this.value);
    });
  },
  beforeDestroy() {
    this.$root.$off("date:set_calendar_value");
  },
  methods: {
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    handleDoubleLeft() {
      this.$root.$emit(`date:doubleLeft_${this.unique}`);
    },
    handleLeft() {
      this.$root.$emit(`date:left_${this.unique}`);
    },
    handleToday() {
      this.$root.$emit(`date:today_${this.unique}`);
    },
    handleRight() {
      this.$root.$emit(`date:right_${this.unique}`);
    },
    handleDoubleRight() {
      this.$root.$emit(`date:doubleRight_${this.unique}`);
    },
    /**
     * Запоминание выбранного дня и подставновка текущего рабочего времени для этого дня
     */
    clickSelectDate(day) {
      this.value = day.id;
      this.monthNumber = format(parseISO(day.id), "M") - 1;
      this.$root.$emit(`date:change_${this.unique}`, this.value);
    },
    /**
     * Получение данных по открытому в календаре месяцу
     */
    getTimeInfo(data) {
      this.$emit("getTimeInfo", data);
      // if (data.year) {
      //   this.currentMonth = `${data.year}-${data.month < 10 ? "0" + data.month : data.month}`;
      // }
      // httpClient({
      //   url: `/WorkingCalendar`,
      //   method: "GET",
      //   params: {
      //     _count: 31,
      //     _search: `{"date":"${this.currentMonth}"}`,
      //     _order_by: "date",
      //     _order: { date: "asc" }
      //   }
      // })
      //   .then((response) => {
      //     this.attributes = [];
      //     this.attributes = response.data.data.items.map((item) => ({
      //       customData: {
      //         hours: item.count_hours,
      //         id: item.id
      //       },
      //       dates: item.date
      //     }));
      //   })
      //   .catch((error) => {
      //     this.errorAlert(error);
      //   });
    },
    hideCalendar() {
      this.showCalendar = false;
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    setClassDependingAttributes(attributes) {
      if (attributes.length) {
        if (attributes[0].customData.hours === String(0)) {
          return "output";
        }
        if (
          attributes[0].customData.hours < String(8) &&
          attributes[0].customData.hours > String(0)
        ) {
          return "shortcut";
        }
      }
    },
    setClassDependingDay(attributes, day, getToday) {
      if (attributes.length) {
        if (this.value === day.id) {
          return "current";
        }
      }
    }
  },
  computed: {
    getToday() {
      return moment().format("YYYY-MM-DD");
    },
    setTodayButton() {
      return this.getToday ? "Сегодня" : "";
    },
    setCurrentMonth() {
      if (this.isFullName) {
        return this.month[this.monthNumber].full + ", " + new Date(this.value).getFullYear();
      } else {
        return this.month[this.monthNumber].val + " " + new Date(this.value).getFullYear();
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.calendar-mobile {
  font-family: SF, sans-serif !important;
  width: 400px;
  display: flex;
  justify-content: center;

  &__control-panel {
    display: flex;
    width: 100%;

    button {
      width: 28px;
      height: 28px;
      border: none;
      background: $action-secondary;
      border-radius: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $icon-subdued;
      outline: none;

      &:hover {
        background: $action-secondary-hovered;
      }

      &:active {
        background: $action-secondary-pressed;
      }
    }

    .calendar-mobile__button {
      &_today,
      &_month {
        width: max-content;
        border-radius: 4px;
        padding: 4px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      &_month {
        margin-left: auto;

        i {
          margin-left: 8px;
        }
      }
    }
  }

  &__widget {
    position: relative;
    margin-left: auto;
  }

  &__container {
    position: absolute;
    top: 28px;
    left: 0;

    @media (max-width: $sm) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: $background-modal;
      border-radius: 0;
      z-index: 10000000;
    }
  }

  & /deep/ .vc-container {
    margin: 0;
    border: none;
    background: $surface-default;
    z-index: 100;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 12px 16px;

    @media (max-width: $sm) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 280px;
      border-radius: 14px 14px 0 0;
      padding: 12px 16px;
    }

    .vc-header {
      padding: 8px 0 0;
      margin: 0 0 8px;
    }

    .vc-title {
      font-family: SF, sans-serif !important;
      font-weight: bold;
      font-size: 16px;
      line-height: 29px;
      cursor: default;
      color: $text-body-dark;
    }

    .vc-title:hover {
      opacity: 1;
    }

    .vc-arrows-container {
      padding: 8px 0 0 0;
      display: flex;
      align-items: center;
    }

    .vc-arrow {
      padding: 0;
      margin: 0;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-disabled;
      border: none;
      border-radius: 0;

      svg {
        height: 20px;
      }
    }

    .vc-arrow:hover {
      background: transparent;
      color: $text-subdued;
    }

    .vc-arrow:active {
      background: transparent;
      color: $action-primary-pressed;
    }

    .vc-svg-icon {
      cursor: pointer;
      stroke: #a0aec0;
    }

    .vc-weeks {
      padding: 0;
    }

    .vc-weekday:first-letter {
      text-transform: uppercase;
    }

    .vc-weekday,
    .vc-day {
      font-family: SF, sans-serif;
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $text-subdued;
      width: 30px;
      height: 30px;
      align-self: center;
      justify-self: center;
      &.in-prev-month.is-not-in-month * {
        color: $text-disabled;
        opacity: 1;
      }
      &.in-next-month.is-not-in-month * {
        color: $text-disabled;
        opacity: 0;
      }
    }

    .vc-weekday {
      height: 30px;
    }

    .vc-day-content {
      width: 30px;
      height: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $text-default;
    }

    .vc-day:hover {
      .vc-h-full {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .vc-day-content:before {
          content: "";
          position: absolute;
          z-index: -1;
          background-color: $action-primary;
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
      }
      &.in-prev-month.is-not-in-month * {
        .vc-h-full {
          .vc-day-content:before {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: transparent;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            transition: 0s;
          }
        }
      }

      span {
        background: transparent;
        color: $surface-default;
      }
    }

    .output {
      color: $text-disabled;
    }

    .shortcut {
      position: relative;

      &::after {
        position: absolute;
        width: 4px;
        height: 4px;
        content: "";
        background-color: #a7a9c0;
        bottom: 4px;
        right: 13px;
        border-radius: 50%;
        z-index: 10;
      }
    }

    .today {
      position: relative;
      color: $text-default;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $action-secondary-hovered;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    .current {
      position: relative;
      color: $surface-default;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $action-primary;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
  }

  .vc-day-content:hover {
    background-color: transparent;
  }
}
</style>
