import { domainV2 } from "@/globalVariables";
import axios, { AxiosRequestConfig } from "axios";
import { KJUR } from "jsrsasign";
import Auth from "@/store/Auth";
import { EventBus } from "@/main";

const config: AxiosRequestConfig = {
  baseURL: domainV2,
  headers: {
    skipAuth: false
  }
};

type ErrorFunction = (data?: Array<string>) => void;

interface IError {
  data: Array<string>;
  code: number;
}

let refreshTokenRequest: null | Promise<any> = null;
let accessTokenExpires = 0;

/** В зависимости от кода ошибки выполняем необходимые действия */
const codeStatus: Record<string, ErrorFunction> = {
  401: async (): Promise<any> => {
    EventBus.$emit("showNotification", {
      type: "error",
      timeout: 5000,
      label: `Нет доступа.`
    });
    await Auth.logout();
  },
  409: (data: Array<string> = []): void => {
    data.forEach((item) => {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `${item}`
      });
    });
  },
  422: (data: object = {}): void => {
    let errorMessage = "";
    for (const key in data) {
      data[key].forEach((item) => {
        errorMessage += `${item}\n`;
      });
    }
    EventBus.$emit("showNotification", {
      type: "error",
      timeout: 5000,
      label: `${errorMessage}`
    });
  },
  default: (data: object = {}): void => {
    let errorMessage = "";
    if (Array.isArray(data)) {
      data.forEach((errorMessage) => {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `${errorMessage}`
        });
      });
    } else {
      for (const key in data) {
        data[key].forEach((item) => {
          errorMessage += `${item}\n`;
        });
      }
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `${errorMessage}`
      });
    }
  }
};

/** Функция обработки ошибок от сервера*/
const errorNotification = (error: IError) => {
  const errorFunc = codeStatus[error.code] || codeStatus.default;
  errorFunc(error.data);
};

const httpClientV2 = axios.create(config);
/**
 * Проверяем срок жизни access token,
 * если токен не валидный, делаем refresh
 * и получаем новую пару токенов
 */
const requestValidAccessToken = async () => {
  let authToken: string | null = localStorage.getItem("access_token");
  if (authToken) {
    accessTokenExpires = KJUR.jws.JWS.parse(authToken).payloadObj.exp;
  }
  const now = Math.floor(Date.now() * 0.001);
  const isValid = accessTokenExpires > now;
  if (!isValid) {
    if (refreshTokenRequest === null) {
      refreshTokenRequest = Auth.refresh();
    }
    try {
      authToken = await refreshTokenRequest;
      refreshTokenRequest = null;
    } catch (e) {
      Auth.logout();
    }
  }
  return authToken;
};
/**
 * Interceptor для перехвата всех запросов к серверу,
 * проверяем нужна ли авторизация для запроса,
 * если нет возвращаем config,
 * если нужна проверяем токен на валидность,
 * и подставляем access token в header
 */
const authInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (config.headers.skipAuth) {
    return config;
  }
  const authToken = await requestValidAccessToken();
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  return config;
};

httpClientV2.interceptors.request.use(authInterceptor);

/** Interceptor для перехвата всех ответов от сервера*/
httpClientV2.interceptors.response.use(
  (response) => {
    /** Обрабатываем успешный результат */
    return response.data;
  },

  (error) => {
    /** Обрабатываем результат, завершенный ошибкой,
     * и вызываем функцию обработки ошибок  */
    if (error.response) {
      const errorData: IError = {
        data: error.response.data.data,
        code: error.response.data.code
      };
      errorNotification(errorData);
    }
    return Promise.reject(error);
  }
);

export default httpClientV2;
