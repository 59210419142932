<template>
  <div class="books-section-bg">
    <div class="books-header" v-show="!getCreateMode && !loader">
      <div class="books-breadcrumbs">
        <Widget type="bread-crumbs" :crumbs="crumbs" />
      </div>
      <div v-if="isAdmin" class="books-add" @click="$store.commit('changeCreateMode', true)">
        <i class="icon-plus"></i>
        <div>Добавить книгу</div>
      </div>
      <div v-if="!isAdmin"></div>
      <div class="books-sort">
        <Widget type="default-filter" :filterValue="filterValue" :items="filterSort" />
      </div>
    </div>
    <div class="books-content" v-show="!getCreateMode && !loader" ref="mainContent">
      <div ref="emptyTopBlock" class="empty-block"></div>
      <BookComponent
        v-for="book in booksList"
        :key="book.id"
        :item="book"
        @updateInfoBook="getBooksInfo"
      />
    </div>
    <div class="empty-books" v-show="!booksList.length && !getCreateMode && !loader">
      В разделе пока нет Книг
    </div>
    <div class="load-bg" v-if="loader">
      <Widget type="loader" />
    </div>
    <BookAddComponent v-if="getCreateMode" @updateBooksList="getBooksInfo" />
    <div class="button-scroll-top" @click="goToTop" v-show="scrollTopButton">
      <i class="icon-up"></i>
    </div>
  </div>
</template>

<script>
import { Model } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";

import BookComponent from "@/components/KnowledgeBaseComponents/Book/BookComponent";
const BookAddComponent = () => import("@/components/KnowledgeBaseComponents/Book/BookAddComponent");
import Widget from "@/components/Widgets/Widget";
import { mapGetters } from "vuex";

export default {
  name: "BooksSection",
  mixins: [],
  props: [],
  components: { Widget, BookAddComponent, BookComponent },
  data() {
    return {
      createMode: true,
      loader: true,
      scrollTopButton: false,
      booksList: [],
      filterValue: {
        name: "Cначала новые",
        value: { datetime_of_creation: "desc" }
      },
      crumbs: [
        {
          title: "Главная",
          linkName: "Section"
        },
        {
          title: "Книги",
          linkName: "Books"
        }
      ],
      filterSort: [
        {
          name: "Cначала новые",
          value: { datetime_of_creation: "desc" }
        },
        {
          name: "Cначала старые",
          value: { datetime_of_creation: "asc" }
        },
        {
          name: "По популярности",
          value: { count_likes: "desc" }
        }
      ]
    };
  },
  beforeDestroy() {
    this.$refs.mainContent.removeEventListener("scroll", this.handleScroll, false);
  },
  async mounted() {
    await this.getBooksInfo();
    this.setEndpointsBook();
    this.$root.$on("changeFilter", (item) => {
      this.filterValue = item;
    });
    this.$refs.mainContent.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    async getBooksInfo() {
      const ModelChannel = new Model({
        model: "Book",
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 100,
            _order: this.filterValue.value,
            _with: JSON.stringify(["current_user_like"]),
            categories:
              this.$route.params.category_name !== "Все"
                ? [{ name: this.$route.params.category_name }]
                : undefined
          }
        }
      });
      await ModelChannel.init();
      this.booksList = ModelChannel.allItems;
      this.$root.$emit("getMetaData");
    },
    handleScroll() {
      if (this.$refs.mainContent.scrollTop > 1000) {
        this.scrollTopButton = true;
      } else {
        this.scrollTopButton = false;
      }
    },
    setEndpointsBook() {
      this.loader = false;
    },
    goToTop() {
      this.$refs.emptyTopBlock.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("knowledge_base_admin")
        : false;
    },
    getCreateMode() {
      return this.$store.getters.getCreateMode;
    }
  },
  watch: {
    filterValue() {
      this.getBooksInfo();
    },
    $route() {
      this.getBooksInfo();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.books-section-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(90vh - 10px);
  margin-left: 30px;
  @media screen and (max-height: $lg) {
    max-height: 100%;
  }

  .books-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .books-breadcrumbs {
      @media screen and (max-width: $md) {
        order: 1;
      }
    }

    .books-sort {
      order: 2;

      .default-filter {
        @media screen and (max-width: $xs) {
          width: 50px;
        }

        /deep/ .default-filter__content span {
          @media screen and (max-width: $xs) {
            display: none !important;
          }
        }

        /deep/ .default-filter__content .icon-filter {
          @media screen and (max-width: $xs) {
            font-size: 16px;
          }
        }
      }
    }

    .books-add {
      user-select: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
      color: $color-interactive;
      transition: all 0.2s ease;

      @media screen and (max-width: $md) {
        width: 100%;
        justify-content: center;
        order: 3;
        margin-top: 20px;
      }

      .icon-plus {
        margin-right: 10px;
        font-size: 20px;
      }

      @media (hover: hover) {
        &:hover {
          color: $color-button-hover;
        }
      }

      &:active {
        color: $color-button-click;
      }
    }
  }

  .books-content {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;

    @media screen and (max-width: $lg) {
      justify-content: center;
    }

    /deep/ {
      .book-bg {
        margin-bottom: 24px;
        margin-right: 15px;
      }
    }
  }
  .empty-books {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $color-other-2;
    user-select: none;
  }
  .load-bg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-scroll-top {
    position: fixed;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddddff;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
  }

  @media (hover: hover) {
    .button-scroll-top:hover {
      box-shadow: 0px 4px 5px rgba(221, 221, 255, 0.62);
    }
  }

  .icon-up {
    display: block;
    color: $color-interactive;
    font-size: 20px;
  }
}
</style>
