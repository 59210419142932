<template>
  <table class="marks">
    <tr class="marks__header" v-if="!loader">
      <th class="marks__cell" v-for="(item, index) in headList" :key="index">{{ item }}</th>
    </tr>
    <tr class="marks__header marks__header_skeleton" v-if="loader">
      <Skeleton :borderRadius="'16px'" />
    </tr>
    <tbody class="marks__container marks__container_skeleton" v-if="!loader">
      <BlackSphereMarksItem
        v-for="item in marks"
        :key="item.black_sphere_id"
        :task="task"
        :item="item"
      />
    </tbody>
    <tbody class="marks__container marks__container_skeleton" v-if="loader">
      <Skeleton :borderRadius="'8px'" />
      <Skeleton :borderRadius="'8px'" />
    </tbody>
  </table>
</template>

<script>
import BlackSphereMarksItem from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/BlackSphereMarks/BlackSphereMarksItem";
import TaskBlackSphereModule from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";

export default {
  name: "BlackSphereMarks",
  mixins: [],
  props: {
    marks: {
      type: Array,
      default: () => []
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  components: { Skeleton, BlackSphereMarksItem },
  data() {
    return {
      headList: ["Поставил", "Получил", "Оценка", "Дата", "Комментарий"]
    };
  },
  mounted() {},
  methods: {},
  computed: {
    loader() {
      return TaskBlackSphereModule.loader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.marks {
  display: block;
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 24px;

  tbody {
    width: 100%;
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    background: #f2f7fe;
    border-radius: 8px;
    min-height: 40px;

    @media (max-width: $sm) {
      display: none;
    }

    .marks__cell {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #343748;
      text-align: left;
    }

    &_skeleton {
      padding: 0;
    }

    .skeleton {
      min-height: 40px;
    }
  }

  &__container {
    &_skeleton {
      margin: 16px 0 0;
    }

    .skeleton {
      min-height: 30px;
      margin: 0 0 16px;
    }
  }

  &__cell {
    width: 30%;

    &:last-child {
      width: 100%;
    }
  }
}
</style>
