














































import { Component, Vue } from "vue-property-decorator";
import TimeTracker from "@/modules/TimeTracker/store";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import { IMember, ITimeProject } from "@/modules/TimeTracker/interface/interface";
import { EventBus } from "@/main";
import UserItem from "@/modules/TimeTracker/component/Member/UserItem";

@Component({
  components: { UserItem, Button, Select, Label }
})
export default class ChangeMemberModal extends Vue {
  currentUser: Array<IMember> = [];
  currentProject: Array<ITimeProject> = [];

  created() {
    document.addEventListener("keydown", this.onEscape);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscape);
  }

  onEscape(e) {
    if (e.keyCode === 27) {
      this.handleClose();
    }
  }

  handleAdd() {
    if (!this.currentUser.length) {
      EventBus.$emit("showNotification", {
        timeout: 5000,
        type: "error",
        label: "Пользователей, которых хотите добавить!"
      });
      return false;
    }
    TimeTracker.memberAddToProject({
      user_id: this.currentUser.map((item) => +item.id),
      project_id: +this.project!.id
    }).then((resp) => {
      if (resp) this.currentUser = [];
    });
  }

  toggleType() {
    TimeTracker.actionProject({ type: "add", project: this.project, isShow: true });
  }

  handleDelete(user_id) {
    TimeTracker.memberDeleteToProject({
      user_id: user_id,
      project_id: +this.project!.id
    });
  }

  handleCancel() {
    TimeTracker.actionProject({ type: "delete", project: this.project, isShow: true });
  }

  handleClose() {
    this.$emit("closeModal");
  }

  get users() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["name", "full_name", "surname", "avatar", "is_active"])
      .filter((item) => {
        return item.is_active && this.projectMembers.every((user_id) => user_id !== item.id);
      });
  }

  get type() {
    return TimeTracker.modalType;
  }

  get isDelete() {
    return this.type === "delete";
  }

  get project() {
    return TimeTracker.editProject;
  }

  get projectMembers() {
    return TimeTracker.projectMembers;
  }
}
