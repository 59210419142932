














import { Component, Vue } from "vue-property-decorator";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";

@Component({
  components: { ButtonIcon, MainButtonNew }
})
export default class CreateEventHeader extends Vue {
  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }
  get isMobileWindowSize() {
    return this.getWidthWindow < 1024;
  }

  get isCreateLoader() {
    return CalendarModule2.isCreateLoader;
  }

  async saveEvent() {
    CalendarModule2.toggleCreateLoader(true);
    await CalendarModule2.changeEvent(this.createData);
    CalendarModule2.toggleCreateLoader(false);
  }

  get createData() {
    return CalendarModule2.createData;
  }

  get buttonTitle() {
    return this.createData.id ? "Сохранить" : "Создать";
  }

  closeCreate() {
    this.$emit("hideModal");
    if (this.setOldPath.name && this.setOldPath.name !== "Login") {
      this.$router.back();
      if (!window.history.state) {
        this.$router.push({ name: "Calendar" });
      }
    } else {
      this.$router.push({ name: "Calendar" });
    }
    ModalModule.toggleConfirmation(false);
    CalendarModule2.toggleCreateModal(false);
    CalendarModule2.setWorkingEvents([]);
  }

  get setOldPath() {
    return this.$store.state.from;
  }

  get isEqual() {
    return CalendarModule2.eventIsEqual;
  }

  cancelCreate() {
    if (this.isEqual) {
      this.closeCreate();
    } else {
      ModalModule.toggleConfirmation(true);
      ModalModule.setConfirmSetting({
        title: "Закрыть окно?",
        text: "Все веденные данные будут сброшены. Закрыть?",
        handleCancel: () => {
          ModalModule.toggleConfirmation(false);
          CalendarModule2.toggleCreateLoader(false);
        },
        cancelButton: "Отменить",
        confirmButton: "Закрыть",
        handleConfirm: () => {
          this.closeCreate();
        }
      });
    }
  }
}
