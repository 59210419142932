















import { Component, Mixins } from "vue-property-decorator";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import moment from "moment";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import FSEventMixin from "@/aopV2/modules/Calendar/helper/FSevent.mixin";

@Component({
  components: { ActionList, MainButtonNew }
})
export default class CalendarMobileButton extends Mixins(FSEventMixin) {
  isShowList: boolean = false;

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  closeList() {
    this.isShowList = false;
  }

  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get isMobileWindowSize() {
    return this.getWidthWindow < 1024;
  }

  createEvent() {
    if (!this.isShowCreateEvent) {
      const day = dateFormat(new Date());
      const start_time = moment(new Date()).add(1, "hour").format("HH:mm");
      const end_time = moment(new Date()).add(2, "hour").format("HH:mm");
      CalendarModule2.toggleCreateModal(true);
      CalendarModule2.setEditEvent(undefined);
      CalendarModule2.setCreateData({
        ...this.createData,
        selectDate: day,
        timeStart: start_time,
        timeEnd: end_time,
        selectResponsible: [{ id: this.userId }],
        selectGroups: this.selectedGroups,
        selectMembers: this.users ? this.users : []
      });
      this.$emit("show");
      if (this.isMobileWindowSize) {
        this.handleFullScreenEvent();
      }
    }
  }

  get users() {
    return CalendarModule2.selectedUsers;
  }

  get selectedGroups() {
    return CalendarModule2.saveGroups;
  }

  get userId() {
    return this.$store.getters.getUserId;
  }

  get createData() {
    return CalendarModule2.createDataDef;
  }

  get actionList() {
    let actions = [
      {
        name: "Мероприятие",
        id: 0
      },
      {
        name: "Группу",
        id: 1
      }
    ];
    if (this.calendarAdmin) {
      actions.push({
        name: "Кабинет",
        id: 2
      });
    }
    return actions;
  }

  get userInfo() {
    return this.$store.getters.getDataInfoUser;
  }

  get calendarAdmin() {
    return !!this.userInfo.roles.includes("admin");
  }

  createGroup() {
    CalendarModule2.toggleShowGroupModal({ show: true, mode: "create" });
  }

  createLocation() {
    CalendarModule2.toggleShowLocationModal({ show: true, mode: "create" });
  }

  handleItem(type) {
    if (type.id === 0) {
      this.createEvent();
    }
    if (type.id === 1) {
      this.createGroup();
    }
    if (type.id === 2) {
      this.createLocation();
    }
    this.closeList();
  }

  get isShowCreateEvent() {
    return CalendarModule2.isShowCreateEvent;
  }

  get date() {
    return CalendarModule2.date;
  }

  get type() {
    return CalendarModule2.type;
  }

  get dateFormat() {
    return `${moment(this.date).format("MMMM")} ${moment(this.date).format("YYYY")}`;
  }
}
