<template>
  <div class="container" @click="closeMenu">
    <ul class="menu" v-click-outside="closeMenu" ref="optionList" v-if="showModal">
      <OptionItem v-for="item in optionList" :key="item" :item="item" />
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import setListPosition from "@/helpers/OptionListPosition";
import OptionItem from "@/modules/Messenger/components/OptionModal/OptionItem";

export default {
  name: "OptionModal",
  mixins: [],
  props: {},
  components: { OptionItem },
  data() {
    return {
      isFirst: true
    };
  },
  mounted() {
    setListPosition(this.container, this.$refs.optionList, this.modalX, this.modalY);
  },
  methods: {
    ...mapActions("MessengerModule", ["switchModal"]),
    closeMenu() {
      if (this.isLeft) {
        if (!this.isFirst) {
          this.switchModal({ show: false, message: null, scrollContainer: this.scrollContainer });
        }
        this.isFirst = !this.isFirst;
      } else {
        this.switchModal({ show: false, message: null, scrollContainer: this.scrollContainer });
      }
    }
  },
  beforeDestroy() {
    this.scrollContainer.classList.remove("stop-scrolling");
  },
  computed: {
    ...mapState("MessengerModule", [
      "modalX",
      "modalY",
      "selectedMessage",
      "optionList",
      "container",
      "showModal",
      "scrollContainer",
      "isLeft"
    ]),
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  @media screen and (max-width: $xs), screen and (max-height: 500px) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(52, 55, 72, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.menu {
  width: fit-content;
  position: absolute;
  background-color: #fff;
  color: #000;
  z-index: 100;
  margin: 0;
  list-style: none;
  padding: 8px;
  transition: transform 0.2s ease;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 9px;
  transform: scale(0);
  @media screen and (max-width: $xs), screen and (max-height: 500px) {
    width: 100%;
    max-height: 77vh;
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: auto;
    transform: scale(1);
  }
  &.open {
    transform: scale(1);
  }
}
</style>
