<template>
  <section class="users table-container">
    <UsersWidgets
      @updateAbsence="initializeTable"
      :isAdmin="isAdminUsers"
      :filterUser="filterUser"
      :filterRole="filterRole"
      :filterDepartment="filterDepartment"
      :filterLevel="filterLevel"
      :filterActive="filterActive"
      :isAdminReportUsers="isAdminReportUsers"
    />
    <UserWidgetsModal
      @updateAbsence="initializeTable"
      :isAdmin="isAdminUsers"
      :filterUser="filterUser"
      :filterRole="filterRole"
      :filterDepartment="filterDepartment"
      :filterLevel="filterLevel"
      :filterActive="filterActive"
      :isAdminReport="isAdminReportUsers"
    />
    <ModalCreateUser v-if="showModalCreateUser" @updateTable="initializeTable" />
    <div class="users__container">
      <Table
        :tableHead="tableHead"
        :tableData="tableData"
        v-if="!loader"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <UsersCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
  </section>
</template>

<script>
import UsersWidgets from "@/modules/Tables/components/TablesCompany/Users/UsersWidgets";
import UsersCellContent from "@/modules/Tables/components/TablesCompany/Users/UsersCellContent";
import Table from "@/modules/Tables/components/Table/Table";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UserWidgetsModal from "@/modules/Tables/components/TablesCompany/Users/UsersModal/UserWidgetsModal";
import ModalCreateUser from "@/modules/Tables/components/TablesCompany/Users/UsersModal/ModalCreateUser";

export default {
  name: "UsersMain",
  mixins: [],
  props: [],
  components: { ModalCreateUser, UserWidgetsModal, UsersWidgets, UsersCellContent, Table },
  data() {
    return {
      localStorage: null,
      loader: false,
      loaderTable: false,
      fromPag: 1,
      totalCount: 0,
      usersData: [],
      oldUserData: [],
      filterUser: [],
      filterUserRequest: [],
      filterDepartment: [],
      filterRole: [],
      filterLevel: [],
      filterActive: false,
      firstScrollDown: false,
      tableHead: [
        { name: "Сотрудник", sort: true, valueSort: "full_name", key: "full_name" },
        { name: "Грейд", sort: false, key: "grade" },
        { name: "Отдел", sort: false, key: "department" },
        {
          name: "Стаж в компании",
          sort: true,
          valueSort: "experience",
          key: "work_experience"
        },
        { name: "Email", sort: false, key: "email" },
        { name: "Телефон", sort: false, key: "phone_number" }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off("changeSelectValue:FilterUserListInUsersTable");
    this.$root.$off("changeSelectValue:FilterDepartmentListInUsersTable");
    this.$root.$off("changeSelectValue:FilterRoleListInUsersTable");
    this.$root.$off("changeSelectValue:FilterLevelListInUsersTable");
    this.$root.$off("switch:change_FilterActiveListInUsersTable");
    this.setCookieValue();
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    this.SET_IS_FILTER_ACTIVE_USERS(false);
    this.initializeTable();
    this.setFilterTable();
  },
  methods: {
    ...mapActions("TablesModules", ["getTableData", "downloadReports"]),
    ...mapMutations("TablesModules", [
      "CLEAR_TABLE_DATA",
      "RESET_PAGINATION",
      "SET_TABLE_PARAMS",
      "SET_IS_FILTER_ACTIVE_USERS"
    ]),
    getTableDataWithPagination() {
      if (this.tableData.length) {
        this.getTableData();
      }
    },
    initializeTable() {
      this.CLEAR_TABLE_DATA();
      this.RESET_PAGINATION();
      this.SET_TABLE_PARAMS({
        route: "User/getItems",
        params: {
          _count: 20,
          _order: JSON.stringify({
            [this.valueSortUser.value]: this.valueSortUser.isAsc ? "asc" : "desc"
          }),
          id: this.filterUser.length ? JSON.stringify(this.setFilterUser) : undefined,
          departments: this.filterDepartment.length
            ? JSON.stringify(this.setFilterDepartment)
            : undefined,
          roles: this.filterRole.length ? JSON.stringify(this.setFilterRole) : undefined,
          levels: this.filterLevel.length ? JSON.stringify(this.setFilterLevel) : undefined,
          is_active: this.filterActive ? JSON.stringify([0]) : JSON.stringify([1]),
          _with: JSON.stringify(["Departments", "Roles", "Levels"])
        }
      });
      this.getTableData();
    },
    setFilterTable() {
      this.$root.$on("changeSelectValue:FilterUserListInUsersTable", (val) => {
        this.filterUser = val;
        this.initializeTable();
      });
      this.$root.$on("changeSelectValue:FilterDepartmentListInUsersTable", (val) => {
        this.filterDepartment = val;
        this.initializeTable();
      });
      this.$root.$on("changeSelectValue:FilterRoleListInUsersTable", (val) => {
        this.filterRole = val;
        this.initializeTable();
      });
      this.$root.$on("changeSelectValue:FilterLevelListInUsersTable", (val) => {
        this.filterLevel = val;
        this.initializeTable();
      });
      this.$root.$on("switch:change_FilterActiveListInUsersTable", (val) => {
        this.filterActive = val;
        // this.SET_IS_FILTER_ACTIVE_USERS(val);
        this.initializeTable();
      });
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("user-table-setting"));
      this.filterUser = setting.users;
      this.filterDepartment = setting.departments;
      this.filterRole = setting.roles;
      this.filterLevel = setting.levels;
      this.filterActive = setting.active;
    },
    setCookieValue() {
      const option = {
        users: this.filterUser,
        departments: this.filterDepartment,
        roles: this.filterRole,
        levels: this.filterLevel,
        active: this.filterActive
      };
      this.localStorage.setItem("user-table-setting", JSON.stringify(option));
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "tableData",
      "tableParams",
      "totalCount",
      "valueSortUser",
      "showModalCreateUser"
    ]),
    ...mapGetters(["getEndpoints"]),
    isAdminUsers() {
      return this.getEndpoints("User", "update");
    },
    isAdminReportUsers() {
      return this.getEndpoints("User", "report");
    },
    setFilterUser() {
      return this.filterUser.map((item) => item.id);
    },
    setFilterDepartment() {
      return this.filterDepartment.map((item) => {
        return { "departments.id": item.id };
      });
    },
    setFilterRole() {
      return this.filterRole.map((item) => {
        return { "roles.id": item.id };
      });
    },
    setFilterLevel() {
      return this.filterLevel.map((item) => {
        return { "levels.id": item.id };
      });
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
  width: 0;
  background: $surface-subdued;
}

::-webkit-scrollbar-thumb {
  background: $surface-pressed;
  border-radius: 8px;
  width: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $action-primary;
  }
}

::-webkit-scrollbar-track {
  margin: 0;
}
.users {
  height: 90%;
  width: 100%;

  @media (height: 1366px) {
    height: 95%;
  }

  @media (max-height: 850px) {
    height: 84%;
  }

  @media (max-height: 600px) {
    height: 78%;
  }

  @media (max-height: $xs) {
    height: 88%;
  }

  @media (max-height: 320px) {
    height: 60%;
  }

  &__container {
    width: 100%;
    height: 90%;
    overflow: scroll;

    @media (max-height: 450px) {
      height: 75%;
    }

    @media (max-height: 420px) {
      height: 68%;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &_table {
      height: 80%;
    }
  }

  &__widgets {
    @media (max-width: 1000px) {
      display: none;
    }

    &_admin {
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  &__widgets-mobile {
    display: none;

    @media (max-width: 1000px) {
      display: flex;
    }

    &_admin {
      @media (max-width: 1200px) {
        display: flex;
      }
    }
  }

  /deep/ {
    .table__empty {
      .table__row {
        width: 100%;
      }
    }

    .table__row,
    .table__head {
      width: 100%;
      @media (max-width: 1766px) {
        width: fit-content;
      }
    }

    .table__cell {
      &:first-child {
        min-width: 400px;
      }

      &:nth-child(2) {
        min-width: 130px;
      }

      &:nth-child(3) {
        min-width: 305px;
      }

      &:nth-child(4) {
        min-width: 250px;
      }

      &:nth-child(5) {
        min-width: 304px;
      }

      &:last-child {
        min-width: 190px;
      }
    }
  }
}
</style>
