<template>
  <div class="message-reply" @click="handleClickReplyMessage">
    <div class="message-reply__wrap">
      <h4 class="message-reply__author">В ответ: {{ setAuthor.full_name }}</h4>
      <div class="message-reply__files" v-if="message.files_in_message.length">
        <div class="message-reply__images" v-if="isImage">
          <img
            :src="returnSrcFile(message.files_in_message[0].path)"
            :alt="message.files_in_message[0].name"
          />
        </div>
        <div class="message-reply__file" v-else>
          <i class="icon-document-text"></i>
          <div class="message-reply__text-wrap">
            <p class="message-reply__name">
              {{ setName }}
            </p>
            <p class="message-reply__size">140.0 Kb</p>
          </div>
        </div>
      </div>
      <p class="message-reply__text">{{ message.text }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import goToMessage from "@/modules/Messenger/mixins/goToMessage.mixin";
import { domainStorage } from "@/globalVariables";

export default {
  name: "MessageReply",
  mixins: [goToMessage],
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClickReplyMessage() {
      this.goToReplyMessage(this.message.id, "#list-dynamic");
    },
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    }
  },
  computed: {
    ...mapState("MessengerModule", ["users", "path"]),
    setAuthor() {
      return this.message.userInfo;
    },
    setName() {
      const length = this.message.files_in_message[0].name.length;
      return (
        this.message.files_in_message[0].name.slice(0, 5) +
        "..." +
        this.message.files_in_message[0].name.slice(length - 7, length)
      );
    },
    isImage() {
      if (this.message.files_in_message.length) {
        return (
          this.message.files_in_message[0].type === "photo" ||
          this.message.files_in_message[0].type === "gif"
        );
      } else {
        return false;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.message-reply {
  width: fit-content;
  max-width: 50%;
  padding: 8px 19px 8px 19px;
  background-color: white;
  font-size: 12px;
  line-height: 14px;
  color: $text-default;
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  &__author {
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: $text-default;
    font-weight: 500;
  }
  &::before {
    content: "";
    position: absolute;
    height: calc(100% - 2px);
    width: 3px;
    top: 1px;
    left: 1px;
    background-color: $chat-editor;
    border-radius: 4px 0px 0px 4px;
  }
  &__text {
    display: -webkit-box;
    padding: 0;
    margin: 5px 0 0;
    font-size: 12px;
    color: $text-default;
    font-weight: 400;
    max-height: 40px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__file {
    display: flex;
    background-color: $surface-primary-default;
    width: 140px;
    align-items: center;
    padding: 2px 4px 2px 2px;
    border-radius: 4px;
  }
  .icon-document-text {
    font-size: 20px;
    color: $icon-highlight;
    margin-left: 9px;
  }
  &__text-wrap {
    margin-left: 12px;
  }
  &__name {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    padding: 0;
  }
  &__size {
    margin: 0;
    padding: 1px 0 0;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: $text-disabled;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
  &__images {
    img {
      width: 140px;
      border-radius: 4px;
      object-fit: cover;
      object-position: 50% 20%;
    }
  }
}
</style>
