<template>
  <div class="curtain-search__container" @mousedown.self="closeCurtainSearch">
    <div class="curtain-search">
      <div class="curtain-search__header">
        <button class="curtain-search__hide-btn" type="button" @click="closeCurtainSearch">
          <i class="icon-arrow-short"></i>
        </button>
        <div class="curtain-search__input">
          <input
            type="text"
            placeholder="Поиск"
            v-model.trim="searchValue"
            ref="input"
            @input="search"
            :maxlength="80"
          />
          <svg
            width="25"
            height="25"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.04203 8.16703H8.5812L8.41786 8.00953C9.11786 7.19286 9.47953 6.0787 9.2812 4.89453C9.00703 3.27287 7.6537 1.97786 6.02036 1.77953C3.55286 1.4762 1.4762 3.55286 1.77953 6.02036C1.97786 7.6537 3.27286 9.00703 4.89453 9.2812C6.0787 9.47953 7.19286 9.11787 8.00953 8.41786L8.16703 8.5812V9.04203L10.6462 11.5212C10.8854 11.7604 11.2762 11.7604 11.5154 11.5212C11.7545 11.282 11.7545 10.8912 11.5154 10.652L9.04203 8.16703ZM5.54203 8.16703C4.08953 8.16703 2.91703 6.99453 2.91703 5.54203C2.91703 4.08953 4.08953 2.91703 5.54203 2.91703C6.99453 2.91703 8.16703 4.08953 8.16703 5.54203C8.16703 6.99453 6.99453 8.16703 5.54203 8.16703Z"
              fill="#A7A9C0"
            />
          </svg>
        </div>
      </div>

      <Spinner v-if="loaderSearch" class="curtain-search__loader" />
      <div class="curtain-search__results" v-else>
        <div class="curtain-search__empty-input" v-if="!searchValue">
          <p>ВВЕДИТЕ НАЗВАНИЕ ПРОБЛЕМЫ</p>
        </div>
        <div class="curtain-search__empty-results" v-else-if="!searchResults.length">
          <p>НИЧЕГО НЕ НАЙДЕНО</p>
        </div>
        <div class="curtain-search__founded" v-else>
          <FoundProblem v-for="problem in searchResults" :key="problem.id" :problem="problem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FoundProblem from "@/modules/Isp/components/Search/FoundProblem";
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "CurtainSearch",
  mixins: [],
  props: {},
  components: { Spinner, FoundProblem },
  data() {
    return {
      showTooltip: false,
      searchValue: "",
      searchTimeout: null
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    ...mapActions("IspModule", ["searchProblemsAction"]),
    ...mapMutations("IspModule", [
      "TOGGLE_SHOW_CURTAIN_SEARCH",
      "SET_SEARCH_RESULTS",
      "TOGGLE_LOADER_SEARCH"
    ]),
    closeCurtainSearch() {
      this.TOGGLE_SHOW_CURTAIN_SEARCH(false);
      this.SET_SEARCH_RESULTS([]);
    },
    search() {
      clearTimeout(this.searchTimeout);
      if (this.searchValue.length <= 80) {
        this.TOGGLE_LOADER_SEARCH(true);
        this.searchTimeout = setTimeout(() => {
          this.searchProblemsAction(this.searchValue);
        }, 600);
      } else {
        this.TOGGLE_LOADER_SEARCH(false);
        this.SET_SEARCH_RESULTS([]);
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["searchResults", "loaderSearch"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background-color: rgba(#d3d2e0, 0.5);
  border-radius: 4px;
  margin: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}
.curtain-search {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__header {
    display: flex;
    margin: 24px;
  }
  &__hide-btn {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    @media (max-width: $md) {
      display: inline-block;
    }
    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__input {
    width: 100%;
    position: relative;
    svg {
      position: absolute;
      top: 0px;
      left: 0;
    }
    input {
      outline: none;
      border: none;
      width: 100%;
      padding: 0 10px 5px 25px;
      border-bottom: 1.5px solid #b2b2b6;
      transition: 0.3s all ease;
      &:focus {
        border-bottom: 1.5px solid #6a67ce;
      }
      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #a7a9c0;
      }
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $text-default;
  }
  &__results {
    height: calc(100% - 75px);
    overflow: auto;
    display: flex;
  }
  &__empty-results {
    position: absolute;
    top: 50%;
    left: 40%;
    color: $text-default;
  }
  &__empty-input {
    margin: auto;
    color: $text-default;
  }
}
</style>
