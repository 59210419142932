<template>
  <li class="list-item" :id="setId">
    <div class="list-item__wrap">
      <p class="list-item__priority" :style="setStylePriority">{{ setPriority.name }}</p>
      <TypeTaskSmall :label="`${setType.name}`" />
      <ActionsList @handleAction="handleAction" :actionList="setActionList" />
    </div>
    <div class="list-item__wrap">
      <router-link
        class="list-item__name"
        :to="{ name: 'Task', params: { id: task.task_id }, query: { alias: task.alias } }"
      >
        {{ task.name }}
      </router-link>
    </div>
    <div class="list-item__wrap list-item__wrap_info">
      <User
        v-if="task.author_id"
        :avatar="setAuthor.avatar"
        :name="setAuthor.full_name"
        :id="setAuthor.id"
        :showInfo="false"
        :size="'32px'"
        :show-tooltip="true"
      />
      <div class="list-item__deadline">
        <p class="list-item__text"><span>Срок окончания:</span>{{ setDate }}</p>
      </div>
    </div>
    <div class="list-item__wrap">
      <p class="list-item__attachment-item" v-if="!task.parent_task_id">
        <i class="icon-list"></i>
        <span class="list-item__count">{{ task.childs_count && task.childs_count }}</span>
        <span class="list-item__tooltip">Подзадачи</span>
      </p>
      <p class="list-item__attachment-item">
        <i class="icon-comment-empty"></i>
        <span class="list-item__count">{{ task.count_comments && task.count_comments }}</span>
        <span class="list-item__tooltip">Комментариии</span>
      </p>
      <p class="list-item__attachment-item">
        <i class="icon-paper-clip-secondary"></i>
        <span class="list-item__count">{{ task.count_files && task.count_files }}</span>
        <span class="list-item__tooltip">Файлы</span>
      </p>
    </div>
    <ConfirmModal
      v-if="showConfirmModal"
      :title="confirmParams.title"
      :description="confirmParams.description"
      @cancel="showConfirmModal = false"
      @accept="confirmParams.callback()"
    />
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import TypeTaskSmall from "@/modules/UiKit/components/TypeTask/TypeTaskSmall";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import copyText from "@/helpers/copyText";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "MyDashboardListItem",
  mixins: [copyText, formatDateAndTime],
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tasksData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    }
  },
  components: { User, ActionsList, TypeTaskSmall, ConfirmModal },
  data() {
    return {
      target: null,
      inViewModel: true,
      showConfirmModal: false,
      confirmParams: null
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#task_${this.task.id}`);
    this.observer = new IntersectionObserver(() => {
      this.inViewModel = !this.inViewModel;
      if (this.inViewModel) {
        const taskList = JSON.parse(JSON.stringify(this.tasksData)).reverse();
        if (taskList[1] && taskList[1]._task && taskList[1]._task.id === this.task.id) {
          if (this.totalCount !== this.tasksData.length) {
            this.$emit("getInfo");
          }
        }
      }
    });
    this.observer.observe(this.target);
  },
  methods: {
    handleAction(item) {
      switch (item.actionType) {
        case "CopyLink":
        case "copyLink": {
          this.copyStringLink({
            id: this.task.task_id,
            alias: this.task.alias
          });
          break;
        }
        case "delete":
          this.setConfirmParams(false);
          this.showConfirm(true);
          break;
      }
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setPriority() {
      return TaskModule.prioritiesList.length
        ? TaskModule.prioritiesList.find((item) => item.id === this.task.priority_id)
        : {};
    },
    setType() {
      let type =
        TaskModule.typesList.length &&
        TaskModule.typesList.find((item) => item.id === this.task.type_id);
      if (this.task.parent_task_id) {
        type = {
          id: null,
          name: "Подзадача"
        };
      } else {
        type = {
          id: type.id,
          name: type.name
        };
      }
      return type;
    },
    setStatus() {
      return this.task.status_name && this.task.status_name;
    },
    setStylePriority() {
      return this.setPriority.name
        ? `color: ${this.setPriorityColor}; border: 1px solid ${this.setPriorityColor}; background: ${this.setPriorityBackground}`
        : "color: #FFB901; border: 1px solid #FFB901; background: #fef2be;";
    },
    setPriorityColor() {
      if (this.setPriority.name === "Высокий") {
        return "#FB646D";
      } else if (this.setPriority.name === "Средний") {
        return "#EE7200";
      } else {
        return "#FFB901";
      }
    },
    setPriorityBackground() {
      if (this.setPriority.name === "Высокий") {
        return "#fed1d3";
      } else if (this.setPriority.name === "Средний") {
        return "#fed3b3";
      } else {
        return "#fef2be";
      }
    },
    setDate() {
      return this.task.date_to ? this.setDateFormatFull(this.task.date_to) : "-";
    },
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.task.author_id);
    },
    isAuthor() {
      return this.task.author_id === this.getUserId;
    },
    setId() {
      return `task_${this.task.id}`;
    },
    setActionList() {
      const list = [];
      list.push({
        isShow: true,
        iconType: "chain",
        actionText: "Копировать ссылку",
        actionType: `copyLink`
      });
      if (this.isAuthor) {
        list.push({
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        });
      }
      return list;
    },
    isSubtask() {
      return this.setType.name === "Подзадача";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 240px;
  background: $surface-default;
  border: 1px solid $secondary;
  border-radius: 8px;
  flex-shrink: 0;
  margin-bottom: 16px;
  padding: 24px;

  @media (max-width: 700px) {
    width: 270px;
    padding: 16px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 18px;

    .action-list {
      margin-left: auto;
    }

    &_info {
      flex-wrap: wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__priority {
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 90px;
    height: 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: default;
  }

  &__name {
    width: 100%;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    color: $text-default;
    @include textEllipsis(2);
  }

  &__avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 16px;

    @media (max-width: 700px) {
      margin: 0 70px 16px 0;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    cursor: default;

    span {
      color: $text-subdued;
      margin-right: 8px;
    }
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: 100%;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: $surface-default;
    background: $text-default;
    border-radius: 2px;
  }

  &__attachment-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 16px;

    i {
      color: $icon-subdued;
      margin-right: 8px;
      font-size: 18px;
    }

    &:hover .list-item__tooltip {
      opacity: 1;
    }
  }

  &__count {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $text-default;
    cursor: default;
  }
}
</style>
