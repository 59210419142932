<template>
  <button
    :class="[setClass, setLoaderClass, 'default-button']"
    :type="eventType"
    @click.stop="handleButton"
  >
    <i :class="`icon-${icon}`" v-if="icon && !loader"></i>
    <span v-if="title.length && !loader">{{ title }}</span>
    <span class="count" v-if="count && !loader">{{ count }}</span>
    <Loader v-if="loader" />
  </button>
</template>

<script>
import Loader from "@/components/Widgets/Loader2.0/Loader";
export default {
  name: "ModalButton",
  mixins: [],
  props: {
    unique: {
      type: String,
      default: "handleButton"
    },
    title: {
      type: String,
      default: "Кнопка"
    },
    count: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "default"
    },
    eventType: {
      type: String,
      default: "button"
    },
    isActionList: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    }
  },
  components: { Loader },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleButton() {
      this.$emit("handleButton");
    }
  },
  computed: {
    setClass() {
      switch (this.type) {
        case "cancel": {
          return "default-button_cancel";
        }
        case "disabled": {
          return "default-button_disabled";
        }
        case "create-light": {
          return "default-button_create-light";
        }
        case "round": {
          return "default-button_round";
        }
        case "outline": {
          return "default-button_outline";
        }
        case "mobile": {
          return "default-button_mobile";
        }
        case "filter-list": {
          return "default-button_filter-list";
        }
        default: {
          return "";
        }
      }
    },
    setLoaderClass() {
      return this.loader ? "default-button_loader" : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.default-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: $action-primary;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fafaff;
  border: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  outline: none;
  i {
    margin-right: 8px;
  }
  &_round {
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background-color: $action-primary;
    border-radius: 50%;
    color: #fff;
    i {
      font-size: 14px;
      margin-right: 0;
    }
  }
  &:hover {
    background: $action-primary-hovered;
  }

  &:focus {
    outline: none;
  }

  &_disabled {
    background: $action-critical-disabled;
    &:hover {
      background: $action-critical-disabled;
    }
  }

  &_cancel {
    background: $action-secondary;
    color: $text-subdued;
    box-shadow: none;

    &:hover {
      background: $action-secondary-hovered;
    }
  }

  &_create-light {
    padding: 5px 12px;
    height: 30px;
    border-radius: 40px;
    border: 1px solid $action-primary;
    font-weight: 500;
    color: $action-primary;
    background: transparent;
    box-shadow: none;

    &:hover {
      color: $action-secondary;
      background: $action-primary-hovered;
    }

    &:active {
      color: $action-secondary;
      background: $action-primary-pressed;
    }
  }

  &_outline {
    padding: 5px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-disabled;
    border-radius: 40px;
    background: $action-secondary;
    border: 1px solid #d3d2e0;
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.05));

    &:hover {
      color: $text-disabled;
      background: $action-secondary-hovered;
    }

    &:active {
      color: $text-disabled;
      background: $action-secondary-pressed;
    }
  }

  &_loader {
    min-width: 120px;
    border: 1px solid #f2f2f7;
    background: #fafafe;
    filter: none;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ {
      .loader {
        width: 20px;
        height: 20px;
        border: 2px solid #d3d2e0;
        border-right: 3px solid transparent;
      }
    }
  }

  &_filter-list {
    width: 100%;
    padding: 8px;
    justify-content: flex-start;
    border: none;
    filter: none;
    background: transparent;
    box-shadow: none;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;

    &:hover,
    &:active,
    &:focus {
      background: #f1effa;
    }

    i {
      color: $border-default;
    }
  }

  &_loader {
    justify-content: center;
  }
}
.count {
  margin-left: 4px;
}
</style>
