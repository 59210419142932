<template>
  <li class="profile-isp__problem problem">
    <p class="problem__role"><span :style="{ background: setColorRole }"></span>{{ role }}</p>
    <router-link :to="{ name: 'IspProblem', params: { id: item.id } }"
      ><h3 class="problem__name">{{ item.name }}</h3></router-link
    >
    <!--    <p class="problem__create-date">-->
    <!--      Создана: {{ setDateFormatWithoutTime(item.datetime_of_creation) }}-->
    <!--    </p>-->
    <div class="problem__container problem__container_members">
      <div class="problem__wrap">
        <p class="problem__subtitle">Ответственный</p>
        <User
          :avatar="item.responsible_user.avatar"
          :name="item.responsible_user.full_name"
          :id="item.responsible_user.id"
          :showInfo="false"
          :size="'36px'"
        />
      </div>
      <div class="problem__wrap">
        <p class="problem__subtitle">Участники</p>
        <AddingMembersList
          :likeMode="false"
          :type="'Участники'"
          :membersList="item.task_executors"
          :userCount="item.task_executors.length"
          :addMember="false"
          :count="2"
          :size="'36px'"
          :fontSize="'12px'"
          :showTooltip="false"
        />
        <div class="problem__members" v-if="item.task_executors.length === 0">-</div>
      </div>
    </div>
    <div class="problem__container problem__container_criticality">
      <div class="problem__wrap">
        <p class="problem__subtitle">Критичность</p>
        <p
          class="problem__criticality"
          :style="
            item.criticality
              ? { color: setCriticalityColor, background: setCriticalityBackground }
              : 'justify-content: flex-start'
          "
        >
          {{ setCriticality }}
        </p>
      </div>
      <div class="problem__wrap problem__wrap_progress">
        <p class="problem__subtitle">Прогресс</p>
        <Progress :percentage="item.completion_percentage" />
      </div>
    </div>
    <div class="problem__container problem__container_quarter">
      <div class="problem__wrap">
        <p class="problem__subtitle">Квартал</p>
        <p class="problem__quarter">{{ currentPeriodName }}</p>
      </div>
      <div class="problem__wrap">
        <p class="problem__subtitle">Дедлайн</p>
        <p class="problem__deadline">{{ setDeadline }}</p>
      </div>
    </div>
  </li>
</template>

<script>
import User from "@/components/Widgets/User/User";
import AddingMembersList from "@/components/Widgets/User/AddingMembers/AddingMembersList";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import Progress from "@/components/Widgets/Progress/Progress";
import { domainStorage } from "@/globalVariables";
export default {
  name: "ProfileISPItem",
  mixins: [formatDateAndTime],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    currentPeriodName: {
      type: String,
      default: ""
    },
    role: {
      type: String,
      default: ""
    }
  },
  components: { User, Progress, AddingMembersList },
  data() {
    return {};
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    getFirstTwoProblemMember() {
      return this.item.task_executors.slice(0, 2);
    },
    setColorRole() {
      switch (this.role) {
        case "Исполнитель":
          return "#FFD200";
        case "Наблюдатель":
          return "#80E6FF";
        case "Ответственный":
          return "#3BE8B0";
        case "Автор":
          return "#F86089";
        case "Заказчик":
          return "#FFE880";
        default:
          return "#FFD200";
      }
    },
    setCriticality() {
      return this.item.criticality ? this.item.criticality.label : "-";
    },
    setCriticalityColor() {
      if (this.item.criticality) {
        switch (this.item.criticality.label) {
          case "Комфорт":
            return "#37C597";
          case "Блокирующая":
            return "#E73B3B";
          case "Значительная":
            return "#02CEFF";
          case "Критическая":
            return "#FD9A00";
          default:
            return "#343748";
        }
      }
    },
    setCriticalityBackground() {
      if (this.item.criticality) {
        switch (this.item.criticality.label) {
          case "Комфорт":
            return "#EBFCF6";
          case "Блокирующая":
            return "#FEF0F0";
          case "Значительная":
            return "#E8F7FC";
          case "Критическая":
            return "#FFF8E5";
          default:
            return "transparent";
        }
      }
    },
    setDeadline() {
      return this.item.deadline ? this.setDateFormatFull(this.item.deadline) : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.problem {
  width: 100%;
  padding: 16px 32px 0;

  @media (max-width: $xxxl) {
    padding: 24px 24px 0;
  }

  @media (max-width: $xl) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-disabled;
  }

  &__role {
    display: flex;
    align-items: center;
    margin: 0 0 32px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;

    @media (max-width: $xxxl) {
      margin: 0 0 24px;
    }

    @media (max-width: $xl) {
      width: 100%;
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &__name {
    margin: 0 0 8px;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    word-break: break-word;

    @media (max-width: $xl) {
      width: 100%;
    }
  }

  &__create-date {
    margin: 0 0 32px;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: $text-disabled;

    @media (max-width: $xl) {
      width: 100%;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    &_members {
      @media (max-width: $xl) {
        width: 40%;
      }

      @media (max-width: $sm) {
        width: 100%;
      }
    }

    &_criticality {
      @media (max-width: $xl) {
        width: 25%;
      }

      @media (max-width: $sm) {
        width: 100%;
      }
    }

    &_quarter {
      @media (max-width: $xl) {
        width: 35%;
      }

      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }

  &__wrap {
    width: 48%;

    &_progress {
      @media (max-width: $xl) {
        display: none;
      }
    }
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__members {
    display: flex;
    .user {
      &:not(:first-child) {
        position: relative;
        left: -15px;
      }

      /deep/ {
        img {
          margin: 0;
          border: 2px solid #ffffff;
        }
      }
    }
  }

  &__count {
    position: relative;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: #c1c7d0;
    left: -30px;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
  }

  &__criticality {
    width: 127px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__quarter {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    color: #343748;
  }
}
</style>
