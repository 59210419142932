import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import TaskModel from "@/modules/TaskManager/Model/Task/TaskModel";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import NotificationObserver from "@/helpers/UserNotification";
import { EventBus } from "@/main";

class TaskService extends Service {
  getTaskList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_my`,
      params: params
    }).then((response) => {
      TaskModule[TaskMutationTypes.SET_TOTAL_COUNT](response.data.count);
      TaskModule[TaskMutationTypes.SET_FROM_PAGE](TaskModule.fromPag + 1);
      return response.data.list.map((item) => new TaskModel(item));
    });
  }

  getCurrentTask(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_one`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }

  updateCurrentTask(params: object): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/tasks/update`,
      data: params
    }).then((response) => {
      NotificationObserver.notification({
        type: "success",
        message: "Задача успешно изменена!"
      });
    });
  }

  deleteCurrentTask(id: number): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/delete`,
      params: {
        id: id
      }
    }).then((response) => {
      NotificationObserver.notification({
        type: "success",
        message: "Задача успешно удалена!"
      });
      // EventBus.$emit("updateData", response.data); закоментил, потому что дублируются задачи в дашборде
    });
  }

  createTask(body: object): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/tasks/create`,
      data: body
    }).then((response) => {
      EventBus.$emit("updateData", body);
    });
  }

  checkSubtaskStatuses(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/check_sub_status`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  getHotTask(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_hot`,
      params
    }).then((response) => {
      TaskModule[TaskMutationTypes.SET_HOT_COUNT](response.data.count);
      TaskModule[TaskMutationTypes.SET_HOT_PAGE](TaskModule.hotPage + 1);
      return response.data.list.map((item) => new TaskModel(item));
    });
  }

  getOverdueTask(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_overdue`,
      params
    }).then((response) => {
      TaskModule[TaskMutationTypes.SET_OVERDUE_COUNT](response.data.count);
      TaskModule[TaskMutationTypes.SET_OVERDUE_PAGE](TaskModule.overduePage + 1);
      return response.data.list.map((item) => new TaskModel(item));
    });
  }

  getTypesList(): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/task_types/get_all`,
      params: {
        page: 1,
        per_page: 50
      }
    }).then((response) => {
      return response.data;
    });
  }

  getPrioritiesList(): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/priorities/getAll`,
      params: {
        page: 1,
        per_page: 50
      }
    }).then((response) => {
      return response.data;
    });
  }

  getStatusesList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/flows/get_flow`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }

  getTaskListByStageId(params): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_stage`,
      params
    }).then((response) => {
      TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_COUNT](response.data.count);
      TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](TaskModule.tasksByStagePage + 1);
      return response.data;
    });
  }

  getSubtaskTaskListByTaskId(params): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_childs`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  createTaskCheckList(body): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/checklists/create`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  updateTaskCheckListItem(body): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/checklists/update`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  deleteTaskCheckListItem(id: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/checklists/delete`,
      params: {
        id: id
      }
    }).then((response) => {
      return response.data;
    });
  }

  getCommentsList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `task_manager/tasks/get_comments`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  getFilesList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `task_manager/files/get_all`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  getAnswerCommentsList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `task_manager/tasks/get_child_comments`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  creteComments(body: object): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/tasks/create_comment`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  updateComments(body: object): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/tasks/update_comment`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  deleteComment(body: object): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/tasks/delete_comment`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  getHistoryTask(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_history`,
      params
    }).then((response) => {
      return response.data;
    });
  }
}

export default new TaskService();
