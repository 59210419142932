<template>
  <div class="nav-section">
    <div
      class="section-name"
      @click="showList = !showList"
      :style="showList ? 'color: #8D96EC;' : ''"
    >
      {{ item.name }}
      <i
        class="icon-down"
        :style="showList ? 'transform: rotate(180deg); color: #8D96EC;' : 'color: #B8B8CB;'"
      ></i>
    </div>
    <slide-up-down :active="showList">
      <div
        class="section-categories"
        @click="$router.push({ name: item.route, params: { category_name: 'Все' } })"
      >
        Показать все
      </div>
      <div
        class="section-categories"
        v-for="category in item.categories"
        :key="category.name"
        @click="$router.push({ name: item.route, params: { category_name: category.name } })"
      >
        {{ category.name }}
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "NavSectionLink",
  mixins: [],
  props: ["item"],
  components: { SlideUpDown },
  data() {
    return {
      showList: false
    };
  },
  mounted() {
    if (this.item.route === "RegulationsPage") {
      this.item.categories = [
        {
          name: "Гайды"
        },
        {
          name: "Памятки"
        },
        {
          name: "Инструкции"
        }
      ];
    }
  },
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.nav-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  .section-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-primary-1;
    cursor: pointer;
    user-select: none;

    .icon-down {
      font-size: 16px;
      transition: 0.3s;
    }
  }

  .section-categories {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-primary-2;
    padding: 5px 10px;
    cursor: pointer;
  }
}
</style>
