<template>
  <div class="info-problem__container">
    <div class="info-problem__box" @click.self="closeInfo"></div>
    <div class="info-problem">
      <div class="info-problem__header header">
        <ProgressBar
          :showNumber="false"
          :showNumberBar="true"
          :percent="currentInfoProblem.completion_percentage"
        />
        <div class="header__tabs">
          <button class="header__hide-btn" type="button" @click="closeInfo">
            <i class="icon-arrow-short"></i>
          </button>
          <ChosenFilter
            v-model="currentTabForChosenFilter"
            :icon="'none'"
            :placeholder="'Команды'"
            :search="false"
            :allValue="false"
            :chosenList="tabsList"
            @updateInfo="selectTab"
          />

          <TabsContent :tabs="tabsList" v-model="currentTab" />

          <div class="info-problem__icons">
            <router-link
              class="info-problem__link"
              :to="{ name: 'IspProblem', params: { id: currentInfoProblem.id } }"
            >
              <i class="icon-launch"></i>
            </router-link>
            <ActionsList v-if="showList" :actionList="setActionList" @handleAction="handleAction" />
          </div>
        </div>
      </div>
      <div class="info-problem__body">
        <component :is="bodyLoader" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import ClickOutside from "vue-click-outside";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";

export default {
  name: "InfoProblem",
  mixins: [],
  props: {},
  components: { ChosenFilter, TabsContent, ProgressBar, ActionsList },
  data() {
    return {
      currentTab: "problem",
      currentTabForChosenFilter: [],
      actionList: []
    };
  },
  created() {
    this.getInfoProblem();
  },
  mounted() {
    this.getHistoryProblemAction();
    this.getProblemCommentsAction(this.currentInfoProblem.id);
  },
  methods: {
    ...mapActions("IspModule", [
      "getFullDataOnProblem",
      "closeCurrentInfoProblem",
      "handleActionProblem",
      "getHistoryProblemAction",
      "getProblemCommentsAction"
    ]),
    closeInfo() {
      this.closeCurrentInfoProblem();
    },
    selectTab() {
      this.currentTab = this.currentTabForChosenFilter[0].value;
    },
    handleAction(event) {
      this.handleActionProblem(event.actionType);
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "currentProblemComments"]),
    isGroupLeader() {
      return this.currentInfoProblem.group.leader_user_id === this.getUserId;
    },
    isExecutor() {
      return this.currentInfoProblem.task_executors.some((item) => item.id === this.getUserId);
    },
    isCustomer() {
      return this.currentInfoProblem.customer_user.id === this.getUserId;
    },
    isResponsible() {
      return this.currentInfoProblem.responsible_user.id === this.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },

    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    setActionList() {
      const list = this.actionList.map((item) => item);
      list.push({
        isShow: (this.isGroupLeader || this.isISPManager) && !this.currentInfoProblem.is_archived,
        iconType: "compare-arrows",
        actionText: "Переместить",
        actionType: "move"
      });
      list.push({
        isShow:
          (this.isGroupLeader || this.isISPManager) &&
          !this.currentInfoProblem.children_problems.length,
        iconType: "copy",
        actionText: "Дублировать",
        actionType: "copy"
      });

      if (this.currentInfoProblem.is_archived) {
        list.push({
          isShow: this.isISPManager,
          iconType: "archive",
          actionText: "Разархивировать",
          actionType: "archive"
        });
      } else {
        list.push({
          isShow: this.isISPManager,
          iconType: "archive",
          actionText: "Архивировать",
          actionType: "archive"
        });
      }
      if (this.currentInfoProblem.period_id) {
        list.push({
          isShow: this.isGroupLeader || this.isISPManager,
          iconType: "arrow-enter",
          actionText: "Вернуть в бэклог",
          actionType: "backlog"
        });
      }
      list.push({
        isShow: this.isISPManager && !this.currentInfoProblem.children_problems.length,
        iconType: "delete",
        actionText: "Удалить проблему",
        actionType: "delete"
      });

      return list;
    },
    showList() {
      return this.setActionList.some((item) => item.isShow === true);
    },

    bodyLoader() {
      switch (this.currentTab) {
        case "problem":
          return () =>
            import(`@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/ProblemBody`);
        case "tasks":
          return () =>
            import(`@/modules/Isp/components/InfoProblem/BodyComponents/TasksBody/TasksBody`);
        case "comments":
          return () =>
            import(`@/modules/Isp/components/InfoProblem/BodyComponents/CommentsBody/CommentsBody`);
        case "history":
          return () =>
            import(`@/modules/Isp/components/InfoProblem/BodyComponents/HistoryBody/HistoryBody`);
        default:
          return () =>
            import(`@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/ProblemBody`);
      }
    },
    tabsList() {
      return [
        { id: 1, label: "Проблема", value: "problem" },
        { id: 2, label: "Задачи", value: "tasks" },
        {
          id: 3,
          label: "Комментарии",
          value: "comments",
          count: this.totalCountComments ? this.totalCountComments : ""
        },
        { id: 4, label: "История", value: "history" }
      ];
    },
    totalCountComments() {
      return (
        this.currentProblemComments.length +
        this.currentProblemComments.reduce((totalCount, comment) => {
          return totalCount + comment.replies.length;
        }, 0)
      );
    }
  },
  watch: {
    currentInfoProblem(val, old) {
      if (val.id !== old.id) {
        this.getHistoryProblemAction();
        this.getProblemCommentsAction(val.id);
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.info-problem {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 101;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 90;
  }
  &__box {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  .icon-launch {
    font-size: 18px;
  }
  &__icons {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: $icon-subdued;
    @media (max-width: $xs) {
      margin-left: 0;
    }
    i {
      cursor: pointer;
    }
  }
  &__link {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $icon-subdued;
    transition: color 0.3s ease;
    margin-right: 16px;

    &:hover {
      background: #f2f7fe;
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-bottom: 1px solid #eae8f0;
    &__hide-btn {
      display: none;
      padding: 0;
      border: none;
      background: transparent;
      outline: none;
      transform: rotate(-90deg);
      margin-right: 15px;

      @media (max-width: $md) {
        display: inline-block;
      }
      i {
        color: $icon-subdued;
        font-size: 20px;
      }

      @media (hover: hover) {
        &:hover i {
          color: $icon-hovered;
        }
      }

      &:active i {
        color: $icon-pressed;
      }
    }
    /deep/ {
      .progress {
        width: 100%;
        &__percent {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          margin: 0;
        }
        &__wrap {
          border-radius: 0;
          height: 18px;
        }
        &__bar {
          border-radius: 0 16px 16px 0;
        }
        &__bar-full {
          border-radius: 0;
        }
      }
    }

    &__tabs {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 15px 20px 20px;
      @media (max-width: $xs) {
        justify-content: space-between;
      }
      /deep/ {
        .tabs {
          @media (max-width: $xs) {
            display: none;
          }
          &__item {
            margin: 0 32px 0 0;
            user-select: none;
          }
          &__list {
            overflow: auto;
            .tabs__item {
              @media (max-width: $xxxl) {
                font-size: 16px;
                margin-right: 25px;
              }
              @media (max-width: $sm) {
                margin-right: 20px;
              }
            }
          }
        }
        .chosen-filter {
          display: none;
          @media (max-width: $xs) {
            display: flex;
          }
          &__label {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    /deep/ {
      .problem-body {
        overflow-x: hidden;
        &__wrap-more {
          flex-wrap: wrap;
          & > div {
            @media (max-width: $xxxl) {
              width: 34%;
              &:nth-child(4n + 1),
              &:nth-child(4n + 2) {
                margin-bottom: 8px;
              }
            }

            @media (max-width: $md) {
              width: auto;
              &:nth-child(4n + 1),
              &:nth-child(4n + 2) {
                margin-bottom: 0;
              }
            }
            @media (max-width: $sm) {
              width: 34%;
              &:nth-child(4n + 1),
              &:nth-child(4n + 2) {
                margin-bottom: 8px;
              }
            }
            @media (max-width: 365px) {
              width: 100%;
              margin-bottom: 8px;
            }
          }
        }
        &__wrap-label {
          justify-content: space-between;
          .wrap-label__observe {
            margin-left: 0;
          }
        }
        &__wrap-docs {
          .wrap-docs__label-wrap {
            @media (max-width: $xs) {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}
</style>
