<template>
  <div class="graph-vacation__modal modal">
    <div class="modal__window">
      <ModalHeader
        :type="'transfer'"
        :editMode="editMode"
        :createMode="createMode"
        :vacation="vacation"
        :isUser="isUser"
        :request="request"
      />
      <div class="modal__main">
        <ModalDateTransfer
          v-if="checkTransfer"
          :editMode="editMode"
          :createMode="createMode"
          :vacation="vacation"
          :transferVacationOrder="vacation.not_accepted_transfer_vacation_order"
          :user="user"
        />
        <ModalDate
          v-else
          :editMode="true"
          :createMode="false"
          :vacation="vacation"
          :user="user"
          :isAvailableForEditing="isAvailableForEditing"
        />
        <ModalInfo
          :editMode="editMode"
          :createMode="createMode"
          :vacation="vacation.not_accepted_transfer_vacation_order || vacation"
        />
      </div>
      <div class="modal__comments modal__reason">
        <h2 class="modal__heading">
          <IcoMoonWrap name="align-left" pathCount="3" fontSize="24" />
          <i class="icon-align-left"></i>Причина
        </h2>
        <p
          class="modal__text"
          v-if="!createMode && !editMode"
          ref="reasonDescription"
          :class="{
            modal__text_full: !bigTextTrigger && showTrigger,
            modal__text_hide: bigTextTrigger
          }"
          v-html="setURL(`<span>${vacation.not_accepted_transfer_vacation_order.reason}</span>`)"
        ></p>
        <button
          class="modal__hide-button"
          type="button"
          @click="showBigText"
          v-if="bigTextTrigger && showTrigger"
        >
          Показать все
        </button>
        <span
          class="modal__hide-button"
          type="button"
          @click="hideBigText"
          v-if="!bigTextTrigger && showTrigger"
          >Свернуть</span
        >
        <div class="modal__text-field" v-else>
          <textarea v-model="vacationTransferReason" @change="changeReason"></textarea>
        </div>
      </div>
      <div class="modal__comments" v-if="isComment">
        <h2 class="modal__heading">
          <i class="icon-comments"></i>Комментарии <span>({{ comment.length }})</span>
        </h2>
        <ModalComment :comment="item" v-for="(item, index) in comment" :key="index" />
      </div>
      <ModalFooter
        v-if="!request"
        :editMode="editMode"
        :createMode="createMode"
        :vacation="vacation.not_accepted_transfer_vacation_order || vacation"
      />
      <ModalFooterConfirm :vacation="vacation" :isProfile="isProfile" v-else />
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalHeader";
import ModalInfo from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalInfo";
import ModalComment from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalComment";
import ModalDateTransfer from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalDateTransfer";
import ModalFooter from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalFooter";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
import Widget from "@/components/Widgets/Widget";
import Textarea from "@/components/Widgets/Textarea/Textarea";
import ModalFooterConfirm from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/Modal/ModalComponent/ModalFooterConfirm";
import ModalDate from "@/components/GraphVacation/ModalComponent/ModalDate";
import UrlMixin from "@/helpers/UrlMixin";
export default {
  name: "VacationRequestModal",
  mixins: [UrlMixin],
  props: {
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    editMode: {
      type: Boolean,
      required: true,
      default: true
    },
    createMode: {
      type: Boolean,
      required: true,
      default: false
    },
    request: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    },
    isProfile: {
      type: Boolean,
      default: false
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    ModalDate,
    ModalFooterConfirm,
    Textarea,
    Widget,
    IcoMoonWrap,
    ModalFooter,
    ModalDateTransfer,
    ModalInfo,
    ModalHeader,
    ModalComment
  },
  data() {
    return {
      comment: [],
      bigTextTrigger: true,
      contentHeight: 0,
      vacationTransferReason: this.vacation.not_accepted_transfer_vacation_order.reason || ""
    };
  },
  mounted() {
    this.setComment();
    this.hideBigText();
    this.contentHeight = this.$refs.reasonDescription.children[0].offsetHeight;
    this.$root.$on(`text:change_vacationTransferReason`, (val) => {
      this.vacationTransferReason = val;
    });
  },
  methods: {
    setComment() {
      this.comment = this.vacation.confirmations
        .map((item) => {
          return { comment: item.comment, user: item.user };
        })
        .filter((item) => item.comment);
    },
    hideBigText() {
      this.bigTextTrigger = true;
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    checkType() {
      return this.vacation.not_accepted_transfer_vacation_order ? "Перенос" : "Отпуск";
    },
    changeReason() {
      this.$root.$emit("vacationTransferReason", this.vacationTransferReason);
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    checkTransfer() {
      return (
        this.vacation.hasOwnProperty("not_accepted_transfer_vacation_order") &&
        this.vacation.not_accepted_transfer_vacation_order
      );
    },
    isComment() {
      if (this.createMode) {
        return false;
      } else {
        return this.comment.length || this.request;
      }
    },
    showTrigger() {
      return this.contentHeight > 40;
    }
  },
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints.scss";
@import "~@/assets/base/color/colors.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 32, 44, 0.8);

  &__window {
    width: 798px;
    background: $bg-surface-primary;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 24px;

    @media (max-width: 767px), (max-height: $xs) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      overflow-y: scroll;
    }
  }

  &__main {
    padding: 48px 42px;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #343748;
    margin: 0 0 24px;

    span {
      margin-left: 8px;
    }

    i {
      margin-right: 8px;
    }
  }
  &__text-field {
    position: relative;

    textarea {
      height: 36px;
      border: none;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $text-disabled;
      width: 100%;
      resize: none;
      &:focus {
        outline: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #6a67ce;
      animation-name: border;
      animation-timing-function: ease;
      animation-duration: 0.5s;
      transform: scale(1);
      transform-origin: center;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }
    @keyframes border {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-subdued;
    margin-bottom: 24px;
    word-break: break-word;
    white-space: pre-wrap;

    &_hide {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 4px;
    }

    &_full {
      margin-bottom: 4px;
      overflow-y: scroll;
      max-height: 100px;
    }

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }

  &__hide-button {
    margin-bottom: 24px;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $link-default;
    background: transparent;
    border: none;

    &:hover {
      color: $link-hovered;
    }

    &:active {
      color: $link-pressed;
    }
  }

  &__comments {
    padding: 24px 42px 18px;
    border-top: 1px solid #f2f2f7;
    @media screen and (max-width: $md) {
      padding-left: 20px;
      padding-right: 20px;
      max-height: 100%;
    }
  }

  .icon-comments {
    color: #a7a9c0;
    opacity: 0.3;
  }

  /deep/ {
    .modal__footer,
    .modal__header {
      padding: 24px 42px;
    }
  }
}
</style>
