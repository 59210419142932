<template>
  <div class="modal-item" @click="handleUser">
    <div class="modal-item__avatar">
      <img class="modal-item__logo" :src="returnSrcFile" alt="" v-if="setChannel.avatar" />
      <span
        class="modal-item__logo"
        v-else
        :style="[setChannel.color ? { backgroundColor: setChannel.color } : '']"
      >
        <span>{{ setChannelName }}</span>
      </span>
    </div>
    <div class="modal-item__wrap">
      <span class="modal-item__name">{{ setChannel.full_name }}</span>
      <span class="modal-item__text">{{ setTag }}{{ setChannel.nickname }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { domainStorage } from "@/globalVariables";

export default {
  name: "SearchItem",
  mixins: [],
  props: {
    chat: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setNewChat"]),
    handleUser() {
      this.setNewChat(this.chat);
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setChannel() {
      if (this.chat.type === 1) {
        return this.chat.companion;
      } else {
        return {
          full_name: this.chat.chat_settings.name,
          nickname: this.chat.chat_settings.tag,
          avatar: this.chat.chat_settings.avatar?.path,
          color: this.chat.chat_settings.color
        };
      }
    },
    returnSrcFile() {
      if (this.chat.type === 1) {
        return domainStorage + this.setChannel.avatar;
      } else {
        return domainStorage + "/chat/files/" + this.setChannel.avatar;
      }
    },
    setChannelName() {
      return this.setChannel.full_name[0].toUpperCase();
    },
    setTag() {
      return this.chat.type === 1 ? "@" : "#";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal-item {
  padding: 8px;
  background: #fff;
  transition: background 0.2s ease;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  @media (hover: hover) {
    &:hover {
      background: $message;
    }
  }
  &__avatar {
    margin-right: 8px;
    height: 40px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    object-fit: cover;
    background-color: $message;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    padding: 0;
    margin: 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 220px;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $system-grey-seven;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 220px;
  }
  &__wrap {
    display: flex;
    flex-flow: column;
  }
}
</style>
