<template>
  <div class="switch-widget">
    <label class="switch-widget__container">
      <span class="switch-widget__text">
        <i :class="icon"></i>
        <span class="switch-widget__label" v-if="label">{{ label }}</span>
      </span>
      <input class="switch-widget__input" type="checkbox" :checked="value" @change="changeSwitch" />
      <span class="switch-widget__indicator"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchComponent",
  mixins: [],
  props: {
    label: {
      type: Text,
      default: ""
    },
    icon: {
      type: Text,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    changeSwitch() {
      this.$emit("changeSwitch");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.switch-widget {
  width: 100%;

  i {
    font-size: 24px;
    color: $text-subdued;
    cursor: pointer;
    margin-right: 12px;
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__text {
    display: flex;
    align-items: center;
  }
  &__input {
    display: none;
    font-size: 0;
    width: 0;
    height: 0;

    &:checked + .switch-widget__indicator {
      border-color: $action-primary;
      background: $message-gradient;

      &::before {
        top: 2px;
        left: 18px;
        width: 12px;
        height: 12px;
        background-color: #fafafe;
      }
    }
  }

  &__indicator {
    position: relative;
    width: 32px;
    height: 16px;
    background: $background-gradient;
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s ease;
    }
  }

  &__label {
    cursor: pointer;
    margin-left: 3px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
