<template>
  <div class="comment">
    <div class="comment__info">
      <User
        :avatar="comment.author.avatar"
        :showTooltip="false"
        :name="comment.author.full_name"
        :info="comment.author.roles.length ? `[${comment.author.roles[0].name}]` : ''"
        :size="size"
        :row="true"
        :id="comment.author.id"
        :additionalInfo="setDateAndTime"
      />
      <div class="button-show-comments" @click="showList = !showList" v-if="comment.replies.length">
        {{ !showList ? `Ответы (${comment.replies.length})` : "Скрыть" }}
      </div>
    </div>
    <div class="comment__wrap">
      <div class="comment__content-wrap">
        <p
          class="comment__text"
          ref="content"
          :style="
            bigTextTrigger
              ? `display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;`
              : ''
          "
          v-if="comment.content"
          v-html="setURL(comment.content)"
        ></p>
        <span v-if="showTrigger">
          <span
            class="comment__plain-button"
            @click="showBigText(comment.content)"
            v-if="bigTextTrigger"
          >
            <span>Показать полностью</span>
          </span>
          <span class="comment__plain-button" @click="hideBigText" v-if="!bigTextTrigger">
            <span>Свернуть</span>
          </span>
        </span>
        <ul
          class="comment__file-list"
          v-if="comment.attachment_files.length && !comment.content.length && getImgFiles.length"
        >
          <CommentFileItem
            v-for="(file, idx) in getImgFiles"
            :key="file.id"
            :idx="idx"
            :file="file"
            :currentId="commentableId"
            :fileName="file.label"
            :showCross="false"
            :isMain="false"
          />
        </ul>
        <ul
          class="comment__file-list"
          v-if="
            comment.attachment_files.length && !comment.content.length && getDocumentsFiles.length
          "
        >
          <CommentFileItem
            v-for="(file, idx) in getDocumentsFiles"
            :key="file.id"
            :idx="idx"
            :file="file"
            :currentId="commentableId"
            :fileName="file.label"
            :showCross="false"
            :isMain="false"
          />
        </ul>
        <p v-if="comment.is_deleted" class="comment__deleted">Комментарий был удален</p>
      </div>
      <NewsFooterItem
        v-if="!comment.is_deleted"
        :icon="'heart'"
        :count="countLike"
        :commentItem="comment"
        :sizeAvatar="sizeAvatar"
        :type="'comments'"
        :action="`heart${comment.id}`"
        :currentUserLike="isUserLike"
        @footer_item_heart="postLike"
      />
    </div>
    <ul
      class="comment__file-list"
      v-if="comment.attachment_files.length && comment.content.length && getImgFiles.length"
    >
      <CommentFileItem
        v-for="(file, idx) in getImgFiles"
        :key="file.id"
        :idx="idx"
        :file="file"
        :currentId="commentableId"
        :fileName="file.label"
        :showCross="false"
        :isMain="false"
      />
    </ul>
    <ul
      class="comment__file-list"
      v-if="comment.attachment_files.length && comment.content.length && getDocumentsFiles.length"
    >
      <CommentFileItem
        v-for="(file, idx) in getDocumentsFiles"
        :key="file.id"
        :idx="idx"
        :file="file"
        :currentId="commentableId"
        :fileName="file.label"
        :showCross="false"
        :isMain="false"
      />
    </ul>
    <div class="comment__footer">
      <p
        v-if="reply && !editCommentMode && !replyMode"
        @click="replyHandler"
        class="comment__reply"
        :style="`display: ${isGlobalMobileMode ? 'block' : ''}`"
      >
        <i class="icon-reply-arrow"></i>
        Ответить
      </p>
      <p
        v-if="isCommentEdit && !replyMode && hoursBeforeComment"
        class="comment__edit"
        @click="setEditMode"
        :style="`display: ${isGlobalMobileMode ? 'block' : ''}`"
      >
        <i class="icon-edit"></i>
        Редактировать
      </p>
      <p
        v-if="isCommentEdit && !replyMode"
        class="comment__edit"
        @click="deleteComment"
        :style="`display: ${isGlobalMobileMode ? 'block' : ''}`"
      >
        <i class="icon-delete"></i>
        Удалить
      </p>
      <p
        v-if="editCommentMode || replyMode"
        @click="closeEditMode"
        class="comment__edit-decline comment__edit"
      >
        Отменить
      </p>
    </div>
    <div class="comment__wrap-footer">
      <slide-up-down :active="showList" v-if="comment.replies.length">
        <h3>Ответы:</h3>
        <div v-if="showList">
          <CommentNew
            v-for="reply in comment.replies"
            :key="reply.id"
            :comment="reply"
            :size="size"
            :reply="false"
            :admin="admin"
            :creator="creator"
            :commentableId="commentableId"
            :news="news"
            :drop="true"
            :dropDirectory="true"
            :fileDrop="true"
            @updateCommentsList="$emit('updateCommentsList')"
            @cancelReply="$emit('cancelReply')"
          >
          </CommentNew>
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import NewsFooterItem from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsFooterItem";
import SlideUpDown from "vue-slide-up-down";
import CommentFileItem from "@/components/GraphVacation/Comments/CommentFileItem";
import { format, parseISO } from "date-fns";
import UrlMixin from "@/helpers/UrlMixin";
import { mapActions, mapState } from "vuex";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";

export default {
  name: "CommentNew",
  inheritAttrs: false,

  mixins: [UrlMixin, formatDateAndTime],
  props: {
    comment: {
      type: Object,
      default: () => {}
    },
    admin: {
      type: Boolean,
      default: false
    },
    creator: {
      type: Boolean,
      default: false
    },
    reply: {
      type: Object,
      default: () => {}
    },
    typeComment: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "42px"
    },
    sizeAvatar: {
      type: String,
      default: "42px"
    },
    commentableId: {
      type: [String, Number],
      default: ""
    },
    news: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { User, CommentFileItem, SlideUpDown, NewsFooterItem },
  data() {
    return {
      current_user_like: null,
      countLike: this.comment.count_likes,
      isUserLike: null,
      editCommentMode: false,
      replyMode: false,
      showList: false,
      editContent: "",
      bigTextTrigger: true,
      contentHeight: 0,
      files: this.comment.attachment_files || [],
      fileUploadingId: [],
      oldFilesId: []
    };
  },
  mounted() {
    this.files = this.comment.attachment_files;
    this.isUserLike = this.isCurrentLikeId;
    this.hideBigText();
  },
  updated() {
    this.contentHeight = this.getHeight(this.$refs.content);
  },
  methods: {
    ...mapActions("NewsModule", [
      "setEditCommentData",
      "setReplyCommentData",
      "like",
      "removeLike",
      "deleteCommentAction"
    ]),
    replyHandler() {
      this.$emit("setReplyComment", this.comment);
      this.setReplyCommentData({ comment: this.comment, commentableId: this.commentableId });
      document.getElementById(`textarea${this.news.id}`).focus();
      this.replyMode = true;
    },
    setEditMode() {
      this.closeEditMode();
      this.editCommentMode = true;
      this.editContent = this.comment.content;

      this.setEditCommentData({
        comment: this.comment,
        editMode: true,
        commentableId: this.commentableId
      });
      document.getElementById(`textarea${this.news.id}`).focus();
      this.$emit("setGlobalCommentEditMode");
    },
    closeEditMode() {
      this.editCommentMode = false;
      this.replyMode = false;
      this.setEditCommentData({
        editMode: false,
        commentableId: this.commentableId
      });
      this.setReplyCommentData(null);
      this.$emit("setReplyComment", null);
      this.$emit("closeGlobalCommentEditMode");
    },
    updateCommentHandler() {
      if (!this.checkValidation()) return false;
      this.loading = true;
      if (!this.fileUploader.length) {
        this.updateComment();
        return false;
      }
      this.createPull(this.fileUploader);
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= 6) {
        return text;
      } else return text.substr(0, 6) + "..." + extension[extension.length - 1];
    },
    checkValidation() {
      if (!this.editContent && !this.files.length && !this.fileUploader.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите текст комментария или добавьте файл!`
        });
        this.loading = false;
        return false;
      }
      // Проверка общего размера пула файлов
      if (this.files.length) {
        let sumSize = 0;
        this.files.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 52428800) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 50мб!`
          });
          this.loading = false;
          return false;
        }
      }

      return true;
    },
    hideBigText() {
      if (this.contentHeight > 70) {
        this.bigTextTrigger = true;
      }
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    postLike() {
      if (this.isUserLike) {
        this.removeLike(this.isUserLike.id)
          .then(() => {
            this.isUserLike = null;
            this.countLike = this.countLike - 1;
          })
          .catch((error) => this.errorAlert(error));
      } else {
        this.like({ type: "comments", id: this.comment.id })
          .then((response) => {
            this.countLike = this.countLike + 1;
            this.isUserLike = response.data.data;
          })
          .catch((error) => this.errorAlert(error));
      }
    },
    deleteComment() {
      this.deleteCommentAction(this.comment.id).then(() => {
        this.$emit("updateCommentsList");
      });
    },
    getHeight(element) {
      return element.offsetHeight;
    }
  },
  computed: {
    ...mapState("NewsModule", ["currentEditableComment", "currentReplyableComment"]),
    hoursBeforeComment() {
      return (
        new Date().getTime() / 3600000 -
          format(parseISO(this.comment.datetime_of_creation), "T") / 3600000 <
        1
      );
    },
    isCurrentUserLike() {
      if (this.comment.likes.length) {
        return this.comment.likes.some((item) => item.user_id === this.getDataInfoUser.id);
      } else {
        return false;
      }
    },
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    },
    isCurrentLikeId() {
      if (this.comment.likes.length) {
        return this.comment.likes.find((item) => item.user_id === this.getDataInfoUser.id);
      } else {
        return false;
      }
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isShowCross() {
      return (
        (this.admin && !this.comment.is_deleted) ||
        (this.comment.author.id === this.getDataInfoUser.id && !this.comment.is_deleted)
      );
    },
    isCommentEdit() {
      return (
        !this.editCommentMode &&
        !this.comment.is_deleted &&
        !this.globalCommentEditMode &&
        (this.comment.author.id === this.getDataInfoUser.id || this.admin)
      );
    },
    showTrigger() {
      return this.contentHeight > 60;
    },
    setDateAndTime() {
      return `${this.setDateFormatWithoutTime(this.comment.datetime_of_creation)}
              ${this.setTimeFormatWithoutDate(this.comment.datetime_of_creation)}`;
    },
    getImgFiles() {
      return this.comment.attachment_files.filter(
        (file) =>
          file.extension === "image/jpeg" ||
          file.extension === "image/jpg" ||
          file.extension === "image/png"
      );
    },
    getDocumentsFiles() {
      return this.comment.attachment_files.filter(
        (file) =>
          file.extension !== "image/jpeg" &&
          file.extension !== "image/jpg" &&
          file.extension !== "image/png"
      );
    }
  },
  watch: {
    currentEditableComment(val) {
      if (!val) {
        this.$emit("closeGlobalCommentEditMode");
        this.editCommentMode = false;
        // this.replyMode = false;
      }
      if (this.comment.id !== val.comment.id) {
        this.editCommentMode = false;
        this.replyMode = false;
      }
    },
    currentReplyableComment(val) {
      if (!val) {
        this.replyMode = false;
        this.editCommentMode = false;
      }
      if (this.comment.id !== val.comment.id) {
        this.replyMode = false;
        this.editCommentMode = false;
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.comment {
  display: flex;
  flex-flow: column;
  padding-top: 0;
  position: relative;
  &:first-of-type {
    padding-top: 20px;
    @media screen and (max-width: $xs) {
      padding-top: 8px;
    }
  }
  &:hover {
    .comment__reply,
    .comment__edit {
      display: flex;
      align-items: center;
      //color: $text-disabled;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    width: 100%;
    /deep/ {
      .user__name {
        font-weight: 500;
        max-width: 200px;
        width: max-content;
      }

      .user__description {
        max-width: 180px;
        width: max-content;
      }
    }
    .button-show-comments {
      font-size: 12px;
      cursor: pointer;
      color: $link-default;
      margin-left: auto;
    }
  }
  &__date {
    font-size: 12px;
    color: $text-disabled;
    margin-left: 8px;
  }
  &__content-wrap {
    width: 90%;
    padding-right: 5px;
    .comment__text {
      @media (max-width: 895px) {
        margin-left: 0;
      }
      @media (max-width: $md) {
        margin-left: 8px;
      }

      @media (max-width: $xs) {
        margin-left: 0;
        font-size: 14px;
      }
    }
    .comment__plain-button {
      @media (max-width: 895px) {
        padding-left: 0;
      }
    }
  }
  &__text {
    margin: 0 0 0 52px;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #343748;
    white-space: pre-wrap;
    @media screen and (max-width: $xs) {
      font-size: 10px;
      line-height: 1.5;
      margin-left: 54px;
    }
    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }
  &__wrap {
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    @media screen and (max-width: $lg) {
      /deep/ {
        .news-footer__likes-member {
          left: auto;
          right: 0;
          &::after {
            right: 15px;
            left: auto;
          }
        }
      }
    }

    @media screen and (max-width: $xs) {
      margin-top: 5px;
    }
  }
  &__plain-button {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    padding-left: 52px;
  }
  &__deleted {
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin: 0 0 0 52px;
    color: $text-disabled;
  }
  &__footer {
    height: 30px;
    display: flex;
    align-self: start;
    margin-left: auto;
    @media (max-width: 895px) {
      margin-left: 0;
    }
    @media (max-width: $md) {
      margin-left: auto;
    }
    @media (max-width: $xs) {
      margin-left: 0;
    }
    p {
      font-size: 12px;
      color: #5551f9;
    }
    i {
      margin-right: 2px;
    }
  }
  &__reply,
  &__edit {
    display: none;
    padding: 2px 5px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    line-height: 16px;
    @media screen and (max-width: $xs) {
      font-size: 10px;
    }
    &:active {
      background-color: #f2f2f7;
    }
    &:focus {
      outline: 2px solid $focused-default;
    }
  }
  &__file-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-flow: row;
    margin: 0;
    list-style: none;
    padding: 5px 0 16px 56px;
  }
  &__wrap-footer {
    margin-left: 52px;
    @media (max-width: 895px) {
      margin-left: 0;
    }
    @media (max-width: $md) {
      margin-left: 52px;
    }
    @media (max-width: $xs) {
      margin-left: 0;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
    }
    /deep/ {
      .user__avatar {
        width: 32px !important;
        height: 32px !important;
      }

      .comment__text {
        max-width: 345px;
        margin-left: 42px;
        @media (max-width: 895px) {
          margin-left: 0;
        }
        @media (max-width: $md) {
          margin-left: 42px;
        }
        @media (max-width: $xs) {
          margin-left: 0;
        }
      }
    }
  }
  /deep/ {
    .user {
      &__info {
        display: flex;
        flex-direction: row;
        @media (max-width: $xl) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__main-info {
        @media (max-width: 895px) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media (max-width: $md) {
          flex-direction: row;
          align-items: center;
        }

        @media (max-width: $xs) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__more-info {
        margin-left: 8px;
        @media (max-width: $xl) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
