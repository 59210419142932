



































import { Component, Prop, Vue } from "vue-property-decorator";
import User from "@/components/Widgets/User/User";
import { secToTime } from "@/modules/TimeTracker/helpers/timeFormat";
import moment from "moment";

@Component({
  components: { User }
})
export default class TimeContent extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: any;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: any;
  @Prop({
    default: moment(new Date()).format("YYYY-MM"),
    type: String
  })
  month!: string;

  openUserMonthTime() {
    this.$emit("showMonthStat", { user_id: this.row.user_id, month: this.month });
  }

  get user() {
    return this.$store.getters["UsersModule/userById"](this.row.user_id)[0];
  }

  get role() {
    return this.user ? this.user.roles[0].name : "-";
  }

  get normalTime() {
    return secToTime(this.row.current_standard_time);
  }

  get trackedTime() {
    return secToTime(this.row.total_tracked_time);
  }

  get rateTime() {
    return secToTime(this.row.rate_1);
  }

  get rate1_5Time() {
    return secToTime(this.row.rate_1_5);
  }

  get rate2Time() {
    return secToTime(this.row.rate_2);
  }

  get absence() {
    return this.row.absence_in_hours !== 0 ? secToTime(this.row.absence_in_hours) : "-";
  }

  get sick() {
    return this.row.sick_in_hours !== 0 ? secToTime(this.row.sick_in_hours) : "-";
  }

  get differenceTime() {
    return secToTime(Math.abs(this.row.difference_time));
  }

  get isLessWork() {
    return this.row.difference_time < 0;
  }
}
