<template>
  <div class="check-list">
    <ul class="check-list__list">
      <CheckListItem
        v-for="(item, index) in list"
        :item="item"
        :key="`item_${index}`"
        :showAccept="index === list.length - 1 && showAccept"
        :isEditMode="isEditMode"
        :isEditRoles="isEditRoles"
        @toggleShowForm="toggleShowForm"
        @handleAccept="handleAccept"
      />
    </ul>
    <CheckListForm
      v-if="showCheckListForm"
      @handleCancel="toggleShowForm"
      @handleAccept="handleAccept"
    />
  </div>
</template>

<script>
import CheckListItem from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckListItem";
import CheckListForm from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckListForm";

export default {
  name: "CheckList",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showCheckListForm: {
      type: Boolean,
      default: false
    },
    showAccept: {
      type: Boolean,
      default: false
    },
    isEditRoles: {
      type: Boolean,
      default: false
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  components: { CheckListForm, CheckListItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleShowForm(val) {
      this.$emit("toggleShowCheckListForm", val);
    },
    handleAccept(val) {
      this.$emit("handleAccept", val);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.check-list {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__empty {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #343748;
  }
}
</style>
