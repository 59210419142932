import httpClientV2 from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import httpClient from "@/api/client/httpClient";

class WorkCalendarService extends Service {
  getUserMonthWorkTime(params: { month: string; user_id: number }): Response<any> {
    return httpClientV2
      .get<any>("/timemanager/working_time/timetable_by_month", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  getWorktable(params: {
    month: string;
    per_page: number;
    page: number;
    users?: Array<number>;
  }): Response<any> {
    return httpClientV2.get<any>("timemanager/working_time/table", { params }).then((resp) => {
      return resp.data;
    });
  }

  getCalendar(params: { month: string }): Response<any> {
    return httpClientV2.get<any>("timemanager/calendars/by_month", { params }).then((resp) => {
      return resp.data;
    });
  }

  getCurrentUserStat(params: { month: string; user_id: number }): Response<any> {
    return httpClientV2
      .get<any>("timemanager/working_time/for_profile", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  editDay(data: { date: string; hours: number }): Response<any> {
    return httpClientV2.patch<any>("timemanager/calendars/set_hours", data).then((resp) => {
      return resp.data;
    });
  }

  editMonolitDay(data: { date: string; count_hours: number; id: number }): Response<any> {
    return httpClient.put<any>(`WorkingCalendar/update/${data.id}`, [data]).then((resp) => {
      return resp.data;
    });
  }
}

export default new WorkCalendarService();
