<template>
  <div
    class="checkbox-bg"
    :class="{ 'checkbox-bg__active': value, rounded: rounded, 'checkbox-bg_disabled': !isEdit }"
    @click="toggleCheckbox"
    :disabled="!isEdit"
  >
    <svg
      v-if="value"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.31476 7.858L0.132954 4.441C-0.044318 4.253 -0.044318 3.947 0.132954 3.757L0.775678 3.074C0.95295 2.886 1.24113 2.886 1.4184 3.074L3.63657 5.466L8.5811 0.141C8.75837 -0.047 9.04655 -0.047 9.22382 0.141L9.86655 0.825C10.0438 1.013 10.0438 1.32 9.86655 1.507L3.95748 7.858C3.78021 8.046 3.49203 8.046 3.31476 7.858Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "CheckboxISP",
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    rounded: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    errorMessage: {
      type: String,
      default: ""
    },
    isComplete: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleCheckbox() {
      if (this.isEdit) {
        if (this.isComplete) {
          this.$emit("input", !this.value);
        } else {
          NotificationObserver.notification({
            type: "error",
            message: this.errorMessage
          });
        }
      } else {
        this.$emit("fillReport");
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.checkbox-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 2px solid #d3d2e0;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  &__active {
    background: #6a67ce;
    border: 2px solid #6a67ce !important;
  }
  &:hover {
    border: 2px solid #6a67ce;
  }
  &_disabled {
    cursor: default;
    &:hover {
      border-color: #d3d2e0;
    }
  }
}
.rounded {
  border-radius: 50%;
}
</style>
