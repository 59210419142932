export const UiKitRoutes = [
  {
    path: "/ui",
    name: "UIMain",
    component: () => import(/* webpackChunkName: "UIMain" */ "@/modules/UiKit/UIKitLayout"),
    redirect: "ui/components",
    meta: { title: "UIMain", requiresAuth: false },
    children: [
      {
        path: "guide",
        name: "UIGuide",
        component: () =>
          import(/* webpackChunkName: "UIGuide" */ "@/modules/UiKit/pages/GuidePage"),
        meta: { title: "UIGuide", name: "Гайд" }
      },
      {
        path: "components",
        name: "UIComponents",
        component: () =>
          import(
            /* webpackChunkName: "UIComponents" */ "@/modules/UiKit/pages/ComponentsPage/ComponentsPage"
          ),
        meta: { title: "UIComponents", name: "Компоненты" },
        redirect: "components/guide",
        children: [
          {
            path: "guide",
            name: "UIComponentsGuide",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/AopGuide/AopGuide"
              ),
            meta: { title: "UIComponentsGuide", name: "Как использовать" }
          },
          {
            path: "Input",
            name: "UIComponentsInput",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/InputPage/InputPage"
              ),
            meta: { title: "UIComponentsInput", name: "Inputs" }
          },
          {
            path: "InputRange",
            name: "UIComponentsInputRange",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/InputRangePage/InputRangePage"
              ),
            meta: { title: "UIComponentsInputRange", name: "InputRange" }
          },
          {
            path: "Files",
            name: "Files",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/FilesPage/FilesPage"
              ),
            meta: { title: "Files", name: "Files" }
          },
          {
            path: "Skeleton",
            name: "Skeleton",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/SkeletonPage/SkeletonPage"
              ),
            meta: { title: "Skeleton", name: "Skeleton" }
          },
          {
            path: "ModalConstructor",
            name: "ModalConstructor",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ModalPage/ModalPage"
              ),
            meta: { title: "ModalConstructor", name: "ModalConstructor" }
          },
          {
            path: "ActionList",
            name: "UIComponentsActionList",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ActionListPage/ActionListPage"
              ),
            meta: { title: "UIComponentsActionList", name: "ActionList" }
          },
          {
            path: "ChosenFilter",
            name: "UIComponentsChosenFilter",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ChosenFilterPage/ChosenFilterPage"
              ),
            meta: { title: "UIComponentsChosenFilter", name: "ChosenFilter" }
          },
          {
            path: "tabs",
            name: "UIComponentsTabs",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TabsPage/TabsPage"
              ),
            meta: { title: "UIComponentsTabs", name: "Tabs" },
            children: [
              {
                path: "1",
                name: "UIComponentsTabs1",
                component: () =>
                  import(
                    /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TabsPage/TabsPage"
                  ),
                meta: { title: "UIComponentsGuide", name: "Как использовать" }
              },
              {
                path: "2",
                name: "UIComponentsTabs2",
                component: () =>
                  import(
                    /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TabsPage/TabsPage"
                  ),
                meta: { title: "UIComponentsGuide", name: "Как использовать" }
              },
              {
                path: "3",
                name: "UIComponentsTabs3",
                component: () =>
                  import(
                    /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TabsPage/TabsPage"
                  ),
                meta: { title: "UIComponentsGuide", name: "Как использовать" }
              }
            ]
          },
          {
            path: "ProgressBar",
            name: "UIComponentsProgressBar",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ProgressBarPage/ProgressBarPage"
              ),
            meta: { title: "UIComponentsProgressBar", name: "ProgressBar" }
          },
          {
            path: "Buttons",
            name: "UIComponentsButtons",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ButtonPage/ButtonPage"
              ),
            meta: { title: "UIComponentsButtons", name: "Buttons" }
          },
          {
            path: "chips",
            name: "UIComponentsChips",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/StatusesPage/StatusesPage"
              ),
            meta: { title: "UIComponentsGuide", name: "Statuses (Статусы)" }
          },
          {
            path: "select",
            name: "UIComponentsSelect",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/SelectPage/SelectPage"
              ),
            meta: { title: "UIComponentsSelect", name: "Select(Списки выбора)" }
          },
          {
            path: "checkbox",
            name: "UIComponentsCheckBox",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/CheckboxPage/CheckboxPage"
              ),
            meta: { title: "UIComponentsCheckBox", name: "Checkbox" }
          },
          {
            path: "Switch",
            name: "UIComponentsSwitch",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/SwitchPage/SwitchPage"
              ),
            meta: { title: "UIComponentsSwitch", name: "Switch" }
          },
          {
            path: "DateRangePicker",
            name: "UIComponentsDateRangePicker",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/DateRangePickerPage/DateRangePickerPage"
              ),
            meta: { title: "UIComponentsDateRangePicker", name: "Date Range Picker" }
          },
          {
            path: "TimePicker",
            name: "UIComponentsTimePicker",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TimePickerPage/TimePickerPage"
              ),
            meta: { title: "UIComponentsTimePicker", name: "Time Picker" }
          },
          {
            path: "Spinner",
            name: "UIComponentsSpinner",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/SpinnerPage/SpinnerPage"
              ),
            meta: { title: "UIComponentsSpinner", name: "Spinner" }
          },
          {
            path: "dropdown",
            name: "UIComponentsDropDown",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/DropDownPage/DropDownPage"
              ),
            meta: { title: "UIComponentsDropDown", name: "DropDown" }
          },
          {
            path: "datepicker",
            name: "UIComponentsDatePicker",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/DatePickerPage/DatePickerPage"
              ),
            meta: { title: "UIComponentsDatePicker", name: "DatePicker" }
          },
          {
            path: "types",
            name: "Types",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/TypeTaskPage/TypeTaskPage"
              ),
            meta: { title: "Types", name: "TypeTaskPage" }
          },
          {
            path: "ConfirmModal",
            name: "UIComponentsConfirmModal",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ConfirmModalPage/ConfirmModalPage"
              ),
            meta: { title: "UIComponentsConfirmModal", name: "Confirm Modal" }
          },
          {
            path: "ContextMenu",
            name: "UIComponentsContextMenu",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/ContextMenuPage/ContextMenuPage"
              ),
            meta: { title: "UIComponentsContextMenu", name: "Context Menu" }
          },
          {
            path: "Comments",
            name: "UIComponentsComments",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/CommentsPage/CommentsPage"
              ),
            meta: { title: "UIComponentsComments", name: "Comments" }
          },
          {
            path: "Tooltip",
            name: "UIComponentsTooltip",
            component: () =>
              import(
                /* webpackChunkName */ "@/modules/UiKit/pages/ComponentsPage/Components/Tooltip/TooltipPage"
              ),
            meta: { title: "UIComponentsTooltip", name: "Tooltip" }
          }
        ]
      },
      {
        path: "colors",
        name: "UIColors",
        component: () =>
          import(/* webpackChunkName: "UIColors" */ "@/modules/UiKit/pages/ColorsPage/ColorsPage"),
        meta: { title: "UIColors", name: "Цвета" }
      },
      {
        path: "typography",
        name: "UITypography",
        component: () =>
          import(
            /* webpackChunkName: "UITypography" */ "@/modules/UiKit/pages/TypographyPage/TypographyPage"
          ),
        meta: { title: "UITypography", name: "Типография" }
      },
      {
        path: "icons",
        name: "UIIcon",
        component: () =>
          import(/* webpackChunkName: "UIIcon" */ "@/modules/UiKit/pages/IconPage/IconPage"),
        meta: { title: "UIIcon", name: "Иконки" }
      }
    ]
  }
];
