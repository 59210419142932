<template>
  <div class="task-files" ref="modal" @click.self="handleClose">
    <div class="task-files__modal">
      <div class="task-files__header">
        <h3 class="task-files__title">Загрузить</h3>
        <i class="icon-cross" @click="handleClose"></i>
      </div>
      <div class="task-files__body">
        <ul class="task-files__list task-files__list_image">
          <li class="task-files__item" v-for="(item, index) in imageList" :key="item.name + index">
            <FileItem :file="item" :index="index" :listLength="imageList.length" />
          </li>
        </ul>
        <ul class="task-files__list">
          <li
            class="task-files__item task-files__item_file"
            v-for="(item, index) in fileList"
            :key="item.name + index"
          >
            <FileItem :file="item" :index="index" :currentTask="currentTask" />
          </li>
        </ul>
      </div>
      <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
      <div class="task-files__footer">
        <file-upload
          :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
          input-id="file2"
          key="modal"
          v-model="files"
          post-action="/upload/post"
          :multiple="true"
          :drop="true"
          :drop-directory="true"
          ref="upload"
          @input="handleUploadFile"
          v-if="totalNumberFiles < 10"
        >
          <ModalButton :title="'Добавить еще'" :type="'cancel'" />
        </file-upload>
        <ModalButton
          v-if="paramsFileUpload === 'upload'"
          @handleButton="checkFileUpload"
          :title="'Загрузить'"
          :style="`margin-left:auto`"
        />
        <ModalButton
          v-if="paramsFileUpload === 'add'"
          @handleButton="addFilesForUpload"
          :title="'Загрузить'"
          :style="`margin-left:auto`"
        />
      </div>
      <div class="task-files__loader" v-if="loaderFiles">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FileItem from "@/modules/TaskManager/components/Modal/TaskFileModal/FileItem";
import FileUpload from "vue-upload-component";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import taskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import uploadFilesTaskManager from "@/helpers/Files/uploadFilesTaskManager.mixin";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import { EventBus } from "@/main";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
export default {
  name: "TaskFileModal",
  mixins: [uploadFilesTaskManager],
  props: {},
  components: {
    Spinner,
    DropFileUpload,
    ModalButton,
    FileUpload,
    FileItem
  },
  data() {
    return {
      fileTypes: [
        "image/apng",
        "image/avif",
        "image/gif",
        "image/png",
        "image/svg+xml",
        "image/jpeg"
      ],
      whiteListFiles: [
        "image/png",
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/pdf",
        "application/pdf",
        "audio/mpeg",
        "video/mpeg",
        "video/mp4",
        "video/3gpp",
        "video/quicktime",
        "video/mpeg",
        "video/x-flv",
        "video/x-ms-wmv",
        "video/x-msvideo"
      ],
      valueEditor: "",
      imageList: [],
      linkMatch:
        /https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}\b([-a-zA-Zа-яА-Я0-9()!@:%_+.~#?&//=]*)/gi,
      fileList: [],
      loaderFiles: false,
      files: []
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyEvent);
    this.sortFiles(this.getFilesFromState);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyEvent);
    document.querySelector("#textEditor").focus();
  },
  methods: {
    ...mapActions("MessengerModule", [
      "closeFileModal",
      "setPasteFile",
      "setEditorValue",
      "sendFirstMessage",
      "sendMessage"
    ]),
    handleClose() {
      taskModule.closeModalFileUpload();
    },
    handleKeyEvent(event) {
      if (event.code === "Escape") {
        this.handleClose();
      }
    },
    filesUploaded() {
      if (this.currentTask) {
        if (this.fileUploadingId.length) {
          TaskModule.updateCurrentTaskAction({
            id: this.currentTask.id,
            files: this.fileUploadingId
          });
        }

        setTimeout(() => {
          TaskModule.getFilesListAction({
            task_id: this.currentTask.id,
            per_page: 100
          });
        }, 100);
      } else {
        if (this.fileUploadingId.length) {
          ProjectModule.updateProjectAction({
            id: this.currentProject.id,
            files: this.fileUploadingId
          });
        }

        setTimeout(() => {
          ProjectModule.getProjectFilesAction(this.currentProject.id);
        }, 100);
      }

      taskModule.closeModalFileUpload();
      this.loaderFiles = false;
    },
    checkFileUpload() {
      this.loaderFiles = true;
      this.uploadFile(this.getFilesFromState);
    },
    addFilesForUpload() {
      let size = 0;
      let error = false;
      this.getFilesModalFromState.filter((item) => {
        if (item.size > 10485760) {
          EventBus.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Размер файла ${item.name} превышает 10 мб`
          });
          error = true;
          return false;
        }
        size += item.size;
        if (item.extension) {
          return true;
        }
        if (item.file.type || item.file.type === "") {
          if (
            this.formats.includes(item.file.type.toLowerCase()) ||
            this.formats.includes(item.type.toLowerCase())
          ) {
            return true;
          } else {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Формат файла ${item.file.type} не поддерживается`
            });
            error = true;

            return false;
          }
        }
        return false;
      });
      if (size > 52428800) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Общий размер файлов превышает 50 мб`
        });
        error = true;
      }
      if (!error) {
        TaskModule[TaskMutationTypes.SET_FILE_UPLOAD](this.getFilesModalFromState);
        TaskModule[TaskMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](false);
        TaskModule[TaskMutationTypes.CLEAR_FILE_UPLOAD_MODAL]();
      }
    },
    handleUploadFile(files) {
      TaskModule[TaskMutationTypes.SET_FILE_UPLOAD_MODAL](files);
      TaskModule[TaskMutationTypes.CLEAR_FILE_UPLOAD]();
      this.files = [];
    },
    isImage(file) {
      return this.fileTypes.includes(file.type);
    },
    sortFiles(fileList) {
      this.imageList = [];
      this.fileList = [];
      fileList.forEach((item) => {
        if (item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png") {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = (e) => {
            this.imageList.push({
              link: reader.result,
              name: item.name,
              id: item.id,
              size: item.size,
              ...item
            });
          };
        } else {
          this.fileList.push(item);
        }
      });
    }
  },
  computed: {
    getFilesFromState() {
      return taskModule.filesUploadModal;
    },
    getFilesModalFromState() {
      return taskModule.filesUploadModal;
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    currentProject() {
      return ProjectModule.currentProject.projectFull;
    },
    paramsFileUpload() {
      return taskModule.paramsFilesUpload;
    },
    totalNumberFiles() {
      const countFilesUploadCurrentTask = taskModule.filesUploadCurrentTask
        ? taskModule.filesUploadCurrentTask.length
        : 0;
      const countFilesList =
        this.paramsFileUpload === "add"
          ? 0
          : taskModule.filesList.length
          ? taskModule.filesList.length
          : ProjectModule.filesList.length
          ? ProjectModule.filesList.length
          : 0;
      const countFilesUploadModal = taskModule.filesUploadModal
        ? taskModule.filesUploadModal.length
        : 0;
      return countFilesUploadCurrentTask + countFilesList + countFilesUploadModal;
    }
  },
  watch: {
    getFilesFromState: function (fileList) {
      if (!fileList.length) {
        taskModule.closeModalFileUpload();
      }
      this.sortFiles(fileList);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.task-files {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $background-modal;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    max-width: 430px;
    max-height: 545px;
    position: relative;

    @media screen and (max-width: $xxs) {
      max-height: 90vh;
      margin-top: auto;
      border-radius: 4px 4px 0 0;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: 100vh;
      height: 100vh;
      max-width: 100%;
      width: 100%;
      margin-top: 0;
      padding: 8px 24px;
      overflow-y: auto;
      border-radius: 0;
    }

    @media screen and (max-width: $sm) {
      max-height: 100vh;
      height: 100vh;
      max-width: 100%;
      width: 100%;
      margin-top: 0;
      padding: 8px 24px;
      overflow-y: auto;
      border-radius: 0;
    }

    /deep/ {
      .drop-active {
        top: 0;
        left: 0;
      }
      .file-image__image {
        @media screen and (max-width: $xs) {
          max-width: 100%;
        }
      }
    }
  }

  &__body {
    position: relative;

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: 250px;
      height: 100%;
      overflow-y: scroll;
    }

    @media screen and (max-width: $sm) {
      max-height: 650px;
      height: 100%;
      overflow-y: scroll;
    }

    @media screen and (max-width: $xxs) {
      max-height: 450px;
      height: 100%;
      overflow-y: scroll;
    }

    @media screen and (max-width: $sm) and (orientation: landscape) {
      max-height: 205px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    position: sticky;

    @media screen and (max-width: $sm) {
      margin-bottom: 8px;
    }
    .icon-cross {
      font-size: 16px;
      color: $icon-subdued;
      cursor: pointer;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on;
    color: #000000;

    @media screen and (max-width: $sm) {
      font-size: 16px;
    }
  }

  &__editor {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  &__text {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
  }

  &__list {
    all: unset;
    list-style: none;
    display: flex;
    max-width: 490px;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    max-height: 130px;
    margin-bottom: 8px;

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: max-content;
      height: max-content;
      max-width: 100%;
      width: 100%;
    }

    @media screen and (max-width: $sm) {
      max-height: max-content;
      height: max-content;
      max-width: 100%;
      width: 100%;
    }

    &_image {
      margin-left: auto;
      margin-right: auto;
      max-height: 240px;
      margin-bottom: 8px;

      @media screen and (max-width: 850px) and (orientation: landscape) {
        max-width: 100%;
        max-height: max-content;
        height: max-content;
        width: 100%;
        justify-content: flex-start;
      }

      @media screen and (max-width: $sm) {
        max-width: 100%;
        max-height: max-content;
        height: max-content;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
  &__item {
    @media screen and (max-width: 850px) and (orientation: landscape) {
      width: 30%;
      margin-right: 8px;
    }

    @media screen and (max-width: $sm) {
      width: 30%;
      margin-right: 8px;
    }

    &_file {
      width: 100%;
      margin-bottom: 8px;

      @media screen and (max-width: 850px) and (orientation: landscape) {
        justify-content: flex-start;
      }

      @media screen and (max-width: $sm) {
        justify-content: flex-start;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    /deep/.file-item {
      width: 100%;
    }
    .file-image__image {
      height: 80px;
    }
  }
  &__footer {
    position: relative;
    border-top: 1px solid $border-default;
    padding-top: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /deep/ {
      label {
        cursor: pointer;
      }
      .default-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
        &_cancel {
          color: #343748 !important;
          background: none !important;
          cursor: pointer;
        }
      }
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.6);
  }
}
</style>
