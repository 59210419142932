import * as types from "@/modules/TaskManager/store/mutations/mutationTypes";

const mutations = {
  TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE(state, payload) {
    state.showCurtainShowAndEditStage = payload;
  },
  //закомментировано по причине переноса state
  // в TaskManagerModuleV2.ts (если ничего не
  // сломается - удалить)

  // TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_TASK(state, payload) {
  //   state.showCurtainShowAndEditTask = payload;
  // },
  SET_CURRENT_STAGE(state, payload) {
    state.currentStage = payload;
  },
  [types.TOGGLE_SHOW_SIDEBAR](state, payload) {
    state.showSidebar = payload;
  }
};

export default mutations;
