<template>
  <div class="news-content">
    <h4 class="news-content__title" @click="toCertainNews" @click.middle="inAnotherTab">
      {{ news.name }}
    </h4>
    <NewsImageGrid :images="images" />
    <div class="news-content__wrap" v-if="news.content">
      <p
        class="news-content__text"
        ref="content"
        :style="
          bigTextTrigger
            ? `display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;`
            : ''
        "
        v-html="setURL(`<span>${news.content}</span>`)"
      ></p>
      <span v-if="showTrigger">
        <span
          class="news-content__plain-button"
          @click="showBigText(news.content)"
          v-if="bigTextTrigger"
        >
          <span>Показать полностью</span>
        </span>
        <span class="news-content__plain-button" @click="hideBigText" v-if="!bigTextTrigger">
          <span>Свернуть</span>
        </span>
      </span>
    </div>
    <QuizComponent v-if="news.poll_id" :quiz="quiz" @updateParentQuiz="getQuiz" />
    <FilesComponent v-if="otherFiles.length" :files="otherFiles" :news="news" />
  </div>
</template>

<script>
import NewsImageGrid from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsImageGrid";
import QuizComponent from "@/modules/News/components/NewsItemsComponents/QuizComponent";
import FilesComponent from "@/modules/News/components/NewsItemsComponents/FilesComponent";
import axios from "axios";
import { domain } from "@/globalVariables";
import UrlMixin from "@/helpers/UrlMixin";
import { mapActions, mapState } from "vuex";
export default {
  name: "NewsContent",
  mixins: [UrlMixin],
  props: {
    news: {
      type: Object,
      default: () => {}
    },
    quiz: {
      type: Array,
      default: () => {}
    }
  },
  components: { FilesComponent, QuizComponent, NewsImageGrid },
  data() {
    return {
      images: [],
      otherFiles: [],
      bigTextTrigger: true,
      contentHeight: 0
    };
  },
  mounted() {
    this.setFilesArrays();
    this.hideBigText(this.news.content);
    this.contentHeight = this.$refs.content.children[0].offsetHeight;
    if (this.isCertainNews) {
      this.bigTextTrigger = false;
    }
  },
  methods: {
    toCertainNews() {
      this.$router.push({
        name: "News",
        query: {
          news: this.news.id
        }
      });
    },
    inAnotherTab() {
      let route = this.$router.resolve({
        name: "News",
        query: {
          news: this.news.id
        }
      });
      window.open(route.href, "_blank");
    },
    setFilesArrays() {
      this.images = [];
      if (this.news.attachment_files.length) {
        this.news.attachment_files.forEach((item) => {
          if (item.extension === "image/jpeg" || item.extension === "image/png") {
            this.images.push(item);
          } else {
            this.otherFiles.push(item);
          }
        });
      }
    },
    getQuiz() {
      this.$emit("updateQuiz", this.news.id);
    },
    hideBigText() {
      if (this.contentHeight > 80) {
        this.bigTextTrigger = true;
      }
    },
    showBigText() {
      this.bigTextTrigger = false;
    }
  },
  computed: {
    ...mapState("NewsModule", ["isCertainNews"]),
    showTrigger() {
      return this.contentHeight > 80 && !this.isCertainNews;
    }
  },
  watch: {
    isCertainNews(val) {
      if (val === true) this.bigTextTrigger = false;
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.news-content {
  font-family: "SF", sans-serif;
  color: $text-default;
  h4 {
    cursor: pointer;
  }
  &__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 16px;
    word-break: break-word;
    @media screen and (max-width: $md) {
      font-size: 21px;
      line-height: 24px;
    }
    @media screen and (max-width: $xs) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    word-break: break-word;
    white-space: pre-line;
    @media screen and (max-width: $md) {
      font-size: 13px;
      line-height: 20px;
    }
    @media screen and (max-width: $xs) {
      font-size: 12px;
      line-height: 1.5;
    }

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }
  &__wrap {
    margin: 0 0 18px;
    @media screen and (max-width: $md) {
      margin-bottom: 26px;
    }
    @media screen and (max-width: $xs) {
      margin-bottom: 4px;
    }
  }
  &__plain-button {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    @media screen and (max-width: $md) {
      font-size: 13px;
    }
    @media screen and (max-width: $xs) {
      font-size: 12px;
    }
    span {
      padding: 2px 5px 2px 0;
    }
  }
}
</style>
