<template>
  <div
    class="members"
    :style="{ width: editMode ? '100%' : 'fit-content' }"
    v-click-outside="hideEditMode"
  >
    <div
      class="members__content"
      @click="setEdit"
      v-show="!editMode"
      :class="{ 'members__content-edit': isEdit }"
    >
      <div class="members__user-empty" v-if="!users.length">-</div>
      <div class="members__user" v-for="user in users" :key="user.id">
        <img
          class="members__avatar"
          v-if="user.avatar"
          :src="returnSrcFile(user.avatar)"
          alt="avatar"
        />
      </div>
      <i class="icon-edit"></i>
    </div>
    <Select
      :maxCount="5"
      v-if="editMode"
      :showAll="false"
      v-model="selected"
      :items="usersList"
      :placeholder="'Выберите сотрудника'"
    />
  </div>
</template>

<script>
import Label from "@/modules/Isp/components/IspProblem/Label";
import { domain, domainStorage } from "@/globalVariables";
import Select from "@/modules/UiKit/components/Select/Select";
import ClickOutside from "vue-click-outside";

import { EventBus } from "@/main";
import { mapState } from "vuex";

export default {
  name: "TaskExecutorsComponent",
  mixins: [],
  props: {
    users: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: { Select },
  data() {
    return {
      editMode: false,
      selected: []
    };
  },
  created() {
    this.selected = this.users;
  },
  mounted() {},
  methods: {
    setEdit() {
      if (this.isEdit) this.editMode = true;
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    hideEditMode() {
      this.editMode = false;
      if (JSON.stringify(this.selected) !== JSON.stringify(this.users)) {
        let selected = [];
        let users = [];
        if (this.selected.length) {
          selected = this.selected.map((item) => item.id);
          users = this.selected;
        }
        this.$emit("editExecutors", { ids: selected, users });
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["usersList"])
  },
  watch: {
    users() {
      this.selected = this.users;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.members {
  display: flex;
  flex-direction: column;
  user-select: none;

  &__content {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 18px;
    border-radius: 4px;
    margin: 0 0 0 8px;

    i {
      visibility: hidden;
      color: #a7a9c0;
      margin-left: 8px;
    }
  }
  &__content-edit {
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #fafafe;
      i {
        visibility: visible;
      }
    }
  }
  &__user {
    margin-left: -10px;
  }

  &__user-empty {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fafafe;
  }

  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #eae8f0;
    border: 2px solid #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #0a1417;
    margin-right: 8px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343748;
    word-break: break-word;
    max-width: 100px;
  }

  /deep/ {
    .select-bg {
      margin: 16px 0 0;
    }
  }
}
</style>
