<template>
  <div class="graph-grid">
    <DateInterval v-for="(interval, index) in intervalList" :key="index" :interval="interval" />
  </div>
</template>

<script>
import DateInterval from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/Graph/GraphGrid/DateInterval";
export default {
  name: "GraphGrid",
  mixins: [],
  props: {
    intervalList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { DateInterval },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.graph-grid {
  display: flex;
  height: 100%;
}
</style>
