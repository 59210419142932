<template>
  <div class="dashboard-header">
    <div class="dashboard-header__item">Горящие задачи</div>
    <div class="dashboard-header__item">Просроченные</div>
  </div>
</template>

<script>
export default {
  name: "MyDashboardHeader",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.dashboard-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  padding: 24px 24px 0;
  z-index: 1;
  background: #edf4fe;

  @media (max-width: $xxl) {
    padding: 16px 24px 8px;
  }

  @media (max-width: $md) {
    padding: 8px 24px;
  }

  @media (max-width: 700px) {
    width: max-content;
  }

  &__item {
    width: 100%;
    padding: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $text-default;
    background: $surface-default;
    border: 2px solid $secondary;
    border-radius: 8px;
    margin-right: 8px;

    @media (max-width: 700px) {
      width: 270px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
