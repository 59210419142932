<template>
  <div class="full-screen">
    <div class="background" @click="closeImage"></div>

    <i class="icon-cross" @click="closeImage"></i>
    <div class="full-screen__container">
      <img
        :src="returnSrcFile(imageInfo)"
        alt="avatar"
        class="full-screen__image"
        :style="{ width: `${widthImage}%` }"
      />

      <div class="full-screen__actions actions">
        <div class="actions__action-item" @click="resizeImage('zoom')">
          <i class="icon-circle-plus"></i>
        </div>
        <div class="actions__action-item" @click="resizeImage('unzoom')">
          <i class="icon-circle-minus"></i>
        </div>
        <div class="actions__action-item">
          <a :download="imageInfo.name" :href="returnSrcFile(imageInfo)">
            <i class="icon-download-secondary"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domainS3 } from "@/globalVariables";
import taskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";

export default {
  name: "FullScreenImage",
  mixins: [],
  props: {
    imageInfo: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return { widthImage: 100 };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      if (src.storage === "server") {
        return `${domainS3}/${src.path || src.path_preview}`;
      } else {
        return src.path;
      }
    },
    closeImage() {
      this.$emit("closeImage");
    },
    resizeImage(data) {
      if (data === "zoom" && this.widthImage < 200) {
        this.widthImage = +(this.widthImage + 50).toFixed(1);
      }
      if (data === "unzoom" && this.widthImage > 100) {
        this.widthImage = +(this.widthImage - 50).toFixed(1);
      }
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  &__actions {
    position: absolute;
    display: flex;
    opacity: 0;
    justify-content: space-between;
    padding: 15px 26px;
    background: rgba(52, 55, 72, 0.5);
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 650px;
    z-index: 10;
    @media (hover: hover) {
      &:hover .full-screen__actions {
        display: flex;
        opacity: 100%;
      }
    }
  }
  &__image {
    border-radius: 8px;
  }

  .icon-cross {
    position: absolute;
    top: 5%;
    right: 5%;
    color: $icon-subdued;
    cursor: pointer;
    font-size: 24px;
    transition: all 0.3s ease;
    border-radius: 4px;
    z-index: 10;
    @media (hover: hover) {
      &:hover {
        background-color: $text-accent;
      }
    }
  }
  .actions {
    &__action-item {
      font-size: 20px;
      color: #fff;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
        color: #fff;
      }
    }
  }
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $background-modal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
