// Sort
export const SET_SORT = "SET_SORT";
export const SET_SORT_USERS = "SET_SORT_USERS";
export const RESET_SORT = "RESET_SORT";

export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_TABLE_PARAMS = "SET_TABLE_PARAMS";
export const SET_SORT_BLACK_SPHERE_STATISTICS = "SET_SORT_BLACK_SPHERE_STATISTICS";
export const CLEAR_TABLE_DATA = "CLEAR_TABLE_DATA";
export const RESET_PAGINATION = "RESET_PAGINATION";

// Educations
export const SET_IS_FILTER_ACTIVE_EDUCATION = "SET_IS_FILTER_ACTIVE_EDUCATION";

// Black Sphere Marks
export const ADD_BLACK_SPHERE_MARKS_RELATION = "ADD_BLACK_SPHERE_MARKS_RELATION";

// Time
export const ADD_TIMES_RELATION = "ADD_TIMES_RELATION";
export const SET_TIME_STATISTICS = "SET_TIME_STATISTICS";
export const ADD_DATES_COLUMN_IN_TABLE = "ADD_DATES_COLUMN_IN_TABLE";

//TEST
export const SET_TESTS_LIST = "SET_TESTS_LIST";

// Absence
export const ADD_ABSENCE_RELATION = "ADD_ABSENCE_RELATION";

// Activity
export const ADD_USER_RELATION = "ADD_USER_RELATION";

//Users
export const SET_IS_FILTER_ACTIVE_USERS = "SET_IS_FILTER_ACTIVE_USERS";
export const TOGGLE_SHOW_MODAL_CREATE_USER = "TOGGLE_SHOW_MODAL_CREATE_USER";

//BlackSphereStatistics
export const ADD_BLACK_SPHERE_STATISTICS = "ADD_BLACK_SPHERE_STATISTICS";
