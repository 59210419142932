<template>
  <div class="icon-widget" :style="{ background: background, border: `1px solid ${color}` }">
    <i :class="`icon-${name}`" :style="{ fontSize: `${fontSize}px`, color: color }"></i>
  </div>
</template>

<script>
export default {
  name: "IconWidgets",
  mixins: [],
  props: {
    name: {
      type: String,
      require: true,
      default: () => null
    },
    pathCount: {
      type: [String, Number],
      require: true,
      default: () => 1
    },
    fontSize: {
      type: [String, Number],
      default: () => 14
    },
    color: {
      type: String,
      default: "#343748"
    },
    background: {
      type: String,
      default: "#F2F2F7"
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
.icon-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
}
</style>
