<template>
  <div class="dropdown_bg" v-click-outside="hideModal">
    <div
      @click="showModal = !showModal"
      class="dropdown_bg__selected"
      :class="{ dropdown_bg__selected_active: showModal }"
    >
      <p>{{ value.name || value.full_name }}</p>
      <i class="icon-down" :style="showModal ? 'transform: rotate(180deg)' : ''"></i>
    </div>
    <ul class="dropdown_bg__list" v-if="showModal">
      <li @click="selectItem(item)" class="dropdown_bg__item" v-for="item in items" :key="item.id">
        <p>{{ item.name || item.full_name }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "DropDown",
  mixins: [],
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      showModal: false
    };
  },
  mounted() {},
  methods: {
    selectItem(item) {
      this.$emit("input", item);
      this.hideModal();
    },
    hideModal() {
      this.showModal = false;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.dropdown_bg {
  position: relative;
  width: 110px;
  user-select: none;

  &__selected {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding: 0 8px;
    background: #ffffff;
    border: 2px solid #eae8f0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;

    &_active {
      border: 2px solid #5551f9;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;
      text-transform: uppercase;
      color: #0a1417;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      margin-left: 8px;
      transition: 0.3s;
    }
  }

  &__list {
    position: absolute;
    top: calc(100% - 4px);
    z-index: 5;
    width: 100%;
    user-select: none;
    list-style: none;
    margin: 0;
    padding: 8px 0 0;
    background: #ffffff;
    border: 1px solid #eae8f0;
    border-radius: 4px;
  }
  &__item {
    width: 100%;
    padding: 4px 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #343748;
    &:hover {
      background: $bg-surface-light;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
