<template>
  <div
    class="table-task"
    :id="setId"
    :class="{ 'table-subtask': isSubtask, 'white-bg': isWhiteBg }"
  >
    <details>
      <summary>
        <div class="table-task__wrap" @click="getSubtaskList">
          <div class="table-task__row table-task__row_name cell">
            <i class="icon-down-secondary" v-if="haveSubtasks"></i>
            <p class="table-task__name" @click.prevent="openCurtainTask">
              {{ task.alias }}-{{ task.task_id }} {{ task.name }}
            </p>
          </div>
          <div class="table-task__priority cell">
            <p class="table-task__priority-content" :style="setStylePriority">
              {{ priority.name }}
            </p>
          </div>
          <div class="table-task__type cell">
            <TypeTaskSmall :label="type.name" />
          </div>
          <div class="table-task__deadline cell">
            <p>{{ date_from }} <br />{{ date_to }}</p>
          </div>
          <div class="table-task__author cell">
            <img :src="setURL(author.avatar)" alt="" />
          </div>
          <div class="table-task__responsible cell">
            <span v-if="responsible.length === 0">-</span>
            <img v-else :src="setURL(responsible.avatar)" alt="" />
          </div>
          <div class="table-task__status cell">
            <p class="task-card__status">
              <span>{{ setStatus }}</span>
            </p>
          </div>
        </div>
        <div class="table-task__wrap_adaptive task-card">
          <div class="task-card__header">
            <p class="table-task__priority-content" :style="setStylePriority">
              {{ priority.name }}
            </p>
            <TypeTaskSmall :label="type.name" :icon="type.icon" />
            <ActionsList :action-list="actionList" class="task-card__action-list" />
          </div>
          <div class="task-card__title">
            <i class="icon-down-secondary" v-if="haveSubtasks"></i>
            <p class="table-task__name" @click.prevent="openCurtainTask">{{ task.name }}</p>
          </div>
          <p class="task-card__deadline">Сроки: {{ date_from }} по {{ date_to }}</p>
          <div class="task-card__info">
            <div class="task-card__info-item">
              <p>Автор</p>
              <img :src="setURL(author.avatar)" alt="" />
            </div>
            <div class="task-card__info-item">
              <p>Исполнитель</p>
              <span v-if="responsible.length === 0">-</span>
              <img v-else :src="setURL(responsible.avatar)" alt="" />
            </div>
            <div class="task-card__info-item">
              <p>Статус</p>
              <p class="task-card__status">
                <span>{{ setStatus }}</span>
              </p>
            </div>
          </div>
        </div>
      </summary>
      <div v-if="subtask.length" class="body-row__subtasks">
        <ProjectStagesTableTask
          v-for="item in subTasksList"
          :task="item"
          :parentTaskId="task.id"
          :key="item.id"
          :tasksData="subtask"
          :totalCount="countSub"
        />
        <button class="body-row__all" type="button" v-if="subtask.length > 5" @click="openFullTask">
          Все задачи
        </button>
      </div>
    </details>
  </div>
</template>

<script>
import TypeTaskSmall from "@/modules/UiKit/components/TypeTask/TypeTaskSmall";
import { mapActions, mapGetters } from "vuex";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule.ts";
import moment from "moment";
import { domainStorage } from "@/globalVariables";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import { EventBus } from "@/main";

export default {
  name: "ProjectStagesTableTask",
  mixins: [],
  props: {
    tasksData: {
      type: Array,
      default: () => []
    },
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
    totalCount: {
      type: Number,
      default: 0
    },
    params: {
      type: Object,
      default: () => {}
    },
    isSubtask: {
      type: Boolean,
      default: false
    },
    isWhiteBg: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TypeTaskSmall,
    ActionsList
  },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "arrow-next",
          actionText: this.isSubtask ? "Перейти к подазадаче" : "Перейти к задаче",
          action: () => {},
          actionType: "goToTask"
        },
        {
          isShow: !this.isSubtask,
          iconType: "circle-plus",
          actionText: "Добавить подзадачу",
          action: () => {},
          actionType: "addSubtask"
        },
        {
          isShow: true,
          iconType: "folder-check",
          actionText: "Выполнить",
          action: () => {},
          actionType: "execute"
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          action: () => {},
          actionType: "copyLink"
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          action: () => {},
          actionType: "delete"
        }
      ],
      target: null,
      openSubtask: false
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#table-task_${this.task.id}`);
    const taskList = JSON.parse(JSON.stringify(this.tasksData)).reverse();
    if (taskList[1]?.id === this.task.id) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.totalCount !== this.tasksData.length) {
              this.getTaskTableData();
              this.observer.unobserve(this.target);
            }
          }
        });
      });
      this.observer.observe(this.target);
    }
  },
  methods: {
    ...mapActions(["handleContextMenuAction"]),
    openFullTask() {
      if (this.task) {
        this.$router.push({
          name: "Task",
          params: {
            id: this.task.task_id
          },
          query: { alias: this.task.alias }
        });
      }
    },
    getTaskTableData() {
      this.$emit("paginationEvent");
    },
    getSubtaskList() {
      if (this.haveSubtasks) {
        if (!this.openSubtask) {
          this.openSubtask = true;
          if (this.subtask.count !== 0) return;
          TaskModule.getSubtaskListAction({
            page: 1,
            per_page: 10,
            id: this.task.id
          });
        } else {
          this.openSubtask = false;
        }
      }
    },
    openCurtainTask() {
      TaskModule.getCurrentTaskAction({
        task_id: this.task.task_id,
        alias: this.task.alias
      });
      TaskManagerModuleV2.openCurtainShowAndEditTask({});
    },
    showContextMenu() {
      this.handleContextMenuAction({
        itemList: this.actionList,
        scrollContainer: document.querySelector(".stages-and-tasks__body")
      });
    },
    setURL(src) {
      return domainStorage + src;
    },
    setIcon(type) {
      if (type === "Задача") {
        return "trip-origin";
      } else if (type === "Подзадача") {
        return "all-out";
      } else if (type === "Улучшение") {
        return "arrow-up";
      } else {
        return "info";
      }
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userById"]),
    subTasksList() {
      return this.subtask.length ? this.subtask.slice(0, 5) : [];
    },
    countSub() {
      return TaskModule.subtaskCount;
    },
    haveSubtasks() {
      return !!(this.task.childs_count && this.task.childs_count !== 0);
    },
    subtask() {
      return TaskModule.subtasksByTask(this.task.id);
    },
    priority() {
      return TaskModule.priority(this.task.priority_id);
    },
    setStylePriority() {
      return this.priority.name
        ? `color: ${this.setPriorityColor}; border: 1px solid ${this.setPriorityColor}; background: ${this.setPriorityBackground}`
        : "color: #FFB901; border: 1px solid #FFB901; background: #fef2be;";
    },
    setPriorityColor() {
      if (this.priority.name === "Высокий") {
        return "#FB646D";
      } else if (this.priority.name === "Средний") {
        return "#EE7200";
      } else {
        return "#FFB901";
      }
    },
    setPriorityBackground() {
      if (this.priority.name === "Высокий") {
        return "#fed1d3";
      } else if (this.priority.name === "Средний") {
        return "#fed3b3";
      } else {
        return "#fef2be";
      }
    },
    type() {
      let type = TaskModule.type(this.task.type_id);
      if (this.task.parent_id) {
        type = {
          id: null,
          name: "Подзадача"
        };
      } else {
        type = {
          id: type.id,
          name: type.name
        };
      }
      return type;
    },
    pageSub() {
      return TaskModule.subtaskPage;
    },
    date_from() {
      return this.task.date_from ? moment(this.task.date_from).format("DD.MM.YYYY") : "-";
    },

    date_to() {
      return this.task.date_to ? moment(this.task.date_to).format("DD.MM.YYYY") : "-";
    },
    author() {
      return this.userById(this.task.author_id).length ? this.userById(this.task.author_id)[0] : [];
    },

    responsible() {
      return this.userById(this.task.executor_id).length
        ? this.userById(this.task.executor_id)[0]
        : [];
    },
    status() {
      return { name: this.task.status_name, id: this.task.status_id };
    },
    setId() {
      return this.task.childs_count !== 0
        ? `table-subtask_${this.task.id}`
        : `table-task_${this.task.id}`;
    },
    setStatus() {
      return this.task.status_name ? this.task.status_name : "НЕТ";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.table-task {
  width: 100%;
  position: relative;

  details > summary {
    list-style: none;
    outline: none;

    i {
      transform: rotate(-90deg);
      margin-right: 16px;
    }
  }

  details[open] > summary {
    i {
      transform: rotate(0);
    }
  }

  &__wrap {
    padding: 12px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;

    @media (max-width: $lg) {
      display: none;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $surface-selected-hovered;
        border-radius: 2px;
      }
    }

    .cell {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      font-size: 6px;
      color: $icon-subdued;
      margin-right: 7px;
    }
  }

  &__row {
    padding: 0 8px 0 24px;
    display: flex;
    align-items: center;
    width: 40%;
    max-width: 40%;
    flex-shrink: 0;
    justify-content: start;

    &.cell {
      justify-content: start;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: $text-default;
      line-height: 23px;
      @media (max-width: $lg) {
        font-size: 14px;
      }
    }
  }

  &__name {
    @media (hover: hover) {
      &:hover {
        color: $link-hovered;
        cursor: pointer;
      }
    }
  }

  &__priority {
    @media (max-width: $lg) {
      /deep/ {
        .task-priority__wrap {
          padding: 4px 10px;
        }
      }
    }
  }

  &__priority-content {
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 90px;
    height: 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: default;
  }

  &__deadline {
    p {
      @media (max-width: $lg) {
        font-size: 14px;
        word-break: normal;
      }
    }
  }

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width: $lg) {
      width: 30px;
      height: 30px;
    }
  }
}

.table-subtask {
  .icon-down-secondary {
    opacity: 0;
  }
}

.table-subtask .task-card {
  .icon-down-secondary {
    display: none;
  }
}

.white-bg {
  background: #fff;
}

.task-card {
  display: none;
  padding: 16px;
  @media (max-width: $lg) {
    display: block;
  }

  i {
    font-size: 6px;
    color: $icon-subdued;
    margin-right: 7px;
  }

  &__header {
    display: flex;
    align-content: center;
    margin-bottom: 12px;
  }

  &__priority {
    margin-right: 12px;
  }

  &__action-list {
    margin-left: auto;
    background: none;

    /deep/ {
      .action-list__setting-button {
        background: transparent;

        &:hover {
          background: #f2f7fe;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      font-weight: 900;
      font-size: 14px;
      line-height: 24px;
      word-break: break-word;
    }
  }

  &__deadline {
    display: block;
    font-size: 12px;
    color: $text-default;
    padding: 8px 0 24px 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__info-item {
    p {
      font-weight: 500;
      font-size: 12px;
      color: #756f86;
      margin-bottom: 16px;
    }

    /deep/ {
      .task-priority__wrap {
        padding: 2px 16px;
      }
    }
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 90px;
    min-height: 30px;
    height: max-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #eae8f0;
    color: #a7a9c0;
    background: #f2f7fe;

    span {
      padding: 0 4px;
      @include textEllipsis(2);
    }
  }
}

.body-row {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: $lg) {
    width: 1065px;
  }

  @media (max-width: $lg) {
    width: 100%;
  }
  &__all {
    font-size: 16px;
    font-weight: 400;
    color: $link-default;
    cursor: pointer;
    white-space: nowrap;
    line-height: 25px;
    border: none;
    padding: 8px 24px 24px;
    outline: none;
    background-color: transparent;
    text-align: start;

    &:hover {
      color: $link-hovered;
    }
  }

  summary {
    display: flex;
    width: 100%;
    outline: none;
  }

  &__subtasks {
    padding: 0;
    background: $surface-selected-default;
    box-shadow: inset 0 4px 9px rgba(167, 169, 192, 0.3);
    max-height: 236px;
    overflow-y: auto;

    @media (max-width: $lg) {
      width: 1065px;
      padding: 16px 0 16px 16px;
    }

    /deep/ {
      .task-row {
        padding: 12px 0;
      }

      .task-row__cell_name {
        max-width: 30%;
        width: 30%;

        @media (max-width: $lg) {
          max-width: calc(300px - 12px);
          width: calc(300px - 12px);
        }
      }
    }
  }
}
</style>
