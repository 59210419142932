<template>
  <div :class="[`news-component__${idx}`, 'news-image__item']">
    <img
      :src="returnSrcFile(image.link)"
      :alt="image.label || image.name"
      @click="openImageModal(image.link)"
    />
    <i class="icon-delete" @click="handleDeleteImage" v-if="editMode"></i>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";

export default {
  name: "NewsImageItem",
  mixins: [],
  props: ["image", "idx", "editMode"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["openFullSizeImage"]),
    returnSrcFile(src) {
      if (src.split("/")[1] === "public") {
        return domainStorage + src;
      } else {
        return src;
      }
    },
    openImageModal(link) {
      this.openFullSizeImage(link);
      // this.$root.$emit("openFullSizeImage", link);
    },
    handleDeleteImage() {
      // this.$root.$emit("newsGridDeleteItem", this.idx);
      this.$emit("news_grid_delete_item", this.idx);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
.news-image__item {
  position: relative;
  &:hover {
    .icon-delete {
      display: block;
    }
  }
  .icon-delete {
    background: rgba(120, 121, 150, 0.5);
    border-radius: 4px;
    padding: 4px;
    transition: all 0.3s ease;
    position: absolute;
    display: block;
    right: 14px;
    top: 19px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    @media screen and (hover: hover) {
      display: none;
    }
  }
}
</style>
