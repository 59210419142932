<template>
  <section class="plan">
    <div class="plan__header">
      <h2>Режим {{ modeText }} плана</h2>
      <Button
        class="plan__to-network"
        :type="'simple'"
        :title="`Перейти к графику`"
        :icon="'arrow-next'"
        v-if="!isModeCreate && !calculatingLoader && !isDisabled"
        @handleButton="goToProjectNetwork"
      />
      <Button
        class="plan__exit-btn"
        :type="'white'"
        :title="`Выйти из режима ${
          isModeCreate || isModeBaseCreate ? 'создания' : 'редактирования'
        }`"
        @handleButton="handleExitButton"
      />
    </div>
    <div class="plan__legend">
      <p>Указать данные для плана работ</p>
      <!--      Пока без выполненных и просроченных статусов-->
      <!--      <ul>-->
      <!--        <li v-for="legend in legendList" :key="legend.id">-->
      <!--          <div :style="{ background: legend.color }"></div>-->
      <!--          {{ legend.text }}-->
      <!--        </li>-->
      <!--      </ul>-->
      <Button
        v-if="isModeEdit"
        :type="hasSteps && !calculatingLoader ? 'white' : 'disabled'"
        :title="'Рассчитать данные'"
        @handleButton="calculateData"
      />
      <Button
        v-if="isModeBaseCreate || isModeBaseEdit"
        :type="'white'"
        :title="'Сохранить'"
        @handleButton="handleSaveButton"
      />
    </div>
    <PlanInfo :plan="currentPlan" :mode="mode" :disabled="isDisabled" @changeTitle="changeTitle" />
    <div class="plan__table">
      <PlanTable
        :plan="currentPlan"
        :stepList="stepList"
        :stepLinksList="stepLinksList"
        :disabled="isDisabled"
      />
    </div>
  </section>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import PlanInfo from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanInfo/PlanInfo";
import PlanTable from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/PlanTable";
import PlanningScheduleModule, {
  PlanningScheduleMutationTypes
} from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import NotificationObserver from "@/helpers/UserNotification";
export default {
  name: "Plan",
  mixins: [],
  props: {},
  components: { PlanInfo, PlanTable, Button },
  data() {
    return {
      // legendList: [
      //   {
      //     id: 0,
      //     color: "rgba(59, 232, 176, 0.2)",
      //     text: "Выполнен"
      //   },
      //   {
      //     id: 1,
      //     color: "rgba(251, 100, 109, 0.54)",
      //     text: "Просрочен"
      //   }
      // ]
      planName: ""
    };
  },
  created() {
    if (this.mode === "create") {
      PlanningScheduleModule.clearData();
    }
  },
  mounted() {
    this.planName = this.currentPlan?.name;
    if (this.currentPlan?.id === this.planId) return;
    if (this.isModeBaseEdit) {
      PlanningScheduleModule.getBasePlanAction(this.versionId)
        .then(() => {
          this.planName = this.currentPlan.name;
        })
        .catch(() => {
          this.$router.push({ name: "WorkPlans" });
        });
      PlanningScheduleModule.getBaseStepListAction({
        plan_id: this.planId,
        version_id: this.versionId
      });
      PlanningScheduleModule.getBaseStepLinkListAction({
        plan_id: this.planId,
        version_id: this.versionId
      });
    } else if (!this.isModeCreate) {
      PlanningScheduleModule.clearData();
      PlanningScheduleModule.getPlanAction(this.planId)
        .then(() => {
          if (this.currentPlan.author_id !== this.getUserId) {
            this.$router.push({ name: "WorkPlans" });
            NotificationObserver.notification({
              type: "error",
              message: "Нет доступа!"
            });
          }
        })
        .catch(() => {
          this.$router.push({ name: "WorkPlans" });
        });
      PlanningScheduleModule.getStepListAction({ plan_id: this.planId });
      PlanningScheduleModule.getStepLinkListAction({ plan_id: this.planId });
    }
  },
  methods: {
    calculateData() {
      PlanningScheduleModule.calculatePlanAction(this.planId);
    },
    handleExitButton() {
      switch (this.mode) {
        case "create":
          this.$router.push({ name: "WorkPlans" });
          break;
        case "base-create":
          this.$router.push({ name: "ProjectNetwork", query: { id: this.planId } });
          break;
        case "base-edit":
          this.$router.push({ name: "ProjectNetwork", query: { id: this.planId } });
          break;
        default:
          this.$router.push({ name: "WorkPlans" });
      }
    },
    goToProjectNetwork() {
      this.$router.push({ name: "ProjectNetwork", query: { id: this.planId } });
    },
    changeTitle(val) {
      this.planName = val;
    },
    validateTitle() {
      if (!this.planName?.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название базового плана!"
        });
        return false;
      }
      if (this.planName.length > 64) {
        NotificationObserver.notification({
          type: "error",
          message: "Название не может содержать более 64 символов!"
        });
        return false;
      }
      return true;
    },
    async handleSaveButton() {
      if (!this.validateTitle()) return;
      if (this.isModeBaseCreate) {
        await PlanningScheduleModule.createBasePlanAction({
          plan_id: this.planId,
          name: this.planName
        });
        NotificationObserver.notification({
          type: "success",
          message: "Базовый план успешно создан!"
        });
      } else if (this.isModeBaseEdit) {
        await PlanningScheduleModule.updateBasePlanAction({
          id: this.versionId,
          name: this.planName
        });
        NotificationObserver.notification({
          type: "success",
          message: "Базовый план успешно отредактирован!"
        });
      }

      this.$router.push({ name: "ProjectNetwork", query: { id: this.planId } });
    }
  },
  computed: {
    planId() {
      return Number(this.$route.query.id);
    },
    currentPlan() {
      return this.isModeBaseEdit
        ? PlanningScheduleModule.currentBasePlan
        : PlanningScheduleModule.currentPlan;
    },
    stepList() {
      return this.isModeBaseEdit
        ? PlanningScheduleModule.baseStepList(this.versionId)
        : PlanningScheduleModule.stepList;
    },
    stepLinksList() {
      return this.isModeBaseEdit
        ? PlanningScheduleModule.baseStepLinkList(this.versionId)
        : PlanningScheduleModule.stepLinksList;
    },
    mode() {
      return this.$route.query.mode;
    },
    modeText() {
      switch (this.mode) {
        case "create":
          return "создания";
        case "base-create":
          return "создания базового";
        case "edit":
          return "редактирования";
        case "base-edit":
          return "редактирования базового";
        default:
          return "создания";
      }
    },
    hasSteps() {
      return this.stepList.length;
    },
    calculatingLoader() {
      return PlanningScheduleModule.calculatingLoader;
    },
    isModeEdit() {
      return this.mode === "edit";
    },
    isModeCreate() {
      return this.mode === "create";
    },
    isModeBaseCreate() {
      return this.mode === "base-create";
    },
    isModeBaseEdit() {
      return this.mode === "base-edit";
    },
    isDisabled() {
      return this.isModeCreate || this.isModeBaseCreate || this.isModeBaseEdit;
    },
    versionId() {
      return Number(this.$route.query.version_id);
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.plan {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__header {
    display: flex;
    align-items: center;
    padding: 0 24px;
    flex: 0 0 88px;
  }
  &__to-network {
    display: flex;
    flex-direction: row-reverse;
    font-size: 18px;
    margin-left: 13px;
    /deep/ {
      span {
        margin-right: 6px;
      }
    }
  }
  &__exit-btn {
    margin-left: auto;
  }
  &__legend {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 24px;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: $text-default;
      margin-right: 24px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 24px;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 29px;
      color: #000000;
      div {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin-right: 4px;
      }
    }
    Button {
      margin-left: auto;
    }
  }
  &__table {
    max-height: calc(100% - 245px);
    padding: 24px;
  }
}
</style>
