<template>
  <section class="black-sphere table-container">
    <BlackSphereStatisticsWidgets
      :filterUser="filterUser"
      :filterMonth="filterMonth"
      @changeMonthYear="changeMonthYear"
      @selectUsers="handleUser"
    />
    <BlackSphereStatisticsWidgetsMobile
      :filterUser="filterUser"
      :filterMonth="filterMonth"
      @changeMonthYear="changeMonthYear"
    />
    <div class="black-sphere__container">
      <Table
        :tableHead="tableHead"
        :tableData="tableData"
        :loader="loader"
        :params="setTableDataParams"
        :isShowLoader="isShowLoader"
        :isScrollObserver="true"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <BlackSphereStatisticsCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
  </section>
</template>

<script>
import BlackSphereStatisticsWidgets from "@/modules/Tables/components/TablesStatistics/BlackSphereStatistics/BlackSphereStatisticsWidgets";
import BlackSphereStatisticsCellContent from "@/modules/Tables/components/TablesStatistics/BlackSphereStatistics/BlackSphereStatisticsCellContent";
import Table from "@/modules/Tables/components/Table/Table";
import { mapActions, mapMutations, mapState } from "vuex";
import BlackSphereStatisticsWidgetsMobile from "@/modules/Tables/components/TablesStatistics/BlackSphereStatistics/BlackSphereStatisticsWidgetsMobile/BlackSphereStatisticsWidgetsMobile";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import moment from "moment";
export default {
  name: "BlackSphereStatistics",
  mixins: [],
  props: [],
  components: {
    BlackSphereStatisticsWidgetsMobile,
    BlackSphereStatisticsWidgets,
    BlackSphereStatisticsCellContent,
    Table
  },
  data() {
    return {
      tableHead: [
        { name: "Сотрудник", sort: false, key: "full_name" },
        { name: "Подключен ЧШ", sort: false, key: "connected" },
        { name: "Количество", sort: true, valueSort: "count_marks", key: "count_marks" },
        { name: "Процент, %", sort: true, valueSort: "percent", key: "percent" },
        { name: "", sort: false, key: "lack" }
      ],
      localStorage: null,
      filterUser: [],
      filterMonth: moment(new Date()).format("YYYY-MM"),
      isShowLoader: false,
      loader: false
    };
  },
  beforeDestroy() {
    this.setCookieValue();
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    this.getTable();
  },
  methods: {
    ...mapActions("TablesModules", ["getTableData"]),
    ...mapMutations("TablesModules", ["CLEAR_TABLE_DATA", "RESET_PAGINATION", "SET_TABLE_PARAMS"]),
    handleUser(val) {
      BlackSphere[BlackSphereMutationTypes.SET_USER_FILTER](val);
      this.filterUser = val;
      this.getTable(true);
    },
    getTableDataWithPagination() {
      if (this.pageBlackSphereListStatistic && this.countBlackSphereListStatistic) {
        if (this.countBlackSphereListStatistic > this.tableData.length) {
          const page = this.pageBlackSphereListStatistic + 1;
          BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE_STATISTIC](page);
          this.loader = true;
          this.getTable().then(() => {
            this.loader = false;
          });
        }
      }
    },
    async getTable(isLoader) {
      if (isLoader) {
        this.isShowLoader = true;
      }
      await BlackSphere.getBlackSphereMarksForStatisticAction({
        users: this.filterUser.length ? this.filterUser.map((item) => item.id) : null,
        month: this.filterMonth,
        per_page: this.perPageBlackSphereListStatistic,
        page: this.pageBlackSphereListStatistic,
        [`sort[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      });
      if (isLoader) {
        this.isShowLoader = false;
      }
    },
    changeMonthYear(val) {
      BlackSphere[BlackSphereMutationTypes.SET_DATE_FILTER](val);

      this.filterMonth = val;
      this.getTable(true);
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("BSStat-table-setting"));
      this.filterUser = setting.users;
      this.filterMonth = setting.month;
    },
    setCookieValue() {
      const option = {
        users: this.filterUser,
        month: this.filterMonth
      };
      window.localStorage.setItem("BSStat-table-setting", JSON.stringify(option));
    }
  },
  computed: {
    ...mapState("TablesModules", [
      "tableDataBlackSphereStatistics",
      "totalCount",
      "valueSort",
      "valueSortList",
      "tableParams"
    ]),
    valueSort() {
      return BlackSphere.valueSortStatistic;
    },
    tableData() {
      return BlackSphere.blackSphereListForStatisticList;
    },
    perPageBlackSphereListStatistic() {
      return BlackSphere.blackSphereListForStatisticPerPage;
    },
    pageBlackSphereListStatistic() {
      return BlackSphere.blackSphereListForStatisticPage;
    },
    countBlackSphereListStatistic() {
      return BlackSphere.blackSphereListForStatisticCount;
    },
    setFilterUser() {
      return this.filterUser.map((item) => item.id);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
  width: 0;
  background: $surface-subdued;
}

::-webkit-scrollbar-thumb {
  background: $surface-pressed;
  border-radius: 8px;
  width: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $action-primary;
  }
}

::-webkit-scrollbar-track {
  margin: 0;
}

.black-sphere {
  height: 88%;

  @media (height: 1366px) {
    height: 95%;
  }

  @media (max-height: 850px) {
    height: 84%;
  }

  @media (max-height: 600px) {
    height: 82%;
  }

  @media (max-height: 320px) {
    height: 68%;
  }

  .black-sphere__container {
    width: 100%;
    height: 90%;
    overflow: scroll;
    @media (max-height: $md) {
      height: 82%;
    }

    @media (max-height: 450px) {
      height: 78%;
    }
    @media (max-height: 320px) {
      height: 68%;
    }
  }

  &__widgets-mobile {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
    }
  }

  &__widgets {
    @media (max-width: 767px) {
      display: none;
    }
  }

  /deep/ {
    .table {
      min-width: fit-content;
    }

    .table__row,
    .table__head {
      width: 100%;
    }

    .table__cell {
      &:first-child {
        width: 300px;
      }

      &:nth-child(2) {
        width: 300px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 200px;
      }

      &:last-child {
        max-width: 100%;
        width: max-content;
        min-width: 400px;
      }
    }
  }
}
</style>
