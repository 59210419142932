<template>
  <ul class="reaction-list">
    <Reaction
      :reaction="key"
      :count="item"
      v-for="(item, key) in reactions"
      :key="key"
      :message="message"
    />
    <li
      class="reaction-list__all"
      @click="showAllReaction"
      ref="list"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    >
      <i class="icon-dots-horizontal"></i>
    </li>
  </ul>
</template>

<script>
import Reaction from "@/modules/Messenger/components/Message/Reaction/Reaction";
import { mapActions, mapState } from "vuex";
export default {
  name: "ReactionList",
  mixins: [],
  props: {
    reactions: {
      type: Object,
      default: () => {}
    },
    message: {
      type: Object,
      default: () => {}
    },
    isSelfMassage: {
      type: Boolean,
      default: false
    }
  },
  components: { Reaction },
  data() {
    return {
      showTimer: null,
      hideTimer: null
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["showChatTooltip", "getAllUserReaction"]),
    showAllReaction() {
      this.getAllUserReaction(this.message);
    },
    showTooltip() {
      clearTimeout(this.showTimer);
      this.showTimer = setTimeout(() => {
        const selector = this.threadMode ? "#list-comment" : "#list-dynamic";
        const rect = this.$refs.list.getBoundingClientRect();
        const container = document.querySelector(selector);
        let x = 0;
        let y = -32;
        if (this.isSelfMassage) {
          x = 32;
        }
        this.showChatTooltip({
          textTooltip: "Показать список всех реакций",
          show: true,
          container: container.parentNode,
          x: rect.x + x,
          y: rect.y + y
        });
      }, 200);
    },
    hideTooltip() {
      clearTimeout(this.hideTimer);
      this.hideTimer = setTimeout(() => {
        this.showChatTooltip({
          textTooltip: "Показать список всех реакций",
          show: false,
          container: null,
          x: 0,
          y: 0
        });
      }, 200);
    }
  },
  computed: {
    ...mapState("MessengerModule", ["isShowTooltip", "threadMode", "showPin"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.reaction-list {
  all: unset;
  display: flex;
  flex-wrap: wrap;
  &__all {
    cursor: pointer;
    user-select: none;
    border: 1px solid #eae8f0;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-right: 4px;
    margin-bottom: 4px;
    flex-shrink: 0;
    height: 26px;
    width: 26px;
    transition: all 0.3s ease;
    position: relative;
    @media (hover: hover) {
      &:hover {
        border-color: $chat-editor;
        .reaction__users-wrap {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>
