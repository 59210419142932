<template>
  <div class="table-stage" ref="tableStage">
    <details class="table-stage__wrap">
      <summary class="table-stage__container" @click="openCurtain">
        <div class="table-stage__header">
          <i class="icon-down-secondary"></i>
          <div class="table-stage__checkbox" @click.prevent.stop v-if="!isOffStage">
            <CheckboxISP @input="completeStage" :value="isCompleted" :isEdit="isEdit" />
          </div>
          <p class="table-stage__name">{{ isOffStage ? "Вне этапа" : stage.name }}</p>
          <div class="table-stage__right">
            <div class="table-stage__add-task">
              <Button
                v-if="!project.is_completed && !stage.is_completed"
                type="simple"
                title=""
                icon="plus"
                @handleButton="addTask"
              />
              <p class="table-stage__tooltip">Добавить задачу</p>
            </div>
            <span class="table-stage__action" v-if="!isOffStage">
              <ActionsList
                v-if="isShowActionList"
                :actionList="setActionList"
                @handleAction="handleAction"
              />
            </span>
          </div>
        </div>
        <span class="table-stage__date_adaptive" v-if="!isOffStage && (date_from || date_to)">
          {{ date_from }} {{ date_to }}
        </span>
        <span class="table-stage__date" v-if="!isOffStage && (date_from || date_to)">
          {{ date_from }} {{ date_to }}
        </span>
      </summary>
      <ProjectStagesTableFields
        :tableHeadList="tableHead"
        v-if="tasks && tasks.count"
        :projectId="projectId"
        :stage="stage"
        @updateTable="updateTable"
      />
      <div class="table-stage__tasks-list" v-if="tasks.count">
        <ProjectStagesTableTask
          v-for="task in tasksList"
          :task="task"
          :key="task.id"
          :tasksData="tasks.list"
          :params="params"
          :totalCount="totalCountTaskByStage"
        />
        <button class="table-stage__all" type="button" v-if="tasks.count > 5" @click="goToStage">
          Все задачи
        </button>
      </div>
    </details>
  </div>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import Button from "@/modules/UiKit/components/buttons/Button";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import ProjectStagesTableTask from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableTask";
import ProjectStagesTableFields from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableFields";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule.ts";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2.ts";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import moment from "moment";
import { ITaskCommentsItem } from "@/modules/TaskManager/Model/Task/TaskModel";

export default {
  name: "ProjectStagesTableStage",
  mixins: [],
  props: {
    stage: {
      type: Object,
      default: () => {}
    },
    isOffStage: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: String,
      default: "0"
    },
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProjectStagesTableTask,
    ProjectStagesTableFields,
    ActionsList,
    Button,
    CheckboxISP
  },
  data() {
    return {
      checkbox: true,
      isOpen: false,
      infoItems: [
        {
          label: "Всего",
          count: 23,
          color: "#5551F9"
        },
        {
          label: "Задачи",
          count: 3,
          color: "#FFB901"
        },
        {
          label: "Подзадачи",
          count: 3,
          color: "#FFD426"
        },
        {
          label: "Закрыто",
          count: 10,
          color: "#3BE8B0"
        }
      ]
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.tableStage);
  },
  mounted() {
    if (this.isLastItem) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            StageModule.getStageByPagination({
              project_id: this.project.id
            });
            this.observer.unobserve(this.$refs.tableStage);
          }
        });
      });
      this.observer.observe(this.$refs.tableStage);
    }
  },
  methods: {
    completeStage(val) {
      StageModule.completeStage({
        stage_id: this.stage.id,
        isComplete: val,
        stage: this.stage
      });
    },
    updateTable(params) {
      this.$emit("updateTable", params);
    },
    async openCurtain(e) {
      const path = e.path || e.composedPath();
      if (path.some((item) => item.className === "action-list")) {
        e.preventDefault();
      } else if (
        !path.some((item) => item.className === "icon-down-secondary") &&
        !this.isOffStage
      ) {
        e.preventDefault();
        await StageModule.getOpenStage(this.stage.id);
        if (this.tasks.count === 0) {
          TaskModule.getTaskListByStageIdAction({
            stage_id: this.stage.id,
            per_page: 10,
            project_id: this.projectId
          });
        }
        StageModule.toggleShowEditModal(true);
      } else {
        this.isOpen = !this.isOpen;
        if (this.isOpen && this.tasks.count === 0) {
          TaskModule.getTaskListByStageIdAction({
            stage_id: this.stage.id,
            per_page: 10,
            project_id: this.projectId
          });
        }
      }
    },
    handleAction(val) {
      switch (val.actionType) {
        case "execute": {
          this.completeStage(!this.isCompleted);
          break;
        }
        default: {
          StageModule.handleActionList({
            action: val.actionType,
            stage: this.stage,
            currentProject: this.project
          });
          break;
        }
      }
    },
    addTask() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 3,
        currentProjectForCreation: this.project,
        currentStageForCreation: this.stage,
        currentTaskForCreation: null
      });
    },
    addStage() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 2,
        currentProjectForCreation: this.project,
        currentStageForCreation: null,
        currentTaskForCreation: null
      });
    },
    goToStage() {
      StageModule.goToStagePage({
        project_id: this.projectId,
        stage: this.stage
      });
      this.$router.push({
        name: "Project",
        params: { id: this.projectId },
        query: { stage: `${this.stage.id}` }
      });
    }
  },
  computed: {
    tasksList() {
      return this.tasks.list.slice(0, 5);
    },
    loader() {
      return TaskModule.loader;
    },
    tasks() {
      TaskModule.tasksByStage(this.stage.id === 0 ? null : this.stage.id).list.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
      return TaskModule.tasksByStage(this.stage.id === 0 ? null : this.stage.id);
    },
    project() {
      return ProjectModule.currentProject.projectFull;
    },
    date_from() {
      if (this.stage.date_from) {
        return `Дата начала: ${moment(this.stage.date_from).format("DD.MM.YYYY")}`;
      } else {
        return null;
      }
    },
    date_to() {
      if (this.stage.date_to) {
        return `Дата окончания: ${moment(this.stage.date_to).format("DD.MM.YYYY")}`;
      } else {
        return null;
      }
    },
    setActionList() {
      return StageModule.actionsList(this.stage, this.project).filter(
        (item) => item.actionType !== "createTask"
      );
    },
    isCompleted() {
      return this.stage.is_completed;
    },
    isEdit() {
      return this.project.is_owner && !this.project.is_completed;
    },
    params() {
      return {
        page: TaskModule.tasksByStagePage,
        stage_id: this.stage.id,
        per_page: 10,
        project_id: this.projectId
      };
    },
    currentStage() {
      return StageModule.stage;
    },
    totalCountTaskByStage() {
      return TaskModule.tasksByStageCount;
    },
    isShowActionList() {
      return !this.project.is_completed && this.project.is_owner;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.table-stage {
  width: 100%;
  border-bottom: 1px solid #eae8f0;

  @media (max-width: $sm) {
    margin-bottom: 8px;
    border-bottom: unset;
  }

  &__all {
    font-size: 16px;
    font-weight: 400;
    color: $link-default;
    cursor: pointer;
    white-space: nowrap;
    line-height: 25px;
    border: none;
    padding: 8px 24px;
    outline: none;
    background-color: $surface-default;
    text-align: start;

    &:hover {
      color: $link-hovered;
    }
  }
  /deep/ {
    .aop-button {
      i {
        font-size: 16px;
      }
    }

    .action-list__modal-list {
      right: 25px;
      top: 25px;
    }
  }

  &__wrap {
    @media (max-width: $sm) {
      border: 1px solid #eae8f0;
      border-radius: 8px;
    }
  }

  &__action {
    position: absolute;
    right: 0;
    z-index: 30;
  }

  &__container {
    padding: 24px 0 24px 8px;
    @media (max-width: $sm) {
      padding: 8px 8px 16px 0;
    }

    /deep/ {
      .aop-button {
        @media (max-width: $md) {
          font-size: 12px;
          padding: 8px;
        }
        @media (max-width: $sm) {
          i {
            margin: 0;
          }
          span {
            display: none;
          }
        }
      }
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  details > summary {
    list-style: none;
    outline: none;
    i {
      transform: rotate(-90deg);
    }
  }

  details[open] > summary {
    i {
      transform: rotate(0);
    }
  }

  i {
    padding: 8px 16px;
    font-size: 6px;
    color: $icon-subdued;
  }

  &__checkbox {
    margin-right: 16px;
  }

  &__name {
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
    color: $text-default;
    word-break: break-word;

    @media (max-width: $sm) {
      max-width: 100%;
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
    @media (hover: hover) {
      &:hover {
        color: $link-hovered;
        cursor: pointer;
      }
    }
  }

  &__tasks-counter {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    padding-left: 42px;
    @media (max-width: $md) {
      overflow: auto;
      width: 100%;
      flex-wrap: nowrap;
      padding-right: 16px;
    }

    /deep/ {
      .tasks-counter {
        @media (max-width: $md) {
          width: 400px;
          flex-wrap: nowrap;
        }
      }

      .tasks-counter__item {
        @media (max-width: $md) {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: $link-default;
      cursor: pointer;
      white-space: nowrap;
      line-height: 25px;
      @media (max-width: $md) {
        font-size: 12px;
      }
    }
  }

  &__date {
    font-size: 12px;
    font-weight: 400;
    color: $text-default;
    margin-left: 42px;
    @media (max-width: $md) {
      font-size: 10px;
    }
    @media (max-width: $sm) {
      display: none;
    }

    &_adaptive {
      display: none;
      font-size: 12px;
      font-weight: 400;
      color: $text-default;
      @media (max-width: $sm) {
        display: block;
        padding: 8px 0 0 42px;
      }
    }
  }

  /deep/ {
    .spinner {
      svg {
        path {
          overflow: visible;
          fill: $text-highlight;
        }
      }
    }
  }

  &__tasks-list {
    margin-bottom: 24px;
  }

  &__add {
    padding-left: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__right {
    position: relative;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-right: 24px;
    padding-right: 24px;
  }

  &__add-task {
    position: relative;

    &:hover .table-stage__tooltip {
      opacity: 1;
      transform: scale(1);
    }

    /deep/ {
      .aop-button {
        padding: 8px;
      }
    }
  }

  &__tooltip {
    transition: opacity 0.3s ease;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    top: -30px;
    right: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 2px;
  }
}
</style>
