<template>
  <div
    class="timeline-vacation"
    ref="tableContainer"
    id="tableContainer"
    :style="`max-width: calc(100vw - ${widthSlideNav}px`"
  >
    <TimeLineWidgets
      :valueSort="valueSort"
      :absenceList="absenceList"
      :isAdmin="isAdmin"
      :isShowWeekNumbers="isShowWeekNumbers"
      :isShowWeekends="isShowWeekends"
      :serviceWiths="serviceWiths"
    />
    <div class="timeline-vacation__table-loader" v-if="loading">
      <Loader />
    </div>
    <div class="timeline-vacation__table" v-show="!loading" ref="timeLineTable" id="timeLineTable">
      <div class="timeline-vacation__col-member">
        <div class="timeline-vacation__header-member">
          <div class="timeline-vacation__filter">
            <Widget
              type="select-redesign"
              :entity="'Department'"
              :actionEntity="'getItems'"
              :itemName="'name'"
              :keyValue="'TimeLineVacationDepartment'"
              :placeholder="'Отдел'"
              :multiple="true"
              v-if="isShowFilterDepartment"
            />
            <Widget
              class="widget__select"
              type="select-redesign"
              placeholder="Сотрудник"
              :entity="'User'"
              :actionEntity="'getItems'"
              :itemName="'full_name'"
              :keyValue="'TimeLineVacationUser'"
              multiple="true"
              v-if="isShowFilterUser"
            />
            <Widget
              type="select-redesign"
              :entity="'Role'"
              :actionEntity="'getItems'"
              :itemName="'name'"
              :keyValue="'TimeLineVacation'"
              :placeholder="'Роль'"
              :multiple="true"
              v-if="isShowFilterRole"
            />
          </div>
          <div class="timeline-vacation__header_empty"></div>
        </div>
        <TimeLineMemberList
          :member_users="member_users"
          :activeTabMember="activeTabMember"
          :scrollLoading="scrollLoading"
          :userTotalCount="userTotalCount"
        />
      </div>
      <div class="timeline-vacation__timeline" id="timeline">
        <div class="timeline-vacation__date-header">
          <div class="timeline-vacation__week" v-for="(week, weekNumber) in date" :key="weekNumber">
            <div class="timeline-vacation__week-wrap">
              {{ setMonthName(week) }}
              <div class="timeline-vacation__day-interval">{{ setDays(week) }}</div>
              <div class="timeline-vacation__week-number">
                <span v-show="isShowWeekNumbers">
                  {{ +weekNumber === 53 ? 1 : weekNumber }}
                </span>
                <span class="timeline-vacation__week-line"></span>
              </div>
            </div>
            <TimeLineDay :week="week" header="true" />
          </div>
          <div class="timeline-vacation__line" id="line" v-if="setTimeLine">
            <span class="now-time">
              {{ nowDate(today.date) }}
            </span>

            <span class="now-line" :style="`height: ${heightLine}px`"></span>
          </div>
        </div>
        <div class="timeline-vacation__row_empty">
          <div class="timeline-vacation__col" v-for="(week, weekNumber) in date" :key="weekNumber">
            <TimeLineDay :week="week" :isShowWeekends="isShowWeekends" />
          </div>
        </div>
        <div class="timeline-vacation__row-wrap" @click="openCreateVacationModal">
          <div
            class="timeline-vacation__row"
            v-for="(item, index) in member_users"
            v-if="!loading && !userLoading"
            :key="index"
          >
            <VacationList :vacationOrders="item.vacationOrders" />
            <!--          <div-->
            <!--            v-if="showVacationItems"-->
            <!--            class="timeline-vacation__vacation"-->
            <!--            @click.self="openCreateVacationModal"-->
            <!--          >-->
            <!--            <transition-group name="fade" appear>-->
            <!--              <TimeLineVacationItem-->
            <!--                v-for="vacation in item.vacationOrders"-->
            <!--                :key="vacation.id + activeTabMember"-->
            <!--                :vacation="vacation"-->
            <!--                :widthSlideNav="widthSlideNav"-->
            <!--                @handleScroll="handleScroll"-->
            <!--                :refs="$refs"-->
            <!--              />-->
            <!--            </transition-group>-->
            <!--          </div>-->
            <!--          <div class="timeline-vacation__col" v-for="(week, weekNumber) in date" :key="weekNumber">-->
            <!--            <TimeLineDay-->
            <!--              :week="week"-->
            <!--              :item="item.vacationOrders"-->
            <!--              :isShowWeekends="isShowWeekends"-->
            <!--            />-->
            <!--          </div>-->
          </div>
        </div>
        <!--        <div-->
        <!--          class="timeline-vacation__row timeline-vacation__row_empty"-->
        <!--          v-for="i in getMemberLength < 10 ? 10 - getMemberLength : 0"-->
        <!--          v-if="getMemberLength < 10"-->
        <!--          :key="i"-->
        <!--        >-->
        <!--          <div class="timeline-vacation__col" v-for="(week, weekNumber) in date" :key="weekNumber">-->
        <!--            <TimeLineDay :week="week" :isShowWeekends="isShowWeekends" />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import MonthPickerTable from "@/components/Widgets/MonthPickerTable/MonthPickerTable";
import TimeLineDay from "@/components/GraphVacation/TimeLine/TimeLineDay";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import TimeLineVacationItem from "@/components/GraphVacation/TimeLine/TimeLineVacationItem";
import ConfirmationsUsersModal from "@/components/Widgets/ConfirmationsUsersModal/ConfirmationsUsersModal";
import moment from "moment";
import TimeLineWidgets from "@/components/GraphVacation/TimeLine/TimeLineWidgets";
import PaginationWidget from "@/components/Widgets/Pagination2.0/PaginationWidget";
import TimeLineMemberList from "@/components/GraphVacation/TimeLine/TimeLineMembers/TimeLineMemberList";
import TimeLineVacationRow from "@/components/GraphVacation/TimeLine/TimeLineVacationRow";
import EmptyRow from "@/components/GraphVacation/TimeLine/EmptyRow";
import VacationList from "@/components/GraphVacation/TimeLine/VacationList";
import httpClient from "@/api/client/httpClient";

const Widget = () => import("@/components/Widgets/Widget");
export default {
  name: "TimeLineVacation",
  mixins: [],
  props: ["graphVacationData", "absenceList", "isAdmin"],
  components: {
    VacationList,
    EmptyRow,
    TimeLineVacationRow,
    TimeLineMemberList,
    PaginationWidget,
    TimeLineWidgets,
    ConfirmationsUsersModal,
    TimeLineVacationItem,
    TimeLineDay,
    MonthPickerTable,
    Widget,
    Loader
  },
  data() {
    return {
      scrollLoading: false,
      showVacationItems: true,
      inputSearchValue: "",
      filterRole: [],
      filterUser: [],
      filterDepartments: [],
      isShowFilterDepartment: true,
      isShowFilterRole: false,
      isShowFilterUser: false,
      isShowWeekNumbers: true,
      isShowWeekends: false,
      vacationList: [],
      date: {},
      month: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      today: {},
      day: "",
      year: moment(new Date()).year().toString(),
      serviceWiths: null,
      member_users: [],
      activeTabMember: 0,
      userCounter: 1,
      userTotalCount: 0,
      offsetSize: 0,
      prevScrollTop: 0,
      startScrollHeight: 0,
      leftLineWrap: 0,
      heightLine: 0,
      loading: true,
      userLoading: true,
      timeoutId: "",
      count: -1,
      currentPerPage: 20,
      perPageOptions: ["15", "25", "100"],
      totalCount: 0,
      fromPag: 1,
      valueSort: {
        name: "Отдел",
        value: "departments"
      },
      resizeObs: null,
      timer: null,
      widthSlideNav: 0,
      windowWidth: 0,
      nextScrollTop: 0,
      lastScrollTop: 0
    };
  },
  mounted() {
    this.$root.$on("setVacationYear", (val) => {
      this.setVacationYear(val);
    });
    this.$root.$on("changeSelectValue:TimeLineVacation", (value) => {
      this.$refs.tableContainer.removeEventListener("scroll", this.handleScroll, false);
      this.filterRole = value.map((item) => {
        return { "roles.id": item.id };
      });
      this.getVacationList();
    });
    this.$root.$on(`changeSelectValue:TimeLineVacationUser`, (val) => {
      this.filterUser = val.map((item) => item.id);
      this.updateVacationList();
    });
    this.$root.$on(`changeSelectValue:TimeLineVacationDepartment`, (val) => {
      this.filterDepartments = val.map((item) => {
        return { "departments.id": item.id };
      });
      this.updateVacationList();
    });
    this.$root.$on(`text:change_TimeLineSearchVacation`, (val) => {
      this.inputSearchValue = val;
      this.$root.$emit("resetPaginationValue");
      this.updateVacationList();
    });
    this.$root.$on(`button:click_todayTimeLine`, () => {
      if (this.year !== moment().format("YYYY")) {
        this.year = moment().format("YYYY");
        this.updateVacationList();
        this.$root.$emit("changeMyVacationYear", new Date().getFullYear());
        this.$root.$emit("timeline_button_today_setToday");
      }
      this.scrollToday(this.scrollLeft);
      this.currentDate = "";
    });
    this.day = new Date().toISOString().slice(0, 10);
    this.getVacationList();
    this.getToday();
    this.$root.$on("updateVacationList", (val) => {
      this.updateVacationList();
    });
    this.$root.$on(`chosenFilter_TimeLine`, (val) => {
      this.valueSort = val;
      if (val.value === "id") {
        this.filterUser = true;
        this.$root.$emit("setCheckboxValue_set-filter-id", true);
        this.$root.$emit("setCheckboxValue_set-filter-departments", false);
        this.$root.$emit("setCheckboxValue_set-filter-roles", false);
      }
      if (val.value === "departments") {
        this.filterDepartment = true;
        this.$root.$emit("setCheckboxValue_set-filter-id", false);
        this.$root.$emit("setCheckboxValue_set-filter-departments", true);
        this.$root.$emit("setCheckboxValue_set-filter-roles", false);
      }
      if (val.value === "roles") {
        this.filterRole = true;
        this.$root.$emit("setCheckboxValue_set-filter-id", false);
        this.$root.$emit("setCheckboxValue_set-filter-departments", false);
        this.$root.$emit("setCheckboxValue_set-filter-roles", true);
      }
    });
    this.$root.$on("setCheckboxValue_set-filter-id", (value) => {
      this.isShowFilterUser = value;
    });
    this.$root.$on("setCheckboxValue_set-filter-departments", (value) => {
      this.isShowFilterDepartment = value;
    });
    this.$root.$on("setCheckboxValue_set-filter-roles", (value) => {
      this.isShowFilterRole = value;
    });
    this.$root.$on("switch:change_switch-view-weekends", (value) => {
      this.isShowWeekends = value;
    });
    this.$root.$on("switch:change_switch-view-week-numbers", (value) => {
      this.isShowWeekNumbers = value;
    });
    // window.addEventListener("resize", this.resizeHandler);
    this.windowWidth = window.innerWidth;
    // setTimeout(() => {
    //   this.resizeObs = new ResizeObserver(this.resizeHandler).observe(
    //     document.querySelector(".app-sidebar")
    //   );
    // }, 100);
  },
  beforeDestroy() {
    this.$root.$off("button:click_todayTimeLine");
    this.$root.$off("changeSelectValue:TimeLineVacation");
    this.$root.$off(`text:change_TimeLineSearchVacation`);
    this.$root.$off("changeSelectValue:TimeLineVacationDepartment");
    this.$root.$off("changeSelectValue:TimeLineVacationUser");
    this.$root.$off("updateVacationList");
    this.$root.$off("chosenFilter_TimeLine");
    this.$root.$off("setVacationYear");
    this.$root.$off("setCheckboxValue_set-filter-departments");
    this.$root.$off("setCheckboxValue_set-filter-departments");
    this.$root.$off("setCheckboxValue_set-filter-roles");
    this.$root.$off("switch:change_switch-view-weekends");
    this.$root.$off("switch:change_switch-view-week-numbers");
    this.$refs.tableContainer.removeEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    setVacationYear(val) {
      const [year, month] = val.split("-");

      if (this.year !== year) {
        this.year = year;
        this.loading = true;
        this.userLoading = true;
        this.count = -1;
        this.$refs.tableContainer.scrollTop = 0;
        this.scrollLeft = 0;
        this.userCounter = 1;
        this.getVacationList();
        this.$root.$emit("changeMyVacationYear", this.year);
      }
      const elem = document.getElementById(`${val}-01`);
      this.$scrollTo(elem, 500, {
        container: "#tableContainer",
        easing: "ease-in",
        lazy: true,
        offset: -350,
        force: true,
        cancelable: true,
        x: true,
        y: false
      });
    },
    getVacationList() {
      this.userCounter = 1;
      this.loading = true;
      httpClient({
        method: "GET",
        url: `/User/getItems`,
        params: {
          year: this.year,
          _from: this.userCounter,
          _count: this.currentPerPage,
          // _count: 300,
          is_active: JSON.stringify([1]),
          _search: this.inputSearchValue.length ? { full_name: this.inputSearchValue } : undefined,
          id: this.filterUser.length ? JSON.stringify(this.filterUser) : undefined,
          roles: this.filterRole.length ? JSON.stringify(this.filterRole) : undefined,
          departments: this.filterDepartments.length
            ? JSON.stringify(this.filterDepartments)
            : undefined,
          _with: JSON.stringify(["roles", "departments"]),
          _service_with: JSON.stringify(["vacation_stock", "vacation_orders"])
        }
      })
        .then((response) => {
          this.member_users = response.data.data.items;
          this.activeTabMember = 0;
          this.totalCount = response.data.data.total_count;
          // if (this.year === moment().format("YYYY")) {
          //   this.heightLine = this.$refs.timeLineTable.getBoundingClientRect().height - 54;
          // }
          this.$nextTick(() => {
            // this.startScrollHeight = this.$refs.tableContainer.scrollHeight / 2;
            this.$refs.tableContainer.addEventListener("scroll", this.handleScroll, false);
          });
          this.getDate();
          this.scrollToday(this.scrollLeft);
        })
        .catch((error) => {
          this.scrollLoading = false;
          this.errorAlert(error);
        });
    },
    scrollGetUserItems(mode) {
      if (mode === "top") {
        if (this.userCounter !== 1) {
          this.userCounter -= 20;
          if (this.userCounter < 1) this.userCounter = 1;
        } else return false;
      }
      if (mode === "bottom") {
        if (this.userCounter < this.totalCount) {
          this.userCounter > 1 ? (this.userCounter += 10) : (this.userCounter += 20);
        } else return false;
      }
      this.scrollLoading = true;
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _from: this.userCounter,
          year: this.year,
          is_active: JSON.stringify([1]),
          _search: this.inputSearchValue.length ? { full_name: this.inputSearchValue } : undefined,
          id: this.filterUser.length ? JSON.stringify(this.filterUser) : undefined,
          roles: this.filterRole.length ? JSON.stringify(this.filterRole) : undefined,
          departments: this.filterDepartments.length
            ? JSON.stringify(this.filterDepartments)
            : undefined,
          _count: 10,
          _with: JSON.stringify(["roles"]),
          _service_with: JSON.stringify(["vacation_stock", "vacation_orders"])
        }
      })
        .then((response) => {
          if (mode === "top") {
            this.member_users.unshift(...response.data.data.items);
            this.member_users.splice(this.member_users.length - 10, 10);
            this.$refs.tableContainer.scrollTop = this.startScrollHeight / 2;
          } else {
            this.member_users.push(...response.data.data.items);
            // this.member_users.splice(0, 10);
            // this.$refs.tableContainer.scrollTop = this.startScrollHeight / 2;
          }
          if (this.year === moment().format("YYYY")) {
            this.heightLine = this.$refs.timeLineTable.getBoundingClientRect().height - 54;
          }
          this.userTotalCount = response.data.data.total_count;
          this.scrollToday(this.scrollLeft);
          // this.handleScroll();
          this.offsetSize = 0;
          this.scrollLoading = false;
        })
        .catch((error) => {
          this.scrollLoading = false;
          this.errorAlert(error);
        });
    },
    handleScroll(e) {
      if (
        this.$refs.tableContainer.scrollHeight <
          e.target.scrollTop + this.$refs.tableContainer.offsetHeight + 250 &&
        !this.loading
      ) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.scrollGetUserItems("bottom");
        }, 100);
      }
      // let currentScrollTop = this.$refs.tableContainer.scrollTop;
      // if (
      //   this.$refs.tableContainer.scrollHeight ===
      //   Math.round(currentScrollTop) + this.$refs.tableContainer.clientHeight
      // ) {
      //   if (this.activeTabMember < this.member_users.length - 1) {
      //     this.showVacationItems = false;
      //     this.activeTabMember++;
      //     this.$nextTick(() => {
      //       this.$refs.tableContainer.scrollTop = 3;
      //       if (this.year === moment().format("YYYY")) {
      //         this.heightLine = this.$refs.timeLineTable.getBoundingClientRect().height - 54;
      //       }
      //       this.showVacationItems = true;
      //     });
      //   }
      // } else if (currentScrollTop === 0) {
      //   if (currentScrollTop < this.prevScrollTop && currentScrollTop === 0) {
      //     if (this.activeTabMember > 0) {
      //       this.showVacationItems = false;
      //       this.activeTabMember--;
      //       this.$nextTick(() => {
      //         setTimeout(() => {
      //           this.$refs.tableContainer.scrollTop =
      //             this.$refs.tableContainer.scrollHeight -
      //             this.$refs.tableContainer.clientHeight -
      //             3;
      //         }, 0);
      //         if (this.year === moment().format("YYYY")) {
      //           this.heightLine = this.$refs.timeLineTable.getBoundingClientRect().height - 54;
      //         }
      //         this.showVacationItems = true;
      //       });
      //     }
      //   }
      // }
      // this.prevScrollTop = currentScrollTop;
    },
    getToday() {
      httpClient({
        method: "GET",
        url: `/WorkingCalendar/getItems`,
        params: {
          _count: 1,
          _order: JSON.stringify({ date: "asc" }),
          _search: JSON.stringify({ date: this.day }),
          year: this.year
        }
      }).then((response) => {
        if (response.data.data.items.length) {
          this.today = response.data.data.items[0];
        }
      });
    },
    getDate() {
      httpClient({
        method: "GET",
        url: `/WorkingCalendar/getItems`,
        params: {
          _count: 366,
          _group: JSON.stringify(["week"]),
          _order: JSON.stringify({ date: "asc" }),
          _service_with: JSON.stringify(["vacation_schedule"]),
          year: this.year
        }
      }).then((response) => {
        this.serviceWiths = response.data.data.serviceWiths[0];
        this.$root.$emit(
          "isAvailableForEditing",
          this.serviceWiths.vacationSchedule.is_available_for_editing
        );
        const lastWeek = response.data.data.items[1].filter((item) => item.month === 12);
        if (lastWeek.length) {
          response.data.data.items[1].filter((item) => item.month === 12);
          response.data.data.items[1] = response.data.data.items[1].filter(
            (e) => lastWeek.findIndex((i) => i.id === e.id) === -1
          );
          response.data.data.items[Object.keys(response.data.data.items).length + 1] = lastWeek;
          this.date = response.data.data.items;
        } else {
          this.date = response.data.data.items;
        }
        this.loading = false;
        this.userLoading = false;
        if (this.year === moment().format("YYYY")) {
          setTimeout(() => {
            this.checkScrollToday();
          }, 0);
        }
      });
    },
    getThisYear() {
      httpClient({
        method: "GET",
        url: `/VacationSchedule/getVacationSchedule`,
        params: {
          year: this.year
        }
      }).then((response) => {
        if (response.data.data.items.length) {
          this.today = response.data.data.items[0];
        }
      });
    },
    checkScrollToday() {
      //@TODO продумать как отслеживать ширину боковой навигации и из каких ширин складываются числа 376 и 567
      if (this.windowWidth > 768) {
        this.leftLineWrap = document.getElementById(this.day).offsetLeft + 372;
      } else if (this.windowWidth > 576) {
        this.leftLineWrap = document.getElementById(this.day).offsetLeft + 256;
      } else {
        this.leftLineWrap = document.getElementById(this.day).offsetLeft + 156;
      }
      if (this.year === moment().format("YYYY")) {
        this.heightLine = this.$refs.timeLineTable.getBoundingClientRect().height - 54;
      }
      this.scrollLeft =
        this.leftLineWrap -
        this.$refs.tableContainer.getBoundingClientRect().left -
        document.querySelector(".timeline-vacation__member-list").getBoundingClientRect().width * 2;

      document.getElementById("line").style.left = `${
        this.scrollLeft +
        document.querySelector(".timeline-vacation__member-list").getBoundingClientRect().width +
        20
      }px`;
      this.scrollToday(this.scrollLeft);
    },
    setMonthName(week) {
      const index = week.length - 1;
      if (week[0].month === week[index].month) {
        return this.month[week[0].month - 1];
      } else {
        return this.month[week[index].month - 1];
      }
    },
    setDays(week) {
      const index = week.length - 1;
      const separate = (date) => {
        const [year, month, day] = date.split("-");
        return +day;
      };
      return `${separate(week[0].date)}-${separate(week[index].date)}`;
    },
    scrollToday(left) {
      document.getElementById("tableContainer").scrollTo({ left: left, behavior: "auto" });
    },
    nowDate(date) {
      if (date) {
        const [year, month, day] = date.split("-");
        return `${this.month[+month - 1]} ${day}, ${year}`;
      }
    },
    updateVacationList() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getVacationList();
      }, 400);
    },
    changePagPage(from) {
      this.fromPag = from;
      this.updateVacationList();
    },
    chosePerPage(per) {
      this.currentPerPage = per;
      this.getVacationList();
    },
    openCreateVacationModal() {
      if (this.isAdmin) {
        this.$root.$emit("openConfirmationModalPersonnelOfficer");
      } else {
        this.$root.$emit("openConformationModal", {
          editMode: false,
          createMode: true
        });
      }
    }
    // resizeHandler() {
    //   this.windowWidth = window.innerWidth;
    //   if (this.windowWidth <= 425) {
    //     this.widthSlideNav = 0;
    //   } else {
    //     this.widthSlideNav =
    //       document.getElementById("appSidenav").getBoundingClientRect().width + 50;
    //   }
    // }
  },
  computed: {
    setTimeLine() {
      if (this.year === String(new Date().getFullYear())) {
        return true;
      } else {
        return false;
      }
    },
    getMemberLength() {
      return this.member_users.length
        ? this.member_users[this.activeTabMember]
          ? this.member_users[this.activeTabMember].length
          : 0
        : this.member_users.length;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
::-webkit-scrollbar {
  position: relative;
  height: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $action-primary-disabled;
  cursor: pointer;

  &:hover {
    background: $action-primary-hovered;
  }
}
::-webkit-scrollbar-track {
  background: transparent;
}

.timeline-vacation {
  display: flex;
  flex-flow: column;
  position: relative;
  color: $text-default;
  box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
  border-radius: 24px;
  background-color: #ffffff;
  height: 98%;
  width: 100%;
  overflow: scroll;
  @media (max-width: $xxl) {
    max-width: calc(100vw - 135px);
  }

  //@media (max-width: 425px) {
  //  max-width: 100%;
  //  width: 100%;
  //  height: 100%;
  //  border-bottom-right-radius: 0;
  //  border-bottom-left-radius: 0;
  //}
  .timeline-vacation__header {
    height: 97px;
    position: sticky;
    left: 0;
    top: 0;
    background-color: $bg-gray-75;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid $border-subdued;

    @media (max-width: 768px) {
      padding: 20px 20px 14px;
    }

    @media (max-width: 425px) {
      padding: 25px 18px 14px;
    }

    &_empty {
      &:first-child {
        max-width: 260px;
        height: 54px;
        border-bottom: 1px solid $border-subdued;
        border-right: 1px solid $border-subdued;

        @media (max-width: $sm) {
          max-width: 160px !important;
        }
      }

      &:nth-child(2) {
        height: 42px;
        max-width: 260px;

        @media (max-width: $sm) {
          max-width: 160px !important;
        }
      }
    }
  }

  /deep/ &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    border-right: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    @media (max-width: $sm) {
      max-width: 160px;
    }

    .select-bg {
      @media (max-width: $sm) {
        min-width: 160px !important;
        max-width: 160px !important;
        .option-name {
          font-size: 12px;
        }
      }
      @media (max-width: $xs), (max-height: $xs) {
        .modal-items {
          max-height: 135px;
          padding: 2px;
          left: 10px;
          font-size: 12px;
          width: unset;
        }
      }
    }
  }

  &__day_start {
    content: "";
    position: absolute;
    top: 0;
    height: 48px;
    background-color: $action-primary;
    border: 1px solid $action-primary;
    border-radius: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      background-color: $action-primary-hovered;
      border: 1px solid $action-primary-hovered;
    }
  }

  &__table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76vh;
  }

  &__table {
    width: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__col-member {
    flex-shrink: 0;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 9;
    contain: content;
  }

  &__date {
    margin-left: 34px;
    line-height: 46px;
    font-size: 14px;
    font-weight: 500;
    &_year {
      color: $text-subdued;
    }
  }
  &__date-header {
    display: flex;
    position: sticky;
    top: 91px;
    background-color: $bg-gray-75;
    z-index: 9;
    border-bottom: 1px solid $border-subdued;

    max-height: 54px;
    height: 100%;
    @media (max-width: $lg) {
      top: 97px;
    }
  }
  &__header_empty {
    border-right: 1px solid $border-subdued;
    &:first-child {
      max-width: 260px;
      height: 54px;
      border-bottom: 1px solid $border-subdued;
      border-right: 1px solid $border-subdued;

      @media (max-width: $sm) {
        max-width: 160px;
      }
    }
    &:nth-child(2) {
      height: 42px;
      max-width: 260px;
      @media (max-width: $sm) {
        max-width: 160px;
      }
    }
  }
  &__header-member {
    z-index: 10;

    position: sticky;
    top: 91px;
    background-color: $bg-gray-75;
    @media (max-width: $lg) {
      top: 97px;
    }
  }

  &__week {
    max-width: 141px;
    display: flex;
    flex-flow: column;
    font-size: 8px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $text-highlight;
  }
  &__week-wrap {
    position: relative;
    display: flex;
    margin-top: 5px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  &__week-number {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    right: 0;
    margin-top: 4px;
    line-height: 20px;
    font-weight: 400;
    color: $background-dark;
    transform: translateX(50%);
  }
  &__week-line {
    width: 1px;
    height: 18px;
    background: $background-dark;
  }
  &__day-list {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
  &__day-interval {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $bg-gray-800;
    text-transform: uppercase;
  }
  &__day {
    width: 20px;
    &_col {
      border-right: 1px solid $border-subdued;
      display: flex;
    }
    &_holiday {
      background-color: $bg-gray-200;
    }
  }
  &__timeline {
    position: relative;
    contain: content;
  }

  .now-line {
    position: absolute;
    z-index: 11;
    width: 1px;
    background-color: $action-primary;
  }
  &__row {
    position: relative;
    display: flex;
    height: 72px;

    &_empty {
      display: flex;
      height: 100%;
      position: sticky;
      top: 96px;
      background-color: $bg-gray-75;
    }

    &_max-height {
      height: 67vh;
    }
  }
  &__row-wrap {
    cursor: pointer;
    top: 95px;
    position: absolute;
    width: 100%;
  }
  &__vacation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    cursor: pointer;
    z-index: 1;
    &:hover {
      background-color: rgba($bg-gray-200, 0.5);
    }
  }

  &__line {
    top: 55px;
    left: -10px;
    position: absolute;
    width: 1px;
    //min-height: 300%;
    background-color: $action-primary;
    z-index: 9;
    cursor: pointer;
    &:hover {
      .now-time {
        display: flex;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      transform: translate(-50%, -75%);
      background-color: $action-primary;
    }
    .now-time {
      position: absolute;
      display: none;
      top: -1px;
      left: 5px;
      padding: 5px 9px;
      min-width: 94px;
      transform: translateY(-105%);
      background-color: $bg-gray-800;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 4px;

      color: $bg-gray-75;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
</style>
