const stateIspReview = {
  loaderPeriod: false,
  quarterList: [],
  quarterProgress: 0,
  quarterStatistics: [],
  showProgressModal: false,
  currentQuarter: null,
  editQuarter: false,
  currentPeriodCriticalityWeight: [],
  problemsReviewParams: null,
  reviewCriticalityValue: [],
  reviewStatusValue: [],
  reviewGroupValue: [],
  reviewCustomersValue: [],
  reviewResponsibleValue: [],
  showCreateAndEditQuarter: false,
  showDuplicateModal: false,
  criticalityWeight: [],
  channelCentrifuge: null
};

export default stateIspReview;
