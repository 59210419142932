export interface ITask {
  id: number;
  task_id: number;
  alias: string | null;
  project_id: number | null;
  stage_id: number | null;
  type_id: number;
  priority_id: number;
  status_id: number;
  name: string;
  date_from: string | null;
  date_to: string | null;
  description: string;
  author_id: number;
  executor_id: null | number;
  order: number;
  count_comments: number;
  checklists: Array<IChekItem>;
  filesList: Array<ITaskFilesItem>;
  author_info: IUserTask;
  childs_count: number;
  count_files: number;
  executor_info: IUserTask;
  parent_id: number | null;
  parent_task_id: number | null;
  parent_task_name: number | null;
  project_name: string | null;
  stage_name: string | null;
  status_name: string | null;
  time_fact: number | null;
  time_plan: number | null;
}

export interface ITaskFull extends ITask {
  stage: ITaskStage;
  project: ITaskProject;
  type: ITaskType;
  priority: ITaskPriority;
  status: ITaskStatus;
}

export interface IStageTask {
  page: number;
  count: number;
  per_page: number;
  list: Array<ITask>;
}

export interface IUserTask {
  avatar: string;
  full_name: string;
  id: number;
  role_name: string | null;
}

interface ITaskStage {
  id: number;
  name: string;
  description: string;
}

interface ITaskProject {
  id: number;
  name: string;
  alias: string;
}

export interface ITaskType {
  id: number;
  name: string;
}

export interface ITaskPriority {
  id: number;
  name: string;
  color: string;
}

export interface ITaskStatus {
  id: number;
  name: string;
  is_start: boolean;
  is_end: boolean;
}

export interface IChekItem {
  id: number;
  text: string;
  is_completed: boolean;
  task_id: number;
}

export interface ISort {
  value: string;
  isAsc: boolean;
}

export interface IParams {
  page: number;
  per_page: number;
  is_dashboard: number;
  projects?: Array<any>;
  status?: string;
  role?: string;
  _order?: string;
}

export interface ITaskModel {
  task: ITask;
}

export interface ITaskComments {
  commentsList: Array<ITaskCommentsItem>;
}

export interface ITaskCommentsItem {
  count_comments: number;
  count_likes: number;
  created_at: string;
  deleted_at: string | null;
  id: number;
  parent_id: number | null;
  text: string;
  user_id: number;
  files: Array<any>;
}

export interface ITaskFilesItem {
  id: number;
  name: string;
  path: string;
  path_preview: string | null;
  size: number;
  ext: string;
  updated_at: null | string;
  count_files: number;
}

export interface IHistoryTaskItem {
  author_id: number;
  created_at: string;
  description: string;
  entity_id: number;
  entity_type: number;
  id: number;
  service_id: number;
}

export default class TaskModel implements ITaskModel {
  private _task: ITask;

  constructor(task: ITask) {
    this._task = task;
  }

  get task() {
    return this._task;
  }

  set task(updatedTask: ITask) {
    this._task = updatedTask;
  }
}
