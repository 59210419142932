<template>
  <div class="problem-navigate">
    <i class="icon-arrow-back" @click="handleBack"></i>
    <div class="problem-navigate__alias">
      {{ currentInfoProblem.group.alias }}-{{ currentInfoProblem.id }}
    </div>
    <div class="problem-navigate__archive" v-if="currentInfoProblem.is_archived">В архиве</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProblemNavigate",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {
      problemData: null,
      history: []
    };
  },
  created() {},
  methods: {
    handleBack() {
      if (this.setOldPath.name) {
        this.$router.back();
        if (!window.history.state) {
          this.$router.push({ name: "IspReview" });
        }
      } else {
        this.$router.push({ name: "IspReview" });
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "filesLoader"]),
    setOldPath() {
      return this.$store.state.from;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.problem-navigate {
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    color: #a7a9c0;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: #5551f9;
    }
  }
  &__alias {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #a7a9c0;
    margin-left: 20px;
  }
  &__archive {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 4px 16px;
    color: $icon-subdued;
    border-radius: 26px;
    border: 1px solid $icon-subdued;
    margin-left: 24px;
    user-select: none;
  }
}
</style>
