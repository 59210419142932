




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CellContent extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: any;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: any;

  get field() {
    return this.row[this.cell.field];
  }
}
