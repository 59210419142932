import { EventBus } from "@/main";

class NotificationObserver {
  notification(notification) {
    EventBus.$emit("showNotification", {
      type: notification.type,
      timeout: 5000,
      label: notification.message
    });
  }
}

export default new NotificationObserver();
