<template>
  <div class="add-node-modal modal" ref="modal" @keydown.enter="addNode">
    <div id="draggable-area"></div>
    <div class="modal__header"><h3>Добавить статус</h3></div>
    <div class="modal__body">
      <p>Название<span :style="{ color: 'red' }">*</span></p>
      <Input class="modal__input" placeholder="Статус" v-model.trim="nodeTitle" :maxlength="20" />
    </div>
    <div class="modal__footer">
      <Button type="cancel" title="Отменить" @handleButton="closeModal" />
      <Button type="simple" title="Добавить" @handleButton="addNode" />
    </div>
  </div>
</template>

<script>
import makeElementDraggable from "@/helpers/makeElementDraggable.mixin";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Button from "@/modules/UiKit/components/buttons/Button";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "AddNodeModal",
  mixins: [makeElementDraggable],
  props: {
    nodesArray: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Button, Input },
  data() {
    return {
      nodeTitle: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.dragElement(this.$refs.modal);
    });
    document.querySelector(".input__control").focus();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    addNode() {
      if (!this.titleValidation()) return;
      this.$emit("addNode", this.nodeTitle);
    },
    titleValidation() {
      if (this.nodeTitle.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название статуса!"
        });
        return false;
      } else if (this.nodeTitle.length > 20) {
        NotificationObserver.notification({
          type: "error",
          message: "Название не может содержать более 20 символов!"
        });
        return false;
      } else if (!/^[a-zA-Z0-9' 'а-яёА-ЯЁ]+$/.test(this.nodeTitle)) {
        NotificationObserver.notification({
          type: "error",
          message: "Название должно состоять из a-z, A-Z, а-я, А-Я, 0-9 и пробел!"
        });
        return false;
      } else if (this.nodesArray.some((node) => node.name === this.nodeTitle)) {
        NotificationObserver.notification({
          type: "error",
          message: "Статус с таким наименованием уже существует"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal {
  top: 155px;
  left: 30px;
  position: absolute;
  z-index: 100;
  width: 400px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 16px;
  #draggable-area {
    width: 100%;
    height: 48px !important;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__header {
    margin-bottom: 24px;
    h3 {
      padding: 0;
      margin: 0;
      font-weight: 900;
      font-size: 20px;
      color: $text-default;
    }
  }
  &__body {
    p {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 18px;
    }
  }
  &__input {
    margin-bottom: 24px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    /deep/ {
      .aop-button {
        padding: 0;
      }
    }
  }
}
</style>
