<template>
  <div
    class="editable-user"
    v-click-outside="hideEditMode"
    :style="{ width: editMode ? '100%' : 'fit-content', 'min-width': user.id ? '' : '200px' }"
  >
    <div class="editable-user__label" v-if="label.length">
      <Label :label="label" />
      <i class="icon-edit" @click="enterEditMode" v-show="isEditable && !editMode"></i>
    </div>
    <div class="editable-user__wrap" v-show="!editMode">
      <User
        v-if="user.id"
        :avatar="user.avatar"
        :name="user.full_name"
        :id="user.id"
        :showInfo="showInfo"
        :info="setRole"
        :size="'44px'"
        :show-tooltip="false"
        class="editable-user__user"
      />
    </div>
    <div class="editable-user__select" v-show="editMode">
      <Select
        v-model="selected"
        :multiple="false"
        placeholder="Выберите сотрудника"
        :showButtonPanel="false"
        :items="items"
      />
    </div>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import Select from "@/modules/UiKit/components/Select/Select";
import ClickOutside from "vue-click-outside";
import Label from "@/modules/Isp/components/IspProblem/Label";

export default {
  name: "EditableUser",
  mixins: [],
  props: {
    label: {
      type: String,
      default: "ВЛАДЕЛЕЦ"
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: () => {
        return null;
      }
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  components: { Label, Select, User },
  data() {
    return {
      editMode: false,
      selected: []
    };
  },
  mounted() {
    this.selected = this.user && this.user.id ? [this.user] : [];
  },
  methods: {
    enterEditMode() {
      this.editMode = true;
    },
    hideEditMode() {
      this.editMode = false;
      if (this.selected[0]?.id !== this.user.id)
        this.$emit("editUser", Number(this.selected[0]?.id));
    }
  },
  computed: {
    setName() {
      return this.user ? this.user.full_name : "";
    },
    setRole() {
      if (this.user) {
        if (this.user.role_name) {
          return this.user.role_name;
        } else if (this.user.roles && this.user.roles.length) {
          return this.user.roles[0]?.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.editable-user {
  &__label {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    &:hover i {
      opacity: 1;
    }
    i {
      margin-left: 8px;
      font-size: 18px;
      color: $icon-subdued;
      transition: 0.3s all ease;
      cursor: pointer;
      opacity: 0;
      &:hover {
        color: $link-default;
      }
    }
    /deep/ {
      .label {
        margin: 0;
        label {
          font-size: 12px;
          font-weight: 700;
          color: $text-default;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    min-height: 44px;
  }
  &__select {
    margin-bottom: 24px;
  }
}
</style>
