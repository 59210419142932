<template>
  <div class="plan-table">
    <PlanTableFields :tableFields="tableFields" />
    <div class="plan-table__body">
      <PlanTableRow :step="initialStep" :isInitial="true" :disabled="disabled" />
      <PlanTableRow
        v-for="(step, index) in stepList"
        :key="step.id"
        :step="step"
        :initialStep="initialStep"
        :finalStep="finalStep"
        :stepList="stepList"
        :stepLinksList="stepLinksList"
        :plan="plan"
        :index="index"
        :disabled="disabled"
      />
      <PlanTableRow :plan="plan" :isForCreation="true" :disabled="disabled" />
      <PlanTableRow
        :step="finalStep"
        :initialStep="initialStep"
        :finalStep="finalStep"
        :isFinal="true"
        :stepList="stepList"
        :stepLinksList="stepLinksList"
        :plan="plan"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import PlanTableFields from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/PlanTableFields";
import PlanTableRow from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/PlanTableRow";
export default {
  name: "PlanTable",
  mixins: [],
  props: {
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    stepLinksList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { PlanTableRow, PlanTableFields },
  data() {
    return {
      tableFields: [
        {
          id: 0,
          name: "Номер"
        },
        {
          id: 1,
          name: "Действие"
        },
        {
          id: 2,
          name: "Связь (номер)",
          tooltip:
            "Строгая последовательность действий, определяющаяся указанием предшествующего действия. У всех действий, не имеющих связи, предшествующим является старт. У всех действий, не связанных с конечным шагом, автоматически появляется данная связь."
        },
        {
          id: 3,
          name: "Длительность (дн)"
        },
        {
          id: 4,
          name: "Ранняя дата начала",
          tooltip: "Плановая дата начала выполнения действия."
        },
        {
          id: 5,
          name: "Ранняя дата окончания ",
          tooltip: "Плановая дата окончания действия."
        },
        {
          id: 6,
          name: "Поздняя дата начала",
          tooltip:
            "Максимальное время, на которое можно передвинуть начало выполнения действия без нарушения сроков завершения плана работ в целом."
        },
        {
          id: 7,
          name: "Резерв (дн)",
          tooltip:
            "Количество рабочего времени, на которое может быть увеличена продолжительность действия или перенесено его начало без нарушения сроков завершения плана работ в целом."
        },
        {
          id: 8,
          name: "Поздняя дата окончания",
          tooltip:
            "Максимальное время, на которое можно увеличить продолжительность действия без нарушения сроков завершения плана работ в целом."
        }
      ]
    };
  },
  mounted() {},
  methods: {},
  computed: {
    initialStep() {
      return {
        id: "initial",
        name: "Старт",
        plan_id: this.plan?.id,
        relative_number: 1
      };
    },
    finalStep() {
      return {
        id: "final",
        name: "Конец",
        plan_id: this.plan?.id,
        relative_number: this.stepList.length + 2
      };
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.plan-table {
  border: 1px solid #f7f7fd;
  position: relative;
  height: 100%;
  &__body {
    height: calc(100% - 46px);
    overflow: auto;
  }
  /deep/ {
    .cell {
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        width: 4%;
      }
      &:nth-child(2) {
        width: 21%;
      }
      &:nth-child(3) {
        width: 12%;
      }
      &:nth-child(4) {
        width: 8%;
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        width: 11%;
      }
    }
  }
}
</style>
