<template>
  <div class="avatar">
    <div class="avatar__wrap" v-if="showImg">
      <img class="avatar__img" :src="returnSrcFile(user.avatar)" alt="avatar" v-if="user.avatar" />
      <span class="avatar__img" v-else :style="[user.color ? { backgroundColor: user.color } : '']">
        <span>{{ setChannelName }}</span>
      </span>
    </div>
    <div class="avatar__wrap avatar__wrap_text" v-if="showInfo">
      <h2 class="avatar__name">
        {{ user.full_name }}
        <span class="avatar__role" v-if="user.role_name && showRole">[{{ user.role_name }}]</span>
        <i class="icon-lock-secondary avatar__icon" v-if="isLock"></i>
        <i class="icon-eye avatar__icon" v-if="isOnlyRead"></i>
      </h2>
      <h4 class="avatar__tag">
        <span v-if="showTag">{{ setTag }}</span
        >{{ user.nickname }}
      </h4>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "Avatar",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    isLock: {
      type: Boolean,
      default: false
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    showRole: {
      type: Boolean,
      default: true
    },
    showTag: {
      type: Boolean,
      default: true
    },
    isOnlyRead: {
      type: Boolean,
      default: false
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    showImg: {
      type: Boolean,
      default: true
    },
    typeChannel: {
      type: Number,
      default: 1
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return this.typeChannel === 1
        ? domainStorage + src
        : domainStorage + "/chat/files/" + src.path;
    }
  },
  computed: {
    setChannelName() {
      return this.user.full_name[0].toUpperCase();
    },
    setTag() {
      if (this.isGroup) {
        return "#";
      } else {
        return "@";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.avatar {
  display: flex;
  align-items: center;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 16px;
    border: 2px solid $bg-gray-100;
    background-color: $message;
    object-fit: cover;
  }
  &__name-wrap {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  &__name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: $text-default;
    padding: 0;
    margin: 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: $lg) {
      max-width: 200px;
    }
    @media screen and (max-width: $md) {
      max-width: 300px;
    }
    @media screen and (max-width: $xs) {
      max-width: 110px;
    }

    i {
      font-size: 14px;
    }
    .icon-lock-secondary {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  &__tag {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }
  &__role {
    color: $text-subdued;
  }
}
</style>
