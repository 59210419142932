<template>
  <div class="date-picker" v-click-outside="hideModal">
    <div
      class="date-picker__value"
      :class="{ 'date-picker__value_active': showModal }"
      @click="showModal = !showModal"
    >
      <p><span v-if="deadline">ДО</span> {{ valueDateFormat }}</p>
      <i class="icon-calendar-secondary" v-if="!showModal"></i>
      <i class="icon-cross" v-else @click.stop="deleteDate"></i>
    </div>
    <div class="date-picker__calendar" v-if="showModal">
      <v-date-picker
        v-model="selectDate"
        :masks="masks"
        :attributes="attributes"
        :min-date="setMinDate"
      >
        <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
          <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
        </div>
        <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
      </v-date-picker>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";

export default {
  name: "DatePickerWidget",
  mixins: [],
  props: {
    value: {
      type: String,
      default: () => {
        return "";
      }
    },
    deadline: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  components: {},
  data() {
    return {
      showModal: false,
      selectDate: new Date(),
      masks: {
        weekdays: "WWW"
      },
      attributes: []
    };
  },
  mounted() {
    if (this.value) {
      this.selectDate = this.value;
    }
  },
  methods: {
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    hideModal() {
      this.showModal = false;
    },
    deleteDate() {
      this.$emit("input", new Date());
    }
  },
  computed: {
    valueDateFormat() {
      return this.deadline
        ? this.value
          ? moment(this.value).format("DD.MM.YYYY")
          : "Выберите дату"
        : this.value
        ? moment(this.value).format("DD.MM.YYYY")
        : moment().format("DD.MM.YYYY");
    },
    setMinDate() {
      return this.minDate ? this.minDate : new Date();
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.selectDate = this.value;
      }
    },
    selectDate() {
      if (this.selectDate !== null) {
        this.$emit("input", this.selectDate);
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    height: 32px;
    background: #ffffff;
    border: 2px solid #eae8f0;
    border-radius: 8px;
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;

    &_active {
      border: 2px solid #5551f9;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #323232;
      margin-top: 2px;
    }

    i {
      color: #a7a9c0;
    }
  }

  &__calendar {
    position: absolute;
    top: calc(100% + 1px);

    /deep/ {
      .vc-container {
        border: 1px solid #eae8f0;
        border-radius: 8px;
        padding: 8px 2px;
      }

      .vc-arrows-container {
        padding: 0;
        margin: 0 0 24px;
      }

      .vc-arrow {
        height: 21px;
      }

      .vc-svg-icon {
        width: 21px;
        height: 21px;

        path {
          fill: $icon-subdued;
        }
      }

      .vc-header {
        padding: 0;
        margin: 0 0 24px;
      }

      .month {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .vc-weekday {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $text-disabled;
      }

      .weekday-1 .vc-day-content,
      .weekday-7 .vc-day-content {
        color: $text-disabled;
      }

      .vc-day-content {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #000000;

        &:hover,
        &:focus {
          width: 40px;
          height: 40px;
          background: #5551f9 !important;
          border-radius: 4px !important;
          color: #ffffff !important;
        }
      }

      .vc-highlight.vc-highlight-base-middle {
        width: 40px;
        height: 40px;
        background: #fafafe !important;
      }

      .vc-highlight,
      .vc-highlight.vc-highlight-base-start,
      .vc-highlight.vc-highlight-base-end {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
      }

      .vc-day.is-not-in-month .vc-day-content {
        opacity: 1;
        color: #747474;
      }

      .is-disabled {
        color: $text-disabled;

        &:hover,
        &:focus {
          background: transparent !important;
          color: $text-disabled !important;
        }
      }
    }
  }
}
</style>
