<template>
  <router-link
    :to="{
      name: 'Task',
      params: { id: item.task_id },
      query: { alias: item.alias }
    }"
    class="subtask-item"
    :id="setId"
  >
    <div class="subtask-item__header">
      <p class="subtask-item__deadline"><span>Срок окончания:</span>{{ setDeadline }}</p>
      <div class="subtask-item__attachments">
        <p class="subtask-item__attachment-item">
          <i class="icon-comment-empty"></i>
          <span class="subtask-item__count">{{ item.count_comments && item.count_comments }}</span>
          <span class="subtask-item__tooltip">Комментариии</span>
        </p>
        <p class="subtask-item__attachment-item">
          <i class="icon-paper-clip-secondary"></i>
          <span class="subtask-item__count">{{ item.count_files && item.count_files }}</span>
          <span class="subtask-item__tooltip">Файлы</span>
        </p>
      </div>
    </div>
    <p class="subtask-item__name">
      {{ item.name }}
    </p>
  </router-link>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "SubtaskItem",
  mixins: [formatDateAndTime],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    },
    tasksData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      selectDate: new Date(),
      checkbox: false,
      showConfirmModal: false,
      target: null,
      inViewModel: true
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#${this.setId}`);
    const taskList = JSON.parse(JSON.stringify(this.tasksData)).reverse();
    if (taskList[0] && taskList[0].id === this.item.id) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.totalCount !== this.tasksData.length) {
              this.$emit("paginationEvent");
              this.observer.unobserve(this.target);
            }
          }
        });
      });
      this.observer.observe(this.target);
    }

    this.selectDate = this.item.date_to ? new Date(this.item.date_to) : null;
  },
  methods: {
    completeTask() {
      if (this.isEndStatus) {
        this.$emit("handleEdit", {
          id: this.item.id,
          status_id: this.setStatusesList.find((item) => item.is_start === true).id
        });
      } else {
        this.showConfirmModal = true;
      }
    },
    setCompleteStatus() {
      this.$emit("handleEdit", {
        id: this.item.id,
        status_id: this.setStatusesList.find((item) => item.is_end === true).id
      });
      this.showConfirmModal = false;
    }
  },
  computed: {
    isEdit() {
      return this.isOwner || this.isAuthor || this.isExecutor;
    },
    setStatusesList() {
      return TaskModule.statusesList;
    },
    setStatus() {
      return this.setStatusesList.find((item) => item.id === this.item.status_id);
    },
    isEndStatus() {
      return this.setStatus ? this.setStatus.is_end : false;
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    setConfirmParams() {
      return {
        title: "Выполнить подзадачу",
        description: `Вы уверены, что хотите выполнить подзадачу?`
      };
    },
    setId() {
      return `subtask_modal_row_${this.item.id}`;
    },
    setPriorityList() {
      return TaskModule.prioritiesList;
    },
    setPriority() {
      return this.setPriorityList.find((item) => item.id === this.item.priority_id);
    },
    setDeadline() {
      return this.item.date_to ? this.setDateFormatFull(this.item.date_to) : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.subtask-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 24px;
  border-bottom: 1px solid #eae8f0;

  @media (max-width: $sm) {
    padding: 16px;
  }

  &:hover {
    background-color: $surface-selected-default;
    border-radius: 2px;
  }

  /deep/ {
    .confirm-modal__footer .aop-button_cancel {
      margin-left: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;

    /deep/ {
      .task-type {
        width: 90px;
        height: 30px;
        margin-right: 24px;

        &__label {
          padding: 4px 8px;
        }
      }
    }
  }

  &__deadline {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    cursor: default;

    span {
      color: $text-subdued;
      margin-right: 8px;

      @media (max-width: $sm) {
        margin-right: 4px;
      }
    }
  }

  &__attachments {
    display: flex;
    margin-left: auto;
  }

  &__name {
    display: flex;
    margin: 24px 0 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: $text-default;
    @include textEllipsis(2);

    @media (max-width: 1740px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: 20px;
    right: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: $surface-default;
    background: $text-default;
    border-radius: 2px;
  }

  &__attachment-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 16px;

    @media (max-width: $sm) {
      margin-right: 8px;
    }

    i {
      color: $icon-subdued;
      margin-right: 8px;
      font-size: 18px;

      @media (max-width: $sm) {
        margin-right: 4px;
      }
    }

    &:hover .subtask-item__tooltip {
      opacity: 1;
    }
  }

  &__count {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $text-default;
    cursor: default;
  }
}
</style>
