






import { Component, Mixins, Prop } from "vue-property-decorator";
import { UrlClassMixin } from "@/helpers/UrlClassMixin";

@Component
export default class TextLabel extends Mixins(UrlClassMixin) {
  @Prop({
    default: "span",
    type: String
  })
  tag!: string;
  @Prop({
    default: "",
    type: String
  })
  text!: string;
  @Prop({
    default: "",
    type: String
  })
  tooltip!: string;
}
