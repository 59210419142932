







































import { Component, Vue } from "vue-property-decorator";
import ControlPanel from "@/aopV2/layout/LayoutMain/ControlPanel/ControlPanel.vue";
import MainHeader from "@/aopV2/layout/LayoutMain/MainHeader/MainHeader.vue";
import MainSidebar from "@/aopV2/layout/LayoutMain/MainSidebar/MainSidebar.vue";
import MainTabs from "@/aopV2/layout/LayoutMain/MainTabs/MainTabs.vue";
import ResizeObserver from "resize-observer-polyfill";
import ConfirmationModal from "@/aopV2/layout/LayoutMain/Modal/ModalConfirm/ConfirmationModal";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import FullScreen from "@/aopV2/components/BaseSection/FullScreen.vue";
import MobileButton from "@/aopV2/layout/LayoutMain/MobileButton/MobileButton.vue";
import MainBreadcrumbs from "@/aopV2/layout/LayoutMain/MainBreadcrumbs/MainBreadcrumbs.vue";

@Component({
  components: {
    MainBreadcrumbs,
    MobileButton,
    FullScreen,
    MainTabs,
    MainSidebar,
    ConfirmationModal,
    MainHeader,
    ControlPanel
  }
})
export default class BaseSection extends Vue {
  content: HTMLBaseElement | null = null;
  ro: ResizeObserver | null = null;
  timerId: number = 0;

  mounted() {
    this.content = this.$el.querySelector(".content");
    this.ro = new ResizeObserver((entries) => {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          if (entry.target.className === "content") {
            this.setContentSize({ height: cr.height, width: cr.width });
          }
        }
      }, 10);
    });
    this.ro.observe(this.content!);
  }

  beforeDestroy() {
    if (this.ro) this.ro.unobserve(this.content!);
  }

  setContentSize(size: { height: number; width: number }) {
    this.$store.commit("MainLayoutSetting/setContentSize", size);
  }

  updateInfo() {
    this.$emit("updateInfo");
  }

  updateType() {
    this.$emit("updateType");
  }

  get leftComponent() {
    return this.mainSetting && this.mainSetting.leftPanel ? this.mainSetting.leftPanel : null;
  }

  get rightComponent() {
    return this.mainSetting && this.mainSetting.rightPanel ? this.mainSetting.rightPanel : null;
  }

  get mobileButton() {
    return this.mainSetting && this.mainSetting.mobileButton ? this.mainSetting.mobileButton : null;
  }

  get mainSetting() {
    return this.$store.getters["MainLayoutSetting/setting"];
  }

  get modalConfig() {
    return ModalModule.confirmSetting;
  }

  get isShowModalConfig() {
    return ModalModule.isShowConfirmation;
  }
}
