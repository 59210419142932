import { ChildrenRouter, RootRouter } from "@/router/baseRouter";
import MainSwitch from "@/layout/MainSwitch.vue";

const profileRouter = new RootRouter(
  "Profile",
  "Profile",
  "Profile/Info",
  () => import("@/views/Profile/Profile"),
  {
    title: "Информация сотрудника",
    requiresAuth: true,
    menu: {
      isShow: true,
      icon: "Profile",
      name: "Profile",
      label: "Личный кабинет"
    }
  },
  [
    new ChildrenRouter(
      "Info",
      "Info",
      () => import("@/components/ProfileComponents/ProfilePersonalArea/ProfilePersonalArea"),
      {
        title: "Мой профиль",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "Info",
          label: "Мой профиль"
        }
      }
    ),
    new ChildrenRouter(
      "Folders",
      "Folders",
      () => import("@/components/ProfileComponents/JobFolderComponents/JobFolder"),
      {
        title: "Обязанности",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "Folders",
          label: "Обязаннсоти"
        }
      }
    ),
    new ChildrenRouter(
      "Education",
      "Education",
      () => import("@/components/ProfileComponents/ProfileEducation/ProfileEducation"),
      {
        title: "Образование",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "Education",
          label: "Образование"
        }
      }
    ),
    new ChildrenRouter(
      "History",
      "History",
      () => import("@/components/ProfileComponents/ProfileHistory/ProfileHistory"),
      {
        title: "Карьера",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "History",
          label: "Карьера"
        }
      }
    ),
    new ChildrenRouter(
      "VacationOrder",
      "VacationRequests",
      () => import("@/components/ProfileComponents/ProfileVacationRequests/VacationRequests"),
      {
        title: "Запросы",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "VacationRequests",
          label: "Запросы"
        }
      }
    )
  ]
);

const calendarRouter = new RootRouter(
  "Calendar",
  "Calendar",
  "Calendar/Person",
  () => import("@/aopV2/modules/Calendar/Calendar"),
  {
    title: "Календарь",
    requiresAuth: true,
    menu: {
      name: "Calendar",
      label: "Календарь",
      isShow: true,
      icon: "Calendar"
    }
  },
  [
    new ChildrenRouter("Person", "Person", () => import("@/aopV2/modules/Calendar/CalendarView"), {
      title: "Личный календарь",
      requiresAuth: true,
      menu: {
        isShow: false,
        name: "Person",
        label: "Личный календарь"
      }
    }),
    new ChildrenRouter(
      "CreateEvent",
      "CreateEvent",
      () => import("@/aopV2/modules/Calendar/components/Modal/CreateEvent/CreateEventFS"),
      {
        title: "Личный календарь",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "Person",
          label: "Личный календарь"
        }
      }
    ),
    new ChildrenRouter("Work", "Work", () => import("@/aopV2/modules/Calendar/CalendarView"), {
      title: "Рабочий календарь",
      requiresAuth: true,
      menu: {
        isShow: false,
        name: "Work",
        label: "Рабочий календарь"
      }
    }),
    new ChildrenRouter("Events", "Events", () => import("@/aopV2/modules/Calendar/CalendarView"), {
      title: "Мероприятия",
      requiresAuth: true,
      menu: {
        isShow: false,
        name: "Events",
        label: "Мероприятия"
      }
    })
  ]
);
const newsRouter = new RootRouter(
  "News",
  "News",
  "",
  () => import("@/modules/News/views/NewsView"),
  {
    title: "Новости",
    requiresAuth: true,
    menu: {
      name: "News",
      label: "Лента",
      isShow: true,
      icon: "News"
    }
  }
);
const timeRouter = new RootRouter(
  "WorkTime",
  "WorkTime",
  "",
  () => import("@/modules/TimeTracker/WorkTime"),
  {
    title: "WorkTime",
    requiresAuth: true,
    menu: {
      name: "WorkTime",
      label: "WorkTime",
      isShow: false,
      icon: "time"
    }
  }
);
const tableRouter = new RootRouter(
  "NewTable",
  "NewTable",
  "",
  () => import("@/modules/NewTable/NewTable"),
  {
    title: "NewTable",
    requiresAuth: true,
    menu: {
      name: "NewTable",
      label: "NewTable",
      isShow: false,
      icon: "time"
    }
  }
);

const messengerRouter = new RootRouter(
  "Messenger",
  "Messenger",
  "",
  () => import("@/modules/Messenger/views/MessengerView"),
  {
    title: "Чат",
    requiresAuth: true,
    menu: {
      name: "Messenger",
      label: "Чат",
      isShow: true,
      icon: "Messenger"
    }
  }
);
const ispRouter = new RootRouter(
  "ISP",
  "ISP",
  "ISP/IspReview",
  () => import("@/modules/Isp/views/IspView"),
  {
    title: "ISP",
    requiresAuth: true,
    menu: { name: "ISP", label: "ISP", isShow: true, icon: "Isp" }
  },
  [
    new ChildrenRouter(
      "IspReview",
      "IspReview",
      () => import("@/modules/Isp/views/IspReview/IspReview"),
      {
        title: "Обзор",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "IspReview",
          label: "Обзор"
        }
      }
    ),
    new ChildrenRouter("IspMy", "IspMy", () => import("@/modules/Isp/views/IspMy/IspMy"), {
      title: "Мое ISP",
      requiresAuth: true,
      menu: {
        isShow: false,
        name: "IspMy",
        label: "Мое ISP"
      }
    }),
    new ChildrenRouter(
      "IspTeams",
      "IspTeams",
      () => import("@/modules/Isp/views/IspTeams/IspTeams"),
      {
        title: "Команды",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "IspTeams",
          label: "Команды"
        }
      }
    ),
    new ChildrenRouter(
      "Problem/:id",
      "IspProblem",
      () => import("@/modules/Isp/views/IspProblem/IspProblem"),
      {
        title: "Проблема ISP",
        requiresAuth: true
      }
    )
  ]
);
const sectionRouter = new RootRouter(
  "Section",
  "Section",
  "Section/New",
  () => import("@/views/KnowledgeBase/KnowledgeBase"),
  {
    title: "База знаний",
    requiresAuth: true,
    menu: { isShow: true, name: "Section", label: "База знаний", icon: "Section" }
  },
  [
    new RootRouter("New", "New", "", () => import("@/views/KnowledgeBase/NewSection/NewSection"), {
      title: "Новое",
      requiresAuth: true,
      menu: {
        isShow: false,
        name: "New",
        label: "Новое"
      }
    }),
    new RootRouter(
      "Books",
      "Books",
      "",
      () => import("@/views/KnowledgeBase/BooksSection/BooksSection"),
      {
        title: "Книги",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "Books",
          label: "Книги"
        }
      },
      [
        new ChildrenRouter(
          ":category_name",
          "BooksPage",
          () => import("@/views/KnowledgeBase/BooksSection/BooksSection"),
          {
            title: "Книги по категориям",
            requiresAuth: true
          }
        )
      ]
    ),
    new ChildrenRouter(
      "Book/:book_id",
      "BookPage",
      () => import("@/views/KnowledgeBase/BooksSection/BookPage"),
      {
        title: "Книга",
        requiresAuth: true
      }
    ),
    new RootRouter(
      "Regulations",
      "Regulations",
      "",
      () => import("@/views/KnowledgeBase/Regulations/RegulationsPage"),
      {
        title: "Регламенты",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "Regulations",
          label: "Регламенты"
        }
      },
      [
        new ChildrenRouter(
          ":category_name",
          "RegulationsPage",
          () => import("@/views/KnowledgeBase/Regulations/RegulationsPage"),
          {
            title: "Регламенты по категориям",
            requiresAuth: true
          }
        )
      ]
    ),
    new ChildrenRouter(
      "Regulation/:regulation_id",
      "RegulationPage",
      () => import("@/views/KnowledgeBase/Regulations/RegulationPage"),
      {
        title: "Регламент",
        requiresAuth: true
      }
    ),
    new RootRouter(
      "Articles",
      "Articles",
      "",
      () => import("@/views/KnowledgeBase/Articles/ArticlesPage"),
      {
        title: "Статьи",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "Articles",
          label: "Статьи"
        }
      },
      [
        new ChildrenRouter(
          ":category_name",
          "ArticlesPage",
          () => import("@/views/KnowledgeBase/Articles/ArticlesPage"),
          {
            title: "Статьи",
            requiresAuth: true
          }
        )
      ]
    ),
    new ChildrenRouter(
      "Article/:article_id",
      "ArticlePage",
      () => import("@/views/KnowledgeBase/Articles/ArticlePage"),
      {
        title: "Статья",
        requiresAuth: true
      }
    )
  ]
);
const vacationRouter = new RootRouter(
  "Vacation",
  "Vacation",
  "Vacation/Schedule",
  () => import("@/views/GraphVacation/Vacation"),
  {
    title: "График отпусков",
    requiresAuth: true,
    menu: { name: "Vacation", label: "График отпусков", isShow: true, icon: "Vacation" }
  },
  [
    new ChildrenRouter(
      "Schedule",
      "Schedule",
      () => import("@/views/GraphVacation/GraphVacation"),
      {
        title: "График отпусков",
        requiresAuth: true
      }
    ),
    new ChildrenRouter(
      "Order",
      "VacationOrderPersonnelOfficer",
      () => import("@/views/VacationRequests/VacationRequests"),
      {
        title: "Заявки на отпуск",
        requiresAuth: true
      }
    )
  ]
);

const workForceRouter = new RootRouter(
  "Workforce",
  "Workforce",
  "Workforce/Users",
  () => import("@/modules/Tables/views/TablesCompany"),
  {
    title: "Компания",
    requiresAuth: true,
    menu: { name: "Workforce", label: "Компания", isShow: true, icon: "Workforce" }
  },
  [
    new ChildrenRouter(
      "Users",
      "UserInfoTable",
      () => import("@/modules/Tables/components/TablesCompany/Users/UsersMain"),
      {
        title: "Сотрудники",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "UserInfoTable",
          label: "Сотрудники"
        }
      }
    ),
    new ChildrenRouter(
      "Education",
      "UserEducationTable",
      () => import("@/modules/Tables/components/TablesCompany/Education/Education"),
      {
        title: "Образование",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "UserEducationTable",
          label: "Образование"
        }
      }
    ),
    // new ChildrenRouter(
    //   "Roles",
    //  "UserRolesTable",
    //   "@/modules/Tables/components/TablesCompany/Roles/Roles"
    // ),
    // TODO: Пока модуль не нужен
    // new ChildrenRouter(
    //   "Tests",
    //   "UserTestsTable",
    //   () => import("@/modules/Tables/components/TablesCompany/Tests/Tests")
    // ),
    // new ChildrenRouter(
    //   "Test/:test_id",
    //   "UserTest",
    //   () => import("@/modules/Tables/components/TablesCompany/Tests/TestPage")
    // ),
    new ChildrenRouter(
      "OrgStructure",
      "OrgStructure",
      () => import("@/views/Structure/Structure"),
      {
        title: "Структура компании",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "OrgStructure",
          label: "Структура компании"
        }
      }
    )
  ]
);

const statisticsRouter = new RootRouter(
  "Statistics",
  "Statistics",
  "Statistics/Time",
  () => import("@/modules/Tables/views/TablesStatistics"),
  {
    title: "Оценки ЧШ",
    requiresAuth: true,
    menu: { name: "Statistics", label: "Статистика", isShow: true, icon: "Statistics" }
  },
  [
    new ChildrenRouter(
      "BlackSphereMarks",
      "BlackSphereMarksTable",
      () =>
        import("@/modules/Tables/components/TablesStatistics/BlackSphereMarks/BlackSphereMarks"),
      {
        title: "Черный шар",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "BlackSphereMarksTable",
          label: "Черный шар"
        }
      }
    ),
    new ChildrenRouter(
      "BlackSphereStatistics",
      "BlackSphereStatisticsTable",
      () =>
        import(
          "@/modules/Tables/components/TablesStatistics/BlackSphereStatistics/BlackSphereStatistics"
        ),
      {
        title: "Оценки ЧШ",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "BlackSphereStatisticsTable",
          label: "Оценки ЧШ"
        }
      }
    ),
    new ChildrenRouter(
      "Time",
      "UsersTimeTable",
      () => import("@/modules/Tables/components/TablesStatistics/Time/Time"),
      {
        title: "Время",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UsersTimeTable",
          label: "Время"
        }
      }
    ),
    new ChildrenRouter(
      "Absence",
      "UsersAbsenceTable",
      () => import("@/modules/Tables/components/TablesStatistics/Absence/Absence"),
      {
        title: "Отсутсвия",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UsersAbsenceTable",
          label: "Отсутсвия"
        }
      }
    )
    //todo_dm: закомментировано по причине ненадобности страницы (компоненты не удалены)
    // new ChildrenRouter(
    //   "Activity",
    //   "UsersActivityTable",
    //   () => import("@/modules/Tables/components/TablesStatistics/Activity/Activity"),
    //   {
    //     title: "Активность",
    //     requiresAuth: true,
    //     menu: {
    //       isShow: false,
    //       name: "UsersActivityTable",
    //       label: "Активность"
    //     }
    //   }
    // )
  ]
);
const userRouter = new RootRouter(
  "User",
  "User",
  "Profile/Info",
  () => import("@/views/UserCard/UserCard2.0"),
  {
    title: "Информация сотрудника",
    requiresAuth: true,
    menu: { name: "User", label: "Информация сотрудника", isShow: false }
  },
  [
    new ChildrenRouter(
      "Info/:user_id",
      "UserInfo",
      () => import("@/components/ProfileComponents/ProfilePersonalArea/ProfilePersonalArea"),
      {
        title: "Информация сотрудника",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UserInfo",
          label: "Информация сотрудника"
        }
      }
    ),
    new ChildrenRouter(
      "Folders/:user_id",
      "UserFolders",
      () => import("@/components/ProfileComponents/JobFolderComponents/JobFolder"),
      {
        title: "Обязанности",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UserFolders",
          label: "Обязаннсоти"
        }
      }
    ),
    new ChildrenRouter(
      "UserEducation/:user_id",
      "CurrentUserEducation",
      () => import("@/components/ProfileComponents/ProfileEducation/ProfileEducation"),
      {
        title: "Образование",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "CurrentUserEducation",
          label: "Образование"
        }
      }
    ),
    new ChildrenRouter(
      "History/:user_id",
      "UserHistory",
      () => import("@/components/ProfileComponents/ProfileHistory/ProfileHistory"),
      {
        title: "Карьера",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UserHistory",
          label: "Карьера"
        }
      }
    ),
    new ChildrenRouter(
      "VacationOrder/:user_id",
      "UserVacationRequests",
      () => import("@/components/ProfileComponents/ProfileVacationRequests/VacationRequests"),
      {
        title: "Запросы",
        requiresAuth: true,
        menu: {
          isShow: false,
          name: "UserVacationRequests",
          label: "Запросы"
        }
      }
    )
  ]
);

const planRouter = new RootRouter(
  "TaskManager/Plan/Table",
  "Plan",
  "",
  () => import("@/modules/TaskManager/views/PlanningSchedule/Plan.vue"),
  {
    menu: {
      title: "Plan",
      requiresAuth: true,
      isShow: false
    }
  }
);
const projectNetworkRouter = new RootRouter(
  "TaskManager/Plan/Graph",
  "ProjectNetwork",
  "",
  () => import("@/modules/TaskManager/views/PlanningSchedule/ProjectNetwork.vue"),
  {
    menu: {
      title: "ProjectNetwork",
      requiresAuth: true,
      isShow: false
    }
  }
);
const taskManagerRouter = new RootRouter(
  "TaskManager",
  "TaskManager",
  "TaskManager/MyDashboard",
  () => import("@/modules/TaskManager/views/TaskManagerView"),
  {
    title: "TaskManager",
    requiresAuth: true,
    menu: {
      name: "TaskManager",
      label: "Таск Менеджер",
      isShow: true,
      icon: "Task"
    }
  },
  [
    new ChildrenRouter(
      "MyDashboard",
      "MyDashboard",
      () => import("@/modules/TaskManager/views/MyDashboard"),
      {
        title: "Мой Dashboard",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "MyDashboard",
          label: "Мой Dashboard"
        }
      }
    ),
    new ChildrenRouter(
      "MyProjects",
      "MyProjects",
      () => import("@/modules/TaskManager/views/MyProjects"),
      {
        title: "Мои проекты",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "MyProjects",
          label: "Мои проекты"
        }
      }
    ),
    new ChildrenRouter(
      "Project/:id",
      "Project",
      () => import("@/modules/TaskManager/views/Project"),
      {
        title: "Проект",
        requiresAuth: true
      }
    ),
    new ChildrenRouter(
      "Project/:project_id/Flowchart/:flow_id",
      "Flowchart",
      () => import("@/modules/TaskManager/views/Flowchart"),
      {
        title: "Статусы",
        requiresAuth: true
      }
    ),
    new ChildrenRouter(
      "KanbanSetting/:id",
      "KanbanSetting",
      () => import("@/modules/TaskManager/views/KanbanSetting"),
      {
        title: "Канбан настройка",
        requiresAuth: true
      }
    ),
    new ChildrenRouter(
      "WorkPlans",
      "WorkPlans",
      () => import("@/modules/TaskManager/views/PlanningSchedule/WorkPlans.vue"),
      {
        title: "Сетевой график",
        requiresAuth: true,
        menu: {
          isShow: true,
          name: "WorkPlans",
          label: "Сетевой график"
        }
      }
    ),

    new ChildrenRouter("MyTasks", "MyTasks", () => import("@/modules/TaskManager/views/MyTasks"), {
      title: "Мои задачи",
      requiresAuth: true,
      menu: {
        isShow: true,
        name: "MyTasks",
        label: "Мои задачи"
      }
    }),
    new ChildrenRouter("Task/:id", "Task", () => import("@/modules/TaskManager/views/Task"), {
      title: "Задача",
      requiresAuth: true
    }),
    new ChildrenRouter(
      "Task/:parent_task_id/:id",
      "Subtask",
      () => import("@/modules/TaskManager/views/Task"),
      {
        title: "Подзадача",
        requiresAuth: true
      }
    )
    // new ChildrenRouter(
    //   "Plan/Graph",
    //   "ProjectNetwork",
    //   () => import("@/modules/TaskManager/views/PlanningSchedule/ProjectNetwork.vue"),
    //   {
    //     title: "ProjectNetwork",
    //     requiresAuth: true,
    //     isShow: true
    //   }
    // )
  ]
);
const notificationRouter = new RootRouter(
  "Notification",
  "Notification",
  "",
  () => import("@/modules/Notification/views/NotificationSettings"),
  {
    title: "Notification",
    requiresAuth: true,
    menu: {
      name: "Notification",
      label: "Notification",
      isShow: false,
      icon: "notification"
    }
  }
);

export const mainRouter = {
  path: "/",
  name: "Main",
  redirect: `Profile/Info`,
  component: MainSwitch,
  meta: {
    title: "Layout1",
    requiresAuth: true,
    menu: {
      name: "main",
      label: "Меню",
      isShow: false
    }
  },
  children: [
    timeRouter,
    profileRouter,
    messengerRouter,
    newsRouter,
    taskManagerRouter,
    planRouter,
    projectNetworkRouter,
    workForceRouter,
    calendarRouter,
    ispRouter,
    vacationRouter,
    statisticsRouter,
    sectionRouter,
    tableRouter,
    userRouter,
    notificationRouter
  ]
};

export const menuList = mainRouter.children
  .map((item) => {
    return { ...item.menu };
  })
  .filter((item) => item.menu.isShow)
  .map((item) => {
    return { ...item };
  });
