<template>
  <div class="table__head">
    <div class="table__cell" v-for="(cell, index) in tableHeadList" :key="`cell-${index}`">
      {{ cell.name }}
      <button
        class="table__sort"
        type="button"
        v-if="cell.sort"
        :class="{
          asc: false,
          active: isActive(cell.valueSort)
        }"
        @click="setSort(cell.valueSort)"
      >
        <i class="icon-swap-vert"></i>
      </button>
    </div>
  </div>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";

export default {
  name: "MyTasksTableFields",
  mixins: [],
  props: {
    tableHeadList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setSort(val) {
      TaskModule[TaskMutationTypes.SET_LOADER](true);
      TaskModule[TaskMutationTypes.RESET_TASK_LIST]();
      TaskModule[TaskMutationTypes.SET_FROM_PAGE](1);
      TaskModule[TaskMutationTypes.SET_VALUE_SORT](val);
      this.$emit("updateInfo", this.setParams);
    },
    isActive(val) {
      return val === this.valueSort.value;
    }
  },
  computed: {
    valueSort() {
      return TaskModule.valueSort;
    },
    setParams() {
      return {
        page: TaskModule.fromPag,
        per_page: 15,
        projects: TaskModule.params.projects,
        status: TaskModule.params.status,
        role: TaskModule.params.role,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__head {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 9;
    padding: 8px 0;
    margin: 0 0 24px;
    background: $surface-selected-default;
    border-radius: 8px;

    @media (max-width: $lg) {
      width: 1065px;
    }
  }

  &__cell {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $text-primary;
    text-transform: uppercase;
    flex-shrink: 1;

    &:first-child {
      width: 30%;
      flex-shrink: 0;
      padding-left: 24px;

      @media (max-width: $lg) {
        width: 300px;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:last-child {
      width: 20%;

      @media (max-width: $lg) {
        width: 115px;
      }
    }

    &:nth-child(3) {
      width: 20%;

      @media (max-width: $lg) {
        width: 150px;
      }
    }

    &:nth-child(5) {
      width: 20%;

      @media (max-width: $lg) {
        width: 150px;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 8px;
    outline: none;
    transition: all 0.3s ease-in-out;

    i {
      color: $text-primary;
      font-size: 14px;
    }
  }

  .asc {
    transform: rotate(180deg);
  }
}

.active {
  i {
    color: #5551f9;
    font-weight: bold;
  }
}
</style>
