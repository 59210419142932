











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TimerButton extends Vue {
  @Prop({ type: Boolean }) isActive!: boolean;
  @Prop({ type: Boolean }) isLarge!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
}
