<template>
  <table class="table" ref="tableContainer" :class="{ table__empty: !tableData.length }">
    <TableRow v-for="row in tableData" :tableHeadList="tableHead" :key="row.id" :row="row">
      <slot slot-scope="props" :row="props.row" :cell="props.cell"></slot>
    </TableRow>
    <tr class="table__row table__row_empty" v-if="!tableData.length && !loaderTable && showEmpty">
      <td class="table__empty">Нет записей :(</td>
    </tr>
  </table>
</template>

<script>
import TableRow from "@/modules/Isp/components/Table/TableRow";
import { mapState } from "vuex";

export default {
  name: "Table",
  mixins: [],
  props: {
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showHead: {
      type: Boolean,
      default: true
    },
    showEmpty: {
      type: Boolean,
      default: true
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    TableRow
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("IspModule", ["loaderTable"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

//::-webkit-scrollbar {
//  height: 4px;
//  width: 4px;
//  background-color: rgba(#d3d2e0, 0.5);
//  border-radius: 16px;
//  margin: 5px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: #eae8f0;
//  border-radius: 16px;
//  cursor: pointer;
//}

.table {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  height: 100%;

  &__loader {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .short {
    height: 10%;
  }

  &__row {
    &_empty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70%;

      @media (max-width: 1500px) {
        width: 85%;
      }

      @media (max-width: $xxxl) {
        width: 75%;
      }

      @media (max-width: $xxl) {
        width: 65%;
      }

      @media (max-width: $xl) {
        width: 50%;
      }

      @media (max-width: $xs) {
        width: 24%;
      }

      @media (max-width: $xxs) {
        width: 22%;
      }

      .table__empty {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }
    }
  }

  /deep/ {
    .table__cell {
      &:first-child {
        max-width: 180px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: 120px;
      }

      &:nth-child(5) {
        max-width: 140px;
      }

      &:nth-child(6),
      &:last-child {
        max-width: 150px;
      }
    }
  }
}
</style>
