<template>
  <router-link class="book-bg" :to="{ name: 'BookPage', params: { book_id: this.item.id } }">
    <div v-if="label" class="book-label">Книги</div>
    <img v-if="item.cover" :src="returnSrcFile(item.cover)" alt="cover" />
    <div class="book-info">
      <LikeComponent
        :parentLike="item"
        :likeableType="'books'"
        @updateLikeInfo="$emit('updateInfoBook')"
      />
    </div>
  </router-link>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import LikeComponent from "@/components/KnowledgeBaseComponents/LikeComponents/LikeComponent";

export default {
  name: "BookComponent",
  mixins: [],
  props: ["item", "label"],
  components: { LikeComponent },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";

.book-bg {
  position: relative;
  width: 234px;
  height: 330px;
  border-radius: 5px 5px 0 0;
  border: 1px solid $color-contour;
  cursor: pointer;

  .book-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97px;
    height: 26px;
    position: absolute;
    top: 13px;
    color: $color-white;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 0 5px 5px 0;
    background-color: rgba(141, 150, 236, 0.8);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  .book-info {
    display: flex;
    position: absolute;
    width: 100%;
    height: 0;
    border-radius: 5px;
    background: $color-white;
    bottom: 0;
    transition: 0.3s;
    padding: 0;

    .like-bg {
      display: flex;
      align-items: center;
      transition: 0.5s;
      opacity: 0;
      transform: scale(0);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      color: $color-text-primary-2;

      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }
}

.book-bg:hover {
  .book-info {
    height: 50px;
    padding: 10px;

    .like-bg {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
