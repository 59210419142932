import { render, staticRenderFns } from "./ChosenFilterItem.vue?vue&type=template&id=32e83841&scoped=true&"
import script from "./ChosenFilterItem.vue?vue&type=script&lang=js&"
export * from "./ChosenFilterItem.vue?vue&type=script&lang=js&"
import style0 from "./ChosenFilterItem.vue?vue&type=style&index=0&id=32e83841&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e83841",
  null
  
)

export default component.exports