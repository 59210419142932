<template>
  <table class="table" ref="tableContainer" :class="{ table__empty: !tableData.length }">
    <tr class="table__loader" v-show="isShowLoader">
      <Loader />
    </tr>
    <TableHeader
      :tableHeadList="tableHead"
      :filterActive="filterActive"
      @updateResults="updateResults"
      v-if="tableData.length && !isShowLoader"
    />
    <tbody v-show="!isShowLoader && !isLoader">
      <TableRow v-for="row in tableData" :tableHeadList="tableHead" :key="row.id" :row="row">
        <slot slot-scope="props" :row="props.row" :cell="props.cell"></slot>
      </TableRow>
      <PaginationObserver @intersect="intersected" />
      <tr
        class="table__row table__row_empty"
        v-if="!tableData.length && !loaderTable && !isShowLoader && !isLoader"
      >
        <td class="table__empty">{{ emptyText }}</td>
      </tr>
    </tbody>
    <tr
      class="table__loader"
      :class="{ short: tableData.length }"
      v-show="loaderTable || loader || isLoader"
    >
      <Loader />
    </tr>
  </table>
</template>

<script>
import TableHeader from "@/modules/Tables/components/Table/TableHeader";
import TableRow from "@/modules/Tables/components/Table/TableRow";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import { mapActions, mapState } from "vuex";
import BlackSphere from "@/modules/BlackSphere/store";
import PaginationObserver from "@/components/PaginationObserver/PaginationObserver";

export default {
  name: "Table",
  mixins: [],
  props: {
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isScrollObserver: {
      type: Boolean,
      default: true
    },
    isShowLoader: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    },
    isWorkTime: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fromPage: {
      type: Number,
      default: 1
    },
    emptyText: {
      type: String,
      default: "Нет записей :("
    }
  },
  components: { PaginationObserver, TableRow, TableHeader, Loader },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["getTableData"]),
    intersected() {
      if (this.isWorkTime) {
        this.$emit("getTableData");
      } else {
        this.getTableData();
      }
    }
  },
  computed: {
    ...mapState("TablesModules", ["loaderTable", "tableParams"]),
    isLoader() {
      return BlackSphere.loader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  position: relative;
  display: flex;
  padding: 0 0 5px 0;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  height: 100%;

  &__loader {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    padding-top: 40px;
  }

  .short {
    height: 10%;
  }

  &__row {
    &_empty {
      width: 100%;
      padding-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70%;

      @media (max-width: 1500px) {
        width: 85%;
      }

      @media (max-width: $xxxl) {
        width: 75%;
      }

      @media (max-width: $xxl) {
        width: 65%;
      }

      @media (max-width: $xl) {
        width: 50%;
      }

      @media (max-width: $xs) {
        width: 24%;
      }

      @media (max-width: $xxs) {
        width: 22%;
      }

      .table__empty {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }
    }
  }
}
</style>
