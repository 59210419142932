<template>
  <section class="flowchart">
    <ConfirmModal
      v-if="confirmDeleteStatus"
      :title="'Удалить статус?'"
      :description="'В данном статусе находятся задачи, для его удаления необходимо указать статус, который будет присвоен задачам, в противном случае статус удалить нельзя.'"
      :acceptTitle="'Перенести и удалить'"
      :acceptType="statusTo.length ? '' : 'disabled'"
      @cancel="cancelDeleteStatus"
      @accept="acceptDeleteStatus"
    >
      <div class="select-another-status">
        <p>Перенести в статус</p>
        <Select
          class="select-another-status"
          :items="statusItems"
          v-model="statusTo"
          :multiple="false"
          :showAvatar="false"
          :placeholder="'Статус'"
        />
      </div>
    </ConfirmModal>
    <ConfirmModal
      v-if="confirmConfigureKanban"
      :title="'Отредактировать kanban-доску?'"
      :description="'В вашем бизнес-процессе появились новые статусы. Их необходимо добавить на kanban-доску проекта для лучшего управления процессом. Перейти в режим настройки kanban-доски сейчас?'"
      :acceptTitle="'Перейти'"
      @cancel="cancelConfigureKanban"
      @accept="acceptConfigureKanban"
    >
    </ConfirmModal>
    <ConfirmModal
      v-if="confirmExitFlowchart"
      :title="'Выйти из режима настройки?'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="cancelExitFlowchart"
      @accept="acceptExitFlowchart"
    >
    </ConfirmModal>
    <ConfirmModal
      v-if="confirmSaveModel"
      :title="'Сохранить?'"
      :description="confirmModalSaveModelText"
      @cancel="cancelSaveModel"
      @accept="saveModel"
    >
    </ConfirmModal>
    <AddNodeModal
      v-if="showAddNodeModal"
      @closeModal="closeAddNodeModal"
      @addNode="addNode"
      :nodesArray="statusList"
    />
    <AddLinkModal
      v-if="showAddLinkModal"
      @closeModal="closeAddLinkModal"
      @addLink="addLink"
      :nodesArray="nodeDataArray"
      :linksArray="linkDataArray"
    />
    <EditNodeModal
      v-if="showEditModal"
      :nodeToEdit="nodeToEdit"
      @closeModal="closeEditNodeModal"
      @editNode="editNode"
      :nodesArray="statusList"
    />
    <div class="flowchart__header">
      <h1>Режим настройки флоу проекта</h1>
      <div class="flowchart__buttons">
        <Button type="white" title="Выйти из режима настройки" @handleButton="exitFlowchart" />
        <Button
          :type="modelModified ? '' : 'disabled'"
          title="Сохранить"
          @handleButton="checkLinks"
          class="flowchart__save"
        />
      </div>
    </div>
    <div class="flowchart__wrap">
      <div class="flowchart__zoom">
        <a @click="increaseZoom"><i class="icon-circle-plus"></i></a>
        <a @click="decreaseZoom"><i class="icon-circle-minus"></i></a>
      </div>
      <div class="flowchart__control">
        <Button
          type="simple"
          icon="add-box"
          title="Добавить статус"
          @handleButton="openAddNodeModal"
        />
        <Button
          type="simple"
          icon="trending-flat"
          title="Добавить переход"
          @handleButton="openAddLinkModal"
        />
        <Button
          v-if="modelModified"
          type="simple"
          icon="settings-backup-restore"
          title="Сбросить изменения"
          @handleButton="resetModel"
          class="flowchart__reset"
        />
      </div>
      <!--      div для диаграммы GoJS-->
      <div id="myDiagramDiv"></div>
    </div>
    <ul id="contextMenu" class="menu">
      <li id="allowAllLink" class="menu__item" @mousedown="allowAllLink">
        <i class="icon-circle-check"></i> Разрешить все переходы
      </li>
      <li id="removeAllLink" class="menu__item" @mousedown="removeAllLink">
        <i class="icon-cross-circle"></i> Убрать все переходы
      </li>
      <li id="makeInitial" class="menu__item" @mousedown="makeInitial">
        <i class="icon-favorites"></i> Сделать статус начальным
      </li>
      <li id="makeFinal" class="menu__item" @mousedown="makeFinal">
        <i class="icon-flag"></i> Сделать статус конечным
      </li>
    </ul>
  </section>
</template>

<script>
import go from "gojs";
import makeElementDraggable from "@/helpers/makeElementDraggable.mixin";
import Button from "@/modules/UiKit/components/buttons/Button";
import AddNodeModal from "@/modules/TaskManager/components/Flowchart/Modals/AddNodeModal";
import AddLinkModal from "@/modules/TaskManager/components/Flowchart/Modals/AddLinkModal";
import EditNodeModal from "@/modules/TaskManager/components/Flowchart/Modals/EditNodeModal";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import Select from "@/modules/UiKit/components/Select/Select";
import FlowchartModule, {
  FlowchartMutationTypes
} from "@/modules/TaskManager/Model/Flowchart/FlowchartModule";

const $ = go.GraphObject.make;
export default {
  name: "Flowchart",
  mixins: [makeElementDraggable],
  props: {},
  components: { Select, ConfirmModal, EditNodeModal, AddLinkModal, AddNodeModal, Button },
  data() {
    return {
      myDiagram: {},
      modelJSON: {},
      contextMenuNode: null,
      contextMenu: null,
      nodeName: "",
      nodeTo: [],
      nodeFrom: [],
      showEditModal: false,
      showAddNodeModal: false,
      showAddLinkModal: false,
      modelModified: false,
      nodeToEdit: {},
      confirmDeleteStatus: false,
      confirmConfigureKanban: false,
      confirmExitFlowchart: false,
      confirmSaveModel: false,
      currentDeletableNode: {},
      addedStatusList: [],
      deletedStatusList: [],
      editedStatusList: [],
      statusTo: [],
      changeTaskStatuses: [],
      initialX: -300,
      initialY: 100,
      next: null,
      nodesWithoutLinks: []
    };
  },
  mounted() {
    this.initDiagram();
  },
  beforeDestroy() {
    FlowchartModule.deleteStatusesAction(this.addedStatusList);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modelModified) {
      this.confirmExitFlowchart = true;
      this.next = next;
    } else {
      next();
    }
  },
  methods: {
    async initDiagram() {
      await FlowchartModule.getFlowchartAction({
        flow_id: this.flowId,
        project_id: this.projectId
      });
      this.modelJSON = JSON.parse(JSON.stringify(this.flowchartCoordinates));
      this.myDiagram = $(go.Diagram, "myDiagramDiv", {
        maxSelectionCount: 1,
        "undoManager.isEnabled": false, //выключает ctrl+z
        minScale: 0.25, // минимальный масштаб
        maxScale: 3, // максимальный масштаб
        padding: new go.Margin(67, 0, 0, 0),
        grid: $(
          //бэкграунд в виде точек
          go.Panel,
          "Grid",
          { gridCellSize: new go.Size(50, 50) },
          $(go.Shape, "LineV", {
            strokeDashArray: [2, 50],
            strokeWidth: 2,
            stroke: "#D2D6DB"
          })
        )
      });

      this.myDiagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal; //вид связей при их создании
      this.myDiagram.toolManager.relinkingTool.temporaryLink.routing = go.Link.Orthogonal; //вид связей при их переносе

      //Переопределяем функцию удаления
      this.myDiagram.commandHandler.deleteSelection = () => {
        this.myDiagram.selection.each((selected) => {
          //Проверяем явялется ли выбранный элемент связью
          if (selected.data.from) {
            this.myDiagram.startTransaction("deleteLink");
            this.myDiagram.model.removeLinkData(selected.data);
            this.myDiagram.commitTransaction("deleteLink");
          } else {
            if (selected.data.category) return;
            this.currentDeletableNode = selected.data;
            if (this.statusList.find((item) => item.id === selected.data.id)?.has_tasks) {
              this.confirmDeleteStatus = true;
            } else {
              this.acceptDeleteStatus();
            }
          }
        });
      };

      this.myDiagram.addModelChangedListener((evt) => {
        if (
          evt.isTransactionFinished &&
          evt.object?.name !== "Initial Layout" &&
          evt.oldValue !== "Initial Layout" &&
          this.myDiagram.isModified
        ) {
          this.modelModified = true;
        } else if (!this.myDiagram.isModified) {
          this.modelModified = false;
        }
      });

      this.contextMenuNode = document.getElementById("contextMenu");
      this.contextMenu = $(go.HTMLInfo, {
        show: this.showContextMenu,
        hide: this.hideContextMenu
      });

      //Создание фигуры Terminator для начального блока
      go.Shape.defineFigureGenerator("Terminator", function (shape, w, h) {
        const geo = new go.Geometry();
        const fig = new go.PathFigure(0.08 * w, 0, true);
        geo.add(fig);
        fig.add(new go.PathSegment(go.PathSegment.Arc, 270, 180, w - 16, 0.5 * h, 16, 0.5 * h));
        fig.add(new go.PathSegment(go.PathSegment.Arc, 90, 180, 16, 0.5 * h, 16, 0.5 * h));
        return geo;
      });

      //Шаблон стандартного блока
      this.addNodeTemplateMap("", "RoundedRectangle", "#409CFF", "#377AC2");
      //Шаблон начального блока
      this.addNodeTemplateMap("Begin", "Terminator", "#706AFF", "#3935BF");
      //Шаблон конечного блока
      this.addNodeTemplateMap("End", "RoundedRectangle", "#07B6AB", "#049C93");
      //Шаблон блока "Все"
      this.myDiagram.nodeTemplateMap.add(
        "All",
        $(
          go.Node,
          "Table",
          { selectionAdorned: false, deletable: false },
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
          {
            locationSpot: go.Spot.Center
          },
          $(
            go.Panel,
            "Auto",
            $(go.Shape, "RoundedRectangle", { fill: "#343748", stroke: "transparent" }),
            $(go.TextBlock, {
              stroke: "#FFFFFF",
              margin: new go.Margin(4, 8),
              editable: false,
              text: "Все"
            })
          )
        )
      );
      //Шаблон стандартной связи
      this.myDiagram.linkTemplate = $(
        go.Link,
        {
          routing: go.Link.AvoidsNodes,
          curve: go.Link.JumpOver,
          corner: 5,
          toShortLength: 4,
          relinkableFrom: true,
          relinkableTo: true,
          // reshapable: true,
          resegmentable: true,
          mouseEnter: (e, link) => (link.findObject("HIGHLIGHT").stroke = "rgba(30,144,255,0.2)"),
          mouseLeave: (e, link) => (link.findObject("HIGHLIGHT").stroke = "transparent"),
          selectionAdorned: false
        },
        new go.Binding("relinkableFrom", "relinkableFrom"),
        new go.Binding("relinkableTo", "relinkableTo"),
        $(go.Shape, {
          isPanelMain: true,
          strokeWidth: 8,
          stroke: "transparent",
          name: "HIGHLIGHT"
        }),
        $(
          go.Shape,
          { isPanelMain: true, stroke: "#D3D2E0", strokeWidth: 2 },
          new go.Binding("stroke", "isSelected", (sel) => (sel ? "#409CFF" : "#D3D2E0")).ofObject()
        ),
        $(go.Shape, { toArrow: "Triangle", strokeWidth: 0, fill: "#D3D2E0" })
        //Пока без названий связей
        // $(
        //   go.Panel,
        //   "Auto",
        //   { visible: true, name: "LABEL", segmentIndex: 2, segmentFraction: 0.5 },
        //   new go.Binding("visible", "hiddenLabels", (b) => !b).ofModel(),
        //   $(
        //     go.TextBlock,
        //     "Переход", // the label
        //     {
        //       textAlign: "center",
        //       font: "10pt helvetica, arial, sans-serif",
        //       stroke: "#333333",
        //       editable: true
        //     },
        //     new go.Binding("text").makeTwoWay()
        //   )
        // )
      );
      //Шаблон связи из блока "Все"
      this.myDiagram.linkTemplateMap.add(
        "linkFromBlockAll",
        $(
          "Link",
          {
            deletable: false,
            routing: go.Link.AvoidsNodes,
            curve: go.Link.JumpOver,
            corner: 20,
            toShortLength: 4,
            relinkableFrom: false,
            relinkableTo: false,
            resegmentable: true,
            selectionAdorned: false
          },
          $(go.Shape, {
            isPanelMain: true,
            strokeWidth: 8,
            stroke: "transparent",
            name: "HIGHLIGHT"
          }),
          $(go.Shape, { isPanelMain: true, stroke: "#D3D2E0", strokeWidth: 2 }),
          $(go.Shape, { toArrow: "Triangle", strokeWidth: 0, fill: "#D3D2E0" })
        )
      );
      this.myDiagram.contextMenu = this.contextMenu;
      this.myDiagram.model = go.Model.fromJson(this.modelJSON);
    },
    showContextMenu(obj, diagram) {
      if (!obj) return; // для показа контекстного меню только у блоков

      let hasMenuItem = false;
      const maybeShowItem = (elt, pred) => {
        if (pred) {
          elt.style.display = "flex";
          hasMenuItem = true;
        } else {
          elt.style.display = "none";
        }
      };
      maybeShowItem(document.getElementById("allowAllLink"), !obj.data.isForAll);
      maybeShowItem(document.getElementById("removeAllLink"), obj.data.isForAll);
      maybeShowItem(
        document.getElementById("makeInitial"),
        obj.data.category !== "Begin" && obj.data.category !== "End"
      );
      maybeShowItem(
        document.getElementById("makeFinal"),
        obj.data.category !== "Begin" && obj.data.category !== "End"
      );

      if (hasMenuItem) {
        this.contextMenuNode.classList.add("show-menu");
        const mousePt = diagram.lastInput.viewPoint;
        this.contextMenuNode.style.left = mousePt.x + 5 + "px";
        this.contextMenuNode.style.top = mousePt.y + 106 + "px";
      }
      window.addEventListener("pointerdown", this.hideCX, true);
    },

    hideContextMenu() {
      this.contextMenuNode.classList.remove("show-menu");
      window.removeEventListener("pointerdown", this.hideCX, true);
    },
    hideCX() {
      if (this.myDiagram.currentTool instanceof go.ContextMenuTool) {
        this.myDiagram.currentTool.doCancel();
      }
    },
    allowAllLink() {
      this.myDiagram.startTransaction("addAllLink");
      this.myDiagram.selection.each((node) => {
        if (node instanceof go.Node) {
          const data = node.data;
          let loc = data.loc.split(" ");
          loc[0] = +loc[0] + 180;
          loc = loc.join(" ");
          this.myDiagram.model.addNodeData({ category: "All", id: `all_${data.id}`, loc: loc });
          this.myDiagram.model.addLinkData({
            to: data.id,
            from: `all_${data.id}`,
            text: "",
            toPort: "R",
            category: "linkFromBlockAll"
          });
          this.myDiagram.model.setDataProperty(data, "isForAll", true);
        }
      });
      this.myDiagram.commitTransaction("addAllLink");
    },
    removeAllLink() {
      this.myDiagram.startTransaction("removeAllLink");
      this.myDiagram.selection.each((node) => {
        if (node instanceof go.Node) {
          const data = node.data;
          const nodeAll = this.myDiagram.findNodeForKey(`all_${data.id}`);
          this.myDiagram.model.removeNodeData(nodeAll.data);
          nodeAll.findLinksOutOf().each((link) => {
            this.myDiagram.model.removeLinkData(link.data);
          });
          this.myDiagram.model.setDataProperty(data, "isForAll", false);
        }
      });
      this.myDiagram.commitTransaction("removeAllLink");
    },
    makeInitial() {
      this.myDiagram.startTransaction("makeInitial");
      this.myDiagram.selection.each((node) => {
        if (node instanceof go.Node) {
          const data = node.data;
          const initialNode = this.myDiagram.model.nodeDataArray.find(
            (node) => node.category === "Begin"
          );
          if (initialNode) {
            this.myDiagram.model.setDataProperty(initialNode, "category", "");
            const editedStatus = {
              id: initialNode.id,
              is_start: false
            };
            this.addToEditedStatusList(editedStatus);
          }
          this.myDiagram.model.setDataProperty(data, "category", "Begin");
          const editedStatus = {
            id: data.id,
            is_start: true
          };
          this.addToEditedStatusList(editedStatus);
        }
      });
      this.myDiagram.commitTransaction("makeInitial");
    },
    makeFinal() {
      this.myDiagram.startTransaction("makeFinal");
      this.myDiagram.selection.each((node) => {
        if (node instanceof go.Node) {
          const data = node.data;
          const finalNode = this.myDiagram.model.nodeDataArray.find(
            (node) => node.category === "End"
          );
          if (finalNode) {
            this.myDiagram.model.setDataProperty(finalNode, "category", "");
            const editedStatus = {
              id: finalNode.id,
              is_end: false
            };
            this.addToEditedStatusList(editedStatus);
          }
          this.myDiagram.model.setDataProperty(data, "category", "End");
          const editedStatus = {
            id: data.id,
            is_end: true
          };
          this.addToEditedStatusList(editedStatus);
        }
      });
      this.myDiagram.commitTransaction("makeFinal");
    },
    increaseZoom() {
      this.myDiagram.commandHandler.increaseZoom(1.1);
    },
    decreaseZoom() {
      this.myDiagram.commandHandler.decreaseZoom(0.9);
    },
    saveModel() {
      const model = this.myDiagram.model.toJSON();
      FlowchartModule.saveFlowchartAction({
        project_id: this.projectId,
        flow_id: this.flowId,
        coordinates: model,
        change_task_statuses: this.changeTaskStatuses,
        deletedStatusList: this.deletedStatusList,
        editedStatusList: this.editedStatusList
      });
      this.modelModified = false;
      FlowchartModule[FlowchartMutationTypes.SET_ORIGIN_STATUS_LIST](
        JSON.parse(JSON.stringify(this.statusList))
      );
      if (
        this.addedStatusList.filter(
          (status) => !this.deletedStatusList.some((item) => item.id === status.id)
        ).length
      )
        this.confirmConfigureKanban = true;
      this.clearArrays();
      this.confirmSaveModel = false;
    },
    resetModel() {
      this.modelJSON = JSON.parse(JSON.stringify(this.flowchartCoordinates));
      this.myDiagram.model = go.Model.fromJson(this.modelJSON);
      FlowchartModule.deleteStatusesAction(this.addedStatusList);
      FlowchartModule[FlowchartMutationTypes.SET_STATUS_LIST](
        JSON.parse(JSON.stringify(this.originStatusList))
      );
      this.clearArrays();
      this.modelModified = false;
    },
    clearArrays() {
      this.addedStatusList = [];
      this.deletedStatusList = [];
      this.editedStatusList = [];
      this.changeTaskStatuses = [];
    },
    addNodeTemplateMap(category, shape, fillColor, fillColorSelected) {
      this.myDiagram.nodeTemplateMap.add(
        category,
        $(
          go.Node,
          "Spot",
          {
            linkValidation: function (fromNode, fromPort, toNode, toPort) {
              let canAddLink = true;
              fromNode.linksConnected.each((linkFrom) => {
                if (linkFrom.data.from === fromNode.data.id && linkFrom.data.to === toNode.data.id)
                  canAddLink = false;
              });
              return canAddLink;
            }
          },
          {
            selectionAdorned: false,
            contextMenu: this.contextMenu,
            deletable: !(category === "Begin" || category === "End")
          },
          {
            doubleClick: (e, node) => {
              this.showAddNodeModal = false;
              this.showAddLinkModal = false;
              this.showEditModal = true;
              this.nodeToEdit = node.data;
              node.isSelected = false;
            },
            mouseEnter: (e, node) => {
              this.toggleVisibilityOfPorts(node.ports, true);
            },
            mouseLeave: (e, node) => {
              this.toggleVisibilityOfPorts(node.ports, false);
            }
          },
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
          new go.Binding("isForAll", "isForAll").makeTwoWay(),
          {
            locationSpot: go.Spot.Center
          },
          $(
            go.Panel,
            "Auto",
            $(
              go.Shape,
              shape,
              { strokeWidth: 0 },
              new go.Binding("fill", "isSelected", (isSelected) => {
                return isSelected ? fillColorSelected : fillColor;
              }).ofObject("")
            ),
            $(
              go.TextBlock,
              this.textStyle,
              new go.Binding("text", "name").makeTwoWay() //makeTwoWay для двухстороннего связывания
            )
          ),
          this.addPort("T", 0.5, 0, go.Spot.TopSide, go.Spot.TopSide),
          this.addPort("TR", 0.75, 0, go.Spot.TopSide, go.Spot.TopSide),
          this.addPort("R", 1, 0.5, go.Spot.RightSide, go.Spot.RightSide),
          this.addPort("BR", 0.75, 1, go.Spot.BottomSide, go.Spot.BottomSide),
          this.addPort("B", 0.5, 1, go.Spot.BottomSide, go.Spot.BottomSide),
          this.addPort("BL", 0.25, 1, go.Spot.BottomSide, go.Spot.BottomSide),
          this.addPort("L", 0, 0.5, go.Spot.LeftSide, go.Spot.LeftSide),
          this.addPort("TL", 0.25, 0, go.Spot.TopSide, go.Spot.TopSide)
        )
      );
    },
    addPort(portId, x, y, fromSpot, toSpot) {
      return $(go.Shape, "Circle", {
        alignment: new go.Spot(x, y),
        fill: "transparent",
        strokeWidth: 1,
        stroke: "transparent",
        desiredSize: new go.Size(5, 5),
        portId,
        fromSpot,
        fromLinkable: true,
        toSpot,
        toLinkable: true,
        cursor: "pointer"
      });
    },
    toggleVisibilityOfPorts(ports, show) {
      ports.each((port) => {
        if (port.portId !== "") {
          port.fill = show ? "#FFFFFF" : "transparent";
          port.stroke = show ? "#D3D2E0" : "transparent";
        }
      });
    },
    openAddNodeModal() {
      this.showAddLinkModal = false;
      this.showEditModal = false;

      this.showAddNodeModal = true;
    },
    closeAddNodeModal() {
      this.showAddNodeModal = false;
    },
    async addNode(nodeTitle) {
      this.myDiagram.commitTransaction("addNode");
      this.myDiagram.startTransaction("addNode");
      let newStatus = {
        name: nodeTitle,
        project_id: this.projectId
      };
      const id = await FlowchartModule.createStatusAction(newStatus);
      this.myDiagram.model.addNodeData({
        name: nodeTitle,
        loc: `${this.initialX} ${this.initialY}`
      });
      this.initialX += 10;
      this.initialY += 10;
      const currentNodeDataArray = this.myDiagram.model.nodeDataArray;
      newStatus = currentNodeDataArray.find((item) => item.name === nodeTitle);
      if (newStatus) this.myDiagram.model.setDataProperty(newStatus, "id", id);
      this.addedStatusList.push(newStatus);
      FlowchartModule[FlowchartMutationTypes.ADD_STATUS]({ id, name: nodeTitle, has_tasks: false });
      this.showAddNodeModal = false;
      this.myDiagram.commitTransaction("addNode");
    },
    openAddLinkModal() {
      this.showEditModal = false;
      this.showAddNodeModal = false;

      this.showAddLinkModal = true;
    },
    closeAddLinkModal() {
      this.showAddLinkModal = false;
    },
    addLink(val) {
      this.myDiagram.startTransaction("addLink");
      this.myDiagram.model.addLinkData({
        to: val.nodeTo.id,
        from: val.nodeFrom.id,
        fromPort: "B",
        toPort: "T"
      });
      this.showAddLinkModal = false;
      this.myDiagram.commitTransaction("addLink");
    },
    closeEditNodeModal() {
      this.showEditModal = false;
    },
    editNode(val) {
      this.myDiagram.startTransaction("editNode");
      this.myDiagram.model.setDataProperty(this.nodeToEdit, "name", val);
      this.addToEditedStatusList(this.nodeToEdit);
      FlowchartModule[FlowchartMutationTypes.EDIT_STATUS]({
        id: this.nodeToEdit.id,
        name: this.nodeToEdit.name
      });
      this.nodeToEdit = {};
      this.showEditModal = false;
      this.myDiagram.commitTransaction("editNode");
    },
    addToEditedStatusList(editedStatus) {
      const nodeIndex = this.editedStatusList.findIndex((item) => item.id === editedStatus.id);
      if (nodeIndex !== -1) {
        for (const key in editedStatus) {
          this.editedStatusList[nodeIndex][key] = editedStatus[key];
        }
      } else {
        this.editedStatusList.push(editedStatus);
      }
    },
    cancelDeleteStatus() {
      this.statusTo = [];
      this.confirmDeleteStatus = false;
    },
    acceptDeleteStatus() {
      this.myDiagram.startTransaction("deleteNode");
      const nodeAll = this.myDiagram.findNodeForKey(`all_${this.currentDeletableNode.id}`);
      if (nodeAll) {
        this.myDiagram.model.removeNodeData(nodeAll.data);
        nodeAll.findLinksOutOf().each((link) => {
          this.myDiagram.model.removeLinkData(link.data);
        });
      }
      //вызов дефолтного поведения при удалении
      go.CommandHandler.prototype.deleteSelection.call(this.myDiagram.commandHandler);
      if (this.statusTo.length) {
        this.changeTaskStatuses.push({
          from: this.currentDeletableNode.id,
          to: this.statusTo[0].id
        });
        FlowchartModule[FlowchartMutationTypes.EDIT_STATUS]({
          id: this.statusTo[0].id,
          has_tasks: true
        });
      } else {
        this.deletedStatusList.push(this.currentDeletableNode);
      }
      FlowchartModule[FlowchartMutationTypes.DELETE_STATUS](this.currentDeletableNode.id);
      //если в массиве измененных статусов есть удаленный статус, то убираем его из этого массива
      const nodeIndex = this.editedStatusList.findIndex(
        (item) => item.id === this.currentDeletableNode.id
      );
      if (nodeIndex !== -1) this.editedStatusList.splice(nodeIndex, 1);

      this.myDiagram.commitTransaction("deleteNode");
      this.currentDeletableNode = {};
      this.confirmDeleteStatus = false;
      this.statusTo = [];
    },
    exitFlowchart() {
      this.$router.push({ name: "Project", params: { id: this.projectId } });
    },
    cancelConfigureKanban() {
      this.confirmConfigureKanban = false;
    },
    acceptConfigureKanban() {
      if (this.addedStatusList.length) this.confirmConfigureKanban = true;
      this.$router.push({ name: "KanbanSetting", params: { id: this.projectId } });
    },
    cancelExitFlowchart() {
      this.confirmExitFlowchart = false;
    },
    checkLinks() {
      this.nodesWithoutLinks = [];
      const currentNodeDataArray = this.myDiagram.model.nodeDataArray;
      if (currentNodeDataArray.some((item) => item.category === "All")) {
        this.saveModel();
        return;
      }
      currentNodeDataArray.forEach((item) => {
        const node = this.myDiagram.findNodeForKey(item.id);
        let hasLinks = false;
        node.findLinksConnected().each(() => {
          hasLinks = true;
        });
        if (!hasLinks) this.nodesWithoutLinks.push(item);
      });
      if (this.nodesWithoutLinks.length) {
        this.confirmSaveModel = true;
      } else {
        this.saveModel();
      }
    },
    acceptExitFlowchart() {
      this.confirmExitFlowchart = false;
      this.next();
    },
    cancelSaveModel() {
      this.confirmSaveModel = false;
    }
  },
  computed: {
    textStyle() {
      return {
        font: "Normal Normal 500 16px Roboto",
        stroke: "#FFFFFF",
        margin: new go.Margin(6, 64),
        maxSize: new go.Size(160, NaN),
        wrap: go.TextBlock.WrapFit
        // editable: true
      };
    },
    nodeDataArray() {
      return this.myDiagram.model?.nodeDataArray.filter((item) => item.category !== "All");
    },
    linkDataArray() {
      return this.myDiagram.model?.linkDataArray.filter(
        (item) => item.category !== "linkFromBlockAll"
      );
    },
    flowId() {
      return this.$route.params.flow_id;
    },
    projectId() {
      return this.$route.params.project_id;
    },
    flowchartCoordinates() {
      return FlowchartModule.flowchartCoordinates;
    },
    originStatusList() {
      return FlowchartModule.originStatusList;
    },
    statusList() {
      return FlowchartModule.statusList;
    },
    statusItems() {
      return this.statusList.filter((item) => item.id !== this.currentDeletableNode.id);
    },
    confirmModalSaveModelText() {
      let text = "";
      this.nodesWithoutLinks.forEach((item) => {
        text += ` "${item.name}",`;
      });
      return `Статус${text.substring(
        0,
        text.length - 1
      )} не имеет переходов! Вы действительно хотите сохранить такой бизнес-процесс?`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.select-another-status {
  margin-bottom: 48px;
  p {
    color: $text-primary;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 16px;
  }
}
.flowchart {
  width: 100%;
  height: 100%;
  position: relative;
  &__header {
    padding: 32px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      padding: 0;
      margin: 0;
      font-weight: 900;
      font-size: 24px;
    }
  }
  &__save {
    margin-left: 24px;
  }
  &__reset {
    margin-left: auto;
  }
  &__buttons {
    display: flex;
  }
  &__wrap {
    position: relative;
    width: 100%;
    height: calc(100% - 106px);
  }
  #myDiagramDiv {
    width: 100%;
    height: 100%;
    /deep/ canvas {
      outline: none;
    }
  }
  &__zoom {
    position: absolute;
    width: 56px;
    height: 104px;
    top: 100px;
    right: 32px;
    display: flex;
    flex-direction: column;
    z-index: 3;
    background: #fafafe;
    border: 1px solid #eae8f0;
    box-sizing: border-box;
    border-radius: 8px;
    a {
      user-select: none;
      cursor: pointer;
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    i {
      color: $icon-subdued;
      font-size: 24px;
    }
  }
  &__control {
    width: 100%;
    height: 67px;
    position: absolute;
    display: flex;
    justify-content: start;
    align-items: center;
    top: 0;
    left: 0;
    background-color: #fafafe;
    z-index: 10;
    border: 1px solid #eae8f0;
  }
}
.menu {
  display: none;
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 8px 0;
  z-index: 999;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  list-style: none;
  background-color: #ffffff;
  border-radius: 4px;
  i {
    color: $icon-subdued;
    font-size: 20px;
    margin-right: 16px;
  }
  &__item {
    display: flex;
    align-items: center;
    position: relative;
    //min-width: 60px;
    margin: 0;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    color: $text-default;
    cursor: pointer;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #000000;
    }
    &:hover::before {
      opacity: 0.04;
    }
  }
}

.menu .menu {
  top: -8px;
  left: 100%;
}

.show-menu,
.menu__item:hover > .menu {
  display: block;
  opacity: 1;
}
</style>
