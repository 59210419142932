export const CHANGE_IMAGE_LINK = "CHANGE_IMAGE_LINK";
export const CHANGE_SHOW_FULL_SIZE_IMAGE = "CHANGE_SHOW_FULL_SIZE_IMAGE";
export const CHANGE_SHOW_ADD_CHANNEL = "CHANGE_SHOW_ADD_CHANNEL";
export const CHANGE_EDIT_CHANNEL_VALUE = "CHANGE_EDIT_CHANNEL_VALUE";
export const CHANGE_SHOW_CHANNEL = "CHANGE_SHOW_CHANNEL";
export const CHANGE_MEMBER_INFO_CHANNEL = "CHANGE_MEMBER_INFO_CHANNEL";
export const CHANGE_MEMBER_USER = "CHANGE_MEMBER_USER";
export const CHANGE_IS_ADMIN_LIKE = "CHANGE_IS_ADMIN_LIKE";
export const CHANGE_MEMBER_LIST_TITLE = "CHANGE_MEMBER_LIST_TITLE";
export const CHANGE_ACTIVE_FILTER = "CHANGE_ACTIVE_FILTER";
export const SET_BIRTHDAY_ITEMS = "SET_BIRTHDAY_ITEMS";
export const CHANGE_BIRTHDAY_LOADER = "CHANGE_BIRTHDAY_LOADER";
export const SET_EMPLOYEE_ITEMS = "SET_EMPLOYEE_ITEMS";
export const CHANGE_EMPLOYEE_LOADER = "CHANGE_EMPLOYEE_LOADER";
export const SET_CHANNEL_LIST = "SET_CHANNEL_LIST";
export const CHANGE_LOADING_CHANNELS = "CHANGE_LOADING_CHANNELS";
export const CHANGE_LOADING = "CHANGE_LOADING";
export const SET_NEWS_COUNTER = "SET_NEWS_COUNTER";
export const CHANGE_ACTIVE_CHANNEL = "CHANGE_ACTIVE_CHANNEL";
export const SET_NEWS_ITEMS = "SET_NEWS_ITEMS";
export const SET_NEWS_TOTAL_COUNT = "SET_NEWS_TOTAL_COUNT";
export const CHANGE_GLOBAL_EDIT_MODE = "CHANGE_GLOBAL_EDIT_MODE";
export const CHANGE_SCROLL_LOADING = "CHANGE_SCROLL_LOADING";
export const SET_CHANNEL_ALL_LIST = "SET_CHANNEL_ALL_LIST";
export const SET_CURRENT_EDITABLE_COMMENT = "SET_CURRENT_EDITABLE_COMMENT";
export const SET_CURRENT_REPLYABLE_COMMENT = "SET_CURRENT_REPLYABLE_COMMENT";
export const CHOOSE_NEWS_TO_UPDATE = "CHOOSE_NEWS_TO_UPDATE";
export const TOGGLE_SHOW_CREATE_CURTAIN = "TOGGLE_SHOW_CREATE_CURTAIN";
export const TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL = "TOGGLE_SHOW_CURTAIN_CREATE_CHANNEL";
export const TOGGLE_SHOW_CURTAIN_NEWS_LIKES = "TOGGLE_SHOW_CURTAIN_NEWS_LIKES";
export const SET_CURRENT_NEWS_LIKES = "SET_CURRENT_NEWS_LIKES";
export const TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL =
  "TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL";
export const TOGGLE_IS_CERTAIN_NEWS = "TOGGLE_IS_CERTAIN_NEWS";
export const TOGGLE_CURTAIN_LIKES_LOADER = "TOGGLE_CURTAIN_LIKES_LOADER";
export const TOGGLE_CURTAIN_CHANNEL_LOADER = "TOGGLE_CURTAIN_CHANNEL_LOADER";
export const SHOW_CHANNEL_MODAL_MENU = "SHOW_CHANNEL_MODAL_MENU";
export const SHOW_CHANNEL_MODAL_CHANNELS = "SHOW_CHANNEL_MODAL_CHANNELS";
export const SHOW_NEWS_BIRTHDAY_MODAL = "SHOW_NEWS_BIRTHDAY_MODAL";
export const SHOW_NEW_EMPLOYEE_MODAL = "SHOW_NEW_EMPLOYEE_MODAL";
