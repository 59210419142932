<template>
  <div class="table__head">
    <div class="table__cell" v-for="(cell, index) in tableHeadList" :key="`cell-${index}`">
      {{ cell.name }}
      <button
        class="table__sort"
        type="button"
        v-if="cell.sort"
        :class="{
          asc: false,
          active: isActive(cell.valueSort)
        }"
        @click="setSort(cell.valueSort)"
      >
        <i class="icon-swap-vert"></i>
      </button>
    </div>
  </div>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import StageModule, { StageMutationTypes } from "@/modules/TaskManager/Model/Stage/StageModule";

export default {
  name: "ProjectStagesTableFields",
  mixins: [],
  props: {
    tableHeadList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: "0"
    },
    stage: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setSort(val) {
      TaskModule[TaskMutationTypes.SET_LOADER](true);
      TaskModule[TaskMutationTypes.RESET_TASKS_BY_STAGE]([]);
      TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](1);
      TaskModule[TaskMutationTypes.SET_VALUE_SORT](val);
      this.$emit("updateTable", this.setParams);
    },
    isActive(val) {
      return val === this.valueSort.value;
    }
  },
  computed: {
    valueSort() {
      return TaskModule.valueSort;
    },
    setParams() {
      const params = {
        page: TaskModule.tasksByStagePage,
        per_page: 10,
        project_id: this.projectId,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
      if (TaskModule.params.status_id) {
        params.status_id = TaskModule.params.status_id;
      }
      if (TaskModule.params.executor_id) {
        params.executor_id = TaskModule.params.executor_id;
      }
      const stage = this.stage ? this.stage.id : this.$route.query.stage;
      params.stage_id = stage === "default" || stage === "null" ? undefined : stage;
      return params;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__head {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 9;
    padding: 8px 0;
    background: $surface-selected-default;
    border-radius: 8px;
    margin-bottom: 16px;

    @media (max-width: $md) {
      display: none;
    }
  }

  &__cell {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $text-primary;
    text-transform: uppercase;
    flex-shrink: 1;
    justify-content: center;
    @media (max-width: $lg) {
      font-size: 9px;
    }
    &:nth-child(1) {
      width: 40%;
      flex-shrink: 0;
      padding-left: 24px;
      justify-content: start;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 8px;
    outline: none;
    transition: all 0.3s ease-in-out;

    i {
      color: $text-primary;
      font-size: 14px;
    }
  }

  .asc {
    transform: rotate(180deg);
  }
}

.active {
  i {
    color: #5551f9;
    font-weight: bold;
  }
}
</style>
