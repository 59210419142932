<template>
  <div
    class="quiz-component-item"
    :class="{
      'quiz-component-item_not-active': (!isSelected && currentUserAnswer) || showResult,
      'quiz-component-item_active': isSelected
    }"
    @click="voteHandler"
  >
    <div class="quiz-component-item__container">
      <p
        class="quiz-component-item__text"
        :class="[isSelected ? 'quiz-component-item__text_active' : '']"
      >
        {{ answer.content }}
      </p>
      <span
        v-if="currentUserAnswer || showResult"
        class="quiz-component-item__percent"
        :style="isSelected ? { color: '#fff' } : { color: '#343748' }"
      >
        {{ answer.percent_users ? answer.percent_users + "%" : "0%" }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "QuizComponentItem",
  mixins: [],
  props: {
    answer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    currentUserAnswer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showResult: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["deleteVote", "addVote"]),
    // Контроллер для запроса
    voteHandler() {
      if (this.currentUserAnswer && this.currentUserAnswer.answer_id === this.answer.id) {
        this.deleteVote(this.currentUserAnswer.user_answer_id)
          .then(() => {
            this.$emit("updateQuiz");
          })
          .catch((error) => {
            this.errorAlert(error);
          });
      } else {
        if (!this.currentUserAnswer) {
          this.addVote(this.answer.id)
            .then(() => {
              this.$emit("updateQuiz");
            })
            .catch((error) => {
              this.errorAlert(error);
            });
        }
      }
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isSelected() {
      return this.currentUserAnswer?.answer_id === this.answer.id;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.quiz-component-item {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 8px;
  background-color: rgba($background-active, 0.5);
  border: 1px solid #fff;
  transition: all 0.2s ease;

  &:hover {
    border: 1px solid $chat-editor;
  }
  &_active {
    background-color: $chat-editor;
    &:hover {
      border: 1px solid #fff;
    }
  }
  &_not-active {
    cursor: default;
    &:hover {
      border: 1px solid #fff;
    }
  }

  &__container {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &__text {
    padding: 4px 9px;
    font-size: 14px;
    line-height: 24px;
    color: $text-default;
    margin-right: 10px;
    word-break: break-word;
    &_active {
      color: #fff;
      font-weight: 500;
    }
  }

  &__percent {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin-left: auto;
    @media screen and (max-width: $xs) {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
</style>
