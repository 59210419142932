















import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import PickerItem from "@/aopV2/components/UIComponents/Picker/Month/PickerItem.vue";
import { yearGenerator } from "@/aopV2/components/UIComponents/Picker/yearGenerator";

@Component({
  components: { PickerItem }
})
export default class CalendarPicker extends Vue {
  @Prop({
    default: "",
    type: String
  })
  date!: string;
  @Prop({
    default: "year",
    type: String
  })
  type!: string;

  months: Array<string> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

  handleItem(data) {
    this.$emit("change", data);
  }

  get activeItem() {
    return this.isMonth ? +moment(this.date).format("M") : +moment(this.date).format("yyyy");
  }

  get isMonth() {
    return this.type === "month";
  }

  get year() {
    return yearGenerator(this.date);
  }

  get array() {
    return this.isMonth ? this.months : this.year;
  }
}
