export const SET_CHATS = "SET_CHATS";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const CLOSE_CURRENT_CHAT = "CLOSE_CURRENT_CHAT";
export const OPEN_CHAT = "OPEN_CHAT";
export const SET_EDITOR_VALUE = "SET_EDITOR_VALUE";
export const SET_PASTE_FILE = "SET_PASTE_FILE";
export const CLOSE_FILE_MODAL = "CLOSE_FILE_MODAL";
export const DELETE_CURRENT_FILE = "DELETE_CURRENT_FILE";
export const REPLACE_CURRENT_FILE = "REPLACE_CURRENT_FILE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGES = "SEND_MESSAGES";
export const SWITCH_MODAL = "SWITCH_MODAL";
export const DELETE_CURRENT_MESSAGE = "DELETE_CURRENT_MESSAGE";
export const SET_CURRENT_MESSAGE = "SET_CURRENT_MESSAGE";
export const SET_PICKED_MESSAGE = "SET_PICKED_MESSAGE";
export const CLOSE_PICKED_MESSAGE = "CLOSE_PICKED_MESSAGE";
export const PIN_CURRENT_MESSAGE = "PIN_CURRENT_MESSAGE";
export const EDIT_CURRENT_MESSAGE = "EDIT_CURRENT_MESSAGE";
export const CLOSE_EDIT_MESSAGE = "CLOSE_EDIT_MESSAGE";
export const REPLY_EDIT_MESSAGE = "REPLY_EDIT_MESSAGE";
export const SEARCH_USER_RESULT = "SEARCH_USER_RESULT";
export const SET_NEW_CHAT = "SET_NEW_CHAT";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const SWITCH_CHAT_MENU = "SWITCH_CHAT_MENU";
export const UPLOAD_MESSAGE = "UPLOAD_MESSAGE";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const OPEN_SEND_MESSAGE_MODAL = "OPEN_SEND_MESSAGE_MODAL";
export const OPEN_CHAT_IN_SEARCH = "OPEN_CHAT_IN_SEARCH";
export const UPDATE_CHAT_SETTING = "UPDATE_CHAT_SETTING";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const UPDATE_READ_STATUS = "UPDATE_READ_STATUS";
export const SWITCH_PIN_MESSAGES = "SWITCH_PIN_MESSAGES";
export const CHANGE_CREATE_EDIT_CHANNEL = "CHANGE_CREATE_EDIT_CHANNEL";
export const UPDATE_MESSAGE_LIST = "UPDATE_MESSAGE_LIST";
export const WRITING_ACTION = "WRITING_ACTION";
export const SET_SEND_MODE = "SET_SEND_MODE";
export const CHANGE_SHOW_CATALOG = "CHANGE_SHOW_CATALOG";
export const SET_EDIT_CURRENT_CHAT = "SET_EDIT_CURRENT_CHAT";
export const SWITCH_IMAGE = "SWITCH_IMAGE";
export const RESET_CURRENT_CHAT = "RESET_CURRENT_CHAT";
export const SET_SEND_SETTING = "SET_SEND_SETTING";
export const SET_NOTIFICATION_SETTING = "SET_NOTIFICATION_SETTING";
export const SET_GROUP_SETTING = "SET_GROUP_SETTING";
export const SWITCH_SETTINGS_MENU = "SWITCH_SETTINGS_MENU";
export const ADD_CHAT = "ADD_CHAT";
export const LEAVE_CHANNEL = "LEAVE_CHANNEL";
export const UPDATE_CHAT_LIST = "UPDATE_CHAT_LIST";
export const ADD_TAG = "ADD_TAG";
export const SET_USERS = "SET_USERS";
export const ADD_MEMBER = "ADD_MEMBER";
export const UPDATE_USERS = "UPDATE_USERS";
export const CHANGE_CATALOG_CHANNELS = "CHANGE_CATALOG_CHANNELS";
export const CLEAR_CATALOG_CHANNELS = "CLEAR_CATALOG_CHANNELS";
export const CHANGE_CATALOG_MEMBERS = "CHANGE_CATALOG_MEMBERS";
export const CHANGE_ATTACHED_MODAL_VALUE = "CHANGE_ATTACHED_MODAL_VALUE";
export const CHANGE_ATTACHED_FILES = "CHANGE_ATTACHED_FILES";
export const CHANGE_VIDEO_PLAYER_DATA = "CHANGE_VIDEO_PLAYER_DATA";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_CATALOG_LOADER = "CHANGE_CATALOG_LOADER";
export const SEARCH_MESSAGE_MODE = "SEARCH_MESSAGE_MODE";
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const UPDATE_USERS_LIST = "UPDATE_USERS_LIST";
export const SHOW_NOTIFICATION_ACTION = "SHOW_NOTIFICATION_ACTION";
export const SET_CHAT_ITEM = "SET_CHAT_ITEM";
export const SET_MESSAGE_FROM_SEARCH = "SET_MESSAGE_FROM_SEARCH";
export const SEARCH_RESULT_PERSON = "SEARCH_RESULT_PERSON";
export const SEARCH_RESULT_CLOSE = "SEARCH_RESULT_CLOSE";
export const SEARCH_RESULT_OPEN = "SEARCH_RESULT_OPEN";
export const OPEN_GROUP_CHAT = "OPEN_GROUP_CHAT";
export const DELETE_ADMIN_CHANNEL = "DELETE_ADMIN_CHANNEL";
export const RESET_CHAT = "RESET_CHAT";
export const UPDATE_READ_USER_STATUS = "UPDATE_READ_USER_STATUS";
export const SWITCH_MEMBER_LOADER = "SWITCH_MEMBER_LOADER";
export const UPDATE_UNREAD_MESSAGE_COUNT = "UPDATE_UNREAD_MESSAGE_COUNT";
export const RESET_EDIT = "RESET_EDIT";
export const SWITCH_PIN = "SWITCH_PIN";
export const SWITCH_TO_PINNED = "SWITCH_TO_PINNED";
export const SET_PINNED_MESSAGE = "SET_PINNED_MESSAGE";
export const READ_MESSAGE_WITH_SCROLL = "READ_MESSAGE_WITH_SCROLL";
export const SET_THREAD_MODE = "SET_THREAD_MODE";
export const SWITCH_COMMENT = "SWITCH_COMMENT";
export const CLOSE_THREAD_MODE = "CLOSE_THREAD_MODE";
export const SWITCH_THREADS_LIST = "SWITCH_THREADS_LIST";
export const SEND_MESSAGE_IN_THREAD = "SEND_MESSAGE_IN_THREAD";
export const SEND_MESSAGES_IN_THREAD = "SEND_MESSAGES_IN_THREAD";
export const SWITCH_REACTION_MODE = "SWITCH_REACTION_MODE";
export const UPDATE_MESSAGE_REACTIONS = "UPDATE_MESSAGE_REACTIONS";
export const SWITCH_TOOLTIP = "SWITCH_TOOLTIP";
export const SET_USERS_REACTION = "SET_USERS_REACTION";
export const SET_UNREAD_MESSAGE = "SET_UNREAD_MESSAGE";
export const ADD_USERS_REACTION = "ADD_USERS_REACTION";
export const COMPARE_THREADS_MESSAGE = "COMPARE_THREADS_MESSAGE";
export const READ_THREAD_MESSAGE = "READ_THREAD_MESSAGE";
export const DELETE_OPEN_THREAD = "DELETE_OPEN_THREAD";
