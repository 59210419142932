<template>
  <tr class="table__head">
    <th class="table__cell" v-for="(cell, index) in tableHeadList" :key="`cell-${index}`">
      {{ cell.name }}
      <button
        class="table__sort"
        type="button"
        v-if="cell.sort"
        :class="{
          asc: isAscSort(cell),
          active: isActive(cell)
        }"
        @click="setSort(cell.valueSort)"
      >
        <i class="icon-arrow-short"></i>
      </button>
    </th>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";

export default {
  name: "TableHeader",
  mixins: [],
  props: {
    tableHeadList: {
      type: Array,
      default: () => []
    },
    filterActive: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data() {
    return {
      activeSort: null
    };
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["getTableData"]),
    ...mapMutations("TablesModules", [
      "SET_SORT",
      "SET_SORT_USERS",
      "RESET_SORT",
      "SET_SORT_BLACK_SPHERE_STATISTICS",
      "SET_TABLE_PARAMS",
      "CLEAR_TABLE_DATA",
      "RESET_PAGINATION"
    ]),
    isAscSort(cell) {
      if (this.getRoutes === "BlackSphereMarksTable") {
        return !this.valueSortListBlackSphere.find((item) => item.value === cell.valueSort).isAsc;
      } else if (this.getRoutes === "BlackSphereStatisticsTable") {
        return !this.valueSortListBlackSphereStatistic.find((item) => item.value === cell.valueSort)
          .isAsc;
      } else return !this.valueSortList.find((item) => item.value === cell.valueSort).isAsc;
    },
    isActive(cell) {
      if (this.getRoutes === "BlackSphereMarksTable") {
        return this.valueSortBlackSphere.value === cell.valueSort;
      } else if (this.getRoutes === "BlackSphereStatisticsTable") {
        return this.valueSortBlackSphereStatistic.value === cell.valueSort;
      } else return (this.valueSort.value || this.valueSortUser.value) === cell.valueSort;
    },
    async getTable() {
      //todo_dm:рефакт

      BlackSphere[BlackSphereMutationTypes.LOADER](true);
      await BlackSphere
        .getBlackSphereMarksForTableAction
        //   {
        //   marks: this.filterMark ? this.filterMark.map((item) => item.id) : null,
        //   got_users: this.filterGotUser ? this.filterGotUser.map((item) => item.id) : null,
        //   put_users: this.filterPutUser ? this.filterPutUser.map((item) => item.id) : null,
        //   month: this.filterMonth,
        //   per_page: this.perPageBlackSphereList,
        //   page: this.pageBlackSphereList,
        //   [`sort[${this.valueSortBlackSphere.value}]`]: this.valueSortBlackSphere.isAsc
        //     ? "asc"
        //     : "desc"
        // }
        ();
      BlackSphere[BlackSphereMutationTypes.LOADER](false);
    },
    async getTableStatistic() {
      BlackSphere[BlackSphereMutationTypes.LOADER](true);
      await BlackSphere.getBlackSphereMarksForStatisticAction({
        users: this.filterUser.length ? this.filterUser.map((item) => item.id) : null,
        month: this.filterMonth,
        per_page: this.perPageBlackSphereListStatistic,
        page: this.pageBlackSphereListStatistic,
        [`sort[${this.valueSortBlackSphereStatistic.value}]`]: this.valueSortBlackSphereStatistic
          .isAsc
          ? "asc"
          : "desc"
      });
      BlackSphere[BlackSphereMutationTypes.LOADER](false);
    },
    setSort(val) {
      if (this.getRoutes === "UserInfoTable") {
        this.SET_SORT_USERS(val);
        this.CLEAR_TABLE_DATA();
        this.RESET_PAGINATION();
        this.getTableData(this.tableParams);
      } else if (this.getRoutes === "BlackSphereStatisticsTable") {
        BlackSphere[BlackSphereMutationTypes.SET_VALUE_SORT_STATISTIC](val);
        this.getTableStatistic();
      } else if (this.getRoutes === "BlackSphereMarksTable") {
        BlackSphere[BlackSphereMutationTypes.SET_VALUE_SORT](val);
        this.getTable();
      } else {
        this.SET_SORT(val);
        this.CLEAR_TABLE_DATA();
        this.RESET_PAGINATION();
        this.getTableData(this.tableParams);
      }
    }
  },
  computed: {
    ...mapState("TablesModules", ["valueSort", "valueSortList", "valueSortUser", "tableParams"]),
    getRoutes() {
      return this.$route.name;
    },

    valueSortBlackSphere() {
      return BlackSphere.valueSort;
    },
    valueSortBlackSphereStatistic() {
      return BlackSphere.valueSortStatistic;
    },
    filterMark() {
      return BlackSphere.filterMark;
    },
    filterGotUser() {
      return BlackSphere.filterGotUser;
    },
    filterPutUser() {
      return BlackSphere.filterPutUser;
    },
    filterUser() {
      return BlackSphere.filterUser;
    },
    filterMonth() {
      return BlackSphere.filterMonth;
    },
    perPageBlackSphereList() {
      return BlackSphere.blackSphereListForTablePerPage;
    },
    pageBlackSphereList() {
      return BlackSphere.blackSphereListForTablePage;
    },
    valueSortListBlackSphere() {
      return BlackSphere.valueSortList;
    },
    valueSortListBlackSphereStatistic() {
      return BlackSphere.valueSortListStatistic;
    },
    perPageBlackSphereListStatistic() {
      return BlackSphere.blackSphereListForTablePerPage;
    },
    pageBlackSphereListStatistic() {
      return BlackSphere.blackSphereListForTablePage;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__head {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    padding: 14px 24px;
    background: $surface-default;
    width: fit-content;
    @media (max-height: 450px) {
      padding: 8px 24px;
    }
  }

  &__cell {
    display: flex;
    width: 100%;
    padding: 16px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $text-subdued;

    @media (max-height: 320px) {
      padding: 8px 0;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 8px;
    outline: none;
    transition: all 0.3s ease-in-out;

    i {
      color: $text-subdued;
      font-size: 12px;
    }
  }

  .asc {
    transform: rotate(180deg);
  }

  .active {
    i {
      color: $action-primary;
      font-weight: bold;
    }
  }
}
</style>
