












import { Component, Prop, Vue } from "vue-property-decorator";
import { IHeader, ITableData } from "@/components/MainTable/MainTable.vue";
import Label from "@/modules/Isp/components/IspProblem/Label.vue";
@Component({
  components: { Label }
})
export default class MainCellHeader extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  isHeader!: boolean;
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITableData;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: IHeader;
  isAsc: boolean = true;

  handleSort() {
    this.isAsc = !this.isAsc;
    this.$emit("handleSort", { field: this.cell.field, type: this.isAsc ? "asc" : "desc" });
  }

  get getToolTip() {
    return this.cell.field === "push"
      ? "Для того чтобы получать браузерные Push-уведомления зайдите в настройки уведомлений в браузере. Найдите АОП и выберите “Разрешить”"
      : "";
  }
}
