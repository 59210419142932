































import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/main";

@Component
export default class WorkingCalendarDay extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  customData!: any;
  @Prop({
    default: true,
    type: Boolean
  })
  isWorkingCalendar!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  showTime!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isAdmin!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isEdit!: boolean;

  value: number | string = "";

  timeRangeMask() {
    return ["##"];
  }

  declOfNum(number, titles = ["час", "часа", "часов"]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  handleEdit() {
    if (this.isAdmin && !this.isEdit) {
      this.$emit("handleEdit", this.customData);
    }
  }

  closeEdit() {
    let [val1, val2] = this.value.toString().split("_");
    if (val1 === "_") {
      val1 = "";
    }
    if (val2 === "_" || val2 === undefined) {
      val2 = "";
    }
    const value = val1 + val2;
    if (24 < +value) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `Введенное занчение привышает 24 часа!`
      });
      this.$emit("handleEdit", { date: null });
      this.value = "";
      return;
    }
    if (value.length && +value !== this.customData.hours) {
      this.$emit("updateTime", { ...this.customData, hours: +value });
    } else {
      this.$emit("handleEdit", { date: null });
      this.value = "";
    }
  }

  get hours() {
    if (this.isWorkingCalendar) {
      return `${this.customData.hours} ${this.declOfNum(this.customData.hours)}`;
    } else {
      return this.customData.hours;
    }
  }

  get isVacation() {
    return this.customData?.has_absence;
  }

  get isSick() {
    return this.customData?.has_sick;
  }

  get isDisabled() {
    return +this.customData.hours === 0 || this.customData.hours === "00:00:00";
  }
}
