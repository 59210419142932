export const timeToSec = (time) => {
  const [hh, mm, ss] = time.split(":");
  return +hh * 3600 + +mm * 60 + +ss;
};
export const secToTime = (sec): string => {
  let hours: string | number = Math.floor(sec / (60 * 60));
  hours = stringFormat(hours);
  const divisor_for_minutes = sec % (60 * 60);
  let minutes: string | number = Math.floor(divisor_for_minutes / 60);
  minutes = stringFormat(minutes);
  const divisor_for_seconds = divisor_for_minutes % 60;
  let seconds: string | number = Math.ceil(divisor_for_seconds);
  seconds = stringFormat(seconds);

  return `${hours}:${minutes}:${seconds}`;
};

const stringFormat = (time: number | string): string => {
  return time.toString().length === 1 ? `0${time}` : time.toString();
};
