import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import NotificationObserver from "@/helpers/UserNotification";
import { IBoardColumn, IKanban } from "@/modules/TaskManager/Model/Kanban/KanbanModel";

type paramsTasksType = {
  stage_id: number | null;
  project_id: number;
  executor_id?: number;
};

type paramsChangeStatusTaskType = {
  id: number;
  status_id: number;
};

class KanbanService extends Service {
  getBoard(params: IKanban): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/boards/get_default`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }

  getBoardColumns(params: IKanban): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/board_columns/get_all`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }

  getStatuses(project_id: number | string): Response<any> {
    return httpClient({
      method: "GET",
      url: "/task_manager/flows/get_flow",
      params: {
        project_id
      }
    }).then((response) => {
      return response.data;
    });
  }

  createBoardColumn(body: IKanban): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/board_columns/create`,
      data: body
    }).then((response) => {
      NotificationObserver.notification({
        type: "success",
        message: "Столбец успешно создан!"
      });
      return response.data;
    });
  }

  updateBoardColumn(body: IBoardColumn): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/board_columns/update`,
      data: body
    }).then((response) => {
      NotificationObserver.notification({
        type: "success",
        message: "Столбец успешно изменен!"
      });
      return response.data;
    });
  }

  deleteBoardColumn(params: number): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/board_columns/delete`,
      params: {
        id: params
      }
    }).then((response) => {
      NotificationObserver.notification({
        type: "success",
        message: "Столбец успешно удален!"
      });
      return response.data;
    });
  }

  getUnusedStatus(params: IKanban): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/flows/get_unused_statuses`,
      params
    }).then((response) => {
      return response.data;
    });
  }
  getTasksByStage(params: paramsTasksType): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_kanban_stage`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  changeStatusTask(data: paramsChangeStatusTaskType): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/tasks/update`,
      data
    }).then((response) => {
      return response.data;
    });
  }

  createBoardColumnStatus(body: IKanban): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/board_columns_statuses/create`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  deleteBoardColumnStatus(body: IKanban): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/board_columns_statuses/delete`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }
}

export default new KanbanService();
