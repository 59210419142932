<template>
  <div class="profile__profile-isp profile-isp">
    <div class="profile-isp__header">
      <h2 class="profile-isp__title"><router-link :to="{ name: 'IspMy' }">Мое ISP</router-link></h2>
      <SortTable :listSorts="listSorts" :valueSort="filterRole" :unique="'ISPProfile'" />
    </div>
    <div class="profile-isp__main">
      <ul class="profile-isp__list">
        <ProfileISPItem
          v-for="(item, index) in problemsData"
          :key="index"
          :item="item"
          :role="filterRole.name"
          :currentPeriodName="currentPeriodName"
        />
        <li class="profile-isp__problem profile-isp__problem_empty" v-if="!problemsData.length">
          Нет проблем :(
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SortTable from "@/components/GraphVacation/TimeLine/SortTable/SortTable";
import axios from "axios";
import { domain } from "@/globalVariables";
import ProfileISPItem from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileISP/ProfileISPItem";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ProfileISP",
  mixins: [],
  props: {},
  components: { ProfileISPItem, SortTable },
  data() {
    return {
      statusPeriod: "В работе",
      currentPeriod: null,
      currentPeriodName: "",
      problemsData: [],
      filterRole: {
        name: "Ответственный",
        value: "responsible_user_id"
      },
      listSorts: [
        {
          name: "Ответственный",
          value: "responsible_user_id"
        },
        {
          name: "Исполнитель",
          value: "task_executors"
        },
        {
          name: "Наблюдатель",
          value: "only_viewer_flag"
        },
        {
          name: "Автор",
          value: "creator_user_id"
        },
        {
          name: "Заказчик",
          value: "customer_user_id"
        }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off("chosenFilter_ISPProfile");
  },
  mounted() {
    if (this.getUserId) {
      this.getCurrentPeriod(this.statusPeriod);
    }
    this.$root.$on("goGetInfoUser", () => {
      this.getCurrentPeriod(this.statusPeriod);
    });
    this.$root.$on("chosenFilter_ISPProfile", (val) => {
      this.filterRole = val;
      if (this.$route.params.user_id) {
        this.getProblemsList(this.currentPeriod, this.$route.params.user_id);
      } else {
        this.getProblemsList(this.currentPeriod, this.getUserId);
      }
    });
  },
  methods: {
    getCurrentPeriod(status) {
      httpClient({
        url: "/ISPPeriod/actionGetItems",
        method: "GET",
        params: {
          status: status
        }
      })
        .then((response) => {
          this.currentPeriod = response.data.data.items[0].id;
          this.currentPeriodName = response.data.data.items[0].name;
          if (this.$route.params.user_id) {
            this.getProblemsList(this.currentPeriod, this.$route.params.user_id);
          } else {
            this.getProblemsList(this.currentPeriod, this.getUserId);
          }
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    getProblemsList(period, userId) {
      httpClient({
        method: "GET",
        url: `/ISPProblem/actionGetItems`,
        params: {
          _count: 300,
          period_id: period,
          responsible_user_id: this.filterRole.name === "Ответственный" ? userId : undefined,
          viewer_users:
            this.filterRole.name === "Наблюдатель" ? { viewer_user_id: userId } : undefined,
          creator_user_id: this.filterRole.name === "Автор" ? userId : undefined,
          customer_user_id: this.filterRole.name === "Заказчик" ? userId : undefined,
          task_executors:
            this.filterRole.name === "Исполнитель"
              ? JSON.stringify([{ executor_user_id: userId }])
              : undefined,
          _with: JSON.stringify(["status", "criticality", "task_executors", "responsible_user"])
        }
      })
        .then((response) => {
          this.problemsData = response.data.data.items;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.profile-isp {
  width: 100%;
  height: 100%;
  min-height: 750px;
  background: $surface-default;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 24px;

  @media (max-width: $xl) {
    min-height: 300px;
    height: fit-content;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $xxxl) {
      padding: 24px 24px 16px;
    }

    @media (max-width: $sm) {
      padding: 16px 16px 8px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: $text-default;

    a {
      color: $text-default;

      &:hover {
        color: $text-subdued;
      }
    }

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  /deep/ {
    .sort-table {
      width: max-content;
    }

    .sort-table__label {
      margin: 0;
      max-width: max-content;

      span {
        @media (max-width: $xl) {
          display: block;
        }
      }
    }

    .sort-table__list {
      left: unset;
      right: 0;
    }

    .sort-table__item {
      width: 165px;
    }
  }

  &__main {
    width: 100%;
    height: 85%;

    @media (max-width: $xxxl) {
      height: 88%;
    }

    @media (max-width: $xl) {
      height: 100%;
    }
  }

  &__list {
    width: 100%;
    height: 100%;
    padding: 0 0 16px;
    margin: 0;
    list-style: none;
    overflow-y: scroll;
  }

  &__problem {
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $text-subdued;
    }
  }
}
</style>
