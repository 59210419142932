<template>
  <tr class="table__row" @click.middle="routerGo">
    <TableCell
      v-for="(cell, index) in tableHeadList"
      :key="index"
      :row="row"
      :cell="cell"
      :tableHead="tableHeadList"
    >
      <slot slot-scope="props" :row="props.row" :cell="props.cell"></slot>
    </TableCell>
  </tr>
</template>

<script>
import TableCell from "@/modules/Isp/components/Table/TableCell";

export default {
  name: "UsersTableRow",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    tableHeadList: {
      type: Array,
      default: () => []
    }
  },
  components: { TableCell },
  data() {
    return {};
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    routerGo() {
      let routeData = this.$router.resolve({ name: "IspProblem", params: { id: this.row.id } });
      window.open(routeData.href, "_blank");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.table {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    width: fit-content;
    border-radius: 8px;
    cursor: pointer;

    @media (max-height: 320px) {
      padding: 8px 24px;
    }
  }

  &__row:hover {
    background: $bg-surface-light;
  }
}
</style>
