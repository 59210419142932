


























import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";

@Component({
  components: { TextLabel, ButtonIcon }
})
export default class TextInput extends Vue {
  @Prop({
    type: String,
    required: true
  })
  id!: string;
  @Prop({
    type: String,
    default: ""
  })
  label!: string;
  @Prop({
    type: String,
    default: "Placeholder"
  })
  placeholder!: string;
  @Prop({
    type: String,
    default: ""
  })
  icon!: string;
  @Prop({
    type: String,
    default: "text"
  })
  type!: string;
  @Prop({
    type: Array,
    default: null
  })
  inputMask!: any;
  @Prop({
    type: Number,
    default: 10000
  })
  maxLength!: number;
  @Prop({
    type: Boolean,
    default: false
  })
  isLeftIcon!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  handleIcon() {
    this.$emit("handleIcon");
  }

  get iconClass() {
    return ["text-input__icon", this.isLeftIcon ? "text-input__icon_left" : ""];
  }
}
