import IspReview from "@/modules/Isp/store/state/IspReview/IspReview";
import IspMy from "@/modules/Isp/store/state/IspMy/IspMy";
import IspTeams from "@/modules/Isp/store/state/IspTeams/IspTeams";
import IspProblem from "@/modules/Isp/store/state/IspProblem/IspProblem";
import Search from "@/modules/Isp/store/state/Search/Search";

const state = {
  ...IspReview,
  ...IspMy,
  ...IspTeams,
  ...IspProblem,
  ...Search,
  infoModalMode: null,
  loaderTable: false,
  loaderQuarter: true,
  problemsData: [],
  backlogProblemsData: [],
  valueSortList: [
    { isAsc: true, value: "criticality_id" },
    { isAsc: true, value: "id" },
    { isAsc: true, value: "customer_user" },
    { isAsc: true, value: "responsible_user" },
    { isAsc: true, value: "completion_percentage" }
  ],
  valueSort: { isAsc: true, value: "criticality_id" },
  showConfirmModal: false,

  //FILTERS
  statusesList: [],
  criticalityList: [],
  usersList: [],

  problemsParams: null,
  problemsMyIspParams: null
};

export default state;
