<template>
  <div class="profile__history">
    <div class="history-start">
      <div class="history-circle"></div>
      <div v-if="dataUserHistory.length">Старт: {{ startWork }}</div>
    </div>
    <div class="profile-structure">
      <div class="profile-structure__content" v-for="arrayRole in dataUserHistory" :key="arrayRole">
        <HistoryCard
          v-for="(item, index) in arrayRole"
          :key="item.id"
          :item="item"
          :lastCard="!item.to_date ? true : false"
          :isAdmin="isAdmin"
          :userCard="userCard"
          @goGetHistoryInfoUser="initialHistory"
          @createHistoryCard="changeHistoryCreateAndEditModal"
        />
        <div class="profile-structure__line">
          <div class="history-circle"></div>
          <div class="history-line"></div>
        </div>
      </div>
      <div class="empty-history" v-if="!dataUserHistory.length && !loader">
        Не заполнено :( <br />
        Обратитесь к специалистам из<br />
        отдела кадров
      </div>
    </div>
    <div class="history-exp">
      <div class="history-circle"></div>
      <div v-if="dataUserHistory.length">Стаж в компании: {{ experienceCompany }}</div>
    </div>
    <button
      class="history__create"
      v-if="isAdmin && userCard"
      type="button"
      @click="changeHistoryCreateAndEditModal(true)"
    >
      <i class="icon-plus"></i>
      <p>Добавить путь в компании</p>
    </button>
    <HistoryCreateAndEditModal
      v-if="isHistoryCreateAndEditModal"
      :editValue="editValue"
      @goGetHistoryInfoUser="initialHistory"
      @hideModal="changeHistoryCreateAndEditModal"
    />
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import HistoryCard from "@/components/ProfileComponents/ProfileHistory/HistoryCard";
import { format, formatDuration } from "date-fns";
import { ru } from "date-fns/locale";
import moment from "moment";
import HistoryCreateAndEditModal from "@/components/ProfileComponents/ProfileHistory/HistoryCreateAndEditModal";
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ProfileHistory",
  mixins: [],
  props: [],
  components: { HistoryCard, HistoryCreateAndEditModal },
  data() {
    return {
      startWork: "",
      experienceCompany: "",
      dataUserHistory: [],
      userCard: false,
      isHistoryCreateAndEditModal: false,
      editValue: null,
      loader: true
    };
  },
  beforeDestroy() {
    this.$root.$off("changeHistoryCreateAndEditModal");
    this.$root.$off("EditUserHistory");
  },
  mounted() {
    this.$root.$on("EditUserHistory", (val) => {
      this.changeHistoryCreateAndEditModal(true, val);
      this.editValue = val;
    });
  },
  methods: {
    initialHistory() {
      if (!this.$route.params.user_id && this.getUserId) {
        this.getUserHistoryInfo(this.getUserId);
        this.userCard = false;
      } else if (this.getUserId) {
        this.getUserHistoryInfo(this.$route.params.user_id);
        this.userCard = true;
      }
    },
    /**
     * getting a history in the company for a current employee
     * @param id - id current user
     */
    getUserHistoryInfo(id) {
      httpClient({
        method: "GET",
        url: `/UserAffiliation/getItems`,
        params: {
          user_id: id,
          _count: 300,
          _order: { role_id: "asc", to_date: "asc" },
          _with: JSON.stringify(["Role", "Department", "Level"])
        }
      })
        .then((response) => {
          this.setDataUserHistoryRelation(response.data.data);
        })
        .catch((error) => this.errorAlert(error));
    },
    /**
     * Function of obtaining information about departments, level and roles of an employee
     * @param resp - user data
     */
    setDataUserHistoryRelation(resp) {
      let items = resp.items;
      for (let i in items) {
        if (items[i].level_id) {
          for (let k in resp.relations.Level) {
            if (items[i].level_id === parseInt(k)) {
              items[i].level_id = resp.relations.Level[k];
            }
          }
        }
        if (items[i].department_id) {
          for (let k in resp.relations.Department) {
            if (items[i].department_id === parseInt(k)) {
              items[i].department_id = resp.relations.Department[k];
            }
          }
        }
        if (items[i].role_id) {
          for (let k in resp.relations.Role) {
            if (items[i].role_id === parseInt(k)) {
              items[i].role_id = resp.relations.Role[k];
            }
          }
        }
      }
      this.getExperienceCompany(items);
    },
    /**
     * the function of obtaining a path in the company, length of service and the date of starting work in the company
     * @param items - array of cards history in the company
     */
    getExperienceCompany(items) {
      if (!items.length) {
        this.dataUserHistory = [];
        this.startWork = format(new Date(), "dd.MM.yyyy", {
          locale: ru
        });
        this.experienceCompany = "1 день";
        this.loader = false;
        return false;
      }
      let arrayAll = [];
      for (let i in items) {
        let arrayRole = [];
        for (let j in items) {
          if (items[i].role_id.id === items[j].role_id.id) {
            arrayRole.push(items[j]);
          }
        }
        arrayAll.push(arrayRole);
      }
      this.dataUserHistory = arrayAll.filter((item, index, arr) => {
        if (!arr[index + 1]) return true;
        return item[0].role_id.id !== arr[index + 1][0].role_id.id;
      });
      this.loader = false;
      this.setStartWorkDayAndExperienceCompany(this.dataUserHistory);
      this.sortHistoryCardAscendingEndDate(this.dataUserHistory);
    },
    /**
     * sort cards in ascending order of end date
     * @param items - array of cards history in the company
     */
    sortHistoryCardAscendingEndDate(items) {
      items.sort(function (arrayNow, arrayNext) {
        let nowDate = arrayNow[arrayNow.length - 1].to_date
          ? new Date(arrayNow[arrayNow.length - 1].to_date)
          : new Date();
        let nextDate = arrayNext[arrayNext.length - 1].to_date
          ? new Date(arrayNext[arrayNext.length - 1].to_date)
          : new Date();
        return nextDate - nowDate;
      });
    },
    /**
     * set Start work Date and set experience in the company
     * @param items array of cards history in the company
     */
    setStartWorkDayAndExperienceCompany(items) {
      let sortedItems = JSON.parse(JSON.stringify(items)).sort(function (arrayNow, arrayNext) {
        let nowDate = new Date(arrayNow[0].from_date);
        let nextDate = new Date(arrayNext[0].from_date);
        return nowDate - nextDate;
      });
      this.startWork = format(new Date(sortedItems[0][0].from_date), "dd.MM.yyyy", {
        locale: ru
      });
      // TODO: Продумать при реархитектуре
      if (this.$route.params.user_id) {
        httpClient({
          method: "GET",
          url: `/User/actionGetItem/${this.$route.params.user_id}`,
          params: {}
        }).then((response) => {
          this.experienceCompany = this.getDateDistance(
            sortedItems[0][0].from_date,
            response.data.data.date_of_dismissal
          );
        });
      } else {
        this.experienceCompany = this.getDateDistance(sortedItems[0][0].from_date);
      }
    },
    getDateDistance(date, endDate) {
      let starts = moment(new Date(date));
      let ends = !endDate
        ? this.getDataInfoUser.date_of_dismissal
          ? moment(new Date(this.getDataInfoUser.date_of_dismissal))
          : moment()
        : moment(new Date(endDate));
      // Если дата назначения позже текущей, то стаж не выводится
      if (moment(starts).isAfter(moment())) {
        return "-";
      } else {
        return formatDuration(
          {
            years: moment.preciseDiff(starts, ends, true).years,
            months: moment.preciseDiff(starts, ends, true).months,
            days: moment.preciseDiff(starts, ends, true).days
          },
          {
            locale: ru
          }
        );
      }
    },
    changeHistoryCreateAndEditModal(show, value) {
      this.isHistoryCreateAndEditModal = show;
      this.editValue = value;
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isAdmin() {
      return this.getEndpoints("User", "update");
    }
  },
  watch: {
    getUserId: {
      handler() {
        this.initialHistory();
      },
      immediate: true
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.profile {
  &__history {
    position: relative;
    height: calc(100% - 30px);

    @media (max-width: $sm) {
      height: calc(100% - 20px);
    }

    .profile-structure {
      display: flex;
      flex-direction: column;
      padding: 60px 0 60px 60px;
      border-left: 2px solid $focused-default;
      height: calc(100% - 70px);
      overflow: scroll;

      @media screen and (max-width: $md) {
        align-items: center;
        padding: 0;
        border: none;
        height: 100%;
      }

      &__content {
        position: relative;
        display: flex;
        width: fit-content;
        margin-bottom: 68px;
        @media screen and (max-width: $md) {
          flex-direction: column;
          margin-bottom: 0;
          width: 80%;
        }
        @media screen and (max-width: $sm) {
          width: 100%;
        }
      }
      &__content:last-child {
        margin-bottom: 0;
      }
      &__line {
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        width: 100%;
        top: 50%;
        left: -71px;
        @media screen and (max-width: $md) {
          display: none;
        }
        .history-circle {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: $focused-default;
          margin-right: 0;
        }
        .history-line {
          width: 100%;
          height: 2px;
          background: $text-highlight;
        }
      }
    }
    .history-start {
      display: flex;
      align-items: center;
      margin-left: -11px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: $text-subdued;
      @media screen and (max-width: $md) {
        display: none;
      }
    }
    .history-exp {
      display: flex;
      align-items: center;
      margin: 0 0 20px -11px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: $text-subdued;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        margin: 0 0 8px -11px;
      }

      @media screen and (max-width: $md) {
        display: none;
      }
    }
    .history-circle {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: $focused-default;
      margin-right: 23px;
    }
  }
  .empty-history {
    display: flex;
    width: 100%;
    height: 70vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-subdued;
  }

  .history {
    &__create {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      padding: 5px 12px;
      border-radius: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $text-disabled;
      border: 1px solid $border-default;
      outline: none;
      @media (max-width: $md) {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 15;
        padding: 7px;
        background-color: $surface-default;
        p {
          display: none;
        }
        i {
          margin-right: 0 !important;
        }
      }
      &:hover {
        color: $text-default;
        background: $action-secondary-hovered;
      }

      &:active {
        color: $text-default;
        background: $action-secondary-pressed;
      }

      i {
        margin-right: 8px;
      }
    }
  }
}
</style>
