const actions = {
  observeActionItem({ commit }, payload) {
    switch (payload) {
      default: {
        alert("передан тип " + payload);
        break;
      }
    }
  }
};

export default actions;
