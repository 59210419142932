





















import { Component, Prop, Vue } from "vue-property-decorator";
import { IModalConfig } from "@/modules/TimeTracker/interface/interface";
import Button from "@/modules/UiKit/components/buttons/Button.vue";

@Component({
  components: { Button }
})
export default class ConfirmationModal extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  modalConfig!: IModalConfig;

  created() {
    document.addEventListener("keydown", this.onEscape);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscape);
  }

  onEscape(e) {
    if (e.keyCode === 27) {
      this.handleCancel();
    }
    if (e.keyCode === 13) {
      this.handleConfirm();
    }
  }

  handleCancel() {
    if (this.modalConfig) this.modalConfig.handleCancel();
  }

  handleConfirm() {
    if (this.modalConfig) this.modalConfig.handleConfirm();
  }

  get isCenter() {
    return !this.modalConfig.confirmButton.length || !this.modalConfig.cancelButton.length;
  }
}
