<template>
  <div class="project-stages-table">
    <ProjectStagesTableBody :tableHead="tableHead" :stages="stages" :projectId="projectId" />
  </div>
</template>

<script>
import ProjectStagesTableBody from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableBody";

export default {
  name: "ProjectStagesTable",
  mixins: [],
  props: {
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    },
    projectId: {
      type: String,
      default: "0"
    },
    stages: {
      type: Object,
      default: () => {}
    }
  },
  components: { ProjectStagesTableBody },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    isCurrentStage() {
      return false;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.project-stages-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
