











import { Component, Prop, Vue } from "vue-property-decorator";
import MainSidebar from "@/aopV2/layout/LayoutMain/MainSidebar/MainSidebar.vue";
import LayoutSideNav from "@/layout/SideNav/LayoutSidenav.vue";
import NotificationModal from "@/modules/Notification/components/NotificationModal.vue";
import { mapState } from "vuex";

@Component({
  components: {
    LayoutSideNav,
    // MainSidebar,
    NotificationModal
  },
  computed: mapState("Notification", ["isShowNotificationModal"])
})
export default class LayoutMain extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  userInfo!: any;
  @Prop({
    type: Boolean,
    default: false
  })
  isNewDesign!: boolean;

  created() {
    window.addEventListener("resize", (e) => {
      this.setSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }

  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      this.setSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }

  setSize(size: { height: number; width: number }) {
    this.$store.commit("MainLayoutSetting/setWindowSize", size);
  }
}
