import centrifuge from "@/centrifuge";
import httpClient from "@/api/client/httpClient";

export default {
  data() {
    return {
      pullName: "",
      chunksArr: [],
      fileUploader: [],
      fileUploadingId: []
    };
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPull(arr) {
      if (!arr.length) {
        return null;
      }
      httpClient({
        url: `/FileManager/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].name,
            sync: true
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.data.pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      await this.getBase64(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArr = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArr = base64.match(/.{1,5000000}/g);
        }
      });
      let countError = 0;
      let nulls =
        Array.from(this.chunksArr.length.toString())
          .map(() => "0")
          .join("") + "0";
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        let chunkName = nulls.substr(0, nulls.length - i.toString().length) + i;
        await httpClient({
          url: `/FileManager/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              chunk_name: chunkName,
              sync: true,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `/FileManager/collectFileFromPool`,
        method: "POST",
        data: [
          {
            pool_name: this.pullName,
            sync: false
          }
        ]
      })
        .then((response) => {
          this[`${response.data.data.channel}`] = centrifuge.subscribe(
            response.data.data.channel,
            (message) => {
              if (message.data.code === 422) {
                this.loaderFiles = false;
                this.$root.$emit("showNotification", {
                  type: "error",
                  timeout: 3000,
                  label: `Выбранный Вами файл не подходит по расширению или размеру!`
                });
                return false;
              }
              this.saveFileId(message.data.data.idFile, response.data.data.channel);
            }
          );
          this[`${response.data.data.channel}`].history().then(
            (resp) => {
              if (resp.publications.length) {
                if (resp.publications[0].data.code && resp.publications[0].data.code === 422) {
                  this.loaderFiles = false;
                  this.$root.$emit("showNotification", {
                    type: "error",
                    timeout: 3000,
                    label: `Выбранный Вами файл не подходит по расширению или размеру!`
                  });
                  return false;
                }
                this.saveFileId(resp.publications[0].data.data.idFile, response.data.data.channel);
              }
            },
            (err) => {
              console.error(err);
            }
          );
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    saveFileId(item, channel) {
      let arrFiles = JSON.parse(JSON.stringify(this.fileUploadingId));
      arrFiles.push(item);
      this.fileUploadingId = [...new Set(arrFiles)];
      this[`${channel}`].unsubscribe();
    }
  }
};
