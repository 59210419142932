






import { Component, Prop, Vue } from "vue-property-decorator";
import WorkingCalendar from "@/modules/TimeTracker/component/WorkingCalendar/WorkingCalendar.vue";

@Component({
  components: { WorkingCalendar }
})
export default class WorkingCalendarModal extends Vue {
  @Prop({ type: Boolean, default: false }) isAdmin!: boolean;

  closeModal() {
    this.$emit("hideProfileWorkingCalendar");
  }

  updateWorkingCalendar(date) {
    this.$emit("updateWorkingCalendar", date);
  }
}
