<template>
  <div class="modal-card">
    <Widget type="loader-alternative" v-if="loading" />
    <div class="modal-card__container" v-else>
      <h4 class="modal-card__title">
        <span>{{ cardData.data.name }}</span>
        <i class="icon-cross" @click="hideModal"></i>
      </h4>
      <ul class="modal-card__list">
        <li class="modal-card__item">
          <h5 class="modal-card__item-title">
            <i class="icon-file"></i>
            <span>Цель</span>
          </h5>
          <p class="modal-card__text" v-html="setURL(setFinalProduct)"></p>
        </li>
        <li class="modal-card__item">
          <h5 class="modal-card__item-title">
            <i class="icon-info"></i>
            <span>Подсказка</span>
          </h5>
          <p class="modal-card__text" v-html="setURL(setPrompt)"></p>
        </li>
        <li class="modal-card__item">
          <h5 class="modal-card__item-title">
            <i class="icon-user"></i>
            <span>Ответственные и участники</span>
          </h5>
          <ul class="modal-card__responsible-list">
            <li class="modal-card__responsible-item" v-if="cardData.data.responsible_user !== null">
              <span>Ответственный</span>
              <div class="modal-card__user">
                <img
                  :src="returnSrcFile(cardData.data.responsible_user.avatar)"
                  alt=""
                  class="modal-card__avatar"
                  width="24px"
                  height="24px"
                />
                <p class="modal-card__username">{{ cardData.data.responsible_user.full_name }}</p>
              </div>
            </li>
            <li class="modal-card__responsible-item" v-if="cardData.data.assistant_user !== null">
              <span>Заместитель</span>
              <div class="modal-card__user">
                <img
                  :src="returnSrcFile(cardData.data.assistant_user.avatar)"
                  alt=""
                  class="modal-card__avatar"
                  width="24px"
                  height="24px"
                />
                <p class="modal-card__username">{{ cardData.data.assistant_user.full_name }}</p>
              </div>
            </li>
          </ul>
          <span class="modal-card__member">Участники</span>
          <ul class="modal-card__member-list">
            <li class="modal-card__member-item" v-for="(item, index) in memberUsers" :key="index">
              <div class="modal-card__user">
                <img
                  :src="returnSrcFile(item.avatar)"
                  alt=""
                  class="modal-card__avatar"
                  width="24px"
                  height="24px"
                />
                <p class="modal-card__username">{{ item.full_name }}</p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="modal-card__access" v-if="isAdmin">
        <p class="modal-card__access-text">Ограничение доступа:</p>
        <p class="modal-card__access-value">
          {{ cardData.data.is_public ? "Выключено" : "Включено" }}
        </p>
      </div>
      <div class="modal-card__buttons" v-if="isAdmin">
        <Widget type="Button" label="Удалить" outlined="true" unique="modal-view-card-delete" />
        <Widget type="Button" label="Редактировать" unique="modal-view-card-update" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { domain, domainStorage } from "@/globalVariables";
import Widget from "@/components/Widgets/Widget";
import Swal from "sweetalert2";
import UrlMixin from "@/helpers/UrlMixin";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "ModalViewCard",
  mixins: [UrlMixin],
  props: ["cardData", "isAdmin"],
  components: { Widget },
  data() {
    return {
      loading: true,
      memberUsers: []
    };
  },
  mounted() {
    this.getMembersUsers();
    this.$root.$on("button:click_modal-view-card-delete", () => {
      this.askDeleteCard();
    });
    this.$root.$on("button:click_modal-view-card-update", () => {
      this.$root.$emit("showCreateChangeCardModal", { type: true, data: this.cardData.data });
      this.hideModal();
    });
  },
  beforeDestroy() {
    this.$root.$off("button:click_modal-view-card-delete");
    this.$root.$off("button:click_modal-view-card-update");
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    hideModal() {
      this.$emit("hideModalViewCard");
    },
    getMembersUsers() {
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _count: 300,
          org_structure_items: JSON.stringify([
            { "org_structure_items.id": this.cardData.data.id }
          ]),
          _with: JSON.stringify(["org_structure_items", "Roles"])
        }
      })
        .then((response) => {
          this.memberUsers = response.data.data.items;
          this.loading = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    deleteCard() {
      httpClient({
        url: `/OrgStructureItem/actionDelete/${this.cardData.data.id}`,
        method: "DELETE"
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Карточка успешно удалена!`
          });
          this.hideModal();
          this.$root.$emit("updateStructureChart");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    askDeleteCard() {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить карточку?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteCard();
          }
        });
    }
  },
  computed: {
    setFinalProduct() {
      return this.cardData.data.final_product
        ? this.cardData.data.final_product
        : "Описание отсутствует";
    },
    setPrompt() {
      return this.cardData.data.prompt ? this.cardData.data.prompt : "Описание отсутствует";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/color/index";

::-webkit-scrollbar {
  height: 2px;
  border-radius: 10px;
  overflow-x: auto;
  width: 2px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  background: $disabled-gray;
  border-radius: 10px;
  width: 2px;
  padding-right: 10px;
}
.modal-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  word-break: break-word;

  background-color: rgba(184, 184, 203, 0.2);

  &__container {
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: 567px;
    width: 100%;
    padding: 0 23px 30px;
    background-color: $color-white;
    overflow-y: auto;
    max-height: 80vh;
    border: 1px solid $color-contour;
    border-radius: 10px;
  }

  &__title {
    position: sticky;
    padding-top: 30px;
    top: 0;
    background-color: #fff;
    color: $color-text-accent;
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-size: 18px;

    .icon-cross {
      font-size: 21px;
      color: $color-text-secondary;
      cursor: pointer;
      &:hover {
        color: $color-button-hover;
      }
    }
  }

  &__list {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-title {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    padding: 0;
    font-size: 14px;
    line-height: 13px;
    font-weight: 500;
    color: $color-text-primary-1;

    i {
      margin-right: 10px;
      font-size: 16px;
      color: $color-text-secondary;
    }
  }

  &__text {
    max-width: 457px;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin: 0 0 0 26px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    color: $color-text-primary-2;
    word-break: break-word;
    white-space: pre-wrap;

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }

  &__responsible-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__responsible-item {
    &:first-child {
      margin-right: 30px;
    }

    span {
      display: flex;
      margin-top: 16px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 13px;
      font-weight: 400;
      color: $color-text-secondary;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__avatar {
    object-fit: cover;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &__username {
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: $color-grey;
  }

  &__member {
    display: flex;
    margin-top: 26px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 13px;
    font-weight: 400;
    color: $color-text-secondary;
  }

  &__member-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    max-height: 80px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  }

  &__member-item {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__access {
    display: flex;
    margin-top: 29px;
  }

  &__access-text,
  &__access-value {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $color-text-secondary;
  }

  &__access-value {
    margin-left: 10px;
    color: $color-charts-purple-1;
  }
  &__buttons {
    display: flex;
    max-width: 282px;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-top: 27px;
  }
}
</style>
