<template>
  <li
    ref="tab"
    @click="$emit('updateTab', idx)"
    :class="[
      'vacation-table__nav-item',
      idx === activeTab ? 'vacation-table__nav-item_active' : ''
    ]"
  >
    <p class="title">{{ tab }} ({{ setTabsContentCount }})</p>
  </li>
</template>

<script>
export default {
  name: "TabRequest",
  mixins: [],
  props: {
    tab: {
      type: String,
      default: ""
    },
    idx: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    },
    myVacationLength: {
      type: Number,
      default: 0
    },
    vacationPendingRequestLength: {
      type: Number,
      default: 0
    },
    transferOrderRequestLength: {
      type: Number,
      default: 0
    },
    absenceVacationRequestLength: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setTabsContentCount() {
      switch (this.tab) {
        case "Мои запросы":
          return this.myVacationLength;
        case "Переносы":
          return this.transferOrderRequestLength;
        case "Отпуска":
          return this.absenceVacationRequestLength;
        default:
          return this.vacationPendingRequestLength;
      }
    }
  },
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.vacation-table {
  .title {
    white-space: nowrap;
  }
  &__nav {
    position: sticky;
    top: 0;
    z-index: 10;

    padding-top: 30px;

    background-color: #fff;
    border-bottom: 1px solid $border-default;

    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
    color: $text-subdued;

    @media (max-width: $sm) {
      padding: 0;
      font-size: 10px;
      line-height: 20px;
    }
  }
  &__nav-list {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__nav-item {
    position: relative;
    margin-left: 24px;
    padding-bottom: 16px;
    cursor: pointer;
    font-feature-settings: "salt" on;
    transition: color 0.3s ease-in-out;

    &:first-child {
      margin-left: 0;
    }

    &_active {
      position: relative;
      color: $text-highlight;
      transition: all 0.3s ease-in-out;
      animation-name: font;
      text-shadow: 0 0 0.65px $text-highlight, 0 0 0.65px $text-highlight,
        0 0 0.65px $text-highlight;
    }

    &_background {
      position: absolute;

      transition: all 0.3s ease;
      z-index: 2;
      bottom: 0;
      top: auto;

      background-color: $color-accent;
      border-radius: 0;
    }
  }

  @keyframes font {
    from {
      text-shadow: 0 0 0 transparent, 0 0 0 transparent;
    }
    to {
      text-shadow: 0 0 0.65px $text-highlight, 0 0 0.65px $text-highlight;
    }
  }

  /deep/ {
    .form-group__input {
      height: auto;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.2px;
      color: #92929d;

      padding: 12px 10px 11px 48px;

      border: 1px solid #e2e2ea;
      border-radius: 12px;
      &::placeholder {
        color: #92929d;
      }
    }
  }
}
</style>
