import { IBaseUser } from "@/store/Users/interfaces/interfaces";

export class UsersList<T extends IBaseUser> {
  users: Array<T>;
  constructor(users: Array<T>) {
    this.users = users;
  }
  get filterList() {
    return (field: string | symbol, value: any) =>
      this.users.filter((user) => user[field] === value);
  }
  get filedList() {
    return (fields: Array<string | symbol>) => {
      const idFields = ["id", ...fields];
      return this.users.map((user) => {
        const array: Array<any> = idFields.map((item) => [item, user[item]]);
        const entries = new Map<string | symbol, any>(array);
        return Object.fromEntries(entries);
      });
    };
  }
}

export function UserListFactory<T extends IBaseUser>(users: Array<T>): UsersList<T> {
  return new UsersList(users);
}
