
































































import { Component, Prop, Vue } from "vue-property-decorator";
import SelectItem from "@/aopV2/components/UIComponents/SelectList/SelectItem.vue";
import SelectedItem from "@/aopV2/components/UIComponents/SelectList/SelectedItem.vue";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import CheckboxInput from "@/aopV2/components/UIComponents/Input/Check/Checkbox/CheckboxInput.vue";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import { EventBus } from "@/main";

@Component({
  components: { ButtonIcon, UserCard, SelectedItem, SelectItem, ButtonNewDesign, CheckboxInput }
})
export default class SelectList extends Vue {
  @Prop({
    default: () => []
  })
  list!: Array<any>;
  @Prop({
    default: () => []
  })
  selected!: Array<any>;
  @Prop({
    default: true
  })
  isMultiple!: boolean;
  @Prop({
    default: true
  })
  isShowSelected!: boolean;
  @Prop({
    default: false
  })
  isShowSearch!: boolean;
  @Prop({
    default: false
  })
  isDelete!: boolean;
  @Prop({
    default: false
  })
  isShowChangePanel!: boolean;
  @Prop({
    default: false
  })
  isShowAvatar!: boolean;
  @Prop({
    default: false
  })
  isUser!: boolean;

  @Prop({
    default: "select-list",
    required: true
  })
  unique!: string;
  @Prop({
    default: -1,
    type: Number
  })
  limit!: number;
  @Prop({
    default: false,
    type: Boolean
  })
  isDeleteItem!: boolean;

  dropdownShouldOpen() {
    return true;
  }

  handleDelete(id) {
    if (this.isDeleteItem) {
      const selected = this.list.filter((item) => item.id !== id);
      this.$emit("handleDelete", selected);
    } else {
      const selected = this.selected.filter((item) => item.id !== id);
      this.$emit("change", selected);
    }
  }

  handleOption(element) {
    let selected = this.selected.map((item) => item);
    const listItem = this.selected.find((item) => item.id === element.id);
    if (this.isMultiple) {
      if (listItem) {
        selected = this.selected.filter((item) => item.id !== element.id);
      } else {
        if (this.limit !== -1) {
          if (+selected.length > this.limit - 1) {
            EventBus.$emit("showNotification", {
              type: "warning",
              timeout: 5000,
              label: `Максимальное количество ${this.limit}`
            });
          } else {
            selected.push(element);
          }
        } else {
          selected.push(element);
        }
      }
    } else {
      selected = [element];
    }
    this.$emit("change", selected);
  }

  selectAll() {
    let selected = this.list;
    this.$emit("change", selected);
  }

  deleteAll() {
    let selected = [];
    this.$emit("change", selected);
  }
}
