<template>
  <router-link
    :to="{ name: 'IspProblem', params: { id: problem.id } }"
    target="_blank"
    class="found-problem"
  >
    <div class="found-problem__header">
      <div class="found-problem__title">
        <p>{{ problem.group.alias }}-{{ problem.id }}</p>
      </div>
      <div class="found-problem__status">
        <StatusProblem :status="problem.status" />
      </div>
    </div>
    <div class="found-problem__body">
      <p>
        {{ problem.name }}
      </p>
    </div>
  </router-link>
</template>

<script>
import StatusProblem from "@/modules/UiKit/components/statuses/StatusProblem";
export default {
  name: "FoundProblem",
  mixins: [],
  props: {
    problem: {
      type: Object,
      default: null
    }
  },
  components: { StatusProblem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.found-problem {
  display: block;
  color: $text-default;
  padding: 24px;
  border-bottom: 1px solid #eae8f0;
  cursor: pointer;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 16px;
    color: #0a1417;
    margin-right: 32px;
  }
  &__status {
    /deep/ {
      .status__content {
        padding-top: 2px;
      }
    }
  }
  &__body {
    font-weight: 500;
    word-break: break-word;
  }
}
</style>
