<template>
  <div
    class="plan-table__row row"
    :class="{ row_completed: isCompleted, row_overdue: isOverdue, row_disabled: disabled }"
  >
    <div class="row__number cell">
      <i v-if="isEditable" class="icon-delete" @click="deleteStep" />
      <span>{{ step.relative_number }}</span>
    </div>
    <div class="row__action cell" v-if="!isInitial && !isFinal">
      <Input
        v-model.trim="title"
        :placeholder="'Введите название и нажмите Enter'"
        :maxlength="64"
        @keydownEnter="handleKeydownEnterTitle"
        v-click-outside="handleClickOutsideTitle"
      />
    </div>
    <div class="row__action_red cell" v-else>
      <div>{{ step.name }}</div>
    </div>
    <div class="row__link cell">
      <Skeleton v-if="calculatingLoader && !isInitial && !isForCreation" />
      <StepLinks
        v-else-if="!isInitial && !isForCreation"
        :linksRelativeList="linksRelativeList"
        :stepList="stepList"
        :stepLinksList="stepLinksList"
        :plan="plan"
        :step="step"
        :disabled="disabledStepLinks"
      />
      <span v-else>-</span>
    </div>
    <div class="row__duration cell">
      <Skeleton v-if="calculatingLoader && isEditable" />
      <Input
        v-model.trim="duration"
        v-else-if="isEditable"
        :type="'number'"
        :placeholder="''"
        @keydownEnter="editStepDuration"
        v-click-outside="editStepDuration"
      />
      <p v-else>-</p>
    </div>
    <div class="row__auto cell" v-for="(item, index) in autoItems" :key="index">
      <Skeleton v-if="calculatingLoader && !isFinal && !isInitial" />
      <span v-else>{{ item }}</span>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Input from "@/modules/UiKit/components/Inputs/Input";
import NotificationObserver from "@/helpers/UserNotification";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import StepLinks from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/StepLinks/StepLinks";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
export default {
  name: "PlanTableRow",
  mixins: [],
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isInitial: {
      type: Boolean,
      default: false
    },
    isFinal: {
      type: Boolean,
      default: false
    },
    initialStep: {
      type: Object,
      default: () => {
        return [];
      }
    },
    finalStep: {
      type: Object,
      default: () => {
        return [];
      }
    },
    isForCreation: {
      type: Boolean,
      default: false
    },
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    stepLinksList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    index: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { Skeleton, StepLinks, Input },
  data() {
    return {
      title: "",
      originTitle: "",
      duration: "",
      originDuration: "",
      isCompleted: false,
      isOverdue: false
    };
  },
  mounted() {
    this.initStep();
  },
  methods: {
    initStep() {
      this.title = this.step.name;
      this.originTitle = this.step.name;
      this.duration =
        this.step.duration === undefined || this.step.duration === null
          ? ""
          : String(this.step.duration);
      this.originDuration = this.duration;
    },
    async handleKeydownEnterTitle() {
      if (!this.validateTitle()) return;
      if (this.isForCreation) {
        await this.createStep();
      } else {
        await this.editStepTitle();
      }
    },
    handleClickOutsideTitle() {
      if (this.isForCreation && this.title) {
        this.title = "";
        return;
      }
      if (!this.title?.length) {
        this.title = this.originTitle;
        return;
      }
      this.editStepTitle();
    },
    async editStepTitle() {
      if (this.originTitle === this.title) return;
      const editedStep = {
        id: this.step.id,
        name: this.title
      };
      await PlanningScheduleModule.editStepAction(editedStep);
      this.originTitle = this.title;
    },
    async createStep() {
      const newStep = {
        plan_id: this.plan.id,
        duration: 1,
        name: this.title
      };
      await PlanningScheduleModule.createStepAction(newStep);
      this.title = "";
    },
    validateTitle() {
      if (!this.title?.length) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название действия!"
        });
        return false;
      }
      if (this.title.length > 64) {
        NotificationObserver.notification({
          type: "error",
          message: "Название не может содержать более 64 символов!"
        });
        return false;
      }
      return true;
    },
    editStepDuration() {
      if (this.originDuration === this.duration) return;
      if (!this.duration.length) {
        this.duration = this.originDuration;
        return;
      }
      const editedStep = {
        id: this.step.id,
        duration: Math.ceil(Number(this.duration))
      };
      PlanningScheduleModule.editStepAction(editedStep);
      this.duration = String(Math.ceil(Number(this.duration)));
      this.originDuration = this.duration;
    },
    deleteStep() {
      PlanningScheduleModule.deleteStepAction(this.step.id);
    }
  },
  computed: {
    autoItems() {
      const autoItems = [
        this.step.date_start,
        this.step.date_end,
        this.step.late_start_date,
        this.step.reserve,
        this.step.late_end_date
      ];
      return autoItems.map((item) => {
        if (this.isInitial || this.isFinal) {
          return "-";
        } else if (item === undefined || item === null) {
          return "Auto";
        } else if (typeof item === "string") {
          return String(item).split("-").reverse().join("/");
        } else {
          return item;
        }
      });
    },
    linksRelativeList() {
      if (this.isFinal) {
        return this.stepLinksList
          .filter(
            (link) =>
              link.step_to === null && this.stepList.some((step) => link.step_from === step.id)
          )
          .map((item) => {
            return item.step_from === null
              ? this.initialStep
              : this.stepList.find((step) => step.id === item.step_from);
          });
      }
      return this.stepLinksList
        .filter(
          (link) =>
            link.step_to === this.step.id &&
            link.step_from !== null &&
            this.stepList.some((step) => link.step_from === step.id)
        )
        .map((item) => {
          return this.stepList.find((step) => step.id === item.step_from);
        });
    },
    calculatingLoader() {
      return PlanningScheduleModule.calculatingLoader;
    },
    isEditable() {
      return !this.isForCreation && !this.isInitial && !this.isFinal;
    },
    disabledStepLinks() {
      return this.index === 0 || this.isFinal;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.row {
  height: fit-content;
  display: flex;
  position: relative;
  &:hover {
    .row__number {
      i {
        display: block;
        color: $icon-subdued;
        font-size: 20px;
        transition: 0.2s all ease;
        cursor: pointer;
        position: absolute;
        background: #ffffff;
        &:hover {
          color: $link-default;
        }
      }
    }
  }
  .cell {
    box-shadow: -1px -1px 0px rgba(166, 166, 166, 0.1), inset -1px -1px 0px rgba(166, 166, 166, 0.1);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text-default;
    padding: 10px;
    /deep/ {
      .input {
        width: 100%;
        border-radius: 2px;
        input {
          border-radius: 2px;
        }
      }
    }
  }
  &__number {
    i {
      display: none;
    }
  }
  &__auto {
    background: #edf4fe;
    opacity: 0.5;
  }
  &__action {
    &_red {
      div {
        height: 100%;
        width: 100%;
        border-radius: 2px;
        border: 2px solid #f2f2f7;
        background: rgba(255, 100, 156, 0.2);
        padding: 16px;
      }
    }
    /deep/ {
      input {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &__duration {
    /deep/ {
      /* Убрать стрелки для input типа number в Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
  &_completed {
    .row__action {
      &:before {
        content: "";
        height: 40px;
        width: 3px;
        margin-right: -1px;
        background-color: #33a021 !important;
        border-radius: 2px;
      }
    }
  }
  &_overdue {
    .row__action {
      /deep/ {
        input {
          color: #fb646d;
        }
      }
      &:before {
        content: "";
        height: 40px;
        width: 3px;
        margin-right: -1px;
        background-color: #f48e78;
        border-radius: 2px;
      }
    }
  }
  &_disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}
</style>
