import moment, { Moment } from "moment";
import { gridType } from "@/aopV2/modules/Calendar/store/calendarModule";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";

export interface IDay {
  date: string;
  weekDay: number;
  isDisabled: boolean;
  events?: Array<any>;
}

export interface ICalendarGenerate {
  days: days;
  start: string;
  stop: string;
}

type days = Array<IDay | Array<IDay>>;

const checkType = (data: {
  type: gridType;
  currentDate?: string;
  startDay?: Moment;
  stopDay?: Moment;
}) => {
  let startDay;
  let stopDay;
  moment.locale("ru");
  if (data.type === gridType.week) {
    startDay = moment(data.currentDate).clone().startOf("week");
    stopDay = moment(data.currentDate).clone().endOf("week");
  } else if (data.type === gridType.month) {
    startDay = moment(data.currentDate).clone().startOf("month").startOf("week");
    stopDay = moment(data.currentDate).clone().endOf("month").endOf("week");
  } else if (data.type === gridType.day) {
    startDay = moment(data.startDay).startOf("week");
    stopDay = moment(data.stopDay).endOf("week");
  } else {
    console.error("Неверный тип. Тип должен быть month или week");
    throw new Error("Неверный тип. Тип должен быть month или week");
  }
  return { startDay, stopDay };
};
const calendarGenerate = (data: {
  type: gridType;
  currentDate?: string;
  startDay?: Moment;
  stopDay?: Moment;
  isWeek: boolean;
}): ICalendarGenerate => {
  const { startDay, stopDay } = checkType(data);
  const days: days = [];
  const date = startDay.clone().subtract(1, "day");
  while (date.isBefore(stopDay, "day")) {
    const array: Array<IDay> = Array(7).fill(0);
    days.push(
      data.isWeek
        ? array.map(() => ({
            date: date.add(1, "day").clone().format("YYYY-MM-DD"),
            weekDay: moment(date).weekday(),
            isDisabled: data.currentDate
              ? moment(date.clone().format("YYYY-MM-DD")).month() !==
                moment(data.currentDate).month()
              : false
          }))
        : {
            date: date.add(1, "day").clone().format("YYYY-MM-DD"),
            weekDay: moment(date).weekday(),
            events: [],
            isDisabled: data.currentDate
              ? moment(date.clone().format("YYYY-MM-DD")).month() !==
                moment(data.currentDate).month()
              : false
          }
    );
  }
  return { days, start: dateFormat(startDay), stop: dateFormat(stopDay) };
};

export default calendarGenerate;
