<template>
  <div :class="['spinner', { spinner_small: small }]">
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.542 1.48703C11.1791 2.86211 7.36835 5.59244 4.6633 9.28136C1.95825 12.9703 0.499853 17.4256 0.5 22C0.5 33.874 10.126 43.5 22 43.5C31.847 43.5 40.364 36.825 42.809 27.428C42.9092 27.0429 42.8524 26.6338 42.651 26.2906C42.4496 25.9474 42.1201 25.6983 41.735 25.598C41.3499 25.4978 40.9408 25.5546 40.5976 25.756C40.2544 25.9574 40.0052 26.2869 39.905 26.672C37.803 34.755 30.473 40.5 22 40.5C11.783 40.5 3.5 32.217 3.5 22C3.5 13.863 8.8 6.75303 16.442 4.35003C16.8217 4.23069 17.1384 3.96541 17.3224 3.61256C17.5065 3.25971 17.5428 2.84819 17.4235 2.46853C17.3042 2.08888 17.0389 1.77218 16.686 1.58811C16.3332 1.40405 15.9217 1.36769 15.542 1.48703Z"
        fill="url(#paint0_linear_547_25678)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_547_25678"
          x1="30.6527"
          y1="56.8839"
          x2="1.34165"
          y2="-11.5272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4942F8" />
          <stop offset="0.625" stop-color="#6B6DFC" />
          <stop offset="1" stop-color="#7F87FF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  mixins: [],
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.spinner {
  width: 44px;
  height: 44px;
  transition: all 0.6s ease;
  animation: rotate 1.5s linear infinite;

  &_small {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
