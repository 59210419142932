<template>
  <aside class="messenger-tabs">
    <ChatSettings />
    <div class="messenger-tabs__wrap">
      <SearchInput />
      <SearchResult v-show="!showSetting && searchMode" />
      <ChatList v-show="showChatList" />
    </div>
  </aside>
</template>

<script>
import ChatList from "@/modules/Messenger/components/ChatList";
import SearchInput from "@/modules/Messenger/components/Search/SearchInput";
import SearchResult from "@/modules/Messenger/components/Search/SearchResult";
import { mapState } from "vuex";
import ChatSettings from "@/modules/Messenger/components/ChatSettings";
export default {
  name: "MessengerTabs",
  mixins: [],
  props: {},
  components: { ChatSettings, SearchResult, SearchInput, ChatList },
  data() {
    return {
      showSettingMenu: false
    };
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", [
      "userListResult",
      "showSetting",
      "searchMessageMode",
      "personChat",
      "searchMessages",
      "searchMode"
    ]),

    showChatList() {
      return !this.searchMessageMode && !this.userListResult.length && !this.searchMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-enter-active {
  transition: all 0.3s ease-in;
}
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.messenger-tabs {
  position: relative;
  background-color: #fff;
  flex-basis: 24%;
  height: 100%;
  flex-shrink: 0;
  min-width: 320px;
  overflow: hidden;
  border-right: 1px solid #eae8f0;
  @media screen and (max-width: $md) {
    flex-basis: 100%;
    min-width: auto;
  }
  &__wrap {
    height: 100%;
    overflow: auto;
    max-height: 100vh;
    position: relative;
  }
}
</style>
