<template>
  <ul class="list-dynamic" ref="vsl">
    <li
      class="list-item"
      v-for="item in messageList"
      :key="item.id"
      @click="handlePickMessage(item)"
    >
      <Message :source="item" :isPin="isPin" />
    </li>
  </ul>
</template>

<script>
import Message from "@/modules/Messenger/components/Message/Message";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "MessageList",
  mixins: [],
  props: {
    isPin: {
      type: Boolean,
      default: false
    },
    isThread: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "list-dynamic"
    },
    messageList: {
      type: Array,
      default: () => []
    }
  },
  components: { Message },
  data() {
    return { container: null, timerId: null };
  },
  mounted() {
    this.$nextTick(() => {
      this.container = document.querySelector(`#${this.id}`);
      if (!this.isPin) this.setVirtualListToBottom();
    });
  },

  methods: {
    ...mapActions("MessengerModule", ["pickMessage"]),
    handlePickMessage(item) {
      if (this.pickMode && item.type !== "system") this.pickMessage(item);
    },

    setVirtualListToBottom() {
      this.container.scrollTo(0, this.container.scrollHeight);
    },
    scrollToSendMessage(id, duration) {
      const el = document.querySelector(`#message_${id}`);
      const offset = el.offsetHeight + 24 - this.container.offsetHeight;
      this.$scrollTo(el, duration, {
        container: this.container,
        lazy: false,
        offset: offset,
        force: false,
        cancelable: false,
        x: false,
        y: true
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "messages",
      "showChat",
      "showPin",
      "pinned_messages",
      "files",
      "showFileModal",
      "pickMode",
      "threadMainMessage",
      "lastThreadMessage",
      "lastSelfMessage",
      "currentChat"
    ]),
    ...mapGetters("MessengerModule", ["getOldMessage"])
  },
  validations: {},
  watch: {
    currentChat: function () {
      this.$nextTick(() => {
        if (!this.isPin) this.setVirtualListToBottom();
      });
    },
    threadMainMessage: function () {
      this.$nextTick(() => {
        if (this.isThread) this.setVirtualListToBottom();
      });
    },
    lastSelfMessage: function () {
      if (!this.isPin && !this.isThread) {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToSendMessage(this.lastSelfMessage.id, 300);
          });
        }, 500);
      }
    },
    lastThreadMessage: function () {
      if (!this.isPin && this.isThread) {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToSendMessage(this.lastThreadMessage.id, 300);
          });
        }, 500);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.list-dynamic {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  flex-shrink: 1;
  width: 100%;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .list-item {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    margin-bottom: 24px;
    &:first-child {
      padding-top: 16px;
      margin-top: auto;
    }
  }
  .active {
    transition: background-color 0.5s ease;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
</style>
