<template>
  <div class="timeline-vacation__header">
    <div class="timeline-vacation__control-panel control-panel">
      <div class="control-panel__search" v-click-outside="hideSearch">
        <i class="icon-search" @click="toggleSearch"></i>
        <Widget
          v-show="showSearсh"
          type="string"
          placeholder="Поиск..."
          maxlength="100"
          :unique="'TimeLineSearchVacation'"
        />
      </div>
      <div class="control-panel__container">
        <div class="control-panel__calendar">
          <Widget
            type="Button"
            v-bind="{
              disabled: false,
              label: 'Сегодня',
              outlined: false,
              unique: 'todayTimeLine'
            }"
          />
          <p class="control-panel__date">
            <MonthPickerTable
              @changeMonthYear="changeMonthYear"
              maxDate="2023-12"
              minDate="2021-01"
              dateMin="2021-01"
              :disabled="false"
            />
          </p>
        </div>
        <div class="control-panel__other">
          <button class="control-panel__create" type="button" @click="openCreateVacationModal">
            <i class="icon-plus"></i>
          </button>
          <router-link
            class="control-panel__button"
            type="button"
            v-if="isAdmin"
            :to="{ name: 'VacationOrderPersonnelOfficer' }"
          >
            <i class="icon-flag"></i><span>Запросы</span>
          </router-link>
          <button
            class="control-panel__button control-panel__button_report"
            type="button"
            v-if="isAdmin"
            @click="exportVacationSchedule"
          >
            <i class="icon-download-secondary" v-if="!loaderReport"></i
            ><span v-if="!loaderReport">Выгрузить</span>
            <Loader v-if="loaderReport" />
          </button>
          <div class="control-panel__wrap" v-click-outside="hideEditingInhibitDateModal">
            <button
              class="control-panel__button"
              type="button"
              v-if="isAdmin"
              @click="isShowEditingInhibitDateModal = true"
            >
              <i class="icon-cross-in-round"></i><span>Остановить редактирование</span>
            </button>
            <EditingInhibitDateModal
              :serviceWiths="serviceWiths"
              @hideModalEditingInhibitDate="hideEditingInhibitDateModal"
              v-if="isShowEditingInhibitDateModal"
            />
          </div>
          <div class="control-panel__wrap" v-click-outside="hideMyVacation">
            <button
              class="control-panel__button"
              type="button"
              @click="isMyVacation = !isMyVacation"
            >
              <i class="icon-calendar-secondary"></i><span>Мои отпуска</span>
            </button>
            <AbsenceCard v-if="isMyVacation" :absenceList="absenceList" :type="'Отпуск'" />
          </div>
          <SortTable :valueSort="valueSort" :listSorts="listSorts" :unique="'TimeLine'" />
          <div class="control-panel__wrap" v-click-outside="hideModalSwitch">
            <button
              class="control-panel__button control-panel__button_last"
              type="button"
              @click="isShowSwitch = !isShowSwitch"
            >
              <i class="icon-eye"></i><span>Показывать</span>
            </button>
            <div class="control-panel__switch switch" v-if="isShowSwitch">
              <ul class="switch__list">
                <li class="switch__item">
                  <span>Выходные</span>
                  <Widget
                    type="Switch"
                    :unique="'switch-view-weekends'"
                    :editValue="isShowWeekends"
                  />
                </li>
                <li class="switch__item">
                  <span>Дни недели</span>
                  <Widget
                    type="Switch"
                    :unique="'switch-view-week-numbers'"
                    :editValue="isShowWeekNumbers"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Widgets/Loader2.0/Loader";
import Widget from "@/components/Widgets/Widget";
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import SortTable from "@/components/GraphVacation/TimeLine/SortTable/SortTable";
import AbsenceCard from "@/components/GraphVacation/Absence/AbsenceCard";
import ClickOutside from "vue-click-outside";
import EditingInhibitDateModal from "@/components/GraphVacation/TimeLine/EditingInhibitDateModal";
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
import centrifuge from "@/centrifuge";

export default {
  name: "TimeLineWidgets",
  mixins: [],
  props: [
    "valueSort",
    "absenceList",
    "isAdmin",
    "isShowWeekNumbers",
    "isShowWeekends",
    "serviceWiths"
  ],
  components: { EditingInhibitDateModal, SortTable, Loader, Widget, MonthPickerTable, AbsenceCard },
  data() {
    return {
      windowWidth: 0,
      isMyVacation: false,
      isShowSwitch: false,
      isShowEditingInhibitDateModal: false,
      showSearсh: true,
      loaderReport: false,
      filterSelected: [],
      listSorts: [
        {
          name: "Сотрудник",
          value: "id"
        },
        {
          name: "Роль",
          value: "roles"
        },
        {
          name: "Отдел",
          value: "departments"
        }
      ]
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  beforeDestroy() {},
  methods: {
    resizeHandler() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.showSearсh = false;
      } else {
        this.showSearсh = true;
      }
    },
    toggleSearch() {
      if (this.windowWidth <= 768) {
        this.showSearсh = !this.showSearсh;
      }
    },
    changeMonthYear(date) {
      this.$root.$emit("setVacationYear", date);
    },
    exportVacationSchedule() {
      this.loaderReport = true;
      httpClient({
        method: "GET",
        url: `/VacationSchedule/export`,
        params: {
          id: this.serviceWiths.vacationSchedule.id
        }
      })
        .then((response) => {
          const channel = response.data.data.channel;
          if (channel) {
            const subs = centrifuge.subscribe(channel, (resp) => {
              let url = resp.data.message;
              window.open(url, "_blank");
              this.loaderReport = false;
              subs.unsubscribe();
            });
          }
        })
        .catch((error) => {
          this.errorAlert(error);
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Во время выгрузки возникла ошибка, попробуйте позже!`
          });
          this.loaderReport = false;
        });
    },
    showMyVacation() {
      this.isMyVacation = true;
    },
    hideMyVacation() {
      this.isMyVacation = false;
    },
    hideModalSwitch() {
      this.isShowSwitch = false;
    },
    hideEditingInhibitDateModal() {
      this.isShowEditingInhibitDateModal = false;
    },
    openCreateVacationModal() {
      if (this.isAdmin) {
        this.$root.$emit("openConfirmationModalPersonnelOfficer");
      } else {
        this.$root.$emit("openConformationModal", {
          editMode: false,
          createMode: true
        });
      }
    },
    hideSearch() {
      if (this.windowWidth <= 768 && this.showSearсh) {
        this.showSearсh = false;
      }
    }
  },

  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  position: relative;
  height: 5px;
  overflow-x: auto;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 8px;

  background: $bg-gray-400;
}
::-webkit-scrollbar-track {
  background: $bg-surface-light;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.timeline-vacation__header {
  height: 97px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: $bg-gray-75;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid $border-subdued;

  &_empty {
    &:first-child {
      max-width: 260px;
      height: 54px;
      border-bottom: 1px solid $border-subdued;
      border-right: 1px solid $border-subdued;

      @media (max-width: $sm) {
        max-width: 160px;
      }
    }

    &:nth-child(2) {
      height: 42px;
      max-width: 260px;

      @media (max-width: $sm) {
        max-width: 160px;
      }
    }
  }
}

.control-panel {
  display: flex;
  width: 100%;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  /deep/ {
    .button-widget {
      button {
        padding: 5px 6px;
        border: 0;
        background-color: transparent;
        font-size: 14px;
        line-height: 20px;
        color: $text-default;
        width: auto;
        height: auto;
        transition: 0.3s;
        margin: 0 24px 0 0;
        border-radius: 4px;
        z-index: -1;

        @media (max-width: 768px) {
          margin-left: 16px;
          margin-right: 8px;
        }

        span {
          margin: 0;
        }

        &:hover {
          background-color: $action-secondary-hovered;
        }

        &:active {
          background-color: $action-secondary-pressed;
        }
      }
    }

    .select-bg {
      margin-right: 24px;
    }
  }

  &__container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $lg) {
      padding: 15px;
      flex-wrap: wrap;
      //justify-content: space-between;
      justify-content: center;
    }
    @media (max-width: $md) {
      padding-top: 0;
    }
  }

  &__calendar {
    display: flex;
    align-items: center;
    @media (max-width: $lg) {
      width: 100%;
      justify-content: center;
      margin-bottom: 8px;
    }
    //@media (max-width: $sm) {
    //  margin-bottom: 16px;
    //}
  }
  /deep/ &__other {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .sort-table {
      width: unset;
      &__label {
        @media (max-width: $xs) {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  &__search {
    position: relative;
    max-width: 260px;
    width: 100%;
    padding: 30px 24px 20px 24px;
    border-right: 1px solid #f2f2f7;

    @media (max-width: $md) {
      max-width: 40px;
      border-right: none;
      padding: 0;
    }

    /deep/ {
      .form-group__input {
        width: 100% !important;
        max-width: 212px;
        height: 40px;
        color: $text-subdued;
        outline: none;
        flex-shrink: 0;
        padding: 10px 10px 10px 32px;
        background: $action-secondary;
        border-radius: 40px;
        box-sizing: border-box;
        border: 1px solid transparent;
        outline: none;

        @media (max-width: $md) {
          position: absolute;
          z-index: 20;
          max-width: 80vw !important;
          width: 600px !important;
          border: 1px solid $border-subdued;
          top: -5px;
        }

        @media (max-width: $sm) {
          position: absolute;
          z-index: 20;
          max-width: 80vw !important;
          //width: 285px !important;
          border: 1px solid $border-subdued;
          top: -5px;
        }

        &:hover,
        &:focus {
          border: 1px solid $border-subdued;
          box-shadow: none;
        }

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-subdued;
        }
      }
    }
  }

  .icon-search {
    position: absolute;
    top: 40px;
    left: 33px;
    color: $icon-subdued;
    font-size: 20px;
    z-index: 21;

    @media (max-width: $md) {
      top: 5px;
      left: 10px;
      cursor: pointer;
    }
  }

  &__wrap {
    position: relative;

    @media (max-width: $sm) {
      position: inherit;
    }
  }

  &__create {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: $action-primary;
    outline: none;

    @media (max-width: $lg) {
      margin-right: 0;
      width: 24px;
      height: 24px;
      margin-left: 0;
    }
    @media (max-width: $xs) {
      margin-right: 10px;
    }
    &:hover {
      background-color: $action-primary-hovered;
    }

    .icon-plus {
      color: $action-secondary;
      font-size: 14px;
    }
  }

  &__button {
    padding: 5px 6px;
    border: 0;
    height: 30px;
    min-width: max-content;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    margin-right: 16px;
    border-radius: 4px;
    outline: none;

    @media (max-width: $md) {
      padding: 0 6px;
      height: 16px;
    }

    @media (max-width: 767px) {
      width: max-content;
    }
    @media (max-width: $xs) {
      margin-right: 10px;
      padding: 0;
    }

    &:hover {
      background-color: $action-secondary-hovered;
    }

    &:active,
    &:focus {
      background-color: $action-secondary-pressed;
    }

    /deep/ {
      .loader {
        width: 16px;
        height: 16px;
        border: 2px solid #d3d2e0;
        border-right: 2px solid transparent;
      }
    }

    i {
      color: $icon-subdued;
      font-size: 14px;
      margin-right: 8px;
      @media (max-width: $xxl) {
        margin-right: 0;
      }
    }

    span {
      @media (max-width: $xxl) {
        display: none;
      }
    }

    &_last {
      margin-right: 0;
    }

    &_report {
      width: 103px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $xxl) {
        width: 26px;
      }
    }
  }

  /deep/ {
    .sort-table__value__list {
      @media (max-width: $sm) {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        max-width: 576px;
        width: 100%;
        border-radius: 14px 14px 0 0;
        z-index: 30;
      }
    }

    .absence-card {
      @media (max-width: $sm) {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        max-width: 576px;
        width: 100%;
        border-radius: 14px 14px 0 0;
        z-index: 30;
        &__date {
          width: unset;
        }
      }
      @media (max-height: $sm) {
        height: 245px;
        min-height: unset;
        padding-bottom: 5px;
        &__item_empty {
          padding: 40px 0;
        }
        &__list {
          overflow-y: scroll;
          height: 135px;
          margin-bottom: 5px;
          padding: 10px 0;
        }
      }
    }

    .absence-card__modal-list {
      top: unset;
      bottom: 0;
    }
  }

  &__switch {
    position: absolute;
    //top: 30px;
    z-index: 10;
    right: 0;
  }
}

.switch {
  display: flex;
  flex-direction: column;
  width: max-content;
  background: $color-white;
  padding: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  /deep/ {
    label {
      margin: 0;
    }
    .switch-widget__indicator {
      width: 20px;
      height: 10px;
      background-color: $icon-subdued;

      &::before {
        top: 2px;
        width: 6px;
        height: 6px;
        background-color: $action-secondary;
      }
    }

    .switch-widget__input:checked + .switch-widget__indicator {
      background-color: $action-primary;

      &:before {
        top: 2px;
        left: 10px;
        width: 6px;
        height: 6px;
        background-color: $action-secondary;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    height: 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;

    span {
      margin-right: 8px;
    }

    &:hover {
      background: #f1effa;
      border-radius: 4px;
    }
  }
}
</style>
