<template>
  <section class="my-dashboard">
    <div class="my-dashboard__head-menu">
      <button class="my-dashboard__menu" type="button" @click="openSidebar">
        <i class="icon-menu"></i>
      </button>
      <h2 class="my-dashboard__title">Приоритетные задачи</h2>
    </div>
    <div class="my-dashboard__container" ref="taskContainer">
      <MyDashboardHeader />
      <div class="my-dashboard__lists-bg">
        <MyDashboardList
          :title="'Горящие задачи'"
          :tasks="getHotTask"
          :totalCount="hotCount"
          :page="hotPage"
          @getDashboardInfo="getHotList"
        />
        <MyDashboardList
          :title="'Просроченные'"
          :tasks="getOverdueTask"
          :totalCount="overdueCount"
          :page="overduePage"
          @getDashboardInfo="getOverdueList"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MyDashboardHeader from "@/modules/TaskManager/components/MyDashboard/MyDashboardHeader";
import MyDashboardList from "@/modules/TaskManager/components/MyDashboard/MyDashboardList";
import { mapMutations } from "vuex";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import moment from "moment";
import { EventBus } from "@/main";

export default {
  name: "MyDashboard",
  mixins: [],
  props: {},
  components: { MyDashboardList, MyDashboardHeader },
  data() {
    return {};
  },
  beforeDestroy() {
    EventBus.$off("updateData");
  },
  mounted() {
    TaskModule[TaskMutationTypes.RESET_TASK_LIST]();
    TaskModule[TaskMutationTypes.SET_HOT_PAGE](1);
    TaskModule[TaskMutationTypes.SET_OVERDUE_PAGE](1);
    this.getTaskListDashboard();
    EventBus.$on("updateData", () => {
      TaskModule[TaskMutationTypes.RESET_TASK_LIST]();
      TaskModule[TaskMutationTypes.SET_HOT_PAGE](1);
      TaskModule[TaskMutationTypes.SET_OVERDUE_PAGE](1);
      this.getTaskListDashboard();
    });
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    getTaskListDashboard() {
      this.getHotList();
      this.getOverdueList();
    },
    getHotList() {
      TaskModule.getHotTaskAction(this.setHotParams);
    },
    getOverdueList() {
      TaskModule.getOverdueTaskAction(this.setOverdueParams);
    },
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    }
  },
  computed: {
    getHotTask() {
      return TaskModule.hotTaskList;
    },
    getOverdueTask() {
      return TaskModule.overdueTaskList;
    },
    hotCount() {
      return TaskModule.hotCount;
    },
    hotPage() {
      return TaskModule.hotPage;
    },
    overdueCount() {
      return TaskModule.overdueCount;
    },
    overduePage() {
      return TaskModule.overduePage;
    },
    setHotParams() {
      return {
        page: this.hotPage,
        per_page: 10
      };
    },
    setOverdueParams() {
      return {
        page: this.overduePage,
        per_page: 10
      };
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.my-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: no-repeat url("~@/modules/TaskManager/assets/DashboardBg.svg");
  background-size: cover;

  &__head-menu {
    width: 100%;
    padding: 16px 24px;
    background: $surface-default;
    border-bottom: 1px solid #eae8f0;
    @media (max-width: $xxl) {
      display: flex;
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: $text-default;
  }

  &__menu {
    display: none;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;

    @media (max-width: $xxl) {
      display: flex;
      margin-right: 10px;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__container {
    position: relative;
    overflow-y: scroll;
  }

  &__lists-bg {
    position: relative;
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 24px;

    @media (max-width: $xxl) {
      padding: 8px 24px;
    }
  }
}
</style>
