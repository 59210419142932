<template>
  <div class="stage-task">
    <div class="stage-task__header">
      <p class="stage-task__deadline"><span>Срок окончания:</span>{{ setDate }}</p>
      <div class="stage-task__attachment">
        <p class="stage-task__attachment-item" v-if="!task.parent_task_id">
          <i class="icon-list"></i>
          <span class="stage-task__count">{{ task.childs_count && task.childs_count }}</span>
          <span class="stage-task__tooltip">Подзадачи</span>
        </p>
        <p class="stage-task__attachment-item">
          <i class="icon-comment-empty"></i>
          <span class="stage-task__count">{{ task.count_comments && task.count_comments }}</span>
          <span class="stage-task__tooltip">Комментариии</span>
        </p>
        <p class="stage-task__attachment-item">
          <i class="icon-paper-clip-secondary"></i>
          <span class="stage-task__count">{{ task.count_files && task.count_files }}</span>
          <span class="stage-task__tooltip">Файлы</span>
        </p>
      </div>
    </div>
    <div class="stage-task__body">
      <router-link
        :to="{
          name: 'Task',
          params: { id: task.task_id },
          query: { alias: task.alias }
        }"
      >
        <p @click="closeCurtain">{{ task.alias }}-{{ task.task_id }} {{ task.name }}</p>
      </router-link>
    </div>
    <p class="stage-task__project" @click="closeStage">
      <router-link :to="{ name: 'Project', params: { id: task.project_id && task.project_id } }">
        {{ project.name }}
      </router-link>
    </p>
  </div>
</template>

<script>
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "ProjectTask",
  mixins: [formatDateAndTime],
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
    project: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      isComplete: false
    };
  },
  mounted() {},
  methods: {
    closeStage() {
      StageModule.toggleShowEditModal(false);
    },
    closeCurtain() {
      StageModule.toggleShowEditModal(false);
    }
  },
  computed: {
    setDate() {
      return this.task.date_to ? this.setDateFormatFull(this.task.date_to) : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.stage-task {
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #eae8f0;

  @media (max-width: $sm) {
    padding: 16px 8px;
  }

  &__header {
    display: flex;
    align-items: center;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    i {
      color: $icon-subdued;
      font-size: 20px;
    }
  }

  /deep/ {
    .action-list {
      @media (max-width: $sm) {
        margin: 0;
      }
    }

    .action-list__setting-button {
      background: transparent;

      &:hover {
        background: #f2f7fe;

        i {
          color: $icon-subdued;
        }
      }
    }
  }

  &__deadline {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    cursor: default;

    @media (max-width: $sm) {
      order: 3;
      width: 100%;
      margin: 16px 0 0;
    }

    span {
      color: $text-subdued;
      margin-right: 8px;
    }
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: 100%;
    right: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: $surface-default;
    background: $text-default;
    border-radius: 2px;
  }

  &__attachment {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: $sm) {
      order: 1;
    }
  }

  &__attachment-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 16px;

    i {
      color: $icon-subdued;
      margin-right: 8px;
      font-size: 18px;
    }

    &:hover .stage-task__tooltip {
      opacity: 1;
    }
  }

  &__count {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $text-default;
    cursor: default;
  }

  &__body {
    padding: 24px 0 16px;

    p {
      cursor: pointer;
      color: $text-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
      @include textEllipsis(2);

      @media (max-width: $sm) {
        font-size: 14px;
        line-height: 20px;
      }
      &:hover {
        color: $link-hovered;
      }
    }
  }

  &__project {
    a {
      color: $link-default;
      font-size: 13px;
      font-weight: 400;
      cursor: pointer;
      @include textEllipsis(2);

      &:hover {
        color: $link-hovered;
      }

      &:active {
        color: $link-pressed;
      }
    }
  }
}
</style>
