<template>
  <div
    class="user-group"
    v-click-outside="hideEditMode"
    :style="{ width: editMode ? '100%' : 'fit-content' }"
  >
    <div class="user-group__label">
      <Label :label="label" />
      <i class="icon-edit" @click="enterEditMode" v-show="isEditable && !editMode"></i>
      <span class="user-group__icons" v-if="editMode">
        <i class="icon-done" @click="hideEditMode"></i>
        <i class="icon-cross" @click="editMode = false"></i>
      </span>
    </div>
    <div class="user-group__content" v-if="!editMode">
      <div class="user-group__content_row" v-if="row">
        <div class="user-group__user_row" v-for="item in usersToShow" :key="item.id">
          <router-link
            :to="{ name: 'UserInfo', params: { user_id: item.id } }"
            :target="'_blank'"
            :key="item.name"
          >
            <img
              v-if="item.avatar"
              :src="returnSrcFile(item.avatar)"
              alt="avatar"
              :title="item.full_name"
            />
          </router-link>
        </div>
        <div class="user-group__count" v-if="users.length > 3" @click="handleClickCount">
          +{{ users.length - 3 }}
        </div>
      </div>
      <div class="user-group__content_column" v-else>
        <div class="user-group__user_column" v-for="user in users" :key="user.id">
          <User
            :avatar="user.avatar"
            :name="user.full_name"
            :id="user.id"
            :showInfo="true"
            :size="'44px'"
            :show-tooltip="false"
            class="editable-user__user"
          />
        </div>
      </div>
    </div>

    <div class="user-group__select" v-show="editMode">
      <Select :placeholder="placeholder" :items="allUsers" v-model="selected" />
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import User from "@/components/Widgets/User/User";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Select from "@/modules/UiKit/components/Select/Select";
import ClickOutside from "vue-click-outside";

export default {
  name: "UserGroup",
  mixins: [],
  props: {
    label: {
      type: String,
      default: "ИСПОЛНИТЕЛИ"
    },
    allUsers: {
      type: Array,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => {
        return [];
      }
    },
    row: {
      type: Boolean,
      default: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "Выберите сотрудников"
    }
  },
  components: { Label, User, Select },
  data() {
    return {
      editMode: false,
      selected: []
    };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleClickCount() {
      this.$emit("clickCount");
    },
    enterEditMode() {
      this.editMode = true;
      if (this.users.length) this.selected = JSON.parse(JSON.stringify(this.users));
    },
    hideEditMode() {
      if (!this.editMode) return;
      this.editMode = false;
      this.$emit("updated", this.selected);
    }
  },
  computed: {
    usersToShow() {
      return this.users.slice(0, 3);
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.user-group {
  display: flex;
  flex-direction: column;
  user-select: none;
  &__label {
    margin-bottom: 16px;
    display: flex;
    &:hover i {
      opacity: 1;
    }
    i {
      margin-left: 8px;
      font-size: 18px;
      color: $icon-subdued;
      transition: 0.3s all ease;
      cursor: pointer;
      opacity: 0;
      &:hover {
        color: $link-default;
      }
    }
    /deep/ {
      .label {
        margin: 0;
        label {
          font-size: 12px;
          font-weight: 700;
          color: $text-default;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  &__content {
    &_row {
      display: flex;
      align-items: center;
    }
  }
  &__user {
    &_row {
      display: flex;
      align-items: center;
      margin-left: -20px;
      &:first-child {
        margin-left: 0;
      }
    }
    &_column {
      margin-bottom: 24px;
    }
  }
  &__count {
    margin-left: -20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #eae8f0;
    border: 2px solid #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #0a1417;
  }
  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fafafe;
    margin-right: 8px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343748;
    word-break: break-word;
    max-width: 100px;
  }
}
</style>
