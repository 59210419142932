<template>
  <ul class="file-list">
    <FileItem
      v-for="(file, index) in arrayList"
      :key="file.id"
      :file="file"
      @openImage="openImage"
      @deleteFile="deleteFile(file, index)"
      :isEdit="isEdit"
      :task="task"
    />
    <transition name="fade">
      <FullScreenImage :imageInfo="imageInfo" v-if="showFullScreenImage" @closeImage="closeImage" />
    </transition>
  </ul>
</template>

<script>
import FileItem from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileItem";
import FullScreenImage from "@/modules/TaskManager/components/Modal/TaskFileModal/FullScreenImage";
import taskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule, {
  ProjectMutationTypes
} from "@/modules/TaskManager/Model/Project/ProjectModule";

export default {
  name: "FileList",
  mixins: [],
  props: {
    fileList: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    task: {
      type: Boolean,
      default: true
    }
  },
  components: { FullScreenImage, FileItem },
  data() {
    return {
      imageInfo: {},
      arrayList: []
    };
  },
  beforeDestroy() {},
  mounted() {
    this.sortFiles(this.fileList);
  },
  methods: {
    deleteFile(file, index) {
      this.arrayList.splice(index, 1);
      if (this.task) {
        TaskModule[TaskMutationTypes.UPDATE_CURRENT_FILES](file.id);
      } else {
        ProjectModule[ProjectMutationTypes.UPDATE_CURRENT_PROJECT_FILES](file.id);
      }
    },
    openImage(data) {
      this.imageInfo = data;
      taskManagerModuleV2.openFullScreenImage();
    },
    closeImage() {
      this.imageInfo = null;
      taskManagerModuleV2.closeFullScreenImage();
    },
    sortFiles(fileList) {
      let uniqueElements = fileList.filter((fileItem) =>
        this.arrayList.every((arrayItem) => arrayItem.id !== fileItem.id)
      );
      uniqueElements.forEach((item) => {
        if (item.file) {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = (e) => {
            this.arrayList.push({
              path: reader.result,
              name: item.name,
              id: item.id,
              size: item.size,
              ...item
            });
          };
        } else {
          this.arrayList.push(item);
        }
      });
    }
  },
  computed: {
    showFullScreenImage() {
      return taskManagerModuleV2.showFullScreenImage;
    }
  },
  watch: {
    fileList(fileList) {
      this.sortFiles(fileList);
    }
  },
  validations: {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.file-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
</style>
