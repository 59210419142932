<template>
  <li class="black-sphere__item">
    <User :avatar="currentUser.avatar" :id="currentUser.id" :showInfo="false" :size="'30px'" />
    <p class="black-sphere__tooltip black-sphere__tooltip_user">{{ currentUser.full_name }}</p>
    <div class="black-sphere__descriptions">
      <p class="black-sphere__tooltip black-sphere__tooltip_descriptions">{{ item.comment }}</p>
      <p class="black-sphere__comment" v-html="setURL(item.comment)"></p>
    </div>
    <div class="black-sphere__mark">{{ item.mark }}</div>
  </li>
</template>

<script>
import User from "@/components/Widgets/User/User";
import { domainStorage } from "@/globalVariables";
import UrlMixin from "@/helpers/UrlMixin";
export default {
  name: "BlackSphereItem",
  mixins: [UrlMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    returnerComment(comment) {
      if (this.checkURL(comment)) {
        return `<a href="${comment}">${comment}</a>`;
      } else {
        return comment;
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["UsersModule/userById"](this.item.put_user_id)[0];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 16px;

      .user:hover + .black-sphere__tooltip_user {
        top: 32px;
      }

      .black-sphere__descriptions:hover .black-sphere__tooltip_descriptions {
        display: block;
        top: 20px;
      }
    }

    &:last-child {
      .black-sphere__descriptions:hover .black-sphere__tooltip_descriptions {
        display: block;
        bottom: 20px;
      }
    }

    &:first-child {
      .user:hover + .black-sphere__tooltip_user {
        top: 32px;
      }

      .black-sphere__descriptions:hover .black-sphere__tooltip_descriptions {
        top: 20px;
        bottom: unset;
      }
    }
  }

  &__descriptions {
    position: relative;
  }

  .user:hover + .black-sphere__tooltip_user {
    display: block;
    left: 0;
    top: -32px;
    max-width: max-content;
  }

  &__tooltip {
    display: none;
    position: absolute;
    max-width: 530px;
    width: max-content;
    left: 0;
    z-index: 2;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;

    @media (max-width: $md) {
      max-width: 202px;
    }

    @media (max-width: $sm) {
      max-width: 100%;
    }
  }

  &__comment {
    max-width: 530px;
    width: 100%;
    overflow-x: hidden;
    word-break: break-word;
    font-size: 14px;
    color: #4e5155;
    white-space: pre-wrap;

    /deep/ {
      a {
        color: $link-default;
      }

      a:hover,
      a:focus {
        color: $link-hovered;
      }
    }
  }

  &__mark {
    position: relative;
    display: flex;
    align-items: center;
    width: 54px;
    height: 30px;
    z-index: 1;
    padding-left: 34px;
    margin-left: auto;
    background: $bg-surface-light;
    border-radius: 16px;
    font-size: 14px;
    color: #4e5155;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 10px;
      left: 16px;
      border-radius: 50%;
      background: $icon-default;
    }
  }
}
</style>
