






import { Component, Prop, Vue } from "vue-property-decorator";
import LabelTooltip from "@/aopV2/components/UIComponents/Tooltip/LabelTooltip.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({
  components: { LabelTooltip }
})
export default class EventTooltip extends Vue {
  get height() {
    return 16 * this.tooltip.length + 20;
  }

  get tooltip() {
    return CalendarModule2.tooltip;
  }

  get style() {
    return { top: this.y - this.height + "px", left: this.x + "px" };
  }

  get x() {
    return CalendarModule2.tooltipX;
  }

  get y() {
    return CalendarModule2.tooltipY;
  }
}
