<template>
  <div
    class="my-tasks__table"
    ref="myTaskContainer"
    :style="tasksData.length ? 'height: 100%' : 'height: max-content'"
  >
    <MyTasksTableFields
      :tableHeadList="tableHead"
      v-if="tasksData.length"
      @updateInfo="updateInfo"
    />
    <MyTasksTableBody :tasksData="tasksData" :params="params" />
    <div class="my-tasks__spinner" v-if="loader">
      <Spinner />
    </div>
    <div class="my-tasks__empty" v-if="!tasksData.length && !loader">Нет задач</div>
  </div>
</template>

<script>
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import MyTasksTableFields from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableFields";
import MyTasksTableBody from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableBody";

export default {
  name: "MyTasksTable",
  mixins: [],
  props: {
    tasksData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    params: {
      type: Object,
      default: () => {
        return [];
      }
    }
  },
  components: { MyTasksTableBody, MyTasksTableFields, Spinner },
  data() {
    return {
      tableHead: [
        {
          name: "Номер и Название задачи",
          sort: false,
          key: "name"
        },
        {
          name: "Приоритет",
          sort: true,
          key: "priority_id",
          valueSort: "priority_id",
          isAsc: true
        },
        { name: "Проект", sort: true, key: "project_id", valueSort: "project_id", isAsc: true },
        { name: "Сроки", sort: true, key: "date_to", valueSort: "date_to", isAsc: true },
        {
          name: "Этап",
          sort: true,
          key: "stage_id",
          valueSort: "stage_id",
          isAsc: true
        },
        { name: "Автор", sort: true, key: "author_id", valueSort: "author_id", isAsc: true },
        { name: "Статус", sort: true, key: "status_id", valueSort: "status_id", isAsc: true }
      ]
    };
  },
  mounted() {},
  methods: {
    updateInfo(params) {
      this.$emit("updateInfo", params);
    }
  },
  computed: {
    loader() {
      return TaskModule.loader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.my-tasks {
  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 0 24px;
    overflow: scroll;

    @media (max-width: $sm) {
      padding: 0 16px;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 150px 0 0;
    color: $text-default;
    font-size: 20px;
    line-height: 32px;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 250px 0 0;

    @media (max-width: $md) {
      padding: 150px 0 0;
    }

    @media (max-width: $sm) {
      padding: 80px 0 0;
    }
  }
}
</style>
