<template>
  <div class="wrap-docs">
    <ul
      class="wrap-docs__content"
      v-if="uploadFiles.attachment_files && uploadFiles.attachment_files.length"
    >
      <Docs
        v-for="file in uploadFiles.attachment_files"
        :key="file.label"
        :isEdit="isEdit"
        :file="file"
        @deleteFile="deleteFile"
        @replaceFile="replaceFile"
      />
    </ul>
  </div>
</template>

<script>
import Docs from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/Documents/Docs";
import { mapActions, mapState } from "vuex";

export default {
  name: "DocsComponent",
  mixins: [],
  props: {
    uploadFiles: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { Docs },
  data() {
    return {
      temporaryFiles: [],
      fileUploader: [],
      oldEditFiles: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["setFilesLoader"]),

    replaceFile(data) {
      const files = this.uploadFiles.attachment_files.filter((item) => item.id !== data.id);
      this.$emit("uploadHandler", [...files, data.file]);
    },
    deleteFile(id) {
      const files = this.uploadFiles.attachment_files.filter((item) => item.id !== id);
      const ids = files.map((item) => item.id);
      this.$emit("editFiles", {
        ids,
        files
      });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.wrap-docs {
  &__label-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  &__content {
    all: unset;
    margin-top: 32px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  /deep/ {
    label {
      cursor: pointer;
    }
  }
}
</style>
