




import { Component, Prop, Vue } from "vue-property-decorator";
import { secToTime } from "@/modules/TimeTracker/helpers/timeFormat";

@Component
export default class TimeStatisticItem extends Vue {
  @Prop({
    default: 0,
    type: Number
  })
  sec!: number;
  @Prop({
    default: "default",
    type: String
  })
  type!: string;

  get time() {
    return secToTime(this.sec);
  }

  get text() {
    switch (this.type) {
      case "time": {
        return "За месяц: ";
      }
      case "normal": {
        return "Норма: ";
      }
      case "flawed": {
        return "Недоработка: ";
      }
      case "overtime": {
        return "Переработка: ";
      }
      case "vacation": {
        return "Отпуск: ";
      }
      case "sick": {
        return "Больничный: ";
      }
      default: {
        return "За месяц: ";
      }
    }
  }

  get color() {
    switch (this.type) {
      case "time": {
        return "#5551F9";
      }
      case "normal": {
        return "#409CFF";
      }
      case "flawed": {
        return "#FB646D";
      }
      case "overtime": {
        return "#59D9AF";
      }
      case "vacation": {
        return "#DA8FFF";
      }
      case "sick": {
        return "#07B6AB";
      }
      default: {
        return "#5551F9";
      }
    }
  }
}
