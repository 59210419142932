<template>
  <router-link
    :to="{ name: 'UserInfo', params: { user_id: birthdayItem.id } }"
    :target="isGlobalMobileMode ? '_self' : '_blank'"
    class="birthday-widget__item"
  >
    <User
      :avatar="birthdayItem.avatar"
      :showTooltip="false"
      :name="birthdayItem.full_name"
      :id="birthdayItem.id"
      :info="setBirthdayDate(birthdayItem)"
      :row="true"
      :additionalInfo="setDifferentDate(birthdayItem)"
    />
  </router-link>
</template>

<script>
import { differenceInCalendarDays, format, formatDuration } from "date-fns";
import { ru } from "date-fns/locale";
import User from "@/components/Widgets/User/User";
import moment from "moment";
export default {
  name: "NewsBirthdayItem",
  mixins: [],
  props: {
    birthdayItem: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // Через сколько дней ДР
    setDifferentDate(user) {
      let today = moment(new Date()).format("YYYY-MM-DD").split("-");
      let birthday = moment(new Date(user.date_of_birth)).format("YYYY-MM-DD").split("-");
      if (Number(today[1]) > Number(birthday[1])) {
        birthday[0] = Number(today[0]) + 1;
      } else {
        birthday[0] = today[0];
      }
      birthday = moment(birthday.join("-"));
      today = moment(today.join("-"));
      let diff = birthday.diff(today, "days");
      if (!diff) {
        return `Сегодня`;
      } else if (diff === 1) {
        return "Завтра";
      } else {
        return `${formatDuration(
          {
            days: diff
          },
          {
            locale: ru
          }
        )}`;
      }
    },

    // Дата ДР
    setBirthdayDate(user) {
      let date = format(new Date(user.date_of_birth), "dd MMMM", {
        locale: ru
      });
      return date;
    }
  },
  computed: {
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.birthday-widget {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    word-break: break-word;

    /deep/ {
      .user__name {
        width: 170px;

        @media (max-width: $lg) {
          width: 140px;
        }
      }
      .user__more-info {
        color: $link-default;
      }

      .user__main-info {
        @media (max-width: $xxxl) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media (max-width: $md) {
          flex-direction: row;
          align-items: center;
        }
      }

      .user__avatar {
        @media (max-width: $xl) {
          display: none;
        }
      }
    }

    &:hover {
      /deep/ .user__name {
        color: $text-subdued;
      }
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__different {
    width: 93px;
    height: 26px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    border-radius: 32px;

    @media (max-width: $sm) {
      width: 79px;
      height: 22px;
    }
  }
}
</style>
