import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import IspReview from "@/modules/Isp/store/mutations/IspReview/IspReview";
import IspMy from "@/modules/Isp/store/mutations/IspMy/IspMy";
import IspTeams from "@/modules/Isp/store/mutations/IspTeams/IspTeams";
import IspProblem from "@/modules/Isp/store/mutations/IspProblem/IspProblem";
import Search from "@/modules/Isp/store/mutations/Search/Search";
import router from "@/router";

const mutations = {
  [types.SET_TABLE_PARAMS](state, payload) {
    state.problemsParams = payload;
  },
  [types.SET_LOADER_TABLE](state, payload) {
    state.loaderTable = payload;
  },
  [types.SET_LOADER_QUARTER](state, payload) {
    state.loaderQuarter = payload;
  },
  [types.CHANGE_INFO_MODAL_MODE](state, payload) {
    state.infoModalMode = payload;
  },
  [types.CLEAR_TABLE_DATA](state) {
    state.problemsData = [];
  },
  [types.SET_SORT](state, payload) {
    state.valueSort = state.valueSortList.find((item) => item.value === payload);
    state.valueSort.isAsc = !state.valueSort.isAsc;
    if (router.app.$route.name === "IspMy") {
      state.problemsMyIspParams._order = JSON.stringify({
        [state.valueSort.value]: state.valueSort.isAsc ? "asc" : "desc"
      });
    } else {
      state.problemsReviewParams._order = JSON.stringify({
        [state.valueSort.value]: state.valueSort.isAsc ? "asc" : "desc"
      });
    }
  },
  [types.GET_STATUSES_LIST](state, payload) {
    state.statusesList = payload;
  },
  [types.GET_CRITICALITY_LIST](state, payload) {
    state.criticalityList = payload;
  },
  [types.GET_USERS_LIST](state, payload) {
    state.usersList = payload;
  },
  [types.SHOW_CONFIRM_MODALS](state, payload) {
    state.showConfirmModal = payload;
  },

  ...IspReview,
  ...IspMy,
  ...IspTeams,
  ...IspProblem,
  ...Search
};

export default mutations;
