<template>
  <div class="black-sphere__icon-widget">
    <IconWidgets :name="'black-sphere'" :fontSize="27" />
    <div class="black-sphere__wrap">
      <p class="black-sphere__subtitle">Черный шар</p>
      <div class="black-sphere__container" v-click-outside="hideTooltip">
        <p class="black-sphere__content">
          <span class="black-sphere__count"
            >{{ blackSphereCount }}
            <span v-if="!setDateSameChangeBSM && !setDateAfterChangeBSM"> из 5 </span></span
          >
          <span class="black-sphere__percent" v-if="setDateAfterChangeBSM || setDateSameChangeBSM"
            >{{ blackSpherePercent }}%</span
          >
        </p>
        <i
          class="icon-info"
          v-if="setDateAfterChangeBSM || setDateSameChangeBSM"
          @click.stop="showTooltip = !showTooltip"
        ></i>
        <p class="black-sphere__tooltip" v-if="showTooltip">
          Как считается процент ЧШ можно узнать
          <router-link :to="{ name: 'RegulationPage', params: { regulation_id: 13 } }"
            >здесь
          </router-link>
          .
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconWidgets from "@/assets/aop-icons/IconWidgets";
import ClickOutside from "vue-click-outside";
import BlackSphere from "@/modules/BlackSphere/store";
import moment from "moment";
import TimeTracker from "@/modules/TimeTracker/store";

export default {
  name: "BlackSphereWidgetNew",
  mixins: [],
  props: {
    blackSphere: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { IconWidgets },
  data() {
    return {
      showTooltip: false
    };
  },
  mounted() {},
  methods: {
    hideTooltip() {
      this.showTooltip = false;
    }
  },
  computed: {
    blackSphereCount() {
      return TimeTracker.statistic.black_sphere_count_marks;
    },
    blackSpherePercent() {
      return TimeTracker.statistic.black_sphere_percent;
    },
    setDateAfterChangeBSM() {
      return moment(this.blackSphere.month.split("-").reverse().join("-") + "-01").isAfter(
        moment(new Date("2021-11-01"))
      );
    },
    setDateSameChangeBSM() {
      return (
        moment(this.blackSphere.month.split("-").reverse().join("-") + "-01").format(
          "YYYY-MM-DD"
        ) === moment(new Date("2021-11-01")).format("YYYY-MM-DD")
      );
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  &__icon-widget {
    display: flex;
    align-items: center;
  }

  .icon-widget {
    margin-right: 8px;
  }

  &__subtitle {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: $text-body-dark;

    @media (max-width: $xxxl) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: $text-body-muted;

    @media (max-width: $xxxl) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__percent {
    display: block;
    margin-left: auto;
  }

  &__tooltip {
    position: absolute;
    padding: 5px 8px;
    color: $surface-default;
    transition: all 0.3s ease;
    top: -28px;
    left: 110%;
    font-size: 14px;
    line-height: 20px;
    background-color: $bg-surface-dark;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
    white-space: nowrap;
    z-index: 10;

    a {
      color: $action-secondary;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $action-secondary-hovered;
      }

      &:active {
        color: $action-secondary-pressed;
      }
    }
  }

  .icon-info {
    margin-left: 4px;
    font-size: 12px;
    color: $text-body-muted;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }
  }
}
</style>
