<template>
  <div
    v-if="images.length"
    :class="[
      'news-images',
      images.length === 2 ? 'news-images_two' : '',
      images.length === 3 ? 'news-images_three' : ''
    ]"
  >
    <NewsImageItem
      v-for="(image, idx) in images"
      :image="image"
      :idx="idx"
      :editMode="editMode"
      :key="idx"
      @news_grid_delete_item="newsGridDeleteItem"
    />
  </div>
</template>

<script>
import NewsImageItem from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsImageItem";
import { mapActions } from "vuex";
export default {
  name: "NewsImageGrid",
  mixins: [],
  props: {
    images: {
      type: Array,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  components: { NewsImageItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["openFullSizeImage"]),
    openImageModal(link) {
      this.openFullSizeImage(link);
      // this.$root.$emit("openFullSizeImage", link);
    },
    newsGridDeleteItem(val) {
      this.$emit("news_grid_delete_item", val);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints.scss";
.news-images {
  margin-bottom: 16px;
  @media screen and (max-width: $md) {
    margin-bottom: 16px;
  }
  @media screen and (max-width: $xs) {
    margin-bottom: 8px;
  }
  &_two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .news-component__0,
    .news-component__1 {
      width: 49% !important;
    }
  }

  &_three {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;

    grid-template-areas:
      "news-component__0 news-component__1"
      "news-component__0 news-component__2";

    .news-component__0 {
      grid-area: news-component__0;
      margin-right: 10px;
      @media screen and (max-width: $xs) {
        margin-right: 5px;
      }
      /deep/ {
        img {
          height: 490px;
          @media screen and (max-width: $xs) {
            height: 200px;
          }
        }
      }
    }

    .news-component__1 {
      grid-area: news-component__1;
      /deep/ {
        img {
          max-height: 240px;
          @media screen and (max-width: $xs) {
            max-height: 98px;
          }
        }
      }
    }

    .news-component__2 {
      grid-area: news-component__2;
      /deep/ {
        img {
          max-height: 240px;
          margin-bottom: 10px;
          @media screen and (max-width: $xs) {
            max-height: 98px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  /deep/ {
    img {
      max-height: 490px;
      width: 100%;
      @media screen and (max-width: $xs) {
        max-height: 200px;
      }

      object-fit: cover;
      cursor: pointer;
    }
  }
}
</style>
