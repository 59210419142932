import { EventBus } from "@/main";
import { notEmpty } from "@/helpers/helperFunction";

class ErrorObserver {
  notification(error) {
    let msg = `undefined error from backend`;
    let code = error.code || "500";
    msg = `${error.message || error}`;
    if (notEmpty(error.data)) {
      let msgErrors = error.data;
      for (let i in msgErrors) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `${msgErrors[i]}`
        });
      }
    } else if (notEmpty(error.response)) {
      let msgErrors = error.data;
      for (let i in msgErrors) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `${msgErrors[i]}`
        });
      }
    } else if (notEmpty(error.response) && error.response.data.data.error.message) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `${error.response.data.data.error.message}`
      });
    } else {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `${msg}`
      });
    }
    throw new Error(error.message);
  }
}

export default new ErrorObserver();
