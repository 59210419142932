<template>
  <div class="links-table__row row">
    <div class="row__number cell-modal">{{ selectedStep.relative_number }}</div>
    <div class="row__action cell-modal">
      <Select
        :items="stepLinksItems"
        v-model="selectedStepData"
        :showAvatar="false"
        :multiple="false"
        :placeholder="'Выберите'"
        :removeCross="false"
        :showButtonPanel="false"
        :disabled="true"
      />
    </div>
    <div class="row__cross cell-modal"><i class="icon-cross" @click="deleteSelectedStep"></i></div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";

export default {
  name: "LinksTableRow",
  mixins: [],
  props: {
    stepLinksItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selectedStep: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { Select },
  data() {
    return {
      selectedStepData: []
    };
  },
  mounted() {
    this.selectedStepData = [this.selectedStep];
  },
  methods: {
    deleteSelectedStep() {
      this.$emit("deleteSelectedStep", this.selectedStep.id);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.row {
  display: flex;
  .cell-modal {
    box-shadow: -1px -1px 0px rgba(166, 166, 166, 0.1), inset -1px -1px 0px rgba(166, 166, 166, 0.1);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text-default;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      width: 12%;
    }
    &:nth-child(2) {
      width: 76%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    i {
      color: $icon-subdued;
      font-size: 24px;
      cursor: pointer;
      transition: 0.2s all ease;
      &:hover {
        color: $link-default;
      }
    }
    /deep/ {
      .input {
        width: 100%;
        border-radius: 2px;
        input {
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
