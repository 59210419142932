<template>
  <li class="modal__base-plan item">
    <CheckboxISP v-model="selected" class="item__checkbox" :isEdit="true" @input="handleCheckbox" />
    <p>{{ basePlanItem.name }}</p>
    <div class="item__control" v-if="isAuthor">
      <i class="icon-edit" @click="editBasePlan"></i>
      <i class="icon-delete" @click="openConfirmModal"></i>
    </div>
  </li>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import NotificationObserver from "@/helpers/UserNotification";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "BasePlanItem",
  mixins: [],
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    basePlanItem: {
      type: Object,
      default: () => {
        return {};
      }
    },
    planId: {
      type: Number,
      default: null
    },
    isAuthor: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CheckboxISP
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openConfirmModal() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.SET_CONFIRM_CONFIG]({
        confirmButton: "Да",
        cancelButton: "Отменить",
        title: "Удалить?",
        text: "Вы действительно хотите удалить базовый план?",
        handleConfirm: async () => {
          try {
            await PlanningScheduleModule.deleteBasePlanAction(this.basePlanItem.id);
            TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_CONFIRMATION_MODAL](false);
            NotificationObserver.notification({
              type: "success",
              message: "План успешно удален!"
            });
          } catch {
            TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_CONFIRMATION_MODAL](false);
          }
        },
        handleCancel: () => {
          TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_CONFIRMATION_MODAL](false);
        }
      });
    },
    handleCheckbox(val) {
      this.$emit("handleCheckbox", { basePlanItem: this.basePlanItem, isSelected: val });
    },
    editBasePlan() {
      this.$router.push({
        name: "Plan",
        query: { id: this.planId, mode: "base-edit", version_id: this.basePlanItem.id }
      });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.item {
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $text-default;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
    text-overflow: ellipsis;
  }
  &__control {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    i {
      color: $icon-subdued;
      font-size: 18px;
      transition: 0.2s all ease;
      cursor: pointer;
      &:hover {
        color: $link-default;
      }
    }
  }
}
</style>
