<template>
  <div class="show-edit-project" @mousedown.self.left="closeCurtainShowAndEditProject">
    <ConfirmModal
      v-if="confirmDeleteProject"
      :title="'Удалить'"
      :description="'Проект будет удален со всеми этапами, задачами и вложенными проектами. Продолжить?'"
      @cancel="cancelDeleteProject"
      @accept="acceptDeleteProject"
    />
    <ConfirmModal
      v-if="confirmCompleteProject"
      :title="'Выполнить'"
      :description="'Проект может содержать невыполненные этапы. Данные этапы будут закрыты. Продолжить?'"
      @cancel="cancelComplete"
      @accept="acceptComplete"
    />
    <div class="show-edit-project__container">
      <Spinner v-if="projectDescriptionLoader" class="show-edit-project__loader" />
      <div class="show-edit-project__wrap" v-else>
        <div class="show-edit-project__header">
          <i class="icon-arrow-back" @click="closeCurtainShowAndEditProject"></i>
          <TabsContent :tabs="tabsList" v-model="currentTab" />
          <div class="show-edit-project__control">
            <i class="icon-launch" @click="goToProject"></i>
            <ActionsList :actionList="actionList" @handleAction="handleAction" />
          </div>
        </div>
        <div class="show-edit-project__body">
          <ProjectDescriptionBody
            :project="currentProject"
            v-if="currentTab === 'description'"
            :isReadonly="isReadonly"
          />
          <ProjectStagesBody
            v-if="currentTab === 'stages'"
            :stages="stages"
            :projectId="currentProject.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import ProjectDescriptionBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/BodyComponents/ProjectDescriptionBody";
import ProjectStagesBody from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/BodyComponents/ProjectStagesBody";
import copyToClipboard from "@/helpers/CopyToClipboard";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import NotificationObserver from "@/helpers/UserNotification";
export default {
  name: "CurtainShowAndEditProject",
  mixins: [],
  props: {},
  components: {
    ProjectStagesBody,
    ProjectDescriptionBody,
    Spinner,
    ConfirmModal,
    ActionsList,
    TabsContent
  },

  data() {
    return {
      currentTab: "description",
      confirmDeleteProject: false,
      confirmCompleteProject: false,
      tabsList: [
        {
          label: "Описание",
          value: "description"
        },
        {
          label: "Этапы и задачи",
          value: "stages"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    closeCurtainShowAndEditProject() {
      ProjectModule.closeCurtainShowAndEditProject();
    },
    goToProject() {
      this.closeCurtainShowAndEditProject();
      this.$router.push({
        name: "Project",
        params: { id: this.currentProject.id }
      });
    },
    handleAction(item) {
      switch (item.actionType) {
        case "complete":
          this.confirmCompleteProject = true;
          break;
        case "reopen":
          this.reopenProject();
          break;
        case "business-setting":
          this.toFlowchart();
          break;
        case "kanban-setting":
          this.openKanbanSetting();
          break;
        case "copyLink":
          this.copyProjectLink();
          break;
        case "delete":
          this.confirmDeleteProject = true;
          break;
      }
    },
    cancelDeleteProject() {
      this.confirmDeleteProject = false;
    },
    acceptDeleteProject() {
      ProjectModule.deleteProjectAction(this.currentProject.id);
      ProjectModule.closeCurtainShowAndEditProject();
      this.confirmDeleteProject = false;
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно удален!"
      });
    },
    cancelComplete() {
      this.confirmCompleteProject = false;
    },
    acceptComplete() {
      const updatedProject = {
        id: this.currentProject.id,
        is_completed: true
      };
      ProjectModule.updateProjectAction(updatedProject).then(() => {
        StageModule.getStageByProjectId({ project_id: this.currentProject.id });
      });
      this.confirmCompleteProject = false;
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно закрыт!"
      });
    },
    toFlowchart() {
      this.$router.push({
        name: "Flowchart",
        params: { flow_id: this.currentProject.flow_id, project_id: this.currentProject.id }
      });
      this.closeCurtainShowAndEditProject();
    },
    openKanbanSetting() {
      this.$router.push({ name: "KanbanSetting", params: { id: this.currentProject.id } });
      this.closeCurtainShowAndEditProject();
    },
    reopenProject() {
      const updatedProject = {
        id: this.currentProject.id,
        is_completed: false
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно возвращен в работу!"
      });
    },
    copyProjectLink() {
      let currentURL = window.location.href.split("/");
      currentURL.pop();
      currentURL.push("Project");
      currentURL.push(this.currentProject.id);
      const projectLink = currentURL.join("/");
      copyToClipboard(projectLink);
    }
  },
  computed: {
    projectDescriptionLoader() {
      return ProjectModule.projectDescriptionLoader;
    },
    currentProject() {
      return ProjectModule.currentProject.projectFull;
    },
    actionList() {
      return [
        {
          isShow: this.currentProject?.is_owner && !this.currentProject?.is_completed,
          iconType: "folder-check",
          actionText: "Выполнить",
          actionType: `complete`
        },
        {
          isShow: this.currentProject?.is_owner && this.currentProject?.is_completed,
          iconType: "reopen",
          actionText: "Вернуть в работу",
          actionType: `reopen`
        },
        {
          isShow: this.currentProject?.is_owner && !this.currentProject?.is_completed,
          iconType: "clipboard-ind",
          actionText: "Настроить бизнес-процесс",
          actionType: `business-setting`
        },
        {
          isShow: this.currentProject?.is_owner && !this.currentProject?.is_completed,
          iconType: "clipboard-secondary",
          actionText: "Настроить доску Канбан",
          actionType: `kanban-setting`
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          actionType: `copyLink`
        },
        {
          isShow: this.currentProject?.is_owner,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ];
    },
    stages() {
      return StageModule.stages(this.currentProject.id);
    },
    isReadonly() {
      return !this.currentProject.is_owner || this.currentProject.is_completed;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.show-edit-project {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__container {
    position: fixed;
    width: 783px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: $surface-default;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);

    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__wrap {
    height: 100%;
    width: 100%;
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 47%;
  }
  &__header {
    padding: 20px 24px 24px 8px;
    border-bottom: 1px solid $system-grey-four;
    display: flex;
    align-items: center;
    height: 70px;

    .icon-arrow-back {
      color: $icon-subdued;
      margin: 0 8px 0 0;
      display: none;
      font-size: 20px;

      @media (max-width: $md) {
        display: block;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 12px;
      cursor: pointer;
      transition: 0.3s all ease;
      &:hover {
        color: $link-default;
      }
    }

    /deep/ {
      .action-list__modal-list {
        width: 255px;
      }
    }
  }
  &__body {
    height: calc(100% - 70px);
    overflow: auto;
  }
}
</style>
