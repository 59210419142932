<template>
  <div class="graph">
    <GraphGrid :intervalList="intervalList" />
    <div class="graph__steps" v-if="!graphLoader">
      <div
        class="graph__steps_current"
        :class="{ graph__steps_unfocus: focusOnBasePlan, graph__steps_focus: !focusOnBasePlan }"
        :style="{ height: stepsBlockHeight }"
      >
        <StepBlock
          v-for="step in stepList"
          :key="step.id"
          :step="step"
          :showCriticalWay="showCriticalWay"
        />
        <StepLink
          v-for="stepLink in stepLinksToShow"
          :key="stepLink.id"
          :stepLink="stepLink"
          :stepList="stepList"
          :showCriticalWay="showCriticalWay"
        />
      </div>
      <div
        class="graph__steps_base"
        :class="{ graph__steps_unfocus: !focusOnBasePlan, graph__steps_focus: focusOnBasePlan }"
      >
        <StepBlock
          v-for="step in baseStepList"
          :key="step.id"
          :step="step"
          :isBase="true"
          @toForefront="focusBasePlan"
          @toBackground="unfocusBasePlan"
        />
      </div>
    </div>
    <div class="graph__steps_loading" v-else>
      <Skeleton class="graph__step-loading graph__step-loading_1" />
      <Skeleton class="graph__step-loading graph__step-loading_2" />
      <Skeleton class="graph__step-loading graph__step-loading_3" />
    </div>
  </div>
</template>

<script>
import GraphGrid from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/Graph/GraphGrid/GraphGrid";
import calendarGenerate from "@/aopV2/modules/Calendar/helper/calendarGenerate";
import moment from "moment";
import { gridType } from "@/aopV2/modules/Calendar/store/calendarModule";
import StepBlock from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/Graph/StepBlock";
import StepLink from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/Graph/StepLink";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton";
export default {
  name: "Graph",
  mixins: [],
  props: {
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    stepLinksList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    baseStepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    baseStepLinkList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showCriticalWay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Skeleton,
    StepLink,
    StepBlock,
    GraphGrid
  },
  data() {
    return {
      focusOnBasePlan: false
    };
  },
  mounted() {},
  methods: {
    focusBasePlan() {
      this.focusOnBasePlan = true;
    },
    unfocusBasePlan() {
      this.focusOnBasePlan = false;
    }
  },
  computed: {
    intervalList() {
      const startPlanDay = this.stepList[0]?.date_start ?? moment(new Date()).format("YYYY-MM-DD");
      const stopPlanDay =
        this.stepList[this.stepList.length - 1]?.late_end_date ??
        moment(new Date()).format("YYYY-MM-DD");
      const startBasePlanDay = this.baseStepList[0]?.date_start;
      const stopBasePlanDay = this.baseStepList[this.baseStepList.length - 1]?.late_end_date;
      const startDay =
        startBasePlanDay && moment(startBasePlanDay).isBefore(startPlanDay)
          ? startBasePlanDay
          : startPlanDay;
      let stopDay =
        stopBasePlanDay && moment(stopBasePlanDay).isAfter(stopPlanDay)
          ? stopBasePlanDay
          : stopPlanDay;
      const diff = moment(stopDay).diff(moment(startDay), "weeks");
      if (diff < 8) {
        stopDay = moment(startDay).add("weeks", 7).format("YYYY-MM-DD");
      }
      return calendarGenerate({
        currentDate: moment(new Date()).format("YYYY-MM-DD"),
        type: gridType.day,
        startDay: moment(startDay),
        stopDay: moment(stopDay),
        isWeek: true
      }).days.map((week) => week.slice(0, -2));
    },
    graphLoader() {
      return PlanningScheduleModule.stepListLoader || PlanningScheduleModule.stepLinksLoader;
    },
    stepLinksToShow() {
      return this.stepLinksList.filter((item) => item.step_from !== null && item.step_to !== null);
    },
    stepsBlockHeight() {
      const amountOfSteps = Math.max(this.stepList.length, this.baseStepLinkList.length);
      return `${amountOfSteps * PlanningScheduleModule.topMargin + 128}px`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.graph {
  width: fit-content;
  height: 100%;
  position: relative;
  overflow: auto;
  &__steps {
    position: absolute;
    width: 100%;
    height: calc(100% - 46px);
    top: 46px;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    &_focus {
      transition: 0.3s all ease;
      opacity: 1;
      z-index: 2;
    }
    &_unfocus {
      transition: 0.3s all ease;
      opacity: 0.5;
      z-index: 1;
    }
  }
  &__step-loading {
    width: 300px;
    height: 16px;
    border-right: 2px;
    position: absolute;
    &_1 {
      top: 160px;
      left: 120px;
    }
    &_2 {
      top: 240px;
      left: 300px;
    }
    &_3 {
      top: 320px;
      left: 480px;
    }
  }
}
</style>
