let TimeTracker: any = null;
export const updateTimer = (data) => {
  const payload = JSON.parse(data);
  if (TimeTracker === null) {
    TimeTracker = require("@/modules/TimeTracker/store").default;
  }
  TimeTracker.setActiveTask(payload);
  TimeTracker.activeTimer(payload);
  if (payload.isWS && TimeTracker.isActive) {
    TimeTracker.stopTask();
  }
};
