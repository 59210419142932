<template>
  <p class="level" :style="{ background: setBackground(level), color: setColor(level) }">
    <span class="level__round" :style="{ background: setColor(level) }"></span>
    {{ level }}
  </p>
</template>

<script>
export default {
  name: "Level",
  mixins: [],
  props: {
    level: {
      type: String,
      default: ""
    }
  },
  components: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    /** Функция установки фона в зависимости от грейда сотрудника
     *
     * @param level - грейд сотрудника
     * @returns {string} - цвет фона
     */
    setBackground(level) {
      switch (level) {
        case "Junior":
          return "#FFF8E5";
        case "Junior+":
          return "#FFF8E5";
        case "Middle-":
          return "#F1EFFA";
        case "Middle":
          return "#F1EFFA";
        case "Middle+":
          return "#F1EFFA";
        case "Senior-":
          return "#EBFCF6";
        case "Senior":
          return "#EBFCF6";
        case "Senior+":
          return "#EBFCF6";
        case "ACADEMY":
          return "#E8F7FC";
        case "Academy":
          return "#E8F7FC";
        default:
          return "#FAFAFE";
      }
    },
    /** Функция установки цвета текста и рамки в зависимости от грейда сотрудника
     *
     * @param level - грейд сотрудника
     * @returns {string} - цвет
     */
    setColor(level) {
      switch (level) {
        case "Junior":
          return "#CA7B00";
        case "Junior+":
          return "#CA7B00";
        case "Middle-":
          return "#504DA8";
        case "Middle":
          return "#504DA8";
        case "Middle+":
          return "#504DA8";
        case "Senior-":
          return "#238B6A";
        case "Senior":
          return "#238B6A";
        case "Senior+":
          return "#238B6A";
        case "ACADEMY":
          return "#127E95";
        case "Academy":
          return "#127E95";
        default:
          return "#A7A9C0";
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
.level {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 6px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border-radius: 16px;

  &__round {
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 50%;
  }
}
</style>
