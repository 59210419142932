import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import { IS_MY_ISP_FILTERS, SET_MY_ISP_PROGRESS } from "../mutationTypes";

const actionsIspMy = {
  [types.SET_MY_ISP_PROBLEM_PARAMS](state, payload) {
    state.problemsMyIspParams = payload;
  },

  [types.GET_MY_ISP_STATISTICS_LIST](state, payload) {
    state.myIspStatistics = payload;
  },

  [types.GET_GROUPS_ISP](state, payload) {
    state.groupsISP = payload;
  },

  [types.SET_MY_ISP_CRITICALITY](state, payload) {
    state.myIspCriticalityValue = payload;
  },

  [types.SET_MY_ISP_STATUS](state, payload) {
    state.myIspStatusValue = payload;
  },

  [types.SET_MY_ISP_ROLES](state, payload) {
    state.myIspRolesValue = payload;
  },

  [types.SET_MY_ISP_GROUPS](state, payload) {
    state.myIspGroupValue = payload;
  },

  [types.SET_MY_ISP_CUSTOMER](state, payload) {
    state.myIspCustomersValue = payload;
  },

  [types.IS_MY_ISP_FILTERS](state, payload) {
    state.isMyIspFilters = payload;
  }
};

export default actionsIspMy;
