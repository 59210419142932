import API from "@/api/Api";

class UserSettings extends API {
  constructor(prefix) {
    super("UserSettings", prefix);
  }
  actionGetItem(callback, data) {
    this.get("actionGetItem", callback, data);
  }
  actionUpdate(callback, data) {
    this.update("actionUpdate", callback, data);
  }
}

export default UserSettings;
