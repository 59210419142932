import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// @ts-ignore
import cryptoJs from "crypto-js";
// @ts-ignore
import VueCookie from "vue-cookie";
import serverHandler from "./helpers/serverHandler";
// @ts-ignore
import VCalendar from "v-calendar";
import centrifuge from "@/centrifuge";
import VueScrollTo from "vue-scrollto";

import clickOutside from "@/aopV2/helpers/v-click-outside";
import vSelect from "vue-select";

const VueInputMask = require("vue-inputmask").default;
Vue.config.productionTip = true;
import "vue-select/dist/vue-select.css";
import firebaseMessaging from "@/helpers/sw/firebase";
import Vue2TouchEvents from "vue2-touch-events";

Vue.prototype.$messaging = firebaseMessaging;

export const debug = false;
// @ts-ignore
Vue.directive("click-outside", clickOutside);
Vue.use(VueInputMask);
// @ts-ignore
Vue.use(cryptoJs);
Vue.use(VueCookie);
Vue.use(VCalendar);
Vue.use(VueScrollTo);
// @ts-ignore
Vue.use(centrifuge);
Vue.mixin(serverHandler);
Vue.use(Vue2TouchEvents);
Vue.component("v-select", vSelect);
new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");

const isDebug = debug ? -1 : "";

Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg.indexOf("prop") !== isDebug) {
    return msg;
  } else {
    // @ts-ignore
    return vm.errorAlert(msg);
  }
};

Vue.config.errorHandler = function (err, vm, info) {
  if (err.message.indexOf("label") !== isDebug) {
    return err;
  } else {
    // @ts-ignore
    return vm.errorAlert(err);
  }
};
export const EventBus = new Vue();
