<template>
  <header class="chat-header">
    <div class="chat-header__wrap chat-header__wrap_button" v-if="pickMode">
      <div class="chat-header__buttons">
        <ModalButton
          :title="'Переслать'"
          :count="pickedMessage.length"
          @handleButton="sendMessage"
        />
        <ModalButton
          :title="'Удалить'"
          :count="pickedMessage.length"
          @handleButton="deleteMessage"
          v-if="isSelfMessage || isAdminChat || currentChat.channel.is_moderator"
        />
      </div>
      <ModalButton :title="'Отменить'" :type="'cancel'" @handleButton="closePick" />
    </div>
    <div class="chat-header__wrap" v-if="!pickMode && threadMode">
      <i
        class="chat-header__back chat-header__icon icon-arrow-short"
        @click="closeCommentMessage"
      ></i>
      <div class="chat-header__wrap-text">
        <p class="chat-header__title">Комментарии</p>
        <p class="chat-header__message">{{ setTextMessage }}</p>
      </div>
    </div>
    <div class="chat-header__wrap" v-if="!pickMode && !threadMode">
      <i
        class="chat-header__back icon-arrow-short"
        v-if="isHiddenChannelList"
        @click="openChannelList"
      ></i>
      <Avatar :user="setChannel" :showImg="false" :showInfo="true" v-if="chat.channel.type === 1" />
      <div class="chat-header__wrap-name" v-if="chat.channel.type === 2">
        <h2 class="chat-header__name">
          {{ chat.channel.chat_settings.name }}
          <i class="icon-lock-secondary chat-header__icon" v-if="isLock && !joinMode"></i>
          <i class="icon-eye chat-header__icon" v-if="isOnlyRead && !joinMode"></i>
          <i class="icon-pin chat-header__icon" v-if="isPinned && !joinMode"></i>
          <i class="icon-mute chat-header__icon" v-if="isMute && !joinMode"></i>
        </h2>
        <h4 class="chat-header__tag">#{{ chat.channel.chat_settings.tag }}</h4>
      </div>
      <div class="chat-header__wrap-name" v-if="chat.channel.type === 3">
        <h2 class="chat-header__name">Избранное</h2>
      </div>
    </div>
    <div class="chat-header__wrap" v-if="!pickMode && !joinMode">
      <i class="icon-search chat-header__icon" v-if="!isMobileWindowSize" @click="handleSearch"></i>
      <span class="chat-header__message" @click="openThreadList" v-if="isGroupChat && !isOnlyRead">
        <i class="icon-comment-empty chat-header__icon"> </i>
        <span
          class="chat-header__new-message"
          v-if="chat.channel.count_unread_thread_messages > 0"
        ></span>
      </span>
      <i
        class="icon-menu chat-header__icon"
        @click="handleOpenMenu"
        :class="{
          'chat-header__icon_active': showMenu && !showComment && !showReactionList,
          'icon-menu-open': showMenu && !showComment && !showReactionList
        }"
      ></i>
      <i
        class="icon-dots-vertical chat-header__icon"
        @click="handleOpenList"
        v-if="isGroupChat"
      ></i>
    </div>
  </header>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import * as types from "@/modules/News/store/mutations/mutationTypes";
export default {
  name: "ChatHeader",
  mixins: [],
  props: {
    chat: {
      type: Object,
      default: () => {}
    }
  },
  components: { ModalButton, Avatar },
  data() {
    return {
      list: [],
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {},
  methods: {
    ...mapActions("MessengerModule", [
      "closeCurrentChat",
      "switchChatMenu",
      "switchPinMessages",
      "closePickMessage",
      "openThreads",
      "closeThread",
      "setSearchMessageMode",
      "sendPickMessage",
      "setAttachedModalValue",
      "deleteMessages",
      "switchModal"
    ]),

    openChannelList() {
      this.$router.replace({ query: {} });
      this.switchChatMenu(false);
    },
    handleCloseChat() {
      this.closeCurrentChat({});
      this.$router.replace({ query: {} });
    },
    closePinMessage() {
      this.switchPinMessages({ show: false });
    },
    listenerEsc(event) {
      if (event.key === "Escape") {
        this.handleCloseChat();
      }
    },
    openThreadList() {
      this.openThreads();
    },
    handleSearch() {
      document.querySelector("#input").focus();
      this.setSearchMessageMode(true);
    },
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    sendMessage() {
      this.sendPickMessage();
    },
    deleteMessage() {
      this.deleteMessages(this.isAdminChat || this.currentChat.channel.is_moderator);
    },
    closePick() {
      this.closePickMessage();
    },
    closeCommentMessage() {
      this.closeThread();
    },
    handleOpenMenu() {
      this.switchChatMenu(!this.showMenu);
    },
    handleOpenList(event) {
      const selector = this.threadMode ? ".chat_comment" : ".chat";
      const container = document.querySelector(selector);
      const windowInnerWidth = window.innerWidth;
      //учет бокового меню при определении позиции клика
      const clickPositionX = windowInnerWidth > 768 ? event.x - 99 : event.x;
      this.switchModal({
        show: !this.showModal,
        x: clickPositionX,
        y: event.y,
        container,
        optionList: this.setList,
        isLeft: true,
        channelItem: this.currentChat,
        scrollContainer: container
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "showMenu",
      "users",
      "showPin",
      "currentChat",
      "showModal",
      "threadMode",
      "joinMode",
      "showComment",
      "showReactionList",
      "threadMainMessage",
      "pickedMessage",
      "pickMode"
    ]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    isHiddenChannelList() {
      return this.isMobileWindowSize && this.$route.query.chat_id;
    },
    isPinned() {
      return this.chat.channel.is_pinned_chat;
    },
    isMute() {
      return !this.chat.channel.notification_enabled;
    },
    isLock() {
      return !this.chat.channel.chat_settings.is_open;
    },
    isOnlyRead() {
      return this.chat.channel.chat_settings.is_read_only;
    },
    isGroupChat() {
      return this.chat.channel.type === 2;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setChannel() {
      return this.currentChat.users.find((item) => item.id !== this.getUserId);
    },
    isSelfMessage() {
      return this.pickedMessage.some((item) => item.user_id === this.getUserId);
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    moderatorsChat() {
      return this.users.filter((item) => item.is_moderator);
    },
    setTextMessage() {
      return this.threadMainMessage.text ? this.threadMainMessage.text : "file";
    },
    setList() {
      const list = this.list.map((item) => item);
      if (
        !this.currentChat.channel.is_moderator ||
        (this.currentChat.channel.is_moderator && this.moderatorsChat.length > 1)
      ) {
        list.push({ name: "Покинуть канал", val: "leave", icon: "entrance" });
      }
      if (this.currentChat.channel.is_pinned_chat) {
        list.push({ name: "Открепить в левом меню", val: "pin-channel", icon: "pin" });
      } else {
        list.push({ name: "Закрепить в левом меню", val: "pin-channel", icon: "unpin" });
      }
      if (this.currentChat.channel.is_moderator || this.isAdminChat) {
        list.unshift({ name: "Добавить участников", val: "add-member", icon: "members-add" });
        list.unshift({ name: "Редактировать  канал", val: "edit-channel", icon: "edit" });
        list.push({ name: "Удалить канал", val: "delete-channel", icon: "delete" });
      }
      if (this.currentChat.channel.notification_enabled) {
        list.unshift({ name: "Отключить уведомления", val: "notification", icon: "bell" });
      } else {
        list.unshift({ name: "Включить уведомления", val: "notification", icon: "bell" });
      }
      return list;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  flex-shrink: 0;
  padding: 0 24px;
  border-bottom: 1px solid #eae8f0;
  @media screen and (max-width: $xs) {
    padding: 0 12px;
  }
  &__title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #343748;
  }
  &__back {
    margin-right: 16px;
    transform: rotate(-90deg);
    font-size: 18px !important;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: white !important;
        color: $text-disabled !important;
      }
    }
  }
  &__pinned {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $chat-editor;
    background: $message-gradient;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    i {
      transform: rotate(27.95deg);
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    &_button {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__buttons {
    display: flex;
    max-width: 260px;
    width: 100%;
    justify-content: space-between;
  }
  /deep/ {
    .default-button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
      border: 2px solid #ffffff;
      @media (hover: hover) {
        &:hover {
          background: linear-gradient(336.67deg, #443cea -13.54%, #5e60ea 70.48%, #686ed1 120.89%);
          box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
            0 4px 5px rgba(48, 79, 254, 0.2);
        }
      }
      &_cancel {
        color: #343748 !important;
        background: none !important;
        box-shadow: none !important;
      }
      @media screen and (max-width: $xs) {
        font-size: 13px;
        padding: 8px 12px;
      }
    }
  }
  &__wrap-name {
    display: flex;
    flex-flow: column;
  }
  &__name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: $text-default;
    padding: 0;
    margin: 0 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    @media screen and (max-width: $lg) {
      max-width: 200px;
    }
    @media screen and (max-width: $md) {
      max-width: 300px;
    }
    @media screen and (max-width: $xs) {
      max-width: 110px;
    }
    i {
      font-size: 14px;
      margin: 0 4px;
      cursor: unset;
      @media (hover: hover) {
        &:hover {
          background: unset;
          color: #a7a9c0;
        }
      }
    }
  }
  &__tag {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    @media screen and (max-width: $lg) {
      max-width: 200px;
    }
    @media screen and (max-width: $md) {
      max-width: 300px;
    }
    @media screen and (max-width: $xs) {
      max-width: 95px;
    }
  }
  &__icon {
    font-size: 24px;
    color: $icon-subdued;
    cursor: pointer;
    border-radius: 4px;
    @media (hover: hover) {
      &:hover {
        background-color: $background-default;
        color: $text-subdued;
        path {
          fill: $text-subdued;
        }
      }
    }
    &_active {
      background-color: $background-default;
      color: $text-subdued;
    }
  }
  .icon-menu {
    margin-left: 24px;
  }
  .icon-chat-message {
    margin-left: 24px;
    font-size: 22px;
    width: 24px;
    height: 24px;
  }
  .icon-dots-vertical {
    margin-left: 24px;
  }
  .icon-comment-empty {
    display: flex;
    margin-left: 20px;
    //font-size: 20px;
    //padding: 4px;
    //transform: scale(-1, 1);
  }
  &__message {
    display: flex;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $text-subdued;
    display: -webkit-box;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
  }
  &__new-message {
    box-sizing: content-box;
    position: absolute;
    width: 7px;
    height: 7px;
    background: $message-gradient;
    border-radius: 50%;
    right: -2px;
    top: -2px;
    border: 3px solid #fff;
  }
}
</style>
