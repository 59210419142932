import router from "@/router";

import { domain } from "@/globalVariables";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

const actionsSearch = {
  searchProblemsAction({ commit }, searchValue) {
    if (!searchValue) {
      commit("SET_SEARCH_RESULTS", []);
      commit("TOGGLE_LOADER_SEARCH", false);
    } else {
      httpClient({
        method: "GET",
        url: `/ISPProblem/actionGetItems`,
        params: {
          _count: 300,
          _with: JSON.stringify(["group:alias,id", "status"]),
          _search: {
            name: searchValue
          }
        }
      })
        .then((response) => {
          commit("SET_SEARCH_RESULTS", response.data.data.items);
          commit("TOGGLE_LOADER_SEARCH", false);
        })
        .catch((error) => {
          ErrorObserver.notification(error);
        });
    }
  }
};

export default actionsSearch;
