<template>
  <div v-click-outside="hideConformationUser">
    <p class="modal__content" v-if="isEdit">
      Создал(а) {{ user.full_name || getDataInfoUser.full_name }}
      <!--      {{ getDataOfCreation(vacation.date_of_creation) }}-->
    </p>

    <div class="modal__user_wrap" @click="isConfirmationsPersonModal = true" v-if="isAdmin">
      <div class="modal__user user" v-if="selectUser">
        <img
          class="user__avatar"
          :src="returnSrcFile(selectUser.avatar)"
          alt="Avatar"
          width="32"
          height="32"
        />
        <span class="modal__user_add-text">{{ selectUser.full_name }}</span>
        <p class="user__name">
          <span @click="deleteUser">Удалить</span>
        </p>
      </div>
      <div class="modal__user_add-wrap" v-else>
        <div class="modal__user modal__user_add">
          <i class="icon-plus"></i>
        </div>
        <span class="modal__user_add-text">Добавить пользователя</span>
      </div>
    </div>
    <ConfirmationsUsersModal
      :maxCount="1"
      :isAdmin="isAdmin"
      v-if="isConfirmationsPersonModal"
      :buttonsPanel="false"
      @hideModal="isConfirmationsPersonModal = false"
    />
    <h2 class="modal__title" v-if="editMode">
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-start-data'"
        :editValue="vacation.date_from"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateStart"
      />
      —
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-end-data'"
        :editValue="vacation.date_to"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateEnd"
      />
    </h2>
    <h2 class="modal__title" v-if="createMode && !editMode">
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-start-data'"
        :displayFormat="'DD.MM.YYYY'"
        :isDateDisabled="checkDisabledDateStart"
        :editValue="startDate"
      />
      —
      <Widget
        :readonly="true"
        type="InputDatePicker"
        :unique="'modal-request-vacation-end-data'"
        :isDateDisabled="checkDisabledDateEnd"
        :displayFormat="'DD.MM.YYYY'"
        :editValue="startDate"
      />
    </h2>
    <h2 class="modal__title_read" v-if="!createMode && !editMode">
      {{ setLocalDate(vacation.date_from) }}
      —
      {{ setLocalDate(vacation.date_to) }}
    </h2>
    <h3 class="modal__subtitle" v-if="showSubtitle">
      {{ setLocalDate(vacation.date_from) }}
      —
      {{ setLocalDate(vacation.date_to) }}
    </h3>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import ConfirmationsUsersModal from "@/components/Widgets/ConfirmationsUsersModal/ConfirmationsUsersModal";
import ClickOutside from "vue-click-outside";

import moment from "moment";
import { format } from "date-fns";
import { domainStorage } from "@/globalVariables";
export default {
  name: "ModalDate",
  mixins: [],
  props: {
    editMode: {
      type: Boolean,
      required: true,
      default: true
    },
    createMode: {
      type: Boolean,
      required: true,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    }
  },
  components: { Widget, ConfirmationsUsersModal },
  data() {
    return {
      dateStartVacation: this.vacation.date_from,
      isConfirmationsPersonModal: false,
      selectUser: null,
      date: new Date()
    };
  },
  mounted() {
    this.$root.$on("date:change_modal-request-vacation-start-data", (val) => {
      this.dateStartVacation = val;
    });
    this.$root.$on("changeUserVacationOrder", (val) => {
      this.selectUser = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("date:change_modal-request-vacation-start-data");
    this.$root.$off("changeUserVacationOrder");
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    hideConformationUser() {
      this.isConfirmationsPersonModal = false;
    },
    getDataOfCreation(date) {
      moment.locale("ru");
      return moment(date).fromNow();
    },
    deleteUser() {
      this.selectUser = null;
    },
    setLocalDate(date) {
      moment.locale("ru");
      return moment(date).format("L");
    },
    checkDisabledDateStart(date) {
      const currentDate = moment().add(5, "day");
      return moment(date) < currentDate;
    },
    checkDisabledDateEnd(date) {
      if (this.dateStartVacation) {
        return date < moment(this.dateStartVacation) || date === moment(this.dateStartVacation);
      } else {
        const currentDate = moment().add(5, "day");
        return moment(date) < currentDate || moment(date) === currentDate;
      }
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isEdit() {
      return this.editMode || (!this.editMode && !this.createMode);
    },
    startDate() {
      this.date.setDate(this.date.getDate() + 6);
      return format(this.date, "Y-MM-dd");
    },
    showSubtitle() {
      return !this.createMode && this.editMode && !this.isAvailableForEditing;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal {
  &__main {
    div {
      /deep/ {
        .confirmation-user {
          .confirmation-user__list {
            height: 150px;

            @media (max-width: $md) {
              height: 77vh;
              max-height: 77vh;
            }

            @media (max-width: 850px) and (max-height: 450px) {
              height: 45vh;
              max-height: 45vh;
            }

            @media (max-width: $sm) {
              height: 75vh;
              max-height: 75vh;
            }

            @media (max-width: 568px) and (max-height: 350px) {
              height: 35vh;
              max-height: 35vh;
            }

            @media (max-width: 320px) {
              height: 65vh;
              max-height: 70vh;
            }
          }
        }
      }
    }
  }

  &__subtitle {
    padding: 0;
    margin: 24px 0 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__title {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $text-disabled;

    &_read {
      margin: 0 0 24px;
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: $text-default;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #6a67ce;
      animation-name: border;
      animation-timing-function: ease;
      animation-duration: 0.5s;
      transform: scale(1);
      transform-origin: center;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background: #d3d2e0;
      z-index: 1;
    }

    @keyframes border {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    /deep/ {
      .data-picker {
        background: transparent;
        max-width: 200px;

        @media (max-width: $xs) {
          max-width: 130px;
          padding: 12px 10px;
        }
      }
      .vdpComponent.vdpWithInput {
        input {
          border: none;
          max-width: 180px;
          width: 100%;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: $text-disabled;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          @media (max-width: $xs) {
            padding: 0;
            font-size: 16px;
          }
        }
      }

      .vdpComponent.vdpWithInput .vdpClearInput {
        display: none;
      }

      .vdpOuterWrap .vdpInnerWrap {
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
        border-radius: 24px;
        max-width: 320px;
        min-width: 320px;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpHeader .vdpPeriodControls .vdpPeriodControl > button {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: $text-default;
      }

      .vdpOuterWrap .vdpInnerWrap .vdpTable .vdpRow .vdpCell.selected .vdpCellContent {
        background: #6a67ce;
      }

      .vdpCell.outOfRange {
        font-size: 12px;
        line-height: 21px;
        color: #a0aec0;
      }

      .vdpOuterWrap {
        font-size: 12px;
        line-height: 21px;
        color: #2d3748;
        box-shadow: none;
      }
    }

    span {
      margin-left: 8px;
    }
  }

  &__user {
    &_ghost {
      width: 32px;
      height: 32px;
    }
    &_wrap {
      cursor: pointer;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
    }
    &_add {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $background-active;
      border: 1px dashed $bg-gray-400;
      border-radius: 50%;
      z-index: 5;
      cursor: pointer;

      &:hover {
        background: $background-hovered;
      }

      .icon-plus {
        color: $icon-subdued;
      }
    }
    &_add-wrap {
      display: flex;
      align-items: center;
    }
    &_add-text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $text-disabled;
      margin-left: 11px;
    }
  }
  &__content {
    margin: 0 0 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-disabled;
  }
}
.user {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ffffff;

  &__avatar {
    flex-shrink: 0;

    display: block;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover {
    .user__name {
      display: block;
    }
  }

  &__name {
    display: none;
    position: absolute;
    top: -30px;
    left: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    span {
      cursor: pointer;
      margin-left: 0;
      text-decoration: underline;
    }
  }
}
</style>
