<template>
  <div class="file-item__switch">
    <div class="file-image" v-if="isImage">
      <img
        class="file-image__image"
        :src="file.link"
        alt=""
        :class="{
          'file-image__image_one': listLength === 1,
          'file-image__image_many': listLength !== 1
        }"
      />
      <div class="file-image__wrap">
        <i class="icon-cross" @click="deleteIcon(file.id)"></i>
      </div>
    </div>
    <div class="file-item" v-else>
      <i class="icon-document-text"></i>
      <div class="file-item__text-wrap">
        <p class="file-item__name">
          {{ setName }}
        </p>
        <p class="file-item__size">
          {{ sizeFloor }}
        </p>
      </div>

      <div class="file-item__wrap">
        <i class="icon-cross" @click="deleteIcon(file.id)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule, {
  ProjectMutationTypes
} from "@/modules/TaskManager/Model/Project/ProjectModule";
export default {
  name: "FileItem",
  mixins: [],
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    currentTask: {
      type: Object,
      default: () => {}
    },
    listLength: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {
      fileTypes: [
        "image/apng",
        "image/avif",
        "image/gif",
        "image/png",
        "image/svg+xml",
        "image/jpeg"
      ],
      files: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["deleteCurrentFile", "replaceFile"]),
    /**
     *
     * @param id - id file
     */
    deleteIcon(id) {
      TaskModule[TaskMutationTypes.UPDATE_FILES_MODAL](id);
    }
  },
  computed: {
    isImage() {
      return this.fileTypes.includes(this.file.type);
    },
    setName() {
      const length = this.file.name.length;
      if (length > 20) {
        return this.file.name.slice(0, 10) + "..." + this.file.name.slice(length - 7, length);
      } else {
        return this.file.name;
      }
    },
    sizeFloor() {
      const size = this.file.file.size;
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "Кб";
      } else {
        return size + "Б";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.file-image {
  margin-bottom: 8px;
  position: relative;
  &__image {
    border-radius: 4px;
    object-fit: cover;
    object-position: 50% 20%;
    &_one {
      max-width: 294px;
      max-height: 184px;
      width: 294px;
      height: 184px;

      @media screen and (max-width: 850px) and (orientation: landscape) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-height: $sm) {
        width: 100%;
        height: auto;
      }
    }
    &_many {
      width: 185px;
      height: 115px;

      @media screen and (max-width: 850px) and (orientation: landscape) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-height: $sm) {
        width: 100%;
        height: auto;
      }
    }
  }
  &__wrap {
    position: absolute;
    z-index: 10;
    right: 4px;
    top: 4px;
    display: flex;
    flex-flow: column;
    padding: 4px;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background-color: rgba($system-grey-seven, 50%);
    /deep/ {
      .file-uploads {
        height: 14px;
        display: flex;
        cursor: pointer;
      }
      .file-uploads.file-uploads-html4 input,
      .file-uploads.file-uploads-html5 label {
        font-size: 14px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    i {
      cursor: pointer;
    }
    .icon-delete {
      margin-top: 10px;
    }
  }
}
.file-item {
  position: relative;
  width: 241px;
  display: flex;
  align-items: center;
  background-color: $surface-primary-default;
  padding: 4px 8px 4px 12px;
  border-radius: 4px;
  .icon-document-text {
    font-size: 20px;
    color: $icon-highlight;
  }
  &__text-wrap {
    margin-left: 20px;
  }
  &__name {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    padding: 0;
    margin: 0 0 1px;
  }
  &__size {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: $text-disabled;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
  &__wrap {
    color: $icon-subdued;
    margin-left: auto;
    /deep/ {
      .file-uploads {
        height: 14px;
        display: flex;
        cursor: pointer;
      }
      .file-uploads.file-uploads-html4 input,
      .file-uploads.file-uploads-html5 label {
        font-size: 14px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    i {
      cursor: pointer;
    }
    .icon-delete {
      margin-top: 10px;
    }
  }
}
</style>
