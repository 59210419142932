import ActivityBasedTimer from "@/helpers/ActivityBasedTimer";
import httpClient from "@/api/client/httpClient";
import store from "@/store";
import TimeProjectService from "@/modules/TimeTracker/Service/TimeProjectService";

const versioningTimer = new ActivityBasedTimer();
const isNewerVersionAvailable = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    TimeProjectService.sync();
  }
  return httpClient({
    url: window.location.origin + "/index.html",
    headers: { "Cache-Control": "no-cache" }
  }).then((resp) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(resp.data, "text/html");
    const oldApp = document.scripts[document.scripts.length - 1].src;
    const newApp = doc.scripts[doc.scripts.length - 1].src;
    return oldApp !== newApp;
  });
};
versioningTimer.setInterval({
  async callback() {
    const newVersionAvailable = await isNewerVersionAvailable();
    // if (!newVersionAvailable) {
    //   return;
    // }
    window.swUpdate = newVersionAvailable;
    store.commit("SHOW_MODAL_NEW_VERSION", newVersionAvailable);
  },
  // Normal interval is once every 10 minutes
  interval: 100 * 6 * 1000,
  // Forced interval is once per day
  forcedInterval: 1000 * 60 * 60 * 24
});
export default versioningTimer;
