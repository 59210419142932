<template>
  <div class="history-body">
    <div class="history-body__wrap-label wrap-label" v-if="showHeader">
      <div class="wrap-label__label">{{ title }}</div>
    </div>
    <ul class="history-body__list">
      <HistoryItem v-for="item in currentProblemHistory" :key="item.id" :historyItem="item" />
    </ul>
  </div>
</template>

<script>
import HistoryItem from "@/modules/Isp/components/InfoProblem/BodyComponents/HistoryBody/HistoryItem";
import { mapState } from "vuex";
export default {
  name: "HistoryBody",
  mixins: [],
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "История"
    }
  },
  components: { HistoryItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("IspModule", ["currentProblemHistory"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.history-body {
  width: 100%;
  height: 100%;
  .wrap-label {
    padding: 24px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    &__label {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 26px;
      color: #343748;
    }
  }
  &__header {
    all: unset;
    list-style: none;
    display: flex;

    background: #fafafe;
    border-radius: 8px;
  }
  &__header-item {
    text-transform: uppercase;
  }
  &__list {
    padding: 0 57px 0 36px;
    height: calc(100% - 130px);
    overflow: auto;
  }
  &__last-item {
    /deep/ {
      .history-item {
        border: none;
      }
    }
  }
}
</style>
