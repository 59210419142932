<template>
  <div id="list-comment" class="chat-main">
    <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive && !showFileModal" />
    <file-upload
      post-action="/upload/post"
      input-id="chatEditor"
      :multiple="true"
      :drop="true"
      v-model="files"
      :drop-directory="true"
      :disabled="editMode"
      @input="handleUploadFile"
      ref="upload"
      id="upload"
    >
    </file-upload>
    <div
      class="list-container"
      :class="{ 'list-container_blur': $refs.upload && $refs.upload.dropActive && !showFileModal }"
    >
      <MessageList :messageList="threadsMessages" :isThread="true" />
    </div>
    <div
      class="chat-main__button"
      v-if="(showButton || scrollNewMessage) && threadMode"
      @mouseover="hoverButton"
      @mouseleave="leaveButton"
    >
      <span class="chat-main__count" v-if="threadMainMessage.thread.count_unread_messages">
        <span>{{ threadMainMessage.thread.count_unread_messages }}</span>
      </span>
      <button
        class="chat-main__down"
        @click="scrollBottom"
        :class="{ 'chat-main__down_show': showButton || scrollNewMessage }"
      >
        <i class="icon-down"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import MessageList from "@/modules/Messenger/components/Chat/MessageList/MessageList";
import FileUpload from "vue-upload-component";
export default {
  name: "ChatMainComment",
  mixins: [],
  data() {
    return {
      container: null,
      timerId: null,
      timerIdScroll: null,
      firstOpen: false,
      showButton: false
    };
  },
  components: { DropFileUpload, FileUpload, MessageList },
  mounted() {
    this.$nextTick(() => {
      this.container = document.querySelector("#list-comment");
      this.container.addEventListener("scroll", this.scrollListener);
    });
  },
  beforeDestroy() {
    this.container.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    ...mapActions("MessengerModule", ["setPasteFile", "getMessage", "readMessageWithScroll"]),
    hoverButton() {
      clearTimeout(this.timerId);
      this.showButton = true;
    },
    leaveButton() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.showButton = false;
      }, 1000);
    },
    handleUploadFile(event) {
      this.setPasteFile(event);
    },
    scrollListener() {
      this.showButton =
        this.container.scrollTop + this.container.offsetHeight < this.container.scrollHeight - 100;

      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.showButton = false;
      }, 1000);
      const show =
        this.container.scrollTop + this.container.offsetHeight < this.container.scrollHeight - 100;
      clearTimeout(this.timerIdScroll);
      this.timerIdScroll = setTimeout(() => {
        if (!show) {
          this.readMessageWithScroll({
            user_id: this.threadMainMessage.thread.id,
            count_unread_messages: this.threadMainMessage.thread.count_unread_messages,
            last_message_id: this.threadsMessages[this.threadsMessages.length - 1].id
          });
        }
      }, 300);
    },
    scrollBottom() {
      let behavior = "smooth";
      if (this.container.scrollHeight > this.container.scrollTop + 5000) behavior = "auto";
      this.container.scrollTo({
        top: this.container.scrollHeight,
        left: 0,
        behavior: behavior
      });
      this.readMessageWithScroll({
        user_id: this.threadMainMessage.thread.id,
        count_unread_messages: this.threadMainMessage.thread.count_unread_messages,
        last_message_id: this.threadsMessages[this.threadsMessages.length - 1].id
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "messages",
      "showModal",
      "showPin",
      "pinned_messages",
      "files",
      "threadsMessages",
      "threadMainMessage",
      "sortMessages",
      "threadMode",
      "scrollNewMessage",
      "showFileModal",
      "currentChat"
    ]),
    ...mapGetters("MessengerModule", ["getOldMessage"]),
    setMessage() {
      return this.showPin ? this.pinned_messages : this.messages;
    }
  },
  watch: {
    currentChat() {
      this.firstOpen = false;
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/Scroll.mixin.scss";
.chat-main {
  //max-height: calc(100vh);
  height: 100%;
  background-color: #edf4fe;
  display: flex;
  flex-flow: column;
  flex-shrink: 1;
  overflow: auto;
  //position: relative;
  &__button {
    position: sticky;
    bottom: 5%;
    left: 90%;
    display: flex;
    flex-flow: column;
  }
  &__count {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
    min-width: 18px;
    transform: translate(50%, 50%);
    font-weight: 400;
    align-self: flex-start;
    border-radius: 9px;
    background: $message-gradient;
    color: #fff;
  }
  &__down {
    all: unset;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 28px;
    height: 28px;
    background-color: #fff;

    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    &_show {
      opacity: 1;
      visibility: visible;
    }
    i {
      color: $icon-subdued;
      transition: color 0.3s ease;
      @media (hover: hover) {
        &:hover {
          color: $icon-hovered;
        }
      }
    }
  }
}
.list-container {
  margin-top: auto;
  &_blur {
    filter: blur(4px);
  }
}
</style>
