<template>
  <ul class="actions__filters filters">
    <FiltersItem v-for="item in filtersList" :key="item" :item="item" :valueFilter="valueFilter" @choseFilter="chosenItem" />
  </ul>
</template>

<script>
import FiltersItem from "@/modules/Tables/components/TablesCompany/Education/EducationWidgetsMobile/Filters/FiltersItem";
export default {
  name: "FiltersList",
  mixins: [],
  props: {
    filtersList: {
      type: Array,
      default: () => {
        return []
      }
    },
    valueFilter: {
      type: String,
      default: ''
    }
  },
  components: {FiltersItem},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    chosenItem(item) {
      this.$emit('choseFilter', item)
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";


.filters {
  list-style: none;
  margin: 0;
  padding: 8px;
}

</style>
