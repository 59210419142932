<template>
  <aside
    v-if="showSidebar"
    class="app-sidebar"
    id="sidebar"
    :style="isNewDesign ? 'border-radius: 0' : ''"
  >
    <router-link :to="'/'">
      <img
        class="app-sidebar__avatar"
        v-if="userInfo"
        :src="returnSrcFile(userInfo.avatar)"
        alt="avatar"
      />
      <LoaderAvatar v-else />
    </router-link>
    <ul class="app-sidebar__links">
      <li class="app-sidebar__item" @click="handleNotification">
        <LayoutSideNavLink :link="notificationItem" />
      </li>
      <li
        class="app-sidebar__item"
        v-for="item in menuList"
        :key="item.name"
        @click="closeNotification"
      >
        <router-link :to="`/${item.name}`">
          <LayoutSideNavLink :link="item" />
        </router-link>
      </li>
    </ul>
<!--    <DefaultSwitch :value="isDarkMode" @change="changeSwitch" />-->
    <ul class="app-sidebar__actions">
      <li class="app-sidebar__item" v-if="adminPanel">
        <router-link :to="`/admin`" target="_blank">
          <LayoutSideNavLink :link="adminItem" />
        </router-link>
      </li>
      <li class="app-sidebar__item" @click="openModal">
        <LayoutSideNavLink :link="helpItem" />
      </li>
      <li class="app-sidebar__item" @click="logout">
        <LayoutSideNavLink :link="exitItem" />
      </li>
    </ul>
  </aside>
</template>

<script>
import LayoutSideNavLink from "@/layout/SideNav/LayoutSideNavLink";
import FeedbackPanelNew from "@/components/FeedbackPanel/FeedbackPanelNew";

import { domain, domainStorage } from "@/globalVariables";
import LoaderAvatar from "@/layout/SideNav/LoaderAvatar";
import { mapActions, mapMutations, mapState } from "vuex";
import { menuList } from "@/router/moduleRouters";
import Auth from "@/store/Auth";
import DefaultSwitch from "@/aopV2/components/UIComponents/Input/Check/Switch/DefaultSwitch";

export default {
  name: "LayoutSideNav",
  mixins: [],
  props: {
    userInfo: {
      type: Object,
      required: true
    },
    isNewDesign: {
      type: Boolean,
      required: true
    }
  },
  components: {
    // DefaultSwitch,
    LoaderAvatar,
    FeedbackPanelNew,
    LayoutSideNavLink
  },
  data() {
    return {
      menuList: [],
      notificationItem: { label: "Уведомления", icon: "Bell" },
      helpItem: { label: "Обратная связь", icon: "Help" },
      exitItem: { label: "Выход", icon: "Exit" },
      loaderUserInfo: false,
      adminItem: { label: "Админ-панель", icon: "Admin" },
      userInfo: null,
      windowWidth: window.innerWidth
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.menuList = menuList.map((item) => item.menu);
    window.addEventListener("resize", this.resizeHandler);
  },
  methods: {
    ...mapActions("bugsAndIdeasModule", ["openModal"]),
    ...mapMutations("Notification", ["toggleModal"]),
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    changeSwitch() {
      this.$store.commit("MainLayoutSetting/toggleDarkMode");
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleWindow() {
      window.swUpdate = !window.swUpdate;
    },
    logout() {
      Auth.logout();
    },
    handleNotification() {
      this.toggleModal(!this.isShowNotificationModal);
      this.$store.dispatch("Notification/resetNotificationsAction");
    },
    closeNotification() {
      this.toggleModal(false);
    }
  },
  computed: {
    isDarkMode() {
      return this.$store.getters["MainLayoutSetting/isDarkMode"];
    },
    ...mapState("Notification", ["isShowNotificationModal"]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    adminPanel() {
      return this.$store.getters.getDataInfoUser.roles.find(
        (role) => role === "rights_admin" || role === "personnel_officer" || role === "admin"
      );
    },
    showSidebar() {
      return !(this.$route.query.chat_id && this.isMobileWindowSize);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.app-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  width: 100px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f1effa;
  border-radius: 0 16px 16px 0;
  flex-shrink: 0;
  overflow-y: auto;
  position: relative;
  z-index: 11;

  .router-link-active {
    /deep/ {
      .icon-bg {
        background: transparent;

        svg {
          path {
            fill: #5551f9;
          }

          rect {
            fill: #5551f9;
          }
        }
      }
    }
  }

  @media (max-width: $md) {
    position: fixed;
    flex-direction: unset;
    width: 100%;
    height: 70px;
    overflow: unset;
    overflow-x: auto;
    border-radius: 8px 8px 0 0;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  &__avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    vertical-align: middle;
    @media (max-width: $md) {
      width: 35px;
      height: 35px;
      margin-bottom: 0;
    }
    @media (max-width: $xxs) {
      width: 40px;
      height: 40px;
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    @media (max-width: $md) {
      width: fit-content;
      flex-direction: row;
    }
  }

  &__item {
    list-style: none;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0 0;
    padding: 0;
    @media (max-width: $md) {
      flex-direction: row;
      margin: 0;
      margin-left: auto;
    }
  }
}
</style>
