<template>
  <div class="status-component" v-click-outside="hideEditMode">
    <Label :label="'Статус'" :tooltip="setTooltip" />
    <StatusProblem :status="status" v-show="!isEdit" />
    <StatusProblem
      v-if="isEdit"
      :status="status"
      @input="handleInput"
      v-model="status"
      :isDropDown="true"
      :statusList="statusList"
    />
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import axios from "axios";
import StatusProblem from "@/modules/UiKit/components/statuses/StatusProblem";
import Label from "@/modules/Isp/components/IspProblem/Label";
import { mapState } from "vuex";

export default {
  name: "StatusComponent",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  components: { StatusProblem, Label },
  data() {
    return {
      status: {},
      statusList: [],
      tooltip: {
        ["initial"]: "Проблема без квартала или у неё нет плана решения",
        ["in_work"]: "Проблема в квартале, но не все задачи закрыты",
        ["final"]: "Проблема решена",
        ["canceled"]: "Проблема отклонена Менеджером ISP",
        ["check"]: "Закрыты все задачи и написан отчет по проблеме, ожидается решение Заказчика",
        ["rework"]: "Проблема дорабатывается после комментария Заказчика",
        ["done"]: "Проблема согласована с Заказчиком и ждет решения Совета ISP"
      },
      editMode: true,
      showTooltip: false
    };
  },
  created() {
    this.setList();
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleInput() {
      this.$emit("editStatus", this.status);
    },
    hideEditMode() {
      // this.editMode = false;
    },
    hideTooltip() {
      this.showTooltip = false;
    },
    setList() {
      this.statusList = this.user.available_flow_statuses;
      this.status = this.user.status;

      if (this.statusList) {
        if (this.status.type === "in_work") {
          this.statusList = this.statusList.filter((item) => item.type !== "check");
        }
        if (this.status.type === "check") {
          this.statusList = this.statusList.filter((item) => item.type !== "rework");
          this.statusList = this.statusList.filter((item) => item.type !== "done");
        }
        if (this.status.type === "rework") {
          this.statusList = this.statusList.filter((item) => item.type !== "check");
        }
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["statusesList"]),
    setTooltip() {
      return this.tooltip[this.status.type];
    }
  },
  watch: {
    user() {
      this.setList();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.status-component {
  display: flex;
  flex-direction: column;
  user-select: none;
  //width: fit-content;
  width: 110px;
  /deep/ {
    .label {
      margin-bottom: 12px;
      label {
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #343748;
      }
      &__tooltip {
        left: -60%;
      }
    }
  }
  .label {
    position: relative;
    display: flex;
    margin-bottom: 12px;
    width: max-content;
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #343748;
      align-items: center;
    }
    .icon-info {
      align-self: flex-start;
      margin-left: 8px;
      font-size: 14px;
      line-height: 12px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }
    &__tooltip {
      display: block;
      position: absolute;
      max-width: 530px;
      width: max-content;
      left: 100%;
      bottom: 100%;
      z-index: 2;
      padding: 4px 8px;
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      background: #343748;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      word-break: break-word;

      p {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
