<template>
  <div class="tooltip" @mouseover="setPositionTooltip">
    <i class="icon-info"></i>
    <p class="tooltip__content" ref="tooltip">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  mixins: [],
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {
      tooltipNode: null
    };
  },
  mounted() {
    this.tooltipNode = this.$refs.tooltip;
  },
  methods: {
    setPositionTooltip() {
      const subtractionRight = window.innerWidth - this.tooltipNode.getBoundingClientRect().right;
      if (window.innerWidth < 500) {
        this.tooltipNode.style.maxWidth = "250px";
      }
      if (subtractionRight < 0) {
        this.tooltipNode.style.left = "unset";
        let right = subtractionRight + this.tooltipNode.getBoundingClientRect().width - 30;
        this.tooltipNode.style.right = `${-right}px`;
        if (window.innerWidth - this.tooltipNode.getBoundingClientRect().right < 0) {
          this.tooltipNode.style.right = `${-(
            right +
            window.innerWidth -
            this.tooltipNode.getBoundingClientRect().right -
            30
          )}px`;
        }
      }
      if (
        this.tooltipNode.getBoundingClientRect().top -
          this.tooltipNode.getBoundingClientRect().height <
        0
      ) {
        this.tooltipNode.style.bottom = "unset";
        this.tooltipNode.style.top = "100%";
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.tooltip {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: fit-content;
  &:hover {
    .tooltip__content {
      visibility: visible;
      opacity: 1;
    }
    .icon-info {
      color: $icon-hovered;
    }
  }
  &__content {
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    max-width: 430px;
    width: max-content;
    left: 100%;
    bottom: 110%;
    z-index: 100;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
    text-align: left;
  }
}
.icon-info {
  align-self: flex-start;
  margin-left: 8px;
  font-size: 14px;
  line-height: 12px;
  color: $icon-subdued;

  &:hover {
    color: $icon-hovered;
  }

  &:active {
    color: $icon-pressed;
  }
}
</style>
