




















import { Component, Vue } from "vue-property-decorator";
import BasePicker from "@/aopV2/components/UIComponents/Picker/Day/BasePicker.vue";
import CalendarControl from "@/aopV2/components/UIComponents/Picker/CalendarControl.vue";
import MonthPicker from "@/aopV2/components/UIComponents/Picker/Month/CalendarPicker.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import moment from "moment";
import TabControl, { ITabItem } from "@/aopV2/components/UIComponents/TabControl/TabControl.vue";
import DropDown from "@/modules/UiKit/components/DropDown/DropDown.vue";
import DropDownList from "@/aopV2/components/UIComponents/DropDownList/DropDownList.vue";

@Component<CalendarControlPanel>({
  components: { DropDownList, DropDown, TabControl, MonthPicker, CalendarControl, BasePicker },
  watch: {
    date() {
      this.selectDate = this.date;
    },
    activeTab() {
      this.setDisabledTab();
    }
  }
})
export default class CalendarControlPanel extends Vue {
  type = "day";
  selectYear: string = moment(new Date()).format("YYYY");
  selectDate: string = dateFormat(new Date());
  tabs: Array<ITabItem> = [
    { type: "week", name: "Неделя", disabled: false },
    { type: "month", name: "Месяц", disabled: false }
  ];

  mounted() {
    if (this.date) {
      this.selectDate = this.date;
      this.setDisabledTab();
    }
  }

  handleInput(date) {
    if (date) this.updateDate(date);
  }

  handlePicker(data) {
    const [year, month, day] = this.selectDate.split("-");
    let date;
    if (data.type === "month") {
      const selectMonth = +data.date + 1 < 10 ? `0${+data.date + 1}` : +data.date + 1;
      date = `${this.selectYear}-${selectMonth}-${day}`;
      const isValid = moment(date).isValid();
      if (isValid) {
        this.selectDate = date;
      } else {
        const newDate = moment(`${this.selectYear}-${selectMonth}-01`).endOf("month");
        this.selectDate = dateFormat(newDate);
      }
      this.type = "day";
    } else {
      this.selectYear = data.date;
      this.type = "month";
    }
  }

  updateDate(date) {
    this.selectDate = date;
    CalendarModule2.setDate(dateFormat(date));
  }

  handleLeft() {
    this.selectDate = dateFormat(moment(this.selectDate).subtract(1, "month"));
  }

  handleRight() {
    this.selectDate = dateFormat(moment(this.selectDate).add(1, "month"));
  }

  pickerType(type) {
    this.type = type;
  }

  handleTab(type) {
    CalendarModule2.setType(type);
    this.$emit("updateType");
  }

  setDisabledTab() {
    const index = this.tabs.findIndex((item) => item.name === "Месяц");
    let newTab = this.tabs.find((item) => item.name === "Месяц");
    if (newTab) {
      if (this.activeTab === "Person" || this.activeTab === "Events") {
        newTab.disabled = false;
        Vue.set(this.tabs, index, newTab);
      } else {
        newTab.disabled = true;
        Vue.set(this.tabs, index, newTab);
      }
    }
  }

  get date() {
    return CalendarModule2.date;
  }

  get typeGrid() {
    return CalendarModule2.type;
  }
  get activeTab() {
    return this.$store.getters["MainLayoutSetting/activeTab"];
  }
}
