






import { Component, Prop, Vue } from "vue-property-decorator";
import { ISyncProject } from "@/modules/TimeTracker/interface/interface";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import TimeTracker from "@/modules/TimeTracker/store";

@Component({
  components: { ProjectTag }
})
export default class SyncProject extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  project!: ISyncProject;

  syncManager() {
    TimeTracker.syncRequest({ provider_identity: this.project.provider_identity });
  }
}
