<template>
  <div class="news-create" @mousedown.self="confirmCloseCurtain">
    <ConfirmModal
      v-if="confirmCloseModal"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="confirmCloseModal = false"
      @accept="closeCurtain"
    />
    <div class="news-create__container">
      <div class="news-create__header">
        <button
          v-if="isMobileWindowSize"
          class="news-create__hide"
          type="button"
          @click="confirmCloseCurtain"
        >
          <i class="icon-arrow-short"></i>
        </button>
        <h2 class="news-create__title-news">Создать новость</h2>
      </div>

      <!--      ////////-->
      <!--      <div class="modal__header">-->
      <!--        <button-->
      <!--          v-if="isMobileWindowSize"-->
      <!--          class="modal__hide"-->
      <!--          type="button"-->
      <!--          @click="hideModal(false)"-->
      <!--        >-->
      <!--          <i class="icon-arrow-short"></i>-->
      <!--        </button>-->
      <!--        <h2 class="modal__title">{{ setTitle }}</h2>-->
      <!--      </div>-->
      <!--      ////////////-->

      <div class="news-create__body">
        <div class="news-create__title">
          <Input
            label="Заголовок"
            :placeholder="'Введите заголовок'"
            v-model.trim="editTitle"
            :maxlength="128"
          />
        </div>
        <div class="news-create__content">
          <Textarea
            label="Содержание"
            :sizeControl="120"
            :placeholder="'Максимум 10000 символов'"
            :maxlength="10000"
            v-model.trim="editContent"
          />
          <div class="news-create__attachments">
            <i class="icon-emoji" @click.stop="showModalEmoji = !showModalEmoji"></i>
            <file-upload
              :accept="'.jpg, .jpeg, .png'"
              post-action="/upload/post"
              :multiple="true"
              v-model="images"
              class="news-create__images"
              :class="{ 'news-create__images-active': images.length }"
              input-id="newsImages"
              name="newsImages"
            >
              <i class="icon-add-photo"></i>
            </file-upload>
            <file-upload
              :accept="'.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
              post-action="/upload/post"
              ref="upload"
              :multiple="true"
              v-model="allFiles"
              class="news-create__files"
              :class="{ 'news-create__files-active': allFiles.length }"
              input-id="newsFiles"
              name="newsFiles"
            >
              <i class="icon-add-file"></i>
            </file-upload>
            <i
              class="icon-schedule-add"
              @click="setQuizMode"
              :class="{ 'icon-schedule-add-active': addQuiz }"
            ></i>
            <Picker
              v-if="showModalEmoji"
              set="apple"
              @select="addEmoji"
              :i18n="settings"
              class="news-create__emojis"
              v-click-outside="closeModalEmojis"
            />
          </div>
          <NewsImageGrid
            :images="imagesLink"
            :editMode="true"
            class="news-create__images-grid"
            @news_grid_delete_item="newsGridDeleteItem"
          />
          <FilesComponent
            :files="allFiles"
            :showCross="true"
            @files_splice="filesSplice"
            :editFiles="true"
          />
          <slide-up-down :active="addQuiz">
            <div class="news-create__quiz quiz">
              <div class="quiz__title">
                <div class="quiz__title-label">
                  <p>Название опроса</p>
                  <i class="icon-cross" @click="closeQuizMode"></i>
                </div>
                <Input
                  :placeholder="'Название опроса'"
                  :classList="'font16'"
                  v-model.trim="editQuizTitle"
                  :maxlength="128"
                />
              </div>
              <div class="quiz__items">
                <div class="quiz__item" v-for="(item, idx) in editQuizAnswers" :key="idx">
                  <div class="quiz__item-wrap">
                    <div class="quiz__item-header">
                      <p>Вариант ответа {{ idx + 1 }}</p>
                      <div class="quiz__item-control">
                        <i
                          class="icon-copy"
                          v-if="isAddQuizAnswer"
                          @click="addQuizAnswer(item.content)"
                        ></i>
                        <i
                          class="icon-delete"
                          v-if="isDeleteQuizAnswer"
                          @click="editQuizAnswers.splice(idx, 1)"
                        ></i>
                      </div>
                    </div>

                    <Input
                      :placeholder="'Введите вариант ответа'"
                      :classList="'font13'"
                      :maxlength="128"
                      v-model.trim="editQuizAnswers[idx].content"
                    />
                  </div>
                </div>
                <div class="quiz__add-answer" v-if="isAddQuizAnswer" @click="addQuizAnswer('')">
                  <span> <i class="icon-plus"></i>Добавить вариант</span>
                </div>
              </div>
              <div class="quiz__settings">
                <div class="quiz__settings-label">Дата и время завершения опроса</div>
                <div class="quiz__end-date-time">
                  <DateTimePicker v-model="editQuizDate" />
                </div>
              </div>
            </div>
          </slide-up-down>
        </div>
        <div class="news-create__channel">
          <p>Канал</p>
          <Select
            :items="availableChannelList"
            :placeholder="'Не выбрано'"
            :showAvatar="false"
            :multiple="false"
            v-model="editChannels"
          />
        </div>
      </div>
      <div class="news-create__footer">
        <Button :title="'Отменить'" :type="'cancel'" @handleButton="confirmCloseCurtain" />
        <Button
          :title="'Создать'"
          :type="loading ? 'disabled' : 'default'"
          :loader="loading"
          @handleButton="postNewsHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/modules/News/store/mutations/mutationTypes";
import { mapActions, mapMutations, mapState } from "vuex";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import Select from "@/modules/UiKit/components/Select/Select";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import NewsImageGrid from "@/modules/News/components/NewsItemsComponents/NewsComponent/NewsImageGrid";
import FilesComponent from "@/modules/News/components/NewsItemsComponents/FilesComponent";
const FileUpload = () => import("vue-upload-component");
import SlideUpDown from "vue-slide-up-down";
const DatePick = () => import("vue-date-pick");
import "vue-date-pick/dist/vueDatePick.css";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import Button from "@/modules/UiKit/components/buttons/Button";
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import DateTimePicker from "@/modules/News/components/NewsCreateAndEdit/NewsCreateAndEditComponent/DateTimePicker";
import moment from "moment";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
export default {
  name: "CurtainNewsCreate",
  mixins: [uploadFilesMixin],
  props: {},
  components: {
    ConfirmModal,
    Button,
    Select,
    Input,
    Textarea,
    FileUpload,
    NewsImageGrid,
    FilesComponent,
    SlideUpDown,
    Picker,
    DateTimePicker
  },
  data() {
    return {
      editTitle: "",
      editContent: "",
      editQuizTitle: "",
      editQuizAnswers: [{ content: "" }, { content: "" }],
      editQuizAnonymous: false,
      count: 0,
      loadImage: false,
      editChannels: [],
      editQuizTime: null,
      tempQuizTime: null,
      editQuizDate: null,
      addQuiz: false,
      quizId: null,
      isSetTime: false,
      allFiles: [],
      images: [],
      files: [],
      oldFilesId: [],
      imagesLink: [],
      openChannel: false,
      loading: false,
      showModalEmoji: false,
      confirmCloseModal: false,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      },
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", this.showArrowHide);
  },
  methods: {
    ...mapActions("NewsModule", [
      "openFullSizeImage",
      "postNewsAction",
      "editNewsAction",
      "setNewsToUpdate"
    ]),
    ...mapMutations("NewsModule", [types.TOGGLE_SHOW_CREATE_CURTAIN]),
    closeModalEmojis() {
      this.showModalEmoji = false;
    },
    confirmCloseCurtain() {
      if (
        this.editTitle.length ||
        this.editContent.length ||
        this.allFiles.length ||
        this.images.length ||
        this.editQuizTitle.length ||
        this.editQuizDate ||
        this.editQuizAnswers.some((item) => item.content.length) ||
        this.editChannels.length
      ) {
        this.confirmCloseModal = true;
      } else {
        this.closeCurtain();
      }
    },
    closeCurtain() {
      this[types.TOGGLE_SHOW_CREATE_CURTAIN](false);
    },
    isDateDisabled(date) {
      const currentDate = new Date();
      return date < currentDate.setHours(0, 0, 0, 0) || date === currentDate.setHours(0, 0, 0, 0);
    },
    filesSplice(val) {
      this.allFiles.splice(val, 1);
    },
    newsGridDeleteItem(val) {
      this.imagesLink.splice(val, 1);
      this.images.splice(val, 1);
    },
    // Контроллер запросов
    openImageModal(link) {
      this.openFullSizeImage(link);
    },
    postNewsHandler() {
      this.files = [];
      this.files.push(this.allFiles, this.imagesLink);
      this.files = this.files.flat();
      if (!this.checkValidation()) return false;
      this.loading = true;
      this.$emit("setNewsLoading");
      this.fileUploader = [];
      this.oldFilesId = [];
      this.files.forEach((item) => {
        if (item.fileObject) {
          this.fileUploader.push(item);
        } else {
          this.oldFilesId.push(item.id);
        }
      });
      if (this.fileUploader.length && !this.fileUploadingId.length) {
        this.createPull(this.fileUploader);
        return false;
      }
      this.postNews();
    },
    showArrowHide() {
      this.windowWidth = window.innerWidth;
    },
    // Когда все файлы загружены
    filesUploaded() {
      this.postNewsHandler();
    },
    // Отмена добавления опроса
    closeQuizMode() {
      this.addQuiz = false;
      this.quizId = null;
      this.editQuizAnswers = [{ content: "" }, { content: "" }];
      this.editQuizTitle = "";
      this.editQuizTime = null;
      this.editQuizDate = null;
      this.isSetTime = false;
    },

    // Добавление опроса
    setQuizMode() {
      if (this.addQuiz) {
        this.closeQuizMode();
      } else {
        this.addQuiz = true;
        this.isSetTime = false;
      }
    },

    // Добавление ответа на опрос
    addQuizAnswer(text) {
      this.editQuizAnswers.push({ content: text });
    },

    // Редактирование новости
    postNews() {
      const data = {
        name: this.editTitle,
        content: this.editContent ? this.editContent : undefined,
        channel_id: this.editChannels[0].id,
        poll_id: this.quizId,
        attachment_files: this.oldFilesId.concat(this.fileUploadingId)
      };
      if (this.addQuiz) {
        if (this.editQuizDate !== null) {
          this.editQuizDate = moment(this.editQuizDate).format("YYYY-MM-DD HH:mm:ss");
        }
        data.poll = {
          name: this.editQuizTitle,
          is_anonymous: false,
          end_datetime: this.editQuizDate,
          answers: this.editQuizAnswers.map((item) => {
            return item.content;
          })
        };
      }
      this.postNewsAction(data)
        .then(() => {
          this.$emit("closeParentCreateMode");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Новость успешно создана.`
          });
          this.$emit("closeNewsCreateMode");
          this.$emit("closeNewsLoading");
          this.loading = false;
          this.closeCurtain();
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("closeNewsLoading");
          this.errorAlert(error);
        });
    },

    // Проверка валидации
    checkValidation() {
      if (this.editQuizDate !== null) {
        if (new Date() > new Date(this.editQuizDate)) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя создать опрос на прошедшее время`
          });
          return false;
        }
      }
      if (!this.editTitle.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите заголовок новости!`
        });
        return false;
      }
      if (!this.editContent.length && !this.addQuiz) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Введите содержание новости!`
        });
        return false;
      }
      if (isObjectEmptyChecker(this.editChannels[0])) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Выберите канал!`
        });
        return false;
      }

      // Проверка общего размера пула файлов
      if (this.allFiles.length) {
        let sumSize = 0;
        this.allFiles.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 104857600) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 100мб!`
          });
          return false;
        }
      }
      if (this.files.length > 10) {
        this.files = this.files.slice(0, 10);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 2000,
          label: `Нельзя прикрепить более 10 файлов!`
        });
        return false;
      }
      if (this.images.length) {
        let sumSize = 0;
        this.images.forEach((item) => {
          sumSize += item.size;
        });
        if (sumSize >= 104857600) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя загрузить файлы общим объемом более 100мб!`
          });
          return false;
        }
      }

      if (this.addQuiz) {
        if (!this.editQuizTitle.length) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Введите тему опроса!`
          });
          return false;
        }

        // Проверка на заполненность вариантов ответов опроса
        let quizCheck = true;
        this.editQuizAnswers.forEach((item) => {
          if (!item.content.length) {
            quizCheck = false;
          }
        });
        if (!quizCheck) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Заполните все варианты ответа!`
          });
          return false;
        }
      }

      return true;
    },
    addEmoji(emoji) {
      let cursorPosition = document.querySelector(".textarea__control").selectionStart;
      let FirstPart = "";
      if (cursorPosition) {
        FirstPart = this.editContent.substring(0, cursorPosition);
      }
      let Emoji = emoji.native;
      let SecondPart = this.editContent.substring(cursorPosition + 1, this.editContent.length);
      this.editContent = FirstPart + Emoji + SecondPart;
      this.$emit("changeText", { value: this.editContent, index: this.index });
    }
  },
  computed: {
    ...mapState("NewsModule", ["channelList"]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isAddQuizAnswer() {
      return this.editQuizAnswers.length < 10;
    },
    isDeleteQuizAnswer() {
      return this.editQuizAnswers.length > 2;
    },
    showHeader() {
      return window.innerWidth <= 767;
    },
    isAdmin() {
      return this.getDataInfoUser.roles.includes("admin");
    },
    availableChannelList() {
      return this.channelList.filter((item) => {
        if (this.isAdmin) {
          return item;
        } else {
          if (item.is_public && item.can_post) {
            return item;
          } else if (!item.is_public && item.can_post && item.current_channel_user) {
            return item;
          } else if (item.current_channel_user.is_admin) {
            return item;
          }
        }
      });
    }
  },
  watch: {
    images() {
      let imagesLink = [];
      let link = "";
      this.images.forEach((file) => {
        if (file.hasOwnProperty("link")) {
          link = file.link;
          imagesLink.push({ link, ...file });
        } else {
          let reader = new FileReader();
          reader.onload = (e) => {
            link = e.target.result;
            imagesLink.push({ link, ...file });
          };
          reader.readAsDataURL(file.file);
        }
      });
      if (this.images.length > 3) {
        this.images = this.images.slice(0, 3);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Нельзя прикрепить более 3 изображений или видео-файлов!`
        });
      }
      this.imagesLink = imagesLink;
    },
    allFiles() {
      let count = 0;
      let videos = [];
      let otherFiles = [];
      let allFiles = [];
      if (this.allFiles.length && this.allFiles.length > 10) {
        this.allFiles = this.allFiles.slice(0, 10);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 2000,
          label: `Нельзя прикрепить более 10 файлов!`
        });
      }
      this.allFiles.forEach((file) => {
        if (
          file.type === "video/quicktime" ||
          file.type === "video/mp4" ||
          file.type === "video/3gpp" ||
          file.type === "video/x-msvideo" ||
          file.type === "video/x-flv" ||
          file.type === "video/x-ms-wmv" ||
          file.type === "video/mpeg" ||
          file.type === "audio/mpeg" ||
          file.extension === "video/quicktime" ||
          file.extension === "video/mp4" ||
          file.extension === "video/3gpp" ||
          file.extension === "video/x-msvideo" ||
          file.extension === "video/x-flv" ||
          file.extension === "video/x-ms-wmv" ||
          file.extension === "audio/mpeg" ||
          file.extension === "video/mpeg"
        ) {
          videos.push(file);
        } else {
          otherFiles.push(file);
        }
      });
      if (videos.length > 3) {
        videos = videos.slice(0, 3);
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Нельзя прикрепить более 3 изображений или видео-файлов!`
        });
        count++;
      }
      allFiles.push(videos, otherFiles);
      allFiles = allFiles.flat();
      if (count) this.allFiles = allFiles;
    }
  },
  directives: {
    ClickOutside
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}
.news-create {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  &__container {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 711px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    margin: 0 0 32px;
  }
  &__title {
    margin: 0 0 32px;
  }
  &__title-news {
    @media (max-width: $sm) {
      font-size: 25px;
    }
  }
  &__hide {
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }

  &__body {
    margin: 0 0 32px;

    flex: 1 0 auto;
    /deep/ {
      input {
        width: 100%;
      }
      textarea {
        width: 100%;
      }
    }
  }

  &__images-grid {
    margin-top: 36px;
    margin-bottom: 32px;
  }
  &__attachments {
    position: relative;
    padding: 0 8px;
    margin-bottom: 16px;
    i {
      font-size: 20px;
      color: $icon-subdued;
      padding: 12px;
      cursor: pointer;
      transition: 0.2s all ease;
      &:hover {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }
  }
  .icon-schedule-add-active {
    color: #5551f9;
    filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
      drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
      drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
  }
  &__emojis {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 100;
  }
  &__images {
    transform: translate(2px, 4px);
    &:hover .icon-add-photo {
      color: #5551f9;
      filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
        drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
        drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
    }
    &-active {
      .icon-add-photo {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }
  &__files {
    transform: translate(4px, 4px);
    &:hover .icon-add-file {
      color: #5551f9;
      filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
        drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
        drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
    }
    &-active {
      .icon-add-file {
        color: #5551f9;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }
  .quiz {
    &__title {
      font-size: 16px;
      color: #756f86;
      margin-bottom: 24px;
      font-weight: 500;
      &-label {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
      }
      i {
        font-size: 24px;
        color: $icon-subdued;
        cursor: pointer;
        transition: 0.2s all ease;
        margin-left: auto;
        &:hover {
          color: #5551f9;
          filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
            drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
            drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
        }
      }
    }

    &__item {
      margin-bottom: 24px;
      &-control {
        margin-left: auto;
        i {
          transition: 0.2s all ease;
          &:hover {
            color: #5551f9;
            filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
              drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
              drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
          }
        }
      }
    }
    &__item-header {
      color: #756f86;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 21px;
      i {
        font-size: 24px;
        color: $icon-subdued;
        cursor: pointer;
      }
      .icon-copy {
        margin-left: auto;
      }
      .icon-delete {
        margin-left: 16px;
      }
    }
    &__add-answer {
      font-size: 14px;
      color: $chat-editor;
      font-weight: 500;
      margin-bottom: 24px;
      cursor: pointer;
      i {
        margin-right: 8px;
      }
    }
    &__settings {
      margin-bottom: 56px;
      &-label {
        font-size: 16px;
        color: #756f86;
        margin-bottom: 24px;
        font-weight: 500;
      }
    }
  }
  &__footer {
    flex: 0 0 auto;

    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__channel {
    p {
      font-size: 18px;
      margin-bottom: 24px;
      color: $text-default;
    }
  }
}
</style>
