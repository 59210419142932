<template>
  <div class="members-container">
    <div class="members-container__header">
      <TabsContent :tabs="tabsList" v-model="currentTab" />
    </div>
    <div class="members-container__body">
      <div class="members-container__members" v-if="isMembers">
        <CustomerComponent
          :user="problem"
          @editCustomer="handleEditCustomer"
          :isEdit="isEditCustomer"
        />
        <ResponsibleComponent
          :user="problem"
          @editResponsible="handleEditResponsible"
          :isEdit="isEditResponsible"
        />
        <ExecutorsComponent
          :user="problem.task_executors"
          label="ИСПОЛНИТЕЛИ"
          :isProblemPage="true"
        />
      </div>
      <div class="members-container__observers" v-else>
        <ExecutorsComponent :user="problem.viewer_users" label="" :isProblemPage="true" />
      </div>
    </div>
  </div>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import CustomerComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/CustomerComponent";
import ResponsibleComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/ResponsibleComponent";
import ExecutorsComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/members/ExecutorsComponent";
import { mapActions, mapState } from "vuex";
export default {
  name: "MembersContainer",
  mixins: [],
  props: {
    problem: {
      type: Object,
      default: null
    }
  },
  components: { ExecutorsComponent, ResponsibleComponent, CustomerComponent, TabsContent },
  data() {
    return {
      currentTab: "members",
      tabsList: [
        {
          label: "Участники",
          value: "members"
        },
        {
          label: "Наблюдатели",
          value: "observers"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["handleUpdateProblem"]),
    handleEditCustomer(customer_user) {
      this.handleUpdateProblem({
        data: {
          customer_user_id: customer_user.id,
          customer_user
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    },
    handleEditResponsible(responsible_user) {
      this.handleUpdateProblem({
        data: {
          responsible_user_id: responsible_user.id,
          responsible_user
        },
        oldPeriodId: this.currentInfoProblem.period_id
      });
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "filesLoader"]),
    isMembers() {
      return this.currentTab === "members";
    },
    isEditCustomer() {
      return (
        ((this.isGroupLeader && this.problem.status.type === "initial") || this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isEditableStatus() {
      return (
        this.currentInfoProblem.status.type !== "done" &&
        this.currentInfoProblem.status.type !== "final"
      );
    },
    isArchived() {
      return this.currentInfoProblem.is_archived;
    },
    isEditResponsible() {
      return (this.isGroupLeader || this.isISPManager) && !this.isArchived && this.isEditableStatus;
    },
    isGroupLeader() {
      return this.problem.group.leader_user_id === this.getUserId;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.members-container {
  width: 360px;
  height: 100%;
  flex-shrink: 0;
  /deep/ {
    .select-item__avatar {
      width: 40px;
      height: 40px;
    }
  }
  /deep/ {
    .label {
      &__tooltip {
        left: -10%;
        max-width: 280px;
        right: auto;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eae8f0;
    user-select: none;
  }
  &__body {
    display: flex;
    height: calc(100% - 70px);
    overflow-y: auto;
    padding-left: 32px;
    padding-right: 16px;
    padding-top: 16px;
  }
  &__members {
    display: flex;
    width: 100%;

    flex-flow: column;
  }
}
</style>
