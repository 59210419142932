<template>
  <li class="found-project" @click="goToProject" :class="{ 'found-project_active': isActive }">
    <p>{{ foundProject.name }}</p>
  </li>
</template>

<script>
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "FoundProject",
  mixins: [],
  props: {
    foundProject: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToProject() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.SET_ACTIVE_FOUND_ITEM]({
        type: "project",
        id: this.foundProject.id
      });
      this.$router.push({
        name: "Project",
        params: { id: this.foundProject.id }
      });
    }
  },
  computed: {
    currentProject() {
      return ProjectModule.currentProject?.projectFull;
    },
    isActive() {
      return (
        TaskManagerModuleV2.activeFoundItem?.type === "project" &&
        TaskManagerModuleV2.activeFoundItem?.id === this.foundProject.id
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.found-project {
  font-size: 14px;
  padding: 12px 0;
  word-break: break-word;
  cursor: pointer;
  &_active {
    color: $link-default;
  }
}
</style>
