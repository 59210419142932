





















import { Component, Prop, Vue } from "vue-property-decorator";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import moment from "moment";

@Component<BasePicker>({
  watch: {
    date() {
      this.moveCalendar();
    }
  }
})
export default class BasePicker extends Vue {
  @Prop({
    default: moment(new Date()).format("YYYY-MM-DD"),
    type: String
  })
  value!: string;
  @Prop({
    default: moment(new Date()).format("YYYY-MM-DD"),
    type: String
  })
  date!: Date;
  @Prop({
    default: true,
    type: Boolean
  })
  hiddenControl!: boolean;
  @Prop({ type: Date, default: null }) minDate!: string | Date;

  fromDate: Date | string = new Date();
  selectDate: Date | string = new Date();
  masks = {
    weekdays: "WWW"
  };

  moveCalendar() {
    const calendar = this.$refs.calendar;
    if (this.date) {
      // @ts-ignore
      calendar.move(new Date(this.date));
      this.selectDate = this.value;
    }
  }

  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.selectDate = this.value;
        this.fromDate = new Date(this.date);
      }
    });
  }
}
