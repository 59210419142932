<template>
  <div class="my-isp__filters">
    <ChosenFilter
      v-model="groupValue"
      :icon="'group'"
      :placeholder="'Команды'"
      :search="true"
      :allValue="'Все команды'"
      :chosenList="groupsISP"
      @updateInfo="updateTableInfo"
    />
    <ChosenFilter
      v-model="rolesValue"
      :chosenList="rolesList"
      :icon="'clipboard-ind'"
      :allValue="'Все роли'"
      :placeholder="'Роли'"
      @updateInfo="updateTableInfo"
    />
    <ChosenFilter
      v-model="criticalityValue"
      :chosenList="criticalityList"
      :icon="'error-secondary'"
      :placeholder="'Критичность'"
      :multiple="true"
      @updateInfo="updateTableInfo"
    />
    <ChosenFilter
      v-model="statusValue"
      :chosenList="statusesList"
      :icon="'circle-check'"
      :placeholder="'Статус'"
      :multiple="true"
      @updateInfo="updateTableInfo"
    />
    <div class="my-isp__filters__position-right">
      <ChosenFilter
        v-model="customersValue"
        :icon="'user'"
        :placeholder="'Заказчик'"
        :multiple="true"
        :search="true"
        :chosenList="usersList"
        @updateInfo="updateTableInfo"
      />
    </div>
    <div class="my-isp__filters__position-right">
      <ChosenFilter
        v-model="responsibleValue"
        :icon="'responsible'"
        :placeholder="'Ответственный'"
        :multiple="true"
        :search="true"
        :modifier="'responsible'"
        :chosenList="usersList"
        @updateInfo="updateTableInfo"
      />
    </div>
  </div>
</template>

<script>
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "IspMyFilters",
  mixins: [],
  props: [],
  components: { ChosenFilter },
  data() {
    return {
      criticalityValue: [],
      statusValue: [],
      rolesValue: [],
      groupValue: [],
      customersValue: [],
      responsibleValue: []
    };
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    // this.getStatuses();
    // this.getCriticality();
    this.getIspGroup();
    this.getUsers();
  },
  methods: {
    ...mapActions("IspModule", [
      "getStatuses",
      "getCriticality",
      "getIspGroup",
      "getUsers",
      "getMyIspProblemsList",
      "getMyIspProblemsListWithFilters"
    ]),
    ...mapMutations("IspModule", [
      "SET_MY_ISP_PROBLEM_PARAMS",
      "CLEAR_TABLE_DATA",
      "IS_MY_ISP_FILTERS"
    ]),
    changeFilter() {
      this.CLEAR_TABLE_DATA();
      this.SET_MY_ISP_PROBLEM_PARAMS(this.myIspParams);
    },
    updateTableInfo() {
      this.getMyIspProblemsList();
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("my-isp-table-setting"));
      this.criticalityValue = setting.criticality ? setting.criticality : [];
      this.statusValue = setting.status ? setting.status : [];
      this.rolesValue = setting.roles ? setting.roles : [];
      this.groupValue = setting.groups ? setting.groups : [];
      this.customersValue = setting.customer ? setting.customer : [];
      this.responsibleValue = setting.responsible ? setting.responsible : [];
    },
    setCookieValue() {
      const option = {
        criticality: this.criticalityValue,
        status: this.statusValue,
        roles: this.rolesValue,
        groups: this.groupValue,
        customer: this.customersValue,
        responsible: this.responsibleValue
      };
      window.localStorage.setItem("my-isp-table-setting", JSON.stringify(option));
    }
  },
  computed: {
    ...mapState("IspModule", [
      "criticalityList",
      "statusesList",
      "groupsISP",
      "usersList",
      "valueSort",
      "rolesList",
      "isMyIspFilters"
    ]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isFilters() {
      this.IS_MY_ISP_FILTERS(
        !!(
          this.rolesValue.length ||
          this.criticalityValue.length ||
          this.statusValue.length ||
          this.groupValue.length ||
          this.responsibleValue.length ||
          this.customersValue.length
        )
      );
      return this.isMyIspFilters;
    },
    myIspParams() {
      return {
        _count: 300,
        _order: JSON.stringify({
          [this.valueSort.value]: this.valueSort.isAsc ? "asc" : "desc"
        }),
        flow_status_id: this.statusValue.length
          ? JSON.stringify(this.statusValue.map((item) => item.id))
          : undefined,
        group_id: this.groupValue.length
          ? JSON.stringify(this.groupValue.map((item) => item.id))
          : undefined,
        criticality_id: this.criticalityValue.length
          ? JSON.stringify(this.criticalityValue.map((item) => item.id))
          : undefined,
        responsible_user_id: this.setResponsibleUsers,
        creator_user_id:
          this.rolesValue.length && this.rolesValue.find((item) => item.name === "Автор")
            ? JSON.stringify([this.getUserId])
            : undefined,
        customer_user_id: this.setCustomerUsers,
        task_executors:
          this.rolesValue.length && this.rolesValue.find((item) => item.name === "Исполнитель")
            ? JSON.stringify({ executor_user_id: this.getUserId })
            : undefined,
        viewer_users:
          this.rolesValue.length && this.rolesValue.find((item) => item.name === "Наблюдатель")
            ? JSON.stringify({ viewer_user_id: this.getUserId })
            : undefined,
        _with: JSON.stringify([
          "group",
          "responsible_user",
          "customer_user",
          "creator",
          "status",
          "task_executors"
        ])
      };
    },
    setResponsibleUsers() {
      if (
        this.rolesValue.length &&
        this.rolesValue.find((item) => item.name === "Ответственный") &&
        this.responsibleValue.length
      ) {
        return JSON.stringify([this.getUserId, ...this.responsibleValue.map((item) => item.id)]);
      } else if (
        this.rolesValue.length &&
        this.rolesValue.find((item) => item.name === "Ответственный")
      ) {
        return JSON.stringify([this.getUserId]);
      } else if (this.responsibleValue.length) {
        return JSON.stringify(this.responsibleValue.map((item) => item.id));
      } else {
        return undefined;
      }
    },
    setCustomerUsers() {
      if (
        this.rolesValue.length &&
        this.rolesValue.find((item) => item.name === "Заказчик") &&
        this.customersValue.length
      ) {
        return JSON.stringify([this.getUserId, ...this.customersValue.map((item) => item.id)]);
      } else if (
        this.rolesValue.length &&
        this.rolesValue.find((item) => item.name === "Заказчик")
      ) {
        return JSON.stringify([this.getUserId]);
      } else if (this.customersValue.length) {
        return JSON.stringify(this.customersValue.map((item) => item.id));
      } else {
        return undefined;
      }
    }
  },
  watch: {
    criticalityValue() {
      this.changeFilter();
    },
    statusValue() {
      this.changeFilter();
    },
    rolesValue() {
      this.changeFilter();
    },
    groupValue() {
      this.changeFilter();
    },
    customersValue() {
      this.changeFilter();
    },
    responsibleValue() {
      this.changeFilter();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.my-isp {
  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @media (max-width: 400px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      justify-items: center;
    }
    &__position-right {
      /deep/ .chosen-filter__list {
        left: unset;
        right: 0;
      }
    }
    .select-bg {
      width: max-content;
    }

    /deep/ {
      .chosen-filter {
        &_responsible {
          .chosen-filter__list {
            right: 0;
            left: unset;
          }

          .chosen-filter__label {
            margin-right: 0;
          }
        }
        @media (max-width: 400px) {
          height: auto;
        }
        &:nth-child(3) {
          &:after {
            content: "";
            width: 100%;
          }
        }

        &__label {
          @media (max-width: $sm) {
            margin-right: 8px;
          }
          @media (max-width: 400px) {
            margin-right: 0;
          }
          span {
            @media (max-width: $lg) {
              display: none;
            }
          }
          i {
            @media (max-width: 400px) {
              font-size: 25px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
