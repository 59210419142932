<template>
  <div class="history-item" :id="setId">
    <div class="history-item__header">
      <div class="history-item__type">
        <img :src="require('@/modules/Isp/assets/IspProblem/task.svg')" alt="" />
      </div>
      <User
        v-if="setAuthor.id"
        :avatar="setAuthor.avatar"
        :name="setAuthor.full_name"
        :id="setAuthor.id"
        :showInfo="true"
        :info="setRole"
        :size="'36px'"
        :show-tooltip="false"
        class="editable-user__user"
      />
    </div>
    <div class="history-item__body">
      <p class="history-item__content">
        {{ historyItem.description }}
      </p>
      <div class="history-item__date">
        {{ setDateFormatWithoutLetterT(historyItem.created_at) }}
        {{ setTimeFormatWithoutLetterT(historyItem.created_at) }}
      </div>
    </div>
  </div>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";
import { mapGetters } from "vuex";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";

export default {
  name: "HistoryTaskItem",
  mixins: [formatDateAndTime],
  props: {
    historyItem: {
      type: Object,
      default: null
    },
    listData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    }
  },
  components: { User },
  data() {
    return {
      target: null
    };
  },
  beforeDestroy() {
    TaskModule[TaskMutationTypes.RESET_HISTORY_TASK]();
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#history-item-${this.historyItem.id}`);
    const taskList = JSON.parse(JSON.stringify(this.listData)).reverse();
    if (taskList[1]?.id === this.historyItem.id) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.totalCount !== this.listData.length) {
              this.paginationEvent({
                page: this.page,
                id: this.currentTask.id
              });
              this.observer.unobserve(this.target);
            }
          }
        });
      });
      this.observer.observe(this.target);
    }
  },
  methods: {
    paginationEvent(params) {
      this.$emit("paginationEvent", params);
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.historyItem.author_id);
    },
    setRole() {
      return this.setAuthor
        ? this.setAuthor.roles.length
          ? `[${this.setAuthor.roles[0].name}]`
          : ""
        : "";
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    setId() {
      return `history-item-${this.historyItem.id}`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.history-item {
  padding: 0 0 50px 44px;
  position: relative;
  border-left: 2px solid #eae8f0;

  @media (max-width: $sm) {
    padding: 0 0 50px 24px;
  }

  &:last-child {
    border-left: 2px solid white;
  }

  &__type {
    top: 0;
    left: -13px;
    position: absolute;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    /deep/ {
      .user {
        @media (max-width: $xl) {
          align-items: flex-start;
        }
      }

      .user__avatar {
        margin-right: 8px;
      }

      .user__main-info {
        display: flex;

        @media (max-width: $xl) {
          flex-wrap: wrap;
        }
      }

      .user__name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: max-content;
        margin-right: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #343748;

        @media (max-width: $xl) {
          max-width: 780px;
        }

        @media (max-width: $lg) {
          max-width: 500px;
        }

        @media (max-width: $md) {
          max-width: 400px;
        }

        @media (max-width: $sm) {
          max-width: 260px;
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: $xxs) {
          max-width: 200px;
        }
      }

      .user__description {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        width: max-content;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #a7a9c0;

        @media (max-width: $xl) {
          max-width: 780px;
        }

        @media (max-width: $lg) {
          max-width: 500px;
        }

        @media (max-width: $md) {
          max-width: 400px;
        }

        @media (max-width: $sm) {
          max-width: 260px;
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: $xxs) {
          max-width: 200px;
        }
      }
    }
  }
  &__date {
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #a7a9c0;
  }
  &__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #343748;
    word-break: break-word;

    @media (max-width: $sm) {
      font-size: 14px;
    }
  }
}
</style>
