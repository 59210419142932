<template>
  <div class="tabs">
    <ul class="tabs__list" ref="tabList">
      <li
        class="tabs__item"
        v-for="(tab, idx) in tabs"
        :key="`tab_${idx}`"
        :ref="`tab`"
        :class="{ active: idx === setActiveTab }"
      >
        <router-link
          class="tabs__link"
          :to="{ name: tab.path }"
          :class="{ active: idx === setActiveTab }"
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsNavigation",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      scrollLeft: 0,
      activeTab: 0
    };
  },
  beforeDestroy() {
    this.$root.$off("scroll");
  },
  mounted() {
    setTimeout(() => {
      this.scrollLeft = this.$refs.tab[this.setActiveTab].getBoundingClientRect().left;
      if (this.setActiveTab !== 0) {
        this.scrollToActiveTab();
      }
    }, 500);
  },
  methods: {
    scrollToActiveTab() {
      this.scrollLeft = this.$refs.tab[this.setActiveTab].getBoundingClientRect().left;
      this.$refs.tabList.scrollTo({ left: this.scrollLeft, behavior: "smooth" });
    }
  },
  computed: {
    setActiveTab() {
      return this.tabs.filter((item) => item.path === this.$route.name)[0].index;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs {
  font-family: "SF";
  position: relative;

  &__list {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    @media (max-width: $lg) {
      overflow-x: scroll;
    }
    //@media (max-width: $md) {
    //  width: 100%;
    //  margin: 0 auto;
    //}
  }

  &__item {
    position: relative;
    transition: all 0.5s ease;

    @media (max-width: $sm) {
      font-size: 14px;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $border-subdued;
    }

    &:hover {
      color: $text-default;
    }
  }

  &__link {
    display: block;
    font-weight: normal;
    padding: 0 20px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $text-disabled;
    white-space: nowrap;
    cursor: pointer;
  }

  .active {
    color: $text-default;
    transition: all 0.2s ease;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $action-primary;
      border-radius: 4px;
      animation-name: opacity;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
    }
  }
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
