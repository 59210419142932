export var deepEqual = function (a, b) {
  if (a === b) {
    return true;
  }
  if (a == null || typeof a !== "object" || b == null || typeof b !== "object") {
    return false;
  }
  let propertiesInA = 0;
  let propertiesInB = 0;
  let property;
  for (property in a) {
    if (a.hasOwnProperty(property)) {
      propertiesInA += 1;
    }
  }
  for (property in b) {
    if (b.hasOwnProperty(property)) {
      propertiesInB += 1;
      if (!(property in a) || !deepEqual(a[property], b[property])) {
        return false;
      }
    }
  }
  return propertiesInA === propertiesInB;
};
export var removeValueFromDocument = function (o) {
  let a = ["getElementById", "getElementsByClassName", "getElementsByName", "getElementsByTagName"];
  for (let k in a) {
    if (a.hasOwnProperty(k) && document[a[k]](o).length === undefined) {
      document[a[k]](o).value = "";
    }
  }
};
export var isJsonString = function (o) {
  try {
    JSON.parse(o);
  } catch (e) {
    return false;
  }
  return true;
};
export var isArray = function (o) {
  return Array.isArray(o);
};
export var isDefined = function (v) {
  return typeof v !== "undefined";
};
export var isEmpty = function (o) {
  return (
    typeof o === "undefined" ||
    o === null ||
    (isString(o) && o.length === 0) ||
    (typeof o === "object" && Object.keys(o).length === 0)
  );
};
export var isFunction = function (o) {
  return typeof o === "function";
};
export var isNumber = function (o) {
  return typeof o === "number";
};
export var isString = function (o) {
  return typeof o === "string";
};
export var isUndefined = function (v) {
  return typeof v === "undefined";
};
export var notUndefined = function (v) {
  return !isUndefined(v);
};
export var isValue = function (v) {
  return v || v === 0;
};
export var notEmpty = function (o) {
  return !isEmpty(o);
};
export var arrayUnique = function (arr) {
  const result = [];
  const duplicatesIndices = [];
  arr.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;
    result.push(current);
    for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
      const comparison = arr[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);
      if (currentKeys.length !== comparisonKeys.length) continue;
      const currentKeysString = currentKeys.sort().join("").toLowerCase();
      const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;
      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });
  return result;
};
export var mergeObject = function (o, o1) {
  if (typeof o !== "object" || typeof o1 !== "object") {
    return Error("Objects are not passed to the function!");
  } else {
    for (let i in o1) {
      if (notEmpty(o[i]) && isEmpty(o[o1[i]["id"]])) {
        o[Object.keys(o1).length + 1] = o1[i];
      } else {
        o[o1[i]["id"] || i] = o1[i];
      }
    }
    return o;
  }
};
export var getLastDayOfMonth = function (y, m) {
  return new Date(y, m, 0).getDate();
};
export var hasProperty = function (o, k) {
  return o ? hasOwnProperty.call(o, k) : false;
};
export var valueEmpty = function (v) {
  return v === false || v === 0 || notEmpty(v);
};
