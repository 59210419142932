<template>
  <header class="chat-header">
    <div class="chat-header__wrap chat-header__wrap_button" v-if="pickMode">
      <div class="chat-header__buttons">
        <ModalButton
          :title="'Переслать'"
          :count="pickedMessage.length"
          @handleButton="sendMessage"
        />
        <ModalButton
          :title="'Удалить'"
          :count="pickedMessage.length"
          @handleButton="deleteMessage"
          v-if="isSelfMessage || isAdminChat || currentChat.channel.is_moderator"
        />
      </div>
      <ModalButton :title="'Отменить'" :type="'cancel'" @handleButton="closePick" />
    </div>
    <div class="chat-header__wrap" v-if="!pickMode">
      <i class="chat-header__back chat-header__icon icon-arrow-short" @click="closePinMessage"></i>
      <span class="chat-header__pinned">
        <i class="icon-pin"></i>
      </span>
      <p class="chat-header__title">Закрепленные сообщения</p>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalButton from "@/components/Widgets/Button/ModalButton";
export default {
  name: "ChatHeader",
  mixins: [],
  props: {
    chat: {
      type: Object,
      default: () => {}
    }
  },
  components: { ModalButton },
  data() {
    return {
      list: []
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions("MessengerModule", [
      "closeCurrentChat",
      "switchChatMenu",
      "switchPinMessages",
      "closePickMessage",
      "observeModalItem",
      "setSearchMessageMode",
      "sendPickMessage",
      "deleteMessages",
      "switchModal"
    ]),
    sendMessage() {
      this.sendPickMessage();
    },
    deleteMessage() {
      this.deleteMessages(this.isAdminChat || this.currentChat.channel.is_moderator);
    },
    closePick() {
      this.closePickMessage();
    },
    closePinMessage() {
      this.switchPinMessages({ show: false });
    },
    closeCommentMessage() {
      this.observeModalItem("comment");
    },
    listenerEsc(event) {
      if (event.key === "Escape") {
        this.handleCloseChat();
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "showMenu",
      "users",
      "showPin",
      "currentChat",
      "showModal",
      "threadMode",
      "joinMode",
      "pickedMessage",
      "currentMessage",
      "pickMode"
    ]),
    isSelfMessage() {
      return this.pickedMessage.some((item) => item.user_id === this.getUserId);
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    moderatorsChat() {
      return this.users.filter((item) => item.is_moderator);
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  flex-shrink: 0;
  padding: 0 24px;
  border-bottom: 1px solid #eae8f0;
  &__title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #343748;
  }
  &__back {
    margin-right: 18px;
    transform: rotate(-90deg);
    font-size: 16px;
  }
  &__buttons {
    display: flex;
    max-width: 260px;
    width: 100%;
    justify-content: space-between;
  }
  /deep/ {
    .default-button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
      border: 2px solid #ffffff;
      @media (hover: hover) {
        &:hover {
          background: linear-gradient(336.67deg, #443cea -13.54%, #5e60ea 70.48%, #686ed1 120.89%);
          box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
            0 4px 5px rgba(48, 79, 254, 0.2);
        }
      }
      &_cancel {
        color: #343748 !important;
        background: none !important;
        box-shadow: none !important;
      }
    }
  }
  &__pinned {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $chat-editor;
    background: $message-gradient;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    i {
      transform: rotate(27.95deg);
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    &_button {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__icon {
    font-size: 18px;
    color: $icon-subdued;
    cursor: pointer;
    border-radius: 4px;
    //&:hover {
    //  background-color: $background-default;
    //  color: $text-subdued;
    //  path {
    //    fill: $text-subdued;
    //  }
    //}
    &_active {
      background-color: $background-default;
      color: $text-subdued;
    }
  }
  &__message {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $text-subdued;
  }
}
</style>
