<template>
  <div class="tasks-table-body">
    <div class="tasks-table-body__wrap" v-if="!Object.keys(currentStage).length">
      <ProjectStagesTableStage
        :isOffStage="true"
        :tableHead="tableHead"
        :projectId="projectId"
        :stage="defaultStage"
        @updateTable="updateTable"
      />
      <ProjectStagesTableStage
        :projectId="projectId"
        v-for="(item, index) in stages.list"
        :key="item.id"
        :stage="item"
        :tableHead="tableHead"
        :isLastItem="stages.list.length - 1 === index"
        @updateTable="updateTable"
      />
      <div class="tasks-table-body__pagination-loader" v-if="stagePaginationLoader">
        <Spinner />
      </div>
    </div>
    <div
      class="tasks-table-body__current-stage-tasks"
      :style="currentStage ? 'height: calc(100% - 70px); overflow-y: scroll' : ''"
      v-else
    >
      <div class="tasks-table-body__header-wrap">
        <ProjectStagesTableFields
          :tableHeadList="tableHead"
          :projectId="projectId"
          @updateTable="updateTable"
        />
      </div>
      <div class="tasks-table-body__row-container">
        <ProjectStagesTableTask
          v-for="task in tasks.list"
          :task="task"
          :tasksData="tasks.list"
          :params="params"
          :totalCount="tasks.count"
          :key="task.id"
          :isWhiteBg="true"
          @paginationEvent="paginationEvent"
        />
      </div>
      <div class="tasks-table-body__spinner" v-if="loader">
        <Spinner />
      </div>
      <p class="tasks-table-body__empty" v-if="!tasks.list.length && !loader">Нет задач</p>
    </div>
    <Button
      v-if="!project.is_completed && !currentStage.is_completed"
      type="simple"
      title="Добавить задачу"
      icon="plus"
      @handleButton="addTask"
    />
  </div>
</template>

<script>
import ProjectStagesTableTask from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableTask";
import Button from "@/modules/UiKit/components/buttons/Button";
import ProjectStagesTableStage from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableStage";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule.ts";
import ProjectStagesTableFields from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTableFields";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import { EventBus } from "@/main";

export default {
  name: "ProjectStagesTableBody",
  mixins: [],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    stages: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: "0"
    },
    tableHead: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    Spinner,
    ProjectStagesTableFields,
    ProjectStagesTableStage,
    ProjectStagesTableTask,
    Button
  },
  data() {
    return {
      defaultStage: {
        id: null,
        is_completed: false,
        name: "Вне этапа"
      },
      openSubtask: false
    };
  },
  beforeDestroy() {
    EventBus.$off("updateData");
  },
  mounted() {
    TaskModule[TaskMutationTypes.RESET_TASKS_BY_STAGE]([]);
    TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](1);
    this.updateTable(this.params);
    EventBus.$on("updateData", () => {
      TaskModule[TaskMutationTypes.RESET_TASKS_BY_STAGE]([]);
      TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](1);
      this.updateTable(this.params);
    });
  },
  methods: {
    addTask() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 3,
        currentProjectForCreation: this.project,
        currentStageForCreation: this.currentStage,
        currentTaskForCreation: null
      });
    },
    updateTable(params) {
      TaskModule.getTaskListByStageIdAction(params);
    },
    paginationEvent() {
      TaskModule.getTaskListByStageIdAction(this.params);
    }
  },
  computed: {
    currentStage() {
      return StageModule.stage;
    },
    project() {
      return ProjectModule.currentProject.projectFull;
    },
    tasks() {
      return TaskModule.tasksByStage(
        this.currentStage ? (this.currentStage?.id === 0 ? null : this.currentStage.id) : null
      );
    },
    loader() {
      return TaskModule.loader;
    },
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    },
    params() {
      return {
        page: TaskModule.tasksByStagePage,
        stage_id: this.currentStage
          ? this.currentStage?.id === 0
            ? null
            : this.currentStage.id
          : null,
        per_page: this.currentStage ? 15 : 10,
        project_id: this.projectId,
        status_id: TaskModule.params.status_id ? TaskModule.params.status_id : undefined,
        executor_id: TaskModule.params.executor_id ? TaskModule.params.executor_id : undefined,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
    },
    valueSort() {
      return TaskModule.valueSort;
    },
    totalCountTaskByStage() {
      return TaskModule.tasksByStageCount;
    },
    pageSub() {
      return TaskModule.subtaskPage;
    }
  },
  watch: {
    currentStage() {
      if (this.currentStage) {
        if (Object.keys(this.currentStage).length) {
          TaskModule[TaskMutationTypes.RESET_TASKS_BY_STAGE]([]);
          TaskModule[TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](1);
          this.updateTable(this.params);
        }
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.container {
  height: 100%;
}

.tasks-table-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media (max-width: $md) {
    height: 100%;
  }
  &__wrap {
    padding: 16px 0;
  }
  &__header-wrap {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 9;
    padding: 8px 0;
    margin: 0 0 24px;
    background: $surface-selected-default;
    border-radius: 8px;
  }

  &__empty {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    margin-top: 24px;
  }

  &__current-stage-tasks {
    overflow: scroll;
    /deep/ {
      .aop-button {
        font-size: 14px;

        i {
          font-size: 16px;
        }
      }
    }
  }
  &__pagination-loader {
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: center;
  }
  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0;
  }
}
.tasks-table-body__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
</style>
