import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { IStatus, ITask } from "@/modules/TaskManager/Model/Kanban/interface/interface";
import store from "@/store";
import KanbanService from "@/modules/TaskManager/Model/Kanban/KanbanService";
import Vue from "vue";
import {
  IBoard,
  IBoardColumn,
  IKanban,
  IStatusLink,
  IUnusedStatuses
} from "@/modules/TaskManager/Model/Kanban/KanbanModel";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";

const name = "KanbanModule";

export enum KanbanMutationTypes {
  SET_CURRENT_DRAGGABLE_TASK = "SET_CURRENT_DRAGGABLE_TASK",
  SET_CURRENT_EDITABLE_TASK = "SET_CURRENT_EDITABLE_TASK",
  SET_BOARD_LOADER = "SET_BOARD_LOADER",
  SET_CURRENT_BOARD = "SET_CURRENT_BOARD",
  SET_AVAILABLE_STATUSES = "SET_AVAILABLE_STATUSES",
  SET_COLUMN_LIST = "SET_COLUMN_LIST",
  SET_STATUS_LIST = "SET_STATUS_LIST",
  ADD_TO_TASK_LISTS_BY_STAGE = "ADD_TO_TASK_LISTS_BY_STAGE",
  DELETE_TASK_FROM_LISTS_BY_STAGE = "DELETE_TASK_FROM_LISTS_BY_STAGE",
  SET_CURRENT_BOARD_COLUMNS = "SET_CURRENT_BOARD_COLUMNS",
  ADD_CURRENT_BOARD_COLUMNS = "ADD_CURRENT_BOARD_COLUMNS",
  UPDATE_CURRENT_BOARD_COLUMNS = "UPDATE_CURRENT_BOARD_COLUMNS",
  DELETE_CURRENT_BOARD_COLUMN = "DELETE_CURRENT_BOARD_COLUMN",
  SET_UNUSED_STATUSES = "SET_UNUSED_STATUSES",
  ADD_UNUSED_STATUSES = "ADD_UNUSED_STATUSES",
  DELETE_UNUSED_STATUSES = "DELETE_UNUSED_STATUSES",
  CHANGE_STATUS_BOARD_COLUMN = "CHANGE_STATUS_BOARD_COLUMN"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

type taskType = ITask | null;
type availableStatusesType = Array<number | string>;
type columnListType = Array<IBoardColumn>;
type statusListAllType = Array<IStatus>;
type taskListType = Array<ITask>;

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class KanbanModule extends VuexModule {
  currentDraggableTask: taskType = null;
  currentEditableTask: taskType = null;
  availableStatuses: availableStatusesType = [];
  boardLoader: boolean = false;
  currentBoard: IBoard | null = null;
  currentBoardColumns: Array<IBoardColumn> = [];
  unusedStatuses: Array<IUnusedStatuses | IStatusLink> = [];
  columnList: columnListType = [];
  statusList: statusListAllType = [];
  taskListsByStage = {};
  @Mutation
  [KanbanMutationTypes.SET_CURRENT_DRAGGABLE_TASK](payload: taskType) {
    this.currentDraggableTask = payload;
  }
  @Mutation
  [KanbanMutationTypes.SET_CURRENT_EDITABLE_TASK](payload: taskType) {
    this.currentEditableTask = payload;
  }
  @Mutation
  [KanbanMutationTypes.SET_AVAILABLE_STATUSES](payload: availableStatusesType) {
    this.availableStatuses = payload;
  }
  @Mutation
  [KanbanMutationTypes.SET_COLUMN_LIST](payload: columnListType) {
    this.columnList = payload;
  }
  @Mutation
  [KanbanMutationTypes.SET_STATUS_LIST](payload: statusListAllType) {
    this.statusList = payload;
  }
  @Mutation
  [KanbanMutationTypes.ADD_TO_TASK_LISTS_BY_STAGE](payload: {
    stageId: number | null;
    projectId: number;
    taskList: taskListType;
  }) {
    if (payload.stageId === null) {
      Vue.set(this.taskListsByStage, `project_${payload.projectId}`, payload.taskList);
    } else {
      Vue.set(this.taskListsByStage, `stage_${payload.stageId}`, payload.taskList);
    }
  }
  @Mutation
  [KanbanMutationTypes.DELETE_TASK_FROM_LISTS_BY_STAGE](payload: {
    stageId: number | null;
    projectId: number;
    taskId: number;
  }) {
    const key =
      payload.stageId === null ? `project_${payload.projectId}` : `stage_${payload.stageId}`;
    if (!this.taskListsByStage[key]) return;
    Vue.set(
      this.taskListsByStage,
      key,
      this.taskListsByStage[key].filter((item) => item.id !== payload.taskId)
    );
  }
  @Mutation
  [KanbanMutationTypes.SET_BOARD_LOADER](payload: boolean) {
    this.boardLoader = payload;
  }

  @Mutation
  [KanbanMutationTypes.SET_CURRENT_BOARD](payload: IBoard) {
    this.currentBoard = payload;
  }

  @Mutation
  [KanbanMutationTypes.SET_CURRENT_BOARD_COLUMNS](payload: Array<IBoardColumn>) {
    this.currentBoardColumns = payload;
  }

  @Mutation
  [KanbanMutationTypes.ADD_CURRENT_BOARD_COLUMNS](payload: IBoardColumn) {
    payload.status_links = [];
    this.currentBoardColumns = this.currentBoardColumns.concat([payload]);
  }

  @Mutation
  [KanbanMutationTypes.UPDATE_CURRENT_BOARD_COLUMNS](payload: IBoardColumn) {
    if (this.currentBoardColumns.length) {
      const column = this.currentBoardColumns.find((item: IBoardColumn) => item.id === payload.id);
      if (column) {
        if (payload.name) column.name = payload.name;
        const index = this.currentBoardColumns.findIndex(
          (item: IBoardColumn) => item.id === payload.id
        );
        Vue.set(this.currentBoardColumns, index, column);
      }
    }
  }

  @Mutation
  [KanbanMutationTypes.DELETE_CURRENT_BOARD_COLUMN](id: number) {
    this.currentBoardColumns = this.currentBoardColumns.filter(
      (item: IBoardColumn) => item.id !== id
    );
  }

  @Mutation
  [KanbanMutationTypes.SET_UNUSED_STATUSES](payload: Array<IUnusedStatuses>) {
    this.unusedStatuses = payload;
  }

  @Mutation
  [KanbanMutationTypes.ADD_UNUSED_STATUSES](payload: Array<IUnusedStatuses | IStatusLink>) {
    this.unusedStatuses.push(...payload);
  }

  @Mutation
  [KanbanMutationTypes.DELETE_UNUSED_STATUSES](
    payload: Array<IUnusedStatuses | IStatusLink | any>
  ) {
    this.unusedStatuses = this.unusedStatuses.filter(
      (item: any) => item.board_column_id !== payload[0]?.board_column_id
    );
  }

  @Mutation
  [KanbanMutationTypes.CHANGE_STATUS_BOARD_COLUMN](payload: Array<IUnusedStatuses | IStatusLink>) {
    this.unusedStatuses.push(...payload);
  }

  @Action
  onDragStartAction(params: { task: taskType; availableStatuses: availableStatusesType }) {
    this[KanbanMutationTypes.SET_CURRENT_DRAGGABLE_TASK](params.task);
    this[KanbanMutationTypes.SET_AVAILABLE_STATUSES](params.availableStatuses);
    const tasksListNode = document.querySelectorAll(
      ".kanban-column__task-list"
    ) as NodeListOf<HTMLElement>;
    const statusListNode = document.querySelectorAll(
      ".kanban-column__status-list"
    ) as NodeListOf<HTMLElement>;
    if (tasksListNode.length) {
      tasksListNode.forEach((node) => {
        node.style.zIndex = "-1";
      });
    }
    if (statusListNode.length) {
      statusListNode.forEach((node) => {
        node.style.zIndex = "1";
      });
    }
  }
  @Action
  onDragStopAction(newStatusId: number) {
    if (
      this.currentDraggableTask &&
      newStatusId &&
      this.currentDraggableTask.status_id !== newStatusId
    )
      KanbanService.changeStatusTask({
        id: this.currentDraggableTask.id,
        status_id: newStatusId
      });
    const tasksListNode = document.querySelectorAll(
      ".kanban-column__task-list"
    ) as NodeListOf<HTMLElement>;
    const statusListNode = document.querySelectorAll(
      ".kanban-column__status-list"
    ) as NodeListOf<HTMLElement>;
    if (tasksListNode.length) {
      tasksListNode.forEach((node) => {
        node.style.zIndex = "1";
      });
    }
    if (statusListNode.length) {
      statusListNode.forEach((node) => {
        node.style.zIndex = "-1";
      });
    }
    this[KanbanMutationTypes.SET_CURRENT_DRAGGABLE_TASK](null);
    this[KanbanMutationTypes.SET_AVAILABLE_STATUSES]([]);
  }

  @Action
  async getBoardAction(params: IKanban) {
    this[KanbanMutationTypes.SET_BOARD_LOADER](true);
    const board = await KanbanService.getBoard(params);
    await this.getBoardColumnsAction({ board_id: board.id });
    if (!params.fromProjectPage) await this.getUnusedStatusAction({ flow_id: board.flow_id });
    this[KanbanMutationTypes.SET_CURRENT_BOARD](board);
    this[KanbanMutationTypes.SET_BOARD_LOADER](false);
  }

  @Action
  async getBoardColumnsAction(params: IKanban) {
    const columns = await KanbanService.getBoardColumns(params);
    this[KanbanMutationTypes.SET_CURRENT_BOARD_COLUMNS](columns);
  }

  @Action
  async createBoardColumnAction(body: IKanban) {
    const column = await KanbanService.createBoardColumn(body);
    this[KanbanMutationTypes.ADD_CURRENT_BOARD_COLUMNS](column);
  }

  @Action
  async updateBoardColumnAction(body: IBoardColumn) {
    await KanbanService.updateBoardColumn(body);
    this[KanbanMutationTypes.UPDATE_CURRENT_BOARD_COLUMNS](body);
  }

  @Action
  async deleteBoardColumnAction(body: IBoardColumn) {
    await KanbanService.deleteBoardColumn(body.id);
    this[KanbanMutationTypes.DELETE_CURRENT_BOARD_COLUMN](body.id);
    if (body.status_links) this[KanbanMutationTypes.ADD_UNUSED_STATUSES](body.status_links);
  }

  @Action
  async getUnusedStatusAction(params: IKanban) {
    const statuses = await KanbanService.getUnusedStatus(params);
    this[KanbanMutationTypes.SET_UNUSED_STATUSES](statuses);
  }

  @Action
  async createBoardColumnStatusAction(body: IKanban) {
    await KanbanService.createBoardColumnStatus(body);
  }

  @Action
  async deleteBoardColumnStatusAction(body: IKanban) {
    await KanbanService.deleteBoardColumnStatus(body);
  }

  @Action
  async getStatusListAction(projectId: number) {
    KanbanService.getStatuses(projectId).then((statusList) => {
      this[KanbanMutationTypes.SET_STATUS_LIST](statusList);
    });
  }

  @Action
  async getTasksByStageAction(params: { stage_id: number | null; project_id: number }) {
    if (
      this.taskListsByStage[`stage_${params.stage_id}`] ||
      (params.stage_id === null && this.taskListsByStage[`project_${params.project_id}`])
    )
      return;
    const taskList = await KanbanService.getTasksByStage({
      stage_id: params.stage_id,
      project_id: params.project_id
    });
    this[KanbanMutationTypes.ADD_TO_TASK_LISTS_BY_STAGE]({
      stageId: params.stage_id,
      projectId: params.project_id,
      taskList
    });
  }
  @Action
  async updateTasksByStageAction(params: { stage_id: number | null; project_id: number }) {
    if (params.stage_id === undefined) params.stage_id = null;
    if (
      !this.taskListsByStage[`stage_${params.stage_id}`] &&
      params.stage_id === null &&
      !this.taskListsByStage[`project_${params.project_id}`]
    )
      return;
    const taskList = await KanbanService.getTasksByStage({
      stage_id: params.stage_id,
      project_id: params.project_id
    });
    this[KanbanMutationTypes.ADD_TO_TASK_LISTS_BY_STAGE]({
      stageId: params.stage_id,
      projectId: params.project_id,
      taskList
    });
  }
  @Action
  editCurrentEditableTaskAction(params: ITask) {
    if (!this.currentEditableTask) return;
    //Перенос задачи в другой этап
    if (params.stage_id || params.stage_id === null) {
      const keyOld = this.currentEditableTask.stage_id
        ? `stage_${this.currentEditableTask.stage_id}`
        : `project_${ProjectModule.currentProject?.projectFull.id}`;
      const index = this.taskListsByStage[keyOld]?.findIndex(
        (item) => item === this.currentEditableTask
      );
      if (index !== -1) {
        const keyNew =
          params.stage_id === null
            ? `project_${ProjectModule.currentProject?.projectFull.id}`
            : `stage_${params.stage_id}`;
        if (this.taskListsByStage[keyNew])
          this.taskListsByStage[keyNew].push(this.taskListsByStage[keyOld][index]);
        this.taskListsByStage[keyOld].splice(index, 1);
      }
    }
    for (const key in params) {
      this.currentEditableTask[key] = params[key];
    }
  }
}

export default getModule(KanbanModule);
