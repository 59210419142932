<template>
  <div class="sort-table" @click="openList" v-click-outside="hideModalList">
    <div class="sort-table__value">
      <button class="sort-table__label" type="button">
        <div class="sort-table__arrows" v-if="!altIcon">
          <i :class="`icon-${icon}`"></i>
          <i
            :class="`icon-${icon}`"
            :style="'transform: rotate(180deg)'"
            v-if="icon === 'sort-arrow'"
          ></i>
        </div>
        <i class="icon-check-all" v-else></i>
        <span>{{ valueSort.name }}</span>
        <span class="sort-table__placeholder" v-if="!valueSort">{{ placeholder }}</span>
      </button>
      <div class="sort-table__wrapper" v-if="showList">
        <ul class="sort-table__list">
          <li class="sort-table__item" @click="chosenAllItem" v-if="multiple">
            <span class="checkbox_custom" :class="{ checkbox_custom_chosen: choseAll }">
              <i class="icon-check" v-if="choseAll"></i>
            </span>
            <span>Вce</span>
          </li>
          <li
            class="sort-table__item"
            v-for="item in listSorts"
            :key="item"
            @click="choseFilter(item)"
          >
            <span
              class="checkbox_custom"
              :class="{ checkbox_custom_chosen: isChosenFilter(item) || choseAll }"
            >
              <i class="icon-check" v-if="isChosenFilter(item) || choseAll"></i>
            </span>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Widget from "@/components/Widgets/Widget";

export default {
  name: "SortTableMobile",
  mixins: [],
  props: {
    icon: {
      type: String,
      default: "sort-arrow"
    },
    placeholder: {
      type: String,
      default: ""
    },
    valueSort: {
      type: Object,
      default: () => {}
    },
    listSorts: {
      type: Array,
      default: () => []
    },
    altIcon: {
      type: Boolean,
      default: false
    },
    unique: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: { Widget },
  data() {
    return {
      showList: false,
      choseAll: false
    };
  },
  mounted() {},
  methods: {
    openList() {
      if (this.listSorts.length) {
        this.showList = !this.showList;
      }
    },
    hideModalList() {
      this.showList = false;
    },
    clickArrows() {
      this.choseSort();
    },
    choseFilter(item) {
      this.$emit("choseSort", item);
      if (this.choseAll) {
        this.$root.$emit(`chosenFilter_${this.unique}`, item);
        this.isChosenFilter(item.value);
        this.showList = false;
      }
      if (item.value !== this.valueSort.value) {
        this.$root.$emit(`chosenFilter_${this.unique}`, item);
        this.isChosenFilter(item.value);
        this.showList = false;
      }
      this.choseAll = false;
    },
    isChosenFilter(item) {
      return [this.valueSort].findIndex((val) => val.value === item.value) !== -1;
    },
    chosenAllItem() {
      this.choseAll = true;
      this.$root.$emit("pushAllOption", this.listSortId);
    }
  },
  computed: {
    listSortId() {
      return this.listSorts.map((item) => item.id);
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.sort-table {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 5px 6px;
  width: 120px;
  height: 30px;

  border-radius: 4px;

  @media (max-width: $md) {
    padding: 0 6px;
    height: 16px;
  }

  @media (max-width: 767px) {
    width: max-content;
  }

  &__arrows {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    margin-right: 8px;

    .icon-sort-arrow {
      color: $icon-subdued;
      font-size: 14px;
    }
  }
  .icon-check-all {
    color: $icon-subdued;
    font-size: 14px;
    margin-right: 8px;
  }
  &__label {
    display: flex;
    align-items: center;
    z-index: 10;
    max-width: 110px;
    padding: 4px;
    border: 0;
    height: 30px;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    margin-right: 16px;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
    @media (max-width: $md) {
      width: max-content;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $action-secondary-hovered;
      }
    }

    &:active {
      background-color: $action-secondary-pressed;
    }

    i {
      color: $icon-subdued;
      font-size: 14px;
    }

    span {
      @media (max-width: $md) {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: max-content;
    top: 40px;
    z-index: 10;
    left: 0;
    background: $color-white;
    list-style: none;
    margin: 0;
    padding: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 14px;

    @media (max-width: $sm) {
      position: fixed;
      width: 100%;
      top: unset;
      bottom: 0;
      height: fit-content;
      border-radius: 16px 16px 0 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 140px;
    height: 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;

    span {
      margin-left: 8px;
    }
  }

  &__item:hover {
    background: #f1effa;
    border-radius: 4px;
  }

  &__placeholder {
    color: $text-disabled;
  }

  &__wrapper {
    @media (max-width: $sm) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: $background-modal;
    }
  }
}

.checkbox_custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid #d3d2e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  flex-shrink: 0;
  .icon-check {
    color: #ffffff;
  }

  &_chosen {
    background-color: $action-primary;
    border: 2px solid $action-primary;
  }
}
</style>
