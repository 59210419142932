<template>
  <div class="chat-editor">
    <EditAndReplyMessage v-if="isEditMode" />
    <SelectList
      v-if="showUserTooltip && searchUserList.length"
      :searchList="searchUserList"
      :focus="focus"
      @setCheckUser="setCheckUser"
    />
    <SelectList
      v-if="showGroupTooltip && searchGroupList.length"
      :searchList="searchGroupList"
      :focus="focus"
      @setCheckUser="setCheckGroup"
    />
    <div class="chat-editor__container" v-click-outside="hideEmojiModal">
      <Avatar :user="getDataInfoUser" v-if="false" />
      <div class="chat-editor__wrap">
        <textarea
          class="chat-editor__field"
          v-model.trim="value"
          ref="textEditor"
          id="textEditor"
          @input="observeChangeInput"
          @keydown.enter.prevent.exact="switchKeyEnter"
          @keydown.up="observeKey"
          @keydown.down="observeKey"
          @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
          @paste="observePaste"
          maxlength="8192"
          placeholder="Введите текст сообщения"
        >
        </textarea>
        <div class="chat-editor__icons">
          <file-upload
            :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
            post-action="/upload/post"
            input-id="chatEditor"
            :multiple="true"
            :drop="false"
            v-model="files"
            :drop-directory="true"
            :disabled="editMode"
            @input="handleUploadFile"
            ref="upload"
            id="upload"
            v-if="!editMode && !sendMode"
          >
            <i class="icon-paper-clip-secondary"></i>
          </file-upload>
          <div class="chat-editor__emoji" v-click-outside="closeEmoji">
            <i class="icon-emoji" @click="showEmojiModal = !showEmojiModal"></i>
            <Picker
              v-show="showEmojiModal"
              set="apple"
              @select="addEmoji"
              title="Выберите Эмоджи"
              :i18n="settings"
            />
          </div>
          <i class="icon-send" @click="sendMessageHandler"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { Picker } from "emoji-mart-vue";
import ResizeMixin from "@/helpers/ResizeMixin";
import SelectList from "@/modules/Messenger/components/SelectList/SelectList";
import FileUpload from "vue-upload-component";
import EditAndReplyMessage from "@/modules/Messenger/components/Chat/ChatEditor/EditAndReplyMessage";
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import writingStatus from "@/modules/Messenger/mixins/writingStatus";
export default {
  name: "ChatEditor",
  mixins: [ResizeMixin],
  props: {},
  components: { EditAndReplyMessage, SelectList, FileUpload, Avatar, Picker },
  data() {
    return {
      showUserTooltip: false,
      showGroupTooltip: false,
      value: "",
      userMatch: /(^|\s)(@[^ ]*)/gi,
      groupMatch: /(^|\s)(#[^ ]*)/gi,
      linkMatch:
        /https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}\b([-a-zA-Zа-яА-Я0-9()!@:%_+.~#?&//=]*)/gi,
      reg: /\w/,
      size: 57,
      searchUserList: [],
      links: [],
      timerId: null,
      writeTimerId: null,
      emojiTimer: null,
      editor: null,
      commentEditor: null,
      nextMatch: [],
      lastMatch: [],
      searchGroupList: [],
      nextMatchGroup: [],
      lastMatchGroup: [],
      focus: 0,
      showEmojiModal: false,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {
    this.value = this.editorValue;
    this.editor = document.querySelector("#textEditor");
    const container = document.querySelector(".chat_comment");
    this.commentEditor = container.querySelector("#textEditor");
    this.editor.focus();
  },
  methods: {
    ...mapActions("MessengerModule", [
      "setEditorValue",
      "setPasteFile",
      "sendMessage",
      "sendMessageInChat",
      "sendFirstMessage"
    ]),
    closeEmoji() {
      this.showEmojiModal = false;
    },
    searchMatch(reg, text) {
      return text.match(reg);
    },
    addEmoji(emoji) {
      // selectionStart - Начало выделения
      // selectionEnd - Конец выделения
      let cursorPositionStart = this.$refs.textEditor.selectionStart;
      let cursorPositionEnd = this.$refs.textEditor.selectionEnd;
      let Emoji = emoji.native;
      let FirstPart = this.value.slice(0, cursorPositionStart);
      let SecondPart = this.value.slice(cursorPositionEnd);
      this.value = FirstPart + Emoji + SecondPart;
      clearTimeout(this.emojiTimer);
      this.emojiTimer = setTimeout(() => {
        if (this.threadMode) {
          this.commentEditor.focus();
        } else {
          this.editor.focus();
        }
      }, 200);
    },
    hideEmojiModal() {
      this.showEmojiModal = false;
    },
    /**
     * Find @ in text and call user list picker
     * @param match - regexp find @
     */
    observeCheckUser(match) {
      this.lastMatch = this.nextMatch.map((item) => item.trim());
      this.nextMatch = this.searchMatch(match, this.value) || [];
      this.nextMatch = this.nextMatch.map((item) => item.trim());
      if (this.lastMatch.length > this.nextMatch.length) {
        this.showUserTooltip = false;
        return false;
      }
      if (this.lastMatch.join() !== this.nextMatch.join() && this.nextMatch.length) {
        this.showUserTooltip = true;
        this.reg = this.nextMatch
          .filter((item) => {
            return this.lastMatch.indexOf(item) < 0;
          })[0]
          .trim();

        this.searchUserList = this.searchSelectUser(this.reg);
        if (this.searchUserList.length === 1 && this.searchUserList[0].name === this.reg) {
          this.showUserTooltip = false;
        }
      } else {
        this.showUserTooltip = false;
      }
    },
    /**
     * Find # in text and call group list picker
     * @param match - regexp find #
     */
    observeCheckGroup(match) {
      this.lastMatchGroup = this.nextMatchGroup.map((item) => item.trim());
      this.nextMatchGroup = this.searchMatch(match, this.value) || [];
      this.nextMatchGroup = this.nextMatchGroup.map((item) => item.trim());
      if (this.lastMatchGroup.join() !== this.nextMatchGroup.join() && this.nextMatchGroup.length) {
        this.showGroupTooltip = true;
        this.reg = this.nextMatchGroup
          .filter((item) => {
            return this.lastMatchGroup.indexOf(item) < 0;
          })[0]
          .trim();
        this.searchGroupList = this.searchSelectUser(this.reg, "selectGroup");
        if (this.searchGroupList.length === 1 && this.searchGroupList[0].name === this.reg) {
          this.showGroupTooltip = false;
        }
      } else {
        this.showGroupTooltip = false;
      }
    },
    observeLink(match) {
      this.links = this.searchMatch(match, this.value);
    },
    observeKey(event) {
      if (this.showGroupTooltip) {
        this.switchIndexSelect(event, this.searchGroupList);
      }
      if (this.showUserTooltip) {
        this.switchIndexSelect(event, this.searchUserList);
      }
    },
    scrollToElement() {
      this.$nextTick(() => {
        const item = document.querySelector(".select-list__item_focus");
        const container = document.querySelector(".select-list");
        this.$scrollTo(item, 0, {
          container: container,
          lazy: false,
          offset: 0,
          force: false,
          cancelable: false,
          x: false,
          y: true
        });
      });
    },
    /**
     * Observe key press
     * @param event - input event
     * @param array - user or group list
     */
    switchIndexSelect(event, array) {
      if (event.code === "ArrowUp" || event.code === "ArrowDown") {
        event.preventDefault();
        switch (event.keyCode) {
          case 38:
            if (this.focus >= 0) {
              this.focus--;
              if (this.focus === -1) {
                this.focus = array.length - 1;
              }
            }
            this.scrollToElement();
            break;
          case 40:
            if (this.focus < array.length) {
              this.focus++;
              if (this.focus === array.length) {
                this.focus = 0;
              }
              this.scrollToElement();
            }
            break;
        }
      }
    },
    /**
     * Input observe change
     * @param event - input event
     */
    observeChangeInput(event) {
      if (this.userMember) {
        clearTimeout(this.timerId);
        clearTimeout(this.writeTimerId);
        this.writeTimerId = setTimeout(() => {
          writingStatus(this.userMember.user_id, true, this.currentChat.channel);
        }, 300);
        this.timerId = setTimeout(() => {
          writingStatus(this.userMember.user_id, false, this.currentChat.channel);
        }, 1000);
      }
      this.setEditorValue(this.value);
      this.observeCheckUser(this.userMatch);
      this.observeCheckGroup(this.groupMatch);
      this.observeLink(this.linkMatch);
      this.mixin_autoResize_resize(event);
    },
    /**
     * Set user name in textarea
     * @param name - checked name in list
     */
    setCheckUser(name) {
      const text = this.value.trim().split(" ");
      const index = text.findIndex((item) => item === this.reg);
      text[index] = name;
      this.value = text.join(" ") + " ";
      this.setEditorValue(this.value);
      this.showUserTooltip = false;
      this.$refs.textEditor.focus();
      this.reg = "@";
      this.focus = 0;
      this.nextMatch = this.nextMatch.map((item) => {
        if (item.trim() === this.reg.trim()) {
          return name.trim();
        } else {
          return item.trim();
        }
      });
    },
    /**
     * Set group name in textarea
     * @param name - checked name in list
     */
    setCheckGroup(name) {
      const text = this.value.trim().split(" ");
      const index = text.findIndex((item) => item === this.reg);
      text[index] = name;
      this.value = text.join(" ") + " ";
      this.showGroupTooltip = false;
      this.$refs.textEditor.focus();
      this.reg = "#";
      this.focus = 0;
      this.nextMatchGroup = this.nextMatchGroup.map((item) => {
        if (item.trim() === this.reg.trim()) {
          return name.trim();
        } else {
          return item.trim();
        }
      });
    },
    /**
     * Depending on the settings, sending a message or moving it to a new line
     * @param event - input event
     */
    switchKeyEnter(event) {
      if ((!this.showUserTooltip && !this.showGroupTooltip) || !this.searchUserList.length) {
        if (this.selectedSettingsEditor === "Enter") {
          this.sendMessageHandler();
        } else {
          this.newLine(event);
        }
      } else {
        this.selectItem();
      }
    },
    selectItem() {
      if (this.showUserTooltip) {
        this.setCheckUser(this.searchUserList[this.focus].nickname);
      }
      if (this.showGroupTooltip) {
        this.setCheckGroup(this.searchGroupList[this.focus].nickname);
      }
    },
    /**
     * Depending on the settings, sending a message or moving it to a new line
     * @param event - input event
     */
    switchKeyCtrlEnter(event) {
      if (!this.showUserTooltip && !this.showGroupTooltip) {
        if (this.selectedSettingsEditor === "Enter") {
          this.newLine(event);
        } else {
          this.sendMessageHandler();
        }
      } else {
        this.selectItem();
      }
    },
    sendMessageHandler() {
      if (+this.value.length > 8192) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Сообщение не может быть больше 8192 символов!`
        });
        return false;
      }
      if (this.value.length || this.sendMode) {
        const links = Array.from(new Set(this.links));
        if (!this.currentChat.channel.id) {
          this.sendFirstMessage({ text: this.value, links: links });
        } else {
          if (this.sendMode) {
            let ids = [];
            if (this.pickedMessage.length) {
              ids = this.pickedMessage.map((item) => item.id);
            } else {
              ids = [this.currentMessage.id];
            }
            this.sendMessageInChat({
              text: this.value,
              id: this.currentChat.channel.id,
              share_message_ids: ids,
              links: links
            });
          } else {
            this.sendMessage({
              text: this.value,
              id: this.currentChat.channel.id,
              links: links
            });
          }
        }
      }
      this.value = "";
      this.$nextTick(() => {
        this.$refs.textEditor.style.height = this.size + "px";
      });
      this.closeEmoji();
    },
    /**
     * paste file in clipboard
     * @param event - input event
     * return file
     */
    observePaste(event) {
      if (event.clipboardData.files.length) {
        event.preventDefault();
        const val = event.clipboardData.files[0];
        const file = {
          file: val,
          name: val.name,
          size: val.size,
          type: val.type,
          fileObject: true,
          postAction: "/upload/post"
        };
        this.setPasteFile([file]);
      }
    },
    handleUploadFile(event) {
      this.setPasteFile(event);
    },
    /**
     * add line break in textarea
     * @param e - input event
     */
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "editorValue",
      "files",
      "showFileModal",
      "editMode",
      "replyMode",
      "sendMode",
      "threadMode",
      "currentChat",
      "pickedMessage",
      "selectedSettingsEditor",
      "currentMessage"
    ]),
    ...mapGetters("MessengerModule", ["searchSelectUser"]),
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isEditMode() {
      return this.editMode || this.replyMode || this.sendMode;
      // return (this.editMode && !this.currentMessage.files.length) || this.replyMode;
    },
    showDropFile() {
      return this.$refs.upload && this.$refs.upload.dropActive && !this.showFileModal;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    userMember() {
      return this.currentChat.users.find((item) => item.user_id !== this.getUserId);
    }
  },
  watch: {
    currentChat() {
      this.showUserTooltip = false;
      this.showGroupTooltip = false;
      if (this.threadMode) {
        this.commentEditor.focus();
      } else {
        this.editor.focus();
      }
    },
    threadMode() {
      if (this.threadMode) {
        this.commentEditor.focus();
      } else {
        this.editor.focus();
      }
    },
    editorValue: function (text) {
      this.value = text;
      if (this.currentMessage && this.currentMessage.files.length) {
        this.value = "";
      } else {
        this.value = text;
      }
      // this.$nextTick(() => {
      //   this.$refs.textEditor.style.height = this.$refs.textEditor.scrollHeight + "px";
      // });
      if (!this.value) {
        this.$refs.textEditor.style.height = this.size + "px";
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-editor {
  display: flex;
  flex-flow: column;
  scroll-behavior: smooth;
  position: relative;
  border-top: 1px solid #eae8f0;
  z-index: 10;
  &__container {
    display: flex;
    align-items: flex-end;
  }
  &__wrap {
    position: relative;
    display: flex;
    width: 100%;
  }

  &__field {
    background-color: #fff;
    border: none;
    padding: 17.5px 120px 17.5px 20px;
    font-size: 16px;
    line-height: 20px;
    color: $text-default;
    resize: none;
    width: 100%;
    max-height: 180px;
    height: 57px;
    transition: border 0.3s ease-in;

    @media screen and (max-width: $xs) {
      font-size: 14px;
    }
    &::placeholder {
      color: #c3c7d2;
    }
    &:active,
    &:focus {
      outline: none;
      & + .chat-editor__icons {
        color: $chat-editor;
      }
    }
  }
  &__icons {
    display: flex;
    float: left;
    right: 20px;
    bottom: 17.5px;
    position: absolute;
    color: $icon-subdued;
    transition: all 0.3s ease-in;
    z-index: 13;
  }
  &__emoji {
    position: relative;
    margin-left: 8px;
    i {
      cursor: pointer;
    }
    .emoji-mart {
      position: absolute;
      bottom: calc(100% + 5px);
      right: 0;
      height: 320px;
      @media screen and (max-height: 560px) {
        height: 220px;
      }
      @media screen and (max-width: $xs) {
        max-width: 250px;
      }
      /deep/ {
        .emoji-mart-preview {
          display: none;
        }
      }
    }
  }
  i {
    font-size: 24px;
  }
  .icon-send {
    margin-left: 10px;
    cursor: pointer;
  }
  /deep/ {
    .avatar__wrap {
      height: 55px;
    }
    .avatar__img {
      border: none;
    }
    .file-uploads {
      height: 24px;
      display: flex;
      cursor: pointer;
    }
    .file-uploads.file-uploads-html4 input,
    .file-uploads.file-uploads-html5 label {
      font-size: 24px;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}
</style>
