<template>
  <div class="drop-down" v-click-outside="hideList">
    <div class="drop-down__current">
      <span>{{ current.name }}</span
      ><i class="icon-down" @click="showList = !showList"></i>
    </div>
    <div class="drop-down__wrapper" v-if="showList" @click="hideList">
      <ul class="drop-down__list" v-if="showList">
        <DropDownItem v-for="item in valueList" :key="item.id" :item="item" :current="current" />
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DropDownItem from "@/modules/Isp/components/IspComponents/QuarterListDropDown/QuarterListDropDownItem";
export default {
  name: "QuarterListDropDown",
  mixins: [],
  props: {
    current: {
      type: Object,
      default: () => {}
    },
    valueList: {
      type: Array,
      default: () => []
    }
  },
  components: { DropDownItem },
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    hideList() {
      this.showList = false;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.drop-down {
  position: relative;

  &__current {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    color: #0a1417;
    span {
      max-width: 300px;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      color: $text-disabled;
      margin-left: 16px;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }
    }
  }
  &__wrapper {
    @media screen and (max-width: 850px) and (max-height: 850px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: rgba(33, 32, 44, 0.8);
    }
  }
  &__list {
    position: absolute;
    width: max-content;
    min-width: 180px;
    top: 40px;
    z-index: 10;
    right: 0;
    list-style: none;
    padding: 8px 0;
    margin: 0;
    background-color: $surface-default;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
    @media screen and (max-width: 850px) and (max-height: 850px) {
      position: fixed;
      max-height: 70vh;
      width: 100%;
      max-width: unset;
      top: unset;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      overflow-y: auto;
      z-index: 100;
    }
  }
}
</style>
