<template>
  <div class="profile__user-data user-data">
    <div class="user-data__header">
      <h2 class="user-data__title">Данные о сотруднике</h2>
      <button class="user-data__full-info" type="button" @click="isProfileUserDataModal = true">
        Посмотреть полные данные
      </button>
      <ActionsList v-if="isAdmin && userCard" :actionList="actionList" />
    </div>
    <div class="user-data__personal-info" :style="editMode ? 'border: none' : ''">
      <div class="user-data__user">
        <img
          class="user-data__avatar"
          @click="isOpenAvatar(true)"
          :src="returnSrcFile(dataUser.avatar)"
          alt="UsePhoto"
        />
        <div class="user-data__user-name">
          <p class="user-data__name">{{ dataUser.full_name }}</p>
          <p class="user-data__active">{{ setActiveStatus }}</p>
        </div>
      </div>
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Email</p>
        <p class="user-data__content user-data__content_email">
          <span ref="userEmail">{{ dataUser.email ? dataUser.email : "-" }}</span>
          <i class="icon-copy" @click="copyText($refs.userEmail)"></i>
        </p>
      </div>
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Номер телефона</p>
        <p class="user-data__content user-data__content_phone">
          <span ref="userPhone">{{ dataUser.phone ? dataUser.phone : "-" }}</span>
          <i class="icon-copy" v-if="dataUser.phone" @click="copyText($refs.userPhone)"></i>
        </p>
      </div>
      <div class="user-data__wrap user-data__wrap_birthday">
        <p class="user-data__subtitle">День рождения</p>
        <p class="user-data__content">
          {{ setBirthday }}
        </p>
      </div>
    </div>
    <div class="user-data__working-info">
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Отдел</p>
        <p class="user-data__content">{{ setDepartment }}</p>
      </div>
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Должность</p>
        <p class="user-data__content">{{ setRoles }}</p>
      </div>
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Грейд</p>
        <Level :level="setGrade" />
      </div>
      <div class="user-data__black-spheres">
        <div class="user-data__wrap">
          <p class="user-data__subtitle">Черный шар</p>
          <SwitchWidget :editValue="dataUser.has_black_sphere" :readonly="true" />
        </div>
        <div class="user-data__wrap" v-if="!editMode">
          <p class="user-data__subtitle">Дата подключения</p>
          <p class="user-data__black-spheres-date" v-if="dataUser.date_of_include_black_sphere">
            <i class="icon-calendar-secondary"></i>
            {{ setDateFormatWidthMonthName(dataUser.date_of_include_black_sphere) }}
          </p>
          <p class="user-data__content" v-else>-</p>
        </div>
      </div>
      <div class="user-data__wrap">
        <p class="user-data__subtitle">Ставка</p>
        <p class="user-data__content">{{ setRate }}</p>
      </div>
    </div>
    <ProfileEditData
      :dataUser="dataUser"
      v-if="editMode"
      @hideModal="editMode = false"
      @goGetInfoUser="$emit('goGetInfoUser')"
    />
    <ProfileUserDataModal
      v-if="isProfileUserDataModal"
      :dataUser="dataUser"
      :userCard="userCard"
      :isAdmin="isAdmin"
      @hideModal="hideProfileUserDataModal"
    />
    <transition name="fade">
      <FullScreenAvatar
        :pathImg="dataUser.avatar"
        @closeImg="isOpenAvatar"
        v-if="showFullScreenAvatar"
      />
    </transition>
  </div>
</template>

<script>
import SwitchWidget from "@/components/Widgets/SwitchWidget/SwitchWidget";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import ProfileUserDataModal from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileUserData/ProfileUserDataModal";
import ActionsList from "@/components/Widgets/ActionList/ActionList";
import Widget from "@/components/Widgets/Widget";
import ChannelPicker from "@/components/Widgets/Select/SelectPicker/SelectPicker";
import SelectPicker from "@/components/Widgets/Select/SelectPicker/SelectPicker";
import ProfileEditData from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileUserData/ProfileEditData";
import FullScreenAvatar from "@/components/ProfileComponents/ProfilePersonalArea/ProfileModal/FullScreenAvatar";
import { domainStorage } from "@/globalVariables";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import Level from "@/components/Widgets/Level/Level";
import { EventBus } from "@/main";
import copyText from "@/helpers/copyText";
export default {
  name: "ProfileUserData",
  mixins: [formatDateAndTime, copyText],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userCard: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FullScreenAvatar,
    Level,
    ProfileEditData,
    SelectPicker,
    ChannelPicker,
    Widget,
    ActionsList,
    ProfileUserDataModal,
    SwitchWidget
  },
  data() {
    return {
      editMode: false,
      isProfileUserDataModal: false,
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: "EditUserCard"
        }
      ],
      showFullScreenAvatar: false
    };
  },
  beforeDestroy() {
    this.$root.$off("actionItem:handleEditUserCard");
  },
  mounted() {
    this.$root.$on("actionItem:handleEditUserCard", () => {
      this.showEditModal();
    });
  },
  methods: {
    isOpenAvatar(payload) {
      this.showFullScreenAvatar = payload;
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    showEditModal() {
      this.editMode = true;
    },
    hideProfileUserDataModal() {
      this.isProfileUserDataModal = false;
    },
    setUserCardBirthdayFormat(date) {
      return format(new Date(date), "dd MMMM", { locale: ru });
    }
  },
  computed: {
    /** Функция установки статуса сотрудника
     * @returns {string} - статус сотрудника
     */
    setActiveStatus() {
      return this.dataUser.is_active ? "Работает" : "Не работает";
    },
    setBirthday() {
      return this.dataUser.date_of_birth
        ? this.userCard
          ? this.setUserCardBirthdayFormat(this.dataUser.date_of_birth)
          : this.setDateFormatFull(this.dataUser.date_of_birth)
        : "-";
    },
    /** Функция установки роли сотрудника
     * @returns {*|string} - роль сотрудника, при отсутствии роли устанавливается прочерк
     */
    setRoles() {
      return this.dataUser.roles.length ? this.dataUser.roles[0].name : "-";
    },
    /** Функция установки отдела сотрудника
     * @returns {*|string} - отдел сотрудника, при отсутствии отдела устанавливается прочерк
     */
    setDepartment() {
      return this.dataUser.departments.length ? this.dataUser.departments[0].name : "-";
    },
    /** Функция установки грейда сотрудника
     * @returns {*|string} - грейд сотрудника, при отсутствии грейда устанавливается грейд ACADEMY
     */
    setGrade() {
      return this.dataUser.levels.length ? this.dataUser.levels[0].name : "Нет уровня";
    },
    /** Функция установки ставки сотрудника
     * @returns {string} - ставка сотрудника
     */
    setRate() {
      return this.dataUser.rate_coefficient === "1" ? "Полная" : "Не полная";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.user-data {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 750px;
  background: $surface-default;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 24px;

  @media (max-width: $xl) {
    min-height: 200px;
  }

  @media (max-width: $sm) {
    padding: 0 0 32px;
    height: fit-content;
  }
  &__avatar {
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $xxxl) {
      padding: 24px 24px 16px;
    }

    @media (max-width: $sm) {
      padding: 16px 16px 8px;
    }

    /deep/ {
      i {
        color: $icon-subdued;
        font-size: 20px;
        cursor: pointer;
      }

      .icon-cross,
      .icon-check {
        &:hover {
          color: $icon-hovered;
        }

        &:active {
          color: $icon-pressed;
        }
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: $text-body-dark;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__full-info {
    display: none;

    @media (max-width: $xl) {
      display: block;
      padding: 0;
      border: none;
      background-color: transparent;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: $text-highlight;
      outline: none;
    }

    @media (max-width: $sm) {
      position: absolute;
      bottom: 24px;
      left: 16px;
    }
  }

  .action-list__modal {
    @media (max-width: $xl) {
      display: none;
    }
  }

  &__personal-info {
    padding: 24px 32px 0;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $xxxl) {
      padding: 24px 24px 0;
    }

    @media (max-width: $xl) {
      display: flex;
      justify-content: space-between;
      //flex-wrap: wrap;
      border-bottom: none;
    }

    @media (max-width: $md) {
      padding: 24px 16px 0;
    }

    @media (max-width: $sm) {
      padding: 16px 16px 0;
      flex-wrap: wrap;
    }
  }

  &__working-info {
    padding: 32px 32px 0;

    @media (max-width: $xxxl) {
      padding: 24px 24px 0;
    }

    @media (max-width: $xl) {
      display: none;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    margin: 0 0 32px;

    @media (max-width: $xxxl) {
      margin: 0 0 24px;
    }

    @media (max-width: $lg) {
      width: 40%;
    }

    @media (max-width: $md) {
      width: 30%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }

    img {
      display: block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 16px;
      object-fit: cover;

      @media (max-width: $md) {
        width: 56px;
        height: 56px;
        margin-right: 8px;
      }
    }
  }

  &__user-name {
    @media (max-width: $lg) {
      width: 50%;
    }

    @media (max-width: $sm) {
      width: 90%;
    }
  }

  &__name {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
    max-width: 210px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: $lg) {
      width: 100%;
    }

    @media (max-width: $sm) {
      max-width: 90%;
    }
  }

  &__active {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
  }

  &__wrap {
    margin: 0 0 24px;

    @media (max-width: $sm) {
      width: 100%;
    }

    &_edit {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        z-index: 2;
        background: #6a67ce;
        animation-name: border;
        animation-timing-function: ease;
        animation-duration: 0.5s;
        transform: scale(1);
        transform-origin: center;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.5px;
        background: #d3d2e0;
        z-index: 1;
      }

      @keyframes border {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    }

    &_birthday {
      @media (max-width: $xl) {
        display: none;
      }
    }

    /deep/ {
      .level {
        width: max-content;
      }

      .switch-widget__indicator {
        cursor: default;
      }

      .vdpComponent.vdpWithInput input {
        width: 100%;
        font-size: 14px;
        line-height: 28px;
        color: $text-disabled;
        border: none;
        outline: none;
      }

      .select-bg {
        width: 100%;
      }

      .data-picker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 48px;
        padding: 12px 18px;
        border-radius: 24px;
        cursor: pointer;
        background: #fafbfc;

        &__label {
          white-space: nowrap;
          color: $text-subdued;
        }
      }

      .vdpComponent {
        width: 100%;
      }

      .vdpClearInput {
        &::before {
          left: 20% !important;
        }
      }
    }
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__content {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    font-feature-settings: "salt" on;
    color: $text-default;
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &_email {
      display: flex;
      margin-right: 10px;
    }

    &_email,
    &_phone {
      span {
        margin-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::selection {
          background: transparent;
        }
      }

      i {
        color: $icon-subdued;
        cursor: pointer;

        &:hover {
          color: $icon-hovered;
        }
      }
    }
  }

  &__edit-box {
    font-size: 14px;
    line-height: 28px;
    color: $text-disabled;
    border: none;
    outline: none;
  }

  &__black-spheres {
    display: flex;
    justify-content: space-between;
  }

  .icon-calendar-secondary {
    margin-right: 8px;
  }

  &__black-spheres-date {
    margin: 0;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: $bg-surface-light;
    border-radius: 12px;
  }
}
</style>
