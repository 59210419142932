import { Response, Service } from "@/helpers/types/service";
// @ts-ignore
import { domainV2 } from "@/globalVariables";
import httpClientV2 from "@/api/client/httpClientV2";
import store from "@/store";

export enum deviceType {
  web = "web"
}

export type loginData = {
  email: string;
  password: string;
  device_type: deviceType;
};

class AuthService extends Service {
  login(loginData: loginData): Response<string> {
    return httpClientV2
      .post<any>(
        `/login`,
        { ...loginData },
        {
          headers: {
            skipAuth: true,
            withCredentials: true
          }
        }
      )
      .then((resp) => {
        return resp.data.access_token;
      });
  }

  logout(): Response<any> {
    return httpClientV2
      .post<any>(
        `/logout`,
        {},
        {
          headers: {
            skipAuth: true,
            withCredentials: true
          }
        }
      )
      .then((resp) => {
        return resp;
      });
  }

  refresh(): Response<string> {
    return httpClientV2
      .post<any>(
        "/refresh",
        {},
        {
          headers: {
            skipAuth: true,
            withCredentials: true
          }
        }
      )
      .then((resp) => {
        return resp.data.access_token;
      });
  }
}

export default new AuthService();
