<template>
  <ul class="chat-list">
    <li v-for="chat in chatList" :key="chat.channel.id">
      <ChatItem :chat="chat" />
    </li>
    <!--    <li v-for="(chat, index) in person" :key="index">-->
    <!--      <ChatItem :chat="chat" />-->
    <!--    </li>-->
  </ul>
</template>

<script>
import { mapState } from "vuex";
import ChatListItem from "@/modules/Messenger/components/ChatListItem";
import ChatItem from "@/modules/Messenger/components/ChatItem/ChatItem";
export default {
  name: "ChatList",
  mixins: [],
  props: {},
  components: { ChatItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["chatList"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-list {
  display: flex;
  flex-flow: column;
  max-height: calc(100% - 70px);
  overflow-y: auto;
  margin: 0;
  padding: 12px 24px;
  list-style: none;
  @media screen and (max-width: $md) {
    padding-bottom: 70px;
  }
  @media screen and (max-width: $xxs) {
    padding: 12px 12px 70px;
  }
  &.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}
</style>
