<template>
  <section class="my-tasks">
    <MyTasksHeader @updateInfo="getTaskTableData" @updateParams="updateParams" />
    <div class="my-tasks__container">
      <MyTasksTable :tasksData="taskList" @updateInfo="getTaskTableData" :params="paramData" />
    </div>
  </section>
</template>

<script>
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import MyTasksHeader from "@/modules/TaskManager/components/MyTasks/MyTasksHeader";
import MyTasksTable from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTable";
import { EventBus } from "@/main";

export default {
  name: "MyTasks",
  mixins: [],
  props: {},
  components: { MyTasksTable, MyTasksHeader },
  data() {
    return {
      statusValue: [
        {
          id: "all",
          name: "Все статусы"
        }
      ],
      projectValue: [],
      roleValue: [
        {
          id: "executor",
          name: "Исполнитель"
        }
      ],
      paramData: null
    };
  },
  created() {
    this.getCookieValue();
  },
  beforeDestroy() {
    EventBus.$off("updateData");
    EventBus.$off("paginationEvent");
  },
  mounted() {
    this.initial();
    EventBus.$on("updateData", () => {
      TaskModule[TaskMutationTypes.SET_LOADER](true);
      this.resetTable();
      this.getTaskTableData(this.paramData);
    });
    EventBus.$on("paginationEvent", () => {
      this.paginationTaskTableData();
    });
  },
  methods: {
    getTaskTableData(params) {
      this.resetTable();
      TaskModule.getTaskListAction(params);
    },
    paginationTaskTableData() {
      this.paramData = TaskModule.params;
      this.paramData.page = TaskModule.fromPag;
      Object.keys(this.paramData).forEach((n) =>
        this.valueSortList.forEach(
          (item) => String(n) === `orders[${item.value}]` && delete this.paramData[n]
        )
      );
      this.paramData[`orders[${this.valueSort.value}]`] = this.valueSort.isAsc ? "asc" : "desc";
      TaskModule.getTaskListAction(this.paramData);
    },
    updateParams(params) {
      this.paramData = params;
      TaskModule[TaskMutationTypes.SET_PARAMS](params);
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("my-task-table"));
      this.projectValue = setting.projects ? setting.projects : [];
      this.statusValue = setting.status ? setting.status : [];
      this.roleValue = setting.roles ? setting.roles : [];
      this.paramData = {
        page: TaskModule.fromPag,
        per_page: 10,
        projects: this.projectValue.length ? this.projectValue.map((item) => item.id) : undefined,
        status: this.statusValue[0]?.id,
        role: this.roleValue[0]?.id,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
    },
    initial() {
      TaskModule[TaskMutationTypes.SET_LOADER](true);
      this.paramData = {
        page: 1,
        per_page: 15,
        projects: this.projectValue.length ? this.projectValue.map((item) => item.id) : undefined,
        status: this.statusValue[0]?.id,
        role: this.roleValue[0]?.id,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
      this.getTaskTableData(this.paramData);
    },
    resetTable() {
      TaskModule[TaskMutationTypes.RESET_TASK_LIST]();
      TaskModule[TaskMutationTypes.SET_FROM_PAGE](1);
    }
  },
  computed: {
    taskList() {
      return TaskModule.taskList.filter((item) => item.childs_count !== null);
    },
    valueSort() {
      return TaskModule.valueSort;
    },
    valueSortList() {
      return TaskModule.valueSortList;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.my-tasks {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__container {
    height: 90%;

    @media (max-width: $md) {
      max-width: 100%;
      height: 85%;
    }

    @media (max-width: 850px) and (orientation: landscape) {
      height: 75%;
    }

    @media (max-width: $sm) {
      height: 90%;
    }

    @media (max-width: $sm) and (orientation: landscape) {
      height: 55%;
    }

    @media (max-width: $xs) {
      height: 80%;
    }

    @media (max-width: $xxs) {
      height: 75%;
    }
  }
}
</style>
