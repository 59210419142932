<template>
  <div class="select-bg">
    <div class="select-content-bg" v-click-outside="hideModalOptions">
      <div
        class="select-content"
        @click="openModalOptions"
        :style="!showModal ? 'border-radius: 24px' : 'border-radius: 24px 24px 0 0'"
      >
        <div
          class="select-content-placeholder select-content-placeholder_empty"
          v-if="!value.length"
        >
          {{ placeholder }}: Не выбрано
        </div>
        <div class="select-content-placeholder" v-if="value.length">{{ placeholder }}:</div>
        <div class="select-content-value-bg" v-if="value.length">
          <div class="select-content-value" v-for="val in value" :key="val.id">
            {{ val.name }}
          </div>
        </div>
        <i
          class="icon-up"
          :style="!showModal ? 'transform: rotate(180deg);' : ''"
          v-show="(value.length <= 1 && multiple) || (value.length < 1 && !multiple)"
        ></i>
        <i
          class="icon-cross"
          v-show="(value.length > 1 && multiple) || (value.length >= 1 && !multiple)"
          @click.self="deleteAllOption"
        ></i>
      </div>
      <ModalSelect
        v-if="showModal"
        :value="value"
        :optionData="optionData"
        :placeholder="placeholder"
        :itemName="itemName"
        :entity="entity"
        :maxCount="maxCount"
        :multiple="multiple"
        :actionEntity="actionEntity"
        :hiddenItems="hiddenItems"
        :createMode="createMode"
        :createEntity="createEntity ? createEntity : 'actionCreate'"
        @pushOptionToValue="pushOptionToValue"
        @deleteOptionToValue="deleteOptionToValue"
        @pushAllOption="pushAllOption"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ModalSelect from "@/components/Widgets/Select/SelectPicker/ModalSelect";

export default {
  name: "SelectPicker",
  mixins: [],
  props: [
    "entity",
    "actionEntity",
    "multiple",
    "placeholder",
    "itemName",
    "keyValue",
    "maxCount",
    "label",
    "editValue",
    "findId",
    "createMode",
    "hiddenItems",
    "createEntity",
    "optionData"
  ],
  components: { ModalSelect },
  data() {
    return {
      value: [],
      showModal: false
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.editValue && this.editValue.length) {
        this.value = this.editValue;
      }
    }, 0);
    this.$root.$on("hideSelectModal", () => {
      this.showModal = false;
    });
  },
  methods: {
    openModalOptions() {
      this.showModal = !this.showModal;
    },
    hideModalOptions() {
      this.showModal = false;
    },
    deleteAllOption() {
      event.stopPropagation();
      this.value = [];
    },
    pushAllOption(options) {
      this.value = JSON.parse(JSON.stringify(options));
    },
    selectOptionWithoutQuarter(options) {
      this.value = options;
    },
    pushOptionToValue(option) {
      if (this.multiple) {
        if (this.maxCount) {
          if (this.value.length < this.maxCount) {
            this.value.push(option);
          } else {
            this.$root.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Максимальное кол-во ${this.maxCount} !`
            });
          }
        } else {
          this.value.push(option);
        }
      } else {
        if (this.value.length) {
          this.value.splice(0, 1);
          this.value.push(option);
        } else {
          this.value.push(option);
        }
      }
    },
    deleteOptionToValue(option) {
      let index = this.value.findIndex((val) => val.id === option.id);
      this.value.splice(index, 1);
    }
  },
  computed: {},
  watch: {
    value() {
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
      this.$emit(`change_select_value`, this.value);
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.icon-cross,
.icon-up {
  color: $color-text-secondary;
  font-size: 16px;
  transition: 0.3s;
}

.select-bg {
  width: max-content;

  .select-content-bg {
    position: relative;
    width: 100%;

    .select-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 48px;
      padding: 12px 18px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
      background: #fafbfc;

      .icon-up,
      .icon-cross {
        font-size: 16px;
        color: $icon-subdued;

        &:hover {
          color: $icon-hovered;
        }
      }

      .select-content-placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-disabled;
        margin-right: 8px;

        &_empty {
          margin-right: 0;
        }
      }

      .select-content-value-bg {
        display: flex;
        align-items: center;
        width: max-content;
        flex-shrink: 0;

        .select-content-value {
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
          padding: 5px;
        }

        .select-content-count {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: $color-background;
          margin: 0 6px 0 4px;
          background: $color-charts-purple-1;
          padding: 2px 3px;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>
