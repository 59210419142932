import { domainV2 } from "@/globalVariables";
import httpClient from "@/api/client/httpClientV2";
import { EventBus } from "@/main";
import centrifuge from "@/centrifuge";

export default {
  data() {
    return {
      files: [],
      pullName: "",
      loading: false,
      chunksArr: [],
      fileUploader: [],
      oldEditFiles: [],
      fileUploadingId: [],
      uploadedFiles: [],
      arrFiles: [],
      deleteFiles: [],
      formats: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/x-flv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/mp4",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/x-msvideo",
        "video/3gpp",
        "video/mpeg",
        "audio/mpeg"
      ]
    };
  },
  methods: {
    uploadFile(value) {
      if (!this.validationFiles(value)) return false;
      this.files = value;
      this.getUrlFile();
      this.fileUploader = [];
      this.oldEditFiles = [];
      if (this.files.length) {
        this.files.forEach((item) => {
          if (item.file) {
            this.fileUploader.push(item);
          }
          if (!item.file) {
            this.oldEditFiles.push(item);
          }
        });
      }
    },
    validationFiles(val) {
      let size = 0;
      this.files = val.filter((item) => {
        if (item.size > 5242880) {
          EventBus.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Размер файла ${item.name} превышает 5 мб`
          });
          this.loading = false;
          this.$emit("toggleFormLoader", false);
          return false;
        }
        size += item.size;

        if (item.extension) {
          return true;
        }
        if (item.file && item.file.type) {
          if (this.formats.includes(item.file.type)) {
            return true;
          } else {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Формат файла ${item.file.type} не поддерживается`
            });
            this.loading = false;
            this.$emit("toggleFormLoader", false);
            return false;
          }
        }
      });
      if (size > 5242880) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Общий размер файлов превышает 5 мб`
        });
        this.loading = false;
        this.$emit("toggleFormLoader", false);
        return false;
      }
      return true;
    },
    getUrlFile() {
      this.files.forEach((item) => {
        if (item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png") {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = () => {
            item.path = reader.result;
          };
        }
      });
    },
    filesUploaded() {
      this.changeLoading(false);
      this.handleEditFiles({
        ids: [...this.oldEditFiles.map((item) => item.id), ...this.fileUploadingId],
        files: [...this.oldEditFiles, ...this.uploadedFiles]
      });
    },
    handleErrorUpload() {},
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPull(arr) {
      this.$emit("toggleFormLoader", true);
      if (!arr.length) {
        this.filesUploaded();
        return null;
      }
      httpClient({
        url: `${domainV2}/comments/files/create_chunk`,
        method: "POST",
        data: {
          file_name: arr[0].name
        }
      })
        .then((response) => {
          this.pullName = response.data.pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.changeLoading(false);
          this.$emit("toggleFormLoader", false);
          this.errorAlert(error);
          this.handleErrorUpload();
        });
      this.changeLoading(false);
    },
    async createChunks(arr) {
      await this.getBase64(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArr = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArr = base64.match(/.{1,5000000}/g);
        }
        // TODO: Если файлы большие
        // this.chunksArr = base64.match(/.{1,5000000}/g)
      });
      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.changeLoading(false);
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        let nulls =
          Array.from(this.chunksArr.length.toString())
            .map(() => "0")
            .join("") + "0";
        let chunkName = nulls.substr(0, nulls.length - i.toString().length) + i;
        await httpClient({
          url: `${domainV2}/comments/files/upload_chunk`,
          method: "POST",
          data: {
            pool_name: this.pullName,
            base_64_chunk: item,
            chunk_name: chunkName
          }
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.changeLoading(false);
            this.loading = false;
            this.$emit("toggleFormLoader", false);
            this.errorAlert(error);
            this.handleErrorUpload();
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domainV2}/comments/files/collect_pool`,
        method: "POST",
        data: {
          pool_name: this.pullName
        }
      })
        .then((response) => {
          this[`${response.data.channel}`] = centrifuge.subscribe(
            response.data.channel,
            (message) => {
              if (message.data.code === 422) {
                this.loading = false;
                this.$emit("toggleFormLoader", false);
                this.$root.$emit("showNotification", {
                  type: "error",
                  timeout: 5000,
                  label: `Выбранный Вами файл не подходит по расширению или размеру!`
                });
                return false;
              }
              this.saveFileId(message.data.message, response.data.channel, arr);
            }
          );
          this[`${response.data.channel}`].history().then(
            (resp) => {
              if (resp.publications.length) {
                if (
                  resp.publications[resp.publications.length - 1].data.message &&
                  typeof resp.publications[resp.publications.length - 1].data.message !==
                    "number" &&
                  isNaN(resp.publications[resp.publications.length - 1].data.message)
                ) {
                  this.loading = false;
                  this.$emit("toggleFormLoader", false);
                  this.$root.$emit("showNotification", {
                    type: "error",
                    timeout: 3000,
                    label: `${resp.publications[resp.publications.length - 1].data.message}`
                  });
                  return false;
                }
                this.saveFileId(
                  Number(resp.publications[resp.publications.length - 1].data.message),
                  response.data.channel,
                  arr
                );
              }
            },
            (err) => {
              this.loading = false;
              this.$emit("toggleFormLoader", false);
              console.error(err);
            }
          );
        })
        .catch((error) => {
          this.$emit("toggleFormLoader", false);
          this.changeLoading(false);
          this.handleErrorUpload(arr);
          this.errorAlert(error);
        });
    },
    changeLoading(val) {
      this.loading = val;
    },
    saveFileId(item, channel, arr) {
      let arrFilesId = JSON.parse(JSON.stringify(this.fileUploadingId));
      arrFilesId.push(item);
      this.fileUploadingId = [...new Set(arrFilesId)];
      this[`${channel}`].unsubscribe();
      arr.splice(0, 1);
      this.getOneFile(item, arr);
    },
    handleEditFiles(files) {},
    getOneFile(id, arr) {
      httpClient({
        url: `${domainV2}/comments/files/get_one`,
        method: "GET",
        params: {
          id: id
        }
      })
        .then((resp) => {
          if (resp.data) this.arrFiles.push(resp.data);
          this.createPull(arr);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$emit("toggleFormLoader", false);
          this.errorAlert(error);
        });
    }
  }
};
