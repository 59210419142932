<template>
  <li class="menu-item" @click="handleClick">
    <i :class="'icon-' + item.icon"></i>
    <span>{{ item.name }}</span>
  </li>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OptionItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["observeModalItem"]),
    handleClick() {
      this.observeModalItem(this.item.val);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.menu-item {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $text-subdued;
  padding: 10px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  user-select: none;
  display: flex;
  align-items: center;
  span {
    color: #343748;
  }
  i {
    font-size: 16px;
    margin-right: 16px;
  }
  @media (hover: hover) {
    &:hover {
      background-color: $border-subdued;
    }
  }
  &:active {
    background-color: $system-grey-five;
  }
}
</style>
