




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class Skeleton extends Vue {
  @Prop({
    type: String,
    default: 0
  })
  borderRadius!: number;
}
