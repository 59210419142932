<template>
  <transition name="fade" appear>
    <SystemMessage :source="source" :id="setId" v-if="isSystemMessage" />
    <PersonMessage :source="source" :isPin="isPin" :id="setId" v-else />
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import SystemMessage from "@/modules/Messenger/components/Message/SystemMessage";
import PersonMessage from "@/modules/Messenger/components/Message/PersonMessage";
export default {
  name: "Message",
  mixins: [],
  props: {
    source: {
      type: Object,
      default: () => {}
    },
    isPin: {
      type: Boolean,
      default: false
    }
  },
  components: { PersonMessage, SystemMessage },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
  computed: {
    isSystemMessage() {
      return this.source.type === "system";
    },
    setId() {
      if (this.isPin) {
        return "message_pin_" + this.source.id;
      } else {
        return "message_" + this.source.id;
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
