<template>
  <div class="show-edit-task" @mousedown.self="closeCurtain">
    <div class="show-edit-task__container">
      <div class="show-edit-task__loader" v-if="loader">
        <Spinner />
      </div>
      <div class="show-edit-task__header" v-if="!loader">
        <i class="icon-arrow-back" @click="closeCurtain"></i>
        <TabsContent :tabs="tabsList" v-model="currentTab" />
        <div class="show-edit-task__control">
          <i class="icon-launch" @click="openFullInfoTask"></i>
          <ActionsList
            :actionList="setActionList"
            class="header__action-list"
            @handleAction="handleAction"
          />
        </div>
      </div>
      <div class="show-edit-task__body" v-if="!loader">
        <component
          :is="bodyComponent"
          :isOwner="isOwner"
          :isMember="isMember"
          :isAuthor="isAuthor"
          :isExecutor="isExecutor"
          :isParentEditUser="isParentEditUser"
          :status="setStatus"
          @getTask="updateCommentsCountTask"
          @completeTask="completeTask"
          @handleEdit="handleEditSubtasks"
          @getSubtasksList="getSubtasksList"
        />
      </div>
      <ConfirmModal
        v-if="showConfirmModal"
        :title="confirmParams.title"
        :description="confirmParams.description"
        @cancel="showConfirmModal = false"
        @accept="confirmParams.callback()"
      />
    </div>
  </div>
</template>

<script>
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import copyText from "@/helpers/copyText";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import { EventBus } from "@/main";
import Task from "@/modules/TaskManager/views/Task";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import KanbanModule, { KanbanMutationTypes } from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import TaskBlackSphereModule, {
  TaskBlackSphereMutationTypes
} from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";

export default {
  name: "CurtainShowAndEditTask",
  mixins: [copyText],
  props: {},
  components: {
    Spinner,
    ActionsList,
    TabsContent,
    ConfirmModal
  },
  data() {
    return {
      currentTab: "description",
      showConfirmModal: false,
      subtasksData: [],
      confirmParams: null
    };
  },
  beforeDestroy() {
    EventBus.$off("updateDataAfterCreate");
    EventBus.$off("updateData");
  },
  mounted() {
    TaskModule[TaskMutationTypes.SET_TASK_LOADER](true);
    EventBus.$on("updateData", () => {
      this.getTask();
    });
  },
  methods: {
    closeCurtain() {
      this.showConfirm(false);
      TaskManagerModuleV2.closeCurtainShowAndEditTask();
      KanbanModule[KanbanMutationTypes.SET_CURRENT_EDITABLE_TASK](null);
    },
    openFullInfoTask() {
      if (this.currentTask) {
        this.$router.push({
          name: "Task",
          params: {
            id: this.currentTask.task_id
          },
          query: { alias: this.currentTask.alias }
        });
      }
      this.closeCurtain();
    },
    getTask() {
      TaskModule[TaskMutationTypes.RESET_SUBTASK_BY_TASK]([]);
      TaskModule[TaskMutationTypes.SET_SUBTASK_PAGE](1);
      TaskModule.getCurrentTaskAction({
        task_id: this.currentTask.task_id,
        alias: this.currentTask.alias
      }).then((error) => {
        if (error === 404 || error === 422 || error === 403) {
          this.closeCurtain();
        }
      });
    },
    updateCommentsCountTask() {
      TaskModule.updateCommentsCountTaskAction({
        task_id: this.currentTask.task_id,
        alias: this.currentTask.alias
      });
    },
    getSubtasksList() {
      TaskModule.getSubtaskListAction(this.setSubtaskParams);
    },
    handleAction(val) {
      switch (val.actionType) {
        case "createSubTask": {
          TaskManagerModuleV2.openCurtainConstructor({
            currentCreationTypeId: 4,
            currentTaskForCreation: this.currentTask
          });
          break;
        }
        case "execute": {
          this.completeTask();
          break;
        }
        case "CopyLink": {
          this.copyStringLink({
            id: this.currentTask.task_id,
            alias: this.currentTask.alias
          });
          break;
        }
        case "delete": {
          this.setConfirmParams(false);
          this.showConfirm(true);
        }
      }
    },
    deleteTask() {
      TaskModule.deleteTaskAction(this.currentTask);
      KanbanModule[KanbanMutationTypes.DELETE_TASK_FROM_LISTS_BY_STAGE]({
        stageId: this.currentTask.stage_id,
        projectId: this.currentTask.project_id,
        taskId: this.currentTask.id
      });
      this.showConfirm(false);
    },
    showConfirm(val) {
      this.showConfirmModal = val;
    },
    completeTask() {
      if (this.currentTask.parent_id && !this.isEndStatus) {
        this.setConfirmParams(true);
        this.showConfirmModal = true;
      } else {
        if (this.isEndStatus) {
          TaskModule.updateCurrentTaskAction({
            id: this.currentTask.id,
            status_id: this.setStatusesList.find((item) => item.is_start === true).id
          });
        } else {
          if (this.currentTask.childs_count && this.currentTask.childs_count > 0) {
            TaskModule.checkSubtaskStatusesAction({ id: this.currentTask.id });
            if (TaskModule.completeSubtasks) {
              this.setCompleteStatus();
            } else {
              this.setConfirmParams(true);
              this.showConfirmModal = true;
            }
          } else {
            this.setCompleteStatus();
          }
        }
      }
    },
    setCompleteStatus() {
      TaskModule.updateCurrentTaskAction({
        id: this.currentTask.id,
        status_id: this.setStatusesList.find((item) => item.is_end === true).id
      });
      this.showConfirmModal = false;
    },
    handleEditSubtasks(val) {
      TaskModule.updateCurrentTaskAction({
        ...val
      });
      TaskModule.getSubtaskListAction({ id: this.currentTask.id, page: 1, per_page: 15 });
    },
    setConfirmParams(status) {
      if (status) {
        if (this.currentTask.parent_id) {
          this.confirmParams = {
            title: "Выполнить подзадачу",
            description: `Вы уверены, что хотите выполнить подзадачу?`,
            callback: () => this.setCompleteStatus()
          };
        } else {
          this.confirmParams = {
            title: "Выполнить задачу",
            description:
              this.currentTask.childs_count && this.currentTask.childs_count > 0
                ? `Задача содержит невыполненные вложенные подзадачи. Вложенные подзадачи выполнятся вместе с ней. Вы уверены, что хотите выполнить задачу?`
                : `Вы уверены, что хотите выполнить задачу?`,
            callback: () => this.setCompleteStatus()
          };
        }
      } else {
        if (this.currentTask.parent_id) {
          this.confirmParams = {
            title: "Удалить подзадачу",
            description: `Вы уверены, что хотите удалить подзадачу?`,
            callback: () => this.deleteTask()
          };
        } else {
          this.confirmParams = {
            title: "Удалить задачу",
            description:
              this.currentTask.childs_count && this.currentTask.childs_count > 0
                ? `Подзадачи будут удалены вместе с задачей. Вы уверены, что хотите удалить задачу?`
                : `Вы уверены, что хотите удалить задачу?`,
            callback: () => this.deleteTask()
          };
        }
      }
    }
  },
  computed: {
    currentTask() {
      return TaskModule.currentTask;
    },
    setSubtaskParams() {
      return {
        id: this.currentTask.id,
        page: TaskModule.subtaskPage,
        per_page: 15
      };
    },
    isEndStatus() {
      return this.setStatus ? this.setStatus.is_end : false;
    },
    setStatusesList() {
      return TaskModule.statusesList;
    },
    setStatus() {
      return this.setStatusesList.find((item) => item.id === this.currentTask.status_id);
    },
    loader() {
      return TaskModule.taskLoader;
    },
    bodyComponent() {
      switch (this.currentTab) {
        case "description":
          return () =>
            import(
              `@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/DescriptionBodyTask`
            );
        case "subtasks":
          return () =>
            import(
              `@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/SubtaskList`
            );
        case "history":
          return () =>
            import(
              `@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/HistoryTask/HistoryTask`
            );
        case "comments":
          return () =>
            import(
              `@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CommentsBodyTask`
            );
        default:
          return () =>
            import(
              `@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/DescriptionBodyTask`
            );
      }
    },
    setActionList() {
      const list = [];
      if (this.currentTask && !this.currentTask.parent_id) {
        list.push({
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить подзадачу",
          actionType: `createSubTask`
        });
      }
      list.push({
        isShow: true,
        iconType: "chain",
        actionText: "Копировать ссылку",
        actionType: `CopyLink`
      });
      if (this.isOwner || this.isAuthor) {
        list.push({
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        });
      }
      return list;
    },
    tabsList() {
      const tabs = [];
      tabs.push({
        label: "Описание",
        value: "description"
      });
      if (!this.currentTask.parent_task_name) {
        tabs.push({
          label: "Подзадачи",
          value: "subtasks",
          count: this.subtaskCount
        });
      }

      tabs.push({
        label: "История",
        value: "history"
      });

      tabs.push({
        label: "Комментарии",
        value: "comments",
        count: this.currentTask.count_comments
      });
      return tabs;
    },
    ownerList() {
      return ProjectModule.ownerList;
    },
    isOwner() {
      return this.ownerList.some((item) => item.user_id === this.getUserId);
    },
    membersList() {
      return ProjectModule.membersList;
    },
    isMember() {
      return this.membersList.some((item) => item.user_id === this.getUserId);
    },
    isAuthor() {
      return this.currentTask.author_id === this.getUserId;
    },
    isExecutor() {
      return this.currentTask.executor_id === this.getUserId;
    },
    isParentEditUser() {
      return (
        this.currentTask.parent_author_id === this.getUserId ||
        this.currentTask.parent_executor_id === this.getUserId
      );
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    subtaskCount() {
      return TaskModule.subtaskCount;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.show-edit-task {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50% 0 0;
  }

  &__container {
    position: fixed;
    width: 768px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    background: $surface-default;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);

    @media (max-width: $xxxl) {
      width: 494px;
    }

    @media (max-width: $md) {
      width: 100%;
    }
  }

  &__header {
    padding: 20px 24px 24px 8px;
    border-bottom: 1px solid $system-grey-four;
    display: flex;
    align-items: center;

    @media (max-width: $xxxl) {
      align-items: flex-start;
    }

    .icon-arrow-back {
      color: $icon-subdued;
      margin: 0 8px 0 0;
      display: none;
      font-size: 20px;

      @media (max-width: $md) {
        display: block;
      }
    }
  }
  &__control {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 12px;
      cursor: pointer;
      transition: 0.3s all ease;
      &:hover {
        color: $link-default;
      }
    }
  }
  &__body {
    height: calc(100% - 100px);
    overflow: auto;
  }

  /deep/ {
    .tabs {
      @media (max-width: $xxxl) {
        margin-right: 8px;
        overflow-x: scroll;
      }
    }

    .tabs__list {
      @media (max-width: $xxxl) {
        height: 40px;
      }

      @media (max-width: $sm) {
        height: 30px;
      }
    }

    .subtasks {
      width: 100%;
    }

    .subtasks__title {
      font-weight: 900;
      font-size: 30px;
      line-height: 28px;
      color: $text-default;

      @media (max-width: $sm) {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .subtasks__header {
      border-right: none;
      padding: 32px 24px;
      height: max-content;

      @media (max-width: $md) {
        border-top: none;
      }
    }

    .subtasks__list {
      height: calc(100vh - 180px);
    }

    .task-history,
    .comments {
      padding: 24px 0 0;
      height: 100%;
    }

    .task-history__list {
      max-height: 100%;
    }

    .task-history__header,
    .task-comment__header {
      display: none;
    }
  }
}
</style>
