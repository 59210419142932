





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonNewDesign extends Vue {
  @Prop({
    default: "default",
    type: String
  })
  type!: string;

  @Prop({
    default: "Кнопка",
    type: String
  })
  title!: string;

  @Prop({
    default: "",
    type: String
  })
  iconName!: string;

  @Prop({
    default: false,
    type: Boolean
  })
  iconLeft!: boolean;

  @Prop({
    default: false,
    type: Boolean
  })
  loader!: boolean;

  @Prop({
    default: "button",
    type: String
  })
  eventType!: string;

  get setType() {
    switch (this.type) {
      case "disabled": {
        return "button-new-design_disabled";
      }
      case "simple": {
        return "button-new-design_simple";
      }
      case "round": {
        return "button-new-design_round";
      }
      default: {
        return "";
      }
    }
  }

  get setLoaderClass() {
    return this.loader ? "aop-button_loader" : "";
  }
}
