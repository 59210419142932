<template>
  <div
    class="app-sidebar__link nav-link"
    @mouseenter="subscribeHover"
    @mouseleave="unsubscribeHover"
    :id="link.icon"
  >
    <IconWrapSidebar :iconName="link.icon" />
    <div class="nav-link__tooltip" :style="{ top: topTooltip + 'px' }">{{ link.label }}</div>
    <div class="app-sidebar__count" v-if="isMessenger && countUnreadMessage"></div>
    <div class="app-sidebar__count" v-if="isNotifications && notificationListCountUnread"></div>
  </div>
</template>

<script>
import IconWrapSidebar from "@/layout/SideNav/SidenavIcon/IconWrapSidebar";
import { mapState } from "vuex";

export default {
  name: "LayoutSideNavLink",
  mixins: [],
  props: {
    link: Object
  },
  components: { IconWrapSidebar },
  data() {
    return {
      deepOpen: false,
      topTooltip: 0
    };
  },
  mounted() {},
  beforeDestroy() {
    this.unsubscribeHover();
  },
  methods: {
    subscribeHover() {
      this.getPositionLink();
      document.getElementById("sidebar").addEventListener("scroll", () => {
        this.getPositionLink();
      });
    },
    unsubscribeHover() {
      this.getPositionLink();
      document.getElementById("sidebar").removeEventListener("scroll", () => {
        this.getPositionLink();
      });
    },
    getPositionLink() {
      const link = document.getElementById(this.link.icon);
      if (link) {
        this.topTooltip = link.getBoundingClientRect().top + 10;
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", ["countUnreadMessage"]),
    ...mapState("Notification", ["notificationListCountUnread"]),
    isMessenger() {
      return this.link.icon === "Messenger";
    },
    isNotifications() {
      return this.link.icon === "Bell";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.app-sidebar {
  &__link {
    margin-bottom: 6px;
    position: relative;
  }

  &__count {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #ff1f1f;
    border-radius: 50%;
    top: 15px;
    right: 12px;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
  }

  .nav-link {
    &__tooltip {
      display: none;
      position: fixed;
      z-index: 1000;
      left: 90px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #202223;
      white-space: nowrap;
      background: #ffffff;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 4px;
      padding: 5px 9px;
      user-select: none;
    }

    @media (hover: hover) {
      &:hover {
        .nav-link__tooltip {
          display: unset;
          @media (max-width: $md) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
