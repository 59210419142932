<template>
  <div class="modal" @mousedown.self="closeModal">
    <div class="modal__window">
      <h2 class="modal__title">Дублировать проблемы?</h2>
      <p class="modal__content">
        Выберите период, откуда будут продублированы незавершенные проблемы.
      </p>
      <p class="modal__label">Период</p>
      <Select
        v-model="fromPeriod"
        :items="setFromPeriodList"
        :showAvatar="false"
        :multiple="false"
        :showSearchPanel="false"
      />
      <p class="modal__content">
        Выберите период, куда будут продублированы незавершенные проблемы.
      </p>
      <p class="modal__label">Период</p>
      <Select
        v-model="toPeriod"
        :items="setToPeriodList"
        :showAvatar="false"
        :multiple="false"
        :showSearchPanel="false"
      />
      <div class="modal__footer">
        <Button :type="'cancel'" :title="'Отмена'" @handleButton="closeModal" />
        <Button :title="'Дублировать'" @handleButton="duplicateProblems" />
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "IspReviewDuplicateProblemsModal",
  mixins: [],
  props: [],
  components: { Button, Select },
  data() {
    return {
      fromPeriod: [],
      toPeriod: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["duplicateAllQuarterProblems"]),
    ...mapMutations("IspModule", ["SHOW_DUPLICATE_MODAL"]),
    duplicateProblems() {
      this.duplicateAllQuarterProblems({
        from_period_id: this.fromPeriod.length ? this.fromPeriod[0].id : "",
        to_period_id: this.toPeriod.length ? this.toPeriod[0].id : ""
      });
    },
    closeModal() {
      this.SHOW_DUPLICATE_MODAL(false);
    }
  },
  computed: {
    ...mapState("IspModule", ["quarterList"]),
    setFromPeriodList() {
      return this.quarterList.filter(
        (item) => item.status === "Завершен" || item.status === "В работе"
      );
    },
    setToPeriodList() {
      return this.quarterList.filter((item) => item.status === "Не начат");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 55, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &__window {
    padding: 32px 24px;
    width: 716px;
    background: $surface-default;
    border-radius: 16px;
  }

  &__title {
    margin: 0 0 40px;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__label {
    margin: 0 0 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $text-subdued;
  }

  &__content {
    margin: 24px 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $text-accent;
  }

  &__footer {
    margin: 40px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ {
    .select-modal__body {
      height: 170px;
    }
  }
}
</style>
