<template>
  <div class="vacation">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Vacation",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.vacation {
  //padding: 24px 32px;
  //height: 100vh;
  width: 100%;
  //width: calc(100% - 20px);
  height: 100%;
  padding: 20px 16px 0;
}
</style>
