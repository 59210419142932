<template>
  <div class="statistics__work-time work-time">
    <ul class="work-time__list">
      <li class="work-time__item">
        <p class="work-time__subtitle">Норма</p>
        <p class="work-time__time">{{ statistic.month_normal_time }}</p>
      </li>
      <li class="work-time__item">
        <p class="work-time__subtitle">Отработано</p>
        <p class="work-time__time">{{ statistic.tracked_time }}</p>
      </li>
    </ul>
    <ul class="work-time__list work-time__list_status">
      <li class="work-time__item" v-if="statistic.difference_time.includes('-') && excludeSeconds">
        <StatisticsStatus :type="'shortage'" :time="statistic.difference_time.replace('-', '')" />
      </li>
      <li
        class="work-time__item"
        v-if="
          !statistic.difference_time.includes('-') &&
          statistic.tracked_time !== statistic.month_normal_time &&
          excludeSeconds
        "
      >
        <StatisticsStatus :type="'excess'" :time="statistic.difference_time" />
      </li>
    </ul>
  </div>
</template>

<script>
import StatisticsStatus from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/StatisticsStatus";

export default {
  name: "StatisticsWorkTime",
  mixins: [],
  props: {
    activity: {
      type: Array,
      default: () => {
        return [];
      }
    },
    statistic: {
      type: Object,
      default: () => {}
    }
  },
  components: { StatisticsStatus },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    /** Функция установки процента активности сотрудника
     * @returns {*} - процент активности сотрудника, округленный до целого числа
     */
    setActivity() {
      return this.activity[0].percent.split(".")[0];
    },
    excludeSeconds() {
      return !(
        Number(this.statistic.difference_time.split(":")[2]) < 60 &&
        this.statistic.difference_time.split(":")[0] === "00" &&
        this.statistic.difference_time.split(":")[1] === "00"
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.work-time {
  //width: 100%;

  @media (max-width: $sm) {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media (max-width: 1700px) {
      flex-wrap: wrap;
    }
    @media (max-width: $sm) {
      width: auto;
    }

    &_status {
      margin-top: 40px;
      @media (max-width: 1700px) {
        margin-top: 16px;
      }
      @media (max-width: $xl) {
        margin-top: 16px;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 16px;
      @media (max-width: 1700px) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      @media (max-width: 1300px) {
        margin-right: 8px;
        margin-bottom: 0;
      }
      @media (max-width: 685px) {
        margin-right: 64px;
      }
    }
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: $text-disabled;

    @media (max-width: $xl) {
      margin: 0;
      font-size: 10px;
    }
  }

  &__time {
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: $text-default;

    @media (max-width: $xl) {
      font-size: 14px;
    }
  }
}
</style>
