<template>
  <div class="search-result__wrap">
    <h3 class="search-result__title" v-if="searchMessageMode">Поиск по каналу:</h3>
    <div class="search-result__channel" v-if="searchMessageMode">
      <ChatItem :chat="currentChat" :showTime="false" :showMessage="false" :isSendMessage="true" />
      <i class="icon-cross" @click="closeSearchMode"></i>
    </div>
    <ul class="search-result" v-if="searchMessages.length">
      <li class="search-result__item" v-for="item in searchMessages" :key="item.id">
        <SearchMessageItem :message="item" />
      </li>
    </ul>
    <ul class="search-result">
      <li v-if="personChat.length || person.length">
        <h3 class="search-result__group" @click="handleOpenPersonList">
          <span>Личные</span> <i class="icon-down" :class="{ 'icon-down_open': showPerson }"></i>
        </h3>
        <div v-show="showPerson">
          <ul class="search-result__list" v-if="personChat.length">
            <li class="search-result__item" v-for="item in personChat" :key="item.id">
              <SearchItem :chat="item" />
            </li>
          </ul>
          <ul class="search-result__list" v-if="person.length">
            <li class="search-result__item" v-for="item in person" :key="item.id">
              <SearchItem :chat="item" />
            </li>
          </ul>
        </div>
      </li>
      <li v-if="closeChat.length || openChat.length">
        <h3 class="search-result__group" @click="handleOpenGroupList">
          <span>Групповые</span> <i class="icon-down" :class="{ 'icon-down_open': showGroup }"></i>
        </h3>
        <div v-show="showGroup">
          <ul class="search-result__list" v-if="closeChat.length">
            <li class="search-result__item" v-for="item in closeChat" :key="item.id">
              <SearchItem :chat="item" />
            </li>
          </ul>
          <ul class="search-result__list" v-if="openChat.length">
            <li class="search-result__item" v-for="item in openChat" :key="item.id">
              <SearchItem :chat="item" />
            </li>
          </ul>
        </div>
      </li>
      <li
        v-if="
          !personChat.length &&
          !person.length &&
          !closeChat.length &&
          !openChat.length &&
          !searchMessages.length
        "
      >
        <p class="search-result__empty">По вашему запросу ничего не найдено</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchMessageItem from "@/modules/Messenger/components/Search/SearchMessageItem";
import SearchItem from "@/modules/Messenger/components/Search/SearchItem";
import ChatItem from "@/modules/Messenger/components/ChatItem/ChatItem";
export default {
  name: "SearchResult",
  mixins: [],
  props: {},
  components: { SearchItem, ChatItem, SearchMessageItem },
  data() {
    return {
      showPerson: true,
      showGroup: true
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setSearchMessageMode"]),
    closeSearchMode() {
      this.setSearchMessageMode(false);
    },
    handleOpenPersonList() {
      this.showPerson = !this.showPerson;
    },
    handleOpenGroupList() {
      this.showGroup = !this.showGroup;
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "userListResult",
      "searchMessageMode",
      "currentChat",
      "personChat",
      "person",
      "closeChat",
      "openChat",
      "searchMessages"
    ])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.search-result {
  all: unset;
  list-style: none;
  display: flex;
  flex-flow: column;
  padding: 8px 24px;
  &__wrap {
    max-height: calc(100% - 70px);
    overflow-y: auto;
  }
  &__item {
    cursor: pointer;
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #343748;
    padding: 24px 24px 0;
    margin: 0;
  }
  &__list {
    list-style: none;
    padding: 12px 24px;
  }
  .icon-down {
    transition: all 0.3s ease;
    margin-left: 8px;
    &_open {
      transform: rotate(180deg);
    }
  }
  &__group {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #343748;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 16px 24px;
    border-top: 1px solid #eae8f0;
    border-bottom: 1px solid #eae8f0;
  }
  &__empty {
    padding: 24px;
    margin: 0;
    font-size: 14px;
    color: $text-subdued;
    text-align: center;
  }
  &__channel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px 8px 16px;
    border-bottom: 1px solid #eae8f0;
    /deep/ {
      .chat-item {
        width: 100%;
        @media (hover: hover) {
          &:hover {
            background-color: transparent;
            cursor: default;
          }
        }
      }
    }
  }
}
.icon-cross {
  font-size: 14px;
  color: $icon-subdued;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (hover: hover) {
    &:hover {
      color: $icon-default;
    }
  }
}
</style>
