














































import TimerButton from "@/modules/TimeTracker/component/TImerButton/TimerButton";
import { Component, Prop, Vue } from "vue-property-decorator";
import TimeTracker from "@/modules/TimeTracker/store";
import { EventBus } from "@/main";
import { ITask, ITimeProject } from "@/modules/TimeTracker/interface/interface";
import moment from "moment";
import Input from "@/modules/UiKit/components/Inputs/Input";

@Component({
  components: { TimerButton, Input }
})
export default class TaskContentItem extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  task!: ITask;
  @Prop({
    type: Object,
    default: () => {}
  })
  project!: ITimeProject;
  @Prop({
    type: Object,
    default: () => {}
  })
  activeTask!: ITask | null;
  timerId: undefined | number = undefined;
  today: string = moment(new Date()).format("YYYY-MM-DD");
  value: string = "";

  goToLink() {
    if (this.task.link) {
      window.open(this.task.link, "_blank");
    }
  }

  handleEdit() {
    if (!this.isEdit) {
      this.$emit("editCurrentTask", this.task);
      this.value = this.task.name;
      this.$nextTick(() => {
        const parentNode = document.querySelector(`#inputEdit${this.task.id}`);
        if (parentNode) {
          //@ts-ignore
          parentNode.children[0].focus();
        }
      });
    } else {
      this.value = "";
      this.$emit("editCurrentTask", { ...this.task, id: 0 });
    }
  }

  handleDelete() {
    TimeTracker.confirmTaskDelete({ id: +this.task.id, project_id: this.project.id });
  }

  async doneEdit() {
    if (this.value !== this.task.name) {
      await TimeTracker.editTask({
        id: +this.task.id,
        project_id: this.project.id,
        name: this.value
      });
    }
    this.handleEdit();
  }

  async deleteTask() {
    await TimeTracker.deleteTask({ id: +this.task.id, project_id: this.project.id });
  }

  toggleTask() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (this.isWeekLimit) {
        if (this.isActive) {
          TimeTracker.toggleTime({
            isActive: !this.isActiveTask,
            task: this.task,
            project: this.project
          });
        }
        EventBus.$emit("showNotification", {
          type: "warning",
          timeout: 5000,
          label: "Достигнут недельный лимит!"
        });
      } else {
        TimeTracker.toggleTime({
          isActive: !this.isActiveTask,
          task: this.task,
          project: this.project
        });
      }
    }, 300);
  }

  get isEdit() {
    return this.activeTask ? this.task.id === this.activeTask.id : false;
  }

  get format() {
    return this.deadline ? moment(this.deadline).format("DD.MM.YYYY") : "";
  }

  get tag() {
    return this.task.alias;
  }

  get deadline() {
    return this.task?.deadline;
  }

  get isOverdueTask() {
    return moment(this.deadline) < moment(this.today) || this.deadline === this.today;
  }

  get isComingSoonDeadline() {
    return moment(this.deadline) < moment(this.today).add(2, "days");
  }

  get isWeekLimit() {
    return TimeTracker.isWeekLimit;
  }

  get limit() {
    return TimeTracker.limit;
  }

  get isActive() {
    return TimeTracker.hasActiveTimeTracker;
  }

  get isActiveTask() {
    return TimeTracker.isActiveTask(this.task.id);
  }
}
