














import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import GroupItem from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarGroup/GroupItem.vue";

@Component({
  components: { GroupItem }
})
export default class GroupList extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  selectedGroups!: Array<any>;
  activeGroup: Array<any> = [];

  setActiveGroups(val) {
    this.activeGroup = val;
    if (this.selectedGroups.length && this.selectedGroups.find((item) => item.id === val.id)) {
      const groups = this.selectedGroups.filter((item) => item.id !== val.id);
      this.$emit("handleChangeGroups", { new: groups, old: this.selectedGroups });
    } else {
      const groups = this.selectedGroups.concat(this.activeGroup);
      this.$emit("handleChangeGroups", { new: groups, old: this.selectedGroups });
    }
  }

  handleDeleteGroup(id) {
    this.$emit("handleDeleteGroup", id);
  }

  get groups() {
    return CalendarModule2.groups;
  }
}
