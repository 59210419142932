<template>
  <div class="form-group">
    <label class="form-group__container">
      <span
        v-if="label"
        :class="['form-group__label', disabled ? 'form-group__label_disabled' : '']"
        >{{ label }}:</span
      >
      <input
        @input.trim="Change__Text($event)"
        type="text"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength"
        :minlength="minlength"
        :class="['form-group__input', value ? 'form-group__input_complete' : '']"
        v-mask="mask"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "InputSecondary",
  data() {
    return {
      value: ""
    };
  },
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: String
    },
    minlength: {
      type: String
    },
    keyUpEnter: {
      type: Function
    },
    tooltip: {
      type: String
    },
    keyAdd: {
      type: [String, Number]
    },
    pattern: {
      type: String
    },
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: [String, Number]
    },
    styleInput: {
      type: [String, Number, Array, Object]
    },
    mask: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [],
  mounted() {
    this.$root.$on(`input-text:clear_${this.unique}`, () => {
      this.value = "";
    });
    if (!this.value) {
      this.value = null;
    }
    if (this.editValue) {
      this.value = this.editValue;
    }
  },
  methods: {
    Change__Text(event) {
      this.value = event.target.value;
      let data = {
        eventName: "Change__Text",
        data: {
          event: event,
          placeholder: this.placeholder,
          value: this.value,
          readonly: this.disabled,
          label: this.label,
          maxlength: this.maxlength,
          keyAdd: this.keyAdd,
          tooltip: this.tooltip,
          pattern: this.pattern,
          disabled: this.disabled,
          unique: this.unique
        }
      };
      this.$root.$emit(`text:change_${this.unique}`, this.value);
      this.$emit(`textChanged`, this.value);
    }
  },

  computed: {
    emitOnEnter() {
      return this.keyUpEnter || false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

@mixin input-box-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.03);
}

.form-group {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  background: #fafbfc;
  border-radius: 24px;

  &__container {
    display: flex;
  }

  &__label {
    max-width: 130px;
    margin-right: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-subdued;
    white-space: nowrap;

    &_disabled {
      color: $text-disabled;
    }
  }

  &__input {
    width: calc(100% - 130px);
    height: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: transparent;
    border: none;
    outline: none;
    color: $text-default;

    &:disabled {
      color: $text-disabled;
    }

    &::placeholder {
      font-family: inherit;
      color: $text-disabled;
    }
  }
}
</style>
