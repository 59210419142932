<template>
  <div class="confirmation-user">
    <div class="confirmation-user__window">
      <div class="confirmation-user__mobile-title">
        <i class="icon-sort-arrow" @click="$emit('hideModal')"></i>
        {{ title }}
        <span v-if="showCount">({{ member_users.length }})</span>
      </div>
      <div class="confirmation-user__search">
        <Widget
          type="string"
          placeholder="Поиск по Имени"
          unique="searchConformation"
          maxlength="100"
        ></Widget>
        <div class="confirmation-user__search-icon">
          <i class="icon-search-secondary"></i>
        </div>
      </div>
      <div class="confirmation-user__panel" v-if="buttonsPanel">
        <button class="confirmation-user__button" type="button" @click="selectAllUsers">
          Выбрать всех
        </button>
        <button class="confirmation-user__button" type="button" @click="deleteAllUsers">
          Очистить
        </button>
      </div>
      <div class="confirmation-user__main">
        <div class="confirmation-user__select" v-if="filter">
          <p class="confirmation-user__text">Показать всех пользователей:</p>
          <Widget
            class="widget__select"
            type="select-filter"
            :entity="'Department'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'Confirmation_Department'"
            :placeholder="'Выберите отделы'"
            :multiple="true"
          />
        </div>
        <ul
          class="confirmation-user__list"
          v-if="!loading"
          :class="[
            !filter ? 'confirmation-user__list_no-filter' : '',
            !buttonsPanel ? 'confirmation-user__list_no-button' : ''
          ]"
        >
          <li
            class="confirmation-user__item"
            v-for="item in member_users"
            :key="item.id"
            @click="selectUser(item)"
          >
            <ConfirmationUserItem :item="item" :selectMembers="selectUserMember" />
          </li>
        </ul>
        <div class="confirmation-user__loader" v-else>
          <Loader />
        </div>
        <div class="confirmation-user__empty" v-if="!member_users.length && !loading">
          Ничего не найдено :(
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";
import ClickOutside from "vue-click-outside";

import { domain } from "@/globalVariables";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import ConfirmationUserItem from "@/components/Widgets/ConfirmationsUsersModal/ConfirmationUserItem";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ConfirmationsUsersModal",
  mixins: [],
  props: {
    maxCount: {
      type: Number,
      default: Infinity
    },
    title: {
      type: String,
      default: "Добавить участников"
    },
    editConfirmationsPerson: {
      type: Array,
      default() {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    keyValue: {
      type: String,
      default: ""
    },
    filter: {
      type: Boolean,
      default: true
    },
    buttonsPanel: {
      type: Boolean,
      default: true
    },
    hideMember: {
      type: [String, Number],
      default: () => {
        return "";
      }
    }
  },
  components: { ConfirmationUserItem, Loader, Widget },
  data() {
    return {
      member_users: [],
      changeSearch: "",
      loading: true,
      selectUserMember: this.editConfirmationsPerson.length ? this.editConfirmationsPerson : [],
      filterDepartment: [],
      showModal: true
    };
  },
  mounted() {
    this.$root.$on("text:change_searchConformation", (val) => {
      this.changeSearch = val;
      this.getUserMembers();
    });
    this.$root.$on(`changeSelectValue:Confirmation_Department`, (val) => {
      this.setDepartment(val);
      this.getUserMembers();
    });
    this.getUserMembers();
  },
  beforeDestroy() {
    this.$root.$off("text:change_searchConformation");
    this.$root.$off("changeSelectValue:Confirmation_Department");
  },
  methods: {
    setDepartment(val) {
      this.filterDepartment = val.map((item) => {
        return { "departments.id": item.id };
      });
    },
    getUserMembers() {
      httpClient({
        method: "GET",
        url: `/User/getItems`,
        params: {
          _count: 200,
          departments: this.filterDepartment.length
            ? JSON.stringify(this.filterDepartment)
            : undefined,
          is_active: true,
          _search: JSON.stringify({ full_name: this.changeSearch }),
          _with: JSON.stringify(["Roles", "Departments"])
        }
      }).then((response) => {
        let items = response.data.data.items;
        this.member_users = this.hideMember
          ? items.filter((item) => item.id !== this.hideMember)
          : items;
        this.loading = false;
      });
    },
    // TODO: описать функцию в JSDoc
    selectUser(user) {
      if (this.isAdmin) {
        this.$root.$emit("changeUserVacationOrder", user);
        this.selectUserMember = [user];
        return false;
      }
      const select = this.selectUserMember.find((item) => item.id === user.id);
      if (this.maxCount === 1) {
        this.selectUserMember = [user];
      } else if (select === undefined && this.selectUserMember.length < this.maxCount) {
        this.selectUserMember.push(user);
        const index = this.selectUserMember.findIndex((item) => item.id === user.id);
        this.selectUserMember[index].index = index + 1;
        this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.selectUserMember);
      } else {
        const index = this.selectUserMember.findIndex((item) => item.id === select.id);
        this.selectUserMember.splice(index, 1);
        this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.selectUserMember);
      }
      this.$emit("changeSelectMember", this.selectUserMember);
    },
    checkSelect(id) {
      return this.selectUserMember.find((item) => item.id === id) !== undefined;
    },
    selectAllUsers() {
      this.selectUserMember = JSON.parse(JSON.stringify(this.member_users));
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.selectUserMember);
      this.$emit("changeSelectMember", this.selectUserMember);
    },
    deleteAllUsers() {
      this.selectUserMember = [];
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.selectUserMember);
      this.$emit("changeSelectMember", this.selectUserMember);
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

::-webkit-scrollbar {
  position: relative;
  height: 5px;
  overflow-x: auto;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 8px;

  background: $bg-gray-400;
}
::-webkit-scrollbar-track {
  background: $bg-surface-light;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.confirmation-user {
  position: absolute;
  z-index: 12;
  max-width: 304px;
  width: 100%;

  @media screen and (max-width: 850px) and (max-height: 450px) {
    bottom: 0;
    left: 0;
    position: fixed;
    max-width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(33, 32, 44, 0.8);
    z-index: 100;
  }

  @media screen and (max-width: $md) {
    bottom: 0;
    left: 0;
    position: fixed;
    max-width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(33, 32, 44, 0.8);
    z-index: 100;
  }

  &__window {
    width: 100%;
    display: flex;
    background: $bg-surface-primary;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;
    flex-flow: column;
    height: 304px;
    background: $bg-surface-primary;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      z-index: 10000000;
      align-items: flex-start;
      border-radius: 0;
    }

    @media (max-width: $md) {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      z-index: 10000000;
      align-items: flex-start;
      border-radius: 0;
    }
  }

  &__mobile-title {
    display: none;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 12px 24px;
    border-bottom: 1px solid $border-disabled;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      display: flex;
    }

    @media (max-width: $md) {
      display: flex;
    }

    i {
      display: block;
      color: $icon-subdued;
      font-size: 18px;
      transform: rotate(90deg);
      margin-right: 20px;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }
    }
  }

  &__search {
    position: relative;
    padding: 12px 20px;
    background: $bg-surface-light;
    border-radius: 14px 14px 0 0;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      width: 100%;
    }

    @media (max-width: $md) {
      width: 100%;
    }

    /deep/ {
      .form-group {
        width: 100%;
        max-width: 304px;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          max-width: 100%;
        }

        @media (max-width: $md) {
          max-width: 100%;
        }
      }

      .form-group__input {
        width: 100%;
        height: 40px;
        padding: 10px 50px 10px 20px;
        background: $surface-default;
        border-radius: 40px;
        border: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        outline: none;
      }

      .form-group__input::placeholder {
        color: $text-subdued;
      }

      .form-group__input:hover {
        box-shadow: none !important;
        border: 1px solid $border-subdued;
      }
    }
  }

  &__panel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px;
    background: $bg-surface-light;
    border-bottom: 1px solid $border-subdued;
  }

  &__button {
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    color: $text-subdued;
    outline: none;

    &:hover {
      color: $icon-hovered;
    }

    &:active {
      color: $icon-pressed;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    flex-flow: column;
    /deep/ {
      .select-bg {
        flex-shrink: 0;
        width: 100%;
        min-width: 210px;
        max-width: 248px;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          min-width: 100%;
        }

        @media (max-width: $md) {
          min-width: 100%;
        }
      }
    }

    .modal-items {
      top: 100%;
      border-radius: 0 0 20px 20px;
      background-color: $bg-gray-75;
      transform: scale(0);
      border: 1px solid #d3d2e0;
      border-top: none;

      input {
        background-color: $bg-gray-75;
        border: none;
        color: $text-subdued;
        font-weight: 500;
      }

      .chosen-options-bg .chosen-options {
        background-color: $action-primary;
      }
    }

    .option-bg .option-name {
      color: $text-subdued;
    }

    .month-picker-background .icon-calendar {
      display: none;
    }

    .month-picker-background #month-picker .month_picker_container {
      transform: translateX(35%);
    }

    .button-month-picker {
      padding: 14px 0 0;
      border: none;
      width: auto;
      font-size: 14px;
      line-height: 29px;
      font-weight: 500;
      color: $bg-gray-800;

      .year {
        color: $text-subdued;
      }
    }
  }

  &__search-icon {
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 23px;
    color: $icon-subdued;

    .icon-search-secondary {
      font-size: 18px;
    }
  }

  &__select {
    padding: 12px 20px;
  }

  &__list {
    display: flex;
    flex-flow: column;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 194px;
    height: 100%;
    overflow-x: auto;
    &_no-button {
      max-height: 225px;
    }
    @media screen and (max-width: 850px) and (max-height: 450px) {
      max-height: calc(100% - 170px);
      height: calc(100vh - 170px);
    }

    @media screen and (max-width: $sm) {
      max-height: calc(100% - 170px);
      height: calc(100vh - 170px);
    }

    &_no-filter {
      @media screen and (max-width: 850px) and (max-height: 450px) {
        max-height: calc(100% - 80px);
        height: calc(100vh - 80px);
      }

      @media screen and (max-width: $md) {
        max-height: calc(100% - 80px);
        height: calc(100vh - 80px);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $bg-gray-200;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .icon-check {
      margin-left: auto;
      margin-right: 20px;
      font-size: 24px;
      color: $icon-subdued;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      background-color: $bg-surface-light;

      .icon-check {
        color: $icon-disabled;
      }
    }
  }

  &__loader {
    align-self: center;
    margin-top: 10px;
  }
  &__empty {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    line-height: 29px;
    font-weight: 500;
    padding: 15px 0 10px;
    color: $text-disabled;
  }
}
</style>
