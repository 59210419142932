<template>
  <div class="check-list__form">
    <Input
      v-model.trim="text"
      :id="setId"
      :maxlength="255"
      :minlength="1"
      :placeholder="'Добавьте пункт'"
      @keydownEnter="handleAccept"
    />
    <div class="check-list__control">
      <button
        class="check-list__create"
        v-if="text.length"
        type="button"
        aria-label="Создать пункт"
        @click="handleAccept"
      >
        <i class="icon-check"></i>
      </button>
      <button
        class="check-list__cancel"
        type="button"
        aria-label="Отменить создание пункта"
        @click="handleCancel"
      >
        <i class="icon-cross"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import ClickOutside from "vue-click-outside";

export default {
  name: "CheckListForm",
  mixins: [],
  props: {
    value: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: null
    },
    showAccept: {
      type: Boolean,
      default: true
    }
  },
  components: { Input },
  data() {
    return {
      text: ""
    };
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.value) {
        this.text = this.value;
      }
    },
    handleAccept() {
      this.$emit("handleAccept", this.text);
      this.text = "";
    },
    handleCancel() {
      this.$emit("handleCancel");
      this.text = "";
    }
  },
  computed: {
    setId() {
      return this.id ? `check-list_${this.id}` : "check-list";
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.check-list {
  &__form {
    position: relative;

    /deep/ {
      .input__control {
        padding: 8px 70px 8px 16px;
      }
    }
  }

  &__control {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
  }

  &__create,
  &__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;

    i {
      font-size: 23px;
      color: $icon-subdued;
    }

    &:hover i {
      color: $icon-hovered;
    }

    &:active i {
      color: $icon-pressed;
    }
  }

  &__cancel {
    margin-left: 8px;
  }
}
</style>
