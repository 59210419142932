


















import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import CheckboxInput from "@/aopV2/components/UIComponents/Input/Check/Checkbox/CheckboxInput";
import ActionList, { IActionItem } from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";

@Component({
  components: { ActionList, CheckboxInput }
})
export default class GroupItem extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  group!: any;
  @Prop({
    type: Number,
    default: 0
  })
  groupsLength!: number;
  @Prop({
    type: Array,
    default: () => []
  })
  selectedGroups!: Array<any>;

  actionList: Array<IActionItem> = [
    {
      name: "Просмотр",
      id: 1,
      type: "show"
    },
    {
      name: "Редактировать",
      id: 2,
      type: "edit"
    },
    {
      name: "Удалить",
      id: 3,
      type: "delete"
    }
  ];
  currentType: IActionItem = {
    name: "Просмотр",
    id: 1,
    type: "show"
  };
  isShowList: boolean = false;

  selectItem() {
    this.$emit("setActiveGroups", this.group);
  }

  setCurrentType(type) {
    this.currentType = type;
    if (type.type === "show") {
      CalendarModule2.setCurrentGroup(this.group);
      CalendarModule2.toggleShowGroupModal({ show: true, mode: "show" });
    }
    if (type.type === "edit") {
      CalendarModule2.setCurrentGroup(this.group);
      CalendarModule2.toggleShowGroupModal({ show: true, mode: "edit" });
    }
    if (type.type === "delete") {
      this.confirmDeleteGroup();
    }
    this.closeList();
  }

  confirmDeleteGroup() {
    ModalModule.setConfirmSetting({
      title: "Удалить группу?",
      text: "Все выбранные участники будут удалены и вам придется создавать группу заново.",
      confirmButton: "Удалить",
      cancelButton: "Отменить",
      handleConfirm: () => this.deleteGroup(),
      handleCancel: () => this.cancelConfirm()
    });
    ModalModule.toggleConfirmation(true);
  }

  cancelConfirm() {
    ModalModule.setConfirmSetting(null);
    ModalModule.toggleConfirmation(false);
  }

  deleteGroup() {
    CalendarModule2.deleteGroupAction({
      id: this.group.id
    });
    this.cancelConfirm();
    this.$emit("handleDeleteGroup", this.group.id);
  }

  closeList() {
    this.isShowList = false;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  get isSelected() {
    return this.selectedGroups.some((item) => item.id === this.group.id);
  }
}
