<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'full_name'">
      <User :avatar="user.avatar" :name="user.full_name" :id="user.id" :showTooltip="false" />
    </div>
    <div class="table__content" v-if="cell.key === 'from_date'">
      {{ setFromDate }}
    </div>
    <div class="table__content" v-if="cell.key === 'to_date'">
      {{ setToDate }}
    </div>
    <div class="table__content" v-if="cell.key === 'absence_type'">
      {{ absence.name }}
    </div>
    <div class="table__content" v-if="cell.key === 'count_days'">
      {{ setCountDays }}
    </div>
    <div class="table__content" v-if="cell.key === 'table_control'">
      <button
        class="table__button table__button_delete"
        v-if="isAdminAbsence"
        type="button"
        @click="askToDeleteAbsence"
      >
        <i class="icon-delete"></i>
      </button>
      <button
        class="table__button table__button_edit"
        v-if="isAdminAbsence"
        type="button"
        @click="handleEdit"
      >
        <i class="icon-edit"></i>
      </button>
    </div>
  </div>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";
import AbsenceModule from "@/modules/Absence/store/AbsenceModule";

export default {
  name: "AbsenceCellContent",
  mixins: [formatDateAndTime],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isAdminAbsence: {
      type: Boolean,
      default: false
    }
  },
  components: {
    User
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    askToDeleteAbsence() {
      AbsenceModule.confirmDeleteAbsence(this.row.id);
    },
    handleEdit() {
      AbsenceModule.setEdit(this.row);
    }
  },
  computed: {
    user() {
      return this.$store.getters["UsersModule/userById"](this.row.user_id)[0];
    },
    absence() {
      return AbsenceModule.absenceType(this.row.absence_type_id);
    },
    setToDate() {
      return this.row.to_date ? this.setDateFormatFullWithoutReverse(this.row.to_date) : "-";
    },
    setFromDate() {
      return this.row.from_date ? this.setDateFormatFullWithoutReverse(this.row.from_date) : "-";
    },
    setCountDays() {
      return this.row.count_days ? this.row.count_days : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__wrap {
    display: flex;
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;

    /deep/ {
      .user__name {
        font-weight: 500;
        max-width: 250px;
      }
    }
  }

  &__button {
    width: 20px;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;

    &_delete {
      margin-left: auto;
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      i {
        color: $icon-hovered;
      }
    }

    &:active {
      color: $icon-pressed;
    }
  }

  i {
    color: $icon-subdued;
    font-size: 18px;
  }
}
</style>
