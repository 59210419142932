<template>
  <section class="profile">
    <h1 class="profile__title visually-hidden">Карточка сотрудника</h1>
    <TabsRouter :tabs="tabsList" />
    <router-view />
  </section>
</template>

<script>
import TabsRouter from "@/components/Widgets/TabsWidget/TabsRouter";
export default {
  name: "UserCardSecond",
  mixins: [],
  props: [],
  components: { TabsRouter },
  data() {
    return {
      tabsList: [
        {
          label: "Профиль сотрудника",
          path: "UserInfo"
        },
        {
          label: "Должностные папки",
          path: "UserFolders"
        },
        {
          label: "Образование",
          path: "CurrentUserEducation"
        },
        {
          label: "Путь в компании",
          path: "UserHistory"
        }
      ]
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.profile {
  font-family: "SF", sans-serif !important;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 30px);
  padding: 24px 40px;
  background: $background-gradient;

  @media screen and (max-width: $xxxl) {
    padding: 24px 24px;
  }
  @media screen and (max-width: $md) {
    padding: 24px 19px;
  }
  @media screen and (max-width: $sm) {
    padding: 24px 9px;
  }

  .tabs {
    margin-bottom: 24px;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
</style>
