<template>
  <vue-slider v-model="range" :tooltip="'always'" min="1" max="99" :disabled="disabled" :value="value" />
</template>

<script>
import VueSlider from "vue-slider-component";
export default {
  name: "InputRange",
  mixins: [],
  model: {
    prop: "range",
    event: "change"
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 1
    }
  },
  components: { VueSlider },
  data() {
    return {
      range: 1,
      min: 1,
      max: 99
    };
  },
  beforeDestroy() {},
  mounted() {
    setTimeout(() => {
      this.range = this.value;
    }, 0);
  },
  methods: {},
  computed: {},
  watch: {
    range() {
      this.$emit("change", this.range);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
/deep/ {
  .vue-slider {
    position: relative;
    width: 100%;
    height: 15px !important;
    border-radius: 24px;
    -webkit-appearance: none;
    background-color: #fafafe;
    padding: 0 !important;
  }

  .vue-slider-rail {
    border-radius: 24px;
    -webkit-appearance: none;
    background-color: #fafafe;
    height: 15px !important;
  }

  .vue-slider-process {
    height: 15px !important;
    border-radius: 24px;
    background: linear-gradient(270deg, #3bdede 0%, #875ef1 137.24%);
  }

  .vue-slider-dot {
    position: relative;
    top: 5px !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
    border: 2px solid #fafafe;
  }

  .vue-slider-dot-tooltip {
    top: 24px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fcfcfe;
  }
}
</style>
