<template>
  <div class="history-item" :id="setId">
    <div class="history-item__header">
      <div class="history-item__type">
        <img
          :src="require('@/modules/Isp/assets/IspProblem/task.svg')"
          alt=""
          v-if="type === 'ISPTask'"
        />
        <img
          :src="require('@/modules/Isp/assets/IspProblem/problem.svg')"
          alt=""
          v-if="type === 'ISPProblem'"
        />
      </div>
      <User
        :avatar="historyItem.user.avatar"
        :name="historyItem.user.full_name"
        :info="`[${historyItem.user.roles.length ? historyItem.user.roles[0].name : ''}]`"
        :showTooltip="false"
        :size="'40px'"
        :row="true"
        :id="historyItem.user.id"
      />
      <div class="history-item__date">
        {{ setDateFormatWithoutTime(historyItem.datetime_of_creation) }}
        {{ setTimeFormatWithoutDate(historyItem.datetime_of_creation) }}
      </div>
    </div>
    <div class="history-item__body">
      <p class="history-item__content">
        {{ historyItem.description }}
      </p>
    </div>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import { mapActions, mapState } from "vuex";
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "HistoryItem",
  mixins: [formatDateAndTime],
  props: {
    historyItem: {
      type: Object,
      default: null
    }
  },
  components: { User },
  data() {
    return {
      target: null,
      inViewModel: true
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.target);
  },
  mounted() {
    this.target = document.querySelector(`#history-item-${this.historyItem.id}`);
    this.observer = new IntersectionObserver(
      () => {
        this.inViewModel = !this.inViewModel;
        if (this.inViewModel) {
          const currentProblemHistoryCopy = JSON.parse(JSON.stringify(this.currentProblemHistory));
          currentProblemHistoryCopy.reverse();
          if (
            currentProblemHistoryCopy[1] &&
            this.historyItem.id === currentProblemHistoryCopy[1].id
          ) {
            if (this.currentProblemHistoryTotalCount !== this.currentProblemHistoryCounter)
              this.scrollGetHistoryProblemAction();
          }
        }
      },
      { root: null, rootMargin: "0px", threshold: 1 }
    );
    this.observer.observe(this.target);
  },
  methods: {
    ...mapActions("IspModule", ["scrollGetHistoryProblemAction"])
  },
  computed: {
    ...mapState("IspModule", [
      "currentProblemHistory",
      "currentProblemHistoryTotalCount",
      "currentProblemHistoryCounter",
      "currentInfoProblem"
    ]),
    setId() {
      return `history-item-${this.historyItem.id}`;
    },
    type() {
      return this.historyItem.changeable_type.split("\\")[3];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.history-item {
  padding: 0 0 50px 44px;
  position: relative;
  border-left: 2px solid #eae8f0;
  &:last-child {
    border-left: 2px solid white;
  }
  &__type {
    top: 0;
    left: -13px;
    position: absolute;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    /deep/ {
      .user__name {
        max-width: 220px;
        width: max-content;
        font-weight: 500;
        font-size: 16px;
      }
      .user__description {
        max-width: 220px;
        width: max-content;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
  &__date {
    font-size: 16px;
    color: $text-default;
  }
  &__content {
    font-size: 16px;
    font-weight: 400;
    color: $text-default;
    line-height: 20px;
    word-break: break-word;
  }
}
</style>
