<template>
  <div class="channel-title" v-click-outside="editTitle">
    <FileUploadChannelAvatar
      v-bind="{
        multiple: false,
        drop: false,
        dropDirectory: false,
        fileDrop: false
      }"
      :editValue="iconChannel ? returnSrcFile(iconChannel) : ''"
      @changeIconChanel="changeIconChanel"
      :isEditable="isEditable"
    />
    <h2 @click="setEditMode" v-show="!editMode" v-if="isEditable" class="channel-title-editable">
      {{ editChannelValue.name }}
    </h2>
    <h2 v-else>{{ editChannelValue.name }}</h2>
    <div class="channel-title__edit" v-show="editMode">
      <Input
        :placeholder="'Название канала'"
        v-model.trim="editableTitle"
        @keydownEnter="editTitle"
        maxlength="70"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { domain, domainStorage } from "@/globalVariables";

import FileUploadChannelAvatar from "@/modules/News/components/Channel/FileUploadChannelAvatar";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ChannelTitle",
  mixins: [],
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    iconChannel: {
      type: String,
      default: ""
    }
  },
  components: { Input, FileUploadChannelAvatar },
  data() {
    return {
      editMode: false,
      editableTitle: "",
      fileUploader: [],
      pullName: []
    };
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["editChannelAction"]),
    setEditMode() {
      this.editMode = true;
      this.editableTitle = this.editChannelValue.name;
      this.$emit("setEditMode");
    },
    editTitle(e) {
      // const parentNode = e ? e.path.find((item) => item.className === "show-edit-channel") : null;
      if (this.editMode || !e) {
        const editedChannel = {
          name: this.editableTitle
        };
        this.editChannelAction({
          editedChannel,
          channelId: this.editChannelValue.id
        }).then(() => {
          this.editMode = false;
          this.editChannelValue.name = this.editableTitle;
          this.$emit("cancelEditMode");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Название канала успешно изменено!`
          });
        });
      }
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    changeIconChanel(file) {
      this.fileUploader = file;
      this.createChunksPoolFiles();
    },
    async createChunksPoolFiles() {
      if (!this.fileUploader.length) {
        return null;
      }
      this.createPull(this.fileUploader);
    },
    createPull(arr) {
      if (!arr.length) {
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].file_name
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.chunk_pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      let base64 = arr[0].file.replace(arr[0].file.substring(0, arr[0].file.search(",") + 1), "");
      this.chunksArr = base64.match(/.{1,500000}/g);

      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: "POST",
        data: [this.pullName]
      })
        .then((response) => {
          const editedChannel = {
            icon: response.data.data.path
          };
          this.editChannelAction({
            editedChannel,
            channelId: this.editChannelValue.id
          }).then(() => {
            this.iconChannel = response.data.data.path;
            this.editChannelValue.icon = response.data.data.path;
            this.$root.$emit("showNotification", {
              type: "success",
              timeout: 5000,
              label: `Аватарка канала успешно изменена!`
            });
          });
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    ...mapState("NewsModule", ["editChannelValue"])
  },
  watch: {
    currentTeam() {
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
.channel-title {
  width: 100%;
  display: flex;
  align-items: center;
  //height: 35px;
  &__edit {
    width: 100%;
  }
  h2 {
    margin: 0;
    padding: 0;
    width: fit-content;
    border: 1px solid #fff;
    border-radius: 5px;
    //padding: 2px 8px;
    word-break: break-word;
  }
  &-editable {
    &:hover {
      border: 1px solid #d2d2d9;
      box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    }
  }
  /deep/ {
    input {
      width: 100%;
    }
  }
}
</style>
