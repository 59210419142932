<template>
  <div
    :class="[
      'critical',
      setClass,
      { critical_isDropDown: isDropDown, critical_open: showList && isDropDown }
    ]"
    v-click-outside="hideList"
    @click="showList = !showList"
  >
    <p class="critical__label">{{ setLabel }}</p>
    <button class="critical__button" v-if="isDropDown">
      <i class="icon-down"></i>
    </button>
    <ul class="critical__list" v-if="showList && isDropDown">
      <li class="critical__item" v-for="item in criticalList" :key="item.id" @click="setItem(item)">
        {{ item.name || item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "StatusCritical",
  mixins: [],
  props: {
    critical: {
      type: String,
      default: ""
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDropDown: {
      type: Boolean,
      default: false
    },
    criticalList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    setItem(value) {
      this.$emit("input", value);
    },
    hideList() {
      this.showList = false;
    }
  },
  computed: {
    setClass() {
      switch (this.critical) {
        case 4:
          return "critical_comfort";
        case 1:
          return "critical_block";
        case 3:
          return "critical_large";
        case 2:
          return "critical_critical";
        default:
          return "";
      }
    },
    setLabel() {
      switch (this.critical) {
        case 4:
          return "Комфорт";
        case 1:
          return "Блокирующая";
        case 3:
          return "Значительная";
        case 2:
          return "Критическая";
        default:
          return "Нет";
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.critical {
  position: relative;
  font-family: "Roboto", "Arial", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 135px;
  width: max-content;
  padding: 6px 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: $surface-default;
  border: 1px solid $surface-default;
  box-sizing: border-box;
  border-radius: 24px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    #c3c7d2;
  &_open {
    i {
      transform: rotate(180deg);
    }
  }
  &_isDropDown {
    cursor: pointer;
    border-radius: 8px;
    padding-left: 10px;
  }

  &_comfort {
    background: $critical-comfort;
  }

  &_large {
    background: $critical-large;
  }

  &_critical {
    background: $critical-critical;
  }

  &_block {
    background: $critical-block;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 0 0 8px;
    cursor: pointer;

    i {
      display: flex;
      transition: all 0.3s ease;
      color: $surface-default;
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background: $surface-default;
    border: 1px solid $system-grey-four;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  &__item {
    padding: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $text-default;
    cursor: pointer;

    &:hover {
      background: $bg-surface-light;
    }
  }
}
</style>
