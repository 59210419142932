<template>
  <div class="stages-and-tasks__header header">
    <div class="header__stage">
      <h2>{{ stage.name || "Все этапы" }}</h2>
      <ChosenFilter
        icon="down"
        v-model="currentStageFromList"
        :chosenList="stages"
        :allItem="false"
        :isLoading="stagePaginationLoader"
        @input="changeCurrentStage"
        @reachLastItem="getMoreStages"
      />
      <ActionsList
        :actionList="setActionList"
        v-if="isShowActionList"
        class="header__action-list"
        @handleAction="handleAction"
      />
    </div>
    <div class="header__row">
      <div class="header__control">
        <ChosenFilter
          icon="chart"
          :chosenList="stageTypeList"
          v-model="currentStageType"
          :allItem="false"
          v-if="!Object.keys(stage).length"
          @input="changeStageType"
        />
        <ChosenFilter
          v-if="Object.keys(stage).length"
          icon="circle-plus"
          :chosenList="setStatuses"
          v-model="currentStatusTask"
          :allItem="false"
          @input="updateTaskTable"
        />
        <ChosenFilter
          v-if="Object.keys(stage).length"
          icon="user"
          :search="true"
          :multiple="false"
          :placeholder="'Исполнитель'"
          :chosenList="isUsersList"
          v-model="currentResponsible"
          :allItem="false"
          @updateInfo="updateTaskTable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";

import { mapGetters, mapMutations, mapState } from "vuex";
import StageModule, { StageMutationTypes } from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import { EventBus } from "@/main";

export default {
  name: "StagesAndTasksHeader",
  mixins: [],
  props: {
    projectId: {
      type: String,
      default: "0"
    }
  },
  components: { ChosenFilter, ActionsList },
  data() {
    return {
      currentStageFromList: [
        {
          id: 0,
          name: "Все этапы"
        }
      ],
      currentStageType: [
        {
          id: 0,
          name: "Все этапы"
        }
      ],
      currentStatusTask: [
        {
          id: 0,
          name: "Все задачи"
        }
      ],
      currentResponsible: [],
      stagesList: [
        {
          id: 0,
          name: "Все этапы"
        }
      ],

      stageTypeList: [
        {
          id: 0,
          name: "Все этапы",
          is_completed: null
        },
        {
          id: 1,
          name: "Закрытые этапы",
          is_completed: true
        },
        {
          id: 2,
          name: "Открытые этапы",
          is_completed: false
        }
      ]
    };
  },
  beforeDestroy() {
    EventBus.$off("updateData");
    this.observer.unobserve(this.$refs.tableStage);
  },
  mounted() {
    if (Object.keys(this.stage).length) {
      this.currentStageFromList = [this.stage];
    }
    EventBus.$on("updateData", (val) => {
      TaskModule.getTaskListByStageIdAction({
        stage_id: val.stage_id,
        per_page: 15,
        project_id: this.projectId,
        toReset: true
      });
    });
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["SET_CURRENT_STAGE"]),
    updateTaskTable() {
      TaskModule[TaskMutationTypes.RESET_TASKS_BY_STAGE]([]);
      TaskModule[TaskMutationTypes.SET_FROM_PAGE](1);
      const params = this.setParams;
      TaskModule[TaskMutationTypes.SET_PARAMS](params);
      TaskModule.getTaskListByStageIdAction({ ...params });
    },
    changeCurrentStage(val) {
      if (val[0].id === 0) {
        StageModule[StageMutationTypes.SET_CURRENT_STAGE]({});
        this.$router.replace({ name: "Project", params: { id: this.projectId } });
      } else {
        StageModule[StageMutationTypes.SET_CURRENT_STAGE](val[0]);
        this.$router.replace({
          name: "Project",
          params: { id: this.projectId },
          query: { stage: val[0].id ? val[0].id : "default" }
        });
      }
    },
    changeStageType(val) {
      let is_completed = null;
      if (val[0].is_completed !== null) {
        is_completed = val[0].is_completed ? 1 : 0;
      }
      StageModule[StageMutationTypes.SET_CURRENT_STAGE_TYPE](val[0]);
      StageModule.getStageByProjectId({
        project_id: this.projectId,
        is_completed
      });
    },
    handleAction(val) {
      StageModule.handleActionList({
        action: val.actionType,
        stage: this.stage,
        currentProject: this.currentProject
      });
    },
    getMoreStages() {
      StageModule.getStageByPagination({
        project_id: this.projectId
      });
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    statusesListName() {
      const statusesList = [
        {
          id: 0,
          name: "Все задачи"
        }
      ];
      TaskModule.statusesList.forEach((e) => {
        const statusesListItem = {
          id: e.id,
          name: e.name
        };
        statusesList.push(statusesListItem);
      });
      return statusesList;
    },
    setParams() {
      return {
        page: TaskModule.tasksByStagePage,
        per_page: 15,
        project_id: this.projectId,
        stage_id: StageModule.stage.id === 0 ? null : StageModule.stage.id,
        status_id: this.currentStatusTask.length ? this.currentStatusTask[0].id : undefined,
        executor_id: this.currentResponsible.length ? this.currentResponsible[0].id : undefined,
        [`orders[${this.valueSort.value}]`]: this.valueSort.isAsc ? "asc" : "desc"
      };
    },
    valueSort() {
      return TaskModule.valueSort;
    },
    isCertainStage() {
      return !!this.stage.id;
    },
    stage() {
      return StageModule.stage;
    },
    isUsersList() {
      const users = ProjectModule.currentProject.projectFull.members
        .map((item) => item.user_info)
        .concat(ProjectModule.currentProject.projectFull.owners.map((item) => item.user_info))
        .sort((x, y) => {
          if (x.full_name < y.full_name) {
            return -1;
          }
          if (x.full_name > y.full_name) {
            return 1;
          }
          return 0;
        });
      users.unshift({
        name: "Все",
        id: null,
        full_name: "Все",
        surname: "Все"
      });
      return users;
    },
    currentProject() {
      return ProjectModule.currentProject?.projectFull;
    },
    stages() {
      const stage = StageModule.stages(this.projectId);
      if (stage) {
        const filters = stage.list.map((item) => item);
        filters.unshift({
          id: null,
          name: "Вне этапа"
        });
        filters.unshift({
          id: 0,
          name: "Все этапы"
        });

        return filters;
      }
      return this.stagesList;
    },
    setActionList() {
      return StageModule.actionsList(this.stage, this.currentProject);
    },
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    },
    isShowActionList() {
      return (
        this.isCertainStage &&
        !this.currentProject.is_completed &&
        (this.currentProject.is_owner || !this.stage.is_completed)
      );
    },
    setStatusesList() {
      return KanbanModule.statusList.length ? KanbanModule.statusList : [];
    },
    setStatuses() {
      let statuses = [];
      const all = [
        {
          id: 0,
          name: "Все задачи"
        }
      ];
      statuses = all.concat(this.setStatusesList);
      return statuses;
    }
  },
  watch: {
    stage() {
      if (Object.keys(this.stage).length) {
        this.currentStageFromList = [this.stage];
      } else {
        this.currentStageFromList = [
          {
            id: 0,
            name: "Все этапы"
          }
        ];
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.header {
  border-radius: 8px;
  border: 1px solid #eae8f0;
  padding: 24px 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;

  &__stage {
    display: flex;
    align-items: center;
    max-width: 55%;

    h2 {
      padding: 0;
      margin: 0 8px 0 0;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      color: #0a1417;
      @include textEllipsis(2);

      @media (max-width: $md) {
        font-size: 22px;
      }
    }

    /deep/ {
      .action-list__modal-list {
        left: 0;
        right: auto;
      }

      .chosen-filter {
        i {
          margin: 0;
        }

        &__name {
          display: none;
        }

        &__placeholder {
          display: none;
        }
      }
    }
  }

  &__action-list {
    margin-left: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $lg) {
      flex-direction: column;
      align-items: self-start;
    }

    /deep/ {
      .tasks-counter {
        justify-content: space-between;
        max-width: 620px;
        width: 100%;
        gap: 8px;
        @media (max-width: $md) {
          width: 400px;
          flex-wrap: nowrap;
        }
      }

      .tasks-counter__item {
        margin: 0;
        @media (max-width: $md) {
          font-size: 12px;
        }
      }
    }
  }

  &__wrap {
    width: 100%;
    overflow: auto;
    padding-right: 24px;
  }

  &__control {
    display: flex;
    margin: 0 24px 0 8px;

    /deep/ {
      .chosen-filter {
        &__item:first-child {
          position: sticky;
          top: 0;
          background-color: $color-white;
          z-index: 1;
        }

        &__list {
          left: auto;
          right: 0;
        }
      }

      .chosen-filter__label {
        padding: 4px 4px 4px 0;
      }
    }

    @media (max-width: $lg) {
      //margin-top: 16px;
      //margin-left: 0;
      /deep/ {
        .chosen-filter {
          padding: 5px 6px 5px 0;
        }

        .chosen-filter__label {
          padding: 4px 4px 4px 0;
        }
      }
    }

    /deep/ {
      .chosen-filter:last-child {
        .chosen-filter__label {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
