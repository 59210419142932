<template>
  <div class="chat-settings" :class="showSetting ? 'chat-settings_showed' : 'chat-settings_hidden'">
    <div class="settings-header">
      <i class="icon-arrow-short" @click="closeMenu"></i>
      <Avatar :showImg="false" :showInfo="true" :user="getDataInfoUser" />
    </div>
    <div class="wrap-label">
      <p>Меню чата</p>
    </div>
    <div class="chat-settings__wrap wrap-hover" @click="changeShowCatalog(true)">
      <i class="icon-global"></i>
      <p>Каталог</p>
    </div>
    <div class="chat-settings__wrap wrap-hover" @click.stop="changeCreateEditChannelModal(true)">
      <i class="icon-plus"></i>
      <p>Создать канал</p>
    </div>
    <div class="chat-settings__wrap wrap-hover" @click="checkFavorites">
      <i class="icon-favorites"></i>
      <p>Избранное</p>
    </div>
    <div class="wrap-label">
      <p>Настройки</p>
    </div>
    <div class="chat-settings__wrap settings-chat">
      <div class="settings-chat__wrap">
        <p>Уведомления</p>
        <SwitchComponent @changeSwitch="handleNotification" :value="isNotification" />
      </div>
      <!--      <div class="settings-chat__wrap">-->
      <!--        <p>Группировать по типу</p>-->
      <!--        <SwitchComponent @changeSwitch="handleGroupChat" :value="isGroupChat" />-->
      <!--      </div>-->
      <div class="settings-chat__wrap">
        <RadioButtons
          :items="settingsEditor"
          :value="selectedSettingsEditor"
          @selectRadioValue="selectRadioValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import SwitchComponent from "@/modules/Messenger/components/Inputs/Switch";
import RadioButtons from "@/modules/Messenger/components/Inputs/RadioButtons";
import { mapActions, mapState } from "vuex";
export default {
  name: "ChatSettings",
  mixins: [],
  props: {},
  components: { RadioButtons, SwitchComponent, Avatar },
  data() {
    return {
      settingsEditor: [
        {
          label: "Отправка сообщений Enter",
          value: "Enter"
        },
        {
          label: "Отправка сообщений Ctrl+Enter",
          value: "CtrlEnter"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", [
      "changeCreateEditChannelModal",
      "changeShowCatalog",
      "setFavoriteChat",
      "updateUserSetting",
      "switchSettingMenu"
    ]),
    closeMenu() {
      this.switchSettingMenu(false);
    },
    selectRadioValue(val) {
      let enter = true;
      if (val === "CtrlEnter") {
        enter = false;
      }
      this.updateUserSetting({
        notification: this.isNotification,
        group: this.isGroupChat,
        enter
      });
    },
    checkFavorites() {
      this.setFavoriteChat();
    },
    handleGroupChat() {
      let enter = true;
      if (this.selectedSettingsEditor === "CtrlEnter") {
        enter = false;
      }
      this.updateUserSetting({
        notification: this.isNotification,
        group: !this.isGroupChat,
        enter
      });
    },
    handleNotification() {
      let enter = true;
      if (this.selectedSettingsEditor === "CtrlEnter") {
        enter = false;
      }
      this.updateUserSetting({
        notification: !this.isNotification,
        group: this.isGroupChat,
        enter
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "chatList",
      "selectedSettingsEditor",
      "isGroupChat",
      "selectedSettingsEditor",
      "showSetting",
      "isNotification"
    ]),
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-settings {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  border-radius: 16px;
  transition: all 0.5s;
  overflow-y: auto;
  @media screen and (max-width: $md) {
    width: 360px;
    border-right: 1px solid #eae8f0;
    border-radius: 0;
  }

  @media screen and (max-width: $xs) {
    width: 100%;
  }

  &_showed {
    left: 0;
  }
  &_hidden {
    visibility: hidden;
    left: -100%;
  }
  &__wrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
    padding: 0 24px;
    i {
      font-size: 18px;
      color: #a7a9c0;
      margin-right: 10px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343748;
    }
  }
  .wrap-label {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid #eae8f0;
    border-bottom: 1px solid #eae8f0;
    margin-bottom: 28px;
    user-select: none;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #343748;
    }
  }
  .wrap-hover {
    cursor: pointer;
    width: fit-content;
    user-select: none;
  }
  .calendar-wrap {
    margin-bottom: 20px;
    cursor: default;
  }
  .settings-chat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #343748;
      }
    }
  }
  .settings-header {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 24px;
    i {
      transform: rotate(-90deg);
      margin-right: 16px;
      cursor: pointer;
      color: $icon-subdued;
    }
    /deep/ {
      .avatar__name {
        margin-right: 8px;
        margin-bottom: 0;
      }
      .avatar__wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
