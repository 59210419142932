<template>
  <li class="comment-file">
    <div class="comment-file__wrap" v-if="isImg">
      <a class="comment-file__img" :href="returnSrcFile(file.link)" target="_blank">
        <img :src="returnSrcFile(file.link)" alt="" />
      </a>
      <i class="icon-cross" v-if="showCross" @click.prevent.stop="deleteFile"></i>
    </div>
    <div class="comment-file__wrap" v-if="!isImg">
      <a class="comment-file__link" :href="returnSrcFile(file.link)" target="_blank">
        <i class="icon-paper-clip"></i>
        <span class="comment-file__name">{{ cutterText(fileName) }}</span>
      </a>
      <i class="icon-cross" v-if="showCross" @click.prevent.stop="deleteFile"></i>
    </div>
  </li>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "CommentFileItem",
  mixins: [],
  props: ["fileName", "file", "idx", "showCross", "isMain", "currentId"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= 6) {
        return text;
      } else return text.substr(0, 6) + "..." + extension[extension.length - 1];
    },
    deleteFile() {
      this.$root.$emit(`filesSplice${this.currentId}`, this.idx);
      this.$emit("filesSplice", this.idx);
    }
  },
  computed: {
    isImg() {
      return (
        this.file.extension === "image/jpeg" ||
        this.file.extension === "image/jpg" ||
        this.file.extension === "image/png" ||
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png"
      );
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.comment-file {
  display: flex;
  max-width: 45%;
  width: max-content;
  max-height: 120px;
  overflow: hidden;

  .icon-cross {
    color: #a7a9c0;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }
  }

  &__wrap {
    display: flex;
    width: 100%;
  }

  &__link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: #a7a9c0;
    font-size: 12px;
    line-height: 16px;

    i {
      margin-right: 8px;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: max-content;
    margin-right: 8px;
    align-self: flex-start;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &_main {
    display: flex;
    padding: 4px 9px 4px 13px;
    margin-right: 15px;
    border-radius: 24px;
    margin-bottom: 10px;
    height: max-content;

    @media screen and (max-width: $xs) {
      padding-right: 4px;
      padding-left: 4px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    i {
      font-size: 16px;
      color: #a7a9c0;
      @media screen and (max-width: $xs) {
        font-size: 8px;
      }
    }

    .comment-file__link {
      color: #a7a9c0;
    }

    .comment-file__name {
      color: #a7a9c0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-left: 12px;
      margin-right: 12px;

      @media screen and (max-width: $xs) {
        font-size: 8px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &_alternative {
    color: $text-disabled;
    background-color: transparent;
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
      color: $text-disabled;
    }

    .comment-file__link {
      color: $text-disabled;
    }

    .comment-file__wrap {
      i {
        color: $icon-subdued;
      }
    }

    .comment-file__name {
      font-size: 12px;
      line-height: 16px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
</style>
