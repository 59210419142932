import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

import {
  IBlackSphere,
  IBlackSphereData,
  IBlackSphereMarks,
  IBlackSphereSort,
  IBlackSphereStatistic,
  IBlackSphereStatisticItem,
  IBlackSphereStatisticSort,
  IBlackSphereUsers,
  IParamsBlackSphereProfile
} from "@/modules/BlackSphere/interface/interface";
import BlackSphereService from "@/modules/BlackSphere/Service/BlackSphereService";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import { ISort } from "@/modules/TaskManager/Model/Task/TaskModel";
import moment from "moment";
import { IBlackSphereParams } from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModel";
import NotificationObserver from "@/helpers/UserNotification";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";

const name = "BlackSphere";

export enum BlackSphereMutationTypes {
  //  blackSphereProfile
  SET_BLACK_SPHERE_MONTH_FOR_PROFILE = "SET_BLACK_SPHERE_MONTH_FOR_PROFILE",
  RESET_BLACK_SPHERE_LIST_FOR_PROFILE = "RESET_BLACK_SPHERE_LIST_FOR_PROFILE",

  //  blackSphereMarks
  SET_BLACK_SPHERE_LIST_FOR_PROFILE = "SET_BLACK_SPHERE_LIST_FOR_PROFILE",
  SET_BLACK_SPHERE_FOR_TABLE = "SET_BLACK_SPHERE_FOR_TABLE",
  SET_COMMENT_FOR_MODAL_BLACK_SPHERE_COMMENT = "SET_COMMENT_FOR_MODAL_BLACK_SPHERE_COMMENT",
  TOGGLE_MODAL_BLACK_SPHERE_COMMENT = "TOGGLE_MODAL_BLACK_SPHERE_COMMENT",
  TOGGLE_MODAL_CONFIRM = "TOGGLE_MODAL_CONFIRM",
  DELETE_BLACK_SPHERE_MARK = "DELETE_BLACK_SPHERE_MARK",
  SET_VALUE_SORT = "SET_VALUE_SORT",
  SET_DATE_FILTER = "SET_DATE_FILTER",
  LOADER = "LOADER",
  SET_BLACK_SPHERE_PAGE = "SET_BLACK_SPHERE_PAGE",
  RESET_BLACK_SPHERE_LIST_FOR_TABLE_LIST = "RESET_BLACK_SPHERE_LIST_FOR_TABLE_LIST",
  SET_GOT_USER_FILTER = "SET_GOT_USER_FILTER",
  SET_PUT_USER_FILTER = "SET_PUT_USER_FILTER",
  SET_MARK_FILTER = "SET_MARK_FILTER",
  //  blackSphereStatistic
  SET_BLACK_SPHERE_FOR_STATISTIC = "SET_BLACK_SPHERE_FOR_STATISTIC",
  SET_VALUE_SORT_STATISTIC = "SET_VALUE_SORT_STATISTIC",
  SET_USER_FILTER = "SET_USER_FILTER",
  SET_BLACK_SPHERE_PAGE_STATISTIC = "SET_BLACK_SPHERE_PAGE_STATISTIC",
  RESET_BLACK_SPHERE_LIST_FOR_STATISTIC_LIST = "RESET_BLACK_SPHERE_LIST_FOR_STATISTIC_LIST"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

function getObject<T>(json: string | null): T | null {
  return json ? JSON.parse(json) : null;
}
@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class BlackSphere extends VuexModule {
  //  blackSphereProfile
  blackSphereCurrentMonth: string = moment(new Date()).format("YYYY-MM");

  //  blackSphereMarks
  blackSphereListForProfile: Array<IBlackSphere> = [];
  blackSphereListForTableList: Array<IBlackSphere> = [];
  blackSphereListForTablePage: number = 1;
  blackSphereListForTablePerPage: number = 15;
  blackSphereListForTableCount: number = 0;
  isShowBlackSphereCommentModal: boolean = false;
  isShowConfirmationModal: boolean = false;
  filterMark: Array<IBlackSphereMarks> | null = getObject(localStorage.getItem("filterMark"));
  filterGotUser: Array<IBlackSphereUsers> | null = getObject(localStorage.getItem("filterGotUser"));
  filterPutUser: Array<IBlackSphereUsers> | null = getObject(localStorage.getItem("filterPutUser"));
  filterMonth: string = localStorage.getItem("filterMonth")
    ? localStorage.getItem("filterMonth")!
    : moment(new Date()).format("YYYY-MM");
  loader: boolean = false;
  modalData: string | null = null;
  valueSort: IBlackSphereSort | undefined = { value: "marked_at", isAsc: false };
  valueSortList: Array<IBlackSphereSort> = [
    { value: "mark", isAsc: true },
    { value: "marked_at", isAsc: true },
    { value: "created_at", isAsc: true }
  ];
  //blackSphereStatistic
  blackSphereListForStatisticList: Array<IBlackSphereStatisticItem> = [];
  blackSphereListForStatisticPage: number = 1;
  blackSphereListForStatisticPerPage: number = 15;
  blackSphereListForStatisticCount: number = 0;
  valueSortStatistic: IBlackSphereStatisticSort | undefined = {
    value: "count_marks",
    isAsc: false
  };
  valueSortListStatistic: Array<IBlackSphereStatisticSort> = [
    { value: "count_marks", isAsc: true },
    { value: "percent", isAsc: true }
  ];
  filterUser: Array<IBlackSphereUsers> = [];

  //  blackSphereProfile
  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_MONTH_FOR_PROFILE](date: string) {
    this.blackSphereCurrentMonth = date;
  }

  @Mutation
  [BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_PROFILE]() {
    this.blackSphereListForProfile = [];
  }
  //  blackSphereMarks

  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_LIST_FOR_PROFILE](payload: Array<IBlackSphere>) {
    this.blackSphereListForProfile = payload;
  }

  @Mutation
  [BlackSphereMutationTypes.LOADER](isShow: boolean) {
    this.loader = isShow;
  }
  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_FOR_TABLE](payload: IBlackSphereData) {
    if (payload.page === 1) {
      this.blackSphereListForTableList = payload.list;
    } else {
      this.blackSphereListForTableList = [...this.blackSphereListForTableList, ...payload.list];
    }
    this.blackSphereListForTableList = setUniqueArray(this.blackSphereListForTableList, "id");
    this.blackSphereListForTablePage = payload.page;
    this.blackSphereListForTablePerPage = payload.per_page;
    this.blackSphereListForTableCount = payload.count;
  }

  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE](payload) {
    this.blackSphereListForTablePage = payload;
  }
  @Mutation
  [BlackSphereMutationTypes.SET_VALUE_SORT](payload) {
    this.valueSort = this.valueSortList.find((item: ISort) => item.value === payload);
    if (this.valueSort) {
      this.valueSort.isAsc = !this.valueSort.isAsc;
      this.valueSort.value = payload;
    }
  }

  @Mutation
  [BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_TABLE_LIST]() {
    this.blackSphereListForTableList = [];
  }

  @Mutation
  [BlackSphereMutationTypes.DELETE_BLACK_SPHERE_MARK](id: number) {
    this.blackSphereListForTableList = this.blackSphereListForTableList.filter(
      (item) => item.id !== id
    );
  }

  @Mutation
  [BlackSphereMutationTypes.SET_GOT_USER_FILTER](users: Array<IBlackSphereUsers>) {
    this.filterGotUser = users;
    window.localStorage.setItem("filterGotUser", JSON.stringify(users));
  }
  @Mutation
  [BlackSphereMutationTypes.SET_DATE_FILTER](date: string) {
    this.filterMonth = date;
    window.localStorage.setItem("filterMonth", date);
  }
  @Mutation
  [BlackSphereMutationTypes.SET_PUT_USER_FILTER](users: Array<IBlackSphereUsers>) {
    this.filterPutUser = users;
    window.localStorage.setItem("filterPutUser", JSON.stringify(users));
  }
  @Mutation
  [BlackSphereMutationTypes.SET_MARK_FILTER](marks: Array<IBlackSphereMarks>) {
    this.filterMark = marks;
    window.localStorage.setItem("filterMark", JSON.stringify(marks));
  }

  @Mutation
  [BlackSphereMutationTypes.TOGGLE_MODAL_BLACK_SPHERE_COMMENT](isShow: boolean) {
    this.isShowBlackSphereCommentModal = isShow;
  }
  @Mutation
  [BlackSphereMutationTypes.SET_COMMENT_FOR_MODAL_BLACK_SPHERE_COMMENT](comment: string) {
    this.modalData = comment;
  }

  @Action
  async getBlackSphereMarksForProfileAction(params: IParamsBlackSphereProfile) {
    const blackSphereList = await BlackSphereService.getBlackSphereMarksForProfile(params);
    this[BlackSphereMutationTypes.SET_BLACK_SPHERE_LIST_FOR_PROFILE](blackSphereList);
  }
  @Action
  async getBlackSphereMarksForTableAction() {
    const params = {
      marks: this.filterMark ? this.filterMark.map((item) => item.id) : null,
      got_users: this.filterGotUser ? this.filterGotUser.map((item) => item.id) : null,
      put_users: this.filterPutUser ? this.filterPutUser.map((item) => item.id) : null,
      month: this.filterMonth,
      per_page: this.blackSphereListForTablePerPage,
      page: this.blackSphereListForTablePage,
      [`sort[${this.valueSort?.value}]`]: this.valueSort?.isAsc ? "asc" : "desc"
    };
    const blackSphereList = await BlackSphereService.getBlackSphereMarksForTable(params);
    this[BlackSphereMutationTypes.SET_BLACK_SPHERE_FOR_TABLE](blackSphereList);
  }

  @Mutation
  [BlackSphereMutationTypes.TOGGLE_MODAL_CONFIRM](isShow: boolean) {
    this.isShowConfirmationModal = isShow;
  }
  //  BlackSphereStatistic

  @Action
  async getBlackSphereMarksForStatisticAction(params: IBlackSphereStatistic) {
    const blackSphereListStatistic = await BlackSphereService.getBlackSphereMarksForStatistic(
      params
    );
    this[BlackSphereMutationTypes.SET_BLACK_SPHERE_FOR_STATISTIC](blackSphereListStatistic);
  }
  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_FOR_STATISTIC](payload: IBlackSphereStatistic) {
    if (payload.page === 1) {
      this.blackSphereListForStatisticList = payload.list;
    } else {
      this.blackSphereListForStatisticList = [
        ...this.blackSphereListForStatisticList,
        ...payload.list
      ];
    }
    this.blackSphereListForStatisticList = setUniqueArray(
      this.blackSphereListForStatisticList,
      "user_id"
    );

    this.blackSphereListForStatisticPage = payload.page;
    this.blackSphereListForStatisticPerPage = payload.per_page;
    this.blackSphereListForStatisticCount = payload.count;
  }

  @Mutation
  [BlackSphereMutationTypes.SET_VALUE_SORT_STATISTIC](payload) {
    this.valueSortStatistic = this.valueSortListStatistic.find(
      (item: ISort) => item.value === payload
    );
    if (this.valueSortStatistic) {
      this.valueSortStatistic.isAsc = !this.valueSortStatistic.isAsc;
      this.valueSortStatistic.value = payload;
    }
  }
  @Mutation
  [BlackSphereMutationTypes.SET_USER_FILTER](users: Array<IBlackSphereUsers>) {
    this.filterUser = users;
  }

  @Mutation
  [BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE_STATISTIC](payload) {
    this.blackSphereListForStatisticPage = payload;
  }

  @Mutation
  [BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_STATISTIC_LIST]() {
    this.blackSphereListForStatisticList = [];
  }

  @Action
  async createBlackSphereMarkForPersonAction(body: IBlackSphereParams) {
    const create = await BlackSphereService.createBlackSphereMarkForPerson(body);
    if (create) {
      NotificationObserver.notification({
        type: "success",
        message: "Оценка успешно создана!"
      });
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        false
      );
    }
  }
}

export default getModule(BlackSphere);
