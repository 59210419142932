<template>
  <li class="news-footer__item_wrap">
    <div @mouseover="showTooltipHandler" @mouseleave="tooltipTimerHandler">
      <div class="news-footer__item" :class="setStyle" @click="handleFooterItemClick">
        <span>
          <span v-if="icon === 'heart'" class="news-footer__item-heart">
            <img :src="require('@/modules/News/assets/img/NewsImage/like.svg')" alt="" />
            <span v-if="setCount">+{{ setCount }}</span>
          </span>
          <span v-else-if="icon === 'comment'">Комментарии ({{ setCount }})</span>
          <svg
            v-else-if="icon === 'views'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
              fill="#D3D4DB"
            />
            <path
              d="M9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z"
              fill="#A7A9C0"
            />
          </svg>
        </span>
      </div>
    </div>
    <i
      class="icon-dots-horizontal"
      :class="{ 'icon-dots-horizontal_current-user-liked': currentUserLike }"
      v-if="icon === 'heart' && setCount"
      @click.stop="showCurtainLikes"
    ></i>
  </li>
</template>

<script>
import axios from "axios";
import { domain } from "@/globalVariables";
import { mapActions } from "vuex";
import httpClient from "@/api/client/httpClient";
export default {
  name: "NewsFooterItem",
  mixins: [],
  props: [
    "count",
    "icon",
    "action",
    "currentUserLike",
    "showComment",
    "news",
    "type",
    "commentItem",
    "sizeAvatar"
  ],
  components: {},
  data() {
    return {
      comment: this.showComment,
      loading: false,
      likeTimer: null,
      tooltipTimer: null,
      timerId: null,
      likesMemberView: false,
      likesCount: 0,
      likesMember: [],
      member_users: [],
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.loading = false;
    if (this.news.count_likes) {
      this.likesCount = this.count;
    }
    // this.setLikesMember();
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();
  },
  methods: {
    ...mapActions("NewsModule", ["showNewsLikes"]),
    handleFooterItemClick() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        if (this.icon === "comment") {
          this.$emit(`footer_item_comment`);
          this.comment = !this.comment;
        }
        if (this.icon === "heart") {
          this.$emit(`footer_item_heart`);
          this.handleLike();
        }
      }, 200);
    },
    handleLike() {
      if (this.currentUserLike) {
        if (this.member_users.length === 1) this.likesMemberView = false;
        // const index = this.member_users.findIndex((item) => {
        //   return item.id === this.getDataInfoUser.id;
        // });
        // this.member_users.splice(index, 1);
        this.likesCount -= 1;
      } else {
        this.likesCount += 1;
        this.likesMemberView = true;
        // let currentUser = JSON.parse(JSON.stringify(this.getDataInfoUser));
        // currentUser.roles = currentUser.positions;
        // this.member_users.unshift(currentUser);
      }
    },
    //функция нигде не вызывается
    getUserMembers() {
      httpClient({
        method: "GET",
        url: `/User/getItems`,
        params: {
          _count: 200,
          id: JSON.stringify(this.likesMember),
          is_active: true
        }
      }).then((response) => {
        this.member_users = response.data.data.items;
        this.loading = true;
      });
    },

    showTooltipHandler() {
      this.likeTimer = setTimeout(() => {
        this.likesMemberView = true;
      }, 500);
    },

    hideTooltip() {
      this.likesMemberView = false;
      this.clearLikeTime();
    },

    tooltipTimerHandler() {
      this.tooltipTimer = setTimeout(() => {
        this.likesMemberView = false;
      }, 500);
      this.clearLikeTime();
    },

    clearTimerHandler() {
      clearTimeout(this.tooltipTimer);
    },

    clearLikeTime() {
      clearTimeout(this.likeTimer);
    },
    // setLikesMember() {
    //   if (this.news.likes.length) this.member_users = this.news.likes.map((item) => item.user);
    // },
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    showCurtainLikes() {
      if (this.news) {
        this.showNewsLikes({ id: this.news.id, type: "news" });
      } else {
        this.showNewsLikes({ id: this.commentItem.id, type: "comments" });
      }
    }
  },
  computed: {
    setCount() {
      if (this.count > 999) {
        return `${(this.count / 1000).toFixed(1)}k`;
      } else {
        return this.count;
      }
    },

    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    // setWidth() {
    //   if (this.count) {
    //     if (this.count.toString().length === 1) {
    //       if (this.windowWidth <= 480) {
    //         return { width: "34px" };
    //       } else {
    //         return { width: "65px" };
    //       }
    //     } else if (this.count.toString().length === 2) {
    //       if (this.windowWidth <= 480) {
    //         return { width: "36px" };
    //       } else {
    //         return { width: "70px" };
    //       }
    //     } else {
    //       if (this.windowWidth <= 480) {
    //         return { width: "38px" };
    //       } else {
    //         return { width: "80px" };
    //       }
    //     }
    //   } else return false;
    // },
    setStyle() {
      return [
        this.icon === "comment" && this.comment ? "news-footer__item_comment-count" : "",
        this.icon === "views" && this.count ? "news-footer__item_views-count" : "",
        this.currentUserLike ? "news-footer__item_like-count" : "",
        this.icon === "comment" ? "news-footer__item_comment" : "",
        this.icon === "views" ? "news-footer__item_views" : "",
        this.icon === "heart" ? "news-footer__item_like" : ""
      ];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.news-footer {
  &__item {
    cursor: pointer;
    //width: 40px;
    padding: 1px 4px 0;
    //height: 40px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    &_wrap {
      height: 30px;
      position: relative;
      list-style: none;
      display: flex;
      align-items: center;
      .icon-dots-horizontal {
        font-size: 10px;
        font-weight: 900;
        color: $icon-subdued;
        border: 1px solid $secondary;
        border-radius: 50%;
        padding: 6px;
        cursor: pointer;
        transition: 0.2s all ease;
        &_current-user-liked {
          border: 1px solid $chat-editor;
        }
      }
      &:last-child {
        margin-left: auto;
      }
    }
    path {
      transition: fill 0.3s ease-in-out;
    }
    &_like {
      transition: all 0.3s ease-in-out;
      position: relative;
      border: 1px solid $secondary;
      margin-right: 4px;
      span {
        padding: 2px 0;
        display: flex;
        align-items: center;
        font-size: 8px;
        font-weight: 900;
        color: #5551f9;
      }
      @media screen and (max-width: $xs) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      &:hover {
        background-color: #fef0f0;

        path {
          fill: $icon-critical;
        }
      }
    }
    &_comment {
      color: $link-default;
      font-size: 14px;
      @media screen and (max-width: $xs) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      //&:hover {
      //  background-color: #e8f7fc;
      //  path {
      //    fill: #02ceff;
      //  }
      //  path:last-child {
      //    fill: #80e6ff;
      //  }
      //}
    }
    &_views {
      margin-left: auto;
      cursor: auto;
      @media screen and (max-width: $xs) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &_like-count {
      border: 1px solid #5551f9;
      transition: all 0.3s ease-in-out;
      img {
        animation: like 0.5s linear;
      }
      path {
        fill: $icon-critical;
      }
    }
    //&_comment-count {
    //  background-color: #e8f7fc;
    //  path {
    //    fill: #02ceff;
    //  }
    //  path:last-child {
    //    fill: #80e6ff;
    //  }
    //}
  }
  &__count {
    margin-left: 5px;
    font-size: 12px;
    line-height: 20px;
    color: $text-default;
    @media screen and (max-width: $xs) {
      font-size: 8px;
      margin-left: 4px;
    }
    &_hidden {
      transform: scale(0);
    }
    &_show {
      transform: scale(1);
      transition: all 0.3s ease-in-out;
    }
  }
  &__likes-member {
    min-height: 60px;
    min-width: 80px;
    z-index: 99;
    transition: all 0.3s ease;
    position: absolute;
    padding: 8px 13px 5px 13px;
    border: 1px solid $decorative-surface-five;
    background-color: #fff;
    border-radius: 32px;
    bottom: 110%;
    left: 0;
    user-select: none;
    @media screen and (max-width: $xs) {
      padding: 3px 8px;
      /deep/ {
        .adding-members__item_alt {
          .adding-members__user {
            width: 24px;
            height: 24px;
          }
          .adding-members__avatar {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      bottom: -5px;
      left: 30px;
      transform: rotate(45deg) translateX(-50%);
      transform-origin: top left;
      background-color: #fff;
      border-bottom: 1px solid $decorative-surface-five;
      border-right: 1px solid $decorative-surface-five;
    }
  }
}
@keyframes like {
  20% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes likePath {
  20% {
    fill: lighten($icon-critical, 10%);
  }
  40% {
    fill: lighten($icon-critical, 5%);
  }
  60% {
    fill: darken($icon-critical, 10%);
  }
  80% {
    fill: darken($icon-critical, 5%);
  }
  100% {
    fill: $icon-critical;
  }
}
</style>
