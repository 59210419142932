import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import NotificationService from "@/modules/Notification/Services/NotificationService";
import {
  IItemTypeSettingNotifications,
  INotification,
  INotificationList,
  IParamsForReadingNotifications,
  IParamsForReceivingNotifications,
  IParamsForSavingSettingNotifications,
  ISettingNotifications,
  IStatusSettingNotifications
} from "@/modules/Notification/interfaces/interface";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import Vue from "vue";

const name = "Notification";

@Module({ namespaced: true })
class Notification extends VuexModule {
  isShowNotificationModal: boolean = false;
  notificationList: Array<INotificationList> = [];
  notificationListPage: number = 1;
  notificationListPerPage: number = 15;
  notificationListCount: number = 0;
  notificationListCountUnread: number = 0;
  loader: boolean = false;
  notificationSettingsList: ISettingNotifications = {};
  @Mutation
  toggleModal(isShow: boolean) {
    this.isShowNotificationModal = isShow;
  }

  /**
   * Toggle loader state
   * @param isShow - show loader
   */
  @Mutation
  toggleLoader(isShow: boolean) {
    this.loader = isShow;
  }

  /**
   * Read notification
   * @param id - id of the notification to be read. If id is missing, all notifications will be read
   */
  @Mutation
  readNotification(id: number | null) {
    if (id) {
      this.notificationList.forEach((item) => {
        if (item.id === id) {
          item.status = 1;
        }
      });
    } else {
      this.notificationList.forEach((item) => {
        item.status = 1;
      });
    }
  }

  /* This method is used to set the number of unread notifications. */
  @Mutation
  setCounterUnreadNotifications() {
    const unreadNotifications = this.notificationList.filter((item) => item.status === 0);
    this.notificationListCountUnread = unreadNotifications.length;
  }

  /* This method is used to add a new notification to the list. */
  @Mutation
  addNewNotification(notification: INotificationList) {
    this.notificationList.push(notification);
    if (notification.status === 0) {
      this.notificationListCountUnread++;
    }
  }

  /**
   * Reset notification list and options
   */
  @Mutation
  resetNotification() {
    this.notificationList = [];
    this.notificationListPage = 1;
    this.notificationListPerPage = 15;
    this.notificationListCount = 0;
  }

  /* Resetting the settings to default. */
  @Mutation
  resetSettingsToDefault(service: string) {
    const listService = JSON.parse(JSON.stringify(this.notificationSettingsList[service].list));
    const newListService = listService.map((item) => ({
      ...item,
      aop: true,
      telegram: false,
      push: false
    }));
    Vue.delete(this.notificationSettingsList[service], "list");
    Vue.set(this.notificationSettingsList[service], "list", newListService);
  }

  /**
   * Set notification list and options
   * @param notifications - notification list and options
   */
  @Mutation
  setNotification(notifications: INotification) {
    if (notifications.page === 1) {
      this.notificationList = notifications.list;
    } else {
      this.notificationList = [...this.notificationList, ...notifications.list];
    }
    this.notificationList = setUniqueArray(this.notificationList, "id");
    this.notificationListPage = notifications.page;
    this.notificationListPerPage = notifications.per_page;
    this.notificationListCount = notifications.count;
  }

  /* This method is used to set the notification settings list. */
  @Mutation
  setNotificationSettingsList(object: ISettingNotifications) {
    const nameService = {
      task_manager: "Менеджер задач",
      taskmanager: "Менеджер задач",
      time_manager: "Трекер времени",
      timemanager: "Трекер времени",
      isp: "ISP",
      calendar: "Календарь",
      messenger: "Чат",
      news: "Новости"
    };
    Object.entries(object).forEach(([key, value]) => {
      if (value instanceof Object) {
        const settingType = value.list.map((item) => ({
          aop: item.setting.aop ? item.setting.aop : false,
          push: item.setting.push ? item.setting.push : false,
          telegram: item.setting.telegram ? item.setting.telegram : false,
          alias: item.alias,
          description: item.description,
          name: item.name,
          service: value.name.split(".")[1],
          serviceTitle: nameService[key]
        }));
        const arrayTypes = [...settingType];
        const settingService = {
          list: arrayTypes,
          isEnableNotifications: value.global,
          serviceTitle: nameService[key],
          service: value.name.split(".")[1]
        };
        Vue.set(this.notificationSettingsList, `${key}`, settingService);
      }
    });
  }

  /* Update notification settings list. */
  @Mutation
  updateSetting(object: IItemTypeSettingNotifications) {
    const service = object.service ? this.notificationSettingsList[object.service] : [];
    const index = service.list?.findIndex((item) => item.alias === object.alias);
    if (service.list.length && index !== -1) {
      Vue.set(service.list, index, object);
    }
  }

  @Mutation
  toggleTheStatusOfNotifications(payload: IStatusSettingNotifications) {
    const service = payload.service ? this.notificationSettingsList[payload.service] : null;
    if (service) {
      service.isEnableNotifications = payload.state;
    }
  }
  /**
   * Receive user notifications
   * @param {IParamsForReceivingNotifications} params - notification status value (0 - unread, 1 - read)
   */
  @Action
  async getNotificationsAction(params: IParamsForReceivingNotifications) {
    const notificationsList = await NotificationService.getNotifications(params);
    this.context.commit("setNotification", notificationsList);
    this.context.commit("setCounterUnreadNotifications");
  }

  /* Reset the list of notifications and options */

  @Action
  async resetNotificationsAction() {
    this.context.commit("resetNotification");
  }

  /**
   *  The method that is called when the user clicks the read notification button.
   * @param {IParamsForReadingNotifications} id - notification id (null - read all notifications)
   */
  @Action
  async readNotificationAction(id: IParamsForReadingNotifications) {
    if (id) {
      await NotificationService.readNotification(id);
      this.context.commit("readNotification", id);
    } else {
      await NotificationService.readAllNotification();
      this.context.commit("readNotification");
    }
    this.context.commit("setCounterUnreadNotifications");
  }

  @Action
  /**
   * Get notification settings
   */
  async getUserSettingsAction() {
    this.context.commit("toggleLoader", true);
    const notificationSettingsList = await NotificationService.getUserSettings();
    this.context.commit("setNotificationSettingsList", notificationSettingsList);
    this.context.commit("toggleLoader", false);
  }

  @Action
  /**
   * Saves user settings for notifications
   * @param {IParamsForSavingSettingNotifications} params - Notification settings
   */
  async saveUserSettingsAction(params: IParamsForSavingSettingNotifications) {
    await NotificationService.saveUserSettings(params);
  }

  @Action
  /**
   * Reset settings to default
   *
   */
  async resetSettingsToDefaultAction(service: string) {
    this.context.commit("resetSettingsToDefault", service);
    await NotificationService.resetSettingsToDefault();
  }
}

export default Notification;
