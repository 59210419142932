import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import ProjectService from "@/modules/TaskManager/Model/Project/ProjectService";
import ProjectModel from "@/modules/TaskManager/Model/Project/ProjectModel";
import ProjectFullModel, {
  IProjectFull
} from "@/modules/TaskManager/Model/Project/ProjectFullModel";
import router from "@/router";
import TaskModel, { ITaskFilesItem } from "@/modules/TaskManager/Model/Task/TaskModel";
import { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import Vue from "vue";
const name = "ProjectModule";

export enum ProjectMutationTypes {
  SET_PROJECT_LIST_RESPONSE = "SET_PROJECT_LIST_RESPONSE",
  SET_CHILD_PROJECTS_RESPONSE = "SET_CHILD_PROJECTS_RESPONSE",
  SET_PROJECT_LIST = "SET_PROJECT_LIST",
  TOGGLE_PROJECT_PAGINATION_LOADER = "TOGGLE_PROJECT_PAGINATION_LOADER",
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",
  SET_ALL_USERS = "SET_ALL_USERS",
  TOGGLE_PROJECT_LIST_LOADER = "TOGGLE_PROJECT_LIST_LOADER",
  TOGGLE_PROJECT_DESCRIPTION_LOADER = "TOGGLE_PROJECT_DESCRIPTION_LOADER",
  TOGGLE_PROJECT_STAGES_LOADER = "TOGGLE_PROJECT_STAGES_LOADER",
  TOGGLE_PROJECT_USERS_LOADER = "TOGGLE_PROJECT_USERS_LOADER",
  TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_PROJECT = "TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_PROJECT",
  SET_PROJECT_TREE = "SET_PROJECT_TREE",
  SET_OWNER_LIST = "SET_OWNER_LIST",
  SET_MEMBERS_LIST = "SET_MEMBERS_LIST",
  SET_CURRENT_SORTING_TYPE = "SET_CURRENT_SORTING_TYPE",
  SET_FILES_CURRENT_PROJECT = "SET_FILES_CURRENT_PROJECT",
  TOGGLE_SHOW_MODAL_FILE_UPLOAD = "TOGGLE_SHOW_MODAL_FILE_UPLOAD",
  SET_FILE_UPLOAD = "SET_FILE_UPLOAD",
  SET_FILE_UPLOAD_MODAL = "SET_FILE_UPLOAD_MODAL",
  SET_PARAMS_FILES_UPLOAD = "SET_PARAMS_FILES_UPLOAD",
  CLEAR_FILE_UPLOAD = "CLEAR_FILE_UPLOAD",
  CLEAR_FILE_UPLOAD_MODAL = "CLEAR_FILE_UPLOAD_MODAL",
  UPDATE_CURRENT_FILES = "UPDATE_CURRENT_FILES",
  UPDATE_CURRENT_PROJECT_FILES = "UPDATE_CURRENT_PROJECT_FILES ",
  UPDATE_FILES_MODAL = "UPDATE_FILES_MODAL",
  UPDATE_PROJECT_FILES = "UPDATE_PROJECT_FILES"
}
type responseDataType = {
  list: Array<any>;
  page: number;
  per_page: number;
  count: number;
};
if (store.hasModule(name)) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class ProjectModule extends VuexModule {
  projectListResponse: responseDataType | null = null;
  childProjectsResponse: responseDataType | null = null;
  projectList: Array<ProjectModel> = [];
  projectPaginationLoader: boolean = false;
  currentProject: ProjectFullModel | null = null;
  showCurtainShowAndEditProject: boolean = false;
  allUsers = [];
  projectListLoader: boolean = false;
  projectDescriptionLoader: boolean = false;
  projectStagesLoader: boolean = false;
  projectUsersLoader: boolean = false;
  projectTree: Array<object> = [];
  ownerList = [];
  membersList = [];
  currentSortingType: object = { "orders[created_at]": "desc" };
  filesList?: Array<ITaskFilesItem> = [];
  paramsFilesUpload: string = "";
  showModalFileUpload: boolean = false;
  filesUploadCurrentTask: any[] = [];
  filesUploadModal: any[] = [];

  @Mutation
  [ProjectMutationTypes.SET_PROJECT_LIST_RESPONSE](payload: responseDataType) {
    this.projectListResponse = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_CHILD_PROJECTS_RESPONSE](payload: responseDataType) {
    this.childProjectsResponse = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_ALL_USERS](payload) {
    this.allUsers = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_PROJECT_LIST](payload) {
    this.projectList = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_CURRENT_PROJECT](payload) {
    this.currentProject = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_PROJECT](payload) {
    this.showCurtainShowAndEditProject = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_PROJECT_LIST_LOADER](payload) {
    this.projectListLoader = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_PROJECT_DESCRIPTION_LOADER](payload) {
    this.projectDescriptionLoader = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_PROJECT_STAGES_LOADER](payload) {
    this.projectStagesLoader = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_PROJECT_USERS_LOADER](payload) {
    this.projectUsersLoader = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_PROJECT_TREE](payload) {
    this.projectTree = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_OWNER_LIST](payload) {
    this.ownerList = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_MEMBERS_LIST](payload) {
    this.membersList = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_CURRENT_SORTING_TYPE](payload) {
    this.currentSortingType = payload;
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_PROJECT_PAGINATION_LOADER](payload) {
    this.projectPaginationLoader = payload;
  }
  @Mutation
  [ProjectMutationTypes.SET_FILES_CURRENT_PROJECT](payload) {
    if (payload === "reset") {
      this.filesList = [];
    } else {
      this.filesList = payload;
    }
  }
  @Mutation
  [ProjectMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](payload) {
    this.showModalFileUpload = payload;
  }
  @Mutation
  [ProjectMutationTypes.UPDATE_CURRENT_PROJECT_FILES](id) {
    this.filesList = this.filesList?.filter((item) => item.id !== id);
  }
  @Mutation
  [ProjectMutationTypes.UPDATE_CURRENT_FILES](id) {
    this.filesUploadCurrentTask = this.filesUploadCurrentTask.filter((item) => item.id !== id);
  }
  @Mutation
  [ProjectMutationTypes.UPDATE_FILES_MODAL](id) {
    this.filesList = this.filesList?.filter((item) => item.id !== id);
  }
  @Mutation
  [ProjectMutationTypes.SET_FILE_UPLOAD](payload) {
    if (this.filesUploadCurrentTask.length < 10) {
      this.filesUploadCurrentTask.push(...payload);
    }
  }
  @Mutation
  [ProjectMutationTypes.SET_FILE_UPLOAD_MODAL](payload) {
    const countFilesUploadCurrentTask = this.filesUploadCurrentTask
      ? this.filesUploadCurrentTask.length
      : 0;
    const countFilesList = this.filesList ? this.filesList.length : 0;
    let countFilesUploadModal = 0;
    payload.forEach((item) => {
      if (countFilesUploadCurrentTask + countFilesList + countFilesUploadModal < 10) {
        this.filesUploadModal.push(item);
        countFilesUploadModal = this.filesUploadModal ? this.filesUploadModal.length : 0;
      }
    });
  }
  @Mutation
  [ProjectMutationTypes.SET_PARAMS_FILES_UPLOAD](payload) {
    this.paramsFilesUpload = payload;
  }
  @Mutation
  [ProjectMutationTypes.CLEAR_FILE_UPLOAD]() {
    this.filesUploadCurrentTask = [];
  }
  @Mutation
  [ProjectMutationTypes.CLEAR_FILE_UPLOAD_MODAL]() {
    this.filesUploadModal = [];
  }
  @Action
  async getProjectListAction() {
    this[ProjectMutationTypes.TOGGLE_PROJECT_LIST_LOADER](true);
    const responseData = await ProjectService.getProjectList({
      page: 1,
      per_page: 18,
      ...this.currentSortingType
    });
    this[ProjectMutationTypes.SET_PROJECT_LIST_RESPONSE](responseData);
    this[ProjectMutationTypes.SET_PROJECT_LIST](
      responseData.list.map((item) => new ProjectModel(item))
    );
    this[ProjectMutationTypes.TOGGLE_PROJECT_LIST_LOADER](false);
  }
  @Action
  async getProjectByPagination() {
    if (!this.projectListResponse) return;
    if (this.projectList.length >= this.projectListResponse.count) return;
    this[ProjectMutationTypes.TOGGLE_PROJECT_PAGINATION_LOADER](true);
    const responseData = await ProjectService.getProjectList({
      page: this.projectListResponse.page + 1,
      per_page: this.projectListResponse.per_page,
      ...this.currentSortingType
    });
    this[ProjectMutationTypes.SET_PROJECT_LIST]([
      ...this.projectList,
      ...responseData.list.map((item) => new ProjectModel(item))
    ]);
    this[ProjectMutationTypes.SET_PROJECT_LIST_RESPONSE](responseData);
    this[ProjectMutationTypes.TOGGLE_PROJECT_PAGINATION_LOADER](false);
  }
  @Action
  async getProjectFullAction(id) {
    this[ProjectMutationTypes.TOGGLE_PROJECT_DESCRIPTION_LOADER](true);
    const projectDescription = ProjectService.getProjectDescription(id);
    const projectOwners = ProjectService.getProjectOwners(id);
    const projectMembers = ProjectService.getProjectMembers(id);
    const projectChild = ProjectService.getChildProjects({ id });
    await this.getProjectFilesAction(id);
    Promise.all([projectDescription, projectOwners, projectMembers, projectChild])
      .then((values) => {
        const projectFull: IProjectFull = {
          ...values[0],
          owners: values[1].list,
          members: values[2].list,
          child_projects: values[3].list.map((item) => new ProjectModel(item))
        };
        this[ProjectMutationTypes.SET_CHILD_PROJECTS_RESPONSE](values[3]);
        this[ProjectMutationTypes.SET_CURRENT_PROJECT](new ProjectFullModel(projectFull));
        this[ProjectMutationTypes.TOGGLE_PROJECT_DESCRIPTION_LOADER](false);
      })
      .catch(() => {
        router.push({ name: "TaskManager" });
      });
  }
  @MutationAction
  async getChildProjectsByPagination(id) {
    if (!this.childProjectsResponse || !this.currentProject) return;
    if (this.currentProject.projectFull.child_projects.length >= this.childProjectsResponse.count)
      return;
    const childProjectsResponse = await ProjectService.getChildProjects({
      id,
      page: this.childProjectsResponse.page + 1
    });
    this.currentProject.projectFull.child_projects.push(
      ...childProjectsResponse.list.map((item) => new ProjectModel(item))
    );
    return { childProjectsResponse };
  }
  @MutationAction
  async updateChildProjectsList(id) {
    if (!this.currentProject || this.currentProject.projectFull.id !== id) return;
    const childProjectsResponse = await ProjectService.getChildProjects({ id });
    this.currentProject.projectFull.child_projects = childProjectsResponse.list.map(
      (item) => new ProjectModel(item)
    );
    return { childProjectsResponse };
  }
  @Action
  openCurtainShowAndEditProject() {
    this[ProjectMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_PROJECT](true);
  }
  @Action
  closeCurtainShowAndEditProject() {
    this[ProjectMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_PROJECT](false);
  }
  @Action
  createProjectAction(projectToCreate) {
    return ProjectService.createProject(projectToCreate);
  }
  @Action
  async updateProjectAction(updatedProject) {
    const func = async (resolve) => {
      await ProjectService.updateProject(updatedProject);
      const projectInArray = this.projectList.find((item) => item.project.id === updatedProject.id);
      if (projectInArray) {
        for (const key in updatedProject) {
          projectInArray.setField(key, updatedProject[key]);
        }
      }
      if (updatedProject.name) this.getProjectTree();
      if (!this.currentProject) return;
      const currentProject = this.currentProject.projectFull;
      for (const key in updatedProject) {
        currentProject[key] = updatedProject[key];
      }
      this.currentProject.projectFull = currentProject;
      resolve();
    };
    return new Promise(func);
  }
  @Action
  async updateProjectUsersAction(params) {
    await ProjectService.updateProjectUsers({
      projectId: params.projectId,
      ownersAdd: params.ownersAdd,
      ownersDelete: params.ownersDelete,
      membersAdd: params.membersAdd,
      membersDelete: params.membersDelete
    });
    if (!this.currentProject) return;
    if (params.changedOwners) this.currentProject.setField("owners", params.changedOwners);
    if (params.changedMembers) this.currentProject.setField("members", params.changedMembers);
    const allUsers = [
      ...this.currentProject.projectFull.owners,
      ...this.currentProject.projectFull.members
    ];
    const allUsersUnique: Array<any> = [];
    allUsers.forEach((user) => {
      if (!allUsersUnique.some((item) => item.id === user.id)) {
        allUsersUnique.push(user);
      }
    });
    const projectToChange = this.projectList.find(
      (project) => project.project.id === this.currentProject?.projectFull.id
    );
    if (projectToChange) projectToChange.project.count_member = allUsersUnique.length;
  }
  @Action
  async updateFilesAction(payload) {
    this[ProjectMutationTypes.UPDATE_PROJECT_FILES](payload);
  }
  @Mutation
  [ProjectMutationTypes.UPDATE_PROJECT_FILES](payload) {
    const current = this.currentProject?.projectFull;
    if (this.currentProject) {
      Vue.set(this.currentProject, "projectFull", { ...current, ...payload });
    }
  }
  @Action
  async getProjectTree() {
    const projectTree = await ProjectService.getProjectTree();
    this[ProjectMutationTypes.SET_PROJECT_TREE](projectTree);
  }
  @Action
  async getOwnerListActions(id) {
    const ownerList = await ProjectService.getOwnersInfo(id);
    this[ProjectMutationTypes.SET_OWNER_LIST](ownerList);
  }
  @Action
  async getMembersListActions(id) {
    const memberList = await ProjectService.getMembersInfo(id);
    this[ProjectMutationTypes.SET_MEMBERS_LIST](memberList);
  }
  @Action
  async deleteProjectAction(projectId: number) {
    await ProjectService.deleteProject(projectId);
    this.getProjectTree();
    const projectList = this.projectList.filter((item) => item.project.id !== projectId);
    this[ProjectMutationTypes.SET_PROJECT_LIST](projectList);
  }
  @Action
  async getProjectFilesAction(projectId: number | string) {
    const projectFiles = await ProjectService.getProjectFiles(projectId);
    this[ProjectMutationTypes.SET_FILES_CURRENT_PROJECT](projectFiles);
  }
  @Action
  openModalFileUpload(payload: Array<ITaskFilesItem>) {
    this[ProjectMutationTypes.SET_FILE_UPLOAD_MODAL](payload);
    this[ProjectMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](true);
  }
  @Action
  closeModalFileUpload() {
    this[ProjectMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](false);
    this[ProjectMutationTypes.CLEAR_FILE_UPLOAD]();
    this[ProjectMutationTypes.CLEAR_FILE_UPLOAD_MODAL]();
  }
}

export default getModule(ProjectModule);
