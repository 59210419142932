<template>
  <div
    class="step-block"
    :class="{ 'step-block_critical': isCritical && showCriticalWay, 'step-block_base': isBase }"
    ref="step-block"
    @mouseover="toForefront"
    @mouseout="toBackground"
  >
    <p class="step-block__name">{{ step.name }}</p>
    <div class="step-block__wrap">
      <div class="step-block__date">{{ dateStart }} - {{ lateEndDate }}</div>
      <div class="step-block__planned" ref="step-block-planned" :id="`planned_${step.id}`"></div>
      <div class="step-block__reserve" ref="step-block-reserve"></div>
    </div>
  </div>
</template>

<script>
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "StepBlock",
  mixins: [formatDateAndTime],
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showCriticalWay: {
      type: Boolean,
      default: false
    },
    isBase: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs["step-block-planned"].style.width = `${this.step.duration * 52}px`;
      this.$refs["step-block-reserve"].style.width = `${this.step.reserve * 52}px`;
      const dayColumnNode = document.getElementById(`${this.step.date_start}`);
      this.$refs["step-block"].style.left = `${
        dayColumnNode?.getBoundingClientRect().left -
        dayColumnNode?.getBoundingClientRect().width +
        4
      }px`;
      if (this.isBase) {
        this.$refs["step-block"].style.top = `${
          (this.step.relative_number - 1) * this.topMargin + 40
        }px`;
      } else {
        this.$refs["step-block"].style.top = `${
          (this.step.relative_number - 1) * this.topMargin
        }px`;
      }
    });
  },
  methods: {
    toForefront() {
      if (this.isBase) this.$emit("toForefront");
    },
    toBackground() {
      if (this.isBase) this.$emit("toBackground");
    }
  },
  computed: {
    topMargin() {
      return PlanningScheduleModule.topMargin;
    },
    isCritical() {
      let isCritical = false;
      PlanningScheduleModule.criticalPath.forEach((array) => {
        if (
          array.some(
            (stepLink) => stepLink.step_from === this.step.id || stepLink.step_to === this.step.id
          )
        )
          isCritical = true;
      });
      return isCritical;
    },
    dateStart() {
      return this.step.date_start ? this.setDateFormatFull(this.step.date_start) : "-";
    },
    lateEndDate() {
      return this.step.late_end_date ? this.setDateFormatFull(this.step.late_end_date) : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.step-block {
  width: fit-content;
  position: absolute;
  z-index: 1;
  &__wrap {
    height: 16px;
    border-radius: 2px;
    width: fit-content;
    display: flex;
    &:hover {
      .step-block__date {
        display: block;
        opacity: 1;
      }
    }
  }
  &__date {
    transition: all 0.3s ease;
    display: none;
    opacity: 0;
    position: absolute;
    max-width: 430px;
    width: max-content;
    left: 60%;
    bottom: calc(100% - 8px);
    z-index: 2;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
    text-align: left;
  }
  &__name {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 0 0 4px 8px;
  }
  &__planned {
    height: 100%;
    background-color: #e4f0ff;
  }
  &__reserve {
    height: 100%;
    background: #f5f8fe;
  }
  &_critical {
    z-index: 2;
    .step-block__wrap {
      border: 2px solid #ff649c;
    }
  }
  &_base {
    .step-block__wrap {
      height: 4px;
    }
  }
}
</style>
