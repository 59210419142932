<template>
  <div class="isp-team-responsible" v-click-outside="editResponsible">
    <h5>РУКОВОДИТЕЛЬ</h5>
    <div class="isp-team-responsible__user" v-show="!editMode">
      <User
        :avatar="currentTeam.leader_user.avatar"
        :name="currentTeam.leader_user.full_name"
        :info="
          currentTeam.leader_user.roles.length ? `[${currentTeam.leader_user.roles[0].name}]` : ''
        "
        :showTooltip="false"
        :size="'60px'"
        :id="currentTeam.leader_user.id"
      />
      <i class="icon-edit" @click="setEditMode" v-if="!currentTeam.is_archived && isEditable"></i>
    </div>
    <div class="isp-team-responsible__edit" v-show="editMode">
      <Select
        :placeholder="'Выберите сотрудника'"
        :items="allUsers"
        v-model="selected"
        :showButtonPanel="false"
        :multiple="false"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import User from "@/components/Widgets/User/User";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";

export default {
  name: "IspTeamResponsible",
  mixins: [],
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  components: { Select, User },
  data() {
    return {
      editMode: false,
      selected: []
    };
  },
  mounted() {
    this.selected = [this.currentTeam.leader_user];
  },
  methods: {
    ...mapActions("IspModule", ["editTeamAction"]),
    setEditMode() {
      this.editMode = true;
    },
    editResponsible(e) {
      // const parentNode = e.path.find((item) => item.className === "isp-team-info");
      if (this.editMode) {
        if (!this.selected.length) {
          this.selected = [this.currentTeam.leader_user];
          this.editMode = false;
        } else {
          const editedTeam = {
            leader_user_id: this.selected[0].id,
            member_users: this.currentTeam.member_users.map((item) => item.id)
          };
          this.editTeamAction({ editedTeam, id: this.currentTeam.id }).then(() => {
            this.editMode = false;
            this.currentTeam.leader_user = this.selected[0];
            this.$root.$emit("showNotification", {
              type: "success",
              timeout: 5000,
              label: `Руководитель команды успешно изменён!`
            });
          });
        }
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeam", "allUsers"])
  },
  watch: {
    currentTeam() {
      this.selected = [this.currentTeam.leader_user];
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.isp-team-responsible {
  margin-bottom: 49px;
  @media screen and (max-width: 850px) and (orientation: landscape) {
    margin-bottom: 15px;
  }
  h5 {
    padding: 0;
    margin: 0 0 23px 0;
    font-size: 12px;
    font-weight: 700;
  }
  &__user {
    display: flex;
    align-items: center;
    /deep/ {
      .user__name {
        font-weight: 500;
        max-width: 200px;
        width: max-content;
      }

      .user__description {
        max-width: 200px;
        width: max-content;
      }
    }
    &:hover i {
      opacity: 1;
    }
    i {
      padding-bottom: 10px;
      margin-left: 10px;
      font-size: 20px;
      color: #a7a9c0;
      cursor: pointer;
      transition: 0.3s all ease;
      opacity: 0;
      &:hover {
        color: $icon-highlight;
      }
    }
  }
}
</style>
