export enum fontList {
  displayRegularLarge = "display-regular-large",
  displayRegularMedium = "display-regular-medium",
  displayRegularSmall = "display-regular-small",

  displayBoldLarge = "display-bold-large",
  displayBoldMedium = "display-bold-medium",
  displayBoldSmall = "display-bold-small",

  headlineRegularLarge = "headline-regular-large",
  headlineRegularMedium = "headline-regular-medium",
  headlineRegularSmall = "headline-regular-small",

  headlineMediumLarge = "headline-medium-large",
  headlineMediumMedium = "headline-medium-medium",
  headlineMediumSmall = "headline-medium-small",

  headlineBoldLarge = "headline-bold-large",
  headlineBoldMedium = "headline-bold-medium",
  headlineBoldSmall = "headline-bold-small",

  titleRegularLarge = "title-regular-large",
  titleRegularMedium = "title-regular-medium",
  titleRegularSmall = "title-regular-small",

  titleMediumLarge = "title-medium-large",
  titleMediumMedium = "title-medium-medium",
  titleMediumSmall = "title-medium-small",

  titleBoldLarge = "title-bold-large",
  titleBoldMedium = "title-bold-medium",
  titleBoldSmall = "title-bold-small",

  bodyRegularLarge = "body-regular-large",
  bodyRegularMedium = "body-regular-medium",
  bodyRegularSmall = "body-regular-small",

  labelRegularLarge = "label-regular-large",
  labelRegularMedium = "label-regular-medium",
  labelRegularSmall = "label-regular-small"
}
