<template>
  <div class="tasks-body">
    <div class="tasks-body__wrap-label wrap-label" v-if="showHeader">
      <div class="wrap-label__label">Задачи</div>
      <div class="wrap-label__add" v-if="isShowCreateTaskButton">
        <Button
          :type="'white'"
          :icon="'add-task'"
          :title="'Создать задачу'"
          @handleButton="openIspTaskCreateModal"
        />
      </div>
    </div>
    <div v-else class="tasks-body__wrap-header">
      <div class="tasks-body__wrap">
        <div class="tasks-body__label">План решения</div>
        <div class="tasks-body__text">
          Прогресс: {{ currentInfoProblem.completion_percentage }} %
        </div>
      </div>
      <Button
        v-if="isEditTask"
        :type="'white'"
        :icon="'add-task'"
        :title="'Создать задачу'"
        @handleButton="openIspTaskCreateModal"
      />
    </div>
    <ul class="tasks-body__list" @click="showCanUse = !showCanUse">
      <TaskItem v-for="task in taskList" :key="task.id" :task="task" />
      <li class="tasks-body__item tasks-body__item_empty" v-if="!taskList.length">Нет задач :(</li>
    </ul>
  </div>
</template>

<script>
import TaskItem from "@/modules/Isp/components/InfoProblem/BodyComponents/TasksBody/TaskItem";
import { mapActions, mapMutations, mapState } from "vuex";
import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "TasksBody",
  mixins: [],
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { Button, TaskItem },
  data() {
    return {};
  },
  mounted() {
    this.getProblemTasks();
  },
  methods: {
    ...mapActions("IspModule", ["getProblemTasks"]),
    ...mapMutations("IspModule", ["SHOW_CREATE_TASK_MODAL", "SHOW_CURRENT_INFO_PROBLEM"]),
    openIspTaskCreateModal() {
      this.SHOW_CURRENT_INFO_PROBLEM(false);
      this.SHOW_CREATE_TASK_MODAL(true);
    }
  },
  computed: {
    ...mapState("IspModule", ["taskList", "currentInfoProblem"]),
    isEditTask() {
      return (
        (this.isGroupLeader || this.isResponsible || this.isExecutor || this.isISPManager) &&
        !this.isArchived &&
        this.isEditableStatus
      );
    },
    isEditableStatus() {
      return (
        this.currentInfoProblem.status.type !== "done" &&
        this.currentInfoProblem.status.type !== "final"
      );
    },
    isGroupLeader() {
      return this.currentInfoProblem.group.leader_user_id === this.getUserId;
    },
    isArchived() {
      return this.currentInfoProblem.is_archived;
    },
    isExecutor() {
      return this.currentInfoProblem.task_executors.some((item) => item.id === this.getUserId);
    },
    isCustomer() {
      return this.currentInfoProblem.customer_user.id === this.getUserId;
    },
    isResponsible() {
      return this.currentInfoProblem.responsible_user.id === this.getUserId;
    },
    isISPManager() {
      return this.getDataInfoUser.roles.includes("isp_admin");
    },
    isShowCreateTaskButton() {
      return (
        (this.isExecutor || this.isResponsible || this.isGroupLeader || this.isISPManager) &&
        !this.isArchived
      );
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {
    currentInfoProblem(val, old) {
      if (val.id !== old.id) this.getProblemTasks();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.tasks-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  &__wrap-header {
    padding-left: 12px;
    padding-right: 24px;
    min-height: 70px;
    border-bottom: 1px solid #eae8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $text-default;
  }
  &__text {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    color: $text-disabled;
  }
  &__wrap-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 24px;
    border-bottom: 1px solid #eae8f0;

    .wrap-label {
      &__label {
        width: 100%;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 28px;
        color: $text-default;
      }

      &__add {
        /deep/ {
          .aop-button {
            width: 170px;
            padding: 8px 12px;
          }
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 200px 0;
    color: $text-disabled;
  }
}
</style>
