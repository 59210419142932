<template>
  <li
    class="kanban-task"
    :id="setId"
    :tabindex="index"
    :data-task_id="task.id"
    @click="openCurtainShowAndEditTask"
  >
    <div class="kanban-task__header">
      <div class="kanban-task__info">
        <TaskPriority :priority="priority" class="kanban-task__priority" />
        <div class="kanban-task__status">
          <p>{{ setStatus.name }}</p>
        </div>
      </div>
    </div>
    <div class="kanban-task__body">
      <div class="kanban-task__name">
        <p>{{ task.name }}</p>
      </div>
      <div class="kanban-task__performer-and-date">
        <router-link
          :to="{ name: 'UserInfo', params: { user_id: executor.id } }"
          :target="'_blank'"
          :key="executor.id"
          v-if="executor"
        >
          <img :src="returnSrcFile(executor.avatar)" :title="executor.full_name" />
        </router-link>
        <div class="kanban-task__deadline" v-if="dateTo">
          <p>
            Дата окончания: <span>{{ dateTo }}</span>
          </p>
        </div>
      </div>
      <div class="kanban-task__attachments">
        <div class="kanban-task__subtasks" title="Подзадачи">
          <i class="icon-list"></i>
          <span>{{ task.children_tasks_count }}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import TaskPriority from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/TaskPriority";
import KanbanModule, { KanbanMutationTypes } from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import { mapGetters } from "vuex";
import { domainStorage } from "@/globalVariables";
import moment from "moment";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";

export default {
  name: "KanbanTask",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  components: {
    TaskPriority
  },
  data() {
    return {
      selectDate: new Date()
    };
  },
  mounted() {},
  methods: {
    openCurtainShowAndEditTask() {
      TaskModule.getCurrentTaskAction({
        task_id: this.task.task_id,
        alias: this.task.alias
      });
      KanbanModule[KanbanMutationTypes.SET_CURRENT_EDITABLE_TASK](this.task);
      TaskManagerModuleV2.openCurtainShowAndEditTask();
    },
    returnSrcFile(avatar) {
      return domainStorage + avatar;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setId() {
      return `kanban-task_${this.index}`;
    },
    setStatus() {
      return KanbanModule.statusList.find((status) => status.id === this.task.status_id);
    },
    executor() {
      return this.task.executor_id
        ? this.userList.users.find((user) => user.id === this.task.executor_id)
        : "";
    },
    dateTo() {
      return this.task.date_to ? moment(this.task.date_to).format("DD.MM.YYYY") : "";
    },
    priority() {
      return TaskModule.priority(this.task.priority_id);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.kanban-task {
  background: #ffffff;
  border: 1px solid $secondary;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;
  z-index: 10;
  cursor: pointer;
  height: 230px;
  display: flex;
  flex-direction: column;
  outline: none;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__priority {
    margin-right: 12px;
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 4px;
    min-width: 90px;
    max-width: 200px;
    height: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #a7a9c0;
    border: 1px solid #eae8f0;
    background: #f2f7fe;

    p {
      width: 100%;
      @include textEllipsis(1);
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 900;
    line-height: 21px;
    color: $text-default;
    margin-bottom: 16px;
    word-break: break-word;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &__performer-and-date {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 16px;
    }
    /deep/ {
      .label {
        display: none;
      }
      .date-picker__value {
        width: fit-content;
        p {
          margin: 0 12px;
        }
      }
    }
  }
  &__deadline {
    p {
      color: $text-subdued;
      font-size: 14px;
      font-weight: 500;
      span {
        color: $text-default;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  &__attachments {
    display: flex;
    margin-top: auto;
    cursor: default;
    div {
      display: flex;
      align-items: center;
      margin-right: 8px;
      i {
        margin-right: 4px;
        font-size: 20px;
        color: $icon-subdued;
      }
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
.draggable-source--is-dragging {
  opacity: 0;
}
</style>
