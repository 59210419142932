
































import MessengerModule from "@/modules/Messenger/store/MessengerModule";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainLink extends Vue {
  @Prop({
    type: Object,
    default: true
  })
  link!: any;
  @Prop({
    type: Boolean,
    default: false
  })
  fullMenu!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  deepOpenFull!: boolean;

  deepOpen: boolean = false;
  topTooltip: number = 0;

  toggleDeepOpen() {
    this.deepOpen = !this.deepOpen;
  }

  get setIcon() {
    switch (this.link.menu ? this.link.menu.icon : this.link.icon) {
      case "Messenger":
        return "messenger";
      case "Calendar":
        return "calendar";
      case "Bell":
        return "bell";
      case "News":
        return "news";
      case "Task":
        return "task";
      case "Workforce":
        return "workforce";
      case "Isp":
        return "isp";
      case "Vacation":
        return "vacation";
      case "Statistics":
        return "statistics";
      case "Section":
        return "books";
      case "Help":
        return "question";
      case "Exit":
        return "exit-door";
      case "Admin":
        return "setting";
      case "Profile":
        return "profile";
      default:
        return "";
    }
  }
  get openDeepList() {
    return (this.deepOpen || this.deepOpenFull) && this.fullMenu;
  }

  get isMessenger() {
    return this.link.icon === "Messenger";
  }

  get countUnreadMessage() {
    return MessengerModule.countUnreadMessage ? MessengerModule.countUnreadMessage : 0;
  }
}
