import location from "@/modules/Calendar/store/actions/location/location";
import * as types from "@/modules/Calendar/store/mutations/mutationTypes";
import ErrorObserver from "@/api/Error";
import { EventBus } from "@/main";
import events from "@/modules/Calendar/store/actions/events/events";
import httpClient from "@/api/client/httpClient";

const actions = {
  showCreateEventModal({ commit }, payload) {
    commit(types.SHOW_CREATE_EVENT_MODAL, payload);
  },
  setEvent({ state }, payload) {
    state.tooltipX = payload.x;
    state.tooltipY = payload.y;
    state.event = payload.event;
    state.showTooltip = payload.show;
  },
  getUsers({ commit }) {
    httpClient({
      url: `/User/getActive`,
      method: "GET"
    })
      .then((response) => {
        if (response.data.data.length) {
          commit(types.GET_USERS_LIST, response.data.data);
        }
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  getCalendar({ state, commit }, payload) {
    httpClient({
      method: "GET",
      url: `/Calendar/getItems`,
      params: {
        _order: { date: "asc" },
        week: state.weekNumber,
        year: state.year,
        ...payload
      }
    })
      .then((response) => {
        commit(types.SET_WEEKS, response.data.data.items);
        EventBus.$emit("updateDayEvent");
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  ...location,
  ...events
};
export default actions;
