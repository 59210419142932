export default {
  namespaced: true,
  state: {
    dataCalendarEvent: null,
    createModeCalendarEvent: false,
    openCalendarModal: false,
    location: [],
    event: null,
    tooltipX: 0,
    tooltipY: 0,
    showTooltip: false
  },
  mutations: {
    changeDataCalendarEvent(state, payload) {
      state.dataCalendarEvent = payload;
    },
    switchCalendarModal(state, payload) {
      state.openCalendarModal = payload;
    },
    calendarLocation(state, payload) {
      state.location = payload;
    },
    setEvent(state, payload) {
      state.tooltipX = payload.x;
      state.tooltipY = payload.y;
      state.event = payload.event;
      state.showTooltip = payload.show;
    }
  },
  actions: {},
  getters: {
    getDataCalendarEvent(state) {
      return state.dataCalendarEvent;
    }
  }
};
