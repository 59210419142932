<template>
  <div class="my-request-table">
    <div class="my-request-table__header">
      <div class="my-request-table__col" v-for="(item, index) in headerList" :key="index">
        {{ item }}
      </div>
    </div>
    <MyRequestTableRow
      v-for="(item, index) in myVacation"
      :key="index"
      :item="item"
      :getDataInfoUser="getDataInfoUser"
      :isAvailableForEditing="isAvailableForEditing"
    />
    <div class="my-request-table__row my-request-table__row_empty" v-if="!myVacation.length">
      Нет запросов :(
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import MyRequestTableRow from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/MyRequestTable/MyRequestTableRow";
export default {
  name: "MyRequestsTable",
  mixins: [],
  props: {
    member_users: {
      type: Array,
      default: () => {
        return [];
      }
    },
    myVacation: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    }
  },
  components: { MyRequestTableRow },
  data() {
    return {
      modalShow: false,
      headerList: [
        "Тип",
        "Автор",
        "Дата запроса",
        "Актуальные даты",
        "Количество дней",
        "Подтверждающие лица",
        "Статус",
        "Действие"
      ]
    };
  },
  mounted() {},
  methods: {
    handleClickIconDots() {
      this.modalShow = !this.modalShow;
    },
    hideModal() {
      this.modalShow = false;
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.my-request-table {
  position: relative;

  &__header {
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    margin-bottom: 24px;
    background-color: $bg-surface-light;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: $text-subdued;

    @media (max-width: $lg) {
      display: none;
    }
  }

  &__row_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    color: $text-disabled;
    font-size: 14px;
    line-height: 20px;
  }

  &__col {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    @media (max-width: $lg) {
      flex-wrap: wrap;
      align-items: flex-start;
      margin-right: 0;
    }

    &:first-child {
      width: 60%;
      @media (max-width: $lg) {
        margin-bottom: 32px;
        order: 1;
      }
    }

    &:nth-child(2) {
      display: none;
      @media (max-width: $lg) {
        width: 100%;
      }
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      width: 60%;
    }

    &:nth-child(3) {
      @media (max-width: $lg) {
        width: 50%;
      }
    }

    &:nth-child(5) {
      justify-content: center;
      @media (max-width: $lg) {
        width: 50%;
      }
    }

    &:nth-child(4) {
      @media (max-width: $lg) {
        order: 5;
      }
    }

    &:nth-child(6) {
      @media (max-width: $lg) {
        width: 50%;
      }
    }

    &:nth-child(7) {
      @media (max-width: $lg) {
        order: 7;
        width: 50%;
      }
    }

    &:last-child {
      width: 25%;
      margin-right: 0;
    }
  }
}
</style>
