export default class TaskDto {
  static task;
  constructor(task) {
    this.id = task.id;
    this.name = task.name;
    this.is_complete = task.is_complete;
    this.report = task.report;
    this.deadline = task.deadline;
    this.executor_users = task.executor_users;
  }
}
