import { Service, Response } from "@/helpers/types/service";
import httpClient from "@/api/client/httpClient";
import { UserListFactory, UsersList } from "@/store/Users/UserModel";
import { IBaseUser } from "@/store/Users/interfaces/interfaces";
import store from "@/store";

class UserService extends Service {
  model = "User";

  getAll(): Response<UsersList<IBaseUser>> {
    return httpClient({
      method: "GET",
      url: `/${this.model}/getItems`,
      params: {
        _with: JSON.stringify(["Roles"]),
        _count: 300
      }
    }).then((resp) => {
      const users = resp.data.data.items;
      return UserListFactory(users);
    });
  }

  getUsersWithFields(withArray: Array<string>, params?: object): Response<UsersList<IBaseUser>> {
    return httpClient({
      method: "GET",
      url: `/${this.model}/getItems`,
      params: {
        _count: 300,
        _with: JSON.stringify(withArray),
        ...params
      }
    }).then((resp) => {
      const instanceUserList = UserListFactory(resp.data.data.items);
      const usersList = store.getters["UsersModule/userList"];
      if (usersList) {
        const fieldsUser = instanceUserList.filedList(withArray);
        const newUsersList = usersList.users.map((user) => {
          const newFields = fieldsUser.find((item) => item.id === user.id);
          return { ...user, ...newFields };
        });
        return UserListFactory(newUsersList);
      }
      return instanceUserList;
    });
  }
}

export default new UserService();
