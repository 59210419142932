




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PaginationObserver extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  options!: any;
  observer: null | any = null;

  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);

    this.observer.observe(this.$el);
  }

  destroyed() {
    this.observer.disconnect();
  }

  handleSort(sort) {
    this.$emit("sortCell", sort);
  }
}
