<template>
  <div class="modal-items" ref="modalBg">
    <OptionSelect
      v-for="(option, index) in options"
      :option="option"
      :index="index"
      :hoverIndex="hoverIndex"
      :value="value"
      :itemName="'name'"
      :hiddenItems="hiddenItems"
      :key="option.id"
      @choseOption="pushOption"
      @deleteChoseOption="deleteOption"
    />
  </div>
</template>

<script>
const OptionSelect = () => import("@/components/Widgets/Select/Select2.0/OptionSelect");

import { domain } from "@/globalVariables";
export default {
  name: "ModalSelect",
  mixins: [],
  props: [
    "value",
    "placeholder",
    "itemName",
    "optionData",
    "entity",
    "actionEntity",
    "multiple",
    "maxCount",
    "createMode",
    "hiddenItems",
    "createEntity"
  ],
  components: { OptionSelect },
  data() {
    return {
      options: [],
      loader: true,
      inputSearchValue: "",
      timerId: 0,
      hoverIndex: null,
      searchFindId: false,
      listenerEvent: null
    };
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.eventsOptions, false);
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.optionData));
    window.addEventListener("keydown", this.eventsOptions, false);
  },
  methods: {
    eventsOptions(event) {
      if (event.key === "ArrowUp") {
        if (this.hoverIndex && this.hoverIndex > this.findUnChosenIndex()) {
          this.hoverIndex--;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "ArrowDown") {
        if (this.hoverIndex === this.options.length - 1) return false;
        if (this.hoverIndex !== null) {
          this.hoverIndex++;
        } else {
          this.findHoverIndex();
        }
      }
      if (event.key === "Enter") {
        if (this.hoverIndex !== null && this.options[this.hoverIndex]) {
          this.pushOption(this.options[this.hoverIndex]);
        }
      }
      if (event.key === "Backspace") {
        this.value.splice(this.value.length - 1, 1);
      }
      if (event.key === "Escape") {
        this.$root.$emit("hideSelectModal");
      }
    },
    selectAllOption() {
      this.$emit("pushAllOption", this.options);
    },
    checkSearchResults() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getEntityData();
      }, 500);
    },
    pushOption(option) {
      this.$emit("pushOptionToValue", option);
      this.inputSearchValue = "";
      this.getEntityData();
    },
    deleteOption(option) {
      this.$emit("deleteOptionToValue", option);
    },
    findHoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          this.hoverIndex = parseInt(child.dataset.index);
          break;
        }
      }
    },
    findUnChosenIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (child.dataset.index) {
          return parseInt(child.dataset.index);
        }
      }
    }
  },
  watch: {
    hoverIndex() {
      for (let i in this.$refs.modalBg.children) {
        let child = this.$refs.modalBg.children[i];
        if (this.hoverIndex === parseInt(child.dataset.index)) {
          this.$refs.modalBg.scrollTop = child.offsetTop - child.offsetHeight;
        }
      }
    }
  },
  validations: {},
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 15;
  width: 100%;
  overflow-y: auto;
  background: #fafbfc;
  padding: 0 0 8px;
  border-radius: 0 0 24px 24px;

  input {
    height: 30px;
    background: #ffffff;
    border: 1px solid $color-text-secondary;
    border-radius: 4px;
    color: $color-text-primary-1;
    outline: none;
    flex-shrink: 0;
    padding: 6px;
    margin: 10px 6px;
  }
  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-secondary;
  }
  .chosen-options-bg {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    padding: 0 6px 10px;
    .chosen-options {
      display: flex;
      align-items: center;
      height: 25px;
      background: $color-charts-purple-1;
      border-radius: 4px;
      padding: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: $color-charts-grey-3;
      margin-bottom: 6px;
      user-select: none;
      margin-right: 6px;
      .icon-cross {
        cursor: pointer;
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }
  .button-chose-all {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-secondary;
  }

  @media (hover: hover) {
    .button-chose-all:hover {
      background-color: $color-background;
    }
  }

  .empty-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    color: $color-text-primary-2;
  }
  .loader-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    .loader-content {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 100%;
    }
    .loader-content div {
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $color-charts-purple-1;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .loader-content div:nth-child(1) {
      left: 8px;
      animation: loader-content1 0.6s infinite;
    }
    .loader-content div:nth-child(2) {
      left: 8px;
      animation: loader-content2 0.6s infinite;
    }
    .loader-content div:nth-child(3) {
      left: 32px;
      animation: loader-content2 0.6s infinite;
    }
    .loader-content div:nth-child(4) {
      left: 56px;
      animation: loader-content3 0.6s infinite;
    }
    @keyframes loader-content1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes loader-content3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes loader-content2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }

  /deep/ {
    .option-bg {
      padding: 8px 18px;
      .option-name {
        color: $text-default;
      }
    }
  }
}
</style>
