<template>
  <div class="isp-team-card" @click.stop="showInfo" :id="setId">
    <div class="isp-team-card__short-title">
      <p>{{ team.alias }}</p>
    </div>
    <div class="isp-team-card__title">
      <h3>{{ team.name }}</h3>
    </div>
    <div class="isp-team-card__users">
      <div class="isp-team-card__responsible">
        <h5>Руководитель</h5>
        <div class="isp-team-card__avatar">
          <img :src="returnSrc(team.leader_user.avatar)" alt="" />
        </div>
      </div>
      <div class="isp-team-card__members">
        <h5>Участники</h5>
        <div class="isp-team-card__avatars">
          <div class="isp-team-card__avatar" v-for="user in usersToShow" :key="user.id">
            <img :src="returnSrc(user.avatar)" alt="" />
          </div>
          <div class="isp-team-card__more-users" v-if="amountOfUsers > 0">
            <p>+{{ amountOfUsers }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { domainStorage } from "@/globalVariables";

export default {
  name: "IspTeamCard",
  mixins: [],
  props: {
    team: {
      type: Object,
      default: null
    }
  },
  components: {},
  data() {
    return {
      target: null,
      inViewModel: true
    };
  },
  // beforeDestroy() {
  //   this.observer.unobserve(this.target);
  // },

  mounted() {
    // this.target = document.querySelector(`#isp-team-card-${this.team.id}`);
    // this.observer = new IntersectionObserver(
    //   () => {
    //     this.inViewModel = !this.inViewModel;
    //     if (this.inViewModel) {
    //       const currentTeamsCopy = JSON.parse(JSON.stringify(this.currentTeams));
    //       currentTeamsCopy.reverse();
    //       if (currentTeamsCopy[2] && this.team.id === currentTeamsCopy[2].id) {
    //         if (this.totalCount !== this.teamsCounter)
    //           this.scrollGetTeamsAction(this.team.is_archived);
    //       }
    //     }
    //   },
    //   { root: null, rootMargin: "0px", threshold: 1 }
    // );
    // this.observer.observe(this.target);
  },
  methods: {
    // ...mapActions("IspModule", ["scrollGetTeamsAction"]),
    ...mapMutations("IspModule", ["TOGGLE_SHOW_ISP_TEAM_INFO", "SET_CURRENT_TEAM"]),
    showInfo() {
      this.SET_CURRENT_TEAM(this.team);
      this.TOGGLE_SHOW_ISP_TEAM_INFO(true);
    },
    returnSrc(src) {
      return domainStorage + src;
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeams"]),
    usersToShow() {
      return this.team.member_users.slice(0, 3);
    },
    amountOfUsers() {
      return this.team.member_users.length - 3;
    },
    setId() {
      return `isp-team-card-${this.team.id}`;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

h5 {
  margin-bottom: 8px;
}
.isp-team-card {
  cursor: pointer;
  //max-width: 550px;
  width: 100%;
  height: 254px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid $secondary;
  transition: 0.5s all ease;
  &:hover {
    box-shadow: 0px 10px 20px -4px rgba(34, 60, 80, 0.2);
    transform: translateY(-8px);
  }
  &__short-title {
    font-size: 14px;
    color: $text-disabled;
  }
  &__title {
    color: #343748;
    max-height: 200px;
    word-break: break-word;
  }
  &__users {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  &__avatars {
    display: flex;
    div {
      margin-left: -8px;
    }
  }
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &__more-users {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f7;
    border: 1px dashed #d3d2e0;
    cursor: pointer;
    color: #828185;
  }
}
</style>
