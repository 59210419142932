





























import { Component, Prop, Vue } from "vue-property-decorator";
import { IModalConfig } from "@/modules/TimeTracker/interface/interface";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";

@Component({
  components: { MainButtonNew, ButtonNewDesign }
})
export default class ConfirmationModal extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  modalConfig!: IModalConfig;

  created() {
    document.addEventListener("keydown", this.onEscape);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscape);
  }

  onEscape(e) {
    if (e.keyCode === 27) {
      this.handleCancel();
    }
    if (e.keyCode === 13) {
      this.handleConfirm();
    }
  }

  handleCancel() {
    if (this.modalConfig) this.modalConfig.handleCancel();
  }

  handleConfirm() {
    if (this.modalConfig) this.modalConfig.handleConfirm();
  }

  get isCenter() {
    return !this.modalConfig.confirmButton.length || !this.modalConfig.cancelButton.length;
  }

  get isLoader() {
    return ModalModule.isLoader;
  }
}
