<template>
  <div class="reaction-content">
    <div class="reaction-content__wrap">
      <i class="icon-arrow-short" @click="handleCloseReaction"></i>
      <p class="reaction-content__title">Выбрать реакцию</p>
    </div>
    <Picker @select="addEmoji" title="Выберите Эмоджи" :i18n="settings" />
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "ReactionContent",
  mixins: [],
  props: {},
  components: { Picker },
  data() {
    return {
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["sendReaction", "closeReaction"]),
    addEmoji(e) {
      this.sendReaction({ reaction: e.native, id: this.currentMessage.id });
    },
    handleCloseReaction() {
      this.closeReaction();
    }
  },
  computed: {
    ...mapState("MessengerModule", ["currentMessage"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/Scroll.mixin.scss";
.reaction-content {
  @include scroll();
  background-color: #fff;
  max-height: 100vh;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 12px 6px 12px 0;

  &__wrap {
    display: flex;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    position: relative;
    border-bottom: 1px solid $border-default;
  }
  .icon-arrow-short {
    display: flex;
    transform: rotate(-90deg);
    margin-right: 16px;
    color: $text-disabled;
    cursor: pointer;
  }
  &__title {
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $text-disabled;
  }
  /deep/ {
    .emoji-mart {
      height: 95%;
      border: none;
    }
    .emoji-mart-preview {
      display: none;
    }
    .emoji-mart-bar:last-child {
      border: none;
    }
  }
}
</style>
