<template>
  <div class="time-picker" v-click-outside="closeModal">
    <div
      class="time-picker__wrap"
      :class="[
        value ? 'time-picker__wrap_complete' : '',
        isFocus ? 'time-picker__wrap_focus' : '',
        readonly && alt ? 'time-picker__wrap_readonly' : ''
      ]"
      @click="switchList"
    >
      <input
        ref="input"
        @input="setTimeValue($event.target.value)"
        type="text"
        :readonly="readonly"
        :value="value"
        v-mask="mask"
        class="time-picker__input"
        :placeholder="placeholder ? placeholder : '08:00'"
      />
      <i class="icon-clock"></i>
    </div>
    <div
      v-show="(showModal && !alt && readonly) || (showModal && !readonly && alt)"
      class="time-picker__select"
      :id="unique + 'time-list'"
    >
      <div
        v-for="(item, idx) in timeArr"
        :key="`${item}_${idx}`"
        :class="['time-picker__time', activeTime === idx ? 'time-picker__time_active' : '']"
        :data-time="unique + item"
        @click="setTime(item, idx)"
      >
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "TimePicker",
  mixins: [],
  model: {
    event: "update:time"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    defaultValue: {
      type: String,
      default: "08:00"
    },
    unique: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "08:00"
    },
    mask: {
      type: String,
      default: "##:##"
    },
    regExpDate: {
      type: String,
      default: new RegExp("[0-9]{2}:[0-9]{2}")
    },
    alt: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isSecond: {
      type: Boolean,
      default: false
    },
    timeInterval: {
      type: Number,
      default: 60
    },
    maxCount: {
      type: Number,
      default: 23
    }
  },
  components: {},
  directives: {
    ClickOutside
  },
  data() {
    return {
      activeTime: null,
      showModal: false,
      timeArr: [],
      isFocus: false
    };
  },
  mounted() {
    this.timeArr = this.generateTimeArr(this.timeInterval);
    if (this.value) {
      this.timeInput = this.value;
    }
  },
  methods: {
    setTimeValue(event) {
      this.$emit("update:time", event);
      if (event.length && this.regExpDate.test(event)) {
        this.setTime(event);
      }
    },
    switchList() {
      this.showModal = !this.showModal;
      this.isFocus = true;
      this.scrollToSelectTime();
      this.setActiveTime();
    },
    setActiveTime() {
      const index = this.timeArr.findIndex((item) => item === this.value.slice(0, 5));
      if (index > -1) {
        this.activeTime = index;
      } else {
        this.activeTime = null;
      }
    },
    scrollToSelectTime() {
      const [hh, mm] = this.value.split(":");
      let time = `${hh}:${mm}`;
      if (mm > 0 && mm < 30) {
        time = `${hh}:00`;
      } else if (mm > 30 && mm < 60) {
        time = `${hh}:30`;
      }
      const $el = document.querySelector(`[data-time="${this.unique}${time}"]`);
      if ($el) {
        this.$nextTick(() => {
          this.$scrollTo($el, 0, {
            container: `#${this.unique}time-list`,
            easing: "ease-in",
            lazy: true,
            offset: 0,
            force: true,
            cancelable: true,
            x: false,
            y: true
          });
        });
      }
    },
    closeModal() {
      this.showModal = false;
      this.isFocus = false;
    },
    generateTimeArr(interval) {
      let arr = [];
      let i = 0;
      let temp = 0;
      for (i; i <= this.maxCount; i++) {
        if (String(i).length === 1) {
          arr.push("0" + i + ":00");
          for (let k = 0; k < Math.floor(60 / interval); k++) {
            temp += interval;
            if (temp === 60) {
              break;
            }
            arr.push("0" + i + ":" + temp);
          }
          temp = 0;
          continue;
        }
        arr.push(i + ":00");
        for (let k = 0; k < Math.floor(60 / interval); k++) {
          temp += interval;
          if (temp === 60) {
            break;
          }
          arr.push(i + ":" + temp);
        }
        temp = 0;
      }
      return arr;
    },
    setTime(item, idx) {
      this.activeTime = idx;
      this.$refs.input.focus();
      if (this.isSecond) {
        if (item.length) {
          this.$emit("update:time", item);
          this.$emit("editTimeValue");
        } else {
          this.$emit("update:time", this.defaultValue);
          this.$emit("editTimeValue");
        }
      } else {
        if (item.length) {
          this.$emit("update:time", item + ":00");
          this.$emit("editTimeValue");
        } else {
          this.$emit("update:time", this.defaultValue);
          this.$emit("editTimeValue");
        }
      }

      this.closeModal();
    },
    checkTimeValidation(time) {
      let temp = time.split(":");
      if (temp[0] >= 24) {
        temp[0] = "00";
      }
      if (temp[0].split("")[0] + temp[0].split("")[1] > 24) {
        temp[0] = "00";
      }
      if (temp[1] > 59) {
        temp[1] = 59;
      }
      temp = temp.join(":");
      return temp;
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      const second = [/[0-5]/, /[0-9]/];
      if (this.isSecond) {
        return value.length > 3 ? [...hours, ":", ...minutes, ":", ...second] : hours;
      } else {
        return value.length > 2 ? [...hours, ":", ...minutes] : hours;
      }
    },
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, "");
      if (numbers.length > 4) {
        return [
          ...this.timeMask(numbers.substring(0, 4)),
          "-",
          ...this.timeMask(numbers.substring(4))
        ];
      }
      return [...this.timeMask(numbers)];
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.time-picker {
  position: relative;
  width: 200px;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafe;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;

    &_complete {
      background: $surface-default;
      border: 2px solid $border-default;
    }

    &_focus {
      background: $surface-default;
      border: 2px solid $action-primary-accent;
    }
  }

  .icon-clock {
    margin-right: 16px;
    font-size: 20px;
    color: $icon-subdued;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #a7a9c0;
    outline: none;
    transition: all 0.2s ease;
    height: 40px;
    background: transparent;
    border-radius: 8px;
    border: 2px solid transparent;

    &::placeholder {
      color: $text-subdued;
    }
  }

  &:hover {
    .time-picker__wrap {
      background: #fafafe;
      border: 2px solid $action-primary-accent;
    }
  }

  &:focus {
    .time-picker__wrap {
      background: $surface-default;
      border: 2px solid $action-primary-accent;
    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 220px;
    overflow-y: scroll;
    z-index: 8;
    border-radius: 4px;
    background-color: $surface-default;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  &__time {
    padding: 11px 16px;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $text-default;
    border-radius: 4px;
    transition: all 0.2s ease;

    @media (hover: hover) {
      &:hover {
        color: $surface-default;
        background-color: $action-primary-accent;
      }
    }

    &:active {
      color: $surface-default;
      background-color: $action-primary-accent;
    }

    &_active {
      color: $surface-default;
      background-color: $action-primary-accent;
      position: relative;

      &:before {
        position: absolute;
        left: -8px;
        top: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        content: "";
        background-color: $action-primary;
        width: 3px;
        height: 36px;
      }
    }
  }
}
</style>
