<template>
  <div class="add-link-modal modal" ref="modal">
    <div id="draggable-area"></div>
    <div class="modal__header"><h3>Добавить переход</h3></div>
    <div class="modal__body">
      <p>Из статуса<span :style="{ color: 'red' }">*</span></p>
      <Select
        placeholder="Статус"
        :items="nodesArray"
        v-model="nodeFrom"
        :showAvatar="false"
        :multiple="false"
        :showSearchPanel="false"
        class="modal__select"
      />
      <p>В статус<span :style="{ color: 'red' }">*</span></p>
      <Select
        placeholder="Статус"
        :items="nodesArray"
        v-model="nodeTo"
        :showAvatar="false"
        :multiple="false"
        :showSearchPanel="false"
        class="modal__select"
      />
    </div>
    <div class="modal__footer">
      <Button type="cancel" title="Отменить" @handleButton="closeModal" />
      <Button type="simple" title="Добавить" @handleButton="addLink" />
    </div>
  </div>
</template>

<script>
import Select from "@/modules/UiKit/components/Select/Select";
import makeElementDraggable from "@/helpers/makeElementDraggable.mixin";
import Button from "@/modules/UiKit/components/buttons/Button";
import NotificationObserver from "@/helpers/UserNotification";

export default {
  name: "AddLinkModal",
  mixins: [makeElementDraggable],
  props: {
    nodesArray: {
      type: Array,
      default: () => {
        return [];
      }
    },
    linksArray: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Select, Button },
  data() {
    return {
      nodeFrom: [],
      nodeTo: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.dragElement(this.$refs.modal);
    });
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    addLink() {
      if (!this.linksValidation()) return;
      const data = {
        nodeFrom: this.nodeFrom[0],
        nodeTo: this.nodeTo[0]
      };
      this.$emit("addLink", data);
    },
    linksValidation() {
      if (this.nodeFrom.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите начальный статус!"
        });
        return false;
      } else if (this.nodeTo.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "Выберите конечный статус!"
        });
        return false;
      } else if (
        this.linksArray.some(
          (link) => link.from === this.nodeFrom[0].id && link.to === this.nodeTo[0].id
        )
      ) {
        NotificationObserver.notification({
          type: "error",
          message: "Такая связь существует!"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal {
  top: 155px;
  left: 200px;
  position: absolute;
  z-index: 100;
  width: 400px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 16px;
  overflow: hidden;
  #draggable-area {
    width: 100%;
    height: 48px !important;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__header {
    margin-bottom: 24px;
    h3 {
      padding: 0;
      margin: 0;
      font-weight: 900;
      font-size: 20px;
      color: $text-default;
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - 88px);
    overflow: auto;
    p {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 18px;
    }
  }
  &__select {
    margin-bottom: 24px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    /deep/ {
      .aop-button {
        padding: 0;
      }
    }
  }
  /deep/ {
    .select-modal {
      margin-top: 0px;
      ::-webkit-scrollbar {
        position: relative;
        height: 5px;
        overflow-x: auto;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        width: 8px;
        background: $icon-disabled;
      }
      ::-webkit-scrollbar-track {
        background: $bg-surface-light;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
}
</style>
