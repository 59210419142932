<template>
  <div class="members">
    <Label :label="label" :tooltip="'Сотрудники, которые решают проблему'" />
    <div class="members__content" v-if="!isProblemPage">
      <div class="members__user" v-for="item in getMembers" :key="item.id">
        <img v-if="item.avatar" :src="returnSrcFile(item.avatar)" alt="avatar" />
      </div>
      <div class="members__user" v-if="user.length > 3">
        <div class="members__count" @click="handleOpenProblem">+{{ user.length - 3 }}</div>
      </div>
    </div>
    <div class="members__content-page" v-else>
      <router-link
        v-for="item in setUsers"
        :key="item.id"
        :to="{ name: 'UserInfo', params: { user_id: item.id } }"
        :target="isGlobalMobileMode ? '_self' : '_blank'"
      >
        <ItemSelect :item="item" :showAvatar="true" :isShowRoles="false" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Label from "@/modules/Isp/components/IspProblem/Label";
import { domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import ItemSelect from "@/modules/UiKit/components/Select/ItemSelect";
import { mapState } from "vuex";

export default {
  name: "ExecutorsComponent",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    label: {
      type: String,
      default: ""
    },
    isProblemPage: {
      type: Boolean,
      default: false
    }
  },
  components: { ItemSelect, Label },
  data() {
    return {
      editMode: false,
      selected: [],
      itemsSelect: []
    };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleOpenProblem() {
      this.$router.push({ name: "IspProblem", params: { id: this.currentInfoProblem.id } });
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "filesLoader"]),
    getMembers() {
      return this.user ? this.user.filter((item, index) => index <= 2) : [];
    },
    setUsers() {
      return this.user.map((item) => {
        item.name = item.user_name;
        return item;
      });
    },
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {
    user() {}
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.members {
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-bottom: 24px;
  /deep/ {
    .label {
      margin-bottom: 12px;
      label {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #343748;
      }
      &__tooltip {
        right: 0;
        left: auto;
      }
    }
  }
  &__content-page {
    display: flex;
    flex-flow: column;
    padding: 7px 0;
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 7px 0;
    height: 58px;
  }
  &__user {
    display: flex;
    align-items: center;
    margin-left: -20px;
    &:first-child {
      margin-left: 0;
    }
  }
  &__count {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #eae8f0;
    border: 2px solid #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #0a1417;
  }
  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fafafe;
    margin-right: 8px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343748;
    word-break: break-word;
    max-width: 100px;
  }
}
</style>
