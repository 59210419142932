<template>
  <div class="profile__user-data-modal user-data-modal">
    <div class="user-data-modal__header">
      <i class="icon-sort-arrow" @click="$emit('hideModal')"></i>
      <h2 class="user-data-modal__title">Данные о сотруднике</h2>
      <ActionsList v-if="isAdmin && userCard" :actionList="actionList" />
    </div>
    <div class="user-data-modal__main">
      <div class="user-data-modal__personal-info">
        <div class="user-data-modal__user">
          <img :src="returnSrcFile(dataUser.avatar)" alt="UsePhoto" />
          <div class="user-data-modal__user-name">
            <p class="user-data-modal__name">{{ dataUser.full_name }}</p>
            <p class="user-data-modal__active">{{ setActiveStatus }}</p>
          </div>
        </div>
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Email</p>
          <p class="user-data-modal__content user-data-modal__content_email">
            <span ref="userEmail">{{ dataUser.email ? dataUser.email : "-" }}</span>
            <i class="icon-copy" @click="copyText($refs.userEmail)"></i>
          </p>
        </div>
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Номер телефона</p>
          <p class="user-data-modal__content user-data-modal__content_phone">
            <span ref="userPhone">{{ dataUser.phone ? dataUser.phone : "-" }}</span>
            <i class="icon-copy" v-if="dataUser.phone" @click="copyText($refs.userPhone)"></i>
          </p>
        </div>
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">День рождения</p>
          <p class="user-data-modal__content">
            {{ setBirthday }}
          </p>
        </div>
      </div>
      <div class="user-data-modal__department-info">
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Отдел</p>
          <p class="user-data-modal__content">{{ setDepartment }}</p>
        </div>
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Должность</p>
          <p class="user-data-modal__content">{{ setRoles }}</p>
        </div>
        <div class="user-data-modal__wrap user-data-modal__wrap_rate">
          <p class="user-data-modal__subtitle">Ставка</p>
          <p class="user-data-modal__content">{{ setRate }}</p>
        </div>
      </div>
      <div class="user-data-modal__working-info">
        <div class="user-data-modal__wrap user-data-modal__wrap_grade">
          <p class="user-data-modal__subtitle">Грейд</p>
          <Level :level="setGrade" />
        </div>

        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Черный шар</p>
          <SwitchWidget :editValue="dataUser.has_black_sphere" :readonly="true" />
        </div>
        <div class="user-data-modal__wrap">
          <p class="user-data-modal__subtitle">Дата подключения</p>
          <p
            class="user-data-modal__black-spheres-date"
            v-if="dataUser.date_of_include_black_sphere"
          >
            <i class="icon-calendar-secondary"></i>
            {{ setDateFormatWidthMonthName(dataUser.date_of_include_black_sphere) }}
          </p>
          <p class="user-data-modal__content" v-else>-</p>
        </div>
        <div class="user-data-modal__wrap user-data-modal__wrap_rate">
          <p class="user-data-modal__subtitle">Ставка</p>
          <p class="user-data-modal__content">{{ setRate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchWidget from "@/components/Widgets/SwitchWidget/SwitchWidget";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import ActionsList from "@/components/Widgets/ActionList/ActionList";
import { domainStorage } from "@/globalVariables";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import Level from "@/components/Widgets/Level/Level";
import copyText from "@/helpers/copyText";

export default {
  name: "ProfileUserDataModal",
  mixins: [formatDateAndTime, copyText],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userCard: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: { Level, SwitchWidget, ActionsList },
  data() {
    return {
      editMode: false,
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: "EditUserCard"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    setUserCardBirthdayFormat(date) {
      return format(new Date(date), "dd MMMM", { locale: ru });
    }
  },
  computed: {
    /** Функция установки статуса сотрудника
     * @returns {string} - статус сотрудника
     */
    setActiveStatus() {
      return this.dataUser.is_active ? "Работает" : "Не работает";
    },
    setBirthday() {
      return this.dataUser.date_of_birth
        ? this.userCard
          ? this.setUserCardBirthdayFormat(this.dataUser.date_of_birth)
          : this.setDateFormatFull(this.dataUser.date_of_birth)
        : "-";
    },
    /** Функция установки роли сотрудника
     * @returns {*|string} - роль сотрудника, при отсутствии роли устанавливается прочерк
     */
    setRoles() {
      return this.dataUser.roles.length ? this.dataUser.roles[0].name : "-";
    },
    /** Функция установки отдела сотрудника
     * @returns {*|string} - отдел сотрудника, при отсутствии отдела устанавливается прочерк
     */
    setDepartment() {
      return this.dataUser.departments.length ? this.dataUser.departments[0].name : "-";
    },
    /** Функция установки грейда сотрудника
     * @returns {*|string} - грейд сотрудника, при отсутствии грейда устанавливается грейд ACADEMY
     */
    setGrade() {
      return this.dataUser.levels.length ? this.dataUser.levels[0].name : "ACADEMY";
    },
    /** Функция установки ставки сотрудника
     * @returns {string} - ставка сотрудника
     */
    setRate() {
      return this.dataUser.rate_coefficient === "1" ? "Полная" : "Не полная";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.user-data-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $surface-default;
  z-index: 100;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 24px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      justify-content: flex-start;
    }

    i {
      font-size: 22px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-sort-arrow {
      transform: rotate(90deg);
      margin-right: 20px;

      @media (max-width: $sm) {
        display: block;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: $text-body-dark;
  }

  &__main {
    @media screen and (max-width: 850px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 80vh;
    }

    @media (max-width: $sm) {
      overflow-y: scroll;
      max-height: 90vh;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 75vh;
    }
  }

  &__personal-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      border-bottom: none;
      padding: 24px 24px 0;
    }
  }

  &__working-info {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
    justify-content: space-between;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      padding: 0 24px 24px;
    }

    @media (max-width: $sm) {
      padding: 24px 24px 0;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      padding: 0 24px 24px;
    }

    .user-data-modal__wrap {
      padding: 0 24px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        padding: 0;
        margin: 0 0 24px;
      }

      @media (max-width: $sm) {
        width: 50%;
        padding: 0;
        margin: 0 0 24px;
      }

      &_grade {
        @media (max-width: $sm) {
          width: 100%;
        }
      }

      &_rate {
        display: none;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          display: block;
        }

        @media (max-width: $sm) {
          display: block;
        }
      }
    }
  }

  &__department-info {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    justify-content: space-between;

    @media (max-width: $sm) {
      border-bottom: none;
      padding: 24px 24px 0;
    }

    .user-data-modal__wrap {
      width: 45%;
      padding: 56px 24px 0;
      margin: 0 0 104px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        padding: 0;
        margin: 0 0 24px;
      }

      @media screen and (max-width: 568px) and (max-height: 450px) {
        padding: 0;
        margin: 0 0 24px;
      }

      @media (max-width: $sm) {
        width: 100%;
        padding: 0;
        margin: 0 0 24px;
      }

      &_rate {
        display: block;

        @media screen and (max-width: 850px) and (max-height: 450px) {
          display: none;
        }

        @media screen and (max-width: 568px) and (max-height: 450px) {
          display: none;
        }

        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    width: 100%;

    img {
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
      object-fit: cover;
    }
  }

  &__user-name {
    width: 80%;
  }

  &__name {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__active {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
  }

  &__wrap {
    margin: 0 32px 0 0;

    @media (max-width: $sm) {
      width: 100%;
      margin: 0 0 24px;
    }

    /deep/ {
      .level {
        width: max-content;
      }

      .switch-widget__indicator {
        cursor: default;
      }
    }
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__content {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    font-feature-settings: "salt" on;
    color: $text-default;
    word-break: break-word;

    &_email,
    &_phone {
      span {
        margin-right: 8px;
        &::selection {
          background: transparent;
        }
      }

      i {
        color: $icon-subdued;
        cursor: pointer;

        &:hover {
          color: $icon-hovered;
        }
      }
    }
  }

  .icon-calendar-secondary {
    margin-right: 8px;
  }

  &__black-spheres-date {
    margin: 0;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: $bg-surface-light;
    border-radius: 12px;
  }
}
</style>
