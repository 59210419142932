














import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarDayWeek from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridWeek/CalendarDayWeek.vue";

@Component({
  components: { CalendarDayWeek }
})
export default class CalendarWeek extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  grid!: Array<any>;
  @Prop({
    default: true,
    type: Boolean
  })
  eventLoader!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowHeader!: boolean;
  @Prop({
    default: "",
    type: String
  })
  scrollTime!: string;

  mounted() {}
}
