export default {
  state: {
    themes: [],
    wikiIndex: null,
    wiki: {},
    editingWiki: false,
    pushInNavigation: false,
    sections: [],
    chosenSection: {},
    chosenCategory: {},
    updatingSectionData: {},
    storePageData: {},
    createSectionEndpoints: false,
    updateSectionEndpoints: false
  },
  mutations: {
    changeTheme(state, payload) {
      state.themes = payload
    },
    changeEditingWiki(state, val) {
      state.editingWiki = val
    },
    changeSections(state, payload) {
      state.sections = payload
    },
    changeChosenSection(state, payload) {
      state.chosenSection = payload
    },
    changeChosenCategory(state, payload) {
      state.chosenCategory = payload
    },
    changeUpdatingSectionData(state, payload) {
      state.updatingSectionData = payload
    },
    changeStorePageData(state, payload) {
      state.storePageData = payload
    },
    changePushInNavigation(state, payload) {
      state.pushInNavigation = payload
    },
    changeCreateSectionEndpoints(state, payload) {
      state.createSectionEndpoints = payload
    },
    changeUpdateSectionEndpoints(state, payload) {
      state.updateSectionEndpoints = payload
    }
  },
  actions: {
    checkNameTheme({ commit, state }, name) {
      return new Promise((resolve, reject) => {
        if (
          state.themes.findIndex((theme) => theme.label.toLowerCase() === name.toLowerCase()) === -1
        ) {
          resolve()
        } else reject(new Error('Ошибка'))
      })
    }
  },
  getters: {
    getEditingWiki(state) {
      return state.editingWiki
    },
    getSections(state) {
      return state.sections
    },
    getChosenSection(state) {
      return state.chosenSection
    },
    getUpdatingSectionData(state) {
      return state.updatingSectionData
    },
    getStorePageData(state) {
      return state.storePageData
    },
    getChosenCategory(state) {
      return state.chosenCategory
    },
    getPushInNavigation(state) {
      return state.pushInNavigation
    },
    getCreateSectionEndpoints(state) {
      return state.createSectionEndpoints
    },
    getUpdateSectionEndpoints(state) {
      return state.updateSectionEndpoints
    }
  }
}
