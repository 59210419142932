







import { Component, Prop, Vue } from "vue-property-decorator";
import TimerButton from "@/modules/TimeTracker/component/TImerButton/TimerButton.vue";
import TimeTracker from "@/modules/TimeTracker/store";

@Component({
  components: { TimerButton }
})
export default class MainTimer extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) isActive!: boolean;

  handleTimerButton() {
    this.$emit("handleTimerButton");
  }

  get time() {
    return TimeTracker.workTime;
  }
}
