

















































import Button from "@/modules/UiKit/components/buttons/Button";
import User from "@/components/Widgets/User/User";

import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
@Component({
  components: { Skeleton, Button, User }
})
export default class NotificationCard extends Vue {
  @Prop({
    type: Object,
    default: () => {}
  })
  card;
  service = {
    task_manager: {
      title: "Менеджер задач",
      icon: "task"
    },

    taskmanager: {
      title: "Менеджер задач",
      icon: "task"
    },
    time_manager: {
      title: "Трекер времени",
      icon: "time"
    },
    timemanager: {
      title: "Трекер времени",
      icon: "time"
    },
    isp: {
      title: "ISP",
      icon: "isp"
    },
    calendar: {
      title: "Календарь",
      icon: "calendar"
    },
    messenger: {
      title: "Чат",
      icon: "messenger"
    },
    news: {
      title: "Новости",
      icon: "news"
    }
  };
  linkOpenHandler(openInNewWindow) {
    this.readNotification();
    this.$store.commit("Notification/toggleModal", false);
    if (this.card.type !== "EVENT_DELETED") {
      const CalendarModule2 = require("@/aopV2/modules/Calendar/store/calendarModule").default;

      // It's a switch statement that checks the service name and depending on the service name it will open the link in a
      // new window or in the current window.
      switch (this.card.service_name) {
        case "task_manager": {
          const linkTaskManager = {
            name: this.card.service_name
              ? this.card.service_name.split("_")[0][0].toUpperCase() +
                this.card.service_name.split("_")[0].slice(1)
              : undefined,
            params: { id: this.taskId },
            query: {
              alias: this.alias === "WAP" ? undefined : this.alias
            }
          };
          if (openInNewWindow) {
            window.open(this.$router.resolve(linkTaskManager).href, "_blank");
          } else {
            this.$router.push(linkTaskManager);
          }
          break;
        }
        case "calendar": {
          const linkCalendar = {
            name: this.card.service_name
              ? this.card.service_name.split("_")[0][0].toUpperCase() +
                this.card.service_name.split("_")[0].slice(1)
              : undefined,
            query: { event_id: this.card.data.link.id }
          };
          this.getEvent(this.card.data.link.id);
          if (openInNewWindow) {
            window.open(this.$router.resolve(linkCalendar).href, "_blank");
          } else {
            this.$router.push(linkCalendar);

            CalendarModule2.toggleViewModal(true);
          }
          break;
        }
        default:
          return console.error("Имя сервиса не распознано");
      }
    }
  }
  async getEvent(id) {
    const CalendarModule2 = require("@/aopV2/modules/Calendar/store/calendarModule").default;

    CalendarModule2.setLoader(true);
    await CalendarModule2.getEvent({
      id: id,
      timezone: this.timezone
    }).then((error) => {
      if (error === 404 || error === 422 || error === 403 || error === 400) {
        this.$router.push({ name: "Calendar" });
        CalendarModule2.toggleViewModal(false);
      }
    });
    CalendarModule2.setLoader(false);
  }
  get currentEvent() {
    const CalendarModule2 = require("@/aopV2/modules/Calendar/store/calendarModule").default;
    return CalendarModule2.currentEvent;
  }
  get timezone() {
    const CalendarModule2 = require("@/aopV2/modules/Calendar/store/calendarModule").default;
    return CalendarModule2.timezone;
  }

  readNotification() {
    if (this.card.status === 0) {
      this.$store.dispatch("Notification/readNotificationAction", this.card.id);
    }
  }

  get serviceName() {
    return this.service[this.card.service_name].title;
  }

  get serviceIcon() {
    return this.service[this.card.service_name].icon;
  }

  handleChangeInvitation(value: boolean | null) {
    const CalendarModule2 = require("@/aopV2/modules/Calendar/store/calendarModule").default;
    if (this.currentEvent?.is_accepted_invitation !== value) {
      const payload = { event_id: this.currentEvent?.id!, is_accepted_invitation: value };
      CalendarModule2.changeInvitation(payload);
    }
    this.readNotification();
  }

  get author() {
    return this.card.author
      ? this.$store.getters["UsersModule/userById"](this.card.author)[0]
      : null;
  }

  get authorAvatar() {
    return this.author ? this.author.avatar : "-";
  }

  get authorName() {
    return this.author ? this.author.full_name : "-";
  }

  get authorId() {
    return this.author ? this.author.id : 0;
  }

  get authorRole() {
    return this.author ? this.author.roles[0].name : "-";
  }
  get date() {
    return moment(this.card.created_at).format("HH:mm DD.MM.YYYY");
  }
  get alias() {
    return this.card.data && this.card.data.number ? this.card.data.number.split("-")[0] : null;
  }
  get taskId() {
    return this.card.data && this.card.data.number ? this.card.data.number.split("-")[1] : null;
  }
  get loader() {
    return this.$store.state.Notification.loader;
  }
  get isShowLink() {
    return this.card.data.link && this.card.type !== "EVENT_DELETED";
  }
  //Уведомление, связанное с календарем. Доработать в будущем
  get isEvent() {
    // return !!this.card.data.start_date;
    return false;
  }

  get message() {
    return this.card.message.split("\n");
  }
}
