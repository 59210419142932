import actions from "@/modules/Calendar/store/actions/actions";
import mutations from "@/modules/Calendar/store/mutations/mutations";
import getters from "@/modules/Calendar/store/getters/getters";
import state from "@/modules/Calendar/store/state/state";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
