<template>
  <div class="modal__footer">
    <button
      class="modal__button modal__button_cancel"
      type="button"
      @click="confirmVacation(false)"
    >
      Отклонить
    </button>
    <button class="modal__button" type="button" @click="confirmVacation(true)">Подтвердить</button>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ModalFooterConfirm",
  mixins: [],
  props: {
    vacation: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default: ""
    },
    isProfile: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      comment: ""
    };
  },
  mounted() {
    this.$root.$on("changeComment", (val) => {
      this.comment = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("changeComment");
  },
  methods: {
    confirmVacation(is_checked) {
      if (this.vacation.hasOwnProperty("is_checked")) {
        this.RequestConfirm(is_checked);
      } else {
        if (this.type === "transfer") {
          this.transferConfirm(is_checked);
        } else if (this.type === "vacation") {
          this.vacationConfirm(is_checked);
        }
      }
    },
    RequestConfirm(is_checked) {
      let data = [
        {
          is_checked: is_checked
        }
      ];
      if (this.comment.length) {
        data = [
          {
            is_checked: is_checked,
            comment: this.comment
          }
        ];
      }
      if (!is_checked) {
        if (!this.checkValidationData()) return null;
        data = [
          {
            is_checked: is_checked,
            comment: this.comment
          }
        ];
      }
      httpClient({
        url: `/Confirmation/actionUpdate?id=${this.vacation.id}`,
        method: "PUT",
        data: data
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Подверждение отправлено"
          });
          this.$root.$emit("closeRequestConfirmation");
          this.$root.$emit("updateVacationRequestTable");
          if (!is_checked && !this.isProfile) {
            this.$root.$emit("openVacationRequestModal", {
              vacation: this.vacation,
              editMode: false,
              createMode: true
            });
          }
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    transferConfirm(is_checked) {
      let data = [
        {
          is_vacation_admin_accepted: is_checked
        }
      ];
      if (this.comment.length) {
        data = [
          {
            is_vacation_admin_accepted: is_checked,
            comment_to_change_is_vacation_admin_accepted: this.comment
          }
        ];
      }
      if (!is_checked) {
        if (!this.checkValidationData()) return null;
        data = [
          {
            is_vacation_admin_accepted: is_checked,
            comment_to_change_is_vacation_admin_accepted: this.comment
          }
        ];
      }
      httpClient({
        url: `/TransferVacationOrder/actionUpdate?id=${this.vacation.id}`,
        method: "PUT",
        data: data
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Подверждение отправлено"
          });
          this.$root.$emit("closeMyConfirmations");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    vacationConfirm(is_checked) {
      let data = [
        {
          is_vacation_admin_accepted: is_checked
        }
      ];
      if (this.comment.length) {
        data = [
          {
            is_vacation_admin_accepted: is_checked,
            comment_to_change_is_vacation_admin_accepted: this.comment
          }
        ];
      }
      if (!is_checked) {
        if (!this.checkValidationData()) return null;
        data = [
          {
            is_personnel_officer_accepted: is_checked,
            comment_to_change_is_personnel_officer_accepted: this.comment
          }
        ];
      }
      httpClient({
        url: `/AbsenceOrder/actionUpdate?id=${this.vacation.id}`,
        method: "PUT",
        data: data
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Подверждение отправлено"
          });
          this.$root.$emit("closeMyConfirmations");
          this.$root.$emit("updateVacationRequestTable");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    checkValidationData() {
      if (!this.comment.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Укажите причину отклонения запроса!"
        });
        return false;
      }

      return true;
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

.modal {
  &__footer {
    border-top: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 30px 42px 42px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }
    &_disabled {
      background: $action-critical-disabled;
      &:hover {
        background: $action-critical-disabled;
      }
    }
    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
