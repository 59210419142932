






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MobileButton extends Vue {
  @Prop({
    default: null,
    type: Function
  })
  mobileButton!: any;
}
