<template>
  <div class="isp-teams">
    <div class="isp-teams__header">
      <h2>{{ archive ? "Архивированные" : "Актуальные" }} команды</h2>
      <p class="isp-teams__change-teams" @click="changeTeams">
        Перейти в {{ archive ? "актуальные" : "архив" }}
      </p>
    </div>
    <Spinner v-if="loaderTeams" class="isp-teams__loader" />
    <div class="isp-teams__body-wrap" v-else>
      <div class="isp-teams__empty" v-if="!currentTeams.length"><p>СПИСОК КОМАНД ПУСТ</p></div>
      <div class="isp-teams__body">
        <div class="isp-teams__create" v-if="!archive && isIspAdmin">
          <ModalButton
            :type="'create-light'"
            :icon="'user-add'"
            :title="'Добавить команду'"
            @handleButton="openCurtainCreateTeam"
          />
        </div>
        <IspTeamCard v-for="team in currentTeams" :key="team.id" :team="team" />
      </div>
      <!--      <Spinner v-if="loaderTeamsScroll" class="isp-teams__scroll-loader" />-->
    </div>
  </div>
</template>

<script>
import IspTeamCard from "@/modules/Isp/components/IspTeams/IspTeamCard";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import { mapActions, mapMutations, mapState } from "vuex";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "IspTeams",
  mixins: [],
  props: {},
  components: { Spinner, ModalButton, IspTeamCard },
  data() {
    return {
      archive: false
    };
  },
  mounted() {
    this.getTeamsAction(false);
  },
  methods: {
    ...mapActions("IspModule", ["getTeamsAction"]),
    ...mapMutations("IspModule", ["TOGGLE_CURTAIN_CREATE_TEAM"]),
    changeTeams() {
      this.archive = !this.archive;
      this.getTeamsAction(this.archive);
    },
    openCurtainCreateTeam() {
      this.TOGGLE_CURTAIN_CREATE_TEAM(true);
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeams", "loaderTeams"]),
    isIspAdmin() {
      return this.$store.getters.getDataInfoUser.roles.includes("isp_admin");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
}
.isp-teams {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
  overflow: auto;
  &__header {
    display: flex;
    align-items: center;
    padding: 16px 0 24px 16px;
    h2 {
      margin-right: 16px;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__scroll-loader {
    margin: 10px auto;
  }
  &__change-teams {
    color: #6a67ce;
    cursor: pointer;
    padding-top: 5px;
    &:hover {
      color: #3a379b;
    }
  }
  &__body-wrap {
    height: calc(100% - 65px);
    overflow: auto;
  }
  &__empty {
    position: absolute;
    top: 50%;
    left: 43%;
    color: $text-default;
  }
  &__body {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    padding: 12px 1px 6px 12px; //пиксель справа необходим для пагинации по скроллу
    overflow: auto;
    @media (max-width: $xxxl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $lg) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media (max-width: $md) {
      padding: 12px 12px 20px 12px;
    }
  }
  &__create {
    background-color: #fcfcfe;
    width: 100%;
    height: 254px;
    border-radius: 8px;
    border: 1px dashed #6a67ce;
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/ {
      .default-button_create-light {
        color: #5551f9;
        border-color: #5551f9;
        &:hover {
          color: #fff;
        }
        i {
          transform: scale(-1, 1);
          font-size: 18px;
        }
      }
    }
  }
}
</style>
