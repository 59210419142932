















import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarDayWeek from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridWeek/CalendarDayWeek.vue";
import CalendarDayMonth from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridMonth/CalendarDayMonth.vue";
import CalendarTimeGrid from "@/aopV2/modules/Calendar/components/CalendarTime/CalendarTimeGrid.vue";
import moment from "moment";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";

@Component({
  components: { CalendarTimeGrid, CalendarDayMonth, CalendarDayWeek }
})
export default class CalendarWeeks extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  grid!: Array<any>;
  timerId: number = 0;

  wheel(e) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (e.deltaY > 0) {
        const date = dateFormat(moment(this.date).add(1, "month"));
        CalendarModule2.setDate(date);
      } else {
        const date = dateFormat(moment(this.date).subtract(1, "month"));
        CalendarModule2.setDate(date);
      }
    }, 300);
  }

  setWeekDay(index) {
    if (this.contentWidth !== null) {
      if (this.contentWidth > 1200) {
        return `${moment().day(index).format("dddd")}`;
      } else {
        return `${moment().day(index).format("dd")}`;
      }
    } else {
      return `${moment().day(index).format("dddd")}`;
    }
  }

  get contentWidth() {
    return this.$store.getters["MainLayoutSetting/getContentWidth"];
  }

  get date() {
    return CalendarModule2.date;
  }
}
