<template>
  <div class="edit-node-modal modal" ref="modal">
    <div class="modal__header" id="draggable-area">
      <h3>Редактировать статус</h3>
      <Tooltip
        :text="'Если в данном статусе находятся задачи, то их статус автоматически изменится на отредактированный.'"
      />
    </div>
    <div class="modal__body">
      <p>Название<span :style="{ color: 'red' }">*</span></p>
      <Input class="modal__input" placeholder="Статус" v-model.trim="nodeTitle" :maxlength="20" />
    </div>
    <div class="modal__footer">
      <Button type="cancel" title="Отменить" @handleButton="closeModal" />
      <Button type="simple" title="Сохранить" @handleButton="editNode" />
    </div>
  </div>
</template>

<script>
import makeElementDraggable from "@/helpers/makeElementDraggable.mixin";
import Button from "@/modules/UiKit/components/buttons/Button";
import Input from "@/modules/UiKit/components/Inputs/Input";
import NotificationObserver from "@/helpers/UserNotification";
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";

export default {
  name: "EditNodeModal",
  mixins: [makeElementDraggable],
  props: {
    nodeToEdit: {
      type: Object,
      default: () => {}
    },
    nodesArray: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Tooltip, Button, Input },
  data() {
    return {
      nodeTitle: ""
    };
  },
  mounted() {
    this.nodeTitle = this.nodeToEdit.name;
    this.$nextTick(() => {
      this.dragElement(this.$refs.modal);
    });
    document.querySelector(".input__control").focus();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    editNode() {
      if (!this.titleValidation()) return;
      this.$emit("editNode", this.nodeTitle);
    },
    titleValidation() {
      if (this.nodeTitle.length === 0) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите название статуса!"
        });
        return false;
      } else if (this.nodeTitle.length > 20) {
        NotificationObserver.notification({
          type: "error",
          message: "Название не может содержать более 20 символов!"
        });
        return false;
      } else if (!/^[a-zA-Z0-9' 'а-яёА-ЯЁ]+$/.test(this.nodeTitle)) {
        NotificationObserver.notification({
          type: "error",
          message: "Название должно состоять из a-z, A-Z, а-я, А-Я, 0-9 и пробел!"
        });
        return false;
      } else if (this.nodesArray.some((node) => node.name === this.nodeTitle)) {
        NotificationObserver.notification({
          type: "error",
          message: "Статус с таким наименованием уже существует"
        });
        return false;
      }
      return true;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal {
  top: 40%;
  left: 40%;
  position: absolute;
  z-index: 100;
  width: 400px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 16px;
  &__header {
    margin-bottom: 24px;
    display: flex;
    h3 {
      padding: 0;
      margin: 0;
      font-weight: 900;
      font-size: 20px;
      color: $text-default;
    }
  }
  &__body {
    p {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 18px;
    }
  }
  &__input {
    margin-bottom: 24px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    /deep/ {
      .aop-button {
        padding: 0;
      }
    }
  }
}
</style>
