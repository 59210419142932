import IspReview from "@/modules/Isp/store/actions/IspReview/IspReview";
import IspMy from "@/modules/Isp/store/actions/IspMy/IspMy";
import IspTeams from "@/modules/Isp/store/actions/IspTeams/IspTeams";
import IspProblem from "@/modules/Isp/store/actions/IspProblem/IspProblem";

import { domain } from "@/globalVariables";
import router from "@/router";
import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import ErrorObserver from "@/api/Error";
import Search from "@/modules/Isp/store/actions/Search/Search";
import httpClient from "@/api/client/httpClient";

const actions = {
  getStatuses({ commit }) {
    httpClient({
      method: "GET",
      url: `/FlowStatus/actionGetItems`,
      params: {}
    })
      .then((response) => {
        if (response.data.data.items.length) {
          commit(types.GET_STATUSES_LIST, response.data.data.items);
        }
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  getCriticality({ commit }) {
    httpClient({
      method: "GET",
      url: `/ISPCriticality/actionGetItems`,
      params: {}
    })
      .then((response) => {
        if (response.data.data.items.length) {
          commit(types.GET_CRITICALITY_LIST, response.data.data.items);
        }
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  getUsers({ commit }) {
    httpClient({
      url: `/User/getActive`,
      method: "GET"
    })
      .then((response) => {
        if (response.data.data.length) {
          commit(types.GET_USERS_LIST, response.data.data);
        }
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  ...IspReview,
  ...IspMy,
  ...IspTeams,
  ...IspProblem,
  ...Search
};

export default actions;
