<template>
  <component :is="getIcon" v-if="iconName"></component>
</template>

<script>
export default {
  name: "IconWrapSidebar",
  mixins: [],
  props: {
    iconName: {
      type: String,
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    getIcon() {
      return () => import(`@/layout/SideNav/SidenavIcon/Icon${this.iconName}`);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
</style>
