<template>
  <div class="isp-team-info__container" @mousedown.self="hideInfo">
    <div class="isp-team-info">
      <ConfirmModal
        v-if="confirmDeleteTeam"
        :title="'Удалить'"
        :description="'Вы уверены, что хотите удалить команду?'"
        @cancel="cancelDeleteTeam"
        @accept="acceptDeleteTeam"
      />
      <ConfirmModal
        v-if="confirmArchiveTeam"
        :title="'Архивировать'"
        :description="'Вы уверены, что хотите архивировать команду?'"
        @cancel="cancelArchiveTeam"
        @accept="acceptArchiveTeam"
      />
      <ConfirmModal
        v-if="confirmUnarchiveTeam"
        :title="'Разархивировать'"
        :description="'Вы уверены, что хотите разархивировать команду?'"
        @cancel="cancelUnarchiveTeam"
        @accept="acceptUnarchiveTeam"
      />
      <div class="isp-team-info__header">
        <div class="isp-team-info__title">
          <button class="isp-team-info__hide-btn" type="button" @click="hideInfo">
            <i class="icon-arrow-short"></i>
          </button>
          <IspTeamTitle
            @setEditMode="editMode = true"
            @cancelEditMode="editMode = false"
            :isEditable="isIspAdmin"
          />
          <div class="isp-team-info__control" v-show="!editMode && isIspAdmin">
            <i
              class="icon-archive"
              v-if="!currentTeam.is_archived"
              @click="openConfirmArchiveTeam"
            ></i>
            <i class="icon-unarchive" v-else @click="openConfirmUnarchiveTeam"></i>
            <i class="icon-delete" @click="openConfirmDeleteTeam"></i>
          </div>
        </div>
        <IspTeamShortTitle :isEditable="isIspAdmin" />
      </div>
      <div class="isp-team-info__body">
        <div class="isp-team-info__users users">
          <IspTeamResponsible :isEditable="isIspAdmin" />
          <IspTeamMembers />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import IspTeamTitle from "@/modules/Isp/components/IspTeams/IspTeamInfo/IspTeamTitle";
import IspTeamShortTitle from "@/modules/Isp/components/IspTeams/IspTeamInfo/IspTeamShortTitle";
import IspTeamResponsible from "@/modules/Isp/components/IspTeams/IspTeamInfo/IspTeamResponsible";
import IspTeamMembers from "@/modules/Isp/components/IspTeams/IspTeamInfo/IspTeamMembers";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
export default {
  name: "IspTeamInfo",
  mixins: [],
  props: {},
  components: { ConfirmModal, IspTeamTitle, IspTeamShortTitle, IspTeamResponsible, IspTeamMembers },
  data() {
    return {
      editMode: false,
      editModeMembers: false,
      confirmDeleteTeam: false,
      confirmArchiveTeam: false,
      confirmUnarchiveTeam: false
    };
  },
  mounted() {
    this.getAllUsersAction();
  },
  methods: {
    ...mapActions("IspModule", [
      "getAllUsersAction",
      "deleteTeamAction",
      "getTeamsAction",
      "editTeamAction"
    ]),
    ...mapMutations("IspModule", ["TOGGLE_SHOW_ISP_TEAM_INFO"]),
    hideInfo() {
      this.TOGGLE_SHOW_ISP_TEAM_INFO(false);
    },
    openConfirmArchiveTeam() {
      this.confirmArchiveTeam = true;
    },
    openConfirmUnarchiveTeam() {
      this.confirmUnarchiveTeam = true;
    },
    openConfirmDeleteTeam() {
      this.confirmDeleteTeam = true;
    },
    cancelDeleteTeam() {
      this.confirmDeleteTeam = false;
    },
    cancelArchiveTeam() {
      this.confirmArchiveTeam = false;
    },
    cancelUnarchiveTeam() {
      this.confirmUnarchiveTeam = false;
    },
    acceptDeleteTeam() {
      this.deleteTeamAction(this.currentTeam.id).then(() => {
        this.TOGGLE_SHOW_ISP_TEAM_INFO(false);
        this.getTeamsAction(this.currentTeam.is_archived);
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Команда удалена`
        });
      });
    },
    acceptArchiveTeam() {
      const editedTeam = {
        is_archived: true,
        leader_user_id: this.currentTeam.leader_user_id,
        member_users: this.currentTeam.member_users.map((item) => item.id)
      };
      this.editTeamAction({ editedTeam, id: this.currentTeam.id }).then(() => {
        this.TOGGLE_SHOW_ISP_TEAM_INFO(false);
        this.getTeamsAction(this.currentTeam.is_archived);
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Команда архивирована`
        });
      });
    },
    acceptUnarchiveTeam() {
      const editedTeam = {
        is_archived: false,
        leader_user_id: this.currentTeam.leader_user_id,
        member_users: this.currentTeam.member_users.map((item) => item.id)
      };
      this.editTeamAction({ editedTeam, id: this.currentTeam.id }).then(() => {
        this.TOGGLE_SHOW_ISP_TEAM_INFO(false);
        this.getTeamsAction(this.currentTeam.is_archived);
        this.$root.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Команда разархивирована`
        });
      });
    }
  },
  computed: {
    ...mapState("IspModule", ["showIspTeamInfo", "currentTeam"]),
    isIspAdmin() {
      return this.$store.getters.getDataInfoUser.roles.includes("isp_admin");
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
}
.isp-team-info {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__header {
    padding: 32px 16px 40px;
    @media screen and (max-width: 850px) and (orientation: landscape) {
      padding: 16px 16px 20px;
    }
  }
  &__hide-btn {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    @media (max-width: $md) {
      display: inline-block;
    }
    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  &__control {
    width: 56px;
    display: flex;
    justify-content: space-between;
    i {
      font-size: 20px;
      color: #a7a9c0;
      cursor: pointer;
      transition: 0.3s all ease;
      &:hover {
        color: $icon-highlight;
      }
    }
  }
  &__body {
    padding: 24px;
    height: calc(100% - 135px);
    overflow: auto;
    @media screen and (max-width: 850px) and (orientation: landscape) {
      height: calc(100% - 95px);
    }
    /deep/ {
      input {
        width: 100%;
      }
    }
  }
  /deep/ {
    .select-bg__selected-list {
      max-height: 180px;
      overflow: auto;
      align-items: flex-start;
    }
    .isp-team-members__members-list {
      @media (max-width: $xxl) {
        grid-row-gap: 20px;
        grid-column-gap: 0;
      }
      @media (max-width: $sm) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
.users {
  height: 100%;
}
</style>
