<template>
  <div class="switch-widget" :class="{ disabled: disabled }">
    <div class="switch-widget__label-wrap" v-if="label.length">
      <label class="switch-widget__label">{{ label }}</label>
      <i class="icon-info" v-if="showInfo"></i>
      <p class="switch-widget__tooltip">{{ tooltip }}</p>
    </div>
    <div
      :class="['switch-widget__container', { 'switch-widget__container_small': small }]"
      @click="toggleValue"
    >
      <input class="switch-widget__input" type="checkbox" :checked="value" />
      <span
        :class="['switch-widget__indicator', { 'switch-widget__indicator_small': small }]"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchIOS",
  mixins: [],
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleValue() {
      this.$emit("change", !this.value);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";

.disabled {
  pointer-events: none;
}

.switch-widget {
  width: 100%;
  transition: all 0.3s ease-in-out;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__label {
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #0a1417;
  }

  &__input {
    display: none;
    font-size: 0;
    width: 0;
    height: 0;

    &:checked + .switch-widget__indicator {
      border-color: $action-primary;
      background: $message-gradient;

      &::before {
        top: 2px;
        left: 18px;
        width: 20px;
        height: 20px;
        background-color: #fafafe;
      }
    }

    &:checked + .switch-widget__indicator_small {
      &::before {
        top: 2px;
        left: 18px;
        width: 12px;
        height: 12px;
      }
    }
  }

  &__indicator {
    position: relative;
    width: 41px;
    height: 24px;
    background: $background-gradient;
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s ease;
    }

    &_small {
      width: 32px;
      height: 16px;

      &::before {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__label-wrap {
    position: relative;
    display: flex;
    margin: 0 0 24px;
    width: max-content;
  }

  .icon-info {
    align-self: flex-start;
    margin-left: 8px;
    font-size: 14px;
    line-height: 12px;
    color: $icon-subdued;
    cursor: pointer;

    &:hover {
      color: $icon-hovered;
    }

    &:hover + .switch-widget__tooltip {
      display: block;
    }

    &:active {
      color: $icon-pressed;
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    max-width: 530px;
    width: max-content;
    right: -100%;
    top: -30px;
    z-index: 2;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
  }
}
</style>
