<template>
  <div class="label" v-if="label.length" @mouseleave="fullTooltip = false">
    <label>{{ label }}</label>
    <div class="label__wrap">
      <i class="icon-info" v-if="tooltip"></i>
      <p class="label__tooltip" v-show="!fullTooltip" ref="tooltip">
        {{ tooltip }}
        <span class="label__more" v-if="isFullText" @click="fullTooltip = true">Подробнее</span>
      </p>
      <p class="label__tooltip" v-html="fullText" v-show="fullTooltip"></p>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "Label",
  mixins: [],
  props: {
    label: {
      type: String,
      default: ""
    },
    fullText: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    isFullText: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      fullTooltip: false
    };
  },
  mounted() {
    if (this.tooltip.length) {
      this.setPositionTooltip();
      setTimeout(() => {
        this.setPositionTooltip();
      }, 600);
    }
    window.addEventListener("resize", this.setPositionTooltip);
  },
  methods: {
    setPositionTooltip() {
      const tooltip = this.$refs.tooltip;
      const rectTooltip = tooltip.getBoundingClientRect();
      const parentTooltipHigherOrder = tooltip.parentNode.parentNode.parentNode;
      const parentTooltip = tooltip.parentNode;
      const offsetRightParentTooltip =
        parentTooltipHigherOrder.getBoundingClientRect().width -
        (parentTooltip.getBoundingClientRect().right -
          parentTooltipHigherOrder.getBoundingClientRect().left);
      const rectParent = parentTooltipHigherOrder.getBoundingClientRect();
      tooltip.style.maxWidth = rectParent.width - 30 + "px";
      if (offsetRightParentTooltip < rectTooltip.width) {
        const leftPosition = rectParent.width - rectTooltip.width - 30 + "px";
        if (leftPosition > 0) {
          tooltip.style.left = leftPosition;
        } else {
          tooltip.style.left = 0 + "px";
        }
      } else {
        tooltip.style.left = "auto";
      }
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.label {
  position: relative;
  display: flex;
  margin: 0 0 24px;
  width: max-content;

  &__wrap {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .label__tooltip {
        visibility: visible;
        opacity: 1;
      }

      .icon-info {
        color: $icon-hovered;
      }
    }
  }

  &__more {
    text-decoration: underline;
  }

  label {
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #0a1417;
  }

  &__tooltip {
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    max-width: 430px;
    width: max-content;
    top: 100%;
    z-index: 100;
    padding: 4px 8px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    word-break: break-word;
  }

  .icon-info {
    align-self: flex-start;
    margin-left: 8px;
    font-size: 14px;
    line-height: 12px;
    color: $icon-subdued;

    &:hover {
      color: $icon-hovered;
    }

    &:active {
      color: $icon-pressed;
    }
  }
}
</style>
