
























import { Component, Vue } from "vue-property-decorator";
import calendarGenerate, {
  ICalendarGenerate,
  IDay
} from "@/aopV2/modules/Calendar/helper/calendarGenerate";
import moment from "moment";
import CalendarMonth from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridMonth/CalendarMonth.vue";
import CalendarTimeGrid from "@/aopV2/modules/Calendar/components/CalendarTime/CalendarTimeGrid.vue";
import CalendarWeek from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridWeek/CalendarWeek.vue";
import BaseSection from "@/aopV2/components/BaseSection/BaseSection.vue";
import CalendarModule2, { gridType } from "@/aopV2/modules/Calendar/store/calendarModule";
import CalendarGroup from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarGroup/CalendarGroup.vue";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal.vue";
import CreateEvent from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/CreateEvent.vue";
import CalendarLocation from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarLocation/CalendarLocation.vue";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import CalendarEventList from "@/aopV2/modules/Calendar/components/CalendarEventCard/CalendarEventList.vue";
import ViewEvent from "@/aopV2/modules/Calendar/components/Modal/ViewEvent/ViewEvent.vue";
import CalendarUsers from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarUsers/CalendarUsers.vue";
import ModalEmployment from "@/aopV2/modules/Calendar/components/Modal/Employment/ModalEmployment.vue";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import EventTooltip from "@/aopV2/modules/Calendar/components/Modal/Tooltip/EventTooltip.vue";
import { IGetEventParams } from "@/aopV2/modules/Calendar/service/CalendarInterface";
import ModalWithBG from "@/aopV2/modules/Calendar/components/Modal/ModalWithBG.vue";

@Component<CalendarView>({
  components: {
    ModalWithBG,
    EventTooltip,
    ModalEmployment,
    ViewEvent,
    CalendarEventList,
    CalendarLocation,
    CreateEvent,
    MainModal,
    CalendarGroup,
    BaseSection,
    CalendarWeek,
    CalendarTimeGrid,
    CalendarMonth,
    CalendarUsers
  },
  watch: {
    paramsWatch() {
      this.setParams();
      if (this.isNeedDataLoad) this.getEvents();
    },
    date_from() {
      CalendarModule2.setDateFrom(this.date_from);
    },
    date_to() {
      CalendarModule2.setDateTo(this.date_to);
    },
    eventsArrayWatcher() {
      this.gridRender();
    },
    isNeedDataLoad(val) {
      if (val) {
        CalendarModule2.saveSelectedUsers([{ id: this.getUserId, full_name: "Мой" }]);
        if (this.$route.name === "Events") {
          CalendarModule2.saveSelectedTypes(this.eventsTypes);
        } else {
          CalendarModule2.saveSelectedTypes(this.types);
        }
        CalendarModule2.saveSelectedLocation(this.locations);
        this.setParams();
      }
    },
    activeTab() {
      if (this.activeTab !== this.$route.name) {
        this.updateTab(this.activeTab);
        CalendarModule2.setActiveTab(this.activeTab);
      }
    }
  }
})
export default class CalendarView extends Vue {
  mode: string = "show";
  group: any = [];
  gridEvent: Array<any> = [];
  eventLoader: boolean = false;
  localStorage: any = null;
  isPerson: boolean = true;
  isWorking: boolean = false;
  isEvents: boolean = false;

  get isLoadUser() {
    return this.$store.state.isLoadUser;
  }

  created() {
    this.gridEvent = this.grid;
  }

  mounted() {
    this.$store.commit("MainLayoutSetting/setSetting", {
      leftPanel: null,
      rightPanel: () =>
        import("@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarControlPanel"),
      headerSlot: () => import("@/aopV2/modules/Calendar/components/CalendarHeader/CalendarHeader"),
      mobileButton: () =>
        import("@/aopV2/modules/Calendar/components/CalendarMobileButton/CalendarMobileButton"),
      title: "Календарь",
      tabs: [
        { name: "Личный календарь", route: "Person" },
        { name: "Рабочий календарь", route: "Work" },
        {
          name: "Календарь мероприятий",
          route: "Events"
        }
      ],
      activeTab: this.$route.name,
      isMainTab: true
    });
    this.gridRender();
    if (this.$route.name) {
      CalendarModule2.setActiveTab(this.$route.name);
    }

    if (!isObjectEmptyChecker(this.$route.query)) {
      CalendarModule2.toggleViewModal(true);
      this.getEvent(this.$route.query.event_id);
    }
    if (this.isLoad) {
      if (this.eventsParams === null) {
        this.setParams();
        this.getEvents();
      }
    } else {
      Promise.all([
        CalendarModule2.getEventTypeCategories(),
        CalendarModule2.getAllEventType(),
        CalendarModule2.getAllLocation()
      ]).then(() => {
        CalendarModule2.toggleMainLoader(false);
        CalendarModule2.setIsLoadType(true);
      });
    }
    CalendarModule2.setDateFrom(this.date_from);
    CalendarModule2.setDateTo(this.date_to);
  }

  get isLoad() {
    return CalendarModule2.isLoad;
  }

  get isNeedDataLoad() {
    return !this.isMainLoader && this.isLoadUser;
  }

  async getEvent(id) {
    CalendarModule2.setLoader(true);
    await CalendarModule2.getEvent({
      id: id,
      timezone: this.timezone
    }).then((error) => {
      if (error === 404 || error === 422 || error === 403 || error === 400) {
        this.$router.push({ name: "Calendar" });
        CalendarModule2.toggleViewModal(false);
      }
    });
    CalendarModule2.setLoader(false);
  }

  async getEvents() {
    this.gridEvent = this.grid;
    await this.getCalendarEvents();
  }

  async updateTab(name) {
    if (name === "Events") {
      CalendarModule2.saveSelectedTypes(this.eventsTypes);
    } else {
      CalendarModule2.saveSelectedTypes(this.types);
    }
    if (name === "Work") {
      this.handleTab("week");
    }
    await this.$router.push({ name });
  }

  gridRender() {
    this.grid.forEach((item: IDay, index) => {
      const events = this.eventsArray.filter(
        (event) => dateFormat(new Date(event.started_at)) === item.date
      );
      Vue.set(this.gridEvent, index, { ...item, events });
    });
  }

  async getCalendarEvents() {
    await CalendarModule2.getCalendarEvents();
  }

  setParams() {
    if (this.params) CalendarModule2.setEventParams(this.params);
  }

  get selectedUsers() {
    return CalendarModule2.selectedUsers;
  }

  get selectedLocations() {
    return CalendarModule2.selectedLocations;
  }

  get selectedGroups() {
    return CalendarModule2.selectedGroups;
  }

  get selectedTypes() {
    return CalendarModule2.selectedTypes;
  }

  get personParams(): IGetEventParams {
    const userIdInGroup = this.selectedGroups.map((item) => item.users).flat();
    const selectedUserId = this.selectedUsers.map((item) => item.id);
    const users = Array.from(new Set([...userIdInGroup, ...selectedUserId]));
    return {
      date_from: this.date_from,
      date_to: this.date_to,
      timezone: this.timezone,
      is_accepted_invitation: this.noAcceptEvent ? [true, null, false] : [true, null],
      users,
      types: this.selectedTypes.map((item) => item.id)
    };
  }

  get workParams(): IGetEventParams {
    return {
      date_from: this.date_from,
      date_to: this.date_to,
      timezone: this.timezone,
      locations: this.selectedLocations.map((item) => item.id),
      types: this.selectedTypes.map((item) => item.id)
    };
  }

  get eventParams(): IGetEventParams {
    return {
      category_id: this.activityCategories ? this.activityCategories.id : null,
      date_from: this.date_from,
      date_to: this.date_to,
      timezone: this.timezone,
      types: this.selectedTypes.map((item) => item.id)
    };
  }

  get params(): IGetEventParams | null {
    return this.isPersonCalendar
      ? this.personParams
      : this.isWorkingCalendar
      ? this.workParams
      : this.eventParams;
  }

  get eventsParams() {
    return CalendarModule2.eventsParams;
  }

  get paramsWatch(): string {
    return JSON.stringify(this.params);
  }

  handleTab(type): void {
    CalendarModule2.setType(type);
  }

  // async getCookieValue() {
  //   this.localStorage = window.localStorage;
  //   const setting = JSON.parse(this.localStorage.getItem("calendar-filters"));
  //   if (setting) {
  //     if (this.isPersonCalendar) {
  //       const params = setting.person;
  //       if (params?.groups) delete params.groups;
  //       this.setParams(params);
  //     } else if (this.isWorkingCalendar) {
  //       const params = setting.working;
  //       this.setParams(params);
  //     } else if (this.isEventsCalendar) {
  //       this.$nextTick(() => this.setParams(setting.events));
  //     }
  //   }
  //   await CalendarModule2.getFilterUsers(setting && setting.saveUsers ? setting.saveUsers : []);
  // }

  setCookieValue() {
    this.localStorage = window.localStorage;
    const setting = JSON.parse(this.localStorage.getItem("calendar-filters"));
    const option = {
      ...setting,
      events: {
        ...(setting ? setting.events : {}),
        category_id: this.activityCategories ? this.activityCategories.id : null
      }
    };
    window.localStorage.setItem("calendar-filters", JSON.stringify(option));
  }

  //methods
  hideGroupModal() {
    CalendarModule2.toggleShowGroupModal({ show: false, mode: "show" });
  }

  hideLocationModal() {
    CalendarModule2.toggleShowLocationModal({ show: false, mode: "show" });
  }

  hideUsersModal() {
    CalendarModule2.toggleShowUsersModal(false);
  }

  hideCreateModal() {
    CalendarModule2.toggleCreateModal(false);
  }

  get eventsArray() {
    return this.isPersonCalendar
      ? this.events
      : this.isWorkingCalendar
      ? this.workEvents
      : this.activityEvents;
  }

  get eventsArrayWatcher() {
    return JSON.stringify(this.eventsArray);
  }

  get events() {
    return CalendarModule2.events;
  }

  get workEvents() {
    return CalendarModule2.workingEvents;
  }

  get activityEvents() {
    return CalendarModule2.activityEvents;
  }

  get weeks(): ICalendarGenerate {
    return calendarGenerate({
      currentDate: moment(this.date).format("YYYY-MM-DD"),
      type: this.type as gridType,
      isWeek: false
    });
  }

  get date_from() {
    return this.weeks.start;
  }

  get date_to() {
    return this.weeks.stop;
  }

  get grid(): Array<IDay> {
    return this.weeks.days as Array<IDay>;
  }

  get isMonth() {
    return this.type === "month";
  }

  get isShowEventList() {
    return CalendarModule2.eventListSetting ? CalendarModule2.eventListSetting.isShow : false;
  }

  get timezone() {
    return CalendarModule2.timezone;
  }

  get isShowEmployment() {
    return CalendarModule2.isShowEmployment;
  }

  get date() {
    return CalendarModule2.date;
  }

  get type() {
    return CalendarModule2.type as gridType;
  }

  get calendarComponent() {
    return this.isMonth ? CalendarMonth : CalendarWeek;
  }

  get showGroupModal() {
    return CalendarModule2.showGroupModal;
  }

  get showLocationModal() {
    return CalendarModule2.showLocationModal;
  }

  get showUsersModal() {
    return CalendarModule2.showUsersModal;
  }

  get isShowCreateEvent() {
    return CalendarModule2.isShowCreateEvent;
  }

  get isShowTooltip() {
    return CalendarModule2.isShowTooltip;
  }

  get isShowViewEvent() {
    return CalendarModule2.isShowViewEvent;
  }

  get isShowFullScreenEvent() {
    return CalendarModule2.isShowFullScreenEvent;
  }

  get getUserId() {
    return this.$store.getters.getUserId;
  }

  get locations() {
    return CalendarModule2.locations;
  }

  get eventsTypes() {
    return CalendarModule2.eventType.filter(
      (item) => item.category_id === this.activityCategories.id
    );
  }

  get types() {
    return CalendarModule2.eventType;
  }

  get noAcceptEvent() {
    return CalendarModule2.noAcceptEvent;
  }

  get activeTab() {
    return this.$store.getters["MainLayoutSetting/activeTab"];
  }

  get isPersonCalendar() {
    return CalendarModule2.isPersonCalendar;
  }

  get isWorkingCalendar() {
    return CalendarModule2.isWorkingCalendar;
  }

  get isEventsCalendar() {
    return CalendarModule2.isEventsCalendar;
  }

  get activityCategories() {
    return CalendarModule2.eventTypeCategories.length
      ? CalendarModule2.eventTypeCategories.find((item) => item.name === "Активность")
      : null;
  }

  get activityCategoriesTypes() {
    return CalendarModule2.eventType.length && this.activityCategories
      ? CalendarModule2.eventType
          .filter((item) => item.category_id === this.activityCategories.id)
          .map((type) => type.id)
      : [];
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }
}
