<template>
  <div
    class="select-bg"
    :class="{ 'select-bg_filter': isFilter, disabled: disabled }"
    v-click-outside="hideModalOptions"
  >
    <div
      class="select-bg__input"
      @click="openCloseModalOptions"
      :class="{
        'select-bg__input_filter-active': showModalOptions && isFilter,
        'select-bg__input_fill': value.length
      }"
      :style="{
        border: showModalOptions && !isFilter ? '2px solid #6A67CE' : ''
      }"
    >
      <div class="select-bg__wrap">
        <i :class="`icon-custom icon-${icon}`"></i>
        <div class="select-bg__placeholder" v-if="!value.length">
          {{ placeholder }}
        </div>
        <div class="select-bg__selected-list" v-if="!isFilter">
          <div
            class="select-bg__selected-item"
            v-for="item in value"
            :key="item.id"
            :style="!showModalOptions ? 'border: 2px solid #6A67CE;' : ''"
          >
            <img v-if="item.avatar" :src="returnSrcFile(item.avatar)" alt="avatar" />
            <span>{{ item["full_name"] || item["name"] }}</span>
            <i class="icon-cross" v-if="removeCross" @click.stop="removeItem(item)"></i>
          </div>
        </div>
        <div class="select-bg__selected-list" v-if="isFilter && value.length">
          <div
            class="select-bg__selected-item"
            :style="!showModalOptions ? 'background: #f2f7fe;' : ''"
          >
            {{ value[0]["name"] || value[0]["full_name"] }}
            <i class="icon-cross" @click.stop="removeItem(value[0])"></i>
          </div>
          <div class="select-bg__selected-count" v-if="value.length">{{ value.length }}</div>
        </div>
      </div>
      <i class="icon-down-secondary"></i>
    </div>
    <div class="select-bg__modal select-modal" v-if="showModalOptions">
      <div class="select-modal__header" v-if="showSearchPanel">
        <div class="select-modal__label">Поиск и добавление</div>
        <div class="select-modal__input">
          <input
            type="text"
            placeholder="Поиск"
            v-model.trim="searchValue"
            id="searchInput"
            autocomplete="off"
          />
          <i class="icon-search"></i>
        </div>
      </div>
      <div class="select-modal__body">
        <ItemSelect
          v-for="item in items"
          :key="item.id"
          :item="item"
          :value="value"
          :showAvatar="showAvatar"
          :searchValue="searchValue"
          :isLastItem="items[items.length - 1] === item"
          @selectItem="selectItem"
          @reachLastItem="reachLastItem"
        />
        <Spinner v-if="isLoading" :small="true" class="select-modal__loader" />
      </div>
      <div class="select-bg__panel" v-if="multiple && showButtonPanel">
        <button class="select-bg__button" type="button" @click="deleteAll">Очистить</button>
        <button
          class="select-bg__button select-bg__button_all"
          v-if="showAll"
          type="button"
          @click="selectAll"
        >
          Выбрать всех
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSelect from "@/modules/UiKit/components/Select/ItemSelect";
import ClickOutside from "vue-click-outside";
import { domainStorage } from "@/globalVariables";
import NotificationObserver from "@/helpers/UserNotification";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "Select",
  mixins: [],
  model: {
    event: "input"
  },
  props: {
    multiple: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    placeholder: {
      type: String || Number,
      default: () => {
        return "";
      }
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    maxCount: {
      type: Number
    },
    icon: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: true
    },
    showButtonPanel: {
      type: Boolean,
      default: true
    },
    showSearchPanel: {
      type: Boolean,
      default: true
    },
    removeCross: {
      type: Boolean,
      default: true
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { Spinner, ItemSelect },
  data() {
    return {
      showModalOptions: false,
      searchValue: ""
    };
  },
  mounted() {
    if (this.isFilter) {
      this.showModalOptions = false;
    }
  },
  methods: {
    reachLastItem() {
      this.$emit("reachLastItem");
    },
    returnSrcFile(src) {
      return domainStorage + src;
    },
    openModalOptions(val) {
      this.showModalOptions = val;
    },
    openCloseModalOptions() {
      this.showModalOptions = !this.showModalOptions;
      this.$nextTick(() => {
        document.getElementById("searchInput").focus();
      });
    },
    selectItem(val) {
      if (!this.value.some((item) => item.id === val.id)) {
        if (this.multiple) {
          if (this.maxCount) {
            if (this.value.length < this.maxCount) {
              let oldValue = JSON.parse(JSON.stringify(this.value));
              oldValue.push(val);
              this.$emit("input", oldValue);
            } else {
              NotificationObserver.notification({
                type: "error",
                message: `Максимальное кол-во ${this.maxCount} !`
              });
            }
          } else {
            let oldValue = JSON.parse(JSON.stringify(this.value));
            oldValue.push(val);
            this.$emit("input", oldValue);
          }
        } else {
          let oldValue = JSON.parse(JSON.stringify(this.value));
          oldValue = [val];
          this.$emit("input", oldValue);
          this.hideModalOptions();
        }
      } else {
        this.removeItem(val);
      }
      if (this.searchValue.length) {
        this.searchValue = "";
        document.getElementById("searchInput").focus();
      }
    },
    selectAll() {
      this.$emit("input", this.items);
    },
    deleteAll() {
      this.$emit("input", []);
    },
    removeItem(val) {
      this.$emit(
        "input",
        this.value.filter((item) => item.id !== val.id)
      );
    },
    hideModalOptions() {
      this.showModalOptions = false;
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.disabled {
  pointer-events: none;
}
.select-bg {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input {
    position: relative;
    display: flex;
    //align-items: center;
    overflow: hidden;
    width: 100%;
    min-height: 40px;
    max-height: 400px;
    background: $bg-surface-light;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    border: 2px solid $surface-default;
    transition: 0.3s;
    padding: 4px 0 0;

    &_fill {
      position: relative;
      border: 2px solid #eae8f0;
      background: $surface-default;
    }
  }
  &__wrap {
    width: calc(100% - 12px);
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 4px;
  }
  .icon-down-secondary {
    margin-left: auto;
    //padding-right: 16px;
    flex-shrink: 0;
    color: $icon-subdued;
    font-size: 8px;
    position: absolute;
    top: 45%;
    right: 16px;
  }

  &__placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $text-disabled;
    padding: 6px 0 8px 16px;
  }

  &__selected-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 4px;
    max-width: 90%;
  }

  &__selected-item {
    display: flex;
    align-items: center;
    padding: 4px;
    background: $surface-default;
    border: 2px solid $border-default;
    border-radius: 4px;
    margin: 0 4px 4px 0;
    max-width: 100%;
    transition: 0.3s;

    span {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 8px;
    }
    i {
      margin-left: 8px;
      color: $icon-subdued;
      font-size: 20px;

      &:hover {
        color: $action-primary;
      }
    }
  }

  .select-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 290px;
    margin-top: 16px;
    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: $text-primary;
      width: 100%;
      margin-bottom: 16px;
    }

    &__input {
      position: relative;
      width: 100%;

      i {
        position: absolute;
        top: 2px;
        left: 0;
        color: $icon-subdued;
      }

      input {
        outline: none;
        border: none;
        width: 100%;
        padding: 0 10px 5px 20px;
        border-bottom: 1.5px solid #b2b2b6;
        transition: 0.3s all ease;
        &:focus {
          border-bottom: 1.5px solid $action-primary;
        }

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-disabled;
        }
      }
    }

    &__body {
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 8px 0;
    }
    &__loader {
      margin: auto;
    }
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 0;
  }

  &__button {
    border: none;
    background: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-primary;
    outline: none;

    &_all {
      color: $text-highlight;
    }
  }

  &_filter {
    position: relative;

    .select-bg__input {
      height: 40px;
      background: $surface-default;
      border-radius: 4px;
      border: 2px solid $surface-default;
      transition: 0.3s;
      padding: 0;

      &_filter-active {
        background: #f2f7fe;
      }
    }

    .select-bg__placeholder {
      padding: 0 8px 0;
    }

    .icon-custom {
      color: $icon-subdued;
      padding: 0 0 0 8px;
    }

    .select-bg__selected-count {
      padding: 0;
    }

    .icon-down-secondary {
      display: none;
    }

    .select-bg__modal {
      position: absolute;
      z-index: 15;
      top: 45px;
      left: 0;
      padding: 16px;
      margin: 0;
      list-style: none;
      width: 100%;
      min-width: 300px;
      background-color: $surface-default;
      cursor: pointer;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 4px;
    }

    .select-bg__selected-item {
      border: none;
      padding: 4px;
      max-width: 90%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
