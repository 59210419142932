<template>
  <div class="base-plan__modal modal">
    <div class="modal__wrap">
      <ul class="modal__list">
        <BasePlanItem
          v-for="basePlanItem in basePlanList"
          :key="basePlanItem.id"
          :basePlanItem="basePlanItem"
          :selected="currentBasePlanId === basePlanItem.id"
          :planId="planId"
          :isAuthor="isAuthor"
          @handleCheckbox="handleCheckbox"
        />
      </ul>
      <p v-if="!basePlanList.length && !isAuthor">Список пуст</p>
    </div>
    <button v-if="isAuthor" class="modal__create-base-plan" @click="createBasePlan">
      Создать базовый план
    </button>
  </div>
</template>

<script>
import BasePlanItem from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/BasePlan/ModalChooseBasePlan/BasePlanItem";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
export default {
  name: "ModalChooseBasePlan",
  mixins: [],
  props: {
    planId: {
      type: Number,
      default: null
    },
    basePlanList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    basePlanToShow: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isAuthor: {
      type: Boolean,
      default: true
    }
  },
  components: { BasePlanItem },
  data() {
    return {
      currentBasePlanId: null
    };
  },
  mounted() {
    this.currentBasePlanId = this.basePlanToShow?.id;
  },
  methods: {
    createBasePlan() {
      this.$router.push({ name: "Plan", query: { id: this.planId, mode: "base-create" } });
    },
    handleCheckbox(val) {
      if (val.isSelected) {
        this.currentBasePlanId = val.basePlanItem.id;
        PlanningScheduleModule.showBasePlanAction({
          planId: this.planId,
          basePlanItem: val.basePlanItem
        });
      } else {
        PlanningScheduleModule.showBasePlanAction(null);
      }
      this.$emit("handleBasePlan");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal {
  position: absolute;
  top: 24px;
  right: 0;
  background: #ffffff;
  z-index: 1;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 4px;
  min-width: 200px;
  max-width: 330px;
  max-height: 195px;
  overflow: hidden;
  padding: 8px 0;
  &__wrap {
    max-height: 160px;
    overflow: scroll;
    p {
      text-align: center;
      color: $text-default;
      padding: 8px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__create-base-plan {
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $link-default;
    background: $surface-selected-default;
  }
}
</style>
