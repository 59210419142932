






























import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/modules/UiKit/components/buttons/Button.vue";

@Component({
  components: { Button }
})
export default class MainHeader extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  isAdmin!: boolean;
  @Prop({
    default: 0,
    type: Number
  })
  tab!: number;

  handleButton() {
    this.$emit("handleButton");
  }

  handleWorkTime() {
    this.$emit("timeNav", 0);
  }

  handleProject() {
    this.$emit("timeNav", 1);
  }

  handleButtonMember() {
    this.$emit("handleButtonMember", true);
  }

  get isProject() {
    return this.tab === 1;
  }
}
