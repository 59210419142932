




























import { Component, Prop, Vue } from "vue-property-decorator";
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP.vue";
@Component({
  components: { CheckboxISP }
})
export default class NotificationSettingCellContent extends Vue {
  @Prop({ default: () => {}, type: Object }) row!: any;
  @Prop({ default: () => {}, type: Object }) cell!: any;
  @Prop({ type: Boolean, default: false }) isEdit!: boolean;
  isSwitchedOn: boolean = this.field;
  enableNotifications() {
    this.isSwitchedOn = !this.field;
    const newObj = { ...this.row, [this.cell.field]: this.isSwitchedOn };
    this.$store.commit("Notification/updateSetting", newObj);

    const body = {
      service: this.row.alias.split(".")[0],
      channel: this.cell.field,
      switch: this.isSwitchedOn,
      notify: this.row.alias
    };
    this.$store.dispatch("Notification/saveUserSettingsAction", body);
  }

  get isShowCheckbox() {
    return this.field === true || this.field === false;
  }
  get isEditCheckbox() {
    return this.isEdit;
  }
  get field() {
    return this.row[this.cell.field];
  }
}
