import { format } from "date-fns";
import moment from "moment";

const getters = {
  getDataFromFilter(state) {
    if (state.activeFilter === "Сегодня") {
      return format(new Date(), "yyyy-MM-dd");
    }
    if (state.activeFilter === "Вчера") {
      return moment().subtract(1, "days").format("YYYY-MM-DD");
    }
    return "";
  }
};
export default getters;
