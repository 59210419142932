<template>
  <div class="date-input" :class="{ disabled: disabled }" v-click-outside="hideModal">
    <div
      class="date-input__wrap"
      @click="showModal"
      :style="{ borderColor: showCalendar ? '#6A67CE' : '#f2f2f7' }"
    >
      {{ dateInput }}
    </div>
    <v-date-picker
      v-if="showCalendar"
      v-model="dateData"
      :min-date="minDate"
      :max-date="maxDate"
      :masks="masks"
      :attributes="attributes"
    >
      <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
        <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
      </div>
      <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";

import ClickOutside from "vue-click-outside";

export default {
  name: "DateInput",
  mixins: [],
  props: {
    date: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      dateData: null,
      masks: {
        weekdays: "WWW"
      },
      mask: {
        mask: "99/99/9999",
        placeholder: "",
        showMaskOnHover: false
      },
      attributes: [],
      showCalendar: false,
      dateInput: "",
      firstEmit: true
    };
  },
  mounted() {
    if (this.date) {
      this.dateData = new Date(this.date);
      this.dateInput = this.dateData ? moment(this.dateData).format("DD/MM/YYYY") : "";
    }
  },
  methods: {
    showModal() {
      this.showCalendar = true;
    },
    hideModal() {
      this.showCalendar = false;
    },
    setDate() {
      this.$emit("input", this.dateData);
      this.dateInput = this.dateData ? moment(this.dateData).format("DD/MM/YYYY") : "";
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    }
  },
  computed: {},
  watch: {
    dateData() {
      if (!this.firstEmit || !this.date) {
        this.setDate();
      } else {
        this.firstEmit = false;
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.date-input {
  position: relative;
  width: fit-content;
  &__wrap {
    width: 125px;
    height: 40px;
    padding: 6px 16px;
    background: #ffffff;
    border: 2px solid #f2f2f7;
    box-sizing: border-box;
    border-radius: 2px;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #343748;
    cursor: pointer;
    &:hover {
      border-color: $action-primary !important;
    }
  }

  /deep/ {
    .vc-container {
      margin-top: 8px;
      border: 1px solid #eae8f0;
      border-radius: 8px;
      padding: 8px 2px;
      max-width: 400px;
      position: absolute;
      right: 0;
      z-index: 2;
    }

    .vc-arrows-container {
      padding: 0;
      margin: 0 0 24px;
    }

    .vc-arrow {
      height: 21px;
    }

    .vc-svg-icon {
      width: 21px;
      height: 21px;

      path {
        fill: $icon-subdued;
      }
    }

    .vc-header {
      padding: 0;
      margin: 0 0 24px;
    }

    .month {
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .vc-weekday {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $text-disabled;
    }

    .weekday-1 .vc-day-content,
    .weekday-7 .vc-day-content {
      color: $text-disabled;
    }

    .vc-day-content {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #000000;

      &:hover,
      &:focus {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
        color: #ffffff !important;
      }
    }

    .vc-highlight.vc-highlight-base-middle {
      width: 40px;
      height: 40px;
      background: #fafafe !important;
    }

    .vc-highlight,
    .vc-highlight.vc-highlight-base-start,
    .vc-highlight.vc-highlight-base-end {
      width: 40px;
      height: 40px;
      background: #5551f9 !important;
      border-radius: 4px !important;
    }

    .vc-day.is-not-in-month .vc-day-content {
      opacity: 1;
      color: #747474;
    }
    .vc-day.is-not-in-month *[data-v-4420d078] {
      opacity: 1;
    }
  }
}
.disabled {
  pointer-events: none;
  .date-input__wrap {
    opacity: 0.5;
  }
}
</style>
