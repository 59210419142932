<template>
  <button
    :class="[
      setLoaderClass,
      setClass,
      'aop-button',
      { 'aop-button_small': small },
      { 'aop-button_large': large },
      { 'aop-button_icon': !!icon },
      { 'aop-button_icon-right': iconRight }
    ]"
    :type="eventType"
    :disabled="type === 'disabled' || type === 'main-disabled' || loader"
    @click.stop="handleButton"
  >
    <i :class="`icon-${icon}`" v-if="icon && !loader && !iconRight"></i>
    <span v-if="title.length && !loader">{{ title }}</span>
    <span class="count" v-if="count && !loader">{{ count }}</span>
    <i :class="`icon-${icon}`" v-if="icon && !loader && iconRight"></i>
    <Spinner v-if="loader" :small="true" />
  </button>
</template>

<script>
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "Button",
  mixins: [],
  props: {
    title: {
      type: String,
      default: "Кнопка"
    },
    count: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "default"
    },
    eventType: {
      type: String,
      default: "button"
    },
    loader: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    }
  },
  components: { Spinner },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleButton() {
      this.$emit("handleButton");
    }
  },
  computed: {
    setClass() {
      switch (this.type) {
        case "disabled": {
          return "aop-button_disabled";
        }
        case "cancel": {
          return "aop-button_cancel";
        }
        case "white": {
          return "aop-button_white";
        }
        case "simple": {
          return "aop-button_simple";
        }
        case "round": {
          return "aop-button_round";
        }
        case "main": {
          return "aop-button_main";
        }
        case "main-disabled": {
          return "aop-button_main-disabled";
        }
        case "main-cancel": {
          return "aop-button_main-cancel";
        }
        default: {
          return "";
        }
      }
    },
    setLoaderClass() {
      return this.loader ? "aop-button_loader" : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.aop-button {
  padding: 14px 30px;
  height: 42px;
  background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
  border: 2px solid #ffffff;
  border-radius: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;

  &_icon {
    padding: 12px 19px;
  }

  &:hover {
    background: linear-gradient(336.67deg, #443cea -13.54%, #5e60ea 70.48%, #686ed1 120.89%);
    box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
      0 4px 5px rgba(48, 79, 254, 0.2);
  }

  &:active {
    background: linear-gradient(336.67deg, #6660ff -13.54%, #9d9efe 70.48%, #b3b7ff 120.89%);
    box-shadow: 0 8px 10px rgba(122, 124, 255, 0.14), 0 3px 14px rgba(122, 124, 255, 0.12),
      0 4px 5px rgba(122, 124, 255, 0.2);
  }

  i {
    margin-right: 6px;
    font-size: 24px;
  }

  span {
    font-family: "Roboto", sans-serif !important;
  }

  &_large {
    padding: 20px 30px;
    height: 56px;
  }

  &_small {
    padding: 10px 30px;
    height: 36px;
  }

  &_loader {
    /deep/ {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }

  &_disabled {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
      #c3c7d2;
    cursor: default;

    &:hover {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
        #c3c7d2;
      box-shadow: none;
    }
  }

  &_white {
    background: #ffffff;
    border: 2px solid #eae8f0;
    color: #5551f9;

    &:hover {
      background: #ffffff;
      border: 2px solid #5551f9;
    }

    &:active {
      background: #ffffff;
      border: 2px solid #5551f9;
    }

    /deep/ {
      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }

  &_cancel {
    color: #343748;
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      border: none;
      box-shadow: none;
      filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
        drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
        drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
    }

    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      filter: drop-shadow(0px 8px 10px rgba(122, 124, 255, 0.14))
        drop-shadow(0px 3px 14px rgba(122, 124, 255, 0.12))
        drop-shadow(0px 4px 5px rgba(122, 124, 255, 0.2));
    }
  }

  &_simple {
    color: #5551f9;
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      border: none;
      box-shadow: none;
      filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
        drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
        drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
    }

    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      filter: drop-shadow(0px 8px 10px rgba(122, 124, 255, 0.14))
        drop-shadow(0px 3px 14px rgba(122, 124, 255, 0.12))
        drop-shadow(0px 4px 5px rgba(122, 124, 255, 0.2));
    }
  }

  &_round {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    justify-content: center;

    span {
      display: none;
    }

    i {
      margin: 0;
    }

    &:hover {
      background: linear-gradient(336.67deg, #443cea -13.54%, #5e60ea 70.48%, #686ed1 120.89%);
      box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
        0 4px 5px rgba(48, 79, 254, 0.2);
    }

    &:active {
      background: linear-gradient(336.67deg, #6660ff -13.54%, #9d9efe 70.48%, #b3b7ff 120.89%);
      box-shadow: 0 8px 10px rgba(122, 124, 255, 0.14), 0 3px 14px rgba(122, 124, 255, 0.12),
        0 4px 5px rgba(122, 124, 255, 0.2);
    }
  }

  &_main {
    background: #5551f9;
    border-radius: 8px;
    border: none;

    &:hover {
      background: #2c40b1;
      box-shadow: none;
    }

    &:active {
      background: #706aff;
      box-shadow: none;
    }

    i {
      font-size: 16px;
    }
  }

  &_main-disabled {
    background: #d3d2e0;
    border-radius: 8px;
    border: none;

    &:hover {
      background: #d3d2e0;
      box-shadow: none;
    }

    &:active {
      background: #d3d2e0;
      box-shadow: none;
    }

    i {
      font-size: 16px;
    }
  }

  &_main-cancel {
    background: #f2f7fe;
    border-radius: 8px;
    border: none;
    color: #0a1417;

    &:hover {
      background: $surface-hovered;
      box-shadow: none;
    }

    &:active {
      background: $surface-pressed;
      box-shadow: none;
    }

    i {
      font-size: 16px;
    }
  }

  &_icon-right {
    i {
      margin: 0 0 0 8px;
    }
  }
}
</style>
