<template>
  <div class="modal__header">
    <i class="icon-sort-arrow" @click="$emit('hideModal')"></i>
    <h2 class="modal__title">{{ title }}</h2>
    <i class="icon-edit" v-if="showEdit" @click="$emit('edit')"></i>
    <i class="icon-cross" @click="$emit('hideModal')"></i>
  </div>
</template>

<script>
export default {
  name: "ModalHeader",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.modal {
  &__header {
    width: 100%;
    padding: 40px 32px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $md) {
      padding: 32px 24px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 850px) and (max-height: 450px) {
      padding: 24px 16px 16px;
    }

    @media (max-width: $sm) {
      padding: 24px 16px 16px;
    }
    i {
      color: $icon-subdued;
      font-size: 22px;
      cursor: pointer;

      @media (max-width: $sm) {
        font-size: 18px;
      }

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-edit {
      margin: 0 8px 0 auto;
      font-size: 18px;
    }

    .icon-cross {
      @media (max-width: $md) {
        display: none;
      }
    }

    .icon-sort-arrow {
      display: none;

      @media (max-width: $md) {
        display: block;
        margin-right: 16px;
        transform: rotate(90deg);
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    color: #000000;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: $sm) {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $text-default;
    }
  }
}
</style>
