

















import { Component, Mixins, Prop } from "vue-property-decorator";
import draggableModals from "@/aopV2/modules/Calendar/helper/draggableModals";
import { transform } from "lodash";

@Component
export default class MainModal extends Mixins(draggableModals) {
  @Prop({
    default: false,
    type: Boolean
  })
  isDragModal!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isCenter!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  showFooter!: boolean;
  @Prop({
    default: 0,
    type: Number
  })
  top!: number;
  @Prop({
    default: 0,
    type: Number
  })
  left!: number;

  mounted() {
    this.$nextTick(() => {
      if (this.isDragModal) {
        this.modal = this.$el;
        this.drag = this.modal.querySelector("#modal-drag");
        if (this.drag) this.dragHandler();
      }
    });
  }

  get style() {
    return this.isCenter
      ? {
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          margin: "auto"
        }
      : {
          left: this.left ? this.left + "px" : "50%",
          top: this.top ? this.top + "px" : "50%"
        };
  }

  handleEsc(e) {
    this.$emit("handleEsc", e);
  }

  hideModal(e) {
    this.$emit("hideModal", e);
  }
}
