const getters = {
  getChatFromQuery: (state) => (id) => {
    return state.chatList.find((item) => item.channel.id === +id);
  },
  getOldMessage: (state) => {
    return state.messages[0];
  },
  searchSelectUser:
    (state) =>
    (reg = "@", selectGroup = "userList") => {
      return state[selectGroup].filter((item) => new RegExp(reg, "g").test(item.nickname));
    },
  searchUser:
    (state) =>
    (reg = "@", selectGroup = "userList", type) => {
      return state[selectGroup].filter((item) =>
        new RegExp(reg, "g").test(item.info[type].toLowerCase())
      );
    },
  searchChats:
    (state) =>
    (reg = "@", selectGroup = "chatList", type, typeChat) => {
      return state[selectGroup].filter((item) => {
        if (item.channel.type === 1) {
          return new RegExp(reg, "g").test(item.channel?.companion[type].toLowerCase());
        } else if (item.channel.type === 2) {
          return new RegExp(reg, "g").test(item.channel.chat_settings[typeChat].toLowerCase());
        } else {
          return new RegExp(reg, "g").test("избранное");
        }
      });
    }
};
export default getters;
