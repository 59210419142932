<template>
  <div class="child-project" :class="{ disabled: isLocked }" @click="goToProject">
    <p class="child-project__title">
      <i v-if="isLocked" class="icon-lock-secondary"></i>{{ project.name }}
    </p>
    <div class="child-project__stages">
      <i class="icon-list"></i>
      {{ project.count_stage }}
    </div>
    <div class="child-project__users">
      <i class="icon-group"></i>
      {{ project.count_member }}
    </div>
  </div>
</template>

<script>
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";

export default {
  name: "NestedProject",
  mixins: [],
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToProject() {
      ProjectModule.closeCurtainShowAndEditProject();
      this.$router.push({
        name: "Project",
        params: { id: this.project.id }
      });
    }
  },
  computed: {
    isLocked() {
      return this.project.is_owner === null ? true : false;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.child-project {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
  &__title {
    width: 80%;
    word-break: break-word;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;

    @media (max-width: $sm) {
      width: 70%;
    }
  }

  &__stages,
  &__users {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 6px;
    }
  }
}
.disabled {
  cursor: default;
  pointer-events: none;
  color: $icon-subdued;

  i,
  p {
    color: $icon-subdued;
  }
  i {
    margin-right: 6px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
</style>
