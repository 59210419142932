<template>
  <div class="chat-catalog">
    <div class="chat-catalog__header">
      <div class="chat-catalog__wrap">
        <i class="icon-arrow-short" @click="changeShowCatalog(false)"></i>
        <TabsCatalog @changeModeTabs="changeModeTabs" :tabsMode="tabsMode" />
      </div>
      <div class="chat-catalog__filters filters-catalog">
        <div class="filters-catalog__search">
          <i
            @click="handleOpenSearch"
            :class="['icon-search', openSearch ? 'icon-search_open' : '']"
          ></i>
          <input
            @input="getSearchValue"
            v-model.trim="searchValue"
            :class="['input-search', openSearch ? 'input-search_open' : '']"
            type="text"
          />
          <i
            @click="handleHideSearch"
            :class="['icon-cross', openSearch ? 'icon-cross_open' : '']"
          ></i>
        </div>
        <SortTable
          :icon="'channel'"
          :valueSort="valueSort"
          :listSorts="listSorts"
          :altIcon="false"
          :unique="'CalendarHeader'"
          @choseSort="choseSort"
          v-if="!isMemberMode"
        />
      </div>
    </div>
    <div class="chat-catalog__main">
      <div class="chat-catalog__container-member" v-show="!isMemberMode" ref="containerChannel">
        <ChannelItemCatalog
          v-for="item in catalogChannels.items"
          :channel="item"
          :key="item.id"
          @changeChannelSubscribe="changeChannelSubscribe"
        />
        <div class="chat-catalog__empty" v-if="!catalogChannels.items.length && !catalogLoader">
          Ничего не найдено :(
        </div>
      </div>
      <div class="chat-catalog__container-member" v-show="isMemberMode" ref="containerMember">
        <MemberItemCatalog v-for="item in catalogMembers" :member="item" :key="item.id" />
        <div class="chat-catalog__empty" v-if="!catalogMembers.length && !catalogLoader">
          Ничего не найдено :(
        </div>
      </div>
      <Widget type="loader-new" v-if="catalogLoader" />
    </div>
  </div>
</template>

<script>
import TabsCatalog from "@/modules/Messenger/components/Chat/Catalog/TabsCatalog";
import SortTable from "@/components/Widgets/SortTable/SortTableMobile";
import { mapActions, mapState } from "vuex";
import MemberItemCatalog from "@/modules/Messenger/components/Chat/Catalog/MemberItemCatalog";
import ChannelItemCatalog from "@/modules/Messenger/components/Chat/Catalog/ChannelItemCatalog";
import Widget from "@/components/Widgets/Widget";
export default {
  name: "ChatCatalog",
  mixins: [],
  props: {},
  components: { Widget, ChannelItemCatalog, MemberItemCatalog, SortTable, TabsCatalog },
  data() {
    return {
      tabsMode: "channels",
      openSearch: false,
      searchValue: "",
      timeoutId: 0,
      timeoutChannelId: 0,
      pageValue: 1,
      valueSort: {
        name: "Все каналы",
        value: "all"
      },
      listSorts: [
        {
          name: "Все каналы",
          value: "all"
        },
        {
          name: "Модерируемые",
          value: "moder"
        },
        {
          name: "Мои",
          value: "my"
        }
      ]
    };
  },
  beforeDestroy() {
    this.$refs.containerChannel.removeEventListener("scroll", this.handleScroll, false);
  },
  mounted() {
    this.$refs.containerChannel.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    ...mapActions("MessengerModule", [
      "changeShowCatalog",
      "findCatalogChannels",
      "findCatalogChannelsModerate",
      "findCatalogGeneralChannels",
      "findCatalogMembers",
      "forMountCatalogMembers",
      "forSearchCatalogMembers",
      "clearCatalogChannel"
    ]),
    handleScroll() {
      if (
        this.$refs.containerChannel &&
        this.$refs.containerChannel.scrollHeight - this.$refs.containerChannel.scrollTop <=
          this.$refs.containerChannel.offsetHeight + 50
      ) {
        if (this.pageValue < Math.ceil(this.catalogChannels.count / 15)) {
          clearTimeout(this.timeoutChannelId);
          this.timeoutChannelId = setTimeout(() => {
            this.pageValue++;
            this.getChannelsFilterInfo();
          }, 300);
        }
      }
    },
    changeModeTabs(val) {
      this.searchValue = "";
      this.tabsMode = val;
      this.pageValue = 1;
      this.clearCatalogChannel();
      if (this.isMemberMode) {
        this.forMountCatalogMembers();
      } else {
        this.getChannelsFilterInfo();
      }
    },
    choseSort(val) {
      this.valueSort = val;
      this.pageValue = 1;
      this.clearCatalogChannel();
      this.getChannelsFilterInfo();
    },
    // fetchCatalogChannels(filterValue) {
    //   this.findCatalogGeneralChannels({
    //     page: this.pageValue,
    //     where: JSON.stringify(filterValue),
    //     search: this.searchValue
    //       ? {
    //           "chatSettings.name": this.searchValue,
    //           "chatSettings.tag": this.searchValue
    //         }
    //       : undefined
    //   });
    //   else {
    //     this.findCatalogChannels({
    //       page: this.pageValue,
    //       where: JSON.stringify(filterValue),
    //       search: this.searchValue
    //         ? {
    //             "chatSettings.name": this.searchValue,
    //             "chatSettings.tag": this.searchValue
    //           }
    //         : undefined
    //     });
    //   }
    // },
    getChannelsFilterInfo() {
      switch (this.valueSort.value) {
        case "all":
          this.findCatalogGeneralChannels({
            page: this.pageValue,
            where: JSON.stringify([{ type: 2 }]),
            search: this.searchValue
              ? {
                  "chatSettings.name": this.searchValue,
                  "chatSettings.tag": this.searchValue
                }
              : undefined
          });
          break;
        case "moder":
          this.findCatalogChannelsModerate({
            page: this.pageValue,
            search: this.searchValue
              ? {
                  "chatSettings.name": this.searchValue,
                  "chatSettings.tag": this.searchValue
                }
              : undefined
          });
          break;
        case "my":
          this.findCatalogChannels({
            page: this.pageValue,
            where: JSON.stringify([{ type: 2, users: { user_id: this.getUserId } }]),
            search: this.searchValue
              ? {
                  "chatSettings.name": this.searchValue,
                  "chatSettings.tag": this.searchValue
                }
              : undefined
          });
          break;
        default:
          return false;
      }
    },
    getSearchValue() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.pageValue = 1;
        this.clearCatalogChannel();
        if (this.isMemberMode) {
          this.forSearchCatalogMembers(this.searchValue);
        } else {
          this.getChannelsFilterInfo();
        }
      }, 300);
    },
    handleOpenSearch() {
      document.querySelector(".input-search").focus();
      this.openSearch = true;
    },
    handleHideSearch() {
      this.searchValue = "";
      this.openSearch = false;
      this.pageValue = 1;
      this.clearCatalogChannel();
      if (this.isMemberMode) {
        this.forMountCatalogMembers();
      } else {
        this.getChannelsFilterInfo();
      }
    },
    changeChannelSubscribe() {
      this.pageValue = 1;
      this.clearCatalogChannel();
      this.getChannelsFilterInfo();
    }
  },
  computed: {
    ...mapState("MessengerModule", ["catalogChannels", "catalogMembers", "catalogLoader"]),
    isMemberMode() {
      return this.tabsMode === "members";
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-catalog {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  z-index: 100;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #eae8f0;
    flex-shrink: 0;
    @media screen and (max-width: $lg) {
      padding: 0 15px;
    }
    @media screen and (max-width: $md) {
      padding: 0 24px;
    }
    @media screen and (max-width: $xs) {
      padding: 0 10px;
    }
  }
  &__container-member {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    max-width: 1050px;
    height: 100%;
    margin: 0 auto;
    padding: 33px 0;
    @media screen and (max-width: 1485px) {
      max-width: 700px;
    }
    @media screen and (max-width: 1125px) {
      max-width: 350px;
    }
    @media screen and (max-width: $md) {
      padding-bottom: 90px;
      max-width: 700px;
    }
    @media screen and (max-width: 700px) {
      padding-bottom: 90px;
      max-width: 350px;
    }
    @media screen and (max-width: $xxs) {
      max-width: 280px;
    }
    .channel-item {
      margin: 10px;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    i {
      transform: rotate(-90deg);
      margin-right: 20px;
      cursor: pointer;
      color: #d3d2e0;
      @media (hover: hover) {
        &:hover {
          color: unset;
        }
      }
    }
  }
  &__filters {
    display: flex;
    align-items: center;
    .filters-catalog {
      &__add-channel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
        border-radius: 50%;
        outline: none;
        border: none;
        margin-right: 16px;
        i {
          color: white;
        }
      }
      &__search {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 16px;
        @media screen and (max-width: $lg) {
          margin-right: 10px;
        }
        @media screen and (max-width: $md) {
          margin-right: 16px;
        }
        @media screen and (max-width: $xs) {
          margin-right: 0;
        }
        .icon-search {
          color: #a7a9c0;
          cursor: pointer;
          &_open {
            position: absolute;
            left: 5px;
            cursor: default;
            z-index: 5;
          }
        }
        .input-search {
          width: 0;
          height: 30px;
          padding: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
          border: none;
          outline: none;
          transition: 0.3s;
          border-bottom: 1px solid #d3d2e0;
          &:focus {
            border-bottom: 1px solid #6a67ce;
          }
          &_open {
            width: 400px;
            padding: 0 25px;
            @media screen and (max-width: $xxl) {
              width: 300px;
            }
            @media screen and (max-width: $xl) {
              width: 150px;
            }
            @media screen and (max-width: $lg) {
              width: 80px;
            }
            @media screen and (max-width: $md) {
              width: 120px;
            }
            @media screen and (max-width: $sm) {
              width: 120px;
            }
            @media screen and (max-width: $xs) {
              width: 70px;
            }
          }
        }
        .icon-cross {
          display: none;
          cursor: pointer;
          color: #a7a9c0;
          @media (hover: hover) {
            &:hover {
              color: unset;
            }
          }
          &_open {
            display: unset;
            position: absolute;
            right: 5px;
          }
        }
      }
    }
    /deep/ {
      .sort-table {
        width: fit-content;
        &__label {
          margin-right: 0;
          max-width: unset;
          span {
            @media screen and (max-width: $lg) {
              display: none;
            }
            @media screen and (max-width: $md) {
              display: flex;
            }
            @media screen and (max-width: $sm) {
              display: none;
            }
          }
        }
        &__list {
          width: fit-content;
          top: calc(100% + 5px);
          right: 0;
          left: unset;
          border-radius: 8px;
          @media screen and (max-width: $sm) {
            top: auto;
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &__item {
          width: auto;
        }
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 70px);
    @media screen and (max-width: $xxs) {
      padding: 10px 0;
    }
  }
  .loader {
    margin: auto;
    flex-shrink: 0;
  }
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #d3d2e0;
  }
}
</style>
