import * as types from "@/modules/Tables/store/mutations/mutationsTypes";

import { domain, domainStorage } from "@/globalVariables";
import router from "@/router";
import { EventBus } from "@/main";
import centrifuge from "@/centrifuge";
import httpClient from "@/api/client/httpClient";

const actions = {
  /**
   * Universal function of obtaining information in tables
   * @param commit - mutation name
   * @param state - state data
   * @param payload - params + route
   */
  getTableData({ commit, state }) {
    state.loaderTable = true;

    const params = {
      _from: state.fromPag,
      ...state.tableParams.params
    };
    if (
      state.tableData.length < state.totalCount ||
      state.totalCount === 0 ||
      state.isDeleteElement
    ) {
      httpClient({
        method: "GET",
        url: `/${state.tableParams.route}`,
        params: params
      })
        .then((response) => {
          state.fromPag += state.tableParams.params._count;
          if (state.tableParams.route === "BlackSphere" && response.data.model === "BlackSphere") {
            state.totalCount = response.data.data.count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
            commit(types.ADD_BLACK_SPHERE_MARKS_RELATION, response.data.data);
          } else if (
            state.tableParams.route === "ActivityByDay" &&
            response.data.model === "ActivityByDay"
          ) {
            state.totalCount = response.data.data.count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
            commit(types.ADD_TIMES_RELATION, response.data.data);
          } else if (
            state.tableParams.route === "Absence/getItems" &&
            response.data.model === "Absence"
          ) {
            state.totalCount = response.data.data.count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
            commit(types.ADD_ABSENCE_RELATION, response.data.data);
          } else if (
            (state.tableParams.route === "ActivityPercentByMonths" ||
              state.tableParams.route === "BlackSpheresPercentByMonth") &&
            response.data.model === "ActivityPercentByMonths"
          ) {
            state.totalCount = response.data.data.count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
            commit(types.ADD_USER_RELATION, response.data.data);
          } else if (
            state.tableParams.route === "BlackSpheresPercentByMonth/actionGetItems" &&
            response.data.model === "BlackSpheresPercentByMonth"
          ) {
            state.totalCount = response.data.data.total_count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
            commit(
              types.ADD_BLACK_SPHERE_STATISTICS,
              response.data.data.items.filter((item) => item.email !== "smartfox@smartworld.team")
            );
          } else if (state.tableParams.route === "Role") {
            state.totalCount = response.data.data.count;
            commit(types.SET_TABLE_DATA, response.data.data.items);
          } else {
            //для таблицы компании
            state.totalCount = response.data.data.total_count;
            commit(
              types.SET_TABLE_DATA,
              response.data.data.items.filter((item) => item.email !== "smartfox@smartworld.team")
            );
          }
          state.loaderTable = false;
          state.isDeleteElement = false;
        })
        .catch((err) => {
          new Error(err);
          state.isDeleteElement = false;
        });
    } else {
      state.loaderTable = false;
      state.isDeleteElement = false;
    }
  },

  /**
   * Data dump function for education table
   * @param commit - mutation name
   * @param state - state data
   * @param payload - Data dump object
   */

  downloadReports({ commit, state }, payload) {
    state.loaderReport = true;

    let month;
    if (!payload.record) {
      month = new Date();
      month = `${month.getFullYear()}-${
        (month.getMonth() + 1).toString().length === 1
          ? "0" + (month.getMonth() + 1)
          : month.getMonth() + 1
      }`;
    }
    let reqParams = {
      url: `/User/reportWithEducations`,
      method: "get",
      params: {
        export: payload.value,
        is_active: state.isActiveEducation ? JSON.stringify([0]) : JSON.stringify([1]),
        from_date: "",
        to_date: ""
      }
    };
    httpClient(reqParams)
      .then((response) => {
        let url = domainStorage + response.data.data;
        window.open(url, "_blank");
        state.loaderReport = false;
      })
      .catch((error) => {
        this.errorAlert(error);
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Во время выгрузки возникла ошибка, попробуйте позже!`
        });
      });
  },

  // Time

  /**
   We get the time rate / how much worked, etc.
   */

  getTimeStats({ commit, state }, payload) {
    const params = {
      month: payload
    };
    httpClient({
      url: `/GroupedActivityByMonths/totalTracked`,
      method: "GET",
      params
    })
      .then((response) => {
        commit(types.SET_TIME_STATISTICS, response.data.data);
      })
      .catch((e) => {
        this.errorAlert(e);
      });
  },

  /**
   * function for setting dates in the table header
   * @param commit - mutation name
   * @param payload - month in YYYY-MM format
   */
  getDatesMonth({ commit }, payload) {
    httpClient({
      url: `/WorkingCalendar`,
      method: "GET",
      params: {
        _count: 31,
        _search: `{"date":"${payload}"}`,
        _order_by: "date",
        _order: { date: "asc" }
      }
    })
      .then((response) => {
        commit(types.ADD_DATES_COLUMN_IN_TABLE, response.data.data.items);
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  },

  // Скачиваем excel-таблицы с отчетами
  downloadReportsTime({ state }, payload) {
    if (payload.value === "TimeReportByRatesExport") {
      state.loaderTimeReportByRatesExport = true;
    } else {
      state.loaderTimeReportByProjectsExport = true;
    }

    let reqParams = {
      url: `/Activity/report`,
      method: "get",
      params: {
        export: payload.value,
        month: payload.month,
        from_date: "",
        to_date: ""
      }
    };
    httpClient(reqParams)
      .then((response) => {
        state.reportSocketId = centrifuge.subscribe(response.data.data.channel, (message) => {
          if (message?.code === 422 || message?.code === 0) {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
            });
            return false;
          }
          if (message.data.message !== "Start") {
            let url = domainStorage + message.data.message;
            window.open(url, "_blank");
            state.loaderTimeReportByRatesExport = false;
            state.loaderTimeReportByProjectsExport = false;
            state.reportSocketId.unsubscribe();
          }
        });
        // let url = domainStorage + response.data.data;
        // window.open(url, "_blank");
        // state.loaderTimeReportByRatesExport = false;
        // state.loaderTimeReportByProjectsExport = false;
      })
      .catch((error) => {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Во время выгрузки возникла ошибка, попробуйте позже!`
        });
        state.loaderTimeReportByRatesExport = false;
        state.loaderTimeReportByProjectsExport = false;
      });
  },

  //Tests

  getTestsList({ commit }, payload) {
    httpClient({
      method: "GET",
      url: `/Test/actionGetItems`,
      params: {
        _with: JSON.stringify(["author"]),
        _order: { id: "desc" },
        _count: 300
      }
    })
      .then((response) => {
        commit(types.SET_TESTS_LIST, response.data.data.items);
      })
      .catch((error) => this.errorAlert(error));
  },

  /**
   * function create absence
   * @param state - state data
   * @param payload - new absence data
   */
  createAbsence({ state }, payload) {
    return httpClient({
      url: `/Absence/create`,
      method: "POST",
      data: [payload]
    });
  },

  /**
   * function update absence
   * @param state - state data
   * @param payload - new absence data
   */
  updateAbsence({ state }, payload) {
    return httpClient({
      url: `/Absence/update/${payload.id}`,
      method: "PUT",
      data: [payload]
    });
  },

  /**
   * function delete absence
   * @param commit - mutation name
   * @param dispatch - function get table data
   * @param state - state data
   * @param payload - absence data
   */
  deleteAbsence({ commit, dispatch, state }, payload) {
    return httpClient({
      url: `/Absence/delete/${payload.id}`,
      method: "DELETE"
    })
      .then(() => {
        commit(types.CLEAR_TABLE_DATA);
        commit(types.RESET_PAGINATION);
        dispatch("getTableData", payload.params);
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  },

  /**
   * function to open / close the modal create / edit absence
   * @param state - state data
   * @param payload - object consuming open / close modal variable and absence value
   */
  showModalAbsenceAddAndEdit({ state }, payload) {
    state.isAbsenceAddAndEdit = payload.show;
    state.editValueAbsence = payload.value;
  },

  // Скачиваем excel-таблицы с отчетами
  uploadUsersReport({ state }, payload) {
    state.loaderReport = true;

    httpClient({
      url: `/User/report`,
      params: {
        export: "UsersExport",
        is_active: payload ? 0 : 1
      },
      method: "GET"
    })
      .then((response) => {
        let url = domainStorage + response.data.data;
        window.open(url, "_blank");
        state.loaderReport = false;
      })
      .catch((error) => {
        this.errorAlert(error);
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Во время выгрузки возникла ошибка, попробуйте позже!`
        });
        state.loaderReport = false;
      });
  },

  createUserAction({ dispatch }, { newUser, callback }) {
    httpClient({
      method: "POST",
      url: `/User/actionCreate`,
      data: [newUser]
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  },

  //Roles

  /**
   * Getting admin rights for the roles table
   * @param state - state data
   * @param payload - endpoints for roles table
   */
  setEndpointsDataRoles({ state }, payload) {
    state.isAdminRoles = false;
    let endpoints = payload;
    state.isAdminRoles = endpoints.includes("update");
  }
};

export default actions;
