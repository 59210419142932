import { render, staticRenderFns } from "./CommentFileItem.vue?vue&type=template&id=882c5642&scoped=true&"
import script from "./CommentFileItem.vue?vue&type=script&lang=js&"
export * from "./CommentFileItem.vue?vue&type=script&lang=js&"
import style0 from "./CommentFileItem.vue?vue&type=style&index=0&id=882c5642&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "882c5642",
  null
  
)

export default component.exports