import { domain } from "@/globalVariables";
import router from "@/router";
import * as types from "@/modules/Isp/store/mutations/mutationTypes";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

const actionsIspMy = {
  getIspGroup({ commit, state }, payload) {
    state.loaderTable = true;

    httpClient({
      method: "GET",
      url: `/ISPGroup/actionGetItems`,
      params: {
        _count: 200,
        is_deleted: false,
        is_archived: false,
        _search: payload ? JSON.stringify({ name: payload }) : null
      }
    })
      .then((response) => {
        commit(types.GET_GROUPS_ISP, response.data.data.items);
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  getMyIspProblemsList({ commit, state, dispatch }) {
    const params = {
      period_id: state.currentQuarter.id,
      ...state.problemsMyIspParams
    };
    state.loaderTable = true;
    httpClient({
      method: "GET",
      url: `/ISPProblem/actionGetAllUserProblems`,
      params: params
    })
      .then((response) => {
        commit(types.GET_PROBLEM_LIST, response.data.data.items);
        commit(types.GET_MY_ISP_STATISTICS_LIST, response.data.data.statistic);
        commit(types.SET_REVIEW_PROGRESS, response.data.data.progress);
        dispatch("getMyIspBacklogProblemsList");
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  getMyIspBacklogProblemsList({ commit, state, dispatch }) {
    const params = {
      period_id: JSON.stringify(null),
      ...state.problemsMyIspParams
    };
    httpClient({
      method: "GET",
      url: `/ISPProblem/actionGetAllUserProblems`,
      params: params
    })
      .then((response) => {
        commit(types.GET_BACKLOG_PROBLEM_LIST, response.data.data.items);
        state.loaderTable = false;
      })
      .catch((error) => {
        ErrorObserver.notification(error);
        state.loaderTable = false;
      });
  }
};

export default actionsIspMy;
