const stateIspMy = {
  problemsMyIspParams: null,
  myIspStatistics: [],
  myIspCriticalityValue: [],
  myIspStatusValue: [],
  myIspRolesValue: [],
  myIspGroupValue: [],
  myIspCustomersValue: [],
  myIspResponsibleValue: [],
  isMyIspFilters: false,
  groupsISP: [],
  rolesList: [
    {
      id: 1,
      name: "Ответственный"
    },
    {
      id: 2,
      name: "Исполнитель"
    },
    {
      id: 3,
      name: "Заказчик"
    },
    {
      id: 4,
      name: "Автор"
    },
    {
      id: 5,
      name: "Наблюдатель"
    }
  ]
};

export default stateIspMy;
