<template>
  <div
    class="select-item"
    v-if="isSearching"
    @click="$emit('selectItem', item)"
    :class="{
      selected: isSelected
    }"
    ref="selectItem"
  >
    <img
      class="select-item__avatar"
      v-if="showAvatar"
      :src="returnSrcFile(item.avatar)"
      alt="avatar"
    />
    <div class="select-item__name">
      {{ item.full_name || item.name }}<span v-if="item.roles && isShowRoles">{{ setRoles }}</span>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "ItemSelect",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    searchValue: {
      type: String || Number,
      default: () => {
        return "";
      }
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    isShowRoles: {
      type: Boolean,
      default: true
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.selectItem);
  },
  mounted() {
    if (this.isLastItem) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.$emit("reachLastItem");
            this.observer.unobserve(this.$refs.selectItem);
          }
        });
      });
      this.observer.observe(this.$refs.selectItem);
    }
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    isSelected() {
      return this.value.some((item) => item.id === this.item.id);
    },
    isSearching() {
      return this.searchValue
        ? this.item.full_name
          ? this.item.full_name.toLowerCase().includes(this.searchValue.toLowerCase())
          : this.item.name.toLowerCase().includes(this.searchValue.toLowerCase())
        : true;
    },
    setRoles() {
      return this.item.roles.length ? `[${this.item.roles[0].name}]` : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.select-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
  user-select: none;
  &:hover {
    background: $bg-surface-light;
  }
  &__avatar {
    width: 32px;
    height: 32px;
    background: $bg-gray-700;
    border-radius: 50%;
    object-fit: cover;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $text-default;
    margin-left: 10px;
    max-width: 95%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      margin-left: 8px;
      color: $text-disabled;
      word-break: break-word;
    }
  }
}
.selected {
  background: $icon-highlight !important;
  .select-item__name {
    color: $surface-default !important;
  }
}
</style>
