



































import { Component, Vue } from "vue-property-decorator";
import SwitchIOS from "@/modules/UiKit/components/Switch/SwitchIOS.vue";
import Button from "@/modules/UiKit/components/buttons/Button";
import NotificationCard from "@/modules/Notification/components/NotificationCard.vue";
import PaginationObserver from "@/components/PaginationObserver/PaginationObserver.vue";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";

@Component({
  components: { Spinner, PaginationObserver, NotificationCard, SwitchIOS, Button }
})
export default class NotificationModal extends Vue {
  isShowUnread: boolean = true;
  loaderPagination: boolean = false;

  /**
   * Toggle the switch and reset the notification list and options
   */
  changeSwitch() {
    this.isShowUnread = !this.isShowUnread;
  }

  /**
   * Getting a list of unread notifications
   */
  async getNotificationList() {
    this.showLoader(true);
    const payload = {
      perPage: this.perPageNotificationList,
      page: 1
    };
    await this.$store.dispatch("Notification/getNotificationsAction", payload);
    this.showLoader(false);
  }

  readAllNotification() {
    this.$store.dispatch("Notification/readNotificationAction");
  }

  openTableNotifications() {
    this.$router.push({
      name: "Notification",
      params: {},
      query: {
        table: "task_manager"
      }
    });
    this.closeModal();
  }

  closeModal() {
    this.$store.commit("Notification/toggleModal", false);
    this.$store.dispatch("Notification/resetNotificationsAction");
  }

  showLoader(state) {
    this.$store.commit("Notification/toggleLoader", state);
  }

  /**
   * Getting a paginated list of notifications
   */
  async getNotificationListWithPagination() {
    if (this.notificationList.length && this.countNotificationList) {
      if (this.countNotificationList > this.notificationList.length) {
        const page = this.pageNotificationList + 1;
        this.loaderPagination = true;
        const payload = {
          perPage: this.perPageNotificationList,
          page: page
        };
        await this.$store.dispatch("Notification/getNotificationsAction", payload);
        this.loaderPagination = false;
      }
    }
  }

  get perPageNotificationList() {
    return this.$store.state.Notification.notificationListPerPage;
  }

  get pageNotificationList() {
    return this.$store.state.Notification.notificationListPage;
  }

  get countNotificationList() {
    return this.$store.state.Notification.notificationListCount;
  }

  get notificationList() {
    return this.isShowUnread
      ? this.$store.state.Notification.notificationList.filter(
          (notification) => notification.status === 0
        )
      : this.$store.state.Notification.notificationList.filter(
          (notification) => notification.status === 1
        );
  }

  get loader() {
    return this.$store.state.Notification.loader;
  }

  get isEmptyList() {
    return !this.loader && !this.loaderPagination && this.notificationList.length === 0;
  }

  get isNewNavigation() {
    return (
      this.$route.name === "Person" ||
      this.$route.name === "Work" ||
      this.$route.name === "Events" ||
      this.$route.name === "Base"
    );
  }

  mounted() {
    this.getNotificationList();
  }
}
