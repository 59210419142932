<template>
  <div class="chat-notification">
    <transition-group name="fade" tag="ul" class="chat-notification__list">
      <li
        class="chat-notification__item"
        v-for="(item, index) in unreadMessages"
        :key="item.message.id"
      >
        <NotificationMessage :newMessage="item" :index="index" />
      </li>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationMessage from "@/modules/Messenger/components/Notification/NotificationMessage";

export default {
  name: "ChatNotification",
  mixins: [],
  props: {},
  components: { NotificationMessage },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["unreadMessages"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.fade-move {
  transition: transform 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  position: absolute;
}
.chat-notification {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 382px;
  max-height: 77vh;
  padding: 30px 10px;
  display: flex;
  flex-flow: column;
  z-index: 1000;
  overflow-y: auto;

  &__list {
    all: unset;
    list-style: none;
    display: flex;
    flex-flow: column;
    transition: height 0.3s ease;
    animation-name: message;
    animation-duration: 0.5s;
  }
  &__item:not(:last-child) {
    margin-bottom: 4px;
  }
}
@keyframes message {
  from {
    transform: translateY(101px);
  }
  to {
    transform: translateY(0);
  }
}
</style>
