var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-picker",class:_vm.alt ? 'data-picker_alt' : ''},[(_vm.showIcon)?_c('i',{staticClass:"icon-calendar-secondary"}):_vm._e(),_c('div',{staticClass:"data-picker__label"},[_vm._v(_vm._s(_vm.label))]),_c('date-pick',_vm._b({attrs:{"inputAttributes":{ placeholder: _vm.getNowDate, readonly: _vm.readonly },"isDateDisabled":_vm.isDateDisabled,"editable":_vm.editable},scopedSlots:_vm._u([(_vm.isIcon)?{key:"default",fn:function(ref){
var toggle = ref.toggle;
var inputValue = ref.inputValue;
return [_c('button',{staticClass:"data-picker__buttons",on:{"click":toggle}},[_c('i',{staticClass:"icon-calendar-secondary"})])]}}:null],null,true),model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'date-pick',{
      mobileBreakpointWidth: _vm.mobileBreakpointWidth,
      displayFormat: _vm.displayFormat,
      format: _vm.format,
      weekdays: _vm.weekdays,
      months: _vm.months,
      nextMonthCaption: _vm.nextMonthCaption,
      prevMonthCaption: _vm.prevMonthCaption
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }