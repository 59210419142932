import { domain } from "@/globalVariables";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

class API {
  constructor(model, prefix) {
    this.model = model;
    this.prefix = prefix;
  }

  get(endpoint, callback, data = undefined) {
    httpClient({
      method: "GET",
      url: `${domain}/${this.prefix}/${this.model}/${endpoint}`,
      params: data
    })
      .then((res) => {
        if (res.data.data.success) {
          callback(res);
        } else {
          ErrorObserver.notification(res.data.data);
        }
      })
      .catch((err) => {
        ErrorObserver.notification(err);
      });
  }

  post(endpoint, callback, data = undefined) {
    httpClient({
      method: "POST",
      url: `${domain}/${this.prefix}/${this.model}/${endpoint}`,
      data: data
    })
      .then((res) => {
        if (res.data.data.success) {
          callback(res);
        } else {
          ErrorObserver.notification(res.data.data);
        }
      })
      .catch((err) => {
        ErrorObserver.notification(err);
      });
  }

  update(endpoint, callback, data = undefined) {
    httpClient({
      method: "PUT",
      url: `${domain}/${this.prefix}/${this.model}/${endpoint}`,
      data: data
    })
      .then((res) => {
        if (res.data.data.success) {
          if (res.data.data.success) {
            callback(res);
          } else {
            ErrorObserver.notification(res.data.data);
          }
        } else {
          ErrorObserver.notification(res.data.data);
        }
      })
      .catch((err) => {
        ErrorObserver.notification(err);
      });
  }

  delete(endpoint, callback, data = undefined) {
    httpClient({
      method: "DELETE",
      url: `${domain}/${this.prefix}/${this.model}/${endpoint}`,
      data: data
    })
      .then((res) => {
        if (res.data.data.success) {
          callback(res);
        } else {
          ErrorObserver.notification(res.data.data);
        }
      })
      .catch((err) => {
        ErrorObserver.notification(err);
      });
  }
}

export default Object.freeze(API);
