<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'full_name'">
      <UserInfo :user="row" />
    </div>
    <div class="table__wrap">
      <div class="table__content" v-if="cell.key === 'grade'">
        <Level :level="setLevelsUser" />
      </div>
    </div>
    <div class="table__wrap">
      <div class="table__content" v-if="cell.key === 'department'">
        <p class="table__department">{{ setDepartmentUser }}</p>
      </div>
    </div>
    <div class="table__wrap">
      <div class="table__content" v-if="cell.key === 'work_experience'">
        {{ setExperience }}
      </div>
    </div>
    <div class="table__wrap">
      <div class="table__content table__content_email" v-if="cell.key === 'email'">
        <span ref="userEmail">{{ setEmail }}</span>
        <i class="icon-copy" v-if="row.email" @click="copyText($refs.userEmail)"></i>
      </div>
    </div>
    <div class="table__wrap">
      <div class="table__content table__content_phone" v-if="cell.key === 'phone_number'">
        <span ref="userPhone">{{ setPhone }}</span>
        <i class="icon-copy" v-if="row.phone" @click="copyText($refs.userPhone)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import Level from "@/components/Widgets/Level/Level";
import UserInfo from "@/modules/Tables/components/TablesCompany/Users/UserInfo";
import copyText from "@/helpers/copyText";

export default {
  name: "UsersCellContent",
  mixins: [formatDateAndTime, copyText],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: { UserInfo, Level },
  data() {
    return {
      wordsYears: ["год", "года", "лет"],
      wordsMonths: ["месяц", "месяца", "месяцев"],
      wordsDays: ["день", "дня", "дней"],
      regExpYear: new RegExp("year", "g"),
      regExpMon: new RegExp("mon", "g"),
      regExpDay: new RegExp("day", "g"),
      regExpDateYear: new RegExp("[0-9]+\\syear", "g"),
      regExpDateMonth: new RegExp("[0-9]+\\smon", "g"),
      regExpDateDay: new RegExp("[0-9]+\\sday", "g")
    };
  },
  mounted() {},
  methods: {
    getExperienceCompany(items) {
      if (!items.length) {
        this.startWork = format(new Date(), "dd.MM.yyyy", {
          locale: ru
        });
        this.experienceCompany = "1 день";
        this.loader = false;
        return false;
      }
    },
    translateDate(date) {
      let years = "";
      let month = "";
      let days = "";

      if (this.regExpYear.test(date)) {
        years = this.changeDate(date, this.regExpDateYear, "year", this.wordsYears);
      }

      if (this.regExpMon.test(date)) {
        month = this.changeDate(date, this.regExpDateMonth, "mon", this.wordsMonths);
      }

      if (this.regExpDay.test(date)) {
        days = this.changeDate(date, this.regExpDateDay, "day", this.wordsDays);
      }

      return years + " " + month + " " + days;
    },
    changeDate(date, regExp, str, arrayWord) {
      let newDate = date.match(regExp);
      return newDate[0].replace(str, this.declOfNum(newDate[0].split(" ")[0], arrayWord));
    }
  },
  computed: {
    setPhone() {
      return this.row.phone ? this.row.phone : "-";
    },
    setEmail() {
      return this.row.email ? this.row.email : "-";
    },
    setDepartmentUser() {
      return this.row.departments.length ? this.row.departments[0].name : "-";
    },
    setLevelsUser() {
      return this.row.levels.length ? this.row.levels[0].name : "Нет уровня";
    },
    setExperience() {
      return this.row.experience ? this.translateDate(this.row.experience) : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.table {
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__cell {
    display: flex;
    width: 100%;
    padding: 16px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;

    @media (max-width: $xs), (max-height: $sm) {
      font-size: 12px;
      padding: 5px;
    }
  }

  &__content {
    &_email,
    &_phone {
      span {
        margin-right: 8px;
        &::selection {
          background: transparent;
        }
      }

      i {
        display: none;
        color: $icon-subdued;
        cursor: pointer;

        &:hover {
          color: $icon-hovered;
        }
      }

      &:hover {
        i {
          display: inline;
        }
      }
    }

    /deep/ {
      .user-info__name {
        max-width: 210px;
      }
    }
  }

  &__department {
    margin: 0;
    display: -webkit-box;
    max-width: 250px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
