import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import StageService from "@/modules/TaskManager/Model/Stage/StageService";
import Vue from "vue";
import { IStage } from "@/modules/TaskManager/Model/Stage/StageModel";
import ProjectModule, {
  ProjectMutationTypes
} from "@/modules/TaskManager/Model/Project/ProjectModule";
import { EventBus } from "@/main";
import TaskManagerModuleV2, {
  IModalConfig,
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import router from "@/router";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModel, { IProject } from "@/modules/TaskManager/Model/Project/ProjectModel";
import NotificationObserver from "@/helpers/UserNotification";
import ProjectService from "@/modules/TaskManager/Model/Project/ProjectService";

const name = "StageModule";

type completeStageType = {
  isComplete: boolean;
  stage_id: number | string;
  stage?: IStage;
};
type deleteStageType = {
  stage_id: number | string;
  project_id: number | string;
};
type stageType = {
  id: number;
  name: string;
  is_completed: null | boolean;
};

export enum StageMutationTypes {
  SET_STAGE = "SET_STAGE",
  SET_CURRENT_STAGE = "SET_CURRENT_STAGE",
  TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE = "TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE",
  TOGGLE_STAGE_PAGINATION_LOADER = "TOGGLE_STAGE_PAGINATION_LOADER",
  SET_OPEN_STAGE = "SET_OPEN_STAGE",
  SET_CURRENT_STAGE_TYPE = "SET_CURRENT_STAGE_TYPE"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class StageModule extends VuexModule {
  stageList = {};
  currentStage: object | IStage = {};
  openStage: null | IStage = null;
  actionList: Array<any> = [];
  showCurtainShowAndEditStage: boolean = false;
  currentStageType: stageType = {
    id: 0,
    name: "Все этапы",
    is_completed: null
  };
  stagePaginationLoader: boolean = false;

  @Mutation
  [StageMutationTypes.SET_STAGE](payload) {
    Vue.set(this.stageList, `project_${payload.project_id}`, payload.stages);
  }

  @Mutation
  [StageMutationTypes.SET_CURRENT_STAGE](payload: IStage | object) {
    this.currentStage = payload;
  }

  @Mutation
  [StageMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE](payload: boolean) {
    this.showCurtainShowAndEditStage = payload;
  }
  @Mutation
  [StageMutationTypes.TOGGLE_STAGE_PAGINATION_LOADER](payload) {
    this.stagePaginationLoader = payload;
  }
  @Mutation
  [StageMutationTypes.SET_OPEN_STAGE](payload: IStage) {
    this.openStage = payload;
  }

  @Mutation
  [StageMutationTypes.SET_CURRENT_STAGE_TYPE](payload) {
    this.currentStageType = payload;
  }

  @Action
  async createStageAction(stageToCreate) {
    const newStage = await StageService.createStage(stageToCreate);
    NotificationObserver.notification({
      type: "success",
      message: "Этап успешно создан!"
    });
    if (!this.stageList[`project_${newStage.project_id}`]) return;
    if (
      this.currentStageType.is_completed === null ||
      newStage.is_completed === this.currentStageType.is_completed
    ) {
      this.stageList[`project_${newStage.project_id}`].list.unshift(newStage);
    }
  }

  @Action
  goToStagePage(data: { stage: IStage; project_id: string }) {
    this[StageMutationTypes.SET_CURRENT_STAGE](data.stage);
    this[StageMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE](false);
  }

  @Action
  toggleShowEditModal(isShow: boolean) {
    this[StageMutationTypes.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_STAGE](isShow);
  }

  @Action
  async handleActionList(payload: { action: string; stage: IStage; currentProject: IProject }) {
    switch (payload.action) {
      case "execute": {
        this.completeStage({
          stage_id: payload.stage.id,
          isComplete: !payload.stage.is_completed,
          stage: payload.stage
        });
        const stage = { ...payload.stage, is_completed: !payload.stage.is_completed };
        this[StageMutationTypes.SET_OPEN_STAGE](stage);
        break;
      }
      case "createTask": {
        TaskManagerModuleV2.openCurtainConstructor({
          currentCreationTypeId: 3,
          currentStageForCreation: payload.stage,
          currentProjectForCreation: payload.currentProject
        });
        break;
      }
      case "edit": {
        await this.getOpenStage(payload.stage.id);
        if (TaskModule.tasksByStage(payload.stage.id).count === 0)
          TaskModule.getTaskListByStageIdAction({
            stage_id: payload.stage.id,
            per_page: 10,
            project_id: payload.currentProject.id
          });
        this.toggleShowEditModal(true);
        break;
      }
      case "delete": {
        this.deleteStage({ stage_id: payload.stage.id, project_id: payload.currentProject.id });
        break;
      }
    }
  }

  @Action
  async getStageByProjectId(params) {
    const stages = await StageService.getAll(params);
    this[StageMutationTypes.SET_STAGE]({ stages, project_id: params.project_id });
  }
  @Action
  async getStageByPagination(params) {
    if (
      this.stageList[`project_${params.project_id}`].list.length >=
      this.stageList[`project_${params.project_id}`].count
    )
      return;
    this[StageMutationTypes.TOGGLE_STAGE_PAGINATION_LOADER](true);
    const responseData = await StageService.getAll({
      ...params,
      page: this.stageList[`project_${params.project_id}`].page + 1,
      per_page: this.stageList[`project_${params.project_id}`].per_page
    });
    const stages = [...this.stageList[`project_${params.project_id}`].list, ...responseData.list];
    responseData.list = stages;
    this[StageMutationTypes.SET_STAGE]({ stages: responseData, project_id: params.project_id });
    this[StageMutationTypes.TOGGLE_STAGE_PAGINATION_LOADER](false);
  }
  @Action
  async editCurrentStage(data) {
    const isSuccess = await StageService.editStage(data);
    if (isSuccess) {
      const stage: IStage | null = { ...this.openStage, ...data };
      if (stage) this[StageMutationTypes.SET_OPEN_STAGE](stage);
      const id = ProjectModule.currentProject?.projectFull.id;
      if (id) await this.getStageByProjectId({ project_id: id });
    }
  }

  @Action
  async getOpenStage(id: number | string) {
    const stage = await StageService.getStage(id);
    if (stage) {
      this[StageMutationTypes.SET_OPEN_STAGE](stage);
    }
  }
  @Action
  async getCurrentStage(id: number | string) {
    const stage = await StageService.getStage(id);
    if (stage) {
      this[StageMutationTypes.SET_CURRENT_STAGE](stage);
    }
  }
  @Action
  async completeStage(data: completeStageType) {
    // const id = ProjectModule.currentProject?.projectFull.id;
    if (!data.isComplete) {
      await StageService.completeStage({ id: data.stage_id, is_completed: data.isComplete });
      this.stageList[`project_${data.stage?.project_id}`].list.find(
        (item) => item.id === Number(data.stage_id)
      ).is_completed = data.isComplete;
      return data.isComplete;
    }
    const tasks = await StageService.checkTaskComplete(data.stage_id);
    if (tasks.length) {
      const confirmFunc = async () => {
        await StageService.completeStage({
          id: data.stage_id,
          is_completed: data.isComplete
        });
        TaskManagerModuleV2.toggleConfirmModal(false);

        // if (id !== undefined) {
        // await this.getStageByProjectId({ project_id: id });
        this.stageList[`project_${data.stage?.project_id}`].list.find(
          (item) => item.id === Number(data.stage_id)
        ).is_completed = data.isComplete;
        // }
      };
      const config: IModalConfig = {
        cancelButton: "Отменить",
        confirmButton: "Подтвердить",
        title: "Закрыть этап?",
        text: "Этап содержит невыполненные задачи. Данные задачи будут закрыты. Продолжить?",
        handleCancel: () => {
          TaskManagerModuleV2.toggleConfirmModal(false);
        },
        handleConfirm: confirmFunc
      };
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.SET_CONFIRM_CONFIG](config);
      return null;
    }
    await StageService.completeStage({ id: data.stage_id, is_completed: data.isComplete });
    this.stageList[`project_${data.stage?.project_id}`].list.find(
      (item) => item.id === Number(data.stage_id)
    ).is_completed = data.isComplete;
    return data.isComplete;
  }

  @Action
  async deleteStage(data: deleteStageType) {
    const confirmFunc = async () => {
      await StageService.deleteStage(data.stage_id);
      TaskManagerModuleV2.toggleConfirmModal(false);
      this.stageList[`project_${data.project_id}`].list = this.stageList[
        `project_${data.project_id}`
      ].list.filter((item) => item.id !== data.stage_id);
      this.toggleShowEditModal(false);
      if (
        router.currentRoute.query.stage &&
        Number(router.currentRoute.query.stage) == data.stage_id
      ) {
        this[StageMutationTypes.SET_CURRENT_STAGE]({});
        // @ts-ignore
        router.replace({ name: "Project", params: { id: data.project_id } });
      }
      NotificationObserver.notification({
        type: "success",
        message: "Этап успешно удален!"
      });
    };
    const config: IModalConfig = {
      cancelButton: "Отменить",
      confirmButton: "Подтвердить",
      title: "Удалить этап?",
      text: "Этап будет удален со всеми задачами. Продолжить?",
      handleCancel: () => {
        TaskManagerModuleV2.toggleConfirmModal(false);
      },
      handleConfirm: confirmFunc
    };
    TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.SET_CONFIRM_CONFIG](config);
  }

  get stages() {
    return (project_id) => this.stageList[`project_${project_id}`];
  }

  get stage() {
    return this.currentStage;
  }

  get actionsList() {
    return (stage, project) => {
      const list = [
        {
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить задачу",
          actionType: `createTask`
        },
        {
          isShow: !stage.is_completed && project.is_owner,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `edit`
        },
        {
          isShow: project.is_owner,
          iconType: "folder-check",
          actionText: stage.is_completed ? "Отменить" : "Выполнить",
          actionType: `execute`
        },
        {
          isShow: project.is_owner,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ];
      if (stage.is_completed) {
        return list.filter(
          (item) => item.actionType !== "edit" && item.actionType !== "createTask"
        );
      }
      if (this.showCurtainShowAndEditStage) {
        return list.filter((item) => item.actionType !== "edit");
      }
      return list;
    };
  }
}

export default getModule(StageModule);
