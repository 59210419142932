<template>
  <li
    class="filters__item"
    @click="$emit('choseFilter', item)"
    :class="{ filters__item_active: valueFilter === item }"
  >
            <span
              class="filters__checkbox"
              :class="{ filters__checkbox_active: valueFilter === item }"
            >
              <i class="icon-check" v-if="valueFilter === item"></i>
            </span>
    {{ item }}
  </li>
</template>

<script>
export default {
  name: "FiltersItem",
  mixins: [],
  props: {
    item: {
      type: String,
      default: ''
    },
    valueFilter: {
      type: String,
      default: ''
    }
  },
  components: [],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";


.filters {
  &__item {
    display: flex;
    align-items: center;
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;

    &_active {
      background: #F1EFFA;
      border-radius: 4px;
    }

    &:hover {
      background: #F1EFFA;
      border-radius: 4px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background: $surface-default;
    border: 2px solid $border-default;
    box-sizing: border-box;
    border-radius: 4px;

    &_active {
      background: $action-primary;
      border: 2px solid $action-primary;
    }

    i {
      color: $surface-default;
    }
  }
}

</style>
