<template>
  <li class="channel" @click="$emit('transferActiveChannel', channel.id)">
    <ConfirmModal
      v-if="confirmDeleteChannel"
      :title="'Удалить'"
      :description="'Вы уверены, что хотите удалить канал?'"
      @cancel="confirmDeleteChannel = false"
      @accept="deleteChannel"
    />
    <p class="channel__tooltip" v-if="showTooltip">
      {{ channel.name }}
    </p>
    <div
      class="channel__wrap"
      :ref="`channel_${channel.id}`"
      :style="{ background: channel.color }"
      v-click-outside="closeChannelSettingsModal"
      @contextmenu.prevent="openChannelSettingsModal"
      @touchstart="longTouch"
      v-closable="{
        exclude: [`channel_${channel.id}`],
        handler: 'closeChannelSettingsModal'
      }"
    >
      <img
        class="channel__icon"
        :src="returnSrcFile(channel.icon)"
        alt="channel-icon"
        v-if="channel.icon"
      />
      <span
        :class="(activeChannel === channel.id ? 'active' : '', channel.icon ? 'channel__mask' : '')"
      >
        {{ setChannelName(channel.name) }}</span
      >
      <transition name="show-modal">
        <div class="settings-modal" v-if="showSettings && channel.current_channel_user">
          <div class="settings-modal__item" @click.stop="openCurtain">
            <i class="icon-info"></i>
            О канале
          </div>
          <div class="settings-modal__item" @click.stop="unsubscribeChannel" v-if="notAuthor">
            <i class="icon-exit"></i>Отписаться
          </div>
          <div
            class="settings-modal__item"
            v-if="isAdmin || channel.author_user_id === getUserId"
            @click.stop="askDeleteChannel"
          >
            <i class="icon-delete"></i>
            Удалить
          </div>
        </div>
      </transition>
    </div>
    <div class="channel__info" v-if="showName">
      <p class="channel__name">{{ channel.name }}</p>
      <i class="icon-lock" v-if="!channel.is_public"></i>
      <div class="channel__subscribe" v-if="!channel.current_channel_user">
        <Button
          :title="'Подписаться'"
          :icon="'plus'"
          :type="'simple'"
          @handleButton="subscribeChannel"
        />
      </div>
      <div class="channel__subscribed" v-else-if="channel.current_channel_user && showUnsubscribe">
        <p><i class="icon-done"></i> <span>Подписка</span></p>
        <Button
          :icon="'cross'"
          :title="'Отписаться'"
          :type="'simple'"
          class="channel__unsubscribe"
          @handleButton="unsubscribeChannel"
        ></Button>
      </div>
    </div>
  </li>
</template>

<script>
import Vue from "vue";
import { domainStorage } from "@/globalVariables";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import Button from "@/modules/UiKit/components/buttons/Button";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import * as types from "@/modules/News/store/mutations/mutationTypes";

// Эта переменная будет содержать ссылку на обработчик кликов документа
let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    //Обработчик click / touchstart / contextmenu (он зарегистрирован ниже) - можно добавить другие события
    handleOutsideClick = (e) => {
      e.stopPropagation();

      //Эта директива принимает два значения: exclude и handler.
      // handler - указывает имя метода, который будет обрабатывать событие внешнего щелчка
      // exclude - массив ссылочных имен элементов, которые мы не хотим запускать событие внешнего щелчка (имя мы указываем в ref элемента
      const { handler, exclude } = binding.value;

      // Эта переменная указывает, исключен ли выбранный элемент
      let clickedOnExcludedEl = false;

      // Мы проверяем, не является ли выбранный элемент элементом диалога и не исключен
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // Если выбранный элемент находится за пределами диалогового окна, вызоваем обработчик внешних щелчков
        // из того же компонента, где эта директива используется
        vnode.context[handler]();
      }
    };

    // Зарегистрируем прослушиватели событий на всей странице
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
    document.addEventListener("contextmenu", handleOutsideClick);
  },
  unbind() {
    // Если элемент, имеющий v-closable, удален, то отключаем прослушиватели со всей страницы
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
    document.addEventListener("contextmenu", handleOutsideClick);
  }
});

export default {
  name: "ChannelComponent",
  mixins: [],
  props: {
    showName: {
      type: Boolean,
      default: false
    },
    activeChannel: {
      type: String,
      default: ""
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    channel: {
      type: Object,
      default: () => {
        return null;
      }
    },
    memberInfoChannel: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showUnsubscribe: {
      type: Boolean,
      default: true
    }
  },
  components: { ConfirmModal, Button },
  data() {
    return {
      showSettings: false,
      timer: null,
      touchDuration: 500,
      onLongTouch: null,
      confirmDeleteChannel: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions("NewsModule", [
      "openSettingsModal",
      "openMembersModal",
      "getChannelInfo",
      "getNewsItems",
      "deleteChannelAction",
      "unsubscribeChannelAction",
      "subscribeChannelAction",
      "setActiveChannel"
    ]),
    ...mapMutations("NewsModule", [types.TOGGLE_IS_CERTAIN_NEWS]),
    returnSrcFile(src) {
      return domainStorage + src;
    },
    // Модалка с настройками открывается
    openChannelSettingsModal() {
      this.$emit("openFullChannelList");
      this.showSettings = true;
    },

    // Модалка с настройками закрывается
    closeChannelSettingsModal() {
      this.showSettings = false;
    },

    // Подтверждение удаления канала
    askDeleteChannel() {
      this.closeChannelSettingsModal();
      this.confirmDeleteChannel = true;
    },
    deleteChannel() {
      this.deleteChannelAction(this.channel.id)
        .then(() => {
          if (this.activeChannel === this.channel.id) {
            this.getChannelInfo().then(() => this.setActiveChannel(""));
          } else {
            this.getChannelInfo().then(() => this.getNewsItems());
          }
          this[types.TOGGLE_IS_CERTAIN_NEWS](false);
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: "Канал успешно удален."
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    // Запрос на отписку от канала
    unsubscribeChannel() {
      this.closeChannelSettingsModal();
      this.unsubscribeChannelAction(this.channel.current_channel_user.id)
        .then(() => {
          if (this.activeChannel === this.channel.id) {
            this.getChannelInfo().then(() => this.setActiveChannel(""));
          } else {
            this.getChannelInfo().then(() => this.getNewsItems());
          }
          this[types.TOGGLE_IS_CERTAIN_NEWS](false);
          this.showSettings = false;
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: "Вы успешно отписались от канала."
          });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },

    // Запрос на подписку на канал
    subscribeChannel() {
      this.subscribeChannelAction(this.channel.id)
        .then(() => {
          this.getChannelInfo().then(() => this.getNewsItems());
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: "Вы успешно подписались на канал."
          });
        })
        .catch((error) => this.errorAlert(error));
    },
    setChannelName(str) {
      return str.charAt(0).toUpperCase();
    },
    // событие долгого касания для мобильной версии приложения
    // открывает панель настроек при зажатии иконки канала
    longTouch() {
      this.setTouchStart();
      this.showSettings();
      this.touchEnd();
    },
    //регистрируем время, прошедшее после нажатия на сенсорный экран
    setTouchStart(evt) {
      evt.preventDefault();
      if (!this.timer) {
        this.timer = setTimeout(this.onLongTouch, this.touchDuration);
      }
    },
    // очищаем счетчик времени
    touchEnd() {
      if (this.timer) clearTimeout(this.timer);
    },
    openCurtain() {
      this.closeChannelSettingsModal();
      this.openSettingsModal({ val: this.channel, show: true });
    }
  },
  computed: {
    ...mapGetters(["getUserId"]),
    ...mapState("NewsModule", ["activeChannel"]),
    isChannelAdmin() {
      let flag = false;
      if (this.channel.admin_users.length) {
        this.channel.admin_users.forEach((item) => {
          if (item.user_id === this.getUserId) {
            flag = true;
          }
        });
      }
      return flag;
    },
    notAuthor() {
      return this.getUserId !== this.channel.author_user_id;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.1s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.channel {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    .channel__tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }
  &__tooltip {
    transition: opacity 0.3s ease;
    opacity: 0;
    width: max-content;
    transform: scale(0);
    position: absolute;
    padding: 5px 9px;
    font-size: 14px;
    line-height: 20px;
    background-color: $bg-surface-dark;
    color: #fff;
    bottom: 100%;
    right: 0;
    z-index: 10;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
  }
  &__wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $decorative-surface-two;
    cursor: pointer;

    @media (max-width: $lg) {
      width: 30px;
      height: 30px;
    }

    .channel__mask {
      display: none;
    }

    &:hover {
      .channel__mask {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: none;
        background: $background-modal;
        z-index: 2;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: $surface-default;
      }
    }
  }
  &__info {
    max-width: calc(100% - 76px);
    width: 100%;
    margin: 0 0 0 16px;
    display: flex;
    align-items: center;

    i {
      color: $icon-subdued;
      margin-left: 6px;
    }
  }
  &__subscribe {
    margin-left: auto;
    /deep/ {
      .aop-button_simple {
        padding: 0;
        i {
          font-size: 16px;
        }
      }
    }
  }
  &__subscribed {
    margin-left: auto;
    &:hover .channel__unsubscribe {
      display: flex;
    }
    &:hover p {
      display: none;
    }
    p {
      font-size: 12px;
      color: $text-disabled;
      display: flex;
      align-items: center;
      span {
        font-size: inherit;
        color: inherit;
      }
    }
    i {
      font-size: 14px;
      margin-right: 6px;
    }
    /deep/ {
      .aop-button_simple {
        padding: 0;
        font-size: 12px;
        i {
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }
  &__unsubscribe {
    display: none;
  }
  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    color: #4e5155;
    font-family: "SF", sans-serif !important;
    @media (max-width: $md) {
      max-width: calc(100% - 66px);
    }
  }

  &__icon {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    width: 46px;
    height: 46px;
    border-radius: 50%;

    @media (max-width: $lg) {
      width: 26px;
      height: 26px;
    }
  }

  span {
    position: relative;
    width: max-content;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $text-default;
    word-break: break-word;
    z-index: 2;

    &.active {
      color: $text-default;
    }
  }

  .settings-modal {
    background-color: $surface-default;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    position: absolute;
    z-index: 50;
    left: 43px;
    top: 0;
    width: 187px;
    padding: 8px;

    @media (max-width: $xl) {
      width: 145px;
    }
    @media (max-width: $lg) {
      left: 0;
      top: 35px;
    }
    @media (max-width: $md) {
      left: 43px;
      top: 0;
      width: 187px;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 8px;
    }

    .icon-sort {
      transform: rotate(90deg);
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      color: $text-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 8px;
      background: transparent;
      border: none;
      outline: none;

      i {
        margin-right: 8px;
        color: $icon-subdued;
        font-size: 18px;
      }

      &:hover {
        background: #f2f7fe;
        border-radius: 4px;
      }
    }
  }
}
</style>
