<template>
  <section class="messenger-view">
    <MessengerTabs v-if="showTabs" />
    <OptionModal v-if="showModal" />
    <div class="messenger-view__wrap">
      <ChatCatalog v-if="showCatalog" />
      <spinner v-if="showLoader" />
      <div
        class="messenger-view__placeholder placeholder-empty"
        v-if="!showChat && !this.$route.query.chat_id"
      >
        <div class="placeholder-empty__bg">
          <p>Выберите, кому хотели бы написать :)</p>
        </div>
      </div>
      <Chat v-if="showChat" />
    </div>

    <transition name="slide">
      <CreateEditChannelModal v-if="showCreateEditChannel" />
    </transition>
    <transition name="fade">
      <ChatFileModal v-if="showFileModal && files.length" />
      <ChatTooltip v-if="isShowTooltip" />
      <FullScreenImage v-if="showFullScreenImage" @closeImage="closeImage" />
      <VideoPlayerModal v-if="videoPlayerData" :item="videoPlayerData" />
      <SendModal v-if="showSendModal" />
      <ConfirmationModal v-if="showNotificationModal" />
    </transition>
  </section>
</template>

<script>
import MessengerTabs from "@/modules/Messenger/components/MessengerTabs";
import Chat from "@/modules/Messenger/components/Chat";
import { mapActions, mapGetters, mapState } from "vuex";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import ChatFileModal from "@/modules/Messenger/components/ChatModal/FileModal/ChatFileModal";
import SendModal from "@/modules/Messenger/components/ChatModal/SendModal/SendModal";
import CreateEditChannelModal from "@/modules/Messenger/components/ChatModal/ChannelModal/CreateEditChannelModal";
import ChatCatalog from "@/modules/Messenger/components/Chat/Catalog/ChatCatalog";
import FullScreenImage from "@/modules/Messenger/components/ChatModal/FullScreenImage/FullScreenImage";
import VideoPlayerModal from "@/modules/Messenger/components/ChatModal/AttachedModal/VideoPlayerModal";
import OptionModal from "@/modules/Messenger/components/OptionModal/OptionModal";
import ConfirmationModal from "@/modules/Messenger/components/ChatModal/ConfirmationModal/ConfirmationModal";
import ChatTooltip from "@/modules/Messenger/components/ChatTooltip";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import taskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "MessengerView",
  mixins: [],
  props: {},
  components: {
    Spinner,
    ChatCatalog,
    ChatTooltip,
    ConfirmationModal,
    VideoPlayerModal,
    FullScreenImage,
    CreateEditChannelModal,
    SendModal,
    ChatFileModal,
    Chat,
    OptionModal,
    MessengerTabs
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    if (isObjectEmptyChecker(this.$route.query)) {
      this.getChats(null);
    } else {
      this.openChatFromQuery(true);
      this.getChats(+this.$route.query.chat_id);
    }
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    this.resetAll();
  },
  methods: {
    ...mapActions("MessengerModule", ["getChats", "openChatFromQuery", "resetAll"]),
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    closeImage() {
      taskManagerModuleV2.closeFullScreenImage();
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "showChat",
      "showFileModal",
      "showCreateEditChannel",
      "showMenu",
      "files",
      "showModal",
      "memberLoader",
      "showNotificationModal",
      "showSendModal",
      "showFullScreenImage",
      "isShowTooltip",
      "showCatalog",
      "videoPlayerData"
    ]),

    ...mapGetters("MessengerModule", ["getChatFromQuery"]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    showTabs() {
      return !((this.$route.query.chat_id || this.showCatalog) && this.isMobileWindowSize);
    },
    showLoader() {
      return !this.showChat && this.$route.query.chat_id;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
.messenger-view {
  display: flex;
  height: 100%;
  width: 100%;
  color: $text-default;
  /deep/ {
    *:not(i) {
      font-family: "Roboto", sans-serif !important;
    }
  }
  &__wrap {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__placeholder {
    width: 100%;
    height: 100%;
    user-select: none;
    background: white;

    .placeholder-empty {
      &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $text-highlight;
        }
      }
    }
  }
  /deep/ {
    .confirmation-modal {
      &__wrap {
        width: 400px;
      }

      &__title {
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 28px;
        color: #343748;
        margin-bottom: 48px;
      }

      &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #0a1417;
        margin-bottom: 30px;
      }

      &__buttons {
        border: none;

        .default-button {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
          border: 2px solid #ffffff;
          @media (hover: hover) {
            &:hover {
              background: linear-gradient(
                336.67deg,
                #443cea -13.54%,
                #5e60ea 70.48%,
                #686ed1 120.89%
              );
              box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
                0 4px 5px rgba(48, 79, 254, 0.2);
            }
          }

          &_cancel {
            color: #343748 !important;
            background: none !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .modal-channel {
      padding-bottom: 40px;
      @media (max-width: $md) {
        padding-bottom: 70px;
      }

      .uploader {
        &__button {
          padding: 14px 30px;
          height: 42px;
          background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
          border: 2px solid #ffffff;
          border-radius: 32px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          transition: all 0.3s ease;
          outline: none;
          cursor: pointer;

          &:hover {
            background: linear-gradient(
              336.67deg,
              #443cea -13.54%,
              #5e60ea 70.48%,
              #686ed1 120.89%
            );
            box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
              0 4px 5px rgba(48, 79, 254, 0.2);
          }

          &:active {
            background: linear-gradient(
              336.67deg,
              #6660ff -13.54%,
              #9d9efe 70.48%,
              #b3b7ff 120.89%
            );
            box-shadow: 0 8px 10px rgba(122, 124, 255, 0.14), 0 3px 14px rgba(122, 124, 255, 0.12),
              0 4px 5px rgba(122, 124, 255, 0.2);
          }

          i {
            margin-right: 6px;
            font-size: 24px;
          }

          span {
            font-family: "Roboto", sans-serif !important;
          }
        }
        &__cancel {
          color: #343748;
          background: transparent;
          border: none;

          &:hover {
            background: transparent;
            border: none;
            box-shadow: none;
            filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
              drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
              drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
          }

          &:active {
            background: transparent;
            border: none;
            box-shadow: none;
            filter: drop-shadow(0px 8px 10px rgba(122, 124, 255, 0.14))
              drop-shadow(0px 3px 14px rgba(122, 124, 255, 0.12))
              drop-shadow(0px 4px 5px rgba(122, 124, 255, 0.2));
          }
        }
      }
    }
  }
  /deep/.spinner {
    margin: auto;
  }
}
</style>
