export interface IProject {
  id: number;
  is_owner: boolean;
  name: string;
  parent_id: null | number;
  date_from: string;
  date_to: string;
  is_completed: boolean;
  description: string;
  flow_id: number;
  author_id: number;
  author_info: object;
  executor_info: object;
  alias: string;
  level: number;
  count_member: number;
  count_stage: number;
  created_at: null | string;
  updated_at: null | string;
  deleted_at: null | string;
}

export interface IProjectModel {
  project: IProject;
}

export default class ProjectModel implements IProjectModel {
  private _project: IProject;

  constructor(project: IProject) {
    this._project = project;
  }

  get project() {
    return this._project;
  }

  set project(updatedProject: IProject) {
    this._project = updatedProject;
  }
  setField(key, value) {
    this._project[key] = value;
  }
}
