import * as types from "@/modules/Messenger/store/mutations/mutationTypes";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import defaultState from "@/modules/Messenger/store/state/defaultState";

function reset() {
  if (!this.replyMode) {
    this.editorValue = "";
  }
  this.showModal = false;
  this.currentMessage = null;
  this.lastMessage = null;
  this.replyMode = false;
  this.editMode = false;
  this.sendMode = false;
  this.files = [];
  const container = document.querySelector("#list-dynamic");
  if (container) {
    container.classList.remove("stop-scrolling");
  }
}
const sortMessage = (message) => {
  if (message.length) message.sort((a, b) => a.id - b.id);
};
const replaceArrayElement = (array, id, isSet = false, obj) => {
  const index = array.findIndex((item) => item.id === id);
  if (isSet) {
    Vue.set(array, index, obj);
  } else {
    if (index !== -1) array.splice(index, 1);
  }
};

const setChatDataFormat = (chat, user_id) => {
  let is_read_companion = chat.last_read_message_id;
  const channel = {
    ...chat,
    files: [],
    is_read_companion,
    is_writing: false
  };
  let users = [];
  if (chat.users && chat.users.length === 1) {
    users = chat.users.map((item) => ({ ...item.info, ...item }));
    is_read_companion = chat.users[0].last_read_message_id;
    channel.is_read_companion = is_read_companion;
  }
  return {
    channel,
    users
  };
};

const createChat = (chat, user_id) => {
  let is_read = false;
  const channel = {
    ...chat,
    files: [],
    is_read,
    is_writing: false
  };
  const user = chat.users.find((item) => item.user_id === user_id);
  channel.count_unread_messages = user.count_unread_messages;
  channel.count_unread_thread_messages = user.count_unread_thread_messages;
  channel.last_read_message_id = user.last_read_message_id;
  channel.tagged_last_message_id = user.tagged_last_message_id;
  channel.chat_user_id = user.id;
  channel.is_moderator = user.is_moderator;
  channel.is_pinned_chat = user.is_pinned_chat;
  channel.notification_enabled = user.notification_enabled;
  return {
    channel,
    users: chat.users.map((item) => ({
      ...item.info,
      is_moderator: item.is_moderator
    }))
  };
};
const sortChat = (state) => {
  state.chatList = Array.from(new Set(state.chatList));
  state.chatList = state.chatList
    .map((item) => item)
    .sort((a, b) => {
      if (a.channel.is_pinned_chat === b.channel.is_pinned_chat) {
        return b.channel.last_message_id - a.channel.last_message_id;
      } else {
        return a.channel.is_pinned_chat ? -1 : 1;
      }
    });
};

const mutations = {
  [types.SET_CHATS](state, payload) {
    if (payload.length) {
      state.chatList = payload.map((item) => {
        return setChatDataFormat(item, store.getters.getUserId);
      });
      sortChat(state);
    } else {
      state.chatList = payload;
    }
    state.showChatList = true;
  },
  [types.SEARCH_USER_RESULT](state, payload) {
    state.userListResult = payload.users;
    state.search = payload.text;
  },
  [types.SET_NEW_CHAT](state, payload) {
    state.userListResult = [];
    state.messages = [];
    state.files = [];
    state.joinMode = false;
    state.pinned_messages = [];
    state.currentChat = {
      channel: { type: 1, files: {}, notification_enabled: true },
      users: [payload]
    };
    state.users = [payload];
    state.search = "";
  },
  [types.OPEN_CHAT_IN_SEARCH](state, payload) {
    state.searchMode = false;
    state.personChat = [];
    state.closeChat = [];
    state.openChat = [];
    state.person = [];
    state.search = "";
    if (payload) {
      router.app.$router.replace({ query: { chat_id: payload } });
    } else {
      if (router.app.$route.query.chat_id) {
        router.app.$router.replace({ query: {} });
      }
    }
  },
  [types.SET_CURRENT_CHAT](state, payload) {
    state.showPin = false;
    state.joinMode = false;
    state.showSetting = false;
    state.showComment = false;
    state.showReaction = false;
    state.showReactionList = false;
    state.showCatalog = false;
    state.threadMainMessage = null;
    if (!state.showSendModal) {
      reset.call(state);
    } else {
      state.editorValue = "";
      state.replyMode = false;
      state.editMode = false;
    }
    if (!isObjectEmptyChecker(payload)) {
      const chat = state.chatList.find((item) => item.channel.id === payload.chat.id);
      if (chat) {
        state.currentChat = chat;
      } else {
        state.currentChat = createChat(payload.chat, store.getters.getUserId);
      }
      if (payload.chat.files) {
        state.currentChat.channel.files = payload.chat.files;
      } else {
        state.currentChat.channel.files = [];
      }
      state.messages = payload.messages;
      sortMessage(state.messages);
      state.users = payload.users;
      state.pinnedCount = payload.chat.count_pinned_messages;
      if (state.pinnedCount) {
        state.pinned_messages = Array.from({ length: state.pinnedCount - 1 }, () => ({
          id: 0
        })).concat(payload.chat.last_pinned_message);
      } else {
        state.pinned_messages = [];
      }
      state.lastMessage = state.currentChat.channel.last_message;
      state.userList = payload.users.map((item) => {
        return { ...item.info, nickname: `@${item.info.nickname}` };
      });
      state.userList.unshift({ nickname: "@all", full_name: null });
      state.showChat = true;
      state.pickMode = false;
    }
    if (
      !router.app.$route.query.chat_id ||
      +router.app.$route.query.chat_id !== state.currentChat.channel.id
    ) {
      router.app.$router.replace({ query: { chat_id: state.currentChat.channel.id } });
    }
  },
  [types.CLOSE_CURRENT_CHAT](state, payload) {
    state.currentChat = payload;
    state.showChat = false;
    state.messages = [];
    state.users = [];
  },
  [types.OPEN_GROUP_CHAT](state, payload) {
    state.currentChat = setChatDataFormat(payload);
    state.joinMode = true;
    state.messages = [];
    state.pinned_messages = [];
    state.users = [];
  },
  [types.OPEN_CHAT](state, payload) {
    state.showChat = payload;
  },
  [types.SET_EDITOR_VALUE](state, payload) {
    state.editorValue = payload;
  },
  [types.SET_EDIT_CURRENT_CHAT](state, payload) {
    state.editCurrentChat = payload;
  },
  [types.SET_PASTE_FILE](state, payload) {
    if (state.files.length < 10) {
      state.files.push(payload);
      state.showFileModal = true;
    }
  },
  [types.CLOSE_FILE_MODAL](state, payload) {
    state.showFileModal = payload;
    state.files = [];
  },
  [types.DELETE_CURRENT_FILE](state, payload) {
    replaceArrayElement(state.files, payload);
  },
  [types.REPLACE_CURRENT_FILE](state, payload) {
    replaceArrayElement(state.files, payload.replaceFileId, true, payload.obj);
  },
  [types.UPDATE_MESSAGE](state, payload) {
    replaceArrayElement(state.messages, payload.id, true, payload);
    replaceArrayElement(state.pinned_messages, payload.id, true, payload);
    replaceArrayElement(state.threadsMessages, payload.id, true, payload);
    if (payload.user_id === store.getters.getUserId) reset.call(state);
  },
  [types.SEND_MESSAGE](state, payload) {
    if (payload.isVisible || payload.message.user_id === store.getters.getUserId) {
      state.lastSelfMessage = payload.message;
    }
    state.messages.push(payload.message);
    sortMessage(state.messages);
    if (payload.message.user_id === store.getters.getUserId) {
      reset.call(state);
    }
  },
  [types.SEND_MESSAGES](state, payload) {
    const isCurrentUserMessage = payload.messages.some(
      (item) => item.user_id === store.getters.getUserId
    );
    const length = payload.messages.length;
    const lastMessage = payload.messages[length - 1];
    if (payload.isVisible || isCurrentUserMessage) {
      state.lastSelfMessage = lastMessage;
    }
    state.messages = Array.from(new Set([...state.messages, ...payload.messages]));
    sortMessage(state.messages);
    if (isCurrentUserMessage) {
      reset.call(state);
    }
  },
  [types.SEND_MESSAGE_IN_THREAD](state, payload) {
    if (payload.isVisible || payload.message.user_id === store.getters.getUserId) {
      state.lastThreadMessage = payload.message;
    }
    state.threadsMessages.push(payload.message);
    sortMessage(state.threadsMessages);

    if (payload.message.user_id === store.getters.getUserId) {
      reset.call(state);
    }
  },
  [types.SEND_MESSAGES_IN_THREAD](state, { payload, isVisible }) {
    const isCurrentUserMessage = payload.messages.some(
      (item) => item.user_id === store.getters.getUserId
    );
    const length = payload.messages.length;
    const message = payload.messages[length - 1];
    const parentMessage = state.messages.find((item) => item.id === payload.parent_message_id);
    if (parentMessage) {
      parentMessage.has_thread_id = message.thread_id;
      if (message.user_id !== store.getters.getUserId && payload.thread_notification_enabled) {
        if (!parentMessage.thread) {
          parentMessage.thread = { count_unread_messages: 0 };
        }
        parentMessage.thread.count_unread_messages += 1;
      }
    }
    if (message.user_id !== store.getters.getUserId && payload.thread_notification_enabled) {
      const chat = state.chatList.find((item) => item.channel.id === payload.chat.id);
      if (chat) {
        chat.channel.count_unread_thread_messages += 1;
      }
    }
    if (state.threadMainMessage) {
      if (state.threadMainMessage.id === payload.parent_message_id) {
        state.threadMainMessage.count_unread_messages += 1;
        state.threadsMessages = Array.from(
          new Set([...state.threadsMessages, ...payload.messages])
        );
        sortMessage(state.threadsMessages);
        if (message.user_id === store.getters.getUserId || isVisible)
          state.lastThreadMessage = message;
      }
    }
    if (isCurrentUserMessage) {
      reset.call(state);
    }
  },
  [types.SWITCH_MODAL](state, payload) {
    state.showModal = payload.show;
    state.modalX = payload.x;
    state.modalY = payload.y;
    state.optionList = payload.optionList;
    state.container = payload.container;
    state.isLeft = payload.isLeft;
    if (payload.channelItem) {
      state.channelItem = payload.channelItem;
    }
    state.scrollContainer = payload.scrollContainer;
    if (payload.show) {
      state.scrollContainer.classList.add("stop-scrolling");
    } else {
      state.scrollContainer.classList.remove("stop-scrolling");
    }
    if (payload.message) {
      state.selectedMessage = payload.message;
    }
  },
  [types.SWITCH_TOOLTIP](state, payload) {
    state.isShowTooltip = payload.show;
    state.textTooltip = payload.textTooltip;
    state.modalX = payload.x;
    state.modalY = payload.y;
    state.container = payload.container;
  },
  [types.DELETE_CURRENT_MESSAGE](state, payload) {
    state.pinned_messages = state.pinned_messages.filter((item) => item.id !== payload.message.id);
    state.pickedMessage = state.pickedMessage.filter((item) => item.id !== payload.message.id);
    let message = state.messages.find((item) => item.id === payload.message.id);
    message = message
      ? message
      : state.threadsMessages.find((item) => item.id === payload.message.id);
    if (state.selectedMessage && state.selectedMessage.id === message.id) {
      document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
      state.selectedMessage = null;
      state.showModal = false;
    }

    if (state.currentMessage && state.currentMessage.id === message.id) {
      state.editMode = false;
      state.replyMode = false;
      state.currentMessage = null;
    }

    if (message && message.user_id === store.getters.getUserId) {
      state.pickMode = !!state.pickedMessage.length;
      state.editMode = false;
      state.replyMode = false;
      state.editorValue = "";
      state.showModal = false;
      document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
      state.currentMessage = null;
    }

    if (!state.pinned_messages.length) {
      state.showPin = false;
    }
    if (!state.pickedMessage.length) {
      state.pickMode = false;
    }
    replaceArrayElement(state.messages, +payload.message.id);
    replaceArrayElement(state.threadsMessages, +payload.message.id);
    const chat = state.chatList.find((item) => item.channel.id === payload.chat.id);

    if (chat.channel.last_message_id === payload.message.id) {
      chat.channel.last_message.text = "Сообщение удалено";
      chat.channel.last_message.user_id = 0;
    }
  },
  [types.EDIT_CURRENT_MESSAGE](state, payload) {
    document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
    state.showModal = false;
    state.editMode = true;
    state.replyMode = false;
    state.editorValue = payload.text;
    if (payload.files && payload.files.length) {
      state.showFileModal = true;
      state.files = payload.files;
    }
  },
  [types.CLOSE_EDIT_MESSAGE](state) {
    reset.call(state);
    state.pickedMessage = [];
  },
  [types.RESET_EDIT](state) {
    reset.call(state);
  },
  [types.REPLY_EDIT_MESSAGE](state) {
    state.showPin = false;
    state.editMode = true;
    state.replyMode = true;
    state.editorValue = "";
    document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
    state.showModal = false;
  },
  [types.SET_CURRENT_MESSAGE](state, payload) {
    state.currentMessage = payload;
  },
  [types.PIN_CURRENT_MESSAGE](state, payload) {
    state.showModal = false;
    document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
    const index = state.pinned_messages.findIndex((item) => item && item.id === payload.id);
    if (index === -1) {
      state.pinned_messages.push(payload);
    } else {
      state.pinned_messages.splice(index, 1);
      if (!state.pinned_messages.length) {
        state.showPin = false;
      }
    }
    const message = state.messages.find((item) => payload.id === item.id);
    if (message) message.is_pinned = payload.is_pinned;
    state.pinned_messages.sort((a, b) => a.id - b.id);
  },
  [types.SWITCH_CHAT_MENU](state, payload) {
    if (state.showComment || state.showReactionList) {
      state.showMenu = true;
      state.showComment = false;
      state.showReactionList = false;
    } else {
      if (payload === false) {
        state.showMenu = false;
      } else {
        state.showMenu = !state.showMenu;
      }
    }
    state.showReaction = false;
    if (!state.showMenu) {
      state.showComment = false;
    }
  },
  [types.UPLOAD_MESSAGE](state, payload) {
    state.messages = [...payload, ...state.messages];
    sortMessage(state.messages);
  },
  [types.OPEN_SEND_MESSAGE_MODAL](state, payload) {
    state.showModal = false;
    // state.showPin = false;
    state.showSendModal = !state.showSendModal;
    state.modalSetting = payload;
    // state.editorValue = "";
  },
  [types.SET_SEND_MODE](state) {
    state.sendMode = true;
    state.showSendModal = false;
  },
  [types.ADD_CHAT](state, payload) {
    const chat = createChat(payload.chat, store.getters.getUserId);
    if (payload.data.message) {
      chat.channel.last_message = payload.data.message;
    } else {
      chat.channel.last_message = payload.data.messages[payload.data.messages.length - 1];
    }
    state.chatList.unshift(chat);
    sortChat(state);
  },
  [types.UPDATE_CHAT](state, payload) {
    const chat = payload.chatList.find(
      (item) =>
        item.channel.id === payload.action.chat?.id || item.channel.id === payload.action.chat_id
    );
    const index = payload.chatList.findIndex(
      (item) =>
        item.channel.id === payload.action.chat?.id || item.channel.id === payload.action.chat_id
    );
    const updateChat = () => {
      payload.chatList.splice(index, 1);
      chat.channel.last_message = payload.action.message;
      chat.channel.last_message_id = payload.action.message.id;
      if (
        store.getters.getUserId !== payload.action.message.user_id &&
        payload.action.message.type !== "system" &&
        !payload.isVisible
      ) {
        chat.channel.count_unread_messages += 1;
        if (state.currentChat)
          state.scrollNewMessage = payload.action.chat.id === state.currentChat.channel.id;
      }
      payload.chatList.unshift(chat);
      sortChat(state);
    };
    const updateChatCreateMany = () => {
      payload.chatList.splice(index, 1);
      const length = payload.action.messages.length;
      const message = payload.action.messages[length - 1];
      chat.channel.last_message = message;
      chat.channel.last_message_id = message.id;
      if (
        store.getters.getUserId !== message.user_id &&
        message.type !== "system" &&
        !payload.isVisible
      ) {
        chat.channel.count_unread_messages += length;
        if (state.currentChat)
          state.scrollNewMessage = payload.action.chat.id === state.currentChat.channel.id;
      }
      payload.chatList.unshift(chat);
      sortChat(state);
    };
    switch (payload.action.action) {
      case "delete": {
        if (chat.channel.last_message_id === payload.action.message.id) {
          chat.channel.last_message.text = "Сообщение удалено";
          chat.channel.last_message.user_id = 0;
          // if (chat.channel.count_unread_messages > 0) {
          //   chat.channel.count_unread_messages -= 1;
          // }
        }
        break;
      }
      case "create": {
        updateChat();
        break;
      }
      case "create_many": {
        updateChatCreateMany();
        break;
      }
      case "moderatorDeleteUser": {
        updateChat();
        break;
      }
      case "moderatorAddUser": {
        updateChat();
        break;
      }
      case "selfUserLeave": {
        updateChat();
        break;
      }
      case "update": {
        if (payload.action.message.id === chat.channel.last_message_id) {
          chat.channel.last_message = payload.action.message;
        }
      }
    }
  },
  [types.UPDATE_CHAT_SETTING](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === state.channelItem.channel.id);
    chat.channel.notification_enabled = !!+payload.notification_enabled;
    chat.channel.is_pinned_chat = !!+payload.is_pinned_chat;
    if (state.currentChat && state.currentChat.channel.id === state.channelItem.channel.id) {
      state.currentChat.channel.notification_enabled = !!+payload.notification_enabled;
      state.currentChat.channel.is_pinned_chat = !!+payload.is_pinned_chat;
    }
    sortChat(state);
    state.scrollContainer.classList.remove("stop-scrolling");
    state.showModal = false;
  },
  [types.SHOW_NOTIFICATION](state, payload) {
    state.showNotification = payload.show;
    if (payload.newMessage) {
      state.unreadMessages.push({
        ...payload.newMessage
      });
      const length = state.unreadMessages.length;
      if (length > 5) {
        state.unreadMessages = state.unreadMessages.slice(length - 5, length);
      }
    } else {
      state.unreadMessages = [];
    }
  },
  [types.UPDATE_READ_STATUS](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === payload.chat_id);
    if (chat && state.currentChat && state.currentChat.channel.id === chat.channel.id) {
      chat.channel.count_unread_messages = 0;
      state.currentChat.channel.count_unread_messages = 0;
      chat.channel.last_read_message_id = +payload.message_id;
      state.currentChat.channel.last_read_message_id = +payload.message_id;
    }
  },
  [types.UPDATE_READ_USER_STATUS](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === payload.chat_id);
    chat.channel.is_read_companion = payload.message_id;
    if (chat && state.currentChat && state.currentChat.channel.id === payload.chat_id) {
      state.currentChat.channel.is_read_companion = payload.message_id;
    }
  },
  [types.SWITCH_PIN_MESSAGES](state, payload) {
    state.showPin = payload.show;
  },
  [types.CHANGE_CREATE_EDIT_CHANNEL](state, payload) {
    state.showCreateEditChannel = payload;
    state.showModal = false;
  },
  [types.WRITING_ACTION](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === payload.chat.id);
    if (chat) {
      chat.channel.is_writing = payload.is_writing;
    }
  },
  [types.UPDATE_MESSAGE_LIST](state, payload) {
    switch (payload.direction) {
      case "up": {
        if (state.threadMode) {
          state.threadsMessages = Array.from(
            new Set([...payload.messages, ...state.threadsMessages])
          );
          sortMessage(state.threadsMessages);
        } else {
          state.messages = Array.from(new Set([...payload.messages, ...state.messages]));
          sortMessage(state.messages);
        }
        // if (state.messages.length > 150) {
        //   state.messages.splice(state.messages.length - 50);
        // }
        break;
      }
      case "down": {
        if (state.threadMode) {
          state.threadsMessages = Array.from(
            new Set([...state.threadsMessages, ...payload.messages])
          );
          sortMessage(state.threadsMessages);
        } else {
          state.messages = Array.from(new Set([...state.messages, ...payload.messages]));
          sortMessage(state.messages);
        }

        // if (state.messages.length > 150) {
        //   state.messages = state.messages.slice(-152);
        // }
        break;
      }
      case "around": {
        break;
      }
      default: {
        break;
      }
    }
  },
  [types.READ_MESSAGE_WITH_SCROLL](state, payload) {
    state.scrollNewMessage = false;
  },
  [types.CHANGE_SHOW_CATALOG](state, payload) {
    state.showCatalog = payload;
  },
  [types.SWITCH_IMAGE](state, payload) {
    state.image = payload.file;
    state.showFullScreenImage = payload.show;
  },
  [types.RESET_CURRENT_CHAT](state) {
    state.currentChat = null;
    state.showChat = false;
  },
  [types.RESET_CHAT](state) {
    Object.assign(state, defaultState);
  },
  [types.SET_PICKED_MESSAGE](state, payload) {
    state.pickMode = true;
    const index = state.pickedMessage.findIndex((item) => item.id === payload.id);
    if (index === -1) {
      state.pickedMessage.push(payload);
    } else {
      state.pickedMessage.splice(index, 1);
      if (!state.pickedMessage.length) {
        state.pickMode = false;
      }
    }
    state.showModal = false;
    document.querySelector("#list-dynamic").classList.remove("stop-scrolling");
  },
  [types.CLOSE_PICKED_MESSAGE](state) {
    state.pickMode = false;
    state.pickedMessage = [];
  },
  [types.SET_SEND_SETTING](state, payload) {
    state.selectedSettingsEditor = payload;
  },
  [types.SET_NOTIFICATION_SETTING](state, payload) {
    state.isNotification = payload;
  },
  [types.SET_GROUP_SETTING](state, payload) {
    state.isGroupChat = payload;
  },
  [types.SWITCH_SETTINGS_MENU](state, payload) {
    state.showSetting = payload;
  },
  [types.ADD_TAG](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === payload?.chat_id);
    if (payload.tread_id === 0) chat.channel.tagged_last_message_id = payload?.message_id;
  },
  [types.SET_USERS](state, payload) {
    state.chats = payload.data.chats;
    if (payload.data.users.length) {
      state.chatUsers = payload.data.users.filter((item) => item.id !== store.getters.getUserId);
    } else {
      state.chatUsers = payload.data.users;
    }
  },
  [types.UPDATE_USERS](state, payload) {
    state.users = payload;
    state.showSendModal = false;
  },
  [types.ADD_MEMBER](state, payload) {
    const index = state.users.findIndex((item) => item.user_id === payload.user_id);
    if (index === -1) {
      state.users.push(payload);
    } else {
      state.users.splice(index, 1);
    }
    state.userList = state.users.map((item) => {
      return { ...item.info, nickname: `@${item.info.nickname}` };
    });
  },
  [types.UPDATE_CHAT_LIST](state, payload) {
    const length = payload.messages.length;
    const index = state.chatList.findIndex(
      (item) => item.channel.id === state.currentChat.channel.id
    );
    if (index === -1) {
      state.currentChat.channel.last_message = payload.messages[length - 1];
      state.chatList.unshift(state.currentChat);
      router.app.$router.replace({ query: { chat_id: state.currentChat.channel.id } });
    } else {
      state.messages = payload.messages;
      sortMessage(state.messages);
      state.lastSelfMessage = payload.messages[length - 1];
      state.currentChat.channel.last_message = payload.messages[length - 1];
      state.currentChat.channel.last_message_id = payload.messages[length - 1].id;
      state.currentChat.channel.chat_settings = payload.setting;
      state.chatList.splice(index, 1);
      state.chatList.unshift(state.currentChat);
    }
    sortChat(state);
    state.showSetting = false;
  },
  [types.LEAVE_CHANNEL](state, payload) {
    state.chatList = state.chatList.filter((item) => item.channel.id !== payload);
    if (state.currentChat && payload === state.currentChat.channel.id) {
      router.app.$router.replace({ query: {} });
      state.currentChat = null;
      state.showChat = false;
    }
    state.showModal = false;
    sortChat(state);
  },
  [types.CLEAR_CATALOG_CHANNELS](state) {
    state.catalogChannels = {
      count: 0,
      items: []
    };
  },
  [types.CHANGE_CATALOG_CHANNELS](state, payload) {
    state.catalogChannels = {
      count: payload.count,
      items: [...state.catalogChannels.items, ...payload.items]
    };
  },
  [types.CHANGE_CATALOG_MEMBERS](state, payload) {
    state.catalogMembers = payload;
  },
  [types.CHANGE_ATTACHED_MODAL_VALUE](state, payload) {
    state.attachedModalValue = payload;
  },
  [types.CHANGE_ATTACHED_FILES](state, payload) {
    state.attachedFiles = payload;
  },
  [types.CHANGE_VIDEO_PLAYER_DATA](state, payload) {
    state.videoPlayerData = payload;
  },
  [types.DELETE_USER](state, payload) {
    if (payload.isCurrent) {
      state.currentChat = null;
      router.app.$router.replace({ query: {} });
    }
    const index = state.chatList.findIndex((item) => item.channel.id === payload.data.chat_id);
    state.chatList.splice(index, 1);
  },
  [types.CHANGE_CATALOG_LOADER](state, payload) {
    state.catalogLoader = payload;
  },
  [types.SEARCH_MESSAGE_MODE](state, payload) {
    state.searchMessageMode = payload;
    state.searchMode = payload;
    state.search = "";
    state.searchMessages = [];
    state.closeChat = [];
    state.openChat = [];
    state.personChat = [];
    state.person = [];
  },
  [types.SET_SEARCH_RESULT](state, payload) {
    state.search = payload.text;
    state.searchMessages = payload.data;
    state.searchMessages.sort((a, b) => b.id - a.id);
  },
  [types.SHOW_NOTIFICATION_MODAL](state, payload) {
    state.showNotificationModal = payload.show;
    if (payload.show) {
      state.notificationData = {
        title: payload.title,
        text: payload.text,
        button: payload.button,
        type: payload.type
      };
    } else {
      state.notificationData = {};
    }
  },
  [types.UPDATE_USERS_LIST](state, payload) {
    if (payload.isDelete) {
      state.users = state.users.filter((item) => item.user_id !== payload.data.user_id);
    } else {
      const user = state.users.find((item) => item.user_id === payload.data.user_id);
      user.is_moderator = payload.data.is_moderator;
    }
  },
  [types.SHOW_NOTIFICATION_ACTION](state, payload) {
    state.notificationAction = payload;
  },
  [types.SET_CHAT_ITEM](state) {
    if (state.currentChat) {
      state.channelItem = state.currentChat;
    }
  },
  [types.SET_MESSAGE_FROM_SEARCH](state, payload) {
    state.messages = payload;
  },
  [types.SEARCH_RESULT_PERSON](state, payload) {
    state.searchMode = payload.show;
    state.search = payload.text;
    state.personChat = payload.data.chats;
    if (payload.data.users.length) {
      state.person = payload.data.users
        .filter((item) => item.id !== store.getters.getUserId)
        .map((item) => ({ companion: item, type: 1, id: null }));
    } else {
      state.person = payload.data.users;
    }
  },
  [types.SEARCH_RESULT_CLOSE](state, payload) {
    state.closeChat = payload.items;
  },
  [types.SEARCH_RESULT_OPEN](state, payload) {
    state.openChat = payload.items;
  },
  [types.SWITCH_MEMBER_LOADER](state, payload) {
    state.memberLoader = payload;
    state.showSendModal = true;
  },
  [types.UPDATE_UNREAD_MESSAGE_COUNT](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === state.currentChat.channel.id);
    if (state.threadMode) {
      const message = state.messages.find((item) => item.id === state.threadMainMessage.id);
      state.threadMainMessage.thread.count_unread_messages = 0;
      message.thread.count_unread_messages = 0;
      state.currentChat.channel.count_unread_thread_messages =
        state.currentChat.channel.count_unread_thread_messages - payload.count;
      chat.channel.count_unread_thread_messages =
        chat.channel.count_unread_thread_messages - payload.count;
    } else {
      state.currentChat.channel.count_unread_messages = 0;
      chat.channel.count_unread_messages = 0;
    }
  },
  [types.DELETE_ADMIN_CHANNEL](state, payload) {
    if (payload.isCurrent) {
      state.currentChat = null;
      state.showChat = false;
      router.app.$router.replace({ query: {} });
    }
    state.chatList = state.chatList.filter((item) => item.channel.id !== payload.id);
    sortChat(state);
  },
  [types.SWITCH_PIN](state, payload) {
    state.pinInChat = payload;
    if (!payload) {
      state.showPin = payload;
    }
  },
  [types.SWITCH_COMMENT](state, payload) {
    state.threadInChat = payload;
    if (!payload) {
      state.threadMode = payload;
    }
  },
  [types.SWITCH_TO_PINNED](state) {
    state.showModal = false;
    state.showPin = false;
  },
  [types.SET_PINNED_MESSAGE](state, payload) {
    state.pinned_messages = payload;
  },
  [types.SWITCH_REACTION_MODE](state, payload) {
    state.showReaction = !state.showReaction;
    state.showMenu = false;
    state.showModal = false;
  },
  [types.CLOSE_THREAD_MODE](state) {
    state.threadMode = false;
    state.threadMainMessage = null;
    reset.call(state);
  },
  [types.SWITCH_THREADS_LIST](state, payload) {
    state.showMenu = payload.show;
    state.showComment = payload.show;
    state.threads = payload.threads;
    state.showReaction = false;
    state.attachedCount = state.threads.length;
    if (state.threads.length) {
      state.threads.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    }
  },
  [types.SET_THREAD_MODE](state, payload) {
    state.threadMode = payload.show;
    state.pickedMessage = [];
    state.threadsMessages = payload.messages;
    sortMessage(state.threadsMessages);
    state.threadMainMessage = state.currentMessage;
    state.showPin = false;
    state.pickMode = false;
    state.showModal = false;
    reset.call(state);
    if (state.scrollContainer) state.scrollContainer.classList.remove("stop-scrolling");
  },
  [types.UPDATE_MESSAGE_REACTIONS](state, payload) {
    let message = state.messages.find((item) => item.id === payload.message.id);
    let pinMessage = state.pinned_messages.find((item) => item.id === payload.message.id);
    message = message
      ? message
      : state.threadsMessages.find((item) => item.id === payload.message.id);
    if (message) {
      message.reactions = payload.message.reactions;
    }
    if (pinMessage) {
      pinMessage.reactions = payload.message.reactions;
    }
  },
  [types.SET_USERS_REACTION](state, payload) {
    state.showMenu = payload.show;
    state.showReactionList = payload.show;
    state.showReaction = false;
    state.attachedCount = payload.count;
    state.reactionList = payload.reactionList;
    state.reactionId = payload.message_id;
  },
  [types.SET_UNREAD_MESSAGE](state, payload) {
    state.countUnreadMessage = payload;
  },
  [types.ADD_USERS_REACTION](state, payload) {
    state.countReaction = payload.count;
    state.reactionList = Array.from(new Set([...state.reactionList, ...payload.reactionList]));
  },
  [types.SET_UNREAD_MESSAGE](state, payload) {
    state.countUnreadMessage = payload;
  },
  [types.COMPARE_THREADS_MESSAGE](state, payload) {
    state.threads = Array.from(new Set([...state.threads, ...payload]));
    if (state.threads.length) {
      state.threads.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    }
  },
  [types.READ_THREAD_MESSAGE](state, payload) {
    const chat = state.chatList.find((item) => item.channel.id === payload.data.parent_chat_id);
    const parentMessage = state.messages.find(
      (item) => item.has_thread_id === payload.data.chat_id
    );
    if (chat) {
      chat.channel.count_unread_thread_messages =
        chat.channel.count_unread_thread_messages - payload.count;
    }
    if (parentMessage && parentMessage.thread) {
      parentMessage.thread.count_unread_messages =
        parentMessage.thread.count_unread_messages - payload.count;
    }
  },
  [types.DELETE_OPEN_THREAD](state) {
    state.threadMode = false;
    state.treadMainMessage = null;
  }
};

export default mutations;
