import router from "@/router";

import { domain } from "@/globalVariables";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

const actionsIspTeams = {
  getTeamsAction({ commit }, isArchived) {
    commit("TOGGLE_LOADER_TEAMS", true);

    httpClient({
      method: "GET",
      url: `/ISPGroup/actionGetItems`,
      params: {
        is_archived: isArchived,
        is_deleted: false,
        _order: { name: "asc" },
        _with: JSON.stringify(["memberUsers.roles", "leaderUser.roles"]),
        _count: 300
      }
    })
      .then((response) => {
        commit("SET_CURRENT_TEAMS", response.data.data.items);
        commit("TOGGLE_LOADER_TEAMS", false);
        // commit("SET_TOTAL_COUNT", response.data.data.total_count);
        // commit("SET_TEAMS_COUNTER", response.data.data.items.length);
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  // scrollGetTeamsAction({ commit, state }, isArchived) {
  //   commit("TOGGLE_LOADER_TEAMS_SCROLL", true);
  //
  //   httpClient({
  //     method: "GET",
  //     url: `/ISPGroup/actionGetItems`,
  //     params: {
  //       is_archived: isArchived,
  //       is_deleted: false,
  //       _with: JSON.stringify(["member_users", "leader_user"]),
  //       _from: state.teamsCounter + 1
  //     }
  //   })
  //     .then((response) => {
  //       commit("PUSH_TO_CURRENT_TEAMS", response.data.data.items);
  //       const teamsCounter = state.teamsCounter + response.data.data.items.length;
  //       commit("SET_TEAMS_COUNTER", teamsCounter);
  //       commit("TOGGLE_LOADER_TEAMS_SCROLL", false);
  //     })
  //     .catch((error) => {
  //       ErrorObserver.notification(error);
  //     });
  // },
  editTeamAction(_, { editedTeam, id }) {
    return httpClient({
      method: "PUT",
      url: `/ISPGroup/actionUpdate/${id}`,
      data: [editedTeam]
    }).catch((error) => {
      ErrorObserver.notification(error);
    });
  },
  getAllUsersAction({ commit }) {
    return httpClient({
      method: "GET",
      url: `/User/getItems`,
      params: {
        _with: JSON.stringify(["Roles"]),
        _count: 300
      }
    })
      .then((response) => {
        commit(
          "SET_ALL_USERS",
          response.data.data.items.filter((item) => item.is_active !== false)
        );
      })
      .catch((error) => {
        ErrorObserver.notification(error);
      });
  },
  createTeamAction(_, newTeam) {
    return httpClient({
      method: "POST",
      url: `/ISPGroup/actionCreate`,
      data: [newTeam]
    }).catch((error) => {
      ErrorObserver.notification(error);
    });
  },
  deleteTeamAction(_, id) {
    return httpClient({
      method: "DELETE",
      url: `/ISPGroup/actionDelete/${id}`
    }).catch((error) => {
      ErrorObserver.notification(error);
    });
  }
};

export default actionsIspTeams;
