

















































































































































































import { Component, Mixins } from "vue-property-decorator";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList";
import ChipsItem from "@/aopV2/components/UIComponents/Chips/ChipsItem.vue";
import InputDate from "@/aopV2/components/UIComponents/Input/Date/InputDate.vue";
import moment from "moment";
import InputTime from "@/aopV2/components/UIComponents/Input/Time/InputTime.vue";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import InputSelect from "@/aopV2/components/UIComponents/Input/Select/InputSelect.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import DefaultSwitch from "@/aopV2/components/UIComponents/Input/Check/Switch/DefaultSwitch.vue";
import CalendarWeekList from "@/aopV2/modules/Calendar/components/CalendarWeekList/CalendarWeekList.vue";
import InputNumber from "@/aopV2/components/UIComponents/Input/Number/InputNumber.vue";
import TextArea from "@/aopV2/components/UIComponents/Input/Text/TextArea/TextArea.vue";
import CalendarWeek from "@/aopV2/modules/Calendar/components/CalendarGrid/CalendarGridWeek/CalendarWeek.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList";
import MemberBlock from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/FSBlocks/MemberBlock.vue";
import BaseSection from "@/aopV2/components/BaseSection/BaseSection.vue";
import { dataToEventAdapter, dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import BasePicker from "@/aopV2/components/UIComponents/Picker/Day/BasePicker.vue";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import RepeatBlock from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/FSBlocks/RepeatBlock.vue";
import createEventMixin from "@/aopV2/modules/Calendar/helper/CreateEventMixin";
import InputFile from "@/aopV2/components/UIComponents/Input/File/InputFile";
import ViewEvent from "@/aopV2/modules/Calendar/components/Modal/ViewEvent/ViewEvent.vue";
import EventTooltip from "@/aopV2/modules/Calendar/components/Modal/Tooltip/EventTooltip.vue";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";

export type typeRepeat = {
  count: null | number;
  to_date: null | string;
  interval_type: string;
  step: number;
  days?: Array<number>;
};

@Component<CreateEventFS>({
  components: {
    EventTooltip,
    ViewEvent,
    MainButtonNew,
    RepeatBlock,
    Skeleton,
    BasePicker,
    MemberBlock,
    SelectList,
    CalendarWeek,
    TextArea,
    InputNumber,
    CalendarWeekList,
    InputFile,
    DefaultSwitch,
    InputSelect,
    TextLabel,
    InputTime,
    InputDate,
    ChipsItem,
    ActionList,
    ButtonNewDesign,
    BaseSection,
    TextInput,
    ButtonIcon,
    MainModal
  },
  watch: {
    selectFilterDate() {
      if (this.selectLocation[0] && this.selectLocation[0].id) {
        this.getWorking();
      }
      this.getEvent();
    }
  }
})
export default class CreateEventFS extends Mixins(createEventMixin) {
  isShowPicker: boolean = false;
  isShow: boolean = false;
  selectFilterDate: string | null = null;
  load: boolean = false;

  get isLoadUser() {
    return this.$store.state.isLoadUser;
  }

  mounted() {
    this.load = false;
    this.$store.commit("MainLayoutSetting/setSetting", {
      leftPanel: null,
      rightPanel: null,
      headerSlot: () =>
        import("@/aopV2/modules/Calendar/components/CalendarHeader/CreateEventHeader"),
      title: this.setTitle,
      tabs: []
    });
    if (this.isLoadUser) CalendarModule2.saveSelectedUsers([{ id: this.userId, full_name: "Мой" }]);
    this.selectFilterDate = this.selectDate;
    if (this.selectResponsible.length === 0) {
      this.selectResponsible = [{ id: this.userId }];
    }
    this.isFS = true;
    this.$nextTick(() => {
      this.selectRepeat = this.repeatList.find((item) => item.name === this.setEditRepeatType);
    });
    CalendarModule2.toggleViewModal(false);
  }

  get setTitle() {
    if (this.id) {
      return this.isMobileWindowSize ? "Редактирование" : "Редактирование мероприятия";
    } else {
      return this.isMobileWindowSize ? "Создание" : "Создание мероприятия";
    }
  }

  get isCreateLoader() {
    return CalendarModule2.isCreateLoader;
  }
  async saveEvent() {
    CalendarModule2.toggleCreateLoader(true);
    await CalendarModule2.changeEvent(this.createData);
    CalendarModule2.toggleCreateLoader(false);
  }

  get isShowViewEvent() {
    return CalendarModule2.isShowViewEvent;
  }
  get buttonTitle() {
    return this.createData.id ? "Сохранить" : "Создать";
  }

  get isShowTooltip() {
    return CalendarModule2.isShowTooltip;
  }

  uploadFile(ids: number[]) {
    this.uploadFiles = ids;
  }

  get isDisabledPrivate() {
    return this.currentType.category_name === this.activityCategories.name;
  }

  get activityCategories() {
    return CalendarModule2.eventTypeCategories.length
      ? CalendarModule2.eventTypeCategories.find((item) => item.name === "Активность")
      : null;
  }

  get setEditRepeatType() {
    if (this.repeat) {
      if (this.repeat.interval_type === "day" && this.repeat.step === 1) {
        return `Ежедневно`;
      }
      if (
        this.repeat.interval_type === "week" &&
        this.repeat.step === 1 &&
        this.repeat.days?.length === 1
      ) {
        return `Еженедельно`;
      }
      if (
        this.repeat.interval_type === "week" &&
        this.repeat.step === 1 &&
        JSON.stringify(
          this.repeat?.days?.sort(function (a, b) {
            return a - b;
          })
        ) === JSON.stringify([1, 2, 3, 4, 5])
      ) {
        return `По будням`;
      }

      if (this.repeat.interval_type === "month" && this.repeat.step === 1) {
        return `Ежемесячно`;
      }
      if (this.repeat.interval_type === "year" && this.repeat.step === 1) {
        return `Ежегодно`;
      }
      return "Другое";
    } else {
      return "Не повторять";
    }
  }

  handleCalendar() {
    this.isShowPicker = !this.isShowPicker;
  }

  handleLeft() {
    this.selectFilterDate = dateFormat(moment(this.selectFilterDate).subtract(1, "day"));
  }

  handleRight() {
    this.selectFilterDate = dateFormat(moment(this.selectFilterDate).add(1, "day"));
  }

  handleFilterDate(date) {
    this.selectFilterDate = dateFormat(date);
    this.closePicker();
  }

  closePicker() {
    this.isShowPicker = false;
  }

  get editEventId() {
    return CalendarModule2.editEventId;
  }

  get minRepeatDate() {
    const date = new Date(this.selectDate);
    return date.setDate(date.getDate() + 1);
  }

  async getEvent() {
    let usersInGroup = this.selectGroups.map((item) => item.users);
    usersInGroup = Array.from(
      new Set([...usersInGroup.flat(1), ...this.selectMembers.map((item) => item.id)])
    );
    const users = [...this.selectResponsible.map((item) => item.id), ...usersInGroup];
    await CalendarModule2.getEvents({
      timezone: this.timezone,
      is_accepted_invitation: [true, null],
      date_to: this.selectFilterDate!,
      date_from: this.selectFilterDate!,
      users
    });
    CalendarModule2.createPreCreationEvent(dataToEventAdapter(this.createData));
    this.load = true;
  }

  changeLocation(location) {
    this.selectLocation = location;
    this.getWorking();
  }

  getWorking() {
    CalendarModule2.getWorkingEvents({
      date_from: this.selectFilterDate!,
      date_to: this.selectFilterDate!,
      timezone: this.timezone,
      locations: this.selectLocation.map((item) => item.id)
    });
  }

  setRepeat(repeat) {
    this.repeat = repeat;
  }

  get locationEvents() {
    return CalendarModule2.workingEvents;
  }

  changeResponsible(responsible) {
    this.selectResponsible = responsible;
    this.getEvent();
  }

  changeMembers(members) {
    this.selectMembers = members;
    this.getEvent();
  }

  changeGroups(groups) {
    this.selectGroups = groups;
    this.getEvent();
  }

  get events() {
    return CalendarModule2.events;
  }

  get allEvents() {
    return setUniqueArray(this.events.concat(this.locationEvents), "id");
  }

  get grid() {
    return [
      {
        date: moment(this.selectFilterDate).format("YYYY-MM-DD"),
        weekDay: 0,
        isDisabled: false,
        events: this.allEvents.filter(
          (event) =>
            dateFormat(new Date(event.started_at)) ===
            moment(this.selectFilterDate).format("YYYY-MM-DD")
        )
      }
    ];
  }

  get selectedDateFormat() {
    return moment(this.selectFilterDate).format("DD MMMM YYYY");
  }

  get currentUser() {
    const user = this.$store.getters["UsersModule/userById"](this.userId) as Array<any>;
    return user.length ? user[0] : this.userId;
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }
  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }
  get isMobileWindowSize() {
    return this.getWidthWindow < 1024;
  }
}
