<template>
  <div class="main-switch">
    <LayoutMain
      v-if="!isNewLayout && !loaderUserInfo"
      :loaderUserInfo="loaderUserInfo"
      :userInfo="userInfo"
      :isNewDesign="isNewDesign"
      @getIndex="getIndex"
    />
    <LayoutMainNew
      v-if="isNewLayout && !loaderUserInfo"
      :isNewDesign="isNewDesign"
      :userInfo="userInfo"
    />
    <Loader v-if="loaderUserInfo" class="loader" />
  </div>
</template>

<script>
import LayoutMainNew from "@/aopV2/layout/LayoutMain/LayoutMainNew";
import LayoutMain from "@/layout/LayoutMain";
import axios from "axios";
import { startRoutePage } from "@/globalVariables";
import { EventBus } from "@/main";
import Cookies from "vue-cookie";
import { mapActions, mapState } from "vuex";
import UserService from "@/model/User/UserService";
import httpClient from "@/api/client/httpClient";
import centrifuge from "@/centrifuge";
import { updateTimer } from "@/modules/TimeTracker/helpers/createBroadcast";
import Loader from "@/components/Widgets/Loader2.0/Loader";

export default {
  name: "MainSwitch",
  mixins: [],
  props: {},
  components: { LayoutMainNew, LayoutMain, Loader },
  data() {
    return {
      // showModal: null,
      windowWidth: 0,
      showSyncAddModal: false,
      loaderUserInfo: true,
      messengerStatus: null,
      register: null,
      messengerStatusSecret: null,
      messengerStatusSecret2: null,
      messengerStatusSecret3: null,
      userInfo: null,
      showSyncInfoModal: false
    };
  },
  created() {
    if (this.$cookie.get("mandate")) {
      axios.defaults.headers["Authorization"] = `bearer ${this.$cookie.get("mandate")}`;
      this.getMeta();
      let path = window.location.pathname;
      if (path === "/") {
        this.$router.push(startRoutePage);
      }
    }
    this.getUsers();
    window.addEventListener("resize", this.resizeWindow);
  },
  mounted() {
    if (localStorage.getItem("isUpdated") === "update") {
      setTimeout(() => {
        EventBus.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: "Система успешно обновлена!"
        });
        localStorage.removeItem("isUpdated");
      }, 300);
    }
    this.getUserInfo();

    this.$root.$on("actionItem:handleSync", () => {
      this.handleSyncInfoModal(true);
    });
    this.windowWidth = window.innerWidth;
  },

  beforeDestroy() {
    this.$root.$off("actionItem:handleSync");
    this.resetAllSetting();
    this.messengerStatusSecret.unsubscribe();
    this.messengerStatusSecret2.unsubscribe();
    this.messengerStatusSecret3.unsubscribe();
    if (this.register) this.register.unregister().then(function (boolean) {});
  },
  destroyed() {
    window.localStorage.clear();
    Cookies.delete("my-isp-setting");
    Cookies.delete("archive-isp-setting");
    Cookies.delete("quarter-isp-setting");
  },
  methods: {
    ...mapActions(["getMeta"]),
    ...mapActions("UsersModule", ["getUsers"]),
    ...mapActions("MessengerModule", [
      "responseMessage",
      "getUserSetting",
      "getSetting",
      "resetAllSetting"
    ]),
    async getUserInfo() {
      const user = await UserService.current();

      const userData = await UserService.actionGetItem(user.id, {
        _with: JSON.stringify(["roles"])
      });
      user.position = userData.data.data.roles;
      this.$store.commit("changeUserId", user.id);
      this.$store.commit("changeDataInfoUser", user);
      this.$root.$emit("goGetInfoUser");
      this.userInfo = user;
      this.loaderUserInfo = false;
      this.subscribeChannelMessenger();
      await this.subscribeFB();
      this.getSetting().then((resp) => {
        this.getUserSetting(resp);
        this.getNotificationList();
      });
    },
    async getNotificationList() {
      const payload = {
        page: 1
      };
      await this.$store.dispatch("Notification/getNotificationsAction", payload);
    },
    getIndex() {
      window.location.reload(true);
      localStorage.setItem("isUpdated", "update");
    },
    getFBToken() {
      this.$messaging
        .getToken({
          vapidKey:
            "BAF1_BbYfuEoK83ALFa7FKyhdiLQCgP8k_DG5SeyHTREYLXb29aKLrgHKipCQEPKIDRjsfsl_Wtau9NLrw0gsGA"
        })
        .then((token) => {
          this.token = token;
          const oldToken = localStorage.getItem("device_token");
          if (token !== oldToken) {
            localStorage.setItem("device_token", token);
            Promise.all([
              httpClient({
                method: "POST",
                url: `UserDevice/actionCreate`,
                data: [
                  {
                    device_token: token,
                    user_id: this.getUserId
                  }
                ]
              }),
              oldToken
                ? httpClient({
                    method: "DELETE",
                    url: `UserDevice/actionDelete`,
                    data: [
                      {
                        device_token: oldToken,
                        user_id: this.getUserId
                      }
                    ]
                  })
                : null
            ]).catch((err) => {});
          }
        })
        .catch((err) => {});
    },
    async subscribeFB() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          this.register = await navigator.serviceWorker.register(`../../firebase-messaging-sw.js`);
          await navigator.serviceWorker.ready;
          this.getFBToken();
        } else {
          console.warn("Service Worker not available");
        }
      } else {
        console.warn("Unable to get permission to notify.");
      }
      this.$messaging.onMessage((message) => {
        this.message = message;
      });
    },
    subscribeChannelMessenger() {
      this.messengerStatusSecret = centrifuge.subscribe(
        `$chat@user.${this.getUserId}`,
        (message) => {
          this.responseMessage(message.data);
        }
      );
      this.messengerStatusSecret2 = centrifuge.subscribe(`$user@${this.getUserId}`, (message) => {
        if (message.data.type === "time") {
          updateTimer(JSON.stringify(message.data.data));
        } else if (message.data.type === "limit") {
          new Notification(`Достигнут недельный лимит`, {
            body: "Таймер остановлен!",
            icon: "/favicon.ico",
            tag: "limit"
          });
          updateTimer(JSON.stringify({ ...message.data.data, isActive: false }));
        } else if (message.data.type === "half-limit") {
          new Notification(`До конца недельного лимита осталось 30 минут`, {
            icon: "/favicon.ico",
            tag: "limit"
          });
        } else if (message.data.type === "notification") {
          this.$store.commit("Notification/addNewNotification", message.data.data);
          new Notification(`${message.data.data.message}`, {
            icon: "/notification.ico",
            tag: "notification"
          });
        }
      });
      this.messengerStatusSecret3 = centrifuge.subscribe(
        `timemanager.${this.getUserId}`,
        (message) => {
          if (message.data.data.type === "time-stop") {
            const module = require("@/modules/TimeTracker/store").default;
            const task = module.activeTask;
            const project = module.activeProject;
            if (module.activeTask && module.activeTask.id === message.data.data.task) {
              new Notification(`Задача остановлена!`, {
                body: `${task.name}`,
                icon: "/favicon.ico",
                tag: "time"
              });
              updateTimer(JSON.stringify({ isActive: false, task, project }));
            }
          }
        }
      );
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    handleSyncInfoModal(value) {
      this.showSyncInfoModal = value;
    },
    handleSyncAddModal(value) {
      this.showSyncAddModal = value;
    }
  },
  computed: {
    ...mapState(["isShowContextMenu", "showModalNewVersion"]),
    mobileSize() {
      return this.windowWidth <= 768;
    },

    getUserId() {
      return this.$store.getters.getUserId;
    },
    isNewDesign() {
      return this.$route.matched.some((item) =>
        [
          "ISP",
          "IspProblem",
          "News",
          "Messenger",
          "Calendar",
          "TaskManager",
          "WorkTime",
          "Notification",
          "Plan"
        ].includes(item.name)
      );
    },
    isNewLayout() {
      return this.$route.matched.some((item) => ["Calendar"].includes(item.name));
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.main-switch {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.loader {
  margin: auto;
}
</style>
