<template>
  <div class="profile__user-data-modal user-data-modal" @click.self="$emit('hideModal')">
    <div class="user-data-modal__window">
      <div class="user-data-modal__header">
        <i class="icon-sort-arrow" @click="$emit('hideModal')"></i>
        <h2 class="user-data-modal__title">Редактировать данные</h2>
        <i class="icon-cross" @click="$emit('hideModal')"></i>
      </div>
      <div class="user-data-modal__main">
        <div class="user-data-modal__user">
          <FileUploadChannelAvatar
            v-bind="{
              multiple: false,
              drop: false,
              dropDirectory: false,
              fileDrop: false
            }"
            :editValue="returnSrcFile(dataUser.avatar)"
            @changeIconChanel="changeAvatar"
          />

          <div class="user-data-modal__user-name">
            <div class="user-data-modal__edit-full-name">
              <Input2
                :label="'Фамилия'"
                class="user-data-modal__surname"
                :editValue="editSurname"
                @textChanged="setEditSurname"
              />
              <Input2
                :label="'Имя'"
                class="user-data-modal__name"
                :editValue="editName"
                @textChanged="setEditName"
              />
            </div>
            <!--            <p class="user-data-modal__name">{{ dataUser.full_name }}</p>-->
            <p class="user-data-modal__active">{{ setActiveStatus }}</p>
          </div>
        </div>
        <div class="user-data-modal__wrap">
          <Widget
            :type="'input-new'"
            :editValue="editPhone"
            :unique="'editUserCardPhoneNumber'"
            :mask="numberMask"
            :label="'Номер телефона'"
          />
        </div>
        <div class="user-data-modal__wrap">
          <Widget
            type="InputDatePicker"
            :displayFormat="'DD.MM.YYYY'"
            :label="'День рождения:'"
            :editValue="editDateBirth"
            :unique="'editUserCardDateBirth'"
          />
        </div>
        <div class="user-data-modal__wrap">
          <SelectPicker
            :optionData="optionDataCoefficient"
            :placeholder="'Ставка'"
            :keyValue="'editUserCardCoefficient'"
            :editValue="editCoefficient"
          />
        </div>
        <div class="user-data-modal__wrap">
          <SelectPicker
            :optionData="optionDataDismissal"
            :placeholder="'Статус'"
            :keyValue="'editUserCardActive'"
            :editValue="editActive"
          />
        </div>
        <div class="user-data-modal__wrap" v-if="!editActive[0].id">
          <Widget
            type="InputDatePicker"
            :displayFormat="'DD.MM.YYYY'"
            :label="'Дата увольнения:'"
            :editValue="editDismissal"
            :unique="'editUserCardDismissal'"
          />
        </div>
        <div class="user-data-modal__black-sphere">
          <div class="user-data-modal__wrap user-data-modal__wrap_black-sphere">
            <p class="user-data-modal__subtitle">Черный шар</p>
            <SwitchWidget
              :editValue="dataUser.has_black_sphere"
              :unique="'editUserCardBlackSphere'"
            />
          </div>
          <div class="user-data-modal__wrap user-data-modal__wrap_date">
            <p class="user-data-modal__subtitle">Дата подключения</p>
            <Widget
              type="InputDatePicker"
              :displayFormat="'DD.MM.YYYY'"
              :editValue="editDateSphere"
              :unique="'editUserCardDateSphere'"
            />
          </div>
        </div>
      </div>
      <div class="user-data-modal__footer">
        <button
          class="user-data-modal__button user-data-modal__button_cancel"
          type="button"
          @click="$emit('hideModal')"
        >
          Отменить
        </button>
        <button class="user-data-modal__button" type="button" @click="updateUserInfo">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchWidget from "@/components/Widgets/SwitchWidget/SwitchWidget";
import formatDateAndTime from "@/helpers/formatDateAndTime";

import { domain } from "@/globalVariables";
import Widget from "@/components/Widgets/Widget";
import SelectPicker from "@/components/Widgets/Select/SelectPicker/SelectPicker";
import FileUploadChannelAvatar from "@/components/Widgets/FileUploader/FileUploadChannelAvatar";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import { domainStorage } from "@/globalVariables";
import Input2 from "@/components/Widgets/Inputs/Input2.0";
import UserService from "@/model/User/UserService";
import httpClient from "@/api/client/httpClient";
export default {
  name: "ProfileEditData",
  mixins: [formatDateAndTime, uploadFilesMixin],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    FileUploadChannelAvatar,
    Widget,
    SwitchWidget,
    SelectPicker,
    Input2
  },
  data() {
    return {
      editName: "",
      editSurname: "",
      editAvatar: "",
      editPhone: "",
      editDateBirth: "",
      editSphere: "",
      editDateSphere: "",
      editCoefficient: "",
      editActive: "",
      editDismissal: "",
      numberMask: {
        mask: "+7 (999) 999-99-99"
      },
      optionDataCoefficient: [
        {
          id: "1",
          name: "Полная"
        },
        {
          id: "0.5",
          name: "Не полная"
        }
      ],
      optionDataDismissal: [
        {
          id: true,
          name: "Активный"
        },
        {
          id: false,
          name: "Уволен"
        }
      ]
    };
  },
  beforeDestroy() {
    this.$root.$off("text:change_editUserCardPhone");
    this.$root.$off("date:change_profileDatePick");
    this.$root.$off("date:change_editUserCardDateSphere");
    this.$root.$off("switch:change_editUserCardBlackSphere");
    this.$root.$off("changeSelectValue:editUserCardActive");
    this.$root.$off("date:change_editUserCardDismissal");
  },
  mounted() {
    this.setEditData();
    this.$root.$on("text:change_editUserCardPhoneNumber", (value) => {
      this.editPhone = value;
    });
    this.$root.$on("date:change_editUserCardDateBirth", (value) => {
      this.editDateBirth = value;
    });
    this.$root.$on("date:change_editUserCardDateSphere", (value) => {
      this.editDateSphere = value;
    });
    this.$root.$on("switch:change_editUserCardBlackSphere", (value) => {
      this.editSphere = value;
    });
    this.$root.$on("changeSelectValue:editUserCardActive", (value) => {
      this.editActive = value;
    });
    this.$root.$on("date:change_editUserCardDismissal", (value) => {
      this.editDismissal = value;
    });
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    /**
     * Setting existing user data for editing
     * */
    setEditData() {
      this.editSurname = this.dataUser.full_name.split(" ")[0];
      this.editName = this.dataUser.full_name.split(" ")[1];
      this.editAvatar = JSON.parse(JSON.stringify(this.dataUser.avatar));
      this.editPhone = JSON.parse(JSON.stringify(this.dataUser.phone));
      this.editDateBirth = JSON.parse(JSON.stringify(this.dataUser.date_of_birth));
      this.editSphere = JSON.parse(JSON.stringify(this.dataUser.has_black_sphere));
      this.editDateSphere = JSON.parse(JSON.stringify(this.dataUser.date_of_include_black_sphere));
      this.editDismissal = JSON.parse(JSON.stringify(this.dataUser.date_of_dismissal));
      if (JSON.parse(JSON.stringify(this.dataUser.rate_coefficient)) === "1") {
        this.editCoefficient = [
          {
            id: "1",
            name: "Полная"
          }
        ];
      } else {
        this.editCoefficient = [
          {
            id: "0.5",
            name: "Не полная"
          }
        ];
      }
      if (JSON.parse(JSON.stringify(this.dataUser.is_active)) === true) {
        this.editActive = [
          {
            id: true,
            name: "Активный"
          }
        ];
      } else {
        this.editActive = [
          {
            id: false,
            name: "Уволен"
          }
        ];
      }
    },
    /**
     * User information editing function
     * */
    updateUserInfo() {
      if (!this.checkValidationData()) return false;
      const data = [
        {
          name: this.editName.trim(),
          surname: this.editSurname.trim(),
          rate_coefficient: this.editCoefficient[0].id,
          avatar: this.editAvatar,
          phone: this.editPhone,
          date_of_birth: this.editDateBirth,
          is_active: this.editActive[0].id,
          date_of_dismissal: !this.editActive[0].id
            ? this.editDismissal
              ? this.editDismissal
              : null
            : null,
          has_black_sphere: this.editSphere,
          date_of_include_black_sphere: this.editSphere
            ? this.editDateSphere
              ? this.editDateSphere
              : null
            : null
        }
      ];
      UserService.update(this.dataUser.id, data)
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Данные пользователя успешно изменены!`
          });
          this.$emit("goGetInfoUser");
          this.$emit("hideModal");
        })
        .catch((error) => this.errorAlert(error));
    },
    /**
     * User data validation function
     */
    checkValidationData() {
      if (!this.editDateBirth) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите день рождения!`
        });
        return false;
      }

      if (this.editPhone && this.editPhone.indexOf("_") !== -1) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Номер телефона введен некорректно!`
        });
        return false;
      }
      if (!this.editActive[0].id && !this.editDismissal) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите дату увольнения сотрудника!`
        });
        return false;
      }
      if (this.editSphere && !this.editDateSphere) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите дату включения ЧШ у сотрудника!`
        });
        return false;
      }
      return true;
    },
    /**
     * User avatar change function
     * @param file - file avatar
     */
    changeAvatar(file) {
      this.fileUploader = file;
      this.createChunksPoolFiles();
    },
    async createChunksPoolFiles() {
      if (!this.fileUploader.length) {
        return null;
      }
      this.createPull(this.fileUploader);
    },
    createPull(arr) {
      if (!arr.length) {
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].file_name
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.chunk_pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      let base64 = arr[0].file.replace(arr[0].file.substring(0, arr[0].file.search(",") + 1), "");
      this.chunksArr = base64.match(/.{1,500000}/g);

      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: "POST",
        data: [this.pullName]
      })
        .then((response) => {
          this.editAvatar = response.data.data.path;
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    setEditSurname(val) {
      this.editSurname = val;
    },
    setEditName(val) {
      this.editName = val;
    }
  },
  computed: {
    setActiveStatus() {
      return this.dataUser.is_active ? "Работает" : "Не работает";
    },
    setRoles() {
      return this.dataUser.roles.length ? this.dataUser.roles[0].name : "-";
    },
    setDepartment() {
      return this.dataUser.departments.length ? this.dataUser.departments[0].name : "-";
    },
    setGrade() {
      return this.dataUser.levels.length ? this.dataUser.levels[0].name : "ACADEMY";
    },
    setRate() {
      return this.dataUser.rate_coefficient === "1" ? "Полная" : "Не полная";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.user-data-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 1000;

  &__window {
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    width: 716px;

    @media (max-width: $md) {
      width: 500px;
    }
    @media (max-height: 700px) {
      overflow: auto;
      max-height: 90%;
    }
    @media (max-width: $sm) {
      max-height: unset;
      width: 100vw;
      height: 100vh;
      background: $surface-default;
      z-index: 1000000;
      border-radius: 0;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 24px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      justify-content: flex-start;
    }

    i {
      font-size: 22px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-sort-arrow {
      transform: rotate(90deg);
      margin-right: 20px;

      @media (max-width: $sm) {
        display: block;
      }
    }
    .icon-cross {
      margin-left: auto;
      @media (max-width: $sm) {
        display: none;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: $text-body-dark;
  }
  &__edit-full-name {
    display: flex;
    width: 100%;
    /deep/ {
      .form-group__input {
        width: 100%;
      }
    }
  }
  &__surname {
    margin-right: 12px;
  }
  &__main {
    padding: 24px 24px 0;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 80vh;
    }

    @media (max-width: $sm) {
      overflow-y: scroll;
      max-height: 90vh;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 75vh;
    }
  }

  &__wrap {
    width: 100%;
    margin: 0 0 24px;

    /deep/ {
      .switch-widget__indicator {
        cursor: default;
      }

      .vdpComponent.vdpWithInput input {
        width: 100%;
        font-size: 14px;
        line-height: 28px;
        color: $text-disabled;
        border: none;
        outline: none;
      }

      .select-bg {
        width: 100%;
      }

      .select-bg .select-content-bg .select-content {
        justify-content: flex-start;
      }

      .select-bg .select-content-bg .select-content .icon-cross {
        font-size: 21px;
        margin-left: auto;
      }

      .data-picker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 48px;
        padding: 12px 18px;
        border-radius: 24px;
        cursor: pointer;
        background: #fafbfc;

        &__label {
          white-space: nowrap;
          color: $text-subdued;
        }
      }

      .vdpCell.outOfRange .vdpCellContent {
        color: $text-disabled !important;
      }

      .vdpComponent {
        width: 100%;
      }

      .vdpClearInput {
        background: #fafbfc;

        &::before {
          box-shadow: none;
          background: #fafbfc;
          top: 50% !important;
          left: 70% !important;
        }
      }
    }

    &_black-sphere {
      width: 40%;
    }

    &_date {
      /deep/ {
        .data-picker {
          height: 30px;
          padding: 2px 18px;
        }

        .vdpClearInput {
          height: 30px;

          &::before {
            top: 60% !important;
            left: 70% !important;
          }
        }
      }
    }
  }

  &__black-sphere {
    display: flex;
  }

  &__user {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    width: 100%;

    img {
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
      object-fit: cover;
    }
  }

  &__name {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
  }

  &__active {
    margin-top: 4px;
    margin-left: 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__content {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    font-feature-settings: "salt" on;
    color: $text-default;
  }

  &__black-spheres-date {
    margin: 0;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: $bg-surface-light;
    border-radius: 12px;
  }

  &__footer {
    border-top: 1px solid $border-disabled;
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 32px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }

    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
