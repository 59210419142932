import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      pullName: "",
      loading: false,
      chunksArr: [],
      fileUploader: [],
      oldEditFiles: [],
      fileUploadingId: [],
      uploadedFiles: [],
      formats: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/x-flv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/mp4",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/x-msvideo",
        "video/3gpp",
        "video/mpeg",
        "audio/mpeg"
      ]
    };
  },
  methods: {
    uploadFile(value) {
      let size = 0;
      this.files = value.filter((item) => {
        if (item.size > 5242880) {
          EventBus.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Размер файла ${item.name} превышает 5 мб`
          });
          return false;
        }
        size += item.size;

        if (item.extension) {
          return true;
        }
        if (item.file.type) {
          if (this.formats.includes(item.file.type)) {
            return true;
          } else {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Формат файла ${item.file.type} не поддерживается`
            });
            this.loading = false;
            return false;
          }
        }
        return false;
      });
      if (size > 5242880) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Общий размер файлов превышает 5 мб`
        });
        this.files = this.task.attachment_files;
        this.loading = false;
      }
      this.uploadHandler(this.files);
    },
    uploadHandler(files) {
      this.fileUploader = [];
      this.oldEditFiles = [];
      this.fileUploadingId = [];
      this.uploadedFiles = [];
      files.forEach((item) => {
        if (!item.link) {
          item.label = item.name;
          this.fileUploader.push(item);
        }
        if (item.link) {
          this.oldEditFiles.push(item);
        }
      });
      if (this.fileUploader.length) {
        this.changeLoading(true);
        this.createPull(this.fileUploader);
      }
    },
    filesUploaded() {
      this.changeLoading(false);
      this.handleEditFiles({
        ids: [...this.oldEditFiles.map((item) => item.id), ...this.fileUploadingId],
        files: [...this.oldEditFiles, ...this.uploadedFiles]
      });
    },
    handleErrorUpload() {},
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPull(arr) {
      if (!arr.length) {
        this.filesUploaded();
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].name
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.chunk_pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.changeLoading(false);
          this.errorAlert(error);
          this.handleErrorUpload();
          this.$emit("stop_loading_in_comments");
        });
      this.changeLoading(false);
    },
    async createChunks(arr) {
      await this.getBase64(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArr = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArr = base64.match(/.{1,5000000}/g);
        }
        // TODO: Если файлы большие
        // this.chunksArr = base64.match(/.{1,5000000}/g)
      });
      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.changeLoading(false);
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.changeLoading(false);
            this.loading = false;
            this.errorAlert(error);
            this.handleErrorUpload();
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/AttachmentFile/uploadFromChunkPool`,
        method: "POST",
        data: [
          {
            type: "file",
            label: arr[0].name,
            extension: arr[0].type
          },
          this.pullName
        ]
      })
        .then((response) => {
          this.uploadedFiles.push(response.data.data);
          this.fileUploadingId.push(response.data.data.id);
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.changeLoading(false);
          this.loaderForm = false;
          this.handleErrorUpload(arr);
          this.errorAlert(error);
          this.$emit("stop_loading_in_comments");
        });
    },
    changeLoading(val) {
      this.loading = val;
    }
  }
};
