<template>
  <div class="vacation-requests">
    <VacationRequestTable
      :userId="getUserId"
      :isAdmin="isAdmin"
      :scrollLoading="scrollLoading"
      :vacationPendingRequest="vacationPendingRequest"
      :absenceVacationRequest="absenceVacationRequest"
      :transferOrderRequest="transferOrderRequest"
      :myVacation="myVacation"
    />
  </div>
</template>

<script>
import VacationRequestTable from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/VacationRequestTable";

import { domain } from "@/globalVariables";
import { isObjectEmptyChecker } from "@/helpers/isObjectEmptyChecker";
import httpClient from "@/api/client/httpClient";
export default {
  name: "VacationRequests",
  mixins: [],
  props: [],
  components: { VacationRequestTable },
  data() {
    return {
      isAdmin: false,
      scrollLoading: false,
      vacationPendingRequest: [],
      absenceVacationRequest: [],
      transferOrderRequest: [],
      myVacation: []
    };
  },
  beforeDestroy() {
    this.$root.$off("goGetInfoUser");
    this.$root.$off("updateVacationRequestTable");
  },
  mounted() {
    if (isObjectEmptyChecker(this.getDataInfoUser)) {
      this.$root.$on("goGetInfoUser", () => {
        if (this.$route.params.user_id) {
          this.getUserData(this.$route.params.user_id);
        } else {
          this.getUserData(this.getDataInfoUser.id);
        }
      });
    } else {
      if (this.$route.params.user_id) {
        this.getUserData(this.$route.params.user_id);
      } else {
        this.getUserData(this.getDataInfoUser.id);
      }
    }
    // this.$root.$emit("goGetInfoUser");
    this.$root.$on("updateVacationRequestTable", () => {
      if (this.$route.params.user_id) {
        this.updateTableData(this.$route.params.user_id);
      } else {
        this.updateTableData(this.getDataInfoUser.id);
      }
    });
  },
  methods: {
    getUserData(id) {
      this.scrollLoading = true;
      // this.isAdmin = this.getDataInfoUser.roles.some((item) => item === "vacation_admin");
      this.getTableData(id);
      this.scrollLoading = false;
    },
    getTableData(id) {
      if (this.isAdmin) {
        this.getMyVacationOrder(id);
        this.getRequestPersonnelOfficer();
      } else {
        this.getMyVacationOrder(id);
        this.getVacationWithMyConfirmation(id);
      }
    },
    updateTableData(id) {
      this.getTableData(id);
      if (!this.isAdmin) {
        this.$root.$emit("updateMyConfirmation");
      }
    },
    getMyVacationOrder(id) {
      this.scrollLoading = true;
      httpClient({
        method: "GET",
        url: `/VacationOrder/actionGetItems`,
        params: {
          _count: 300,
          user_id: id,
          _with: JSON.stringify(["confirmations", "notAcceptedTransferVacationOrder.confirmations"])
        }
      }).then((response) => {
        this.myVacation = response.data.data.data;
        this.scrollLoading = false;
      });
    },
    getRequestPersonnelOfficer() {
      this.scrollLoading = true;
      httpClient({
        method: "GET",
        url: `/VacationOrder/actionGetAdminOrders`
      }).then((response) => {
        this.absenceVacationRequest = response.data.data.data.absence_orders;
        this.transferOrderRequest = response.data.data.data.transfer_vacation_orders;
        this.scrollLoading = false;
      });
    },
    getVacationWithMyConfirmation(id) {
      this.scrollLoading = true;
      httpClient({
        method: "GET",
        url: `/Confirmation/actionGetItems`,
        params: {
          _count: 300,
          user_id: id,
          is_current: true,
          _with: JSON.stringify(["confirmable.confirmations"]),
          is_null: JSON.stringify(["is_checked"])
        }
      }).then((response) => {
        this.vacationPendingRequest = response.data.data.data;
        this.scrollLoading = false;
      });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
.vacation-requests {
  font-family: "SF", sans-serif !important;
  width: 100%;
  height: calc(100% - 30px);
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  //
  //@media (max-width: 450px) {
  //  padding: 30px 0 0;
  //}
}
</style>
