<template>
  <div
    :style="{
      width: vacation.count_days * 20 + 'px',
      left: left + 'px'
    }"
    class="timeline-vacation__day_start"
    @click.stop="
      $root.$emit('openConformationModal', { vacation, editMode: false, createMode: false })
    "
  >
    <div class="timeline-vacation__dates" v-if="vacation.count_days > 6">
      {{ setFormatDate(vacation.date_from) }} - {{ setFormatDate(vacation.date_to) }}
    </div>
    <div class="timeline-vacation__count" v-if="vacation.count_days > 3">
      {{ vacation.count_days }}
      {{ setLabelCountDays(vacation.count_days, "день", "дня", "дней") }}
    </div>
  </div>
</template>

<script>
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";

export default {
  name: "TimeLineVacationItem",
  mixins: [vacationMixin],
  components: {},
  props: {
    vacation: {
      type: Object,
      default: () => {
        return {};
      }
    },
    refs: {
      type: Object,
      default: () => {
        return {};
      }
    },
    widthSlideNav: {
      type: Number,
      default: 330
    }
  },
  data() {
    return {
      left: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getLeftPositionVacation();
    });
  },
  methods: {
    //@TODO продумать как отслеживать ширину боковой навигации и из каких ширин складываются числа 376 и 567
    getLeftPositionVacation() {
      if (this.widthSlideNav < 200) {
        this.left = document.getElementById(this.vacation.date_from).offsetLeft;
      } else {
        this.left =
          document.getElementById(this.vacation.date_from).offsetLeft +
          567 -
          this.refs.tableContainer.getBoundingClientRect().left -
          document.querySelector(".timeline-vacation__member-list").offsetWidth;
      }
    },
    setFormatDate(date) {
      return date.split("-").reverse().join(".");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
.timeline-vacation {
  &__dates,
  &__count {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ebf2fa;
    padding-right: 24px;
    margin: 0;
  }

  &__dates {
    display: none;
    padding-right: 8px;
  }

  &__day_start {
    flex-wrap: wrap;
  }

  &__day_start:hover .timeline-vacation__dates {
    display: block;
  }
}
</style>
