<template>
  <div class="comment-isp" :class="parentComment ? 'comment-isp-child' : 'comment-isp'">
    <div class="comment-isp__header">
      <User
        :avatar="comment.author.avatar"
        :name="comment.author.full_name"
        :info="comment.author.roles.length ? `[${comment.author.roles[0].name}]` : ''"
        :showTooltip="false"
        :size="'40px'"
        :row="true"
        :id="comment.author.id"
      />
      <div class="comment-isp__date">
        {{ setDateFormatWithoutTime(comment.datetime_of_creation) }}
        {{ setTimeFormatWithoutDate(comment.datetime_of_creation) }}
      </div>
      <div
        class="comment-isp__replies-button"
        v-if="comment.replies.length"
        @click="showHideReplies"
      >
        <p v-if="!showReplies">Ответы ({{ comment.replies.length }})</p>
        <p v-else>Скрыть</p>
      </div>
    </div>
    <div class="comment-isp__body">
      <p
        class="comment-isp__content"
        v-if="comment.content || comment.attachment_files.length"
        ref="content"
        :style="
          bigTextTrigger
            ? `display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;`
            : ''
        "
        v-html="setURL(`<span>${comment.content}</span>`)"
      ></p>

      <p class="comment-isp__empty" v-else>Комментарий удален</p>
      <span v-if="showTrigger">
        <span
          class="comment-isp__plain-button"
          @click="showBigText(comment.content)"
          v-if="bigTextTrigger"
        >
          <span>Показать полностью</span>
        </span>
        <span class="comment-isp__plain-button" @click="hideBigText" v-if="!bigTextTrigger">
          <span>Свернуть</span>
        </span>
      </span>
      <ul class="comment-isp__files" v-if="comment.attachment_files.length && getImgFiles.length">
        <CommentFileItem
          v-for="(file, idx) in getImgFiles"
          :key="file.id"
          :idx="idx"
          :file="file"
          :showCross="false"
          :isMain="false"
          :fileName="file.label"
        />
      </ul>
      <ul
        class="comment-isp__files"
        v-if="comment.attachment_files.length && getDocumentsFiles.length"
      >
        <CommentFileItem
          v-for="(file, idx) in getDocumentsFiles"
          :key="file.id"
          :idx="idx"
          :file="file"
          :showCross="false"
          :isMain="false"
          :fileName="file.label"
        />
      </ul>
    </div>
    <div
      :class="parentComment ? 'comment-isp__footer-child' : 'comment-isp__footer'"
      v-if="!currentInfoProblem.is_archived"
    >
      <p class="comment-isp__reply" v-if="!mode && !parentComment" @click="replyHandler">
        <i class="icon-reply-arrow"></i>Ответить
      </p>
      <p class="comment-isp__edit" @click.stop="editHandler" v-if="canEdit">
        <i class="icon-edit"></i>Редактировать
      </p>
      <p class="comment-isp__delete" @click="deleteComment" v-if="canDelete">
        <i class="icon-delete"></i>Удалить
      </p>
      <p class="comment-isp__cancel" v-if="mode" @click="cancelMode">
        <i class="icon-cross"></i>Отменить
      </p>
    </div>

    <slide-up-down class="comment-isp__replies" :active="showReplies">
      <CommentIsp
        v-for="reply in comment.replies"
        :key="reply.id"
        :comment="reply"
        :parentComment="comment"
      />
    </slide-up-down>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import { mapActions, mapMutations, mapState } from "vuex";
import SlideUpDown from "vue-slide-up-down";
import CommentFileItem from "@/components/GraphVacation/Comments/CommentFileItem";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import { format, parseISO } from "date-fns";
import UrlMixin from "@/helpers/UrlMixin";

export default {
  name: "CommentIsp",
  mixins: [formatDateAndTime, UrlMixin],
  props: {
    comment: {
      type: Object,
      default: null
    },
    parentComment: {
      type: Object,
      default: null
    }
  },
  components: { CommentFileItem, User, SlideUpDown },
  data() {
    return {
      mode: "",
      showReplies: false,
      bigTextTrigger: true,
      contentHeight: 0
    };
  },
  mounted() {
    if (this.parentComment) {
      this.$nextTick(() => {
        this.contentHeight = this.getHeight(this.$refs.content);
      });
    }

    this.contentHeight = this.$refs.content.offsetHeight;
  },
  methods: {
    ...mapActions("IspModule", ["deleteProblemCommentAction"]),
    ...mapMutations("IspModule", [
      "SET_CURRENT_EDITABLE_COMMENT",
      "SET_CURRENT_REPLYABLE_COMMENTS",
      "SET_CURRENT_PROBLEM_COMMENTS"
    ]),
    replyHandler() {
      this.mode = "reply";
      this.SET_CURRENT_REPLYABLE_COMMENTS(this.comment);
    },
    editHandler() {
      this.mode = "edit";
      this.SET_CURRENT_EDITABLE_COMMENT(this.comment);
    },
    deleteComment() {
      this.deleteProblemCommentAction(this.comment.id).then(() => {
        if (this.parentComment) {
          let newReplies = this.parentComment.replies.filter((item) => item.id !== this.comment.id);
          this.parentComment.replies = newReplies;
          if (!this.parentComment.content && !newReplies.length) {
            this.SET_CURRENT_PROBLEM_COMMENTS(
              this.currentProblemComments.filter((item) => item.id !== this.parentComment.id)
            );
          }
        } else {
          if (this.comment.replies.length) {
            this.comment.content = "";
            this.comment.attachment_files = [];
          } else {
            this.SET_CURRENT_PROBLEM_COMMENTS(
              this.currentProblemComments.filter((item) => item.id !== this.comment.id)
            );
          }
        }
      });
    },
    cancelMode() {
      this.mode = "";
      this.SET_CURRENT_EDITABLE_COMMENT(null);
      this.SET_CURRENT_REPLYABLE_COMMENTS(null);
    },
    showHideReplies() {
      this.showReplies = !this.showReplies;
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    hideBigText() {
      if (this.contentHeight > 70) {
        this.bigTextTrigger = true;
      }
    },
    getHeight(element) {
      const elementClone = element.cloneNode(true);
      elementClone.style.visibility = "hidden";
      document.body.appendChild(elementClone);
      const height = elementClone.offsetHeight + 0;
      document.body.removeChild(elementClone);
      elementClone.style.visibility = "visible";
      return height;
    }
  },
  computed: {
    ...mapState("IspModule", [
      "currentEditableComment",
      "currentReplyableComment",
      "currentInfoProblem",
      "currentProblemComments"
    ]),
    showTrigger() {
      return this.contentHeight > 70;
    },
    canDelete() {
      return (
        this.comment.author.id === this.$store.getters.getDataInfoUser.id &&
        !this.mode &&
        (this.comment.content || this.comment.attachment_files.length)
      );
    },
    canEdit() {
      const timeDif =
        new Date().getTime() / 3600000 -
          format(parseISO(this.comment.datetime_of_creation), "T") / 3600000 <
        1;
      return this.canDelete && timeDif;
    },
    getImgFiles() {
      return this.comment.attachment_files.filter(
        (file) =>
          file.extension === "image/jpeg" ||
          file.extension === "image/jpg" ||
          file.extension === "image/png"
      );
    },
    getDocumentsFiles() {
      return this.comment.attachment_files.filter(
        (file) =>
          file.extension !== "image/jpeg" &&
          file.extension !== "image/jpg" &&
          file.extension !== "image/png"
      );
    }
  },
  watch: {
    currentEditableComment(val) {
      if (!val || val.id !== this.comment.id) {
        this.mode = "";
        this.$nextTick(() => {
          this.contentHeight = this.$refs.content.offsetHeight;
        });
      }
    },
    currentReplyableComment(val) {
      if (!val || val.id !== this.comment.id) {
        this.mode = "";
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.comment-isp {
  width: 100%;
  &:hover &__footer {
    opacity: 1;
  }

  &__header {
    display: flex;
    align-items: center;

    /deep/ {
      .user__name {
        max-width: 220px;
        width: max-content;
        font-weight: 500;
        font-size: 16px;
      }
      .user__description {
        max-width: 220px;
        width: max-content;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
  &__replies-button {
    font-size: 12px;
    color: #5551f9;
    margin-left: auto;
    cursor: pointer;
  }
  &__date {
    font-size: 12px;
    color: $text-disabled;
  }
  &__body {
    border-radius: 0px 16px 16px 16px;
    margin: 0 0 6px 48px;
    word-break: break-word;
    p {
      color: $text-default;
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__content {
    white-space: pre-wrap;
  }
  &__plain-button {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
  }
  &__empty {
    color: $text-disabled !important;
  }
  &__files {
    padding: 0;
    display: flex;
  }
  &__footer {
    width: fit-content;
    margin-left: auto;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    opacity: 0;
    padding: 0 0 12px 40px;
    p {
      margin: 0 6px;
      color: #5551f9;
      display: flex;
      align-items: center;
      i {
        margin-right: 2px;
        font-size: 14px;
      }
    }
  }
  &__footer-child {
    width: fit-content;
    margin-left: auto;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    opacity: 0;
    padding: 0 0 12px 40px;
    p {
      margin: 0 6px;
      color: #5551f9;
      display: flex;
      align-items: center;
      i {
        margin-right: 2px;
        font-size: 14px;
      }
    }
  }
  &__replies {
    padding: 0 0 0 40px;
  }
}
.comment-isp-child {
  &:hover .comment-isp__footer-child {
    opacity: 1;
  }
}
</style>
