<template>
  <router-link
    :to="{ name: 'UserInfo', params: { user_id: employeeItem.id } }"
    :target="isGlobalMobileMode ? '_self' : '_blank'"
    class="new-employee-widget__item"
  >
    <User
      :avatar="employeeItem.avatar"
      :name="employeeItem.full_name"
      :id="employeeItem.id"
      :showTooltip="false"
      :info="getUserRole ? `[${getUserRole}]` : ''"
    />
  </router-link>
</template>

<script>
import User from "@/components/Widgets/User/User";
export default {
  name: "NewEmployeeItem",
  mixins: [],
  props: {
    employeeItem: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    },
    getUserRole() {
      return this.employeeItem.roles.length ? this.employeeItem.roles[0].name : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.new-employee-widget {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    word-break: break-word;
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:hover {
      /deep/ .user__name {
        color: $text-subdued;
      }
    }
  }

  .user {
    @media (max-width: $lg) {
      /deep/a:first-child {
        display: none;
      }
    }
  }

  /deep/ {
    .user__name {
      max-width: 140px;
    }

    .user__description {
      max-width: 140px;
    }
  }
}
</style>
