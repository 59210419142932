<template>
  <section class="education table-container">
    <EducationWidgets
      :isAdmin="isAdminEducation"
      :filterUser="filterUser"
      :filterTypeEducation="filterTypeEducation"
      :filterInstitution="filterInstitution"
      :filterActive="filterActive"
    />
    <EducationWidgetsMobile
      :isAdmin="isAdminEducation"
      :filterUser="filterUser"
      :filterTypeEducation="filterTypeEducation"
      :filterInstitution="filterInstitution"
      :filterActive="filterActive"
    />
    <div class="education__container">
      <Table
        :tableHead="tableHead"
        :tableData="tableData"
        :isWorkTime="true"
        @getTableData="getTableDataWithPagination"
      >
        <template slot-scope="props">
          <EductionCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
  </section>
</template>

<script>
import EducationWidgets from "@/modules/Tables/components/TablesCompany/Education/EducationWidgets";
import EducationWidgetsMobile from "@/modules/Tables/components/TablesCompany/Education/EducationWidgetsMobile/EducationWidgetsMobile";
import EductionCellContent from "@/modules/Tables/components/TablesCompany/Education/EductionCellContent";
import Table from "@/modules/Tables/components/Table/Table";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "Education",
  mixins: [],
  props: [],
  components: { EducationWidgets, EducationWidgetsMobile, Table, EductionCellContent },
  data() {
    return {
      tableHead: [
        { name: "Сотрудник", sort: true, valueSort: "full_name", key: "full_name" },
        { name: "Учебное заведение", sort: false, key: "education_institution" },
        { name: "Вид образования", sort: false, key: "education_type" },
        { name: "Квалификация", sort: false, key: "education_qualification" },
        { name: "Специальность", sort: false, key: "education_speciality" },
        { name: "Начало", sort: false, key: "from_year" },
        { name: "Завершение", sort: false, key: "to_year" }
      ],
      filterActive: false,
      filterUser: [],
      filterInstitution: [],
      filterTypeEducation: []
    };
  },
  beforeDestroy() {
    this.$root.$off("changeSelectValue:FilterUserListInEducationTable");
    this.$root.$off("changeSelectValue:FilterTypeListInEducationTable");
    this.$root.$off("changeSelectValue:FilterInstitutionListInEducationTable");
    this.$root.$off("switch:change_FilterActiveListInEducationTable");
    this.setCookieValue();
  },
  created() {
    this.getCookieValue();
  },
  updated() {
    this.setCookieValue();
  },
  mounted() {
    if (!this.isAdminEducation) {
      this.$router.push({ name: "Profile" });
    }
    this.initializeTable();
    this.setFilterTable();
  },
  methods: {
    ...mapActions("TablesModules", ["getTableData"]),
    ...mapMutations("TablesModules", [
      "CLEAR_TABLE_DATA",
      "SET_TABLE_PARAMS",
      "RESET_PAGINATION",
      "SET_IS_FILTER_ACTIVE_EDUCATION"
    ]),
    getTableDataWithPagination() {
      if (this.tableData.length) {
        this.getTableData();
      }
    },
    initializeTable() {
      this.CLEAR_TABLE_DATA();
      this.RESET_PAGINATION();
      this.SET_TABLE_PARAMS({
        route: "User/getItems",
        params: {
          _count: 25,
          _order: JSON.stringify({
            full_name: this.valueSort.isAsc ? "asc" : "desc"
          }),
          id: this.filterUser.length ? JSON.stringify(this.setFilterUser) : undefined,
          education_types: this.filterTypeEducation.length
            ? JSON.stringify(this.setTypeEducation)
            : undefined,
          education_institutions: this.filterInstitution.length
            ? JSON.stringify(this.setFilterInstitution)
            : undefined,
          is_active: this.filterActive ? JSON.stringify([0]) : JSON.stringify([1]),
          _with: JSON.stringify(["educations"])
        }
      });
      this.getTableData();
    },
    /**
     * Set Filter Tables
     */
    setFilterTable() {
      this.$root.$on("changeSelectValue:FilterUserListInEducationTable", (val) => {
        this.filterUser = val;
        this.initializeTable();
      });
      this.$root.$on("changeSelectValue:FilterTypeListInEducationTable", (val) => {
        this.filterTypeEducation = val;
        this.initializeTable();
      });
      this.$root.$on("changeSelectValue:FilterInstitutionListInEducationTable", (val) => {
        this.filterInstitution = val;
        this.initializeTable();
      });
      this.$root.$on("switch:change_FilterActiveInEducationTable", (val) => {
        this.filterActive = val;
        this.SET_IS_FILTER_ACTIVE_EDUCATION(val);
        this.initializeTable();
      });
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("Education-table-setting"));
      this.filterUser = setting.users;
      this.filterTypeEducation = setting.type;
      this.filterInstitution = setting.institution;
      this.filterActive = setting.active;
    },
    setCookieValue() {
      const option = {
        users: this.filterUser,
        type: this.filterTypeEducation,
        institution: this.filterInstitution,
        active: this.filterActive
      };
      window.localStorage.setItem("Education-table-setting", JSON.stringify(option));
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    ...mapState("TablesModules", ["tableData", "valueSortList", "tableParams", "valueSort"]),
    isAdminEducation() {
      return this.getEndpoints("UserEducation", "update");
    },
    setFilterUser() {
      return this.filterUser.map((item) => item.id);
    },
    setTypeEducation() {
      return this.filterTypeEducation.map((item) => {
        return { "education_types.id": item.id };
      });
    },
    setFilterInstitution() {
      return this.filterInstitution.map((item) => {
        return { "education_institutions.id": item.id };
      });
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  border-radius: 10px;
  width: 0;
  background: $surface-subdued;
}

::-webkit-scrollbar-thumb {
  background: $surface-pressed;
  border-radius: 8px;
  width: 6px;
  cursor: pointer;

  &:hover,
  &:active {
    background: $action-primary;
  }
}

::-webkit-scrollbar-track {
  margin: 0;
}

.education {
  height: calc(100% - 100px);

  &__container {
    width: 100%;
    height: 90%;
    overflow: scroll;

    @media (max-height: $sm) {
      height: 83%;
    }

    @media (max-height: 450px) {
      height: 75%;
    }

    @media (max-height: 420px) {
      height: 68%;
    }
  }

  &__widgets-mobile {
    display: none;
    @media (max-width: 1300px) {
      display: flex;
      align-items: center;
    }
  }

  &__widgets {
    @media (max-width: 1300px) {
      display: none;
    }
  }

  /deep/ {
    .table__empty {
      .table__row {
        width: 100%;
      }
    }

    .table__row,
    .table__head {
      width: 100%;
      @media (max-width: 1766px) {
        width: fit-content;
      }
    }

    .table__cell {
      &:first-child {
        min-width: 400px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 230px;
      }

      &:nth-child(5) {
        min-width: 135px;
      }

      &:nth-child(6),
      &:last-child {
        min-width: 120px;
        width: 30%;
      }
    }
  }
}
.users {
  &__table {
    height: calc(100% - 140px);
  }
}
</style>
