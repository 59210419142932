














import { Component, Prop, Vue } from "vue-property-decorator";
import LocationItem from "@/aopV2/modules/Calendar/components/CalendarControlPanel/CalendarLocation/LocationItem.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({
  components: { LocationItem }
})
export default class LocationList extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  selectedLocations!: Array<any>;
  @Prop({
    type: Boolean,
    default: false
  })
  admin!: boolean;

  activeLocation: Array<any> = [];

  setActiveLocation(val) {
    this.activeLocation = val;
    if (
      this.selectedLocations.length &&
      this.selectedLocations.find((item) => item.id === val.id)
    ) {
      this.$emit(
        "handleChangeLocations",
        this.selectedLocations.filter((item) => item.id !== val.id)
      );
    } else {
      this.$emit("handleChangeLocations", this.selectedLocations.concat(this.activeLocation));
    }
  }

  get locations() {
    return CalendarModule2.locations;
  }
}
