<template>
  <div class="date-picker__calendar">
    <v-date-picker
      v-model="selectDate"
      :masks="masks"
      :show-current="false"
      :attributes="attributes"
      :min-date="setMinDate"
      @input="handleInput"
    >
      <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
        <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
      </div>
      <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DeadLineCalendar",
  mixins: [],
  props: {
    amountDaysForMinDate: {
      type: Number,
      default: undefined
    },
    showCalendar: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    minDate: {
      type: Boolean,
      default: true
    },
    masks: {
      weekdays: "WWW"
    },
    attributes: []
  },
  components: {},
  data() {
    return {
      selectDate: new Date()
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.selectDate = this.value;
      }
    })
  },
  methods: {
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    handleInput(value) {
      this.$emit("input", value);
      this.$emit("closeCalendar", value);
    }
  },
  computed: {
    setMinDate() {
      const setAmountDays =
        typeof this.amountDaysForMinDate === "number" ? this.amountDaysForMinDate : 1;
      return this.minDate ? new Date(moment().add(setAmountDays, "days")) : '';
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.date-picker {
  &__calendar {
    width: 100%;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;

    /deep/ {
      .vc-container {
        border: 1px solid #eae8f0;
        border-radius: 8px;
        padding: 8px 2px;
        width: 100%;
      }

      .vc-arrows-container {
        padding: 0;
        margin: 0 0 24px;
        width: 307px;
        left: 50%;
        transform: translateX(-50%);
      }

      .vc-arrow {
        height: 21px;
      }

      .vc-svg-icon {
        width: 21px;
        height: 21px;

        path {
          fill: $icon-subdued;
        }
      }

      .vc-header {
        padding: 0;
        margin: 0 0 24px;
      }

      .month {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .vc-weekday {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $text-disabled;
      }

      .weekday-1 .vc-day-content,
      .weekday-7 .vc-day-content {
        color: $text-disabled;
      }

      .vc-day-content {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 16px !important;
        &:hover,
        &:focus {
          width: 40px;
          height: 40px;
          background: #5551f9 !important;
          border-radius: 4px !important;
          color: #ffffff !important;
        }
      }

      .vc-highlight.vc-highlight-base-middle {
        width: 40px;
        height: 40px;
        background: #fafafe !important;
      }

      .vc-highlight,
      .vc-highlight.vc-highlight-base-start,
      .vc-highlight.vc-highlight-base-end {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
      }

      .vc-day.is-not-in-month .vc-day-content {
        opacity: 1;
        color: #747474;
      }
    }
  }
}
</style>
