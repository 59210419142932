

























































import { Component, Prop, Vue } from "vue-property-decorator";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import CheckboxInput from "@/aopV2/components/UIComponents/Input/Check/Checkbox/CheckboxInput.vue";
import RadioInput from "@/aopV2/components/UIComponents/Input/Check/Radio/RadioInput.vue";
import { domainStorage } from "@/globalVariables";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";

@Component({
  components: { RadioInput, CheckboxInput, UserCard, ButtonIcon }
})
export default class SelectItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  item: any;
  @Prop({
    default: () => [],
    type: Array
  })
  list!: Array<any>;
  @Prop({
    default: true,
    type: Boolean
  })
  isMultiple!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isShowAvatar!: boolean;
  @Prop({
    default: true,
    type: Boolean
  })
  isUser!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isLimit!: boolean;
  @Prop({
    default: false,
    type: Boolean
  })
  isDelete!: boolean;
  @Prop({
    default: "select-list",
    required: true
  })
  unique!: string;
  @Prop({
    default: false
  })
  isShowSelected!: boolean;

  handleItem() {
    this.$emit("handleItem", this.item);
  }

  handleDelete() {
    this.$emit("handleDelete", this.item.id);
  }

  get src() {
    return domainStorage + this.item.avatar;
  }

  get role() {
    const roles = this.item.roles;
    if (Array.isArray(roles)) {
      return roles.length ? roles[0].name : "";
    } else {
      return "";
    }
  }

  get isSelected() {
    return this.list.some((item) => item.id === this.item.id);
  }

  get inputComponent() {
    return this.isMultiple ? CheckboxInput : RadioInput;
  }

  get isShow() {
    return !(this.isSelected && this.isShowSelected);
  }
}
