











import { Component, Prop, Vue } from "vue-property-decorator";
import { ITabItem } from "@/aopV2/components/UIComponents/TabControl/TabControl.vue";

@Component
export default class TabControlItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  tab!: ITabItem;
  @Prop({
    type: String,
    required: true
  })
  activeTab!: string;
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: string;

  handleTab() {
    if (!this.isDisabled) {
      this.$emit("handleTab", this.tab.type);
    }
  }

  get isActive() {
    return this.tab.type === this.activeTab;
  }

  get isDisabled() {
    return this.tab.disabled;
  }
}
