import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import {
  IBlackSphere,
  IBlackSphereParams
} from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModel";
import TaskBlackSphereService from "@/modules/TaskManager/Model/BlackSphere/BlackSphereService";
import NotificationObserver from "@/helpers/UserNotification";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import BlackSphereService from "@/modules/BlackSphere/Service/BlackSphereService";

const name = "TaskBlackSphereModule";

export enum TaskBlackSphereMutationTypes {
  SET_BLACK_SPHERE = "SET_BLACK_SPHERE",
  ADD_BLACK_SPHERE = "ADD_BLACK_SPHERE",
  DELETE_BLACK_SPHERE = "DELETE_BLACK_SPHERE",
  SET_LOADER = "SET_LOADER"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class TaskBlackSphereModule extends VuexModule {
  marks: Array<IBlackSphere> = [];
  loader: boolean = false;

  @Mutation
  [TaskBlackSphereMutationTypes.SET_BLACK_SPHERE](payload: Array<IBlackSphere>) {
    this.marks = payload;
  }

  @Mutation
  [TaskBlackSphereMutationTypes.ADD_BLACK_SPHERE](payload: IBlackSphere) {
    this.marks = this.marks.concat(payload);
  }

  @Mutation
  [TaskBlackSphereMutationTypes.DELETE_BLACK_SPHERE](payload: IBlackSphereParams) {
    this.marks = this.marks.filter((item: IBlackSphere) =>
      item.black_sphere_id && payload.black_sphere_id
        ? item.black_sphere_id !== payload.black_sphere_id
        : item.id !== payload.black_sphere_id
    );
  }

  @Mutation
  [TaskBlackSphereMutationTypes.SET_LOADER](payload: boolean) {
    this.loader = payload;
  }

  @Action
  async createTaskBlackSphereAction(body: IBlackSphereParams) {
    const create = await TaskBlackSphereService.createTaskBlackSphere(body);
    if (create) {
      NotificationObserver.notification({
        type: "success",
        message: "Оценка успешно создана!"
      });
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        false
      );
      this[TaskBlackSphereMutationTypes.ADD_BLACK_SPHERE](create);
    }
  }

  @Action
  async getTaskBlackSphereAction(params: IBlackSphereParams) {
    this[TaskBlackSphereMutationTypes.SET_LOADER](true);
    const marks = await TaskBlackSphereService.getTaskBlackSphere(params);
    this[TaskBlackSphereMutationTypes.SET_BLACK_SPHERE](marks);
    this[TaskBlackSphereMutationTypes.SET_LOADER](false);
  }

  @Action
  async deleteTaskBlackSphereAction(body: IBlackSphereParams) {
    await TaskBlackSphereService.deleteTaskBlackSphere(body);
    this[TaskBlackSphereMutationTypes.DELETE_BLACK_SPHERE](body);
  }
}

export default getModule(TaskBlackSphereModule);
