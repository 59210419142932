<template>
  <div class="request-table__row" @click.self="openInfoModal">
    <div class="request-table__col request-table__col_author" @click="openInfoModal">
      <img
        class="request-table__avatar"
        :src="returnSrcFile(row.vacationOrder.user.avatar)"
        alt=""
        width="36px"
        height="36px"
      />
      <span class="request-table__username">{{ row.vacationOrder.user.full_name }}</span>
    </div>
    <div class="request-table__col request-table__col_type" @click="openInfoModal">
      <div class="request-table__type" :style="setStyleType"></div>
      {{ setType }}
    </div>
    <div class="request-table__col request-table__col_department" @click="openInfoModal">
      {{ setDepartment }}
    </div>
    <div class="request-table__col request-table__col_confirm" @click="openInfoModal">
      <p class="request-table__subtitle">Подтверждающие лица</p>
      <ConfirmationsUsers
        v-for="user in row.confirmations"
        :key="user.user.id"
        :user="user.user"
        :checkVacation="user.is_checked"
      />
    </div>
    <div class="request-table__col request-table__col_status" @click="openInfoModal">
      <p class="request-table__subtitle">Статус</p>
      <p class="request-table__status" :style="{ color: color, backgroundColor: bgcolor }">
        <span class="request-table__status-icon" :style="{ border: `1px solid ${color}` }">
          <i class="icon-check-mark"></i>
        </span>
        <span>{{ setStatus(row.is_vacation_admin_accepted) }}</span>
      </p>
    </div>
    <div class="request-table__col request-table__col_actions">
      <ActionsListConfirm
        :currentVacationData="row"
        :showAllItem="false"
        :showExport="setShowExport"
        :confirmFooter="confirmFooter"
        :type="'vacation'"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ConfirmationsUsers from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestComponents/ConfirmationsUsers";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";
import ActionsListConfirm from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestComponents/ActionsListConfirm";
import { domainStorage } from "@/globalVariables";

export default {
  name: "RequestTableRow",
  mixins: [vacationMixin],
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { ConfirmationsUsers, ActionsListConfirm },
  data() {
    return {
      confirmFooter: true
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    openInfoModal() {
      this.$root.$emit("openMyConfirmations", {
        vacation: this.row,
        type: "vacation"
      });
    }
  },
  computed: {
    setStyleType() {
      return this.row.old_date_to ? "background-color: #FFB901" : "background-color: #6A67CE";
    },
    setType() {
      return this.row.old_date_to ? "Перенос отпуска" : "Отпуск";
    },
    setDepartment() {
      if (this.row.vacationOrder.user.departments) {
        return this.row.vacationOrder.user.departments.length
          ? this.row.vacationOrder.user.departments[0].name
          : "-";
      } else {
        return "-";
      }
    },
    setShowExport() {
      if (this.row.is_vacation_admin_accepted) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.request-table {
  position: relative;

  &__header {
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    margin-bottom: 24px;
    background-color: $bg-surface-light;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: $text-subdued;
  }

  &__container {
    width: 100%;
    display: flex;
  }

  &__row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 40px;
    cursor: pointer;

    @media (max-width: $lg) {
      flex-wrap: wrap;
      padding: 16px 18px 36px;
      border-top: 0.8px solid #e2e8f0;
      margin-bottom: 0;
    }
  }

  &__row {
    .request-table__col {
      /deep/ {
        .absence-card__modal {
          .absence-card__modal-list {
            top: unset;
            bottom: 34px;

            @media (max-width: $md) {
              top: 34px;
              bottom: unset;
            }
          }
        }
      }
    }
  }

  &__row:nth-child(2),
  &__row:nth-child(3),
  &__row:nth-child(4),
  &__row:nth-child(5),
  &__row:nth-child(6),
  &__row:nth-child(7),
  &__row:nth-child(8) {
    .request-table__col {
      /deep/ {
        .absence-card__modal {
          .absence-card__modal-list {
            top: 34px;
            bottom: unset;
          }
        }
      }
    }
  }

  &__col {
    position: relative;
    display: flex;
    align-items: center;
    width: 70%;
    margin-right: 10px;

    @media (max-width: 1100px) {
      font-size: 12px;
      line-height: 20px;
    }

    @media (max-width: $lg) {
      flex-wrap: wrap;
      margin-right: 0;
      font-size: 14px;
    }

    &_type {
      display: flex;
      align-items: center;
      @media (max-width: $lg) {
        width: 100%;
        order: 1;
        margin-bottom: 32px;
      }
    }

    &_department {
      @media (max-width: $lg) {
        order: 5;
        width: 100%;
        margin-bottom: 32px;
      }
    }

    &_status {
      @media (max-width: $lg) {
        order: 7;
        margin-bottom: 20px;
        width: 50%;
      }
    }

    &_confirm {
      @media (max-width: $lg) {
        order: 6;
        width: 50%;
        align-self: flex-start;
      }
    }

    &_status {
      @media (max-width: $lg) {
        order: 7;
        margin-bottom: 20px;
        width: 50%;
      }
    }

    &_actions {
      width: 25%;
      margin-right: 0;

      @media (max-width: $lg) {
        order: 8;
        position: absolute;
        top: 20px;
        right: 25px;
      }
    }

    &_author {
      width: 100%;
      @media (max-width: $lg) {
        order: 2;
        margin-bottom: 16px;
      }
    }
  }

  &__type {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__subtitle {
    display: none;

    @media (max-width: $lg) {
      width: 100%;
      display: block;
      margin: 0 0 8px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: $text-subdued;
    }
  }

  &__confirmations {
    position: relative;
    width: 36px;

    &:nth-child(2) {
      position: relative;
      left: -8px;
    }

    &:nth-child(3) {
      position: relative;
      left: -16px;
    }

    &:hover {
      .request-table__user-name {
        display: block;
      }
    }
  }

  &__avatar,
  &__img {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 24px;
  }

  &__avatar {
    margin-right: 24px;
  }

  &__username {
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: $text-default;
  }

  &__user-name {
    display: none;
    position: absolute;
    top: -30px;
    left: 0;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;
  }

  &__status {
    display: flex;
    align-items: center;
    width: max-content;
    height: 24px;
    border-radius: 40px;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    &_pending {
      background: #fff8e5;
      color: #fd9a00;

      .request-table__status-icon {
        border: 1px solid #fd9a00;
      }
    }

    &_approved {
      background: #ebfcf6;
      color: #37c597;

      .request-table__status-icon {
        background-color: #37c597;
      }

      i {
        color: #ffffff;
      }
    }

    &_rejected {
      background: #fef0f0;
      color: #fb646d;

      .request-table__status-icon {
        background-color: #fb646d;
      }

      i {
        color: #ffffff;
      }
    }

    .request-table__status-icon {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 50%;
    }

    i {
      font-size: 5px;
      margin-bottom: 1px;
    }
  }

  /deep/ {
    .absence-card__modal {
      position: relative;
      margin-left: auto;

      .icon-dots-vertical {
        font-size: 20px;
        color: $icon-subdued;
        cursor: pointer;
      }
    }
  }
}
</style>
