<template>
  <div class="search-input__wrap">
    <div class="search-input">
      <i class="icon-menu" @click="showMenu"></i>
      <!--      <i class="icon-search" v-if="!showSearch && !searchMessageMode" @click="changeShowSearch"></i>-->
      <TextInput
        :iconClass="'icon-search'"
        @changeText="changeText"
        :value="search"
        :classList="'font16'"
      />
      <InputDatePicker
        @changeDate="changeDate"
        v-if="searchMessageMode"
        :readonly="true"
        :isIcon="true"
        :isDateDisabled="isFutureDate"
        :unique="'message'"
        :editValue="date"
        :displayFormat="'DD.MM.YYYY'"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/Widgets/Inputs/TextInput";
import { mapActions, mapState } from "vuex";
import InputDatePicker from "@/components/Widgets/DatePickers/InputDatePicker";
import moment from "moment";
export default {
  name: "SearchInput",
  mixins: [],
  props: {},
  components: { InputDatePicker, TextInput },
  data() {
    return {
      timerId: null,
      showCalendar: false,
      showSearch: false,
      date: "",
      value: ""
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["searchUser", "switchSettingMenu", "searchMessage"]),
    changeText(val) {
      clearTimeout(this.timerId);
      this.value = val.value;

      this.timerId = setTimeout(() => {
        if (this.searchMessageMode) {
          this.searchMessage({
            channel_id: this.currentChat.channel.id,
            per_page: 20,
            page: 1,
            text: this.value,
            from_date: this.date
          });
        } else {
          this.searchUser(this.value);
        }
      }, 300);
    },
    changeDate(date) {
      this.date = date;
      this.searchMessage({
        channel_id: this.currentChat.channel.id,
        text: this.value,
        from_date: this.date
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    datePick() {
      this.showCalendar = !this.showCalendar;
    },
    showMenu() {
      this.switchSettingMenu(true);
    },
    changeShowSearch() {
      this.showSearch = !this.showSearch;
    }
  },
  computed: {
    ...mapState("MessengerModule", ["search", "searchMessageMode", "currentChat"])
  },
  watch: {
    searchMessageMode() {
      this.timerId = null;
      this.showCalendar = false;
      this.date = "";
      this.value = "";
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.search-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 70px;
  //border-bottom: 1px solid #eae8f0;
  //margin-bottom: 24px;
  .icon-menu {
    font-size: 24px;
    color: $text-disabled;
    cursor: pointer;
    position: absolute;
    left: 16px;
    z-index: 1;
  }
  .icon-search {
    font-size: 24px;
    color: $text-disabled;
    cursor: pointer;
  }
  /deep/ {
    .input-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0 28px 0 48px;
      i {
        font-size: 24px;
        cursor: pointer;
      }
      &__input {
        margin-bottom: 0;
        padding-right: 24px;
      }
      &_disabled {
        &::before {
          background: #eae8f0;
        }
        &::after {
          background: #eae8f0;
        }
      }
      input::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #a7a9c0;
      }
    }
    .icon-calendar-secondary {
      position: absolute;
      font-size: 20px;
      color: $text-disabled;
      cursor: pointer;
      z-index: 1;
      right: 24px;
      bottom: -5px;
    }
    .vdpComponent {
      width: auto;
      .vdpInnerWrap {
        border-radius: 4px;
        .vdpCellContent {
          border-radius: 4px;
        }
      }
    }
    .data-picker {
      padding: 0;
      background-color: transparent;
      width: auto;
      position: absolute;
      right: 0;
      height: 24px;
      top: 20px;
      &__buttons {
        all: unset;
        font-size: 22px;
        i {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
