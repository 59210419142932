



































import ModalButton from "@/components/Widgets/Button/ModalButton";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import AbsenceModule from "@/modules/Absence/store/AbsenceModule";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAbsenceTypes } from "@/modules/Absence/store/intefaces";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import DateRangePicker from "@/modules/UiKit/components/DateRangePicker/DateRangePicker";

@Component({
  components: { DateRangePicker, ChosenFilter, ModalButton, Button }
})
export default class AbsenceWidgets extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  isAdmin!: boolean;
  @Prop({
    type: Array,
    default: () => []
  })
  users!: Array<any>;
  @Prop({
    type: Array,
    default: () => []
  })
  types!: Array<IAbsenceTypes>;
  currentResponsible: Array<any> = [];
  absenceValue: Array<any> = [];
  range = {
    start: null,
    end: null
  };

  mounted() {
    this.currentResponsible = this.users;
    this.absenceValue = this.types;
  }

  changeDate(date) {
    this.$emit("selectDate", date);
  }

  handleUser(val) {
    this.currentResponsible = val;
    this.$emit("selectUsers", this.currentResponsible);
  }

  addAbsence() {
    AbsenceModule.toggleModal(true);
  }

  handleAbsenceType(val) {
    this.absenceValue = val;
    this.$emit("selectTypes", this.absenceValue);
  }

  get setShowParams() {
    return {
      show: true,
      value: null
    };
  }

  get setMaxDate() {
    return `${new Date().getFullYear() + 1}-${
      new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
    }`;
  }

  get usersList() {
    return this.$store.getters["UsersModule/userList"]
      .filedList(["full_name", "avatar", "is_active"])
      .filter((item) => item.is_active);
  }

  get absenceList() {
    return AbsenceModule.absenceTypes;
  }
}
