<template>
  <div class="comment-bg" v-click-outside="hideModalEmoji">
    <div class="user-avatar">
      <img :src="returnSrcFile(getDataInfoUser.avatar)" alt="Avatar" />
    </div>
    <textarea
      maxlength="255"
      type="text"
      v-model.trim="value"
      ref="inputComment"
      @change="changeComment"
      placeholder="Добавить комментарий..."
    ></textarea>
    <i
      class="icon-emoji"
      @click="showModalEmoji = !showModalEmoji"
      :style="showModalEmoji ? 'color: #1A1C1D' : 'color: #A7A9C0'"
    ></i>
    <Picker
      v-show="showModalEmoji"
      set="apple"
      @select="addEmoji"
      title="Выберите Эмоджи"
      :i18n="settings"
    />
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";
import { domainStorage } from "@/globalVariables";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
import Textarea from "@/components/Widgets/Textarea/Textarea";

export default {
  name: "CommentComponent",
  mixins: [],
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { Textarea, IcoMoonWrap, Picker },
  data() {
    return {
      value: "",
      showModalEmoji: false,
      // TODO: вынести settings для пикера в миксин и использовать везде, где есть пикер
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {},
  methods: {
    addEmoji(emoji) {
      let cursorPosition = this.$refs.inputComment.selectionStart;
      let FirstPart = this.value.substring(0, cursorPosition);
      let Emoji = emoji.native;
      let SecondPart = this.value.substring(cursorPosition + 1, this.value.length);
      this.value = FirstPart + Emoji + SecondPart;
    },
    changeComment() {
      this.$root.$emit("changeComment", this.value);
    },
    hideModalEmoji() {
      this.showModalEmoji = false;
    },
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.comment-bg {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;

  .user-avatar {
    margin-right: 16px;
    width: 42px;
    height: 42px;

    img {
      width: 42px;
      height: 42px;

      border-radius: 50%;
      object-fit: cover;
    }
  }

  span {
    display: block;
    width: 15px;
    height: 15px;
  }

  .icon-emoji,
  .icon-image,
  .icon-paper-clip,
  .icon-send {
    position: absolute;
    right: 36px;
    cursor: pointer;
    font-size: 15px;
    color: $icon-subdued;

    &:hover {
      color: $icon-hovered;
    }
  }

  .icon-paper-clip {
    right: 86px;
  }

  .icon-image {
    right: 55px;
  }

  .icon-send {
    right: 24px;
  }

  textarea {
    width: 100%;
    height: 48px;
    padding: 8px 55px 8px 30px;
    background: #ffffff;
    border: none;
    mix-blend-mode: normal;
    border-radius: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    outline: none;
    resize: none;
  }

  .reply {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 100%;
    left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
    .icon-cross {
      cursor: pointer;
      font-size: 15px;
    }
  }
  /deep/ {
    .emoji-mart {
      position: absolute;
      right: 0;
      bottom: 100%;
      width: 338px !important;

      @media (max-width: $sm) {
        width: 250px !important;
      }
      @media (max-width: $xs) {
        width: 220px !important;
      }

      .emoji-mart-preview {
        display: none !important;
      }

      .emoji-mart-title-label {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
