const state = {
  imageLink: "",
  showFullSizeImage: false,
  showAddChannel: false,
  editChannelValue: null,
  showChannel: false,
  memberInfoChannel: null,
  memberUser: [],
  isAdminLike: true,
  memberListTitle: "",
  activeFilter: "Все записи",
  birthdayItems: [],
  birthdayLoader: true,
  employeeItems: [],
  employeeLoader: true,
  channelList: [],
  loadingChannels: true,
  loading: false,
  newsCounter: 6,
  activeChannel: "",
  newsItems: [],
  newsTotalCount: 0,
  globalEditMode: false,
  scrollLoading: false,
  channelAllList: [],
  currentEditableComment: {},
  currentReplyableComment: null,
  newsToUpdate: null,
  showCurtainCreateNews: false,
  showCurtainCreateChannel: false,
  showCurtainNewsLikes: false,
  currentNewsLikes: [],
  showCurtainShowAndEditChannel: false,
  isCertainNews: false,
  curtainLikesLoader: false,
  curtainChannelLoader: false,
  showChannelModalMenu: false,
  showChannelModalChannels: false,
  showNewsBirthdayModal: false,
  showNewEmployeeModal: false
};

export default state;
