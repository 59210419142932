<template>
  <div id="app" :class="[isDarkMode ? 'theme_night' : 'theme_day']">
    <router-view />
    <notifications />
    <ModalAddBugsAndIdea v-if="isOpenModal" :dataModal="showModal" />
  </div>
</template>

<script lang="js">
import {mapActions, mapGetters, mapState} from 'vuex';
import axios from "axios";
import {
  domain,
} from "@/globalVariables";
import centrifuge from "@/centrifuge";
import ModalAddBugsAndIdea from "@/components/FeedbackPanel/ModalAddBugsAndIdea";
import httpClient from "@/api/client/httpClient";

const Notifications = () => import("@/components/Notifications/Notifications");

export default {
  name: 'App',
  components: {ModalAddBugsAndIdea, Notifications},
  metaInfo: {
    title: 'AOP',
    titleTemplate: 'AOP'
  },
  data() {
    return {
      microLen: 0,
      countReq: 0,
      socketStatus: null,
      vh: 0,
      showModal: {
        title: 'Обратная связь',
        placeholder: 'Описание',
      }
    }
  },
  created() {
    window.addEventListener('resize', this.detectCurrentDevice);
  },
  mounted() {
    this.detectCurrentDevice();
    this.setHeightDevice();
    window.addEventListener('resize', this.setHeightDevice);
    /**
     * Обработка каждого response запроса axios
     * return => отправка в ту функцию с которой был запрос
     */
    axios.interceptors.response.use(
      (res) => {
        return res
      },
      (err) => {
        this.errorAlert(err)
        return Promise.reject(err)
      }
    )
    this.getStatusApp()
    this.socketStatus = centrifuge.subscribe(`auth@AppStatus`, (message) => {
      if (message.data.event_name === 'ChangedAppStatusEvent') {
        this.getStatusApp(true)
      }
    })

    /**
     * Блокировка кнопки назад, если включен режим Технических работ.
     */
    window.onpopstate = () => {
      if (this.$store.getters.isServiceWork) {
        window.history.forward(1)
      }
    }
  },
  beforeDestroy() {
    this.socketStatus.unsubscribe()
  },
  methods: {
    ...mapActions(['setGlobalMobileMode', 'unsetGlobalMobileMode', 'getMeta']),
    ...mapActions('TaskManagerModule/projectModule', ['getProjectById']),
    /**
     * Метод для определения, с кого устройства находится пользователь.
     */
    detectCurrentDevice() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        this.setGlobalMobileMode();
      } else {
        this.unsetGlobalMobileMode();
      }
    },
    setHeightDevice() {
      this.vh = window.innerHeight * 0.01;
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    /**
     * Получение информации о статусе приложения, на тех. работах или нет.
     * @param isCentrifuge - если из Центрифуги
     */
    getStatusApp(isCentrifuge) {
      httpClient({
        method: 'get',
        url: `${domain}/auth/User/hasAppAccess`
      })
        .then((response) => {
          this.$store.commit('setStatusServiceWork', !response.data.data.has_access)
          if (!response.data.data.has_access) {
            this.$store.dispatch('logout')
            setTimeout(() => {
              this.$router.push('/works')
            }, 0)
          } else if (response.data.data.has_access && isCentrifuge) {
            this.$router.push('/login')
          }
        })
        .catch((error) => this.errorAlert(error))
    },
  },
  computed: {
    ...mapState("bugsAndIdeasModule", ["isOpenModal"]),
    ...mapGetters(['getEndpoints']),
    isDarkMode() {
      return this.$store.getters['MainLayoutSetting/isDarkMode']
    }
  },
  watch: {}
}
</script>
<style lang="scss">
// TODO: Перенести все стили по scss файлам
@import "assets/base/color/index";
@import "assets/base/breakpoints/breakpoints";

:root {
  --vh: 0;
}

html {
  height: calc(var(--vh, 1vh) * 100);
}

body {
  font-family: SF, sans-serif !important;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  -moz-appearance: none !important;
  -webkit-appearance: none;
  overflow-x: hidden;

  * {
    font-family: "SF", sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }

  .swal2-height-auto {
    height: inherit !important;
  }
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#app {
  width: 100%;
  height: 100%;
}

p {
  margin: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none !important;
}

::selection {
  background-color: $color-elements-background;
  color: $color-text-primary-1;
}
</style>
<style lang="scss">
@import "assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
@import "assets/base/mixins/ModalConfirm";

.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.swal2-container {
  font-family: "SF", sans-serif !important;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 32, 44, 0.8) !important;
}

.swal2-popup {
  position: relative;
  width: 367px !important;
  padding: 44px 32px !important;
  background: $bg-surface-primary !important;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2) !important;
  border-radius: 24px !important;
  height: 163px;
  display: flex !important;
  flex-direction: column;
}

.swal2-header {
  order: 2;
  display: block !important;
  padding: 0 !important;
}

.swal2-title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: $text-default !important;
  text-align: left !important;

  @media (max-width: $sm) {
    font-size: 20px !important;
  }
}

.swal2-content {
  width: 100%;
  padding: 0 !important;
  margin: 0 0 24px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: $text-disabled !important;
  text-align: left !important;
  order: 1;
}

.swal2-html-container {
  color: $text-subdued;
}

.swal2-actions {
  position: absolute;
  padding: 0;
  width: max-content !important;
  margin: 0 !important;
  bottom: 44px !important;
  right: 32px !important;
}

.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 5px 12px;
  background: $action-primary;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fafaff;
  border: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  outline: none !important;

  &:hover {
    opacity: 1;
    color: #fafaff;
    background: $action-primary-hovered;
  }
}

body {
  .jodit-popup__content {
    @media screen and (max-width: 850px) and (max-height: 450px) {
      max-height: 190px !important;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      max-height: 190px !important;
    }
  }

  .jodit-popup {
    @media (max-width: 480px) {
      max-width: 35vh;
    }

    @media (max-width: 420px) {
      max-width: 22vh;
    }
  }
}
</style>
