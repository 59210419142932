<template>
  <div class="my-isp__header">
    <div class="my-isp__container">
      <div class="my-isp__wrap">
        <h2 class="my-isp__title">Мое ISP:</h2>
        <QuarterListDropDown :valueList="quarterList" :current="currentQuarter" />
      </div>
      <p class="my-isp__info">
        <span>{{ currentQuarter.status }}:</span>
        <span>{{ setDateFormatWidthShortMonthName(currentQuarter.start_date) }}</span>
        <span>-</span>
        <span>{{ setDateFormatWidthShortMonthName(currentQuarter.end_date) }}</span>
      </p>
    </div>
    <IspMyFilters />
    <ProgressBar :percent="quarterProgress" :showNumber="false" />
    <div class="my-isp__wrap my-isp__wrap_fill">
      <div class="my-isp__stats">
        Подробная статистика<span :class="{ rotate: showStatistics }"
          ><i class="icon-down" @click="showStatistics = !showStatistics"></i
        ></span>
      </div>
      <p class="my-isp__percent">
        Прогресс решения проблем в квартале: {{ quarterProgress }}% решено
      </p>
    </div>
    <IspStatistics v-if="showStatistics" :statistics="myIspStatistics" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuarterListDropDown from "@/modules/Isp/components/IspComponents/QuarterListDropDown/QuarterListDropDown";
import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import IspStatistics from "@/modules/Isp/components/IspComponents/Statistics/IspStatistics";
import IspMyFilters from "@/modules/Isp/components/IspMy/IspMyFilters";

export default {
  name: "IspMyHeader",
  mixins: [formatDateAndTime],
  props: [],
  components: { IspMyFilters, IspStatistics, ProgressBar, QuarterListDropDown },
  data() {
    return {
      showStatistics: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["getIspGroup"])
  },
  computed: {
    ...mapState("IspModule", [
      "quarterList",
      "currentQuarter",
      "quarterProgress",
      "myIspStatistics"
    ])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.my-isp {
  &__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
    margin: 0 0 24px;
    border: 1px solid $system-grey-four;
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &_fill {
      width: 100%;
      margin: 16px 0 0;
      justify-content: space-between;
    }
    /deep/ {
      .drop-down__current {
        @media (max-width: $sm) {
          font-size: 20px;
        }
        @media (max-width: $xs) {
          font-size: 16px;
        }
      }
    }
  }

  &__title {
    margin: 0 8px 0 0;
    font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    color: $text-primary;
    font-family: inherit;
    @media (max-width: $sm) {
      font-size: 20px;
    }
    @media (max-width: $xs) {
      font-size: 16px;
    }
  }

  .progress {
    width: 100%;
  }

  &__info {
    margin: 4px 0 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $text-disabled;

    span {
      margin-right: 8px;
    }
  }

  &__percent {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $text-subdued;
  }

  &__stats {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: $text-default;

    i {
      color: $text-disabled;
      margin-left: 8px;
      transition: all 0.3s ease;
    }
  }
}

.rotate {
  transform: rotate(180deg);
}
</style>
