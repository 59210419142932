











import { Component, Prop, Vue } from "vue-property-decorator";
import TimeStatisticItem from "@/modules/TimeTracker/component/TimeStatistic/TimeStatisticItem.vue";

@Component({
  components: { TimeStatisticItem }
})
export default class TimeStatisticList extends Vue {
  @Prop({
    default: null,
    type: Object
  })
  userStatistic: any;
  @Prop({
    default: false,
    type: Boolean
  })
  isShowNormal!: boolean;

  get statList() {
    if (this.userStatistic) {
      let list = [
        { type: "time", sec: this.userStatistic.total_tracked_time },
        { type: "normal", sec: this.userStatistic.month_standard_time },
        {
          type: this.userStatistic.difference_time > 0 ? "overtime" : "flawed",
          sec: Math.abs(this.userStatistic.difference_time)
        },
        { type: "vacation", sec: this.userStatistic.absence_tracked_time },
        { type: "sick", sec: this.userStatistic.sick_tracked_time }
      ];
      if (!this.isShowNormal) {
        list = list.filter((item) => item.type !== "normal");
      }
      return list;
    } else {
      return [];
    }
  }
}
