<template>
  <div class="statistics" :class="{ statistics_full: !isShowTimeTracker }">
    <div class="statistics__header">
      <h2 class="statistics__title">Активность сотрудника</h2>
      <div class="statistics__calendar">
        <MonthPickerTable
          @changeMonthYear="changeMonthYear"
          :showIcon="true"
          :showYear="false"
          :currentMonth="setCurrentStatisticMonth"
          :dateMax="setDateMax"
        />
      </div>
    </div>
    <div class="statistics__body">
      <div class="statistics__wrap" :class="{ 'statistics__body_no-time': !isShowTimeTracker }">
        <div class="statistics__work-time-widget">
          <div class="statistics__canvas canvas">
            <canvas ref="canvasTimeStatistics" width="185" height="185"></canvas>
            <div class="canvas__content">
              <h3 class="canvas__title">Рабочее время</h3>
              <p class="canvas__time">{{ statistic.left_to_work }} <span>Oсталось</span></p>
            </div>
          </div>
          <StatisticsWorkTime :statistic="statistic" :activity="dataUser.activity_statistics" />
        </div>
        <StatisticsList
          :blackSphere="dataUser.black_spheres_statistics"
          :isShowTimeTracker="isShowTimeTracker"
          :statistic="statistic"
          @showBlackSphereModal="showModalBlackSphere"
        />
      </div>
      <div class="statistics__link" v-if="isAdmin && !isShowTimeTracker" @click="handleEditTime">
        <i class="icon-edit"></i>
        <span>Редактировать время</span>
      </div>
    </div>

    <BlackSphereModal
      v-if="isBlackSphereModal"
      :sphereList="sphereList"
      :blackSphere="dataUser.black_spheres_statistics"
      @hideModal="hideModal"
    />
    <BlackSphereModalMobile
      v-if="isBlackSphereModal"
      :fullSphereList="sphereList"
      :shortSphereList="getLastFourBlackSphereItems"
      :blackSphere="dataUser.black_spheres_statistics"
      @hideModal="hideModal"
    />
  </div>
</template>

<script>
import StatisticsList from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/StatisticsList";
import StatisticsWorkTime from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/StatisticsWorkTime";
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import BlackSphereModal from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereModal";
import BlackSphereModalMobile from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereModalMobile";
import { format, parseISO } from "date-fns";
import TimeTracker from "@/modules/TimeTracker/store";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";

export default {
  name: "ProfileStat",
  mixins: [],
  props: {
    dataUser: {
      type: Object,
      default: () => {}
    },
    sphereList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    statistic: {
      type: Object,
      default: () => {}
    },
    isShowTimeTracker: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    currentStatisticMonth: {
      type: String,
      default: null
    }
  },
  components: {
    BlackSphereModalMobile,
    BlackSphereModal,
    MonthPickerTable,
    StatisticsWorkTime,
    StatisticsList
  },
  data() {
    return {
      intervalTime: 0,
      isBlackSphereModal: false
    };
  },
  beforeDestroy() {},
  mounted() {
    this.setCanvasTimeProgress();
  },
  methods: {
    hideModal() {
      this.isBlackSphereModal = false;
      BlackSphere[BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_PROFILE]();
    },
    getBlackSphereInfo() {
      BlackSphere.getBlackSphereMarksForProfileAction({
        month: this.getBlackSphereMonth,
        got_user_id: this.dataUser.id
      });
    },
    showModalBlackSphere() {
      this.getBlackSphereInfo();
      this.isBlackSphereModal = true;
    },
    handleEditTime() {
      TimeTracker.setCurrentUserId(this.dataUser.id);
      this.$router.push({ name: "WorkTime" });
    },
    /**
     * Функция отрисовки виджета рабочего времени
     */
    setCanvasTimeProgress() {
      clearInterval(this.intervalTime);
      let can = this.$refs.canvasTimeStatistics;
      let c = can.getContext("2d");
      c.clearRect(0, 0, can.width, can.height);
      let posX = can.width / 2;
      let posY = can.height / 2;
      let onePercent = 360 / 100;
      let isMinus = this.statistic.difference_time.includes("-");
      let totalPercent = this.timePercentReturner(
        this.statistic.month_normal_time,
        this.statistic.current_normal_time
      );
      let trackedPercent = this.timePercentReturner(
        this.statistic.current_normal_time,
        this.statistic.tracked_time
      );
      let differencePercent = this.timePercentReturner(
        this.statistic.current_normal_time,
        this.statistic.difference_time.includes("-")
          ? this.statistic.difference_time.replace("-", "")
          : this.statistic.difference_time
      );
      let resultTracked = 0;
      let resultDifference = 0;
      if (!isMinus) {
        resultTracked =
          onePercent *
          (((parseInt(trackedPercent) - parseInt(differencePercent)) * parseInt(totalPercent)) /
            100);
        resultDifference = onePercent * ((parseInt(trackedPercent) * parseInt(totalPercent)) / 100);
      } else {
        resultTracked = onePercent * ((parseInt(trackedPercent) * parseInt(totalPercent)) / 100);
        resultDifference =
          onePercent *
          (((parseInt(trackedPercent) + parseInt(differencePercent)) * parseInt(totalPercent)) /
            100);
      }
      c.lineCap = "round";

      let stepTotal = 0;
      let stepTracked = 0;
      let stepDifference = 0;
      let self = this;
      this.intervalTime = setInterval(function () {
        stepTotal += 1;
        if (stepTracked <= resultTracked) {
          stepTracked += 1;
        }
        if (stepDifference <= resultDifference) {
          stepDifference += 1;
        }
        c.clearRect(0, 0, can.width, can.height);

        c.beginPath();
        c.arc(posX, posY, 85, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + 360));
        c.strokeStyle = "#F2F2F7";
        c.lineWidth = "12";
        c.stroke();
        c.beginPath();
        c.strokeStyle = isMinus ? "#FFB6C9" : "#3BE8B0";
        c.lineWidth = "12";
        c.arc(posX, posY, 85, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + stepDifference));
        c.stroke();
        c.beginPath();
        c.strokeStyle = "#6A67CE";
        c.lineWidth = "12";
        c.arc(posX, posY, 85, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + stepTracked));
        c.stroke();
        if (stepTotal >= resultTracked + resultDifference) clearInterval(self.intervalTime);
      }, 1000 / 500);
    },
    /** Функция для определения процента фактического времени от общего
     * @param total - общее время
     * @param time - фактическое время
     * @returns {string} - процент фактического времени от общего, используется для заливки полосы времени
     */
    timePercentReturner(total, fact) {
      let totalTime = total.split(":")[0] * 3600 + total.split(":")[1] * 60 + total.split(":")[2];
      let factTime = fact.split(":")[0] * 3600 + fact.split(":")[1] * 60 + fact.split(":")[2];
      let pct = ((100 * factTime) / totalTime).toFixed(2);
      return pct;
    },
    changeMonthYear(date) {
      this.$root.$emit("changeStatisticsMonthPicker", date);
    }
  },
  computed: {
    setCurrentStatisticMonth() {
      return format(parseISO(this.currentStatisticMonth), "M") - 1;
    },
    getLastFourBlackSphereItems() {
      return this.sphereList.slice(0, 4);
    },
    setDateMax() {
      if (!this.dataUser.is_active) {
        return this.dataUser.date_of_dismissal;
      } else {
        return format(new Date(), "yyyy-MM");
      }
    },
    getBlackSphereMonth() {
      return BlackSphere.blackSphereCurrentMonth;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.statistics {
  width: 100%;
  height: 100%;
  flex-basis: 55%;

  &_full {
    flex-basis: 100%;
  }

  @media (max-width: $xl) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: max-content;
    min-height: unset;
  }
  @media (max-width: $sm) {
    flex-flow: column;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $xxxl) {
      padding: 24px 24px 16px;
    }

    @media (max-width: $xl) {
      width: 100%;
    }

    @media (max-width: 685px) {
      padding: 16px 16px 8px;
    }

    /deep/ {
      .month_picker_container {
        @media (max-width: $xl) {
          left: -150px !important;
        }
      }
    }
  }

  &__body {
    display: flex;
    //flex-flow: column;
    align-items: flex-start;
    width: 100%;
    position: relative;

    &_no-time {
      max-width: 580px;
      @media (max-width: 1300px) {
        max-width: 100%;
        flex-flow: row;
      }
      @media (max-width: $sm) {
        flex-flow: column;
        align-items: center;
      }
    }
  }

  &__wrap {
    @media (max-width: $md) {
      width: 100%;
      display: flex;
      flex-flow: row;
    }
    @media (max-width: $sm) {
      flex-flow: column;
    }
  }

  &__link {
    right: 12px;
    top: 12px;
    position: absolute;
    color: $link-default;
    transition: color 0.3s ease;
    cursor: pointer;
    user-select: none;
    margin-left: auto;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;

    @media (max-width: $md) {
      span {
        visibility: hidden;
        display: none;
      }
    }

    i {
      font-size: 16px;
      margin-right: 4px;
    }

    span {
      width: max-content;
    }

    @media (hover: hover) {
      &:hover {
        color: $link-hovered;
      }
    }

    &:active {
      color: $link-pressed;
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: $text-default;

    @media (max-width: 685px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__work-time-widget {
    display: flex;
    padding: 16px 32px 0;
    width: 100%;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1700px) {
      padding: 24px 24px 0;
    }

    @media (max-width: $xl) {
      width: max-content;
    }

    @media (max-width: $md) {
      padding: 24px 12px 0;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 24px 16px 0;
    }
  }

  .canvas {
    position: relative;
    display: flex;
    justify-content: center;
    margin-right: 24px;
    width: 180px;
    height: 180px;

    @media (max-width: 1700px) {
      width: 135px;
      height: 135px;
    }

    @media (max-width: $sm) {
      margin: 0 0 32px;
    }

    &__content {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &__time {
      display: flex;
      flex-direction: column;
      margin: 0;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: $text-default;

      @media (max-width: 1700px) {
        font-size: 18px;
      }

      @media (max-width: $xl) {
        font-size: 14px;
      }

      span {
        margin: 0;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        color: $text-disabled;

        @media (max-width: 1700px) {
          line-height: 11px;
        }
      }
    }

    &__title {
      margin: 0 0 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: $text-disabled;

      @media (max-width: 1700px) {
        margin: 0;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }

  .black-sphere {
    display: flex;

    @media (max-width: 685px) {
      display: none;
    }

    &_mobile {
      display: none;

      @media (max-width: 685px) {
        display: flex;
      }
    }
  }
}
</style>
