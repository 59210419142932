<template>
  <div class="layout-auth">
    <div class="layout-auth__images">
      <img class="logo-img" src="~@/assets/img/imgAuth/logoImage.svg" />
      <img class="slider-img" :src="require(`@/assets/img/imgAuth/${numberImage}.png`)" />
      <div class="decor" id="decor">
        <div data-depth="0.20" class="decor__cube1"></div>
        <div data-depth="0.60" class="decor__cube2"></div>
        <div data-depth="1.00" class="decor__ellipse3">Добро пожаловать!</div>
        <div data-depth="0.60" class="decor__circle4"></div>
        <div data-depth="0.20" class="decor__circle5"></div>
      </div>
    </div>
    <div class="layout-auth__forms">
      <div class="forms-bg">
        <img class="forms-bg__logo" src="~@/assets/img/imgAuth/logoForm.svg" />
        <div class="forms-bg__nav" v-if="isReg">
          <div @click="changeAuthMode(false)">Авторизация</div>
          <div class="chosen-nav">Регистрация</div>
        </div>
        <div class="forms-bg__nav" v-if="!isReg">
          <div @click="changeAuthMode(true)">Регистрация</div>
          <div class="chosen-nav">Авторизация</div>
        </div>
        <form v-if="!isReg">
          <div class="input-bg">
            <label for="mail-auth" class="label-input">Введите ваш email:</label>
            <input
              id="mail-auth"
              type="email"
              v-model.trim="email"
              maxlength="64"
              @keyup.enter="handleAuth"
              :placeholder="windowWidth <= 768 ? 'Введите ваш email' : ''"
            />
          </div>
          <div class="input-bg">
            <label for="pass-auth" class="label-input">Введите пароль:</label>
            <input
              id="pass-auth"
              :type="showPassword ? 'text' : 'password'"
              v-model.trim="password"
              @keyup.enter="handleAuth"
              class="input-password"
              maxlength="64"
              :placeholder="windowWidth <= 768 ? 'Введите пароль' : ''"
            />
            <i class="icon-eye" v-show="!showPassword" @click="showPassword = !showPassword"></i>
            <i
              class="icon-eye-closed"
              v-show="showPassword"
              @click="showPassword = !showPassword"
            ></i>
          </div>
          <a href="javascript:void(0)" @click="returnOldPassword">Забыли пароль?</a>
          <button class="auth-login" type="button" :disabled="isInvalid" @click="handleAuth">
            Войти
          </button>
        </form>
        <form v-if="isReg">
          <div class="input-bg">
            <label for="mail-reg" class="label-input">Введите ваш email:</label>
            <input
              id="mail-reg"
              type="email"
              v-model.trim="email"
              maxlength="64"
              @keyup.enter="createAccount"
              :placeholder="windowWidth <= 768 ? 'Введите ваш email' : ''"
            />
          </div>
          <div class="input-bg">
            <label for="name-reg" class="label-input">Введите ваше Имя:</label>
            <input
              id="name-reg"
              type="text"
              v-model.trim="name"
              maxlength="64"
              @keyup.enter="createAccount"
              :placeholder="windowWidth <= 768 ? 'Введите ваше Имя' : ''"
            />
          </div>
          <div class="input-bg">
            <label for="pass-reg" class="label-input">Введите пароль:</label>
            <input
              id="pass-reg"
              :type="showPassword ? 'text' : 'password'"
              v-model.trim="password"
              @keyup.enter="createAccount"
              class="input-password"
              maxlength="64"
              :placeholder="windowWidth <= 768 ? 'Введите пароль' : ''"
            />
            <i class="icon-eye" v-show="!showPassword" @click="showPassword = !showPassword"></i>
            <i
              class="icon-eye-closed"
              v-show="showPassword"
              @click="showPassword = !showPassword"
            ></i>
          </div>
          <button
            class="auth-registration"
            type="button"
            :disabled="isInvalidReg"
            @click="createAccount"
          >
            Зарегистрироваться
          </button>
        </form>
        <ModalReturnPassword :editValue="email" v-if="showModalReturnPassword" />
        <ModalChangePassword v-if="showModalChangePassword" />
      </div>
      <div class="decor-form" id="decorForm">
        <div data-depth="0.60" class="decor-form__circle1"></div>
        <div data-depth="0.20" class="decor-form__circle2"></div>
      </div>
    </div>
    <div class="button-bugs" @click="openModal">
      <div class="button-bugs__circle">
        <i class="icon-bug"></i>
      </div>
    </div>
  </div>
</template>
<script>
import authEvents from "@/helpers/authEvents";
import ModalReturnPassword from "@/layout/ModalReturnPassword";
import ModalChangePassword from "@/layout/ModalChangePassword";
import Parallax from "parallax-js";
import { mapActions } from "vuex";
import Auth from "@/store/Auth";
import CryptoJS from "crypto-js";

const ModalAddBugsAndIdea = () => import("@/components/FeedbackPanel/ModalAddBugsAndIdea");

export default {
  name: "LayoutAuth",
  components: { ModalAddBugsAndIdea, ModalChangePassword, ModalReturnPassword },
  mixins: [authEvents],
  data() {
    return {
      showPassword: false,
      numberImage: 1,
      windowWidth: 0
    };
  },
  beforeDestroy() {},
  mounted() {
    let decor = document.getElementById("decor");
    let parallaxDecor = new Parallax(decor);
    let decorForm = document.getElementById("decorForm");
    let parallaxDecorForm = new Parallax(decorForm);

    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();
  },
  created() {
    this.numberImage = Math.round(1 - 0.5 + Math.random() * (7 - 1 + 1));
  },
  methods: {
    ...mapActions("bugsAndIdeasModule", ["openModal"]),
    changeAuthMode(val) {
      this.isReg = val;
      this.showPassword = false;
    },
    handleAuth() {
      this.auth();
      this.sendPassword();
    },
    sendPassword() {
      const loginData = {
        password: CryptoJS.MD5(this.password).toString(),
        email: this.email.toLowerCase(),
        device_type: "web"
      };
      Auth.login(loginData);
    },
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/fonts/index.scss";

.layout-auth {
  display: flex;
  width: 100%;
  height: 100vh;
  background: $text-on-interactive;
  @media screen and (max-width: $xxl) {
    justify-content: center;
  }

  &__images {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    @media screen and (max-width: $xxl) {
      display: none;
    }

    .logo-img {
      position: fixed;
      left: 2%;
      bottom: 3%;
    }

    .slider-img {
      width: 100%;
      object-fit: cover;
    }

    .decor {
      width: 650px;
      height: 550px;
      position: absolute;
      right: 150px;
      bottom: 150px;
      @media screen and (max-width: 1600px) {
        width: 450px;
        height: 350px;
      }

      &__cube1 {
        position: absolute !important;
        left: 0 !important;
        top: 100px !important;
        height: 60px;
        width: 60px;
        border-radius: 3px;
        background: #0084ff;
        @media screen and (max-width: 1600px) {
          height: 50px;
          width: 50px;
        }
      }

      &__cube2 {
        position: absolute !important;
        left: 70px !important;
        top: 65% !important;
        height: 86px;
        width: 86px;
        border-radius: 3px;
        background: #ffb900;
        @media screen and (max-width: 1600px) {
          height: 66px;
          width: 66px;
        }
      }

      &__ellipse3 {
        position: absolute !important;
        left: 20% !important;
        top: 35% !important;
        width: fit-content;
        padding: 22px 14px;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        color: #ffffff;
        background: #6a67ce;
        border-radius: 100px;
        @media screen and (max-width: 1600px) {
          font-size: 30px;
          padding: 14px 14px;
        }
      }

      &__circle4 {
        position: absolute !important;
        left: 80% !important;
        top: 10% !important;
        height: 110px;
        width: 110px;
        border-radius: 50%;
        background: #ffb900;
        @media screen and (max-width: 1600px) {
          height: 70px;
          width: 70px;
        }
      }

      &__circle5 {
        position: absolute !important;
        left: 100% !important;
        top: 0 !important;
        height: 56px;
        width: 56px;
        border-radius: 50%;
        background: #0084ff;
        @media screen and (max-width: 1600px) {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  &__forms {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    flex-shrink: 0;

    .forms-bg {
      display: flex;
      flex-direction: column;

      &__logo {
        width: 120px;
        margin-bottom: 30px;
        @media screen and (max-width: 850px) and (orientation: landscape) {
          margin-bottom: 10px;
        }
      }

      &__nav {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        @media screen and (max-width: 850px) and (orientation: landscape) {
          margin-bottom: 5px;
        }

        div {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: $text-disabled;
          cursor: pointer;
          user-select: none;
        }

        .chosen-nav {
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 60px;
          color: #323232;
          @media screen and (max-width: $xxl) {
            font-size: 30px;
          }
          @media screen and (max-width: 850px) and (orientation: landscape) {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $md) {
        }

        .input-bg {
          display: flex;
          align-items: center;
          padding: 12px;
          background: #fafbfc;
          border-radius: 24px;
          margin-top: 50px;
          @media screen and (max-width: $xxl) {
            margin-top: 25px;
          }
          @media screen and (max-width: 850px) and (orientation: landscape) {
            margin-top: 10px;
          }

          .label-input {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $text-subdued;
            margin-right: 5px;
            margin-bottom: 0;
            @media screen and (max-width: $xxl) {
              font-size: 13px;
            }
            @media screen and (max-width: $md) {
              display: none;
            }
          }

          input {
            width: 260px;
            height: 24px;
            border: none;
            background: none;
            outline: none;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $text-disabled;
            filter: none;
            @media screen and (max-width: $xxl) {
              width: 150px;
              font-size: 13px;
            }
            @media screen and (max-width: $md) {
              width: 250px;
            }

            &:focus {
              color: $text-default;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              border: none;
              -webkit-text-fill-color: $text-subdued;
              -webkit-box-shadow: 0 0 0px 1000px #fafbfc inset;
              transition: background-color 5000s ease-in-out 0s;
            }
          }

          i {
            cursor: pointer;
          }
        }

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: $text-highlight;
          margin-top: 20px;
          margin-left: 12px;
          outline: none;
          @media screen and (max-width: $xxl) {
            font-size: 13px;
          }
          @media screen and (max-width: 850px) and (orientation: landscape) {
            margin-top: 10px;
          }
        }

        button {
          background: $text-highlight;
          border-radius: 100px;
          border: none;
          margin: 66px auto 0;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          color: $text-on-interactive;
          outline: none;
          padding: 12px 70px;
          @media screen and (max-width: $xxl) {
            font-size: 15px;
            padding: 12px 30px;
          }
          @media screen and (max-width: $sm) {
            margin: 30px auto 0;
          }
          @media screen and (max-width: 850px) and (orientation: landscape) {
            font-size: 12px;
            margin: 10px auto 0;
            padding: 8px 30px;
          }
        }
      }
    }

    .decor-form {
      width: 200px;
      height: 100px;
      position: absolute;
      right: 0;
      top: 0;
      @media screen and (max-width: $lg) {
        display: none;
      }

      &__circle1 {
        position: absolute !important;
        left: 40% !important;
        top: 40% !important;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background: #ffb900;
      }

      &__circle2 {
        position: absolute !important;
        left: 75% !important;
        top: 10% !important;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: #0084ff;
      }
    }
  }

  .button-bugs {
    position: fixed;
    right: 0;
    bottom: 3%;
    width: 100px;
    height: 70px;
    background: #0085ff;
    border-radius: 100px 0 0 100px;
    padding: 14px;
    cursor: pointer;
    transition: 0.3s;
    @media screen and (max-width: $xxl) {
      width: 80px;
      height: 50px;
      padding: 8px;
    }
    @media screen and (max-width: 320px) {
      width: 50px;
    }

    &:hover {
      width: 120px;
      @media screen and (max-width: $xxl) {
        width: 100px;
      }
      @media screen and (max-width: 320px) {
        width: 50px;
      }
    }

    &__circle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background: #ffffff;
      @media screen and (max-width: $xxl) {
        height: 35px;
        width: 35px;
      }

      i {
        font-size: 24px;
        @media screen and (max-width: $xxl) {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
