<template>
  <div
    class="structure-card"
    :style="!item.children_items.length || !openChildren ? 'padding: 0 20px;' : ''"
  >
    <div class="structure-card__content" :id="`child${item.id}`">
      <div
        class="card"
        :style="openChildren ? '' + 'filter: drop-shadow(2px 5px 10px rgba(0, 0, 0, 0.05));' : ''"
      >
        <svg
          width="234"
          height="217"
          viewBox="0 0 234 217"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10V12V13V209C0 213.418 3.58172 217 8 217H226C230.418 217 234 213.418 234 209V20C234 15.5817 230.418 12 226 12H145.339L137.412 0H10Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M144.801 13L136.874 1H10C5.02944 1 1 5.02944 1 10V209C1 212.866 4.13401 216 8 216H226C229.866 216 233 212.866 233 209V20C233 16.134 229.866 13 226 13H144.801ZM137.412 0L145.339 12H226C230.418 12 234 15.5817 234 20V209C234 213.418 230.418 217 226 217H8C3.58172 217 0 213.418 0 209V10C0 4.47715 4.47715 0 10 0H137.412Z"
            fill="#E7E7FB"
          />
        </svg>
        <div class="card__content">
          <div class="header-content">
            <div class="header-content__name" @click="ViewCardModal">{{ item.name }}</div>
            <div class="header-content__tooltips">
              <div class="tooltip_final">
                <div class="tooltip_final__label" v-if="item.final_product">
                  {{ item.final_product }}
                </div>
                <i class="icon-file"></i>
              </div>
              <div class="tooltip_prompt">
                <div class="tooltip_prompt__label" v-if="item.prompt">{{ item.prompt }}</div>
                <i class="icon-info"></i>
              </div>
            </div>
          </div>
          <div class="main-content">
            <router-link
              :to="{ name: 'UserInfo', params: { user_id: item.responsible_user.id } }"
              :target="isGlobalMobileMode ? '_self' : '_blank'"
              class="main-content__member"
              v-if="item.responsible_user"
            >
              <img :src="returnSrcFile(item.responsible_user.avatar)" alt="" />
              <div class="member-info">
                <div class="member-info__name">{{ item.responsible_user.full_name }}</div>
                <div class="member-info__role">Ответственный</div>
              </div>
            </router-link>
            <router-link
              :to="{ name: 'UserInfo', params: { user_id: item.assistant_user.id } }"
              :target="isGlobalMobileMode ? '_self' : '_blank'"
              class="main-content__member"
              v-if="item.assistant_user"
            >
              <img :src="returnSrcFile(item.assistant_user.avatar)" alt="" />
              <div class="member-info">
                <div class="member-info__name">{{ item.assistant_user.full_name }}</div>
                <div class="member-info__role">Заместитель</div>
              </div>
            </router-link>
          </div>
          <div class="footer-content">
            <div class="footer-content__members">
              <i class="icon-group" @click="$root.$emit('openModalViewCardMembers', item)"></i>
              <div>{{ item.count_users }}</div>
            </div>
            <div class="footer-content__responsibilities" @click="openResponsibilities">Задачи</div>
          </div>
        </div>
        <div class="card__settings-card" v-if="isAdmin">
          <i class="icon-edit" @click="editCard"></i>
          <i class="icon-plus" @click="createChildrenCard"></i>
          <i class="icon-delete" @click="askDeleteCard"></i>
        </div>
      </div>
      <div class="connection-children" v-if="item.children_items.length">
        <div class="connection-children__line"></div>
        <div class="connection-children__count" @click="showChildren">
          {{ !openChildren ? item.children_items.length : "-" }}
        </div>
      </div>

      <div class="line" :style="{ width: withLine + 'px' }" :id="`line${item.id}`"></div>
    </div>
    <div
      class="structure-card__children"
      :class="hasGrandChild ? 'structure-card__children_col' : ''"
      v-if="openChildren && item.children_items.length"
    >
      <StructureCard
        v-for="(card, index) in item.children_items"
        :key="card.id"
        :item="card"
        :childs="item.children_items"
        :index="index"
        :isAdmin="isAdmin"
        :reRender="reRender"
        @openModalViewCardMembers="
          $emit('openModalViewCardMembers', card);
          $root.$emit('getCurrentCardData', card);
        "
      />
    </div>
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import Swal from "sweetalert2";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});
export default {
  name: "StructureCard",
  mixins: [],
  props: ["item", "childs", "index", "reRender", "isAdmin"],
  components: {},
  data() {
    return {
      withLine: 0,
      openChildren: true
    };
  },
  mounted() {
    this.getWidth();
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    ViewCardModal() {
      this.$root.$emit("showViewCardModal", { type: false, data: this.item });
    },
    showChildren() {
      if (this.item.children_items.length) {
        this.openChildren = !this.openChildren;
        this.$nextTick(() => {
          this.$root.$emit("goReRender");
        });
      }
    },
    createChildrenCard() {
      this.$root.$emit("showCreateChangeCardModal", { type: false, data: this.item });
    },
    editCard() {
      this.$root.$emit("showCreateChangeCardModal", { type: true, data: this.item });
    },
    openResponsibilities() {
      this.$root.$emit("showResponsibilitiesCard", this.item);
    },
    getWidth() {
      let child = this.childs[this.index + 1];
      if (child) {
        let nextChild = document.getElementById(`child${child.id}`);
        let nowChild = document.getElementById(`child${this.item.id}`);
        this.withLine = nextChild.offsetLeft - nowChild.offsetLeft;
      } else {
        this.withLine = 0;
      }
    },
    deleteCard() {
      httpClient({
        url: `/OrgStructureItem/actionDelete/${this.item.id}`,
        method: "DELETE"
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Карточка успешно удалена!`
          });
          this.$root.$emit("updateStructureChart");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    askDeleteCard() {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить карточку?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteCard();
          }
        });
    }
  },
  computed: {
    hasGrandChild() {
      return this.item.hasOwnProperty("has_grand_children") && !this.item.has_grand_children;
    },

    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {
    reRender() {
      this.getWidth();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.structure-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  &__content {
    position: relative;

    .card {
      width: 234px;
      height: 217px;
      position: relative;
      z-index: 2;
      background: transparent;
      border: none;

      &__content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 234px;
        height: 217px;
        padding: 25px 15px 15px;
        box-sizing: border-box;

        .header-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15px;
          border-bottom: 1px solid $color-contour;
          margin-bottom: 10px;

          &__name {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 15px;
            color: $color-text-primary-1;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .icon-file,
          .icon-info {
            font-size: 21px;
            color: $color-interactive;
          }

          &__tooltips {
            display: flex;
            align-items: center;
            height: 100%;

            .tooltip_final {
              position: relative;
              margin-right: 10px;
              display: flex;
              align-items: center;
              height: 100%;

              &__label {
                display: none;
                position: absolute;
                width: max-content;
                padding: 6px 12px;
                background: #fdfefe;
                border: 1px solid #e7e7fb;
                border-radius: 4px;
                bottom: calc(100% + 15px);
                left: -10px;
                max-width: 200px;
                z-index: 10;
                word-break: break-word;
              }
            }
            .tooltip_final:hover {
              .tooltip_final__label {
                display: block;
              }
            }

            .tooltip_prompt {
              position: relative;
              display: flex;
              align-items: center;
              height: 100%;

              &__label {
                display: none;
                position: absolute;
                padding: 6px 12px;
                background: #fdfefe;
                border: 1px solid #e7e7fb;
                border-radius: 4px;
                left: calc(100% + 20px);
                top: -5px;
                width: max-content;
                max-width: 200px;
                z-index: 10;
                word-break: break-word;
              }
            }

            .tooltip_prompt:hover {
              .tooltip_prompt__label {
                display: block;
              }
            }
          }
        }

        .main-content {
          display: flex;
          flex-direction: column;
          height: 100%;

          &__member {
            display: flex;
            margin-bottom: 13px;

            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              object-fit: cover;
              flex-shrink: 0;
            }

            .member-info {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              width: calc(100% - 45px);
              &__name {
                width: 100%;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: $color-text-accent;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &__role {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                color: $color-text-primary-2;
              }
            }
          }

          &__member:hover {
            .member-info {
              &__name {
                color: $color-interactive;
              }

              &__role {
                color: $color-interactive;
              }
            }
          }
        }

        .footer-content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__members {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            color: $color-text-primary-2;

            .icon-group {
              display: block;
              margin-right: 10px;
              color: $color-interactive;
              font-size: 21px;
            }
          }

          &__responsibilities {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 13px;
            color: $color-interactive;
            cursor: pointer;
          }
        }
      }

      &__settings-card {
        display: none;
        position: absolute;
        width: 100%;
        top: 100%;
        border-radius: 50%;
        z-index: 3;

        .icon-edit,
        .icon-delete {
          color: $color-interactive;
          border: 1px solid $color-interactive;
          border-radius: 50%;
          padding: 5px;
          font-size: 13px;
        }

        .icon-plus {
          color: $main-white;
          border-radius: 50%;
          padding: 6px;
          font-size: 13px;
          background-color: $color-interactive;
        }
      }
    }

    .card-blocked {
      width: 234px;
      height: 217px;
      position: relative;
      z-index: 2;
      background: transparent;

      &__not-public {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 234px;
        height: 217px;
        padding: 25px 15px 15px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;
        color: #b8b8cb;

        svg {
          margin-bottom: 10px;
        }
      }
    }

    .connection-children {
      display: flex;
      align-items: center;
      flex-direction: column;

      &__line {
        width: 1px;
        height: 43px;
        background-color: $color-interactive;
      }

      &__count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid $color-interactive;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $color-text-primary-2;
        user-select: none;
        cursor: pointer;
      }
    }

    .line {
      position: absolute;
      top: 115px;
      left: 50%;
      height: 1px;
      background-color: $color-interactive;
      z-index: 1;
    }
  }

  &__children {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
    &_col {
      flex-direction: column;
      .structure-card {
        &:not(:last-child) {
          &:after {
            content: "";
            width: 1px;
            height: 43px;
            background-color: $color-interactive;
          }
        }
      }
    }
  }

  &__content:hover {
    cursor: pointer;

    .card {
      z-index: 10;

      svg {
        path:last-child {
          fill: $color-interactive;
        }
      }

      &__content {
        .header-content {
          &:hover .header-content__name {
            color: $color-interactive;
          }
          &__tooltips {
            svg {
              path:last-child {
                fill: none;
              }
            }
          }
        }
      }

      &__settings-card {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          path:last-child {
            fill: white;
          }
        }
      }
    }
  }
}
</style>
