<template>
  <div class="modal" @mousedown.self="closeRejectProblemModal">
    <div class="modal__window">
      <h2 class="modal__title">Отправить проблему на доработку?</h2>
      <Textarea
        :placeholder="'Причина возврата проблемы на доработку'"
        :ref="'textareaReject'"
        :maxlength="1024"
        type="text"
        v-model.trim="value"
        @input="mixin_autoResize_resize"
      />
      <div class="modal__footer">
        <Button :type="'cancel'" :title="'Отменить'" @handleButton="closeRejectProblemModal" />
        <Button :title="'Отправить'" @handleButton="sendComment" />
      </div>
    </div>
    <ConfirmModal
      v-if="confirmClose"
      :title="'Закрыть'"
      :description="'Введенные данные не будут сохранены. Закрыть?'"
      @cancel="cancelClose"
      @accept="acceptClose"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import ClickOutside from "vue-click-outside";
import Button from "@/modules/UiKit/components/buttons/Button";
import ResizeMixin from "@/helpers/ResizeMixin";
import NotificationObserver from "@/helpers/UserNotification";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";

export default {
  name: "ISPProblemRejectModal",
  mixins: [ResizeMixin],
  props: {},
  components: { ConfirmModal, Textarea, Button },
  data() {
    return {
      value: "",
      confirmClose: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", [
      "handleUpdateProblem",
      "createProblemCommentAction",
      "getProblemCommentsAction"
    ]),
    sendComment() {
      if (!this.checkValidationData()) return false;
      this.createProblemCommentAction({
        content: this.value,
        id: this.currentInfoProblem.id
      }).then(() => {
        this.getProblemCommentsAction(this.currentInfoProblem.id);
        this.value = "";
      });
      this.handleUpdateProblem(this.rejectParams);
      this.$emit("close");
    },
    checkValidationData() {
      if (!this.value) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите текст комментария!"
        });
        return false;
      }

      if (this.value.length > 1024) {
        NotificationObserver.notification({
          type: "error",
          message: "Комментарий не может быть больше 1024 символов!"
        });
        return false;
      }
      return true;
    },
    cancelClose() {
      this.confirmClose = false;
    },
    acceptClose() {
      this.$emit("close");
    },
    closeRejectProblemModal() {
      if (this.value.length) {
        this.confirmClose = true;
      } else {
        this.$emit("close");
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentInfoProblem", "statusesList"]),
    rejectParams() {
      return {
        data: {
          flow_status_id: this.statusesList.find((item) => item.type === "rework").id,
          status: this.statusesList.find((item) => item.type === "rework"),
          reconciliation: true
        },
        oldPeriodId: this.currentInfoProblem.period_id
      };
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 55, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &__window {
    padding: 32px 24px;
    width: 716px;
    background: $surface-default;
    border-radius: 16px;
  }

  &__title {
    margin: 0 0 40px;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__footer {
    margin: 40px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__files {
    display: flex;
    align-items: center;
    margin: 16px 0 0;
  }

  /deep/ {
    .textarea__control {
      width: 100%;
      min-height: 120px !important;
    }

    .wrap-docs__content {
      margin: 0;
    }

    .doc {
      margin: 0 0 0 16px;
    }

    label,
    input {
      cursor: pointer;
    }
  }
}
</style>
