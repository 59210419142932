<template>
  <div class="kanban-setting__body">
    <div class="kanban-setting__add-column">
      <Button
        :title="'Добавить столбец'"
        :type="'simple'"
        :icon="'add-box'"
        @handleButton="createNewColumn"
      />
    </div>
    <div class="kanban-setting__container">
      <div
        class="kanban-setting__area"
        ref="kanbanArea"
        :class="{ 'kanban-setting__area_full': !showUnattachedStatuses }"
      >
        <draggable
          class="kanban-setting__draggable"
          :list="columns"
          group="columns"
          @start="drag = true"
          @end="changeColumn($event, drag)"
        >
          <KanbanSettingColumn v-for="column in columns" :column="column" :key="column.id" />
        </draggable>
        <KanbanSettingColumn
          v-for="column in createParams"
          :column="column"
          :key="column.id"
          :createMode="createMode"
          @createColumn="createColumn"
          @hideCreate="hideCreate"
        />
        <div class="kanban-setting__skeleton skeleton" v-if="loader">
          <div class="skeleton__column" v-for="item in 3" :key="item">
            <div class="skeleton__header"></div>
            <div class="skeleton__list"></div>
          </div>
        </div>
      </div>
      <KanbanUnattachedStatuses
        :statuses="columns"
        :showUnattachedStatuses="showUnattachedStatuses"
        @toggleShow="toggleShow"
      />
    </div>
  </div>
</template>

<script>
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
import Button from "@/modules/UiKit/components/buttons/Button";
import KanbanUnattachedStatuses from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanUnattachedStatuses";
import KanbanSettingColumn from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanSettingColumn";
import draggable from "vuedraggable";

export default {
  name: "KanbanSettingBody",
  mixins: [],
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  },
  components: { KanbanSettingColumn, KanbanUnattachedStatuses, Button, draggable },
  data() {
    return {
      showUnattachedStatuses: true,
      createMode: false,
      createParams: []
    };
  },
  mounted() {},
  methods: {
    toggleShow() {
      this.showUnattachedStatuses = !this.showUnattachedStatuses;
    },
    createNewColumn() {
      this.createParams = [
        {
          id: null,
          board_id: this.board ? this.board.id : null,
          name: "",
          order: 0,
          status_links: []
        }
      ];
      this.$nextTick(() => {
        this.createMode = true;
        this.$refs.kanbanArea.scrollTo({
          left: this.$refs.kanbanArea.clientWidth,
          behavior: "auto"
        });
        setTimeout(() => {
          const input = document.getElementById(`input_null`);
          if (input) input.focus();
        }, 0);
      });
    },
    createColumn(name) {
      KanbanModule.createBoardColumnAction({
        board_id: this.board ? this.board.id : null,
        name: name
      });
      this.hideCreate();
    },
    hideCreate() {
      this.createMode = false;
      this.createParams = [];
    },
    changeColumn(event, drag) {
      drag = false;
      KanbanModule.updateBoardColumnAction({
        order: Number(event.newIndex) + 1,
        id: Number(event.item.lastElementChild.id)
      });
    }
  },
  computed: {
    loader() {
      return KanbanModule.boardLoader;
    },
    board() {
      return KanbanModule.currentBoard;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";
@import "~@/assets/base/mixins/Skeleton.mixin.scss";

::-webkit-scrollbar {
  height: 0;
  width: 6px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.kanban-setting {
  &__body {
    width: 100%;
  }

  &__add-column {
    width: 100%;
    padding: 24px;
    background: #fafafe;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $sm) {
      padding: 16px 8px;
    }

    /deep/ span {
      color: $text-default;
    }
  }

  &__container {
    position: relative;
    width: 100%;
    display: flex;
    height: calc(100vh - 180px);
    overflow-x: hidden;
  }

  &__area {
    max-width: calc(100% - 400px);
    width: 100%;
    background: #f2f2f7;
    height: 100%;
    padding: 24px;
    display: flex;
    overflow-x: scroll;
    transition: all 0.3s ease;

    @media (max-width: $sm) {
      max-width: calc(100% - 200px);
    }

    &_full {
      @media (max-width: $md) {
        max-width: 100%;
      }
    }
  }

  &__draggable {
    display: flex;
  }

  &__column {
    margin-right: 8px;
  }
}

.skeleton {
  width: 100%;
  display: flex;
  overflow-x: scroll;

  &__column {
    min-width: 320px;
    width: 320px;
    height: 100%;
    margin-right: 8px;
    border-radius: 8px;
  }

  &__header {
    width: 100%;
    height: 40px;
    margin: 0 0 24px;
    border-radius: 4px;
    @include background-gradient();
  }

  &__list {
    width: 100%;
    height: calc(100% - 100px);
    background: $secondary;
    border-radius: 8px;
    padding: 24px;
    margin: 0;
    list-style: none;
    transition: all 0.3s ease;
  }
}
</style>
