<template>
  <div class="isp-team-title" v-click-outside="editTitle">
    <h2
      @click="setEditMode"
      v-show="!editMode"
      v-if="!currentTeam.is_archived && isEditable"
      class="isp-team-title-editable"
    >
      {{ currentTeam.name }}
    </h2>
    <h2 v-else>{{ currentTeam.name }}</h2>
    <div class="isp-team-info__edit" v-show="editMode">
      <Input
        :placeholder="'Название команды'"
        v-model="editableTitle"
        @keydownEnter="editTitle"
        maxlength="70"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
export default {
  name: "IspTeamTitle",
  mixins: [],
  props: {
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  components: { Input },
  data() {
    return {
      editMode: false,
      editableTitle: ""
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", ["editTeamAction"]),
    setEditMode() {
      this.editMode = true;
      this.editableTitle = this.currentTeam.name;
      this.$emit("setEditMode");
    },
    editTitle(e) {
      // const parentNode = e ? e.path.find((item) => item.className === "isp-team-info") : null;
      if (this.editMode) {
        const editedTeam = {
          name: this.editableTitle,
          member_users: this.currentTeam.member_users.map((item) => item.id),
          leader_user_id: this.currentTeam.leader_user_id
        };
        this.editTeamAction({
          editedTeam,
          id: this.currentTeam.id
        }).then(() => {
          this.editMode = false;
          this.currentTeam.name = this.editableTitle;
          this.$emit("cancelEditMode");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Название команды успешно изменено!`
          });
        });
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["currentTeam", "currentTeams"])
  },
  watch: {
    currentTeam() {
      this.editMode = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
.isp-team-title {
  width: 100%;
  //height: 35px;
  h2 {
    margin: 0;
    padding: 0;
    width: fit-content;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 2px 8px;
    word-break: break-word;
  }
  &-editable {
    &:hover {
      border: 1px solid #d2d2d9;
      box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    }
  }
  /deep/ {
    input {
      width: 100%;
    }
  }
}
</style>
