<template>
  <div class="tasks__task-body task-body" @paste="onPaste">
    <div class="task-body__tabs">
      <button class="task-body__menu" type="button" @click="openSidebar">
        <i class="icon-menu"></i>
      </button>
      <button class="task-body__back" type="button" @click="goMyTasks">
        <i class="icon-arrow-back"></i>
      </button>
      <TabsContent :tabs="tabsList" v-model="currentTab" />
    </div>
    <div class="task-body__container" v-if="showBody">
      <div class="task-body__statuses">
        <TaskStatuses
          :task="task"
          :isEdit="isEdit"
          :isOwner="isOwner"
          :isMember="isMember"
          :isAuthor="isAuthor"
          :isExecutor="isExecutor"
          :setStatus="setStatus"
          @handleEditDone="handleEditDone"
          @completeTask="completeTask"
          @setCompleteStatus="setCompleteStatus"
          @showConfirm="showConfirm"
        />
        <ActionsList :actionList="setActionList" @handleAction="handleAction" />
      </div>
      <div class="task-body__header">
        <div class="task-body__alias">{{ setAlias }}-{{ task.task_id }}</div>
        <div class="task-body__title">
          <EditField
            classSize="h2"
            keyUser="name"
            :maxLength="255"
            :user="setName"
            :isReadonly="!isEdit"
            @editDone="handleEditName"
            placeholder="Добавить название задачи"
          />
        </div>
        <div class="task-body__content" v-if="task.parent_task_name">
          <router-link class="task-body__link" :to="setRoute">
            {{ task.parent_task_name }}
          </router-link>
        </div>
        <div class="task-body__content" v-if="!task.parent_task_name">
          <router-link
            v-if="task.project_id"
            class="task-body__link"
            :to="{ name: 'Project', params: { id: task.project_id } }"
          >
            <span>Проект:</span>{{ setProjectName }}
          </router-link>
        </div>
        <div class="task-body__stage">
          <p class="task-body__content" v-if="!showEditStage">
            <span v-if="!task.stage_id">Этап:</span>
            <span v-if="!task.stage_id">{{ setStageName }}</span>
            <span v-if="task.stage_id" class="task-body__link" @click="goToStage">
              Этап: {{ setStageName }}
            </span>
          </p>
          <Select
            v-if="showEditStage"
            :placeholder="'Выберите этап'"
            :items="setStageList"
            v-model="parentStage"
            :multiple="false"
            :showAvatar="false"
            :removeCross="false"
            :isLoading="stagePaginationLoader"
            @input="editStage"
            @reachLastItem="getMoreStages"
          />
          <button
            v-if="isStageEdit"
            class="task-body__button task-body__button_edit"
            type="button"
            @click="toggleEditStage(!showEditStage)"
          >
            <i class="icon-edit" v-if="!showEditStage"></i>
            <i class="icon-cross" v-if="showEditStage"></i>
          </button>
        </div>
        <TaskDataTimeParams
          :task="task"
          :isEdit="isEdit"
          :isOwner="isOwner"
          :isMember="isMember"
          :isAuthor="isAuthor"
          :isExecutor="isExecutor"
          :setStatus="setStatus"
          @handleEditDone="handleEditDone"
          @completeTask="completeTask"
          @setCompleteStatus="setCompleteStatus"
          @showConfirm="showConfirm"
        />
      </div>
      <div class="task-body__description">
        <details open>
          <summary class="task-body__summary-row">
            <h3 v-if="isSubTask" class="task-body__summary-title">
              Описание подзадачи
              <i class="icon-down-secondary"></i>
            </h3>
            <h3 v-else class="task-body__summary-title">
              Описание задачи
              <i class="icon-down-secondary"></i>
            </h3>
          </summary>
          <div class="task-body__body">
            <EditField
              classSize="text"
              keyUser="description"
              :maxLength="32000"
              :user="setDescription"
              :isReadonly="!isEdit"
              v-if="isEdit"
              @editDone="handleEditDescription"
              :placeholder="setDescriptionPlaceholder"
            />
            <p
              v-if="!isEdit"
              class="task-body__task-description"
              ref="taskDescription"
              :style="
                bigTextTrigger
                  ? `display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden;`
                  : ''
              "
              v-html="setURL(`<span>${task.description ? task.description : '-'}</span>`)"
            ></p>
            <button
              class="task-body__button"
              type="button"
              @click="showBigText"
              v-if="bigTextTrigger && !isEdit && task.description"
            >
              Показать все
            </button>
            <span
              class="task-body__button"
              type="button"
              @click="hideBigText"
              v-if="!bigTextTrigger && !isEdit && task.description"
              >Свернуть</span
            >
          </div>
        </details>
      </div>
      <div class="task-body__files">
        <details open>
          <summary
            class="task-body__summary-row"
            :style="!filesList.length ? 'border-bottom: none;' : ''"
          >
            <h3 class="task-body__summary-title">
              Прикрепленные документы
              <i class="icon-down-secondary"></i>
            </h3>

            <file-upload
              v-if="filesList.length < 10 && isEdit"
              :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
              post-action="/upload/post"
              class="file-uploader"
              input-id="taskBody"
              :multiple="true"
              :drop="true"
              v-model="files"
              :drop-directory="true"
              @input="handleUploadFile"
              ref="upload"
              id="upload"
            >
              <Button
                v-if="filesList.length < 10 && isEdit"
                class="task-body__button"
                :type="'simple'"
                :icon="'plus'"
                :title="'Добавить'"
              />
            </file-upload>
          </summary>
          <div class="task-body__body" v-if="filesList.length">
            <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
            <FileList :fileList="filesList" :isEdit="isEdit" />
          </div>
        </details>
      </div>
      <div class="task-body__checks">
        <details open id="task-check-list">
          <summary
            class="task-body__summary-row"
            :style="!task.checklists.length && !showCheckListForm ? 'border-bottom: none;' : ''"
          >
            <h3 class="task-body__summary-title">
              Чеклист
              <i class="icon-down-secondary"></i>
            </h3>
            <Button
              class="task-body__button"
              :type="'simple'"
              :icon="'plus'"
              :title="'Добавить'"
              v-if="showCheckListButton"
              @handleButton="toggleShowCheckListForm(true)"
            />
          </summary>
          <div class="task-body__body" v-if="task.checklists.length || showCheckListForm">
            <CheckList
              :list="task.checklists"
              :isEditRoles="isEdit || isParentEditUser"
              :showCheckListForm="showCheckListForm"
              @toggleShowCheckListForm="toggleShowCheckListForm"
              @handleAccept="handleAccept"
            />
          </div>
        </details>
      </div>
      <div class="task-body__task-marks">
        <details open id="task-marks">
          <summary
            class="task-body__summary-row"
            :style="!marks.length ? 'border-bottom: none;' : ''"
          >
            <h3 class="task-body__summary-title">
              Оценка
              <i class="icon-down-secondary"></i>
            </h3>
            <Button
              class="task-body__button"
              :type="'simple'"
              :icon="'plus'"
              :title="'Поставить'"
              @handleButton="openBlackSphereModal"
            />
          </summary>
          <div class="task-body__body" v-if="marks.length">
            <BlackSphereMarks :marks="marks" :task="task" />
          </div>
        </details>
      </div>
      <ConfirmModal
        v-if="showConfirmModal"
        :title="confirmParams.title"
        :description="confirmParams.description"
        @cancel="showConfirmModal = false"
        @accept="confirmParams.callback()"
      />
    </div>
  </div>
</template>

<script>
import EditField from "@/modules/Isp/components/EditField/EditField";
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import UrlMixin from "@/helpers/UrlMixin";
import TaskDataTimeParams from "@/modules/TaskManager/components/Task/TaskComponents/TaskDataTimeParams";
import TabsContent from "@/modules/UiKit/components/Tabs/TabsContent";
import { mapMutations } from "vuex";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import copyText from "@/helpers/copyText";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import CheckList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckList";
import Button from "@/modules/UiKit/components/buttons/Button";
import NotificationObserver from "@/helpers/UserNotification";
import FileList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileList";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import FileUpload from "vue-upload-component";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import BlackSphereMarks from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/BlackSphereMarks/BlackSphereMarks";
import Select from "@/modules/UiKit/components/Select/Select";
import moment from "moment";
import TaskStatuses from "@/modules/TaskManager/components/Task/TaskComponents/TaskStatuses";
import TaskBlackSphereModule, {
  TaskBlackSphereMutationTypes
} from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";

export default {
  name: "TaskBody",
  mixins: [UrlMixin, copyText],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    },
    isParentEditUser: {
      type: Boolean,
      default: false
    },
    showBody: {
      type: Boolean,
      default: false
    },
    prevRoute: {
      type: String,
      default: null
    }
  },
  components: {
    TaskStatuses,
    DropFileUpload,
    FileList,
    EditField,
    TaskDataTimeParams,
    ActionsList,
    TabsContent,
    ConfirmModal,
    CheckList,
    Button,
    FileUpload,
    Select,
    BlackSphereMarks
  },
  data() {
    return {
      bigTextTrigger: false,
      contentHeight: 0,
      showConfirmModal: false,
      confirmParams: null,
      status: null,
      watch: false,
      currentTab: "description",
      name: "",
      description: "",
      statusInfo: false,
      confirmCloseModal: false,
      showCheckListForm: false,
      files: [],
      showEditStage: false,
      parentStage: [],
      deadline: new Date(),
      dateStart: new Date(),
      timePlan: 0,
      timeFact: 0,
      type: null,
      priority: null,
      isEditDeadline: false,
      showCalendar: false,
      showDeadlineCalendar: false
    };
  },
  mounted() {
    this.hideBigText();
    this.contentHeight = this.$refs.taskDescription.offsetHeight;
    this.$nextTick(() => {
      this.setDefaultValue();
    });
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    goMyTasks() {
      this.$router.push({ name: "MyTasks" });
      TaskManagerModuleV2.closeCurtainShowAndEditTask();
    },
    setDefaultValue() {
      if (
        this.task &&
        this.setPriorityList.length &&
        this.setTypeList.length &&
        this.setStatusesList.length
      ) {
        this.name = this.setName;
        this.description = this.task.description;
        this.deadline = this.setDeadlineDate;
        this.dateStart = this.setDateStart;
        this.priority = this.setPriority;
        this.status = this.setStatus;
        this.type = this.setType;
        this.timePlan = this.task.time_plan;
        this.timeFact = this.task.time_fact;
      }
    },
    onPaste(val) {
      if (val.clipboardData.files.length !== 0 && this.totalNumberFiles < 10) {
        TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("upload");

        Array.from(val.clipboardData.files).forEach((file) => {
          const fileItem = {
            id: file.name + Date.now(),
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            fileObject: true,
            postAction: "/upload/post"
          };
          this.files.push(fileItem);
        });

        taskModule.openModalFileUpload(this.files);
        this.files = [];
      }
    },
    handleUploadFile(val) {
      val.forEach((item) => {
        if (item.type === "") {
          item.type = item.name.split(".").slice(-1).join("").toLowerCase();
        }
      });
      TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("upload");
      taskModule.openModalFileUpload(val);
      this.files = [];
    },
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    },
    hideBigText() {
      this.bigTextTrigger = true;
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    changeCurrentTab() {
      this.$emit("changeCurrentTab", this.currentTab);
    },
    toggleShowCheckListForm(val) {
      this.showCheckListForm = val;
      if (this.showCheckListForm) {
        const details = document.getElementById("task-check-list");
        details.setAttribute("open", "open");
        this.$nextTick(() => {
          const input = document.getElementById("check-list");
          input.scrollIntoView(false + 300, { behavior: "smooth" });
          if (input) input.focus();
        });
      }
    },
    handleAccept(val) {
      if (!this.checkListValidation(val)) return false;
      TaskModule.createTaskCheckListAction({
        id: this.task.id,
        check_points: [val]
      });
      this.showCheckListForm = false;
    },
    checkListValidation(val) {
      if (!val.trim().length) {
        NotificationObserver.notification({
          type: "error",
          message: "Пункт не может быть пустым!"
        });
        return false;
      }
      return true;
    },
    completeTask() {
      if (this.isEndStatus) {
        this.$emit("handleEdit", {
          status_id: this.setStatusesList.find((item) => item.is_start === true).id
        });
      } else {
        if (this.isSubTask) {
          this.setConfirmParams(true);
          this.showConfirmModal = true;
        } else {
          if (this.task.childs_count && this.task.childs_count > 0) {
            TaskModule.checkSubtaskStatusesAction({ id: this.task.id });
            if (TaskModule.completeSubtasks) {
              this.setCompleteStatus();
            } else {
              this.setConfirmParams(true);
              this.showConfirmModal = true;
            }
          } else {
            this.setCompleteStatus();
          }
        }
      }
    },
    setCompleteStatus() {
      TaskModule[TaskMutationTypes.SET_SUBTASK_LOADER](true);
      this.$emit("handleEdit", {
        status_id: this.setStatusesList.find((item) => item.is_end === true).id
      });
      this.showConfirm(false);
    },
    showConfirm(val) {
      this.showConfirmModal = val;
    },
    handleEditName(val) {
      this.$emit("handleEdit", {
        name: val
      });
    },
    handleEditDescription(val) {
      this.$emit("handleEdit", {
        description: val
      });
    },
    handleEditDone(val) {
      this.$emit("handleEdit", val);
    },
    handleAction(val) {
      switch (val.actionType) {
        case "createSubTask": {
          TaskManagerModuleV2.openCurtainConstructor({
            currentCreationTypeId: 4,
            currentTaskForCreation: this.task
          });
          break;
        }
        case "execute": {
          this.completeTask();
          break;
        }
        case "copyLink": {
          this.copyStringLink({
            id: this.task.task_id,
            alias: this.task.alias
          });
          break;
        }
        case "delete": {
          this.setConfirmParams(false);
          this.showConfirm(true);
        }
      }
    },
    deleteTask() {
      TaskModule.deleteTaskAction(this.task);
      if (this.setOldPath.name) {
        this.$router.back();
        if (!window.history.state) {
          this.$router.push({ name: "MyTasks" });
        }
      } else {
        this.$router.push({ name: "MyTasks" });
      }
      TaskManagerModuleV2.closeCurtainShowAndEditTask();
    },
    editStage(val) {
      this.$emit("handleEdit", {
        stage_id: val[0]?.id,
        stage_name: val[0]?.id === null ? "-" : val[0]?.name
      });
      this.showEditStage = false;
    },
    toggleEditStage(val) {
      this.showEditStage = val;
      if (this.showEditStage) {
        StageModule.getStageByProjectId({ project_id: this.task.project_id }).then(() => {
          this.parentStage = this.setStageList.filter((item) => item.id === this.task.stage_id);
        });
      }
    },
    getMoreStages() {
      StageModule.getStageByPagination({
        project_id: this.task.project_id
      });
    },
    setEditModeOfDeadline() {
      this.isEditDeadline = true;
    },
    editDeadlines() {
      this.isEditDeadline = false;
    },
    cancelEditDeadline() {
      this.isEditDeadline = false;
    },
    switchCalendar() {
      this.showDeadlineCalendar = false;
      this.showCalendar = !this.showCalendar;
    },
    switchDeadlineCalendar() {
      this.showCalendar = false;
      this.showDeadlineCalendar = !this.showDeadlineCalendar;
    },
    closeCalendar() {
      this.showCalendar = false;
      this.showDeadlineCalendar = false;
    },
    editDeadline(deadline) {
      this.deadline = deadline;
      if (new Date(deadline) !== new Date(this.task.date_to) || deadline === null) {
        this.$emit("handleEdit", {
          date_to: deadline === null ? null : moment(deadline).format("YYYY-MM-DD")
        });
      }
      this.closeCalendar();
    },
    editDateStart(deadline) {
      if (new Date(deadline) !== new Date(this.task.date_from) || deadline === null) {
        this.dateStart = deadline === null ? null : moment(deadline).format("YYYY-MM-DD");
        this.deadline = this.task.date_to ? this.task.date_to : this.dateStart;
        this.$emit("handleEdit", {
          date_from: this.dateStart,
          date_to: this.deadline
        });
        this.closeCalendar();
      }
    },
    editPriority(val) {
      if (val.id !== this.task.priority_id) {
        this.$emit("handleEdit", {
          priority_id: val.id
        });
      }
    },
    editType(val) {
      if (val.id !== this.task.type_id) {
        this.$emit("handleEdit", {
          type_id: val.id
        });
      }
    },
    editStatus(val) {
      if (val.id !== this.task.status_id) {
        if (val.is_end) {
          this.status = this.setStatus;
          this.$emit("completeTask");
        } else {
          this.status = val;
          this.$emit("handleEdit", {
            status_id: val.id
          });
        }
      }
    },
    editPlanTime(val) {
      if (val !== this.task.time_plan) {
        this.$emit("handleEdit", {
          time_plan: val
        });
      }
    },
    editFactTime(val) {
      if (val !== this.task.time_fact) {
        this.$emit("handleEdit", {
          time_fact: val
        });
      }
    },
    goToStage() {
      if (this.task.stage_id) {
        TaskManagerModuleV2.closeCurtainShowAndEditTask();
        StageModule.goToStagePage({
          project_id: this.task.project_id,
          stage: {
            id: this.task.stage_id,
            name: this.task.stage_name
          }
        });
        TaskModule.getTaskListByStageIdAction({
          stage_id: this.task.stage_id,
          per_page: 10,
          project_id: this.task.project_id
        });
        this.$router.push({
          name: "Project",
          params: { id: this.task.project_id },
          query: { stage: `${this.task.stage_id}` }
        });
        TaskManagerModuleV2.closeCurtainShowAndEditTask();
      }
    },
    setConfirmParams(status) {
      if (status) {
        if (this.isSubTask) {
          this.confirmParams = {
            title: "Выполнить подзадачу",
            description: `Вы уверены, что хотите выполнить подзадачу?`,
            callback: () => this.setCompleteStatus()
          };
        } else {
          this.confirmParams = {
            title: "Выполнить задачу",
            description:
              this.task.childs_count && this.task.childs_count > 0
                ? `Задача содержит невыполненные вложенные подзадачи. Вложенные подзадачи выполнятся вместе с ней. Вы уверены, что хотите выполнить задачу?`
                : `Вы уверены, что хотите выполнить задачу?`,
            callback: () => this.setCompleteStatus()
          };
        }
      } else {
        if (this.isSubTask) {
          this.confirmParams = {
            title: "Удалить подзадачу",
            description: `Вы уверены, что хотите удалить подзадачу?`,
            callback: () => this.deleteTask()
          };
        } else {
          this.confirmParams = {
            title: "Удалить задачу",
            description:
              this.task.childs_count && this.task.childs_count > 0
                ? `Подзадачи будут удалены вместе с задачей. Вы уверены, что хотите удалить задачу?`
                : `Вы уверены, что хотите удалить задачу?`,
            callback: () => this.deleteTask()
          };
        }
      }
    },
    openBlackSphereModal() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        true
      );
    }
  },
  computed: {
    totalNumberFiles() {
      const countFilesUploadCurrentTask = taskModule.filesUploadCurrentTask
        ? taskModule.filesUploadCurrentTask.length
        : 0;
      const countFilesList = TaskModule.filesList ? TaskModule.filesList.length : 0;
      const countFilesUploadModal = taskModule.filesUploadModal
        ? taskModule.filesUploadModal.length
        : 0;
      return countFilesUploadCurrentTask + countFilesList + countFilesUploadModal;
    },
    filesList() {
      return TaskModule.filesList;
    },
    isSubTask() {
      return TaskModule.currentTask.parent_id;
    },
    setRoute() {
      return {
        name: "Task",
        params: { id: this.task.parent_task_id },
        query: {
          alias: this.task.alias
        }
      };
    },
    isEdit() {
      return this.isOwner || this.isAuthor || this.isExecutor;
    },
    setActionList() {
      const list = [];
      if (this.task && !this.task.parent_id) {
        list.push({
          isShow: true,
          iconType: "circle-plus",
          actionText: "Добавить подзадачу",
          actionType: `createSubTask`
        });
      }
      list.push({
        isShow: true,
        iconType: "chain",
        actionText: "Копировать ссылку",
        actionType: `copyLink`
      });
      if (this.isOwner || this.isAuthor) {
        list.push({
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        });
      }
      return list;
    },
    tabsList() {
      const tabs = [];
      tabs.push({
        label: "Описание",
        value: "description"
      });
      tabs.push({
        label: "История",
        value: "history"
      });

      tabs.push({
        label: "Комментарии",
        value: "comments",
        count: this.task.count_comments
      });
      return tabs;
    },
    setDescriptionPlaceholder() {
      return this.isSubTask ? "Добавьте описание подзадачи" : "Добавьте описание задачи";
    },
    setTaskTitle() {
      return this.task.alias + "-" + this.task.task_id + " " + this.task.name;
    },
    setProjectName() {
      return this.task.project_name ? this.task.project_name : "-";
    },
    setStageName() {
      return this.task.stage_name ? this.task.stage_name : "-";
    },
    setName() {
      return { name: this.task.name ? this.task.name : "" };
    },
    setDescription() {
      return { description: this.task.description ? this.task.description : "" };
    },
    setAlias() {
      return this.task.alias ? this.task.alias : "WAP";
    },
    setStatusesList() {
      return TaskModule.statusesList.length
        ? TaskModule.statusesList.map((item) => {
            return { ...item, type: "status" };
          })
        : [];
    },
    setStatus() {
      return this.setStatusesList.length
        ? this.setStatusesList.find((item) => item.id === this.task.status_id)
        : [];
    },
    isEndStatus() {
      return this.setStatus ? this.setStatus.is_end : false;
    },
    showCheckListButton() {
      return this.task.checklists.length < 50 && (this.isEdit || this.isParentEditUser);
    },
    isStageEdit() {
      return (this.isAuthor || this.isOwner) && this.task.project_id && !this.task.parent_id;
    },
    setStageList() {
      const stages = [
        {
          id: null,
          name: "Вне этапа"
        }
      ];
      const stageList =
        this.task.project_id &&
        StageModule.stageList &&
        StageModule.stageList[`project_${this.task.project_id}`]
          ? StageModule.stageList[`project_${this.task.project_id}`].list
          : [];
      stages.push(...stageList);
      return stages;
    },
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    },
    setOldPath() {
      return this.$store.state.from;
    },
    setPriorityList() {
      return TaskModule.prioritiesList.length ? TaskModule.prioritiesList : [];
    },
    setPriority() {
      let priority =
        this.setPriorityList.length &&
        this.setPriorityList.find((item) => item.id === this.task.priority_id);
      priority = {
        id: priority.id,
        name: priority.name,
        type: "priority"
      };
      return priority;
    },
    setType() {
      let type =
        this.setTypeList.length && this.setTypeList.find((item) => item.id === this.task.type_id);
      if (this.task.parent_id) {
        type = {
          id: null,
          name: "Подзадача",
          type: "type"
        };
      } else {
        type = {
          id: type.id,
          name: type.name,
          type: "type"
        };
      }
      return type;
    },
    setTypeList() {
      return TaskModule.typesList.length ? TaskModule.typesList : [];
    },
    setDeadlineDate() {
      return this.task.date_to ? moment(this.task.date_to) : null;
    },
    setDateStart() {
      return this.task.date_from ? moment(this.task.date_from) : null;
    },
    setPlanTime() {
      return this.task.time_plan ? this.task.time_plan : "-";
    },
    setFactTime() {
      return this.task.time_fact ? this.task.time_fact : "-";
    },
    marks() {
      return TaskBlackSphereModule.marks;
    }
  },
  watch: {
    currentTab() {
      this.changeCurrentTab();
    },
    setType() {
      this.type = this.setType;
    },
    setPriority() {
      this.priority = this.setPriority;
    },
    setStatus() {
      this.status = this.setStatus;
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.task-body {
  width: 100%;
  border-right: 1px solid $system-grey-four;

  details > summary {
    list-style: none;
    outline: none;
    padding: 16px 24px;
    border-top: 1px solid $system-grey-four;

    @media (max-width: $md) {
      padding: 16px;
    }

    @media (max-width: $sm) {
      padding: 16px 8px;
    }

    i {
      transform: rotate(-90deg);
    }
  }

  details[open] > summary {
    border-bottom: 1px solid $system-grey-four;
    i {
      transform: rotate(0);
    }
  }

  &__body {
    padding: 16px 24px;

    @media (max-width: $md) {
      padding: 16px;
    }

    @media (max-width: $sm) {
      padding: 16px 8px;
    }

    /deep/ {
      .drop-active {
        position: relative;
        margin-bottom: 15px;
        padding: 15px 0;
      }
    }
  }

  &__files {
    .file-uploader {
      &:hover {
        background: transparent;
        border: none;
        box-shadow: none;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
        color: #787996;
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }
  &__link {
    color: $link-default;
    cursor: pointer;

    &:hover {
      color: $link-hovered;
    }

    span {
      margin-right: 8px;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 14px 24px;
    border-bottom: 1px solid $system-grey-four;

    @media (max-width: $xl) {
      padding: 8px 0;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 24px;

    @media (max-width: $xxxl) {
      padding: 16px 8px;
    }

    @media (max-width: $xl) {
      padding: 24px;
    }

    @media (max-width: $md) {
      padding: 16px;
    }

    @media (max-width: $sm) {
      padding: 16px 8px;
    }
  }

  &__menu,
  &__back {
    display: none;

    @media (max-width: $xxl) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      background: transparent;
      border: none;
      padding: 0;
      margin: 14px 16px;
      cursor: pointer;
      outline: none;
    }

    @media (max-width: $xl) {
      margin: 0 16px;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }

  &__back {
    margin: 14px 16px 14px 0;

    @media (max-width: $xl) {
      margin: 0 16px 0 0;
    }
  }

  &__container {
    overflow-y: scroll;
    height: calc(100vh - 70px);

    @media (max-width: $xl) {
      overflow-y: auto;
      height: max-content;
    }
  }

  &__statuses {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 24px 8px;

    @media (max-width: $md) {
      padding: 16px 16px 8px;
    }

    @media (max-width: $sm) {
      align-items: flex-start;
      padding: 16px 8px 0 16px;
    }

    .action-list {
      margin-left: auto;
    }
  }

  &__header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_watch {
      margin-left: auto;
    }
  }

  &__text {
    margin: 0 0 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $text-default;

    @media (max-width: $xxxl) {
      font-size: 14px;
    }

    @media (max-width: $sm) {
      font-size: 12px;
      margin: 0 0 0 8px;
    }

    &_watch {
      margin: 0 16px 0 0;

      @media (max-width: $sm) {
        margin: 0 8px 0 0;
      }
    }
  }

  &__title {
    display: flex;
    width: 100%;
    margin: 0 0 16px;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    color: $text-default;

    span {
      min-width: max-content;
      margin: 0 8px 16px 0;
      padding-top: 8px;
    }

    @media (max-width: $sm) {
      font-size: 16px;
    }
  }

  &__alias {
    margin: 0 0 8px;
    padding: 0 0 0 8px;
    white-space: nowrap;
    font-size: 22px;
    font-weight: 900;
    line-height: 26px;
    color: #343748;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    &:hover {
      .icon-edit {
        opacity: 1;
      }
    }
    @media (max-width: $sm) {
      font-size: 16px;
      line-height: 20px;
    }
    .icon-edit {
      opacity: 0;
      @media (max-width: $md) {
        opacity: 1;
      }
    }
  }

  &__content {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    padding-left: 8px;

    span {
      margin-right: 8px;
      white-space: nowrap;
      align-self: flex-start;
    }
  }

  &__stage {
    width: 100%;
    display: flex;
    margin: 0 0 16px;

    .task-body__content {
      max-width: 95%;
      width: max-content;
      display: flex;
      align-items: center;
      margin: 0;
    }

    /deep/ .select-bg {
      width: 95%;
    }

    .task-body__button {
      display: none;

      @media (max-width: $md) {
        display: block;
      }
    }

    &:hover .task-body__button {
      display: block;
    }
  }

  &__link {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $link-default;
    word-break: break-word;
    display: flex;
    align-items: center;

    @media (max-width: $xxxl) {
      font-size: 14px;
    }

    &:hover {
      color: $link-hovered;
    }

    &:active {
      color: $link-pressed;
    }

    i {
      color: $icon-subdued;
      margin-right: 16px;
    }
  }

  &__task-description {
    margin: 0 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $text-default;
    word-break: break-word;

    @media (max-width: $xxxl) {
      font-size: 14px;
      line-height: 20px;
    }

    /deep/ a {
      color: $link-default;

      &:hover {
        color: $link-hovered;
      }

      &:active {
        color: $link-pressed;
      }
    }
  }

  &__summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__summary-title {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $text-default;

    i {
      font-size: 6px;
      color: $icon-subdued;
      margin-left: 8px;
    }
  }

  &__button {
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $link-default;
    background: transparent;
    border: none;
    outline: none;

    &_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $icon-subdued;
      margin-left: 8px;

      i {
        font-size: 18px;
      }
    }

    @media (max-width: $xxxl) {
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      color: $link-hovered;
    }

    &:active {
      color: $link-pressed;
    }
  }
}

/deep/ {
  .tabs {
    @media (max-width: $sm) {
      overflow-x: scroll;
    }

    &__list {
      @media (max-width: $sm) {
        height: 30px;
      }
    }

    &__item {
      min-height: 24px;
    }

    label {
      padding: 0 0 4px;
    }

    .active {
      &::before {
        @media (max-width: $sm) {
          bottom: 0;
          height: 2px;
        }
      }
    }
  }

  .aop-button {
    span {
      @media (max-width: $sm) {
        display: none;
      }
    }
  }
}
</style>
