<template>
  <div class="plans-table__row row">
    <ConfirmModal
      v-if="confirmDeleteModal"
      title="Удалить?"
      description="Вы действительно хотите удалить план?"
      @cancel="cancelDelete"
      @accept="acceptDelete"
    />
    <router-link class="row__name cell" :to="{ name: 'ProjectNetwork', query: { id: plan.id } }">
      {{ plan.name }}
    </router-link>
    <div class="row__date-from cell">{{ dateStart }}</div>
    <div class="row__date-to cell">{{ dateEndLate }}</div>
    <div class="row__last-changes cell">{{ lastChanges }}</div>
    <div class="row__actions cell">
      <ActionsList :actionList="actionList" @handleAction="handleAction" />
    </div>
  </div>
</template>

<script>
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import NotificationObserver from "@/helpers/UserNotification";
import moment from "moment";

export default {
  name: "PlansTableRow",
  mixins: [formatDateAndTime],
  props: {
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { ConfirmModal, ActionsList },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "edit",
          actionText: "Редактировать",
          actionType: `edit`
        },
        {
          isShow: true,
          iconType: "user-add",
          actionText: "Настроить доступ",
          actionType: `access`
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ],
      confirmDeleteModal: false
    };
  },
  mounted() {},
  methods: {
    handleAction(val) {
      switch (val.actionType) {
        case "edit":
          this.$router.push({ name: "Plan", query: { id: this.plan.id, mode: "edit" } });
          break;
        case "access":
          this.$emit("toggleAccessUserModal", { show: true, id: this.plan.id });
          break;
        case "delete":
          this.confirmDeleteModal = true;
          break;
      }
    },
    cancelDelete() {
      this.confirmDeleteModal = false;
    },
    async acceptDelete() {
      this.confirmDeleteModal = false;
      await PlanningScheduleModule.deletePlanAction(this.plan.id);
      NotificationObserver.notification({
        type: "success",
        message: "План успешно удален!"
      });
    }
  },
  computed: {
    dateStart() {
      return this.plan.date_start ? this.setDateFormatFull(this.plan.date_start) : "-";
    },
    dateEndLate() {
      return this.plan.date_end_late ? this.setDateFormatFull(this.plan.date_end_late) : "-";
    },
    lastChanges() {
      if (this.plan.calculated_at) {
        let dateLocale = moment
          .parseZone(this.plan.calculated_at)
          .local(false)
          .format("YYYY-MM-DD HH:mm:ss");
        let date = dateLocale.split(" ")[0];
        let time = dateLocale.split(" ")[1];
        date = this.setDateFormatFull(date);
        time = this.setTimeFormatMinutes(time);
        return `${date} ${time}`;
      } else {
        return "-";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.row {
  display: flex;
  width: 100%;
  padding: 20px 10px;
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
  }
  &__name {
    width: 40%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    justify-content: start !important;
    cursor: pointer;
    transition: 0.2s all ease;
    &:hover {
      color: $link-hovered;
    }
  }
  &__date-from,
  &__date-to,
  &__last-changes {
    width: 18%;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: $text-default;
  }

  &__actions {
    width: 6%;
  }
}
</style>
