import { Component, Watch, Mixins } from "vue-property-decorator";
import { IActionItem } from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import { dataToEventAdapter, dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import { typeRepeat } from "@/aopV2/modules/Calendar/components/Modal/CreateEvent/CreateEventFS.vue";
import moment from "moment";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import _ from "lodash";
import { ICreateData } from "@/aopV2/modules/Calendar/service/CalendarInterface";
import FSEventMixin from "@/aopV2/modules/Calendar/helper/FSevent.mixin";

@Component
export default class createEventMixin extends Mixins(FSEventMixin) {
  id: undefined | number = undefined;
  selectDate: string = dateFormat(new Date());
  isPrivate: boolean = false;
  isCommonEvent: boolean = false;
  toggleLink: boolean = false;
  name: string = "";
  description: string = "";
  currentType: IActionItem = { name: "default", id: 0 };
  timeStart: string = moment(new Date()).add(1, "hour").format("HH:mm");
  timeEnd: string = moment(new Date()).add(2, "hour").format("HH:mm");
  selectRepeat = {
    name: "Не повторять",
    id: "no-repeat"
  };
  selectLocation = [
    {
      name: "Без кабинета",
      id: 0
    }
  ];
  selectMembers: Array<any> = [];
  selectGroups: Array<any> = [];
  selectResponsible: Array<any> = [];
  repeat: null | typeRepeat = null;
  isDateValid: boolean = true;
  isStartValid: boolean = true;
  isEndValid: boolean = true;
  repeatList: Array<any> = [
    {
      name: "Не повторять",
      id: "no-repeat"
    },
    {
      name: "Ежедневно",
      id: "day"
    },
    {
      name: "По будням",
      id: "week"
    },
    {
      name: "Еженедельно",
      id: "week-repeat"
    },
    {
      name: "Ежемесячно",
      id: "month"
    },
    {
      name: "Ежегодно",
      id: "year"
    },
    {
      name: "Другое",
      id: "other"
    }
  ];
  isFS: boolean = false;
  isShowList: boolean = false;
  files: Array<any> = [];
  uploadFiles: Array<any> = [];
  defData: any = null;

  mounted() {
    if (!this.isLoad) {
      Promise.all([
        CalendarModule2.getEventTypeCategories(),
        CalendarModule2.getAllEventType(),
        CalendarModule2.getAllLocation()
      ]).then(() => {
        CalendarModule2.toggleMainLoader(false);
        CalendarModule2.setIsLoadType(true);
        this.setDefault();
      });
    } else {
      this.setDefault();
    }
    const input = this.$el.querySelector("#name-event-input");
    (input as HTMLElement).focus();
    this.selectResponsible = [{ id: this.userId }];
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }

  get isLoad() {
    return CalendarModule2.isLoad;
  }

  setDefault() {
    let type = this.eventTypes.find((item) => item.id === this.eventDefData.currentType.id);
    if (!type) {
      type = this.eventTypes.find((item) => item.is_default);
    }
    this.id = this.eventDefData.id;
    this.selectDate = this.eventDefData.selectDate;
    this.isPrivate = this.eventDefData.isPrivate;
    this.isCommonEvent = this.eventDefData.isCommonEvent;
    this.toggleLink = this.eventDefData.toggleLink;
    this.name = this.eventDefData.name;
    this.description = this.eventDefData.description;
    this.currentType = type;
    this.timeStart = this.eventDefData.timeStart;
    this.timeEnd = this.eventDefData.timeEnd;
    this.selectLocation = this.eventDefData.selectLocation;
    this.selectMembers = this.eventDefData.selectMembers;
    this.selectGroups = this.eventDefData.selectGroups;
    this.selectResponsible = this.eventDefData.selectResponsible;
    this.repeat = this.eventDefData.repeat;
    this.isDateValid = this.eventDefData.isDateValid;
    this.isStartValid = this.eventDefData.isStartValid;
    this.isEndValid = this.eventDefData.isEndValid;
    this.files = this.eventDefData.files;
    this.uploadFiles = this.files.map((item) => item.id);
    this.defData = this.createData;
  }

  get minDate() {
    return new Date();
  }

  get userId() {
    return this.$store.getters.getUserId;
  }

  get timezone() {
    return CalendarModule2.timezone;
  }

  setCurrentType(type) {
    this.currentType = type;
    this.closeList();
  }

  handleEventName(event) {
    this.name = event.target.value;
  }

  changeGroups(groups) {
    this.selectGroups = groups;
  }

  changeMembers(members) {
    this.selectMembers = members;
  }

  changeDate(date) {
    this.selectDate = date;
  }

  handleValidDate(isValid) {
    this.isDateValid = isValid;
  }

  handleValidStart(isValid) {
    this.isStartValid = isValid;
  }

  handleValidEnd(isValid) {
    this.isEndValid = isValid;
  }

  changeTimeStart(time) {
    this.timeStart = time;
    if (this.timeStart >= this.timeEnd) {
      const [hh, mm] = this.timeStart.split(":");
      const newHH = +hh + 1 <= 9 ? `0${+hh + 1}` : `${+hh + 1}`;
      this.timeEnd = `${newHH}:${mm}`;
    }
  }

  changeTimeEnd(time) {
    this.timeEnd = time;
  }

  get eventsParams() {
    return CalendarModule2.eventsParams;
  }

  changeRepeatType(type) {
    this.selectRepeat = type;
    this.setStandardDataReplayEvent();
  }

  changeLocation(location) {
    this.selectLocation = [location];
  }

  closeList() {
    this.isShowList = false;
  }

  handleToggleCommonEvent() {
    this.isCommonEvent = !this.isCommonEvent;
  }

  handleTogglePrivate() {
    this.isPrivate = !this.isPrivate;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  handleDescription(event) {
    this.description = event.target.value;
  }

  setStandardDataReplayEvent() {
    if (this.selectRepeat.id === "week") {
      //weekdays
      this.repeat = {
        count: null,
        to_date: null,
        interval_type: "week",
        step: 1,
        days: [1, 2, 3, 4, 5]
      };
    } else if (this.selectRepeat.id === "week-repeat") {
      // repeat current day in week
      this.repeat = {
        count: null,
        to_date: null,
        interval_type: "week",
        step: 1,
        days: [this.currentWeekDay]
      };
    } else if (this.selectRepeat.id === "day") {
      // repeat day with step
      this.repeat = {
        count: null,
        to_date: null,
        interval_type: "day",
        step: 1
      };
    } else if (this.selectRepeat.id === "month") {
      // repeat current day in month
      this.repeat = {
        count: null,
        to_date: null,
        interval_type: "month",
        step: 1
      };
    } else if (this.selectRepeat.id === "year") {
      // repeat current day in year
      this.repeat = {
        count: null,
        to_date: null,
        interval_type: "year",
        step: 1
      };
    } else if (this.selectRepeat.id === "other") {
      this.repeat = {
        count: null,
        interval_type: "week",
        step: 1,
        days: [this.currentWeekDay],
        to_date: null
      };
      if (!this.isFS) {
        this.$nextTick(() => {
          this.handleFullScreenEvent("repeat");
        });
      }
    } else {
      // not repeat
      this.repeat = null;
    }
  }

  toggleAddLink() {
    this.toggleLink = !this.toggleLink;
  }

  get eventTypes() {
    return CalendarModule2.eventType;
  }

  get groups() {
    return CalendarModule2.groups;
  }

  get locations() {
    const locations = CalendarModule2.locations.map((item) => item);
    locations.unshift({
      name: "Без кабинета",
      id: 0
    });
    return locations;
  }

  get members() {
    return this.$store.getters["UsersModule/userList"].users.filter((item) => item.is_active);
  }

  get currentWeekDay() {
    return new Date(this.selectDate).getDay();
  }

  get createData() {
    const data: ICreateData = {
      currentType: this.currentType,
      name: this.name.length ? this.name.trim() : this.name,
      selectMembers: this.selectMembers,
      selectGroups: this.selectGroups,
      selectDate: this.selectDate,
      timeStart: this.timeStart,
      timeEnd: this.timeEnd,
      id: this.id,
      isCommonEvent: this.isCommonEvent,
      isPrivate: this.isPrivate,
      toggleLink: this.toggleLink,
      timezone: this.timezone,
      selectResponsible: this.selectResponsible,
      description:
        this.description && this.description.length ? this.description.trim() : this.description,
      selectLocation: this.selectLocation,
      repeat: this.repeat,
      isDateValid: this.isDateValid,
      isStartValid: this.isStartValid,
      isEndValid: this.isEndValid,
      files: this.uploadFiles
    };
    return data;
  }

  get eventDefData() {
    return CalendarModule2.createData;
  }

  get event() {
    return CalendarModule2.preCreateEvent;
  }

  get isEqual() {
    return _.isEqual(this.createData, this.defData);
  }

  get setCreateDataWatch() {
    return JSON.stringify(this.createData);
  }

  timerId: number | undefined = undefined;

  @Watch("setCreateDataWatch") onChangeCreateData() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      CalendarModule2.setCreateData(this.createData);
    }, 100);
    CalendarModule2.editPreCreationEvent({
      event: dataToEventAdapter(this.createData),
      oldId: this.id
    });
  }

  @Watch("isEqual") onChangeIsEqual() {
    CalendarModule2.switchIsEqual(this.isEqual);
  }
}
