import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";
import { ITimeModalData } from "@/modules/TimeTracker/interface/interface";

interface IParams {
  page: number;
  per_page: number;
  project_id?: number;
}

class TimeProjectService extends Service {
  createTask(data): Response<any> {
    return httpClientV2.post<any>("timemanager/tasks/create", data).then((resp) => {
      return resp.data.id;
    });
  }

  editTask(data: { name: string; id: number }): Response<any> {
    return httpClientV2.patch<any>("timemanager/tasks/update", data).then((resp) => {
      return resp.data;
    });
  }

  deleteTask(id): Response<any> {
    return httpClientV2.delete<any>("timemanager/tasks/delete", { data: { id } }).then((resp) => {
      return resp.data;
    });
  }

  createProject(data): Response<any> {
    return httpClientV2.post<any>("timemanager/projects/create", data).then((resp) => {
      return resp.data.id;
    });
  }

  getMonthStatistics(params): Response<any> {
    return httpClientV2
      .get<any>("timemanager/timesheet/month-user-work-time", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  getProjectMember(params): Response<any> {
    return httpClientV2
      .get<any>("timemanager/projects/user_in_project", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  getWeekListLimit(params): Response<any> {
    return httpClientV2
      .get<any>("timemanager/timesheet/list-week-limit", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  editUserWeekLimit(data): Response<any> {
    return httpClientV2
      .post<any>("timemanager/timesheet/edit-user-week-limit", data)
      .then((resp) => {
        return resp.data;
      });
  }

  editWeekLimit(data): Response<any> {
    return httpClientV2.post<any>("timemanager/timesheet/edit-week-limit", data).then((resp) => {
      return resp.data;
    });
  }

  exportTask(params: { date_from: string; date_to: string; user_id: number }): Response<any> {
    return httpClientV2.get<any>("timemanager/exports/by_user_tasks", { params }).then((resp) => {
      return resp.data.channel;
    });
  }

  getReason(): Response<any> {
    return httpClientV2.get<any>("timemanager/timesheet/reasons").then((resp) => {
      return resp.data;
    });
  }

  sync(): Response<any> {
    return httpClientV2.get<any>("timemanager/tasks/sync").then((resp) => {
      return resp.data;
    });
  }

  getTimeTable(params): Response<any> {
    return httpClientV2.get<any>("timemanager/timesheet/get-my-time", { params }).then((resp) => {
      return resp.data;
    });
  }

  getTimeTableCurrentUser(params): Response<any> {
    return httpClientV2.get<any>("timemanager/timesheet/get-user-time", { params }).then((resp) => {
      return resp.data;
    });
  }

  getMyWeekLimit(params?): Response<any> {
    return httpClientV2
      .get<any>("timemanager/timesheet/user-week-limit", { params })
      .then((resp) => {
        return resp.data.time;
      });
  }

  getUserLimit(params: { year: string | number; user_id: number }): Response<any> {
    return httpClientV2
      .get<any>("timemanager/timesheet/get_user_week_limit_with_default", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  getMyWorkTime(params): Response<any> {
    return httpClientV2
      .get<any>("timemanager/timesheet/user-work-time", { params })
      .then((resp) => {
        return resp.data;
      });
  }

  getMyProject(params: IParams): Response<any> {
    return httpClientV2.get<any>("timemanager/projects/get-my", { params }).then((resp) => {
      return resp.data;
    });
  }

  needToSync(params?): Response<any> {
    return httpClientV2.get<any>("timemanager/users/access-token-info", { params }).then((resp) => {
      return resp.data;
    });
  }

  requestJiraToken(params: { provider_identity: number }): Response<any> {
    return httpClientV2
      .get<any>("timemanager/users/request-jira-token", { params })
      .then((resp) => {
        return resp.data.link;
      });
  }

  saveJiraToken(params: { provider_identity: number }): Response<any> {
    return httpClientV2.get<any>("timemanager/users/save-jira-access", { params }).then((resp) => {
      return resp.data;
    });
  }

  getAllProject(): Response<any> {
    return httpClientV2
      .get<any>("timemanager/projects/get-all", {
        params: {
          page: 1,
          per_page: 300
        }
      })
      .then((resp) => {
        return resp.data;
      });
  }

  getTaskByProjectId(params: IParams): Response<any> {
    return httpClientV2.get<any>("timemanager/tasks/get-my", { params }).then((resp) => {
      return resp.data;
    });
  }

  memberAddToProject(data): Response<any> {
    return httpClientV2.post("timemanager/projects/add_user_in_project", data).then((resp) => {
      return resp.data;
    });
  }

  memberDeleteToProject(data): Response<any> {
    return httpClientV2
      .post("timemanager/projects/delete_user_without_project", data)
      .then((resp) => {
        return resp.data;
      });
  }

  startTime(task_id): Response<any> {
    return httpClientV2.post("timemanager/timesheet/start", { task_id }).then((resp) => {
      return resp.data;
    });
  }

  stopTime(): Response<any> {
    return httpClientV2.post("timemanager/timesheet/stop").then((resp) => {
      return resp.data;
    });
  }

  editTime(data: ITimeModalData): Response<any> {
    return httpClientV2.post("timemanager/timesheet/edit-time", { ...data }).then((resp) => {
      return resp.data;
    });
  }

  addTime(data: ITimeModalData): Response<any> {
    return httpClientV2.post("timemanager/timesheet/add-time", { ...data }).then((resp) => {
      return resp.data;
    });
  }

  deleteTime(data): Response<any> {
    return httpClientV2.delete("timemanager/timesheet/delete-time", { data }).then((resp) => {
      return resp.data;
    });
  }
}

export default new TimeProjectService();
