<template>
  <div
    class="body-row__subtasks"
    :style="!subtask.length ? 'display: flex; justify-content: center; padding: 40px 0;' : ''"
  >
    <Spinner :small="true" v-if="!subtask.length" />
    <MyTasksTableTask
      v-for="item in subtasksList"
      :task="item"
      :key="item.id"
      :isSubtask="true"
      :tasksData="tasksData"
      :totalCount="totalCount"
      :fromPag="fromPag"
    />
    <button class="task-row__all" type="button" v-if="subtask.length > 5" @click="openFullTask">
      Все подзадачи
    </button>
  </div>
</template>

<script>
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import MyTasksTableTask from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableTask";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import MyTasksTableRow from "@/modules/TaskManager/components/MyTasks/MyTasksTable/MyTasksTableRow";

export default {
  name: "MyTasksTableSubtask",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    tasksData: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    fromPag: {
      type: Number,
      default: 1
    }
  },
  components: {
    MyTasksTableTask,
    Spinner
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    paginationEvent() {
      this.$emit("getSubtask");
    },
    openFullTask() {
      if (this.task) {
        this.$router.push({
          name: "Task",
          params: {
            id: this.task.task_id
          },
          query: { alias: this.task.alias }
        });
      }
    }
  },
  computed: {
    subtask() {
      return TaskModule.subtasksByTask(this.task.id);
    },
    subtasksList() {
      return this.subtask.slice(0, 5);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.task-row {
  display: flex;
  align-items: center;
  padding: 32px 0;
  width: 100%;

  @media (max-width: $md) {
    width: 1065px;
  }

  &__all {
    font-size: 16px;
    font-weight: 400;
    color: #5551f9;
    cursor: pointer;
    white-space: nowrap;
    line-height: 25px;
    border: none;
    background: transparent;
    padding-left: 24px;
    outline: none;

    &:hover {
      color: $link-hovered;
    }
  }

  &__cell {
    display: flex;
    width: 100%;
    flex-shrink: 1;
    padding-right: 20px;

    i {
      color: $icon-subdued;
      font-size: 8px;
      margin-right: 8px;
    }

    &_name {
      display: flex;
      align-items: center;
      width: 30%;
      max-width: 30%;
      flex-shrink: 0;
      padding-left: 16px;

      @media (max-width: $md) {
        max-width: 300px;
        width: 300px;
      }

      i {
        margin-right: 8px;
      }

      .arrow-slidedown {
        flex-shrink: 0;
      }

      .checkbox-bg {
        flex-shrink: 0;
        margin-right: 8px;
      }
    }

    &_stage {
      width: 20%;
      flex-shrink: 0;
    }

    &_author {
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      @media (max-width: $md) {
        width: 115px;
      }
    }

    &:nth-child(3) {
      @media (max-width: $md) {
        width: 150px;
      }
    }

    &:nth-child(5) {
      width: 20%;
      flex-shrink: 0;

      @media (max-width: $md) {
        width: 150px;
      }
    }

    &:last-child {
      @media (max-width: $md) {
        width: 120px;
      }
    }
  }

  &__name,
  &__stage,
  &__project {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    word-break: break-word;
  }

  &__link,
  &__name {
    cursor: pointer;

    &:hover {
      color: $link-hovered;
    }
  }

  &__priority,
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    width: 90px;
    height: 30px;
  }

  &__status {
    min-height: 30px;
    height: max-content;
    border: 2px solid #c3c7d2;

    span {
      padding: 0 4px;
      @include textEllipsis(2);
    }
  }
}
</style>
