<template>
  <li
    class="reaction"
    :class="{ reaction_more: count > 1, reaction_self: selfReaction }"
    v-if="count"
  >
    <span class="reaction__wrap" @click="handleReaction">
      <span class="reaction__icon">{{ reaction }}</span>
      <span class="reaction__count" v-if="count > 1">+{{ count - 1 }}</span>
    </span>
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { domainStorage } from "@/globalVariables";

export default {
  name: "Reaction",
  mixins: [],
  props: {
    reaction: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    },
    message: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      timer: null,
      overTimer: null,
      leaveTimer: null,
      isElement: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["sendReaction", "deleteReaction"]),
    setSrc(src) {
      return domainStorage + src;
    },
    handleReaction() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.selfReaction) {
          this.deleteReaction({ reaction: this.reaction, id: this.message.id });
        } else {
          this.sendReaction({ reaction: this.reaction, id: this.message.id });
        }
      }, 200);
    }
  },
  computed: {
    ...mapState("MessengerModule", ["usersReaction"]),
    selfReaction() {
      if (this.message.my_reactions && this.message.my_reactions.length) {
        return this.message.my_reactions.some((item) => item.reaction === this.reaction);
      } else {
        return false;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.reaction {
  cursor: pointer;
  user-select: none;
  border: 1px solid #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-right: 4px;
  margin-bottom: 4px;
  flex-shrink: 0;
  height: 26px;
  width: 26px;
  transition: all 0.3s ease;
  position: relative;
  @media (hover: hover) {
    &:hover {
      border-color: $chat-editor;
      .reaction__users-wrap {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &_more {
    width: auto;
  }
  &_self {
    border: 1px solid #5551f9;
    .reaction__count {
      color: #fff;
    }
  }
  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
  &__users-wrap {
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    transform: translateY(-8px);
    z-index: 10;
  }
  &__user-count {
    color: $chat-editor;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 16px;
  }
  &__loader {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #fff;
  }
  &__users {
    all: unset;
    background-color: #fff;
    padding: 8px 12px;
    display: flex;
    border: 1px solid $chat-editor;
    border-radius: 32px;
    align-items: center;
    position: relative;
    &:after {
      content: "";
      left: 50%;
      top: 100%;
      transition: all 0.3s ease;
      margin-left: -5px;
      margin-top: -5px;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-left: 1px solid $chat-editor;
      border-bottom: 1px solid $chat-editor;
      transform: rotate(-45deg);
    }
  }
  &__user {
    margin-right: -8px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #fff;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    padding: 6px;
  }
  &__count {
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: #5551f9 !important;
    margin-left: 2px;
  }
  &__icon {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 12px;
  }
}
</style>
