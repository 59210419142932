<template>
  <div class="tabs">
    <ul class="tabs__list" ref="tabList">
      <li
        class="tabs__item"
        v-for="(tab, idx) in tabs"
        :key="`tab_${idx}`"
        :ref="`tab`"
        :class="{ active: idx === setActiveTab }"
      >
        <router-link
          class="tabs__link"
          :to="{ path: '/Notification', query: { table: tab.path } }"
          :class="{ active: idx === setActiveTab }"
          replace
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsNavigationQuery",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      activeTab: 0
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {
    setActiveTab() {
      return this.tabs.filter((item) => item.path === this.$route.query.table).length
        ? this.tabs.filter((item) => item.path === this.$route.query.table)[0].index
        : null;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs {
  position: relative;
  &__list {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    @media (max-width: $lg) {
      overflow-x: scroll;
    }
  }

  &__item {
    position: relative;
    transition: all 0.5s ease;

    @media (max-width: $sm) {
      font-size: 14px;
    }
    &:not(:last-child) {
      margin: 0 32px 0 0;
    }

    &:hover {
      color: $text-default;
    }
  }

  &__link {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $text-disabled;
    white-space: nowrap;
    cursor: pointer;
  }

  .active {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0a1417;
    transition: all 0.2s ease;

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #5551f9;
      border-radius: 4px;
      animation-name: opacity;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
    }
  }
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
