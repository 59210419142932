<template>
  <div class="profile__events events">
    <div class="events__header">
      <div class="events__wrap">
        <h2 class="events__title">Запланированные мероприятия</h2>
        <button class="events__subtitle" type="button" @click="isWorkingCalendar = true">
          Календарь рабочих дней
        </button>
      </div>
      <ModalButton
        :title="'Запланировать'"
        :type="'create-light'"
        @handleButton="redirectCalendarCreateEvent"
      />
    </div>
    <div class="events__main">
      <div class="events__calendar">
        <CalendarPicker
          :unique="'profileDatePicker'"
          @getTimeInfo="getTimeInfo"
          :attributes="attributes"
          v-if="!isShowMobile"
        />
        <CalendarPickerMobile
          :unique="'profileDatePicker'"
          :isShowDoubleArrows="false"
          :isShowArrows="false"
          :attributes="attributes"
          @getTimeInfo="getTimeInfo"
          v-if="isShowMobile"
        />
      </div>
      <ul class="events__list">
        <ProfileEventItem v-for="(event, index) in eventsList" :key="event.id" :event="event" />
        <li class="events__item-event events__item-event_empty" v-if="!eventsList.length">
          <span>{{ setEmptyText }}</span>
        </li>
      </ul>
    </div>
    <WorkingCalendarModal
      v-if="isWorkingCalendar"
      :isAdmin="isAdminCalendar"
      @hideProfileWorkingCalendar="hideProfileWorkingCalendar"
      @updateWorkingCalendar="updateWorkingCalendar"
    />
  </div>
</template>

<script>
import CalendarPicker from "@/components/Widgets/CalendarPicker/CalendarPicker";
import { format } from "date-fns";
import ProfileEventItem from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileEvents/ProfileEventItem";
import CalendarPickerMobile from "@/components/Widgets/CalendarPicker/CalendarPickerMobile";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import moment from "moment";
import { mapActions, mapMutations } from "vuex";
import httpClient from "@/api/client/httpClient";
import WorkingCalendarModal from "@/modules/TimeTracker/component/Modal/WorkingCalendarModal";

export default {
  name: "ProfileEvents",
  mixins: [],
  props: {
    eventsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    dataUser: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userId: {
      type: String,
      default: ""
    },
    currentEventDate: {
      type: String,
      default: ""
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isAdminCalendar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WorkingCalendarModal,
    ModalButton,
    CalendarPickerMobile,
    ProfileEventItem,
    CalendarPicker
  },
  data() {
    return {
      isWorkingCalendar: false,
      debounceTimer: null,
      isShowMobile: false,
      attributes: []
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.computedWidth();
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.computedWidth();
    });
  },
  methods: {
    ...mapActions("CalendarModule", ["showCreateEventModal"]),
    ...mapMutations("CalendarModule", [
      "SET_TIME_START",
      "SET_TIME_END",
      "SHOW_EVENT_INFO_MODAL",
      "SET_DATA_INFO_MEMBER_USER"
    ]),
    /**
     * The function of hiding the modal window of the Working Time Calendar
     */
    hideProfileWorkingCalendar() {
      this.isWorkingCalendar = false;
    },
    updateWorkingCalendar(date) {
      this.$emit("updateWorkingCalendar", date);
      this.getTimeInfo(this.currentMonth);
    },
    redirectCalendarCreateEvent() {
      this.$router.push({ name: "Calendar" });
      this.SHOW_EVENT_INFO_MODAL(false);
      this.SET_TIME_START(moment().format("HH:mm"));
      this.SET_TIME_END(moment().add(1, "hour").format("HH:mm"));
      this.SET_DATA_INFO_MEMBER_USER(this.dataUser);
      this.showCreateEventModal(true);
    },
    getTimeInfo(data) {
      if (data.year) {
        this.currentMonth = `${data.year}-${data.month < 10 ? "0" + data.month : data.month}`;
      }
      httpClient({
        url: `/WorkingCalendar`,
        method: "GET",
        params: {
          _count: 31,
          _search: `{"date":"${this.currentMonth}"}`,
          _order_by: "date",
          _order: { date: "asc" }
        }
      })
        .then((response) => {
          this.attributes = [];
          this.attributes = response.data.data.items.map((item) => ({
            customData: {
              hours: item.count_hours,
              id: item.id
            },
            dates: item.date
          }));
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    computedWidth() {
      clearTimeout(this.debounceTimer);
      // this.debounceTimer = setTimeout(() => {
      this.isShowMobile =
        (window.innerWidth < 1440 && window.innerWidth > 1200) || window.innerWidth < 685;
      // }, 100);
    }
  },
  computed: {
    setEmptyText() {
      return this.currentEventDate === format(new Date(), "Y-MM-dd")
        ? "Сегодня мероприятий нет :("
        : `В этот день мероприятий нет :(`;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  position: relative;
  width: 4px;
  height: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $surface-pressed;
  cursor: pointer;

  &:hover {
    background: $action-primary-hovered;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}

.events {
  width: 100%;
  height: 100%;
  min-height: 432px;
  background: $surface-default;
  box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 24px;

  &__header {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 24px 32px 16px;
    border-bottom: 1px solid $border-disabled;

    @media (max-width: $xxxl) {
      padding: 24px 24px 16px;
      border: none;
    }

    @media (max-width: $xl) {
      border-bottom: 1px solid $border-disabled;
    }

    @media (max-width: 685px) {
      flex-wrap: wrap;
      padding: 16px 16px 8px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: $text-default;

    @media (max-width: 685px) {
      margin: 0 0 16px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__subtitle {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: $text-highlight;
    border: none;
    background: transparent;
    outline: none;

    @media (max-width: 685px) {
      margin: 0 0 16px;
    }

    &:hover {
      color: $text-subdued;
    }

    &:active {
      color: $text-subdued;
    }
  }

  &__plan-event {
    width: 122px;
    height: 30px;
    padding: 5px 12px;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $text-subdued;
    border: 1px solid $action-primary;
    border-radius: 40px;
    background: $surface-default;
    outline: none;

    @media (max-width: 685px) {
      position: absolute;
      right: 16px;
      bottom: 24px;
      width: 90px;
      height: 25px;
      padding: 0;
      font-size: 10px;
      line-height: 10px;
    }

    &:hover {
      color: $action-secondary;
      background: $action-primary-hovered;
      border: 1px solid $action-primary-hovered;
    }

    &:active {
      color: $action-secondary;
      background: $action-primary-pressed;
      border: 1px solid $action-primary-pressed;
    }
  }

  &__main {
    display: flex;
    height: 338px;

    @media (max-width: $xxxl) {
      height: 280px;
      flex-wrap: wrap;
    }
    @media (max-width: $xl) {
      flex-wrap: nowrap;
      height: 338px;
    }

    @media (max-width: 685px) {
      height: 260px;
      flex-wrap: wrap;
    }

    @media (max-width: $sm) {
      height: 220px;
    }
  }

  &__calendar {
    @media (max-width: $xxxl) {
      width: 100%;
    }

    @media (max-width: $xl) {
      width: max-content;
    }

    @media (max-width: 685px) {
      width: 100%;
    }

    /deep/ {
      .calendar {
        @media (max-width: $xxxl) {
          display: none;
        }

        @media (max-width: $xl) {
          display: block;
        }

        @media (max-width: 685px) {
          display: none;
        }

        .vc-container .vc-title span {
          cursor: pointer;
        }
      }

      .vc-nav-popover-container {
        background: $surface-default;
        border: 1px solid $border-disabled;
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
        border-radius: 16px;
      }

      .vc-nav-title,
      .vc-nav-popover-container {
        color: $text-default;
        font-weight: 400;
      }

      .vc-nav-title:hover,
      .vc-nav-arrow:hover {
        background: $action-primary-hovered;
        color: $action-secondary;
      }

      .vc-nav-item {
        color: $text-subdued;
        font-weight: 400;

        &:hover {
          background: $action-primary-hovered;
          color: $action-secondary;
        }
      }

      .vc-nav-item.is-active {
        background: $action-primary;
        color: $action-secondary;
      }

      .calendar-mobile {
        display: none;

        @media (max-width: $xxxl) {
          display: flex;
          width: 100%;
          padding: 0 24px 16px;
          border-bottom: 1px solid $border-disabled;
        }

        @media (max-width: $xl) {
          display: none;
        }

        @media (max-width: 685px) {
          display: flex;
          padding: 16px 8px;
        }
      }
    }
  }

  &__list {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    list-style: none;
    border-left: 1px solid $border-disabled;
    border-radius: 0 0 24px 0;

    @media (max-width: $xxxl) {
      border: none;
    }

    @media (max-width: $xl) {
      border-left: 1px solid $border-disabled;
    }

    @media (max-width: 685px) {
      border: none;
    }
  }

  &__item-event {
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @media (max-width: 685px) {
        height: 200px;
      }

      span {
        width: 200px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #787996;
        text-align: center;
      }
    }
  }
}
</style>
