













import { Component, Vue } from "vue-property-decorator";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";

@Component({
  components: { Button }
})
export default class ConfirmationModal extends Vue {
  handleCancel() {
    this.modalConfig.cancel();
  }

  handleConfirm() {
    this.modalConfig.confirm();
  }

  get modalConfig() {
    return TaskManagerModuleV2.modalConfig;
  }
}
