







import {Component, Prop, Vue} from "vue-property-decorator";
import TextField from "@/aopV2/components/UIComponents/TextField/TextField.vue";
import {fontList} from "@/aopV2/assets/fontList";

@Component({components: {TextField}})
export default class BreadcrumbsItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  item!: any;
  @Prop({
    default: "",
    type: String
  })
  activeBreadcrumbs!: string;
  @Prop({
    default: true,
    type: Boolean
  })
  last!: boolean;

  handleBreadcrumb() {
    this.$emit('handleBreadcrumb', this.item);
  }

  get fontStyle() {
    return fontList.labelRegularMedium;
  }

  get isActive() {
    return this.item.route === this.activeBreadcrumbs;
  }
}
