






































import { Component, Prop, Vue } from "vue-property-decorator";
import TrackerProjectList from "@/modules/TimeTracker/component/TimeTrackerWindow/TrackerProjectList.vue";
import TimeTracker, { TimeTrackerMutationTypes } from "@/modules/TimeTracker/store";
import AddProject from "@/modules/TimeTracker/component/TimeModal/AddProject.vue";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import SyncProject from "@/modules/TimeTracker/component/TimeTrackerWindow/SyncProject.vue";

@Component({
  components: { SyncProject, ProjectTag, AddProject, TrackerProjectList }
})
export default class TimeTrackerWindow extends Vue {
  showModal = false;

  closeModal() {
    TimeTracker[TimeTrackerMutationTypes.TOGGLE_PROJECT_MODAL](false);
  }

  closeTaskModal() {
    this.showModal = false;
  }

  openTaskModal() {
    this.showModal = true;
  }

  async addProject(name) {
    await TimeTracker.createTask({ name });
    this.closeTaskModal();
  }

  async handleSync() {
    await TimeTracker.syncData(true);
    const projects = document.querySelectorAll(".tracker-project__wrap");
    if (projects.length) {
      projects.forEach((item) => {
        // @ts-ignore
        if (item.open) {
          TimeTracker.getTaskByProjectId({ project_id: item.id, page: 1, per_page: 50 });
        }
      });
    }
  }

  get syncProject() {
    return TimeTracker.projectSyncList.filter((item) => item.need_to_sync);
  }

  get projects() {
    return TimeTracker.projects;
  }

  get isSync() {
    return TimeTracker.isSync;
  }
}
