<template>
  <div class="modal__footer">
    <ModalButton
      v-if="showHideButton"
      :title="hideTitle"
      :type="'cancel'"
      @handleButton="$emit('hideModal')"
    />
    <ModalButton
      v-if="editValue"
      :title="editTitle"
      @handleButton="$emit('edit')"
      :eventType="'submit'"
    />
    <ModalButton
      v-if="!editValue"
      :title="createTitle"
      @handleButton="$emit('create')"
      :eventType="'submit'"
    />
  </div>
</template>

<script>
import ModalButton from "@/components/Widgets/Button/ModalButton";
export default {
  name: "ModalFooter",
  mixins: [],
  props: {
    editValue: {
      type: Object,
      default: () => {
        return null;
      }
    },
    createTitle: {
      type: String,
      default: () => {
        return "Создать";
      }
    },
    editTitle: {
      type: String,
      default: () => {
        return "Сохранить";
      }
    },
    hideTitle: {
      type: String,
      default: () => {
        return "Отменить";
      }
    },
    showHideButton: {
      type: Boolean,
      default: true
    }
  },
  components: { ModalButton },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal {
  &__footer {
    width: 100%;
    padding: 32px 32px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-disabled;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      padding: 24px 16px;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      padding: 16px;
    }

    @media screen and (max-width: $sm) {
      padding: 16px;
    }
  }
}
</style>
