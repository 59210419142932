import { Response, Service } from "@/helpers/types/service";
import httpClientV2 from "@/api/client/httpClientV2";
import {
  IParamsForReadingNotifications,
  IParamsForReceivingNotifications,
  IParamsForSavingSettingNotifications
} from "@/modules/Notification/interfaces/interface";

class NotificationService extends Service {
  getNotifications(params: IParamsForReceivingNotifications): Response<any> {
    return httpClientV2.get<any>("notifymanager/notify/get", { params }).then((resp) => {
      return resp.data;
    });
  }
  readNotification(body: IParamsForReadingNotifications): Response<any> {
    return httpClientV2.post<any>("notifymanager/notify/read", { id: body }).then((resp) => {
      return resp.data;
    });
  }
  readAllNotification(): Response<any> {
    return httpClientV2.post<any>("notifymanager/notify/read", {}).then((resp) => {
      return resp.data;
    });
  }
  getUserSettings(): Response<any> {
    return httpClientV2.get<any>("notifymanager/notify-type/get", {}).then((resp) => {
      return resp.data;
    });
  }

  saveUserSettings(body: IParamsForSavingSettingNotifications): Response<any> {
    return httpClientV2.post("notifymanager/user-setting/save", body).then((resp) => {
      return resp.data;
    });
  }

  resetSettingsToDefault(): Response<any> {
    return httpClientV2.post<any>("notifymanager/user-setting/save-default", {}).then((resp) => {
      return resp.data;
    });
  }
}

export default new NotificationService();
