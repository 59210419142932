import { render, staticRenderFns } from "./ChildProject.vue?vue&type=template&id=66df122a&scoped=true&"
import script from "./ChildProject.vue?vue&type=script&lang=js&"
export * from "./ChildProject.vue?vue&type=script&lang=js&"
import style0 from "./ChildProject.vue?vue&type=style&index=0&id=66df122a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66df122a",
  null
  
)

export default component.exports