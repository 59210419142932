<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'full_name'">
      <User
        :avatar="row.user_id.avatar"
        :name="row.user_id.full_name"
        :id="row.user_id.id"
        :showTooltip="false"
      />
    </div>
    <div class="table__content" v-else-if="cell.key === 'department'">
      <p class="table__text">{{ setDepartment }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'roles'">
      <p class="table__text">{{ setRoles }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'normal_time'">
      <p class="table__text">{{ row.normal_time }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'tracked_time'">
      <p class="table__text">{{ row.tracked_time }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'tracked_time_rate_1'">
      <p class="table__text">{{ row.tracked_time_by_rate_1 }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'tracked_time_rate_2'">
      <p class="table__text">{{ row.tracked_time_by_rate_2 }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'tracked_time_rate_3'">
      <p class="table__text">{{ row.tracked_time_by_rate_3 }}</p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'difference_time'">
      <p class="table__text overwork" :class="{ lesswork: row.difference_time.charAt(0) === '-' }">
        <span class="table__round"></span>{{ setDifferentTime }}
      </p>
    </div>
    <div class="table__content" v-else-if="cell.key === 'sick_leave'">
      <p class="table__text">{{ setSickLeave }}</p>
    </div>
    <div class="table__content" v-else>
      <p
        class="table__text"
        :class="{
          sick: row[cell.absence] === 1,
          vacation: row[cell.absence] === 2 || row[cell.absence] === 3
        }"
      >
        <span class="table__round" v-if="row[cell.absence] !== null"></span>{{ row[cell.tracker] }}
      </p>
    </div>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import { mapState } from "vuex";

export default {
  name: "TimeCellContent",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setDepartment() {
      return this.row.department.length ? this.row.department[0].name : "-";
    },
    setRoles() {
      return this.row.roles.length ? this.row.roles[0].name : "-";
    },
    setSickLeave() {
      return this.row.sick_leave ? this.row.sick_leave : "-";
    },
    setDifferentTime() {
      return this.row.difference_time.charAt(0) === "-"
        ? this.row.difference_time.slice(1)
        : this.row.difference_time;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  .table__wrap {
    display: flex;
    flex-shrink: 0;
    width: 100%;
  }

  .table__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    /deep/ {
      .user__name {
        max-width: 200px;
      }
    }
  }

  .table__text {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    padding: 0 8px 0 0;
    word-break: break-word;
  }

  &__round {
    display: block;
    margin: 0 8px 0 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .overwork {
    width: max-content;
    padding: 4px 12px;
    height: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $interactive-success-depressed;
    background: $surface-success-default;
    border-radius: 40px;

    span {
      background: $interactive-success-depressed;
    }
  }

  .lesswork {
    color: $interactive-critical-depressed;
    background: $action-critical-light;

    span {
      background: $interactive-critical-depressed;
    }
  }

  .vacation {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    width: max-content;
    height: 24px;
    border-radius: 40px;
    background: #f2f2f7;

    .table__round {
      background: #6d7175;
    }
  }

  .sick {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    width: max-content;
    height: 24px;
    border-radius: 40px;
    background: #fff8e5;
    color: #ca7b00;

    .table__round {
      background: #ca7b00;
    }
  }
}
</style>
