<template>
  <tr class="marks__item">
    <td class="marks__cell">
      <h3 class="marks__label">Поставил</h3>
      <User
        v-if="setPutUser"
        :avatar="setPutUser.avatar"
        :name="setPutUser.full_name"
        :id="setPutUser.id"
        :showInfo="false"
        :info="setRole(setPutUser)"
        :size="'36px'"
        :show-tooltip="true"
        class="marks__user"
      />
    </td>
    <td class="marks__cell">
      <h3 class="marks__label">Получил</h3>
      <User
        v-if="setGotUser"
        :avatar="setGotUser.avatar"
        :name="setGotUser.full_name"
        :id="setGotUser.id"
        :showInfo="false"
        :info="setRole(setGotUser)"
        :size="'36px'"
        :show-tooltip="true"
        class="marks__user"
      />
    </td>
    <td class="marks__cell">
      <h3 class="marks__label">Оценка</h3>
      <div class="marks__mark" :style="{ background: setBackground, color: setColor }">
        <i class="icon-check"></i>
        <span>{{ item.mark }}</span>
      </div>
    </td>
    <td class="marks__cell">
      <h3 class="marks__label">Дата</h3>
      {{ setDateFormatFull(item.marked_at) }}
    </td>
    <td class="marks__cell marks__cell_comment">
      <h3 class="marks__label">Комментарий</h3>
      <p class="marks__comment" v-html="setURL(setComment)"></p>
      <button
        class="marks__delete"
        v-if="getUserId === setPutUser.id"
        type="button"
        aria-label="Удалить оценку"
        @click="deleteMark"
      >
        <i class="icon-delete"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import UrlMixin from "@/helpers/UrlMixin";
import User from "@/components/Widgets/User/User";
import { mapGetters } from "vuex";
import TaskBlackSphereModule from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";

export default {
  name: "BlackSphereMarksItem",
  mixins: [formatDateAndTime, UrlMixin],
  props: {
    item: {
      type: Array,
      default: () => []
    },
    task: {
      type: Object,
      default: () => {}
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setRole(val) {
      return val ? (val.roles.length ? `[${val.roles[0].name}]` : "") : "";
    },
    deleteMark() {
      TaskBlackSphereModule.deleteTaskBlackSphereAction({
        id: this.task.id,
        black_sphere_id: this.item.black_sphere_id ? this.item.black_sphere_id : this.item.id
      });
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    setGotUser() {
      return this.userList.users.find((item) => item.id === this.item.got_user_id);
    },
    setPutUser() {
      return this.userList.users.find((item) => item.id === this.item.put_user_id);
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setComment() {
      return this.item.comment ? this.item.comment : "-";
    },
    setBackground() {
      switch (this.item.mark) {
        case 2: {
          return "#EBFCF6";
        }
        case 1: {
          return "#fff8e5";
        }
        case 0: {
          return "#fef0f0";
        }
        default: {
          return "transparent";
        }
      }
    },
    setColor() {
      switch (this.item.mark) {
        case 2: {
          return "#238B6A";
        }
        case 1: {
          return "#ca7b00";
        }
        case 0: {
          return "#a22929";
        }
        default: {
          return "#787996";
        }
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.marks {
  &__item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;

    &:nth-child(2n) {
      background: #fafafe;
    }

    @media (max-width: $sm) {
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  &__cell {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #787996;
    width: 30%;

    @media (max-width: $sm) {
      width: 50%;
      margin: 0 0 16px;
    }

    &:last-child {
      width: 100%;
    }

    &_comment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $sm) {
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
      }
    }
  }

  &__mark {
    width: 53px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 40px;

    span {
      margin-left: 8px;
    }
  }

  &__comment {
    @include textEllipsis(2);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #343748;

    /deep/ a {
      color: $link-default;

      &:hover {
        color: $link-hovered;
      }

      &:active {
        color: $link-pressed;
      }
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;

    @media (max-width: $sm) {
      position: absolute;
      right: 8px;
      top: 10px;
    }

    i {
      margin-left: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover i {
      color: $icon-hovered;
    }
  }

  &__label {
    display: none;

    @media (max-width: $sm) {
      display: block;
      width: 100%;
      margin: 0 0 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: $text-subdued;
      text-align: left;
    }
  }
}
</style>
