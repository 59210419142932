<template>
  <ul class="timeline-vacation__member-list">
    <TimeLineMembers v-for="(user, index) in member_users" :user="user" :key="index" />
    <li
      class="timeline-vacation__member-item timeline-vacation__member-item_empty"
      v-if="!getMemberLength"
    >
      Нет результатов :(
    </li>
    <Loader v-if="scrollLoading" class="loader" />
    <EmptyRowMemberList
      v-if="getMemberLength && getMemberLength < 10"
      v-for="i in getMemberLength < 10 ? 10 - getMemberLength : 0"
      :key="i"
    />
  </ul>
</template>

<script>
import TimeLineMembers from "@/components/GraphVacation/TimeLine/TimeLineMembers/TimeLineMembers";
import EmptyRowMemberList from "@/components/GraphVacation/TimeLine/TimeLineMembers/EmptyRowMemberList";
import Loader from "@/components/Widgets/Loader2.0/Loader";
export default {
  name: "TimeLineMemberList",
  mixins: [],
  props: {
    member_users: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeTabMember: {
      type: Boolean,
      default: () => {
        return 0;
      }
    },
    scrollLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loader,
    EmptyRowMemberList,
    TimeLineMembers
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  computed: {
    getMemberLength() {
      return this.member_users.length;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.timeline-vacation {
  &__member-list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    width: 260px;
    border-right: 1px solid #f2f2f7;
    background-color: $bg-gray-75;

    @media (max-width: $sm) {
      max-width: 160px;
    }
  }

  &__member-item_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    color: $text-disabled;
    font-size: 14px;
    line-height: 20px;
    width: 260px;
    height: 74vh;
  }
}
.loader {
  margin-left: auto;
  margin-right: auto;
}
</style>
