<template>
  <div class="status" v-click-outside="hideEditMode">
    <Label
      :label="'КРИТИЧНОСТЬ'"
      :tooltip="setTooltip"
      :fullText="`Блокирующая — Проблема блокирует развитие компании. От решения проблемы зависит прибыль
          компании или скорость разработки
          <br />
          <br />
          Критическая — Проблема напрямую или косвенно мешает развитию компании/отдела/проекта
          <br />
          <br />
          Значительная — Проблема влияет на рабочие процессы в компании/отделе/проекте
          <br />
          <br />
          Комфортная — Проблема не влияет напрямую на развитие компании. Решение проблемы сделает
          работу в компании более комфортной`"
      :isFullText="isFullText"
    />
    <StatusCritical v-if="!isEdit" :critical="critical.id" />
    <StatusCritical
      v-if="isEdit"
      v-model="critical"
      @input="handleInput"
      :critical="critical.id"
      :isDropDown="true"
      :criticalList="criticalityList"
    />
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import axios from "axios";
import StatusCritical from "@/modules/UiKit/components/statuses/StatusCritical";
import Label from "@/modules/Isp/components/IspProblem/Label";
import { mapState } from "vuex";

export default {
  name: "CriticalComponent",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: { StatusCritical, Label },
  data() {
    return {
      tooltip: {
        [null]: "Как определить критичность проблемы?",
        1:
          "Проблема блокирует развитие компании.\n" +
          "От решения проблемы зависит прибыль компании \n" +
          "или скорость разработки",
        2: "Проблема напрямую или косвенно мешает \n" + "развитию компании/отдела/проекта",
        3: "Проблема влияет на рабочие процессы в\n" + "компании/отделе/проекте",
        4:
          "Проблема не влияет напрямую на развитие \n" +
          "компании. Решение проблемы сделает работу в компании\n" +
          "более комфортной"
      },
      critical: {
        id: null
      },
      showTooltip: false,
      fullTooltip: false
    };
  },
  created() {
    this.critical = this.setCriticality;
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleInput() {
      this.$emit("editCritical", this.critical);
    },
    hideEditMode() {
      // this.editMode = false;
    },
    hideTooltip() {
      this.showTooltip = false;
      this.fullTooltip = false;
    }
  },
  computed: {
    ...mapState("IspModule", ["criticalityList"]),
    setTooltip() {
      return this.tooltip[this.critical.id];
    },
    setCriticality() {
      return this.user?.criticality ? this.user.criticality : { id: null };
    },
    isFullText() {
      return this.critical.id === null;
    }
  },
  watch: {
    user() {
      this.critical = this.setCriticality;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.status {
  display: flex;
  flex-direction: column;
  user-select: none;
  /deep/ {
    .label {
      margin-bottom: 12px;
      label {
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #343748;
      }
      &__tooltip {
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 7px 9px;

    i {
      visibility: hidden;
      color: #a7a9c0;
      margin-left: 8px;
    }
  }
  &__content-edit {
    cursor: pointer;
    &:hover {
      background: #fafafe;
      i {
        visibility: visible;
      }
    }
  }
  &__avatar {
    margin-right: 8px;
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #fafafe;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #343748;
    word-break: break-word;
    max-width: 100px;
  }
}
</style>
