<template>
  <div class="birthday-widget" id="newsBirthdayWidget" @click.self="$emit('hideBirthdayWidget')">
    <div class="birthday-widget__content">
      <div class="birthday-widget__title">
        <h2>Дни рождения</h2>
        <img :src="require('@/modules/News/assets/img/NewsImage/pieceOfCake.svg')" alt="" />
      </div>
      <div class="birthday-widget__loader" v-if="loading">
        <Spinner />
      </div>
      <div class="birthday-widget__body" v-else>
        <NewsBirthdayItem v-for="item in birthdayItems" :key="item.id" :birthdayItem="item" />
        <p v-if="!birthdayItems.length && !loading" class="birthday-widget__empty">Пусто :(</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import ClickOutside from "vue-click-outside";
import NewsBirthdayItem from "@/modules/News/components/BirthdayComponents/NewsBirthdayItem";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "NewsBirthdayWidget",
  mixins: [],
  props: {
    birthdayItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean
    }
  },
  components: { Spinner, NewsBirthdayItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.birthday-widget {
  position: relative;
  width: 355px;
  background: $bg-surface-primary;
  border-left: 1px solid #eae8f0;
  flex: 1 1 50%;
  overflow: hidden;
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    border-top: 1px solid #eae8f0;
    border-bottom: 1px solid #eae8f0;
    height: 70px;

    @media (max-width: $lg) {
      text-align: center;
    }

    h2 {
      margin: 0 16px 0 0;
      padding: 0;
      font-weight: 500;
      font-size: 18px;
      color: $text-default;
    }
  }
  &__body {
    padding: 25px 28px;
    height: calc(100% - 70px);
    overflow: auto;
  }
  &__empty {
    padding: 77px 0 66px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: $text-subdued;
    user-select: none;
  }
}
</style>
