<template>
  <ul class="adding-members">
    <li
      class="adding-members__item"
      v-if="membersList < count + 1"
      :class="[viewMode ? 'adding-members__item_alt' : '']"
    >
      <AddingMembersItem
        v-for="(user, index) in membersList"
        :user="user"
        :key="index"
        :size="size"
        :showTooltip="showTooltip"
        @deleteUser="deleteUser"
        :addMember="addMember"
        :deleteMember="deleteMember"
      />
    </li>
    <li
      class="adding-members__item"
      v-if="membersList < count + 1"
      :class="[viewMode ? 'adding-members__item_alt' : '']"
    >
      <AddingMembersItem
        v-for="(user, index) in membersList"
        :user="user"
        :key="index"
        :size="size"
        :showTooltip="showTooltip"
        @deleteUser="deleteUser"
        :addMember="addMember"
        :deleteMember="deleteMember"
      />
    </li>
    <li class="adding-members__item" v-else :class="[viewMode ? 'adding-members__item_alt' : '']">
      <AddingMembersItem
        v-for="(user, index) in getShowUsers"
        :user="user"
        :key="index"
        :showTooltip="showTooltip"
        :size="size"
        :addMember="addMember"
        :deleteMember="deleteMember"
        @deleteUser="deleteUser"
      />
    </li>
    <li class="adding-members__item adding-members__item_count" v-if="userCount > count">
      <div
        :class="[viewMode ? 'adding-members__count_alt' : 'adding-members__count']"
        :style="{ width: size, height: size, 'font-size': fontSize }"
        @click.prevent="openMemberModal"
      >
        +{{ userCount - count }}
      </div>
    </li>
    <li class="adding-members__item" v-click-outside="hideConformationUser" v-if="addMember">
      <button
        class="adding-members__add"
        type="button"
        @click="isConfirmationsPersonModal = true"
        :style="{ width: size, height: size, 'font-size': fontSize }"
      >
        <i class="icon-plus"></i>
      </button>
      <ConfirmationsUsersModal
        v-if="isConfirmationsPersonModal"
        :keyValue="keyValue"
        :title="title"
        :maxCount="maxCount"
        :filter="false"
        :buttonsPanel="buttonsPanel"
        :editConfirmationsPerson="membersList"
        :hideMember="hideMember"
        @hideModal="isConfirmationsPersonModal = false"
        @changeSelectMember="setUser"
      />
    </li>
  </ul>
</template>

<script>
import ClickOutside from "vue-click-outside";
import AddingMembersItem from "@/components/Widgets/User/AddingMembers/AddingMembersItem";
import ConfirmationsUsersModal from "@/components/Widgets/ConfirmationsUsersModal/ConfirmationsUsersModal";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
export default {
  name: "AddingMembersList",
  mixins: [],
  props: {
    membersList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    news: {
      type: Array,
      default: () => {
        return {};
      }
    },
    keyValue: {
      type: String,
      default: ""
    },
    unique: {
      type: String,
      default: ""
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    maxCount: {
      type: Number,
      default: Infinity
    },
    userCount: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: "Добавить участников"
    },
    type: {
      type: String,
      default: "news"
    },
    size: {
      type: String,
      default: "45px"
    },
    fontSize: {
      type: String,
      default: "14px"
    },
    count: {
      type: Number,
      default: 5
    },
    addMember: {
      type: Boolean,
      default: true
    },
    deleteMember: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    buttonsPanel: {
      type: Boolean,
      default: true
    },
    likeMode: {
      type: Boolean,
      default: true
    },
    showMemberModal: {
      type: Boolean,
      default: true
    },
    hideMember: {
      type: [String, Number],
      default: () => {
        return "";
      }
    }
  },
  components: { ConfirmationsUsersModal, AddingMembersItem },
  data() {
    return {
      isConfirmationsPersonModal: false
    };
  },
  beforeDestroy() {
    this.$root.$off("hideConfirmationUserModal");
  },
  mounted() {
    this.$root.$on("hideConfirmationUserModal", () => {
      this.isConfirmationsPersonModal = false;
    });
  },
  methods: {
    setUser(members) {
      this.$emit(`changeSelectValue`, members);
    },
    openMemberModal() {
      if (this.likeMode) {
        httpClient({
          method: "GET",
          url: `/Like/actionGetItems`,
          params: {
            likeable_id: this.news.id,
            likeable_type: this.type,
            _with: JSON.stringify("user"),
            _count: this.userCount
          }
        }).then((response) => {
          this.membersList = response.data.data.items.map((item) => item.user);
          this.$root.$emit("openMembersModal", {
            memberUser: this.membersList,
            title: "Понравилось"
          });
        });
      } else {
        if (this.showMemberModal) {
          this.$root.$emit("openMembersModal", {
            memberUser: this.membersList,
            title: this.title,
            editMode: this.addMember
          });
        }
      }
    },
    hideConformationUser() {
      this.isConfirmationsPersonModal = false;
    },
    deleteUser(id) {
      const index = this.membersList.findIndex((item) => item.id === id);
      this.membersList.splice(index, 1);
    }
  },
  computed: {
    getShowUsers() {
      return this.membersList.slice(0, this.count);
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.adding-members {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    color: $border-default;
    font-size: 14px;
    line-height: 20px;
    background: $background-active;
    border-radius: 50%;
    border: 1px dashed $border-default;
    &_alt {
      cursor: pointer;
      display: flex;
      width: 50px;
      height: 36px;
      align-items: center;
      justify-content: center;
      color: $text-default;
      font-size: 12px;
      line-height: 16px;
      margin-left: 8px;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    padding: 0;
    background: $background-active;
    border-radius: 50%;
    border: 1px dashed $border-default;
    outline: none;
    position: relative;

    i {
      color: $border-default;
      font-size: 20px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &_count {
      margin-left: -16px;
      cursor: pointer;
    }

    &:last-child {
      //margin-left: -10px;
      cursor: pointer;
      //z-index: 2;
    }
    &_alt {
      /deep/ {
        .adding-members__avatar {
          border-width: 1px;
          width: 36px;
          height: 36px;
        }
        .adding-members__user {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  /deep/ {
    .confirmation-user {
      width: 304px;
      top: 45px;
      left: 0;
      height: auto;
    }
  }
}
</style>
