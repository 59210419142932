<template>
  <div class="plans-table">
    <PlansTableFields />
    <PlansTableRow
      v-for="plan in tableData"
      :key="plan.id"
      :plan="plan"
      @toggleAccessUserModal="toggleAccessUserModal"
    />
  </div>
</template>

<script>
import PlansTableFields from "@/modules/TaskManager/components/PlanningSchedule/PlansTable/PlansTableFields";
import PlansTableRow from "@/modules/TaskManager/components/PlanningSchedule/PlansTable/PlansTableRow";
export default {
  name: "PlansTable",
  mixins: [],
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { PlansTableRow, PlansTableFields },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleAccessUserModal(val) {
      this.$emit("toggleAccessUserModal", val);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.plans-table {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
