<template>
  <section class="work-plans">
    <div class="work-plans__header">
      <button class="work-plans__menu" type="button" @click="openSidebar">
        <i class="icon-menu"></i>
      </button>
      <h2>Планы работ</h2>
      <Button
        :type="'simple'"
        :title="'Запланировать'"
        :icon="'plus'"
        @handleButton="createPlan"
        class="work-plans__add-btn"
      />
    </div>
    <div class="work-plans__body" v-if="!isEmpty">
      <PlansTable :tableData="planList" @toggleAccessUserModal="toggleAccessUserModal" />
    </div>
    <div class="work-plans__empty" v-else>Список пуст</div>
    <ModalGiveAccess
      v-if="showModalGiveAccess"
      :planId="planId"
      @cancel="toggleAccessUserModal(false)"
      @accept="giveAccess"
    />
  </section>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import PlansTable from "@/modules/TaskManager/components/PlanningSchedule/PlansTable/PlansTable";
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import { mapMutations } from "vuex";
import ModalGiveAccess from "@/modules/TaskManager/components/PlanningSchedule/ProjectNetwork/ModalGiveAccess/ModalGiveAccess";
export default {
  name: "WorkPlans",
  mixins: [],
  props: {},
  components: { ModalGiveAccess, PlansTable, Button },
  data() {
    return {
      showModalGiveAccess: false,
      planId: null
    };
  },
  mounted() {
    PlanningScheduleModule.getPlanListAction();
  },
  methods: {
    ...mapMutations("TaskManagerModule", ["TOGGLE_SHOW_SIDEBAR"]),
    createPlan() {
      this.$router.push({ name: "Plan", query: { mode: "create" } });
    },
    openSidebar() {
      this.TOGGLE_SHOW_SIDEBAR(true);
    },
    toggleAccessUserModal(val) {
      this.showModalGiveAccess = val.show;
      this.planId = val.id;
    },
    giveAccess(body) {
      PlanningScheduleModule.createAccessUsersAction(body);
      this.showModalGiveAccess = false;
      this.planId = null;
    }
  },
  computed: {
    planList() {
      return PlanningScheduleModule.planList;
    },
    planListLoader() {
      return PlanningScheduleModule.planListLoader;
    },
    isEmpty() {
      return !this.planList.length && !this.planListLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";
.task-manager__container {
  display: none;
}
.work-plans {
  width: 100%;
  height: 100%;
  padding: 0 24px;
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 88px;
    h2 {
      margin: 0 0 0 16px;
    }
  }
  &__body {
    height: calc(100% - 88px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__menu {
    display: none;

    @media (max-width: $xxl) {
      display: flex;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
    }
  }
  &__empty {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $text-default;
  }

  &__add-btn {
    margin-left: auto;
  }
}
</style>
