<template>
  <div class="type-small">
    <div class="type-small__label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TypeTaskSmall",
  mixins: [],
  props: {
    icon: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/TextEllipsis.scss";
.type-small {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  width: max-content;
  height: 28px;
  background: $surface-selected-hovered;
  border: 1px solid $system-blue;
  border-radius: 4px;

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $system-blue;
    cursor: default;
    @include textEllipsis(2);
  }
}
</style>
