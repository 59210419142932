<template>
  <div class="chat-tooltip" ref="optionList">{{ textTooltip }}</div>
</template>

<script>
import setListPosition from "@/helpers/OptionListPosition";
import { mapState } from "vuex";

export default {
  name: "ChatTooltip",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    setListPosition(this.container, this.$refs.optionList, this.modalX, this.modalY);
  },
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["modalX", "textTooltip", "modalY", "container"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-tooltip {
  padding: 8px;
  border-radius: 8px;
  display: flex;
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  background-color: $background-modal;
  white-space: nowrap;
  z-index: 30;
  @media screen and (max-width: $lg) {
    display: none;
  }
}
</style>
