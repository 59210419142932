<template>
  <div class="task-type" v-click-outside="hideList" @click="openList">
    <div
      :class="[
        'task-type__label',
        isEdit ? 'task-type__label_edit' : '',
        showList ? 'task-type__label_open' : ''
      ]"
      :style="setStylePriority"
    >
      <span>{{ value.name }}</span>
    </div>
    <ul class="task-type__list" v-if="showList">
      <li class="task-type__item" v-for="item in list" :key="item" @click="setItem(item)">
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "TypeTask",
  mixins: [],
  props: {
    value: {
      type: Object,
      default: () => {
        return null;
      }
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      showList: false
    };
  },
  mounted() {},
  methods: {
    setItem(item) {
      this.$emit("input", item);
    },
    openList() {
      if (!this.isEdit) return false;
      this.showList = !this.showList;
    },
    hideList() {
      this.showList = false;
    }
  },
  computed: {
    setStylePriority() {
      return this.value && this.value.type === "priority"
        ? `color: ${this.setPriorityColor}; border: 1px solid ${this.setPriorityColor}; background: ${this.setPriorityBackground}`
        : this.value && this.value.type === "type"
        ? "color: #409CFF; border: 1px solid #409CFF; background: #EDF4FE;"
        : "color: #A7A9C0; border: 1px solid #A7A9C0; background: #f2f7fe;";
    },
    setPriorityColor() {
      if (this.value.name === "Высокий") {
        return "#FB646D";
      } else if (this.value.name === "Средний") {
        return "#EE7200";
      } else {
        return "#FFB901";
      }
    },
    setPriorityBackground() {
      if (this.value.name === "Высокий") {
        return "#fed1d3";
      } else if (this.value.name === "Средний") {
        return "#fed3b3";
      } else {
        return "#fef2be";
      }
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/mixins/TextEllipsis.scss";

.task-type {
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  max-width: 150px;
  min-width: 110px;
  min-height: 34px;

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 34px;
    padding: 6px 12px;
    border-radius: 4px;
    background: white;
    position: relative;
    z-index: 6;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: default;

    span {
      @include textEllipsis(1);
    }

    &_edit {
      user-select: none;
      cursor: pointer;
    }

    &_open {
      border: 2px solid #5551f9;
    }

    i {
      font-size: 16px;
      line-height: 20px;
      color: #343748;
      margin-right: 8px;
    }
  }

  &__list {
    position: absolute;
    top: calc(100% - 4px);
    left: 0;
    list-style: none;
    margin: 0;
    z-index: 5;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 8px 0 0;
    background: #ffffff;
    border: 1px solid #eae8f0;
    border-radius: 4px;
  }

  &__item {
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #343748;

    span {
      @include textEllipsis(1);
    }

    &:hover {
      background: #fafafe;
    }
  }
}
</style>
