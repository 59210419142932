


























import { Component, Prop, Vue } from "vue-property-decorator";
import BasePicker from "@/aopV2/components/UIComponents/Picker/Day/BasePicker.vue";
import moment from "moment";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput.vue";
import { dateValidate } from "@/aopV2/helpers/dateValidate";
import { EventBus } from "@/main";

@Component<InputDate>({
  components: { TextInput, BasePicker },
  watch: {
    selectDate() {
      this.$emit("change", this.selectDate);
      this.closePicker();
    },
    date() {
      this.selectDate = this.date;
      this.dateString = this.date ? moment(this.date).format("DD.MM.YYYY") : "";
    }
  }
})
export default class InputDate extends Vue {
  @Prop({ type: String, default: moment(new Date()).format("YYYY-MM-DD") }) date!: string;
  @Prop({ type: Date, default: null }) minDate!: string | Date;
  selectDate: string = moment(new Date()).format("YYYY-MM-DD");
  dateString: string = "";
  masks = {
    weekdays: "WWW"
  };
  isShowCalendar: boolean = false;
  inputMask = ["##.##.####"];

  mounted() {
    this.selectDate = this.date;
    this.dateString = this.date ? moment(this.date).format("DD.MM.YYYY") : "";
  }

  handleInput(event) {
    this.dateString = event.target.value;
    this.$emit("handleValid", this.isValidDate);
  }

  handleIcon() {
    this.isShowCalendar = !this.isShowCalendar;
  }

  closePicker() {
    this.isShowCalendar = false;
  }

  openPicker() {
    this.isShowCalendar = true;
  }

  get isValidDate() {
    return this.dateString.length === 10 && !this.dateString.includes("_");
  }

  handleDay() {
    if (this.isValidDate) {
      this.dateString = dateValidate(this.dateString);
      if (new Date(this.dateString) > this.minDate) {
        const [d, m, y] = this.dateString.split(".");
        this.selectDate = `${y}-${m}-${d}`;
      } else {
        this.selectDate = dateFormat(this.minDate);
        this.dateString = moment(this.minDate).format("DD.MM.YYYY");
        EventBus.$emit("showNotification", {
          type: "warning",
          timeout: 5000,
          label: "Нельзя создать мероприятие на прошедший день"
        });
      }
    }
  }

  handleDate(date) {
    if (date) {
      this.selectDate = dateFormat(date);
      this.dateString = moment(date).format("DD.MM.YYYY");
    } else {
      this.selectDate = dateFormat(new Date());
      this.dateString = "";
    }
    this.$emit("handleValid", this.isValidDate);
  }
}
