<template>
  <section class="kanban-table">
    <div class="kanban-table__wrap">
      <KanbanColumn
        v-for="column in columns"
        :key="column.id"
        :column="column"
        :taskList="taskList"
      />
    </div>
  </section>
</template>

<script>
import KanbanColumn from "@/modules/TaskManager/components/Kanban/KanbanColumn";
import { Droppable } from "@shopify/draggable";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";
export default {
  name: "KanbanTable",
  mixins: [],
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stage: {
      type: Object,
      default: () => {
        return {};
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    executor: {
      type: Object,
      default: null
    }
  },
  components: { KanbanColumn },
  data() {
    return {
      droppable: null
    };
  },
  mounted() {
    this.setDraggable();
  },
  methods: {
    setDraggable() {
      if (this.droppable) this.droppable.destroy();
      const containers = document.querySelectorAll(".dropzone");
      if (containers.length === 0) {
        return false;
      }
      this.droppable = new Droppable(containers, {
        draggable: ".kanban-task",
        dropzone: ".dropzone",
        delay: 150,
        mirror: {
          constrainDimensions: true,
          appendTo: ".kanban-table"
        }
      });
      this.droppable.on("drag:start", (evt) => {
        const task = this.taskList.find((item) => item.id === Number(evt.source.dataset.task_id));
        const status = this.statusList.find((item) => item.id === task.status_id);
        const availableStatuses = [
          task.status_id,
          ...status.to_statuses,
          ...this.statusList.filter((item) => item.to_statuses === null).map((item) => item.id)
        ];
        KanbanModule.onDragStartAction({ task, availableStatuses });
      });
      this.droppable.on("droppable:stop", (evt) => {
        const task = this.taskList.find(
          (item) => item.id === Number(evt.dragEvent.source.dataset.task_id)
        );
        evt.dropzone.classList.remove("draggable-dropzone--occupied");
        KanbanModule.onDragStopAction(Number(evt.dropzone.dataset.status_id));
        task.status_id = evt.dropzone.dataset.status_id
          ? Number(evt.dropzone.dataset.status_id)
          : task.status_id;
        if (
          task.status_id === Number(evt.dropzone.dataset.status_id) ||
          evt.dropzone.dataset.status_id === undefined
        ) {
          evt.dragEvent.sourceContainer.appendChild(evt.dragEvent.source);
          const column = this.columns.find((column) =>
            column.status_links.some((item) => item.flow_status === task.status_id)
          );
          if (column) {
            const id = column.id;
            column.id = 0;
            this.$nextTick(() => {
              column.id = id;
              this.$nextTick(() => {
                this.setDraggable();
              });
            });
          }
        }
      });
    }
  },
  computed: {
    statusList() {
      return KanbanModule.statusList;
    },
    taskList() {
      if (this.stage.id === null) {
        return this.executor
          ? KanbanModule.taskListsByStage[`project_${this.project.id}`].filter(
              (task) => task.executor_id === this.executor.id
            )
          : KanbanModule.taskListsByStage[`project_${this.project.id}`];
      }
      return this.executor
        ? KanbanModule.taskListsByStage[`stage_${this.stage.id}`].filter(
            (task) => task.executor_id === this.executor.id
          )
        : KanbanModule.taskListsByStage[`stage_${this.stage.id}`];
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: $bg-gray-500;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}
::-webkit-scrollbar-track {
  background: $surface-disabled;
}
.kanban-table {
  width: 100%;
  background-image: url("~@/modules/TaskManager/assets/img/kanban-bg.svg");
  background-repeat: no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: auto;
  list-style: none;
  &__wrap {
    padding: 32px 8px 32px 24px;
    width: fit-content;
    display: flex;
  }
  &__add-column {
    padding-right: 16px;
    i {
      font-size: 24px;
      color: $link-default;
      cursor: pointer;
    }
  }
}
</style>
