<template>
  <div class="news" ref="news">
    <div class="news__wrapper">
      <transition name="slide">
        <CurtainNewsCreate
          v-if="showCurtainCreateNews"
          :createMode="true"
          @closeNewsCreateMode="closeNewsCreateMode"
          @closeNews="setCreateNewsMode"
        />
        <CurtainCreateChannel v-if="showCurtainCreateChannel" />
        <CurtainShowAndEditChannel v-if="showCurtainShowAndEditChannel" />
      </transition>
      <transition name="slide">
        <CurtainNewsLikes v-if="showCurtainNewsLikes" />
      </transition>
      <div class="news__channels">
        <ChannelsColumn
          :windowWidth="windowWidth"
          :channelList="channelList"
          :activeChannel="activeChannel"
          :loadingChannels="loadingChannels"
          :isAdmin="newsAdmin"
          :memberInfoChannel="memberInfoChannel"
          @setActiveChannel="activeChannelHandler"
        />
      </div>
      <div class="news__feed feed">
        <div class="feed__header">
          <h2 class="feed__title">
            <i class="icon-menu" @click="showMenu(true)" v-if="showBurgerMenu"></i>
            <i class="icon-arrow-back" @click="showMenu(false)" v-if="showArrowBack"></i>
            <i
              v-if="activeChannel || isCertainNews"
              class="icon-arrow-back"
              @click="backToAllNews"
            ></i>
            <div>{{ setTitle }}</div>
          </h2>
          <Button
            class="feed__add-news"
            :title="'Создать новость'"
            @handleButton="setCreateNewsMode"
            v-if="!isCertainNews && !isMobileWindowSize"
          />
          <Button
            class="feed__add-news"
            icon="plus"
            :title="''"
            @handleButton="setCreateNewsMode"
            v-if="!isCertainNews && isMobileWindowSize"
          />
          <i class="icon-chain" @click="copyLink" v-if="isCertainNews"></i>
        </div>
        <EmptyNewsItems
          v-if="!loading && !newsItems.length && channelList.length && !createNewsMode"
        />
        <Spinner v-if="loading" class="loader" />
        <NewsEdit
          v-show="!loading"
          v-if="createNewsMode && channelList.length"
          :createMode="true"
          @closeNewsCreateMode="closeNewsCreateMode"
          @closeNews="setCreateNewsMode"
        />
        <div v-if="!loading && newsItems.length" class="feed__items" ref="newsContainer">
          <NewsComponent
            v-for="news in newsItems"
            :key="news.id"
            :news="news"
            :commentsAdmin="newsAdmin"
            :newsAdmin="newsAdmin"
            @updateAllNews="getNewsItems"
            :globalEditMode="globalEditMode"
            @setGlobalEditMode="CHANGE_GLOBAL_EDIT_MODE(true)"
            @closeGlobalEditMode="CHANGE_GLOBAL_EDIT_MODE(false)"
          />
          <Spinner
            v-if="scrollLoading && newsTotalCount !== newsItems.length"
            class="loader-scroll"
          />
          <transition name="show-modal">
            <div
              v-if="showScrollToTop"
              @click="scrollToTopContainer($refs.newsContainer)"
              class="scroll-to-top"
            >
              <i class="icon-up"></i>
            </div>
          </transition>
        </div>
      </div>
      <div class="news__widgets">
        <NewEmployeeWidget :employeeItems="employeeItems" :loading="employeeLoader" />
        <NewsBirthdayWidget :birthdayItems="birthdayItems" :loading="birthdayLoader" />
      </div>
      <transition name="slideLeft">
        <ChannelModalMenu v-if="showChannelModalMenu && isMobileWindowSize" />
        <ChannelModalChannels
          v-if="showChannelModalChannels && isMobileWindowSize"
          :windowWidth="windowWidth"
          :channelList="channelList"
          :activeChannel="activeChannel"
          :loadingChannels="loadingChannels"
          :isAdmin="newsAdmin"
          :memberInfoChannel="memberInfoChannel"
          @setActiveChannel="activeChannelHandler"
        />
        <NewsBirthdayModal
          v-if="showNewsBirthdayModal && isMobileWindowSize"
          :birthdayItems="birthdayItems"
          :loading="birthdayLoader"
        />
        <NewEmployeeModal
          v-if="showNewEmployeeModal && isMobileWindowSize"
          :employeeItems="employeeItems"
          :loading="employeeLoader"
        />
      </transition>
    </div>

    <FullSizeImageModal
      @closeFullSizeImageModal="closeFullSizeImageModal"
      :imageLink="imageLink"
      v-if="showFullSizeImage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { format } from "date-fns";
import moment from "moment";
import ChannelsColumn from "@/modules/News/components/Channel/ChannelsColumn";
import NewsBirthdayWidget from "@/modules/News/components/BirthdayComponents/NewsBirthdayWidget";
import NewEmployeeWidget from "@/modules/News/components/NewEmployeeComponents/NewEmployeeWidget";
import NewsComponent from "@/modules/News/components/NewsItemsComponents/NewsComponent";
import FullSizeImageModal from "@/modules/News/components/FullSizeImageModal";
import NewsEdit from "@/modules/News/components/NewsCreateAndEdit/NewsEdit";
const EmptyNewsItems = () => import("@/modules/News/components/EmptyComponents/EmptyNewsItems");
import {
  CHANGE_GLOBAL_EDIT_MODE,
  CHANGE_SCROLL_LOADING
} from "@/modules/News/store/mutations/mutationTypes";
import Button from "@/modules/UiKit/components/buttons/Button";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import CurtainNewsCreate from "@/modules/News/components/NewsCreateAndEdit/CurtainNewsCreate";
import * as types from "@/modules/News/store/mutations/mutationTypes";
import CurtainCreateChannel from "@/modules/News/components/Channel/CurtainCreateCannel";
import CurtainNewsLikes from "@/modules/News/components/NewsLikes/CurtainNewsLikes";
import CurtainShowAndEditChannel from "@/modules/News/components/Channel/ShowAndEditChannel/CurtainShowAndEditChannel";
import copyToClipboard from "@/helpers/CopyToClipboard";
import ChannelModalMenu from "@/modules/News/components/Channel/ChannelModals/ChannelModalMenu";
import ChannelModalChannels from "@/modules/News/components/Channel/ChannelModals/ChannelModalChannels";
import NewsBirthdayModal from "@/modules/News/components/BirthdayComponents/NewsBirthdayModal";
import NewEmployeeModal from "@/modules/News/components/NewEmployeeComponents/NewEmployeeModal";

export default {
  name: "News",
  mixins: [],
  props: [],
  components: {
    NewsBirthdayModal,
    ChannelModalMenu,
    CurtainShowAndEditChannel,
    CurtainNewsLikes,
    CurtainCreateChannel,
    Spinner,
    Button,
    NewsEdit,
    EmptyNewsItems,
    FullSizeImageModal,
    NewsComponent,
    NewEmployeeWidget,
    NewsBirthdayWidget,
    ChannelsColumn,
    CurtainNewsCreate,
    ChannelModalChannels,
    NewEmployeeModal
  },
  data() {
    return {
      quizItems: [],
      createNewsMode: false,
      showScrollToTop: false,
      windowWidth: window.innerWidth
    };
  },
  beforeDestroy() {
    this.$refs.newsContainer.removeEventListener("scroll", this.handleScroll, false);
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    if (this.$route.query.news) {
      this.getCertainNews(this.$route.query.news);
    } else {
      this.getNewsItems();
    }
    this.getChannelInfo();
    this.getUsersBirthdaysInfo();
    this.getNewEmployeeInfo();
    window.addEventListener("resize", this.resizeHandler);
  },
  updated() {
    this.$refs.newsContainer.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("NewsModule", [
      "openFullSizeImage",
      "closeFullSizeImageModal",
      "getUsersBirthdaysInfo",
      "getNewEmployeeInfo",
      "setActiveChannel",
      "getNewsItems",
      "getChannelInfo",
      "getNewsItemInfoAction"
    ]),
    ...mapMutations("NewsModule", [
      CHANGE_GLOBAL_EDIT_MODE,
      types.TOGGLE_SHOW_CREATE_CURTAIN,
      types.SET_NEWS_ITEMS,
      types.CHANGE_LOADING,
      types.TOGGLE_IS_CERTAIN_NEWS,
      types.CHANGE_SCROLL_LOADING,
      types.SHOW_CHANNEL_MODAL_MENU,
      types.SHOW_NEW_EMPLOYEE_MODAL,
      types.SHOW_NEWS_BIRTHDAY_MODAL,
      types.SHOW_CHANNEL_MODAL_CHANNELS
    ]),
    // Включается режим создания новости
    setCreateNewsMode() {
      this[types.TOGGLE_SHOW_CREATE_CURTAIN](true);
    },
    copyLink() {
      copyToClipboard(window.location.href);
    },
    handleScroll() {
      if (this.$refs.newsContainer) {
        if (this.$refs.newsContainer.scrollTop >= 1000) {
          this.showScrollToTop = true;
        }
        if (this.$refs.newsContainer.scrollTop < 1000) {
          this.showScrollToTop = false;
        }
      }
    },
    // Отключается режим создания новости
    closeNewsCreateMode() {
      this.getNewsItems();
      this.createNewsMode = false;
    },
    // Промотать контейнер вверх
    scrollToTopContainer(item) {
      item.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    showMenu(payload) {
      this.SHOW_CHANNEL_MODAL_MENU(payload);
      if (payload === false) {
        this.SHOW_NEW_EMPLOYEE_MODAL(false);
        this.SHOW_NEWS_BIRTHDAY_MODAL(false);
        this.SHOW_CHANNEL_MODAL_CHANNELS(false);
      }
    },
    backToAllNews() {
      if (this.isCertainNews) {
        this.$router.push({ name: "News" });
        this.getNewsItems();
      } else {
        this.setActiveChannel("");
      }
    },
    getCertainNews(id) {
      this[types.CHANGE_SCROLL_LOADING](false);
      this[types.CHANGE_LOADING](true);
      if (this.newsItems.length) {
        this[types.SET_NEWS_ITEMS]([this.newsItems.find((news) => news.id === id)]);
        this[types.CHANGE_LOADING](false);
        this[types.TOGGLE_IS_CERTAIN_NEWS](true);
      } else {
        this.getNewsItemInfoAction(id)
          .then((response) => {
            this[types.TOGGLE_IS_CERTAIN_NEWS](true);
            this[types.SET_NEWS_ITEMS]([response.data.data]);
            this[types.CHANGE_LOADING](false);
          })
          .catch((error) => {
            if (error.response.data.data.error.message) {
              this.$router.push({ name: "News" });
            }
          });
      }
    },
    activeChannelHandler(val) {
      if (this.$route.query.news) this.$router.push({ name: "News" });
      this.setActiveChannel(val);
    }
  },
  computed: {
    ...mapGetters(["getDataInfoUser", "getUserId"]),
    ...mapState("NewsModule", [
      "imageLink",
      "showFullSizeImage",
      "showAddChannel",
      "editChannelValue",
      "memberInfoChannel",
      "memberUser",
      "isAdminLike",
      "memberListTitle",
      "activeFilter",
      "birthdayItems",
      "birthdayLoader",
      "employeeItems",
      "employeeLoader",
      "activeChannel",
      "loading",
      "newsCounter",
      "newsItems",
      "newsTotalCount",
      "channelList",
      "globalEditMode",
      "loadingChannels",
      "scrollLoading",
      "showCurtainCreateNews",
      "showCurtainCreateChannel",
      "showCurtainNewsLikes",
      "showCurtainShowAndEditChannel",
      "isCertainNews",
      "showChannelModalMenu",
      "showChannelModalChannels",
      "showNewsBirthdayModal",
      "showNewEmployeeModal"
    ]),
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    isAdmin() {
      return this.$store.state.auth.endpoints["monolit"]["Channel"].includes("actionUpdate");
    },
    commentsAdmin() {
      return this.$store.state.auth.endpoints["monolit"]["Comment"].includes("actionUpdate");
    },
    newsAdmin() {
      return this.getDataInfoUser.roles.includes("admin");
    },
    activeChannelName() {
      return this.channelList.find((item) => item.id === this.activeChannel).name;
    },
    setTitle() {
      let title = "";
      if (this.isCertainNews) {
        title = `Новость канала ${this.newsItems[0].channel.name}`;
      } else if (this.activeChannel) {
        title = this.activeChannelName;
      } else {
        title = "Лента новостей";
      }
      return title;
    },
    showArrowBack() {
      return (
        this.isMobileWindowSize &&
        !this.activeChannel &&
        (this.showChannelModalMenu ||
          this.showChannelModalChannels ||
          this.showNewsBirthdayModal ||
          this.showNewEmployeeModal)
      );
    },
    showBurgerMenu() {
      return (
        !this.showArrowBack && !this.activeChannel && !this.isCertainNews && this.isMobileWindowSize
      );
    }
  },
  watch: {
    activeChannel() {
      this.getNewsItems();
      this.showScrollToTop = false;
    },
    activeFilter() {
      this.getNewsItems();
    },
    $route(route) {
      if (route.query.news) {
        this.getCertainNews(route.query.news);
      } else {
        this.getNewsItems();
        this[types.TOGGLE_IS_CERTAIN_NEWS](false);
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.slideLeft-leave-active,
.slideLeft-enter-active {
  transition: 0.5s;
}
.slideLeft-enter {
  transform: translate(-100%, 0);
}
.slideLeft-leave-to {
  transform: translate(-100%, 0);
}

.loader {
  margin: auto;
}
.loader-scroll {
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
}

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.2s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.news {
  font-family: "SF", sans-serif !important;
  display: flex;
  width: 100%;
  background: #edf4fe;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  &__channel-button {
    display: none;

    @media (max-width: 767px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 24px;
      padding: 5px 12px;
      border: none;
      border-radius: 100px;
      background: $surface-default;
      color: $text-subdued;
      font-size: 14px;
      line-height: 20px;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      outline: none;
      transition: all 0.3s ease;
      &:hover {
        background: $action-primary;
        color: $surface-default;
      }
      &:active {
        background: $action-primary-pressed;
        color: $surface-default;
      }
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    overflow-y: hidden;
    overflow-x: auto;
    @media screen and (max-width: $md) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 60px;
    }
    @media screen and (max-width: $xxs) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__channels {
    width: 355px;

    @media (max-width: $xxxl) {
      min-width: 260px;
      width: 260px;
    }
    @media (max-width: $xl) {
      min-width: 230px;
      width: 230px;
    }
    @media (max-width: $md) {
      display: none;
    }
    @media (max-width: $lg) {
      min-width: 179px;
      width: 179px;
    }
  }
  .channels-column {
    margin-right: 30px;

    @media (max-width: 1410px) {
      margin-right: 10px;
    }

    @media (max-width: 1400px) {
      margin-right: 30px;
    }
  }
  .feed {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__items {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      height: calc(100% - 70px);
      overflow: auto;
      position: relative;

      /deep/ {
        .news-component {
          @media (max-width: $xxxl) {
            max-width: 566px;
          }
          @media (max-width: $xl) {
            max-width: 425px;
          }
          @media (max-width: 895px) {
            max-width: 300px;
          }
          @media (max-width: $md) {
            width: 100%;
            max-width: 696px;
          }
          .news-header__text-wrap {
            @media (max-width: $xl) {
              align-items: flex-start;
              flex-direction: column;
            }
            @media (max-width: $md) {
              align-items: center;
              flex-direction: row;
            }
            @media (max-width: $sm) {
              align-items: flex-start;
              flex-direction: column;
            }
          }

          .comment-bg {
            &__icons {
              .file-button {
                .icon-paper-clip-secondary {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    &__header {
      height: 70px;
      background-color: #fff;
      border-bottom: 1px solid #eae8f0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 24px;

      @media (max-width: $md) {
        width: auto;
        padding: 0 20px;
        margin: 0 -20px;
      }
      @media (max-width: $xxs) {
        width: auto;
        padding: 0 16px;
        margin: 0 -16px;
      }
      @media screen and (max-width: $sm) and (orientation: landscape) {
        height: 50px;
      }
      .icon-chain {
        cursor: pointer;
        color: $icon-subdued;
        font-size: 20px;
      }
      /deep/ {
        .action-list {
          transition: none;
        }
        .action-list__modal-button {
          white-space: nowrap;
        }
        .action-list__modal-list {
          padding: 8px;
        }
      }
    }
    &__title {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      max-width: 800px;
      i {
        color: $text-disabled;
        margin-right: 8px;
        font-size: 20px;
        cursor: pointer;
        transition: 0.3s color ease;
        &:hover {
          color: $icon-highlight;
        }
      }
      div {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &__item-wrap {
      padding-bottom: 40px;
    }

    &__add-news {
      padding: 10px 12px;
      height: 36px;
      @media (max-width: $lg) {
        font-size: 12px;
        padding: 10px 6px;
      }
      @media (max-width: $md) {
        position: absolute;
        bottom: 80px;
        right: 40px;
        height: 48px;
        width: 48px;
        justify-content: center;
        z-index: 8;
        padding: 0;
        margin: 0;
      }
    }
  }
  /deep/.aop-button {
    i {
      margin: 0;
    }
  }
  &__widgets {
    display: flex;
    flex-direction: column;
    width: 355px;
    min-width: 355px;
    height: 100%;
    overflow: hidden;

    @media (max-width: $xxxl) {
      min-width: 260px;
      width: 260px;
    }
    @media (max-width: $xl) {
      min-width: 230px;
      width: 230px;
    }
    @media (max-width: $lg) {
      min-width: 179px;
      width: 179px;
    }
    @media (max-width: $md) {
      display: none;
    }

    .new-employee-widget {
      @media (max-width: $xxxl) {
        width: auto;
      }
    }
    .birthday-widget {
      @media (max-width: $xxxl) {
        width: auto;
      }
    }
    .news-birthday-widget {
      margin-bottom: 30px;
    }
  }
  .scroll-to-top {
    position: sticky;
    left: 90%;
    bottom: 2%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border: 1px solid $secondary;
    font-size: 28px;
    color: $chat-editor;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    z-index: 50;
    transition: all 0.1s ease;
    @media screen and (max-width: $md) {
      display: none;
    }
    @media screen and (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: #f2f2f7;
      }
    }
    &:active {
      background-color: #d3d2e0;
    }
    svg {
      margin-right: 4px;
    }
    .icon-sort-arrow {
      transform: rotate(180deg);
      display: block;
      color: $icon-subdued;
      font-size: 16px;
    }
  }
}
</style>
