



























import { Component, Prop, Vue } from "vue-property-decorator";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import LabelTooltip from "@/aopV2/components/UIComponents/Tooltip/LabelTooltip.vue";

@Component({
  components: { LabelTooltip, TextLabel }
})
export default class DefaultSwitch extends Vue {
  @Prop({
    default: "",
    type: String
  })
  label!: string;
  @Prop({
    default: "",
    type: String
  })
  tooltip!: string;
  @Prop({
    default: "",
    type: String
  })
  icon!: string;
}
