<template>
  <div class="pin-messages">
    <div class="pin-messages__count" @click="handleOpenPinMessages">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M14.0703 4H11.0703V5.33333H14.0703V4ZM14.0703 12V10.6667H5.07031V12H14.0703ZM14.0703 7.33333H8.07031V8.66667H14.0703V7.33333Z"
            fill="#787996"
          />
          <g clip-path="url(#clip1)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.14501 6.77381C7.18527 6.62241 7.10831 6.47087 6.97126 6.38958C6.59214 6.16802 6.39193 5.71467 6.51017 5.27012L6.93854 3.65945L7.26068 3.74512C7.43785 3.79225 7.62136 3.68584 7.66849 3.50866C7.71561 3.33149 7.6092 3.14798 7.43203 3.10085L4.21068 2.2441C4.0335 2.19698 3.84999 2.30339 3.80287 2.48056C3.75574 2.65774 3.86215 2.84125 4.03933 2.88837L4.36146 2.97405L3.93309 4.58472C3.81485 5.02927 3.41589 5.32327 2.97679 5.32721C2.81747 5.32968 2.67539 5.42296 2.63512 5.57436L2.62398 5.61624C2.57686 5.79341 2.68327 5.97693 2.86044 6.02405L4.46467 6.45071L3.87139 8.70737C3.82427 8.88454 3.93068 9.06806 4.10785 9.11518C4.28502 9.1623 4.46854 9.05589 4.51566 8.87872L5.10894 6.62206L6.72606 7.05215C6.90323 7.09927 7.08675 6.99286 7.13387 6.81569L7.14501 6.77381Z"
              fill="#787996"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(16.0703 16) rotate(-180)"
            />
          </clipPath>
          <clipPath id="clip1">
            <rect
              width="8"
              height="8"
              fill="white"
              transform="translate(2.12695 1) rotate(14.8936)"
            />
          </clipPath>
        </defs>
      </svg>
      <span>{{ pinned_messages.length }}</span>
    </div>
    <p class="pin-messages__text" @click="handleClickItem">
      {{ setText }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import goToMessage from "@/modules/Messenger/mixins/goToMessage.mixin";

export default {
  name: "PinMessages",
  mixins: [goToMessage],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["switchPinMessages"]),
    handleClickItem() {
      this.goToReplyMessage(
        this.pinned_messages[this.pinned_messages.length - 1].id,
        "#list-dynamic"
      );
    },
    handleOpenPinMessages() {
      this.switchPinMessages({ show: true });
    }
  },
  computed: {
    ...mapState("MessengerModule", ["pinned_messages", "showPin", "pinnedCount"]),
    setText() {
      const length = this.pinned_messages.length;
      return this.pinned_messages[length - 1].text.length
        ? this.pinned_messages[length - 1].text
        : "file";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.pin-messages {
  top: 0;
  position: sticky;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  padding: 8px;
  display: flex;
  border-bottom: 1px solid #eae8f0;
  &__count {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-subdued;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__text {
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    margin-left: 8px;
    overflow: hidden;
    height: 20px;
    word-break: break-word;
  }
}
</style>
