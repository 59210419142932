<template>
  <div class="my-request-table__row" @click.self="openInfoModal">
    <div class="my-request-table__col my-request-table__col_type">
      <div
        class="my-request-table__type"
        :style="
          item.not_accepted_transfer_vacation_order
            ? 'background-color: #FFB901'
            : 'background-color: #6A67CE'
        "
      ></div>
      {{ setType }}
    </div>
    <div class="my-request-table__col my-request-table__col_author user" @click="openInfoModal">
      <img class="user__img" :src="returnSrcFile(getDataInfoUser.avatar)" alt="" />
      <div class="user__wrap">
        <p class="user__name">{{ getDataInfoUser.full_name }}</p>
      </div>
    </div>
    <div class="my-request-table__col my-request-table__col_date-request" @click="openInfoModal">
      <p class="my-request-table__subtitle">Дата запроса</p>
      {{ setLocalDate(date_of_creation) }}
    </div>
    <div class="my-request-table__col my-request-table__col_dates" @click="openInfoModal">
      <p class="my-request-table__subtitle">Актуальные даты</p>
      {{ setLocalDate(date_from) }} - {{ setLocalDate(date_to) }}
    </div>
    <div class="my-request-table__col my-request-table__col_count-days" @click="openInfoModal">
      <p class="my-request-table__subtitle">Количество дней</p>
      {{ count_days }}
    </div>
    <div class="my-request-table__col my-request-table__col_confirm" @click="openInfoModal">
      <p class="my-request-table__subtitle">Подтверждающие лица</p>
      <ConfirmationsUsers
        v-for="user in confirmations"
        :key="user.user.id"
        :user="user.user"
        :checkVacation="user.is_checked"
      />
    </div>
    <div class="my-request-table__col my-request-table__col_status" @click="openInfoModal">
      <p class="my-request-table__subtitle">Статус</p>
      <p
        class="my-request-table__status"
        :class="{
          'my-request-table__status_pending':
            status === 'pending' || status === 'pendingByVacationAdmin',
          'my-request-table__status_approved': status === 'accepted',
          'my-request-table__status_rejected': status === 'rejected'
        }"
      >
        <span class="my-request-table__status-icon">
          <i class="icon-check-mark"></i>
        </span>
        <span>{{ setStatusVacation(status) }}</span>
      </p>
    </div>
    <div class="my-request-table__col my-request-table__col_actions">
      <ActionsList
        :currentVacationData="item"
        :isAvailableForEditing="isAvailableForEditing"
        :showAllItem="true"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ActionsList from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestComponents/ActionsList";
import ConfirmationsUsers from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestComponents/ConfirmationsUsers";
import vacationMixin from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/mixins/vacationMixin";
import { domainStorage } from "@/globalVariables";

export default {
  name: "MyRequestTableRow",
  mixins: [vacationMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    getDataInfoUser: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isAvailableForEditing: {
      type: Boolean,
      default: true
    }
  },
  components: { ConfirmationsUsers, ActionsList },
  data() {
    return {
      date_of_creation: "",
      date_from: "",
      date_to: "",
      status: "",
      count_days: "",
      confirmations: [],
    };
  },
  mounted() {
    this.setDataVacation();
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    openInfoModal() {
      this.$root.$emit("openVacationRequestModal", {
        editMode: false,
        createMode: false,
        vacation: this.item,
        request: false
      });
    },
    setLocalDate(date) {
      moment.locale("ru");
      return moment(date).format("L");
    },

    //TODO: опишите функцию
    // TODO: сразу присвойсте какое-то значение для переменных в data(), потом меняйте, если нужно
    // TODO: можно вообще в отдельный файл эту функцию вынести, чтобы в коде не мешалась
    setDataVacation() {
      if (this.item.not_accepted_transfer_vacation_order) {
        this.date_of_creation = this.item.not_accepted_transfer_vacation_order.date_of_creation;
        this.date_from = this.item.not_accepted_transfer_vacation_order.new_date_from;
        this.date_to = this.item.not_accepted_transfer_vacation_order.new_date_to;
        this.status = this.item.not_accepted_transfer_vacation_order.status;
        this.confirmations = this.setConfirmationPerson(this.item.not_accepted_transfer_vacation_order.confirmations);
        this.count_days = this.item.not_accepted_transfer_vacation_order.count_days;
      } else {
        this.date_of_creation = this.item.date_of_creation;
        this.date_from = this.item.date_from;
        this.date_to = this.item.date_to;
        this.status = this.item.status;
        this.confirmations = this.setConfirmationPerson(this.item.confirmations);
        this.count_days = this.item.count_days;
      }
    },
    setConfirmationPerson(confirmations) {
      if (confirmations.length) {
        return confirmations.map(item => item).sort((a, b) => a.index > b.index ? 1 : -1);
      } else {
        return []
      }
    }
  },
  computed: {
    setType() {
      return this.item.not_accepted_transfer_vacation_order ? "Перенос" : "Отпуск";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";
.my-request-table {
  &__row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 40px;
    cursor: pointer;
    @media (max-width: $lg) {
      flex-wrap: wrap;
      padding: 16px 18px 36px;
      border-top: 0.8px solid #e2e8f0;
      margin-bottom: 0;
    }
  }

  &__row {
    .my-request-table__col {
      /deep/ {
        .absence-card__modal {
          .absence-card__modal-list {
            top: unset;
            bottom: 34px;

            @media (max-width: $lg) {
              top: 34px;
              bottom: unset;
            }
          }
        }
      }
    }
  }

  &__row:nth-child(2),
  &__row:nth-child(3),
  &__row:nth-child(4),
  &__row:nth-child(5),
  &__row:nth-child(6),
  &__row:nth-child(7),
  &__row:nth-child(8) {
    .my-request-table__col {
      /deep/ {
        .absence-card__modal {
          .absence-card__modal-list {
            top: 34px;
            bottom: unset;
          }
        }
      }
    }
  }

  &__col {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;

    @media (max-width: 1100px) {
      font-size: 12px;
      line-height: 20px;
    }

    @media (max-width: $lg) {
      flex-wrap: wrap;
      margin-right: 0;
      font-size: 14px;
    }

    &_type {
      width: 60%;
      @media (max-width: $lg) {
        margin-bottom: 32px;
        order: 1;
      }
    }

    &_author {
      display: none;

      .user {
        &__img {
          display: block;
          width: 36px;
          height: 36px;
          margin-right: 8px;
          border-radius: 50%;
          object-fit: cover;
        }

        &__name {
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          color: $text-default;
        }
      }

      @media (max-width: $lg) {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 32px;
        order: 2;
      }
    }

    &_date-request,
    &_count-days,
    &_status {
      width: 60%;
    }

    &_date-request {
      @media (max-width: $lg) {
        width: 50%;
        margin-bottom: 32px;
        order: 3;
      }
    }

    &_count-days {
      justify-content: center;
      @media (max-width: $lg) {
        justify-content: flex-start;
        width: 50%;
        margin-bottom: 32px;
        order: 4;
      }
    }

    &_dates {
      @media (max-width: $lg) {
        order: 5;
        margin-bottom: 32px;
      }
    }

    &_confirm {
      @media (max-width: $lg) {
        order: 6;
        width: 50%;
        align-self: flex-start;
      }
    }

    &_status {
      @media (max-width: $lg) {
        order: 7;
        margin-bottom: 25px;
        width: 50%;
      }
    }

    &_actions {
      width: 25%;
      margin-right: 0;

      @media (max-width: $lg) {
        order: 8;
        position: absolute;
        top: 20px;
        right: 25px;
      }
    }
  }

  &__subtitle {
    display: none;

    @media (max-width: $lg) {
      width: 100%;
      display: block;
      margin: 0 0 8px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: $text-subdued;
    }
  }

  &__type {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__status {
    display: flex;
    align-items: center;
    width: max-content;
    height: 24px;
    border-radius: 40px;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    &_pending {
      background: #fff8e5;
      color: #fd9a00;

      .my-request-table__status-icon {
        border: 1px solid #fd9a00;
      }
    }

    &_approved {
      background: #ebfcf6;
      color: #37c597;

      .my-request-table__status-icon {
        background-color: #37c597;
      }

      i {
        color: #ffffff;
      }
    }

    &_rejected {
      background: #fef0f0;
      color: #fb646d;

      .my-request-table__status-icon {
        background-color: #fb646d;
      }

      i {
        color: #ffffff;
      }
    }

    .my-request-table__status-icon {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 50%;
    }

    i {
      font-size: 5px;
      margin-bottom: 1px;
    }
  }

  /deep/ {
    .absence-card__modal {
      position: relative;
      margin-left: auto;

      .icon-dots-vertical {
        font-size: 20px;
        color: $icon-subdued;
        cursor: pointer;
      }
    }
  }
}
</style>
