<template>
  <div class="black-sphere__widgets" :class="{ 'black-sphere__widgets_admin': isAdmin }">
    <ChosenFilter
      icon="user"
      :search="true"
      placeholder="Получил оценку"
      :multiple="true"
      :chosenList="usersList"
      v-model="gotUsers"
      :allItem="true"
      @input="handleGotUser"
    />

    <ChosenFilter
      icon="user"
      :search="true"
      placeholder="Выставил оценку"
      :multiple="true"
      :chosenList="usersList"
      v-model="putUsers"
      :allItem="true"
      @input="handlePutUser"
    />

    <ChosenFilter
      icon="verified"
      :search="false"
      placeholder="Оценка"
      :multiple="true"
      :chosenList="markList"
      v-model="selectedMarks"
      :allItem="true"
      @input="handleMark"
    />

    <MonthPickerTable
      @changeMonthYear="changeMonthYear"
      :showIcon="true"
      :currentMonth="setCurrentMonth"
      :filterMonth="filterMonth"
    />

    <Button
      class="black-sphere__button"
      :type="'simple'"
      :icon="'plus'"
      :title="'Добавить оценку'"
      @handleButton="showModalBlackSphereAddAndEdit"
      v-if="isAdmin"
    />
  </div>
</template>

<script>
import MonthPickerTable from "@/components/Widgets/MonthPickerTable2.0/MonthPickerTable";
import Button from "@/modules/UiKit/components/buttons/Button";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";
import moment from "moment";

export default {
  name: "BlackSphereMarksWidgets",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ChosenFilter,
    Button,
    MonthPickerTable
  },
  data() {
    return {
      gotUsers: [],
      putUsers: [],
      selectedMarks: [],
      selectedMonth: "",
      markList: [
        {
          id: 0,
          name: "0"
        },
        {
          id: 1,
          name: "1"
        },
        {
          id: 2,
          name: "2"
        }
      ]
    };
  },
  mounted() {
    this.gotUsers = this.filterGotUser;
    this.putUsers = this.filterPutUser;
    this.selectedMarks = this.filterMark;
    this.selectedMonth = this.filterMonth;
  },
  methods: {
    showModalBlackSphereAddAndEdit() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        true
      );
    },
    resetDataForTable() {
      BlackSphere[BlackSphereMutationTypes.SET_BLACK_SPHERE_PAGE](1);
      BlackSphere[BlackSphereMutationTypes.RESET_BLACK_SPHERE_LIST_FOR_TABLE_LIST]();
    },
    changeMonthYear(val) {
      this.resetDataForTable();
      this.$emit("changeMonthYear", val);
    },
    handleGotUser(val) {
      this.gotUsers = val;
      this.resetDataForTable();

      this.$emit("selectGotUsers", this.gotUsers);
    },
    handlePutUser(val) {
      this.putUsers = val;
      this.resetDataForTable();

      this.$emit("selectPutUsers", this.putUsers);
    },
    handleMark(val) {
      this.selectedMarks = val;
      this.resetDataForTable();

      this.$emit("selectMarks", this.selectedMarks);
    }
  },
  computed: {
    setCurrentMonth() {
      return moment(this.filterMonth).format("M") - 1;
    },
    usersList() {
      return this.$store.getters["UsersModule/userList"]
        .filedList(["full_name", "avatar", "is_active"])
        .filter((item) => item.is_active);
    },
    filterMark() {
      return BlackSphere.filterMark ? BlackSphere.filterMark : [];
    },
    filterGotUser() {
      return BlackSphere.filterGotUser ? BlackSphere.filterGotUser : [];
    },
    filterPutUser() {
      return BlackSphere.filterPutUser ? BlackSphere.filterPutUser : [];
    },
    filterMonth() {
      return BlackSphere.filterMonth ? BlackSphere.filterMonth : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  &__button {
    margin-left: auto;
  }
  &__widgets {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    /deep/ {
      .chosen-filter {
        &__wrapper {
          @media screen and (max-width: 850px) and (max-height: 850px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 100;
            background: rgba(33, 32, 44, 0.8);
          }
        }
        &__list {
          @media screen and (max-width: 850px) and (max-height: 850px) {
            position: fixed;
            max-height: 70vh;
            width: 100%;
            max-width: unset;
            top: unset;
            right: 0;
            bottom: 0;
            border-radius: 16px 16px 0 0;
            overflow-y: auto;
            z-index: 100;
          }
        }
      }
      .select-bg {
        width: 350px;
        margin-right: 24px;

        @media (max-width: 1335px) {
          margin-right: 16px;
        }

        @media (max-width: 1100px) {
          margin-right: 8px;
        }

        .select-content-bg {
          height: 28px;

          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 190px);
            }
          }
        }

        .select-label {
          white-space: nowrap;
        }
      }

      .modal-items {
        background: #fafafe;
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }

      .select-bg_mark {
        width: 185px;
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      .month-picker-background {
        width: 145px;

        @media (max-width: 1100px) {
          width: 30px;
          margin-right: 8px;
        }

        .button-month-picker .button-month {
          @media (max-width: 1100px) {
            display: none;
          }
        }
      }
    }
  }
}
.default-button {
  margin-left: auto;

  @media (max-width: 1480px) {
    width: 30px;
    padding: 8px;
  }

  /deep/ {
    span {
      @media (max-width: 1480px) {
        display: none;
      }
    }

    i {
      @media (max-width: 1480px) {
        margin: 0;
      }
    }
  }
}
/deep/.app-bg__module-container {
  display: none !important;
}
</style>
