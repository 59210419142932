











































import { Component, Prop, Vue } from "vue-property-decorator";
import TextField from "@/aopV2/components/UIComponents/TextField/TextField.vue";
import { fontList } from "@/aopV2/assets/fontList";
import { domainStorage } from "@/globalVariables";
import MainTimer from "@/modules/TimeTracker/component/MainTimer.vue";
import UserCard from "@/aopV2/components/UIComponents/User/UserCard.vue";
import { mapState } from "vuex";
import TimeTracker from "@/modules/TimeTracker/store";
import { EventBus } from "@/main";
import { weekDay } from "@/modules/TimeTracker/helpers/weekDay";
import moment from "moment";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import ActionList, { IActionItem } from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import Auth from "@/store/Auth";

@Component({
  components: {
    // Skeleton,
    // UserCard,
    TextField,
    // MainTimer,
    // ActionList
  },
  computed: mapState("Notification", ["isShowNotificationModal"])
})
export default class MainHeader extends Vue {
  @Prop({
    default: "Main title",
    type: String
  })
  title!: string;
  @Prop({
    default: null,
    type: Function
  })
  headerSlot!: any;
  timerId: undefined | number = undefined;
  loader: boolean = false;
  currentType: IActionItem = {
    name: "Профиль",
    id: 1,
    type: "profile"
  };
  isShowList: boolean = false;

  mounted() {
    if (!this.key) {
      TimeTracker.setCurrentUserId(this.currentUserId);
      TimeTracker.getMyProject();
      TimeTracker.needToSync();
      this.getMyWorkTime();
    }
    this.$store.commit("MainLayoutSetting/setHeaderKey", "header");
  }

  get key() {
    return this.$store.getters["MainLayoutSetting/key"];
  }

  handleNotification(val: boolean) {
    this.$store.commit("Notification/toggleModal", !val);
    this.$store.dispatch("Notification/resetNotificationsAction");
  }

  setCurrentType(type) {
    this.currentType = type;
    if (this.currentType.type === "profile") {
      this.$router.push({ name: "Profile" });
    }
    if (this.currentType.type === "feedback") {
      this.$store.dispatch("bugsAndIdeasModule/openModal", true);
    }
    if (this.currentType.type === "admin") {
      let routeData = this.$router.resolve({ path: "/admin" });
      window.open(routeData.href, "_blank");
    }
    if (this.currentType.type === "logout") {
      Auth.logout();
    }
    this.closeList();
  }

  closeList() {
    this.isShowList = false;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  async getMyWorkTime() {
    this.loader = true;
    const days = weekDay(new Date(), "Воскресенье");
    const date_stop =
      days.startWeekDay === moment(new Date()).format("YYYY-MM-DD")
        ? null
        : moment(new Date()).format("YYYY-MM-DD");
    await Promise.all([
      TimeTracker.getMyWeekLimit(),
      TimeTracker.getMyWeekWorkTime({
        date_start: days.startWeekDay,
        date_stop,
        is_week: true
      })
    ]);
    if (!this.isActive) await TimeTracker.getMyTodayWorkTime(true);
    this.loader = false;
  }

  handleTimerButton() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (this.isWeekLimit) {
        if (this.isActive) {
          TimeTracker.toggleTime({
            isActive: !this.isActive,
            task: this.activeTask,
            project: this.activeProject
          });
        } else {
          EventBus.$emit("showNotification", {
            type: "warning",
            timeout: 5000,
            label: "Достигнут недельный лимит!"
          });
        }
      } else {
        if (this.isActiveTask) {
          TimeTracker.toggleTime({
            isActive: !this.isActive,
            task: this.activeTask,
            project: this.activeProject
          });
        } else {
          EventBus.$emit("showNotification", {
            type: "warning",
            timeout: 5000,
            label: "Выберите задачу!"
          });
        }
      }
    }, 300);
  }

  get fontStyle() {
    return fontList.headlineBoldMedium;
  }

  get user() {
    return this.$store.getters.getDataInfoUser;
  }

  get avatar() {
    return domainStorage + this.user.avatar;
  }

  get isActive() {
    return TimeTracker.hasActiveTimeTracker;
  }

  get limit() {
    return TimeTracker.limit;
  }

  get isActiveTask() {
    return !!this.activeTask && !!this.activeProject;
  }

  get isWeekLimit() {
    return TimeTracker.isWeekLimit;
  }

  get activeTask() {
    return TimeTracker.activeTask;
  }

  get activeProject() {
    return TimeTracker.activeProject;
  }

  get project() {
    return this.activeProject && TimeTracker.project(this.activeProject!.id);
  }

  get currentUserId() {
    return this.$store.getters.getUserId;
  }

  get countNotificationList() {
    return this.$store.state.Notification.notificationListCountUnread;
  }

  get actionList() {
    let actionList: Array<IActionItem> = [];
    actionList.push(
      {
        name: "Профиль",
        id: 1,
        type: "profile"
      },
      {
        name: "Обратная связь",
        id: 2,
        type: "feedback"
      }
    );
    if (this.adminPanel) {
      actionList.push({
        name: "Админ панель",
        id: 3,
        type: "admin"
      });
    }
    actionList.push({
      name: "Выход",
      id: 4,
      type: "logout"
    });
    return actionList;
  }

  get adminPanel() {
    return this.$store.getters.getDataInfoUser.roles.find(
      (role) => role === "rights_admin" || role === "personnel_officer" || role === "admin"
    );
  }
}
