<template>
  <div class="tabs">
    <ul class="tabs__list">
      <li class="tabs__item" v-for="tab in tabs" :key="tab.value">
        <label :class="{ active: tab.value === value }">
          <input
            class="visually-hidden"
            type="radio"
            name="tab"
            :checked="tab.value === value"
            :value="textValue"
            @change="$emit('input', tab.value)"
          />
          {{ tab.label }}
          <span v-if="tab.count">({{ setCount(tab) }})</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsContent",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: String,
      default: ""
    },
    textValue: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setCount(tab) {
      return tab.count ? (tab.count > 99 ? "99+" : tab.count) : 0;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  height: 4px;
  width: 0;
  border-radius: 16px;
  padding-top: 5px;
  cursor: pointer;

  @media (max-width: $sm) {
    height: 2px;
  }
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.tabs {
  font-family: "Roboto", "Arial", sans-serif;
  position: relative;

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  &__list {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    @media (max-width: $lg) {
      overflow-x: scroll;
    }
  }

  &__item {
    position: relative;
    transition: all 0.5s ease;
    display: block;
    padding: 0;
    margin: 0 20px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: $text-disabled;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $text-primary;
    }

    @media (max-width: $sm) {
      font-size: 14px;
      margin: 0 8px;
    }
  }

  label {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0 0 8px;
  }

  .active {
    color: $text-primary;
    transition: all 0.2s ease;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $action-primary-accent;
      border-radius: 4px;
      animation-name: opacity;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;

      @media (max-width: $sm) {
        bottom: 6px;
        height: 2px;
      }
    }
  }
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
