<template>
  <div class="create-stage">
    <div class="create-stage__project field">
      <Label :label="'Проект'" />
      <Select
        :placeholder="'Выбрать'"
        :items="projectList"
        v-model="project"
        :value="project"
        :multiple="false"
        :showAvatar="false"
        :disabled="!!currentProjectForCreation"
        :removeCross="!currentProjectForCreation"
        @input="handleChangeProject"
      />
    </div>
    <div class="create-stage__field field">
      <Label :label="'Название этапа*'" />
      <Input :placeholder="'Введите название этапа'" :maxlength="255" @input="handleChangeTitle" />
    </div>
    <div class="create-stage__field field">
      <Label :label="'Описание'" />
      <Textarea
        :placeholder="'Добавьте описание этапа'"
        :maxlength="32000"
        @input="handleChangeDescription"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import Label from "@/modules/Isp/components/IspProblem/Label";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import Select from "@/modules/UiKit/components/Select/Select";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";

export default {
  name: "CreateStage",
  mixins: [],
  model: {
    prop: "stageToCreate",
    event: "fieldChanged"
  },
  props: {
    allUsers: {
      type: Array,
      default: () => {}
    },
    stageToCreate: {
      type: Object,
      default: () => {}
    },
    projectList: {
      type: Array,
      default: () => {}
    }
  },
  components: { Input, Label, Textarea, Select },
  data() {
    return {
      project: []
    };
  },
  mounted() {
    if (this.currentProjectForCreation) {
      this.project = [this.currentProjectForCreation];
      this.handleChangeProject(this.project);
    }
  },
  methods: {
    handleChangeProject(val) {
      const changedStage = JSON.parse(JSON.stringify(this.stageToCreate));
      changedStage.project_id = val[0]?.id;
      this.$emit("fieldChanged", changedStage);
    },
    handleChangeTitle(val) {
      const changedStage = JSON.parse(JSON.stringify(this.stageToCreate));
      changedStage.name = val.trim();
      this.$emit("fieldChanged", changedStage);
    },
    handleChangeDescription(val) {
      const changedStage = JSON.parse(JSON.stringify(this.stageToCreate));
      changedStage.description = val.trim().length ? val.trim() : undefined;
      this.$emit("fieldChanged", changedStage);
    }
  },
  computed: {
    currentProjectForCreation() {
      return TaskManagerModuleV2.currentProjectForCreation;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
