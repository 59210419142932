<template>
  <ul class="switch" :class="setColorStatus">
    <li
      v-for="radio in items"
      :key="radio.value"
      class="switch__item"
      :class="{ switch__item_disabled: disabledSwitch }"
      @click="handleChangeInvitation(radio.value)"
    >
      <div
        class="switch__button"
        :class="defaultValue === radio.value ? 'active' : ''"
        :style="`width: ${radio.size}px; height: ${radio.size}px;`"
      ></div>
    </li>
  </ul>
</template>

<script>
import moment from "moment";

export default {
  name: "SwitchThreeStateWidget",
  mixins: [],
  props: ["unique", "defaultValue", "items", "emit", "readonly", "size", "event"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleChangeInvitation(value) {
      if (!this.disabledSwitch) {
        this.$emit("handleChangeInvitation", value);
      }
    }
  },
  computed: {
    setColorStatus() {
      return this.defaultValue === "true"
        ? "switch-active"
        : this.defaultValue === "false"
        ? "switch-off"
        : "";
    },
    eventPassed() {
      return moment(this.event.started_at).isBefore(moment());
    },
    disabledSwitch() {
      return this.eventPassed || this.readonly;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 40px;
  height: 14px;
  padding: 2px;
  background: $icon-disabled;
  border-radius: 24px;

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    &_disabled {
      cursor: default;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $surface-default;
    border-radius: 50%;
    transition: all 0.3s ease;
    opacity: 0;
  }

  .active {
    opacity: 1;
  }
}

.switch-active {
  background: $action-primary;
}

.switch-off {
  background: $text-subdued;
}
</style>
