export default {
  state: {
    dataInfoUser: {}
  },
  mutations: {
    changeDataInfoUser(state, payload) {
      state.dataInfoUser = payload;
    }
  },
  actions: {},
  getters: {
    getDataInfoUser(state) {
      return state.dataInfoUser;
    }
  }
};
