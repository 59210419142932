import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import { IBlackSphereParams } from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModel";

class TaskBlackSphereService extends Service {
  createTaskBlackSphere(body: IBlackSphereParams): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/tasks/create_black_spheres`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }

  getTaskBlackSphere(params: IBlackSphereParams): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/tasks/get_black_spheres`,
      params
    }).then((response) => {
      return response.data;
    });
  }

  deleteTaskBlackSphere(body: IBlackSphereParams): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/tasks/delete_black_sphere`,
      data: body
    }).then((response) => {
      return response.data;
    });
  }
}

export default new TaskBlackSphereService();
