<template>
  <div
    class="step-link"
    :class="{ 'step-link_critical': isCritical && showCriticalWay }"
    ref="step-link"
  >
    <div class="step-link__line-1"></div>
    <div class="step-link__rectangle-1" ref="rectangle-1"></div>
    <div class="step-link__rectangle-2" ref="rectangle-2"></div>
    <div class="step-link__arrow" ref="arrow"></div>
  </div>
</template>

<script>
import PlanningScheduleModule from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";
import moment from "moment";

export default {
  name: "StepLink",
  mixins: [],
  props: {
    stepLink: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showCriticalWay: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const stepFrom = this.stepList.find((item) => this.stepLink.step_from === item.id);
      const stepTo = this.stepList.find((item) => this.stepLink.step_to === item.id);
      if (!stepFrom && stepTo) return;
      let endDate = stepFrom.date_end;
      let reserve = stepFrom.reserve;
      while (reserve) {
        endDate = moment(endDate).add("days", 1).format("YYYY-MM-DD");
        if (new Date(endDate).getDay() !== 6 && new Date(endDate).getDay() !== 0) {
          reserve -= 1;
        }
      }
      const startLinkDayColumnNode = document.getElementById(endDate);
      const endLinkDayColumnNode = document.getElementById(`${stepTo.date_start}`);
      const dayColumnWidth = startLinkDayColumnNode.getBoundingClientRect().width;
      const diffRelativeNumbers = stepTo.relative_number - stepFrom.relative_number;
      const daysDiffPx =
        endLinkDayColumnNode?.getBoundingClientRect().left -
        startLinkDayColumnNode?.getBoundingClientRect().left;
      this.$refs["step-link"].style.top = `${
        (stepFrom.relative_number - 1) * this.topMargin + 25
      }px`;
      if (daysDiffPx > 0) {
        this.$refs["step-link"].style.left = `${
          startLinkDayColumnNode?.getBoundingClientRect().left +
          4 +
          dayColumnWidth -
          dayColumnWidth * 2
        }px`;
        this.$refs["step-link"].style.flexDirection = "row";
        this.$refs["step-link"].style.alignItems = "start";
        this.$refs["rectangle-1"].style.height = `${diffRelativeNumbers * this.topMargin}px`;
        this.$refs["rectangle-1"].style.width = "1px";
        this.$refs.arrow.style.marginRight = "unset";
        this.$refs.arrow.style.alignSelf = "end";
        this.$refs.arrow.style.marginLeft = "-2px";
        this.$refs.arrow.style.width = `${daysDiffPx - dayColumnWidth / 2 - 2}px`;
      } else {
        this.$refs["step-link"].style.left = `${
          startLinkDayColumnNode?.getBoundingClientRect().left +
          4 -
          Math.abs(daysDiffPx) +
          dayColumnWidth / 2 -
          dayColumnWidth * 2
        }px`;
        this.$refs["rectangle-1"].style.height = `${
          diffRelativeNumbers * this.topMargin - this.topMargin / 2
        }px`;
        this.$refs["rectangle-1"].style.width = `${dayColumnWidth + Math.abs(daysDiffPx)}px`;
        this.$refs["rectangle-2"].style.height = `${this.topMargin / 2}px`;
        this.$refs["rectangle-2"].style.width = `${dayColumnWidth + Math.abs(daysDiffPx)}px`;
      }
    });
  },
  methods: {},
  computed: {
    topMargin() {
      return PlanningScheduleModule.topMargin;
    },
    isCritical() {
      let isCritical = false;
      PlanningScheduleModule.criticalPath.forEach((array) => {
        if (array.some((stepLink) => stepLink.id === this.stepLink.id)) isCritical = true;
      });
      return isCritical;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.step-link {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  &__line-1 {
    height: 1px;
    width: 23px;
    background-color: #409cff;
  }
  &__rectangle-1 {
    width: 26px;
    border-right: 1px solid #409cff;
    border-bottom: 1px solid #409cff;
  }
  &__rectangle-2 {
    border-left: 1px solid #409cff;
  }
  &__arrow {
    height: 1px;
    width: 26px;
    background-color: #409cff;
    position: relative;
    margin-right: auto;
    &:after {
      content: "";
      height: 1px;
      width: 6px;
      background-color: #409cff;
      transform: rotate(20deg);
      position: absolute;
      bottom: 1px;
      right: 0;
    }
    &:before {
      content: "";
      height: 1px;
      width: 6px;
      background-color: #409cff;
      transform: rotate(-20deg);
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
  &_critical {
    z-index: 1;
    .step-link {
      &__line-1 {
        background-color: #ff649c;
      }
      &__rectangle-1 {
        border-color: #ff649c;
      }
      &__rectangle-2 {
        border-color: #ff649c;
      }
      &__arrow {
        background-color: #ff649c;
        &:after {
          background-color: #ff649c;
        }
        &:before {
          background-color: #ff649c;
        }
      }
    }
  }
}
</style>
