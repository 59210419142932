













import { Component, Prop, Vue } from "vue-property-decorator";
import TabItem from "@/aopV2/layout/LayoutMain/MainTabs/TabItem.vue";

@Component({
  components: { TabItem }
})
export default class MainTabs extends Vue {
  @Prop({ type: Array, default: () => [] }) tabs!: Array<any>;
  @Prop({ type: String, default: "" }) activeTabName!: string;
  @Prop({ type: Boolean, default: true }) isMainTab!: boolean;

  activeTab(tab: { route: string }) {
    if (this.isMainTab) {
      this.$store.commit("MainLayoutSetting/updateSettingParams", {
        paramName: "activeTab",
        value: tab.route
      });
    } else {
      this.$emit("change", tab);
    }
  }
}
