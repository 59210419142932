

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainLoader extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  small!: boolean;
  @Prop({
    type: String,
    default: ""
  })
  color!: string;

  get isWhite() {
    return this.color === "#fff";
  }
}
