<template>
  <div class="step-links" @click="openModal" :class="{ disabled: disabled }">
    <ModalLinks
      v-if="showModal"
      :linksRelativeList="linksRelativeList"
      :stepList="stepList"
      :stepLinksList="stepLinksList"
      :plan="plan"
      :step="step"
      @cancel="cancelSetLinks"
      @setLinks="setLinks"
    />
    <p>{{ linksNumbersSorted }}</p>
    <i class="icon-down-secondary"></i>
  </div>
</template>

<script>
import ModalLinks from "@/modules/TaskManager/components/PlanningSchedule/Plan/PlanTable/StepLinks/ModalLinks/ModalLinks";
export default {
  name: "StepLinks",
  mixins: [],
  props: {
    plan: {
      type: Object,
      default: () => {
        return {};
      }
    },
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    stepList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    stepLinksList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    linksRelativeList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { ModalLinks },
  data() {
    return {
      showModal: false
    };
  },
  mounted() {},
  methods: {
    openModal() {
      this.showModal = true;
    },
    cancelSetLinks() {
      this.showModal = false;
    },
    setLinks() {
      this.showModal = false;
    }
  },
  computed: {
    linksNumbersSorted() {
      if (!this.linksRelativeList.length) {
        return "1";
      }
      let text = "";
      const sorted = this.linksRelativeList;
      sorted.sort((a, b) => a?.relative_number - b?.relative_number);
      sorted.forEach((item) => {
        text += `${item.relative_number}, `;
      });
      return text.substring(0, text.length - 2);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.step-links {
  width: 100%;
  height: 40px;
  border: 1px solid #eae8f0;
  border-radius: 2px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //transition: 0.2s all ease;
  cursor: pointer;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
  i {
    font-size: 7px;
    color: $icon-subdued;
  }
  &:hover {
    border-color: $link-default;
  }
}
.disabled {
  pointer-events: none;
}
</style>
