




















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";

@Component({ components: { TextLabel } })
export default class TextArea extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  id!: number;
  @Prop({
    type: String,
    default: ""
  })
  label!: string;
  @Prop({
    type: String,
    default: "Placeholder"
  })
  placeholder!: string;
  @Prop({
    type: Number,
    default: 24
  })
  rowHeight!: number;
  @Prop({
    type: Number,
    default: 1
  })
  rows!: number;
  @Prop({
    type: Number,
    default: 10000
  })
  maxLength!: number;
  @Prop({
    type: Boolean,
    default: true
  })
  autoGrow!: Boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: Boolean;

  mounted() {
    this.$nextTick(() => {
      this.calculateInputHeight();
    });
  }

  changeTextarea() {
    if (this.autoGrow) {
      this.calculateInputHeight();
    }
  }

  calculateInputHeight() {
    if (this.autoGrow) {
      const input = this.$refs.textarea as HTMLTextAreaElement;
      if (!input) return;
      input.style.height = "0";
      const height = input.scrollHeight;
      const minHeight: number = this.rows * this.rowHeight;
      input.style.height = Math.max(minHeight, height) + "px";
    }
  }

  get styleClasses() {
    return {
      textarea__input: true,
      "textarea__input_no-resize": !this.autoGrow || this.disabled,
      textarea__input_disabled: this.disabled
    };
  }

  get width() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  @Watch("width") onChangeWidthWindow() {
    this.calculateInputHeight();
  }
}
