<template>
  <div
    class="statistics__status"
    :style="
      type === 'shortage'
        ? { color: '#E73B3B', background: '#FEF0F0' }
        : { color: '#29A27B', background: '#EBFCF6' }
    "
  >
    <i :class="type === 'shortage' ? `icon-shortage` : 'icon-excess'"></i>
    <p class="statistics__text">
      {{ type === "shortage" ? "Недоработка" : "Переработка" }}:
      {{ setTimeFormatWithFullTimUnits(time) }}
    </p>
  </div>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";

export default {
  name: "StatisticsStatus",
  mixins: [formatDateAndTime],
  props: {
    type: {
      type: String,
      default: ""
    },
    time: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.statistics {
  &__status {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 16px;

    i {
      font-size: 16px;
      line-height: 29px;
      margin-right: 8px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.8;

    @media (max-width: $xxxl) {
      font-size: 9px;
      line-height: 16px;
    }
  }
}
</style>
