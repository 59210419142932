<template>
  <div class="plans-table__fields fields">
    <div class="fields__name cell">Название плана</div>
    <div class="fields__date-from cell">Ранняя дата начала</div>
    <div class="fields__date-to cell">Поздняя дата окончания</div>
    <div class="fields__last-changes cell">Последние изменения</div>
    <div class="fields__actions cell">Действия</div>
  </div>
</template>

<script>
export default {
  name: "PlansTableFields",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.fields {
  background-color: $surface-selected-default;
  display: flex;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $text-default;
  }
  &__name {
    width: 40%;
  }
  &__date-from,
  &__date-to,
  &__last-changes {
    width: 18%;
  }

  &__actions {
    width: 6%;
  }
}
</style>
