<template>
  <div class="modal-textarea__wrap" v-click-outside="hideEmojiModal">
    <div class="modal-textarea__emoji">
      <i class="icon-emoji" @click="showEmojiModal = !showEmojiModal"></i>
      <Picker
        v-show="showEmojiModal"
        set="apple"
        @select="addEmoji"
        title="Выберите Эмоджи"
        :i18n="settings"
      />
    </div>
    <textarea
      class="modal-textarea"
      id="textEditorModal"
      @input="mixin_autoResize_resize"
      @paste="observePaste"
      ref="modalTextEditor"
      @keydown.enter.prevent.exact="switchKeyEnter"
      @keyup.ctrl.enter.prevent="switchKeyCtrlEnter"
      v-model.trim="value"
      maxlength="8192"
      placeholder="Введите текст сообщения"
    >
    </textarea>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ResizeMixin from "@/helpers/ResizeMixin";
import { Picker } from "emoji-mart-vue";
import ClickOutside from "vue-click-outside";

export default {
  name: "ModalTextEditor",
  mixins: [ResizeMixin],
  props: {},
  components: { Picker },
  data() {
    return {
      value: "",
      sendKey: "enter",
      size: 28,
      showEmojiModal: false,
      settings: {
        search: "Поиск",
        notfound: "Эмоджи не найдено",
        categories: {
          search: "Результаты поиска",
          recent: "Часто используемые",
          people: "Смайлы и Люди",
          nature: "Животные и Природа",
          foods: "Еда и Напитки",
          activity: "Мероприятия",
          places: "Путешествия и Места",
          objects: "Разное",
          symbols: "Символы",
          flags: "Флаги"
        }
      }
    };
  },
  mounted() {
    this.value = this.editorValue;
  },
  methods: {
    ...mapActions("MessengerModule", [
      "setPasteFile",
      "sendMessage",
      "closeFileModal",
      "setEditorValue"
    ]),
    addEmoji(emoji) {
      // selectionStart - Начало выделения
      // selectionEnd - Конец выделения
      let cursorPositionStart = this.$refs.modalTextEditor.selectionStart;
      let cursorPositionEnd = this.$refs.modalTextEditor.selectionEnd;
      let Emoji = emoji.native;
      let FirstPart = this.value.slice(0, cursorPositionStart);
      let SecondPart = this.value.slice(cursorPositionEnd);
      this.value = FirstPart + Emoji + SecondPart;
    },
    hideEmojiModal() {
      this.showEmojiModal = false;
    },
    observePaste(event) {
      if (event.clipboardData.files.length) {
        event.preventDefault();
        const val = event.clipboardData.files[0];
        const file = {
          file: val,
          name: val.name,
          size: val.size,
          type: val.type,
          fileObject: true,
          postAction: "/upload/post"
        };
        this.setPasteFile([file]);
      }
    },
    switchKeyCtrlEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.newLine(event);
      } else {
        this.sendMessageHandler();
      }
    },
    switchKeyEnter(event) {
      if (this.selectedSettingsEditor === "Enter") {
        this.sendMessageHandler();
      } else {
        this.newLine(event);
      }
    },
    sendMessageHandler() {
      this.$emit("sendFile");
    },
    newLine(e) {
      let caret = e.target.selectionStart;
      e.target.style.height = `${e.target.scrollHeight + 20}px`;
      e.target.setRangeText("\n", caret, caret, "end");
      this.value = e.target.value;
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    ...mapState("MessengerModule", ["editorValue", "currentChat", "selectedSettingsEditor"])
  },
  watch: {
    value() {
      this.$emit("changeModalTextEditor", this.value);
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal-textarea {
  border: 1px solid $border-subdued;
  background-color: $bg-surface-light;
  resize: none;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 8px 4px 26px;
  height: 28px;
  max-height: 68px;
  width: 100%;
  color: $text-default;
  border-radius: 4px;
  &__wrap {
    margin-top: 8px;
  }
  &__emoji {
    position: relative;
    i {
      position: absolute;
      top: 6px;
      left: 4px;
      color: $icon-subdued;
      cursor: pointer;
    }
    .emoji-mart {
      position: absolute;
      z-index: 100;
      bottom: calc(100% + 5px);
      left: 0;
      height: 320px;
      /deep/ {
        .emoji-mart-preview {
          display: none;
        }
      }
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
}
</style>
