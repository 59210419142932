












import { Component, Prop, Vue } from "vue-property-decorator";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({
  components: { SelectList }
})
export default class ConfirmationRepeatModal extends Vue {
  @Prop({
    default: () => {}
  })
  propA: object = {};
  selectList = [
    { name: "Одно", just_current: true, id: 0 },
    { name: "Все последующие", just_current: false, id: 1 }
  ];
  selected = [{ name: "Одно", just_current: true, id: 0 }];

  change(selected) {
    this.selected = selected;
    CalendarModule2.setJustCurrent(this.selected[0].just_current);
  }
}
