import { domain, domainV2 } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";
import { EventBus } from "@/main";
import httpClientV2 from "@/api/client/httpClientV2";
import centrifuge from "@/centrifuge";

export default {
  data() {
    return {
      pullName: "",
      loading: false,
      chunksArr: [],
      fileUploader: [],
      oldEditFiles: [],
      fileUploadingId: [],
      uploadedFiles: [],
      formats: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/x-flv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/mp4",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/x-msvideo",
        "video/3gpp",
        "video/mpeg",
        "audio/mpeg",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "jpeg",
        "jpg",
        "pdf",
        "png",
        "flv",
        "mp3",
        "mp4",
        "ppt",
        "pptx",
        "avi",
        "mov",
        "wmv",
        "3gp"
      ],
      flag: false,
      typeCreate: Number,
      error: false
    };
  },
  methods: {
    uploadFile(value, payload) {
      this.typeCreate = payload;
      if (value.length === 0) {
        this.filesUploaded();
      }
      this.error = false;
      let size = 0;
      this.files = value.filter((item) => {
        if (item.size > 10485760) {
          EventBus.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Размер файла ${item.name} превышает 10 мб`
          });
          this.handleErrorUpload();

          return false;
        }
        size += item.size;
        if (item.extension) {
          return true;
        }
        if (item.file.type || item.file.type === "") {
          if (
            this.formats.includes(item.file.type.toLowerCase()) ||
            this.formats.includes(item.type.toLowerCase())
          ) {
            return true;
          } else {
            EventBus.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Формат файла ${item.file.type} не поддерживается`
            });
            this.handleErrorUpload();
            return false;
          }
        }
        return false;
      });
      if (size > 52428800) {
        EventBus.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Общий размер файлов превышает 50 мб`
        });
        this.files = this.task.attachment_files;
        this.handleErrorUpload();
      }
      if (!this.error) {
        this.uploadHandler(this.files);
      }
    },
    uploadHandler(files) {
      this.fileUploader = [];
      this.oldEditFiles = [];
      this.fileUploadingId = [];
      this.uploadedFiles = [];
      files.forEach((item) => {
        if (!item.link) {
          item.label = item.name;
          this.fileUploader.push(item);
        }
        if (item.link) {
          this.oldEditFiles.push(item);
        }
      });
      if (this.fileUploader.length) {
        this.changeLoading(true);
        this.createPull(this.fileUploader);
      } else {
        this.handleErrorUpload();
      }
    },
    filesUploaded() {
      this.changeLoading(false);
      this.handleEditFiles({
        ids: [...this.oldEditFiles.map((item) => item.id), ...this.fileUploadingId],
        files: [...this.oldEditFiles, ...this.uploadedFiles]
      });
    },
    handleErrorUpload() {
      this.error = true;
      this.loaderFiles = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPull(arr) {
      if (!arr.length) {
        this.filesUploaded();
        return null;
      }
      httpClientV2({
        url: `${domainV2}/task_manager/files/create_chunk`,
        method: "POST",
        data: {
          file_name: arr[0].name
        }
      })
        .then((response) => {
          this.pullName = response.data.pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.changeLoading(false);
          this.errorAlert(error);
          this.handleErrorUpload();
        });
    },

    async createChunks(arr) {
      await this.getBase64(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArr = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArr = base64.match(/.{1,5000000}/g);
        }
        // TODO: Если файлы большие
        // this.chunksArr = base64.match(/.{1,5000000}/g)
      });
      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.changeLoading(false);
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        let nulls =
          Array.from(this.chunksArr.length.toString())
            .map(() => "0")
            .join("") + "0";
        let chunkName = nulls.substr(0, nulls.length - i.toString().length) + i;
        await httpClientV2({
          url: `${domainV2}/task_manager/files/upload_chunk`,
          method: "POST",
          data: {
            base_64_chunk: item,
            chunk_name: chunkName,
            pool_name: this.pullName
          }
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.changeLoading(false);
            this.loading = false;
            this.errorAlert(error);
            this.handleErrorUpload();
          });
      }
    },
    getFileFromPool(arr) {
      httpClientV2({
        url: `${domainV2}/task_manager/files/collect_pool`,
        method: "POST",
        data: {
          pool_name: this.pullName
        }
      })
        .then((response) => {
          this[`${response.data.channel}`] = centrifuge.subscribe(
            response.data.channel,
            (message) => {
              if (message.data.code === 422) {
                this.loaderFiles = false;
                this.$root.$emit("showNotification", {
                  type: "error",
                  timeout: 3000,
                  label: `Выбранный Вами файл не подходит по расширению или размеру!`
                });
                this.loading = false;
                return false;
              }
              this.saveFileId(message.data.message, response.data.channel, arr);
            }
          );
          this[`${response.data.channel}`].history().then(
            (resp) => {
              if (resp.publications.length) {
                if (
                  resp.publications[resp.publications.length - 1].data.message &&
                  typeof resp.publications[resp.publications.length - 1].data.message !==
                    "number" &&
                  isNaN(resp.publications[resp.publications.length - 1].data.message)
                ) {
                  this.loaderFiles = false;
                  this.$root.$emit("showNotification", {
                    type: "error",
                    timeout: 3000,
                    label: `${resp.publications[resp.publications.length - 1].data.message}`
                  });
                  this.loading = false;
                  return false;
                }
                this.saveFileId(
                  Number(resp.publications[resp.publications.length - 1].data.message),
                  response.data.channel,
                  arr
                );
              }
            },
            (err) => {
              console.error(err);
              this.loading = false;
            }
          );
        })
        .catch((error) => {
          this.changeLoading(false);
          this.handleErrorUpload(arr);
          this.errorAlert(error);
        });
    },
    changeLoading(val) {
      this.loading = val;
    },
    saveFileId(item, channel, arr) {
      let arrFiles = JSON.parse(JSON.stringify(this.fileUploadingId));
      arrFiles.push(item);
      this.fileUploadingId = [...new Set(arrFiles)];
      this[`${channel}`].unsubscribe();
      arr.splice(0, 1);
      this.createPull(arr);
    }
  }
};
