<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'full_name'">
      <User
        :avatar="getUsers.avatar"
        :name="getUsers.full_name"
        :id="getUsers.id"
        :showTooltip="false"
      />
    </div>
    <div class="table__content table__content_connect" v-if="cell.key === 'connected'">
      {{ setConnectValue }}
    </div>
    <div class="table__content" v-if="cell.key === 'count_marks'">
      {{ row.count_marks }}
    </div>
    <div
      class="table__content"
      :class="{
        table__content_percent: row.percent,
        'table__content_no-marks': row.percent === '0.00'
      }"
      v-if="cell.key === 'percent'"
    >
      <i class="icon-cross" v-if="row.percent === '0.00'"></i>
      <i class="icon-check" v-if="row.percent !== '0.00'"></i>
      {{ setMathRoundPercent }}
    </div>
    <div
      class="table__content"
      v-if="cell.key === 'lack' && !setDateAfterChangeBSM && !setDateSameChangeBSM"
    >
      <div class="table__lack" v-if="row.count_marks < 5">
        <i class="icon-cross" v-if="row.percent === '0.00'"></i>
        недостаток
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/Widgets/User/User";
import formatDateAndTime from "@/helpers/formatDateAndTime";
import moment from "moment";

export default {
  name: "BlackSphereStatisticsCellContent",
  mixins: [formatDateAndTime],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: { User },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    getUsers() {
      return this.$store.getters["UsersModule/userById"](this.row.user_id)[0];
    },
    setMathRoundPercent() {
      const reqExp = new RegExp("^99.[0-9]+");
      if (reqExp.test(this.row.percent)) {
        return "99";
      } else {
        return Math.round(this.row.percent);
      }
    },
    setDateAfterChangeBSM() {
      return moment(this.row.month.split("-").reverse().join("-") + "-01").isAfter(
        moment(new Date("2021-11-01"))
      );
    },
    setDateSameChangeBSM() {
      return (
        moment(this.row.month.split("-").reverse().join("-") + "-01").format("YYYY-MM-DD") ===
        moment(new Date("2021-11-01")).format("YYYY-MM-DD")
      );
    },
    setConnectValue() {
      return this.row.has_black_sphere ? "Да" : "Нет";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__wrap {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;

    &_percent {
      padding: 4px 12px;
      width: max-content;
      height: 24px;
      background: #ebfcf6;
      border-radius: 40px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #027a48;
    }

    &_no-marks {
      background: #f1effa;
      color: #a7a9c0;
    }

    i {
      font-weight: 600;
      margin-right: 4px;
      font-size: 16px;
    }

    /deep/ {
      .user__name {
        max-width: 200px;
        font-weight: 500;
      }
    }
  }

  &__lack {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    width: max-content;
    height: 24px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: #fef0f0;
    color: #a22929;
  }
}
</style>
