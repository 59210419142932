<template>
  <tr class="table__head">
    <th class="table__cell" v-for="(cell, index) in tableHeadList" :key="`cell-${index}`">
      {{ cell.name }}
      <button
        class="table__sort"
        type="button"
        v-if="cell.sort"
        :class="{
          asc: cell.sort
            ? !valueSortList.find((item) => item.value === cell.valueSort).isAsc
            : false,
          active: valueSort.value === cell.valueSort
        }"
        @click="setSort(cell.valueSort)"
      >
        <i class="icon-swap-vert"></i>
      </button>
    </th>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "TableHeader",
  mixins: [],
  props: {
    tableHeadList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      activeSort: null
    };
  },
  mounted() {},
  methods: {
    ...mapActions("IspModule", [
      "getMyIspProblemsListWithFilters",
      "getProblemsList",
      "getMyIspProblemsList"
    ]),
    ...mapMutations("IspModule", ["SET_SORT", "CLEAR_TABLE_DATA", "RESET_PAGINATION"]),
    setSort(val) {
      this.SET_SORT(val);
      this.CLEAR_TABLE_DATA();
      if (this.getRoutes === "IspMy") {
        this.getMyIspProblemsList();
      } else {
        this.getProblemsList();
      }
    }
  },
  computed: {
    ...mapState("IspModule", ["valueSort", "valueSortList", "isMyIspFilters"]),
    getRoutes() {
      return this.$route.name;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  &__head {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 9;
    padding: 8px 24px;
    margin: 0 0 24px;
    background: #fafafe;
    border-radius: 8px;
  }

  &__cell {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $text-primary;
    text-transform: uppercase;
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 8px;
    outline: none;
    transition: all 0.3s ease-in-out;

    i {
      color: $text-primary;
      font-size: 14px;
    }
  }

  .asc {
    transform: rotate(180deg);
  }
}

.active {
  i {
    color: #5551f9;
    font-weight: bold;
  }
}
</style>
