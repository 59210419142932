




import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";

@Component
export default class ReasonCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;

  get reason() {
    return this.row.reason_name ? this.row.reason_name : "";
  }
}
