<template>
  <div class="table__wrap">
    <div class="table__content" v-if="cell.key === 'got_user'">
      <User
        :avatar="gotUser.avatar"
        :name="gotUser.full_name"
        :id="gotUser.id"
        :showTooltip="false"
      />
    </div>
    <div class="table__content" v-if="cell.key === 'put_user'">
      <User
        :avatar="putUser.avatar"
        :name="putUser.full_name"
        :id="putUser.id"
        :showTooltip="false"
      />
    </div>
    <div class="table__content" v-if="cell.key === 'mark'">
      <Mark :mark="row.mark" />
    </div>
    <div class="table__content" v-if="cell.key === 'marked_at'">
      {{ setDateMarked }}
    </div>
    <div class="table__content" v-if="cell.key === 'created_at'">
      {{ setDateCreated }}
    </div>
    <div class="table__content table__content_comment" v-if="cell.key === 'task_link'">
      <a :href="row.comment" target="_blank" v-if="row.comment.match(reqExpLink)">{{
        row.comment
      }}</a>
      <p v-else @click="openBlackSphereCommentModal">
        {{ row.comment }}
      </p>
    </div>
    <div class="table__content" v-if="isAdminBlackSphereMarks && cell.key === 'control'">
      <button
        class="table__button button_delete"
        type="button"
        @click="$emit('askDeleteBlackSphereMarks', row.id)"
      >
        <i class="icon-delete"></i>
      </button>
    </div>
  </div>
</template>

<script>
import formatDateAndTime from "@/helpers/formatDateAndTime";
import User from "@/components/Widgets/User/User";
import { mapActions, mapState } from "vuex";
import Mark from "@/modules/Tables/components/TablesStatistics/BlackSphereMarks/Mark";
import moment from "moment";
import BlackSphere, { BlackSphereMutationTypes } from "@/modules/BlackSphere/store";

export default {
  name: "BlackSphereMarksCellContent",
  mixins: [],
  props: {
    row: {
      type: Object,
      default: () => {
        return null;
      }
    },
    cell: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isAdminBlackSphereMarks: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Mark,
    User
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openBlackSphereCommentModal() {
      BlackSphere[BlackSphereMutationTypes.TOGGLE_MODAL_BLACK_SPHERE_COMMENT](true);
      BlackSphere[BlackSphereMutationTypes.SET_COMMENT_FOR_MODAL_BLACK_SPHERE_COMMENT](
        this.row.comment
      );
    }
  },
  computed: {
    ...mapState("TablesModules", ["isBlackSphereMarksCommentsModal"]),

    reqExpLink() {
      return `${"^(http|https):\\/\\/"}`;
    },
    putUser() {
      return this.$store.getters["UsersModule/userById"](this.row.put_user_id)[0];
    },
    gotUser() {
      return this.$store.getters["UsersModule/userById"](this.row.got_user_id)[0];
    },
    setDateCreated() {
      return moment(this.row.created_at).format("DD.MM.YYYY HH:mm");
    },
    setDateMarked() {
      return moment(this.row.marked_at).format("DD.MM.YYYY");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.table {
  .table__wrap {
    display: flex;
    width: 100%;
  }

  .table__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;

    &_comment {
      margin: 0;
      cursor: pointer;
      word-break: break-word;

      a {
        color: $text-default;

        &:visited {
          color: $text-accent;
        }

        &:hover,
        &:active {
          color: $text-subdued;
        }
      }
    }

    /deep/ {
      .user__name {
        max-width: 200px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;

    &_edit {
      margin-left: auto;
    }

    i {
      font-size: 18px;
      color: $icon-subdued;
    }

    &:hover i,
    &:focus i {
      color: $icon-hovered;
    }

    &:active i {
      color: $icon-pressed;
    }
  }
}
</style>
