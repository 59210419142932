<template>
  <div class="confirm-modal" @mousedown.self="cancel" ref="modal">
    <div class="confirm-modal__window" @click.stop>
      <h2 class="confirm-modal__title">{{ title }}</h2>
      <p class="confirm-modal__description">{{ description }}</p>
      <div class="confirm-modal__slot">
        <slot></slot>
      </div>
      <div class="confirm-modal__footer">
        <Button :type="'cancel'" :title="cancelTitle" @handleButton="cancel" />
        <Button :type="acceptType" :title="acceptTitle" :loader="loader" @handleButton="accept" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "ConfirmModal",
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    acceptTitle: {
      type: String,
      default: "Да"
    },
    cancelTitle: {
      type: String,
      default: "Отменить"
    },
    loader: {
      type: Boolean,
      default: false
    },
    acceptType: {
      type: String,
      default: ""
    }
  },
  components: { Button },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {
    this.$refs.modal.style.opacity = "0";
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    accept() {
      this.$emit("accept");
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 55, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &__window {
    overflow: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 100%;
    max-height: 80vh;
    background: $surface-default;
    border-radius: 16px;
    padding: 32px 24px 40px;
  }

  &__title {
    margin: 0 0 60px 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__description {
    margin: 0 0 60px 0;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $text-primary;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }
  &__accept {
    padding: 8px 32px;
    background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 32px;
    transition: all 0.2s ease-in-out;
    outline: none;
  }
}
</style>
