<template>
  <div class="project-card" @click="openCurtain" ref="projectCard">
    <div class="project-card__container">
      <ConfirmModal
        v-if="confirmDeleteProject"
        :title="'Удалить'"
        :description="'Проект будет удален со всеми этапами, задачами и вложенными проектами. Продолжить?'"
        @cancel="cancelDelete"
        @accept="acceptDelete"
      />
      <ConfirmModal
        v-if="confirmCompleteProject"
        :title="'Выполнить'"
        :description="'Проект может содержать невыполненные этапы. Данные этапы будут закрыты. Продолжить?'"
        @cancel="cancelComplete"
        @accept="acceptComplete"
      />
      <div class="project-card__wrap">
        <div class="project-card__header">
          <div class="project-card__members">
            <i class="icon-group"></i>
            <p class="project-card__count">{{ countMembers }}</p>
            <p class="project-card__tooltip">Участники</p>
          </div>
          <ActionsList
            :actionList="actionList"
            class="project-card__action-list"
            @handleAction="handleAction"
          />
        </div>
        <div class="project-card__body">
          <p class="project-card__title">
            {{ project.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsList from "@/modules/UiKit/components/ActionList/ActionList";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import copyToClipboard from "@/helpers/CopyToClipboard";
import NotificationObserver from "@/helpers/UserNotification";
export default {
  name: "ProjectCard",
  mixins: [],
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  },
  components: { ConfirmModal, ActionsList },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "arrow-next",
          actionText: "Открыть проект",
          actionType: `openProject`
        },
        {
          isShow: !this.project.is_completed,
          iconType: "circle-plus",
          actionText: "Добавить задачу",
          actionType: `addTask`
        },
        {
          isShow: this.project.is_owner && !this.project.is_completed,
          iconType: "folder-check",
          actionText: "Выполнить",
          actionType: `complete`
        },
        {
          isShow: this.project.is_owner && this.project.is_completed,
          iconType: "reopen",
          actionText: "Вернуть в работу",
          actionType: `reopen`
        },
        {
          isShow: this.project.is_owner && !this.project.is_completed,
          iconType: "clipboard-ind",
          actionText: "Настроить бизнес-процесс",
          actionType: `business-setting`
        },
        {
          isShow: this.project.is_owner && !this.project.is_completed,
          iconType: "clipboard-secondary",
          actionText: "Настроить доску Канбан",
          actionType: `kanban-setting`
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          actionType: `copyLink`
        },
        {
          isShow: this.project.is_owner,
          iconType: "delete",
          actionText: "Удалить",
          actionType: `delete`
        }
      ],
      confirmDeleteProject: false,
      confirmCompleteProject: false
    };
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.projectCard);
  },
  mounted() {
    if (this.isLastItem) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            ProjectModule.getProjectByPagination();
            this.observer.unobserve(this.$refs.projectCard);
          }
        });
      });
      this.observer.observe(this.$refs.projectCard);
    }
  },
  methods: {
    openCurtain(e) {
      const path = e.path || e.composedPath();
      if (path.some((item) => item.className === "action-list__modal")) return;
      ProjectModule.openCurtainShowAndEditProject();
      ProjectModule.getProjectFullAction(this.project.id);
      StageModule.getStageByProjectId({ project_id: this.project.id });
    },
    handleAction(item) {
      switch (item.actionType) {
        case "openProject":
          this.$router.push({ name: "Project", params: { id: this.project.id } });
          break;
        case "addTask":
          TaskManagerModuleV2.openCurtainConstructor({
            currentCreationTypeId: 3,
            currentProjectForCreation: this.project
          });
          break;
        case "complete":
          this.confirmCompleteProject = true;
          break;
        case "reopen":
          this.reopenProject();
          break;
        case "business-setting":
          this.toFlowchart();
          break;
        case "kanban-setting":
          this.openKanbanSetting();
          break;
        case "copyLink":
          this.copyProjectLink();
          break;
        case "delete":
          this.confirmDeleteProject = true;
      }
    },
    cancelDelete() {
      this.confirmDeleteProject = false;
    },
    cancelComplete() {
      this.confirmCompleteProject = false;
    },
    acceptDelete() {
      ProjectModule.deleteProjectAction(this.project.id);
      this.confirmDeleteProject = false;
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно удален!"
      });
    },
    acceptComplete() {
      const updatedProject = {
        id: this.project.id,
        is_completed: true
      };
      ProjectModule.updateProjectAction(updatedProject);
      this.confirmCompleteProject = false;
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно закрыт!"
      });
    },
    openKanbanSetting() {
      this.$router.push({ name: "KanbanSetting", params: { id: this.project.id } });
    },
    reopenProject() {
      const updatedProject = {
        id: this.project.id,
        is_completed: false
      };
      ProjectModule.updateProjectAction(updatedProject);
      NotificationObserver.notification({
        type: "success",
        message: "Проект успешно возвращен в работу!"
      });
    },
    copyProjectLink() {
      let currentURL = window.location.href.split("/");
      currentURL.pop();
      currentURL.push("Project");
      currentURL.push(this.project.id);
      const projectLink = currentURL.join("/");
      copyToClipboard(projectLink);
    },
    toFlowchart() {
      this.$router.push({
        name: "Flowchart",
        params: { flow_id: this.project.flow_id, project_id: this.project.id }
      });
    }
  },
  computed: {
    countMembers() {
      return this.project.count_member;
    },
    randomAvatar() {
      return require(`@/modules/TaskManager/assets/img/avatarDefault${
        Math.floor(Math.random() * 6) + 1
      }.svg`);
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/mixins/TextEllipsis.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.project-card {
  max-height: 300px;
  width: 100%;

  /deep/ {
    .action-list {
      &__wrapper {
        @media screen and (max-width: 850px) and (max-height: 850px) {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 100;
          background: rgba(33, 32, 44, 0.8);
        }
      }
      &__modal-list {
        min-width: 255px;
        @media (max-width: $xs) {
          min-width: 200px;
        }
        @media screen and (max-width: 850px) and (max-height: 850px) {
          position: fixed;
          max-height: 70vh;
          width: 100%;
          max-width: unset;
          top: unset;
          right: 0;
          bottom: 0;
          border-radius: 16px 16px 0 0;
          overflow-y: auto;
          z-index: 100;
        }
      }
    }
  }

  &__container {
    position: relative;
    padding: 15px 0 0;
    height: 210px;
    transition: 0.5s all ease;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 70%;
      height: 140px;
      border-radius: 16px;
      background: #e5effd;
      transition: 0.5s all ease;
    }

    &:hover::before {
      background: #5551f9;
    }

    &:hover .project-card__wrap {
      border: 1px solid #5551f9;
    }
  }

  &__wrap {
    width: 100%;
    height: 100%;
    padding: 32px 24px;
    border-radius: 16px;
    border: 1px solid #e5effd;
    cursor: pointer;
    position: relative;
    background: #fff;
    transition: 0.5s all ease;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
  }

  &__members {
    position: relative;
    display: flex;
    align-items: center;

    &:hover .project-card__tooltip {
      opacity: 1;
    }

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 8px;
    }
  }

  &__count {
    font-size: 12px;
    font-weight: 500;
  }

  &__tooltip {
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: 100%;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: $surface-default;
    background: $text-default;
    border-radius: 2px;
  }

  &__title {
    @include textEllipsis(4);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }
}
</style>
