<template>
  <li class="context-menu-item" v-if="item.isShow" @click.stop="handleActionItem" @contextmenu.stop>
    <i :class="`icon-${item.iconType}`"></i>
    {{ item.actionText }}
  </li>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ContextMenuItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => ({
        isShow: true,
        iconType: "close",
        actionText: "Закрыть",
        actionType: "close"
      })
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions(["closeContextMenuAction"]),
    handleActionItem() {
      this.item.action();
      this.closeContextMenuAction();
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
.context-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  color: $text-default;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 8px;
  background: transparent;
  border: none;
  outline: none;
  i {
    margin-right: 8px;
    color: $icon-subdued;
    font-size: 18px;
  }
  &:hover {
    background: #f2f7fe;
    border-radius: 4px;
  }
}
</style>
