<template>
  <div class="stage-description">
    <div class="stage-description__title">
      <EditField
        classSize="h2"
        keyUser="name"
        :maxLength="255"
        :user="currentStage"
        :isReadonly="currentStage.is_completed || project.is_completed"
        @editDone="handleEditTitle"
        placeholder="Введите название этапа"
      />
    </div>
    <div class="stage-description__text">
      <h4 class="stage-description__label">Описание</h4>
      <EditField
        classSize="text"
        keyUser="description"
        :maxLength="32000"
        :user="currentStage"
        :isReadonly="currentStage.is_completed || project.is_completed"
        @editDone="handleEditDescription"
        placeholder="Добавьте описание этапа"
        class="project-description__text"
      />
    </div>
    <div class="stage-description__deadlines">
      <Label
        label="Сроки выполнения"
        tooltip="Период считается автоматически в зависимости от даты начала/окончания задач в этапе "
      />
      <p class="stage-description__dates">
        <span v-if="dateForm">{{ dateForm }}</span> <span v-if="dateTo">{{ dateTo }}</span>
        <span v-else>Не заданы</span>
      </p>
    </div>
    <div class="stage-description__project">
      <h4 class="stage-description__label">Проект</h4>
      <div class="stage-description__link" @click="closeStage">
        <router-link :to="{ name: 'Project', params: { id: currentStage.project_id } }">
          {{ currentStage.project_name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EditField from "@/modules/Isp/components/EditField/EditField";
import Label from "@/modules/Isp/components/IspProblem/Label";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule.ts";
import NotificationObserver from "@/helpers/UserNotification";
import moment from "moment";

export default {
  name: "StageDescriptionBody",
  mixins: [],
  props: {
    currentStage: {
      type: Object,
      default: () => {}
    },
    project: {
      type: Object,
      default: () => {}
    }
  },
  components: { Label, EditField },
  data() {
    return {
      title: {
        name: "Название этапа длинное или короткое не важно тут места достаточно"
      },
      range: {
        start: new Date(),
        end: new Date()
      }
    };
  },
  mounted() {},
  methods: {
    closeStage() {
      StageModule.toggleShowEditModal(false);
    },
    handleEditTitle(name) {
      StageModule.editCurrentStage({
        id: this.currentStage.id,
        name,
        description:
          this.currentStage.description === null ? undefined : this.currentStage.description
      });
      NotificationObserver.notification({
        type: "success",
        message: "Этап успешно изменен!"
      });
    },
    handleEditDescription(description) {
      StageModule.editCurrentStage({
        id: this.currentStage.id,
        description,
        name: this.currentStage.name
      });
      NotificationObserver.notification({
        type: "success",
        message: "Этап успешно изменен!"
      });
    }
  },
  computed: {
    dateForm() {
      if (this.currentStage.date_from) {
        return `Дата начала: ${moment(this.currentStage.date_from).format("DD.MM.YYYY")}`;
      } else {
        return null;
      }
    },
    dateTo() {
      if (this.currentStage.date_to) {
        return `Дата окончания: ${moment(this.currentStage.date_to).format("DD.MM.YYYY")}`;
      } else {
        return null;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.stage-description {
  &__title,
  &__text {
    padding: 24px 16px 24px 16px;
    border-bottom: 1px solid #eae8f0;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    color: $text-default;

    @media (max-width: $sm) {
      padding: 16px;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .textarea__control {
        font-size: 22px;
        line-height: 28px;

        @media (max-width: $sm) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;

    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }

    /deep/ {
      .textarea__control {
        font-size: 16px;
        line-height: 26px;

        @media (max-width: $sm) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 16px 0;

    @media (max-width: $sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__deadlines {
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }

    /deep/ {
      label {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
      }

      .label__tooltip {
        @media (max-width: $sm) {
          max-width: 220px;
        }

        @media (max-width: $xs) {
          max-width: 160px;
        }
      }
    }
  }

  &__dates {
    font-size: 12px;
    font-weight: 400;

    @media (max-width: $sm) {
      display: flex;
      flex-direction: column;
    }

    span {
      @media (max-width: $sm) {
        margin: 0 0 16px;
      }
    }
  }

  &__project {
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }
  }

  &__link {
    font-size: 16px;
    font-weight: 400;
    color: $link-default;
    cursor: pointer;
    word-break: break-word;
    @media (max-width: $sm) {
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      color: $link-hovered;
    }

    &:active {
      color: $link-pressed;
    }
  }
}
</style>
