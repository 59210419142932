<template>
  <li class="kanban-stage" @click="handleClick" :class="{ 'kanban-stage_is-chosen': isChosen }">
    <div class="kanban-stage__wrap">
      <div class="kanban-stage__header">
        <p class="kanban-stage__title">{{ stage.name }}</p>
        <i class="icon-up" v-if="isChosen"></i>
        <i class="icon-down" v-else></i>
        <div class="kanban-stage__add-task" v-if="showAddButton">
          <Button type="simple" title="" icon="plus" @handleButton="addTask" />
          <p class="kanban-stage__tooltip">Добавить задачу</p>
        </div>
      </div>
      <div class="kanban-stage__info">
        <span class="kanban-stage__date">
          <p v-if="dateFrom">Дата начала: {{ dateFrom }}</p>
          <p v-if="dateTo">Дата окончания: {{ dateTo }}</p>
        </span>
        <div class="kanban-stage__filters" v-if="isChosen">
          <div @click.stop>
            <ChosenFilter
              v-model="responsible"
              :icon="'user'"
              :placeholder="'Исполнитель'"
              :chosenList="responsibleList"
              :allItem="true"
              :search="true"
              class="kanban-stage__responsible"
              @input="chooseExecutor"
            />
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ChosenFilter from "@/modules/UiKit/components/ChosenFilter/ChosenFilter";
import moment from "moment";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import Button from "@/modules/UiKit/components/buttons/Button";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
export default {
  name: "KanbanStage",
  mixins: [],
  props: {
    stage: {
      type: Object,
      default: () => {}
    },
    project: {
      type: Object,
      default: () => {}
    },
    isChosen: {
      type: Boolean,
      default: false
    }
  },
  components: { ChosenFilter, Button },
  data() {
    return {
      taskType: [],
      responsible: []
    };
  },
  mounted() {},
  methods: {
    handleClick() {
      if (this.isChosen) {
        this.$emit("rollUp");
      } else {
        this.$emit("chooseStage", this.stage);
      }
    },
    chooseExecutor(val) {
      this.$emit("chooseExecutor", val[0]);
    },
    addTask() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 3,
        currentProjectForCreation: this.project,
        currentStageForCreation: this.stage,
        currentTaskForCreation: null
      });
    }
  },
  computed: {
    dateFrom() {
      return this.stage.date_from ? moment(this.stage.date_from).format("DD.MM.YYYY") : "";
    },
    dateTo() {
      return this.stage.date_to ? moment(this.stage.date_to).format("DD.MM.YYYY") : "";
    },
    responsibleList() {
      const list = this.project.owners
        .map((item) => item.user_info || item)
        .concat(this.project.members.map((item) => item.user_info || item))
        .sort((a, b) => {
          return a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1;
        });
      list.unshift({ id: null, name: "Без исполнителя" });
      return list;
    },
    showAddButton() {
      return !this.project.is_completed && !this.stage.is_completed && this.isChosen;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.kanban-stage {
  list-style: none;
  border-bottom: 1px solid $secondary;
  cursor: pointer;
  &__wrap {
    padding: 24px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  i {
    font-size: 18px;
    color: $icon-subdued;
    margin-bottom: 4px;
  }
  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 23px;
    color: $text-default;
    word-break: break-word;
    margin-right: 12px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //height: 30px;
  }
  &__date {
    font-size: 12px;
    font-weight: 400;
    color: $text-default;
    display: flex;
    gap: 8px;
  }
  &__filters {
    display: flex;
    /deep/ {
      .chosen-filter__list {
        left: unset;
        right: 0;
      }
    }
  }
  &__responsible {
    /deep/ {
      button {
        margin: 0;
      }
    }
  }
  &__add-task {
    position: relative;
    margin-left: auto;
    &:hover .kanban-stage__tooltip {
      opacity: 1;
      transform: scale(1);
    }

    /deep/ {
      .aop-button {
        padding: 8px;
      }
    }
  }
  &__tooltip {
    transition: opacity 0.3s ease;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    top: -10px;
    right: 100%;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 2px;
  }
  &_is-chosen {
    .kanban-stage__header {
      margin-bottom: 0;
    }
  }
}
</style>
