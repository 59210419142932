











import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import ProjectTag from "@/modules/TimeTracker/component/Tag/ProjectTag.vue";
import TimeTracker from "@/modules/TimeTracker/store";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";

@Component({
  components: { ProjectTag }
})
export default class ProjectNameCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;
  @Prop({
    default: () => {},
    type: Object
  })
  data!: any;

  get project() {
    return this.isAdmin
      ? TimeTracker.projectInAll(this.row.project_id)
      : TimeTracker.project(this.row.project_id);
  }

  get isAdmin() {
    return this.$store.getters.getDataInfoUser.roles.includes("timemanager_admin");
  }

  get tag() {
    if (this.project) {
      switch (this.project!.tracker_id) {
        case null: {
          return "AOP-TIME";
        }
        case 1: {
          return "Jira";
        }
        case 2: {
          return "Jira";
        }
        case 3: {
          return "AOP-TASK";
        }
        default: {
          return "AOP";
        }
      }
    } else {
      return "AOP";
    }
  }
}
