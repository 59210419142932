


































import { Component, Prop, Vue } from "vue-property-decorator";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import NotificationObserver from "@/helpers/UserNotification";
import { EventBus } from "@/main";
import Comments from "@/modules/UiKit/components/Comments/Comments";
import PlanningScheduleModule, {
  PlanningScheduleMutationTypes
} from "@/modules/TaskManager/Model/PlanningSchedule/PlanningScheduleModule";

@Component({ components: { Spinner, Comments } })
export default class PlanComments extends Vue {
  @Prop({
    type: Number,
    default: null
  })
  planId!: number | null;
  loader: boolean = false;

  beforeDestroy() {
    this.resetComments();
  }

  mounted() {
    this.loader = true;
    this.getComments();
  }

  hideModal() {
    this.$emit("hideModal");
  }

  getComments() {
    if (this.planId)
      PlanningScheduleModule.getPlanCommentsAction(this.planId).then(() => {
        EventBus.$emit("offLoader");
        this.loader = false;
      });
  }

  getAnswer(body) {
    this.resetAnswer(body.id);
    if (this.planId)
      PlanningScheduleModule.getAnswerPlanCommentsAction({
        id: this.planId,
        parent_id: body.id
      }).then(() => {
        this.loader = false;
        EventBus.$emit("offLoader");
      });
  }

  resetAnswer(id) {
    PlanningScheduleModule[PlanningScheduleMutationTypes.RESET_CURRENT_CHILDREN_COMMENT](id);
  }

  createAction(val) {
    if (!this.checkValidationData(val)) return null;
    PlanningScheduleModule.createPlanCommentAction({
      id: this.planId,
      text: val.text,
      parent_id: this.currentComment ? this.currentComment.id : null,
      author: this.getUserId
    }).then(() => EventBus.$emit("offLoader"));
  }

  updateAction(val) {
    if (!this.checkValidationData(val)) return null;
    if (this.currentComment && this.planId) {
      PlanningScheduleModule.updatePlanCommentAction({
        id: this.planId,
        text: val.text,
        comment_id: this.currentComment.id,
        parent_id: this.currentComment ? this.currentComment.parent_id : null,
        count_comments: this.currentComment ? this.currentComment.count_comments : null
      }).then(() => EventBus.$emit("offLoader"));
    }
  }

  deleteAction(body) {
    if (this.currentComment && this.planId) {
      PlanningScheduleModule.deletePlanCommentAction({
        id: this.planId,
        comment_id: body.id,
        parent_id: body.parent
      });
    }
  }

  checkValidationData(val) {
    if (!val.text || !val.text.trim().length) {
      NotificationObserver.notification({
        type: "error",
        message: "Введите текст комментария!"
      });
      PlanningScheduleModule[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](false);
      return false;
    }
    if (val.text && val.text.trim().length > 1024) {
      NotificationObserver.notification({
        type: "error",
        message: "Длинна комментария должна быть не более 1024 символов!"
      });
      PlanningScheduleModule[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](false);
      return false;
    }
    return true;
  }

  setCurrentComment(val) {
    PlanningScheduleModule[PlanningScheduleMutationTypes.SET_CURRENT_COMMENT](val);
  }

  resetComments() {
    PlanningScheduleModule[PlanningScheduleMutationTypes.SET_CURRENT_COMMENT](null);
    PlanningScheduleModule[PlanningScheduleMutationTypes.RESET_PLAN_COMMENTS]();
    PlanningScheduleModule[PlanningScheduleMutationTypes.SET_COMMENT_COUNT](0);
    PlanningScheduleModule[PlanningScheduleMutationTypes.SET_COMMENT_PAGE](1);
  }

  toggleFormLoader(val) {
    PlanningScheduleModule[PlanningScheduleMutationTypes.TOGGLE_FORM_LOADER](val);
  }

  get getUserId() {
    return this.$store.getters.getUserId;
  }

  get commentsList() {
    return PlanningScheduleModule.comments;
  }

  get commentsPage() {
    return PlanningScheduleModule.commentPage;
  }

  get commentsCount() {
    return PlanningScheduleModule.commentCount;
  }

  get currentComment() {
    return PlanningScheduleModule.currentComment;
  }

  get childList() {
    return PlanningScheduleModule.childCommentsList;
  }

  get formLoader() {
    return PlanningScheduleModule.commentLoader;
  }
}
