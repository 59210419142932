import { Vue, Component } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import { isString } from "@/helpers/helperFunction";

@Component
export default class FSEventMixin extends Vue {
  handleFullScreenEvent(query = "none") {
    CalendarModule2.setActiveTab("Person");
    if (isString(query) && query !== "none") {
      this.$router.push({ name: "CreateEvent", query: { repeat: "true" } }).finally();
    } else {
      this.$router.push({ name: "CreateEvent" }).finally();
    }
  }
}
