













import { Component, Prop, Vue } from "vue-property-decorator";
import { domainStorage } from "@/globalVariables";

@Component
export default class UserItem extends Vue {
  @Prop({
    default: 0,
    type: Number
  })
  userId!: number;
  @Prop({
    default: false,
    type: Boolean
  })
  isDelete!: boolean;

  handleDeleteUser() {
    this.$emit("handleDeleteUser", this.userId);
  }

  get user() {
    return this.$store.getters["UsersModule/userById"](this.userId)[0];
  }

  get src() {
    return domainStorage + this.user.avatar;
  }

  get role() {
    return this.user.roles.length ? `[${this.user.roles[0].name}]` : "";
  }
}
