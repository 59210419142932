<template>
  <i :class="`icon-${name}`" :style="`fontSize: ${fontSize}px`">
    <i
      v-for="index in Number(pathCount)"
      :class="`path${index}`"
      :key="index"
      :style="`fontSize: ${fontSize}px`"
    ></i>
  </i>
</template>

<script>
export default {
  name: "IcoMoonWrap",
  mixins: [],
  props: {
    name: {
      type: String,
      require: true,
      default: () => null
    },
    pathCount: {
      type: [String, Number],
      require: true,
      default: () => 1
    },
    fontSize: {
      type: [String, Number],
      default: () => 14
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
</style>
