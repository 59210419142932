<template>
  <section class="stages-and-tasks">
    <StagesAndTasksHeader :projectId="projectId" />
    <div class="stages-and-tasks__body">
      <ProjectStagesTable :tableHead="tableHead" :stages="stages" :projectId="projectId" />
    </div>
  </section>
</template>

<script>
import ProjectStagesTable from "@/modules/TaskManager/components/MyProjects/ProjectPage/ProjectStagesTable/ProjectStagesTable";
import { mapMutations } from "vuex";
import StagesAndTasksHeader from "@/modules/TaskManager/components/MyProjects/ProjectPage/StagesAndTasksHeader";
import StageModule, { StageMutationTypes } from "@/modules/TaskManager/Model/Stage/StageModule";

export default {
  name: "StagesAndTasks",
  mixins: [],
  props: {
    stages: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: "0"
    }
  },
  components: { StagesAndTasksHeader, ProjectStagesTable },
  data() {
    return {
      tableHead: [
        {
          name: "Название",
          sort: false,
          key: "name"
        },
        { name: "Приоритет", sort: true, key: "priority", valueSort: "priority_id", isAsc: true },
        { name: "Вид", sort: true, key: "type", valueSort: "type_id", isAsc: true },
        { name: "Сроки", sort: true, key: "deadline", valueSort: "date_to", isAsc: true },
        { name: "Автор", sort: true, key: "author", valueSort: "author_id", isAsc: true },
        {
          name: "Исполнитель",
          sort: true,
          key: "responsible",
          valueSort: "executor_id",
          isAsc: true
        },
        { name: "Статус", sort: true, key: "status", valueSort: "status_id", isAsc: true }
      ]
    };
  },
  beforeDestroy() {
    StageModule[StageMutationTypes.SET_CURRENT_STAGE]({});
  },
  mounted() {},
  methods: {
    ...mapMutations("TaskManagerModule", ["SET_CURRENT_STAGE"])
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

h2 {
  margin: 0;
  padding: 0;
}

.stages-and-tasks {
  padding: 24px 24px 0 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $xs) {
    height: calc(100% - 40px);
  }

  &__body {
    overflow-y: auto;
    height: 100%;
  }
  /deep/ {
    .action-list {
      &__wrapper {
        @media (max-width: $sm) {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 100;
          background: rgba(33, 32, 44, 0.8);
        }
      }
      &__modal-list {
        @media (max-width: $xs) {
          min-width: 200px;
        }
        @media (max-width: $sm) {
          position: fixed;
          width: 100%;
          max-width: unset;
          top: unset;
          right: 0;
          bottom: 0;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          border-radius: 16px 16px 0 0;
          z-index: 100;
        }
      }
    }
    .table-stage {
      &__action {
        z-index: auto;
      }
    }
  }
}
</style>
