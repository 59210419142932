<template>
  <div class="color-picker">
    <ul class="color-picker__list">
      <li
        class="color-picker__item"
        v-for="(color, index) in colorsList"
        :key="index"
        :style="{ background: color.color }"
        @click="$emit('setChannelColor', color.color)"
      ></li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "ColorPicker",
  mixins: [],
  props: [],
  components: {},
  data() {
    return {
      colorsList: [
        {
          color: "#ECECF9"
        },
        {
          color: "#3BE8B0"
        },
        {
          color: "#D0BBFE"
        },
        {
          color: "#FFE880"
        },
        {
          color: "#FFB6C9"
        },
        {
          color: "#FB646D"
        },
        {
          color: "#80E6FF"
        },
        {
          color: "#6A67CE"
        },
        {
          color: "#FFB901"
        },
        {
          color: "#02CEFF"
        },
        {
          color: "#37C597"
        },
        {
          color: "#A7A9C0"
        }
      ]
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background-color: rgba(#d3d2e0, 0.5);
  border-radius: 4px;
  margin: 5px;
}

::-webkit-scrollbar-thumb {
  background: #d3d2e0;
  border-radius: 4px;
}
.color-picker {
  width: 100%;
  height: 85px;
  border-radius: 24px;
  background: #fafbfc;
  padding: 24px 36px 12px;

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: space-between;

    @media (max-width: $md) {
      padding-right: 14px;
      margin-right: 32px;
    }

    @media screen and (max-width: 568px) and (max-height: 350px) {
      justify-content: flex-start;
    }
  }

  &__item {
    display: block;
    width: 30px;
    height: 30px;
    padding: 14px;
    border-radius: 50%;
    border: 2px solid $surface-default;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 32px;

      @media (max-width: 374px) {
        margin-right: 24px;
      }
    }

    &:nth-child(4n) {
      @media (max-width: $sm) {
        margin-right: 0;
      }

      @media screen and (max-width: 568px) and (max-height: 350px) {
        margin-right: 24px;
      }
    }
  }
}
</style>
