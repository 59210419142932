<template>
  <div
    class="users__widgets-mobile"
    :class="{ 'users__widgets-mobile_admin': isAdmin || isAdminReportUsers }"
  >
    <Widget
      type="select-new"
      :entity="'User'"
      :actionEntity="'getItems'"
      :itemName="'full_name'"
      :keyValue="'FilterUserListInUsersTable'"
      :label="'Сотрудник'"
      :placeholder="'Все'"
      :multiple="true"
      v-show="valueFilter === 'Сотрудник'"
      :editValue="filterUser"
      :filterActive="!filterActive"
    />
    <Widget
      type="select-new"
      :entity="'Department'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterDepartmentListInUsersTable'"
      :label="'Отдел'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterDepartment"
      v-show="valueFilter === 'Отдел'"
    />
    <Widget
      type="select-new"
      :entity="'Role'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterRoleListInUsersTable'"
      :label="'Должность'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterRole"
      v-show="valueFilter === 'Должность'"
    />
    <Widget
      type="select-new"
      :entity="'Level'"
      :actionEntity="'getItems'"
      :itemName="'name'"
      :keyValue="'FilterLevelListInUsersTable'"
      :label="'Грейд'"
      :placeholder="'Все'"
      :multiple="true"
      :editValue="filterLevel"
      v-show="valueFilter === 'Грейд'"
    />
    <div class="users__actions actions" v-click-outside="hideModalList">
      <button
        class="actions__toggle"
        type="button"
        @click="openList"
        :class="{ actions__toggle_active: showFilterList }"
      >
        <i class="icon-dots-vertical"></i>
      </button>
      <div class="actions__settings" v-show="showFilterList">
        <FiltersList
          :filtersList="filtersList"
          :valueFilter="valueFilter"
          @choseFilter="choseFilter"
        />
        <div class="actions__switches" v-if="isAdmin || isAdminReportUsers">
          <Widget
            class="table__switch"
            type="Switch"
            :unique="'FilterActiveListInUsersTable'"
            :label="'Уволен'"
            :editValue="filterActive"
          />
        </div>
        <div class="actions__buttons" v-if="isAdmin || isAdminReportUsers">
          <ModalButton
            :icon="'plus'"
            :title="'Сотрудник'"
            :type="'filter-list'"
            @handleButton="openModalCreateUser"
            class="users__add"
            v-if="isRightsAdmin"
          />
          <ModalButton
            :title="'Выгрузить'"
            :icon="'download'"
            :type="'filter-list'"
            :loader="loaderReport"
            @handleButton="uploadReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import Widget from "@/components/Widgets/Widget";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import FiltersList from "@/modules/Tables/components/TablesStatistics/Time/TimeWidgetsMobile/Filters/FiltersList";
import * as types from "@/modules/Tables/store/mutations/mutationsTypes";

export default {
  name: "UserWidgetsModal",
  mixins: [],
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isAdminReportUsers: {
      type: Boolean,
      default: false
    },
    filterUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterRole: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterDepartment: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterLevel: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filterActive: {
      type: Boolean,
      default: false
    }
  },
  components: { Widget, ModalButton, FiltersList },
  data() {
    return {
      showFilterList: false,
      filtersList: ["Сотрудник", "Отдел", "Должность", "Грейд"],
      valueFilter: "Сотрудник"
    };
  },
  mounted() {},
  methods: {
    ...mapActions("TablesModules", ["uploadUsersReport"]),
    ...mapMutations("TablesModules", [types.TOGGLE_SHOW_MODAL_CREATE_USER]),
    // Выгрузка отчета
    uploadReport() {
      this.uploadUsersReport(this.filterActive);
    },
    openList() {
      this.showFilterList = !this.showFilterList;
    },
    hideModalList() {
      this.showFilterList = false;
    },
    choseFilter(item) {
      this.valueFilter = item;
    },
    updateAbsence() {
      this.$emit("updateAbsence");
    },
    openModalCreateUser() {
      this[types.TOGGLE_SHOW_MODAL_CREATE_USER](true);
    }
  },
  computed: {
    ...mapState("TablesModules", ["loaderReport"]),
    isRightsAdmin() {
      return this.$store.getters.getDataInfoUser.roles.includes("rights_admin");
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.users {
  &__widgets-mobile {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $border-subdued;
    border-bottom: 1px solid $border-subdued;

    @media (max-height: $sm) {
      padding: 8px 24px;
    }

    /deep/ {
      .select-bg {
        width: 300px;
        margin-right: 8px;

        @media (max-width: $sm) {
          margin-right: 0;
          width: 250px;
        }

        .select-content-bg {
          height: 28px;

          .select-content {
            background: #fafafe;
            border-radius: 4px;
            padding: 5px 8px;

            .select-content-value-bg {
              width: calc(100% - 140px);
            }
          }
        }
      }

      .modal-items {
        max-height: 300px;

        @media (max-height: $xs) {
          max-height: 200px;
        }

        @media (max-height: $xxs) {
          max-height: 150px;
        }
      }

      .switch-widget__label {
        font-size: 14px;
        line-height: 20px;
        color: $text-subdued;
      }

      .month-picker-background {
        width: 200px;
        .month-picker-wrap {
          padding: 0 4px 0 0;

          i {
            color: #d3d2e0;
            font-size: 18px;
          }
        }

        #month-picker .month_picker_container {
          @media (max-height: $sm) {
            padding: 16px;
          }
        }
      }
    }
  }

  .actions {
    position: relative;

    &__toggle {
      width: 32px;
      height: 32px;
      background: $action-secondary;
      border-radius: 4px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: $icon-subdued;
        font-size: 24px;
        text-align: center;
      }
      &:hover {
        background: $action-secondary-hovered;

        i {
          color: $icon-subdued;
        }
      }

      &:active,
      &:focus {
        background: $action-secondary-pressed;
      }
    }

    &__settings {
      position: absolute;
      z-index: 15;
      top: 34px;
      right: 0;
      padding: 8px 0;
      list-style: none;
      min-width: 180px;
      width: max-content;
      background-color: $surface-default;
      cursor: pointer;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 14px;

      @media (max-height: 812px) {
        top: 0;
        right: 34px;
      }

      @media (max-height: $xs) {
        top: -60px;
        right: 34px;
      }
    }

    &__switches {
      padding: 8px 16px;
      border-top: 1px solid $border-disabled;

      /deep/ {
        .switch-widget__label {
          margin: 0;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $text-default;
        }

        .switch-widget__indicator {
          width: 18px;
          height: 10px;
          background-color: $icon-disabled;
          margin-right: 8px;

          &::before {
            top: 2px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }

        .switch-widget__input:checked + .switch-widget__indicator {
          background-color: $action-primary;

          &:before {
            top: 2px;
            left: 10px;
            width: 6px;
            height: 6px;
            background-color: $action-secondary;
          }
        }
      }
    }

    &__buttons {
      padding: 8px;
      border-top: 1px solid $border-disabled;
    }
  }
  &__add {
    padding: 6px 8px;
    margin-left: auto;
    width: 100%;
    justify-content: start;
    /deep/i {
      margin-right: 6px;
    }
  }
}
</style>
