













import { Component, Prop, Vue } from "vue-property-decorator";
import ChipsItem from "@/aopV2/components/UIComponents/Chips/ChipsItem.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({ components: { ChipsItem } })
export default class CalendarTypes extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  currentTypes!: Array<any>;
  @Prop({
    type: Boolean,
    default: false
  })
  isEventsCalendar!: boolean;
  activeType: any = null;

  setActiveType(name) {
    this.activeType = this.types.length ? this.types.find((item) => item.name === name) : null;
    if (this.currentTypes.length && this.currentTypes.find((item) => item.name === name)) {
      this.$emit(
        "handleChangeTypes",
        this.currentTypes.filter((item) => item.name !== name)
      );
    } else {
      this.$emit("handleChangeTypes", this.currentTypes.concat(this.activeType));
    }
  }

  get eventsParams() {
    return CalendarModule2.eventsParams;
  }

  get types() {
    return this.isEventsCalendar && this.activityCategories
      ? CalendarModule2.eventType.filter((item) => item.category_id === this.activityCategories.id)
      : CalendarModule2.eventType;
  }

  get activityCategories() {
    return CalendarModule2.eventTypeCategories.length
      ? CalendarModule2.eventTypeCategories.find((item) => item.name === "Активность")
      : null;
  }
}
