<template>
  <div class="tabs">
    <ul class="tabs__list">
      <li
        class="tabs__item"
        v-for="tab in tabs"
        :key="`tab_${tab.index}`"
        :class="{ active: tab.index === setActiveTab }"
      >
        <router-link
          class="tabs__link"
          :to="{ name: tab.path }"
          :class="{ active: tab.index === setActiveTab }"
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsList",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    setActiveTab() {
      return this.tabs.filter((item) => item.path === this.$route.name)[0].index;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs {
  font-family: "Roboto", "Arial", sans-serif;
  position: relative;

  &__list {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    @media (max-width: $lg) {
      overflow-x: scroll;
    }
  }

  &__item {
    position: relative;
    transition: all 0.5s ease;
    display: block;
    padding: 0;
    margin: 0 20px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: $text-disabled;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $text-primary;
    }

    @media (max-width: $sm) {
      font-size: 14px;
    }
  }

  &__link {
    display: block;
    padding: 0 0 8px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: $text-disabled;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $text-primary;
    }
  }

  .active {
    color: $text-primary;
    transition: all 0.2s ease;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $action-primary-accent;
      border-radius: 4px;
      animation-name: opacity;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
    }
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
