








import { Component, Prop, Vue } from "vue-property-decorator";
import { ICell } from "@/modules/TimeTracker/WorkTime.vue";
import { ITimeRow } from "@/modules/TimeTracker/interface/interface";

@Component
export default class WorkTimeCell extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  row!: ITimeRow;
  @Prop({
    default: () => {},
    type: Object
  })
  cell!: ICell;

  components = {
    project_name: () => import("@/modules/TimeTracker/component/WorkTImeCell/ProjectNameCell.vue"),
    duration: () => import("@/modules/TimeTracker/component/WorkTImeCell/DurationCell.vue"),
    time: () => import("@/modules/TimeTracker/component/WorkTImeCell/TimeCell.vue"),
    reason: () => import("@/modules/TimeTracker/component/WorkTImeCell/ReasonCell.vue"),
    action: () => import("@/modules/TimeTracker/component/WorkTImeCell/ActionCell.vue"),
    default: () => import("@/modules/TimeTracker/component/WorkTImeCell/ProjectNameCell.vue")
  };

  get component() {
    return this.components[this.cell.key] || this.components.default;
  }
}
