import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import TaskService from "@/modules/TaskManager/Model/Task/TaskService";
import { setUniqueArray } from "@/helpers/setUniqueArray";
import _ from "lodash";

import TaskModel, {
  IChekItem,
  IHistoryTaskItem,
  IParams,
  ISort,
  ITask,
  ITaskCommentsItem,
  ITaskFilesItem,
  ITaskPriority,
  ITaskStatus,
  ITaskType
} from "@/modules/TaskManager/Model/Task/TaskModel";
import Vue from "vue";
import SubTaskModel from "@/modules/TaskManager/Model/Subtask/SubTaskModel";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import TaskBlackSphereModule from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import { EventBus } from "@/main";

const name = "TaskModule";

export enum TaskMutationTypes {
  SET_TASK_LIST = "SET_TASK_LIST",
  RESET_TASK_LIST = "RESET_TASK_LIST",
  SET_TASK_LOADER = "SET_TASK_LOADER",
  SET_LOADER = "SET_LOADER",
  SET_SUBTASK_LOADER = "SET_SUBTASK_LOADER",
  SET_PARAMS = "SET_PARAMS",
  SET_VALUE_SORT = "SET_VALUE_SORT",
  SET_FROM_PAGE = "SET_FROM_PAGE",
  SET_TOTAL_COUNT = "SET_TOTAL_COUNT",
  DELETE_TASK = "DELETE_TASK",
  SET_HOT_PAGE = "SET_HOT_PAGE",
  SET_HOT_COUNT = "SET_HOT_COUNT",
  SET_OVERDUE_PAGE = "SET_OVERDUE_PAGE",
  SET_OVERDUE_COUNT = "SET_OVERDUE_COUNT",
  SET_SUBTASK_PAGE = "SET_SUBTASK_PAGE",
  SET_SUBTASK_COUNT = "SET_SUBTASK_COUNT",
  SET_CURRENT_TASK = "SET_CURRENT_TASK",
  TOGGLE_SHOW_MODAL_FILE_UPLOAD = "TOGGLE_SHOW_MODAL_FILE_UPLOAD",
  SET_FILE_UPLOAD = "SET_FILE_UPLOAD",
  SET_FILE_UPLOAD_MODAL = "SET_FILE_UPLOAD_MODAL",
  SET_PARAMS_FILES_UPLOAD = "SET_PARAMS_FILES_UPLOAD",
  CLEAR_FILE_UPLOAD = "CLEAR_FILE_UPLOAD",
  CLEAR_FILE_UPLOAD_MODAL = "CLEAR_FILE_UPLOAD_MODAL",
  UPDATE_TASK = "UPDATE_TASK",
  UPDATE_SUBTASK_TASK = "UPDATE_SUBTASK_TASK",
  UPDATE_TASK_BY_STAGE = "UPDATE_TASK_BY_STAGE",
  SET_TASKS_BY_STAGE = "SET_TASKS_BY_STAGE",
  RESET_TASKS_BY_STAGE = "RESET_TASKS_BY_STAGE",
  SET_TASKS_BY_STAGE_PAGE = "SET_TASK_BY_STAGE_PAGE",
  SET_TASKS_BY_STAGE_COUNT = "SET_TASK_BY_STAGE_COUNT",
  SET_SUBTASK_BY_TASK = "SET_SUBTASK_BY_TASK",
  RESET_SUBTASK_BY_TASK = "RESET_SUBTASK_BY_TASK",
  SET_HOT_TASK_LIST = "SET_HOT_TASK_LIST",
  SET_OVERDUE_TASK_LIST = "SET_OVERDUE_TASK_LIST",
  GET_TASK_TYPES_LIST = "GET_TASK_TYPES_LIST",
  GET_TASK_STATUS_LIST = "GET_TASK_STATUS_LIST",
  GET_TASK_PRIORITY_LIST = "GET_TASK_PRIORITY_LIST",
  CHECK_COMPLETE_SUBTASK = "CHECK_COMPLETE_SUBTASK",
  UPDATE_CURRENT_TASK = "UPDATE_CURRENT_TASK",
  GET_CURRENT_TASK_COMMENTS = "GET_CURRENT_TASK_COMMENTS",
  SET_COMMENTS_PAGE = "SET_COMMENTS_PAGE",
  SET_COMMENTS_COUNT = "SET_COMMENTS_COUNT",
  UPDATE_CURRENT_COMMENTS = "UPDATE_CURRENT_COMMENTS",
  UPDATE_CURRENT_ANSWER_COMMENTS = "UPDATE_CURRENT_ANSWER_COMMENTS",
  SET_CURRENT_COMMENT = "SET_CURRENT_COMMENT",
  GET_CURRENT_TASK_FILES = "GET_CURRENT_TASK_FILES",
  SET_FILES_PAGE = "SET_FILES_PAGE",
  SET_FILES_COUNT = "SET_FILES_COUNT",
  UPDATE_CURRENT_FILES = "UPDATE_CURRENT_FILES",
  UPDATE_FILES_MODAL = "UPDATE_FILES_MODAL",
  SET_CHILDREN_COMMENT = "SET_CHILDREN_COMMENT",
  RESET_CHILDREN_COMMENT = "RESET_CHILDREN_COMMENT",
  RESET_CURRENT_CHILDREN_COMMENT = "RESET_CURRENT_CHILDREN_COMMENT",
  RESET_TASK_COMMENTS = "RESET_TASK_COMMENTS",
  DELETE_COMMENT = "DELETE_COMMENT",
  DELETE_CHILDREN_COMMENT = "DELETE_CHILDREN_COMMENT",
  TOGGLE_FORM_LOADER = "TOGGLE_FORM_LOADER",
  SET_HISTORY_TASK = "SET_HISTORY_TASK",
  RESET_HISTORY_TASK = "RESET_HISTORY_TASK",
  SET_HISTORY_PAGE = "SET_HISTORY_PAGE",
  SET_HISTORY_COUNT = "SET_HISTORY_COUNT"
}

if (store.hasModule(name)) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, namespaced: true, name, stateFactory: true, store })
class TaskModule extends VuexModule {
  taskList: Array<TaskModel> = [];
  hotTaskList: Array<TaskModel> = [];
  overdueTaskList: Array<TaskModel> = [];
  currentTask: ITask | null = null;
  subtaskList: Array<SubTaskModel> = [];
  stageTask: any = {};
  typesList: Array<ITaskType> = [];
  prioritiesList: Array<ITaskPriority> = [];
  statusesList: Array<ITaskStatus> = [];
  loader: boolean = false;
  taskLoader: boolean = false;
  subtaskLoader: boolean = false;
  fromPag: number = 1;
  hotPage: number = 1;
  overduePage: number = 1;
  totalCount: number = 0;
  hotCount: number = 0;
  overdueCount: number = 0;
  subtaskPage: number = 1;
  subtaskCount: number = 0;
  tasksByStagePage: number = 1;
  tasksByStageCount: number = 0;
  completeSubtasks: boolean = false;
  params: IParams = {
    page: this.fromPag,
    per_page: 10,
    is_dashboard: 0,
    status: "all",
    role: "executor",
    projects: []
  };
  valueSort: ISort | null = { value: "priority_id", isAsc: true };
  valueSortList: Array<ISort> = [
    { value: "priority_id", isAsc: true },
    { value: "project_id", isAsc: true },
    { value: "date_to", isAsc: true },
    { value: "stage_id", isAsc: true },
    { value: "author_id", isAsc: true },
    { value: "status_id", isAsc: true },
    { value: "type_id", isAsc: true },
    { value: "executor_id", isAsc: true }
  ];
  commentsList: Array<ITaskCommentsItem> = [];
  commentsPage: number = 1;
  commentsCount: number = 0;
  commentFormLoader: boolean = false;
  currentComment: ITaskCommentsItem | null = null;
  filesList?: Array<ITaskFilesItem> = [];
  filesTask?: Array<ITaskFilesItem> = [];
  paramsFilesUpload: string = "";
  filesPage: number = 1;
  filesCount: number = 0;
  childCommentsList: any = {};
  showModalFileUpload: boolean = false;
  filesUploadCurrentTask: any[] = [];
  filesUploadModal: any[] = [];
  historyList: Array<IHistoryTaskItem> = [];
  historyPage: number = 1;
  historyCount: number = 0;

  @Mutation
  [TaskMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](payload) {
    this.showModalFileUpload = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_FILE_UPLOAD](payload) {
    if (this.filesUploadCurrentTask.length < 10) {
      this.filesUploadCurrentTask.push(...payload);
    }
  }

  @Mutation
  [TaskMutationTypes.SET_FILE_UPLOAD_MODAL](payload) {
    const countFilesUploadCurrentTask = this.filesUploadCurrentTask
      ? this.filesUploadCurrentTask.length
      : 0;
    const countFilesList =
      this.paramsFilesUpload === "add"
        ? 0
        : this.filesList?.length
        ? this.filesList.length
        : ProjectModule.filesList?.length
        ? ProjectModule.filesList.length
        : 0;
    let countFilesUploadModal = 0;
    payload.forEach((item) => {
      if (countFilesUploadCurrentTask + countFilesList + countFilesUploadModal < 10) {
        this.filesUploadModal.push(item);
        countFilesUploadModal = this.filesUploadModal ? this.filesUploadModal.length : 0;
      }
    });
  }

  @Mutation
  [TaskMutationTypes.SET_PARAMS_FILES_UPLOAD](payload) {
    this.paramsFilesUpload = payload;
  }

  @Mutation
  [TaskMutationTypes.CLEAR_FILE_UPLOAD]() {
    this.filesUploadCurrentTask = [];
  }

  @Mutation
  [TaskMutationTypes.CLEAR_FILE_UPLOAD_MODAL]() {
    this.filesUploadModal = [];
  }

  @Mutation
  [TaskMutationTypes.SET_TASK_LIST](payload) {
    this.taskList.push(...payload);
  }

  @Mutation
  [TaskMutationTypes.UPDATE_TASK](payload) {
    const task = this.taskList.length
      ? this.taskList.find((item: TaskModel) => item.task.id === payload.id)
      : false;
    if (task) {
      const index = this.taskList.findIndex((item) => item.task.id === payload.id);
      const newTask = new TaskModel({ ...task.task, ...payload });
      Vue.set(this.taskList, index, newTask);
    }
    const current = this.currentTask;
    if (current) {
      this.currentTask = { ...current, ...payload };
    }
  }

  @Mutation
  [TaskMutationTypes.UPDATE_SUBTASK_TASK](payload: ITask) {
    if (this.subtaskList && this.subtaskList[`subtask_${this.currentTask?.parent_id}`]) {
      const subtask = this.subtaskList[`subtask_${this.currentTask?.parent_id}`].length
        ? this.subtaskList[`subtask_${this.currentTask?.parent_id}`].find(
            (item) => item.id === payload.id
          )
        : false;
      if (subtask) {
        const index = this.subtaskList[`subtask_${this.currentTask?.parent_id}`].findIndex(
          (item) => item.id === payload.id
        );
        const newSubTask = { ...subtask, ...payload };
        Vue.set(this.subtaskList[`subtask_${this.currentTask?.parent_id}`], index, newSubTask);
      }
    }
  }

  @Mutation
  [TaskMutationTypes.UPDATE_TASK_BY_STAGE](payload) {
    if (this.stageTask && this.stageTask[`stage_${this.currentTask?.stage_id}`]) {
      const taskByStage = this.stageTask[`stage_${this.currentTask?.stage_id}`].list.length
        ? this.stageTask[`stage_${this.currentTask?.stage_id}`].list.find(
            (item) => item.id === this.currentTask?.id
          )
        : false;
      if (taskByStage) {
        const index = this.stageTask[`stage_${this.currentTask?.stage_id}`].list.findIndex(
          (item) => item.id === this.currentTask?.id
        );
        const newTask = { ...taskByStage, ...payload };
        Vue.set(this.stageTask[`stage_${this.currentTask?.stage_id}`].list, index, newTask);
      }
    }
  }

  @Mutation
  [TaskMutationTypes.RESET_TASK_LIST]() {
    this.taskList = [];
    this.hotTaskList = [];
    this.overdueTaskList = [];
  }

  @Mutation
  [TaskMutationTypes.SET_HOT_TASK_LIST](payload) {
    this.hotTaskList.push(...payload);
  }

  @Mutation
  [TaskMutationTypes.SET_OVERDUE_TASK_LIST](payload) {
    this.overdueTaskList.push(...payload);
  }

  @Mutation
  [TaskMutationTypes.RESET_TASKS_BY_STAGE](payload) {
    this.stageTask = payload;
    this.tasksByStagePage = 1;
  }

  @Mutation
  [TaskMutationTypes.SET_LOADER](payload) {
    this.loader = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_TASK_LOADER](payload) {
    this.taskLoader = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_SUBTASK_LOADER](payload) {
    this.subtaskLoader = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_PARAMS](payload) {
    this.params = {
      ...payload
    };
  }

  @Mutation
  [TaskMutationTypes.SET_VALUE_SORT](payload) {
    this.valueSort = null;
    const sort = this.valueSortList.find((item: ISort) => item.value === payload);
    this.valueSort = sort ? sort : { value: "priority_id", isAsc: true };
    if (this.valueSort) {
      this.valueSort.isAsc = !this.valueSort.isAsc;
      this.valueSort.value = payload;
    }
  }

  @Mutation
  [TaskMutationTypes.SET_CURRENT_TASK](payload) {
    this.currentTask = payload;
  }

  @Mutation
  [TaskMutationTypes.UPDATE_CURRENT_TASK](payload) {
    if (this.currentTask) {
      this.currentTask[payload.key] = payload.value;
    }
  }

  @Mutation
  [TaskMutationTypes.SET_FROM_PAGE](payload) {
    this.fromPag = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_TOTAL_COUNT](payload) {
    this.totalCount = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_HOT_PAGE](payload) {
    this.hotPage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_HOT_COUNT](payload) {
    this.hotCount = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_SUBTASK_PAGE](payload) {
    this.subtaskPage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_SUBTASK_COUNT](payload) {
    this.subtaskCount = payload;
  }
  @Mutation
  [TaskMutationTypes.SET_TASKS_BY_STAGE_PAGE](payload) {
    this.tasksByStagePage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_TASKS_BY_STAGE_COUNT](payload) {
    this.tasksByStageCount = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_OVERDUE_PAGE](payload) {
    this.overduePage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_OVERDUE_COUNT](payload) {
    this.overdueCount = payload;
  }

  @Mutation
  [TaskMutationTypes.GET_TASK_TYPES_LIST](payload) {
    this.typesList = payload;
  }

  @Mutation
  [TaskMutationTypes.GET_TASK_STATUS_LIST](payload) {
    this.statusesList = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_TASKS_BY_STAGE](payload) {
    if (!this.stageTask[`stage_${payload.stage_id}`] || payload.toReset) {
      Vue.set(this.stageTask, `stage_${payload.stage_id}`, payload.tasks);
    } else {
      //не добавляется новые задачи после пагниации, вернулся к прошлому варианту
      // this.stageTask[`stage_${payload.stage_id}`].list = this.stageTask[
      //   `stage_${payload.stage_id}`
      // ].list.filter((el) => {
      //   return payload.tasks.list.indexOf(el) == -1;
      // });

      this.stageTask[`stage_${payload.stage_id}`].list = this.stageTask[
        `stage_${payload.stage_id}`
      ].list.concat(payload.tasks.list);
    }
  }

  @Mutation
  [TaskMutationTypes.SET_SUBTASK_BY_TASK](payload) {
    Vue.set(
      this.subtaskList,
      `subtask_${payload.id}`,
      this.subtaskList[`subtask_${payload.id}`] ? this.subtaskList[`subtask_${payload.id}`] : []
    );
    this.subtaskList[`subtask_${payload.id}`].push(...payload.subtask);
  }

  @Mutation
  [TaskMutationTypes.RESET_SUBTASK_BY_TASK](payload) {
    this.subtaskList[`subtask_${payload.id}`] = payload.subtask;
  }

  @Mutation
  [TaskMutationTypes.GET_TASK_PRIORITY_LIST](payload) {
    this.prioritiesList = payload;
  }

  @Mutation
  [TaskMutationTypes.CHECK_COMPLETE_SUBTASK](payload) {
    this.completeSubtasks = payload;
  }

  @Mutation
  [TaskMutationTypes.GET_CURRENT_TASK_COMMENTS](payload) {
    this.commentsList.push(...payload);
    this.commentsList = setUniqueArray(this.commentsList, "id").sort(
      (a: ITaskCommentsItem, b: ITaskCommentsItem) => (a.id > b.id ? 1 : -1)
    );
  }

  @Mutation
  [TaskMutationTypes.RESET_TASK_COMMENTS]() {
    this.commentsList = [];
  }

  @Mutation
  [TaskMutationTypes.SET_COMMENTS_PAGE](payload) {
    this.commentsPage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_COMMENTS_COUNT](payload) {
    this.commentsCount = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_CHILDREN_COMMENT](payload) {
    if (this.childCommentsList[`answer_${payload.id}`]) {
      const newCurrentList = payload.comments.list ? payload.comments.list : [payload.comments];
      this.childCommentsList[`answer_${payload.id}`].list.push(...newCurrentList);
      Vue.set(
        this.childCommentsList[`answer_${payload.id}`],
        `list`,
        this.childCommentsList[`answer_${payload.id}`].list
      );
    } else {
      const newAnswerList = payload.comments.list
        ? payload.comments
        : { list: [payload.comments], page: 0 };
      Vue.set(this.childCommentsList, `answer_${payload.id}`, newAnswerList);
    }
    if (payload.comments.page) {
      Vue.set(this.childCommentsList[`answer_${payload.id}`], "page", payload.comments.page + 1);
    }
    const comment = this.commentsList?.find((item: ITaskCommentsItem) => item.id === payload.id);
    if (comment) {
      const index = this.commentsList.findIndex(
        (item: ITaskCommentsItem) => item.id === payload.id
      );
      const countComments = payload.comments.count
        ? payload.comments.count
        : comment.count_comments + 1;
      Vue.set(this.commentsList, index, {
        ...comment,
        count_comments: countComments
      });
    }
  }

  @Mutation
  [TaskMutationTypes.RESET_CHILDREN_COMMENT]() {
    this.childCommentsList = {};
  }

  @Mutation
  [TaskMutationTypes.RESET_CURRENT_CHILDREN_COMMENT](id) {
    if (this.childCommentsList[`answer_${id}`]) delete this.childCommentsList[`answer_${id}`];
  }

  @Mutation
  [TaskMutationTypes.UPDATE_CURRENT_ANSWER_COMMENTS](payload) {
    const comment = this.childCommentsList[`answer_${payload.parent_id}`]!.list
      ? this.childCommentsList[`answer_${payload.parent_id}`].list.find(
          (item: ITaskCommentsItem) => item.id === payload.comment_id
        )
      : false;
    if (comment) {
      const index = this.childCommentsList[`answer_${payload.parent_id}`].list.findIndex(
        (item: ITaskCommentsItem) => item.id === payload.comment_id
      );
      let files = comment.files;
      if (payload.files.length) {
        files.push(...payload.files);
      }
      if (comment.files.length && payload.files_delete) {
        const arrId = payload.files_delete.map((item) => {
          return { id: item };
        });
        files = _.differenceBy(files, arrId, "id");
      }
      Vue.set(this.childCommentsList[`answer_${payload.parent_id}`].list, index, {
        ...comment,
        text: payload.text ? payload.text : undefined,
        files: files
      });
    }
  }

  @Mutation
  [TaskMutationTypes.UPDATE_CURRENT_COMMENTS](payload) {
    const comment = this.commentsList.length
      ? this.commentsList.find((item: ITaskCommentsItem) => item.id === payload.comment_id)
      : false;
    if (comment) {
      const index = this.commentsList.findIndex(
        (item: ITaskCommentsItem) => item.id === payload.comment_id
      );
      let files = comment.files;
      let arrId = [];
      if (comment.files.length && payload.files_delete) {
        arrId = payload.files_delete.map((item) => {
          return { id: item };
        });
        files = _.differenceBy(files, arrId, "id");
      }
      if (payload.files.length) {
        files.push(...payload.files);
      }
      Vue.set(this.commentsList, index, {
        ...comment,
        text: payload.text ? payload.text : undefined,
        files: files,
        count_comments: payload.count_comments
      });
    }
  }

  @Mutation
  [TaskMutationTypes.SET_CURRENT_COMMENT](payload) {
    this.currentComment = payload;
  }

  @Mutation
  [TaskMutationTypes.DELETE_COMMENT](id) {
    const newCommentList = this.commentsList.filter((item: ITaskCommentsItem) => item.id !== id);
    this.commentsList = newCommentList;
  }

  @Mutation
  [TaskMutationTypes.DELETE_TASK](task) {
    const newTaskList = this.taskList.filter((item: TaskModel) => item.task.id !== task.id);
    const newHotTaskList = this.hotTaskList.filter(
      (item: TaskModel) => item.task.id !== task.id && item.task.parent_task_id !== task.task_id
    );
    const newOverdueTaskList = this.overdueTaskList.filter(
      (item: TaskModel) => item.task.id !== task.id && item.task.parent_task_id !== task.task_id
    );
    this.taskList = newTaskList;
    this.hotTaskList = newHotTaskList;
    this.overdueTaskList = newOverdueTaskList;
    if (this.stageTask[`stage_${this.currentTask?.stage_id}`]) {
      const filterStageList = this.stageTask[`stage_${this.currentTask?.stage_id}`]?.list.filter(
        (item: ITask | null) => item?.id !== task.id
      );
      Vue.set(this.stageTask[`stage_${this.currentTask?.stage_id}`], `list`, filterStageList);
    }
    if (this.subtaskList[`subtask_${this.currentTask?.parent_id}`]) {
      const filterSubtaskList = this.subtaskList[`subtask_${this.currentTask?.parent_id}`].filter(
        (item: ITask | null) => item?.id !== task.id
      );
      this.subtaskList[`subtask_${this.currentTask?.parent_id}`] = filterSubtaskList;
    }
  }

  @Mutation
  [TaskMutationTypes.DELETE_CHILDREN_COMMENT](payload) {
    const newCommentList = this.childCommentsList[`answer_${payload.parent}`]?.list.filter(
      (item: ITaskCommentsItem) => item.id !== payload.comment_id
    );
    this.childCommentsList[`answer_${payload.parent}`].list = newCommentList;
    const comment = this.commentsList?.find(
      (item: ITaskCommentsItem) => item.id === payload.parent
    );
    if (comment) {
      const index = this.commentsList.findIndex(
        (item: ITaskCommentsItem) => item.id === payload.parent
      );
      Vue.set(this.commentsList, index, {
        ...comment,
        count_comments: newCommentList.length
      });
    }
  }

  @Mutation
  [TaskMutationTypes.TOGGLE_FORM_LOADER](val) {
    this.commentFormLoader = val;
  }

  @Mutation
  [TaskMutationTypes.GET_CURRENT_TASK_FILES](payload) {
    if (payload === "reset") {
      this.filesList = [];
    } else {
      this.filesList = payload;
    }
  }

  @Mutation
  [TaskMutationTypes.SET_FILES_PAGE](payload) {
    this.filesPage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_FILES_COUNT](payload) {
    this.filesCount = payload;
  }

  @Mutation
  [TaskMutationTypes.UPDATE_CURRENT_FILES](id) {
    this.filesUploadCurrentTask = this.filesUploadCurrentTask.filter((item) => item.id !== id);
  }

  @Mutation
  [TaskMutationTypes.UPDATE_FILES_MODAL](id) {
    this.filesUploadModal = this.filesUploadModal.filter((item) => item.id !== id);
  }

  @Mutation
  [TaskMutationTypes.SET_HISTORY_TASK](payload) {
    this.historyList.push(...payload);
  }

  @Mutation
  [TaskMutationTypes.RESET_HISTORY_TASK]() {
    this.historyList = [];
  }

  @Mutation
  [TaskMutationTypes.SET_HISTORY_PAGE](payload) {
    this.historyPage = payload;
  }

  @Mutation
  [TaskMutationTypes.SET_HISTORY_COUNT](payload) {
    this.historyCount = payload;
  }

  @Action
  openModalFileUpload(payload) {
    this[TaskMutationTypes.SET_FILE_UPLOAD_MODAL](payload);
    this[TaskMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](true);
  }

  @Action
  closeModalFileUpload() {
    this[TaskMutationTypes.TOGGLE_SHOW_MODAL_FILE_UPLOAD](false);
    this[TaskMutationTypes.CLEAR_FILE_UPLOAD]();
    this[TaskMutationTypes.CLEAR_FILE_UPLOAD_MODAL]();
  }

  @Action
  async getTaskListAction(params: object) {
    if (this.taskList.length <= this.totalCount) {
      const taskList = await TaskService.getTaskList(params);
      this[TaskMutationTypes.SET_TASK_LIST](taskList);
      this[TaskMutationTypes.SET_LOADER](false);
    }
  }

  @Action
  async updateCommentsCountTaskAction(params: object) {
    const currentTask = await TaskService.getCurrentTask(params);
    this[TaskMutationTypes.SET_CURRENT_TASK](currentTask);
  }

  @Action
  async getCurrentTaskAction(params: object) {
    this[TaskMutationTypes.GET_CURRENT_TASK_FILES]("reset");
    const currentTask = await TaskService.getCurrentTask(params).catch((error) => {
      return error.response.data.code;
    });
    if (currentTask === 403 || currentTask === 404 || currentTask === 422) {
      return currentTask;
    } else {
      this[TaskMutationTypes.SET_CURRENT_TASK](currentTask);
      await this.getStatusesListAction({
        project_id: currentTask.project_id ? currentTask.project_id : null
      });
      this[TaskMutationTypes.RESET_SUBTASK_BY_TASK]({
        id: currentTask.id,
        subtask: []
      });
      this[TaskMutationTypes.SET_SUBTASK_PAGE](1);
      this[TaskMutationTypes.SET_SUBTASK_LOADER](true);
      await this.getSubtaskListAction({
        id: currentTask.id,
        per_page: 15,
        page: this.subtaskPage
      });
      await TaskBlackSphereModule.getTaskBlackSphereAction({
        id: currentTask.id
      });
      this[TaskMutationTypes.SET_TASK_LOADER](false);
      this[TaskMutationTypes.SET_SUBTASK_LOADER](false);
      if (currentTask.project_id) {
        await ProjectModule.getOwnerListActions(currentTask.project_id);
        await ProjectModule.getMembersListActions(currentTask.project_id);
      }
      const filesList = await TaskService.getFilesList({
        task_id: currentTask.id,
        per_page: 10
      });
      this[TaskMutationTypes.GET_CURRENT_TASK_FILES](filesList.list);
    }
  }

  @Action
  async updateCurrentTaskAction(data) {
    this[TaskMutationTypes.SET_SUBTASK_LOADER](false);
    const isSuccess = await TaskService.updateCurrentTask(data);
    if (this.currentTask) {
      if (data.stage_id !== undefined) {
        // удаление задачи из списка задач старого этапа
        if (this.stageTask[`stage_${this.currentTask?.stage_id}`]) {
          const filterStageList = this.stageTask[`stage_${this.currentTask?.stage_id}`].list.filter(
            (item: ITask | null) => item?.id !== this.currentTask?.id
          );
          Vue.set(this.stageTask[`stage_${this.currentTask?.stage_id}`], `list`, filterStageList);
        }
        //добавление задачи в список задач нового этапа
        if (this.stageTask[`stage_${data.stage_id}`]) {
          const newStageList = this.currentTask
            ? this.stageTask[`stage_${data.stage_id}`].list.concat([this.currentTask])
            : this.stageTask[`stage_${data.stage_id}`].list;
          Vue.set(this.stageTask[`stage_${data.stage_id}`], `list`, newStageList);
        }
      }
      this[TaskMutationTypes.UPDATE_TASK]({
        id: this.currentTask.id,
        ...data
      });
      this[TaskMutationTypes.UPDATE_SUBTASK_TASK]({
        id: data.id,
        ...data
      });
      this[TaskMutationTypes.UPDATE_TASK_BY_STAGE]({
        id: this.currentTask.id,
        ...data
      });
      await this.getCurrentTaskAction({
        task_id: this.currentTask.task_id,
        alias: this.currentTask.alias
      });
    }
    if (isSuccess) {
      const task: ITask | null = { ...this.currentTask, ...data };
      if (task) this[TaskMutationTypes.SET_CURRENT_TASK](task);
      if (this.currentTask) {
        await this.getSubtaskListAction({ id: this.currentTask.id });
        await this.getTaskListByStageIdAction({ id: this.currentTask.id });
      }
    }
  }

  @Action
  async checkSubtaskStatusesAction(data) {
    await TaskService.checkSubtaskStatuses(data);
    this[TaskMutationTypes.CHECK_COMPLETE_SUBTASK](true);
  }

  @Action
  async deleteTaskAction(task: ITask) {
    await TaskService.deleteCurrentTask(task.id);
    this[TaskMutationTypes.DELETE_TASK](task);
    TaskManagerModuleV2.closeCurtainShowAndEditTask();
  }

  @Action
  async createTaskAction(body: object) {
    await TaskService.createTask(body);
  }

  @Action
  async getHotTaskAction(params: object) {
    this[TaskMutationTypes.SET_LOADER](true);
    const taskList = await TaskService.getHotTask(params);
    this[TaskMutationTypes.SET_HOT_TASK_LIST](taskList);
    this[TaskMutationTypes.SET_LOADER](false);
  }

  @Action
  async getOverdueTaskAction(params: object) {
    this[TaskMutationTypes.SET_LOADER](true);
    const taskList = await TaskService.getOverdueTask(params);
    this[TaskMutationTypes.SET_OVERDUE_TASK_LIST](taskList);
    this[TaskMutationTypes.SET_LOADER](false);
  }

  @Action
  async getPrioritiesListAction() {
    const prioritiesList = await TaskService.getPrioritiesList();
    this[TaskMutationTypes.GET_TASK_PRIORITY_LIST](prioritiesList);
  }

  @Action
  async getTypesListAction() {
    const typesList = await TaskService.getTypesList();
    this[TaskMutationTypes.GET_TASK_TYPES_LIST](typesList);
  }

  @Action
  async getStatusesListAction(params: object) {
    const statusesList = await TaskService.getStatusesList(params);
    this[TaskMutationTypes.GET_TASK_STATUS_LIST](statusesList);
  }

  @Action
  async getSubtaskListAction(payload: any) {
    const subtasks = await TaskService.getSubtaskTaskListByTaskId(payload);
    const subtask = subtasks.list.map((item) => item);
    this[TaskMutationTypes.SET_SUBTASK_COUNT](subtasks.count);
    this[TaskMutationTypes.SET_SUBTASK_PAGE](subtasks.page + 1);
    this[TaskMutationTypes.SET_SUBTASK_BY_TASK]({ id: payload.id, subtask });
    this[TaskMutationTypes.SET_SUBTASK_LOADER](false);
  }

  get subtasksByTask() {
    return (subtask_id) =>
      this.subtaskList[`subtask_${subtask_id}`] !== undefined
        ? this.subtaskList[`subtask_${subtask_id}`]
        : { list: [], count: 0, page: 1 };
  }

  @Action
  async getTaskListByStageIdAction(payload: any) {
    this[TaskMutationTypes.SET_LOADER](true);
    const taskListByStageId = await TaskService.getTaskListByStageId(payload);
    const changePayload = {
      stage_id: payload.stage_id === 0 || payload.stage_id === undefined ? null : payload.stage_id,
      toReset: payload.toReset,
      tasks: {
        count: taskListByStageId.count,
        list: taskListByStageId.list,
        page: taskListByStageId.page,
        per_page: taskListByStageId.per_page
      }
    };
    this[TaskMutationTypes.SET_TASKS_BY_STAGE](changePayload);
    this[TaskMutationTypes.SET_LOADER](false);
  }

  get tasksByStage() {
    return (stage_id) =>
      this.stageTask[`stage_${stage_id}`] !== undefined
        ? this.stageTask[`stage_${stage_id}`]
        : { list: [], count: 0 };
  }

  get priority() {
    return (id) => this.prioritiesList.find((item) => item.id === id);
  }

  get type() {
    return (id) => this.typesList.find((item) => item.id === id);
  }

  get status() {
    return (id) => this.statusesList.find((item) => item.id === id);
  }

  @Action
  async createTaskCheckListAction(body: object) {
    const isSuccess = await TaskService.createTaskCheckList(body);
    if (this.currentTask && isSuccess) {
      this[TaskMutationTypes.UPDATE_CURRENT_TASK]({
        key: "checklists",
        value: isSuccess
      });
    }
  }

  @Action
  async updateTaskCheckListItemAction(body: object | any) {
    const isSuccess = await TaskService.updateTaskCheckListItem(body);
    if (
      this.currentTask &&
      isSuccess &&
      body.id &&
      this.currentTask.checklists.filter((item: IChekItem) => item.id === body.id).length
    ) {
      if (body.text) {
        this.currentTask.checklists.filter((item: IChekItem) => item.id === body.id)[0].text =
          body.text;
      }
      if (body.is_completed) {
        this.currentTask.checklists.filter(
          (item: IChekItem) => item.id === body.id
        )[0].is_completed = body.is_completed;
      }
    }
  }

  @Action
  async deleteTaskCheckListItemAction(id: number) {
    const isSuccess = await TaskService.deleteTaskCheckListItem(id);
    if (this.currentTask && isSuccess) {
      this[TaskMutationTypes.UPDATE_CURRENT_TASK]({
        key: "checklists",
        value: this.currentTask.checklists.filter((item: IChekItem) => item.id !== id)
      });
    }
  }

  @Action
  async getCommentsListAction(payload: any) {
    const commentsList = await TaskService.getCommentsList(payload);
    this[TaskMutationTypes.GET_CURRENT_TASK_COMMENTS](commentsList.list);
    this[TaskMutationTypes.SET_COMMENTS_PAGE](commentsList.page + 1);
    this[TaskMutationTypes.SET_COMMENTS_COUNT](commentsList.count);
  }

  @Action
  async getFilesListAction(payload: object) {
    const filesList = await TaskService.getFilesList(payload);
    this[TaskMutationTypes.GET_CURRENT_TASK_FILES](filesList.list);
    //вернуться к реализации мутации позже
    // this[TaskMutationTypes.SET_COMMENTS_PAGE](filesList.page + 1);
    // this[TaskMutationTypes.SET_FILES_COUNT](filesList.count);
  }

  @Action
  async getAnswerCommentsListAction(payload: any) {
    const comments = await TaskService.getAnswerCommentsList(payload);
    this[TaskMutationTypes.SET_CHILDREN_COMMENT]({ id: payload.comment_id, comments });
  }

  @Action
  async creteCommentsAction(payload: any) {
    this[TaskMutationTypes.TOGGLE_FORM_LOADER](true);
    const comment = await TaskService.creteComments({
      id: payload.id,
      text: payload.text,
      files: payload.files.length ? payload.files.map((item) => item.id) : undefined,
      parent_id: payload.parent_id ? payload.parent_id : undefined
    }).catch(() => EventBus.$emit("offLoader"));
    const comments = payload;
    comments.id = comment.id;
    if (!payload.parent_id) {
      this.commentsList.push(comments);
    } else {
      this[TaskMutationTypes.SET_CHILDREN_COMMENT]({ id: payload.parent_id, comments });
    }
    Vue.set(this.currentTask!, `count_comments`, this.currentTask!.count_comments + 1);
    this[TaskMutationTypes.TOGGLE_FORM_LOADER](false);
  }

  @Action
  async updateCommentsLisAction(payload: any) {
    this[TaskMutationTypes.TOGGLE_FORM_LOADER](true);
    await TaskService.updateComments({
      comment_id: payload.comment_id,
      id: payload.id,
      text: payload.text,
      files: payload.files.length ? payload.files.map((item) => item.id) : undefined,
      files_delete: payload.files_delete.length ? payload.files_delete : undefined
    }).catch(() => EventBus.$emit("offLoader"));
    if (payload.parent_id) {
      this[TaskMutationTypes.UPDATE_CURRENT_ANSWER_COMMENTS](payload);
    } else {
      this[TaskMutationTypes.UPDATE_CURRENT_COMMENTS](payload);
    }
    if (!payload.files_delete.length || (payload.files_delete.length && !payload.files.length))
      this[TaskMutationTypes.TOGGLE_FORM_LOADER](false);
  }

  @Action
  async deleteCurrentCommentAction(payload) {
    await TaskService.deleteComment({
      id: payload.id,
      comment_id: payload.comment_id
    });
    if (payload.parent) {
      this[TaskMutationTypes.DELETE_CHILDREN_COMMENT](payload);
    } else {
      this[TaskMutationTypes.DELETE_COMMENT](payload.comment_id);
    }
  }

  @Action
  async getHistoryTaskAction(payload) {
    const history = await TaskService.getHistoryTask(payload);
    this[TaskMutationTypes.SET_HISTORY_TASK](history.list);
    this[TaskMutationTypes.SET_HISTORY_PAGE](history.page + 1);
    this[TaskMutationTypes.SET_HISTORY_COUNT](history.count);
  }
}

export default getModule(TaskModule);
