<template>
  <div class="calendar">
    <v-calendar
      title-position="left"
      class="calendar__custom"
      :masks="masks"
      popover.visibility="false"
      :attributes="attributes"
      @update:to-page="getTimeInfo"
      title-transition
      is-expanded
      locale="ru"
    >
      <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
        <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
      </div>
      <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
      <div slot="day-content" slot-scope="{ day, attributes }" @click="clickSelectDate(day)">
        <div class="vc-h-full">
          <span
            :class="[
              'vc-day-content',
              attributes.length ? (day.id === getToday ? 'today' : '') : '',
              setClassDependingAttributes(attributes),
              setClassDependingDay(attributes, day, getToday)
            ]"
            >{{ day.day }}</span
          >
        </div>
      </div>
    </v-calendar>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";

import { format } from "date-fns";
import moment from "moment";

export default {
  name: "CalendarPicker",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    attributes: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      masks: {
        weekdays: "WWW"
      },
      value: format(new Date(), "Y-MM-dd"),
      currentMonth: null
    };
  },
  beforeDestroy() {
    this.$root.$off("getTimeInfo");
  },
  mounted() {
    this.$root.$on("getTimeInfo", () => {
      this.getTimeInfo();
    });
  },
  methods: {
    /**
     * Запоминание выбранного дня и подставновка текущего рабочего времени для этого дня
     */
    clickSelectDate(day) {
      this.value = day.id;
      this.$root.$emit(`date:change_${this.unique}`, this.value);
    },
    /**
     * Получение данных по открытому в календаре месяцу
     */
    getTimeInfo(data) {
      this.$emit("getTimeInfo", data);
      // if (data.year) {
      //   this.currentMonth = `${data.year}-${data.month < 10 ? "0" + data.month : data.month}`;
      // }
      // httpClient({
      //   url: `/WorkingCalendar`,
      //   method: "GET",
      //   params: {
      //     _count: 31,
      //     _search: `{"date":"${this.currentMonth}"}`,
      //     _order_by: "date",
      //     _order: { date: "asc" }
      //   }
      // })
      //   .then((response) => {
      //     this.attributes = [];
      //     this.attributes = response.data.data.items.map((item) => ({
      //       customData: {
      //         hours: item.count_hours,
      //         id: item.id
      //       },
      //       dates: item.date
      //     }));
      //   })
      //   .catch((error) => {
      //     this.errorAlert(error);
      //   });
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    setClassDependingAttributes(attributes) {
      if (attributes.length) {
        if (attributes[0].customData.hours === String(0)) {
          return "output";
        }
        if (
          attributes[0].customData.hours < String(8) &&
          attributes[0].customData.hours > String(0)
        ) {
          return "shortcut";
        }
      }
    },
    setClassDependingDay(attributes, day, getToday) {
      if (attributes.length) {
        if (this.value === day.id) {
          return "current";
        }
      }
    }
  },
  computed: {
    getToday() {
      return format(new Date(), "Y-MM-dd");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.calendar {
  font-family: SF, sans-serif !important;
  width: 400px;
  display: flex;
  justify-content: center;
  & /deep/ .vc-container {
    margin: 0;
    border: none;

    .vc-header {
      padding: 8px 0 0;
    }

    .vc-title {
      margin: 0 0 16px 40px;
      font-family: SF, sans-serif !important;
      font-weight: bold;
      font-size: 16px;
      line-height: 29px;
      cursor: default;
      color: $text-body-dark;
    }

    .vc-title:hover {
      opacity: 1;
    }

    .vc-arrows-container {
      padding: 8px 24px 0 0;
      display: flex;
      align-items: center;
    }

    .vc-arrow {
      padding: 0;
      margin: 0;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-disabled;
      border: none;
      border-radius: 0;

      svg {
        height: 20px;
      }
    }

    .vc-arrow:hover {
      background: transparent;
      color: $text-subdued;
    }

    .vc-arrow:active {
      background: transparent;
      color: $action-primary-pressed;
    }

    .vc-svg-icon {
      cursor: pointer;
      stroke: #a0aec0;
    }

    .vc-weeks {
      padding: 0 24px 0;
    }

    .vc-weekday:first-letter {
      text-transform: uppercase;
    }

    .vc-weekday,
    .vc-day {
      font-family: SF, sans-serif;
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $text-subdued;
      width: 30px;
      height: 30px;
      align-self: center;
      justify-self: center;
      &.in-prev-month.is-not-in-month * {
        color: $text-disabled;
        opacity: 1;
      }
      &.in-next-month.is-not-in-month * {
        color: $text-disabled;
        opacity: 0;
      }
    }

    .vc-weekday {
      height: 30px;
    }

    .vc-day-content {
      width: 30px;
      height: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: $text-default;
    }

    .vc-day:hover {
      .vc-h-full {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .vc-day-content:before {
          content: "";
          position: absolute;
          z-index: -1;
          background-color: $action-primary;
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
      }
      &.in-prev-month.is-not-in-month * {
        .vc-h-full {
          .vc-day-content:before {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: transparent;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            transition: 0s;
          }
        }
      }

      span {
        background: transparent;
        color: $main-white;
      }
    }

    .output {
      color: $text-disabled;
    }

    .shortcut {
      position: relative;

      &::after {
        position: absolute;
        width: 4px;
        height: 4px;
        content: "";
        background-color: #a7a9c0;
        bottom: 4px;
        right: 12px;
        border-radius: 50%;
        z-index: 10;
      }
    }

    .today {
      position: relative;
      color: $text-default;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $action-secondary-hovered;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    .current {
      position: relative;
      color: $surface-default;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $action-primary;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
  }

  .vc-day-content:hover {
    background-color: transparent;
  }
}
</style>
