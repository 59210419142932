<template>
  <transition name="show-modal">
    <div class="absence-card__modal" v-click-outside="hideModal">
      <button class="absence-card__setting-button" type="button" @click="handleClickIconDots">
        <i class="icon-dots-vertical"></i>
      </button>
      <ul class="absence-card__modal-list" v-show="modalShow">
        <li class="absence-card__modal-item">
          <button class="absence-card__modal-button" type="button" @click="openModal">
            <i class="icon-details"></i>
            Подробнее
          </button>
        </li>
        <li class="absence-card__modal-item" v-if="showExport">
          <button class="absence-card__modal-button" type="button" @click="exportRequest">
            <i class="icon-download-secondary"></i> Выгрузить
          </button>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
import axios from "axios";
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ActionsListConfirm",
  mixins: [],
  props: {
    currentVacationData: {
      type: Object,
      default() {
        return {};
      }
    },
    showAllItem: {
      type: Boolean,
      default: false
    },
    request: {
      type: Boolean,
      default: false
    },
    confirmFooter: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    showExport: {
      type: Boolean,
      default: false
    }
  },
  components: { IcoMoonWrap },
  data() {
    return {
      modalShow: false,
      isAvailableForEditing: true
    };
  },
  mounted() {
    this.$root.$on("isAvailableForEditing", (val) => {
      this.isAvailableForEditing = val;
    });
  },
  beforeDestroy() {
    this.$root.$off("isAvailableForEditing");
  },
  methods: {
    handleClickIconDots() {
      this.modalShow = !this.modalShow;
    },
    hideModal() {
      this.modalShow = false;
    },
    openModal() {
      if (this.request) {
        this.$root.$emit("openRequestConfirmation", {
          vacation: this.currentVacationData
        });
      } else {
        this.$root.$emit("openMyConfirmations", {
          vacation: this.currentVacationData,
          type: this.type
        });
      }
      this.hideModal();
    },
    exportRequest() {
      if (this.type === "transfer") {
        this.exportTransferVacationRequest();
      } else {
        this.exportAbsenceRequest();
      }
    },
    exportTransferVacationRequest() {
      httpClient({
        method: "GET",
        url: `/TransferVacationOrder/export`,
        params: {
          id: this.currentVacationData.id
        }
      })
        .then((response) => {
          let url = response.data.data.url;
          window.open(url, "_blank");
          this.hideModal();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    exportAbsenceRequest() {
      httpClient({
        method: "GET",
        url: `/AbsenceOrder/export`,
        params: {
          id: this.currentVacationData.id
        }
      })
        .then((response) => {
          let url = response.data.data;
          window.open(url, "_blank");
          this.hideModal();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

.show-modal-enter-active,
.show-modal-leave-active {
  transition: all 0.2s ease;
}

.show-modal-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.show-modal-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.absence-card {
  &__modal {
    position: relative;
    margin-left: auto;
  }

  &__setting-button {
    width: 32px;
    height: 32px;
    background: $action-secondary;
    border-radius: 4px;
    border: none;
    outline: none;

    &:hover {
      background: $action-secondary-hovered;

      i {
        color: $icon-hovered;
      }
    }

    &:active,
    &:focus {
      background: $action-secondary-pressed;
      i {
        color: $icon-hovered;
      }
    }
  }

  &__modal-list {
    position: absolute;
    z-index: 15;
    margin: 0;
    top: 34px;
    right: 0;
    padding: 8px 0;
    list-style: none;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 14px;
  }

  &__modal-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: left;
    vertical-align: middle;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 20px;
    }

    &:hover {
      background: $text-light;
      border-radius: 4px;
    }
  }
}
</style>
