<template>
  <div class="channel-item" @click="handleChannelClick">
    <div class="channel-item__avatar">
      <img
        class="channel-item__logo"
        :src="returnSrcFileChat(channel.chat_settings.avatar.path)"
        alt="avatar"
        v-if="channel.chat_settings.avatar"
      />
      <span
        class="channel-item__logo"
        v-else
        :style="[
          channel.chat_settings.color ? { backgroundColor: channel.chat_settings.color } : ''
        ]"
      >
        <span>{{ setChannelName }}</span>
      </span>
      <span class="chat-item__status"></span>
    </div>
    <div class="channel-item__wrap wrap-info">
      <div class="wrap-info__header">
        <h3 class="wrap-info__label">{{ channel.chat_settings.name }}</h3>
        <div class="wrap-info__members">
          <i class="icon-people"></i>
          {{ channel.count_users }}
        </div>
      </div>
      <p class="wrap-info__description" v-if="channel.chat_settings.description">
        {{ channel.chat_settings.description }}
      </p>
      <p class="wrap-info__tag">{{ channel.chat_settings.tag }}</p>
      <button
        @click.stop="handleChannelButton"
        v-if="!isModerator"
        class="wrap-info__subscribe"
        :class="{ 'wrap-info__subscribe_sub': isSubscribe }"
      >
        <i :class="setType.icon"></i>
        {{ setType.title }}
      </button>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";

export default {
  name: "ChannelItemCatalog",
  mixins: [],
  props: {
    channel: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", [
      "joinChannel",
      "leaveChannel",
      "openChatFromCatalog",
      "leaveChannelInCatalog"
    ]),
    returnSrcFileChat(src) {
      return domainStorage + "/chat/files/" + src;
    },
    handleChannelButton() {
      if (this.isSubscribe) {
        this.leaveChannel({
          type: "leave",
          id: this.channel.id
        })
          .then(() => {
            this.leaveChannelInCatalog(this.channel.id);
            this.$emit("changeChannelSubscribe", {
              type: "leave",
              id: this.channel.id
            });
          })
          .catch((err) => {
            new Error(err);
          });
      } else {
        this.joinChannel({
          type: "join",
          id: this.channel.id
        })
          .then(() => {
            this.$emit("changeChannelSubscribe", {
              type: "join",
              id: this.channel.id
            });
          })
          .catch((err) => {
            new Error(err);
          });
      }
    },
    handleChannelClick() {
      this.openChatFromCatalog(this.channel);
    }
  },
  computed: {
    isSubscribe() {
      return this.channel.my_settings.length;
    },
    isModerator() {
      return this.channel.my_settings.length ? this.channel.my_settings[0].is_moderator : false;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setType() {
      if (this.isSubscribe) {
        return { icon: "icon-check", title: "Отписаться" };
      } else {
        return { icon: "icon-plus", title: "Подписаться" };
      }
    },
    setChannelName() {
      return this.channel.chat_settings.name[0].toUpperCase();
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
.channel-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  width: 330px;
  height: 166px;
  background: white;
  border: 1px solid $border-subdued;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 0 16px 0;
  transition: 0.3s;
  @media screen and (max-width: $xxs) {
    margin-bottom: 8px;
    width: 310px;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      border: 1px solid $text-highlight;
    }
  }

  &__avatar {
    margin-right: 16px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    background-color: $message;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 70px);
    height: 100%;
    .wrap-info {
      &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;
      }
      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: $text-default;
        margin: 0;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        padding-right: 3px;
      }
      &__members {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: $text-disabled;
        margin-top: 3px;
        i {
          font-size: 14px;
          color: $text-disabled;
          margin-right: 4px;
        }
      }
      &__description {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $text-subdued;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__tag {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $text-disabled;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__editable {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $text-disabled;
        margin-bottom: 16px;
      }
      &__subscribe {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $chat-editor;
        background: none;
        outline: none;
        border: none;
        margin-top: auto;
        padding: 0;
        i {
          font-size: 12px;
          margin-right: 4px;
        }
        &_sub {
          color: $text-subdued;
        }
      }
    }
  }
}
</style>
