






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WeekItem extends Vue {
  @Prop({
    default: () => {},
    type: Object
  })
  day!: { name: string; index: number };
  @Prop({
    default: () => [],
    type: Array
  })
  selectDays!: Array<number>;

  handleDay() {
    this.$emit("handleDay", { day: this.day, isActive: this.isActive });
  }

  get isActive() {
    return this.selectDays.some((item) => item === this.day.index);
  }
}
