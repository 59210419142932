























import ProjectStage from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/ProjectStage";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner.vue";
export default {
  name: "ProjectStagesBody",
  mixins: [],
  props: {
    isOnCurtain: {
      type: Boolean,
      default: true
    },
    projectId: {
      type: String,
      default: "0"
    },
    stages: {
      type: Object,
      default: () => {}
    }
  },
  components: { Spinner, ProjectStage },
  data() {
    return {
      defaultStage: {
        id: 0,
        name: "Вне этапа"
      }
    };
  },
  mounted() {},
  methods: {},
  computed: {
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    }
  },
  watch: {},
  validations: {}
};
