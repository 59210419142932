import * as types from "@/modules/News/store/mutations/mutationTypes";

import { domain } from "@/globalVariables";
import router from "@/router";
import store from "@/store";
import { format } from "date-fns";
import ErrorObserver from "@/api/Error";
import moment from "moment";
import Vue from "vue";
import httpClient from "@/api/client/httpClient";

const actions = {
  openFullSizeImage({ commit }, link) {
    commit(types.CHANGE_IMAGE_LINK, link);
    commit(types.CHANGE_SHOW_FULL_SIZE_IMAGE, true);
  },
  closeFullSizeImageModal({ commit }) {
    commit(types.CHANGE_IMAGE_LINK, "");
    commit(types.CHANGE_SHOW_FULL_SIZE_IMAGE, false);
  },
  changeModalAddChannel({ commit }, payload) {
    commit(types.CHANGE_SHOW_ADD_CHANNEL, payload);
  },
  openSettingsModal({ state, commit }, { val, show }) {
    commit(types.TOGGLE_SHOW_CURTAIN_SHOW_AND_EDIT_CHANNEL, true);
    commit(types.TOGGLE_CURTAIN_CHANNEL_LOADER, true);
    httpClient({
      method: "GET",
      url: `/Channel/actionGetItem/${val.id}`,
      params: {
        _with: JSON.stringify([
          "memberUsers.roles",
          "adminUsers.roles",
          "author_user.roles",
          "current_channel_user"
        ])
      }
    }).then((response) => {
      const indexInAllChannels = state.channelAllList.findIndex(
        (item) => item.id === response.data.data.id
      );
      const indexInMyChannels = state.channelList.findIndex(
        (item) => item.id === response.data.data.id
      );
      Vue.set(state.channelAllList, indexInAllChannels, response.data.data);
      Vue.set(state.channelList, indexInMyChannels, response.data.data);
      commit(types.CHANGE_EDIT_CHANNEL_VALUE, response.data.data);
      commit(types.TOGGLE_CURTAIN_CHANNEL_LOADER, false);
    });
    // commit(types.CHANGE_SHOW_ADD_CHANNEL, show);
  },
  openMembersModal({ state, commit }, payload) {
    if (payload.memberInfoChannel) {
      commit(types.CHANGE_SHOW_CHANNEL, true);
    }
    commit(types.CHANGE_MEMBER_INFO_CHANNEL, payload.memberInfoChannel);
    commit(types.CHANGE_MEMBER_USER, payload.memberUser);
    if (state.memberUser.length) {
      commit(types.CHANGE_IS_ADMIN_LIKE, false);
    } else {
      commit(types.CHANGE_IS_ADMIN_LIKE, true);
    }
    if (payload.title) {
      commit(types.CHANGE_MEMBER_LIST_TITLE, payload.title);
    } else {
      commit(types.CHANGE_MEMBER_LIST_TITLE, payload.title, "Участники канала");
    }
  },
  openChannelsModal({ commit }) {
    commit(types.CHANGE_SHOW_CHANNEL, true);
  },
  closeChannelsModal({ commit }) {
    commit(types.CHANGE_SHOW_CHANNEL, false);
  },
  changeTab({ commit }, payload) {
    commit(types.CHANGE_ACTIVE_FILTER, payload);
  },
  getUsersBirthdaysInfo({ state, commit }) {
    httpClient({
      method: "GET",
      url: `/User/birthdays`,
      params: {
        _count: 5
      }
    })
      .then((response) => {
        commit(types.SET_BIRTHDAY_ITEMS, response.data.data);
        commit(types.CHANGE_BIRTHDAY_LOADER, false);
      })
      .catch((error) => {
        commit(types.CHANGE_BIRTHDAY_LOADER, false);
        ErrorObserver.notification(error);
      });
  },
  getNewEmployeeInfo({ commit }) {
    httpClient({
      method: "GET",
      url: `/User/getItems`,
      params: {
        is_active: JSON.stringify([1]),
        _order: { date_of_employment: "desc" },
        _count: 5,
        _with: JSON.stringify(["roles"])
      }
    })
      .then((response) => {
        commit(types.SET_EMPLOYEE_ITEMS, response.data.data.items);
        commit(types.CHANGE_EMPLOYEE_LOADER, false);
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  // getChannelList({ commit }, search) {
  //
  //   httpClient({
  //     method: "GET",
  //     url: `/Channel/actionGetItems`,
  //     params: {
  //       _count: 300,
  //       _with: JSON.stringify([
  //         "memberUsers.roles",
  //         "adminUsers.roles",
  //         "author_user.roles",
  //         "current_channel_user"
  //       ]),
  //       _search: { name: search },
  //       _order: {
  //         name: "asc"
  //       }
  //     }
  //   })
  //     .then((response) => {
  //       commit(types.SET_CHANNEL_ALL_LIST, response.data.data.items);
  //     })
  //     .catch((error) => ErrorObserver.notification(error));
  // },
  getChannelInfo({ commit }) {
    commit(types.CHANGE_LOADING_CHANNELS, true);

    return new Promise((resolve) => {
      httpClient({
        method: "GET",
        url: `/Channel/actionGetItems`,
        params: {
          _count: 300,
          _with: JSON.stringify(["current_channel_user"]),
          _order: {
            name: "asc"
          }
        }
      })
        .then((response) => {
          commit(types.CHANGE_LOADING_CHANNELS, false);
          commit(types.SET_CHANNEL_ALL_LIST, response.data.data.items);
          commit(
            types.SET_CHANNEL_LIST,
            response.data.data.items.filter((item) => item.current_channel_user)
          );
          resolve();
        })
        .catch((error) => {
          ErrorObserver.notification(error);
        });
    });
  },
  getNewsItems({ commit, state }) {
    commit(types.CHANGE_LOADING, true);
    commit(types.SET_NEWS_COUNTER, 6);
    return new Promise((resolve) => {
      httpClient({
        url: `/News/actionGetItems`,
        method: "GET",
        params: {
          _with: JSON.stringify(["current_user_like", "author", "attachment_files"]),
          _count: 5,
          channel: state.activeChannel !== "" ? { channel_id: state.activeChannel } : undefined
        }
      })
        .then((response) => {
          if (
            !response.data.query.channel ||
            state.activeChannel === JSON.parse(response.data.query.channel).channel_id
          ) {
            commit(types.SET_NEWS_ITEMS, response.data.data.items);
            commit(types.SET_NEWS_TOTAL_COUNT, response.data.data.total_count);
            commit(types.CHANGE_LOADING, false);
            commit(types.CHANGE_GLOBAL_EDIT_MODE, false);
            resolve();
          }
        })
        .catch((error) => {
          ErrorObserver.notification(error);
        });
    });
  },

  scrollGetNewsItems({ state, commit, getters, rootGetters }) {
    commit(types.CHANGE_SCROLL_LOADING, true);
    return new Promise((resolve) => {
      httpClient({
        url: `/News/actionGetItems`,
        method: "GET",
        params: {
          _with: JSON.stringify(["current_user_like", "author", "attachment_files"]),
          _from: state.newsCounter,
          _count: 5,
          channel: state.activeChannel !== "" ? { channel_id: state.activeChannel } : undefined
        }
      })
        .then((response) => {
          if (!state.isCertainNews) {
            state.newsItems.push(...response.data.data.items);
            commit(types.SET_NEWS_TOTAL_COUNT, response.data.data.total_count);
            commit(types.SET_NEWS_COUNTER, state.newsCounter + 5);
            commit(types.CHANGE_SCROLL_LOADING, false);
            resolve();
          }
        })
        .catch((error) => {
          commit(types.CHANGE_SCROLL_LOADING, false);
          ErrorObserver.notification(error);
        });
    });
  },
  getNewsItemInfoAction(_, newsId) {
    return httpClient({
      url: `/News/actionGetItem/${newsId}`,
      method: "GET",
      params: {
        _with: JSON.stringify(["current_user_like", "author", "channel", "attachment_files"])
      }
    });
  },
  getQuizAction(_, newsId) {
    return httpClient({
      url: `/News/actionGetItem/${newsId}`,
      method: "GET"
    });
  },
  postNewsAction(_, news) {
    return httpClient({
      url: `/News/actionCreate`,
      method: "POST",
      data: [news]
    });
  },
  setNewsToUpdate({ commit }, newsToUpdate) {
    commit(types.CHOOSE_NEWS_TO_UPDATE, newsToUpdate);
  },
  editNewsAction(_, { news, newsId }) {
    return httpClient({
      url: `/News/actionUpdate/${newsId}`,
      method: "PUT",
      data: [news]
    });
  },
  deleteNewsAction(_, newsId) {
    return httpClient({
      url: `/News/actionDelete/${newsId}`,
      method: "DELETE"
    });
  },

  setActiveChannel({ commit }, payload) {
    commit(types.CHANGE_ACTIVE_CHANNEL, payload);
  },
  deleteChannelAction(_, channelId) {
    return httpClient({
      url: `/Channel/actionDelete/${channelId}`,
      action: "DELETE"
    });
  },
  unsubscribeChannelAction(_, currentChannelUserId) {
    return httpClient({
      url: `/ChannelUser/actionDelete/${currentChannelUserId}`,
      action: "DELETE"
    });
  },
  subscribeChannelAction({ rootGetters }, channelId) {
    return httpClient({
      url: `/ChannelUser/actionCreate`,
      method: "POST",
      data: [
        {
          user_id: rootGetters.getUserId,
          channel_id: channelId
        }
      ]
    });
  },
  createChannelAction(_, newChannel) {
    return httpClient({
      url: `/Channel/actionCreate`,
      method: "POST",
      data: [newChannel]
    });
  },
  editChannelAction(_, { editedChannel, channelId }) {
    return httpClient({
      url: `/Channel/actionUpdate/${channelId}`,
      method: "PUT",
      data: [editedChannel]
    });
  },
  deleteUserFromChannelAction(_, channelUserId) {
    return httpClient({
      url: `/ChannelUser/actionDelete/${channelUserId}`,
      method: "DELETE"
    });
  },
  setEditCommentData({ commit }, commentForEditing) {
    commit(types.SET_CURRENT_EDITABLE_COMMENT, commentForEditing);
  },
  setReplyCommentData({ commit }, commentForReply) {
    commit(types.SET_CURRENT_REPLYABLE_COMMENT, commentForReply);
  },
  deleteCommentAction(_, commentId) {
    return httpClient({
      url: `/Comment/actionDelete/${commentId}`,
      method: "DELETE"
    });
  },
  sendCommentAction(_, newComment) {
    return httpClient({
      url: `/Comment/actionCreate`,
      method: "POST",
      data: [newComment]
    });
  },
  updateCommentAction(_, { editedComment, commentId }) {
    return httpClient({
      url: `/Comment/actionUpdate/${commentId}`,
      method: "PUT",
      data: [editedComment]
    });
  },
  createViewAction(_, newsId) {
    return httpClient({
      url: `/View/actionCreate`,
      method: "POST",
      data: [
        {
          viewable_id: newsId,
          viewable_type: "news"
        }
      ]
    });
  },
  getCommentsInfoAction(_, { newsId, commentsLength }) {
    return httpClient({
      method: "GET",
      url: `/Comment/actionGetItems`,
      params: {
        commentable_id: newsId,
        commentable_type: "news",
        _order: { datetime_of_creation: "asc" },
        _count: commentsLength
      }
    });
  },
  like(_, { type, id }) {
    return httpClient({
      url: `/Like/actionCreate`,
      method: "POST",
      data: [
        {
          likeable_id: id,
          likeable_type: type
        }
      ]
    });
  },
  removeLike(_, currentUserLikeId) {
    return httpClient({
      url: `/Like/actionDelete/${currentUserLikeId}`,
      method: "DELETE"
    });
  },
  addVote(_, answerId) {
    return httpClient({
      url: `/UserPollAnswer/actionCreate`,
      method: "POST",
      data: [
        {
          poll_answer_id: answerId
        }
      ]
    });
  },
  deleteVote(_, userAnswerId) {
    return httpClient({
      url: `/UserPollAnswer/actionDelete/${userAnswerId}`,
      method: "DELETE"
    });
  },
  showNewsLikes({ commit }, { id, type }) {
    commit(types.TOGGLE_SHOW_CURTAIN_NEWS_LIKES, true);
    const timeoutId = setTimeout(() => {
      commit(types.TOGGLE_CURTAIN_LIKES_LOADER, true);
    }, 300);
    httpClient({
      url: `/Like/actionGetItems`,
      method: "GET",
      params: {
        likeable_id: id,
        likeable_type: type,
        _count: 300
      }
    }).then((response) => {
      clearTimeout(timeoutId);
      commit(types.TOGGLE_CURTAIN_LIKES_LOADER, false);
      commit(types.SET_CURRENT_NEWS_LIKES, response.data.data.items);
    });
  },
  closeNewsLikes({ commit }) {
    commit(types.TOGGLE_SHOW_CURTAIN_NEWS_LIKES, false);
    commit(types.SET_CURRENT_NEWS_LIKES, []);
  }
};

export default actions;
