import { Vue } from "vue-property-decorator";

const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const validateDay = (day, month) => {
  const lastDay = monthLength[month - 1];
  if (day > lastDay) {
    return lastDay;
  } else {
    if (day <= 9) {
      return `0${day}`;
    } else {
      return day;
    }
  }
};

const validateMonth = (month) => {
  if (month === 0) {
    return 1;
  } else if (month <= 9) {
    return `0${month}`;
  } else if (month > 12) {
    return 12;
  } else {
    return month;
  }
};

const validateYear = (year) => {
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) Vue.set(monthLength, 1, 29);
  return year;
};
export const dateValidate = (date: string) => {
  if (date.length) {
    const parts = date.split(".");
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10);
    let year = parseInt(parts[2], 10);
    if (year) {
      year = validateYear(year);
    }
    if (day && month) {
      month = validateMonth(month);
      day = validateDay(day, month);
    }
    if (day && month && year) {
      return `${day}.${month}.${year}`;
    }
    return date;
  }
  return date;
};
