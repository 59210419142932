<template>
  <div class="profile__education-modal education-modal" @click.self="$emit('hideModal')">
    <div class="education-modal__window">
      <div class="education-modal__header">
        <i class="icon-sort-arrow" @click="$emit('hideModal', false, null)"></i>
        <h2 class="education-modal__title">
          {{ editValue ? "Редактировать образование" : "Добавить образование" }}
        </h2>
        <i class="icon-cross" @click="$emit('hideModal', false, null)"></i>
      </div>
      <div class="education-modal__main">
        <div class="education-modal__wrap education-modal__wrap_full-select">
          <div class="education-modal__label">Название организации</div>
          <Widget
            :type="'select-new'"
            :entity="'EducationInstitution'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editUniversityEducationUser'"
            :placeholder="'Например, Томский Государственный университет'"
            :editValue="editUniversity"
            createEntity="create"
            :createMode="true"
          />
        </div>
        <div class="education-modal__wrap">
          <Widget
            :type="'select-new'"
            :entity="'EducationType'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editTypeEducationUser'"
            :label="'Образование:'"
            :editValue="editType"
            :placeholder="'Не выбрано'"
            :multiple="false"
            createEntity="create"
            :createMode="true"
          />
        </div>
        <div class="education-modal__wrap">
          <Widget
            :type="'select-new'"
            :entity="'EducationQualification'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editQualificationEducationUser'"
            :label="'Направление:'"
            :placeholder="'Нет направления'"
            :multiple="false"
            :editValue="editQualification"
            createEntity="create"
            :createMode="true"
          />
        </div>
        <div class="education-modal__wrap">
          <Widget
            :type="'select-new'"
            :multiple="false"
            :entity="'EducationSpeciality'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'editSpecialityEducationUser'"
            :label="'Специальность:'"
            :editValue="editSpeciality"
            :placeholder="'Введите специальность'"
            createEntity="create"
            :createMode="true"
          />
        </div>
        <div class="education-modal__wrap education-modal__wrap_date">
          <div class="education-modal__date">
            <Widget
              v-if="!editValue"
              :type="'InputDatePicker'"
              :editValue="editStartDate"
              :displayFormat="'DD.MM.YYYY'"
              :unique="'editStartDateEducationUser'"
              :showIcon="true"
              :label="'Начало обучения:'"
            />
            <YearPickerWidget
              v-if="editValue"
              :editValue="editValue.from_year"
              :unique="'editStartDateEducationUser'"
            />
          </div>
          <div class="education-modal__date">
            <Widget
              v-if="!editValue"
              :type="'InputDatePicker'"
              :editValue="editEndDate"
              :displayFormat="'DD.MM.YYYY'"
              :unique="'editEndDateEducationUser'"
              :showIcon="true"
              :label="'Конец обучения:'"
            />
            <YearPickerWidget
              v-if="editValue"
              :editValue="editValue.to_year"
              :unique="'editEndDateEducationUser'"
            />
          </div>
        </div>
      </div>
      <div class="education-modal__footer">
        <button
          class="education-modal__button education-modal__button_cancel"
          type="button"
          @click="$emit('hideModal', false, null)"
        >
          Отменить
        </button>
        <button
          class="education-modal__button"
          type="button"
          v-if="!editValue"
          @click="addNewEducationItem"
        >
          Создать
        </button>
        <button
          class="education-modal__button"
          type="button"
          v-if="editValue"
          @click="editEducationItem(editValue.id)"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from "@/components/Widgets/Widget";

import { domain } from "@/globalVariables";
import TextInput from "@/components/Widgets/Inputs/TextInput";
import moment from "moment";
import YearPickerWidget from "@/components/Widgets/DatePickers/YearPickerWidget";
import httpClient from "@/api/client/httpClient";
export default {
  name: "EducationCreateAndEditModal",
  mixins: [],
  props: {
    dataUser: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editValue: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  components: { YearPickerWidget, TextInput, Widget },
  data() {
    return {
      editStartDate: moment(new Date()).format("YYYY-MM-DD"),
      editEndDate: moment(new Date()).format("YYYY-MM-DD"),
      editType: "",
      editSpeciality: "",
      editQualification: "",
      editUniversity: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("date:change_editStartDateEducationUser");
    this.$root.$off("date:change_editEndDateEducationUser");
    this.$root.$off("year:change_editStartDateEducationUser");
    this.$root.$off("year:change_editEndDateEducationUser");
    this.$root.$off("changeSelectValue:editTypeEducationUser");
    this.$root.$off("changeSelectValue:editSpecialityEducationUser");
    this.$root.$off("changeSelectValue:editQualificationEducationUser");
    this.$root.$off("changeSelectValue:editUniversityEducationUser");
  },
  mounted() {
    this.setEditData();
    this.$root.$on("date:change_editStartDateEducationUser", (value) => {
      this.editStartDate = value;
    });
    this.$root.$on("date:change_editEndDateEducationUser", (value) => {
      this.editEndDate = value;
    });
    this.$root.$on("year:change_editStartDateEducationUser", (value) => {
      this.editStartDate = value;
    });
    this.$root.$on("year:change_editEndDateEducationUser", (value) => {
      this.editEndDate = value;
    });
    this.$root.$on("changeSelectValue:editTypeEducationUser", (value) => {
      this.editType = value;
    });
    this.$root.$on("changeSelectValue:editSpecialityEducationUser", (value) => {
      this.editSpeciality = value;
    });
    this.$root.$on("changeSelectValue:editQualificationEducationUser", (value) => {
      this.editQualification = value;
    });
    this.$root.$on("changeSelectValue:editUniversityEducationUser", (value) => {
      this.editUniversity = value;
    });
  },
  methods: {
    /**
     * function of creating a new education card in a company
     */
    addNewEducationItem() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      httpClient({
        url: `/UserEducation/create`,
        method: "POST",
        data: [
          {
            user_id: this.$route.params.user_id ? this.$route.params.user_id : this.getUserId,
            education_type_id: this.editType.length ? this.editType[0].id : null,
            education_institution_id: this.editUniversity.length ? this.editUniversity[0].id : null,
            education_speciality_id: this.editSpeciality.length ? this.editSpeciality[0].id : null,
            education_qualification_id: this.editQualification.length
              ? this.editQualification[0].id
              : null,
            from_year: moment(this.editStartDate).format("YYYY"),
            to_year: this.editEndDate === "" ? null : moment(this.editEndDate).format("YYYY")
          }
        ]
      })
        .then(() => {
          this.$emit("goGetInfoUser");
          this.$emit("hideModal", false, null);
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Образование сотрудника успешно добавлено!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    /**
     * User education card validation function
     */
    checkValidationData() {
      if (!this.editUniversity.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Заполните поле с названием учебного заведения!`
        });
        return false;
      } else if (!this.editStartDate.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите год начала обучения!`
        });
        return false;
      } else if (!this.editEndDate.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите год окончания обучения!`
        });
        return false;
      } else if (!this.editType.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите вид образования!`
        });
        return false;
      } else if (!this.editQualification.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите направление!`
        });
        return false;
      } else if (!this.editSpeciality.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите специальность!`
        });
        return false;
      }
      return true;
    },
    /**
     * Setting existing user data for editing
     * */
    setEditData() {
      if (this.editValue) {
        this.editStartDate = this.editValue.from_year;
        this.editEndDate = this.editValue.to_year;
        this.editType = [this.editValue.education_type];
        this.editSpeciality = [this.editValue.education_speciality];
        this.editQualification = [this.editValue.education_qualification];
        this.editUniversity = [this.editValue.education_institution];
      }
    },
    /**
     * function of editing the education card in the company
     * @param id - id history card
     */
    editEducationItem(id) {
      if (!this.checkValidationData()) return null;
      httpClient({
        url: `/UserEducation/update/${id}`,
        method: "PUT",
        data: [
          {
            user_id: this.$route.params.user_id ? this.$route.params.user_id : this.getUserId,
            education_type_id: this.editType.length ? this.editType[0].id : null,
            education_institution_id: this.editUniversity.length ? this.editUniversity[0].id : null,
            education_speciality_id: this.editSpeciality.length ? this.editSpeciality[0].id : null,
            education_qualification_id: this.editQualification.length
              ? this.editQualification[0].id
              : null,
            from_year: moment(this.editStartDate).format("YYYY"),
            to_year: this.editEndDate === "" ? null : moment(this.editEndDate).format("YYYY")
          }
        ]
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: `Образование успешно изменено!`
          });
          this.$emit("goGetInfoUser");
          this.$emit("hideModal", false, null);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },
    setGrade() {
      return this.editValue.levels.length ? this.editValue.levels[0].name : "ACADEMY";
    },
    setRole() {
      return this.editValue.roles.length ? this.editValue.roles[0].name : "-";
    },
    setDepartment() {
      return this.editValue.departments.length ? this.editValue.departments[0].name : "-";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.education-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 100;

  &__window {
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    width: 736px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      width: 100vw;
      height: 100vh;
      background: $surface-default;
      z-index: 100;
      border-radius: 0;
    }

    @media (max-width: $sm) {
      width: 100vw;
      height: 100vh;
      background: $surface-default;
      z-index: 100;
      border-radius: 0;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 24px;
    border-bottom: 1px solid $border-disabled;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      justify-content: flex-start;
      padding: 16px 16px 8px;
    }

    @media (max-width: $sm) {
      justify-content: flex-start;
      padding: 16px 16px 8px;
    }

    i {
      font-size: 22px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-sort-arrow {
      display: none;
      transform: rotate(90deg);
      margin-right: 20px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        display: block;
      }

      @media (max-width: $sm) {
        display: block;
      }
    }
    .icon-cross {
      @media screen and (max-width: 850px) and (max-height: 450px) {
        display: none;
      }

      @media (max-width: $sm) {
        display: none;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    color: #000000;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      font-size: 16px;
      line-height: 32px;
      color: $text-body-dark;
    }

    @media (max-width: $sm) {
      font-size: 16px;
      line-height: 32px;
      color: $text-body-dark;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 32px 72px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 60vh;
      padding: 16px 16px 0;
    }

    @media (max-width: $sm) {
      overflow-y: scroll;
      max-height: 90vh;
      padding: 16px 16px 0;
    }

    @media (max-width: 320px) {
      overflow-y: scroll;
      max-height: 75vh;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      overflow-y: scroll;
      max-height: 55vh;
    }
  }

  &__wrap {
    width: 100%;

    &_date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 16px;

      @media (max-width: $sm) {
        flex-direction: column;
      }
    }

    &:not(:last-child) {
      margin: 0 0 54px;

      @media screen and (max-width: 850px) and (max-height: 450px) {
        margin: 0 0 16px;
      }

      @media (max-width: $sm) {
        margin: 0 0 16px;
      }
    }

    /deep/ {
      .select-bg {
        width: 100%;
      }

      .select-content-value-bg {
        width: 70% !important;

        @media (max-width: $sm) {
          width: 34% !important;
        }
      }

      .select-content-placeholder {
        width: 70% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: $sm) {
          width: 34% !important;
        }
      }
    }

    &_full-select {
      /deep/ {
        .select-content-value-bg {
          width: 80% !important;

          @media (max-width: $sm) {
            width: 80% !important;
          }
        }

        .select-content-placeholder {
          width: 80% !important;

          @media (max-width: $sm) {
            width: 80% !important;
          }
        }
      }
    }
  }

  &__date {
    width: 48%;

    @media (max-width: $sm) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__label {
    margin: 0 0 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-disabled;
  }

  &__user {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    width: 100%;

    img {
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  &__name {
    margin: 0 0 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $text-default;
  }

  &__active {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
  }

  &__subtitle {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $text-subdued;
  }

  &__content {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }

  &__black-spheres-date {
    margin: 0;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    background: $bg-surface-light;
    border-radius: 12px;
  }

  &__footer {
    border-top: 1px solid $border-disabled;
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 32px;

    @media screen and (max-width: 850px) and (max-height: 450px) {
      padding: 16px;
    }

    @media (max-width: $sm) {
      padding: 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    background: $action-primary;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #fafaff;
    border: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $action-primary-hovered;
    }
    &:focus {
      outline: none;
    }

    &_cancel {
      background: $action-secondary;
      color: $text-subdued;
      box-shadow: none;

      &:hover {
        background: $action-secondary-hovered;
      }
    }
  }
}
</style>
