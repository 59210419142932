export default {
  data() {
    return {
      option: {},
      container: null,
      scrollList: null,
      isVisible: false,
      lastScrollTop: 0,
      nextScrollTop: 0
    };
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault(id = ".chat", idList = "#list-dynamic") {
      this.container = document.querySelector(id);
      this.scrollList = document.querySelector(idList);
      this.options = {
        root: this.container,
        rootMargin: "0px",
        threshold: 1
      };
      this.observer = this.setObserver();
    },
    callback(entries) {
      this.nextScrollTop = this.scrollList.scrollTop;
      const isDownDirection = this.lastScrollTop < this.scrollList.scrollTop;
      this.lastScrollTop = this.scrollList.scrollTop;
      this.isVisible = entries[0].intersectionRatio === 1;
      this.handleView(isDownDirection);
    },
    setObserver() {
      return new IntersectionObserver(this.callback, this.options);
    },
    handleView() {}
  }
};
