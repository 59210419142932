<template>
  <div @click="$emit('closeFullSizeImageModal')" class="full-size-image-modal">
    <div class="full-size-image-modal__content">
      <img :src="returnSrcFile(imageLink)" alt="FullSizeImage" />
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "FullSizeImageModal",
  mixins: [],
  props: ["imageLink"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.full-size-image-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10000;
  background-color: rgba($text-default, 0.5);

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-radius: 24px;

    overflow: hidden;

    img {
      max-width: 90%;
      max-height: 100vh;
      //border-radius: 24px;
    }
  }
}
</style>
