import axios from "axios";
import { domain } from "@/globalVariables";
import Cookies from "vue-cookie";
import ExtendableError from "@/api/ErrorHandler";
import router from "@/router";
import EventObserver from "@/api/EventObserver";
import { debug, EventBus } from "@/main";
import { notEmpty } from "@/helpers/helperFunction";
import store from "@/store";
const prefix = "monolit";

const config = {
  baseURL: `${domain}/${prefix}/`
};

const httpClient = axios.create(config);
const errorsStatus = {
  401: (error) => {
    Cookies.delete("mandate");
    delete httpClient.defaults.headers["Authorization"];
    if (router.app.$route.path !== "/login") {
      store.dispatch("logout");
    }
    EventObserver.broadcast("showNotification", {
      type: "error",
      timeout: 5000,
      label: "Пользователь не авторизирован!"
    });
    throw new ExtendableError(error.data.data.error.message);
  }
};
const authInterceptor = (config) => {
  // достаём токент аутентификации пользователя, с LocalStorage, или cookies
  const authToken = Cookies.get("mandate");
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;

  return config;
};
httpClient.interceptors.request.use(authInterceptor);

const errorAlert = (error) => {
  let msg = `undefined error from backend`;
  let code = error.name || "500";
  if (error.response) {
    msg = `${error.response.data.data.error.message}`;
    code = `${error.response.data.data.error.code}`;
  } else {
    msg = `${error.message || error}`;
  }
  // eslint-disable-next-line no-console
  if (debug) console.error(error);
  if (notEmpty(error.response.data.data.error.messages)) {
    let msgErrors = error.response.data.data.error.messages;
    for (let i in msgErrors) {
      EventBus.$emit("showNotification", {
        type: "error",
        timeout: 5000,
        label: `${msgErrors[i]}`
      });
    }
  } else {
    return EventBus.$emit("showNotification", {
      type: "error",
      timeout: 5000,
      label: `${msg}`
    });
  }
};

httpClient.interceptors.response.use(
  (response) => {
    /** Как-то обрабатываем успешный результат */
    return response;
  },

  (error) => {
    /** Как-то обрабатываем результат, завершенный ошибкой */
    const errorAction = errorsStatus[error.response.status];
    if (errorAction) errorAction(error.response);
    errorAlert(error);
    return Promise.reject(error);
  }
);
export default httpClient;
