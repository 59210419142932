<template>
  <div class="chat__wrap">
    <div
      class="chat"
      v-show="!threadMode"
      :class="{
        'chat_menu-open': showMenu || (showReaction && !showCatalog),
        chat_read: currentChat && !showEditor
      }"
    >
      <ChatHeader :chat="currentChat" v-if="currentChat" />
      <ChatHeaderLoader v-if="!currentChat" />
      <div class="chat__container">
        <div class="chat__main-wrap">
          <ChatMain v-if="currentChat" />
          <ChatMainLoader v-if="!currentChat" />
          <ChatEditor v-if="currentChat && showEditor && !joinMode" />
          <button class="chat__join" v-if="currentChat && joinMode" @click="handleJoinChannel">
            Вступить в канал
          </button>
          <ChatFooterLoader v-if="!currentChat" />
        </div>
        <ChatMenu v-show="showMenu && !showReaction" />
        <ReactionContent v-if="showReaction" />
      </div>
    </div>
    <div
      class="chat chat__pinned"
      :class="{ chat__pinned_open: showPin }"
      v-if="pinInChat && !threadMode && currentChat"
    >
      <ChatHeaderPinned :chat="currentChat" />
      <div class="chat__container">
        <div class="chat__main-wrap">
          <ChatMainPinned />
        </div>
        <transition name="slide">
          <ChatMenu v-show="showMenu && !showReaction" />
        </transition>
        <ReactionContent v-if="showReaction" />
      </div>
    </div>
    <div
      class="chat"
      v-show="threadMode"
      :class="{
        'chat_menu-open': showMenu || (showReaction && !showCatalog),
        chat_comment: threadMode
      }"
      v-if="currentChat.channel.type === 2"
    >
      <ChatHeader :chat="currentChat" v-if="currentChat" />
      <div class="chat__container">
        <div class="chat__main-wrap">
          <ChatMainComment />
          <ChatEditor />
        </div>
        <transition name="slide">
          <ChatMenu v-show="showMenu && !showReaction" />
        </transition>
        <ReactionContent v-if="showReaction" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ChatHeader from "@/modules/Messenger/components/Chat/ChatHeader/ChatHeader";
import ChatMain from "@/modules/Messenger/components/Chat/ChatMain/ChatMain";
import ChatEditor from "@/modules/Messenger/components/Chat/ChatEditor/ChatEditor";
import ChatHeaderLoader from "@/modules/Messenger/components/ChatLoaders/ChatHeaderLoader";
import ChatMainLoader from "@/modules/Messenger/components/ChatLoaders/ChatMainLoader";
import ChatFooterLoader from "@/modules/Messenger/components/ChatLoaders/ChatFooterLoader";

import { domain } from "@/globalVariables";
import ChatMenu from "@/modules/Messenger/components/ChatMenu/ChatMenu";
import ChatHeaderPinned from "@/modules/Messenger/components/Chat/ChatHeader/ChatHeaderPinned";
import ChatMainPinned from "@/modules/Messenger/components/Chat/ChatMain/ChatMainPinned";
import ChatMainComment from "@/modules/Messenger/components/Chat/ChatMain/ChatMainComment";
import ReactionContent from "@/modules/Messenger/components/ChatModal/AttachedModal/ReactionContent";
import httpClient from "@/api/client/httpClient";
export default {
  name: "Chat",
  mixins: [],
  props: {},
  components: {
    ReactionContent,
    ChatMainComment,
    ChatMenu,
    ChatFooterLoader,
    ChatMainLoader,
    ChatHeaderLoader,
    ChatMainPinned,
    ChatHeaderPinned,
    ChatEditor,
    ChatMain,
    ChatHeader
  },
  data() {
    return {
      chat: {},
      messengerStatus: null,
      tags: null,
      timeId: null,
      writingStatus: null
    };
  },
  mounted() {},
  beforeDestroy() {
    this.messengerStatus.unsubscribe();
  },
  methods: {
    ...mapActions("MessengerModule", ["responseMessage", "updateJoinChannel"]),
    handleJoinChannel() {
      this.updateJoinChannel({ type: "join", id: this.currentChat.channel.id });
    },
    handleClickLink(link) {
      clearTimeout(this.timeId);
      this.timeId = setTimeout(() => {
        const reg = /[,.!?()]/gi;
        const nickname = link.target.dataset.link
          .trim()
          .replaceAll("@", "")
          .replaceAll(reg, " ")
          .split(" ");
        if (nickname[0] !== "all") {
          this.getUserForNickname(nickname[0]);
        }
      }, 200);
    },
    getUserForNickname(nickname) {
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _count: 1,
          _search: { nickname: nickname },
          is_active: JSON.stringify([1])
        }
      })
        .then((response) => {
          const id = response.data.data.items[0].id;
          let routeData = this.$router.resolve({ name: "UserInfo", params: { user_id: id } });
          window.open(routeData.href, "_blank");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "currentChat",
      "showMenu",
      "showModal",
      "users",
      "pinInChat",
      "showChat",
      "showCatalog",
      "showPin",
      "threadMode",
      "threadInChat",
      "showReaction",
      "joinMode",
      "messages"
    ]),
    ...mapGetters("MessengerModule", ["getChatFromQuery"]),
    showEditor() {
      if (
        this.currentChat.channel.type === 2 &&
        this.currentChat.channel.chat_settings.is_read_only
      ) {
        return this.currentChat.channel.is_moderator || this.isAdminChat;
      } else {
        return true;
      }
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {
    messages() {
      if (this.tags) {
        this.tags.forEach((item) => {
          item.removeEventListener("click", this.handleClickLink);
        });
      }
      this.$nextTick(() => {
        this.tags = document.querySelectorAll(".userTagChat");
        this.tags.forEach((item) => {
          item.addEventListener("click", this.handleClickLink);
        });
      });
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

::-webkit-scrollbar {
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: $bg-gray-500;
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $surface-disabled;
}
.chat {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: flex-end;
  &_menu-open {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &__container {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);

    /deep/ {
      .reaction-content {
        position: relative;
        max-height: calc(100vh - 70px);
        padding: 0;
        border-left: 1px solid #eae8f0;
        width: 344px;
        flex-shrink: 0;

        @media screen and (max-width: $xxxl) {
          width: 100%;
          max-width: 375px;
          position: absolute;
          right: 0;
          border-radius: 0;
          z-index: 14;
        }

        @media screen and (max-width: $xl) {
          width: 300px;
        }
        @media screen and (max-width: $lg) {
          max-width: 100%;
          width: 100%;

          bottom: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &__wrap {
          display: flex;
          align-items: center;
          padding: 0 24px;
          width: 100%;
          height: 58px;
          border-bottom: 1px solid #eae8f0;
          @media screen and (max-width: $xl) {
            padding: 0 12px;
          }
          @media screen and (max-width: $lg) {
            padding: 0 24px;
          }
        }
        &__title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #343748;
        }
        .emoji-mart {
          height: calc(100% - 70px);
          @media screen and (max-width: $xxxl) {
            width: 100% !important;
            height: calc(100vh - 128px);
          }
          @media screen and (max-width: $lg) {
            height: 343px;
          }

          .emoji-mart-anchor {
            @media screen and (max-width: $xl) {
              div svg {
                height: 16px;
                width: 16px;
              }
            }
            @media screen and (max-width: $lg) {
              div svg {
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
  &__main-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &__thread,
  &__pinned {
    position: absolute;
    visibility: hidden;
    right: -100%;
    transition: visibility 0.5s, right 0.5s;
    z-index: 15;

    &_open {
      visibility: visible;
      right: 0;
    }
  }
  &__pinned {
    /deep/ {
      //.chat-main {
      //  max-height: 100vh;
      //}
      //.list-dynamic {
      //  max-height: calc(100vh - 70px);
      //}
    }
  }
  &__wrap {
    display: flex;
    //flex-basis: 79%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  &__join {
    all: unset;
    align-self: center;
    transform: translateY(-200%);
    padding: 8px 16px;
    box-shadow: 13px 12px 30px rgba(1, 26, 255, 0.1), -16px -16px 40px rgba(255, 255, 255, 0.2),
      16px 16px 40px rgba(255, 255, 255, 0.2);
    border: 1px solid #f1effa;
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
    cursor: pointer;
  }
  &_read {
    justify-content: flex-start;

    /deep/ {
      //.chat-main {
      //  max-height: 90vh;
      //}
      //.list-dynamic {
      //  max-height: calc(86vh - 40px);
      //}
    }
  }
  /deep/ {
    a {
      color: $action-primary;
      transition: all 0.3s ease;
      @media (hover: hover) {
        &:hover {
          color: $action-primary-hovered;
        }
      }
      &:active {
        color: $action-primary-pressed;
      }
    }
  }
}
</style>
