import httpClient from "@/api/client/httpClientV2";
import { Response, Service } from "@/helpers/types/service";
import ProjectFullModel from "@/modules/TaskManager/Model/Project/ProjectFullModel";
class ProjectService extends Service {
  getProjectList(params: object): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/projects/getAll`,
      params: params
    }).then((response) => {
      return response.data;
    });
  }
  getProjectDescription(id): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/projects/get`,
      params: {
        id
      }
    }).then((response) => {
      return response.data;
    });
  }
  getProjectOwners(id): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/members/getOwners`,
      params: {
        per_page: 300,
        project_id: id
      }
    }).then((response) => {
      return response.data;
    });
  }
  getProjectMembers(id): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/members/getMembers`,
      params: {
        per_page: 300,
        project_id: id
      }
    }).then((response) => {
      return response.data;
    });
  }
  getChildProjects({ id, page = 1 }): Response<any> {
    return httpClient({
      method: "GET",
      url: `/task_manager/projects/getAll`,
      params: {
        page: page,
        per_page: 3,
        parent_id: id,
        is_member: 0,
        "orders[created_at]": "desc"
      }
    }).then((response) => {
      return response.data;
    });
  }
  createProject(projectToCreate): Response<any> {
    return httpClient({
      method: "POST",
      url: `/task_manager/projects/create`,
      data: projectToCreate
    });
  }
  updateProject(updatedProject): Response<any> {
    return httpClient({
      method: "PATCH",
      url: `/task_manager/projects/update`,
      data: updatedProject
    });
  }
  deleteProject(projectId): Response<any> {
    return httpClient({
      method: "DELETE",
      url: `/task_manager/projects/delete`,
      data: { id: projectId }
    });
  }
  updateProjectUsers({ projectId, ownersAdd, ownersDelete, membersAdd, membersDelete }) {
    return httpClient({
      method: "POST",
      url: `/task_manager/members/create`,
      data: {
        project_id: projectId,
        add: {
          owners: ownersAdd,
          members: membersAdd
        },
        delete: {
          owners: ownersDelete,
          members: membersDelete
        }
      }
    });
  }
  getProjectTree() {
    return httpClient({
      method: "GET",
      url: `/task_manager/projects/get_tree`
    }).then((response) => {
      return response.data;
    });
  }

  getOwnersInfo(id): Response<ProjectFullModel> {
    return httpClient({
      method: "GET",
      url: `/task_manager/members/getOwners`,
      params: {
        project_id: id
      }
    }).then((response) => {
      return response.data.list;
    });
  }

  getMembersInfo(id): Response<ProjectFullModel> {
    return httpClient({
      method: "GET",
      url: `/task_manager/members/getMembers`,
      params: {
        project_id: id
      }
    }).then((response) => {
      return response.data.list;
    });
  }

  getProjectFiles(id): Response<ProjectFullModel> {
    return httpClient({
      method: "GET",
      url: `/task_manager/files/get_all`,
      params: {
        project_id: id
      }
    }).then((response) => {
      return response.data.list;
    });
  }
}

export default new ProjectService();
