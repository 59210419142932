<template>
  <ul class="task-body__list">
    <li class="task-body__item" :style="'z-index: 3'">
      <TypeTask
        v-model="priority"
        :list="setPriorityList"
        :isEdit="isEdit && !isSubTask"
        @input="editPriority"
      />
    </li>
    <li class="task-body__item" :style="'z-index: 2'">
      <TypeTask
        v-model="type"
        :list="setTypeList"
        :isEdit="isEdit && !isSubTask"
        @input="editType"
      />
    </li>
    <li class="task-body__item" :style="'z-index: 1'">
      <TypeTask
        v-model="setStatus"
        :list="setToStatuses"
        :isEdit="isEdit && setToStatuses.length"
        @input="editStatus"
      />
    </li>
  </ul>
</template>

<script>
import TypeTask from "@/modules/UiKit/components/TypeTask/TypeTask";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";

export default {
  name: "TaskStatuses",
  mixins: [],
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    setStatus: {
      type: Object,
      default: () => {}
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TypeTask
  },
  data() {
    return {
      type: null,
      priority: null,
      status: null
    };
  },
  mounted() {
    if (this.task && this.setPriorityList && this.setStatusesList && this.setTypeList) {
      this.type = this.setType;
      this.priority = this.setPriority;
      this.status = this.setStatus;
    }
  },
  methods: {
    editPriority(val) {
      if (val.id !== this.task.priority_id) {
        this.$emit("handleEditDone", {
          priority_id: val.id
        });
      }
    },
    editType(val) {
      if (val.id !== this.task.type_id) {
        this.$emit("handleEditDone", {
          type_id: val.id
        });
      }
    },
    editStatus(val) {
      if (val.id !== this.task.status_id) {
        if (val.is_end) {
          this.status = this.setStatus;
          this.$emit("completeTask");
        } else {
          this.status = val;
          this.$emit("handleEditDone", {
            status_id: val.id
          });
        }
      }
    }
  },
  computed: {
    isSubTask() {
      return TaskModule.currentTask.parent_id;
    },
    setPriorityList() {
      return TaskModule.prioritiesList.length
        ? TaskModule.prioritiesList.map((item) => {
            return { ...item, type: "priority" };
          })
        : [];
    },
    setPriority() {
      return this.setPriorityList.length
        ? this.setPriorityList.find((item) => item.id === this.task.priority_id)
        : [];
    },
    setType() {
      let type =
        this.setTypeList.length && this.setTypeList.find((item) => item.id === this.task.type_id);
      if (this.task.parent_id) {
        type = {
          id: null,
          name: "Подзадача",
          type: "type"
        };
      } else {
        type = {
          id: type.id,
          name: type.name,
          type: "type"
        };
      }
      return type;
    },
    setTypeList() {
      return TaskModule.typesList.length
        ? TaskModule.typesList.map((item) => {
            return { ...item, type: "type" };
          })
        : [];
    },
    setStatusesList() {
      return TaskModule.statusesList.length
        ? TaskModule.statusesList.map((item) => {
            return { ...item, type: "status" };
          })
        : [];
    },
    setToStatuses() {
      const ids =
        this.setStatus && this.setStatus.to_statuses
          ? this.status.to_statuses
          : this.setStatusesList.length
          ? this.setStatusesList
              .filter((item) => item.id !== this.task.status_id)
              .map((item) => item.id)
          : [];
      let toStatuses = [];
      this.setStatusesList.map((item) => {
        ids.forEach((id) => {
          if (id === item.id) {
            toStatuses.push(item);
          }
        });
      });
      return toStatuses;
    }
  },
  watch: {
    setType() {
      this.type = this.setType;
    },
    setPriority() {
      this.priority = this.setPriority;
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.task-body {
  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-right: 8px;

    @media (max-width: $sm) {
      margin: 0 8px 16px 0;
    }
  }
}
</style>
