<template>
  <div class="radio-buttons">
    <div
      @click="$emit('selectRadioValue', item.value)"
      class="radio-buttons__item"
      v-for="item in items"
      :key="item.value"
    >
      <div :class="item.value === value ? 'radio-btn_selected' : 'radio-btn'" id="radio-btn">
        <i class="icon-check-mark"></i>
      </div>
      <label for="radio-btn">{{ item.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButtons",
  mixins: [],
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: [String, Number],
      default: () => {
        return "";
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    cursor: pointer;
    i {
      padding-top: 1px;
      display: none;
      font-size: 8px;
      color: white;
    }
    .radio-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #d3d2e0;
      margin-right: 9px;
      flex-shrink: 0;
      &_selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 9px;
        border: 2px solid #6a67ce;
        background: #6a67ce;
        flex-shrink: 0;
        i {
          display: block;
        }
      }
    }
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #343748;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
