<template>
  <div class="kanban-setting">
    <KanbanSettingHeader />
    <KanbanSettingBody :columns="currentBoardColumns" />
  </div>
</template>

<script>
import KanbanSettingHeader from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanSettingHeader";
import KanbanSettingBody from "@/modules/TaskManager/components/Kanban/KanbanSetting/KanbanSettingBody";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "KanbanSetting",
  mixins: [],
  props: {},
  components: { KanbanSettingBody, KanbanSettingHeader },
  data() {
    return {};
  },
  mounted() {
    this.getColumns();
  },
  methods: {
    getColumns() {
      KanbanModule.getBoardAction({ project_id: this.$route.params.id });
    }
  },
  computed: {
    currentBoardColumns() {
      return KanbanModule.currentBoardColumns;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.kanban-setting {
  width: calc(100% - 355px);

  @media (max-width: 1740px) {
    width: calc(100% - 270px);
  }

  @media (max-width: 1400px) {
    width: 100%;
  }
}
</style>
