import * as types from "@/modules/Calendar/store/mutations/mutationTypes";

import { domain } from "@/globalVariables";
import router from "@/router";
import NotificationObserver from "@/helpers/UserNotification";
import ErrorObserver from "@/api/Error";
import httpClient from "@/api/client/httpClient";

const location = {
  getCalendarLocation({ commit }) {
    httpClient({
      method: "GET",
      url: `/CalendarLocation/actionGetItems`,
      params: {
        _count: 300
      }
    }).then((response) => {
      commit(types.GET_LOCATION_LIST, response.data.data.items);
    });
  },
  /**
   * create or edit location function
   * @param {object} payload - location params
   */
  createAndEditLocation({ state, dispatch, commit }, payload) {
    httpClient({
      url: `/CalendarLocation/${state.location ? `actionUpdate/${payload.id}` : "actionCreate"}`,
      method: state.location ? "PUT" : "POST",
      data: [payload]
    })
      .then(() => {
        dispatch("getCalendarLocation");
        commit(types.SHOW_LOCATION_MODAL, false);
        NotificationObserver.notification({
          type: "success",
          message: state.location ? "Кабинет успешно отредактирован!" : "Кабинет успешно создан!"
        });
      })
      .catch((error) => ErrorObserver.notification(error));
  },
  /**
   * delete location function
   * @param {number} id - location id
   */
  deleteLocation({ dispatch, state, commit }, payload) {
    httpClient({
      url: `/CalendarLocation/actionDelete/${payload}`,
      method: "DELETE"
    })
      .then(() => {
        dispatch("getCalendarLocation");
        commit(types.SHOW_CONFIRM_MODALS, false);
        NotificationObserver.notification({
          type: "success",
          message: "Место проведение успешно удалено!"
        });
      })
      .catch((error) => ErrorObserver.notification(error));
  }
};
export default location;
