<template>
  <ul class="feedback">
    <li
      class="feedback__item"
      :class="{ feedback__item_active: isClosed }"
      @click="
        $root.$emit('openModalBugsAndIdea', {
          title: 'Предложить идею',
          label: 'Идея',
          placeholder: 'Опишите идею',
          type: 'Идея'
        })
      "
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 2V13H10V22L17 10H13L17 2H7Z" fill="#E7E9ED" />
      </svg>

      <p>Предложить идею</p>
    </li>
    <li
      class="feedback__item"
      :class="{ feedback__item_active: isClosed }"
      @click="
        $root.$emit('openModalBugsAndIdea', {
          title: 'Сообщить о проблеме',
          label: 'Проблема',
          placeholder: 'Опишите проблему',
          type: 'Баг'
        })
      "
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 21H23L12 2L1 21Z" fill="#E7E9ED" />
        <path d="M13 14H11V8H13V14Z" fill="#B0B7C3" />
        <path d="M13 18H11V16H13V18Z" fill="#B0B7C3" />
      </svg>

      <p>Сообщить о проблеме</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FeedbackPanelNew",
  components: {},
  props: {
    isClosed: Boolean
  },
  data: () => ({
    isShowModal: false,
    dataModal: Object
  })
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.feedback {
  display: flex;
  flex-flow: column;
  padding: 0;
  list-style: none;
  &__item {
    display: flex;
    margin-left: 19px;
    padding-left: 12px;
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.47, 0, 0.69, 0.94);

    &:hover {
      p {
        color: $text-highlight;
      }
    }
    &:active {
      p {
        color: $action-primary-pressed;
      }
    }
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 0;
      transform: translateX(0);
      transition: all 0.1s cubic-bezier(0.47, 0, 0.69, 0.94);
    }
    p {
      width: 0;

      transition: all 0.1s cubic-bezier(0.47, 0, 0.69, 0.94);
      opacity: 0;
      transform: scale(0);

      font-size: 14px;
      line-height: 29px;
      font-weight: 500;
      color: #8a94a6;
      white-space: nowrap;
    }
    &_active {
      svg {
        transform: translateX(5px);
        transition: all 0.1s cubic-bezier(0.47, 0, 0.69, 0.94);
        margin-right: 16px;
      }
      p {
        width: auto;
        height: auto;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
</style>
