<template>
  <div class="calendar-mobile" v-click-outside="hideCalendar">
    <div class="calendar-mobile__control-panel">
      <button class="calendar-mobile__button" v-if="isShowDoubleArrows" @click="handleDoubleLeft">
        <i class="icon-double-left"></i>
      </button>
      <button class="calendar-mobile__button" v-if="isShowArrows" @click="handleLeft">
        <i class="icon-left"></i>
      </button>
      <button class="calendar-mobile__button calendar-mobile__button_today" @click="switchCalendar">
        {{ setCurrentMonth }}
      </button>
      <button class="calendar-mobile__button" v-if="isShowArrows" @click="handleRight">
        <i class="icon-right"></i>
      </button>
      <button class="calendar-mobile__button" v-if="isShowDoubleArrows" @click="handleDoubleRight">
        <i class="icon-double-right"></i>
      </button>
      <div class="calendar-mobile__widget">
        <!--        <button-->
        <!--          class="calendar-mobile__button calendar-mobile__button_month"-->
        <!--          @click="switchCalendar"-->
        <!--        >-->
        <!--          {{ setCurrentMonth }}-->
        <!--          <i class="icon-down" :style="showCalendar ? 'transform: rotate(180deg)' : ''"></i>-->
        <!--        </button>-->
        <div
          class="calendar-mobile__container"
          v-show="showCalendar"
          @click.self="showCalendar = false"
        >
          <div class="calendar-mobile__wrap">
            <div v-if="mobileText" class="calendar-mobile__text">
              <i class="icon-calendar-secondary"></i>
              <span> {{ mobileText }} </span>
            </div>
            <v-calendar
              title-position="center"
              class="calendar__custom"
              :masks="masks"
              ref="calendar"
              id="calendar"
              popover.visibility="false"
              :attributes="attributes"
              @update:to-page="getTimeInfo"
              title-transition
              is-expanded
            >
              <div slot="header-title" slot-scope="{ monthLabel, yearLabel }">
                <span class="month">{{ upperFirstChar(monthLabel) + ", " + yearLabel }}</span>
              </div>
              <div slot="weekdays" slot-scope="{ day }">{{ day.week }}</div>
              <div
                slot="day-content"
                slot-scope="{ day, attributes }"
                @click="clickSelectDate(day)"
              >
                <div class="vc-h-full">
                  <span
                    :class="[
                      'vc-day-content',
                      attributes.length ? (day.id === getToday ? 'today' : '') : '',
                      setClassDependingAttributes(attributes),
                      setClassDependingDay(attributes, day, getToday)
                    ]"
                    >{{ day.day }}</span
                  >
                </div>
              </div>
            </v-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default {
  name: "DateFilter",
  mixins: [],
  props: {
    isFullName: {
      type: Boolean,
      default: true
    },
    isShowArrows: {
      type: Boolean,
      default: true
    },
    isShowDoubleArrows: {
      type: Boolean,
      default: true
    },
    mobileText: {
      type: String,
      default: ""
    },
    currentDate: {
      type: String,
      default: ""
    },
    weekNumber: {
      type: Number,
      default: moment(new Date()).isoWeek()
    },
    year: {
      type: Number,
      default: new Date().getFullYear()
    },
    calendarParams: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      masks: {
        weekdays: "WWW"
      },
      value: moment().format("YYYY-MM-DD"),
      attributes: [],
      showCalendar: false,
      currentMonth: null,
      monthNumber: new Date().getMonth(),
      date: moment(new Date()).format("YYYY-MM-DD"),
      lastYear: new Date().getFullYear(),
      weekCount: moment().weeksInYear(),
      month: [
        {
          full: "Январь",
          val: "Янв"
        },
        {
          full: "Февраль",
          val: "Фев"
        },
        {
          full: "Март",
          val: "Март"
        },
        {
          full: "Апрель",
          val: "Апр"
        },
        {
          full: "Май",
          val: "Май"
        },
        {
          full: "Июнь",
          val: "Июнь"
        },
        {
          full: "Июль",
          val: "Июль"
        },
        {
          full: "Август",
          val: "Авг"
        },
        {
          full: "Сентябрь",
          val: "Сен"
        },
        {
          full: "Октябрь",
          val: "Окт"
        },
        {
          full: "Ноябрь",
          val: "Ноя"
        },
        {
          full: "Декабрь",
          val: "Дек"
        }
      ]
    };
  },
  mounted() {
    if (this.currentDate.length) {
      this.value = this.currentDate;
    }
  },
  methods: {
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    moveCalendar(val) {
      this.monthNumber = new Date(val).getMonth();
      const calendar = this.$refs.calendar;
      calendar.move(new Date(this.value));
    },
    handleDoubleLeft() {
      const day = moment(this.value).subtract(1, "week").format("YYYY-MM-DD");
      this.handleCalendarDay(day);
    },
    handleLeft() {
      const day = moment(this.value).subtract(1, "days").format("YYYY-MM-DD");
      this.handleCalendarDay(day);
    },
    handleToday() {
      if (this.weekNumber !== moment(new Date()).isoWeek()) {
        const day = moment(new Date());
        this.handleCalendarDay(day);
        this.value = moment(day).format("YYYY-MM-DD");
      }
    },
    handleRight() {
      const day = moment(this.value).add(1, "days").format("YYYY-MM-DD");
      this.handleCalendarDay(day);
    },
    handleDoubleRight() {
      const day = moment(this.value).add(1, "week").format("YYYY-MM-DD");
      this.handleCalendarDay(day);
    },
    handleCalendarDay(day) {
      if (this.value !== day) {
        this.value = day;
        this.$emit("getCalendar", this.value);
        const weekNumber = moment(new Date(day)).isoWeek();
        if (this.weekNumber !== weekNumber) {
          const year = moment(day).year();
          if (moment(day) < moment(`${year}-01-07`) && weekNumber > 50) {
            if (this.year <= year) {
              this.$emit("setYears", year - 1);
            }
            // this.$emit("getCalendar", this.value);
            this.$emit("setWeekNumber", weekNumber);
            this.monthNumber = moment().week(this.weekNumber).month();
          } else {
            // this.$emit("getCalendar", this.value);
            this.$emit("setWeekNumber", weekNumber);
            this.monthNumber = moment().week(this.weekNumber).month();
            this.$emit("setYears", new Date(this.value).getFullYear());
          }
        }
      }

      this.moveCalendar(day);
    },
    /**
     * Запоминание выбранного дня и подставновка текущего рабочего времени для этого дня
     */
    clickSelectDate(day) {
      this.monthNumber = format(parseISO(day.id), "M") - 1;
      this.handleCalendarDay(day.id);
      this.hideCalendar();
    },
    /**
     * Получение данных по открытому в календаре месяцу
     */
    getTimeInfo(data) {
      this.$emit("getTimeInfo", data);
    },
    hideCalendar() {
      this.showCalendar = false;
    },
    upperFirstChar(string) {
      let temp = string[0].toUpperCase();
      return (string = temp + string.slice(1));
    },
    setClassDependingAttributes(attributes) {
      if (attributes.length) {
        if (attributes[0].customData.hours === String(0)) {
          return "output";
        }
        if (
          attributes[0].customData.hours < String(8) &&
          attributes[0].customData.hours > String(0)
        ) {
          return "shortcut";
        }
      }
    },
    setClassDependingDay(attributes, day) {
      if (this.value === day.id) {
        return "current";
      }
    }
  },
  computed: {
    getToday() {
      return moment().format("YYYY-MM-DD");
    },
    setTodayButton() {
      return this.getToday ? moment().format("DD.MM.YYYY") : "";
    },
    setCurrentMonth() {
      return moment(this.value).format("DD.MM.YYYY");
    }
  },
  watch: {
    currentDate(val) {
      this.value = val;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/breakpoints/breakpoints.scss";
@import "~@/assets/base/color/colors.scss";

.calendar-mobile {
  display: flex;
  align-items: center;
  max-width: 100%;

  &__control-panel {
    display: flex;
    width: 100%;

    button {
      width: 28px;
      height: 28px;
      border: none;
      background: $surface-default;
      border-radius: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $icon-subdued;
      outline: none;

      @media (hover: hover) {
        &:hover {
          background-color: $surface-selected-default;
        }
      }

      &:active {
        background-color: $surface-selected-default;
      }

      @media (max-width: $xs) {
        width: 22px;
      }
      @media (max-width: $xxs) {
        width: 20px;
      }
    }

    .calendar-mobile__button {
      &_today,
      &_month {
        //width: max-content;
        border-radius: 2px;
        padding: 4px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-default;
        width: 85px;
      }

      &_month {
        margin-left: auto;

        i {
          margin-left: 8px;
          color: $icon-subdued;
        }
      }

      @media (max-width: $xxs) {
        font-size: 12px;
      }
    }
  }

  &__widget {
    position: relative;
    margin-left: auto;
  }

  &__wrap {
    @media (max-width: $sm), (max-height: 420px) {
      background-color: #fff;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  &__container {
    position: absolute;
    top: 28px;
    left: 0;
    @media (max-width: $sm), (max-height: 420px) {
      position: fixed;
      display: flex;
      flex-flow: column;
      //align-items: flex-end;
      justify-content: flex-end;
      top: auto;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      background: $background-modal;
      border-radius: 0;
      z-index: 10000000;
    }
  }

  &__text {
    display: none;
    @media (max-width: $sm), (max-height: 420px) {
      .icon-calendar-secondary {
        color: $icon-subdued;
        font-size: 20px;
        margin-right: 16px;
      }
      display: flex;
      width: 100%;
      background-color: #fff;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 18px 8px;
      font-size: 16px;
      line-height: 20px;
      color: $text-default;
      font-weight: 500;
    }
  }

  /deep/ {
    .vc-container {
      border: 1px solid #eae8f0;
      border-radius: 0;
      padding: 16px 16px 0;
      z-index: 10;

      .vc-arrows-container {
        padding: 0;
        margin: 0 0 24px;
      }

      .vc-arrow {
        height: 21px;
      }

      .vc-svg-icon {
        width: 21px;
        height: 21px;

        path {
          fill: $icon-subdued;
        }
      }

      .vc-header {
        padding: 0;
        margin: 0 0 24px;
        @media screen and (max-width: 850px) and (orientation: landscape) {
          margin: 0;
        }
      }

      .month {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .vc-weekday {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }

      .weekday-1 .vc-day-content,
      .weekday-7 .vc-day-content {
        color: $text-disabled;
      }

      .vc-day-content {
        width: 40px;
        height: 40px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #000000;

        @media (max-width: $sm), (max-height: 530px) {
          width: 20px;
          height: 20px;
        }

        &:hover,
        &:focus {
          width: 40px;
          height: 40px;
          background: #5551f9 !important;
          border-radius: 4px !important;
          color: #ffffff !important;
        }
      }

      .vc-highlight.vc-highlight-base-middle {
        width: 40px;
        height: 40px;
        background: #fafafe !important;
      }

      .vc-highlight,
      .vc-highlight.vc-highlight-base-start,
      .vc-highlight.vc-highlight-base-end {
        width: 40px;
        height: 40px;
        background: #5551f9 !important;
        border-radius: 4px !important;
      }

      .vc-day.is-not-in-month * {
        opacity: 1;
        color: #747474;
      }
    }
  }

  .current {
    width: 40px;
    height: 40px;
    background: #5551f9 !important;
    border-radius: 4px !important;
    color: #ffffff !important;
  }
}
</style>
