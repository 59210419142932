<template>
  <section class="task-manager">
    <ConfirmationModal v-if="isShowConfirmModal" />
    <transition name="sidebar">
      <TaskManagerSidebar />
    </transition>
    <router-view />
    <transition name="slide">
      <CurtainShowAndEditProject v-if="showCurtainShowAndEditProject" />
      <CurtainShowAndEditStage v-if="showCurtainShowAndEditStage" />
      <CurtainShowAndEditTask v-if="showCurtainShowAndEditTask" />
    </transition>
    <transition name="slide">
      <CurtainConstructor v-if="showCurtainConstructor" />
      <BlackSphereModal v-if="showBlackSphereModal" :isTask="true" />
    </transition>
    <TaskFileModal v-if="showModalFileUpload" />
    <Button
      v-if="$route.name !== 'Task'"
      icon="plus"
      type="round"
      class="task-manager__create"
      @handleButton="openCurtain"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import TaskManagerSidebar from "@/modules/TaskManager/components/TaskManagerSidebar";
import CurtainConstructor from "@/modules/TaskManager/components/MyProjects/CurtainConstructor/CurtainConstructor";
import Button from "@/modules/UiKit/components/buttons/Button";
import CurtainShowAndEditProject from "@/modules/TaskManager/components/MyProjects/ShowAndEditProject/CurtainShowAndEditProject";
import CurtainShowAndEditTask from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/CurtainShowAndEditTask";
import CurtainShowAndEditStage from "@/modules/TaskManager/components/MyProjects/ShowAndEditStage/CurtainShowAndEditStage";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import ConfirmationModal from "@/modules/TaskManager/components/Modal/ConfirmationModal";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import TaskFileModal from "@/modules/TaskManager/components/Modal/TaskFileModal/TaskFileModal";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import BlackSphereModal from "@/modules/TaskManager/components/Modal/BlackSphereModal/BlackSphereModal";

export default {
  name: "TaskManagerView",
  mixins: [],
  props: [],
  components: {
    BlackSphereModal,
    TaskFileModal,
    ConfirmationModal,
    CurtainShowAndEditStage,
    CurtainConstructor,
    TaskManagerSidebar,
    Button,
    CurtainShowAndEditProject,
    CurtainShowAndEditTask
  },
  data() {
    return {};
  },
  mounted() {
    TaskModule.getPrioritiesListAction();
    TaskModule.getTypesListAction();
    TaskModule.getStatusesListAction({
      project_id: null
    });
    ProjectModule.getProjectTree();
  },
  methods: {
    openCurtain() {
      TaskManagerModuleV2.openCurtainConstructor();
    }
  },
  computed: {
    ...mapState("TaskManagerModule", ["showCurtainShowAndEditTask"]),
    showModalFileUpload() {
      return taskModule.showModalFileUpload;
    },
    showBlackSphereModal() {
      return TaskManagerModuleV2.showBlackSphereModal;
    },
    showCurtainConstructor() {
      return TaskManagerModuleV2.showCurtainConstructor;
    },
    showCurtainShowAndEditTask() {
      return TaskManagerModuleV2.showCurtainShowAndEditTask;
    },
    showCurtainShowAndEditStage() {
      return StageModule.showCurtainShowAndEditStage;
    },
    isShowConfirmModal() {
      return TaskManagerModuleV2.showConfirmationModal;
    },
    showCurtainShowAndEditProject() {
      return ProjectModule.showCurtainShowAndEditProject;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}

.sidebar-leave-active,
.sidebar-enter-active {
  @media (max-width: $xxl) {
    transition: 0.5s;
  }
}

.sidebar-enter {
  @media (max-width: $xxl) {
    transform: translate(-100%, 0);
  }
}

.sidebar-leave-to {
  @media (max-width: $xxl) {
    transform: translate(-100%, 0);
  }
}

.task-manager {
  display: flex;
  width: 100%;
  height: 100%;
  background: $surface-default;
  position: relative;
  overflow-y: hidden;

  /deep/ *:not(i) {
    font-family: "Roboto", sans-serif !important;
  }

  &__create {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 60;
    width: 62px;
    height: 62px;
    @media (max-width: $md) {
      bottom: 80px;
    }
  }
}
</style>
