<template>
  <component :is="component" v-if="component" v-bind="{ ...$props, ...$attrs }" />
</template>

<script>
import { debug } from "@/main";

export default {
  name: "Widget",
  inheritAttrs: false,
  props: ["type"],
  data() {
    return {
      component: null
    };
  },
  computed: {
    componentLoader() {
      if (this.type === "field") {
        this.type =
          this.$store.state.metaProjectData["monolit"][this.$route.params.model]["fields"][
            this.$attrs.keyAdd
          ].type;
      }
      if (!this.type) {
        return null;
      } else if (this.type === "bool") {
        return () => import(`@/components/Widgets/CheckBox/CheckBox`);
      } else if (this.type === "text") {
        return () => import(`@/components/Widgets/Textarea/Textarea`);
      } else if (this.type === "ActionList") {
        return () => import(`@/components/Widgets/ActionList/ActionList`);
      } else if (this.type === "input") {
        return () => import(`@/components/Widgets/Inputs/Input`);
      } else if (this.type === "input-new") {
        return () => import(`@/components/Widgets/Inputs/Input2.0`);
      } else if (this.type === "input-title") {
        return () => import(`@/components/Widgets/Inputs/InputTitle`);
      } else if (this.type === "input-main") {
        return () => import(`@/components/Widgets/Inputs/InputMain`);
      } else if (this.type === "string") {
        return () => import(`@/components/Widgets/InputText/InputText`);
      } else if (this.type === "radio") {
        return () => import(`@/components/Widgets/InputRadio/InputRadio`);
      } else if (this.type === "Button") {
        return () => import(`@/components/Widgets/Button/Button`);
      } else if (this.type === "mark") {
        // return () => import("../Widgets/MarkDown/MarkDown");
      } else if (this.type === "file-uploader") {
        return () => import("./FileUploader/FileUploader");
      } else if (
        this.type === "range-day" ||
        this.type === "range-month" ||
        this.type === "range-date-for-two-columns" ||
        this.type === "range-year" ||
        this.type === "month" ||
        this.type === "month_range" ||
        this.type === "range"
      ) {
        return () => import("./DateRangePicker/DateRangePicker.vue");
      } else if (this.type === "select-new") {
        return () => import("./Select/Select2.0/Select2.0");
      } else if (this.type === "select-filter") {
        return () => import("./Select/SelectFilter/SelectFilter");
      } else if (this.type === "user") {
        return () => import("./User/User");
      } else if (this.type === "select-redesign") {
        return () => import("./Select/Select2.1/Select2.1");
      } else if (this.type === "DefaultDatePicker") {
        return () => import("./DatePickers/DefaultDatePicker");
      } else if (this.type === "InputDatePicker") {
        return () => import("./DatePickers/InputDatePicker");
      } else if (this.type === "DefaultTimePicker") {
        return () => import("./TimePickers/DefaultTimePicker");
      } else if (this.type === "loader") {
        return () => import("./Loader/Loader");
      } else if (this.type === "loader-new") {
        return () => import("./Loader2.0/Loader");
      } else if (this.type === "tag") {
        return () => import("./Tags/Tags");
      } else if (this.type === "text-editor") {
        return () => import("./TextEditor/TextEditorWidget");
      } else if (this.type === "Switch") {
        return () => import("./SwitchWidget/SwitchWidget");
      } else if (this.type === "default-filter") {
        return () => import("./Filters/DefaultFilter");
      } else if (this.type === "bread-crumbs") {
        return () => import("./BreadCrumbs/BreadCrumbs");
      } else if (this.type === "links-widget") {
        return () => import("./LinksWidget/LinksWidget");
      } else if (this.type === "links-edit-widget") {
        return () => import("./LinksWidget/LinksEditWidget");
      } else if (this.type === "article") {
        return () => import("../KnowledgeBaseComponents/Article/ArticleComponent");
      } else if (
        this.type === "regulation" ||
        this.type === "guide" ||
        this.type === "instruction"
      ) {
        return () => import("../KnowledgeBaseComponents/Regulation/RegulationComponent");
      } else if (this.type === "book") {
        return () => import("../KnowledgeBaseComponents/Book/BookComponent");
      } else if (this.type === "loader-alternative") {
        return () => import("./Loader/LoaderAlternative");
      } else if (this.type === "modal-close-widget") {
        return () => import("./ModalComponents/ModalCloseWidget");
      } else if (this.type === "year-picker-widget") {
        return () => import("./DatePickers/YearPickerWidget");
      } else if (this.type === "new-time-picker") {
        return () => import("./DatePickers/NewTimePicker");
      } else if (this.type === "tabs-widget") {
        return () => import("./TabsWidget/TabsWidget");
      } else if (this.type === "tabs-widget-new") {
        return () => import("./TabsWidget/TabsWidget2.0");
      } else if (this.type === "multi-bool") {
        return () => import("./MultiCheckbox/MultiCheckbox");
      } else {
        return null;
      }
      // return () => import(`../Widgets/${this.type}/${this.type}.vue`)
    }
  },
  mounted() {
    if (this.componentLoader) {
      this.component = () => this.componentLoader();
    } else {
      if (this.type === undefined) {
        return null;
      }
      if (debug) {
        this.messageAnyType(`Виджет с типом: ${this.type} не найден`, "error");
      }
    }
  },
  methods: {
    $emits(data) {}
  }
};
</script>
