<template>
  <div class="chat-container">
    <div class="chat-menu">
      <div class="chat-menu__avatar-wrap">
        <div class="chat-menu__avatar" v-if="currentChat.channel.type === 3">
          <span class="chat-menu__logo" :style="{ backgroundColor: '#504DA8' }">
            <span><i class="icon-favorites"></i></span>
          </span>
          <span class="chat-menu__text">Избранное</span>
        </div>
        <i
          class="chat-menu__hide-btn icon-arrow-short"
          v-if="isMobileWindowSize"
          @click="handleOpenMenu"
        ></i>
        <router-link
          :to="{ name: 'UserInfo', params: { user_id: currentChat.channel.companion.id } }"
          v-if="currentChat.channel.type === 1"
        >
          <Avatar
            :user="setChannel"
            :isGroup="isGroup"
            :showInfo="true"
            :typeChannel="currentChat.channel.type"
          />
        </router-link>
        <Avatar
          :user="setChannel"
          :isGroup="isGroup"
          :showInfo="true"
          :typeChannel="currentChat.channel.type"
          v-if="currentChat.channel.type === 2"
        />
      </div>
      <p class="chat-menu__descr" v-if="showDescr">
        {{ currentChat.channel.chat_settings.description }}
      </p>
      <div class="chat-menu__wrap">
        <MenuList
          :menuList="setMenu"
          v-if="currentChat.channel.files"
          @changeShowAttachModal="changeShowAttachModal"
        />
        <div class="chat-menu__switch" v-if="currentChat.channel.type !== 3">
          <SwitchComponent
            :label="'Оповещения'"
            :icon="'icon-bell'"
            @changeSwitch="changeSwitch"
            :value="currentChat.channel.notification_enabled"
          />
        </div>
      </div>
    </div>
    <AttachedModal
      @changeShowAttachModal="changeShowAttachModal"
      :showAttachModal="showAttachModal || showComment || showReactionList"
    />
  </div>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import SwitchComponent from "@/modules/Messenger/components/Inputs/Switch";
import MenuList from "@/modules/Messenger/components/ChatMenu/MenuList";
import { mapActions, mapState } from "vuex";
import AttachedModal from "@/modules/Messenger/components/ChatModal/AttachedModal/AttachedModal";
import ClickOutside from "vue-click-outside";

export default {
  name: "ChatMenu",
  mixins: [],
  props: {},
  components: { AttachedModal, MenuList, SwitchComponent, Avatar },
  data() {
    return {
      menuList: [
        {
          name: "Фотографии",
          icon: "icon-photo-library",
          val: "photo",
          component: "PhotosContent",
          count: 0
        },
        {
          name: "Видеофайлы",
          icon: "icon-video-library",
          val: "video",
          component: "VideosContent",
          count: 0
        },
        {
          name: "Прикрепленные файлы",
          icon: "icon-paper-clip-secondary",
          val: "other",
          component: "OthersContent",
          count: 0
        },
        { name: "Ссылки", icon: "icon-chain", val: "link", component: "LinksContent", count: 0 }
        // { name: "Gif:", icon: "icon-gif", val: "gif", component: "PhotosContent", count: 0 }
      ],
      showAttachModal: false,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
  methods: {
    ...mapActions("MessengerModule", ["toggleChatNotification", "switchChatMenu"]),
    closeOpenMenu() {
      this.switchChatMenu(false);
    },
    handleOpenMenu() {
      this.switchChatMenu();
    },
    changeSwitch() {
      this.toggleChatNotification();
    },
    changeShowAttachModal(val) {
      this.showAttachModal = val;
    },
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapState("MessengerModule", [
      "showMenu",
      "users",
      "currentChat",
      "showComment",
      "showReactionList"
    ]),
    isMobileWindowSize() {
      return this.windowWidth <= 375;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    isGroup() {
      return this.currentChat.channel.type === 2;
    },
    showDescr() {
      return (
        this.currentChat.channel.type === 2 && this.currentChat.channel?.chat_settings?.description
      );
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },

    setChannel() {
      if (this.isGroup) {
        return {
          avatar: this.currentChat.channel.chat_settings.avatar,
          color: this.currentChat.channel.chat_settings.color,
          nickname: this.currentChat.channel.chat_settings.tag,
          full_name: this.currentChat.channel.chat_settings.name
        };
      } else {
        return this.currentChat.users.find((item) => item.id !== this.getUserId);
      }
    },
    setMenu() {
      let menu = this.menuList.map((item) => {
        if (!Array.isArray(this.currentChat.channel.files)) {
          if (this.currentChat.channel.files[item.val]) {
            item.count = this.currentChat.channel.files[item.val];
          } else if (item.val === "link") {
            item.count = 9;
          } else {
            item.count = 0;
          }
          return item;
        } else {
          item.count = 0;
          return item;
        }
      });
      if (this.isGroup) {
        menu.push({
          name: "Участники",
          icon: "icon-people",
          val: "members",
          component: "MembersContent",
          count: this.users.length
        });
      }
      return menu;
    }
  },
  watch: {
    currentChat() {
      this.showAttachModal = false;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.chat-menu {
  position: relative;
  width: 344px;
  height: 100%;
  flex-shrink: 0;
  background-color: #fff;
  overflow: auto;
  border-left: 1px solid #eae8f0;
  padding: 24px;
  @media screen and (max-width: $xxxl) {
    max-width: 260px;
  }
  @media screen and (max-width: $lg) {
    width: 100%;
    max-width: 375px;
    height: 100%;
    position: absolute;
    right: 0;
    padding: 24px 24px 90px;
    z-index: 10;
  }
  @media screen and (max-width: $xs) {
    height: 100%;
    top: 0;
    padding: 24px;
  }
  /deep/ {
    .avatar {
      &__img {
        border: 1px solid #fafafe;
      }
      &__name {
        max-width: 220px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #343748;
        @media screen and (max-width: $xxxl) {
          max-width: 140px;
        }
        @media screen and (max-width: $lg) {
          max-width: 220px;
        }
        @media screen and (max-width: $xs) {
          max-width: 140px;
        }
      }
      &__role {
        max-width: 220px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @media screen and (max-width: $xxxl) {
          max-width: 140px;
        }
        @media screen and (max-width: $lg) {
          max-width: 220px;
        }
        @media screen and (max-width: $xs) {
          max-width: 140px;
        }
      }
      &__tag {
        max-width: 220px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #a7a9c0;
        @media screen and (max-width: $xxxl) {
          max-width: 140px;
        }
        @media screen and (max-width: $lg) {
          max-width: 220px;
        }
        @media screen and (max-width: $xs) {
          max-width: 140px;
        }
      }
      &__wrap_text {
        max-width: calc(100% - 50px);
      }
    }
    .menu-item {
      i {
        font-size: 20px;
        color: #a7a9c0;
      }
      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #343748;
      }
    }
    .switch-widget {
      i {
        font-size: 20px;
        color: #a7a9c0;
      }
      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #343748;
      }
    }
  }
  &__hide-btn {
    margin-right: 16px;
    transform: rotate(-90deg);
    font-size: 18px !important;
    cursor: pointer;
  }

  &__avatar-wrap {
    display: flex;
    align-items: flex-start;
    i {
      margin-top: 10px;
      cursor: pointer;
      font-size: 24px;
      color: $icon-subdued;
      border-radius: 4px;
      transition: all 0.3s ease;
      @media (hover: hover) {
        &:hover {
          background-color: $background-default;
          color: $text-subdued;
        }
      }
      &_active {
        background-color: $background-default;
        color: $text-subdued;
      }
    }
  }
  &__descr {
    padding: 0;
    margin: 24px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #787996;
    word-break: break-word;
    white-space: pre-wrap;
  }
  &__visits {
    padding: 0;
    margin: 0 0 0 48px;
    font-size: 15px;
    line-height: 20px;
    color: $text-disabled;
  }
  &__avatar {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .icon-favorites {
    color: #fff;
    font-size: 16px;
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $text-light;
    line-height: 40px;
    background-color: $message;
    margin-right: 8px;
  }
  &__text {
  }
  &__notices {
    margin-top: 45px;
    display: flex;
    align-items: center;
  }
  &__switch {
    display: flex;
    align-items: center;
  }
}
</style>
