

















































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput.vue";
import MainTabs from "@/aopV2/layout/LayoutMain/MainTabs/MainTabs.vue";
import SelectList from "@/aopV2/components/UIComponents/SelectList/SelectList.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";

@Component({
  components: {MainTabs, TextInput, SelectList}
})
export default class MemberPicker extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  selectedMember!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  selectedGroup!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  members!: Array<any>;
  @Prop({
    default: () => [],
    type: Array
  })
  groups!: Array<any>;
  tabs: Array<{ name: string; route: string }> = [
    {name: "Участники", route: "Участники"},
    {
      name: "Группы",
      route: "Группы"
    }
  ];
  activeTabs: string = "Участники";
  isShowToggle: boolean = false;

  toggleList() {
    this.isShowToggle = !this.isShowToggle;
  }

  closePicker() {
    this.isShowToggle = false;
  }

  handleChangeGroup(groups) {
    const deletedGroup = this.selectedGroup.find(
      (item) => groups.findIndex((group) => item.id === group.id) === -1
    );
    this.$emit("changeGroups", groups);
  }


  changeMembers(members) {
    this.$emit("changeMembers", members);
  }

  handleTab(tab) {
    this.activeTabs = tab.name;
  }

  declOfNum(n, titles) {
    return titles[
      n % 10 == 1 && n % 100 != 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? 1
          : 2
      ];
  }

  get name() {
    if (this.selectedMember.length && this.selectedGroup.length) {
      return `${this.selectedMember.length} ${this.declOfNum(this.selectedMember.length, [
        "участник",
        "участника",
        "участников"
      ])} / ${this.selectedGroup.length} ${this.declOfNum(this.selectedGroup.length, [
        "группа",
        "группы",
        "групп"
      ])}`;
    } else {
      if (this.selectedMember.length) {
        if (this.selectedMember.length === 1) {
          return this.selectedMember[0].full_name;
        } else {
          return `${this.selectedMember.length} ${this.declOfNum(this.selectedMember.length, [
            "участник",
            "участника",
            "участников"
          ])}`;
        }
      }
      if (this.selectedGroup.length) {
        if (this.selectedGroup.length === 1) {
          return this.selectedGroup[0].name;
        } else {
          return `${this.selectedGroup.length} ${this.declOfNum(this.selectedGroup.length, [
            "группа",
            "группы",
            "групп"
          ])}`;
        }
      }
      return "";
    }
  }

  get isActiveMember() {
    return this.activeTabs === "Участники";
  }
}
