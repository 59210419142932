import actions from "@/modules/News/store/actions/action";
import mutations from "@/modules/News/store/mutations/mutations";
import getters from "@/modules/News/store/getters/getters";
import state from "@/modules/News/store/state/state";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
