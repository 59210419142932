





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import EventCardMonth from "@/aopV2/modules/Calendar/components/CalendarEventCard/EventCardMonth.vue";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import moment from "moment";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon.vue";
import setListPosition from "@/aopV2/helpers/OptionListPosition";

@Component({
  components: { ButtonIcon, EventCardMonth }
})
export default class CalendarEventList extends Vue {
  isFull: boolean = false;
  container: null | HTMLBaseElement = null;

  mounted() {
    this.container = document.querySelector(".calendar");
    setListPosition(this.container, this.$el, this.setting.x, this.setting.y);
    this.isFull = this.getWidthWindow < 576;
  }

  closeModal() {
    CalendarModule2.toggleEventList(null);
  }

  openFull() {
    this.isFull = !this.isFull;
  }

  get setting() {
    return CalendarModule2.eventListSetting
      ? CalendarModule2.eventListSetting
      : {
          x: 0,
          y: 0,
          events: [],
          isShow: false,
          day: dateFormat(new Date())
        };
  }

  get events() {
    return this.setting.events;
  }

  get icon() {
    return !this.isFull ? "arrow-resize" : "close-fullscreen";
  }

  get day() {
    return moment(this.setting.day).format("DD MMMM yyyy");
  }

  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get isMobileWindowSize() {
    return this.getWidthWindow < 576;
  }

  resizeHandler() {
    this.isFull = this.getWidthWindow < 576;
  }

  @Watch("getWidthWindow") onChangeWidthWindow() {
    this.resizeHandler();
  }
}
