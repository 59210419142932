<template>
  <div class="nav-section-bg">
    <div class="nav-header">Разделы</div>
    <NavSectionLink v-if="!loader" v-for="item in navList" :key="item.name" :item="item" />
    <div class="nav-loader-bg" v-if="loader">
      <Widget
        v-bind="{
          type: 'loader'
        }"
      />
    </div>
  </div>
</template>

<script>
import { Model } from "@frontend-modules/fsql";
import { domain } from "@/globalVariables";
import NavSectionLink from "@/components/KnowledgeBaseComponents/NavSection/NavSectionLink";
import Widget from "@/components/Widgets/Widget";

export default {
  name: "NavSectionColumn",
  mixins: [],
  props: [],
  components: { Widget, NavSectionLink },
  data() {
    return {
      navList: [],
      loader: false
    };
  },
  mounted() {
    this.getInfoNav();
  },
  methods: {
    async getInfoNav() {
      this.navList = [];
      this.loader = true;
      let array = [
        {
          name: "Книги",
          entity: "Book",
          route: "BooksPage"
        },
        {
          name: "Статьи",
          entity: "Article",
          route: "ArticlesPage"
        },
        {
          name: "Документы компании",
          entity: "Regulation",
          route: "RegulationsPage"
        }
      ];
      for (const [i, item] of array.entries()) {
        await this.getEntityInfo(item);
      }
      this.loader = false;
    },
    async getEntityInfo(item) {
      const ModelChannel = new Model({
        model: item.entity,
        proxy: {
          url: `${domain}/monolit`,
          query: {
            _count: 5,
            _with: JSON.stringify(["categories"])
          }
        }
      });
      await ModelChannel.init();
      let list = ModelChannel.allItems;
      let arrayDone = [];
      for (let i in list) {
        arrayDone = arrayDone.concat(list[i].categories).filter(function (item, index, array) {
          return array.findIndex((item1) => item1.id === item.id) === index;
        });
      }
      this.navList.push({
        name: item.name,
        route: item.route,
        categories: arrayDone
      });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";

.nav-section-bg {
  display: flex;
  flex-direction: column;
  width: 234px;
  height: fit-content;
  background: $color-white;
  border: 1px solid $color-contour;
  border-radius: 10px;
  padding: 20px 20px 10px;
  flex-shrink: 0;
  position: sticky;
  top: 0;

  .nav-header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-accent;
    margin-bottom: 24px;
  }

  .nav-loader-bg {
    display: flex;
    justify-content: center;
  }
}
</style>
