<template>
  <div class="uploader">
    <img
      class="uploader__img"
      :src="editValue ? editValue : require('@/assets/img/NewsImage/avatar-default.svg')"
      ref="iconChannel"
      alt="avatar"
      @click="showModal"
    />
    <div class="uploader__mask" v-if="!avatar" @click="showModal"><i class="icon-plus"></i></div>
    <div class="uploader__container" v-show="show">
      <div class="uploader__main">
        <cropper ref="cropper" :src="image.src" />
        <label for="file" class="uploader__button">
          Выберите файл
          <input id="file" type="file" ref="file" @input="loadImage($event)" accept="image/*" />
        </label>
      </div>
      <div class="uploader__footer">
        <button class="uploader__cancel" @click="hideModal">Отменить</button>
        <button class="uploader__button" @click="saveAvatar">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "AvatarUploadChannel",
  mixins: [],
  props: [
    "multiple",
    "drop",
    "dropDirectory",
    "fileDrop",
    "deep",
    "lengthText",
    "showOnlyFiles",
    "editValue"
  ],
  components: {
    Cropper
  },
  data() {
    return {
      avatar: null,
      show: false,
      file: null,
      whiteListFiles: ["image/png", "image/jpeg"],
      image: {
        name: null,
        src: null,
        type: null
      }
    };
  },
  mounted() {
    if (this.editValue) {
      this.avatar = this.editValue;
    }
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
      this.image = {
        name: null,
        src: null,
        type: null
      };
    },
    saveAvatar() {
      const { canvas } = this.$refs.cropper.getResult();
      if (!canvas) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Выбранный Вами файл не подходит по расширению!`
        });
        return false;
      }
      canvas.toBlob((blob) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          let file = [
            {
              file: reader.result,
              file_name: this.image.name
            }
          ];
          this.$emit("changeIconChanel", file);
          this.show = false;
        };
      }, this.image.type);
    },
    loadImage(event) {
      const { files } = event.target;
      if (Array.from(files).some((item) => !this.whiteListFiles.includes(item.type))) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: `Выбранный Вами файл не подходит по расширению!`
        });
        return false;
      }
      if (files.length) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            name: files[0].name,
            src: blob,
            type: this.getMimeType(e.target.result, files[0].type)
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    getMimeType(file, fallback = null) {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = "";
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.uploader {
  position: relative;
  /deep/ {
    .uploader__img {
      display: block;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      margin-right: 16px;
      border: 2px dashed #d3d2e0;
    }

    .uploader__mask {
      position: absolute;
      width: 72px;
      height: 72px;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
      border-radius: 50%;
      cursor: pointer;

      i {
        font-size: 20px;
        color: $surface-default;
      }
    }

    .uploader__container {
      position: absolute;
      z-index: 100000;
      top: 69px;
      left: 0;
      width: 434px;
      max-height: 500px;
      background: #ffffff;
      box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
      border-radius: 24px;

      @media screen and (max-width: $sm) {
        left: -100px;
        width: 250px;
        height: 380px;
      }
    }

    .uploader__canvas canvas {
      width: 215px;
      height: 192px;
      background: #ffffff;
      border: 2px dashed #d3d2e0;
      border-radius: 24px;

      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }

    .uploader__main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 50%;
      padding: 24px;
      .uploader__button {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        input {
          display: none;
        }
      }
      .vue-advanced-cropper {
        max-height: 300px;
      }
    }

    .uploader__wrap {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $text-disabled;

      @media screen and (max-width: $sm) {
        width: 100%;
      }

      &:not(:last-child) {
        @media screen and (max-width: $sm) {
          margin-bottom: 20px;
        }
      }
    }

    .uploader__range {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }

    .uploader__range input {
      width: 100%;
      height: 24px;
    }

    input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }

    /deep/ input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      cursor: pointer;
    }

    input[type="range"]::-webkit-slider-runnable-track,
    input[type="range"]::-moz-range-track {
      background-color: $surface-default !important;
      border: 1px solid $border-default !important;
      border-radius: 34px;
    }

    .icon-cross {
      color: $icon-subdued;
      font-size: 20px;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0;
      cursor: pointer;
      @media screen and (max-width: $sm) {
        top: 15px;
        right: 15px;
      }

      @media (hover: hover) {
        &:hover {
          color: $icon-hovered;
        }
      }

      &:active {
        color: $color-button-click;
      }
    }

    .uploader__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-top: 1px solid $border-disabled;
    }

    .uploader__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border: none;
      padding: 5px 12px;
      color: $surface-default;
      background-color: $action-primary;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 100px !important;
      outline: none;
    }

    @media (hover: hover) {
      .uploader__button:hover {
        background-color: $action-primary-hovered;
      }
    }

    .uploader__cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border: none;
      padding: 5px 12px;
      color: $text-subdued;
      background-color: $surface-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 100px !important;
      outline: none;
    }

    @media (hover: hover) {
      .uploader__cancel:hover {
        background-color: $surface-hovered;
      }
    }

    .avatar {
      position: relative;

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
      }

      img {
        width: 102px;
        height: 102px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .avatar-bg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .avatar {
        img {
          width: 102px;
          height: 102px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .bg-plus {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;

        .icon-plus {
          color: $main-white;
          font-size: 23px;
        }
      }
    }
  }
}
</style>
