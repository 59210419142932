<template>
  <div class="modal-channel" v-click-outside="hideModal">
    <div class="modal-channel__header">
      <button
        v-if="isMobileWindowSize"
        class="modal-channel__hide-btn"
        type="button"
        @click="hideModal"
      >
        <i class="icon-arrow-short"></i>
      </button>

      <p class="modal-channel__title">
        {{ editCurrentChat ? "Редактировать канал" : "Создать канал" }}
      </p>
    </div>
    <div class="modal-channel__main">
      <div class="modal-channel__channel-name channel-name">
        <div class="channel-name__avatar">
          <AvatarUploadChannel
            v-bind="{
              multiple: false,
              drop: false,
              dropDirectory: false,
              fileDrop: false
            }"
            :editValue="avatarChannelPath"
            @changeIconChanel="changeIconChanel"
          />
          <div v-if="loader" class="channel-name__loader-avatar">
            <Widget type="loader-new" class="loader" />
          </div>
        </div>
        <div class="channel-name__wrap">
          <label>Название канала</label>
          <Input v-model.trim="nameChannel" :maxlength="64" :placeholder="'Максимум 64 символа'" />
        </div>
      </div>
      <div class="modal-channel__channel-description channel-description">
        <div class="channel-description__tag">
          <p>Тег канала</p>
          <Input v-model.trim="tagChannel" :maxlength="255" :placeholder="'Например, AOP'" />
        </div>
        <div class="channel-description__description">
          <p>Описание</p>
          <Textarea
            v-model.trim="descriptionChannel"
            :placeholder="'Максимум 255 символов'"
            :maxlength="255"
          />
        </div>
      </div>
      <div class="modal-channel__members members">
        <div class="members__wrap">
          <p class="members__label">Модераторы</p>
          <Select
            :showAll="false"
            v-model="adminsChannel"
            :items="membersList"
            :maxCount="4"
            :placeholder="'Выберите сотрудника'"
          />
        </div>
        <div class="members__wrap">
          <p class="members__label">Участники</p>
          <Select
            v-model="membersChannel"
            :items="membersList"
            :placeholder="'Выберите сотрудника'"
          />
        </div>
      </div>
      <div class="modal-channel__settings settings">
        <div class="settings__wrap">
          <p>Открытый доступ</p>
          <SwitchComponent :value="isPublic" @changeSwitch="changeSwitchPublic" />
        </div>
        <div class="settings__wrap" v-if="!editCurrentChat">
          <p>Только для чтения</p>
          <SwitchComponent :value="onlyRead" @changeSwitch="changeSwitchReadonly" />
        </div>
        <div class="settings__wrap" v-if="isPublic">
          <p>Автодобавление новых сотрудников</p>
          <SwitchComponent :value="autoAddNewUsers" @changeSwitch="changeSwitchAddNew" />
        </div>
      </div>
    </div>
    <div class="modal-channel__footer">
      <button
        class="modal-channel__button modal-channel__button_cancel"
        type="button"
        @click="hideModal"
        :disabled="loader"
      >
        Отменить
      </button>
      <button
        class="modal-channel__button modal-channel__button_create"
        type="button"
        @click="handleCreateEdit"
        :disabled="loader"
      >
        {{ editCurrentChat ? "Сохранить" : "Создать" }}
      </button>
    </div>
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import ClickOutside from "vue-click-outside";
import AvatarUploadChannel from "@/modules/Messenger/components/AvatarUploadChannel";
import SwitchComponent from "@/modules/Messenger/components/Inputs/Switch";
import { mapActions, mapState } from "vuex";
import centrifuge from "@/centrifuge";
import Widget from "@/components/Widgets/Widget";
import Input from "@/modules/UiKit/components/Inputs/Input";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import Select from "@/modules/UiKit/components/Select/Select";
import httpClient from "@/api/client/httpClient";

export default {
  name: "CreateEditChannelModal",
  mixins: [],
  props: {},
  components: {
    Select,
    Textarea,
    Input,
    Widget,
    SwitchComponent,
    AvatarUploadChannel
  },
  data() {
    return {
      avatarChannelPath: "",
      avatarChannelId: "",
      nameChannel: "",
      tagChannel: "",
      descriptionChannel: "",
      isPublic: false,
      onlyRead: false,
      autoAddNewUsers: false,
      adminsChannel: [],
      membersChannel: [],
      colorChannel: "",
      isColorPicker: false,
      pullName: "",
      fileUploader: [],
      chunksArr: [],
      loader: false,
      membersList: [],
      windowWidth: window.innerWidth
    };
  },
  beforeDestroy() {},
  created() {
    if (this.editCurrentChat) {
      this.setEditValue();
    }
    this.getMembers();
  },
  mounted() {
    window.addEventListener("resize", this.showArrowHide);
  },
  methods: {
    ...mapActions("MessengerModule", [
      "createEditChatChannel",
      "changeCreateEditChannelModal",
      "updateUsers",
      "updateChatList"
    ]),
    showArrowHide() {
      this.windowWidth = window.innerWidth;
    },
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    },
    handleCreateEdit() {
      // Конкатинируем два массива admins && members, и превращаем в нужный формат
      let arrMembers = this.adminsChannel
        .concat(this.membersChannel)
        .filter((item, index, arr) => index === arr.findIndex((mem) => mem.id === item.id))
        .map((item) => {
          let data = { user_id: item.id, is_moderator: false, info: item };
          if (this.editCurrentChat) {
            data.chat_id = this.editCurrentChat.channel.id;
          }
          return data;
        });
      arrMembers.forEach((item) => {
        // Проверка: Админ ли пользователь
        let isAdmin = this.adminsChannel.some((mem) => mem.id === item.user_id);
        if (isAdmin) {
          item.is_moderator = true;
        }
      });
      let data = {
        prefix: "monolit",
        editMode: !!this.editCurrentChat,
        data: {
          type: 2,
          id: this.editCurrentChat ? this.editCurrentChat.channel.chat_settings.id : undefined,
          avatar_id: this.avatarChannelId,
          name: this.nameChannel,
          description: this.descriptionChannel,
          tag: this.tagChannel.replaceAll(" ", ""),
          is_open: this.isPublic,
          is_read_only: this.onlyRead,
          is_auto_add_new_members: this.isPublic ? this.autoAddNewUsers : false,
          users: arrMembers.map((item) => ({
            user_id: item.user_id,
            chat_id: item.chat_id,
            is_moderator: item.is_moderator
          })),
          color: this.colorChannel
        }
      };
      if (!this.checkValidation()) return false;
      this.createEditChatChannel(data).then((response) => {
        if (response.data.data?.code === 422) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: response.data.data.data?.tag.length
              ? response.data.data.data.tag[0]
              : "Произошла ошибка про создании канала"
          });
        } else {
          this.updateChatList({ data: response.data.data.data, editMode: this.editCurrentChat });
          if (this.editCurrentChat) {
            this.updateUsers(arrMembers);
          }
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: this.editCurrentChat ? "Канал успешно изменен!" : "Канал успешно создан!"
          });
          this.hideModal();
        }
      });
    },
    checkValidation() {
      if (!this.nameChannel.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите имя Канала!`
        });
        return false;
      }
      if (this.nameChannel.length > 255) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Имя Канала не должно содержать более 255 символов!`
        });
        return false;
      }
      if (!this.tagChannel.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите тег Канала!`
        });
        return false;
      }
      if (this.tagChannel.length > 255) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Тег Канала не должен содержать более 255 символов!`
        });
        return false;
      }
      if (/[а-яА-я]/g.test(this.tagChannel)) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Тег Канала должен содержать только латиницу и цифры!`
        });
        return false;
      }
      // if (!this.adminsChannel.length) {
      //   this.$root.$emit("showNotification", {
      //     type: "error",
      //     timeout: 5000,
      //     label: `У Канала должен быть минимум 1 Модератор!`
      //   });
      //   return false;
      // }
      if (this.adminsChannel.length > 5) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Модераторов не может быть больше 5!`
        });
        return false;
      }
      if (!this.membersChannel.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `У Канала должен быть минимум 1 Участник!`
        });
        return false;
      }
      return true;
    },
    setEditValue() {
      this.avatarChannelPath = this.editCurrentChat.channel.chat_settings.avatar
        ? this.returnSrcFile(this.editCurrentChat.channel.chat_settings.avatar.path)
        : "";
      this.avatarChannelId = this.editCurrentChat.channel.chat_settings.avatar
        ? this.editCurrentChat.channel.chat_settings.avatar.id
        : "";
      this.nameChannel = this.editCurrentChat.channel.chat_settings.name;
      this.tagChannel = this.editCurrentChat.channel.chat_settings.tag;
      this.descriptionChannel = this.editCurrentChat.channel.chat_settings.description;
      this.isPublic = this.editCurrentChat.channel.chat_settings.is_open;
      this.onlyRead = this.editCurrentChat.channel.chat_settings.is_read_only;
      this.autoAddNewUsers = this.editCurrentChat.channel.chat_settings.is_auto_add_new_members;
      this.adminsChannel = this.editCurrentChat.users
        .filter((item) => item.is_moderator)
        .map((item) => item.info);
      this.membersChannel = this.editCurrentChat.users
        .filter((item) => !item.is_moderator)
        .map((item) => item.info);
      this.colorChannel = this.editCurrentChat.channel.chat_settings.color;
    },
    changeIconChanel(file) {
      this.fileUploader = file;
      this.createChunksPoolFiles();
    },
    createChunksPoolFiles() {
      if (!this.fileUploader.length) {
        return null;
      }
      this.loader = true;
      this.createPull(this.fileUploader);
    },
    createPull(arr) {
      if (!arr.length) {
        return null;
      }
      httpClient({
        url: `${domain}/monolit/FileManager/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].file_name,
            sync: true
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.data.pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      this.avatarChannelPath = arr[0].file;
      let base64 = arr[0].file.replace(arr[0].file.substring(0, arr[0].file.search(",") + 1), "");
      this.chunksArr = base64.match(/.{1,500000}/g);

      let countError = 0;
      let nulls =
        Array.from(this.chunksArr.length.toString())
          .map(() => "0")
          .join("") + "0";
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        let chunkName = nulls.substr(0, nulls.length - i.toString().length) + i;
        await httpClient({
          url: `${domain}/monolit/FileManager/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              chunk_name: chunkName,
              sync: true,
              base_64_chunk: item
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/FileManager/collectFileFromPool`,
        method: "POST",
        data: [
          {
            pool_name: this.pullName,
            sync: false
          }
        ]
      })
        .then((response) => {
          this[`${response.data.data.channel}`] = centrifuge.subscribe(
            response.data.data.channel,
            (message) => {
              if (message?.code === 422 || message?.code === 0) {
                this.$root.$emit("showNotification", {
                  type: "error",
                  timeout: 5000,
                  label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
                });
                return false;
              }
              this.saveFileId(message.data.data.idFile, response.data.data.channel);
            }
          );
          this[`${response.data.data.channel}`].history().then(
            (resp) => {
              if (resp.publications.length) {
                if (
                  resp.publications[0].data.data?.code === 422 ||
                  resp.publications[0].data.data?.code === 0
                ) {
                  this.$root.$emit("showNotification", {
                    type: "error",
                    timeout: 5000,
                    label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
                  });
                  return false;
                }
                this.saveFileId(resp.publications[0].data.data.idFile, response.data.data.channel);
              }
            },
            (err) => {
              console.error(err);
            }
          );
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    saveFileId(item, channel) {
      this.avatarChannelId = item;
      this[`${channel}`].unsubscribe();
      this.loader = false;
    },
    getMembers() {
      httpClient({
        method: "GET",
        url: `/User/getItems`,
        params: {
          _count: 500,
          is_active: true
        }
      }).then((response) => {
        this.membersList = response.data.data.items;
      });
    },
    setChannelColor(val) {
      this.colorChannel = val;
    },
    hideModal() {
      if (this.loader) return false;
      this.changeCreateEditChannelModal(false);
    },
    hideColorPicker() {
      this.isColorPicker = false;
    },
    changeSwitchPublic() {
      this.isPublic = !this.isPublic;
    },
    changeSwitchReadonly() {
      this.onlyRead = !this.onlyRead;
    },
    changeSwitchAddNew() {
      this.autoAddNewUsers = !this.autoAddNewUsers;
    },
    changeAdminValue(val) {
      this.adminsChannel = val;
    },
    changeMemberValue(val) {
      this.membersChannel = val;
    }
  },
  computed: {
    isMobileWindowSize() {
      return this.windowWidth <= 768;
    },
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },
    ...mapState("MessengerModule", ["editCurrentChat"])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.modal-channel {
  position: fixed;
  width: 716px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 24px;
  background: white;
  box-shadow: 3px 0 24px rgba(120, 121, 150, 0.6);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 36px;

    i {
      font-size: 24px;
      color: $icon-subdued;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: $icon-hovered;
        }
      }
    }
  }

  &__hide-btn {
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: #000000;
  }

  &__main {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
    overflow-y: auto;
  }

  &__channel-name {
    display: flex;
    width: 100%;
    margin-bottom: 36px;

    .channel-name {
      &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        label {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #0a1417;
          margin-bottom: 16px;
          &::after {
            content: "*";
            color: $text-critical;
          }
        }

        /deep/ {
          .input {
            width: 100%;
            height: 48px;
            &__control {
              width: 100%;
            }
            input::placeholder {
              color: #a7a9c0;
            }
          }
        }
      }
      &__avatar {
        position: relative;
      }
      &__loader-avatar {
        position: absolute;
        display: flex;
        align-items: center;
        background: white;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-left: 10px;
      }
    }
  }

  &__channel-description {
    display: flex;
    flex-direction: column;
    width: 100%;

    .channel-description {
      &__tag {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #0a1417;
          margin-bottom: 16px;
          &::after {
            content: "*";
            color: #fb646d;
          }
        }

        /deep/ {
          .input {
            width: 100%;
            height: 48px;
            &__control {
              width: 100%;
            }
            input::placeholder {
              color: #a7a9c0;
            }
          }
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          color: #0a1417;
          margin-bottom: 16px;
          &::after {
            content: "*";
            color: #fb646d;
          }
        }

        /deep/ {
          .form-group {
            width: 100%;

            &__control {
              width: 100%;
            }
            textarea {
              font-size: 16px;
              border-radius: 8px;
              height: 90px !important;
              background: #fafafe;
            }
            textarea::placeholder {
              color: #a7a9c0;
            }
          }
        }
      }
    }
  }

  &__settings {
    width: 100%;
    margin-bottom: 30px;

    .settings {
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 20px;
        }
        /deep/ {
          .switch-widget {
            width: auto;
            &__indicator {
              width: 41px;
              height: 24px;
              &::before {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &_members {
      margin-bottom: 24px;
    }

    &_color {
      position: relative;
    }

    .modal-channel__picker {
      position: absolute;
      top: 0;
      right: -32px;
      height: 85px;
    }

    .color-picker {
      width: 603px;
      border-radius: 24px 0 0 24px;
      padding: 24px 36px 12px;

      /deep/ {
        .color-picker__list {
          width: 100%;
          overflow-x: scroll;
        }

        .color-picker__item {
          margin-bottom: 25px;
        }
      }
    }

    .select-bg {
      margin-right: 32px;
      margin-bottom: 24px;
    }
  }

  &__members {
    display: flex;
    flex-direction: column;
    width: 100%;

    .members {
      &__wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
      }

      &__label {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: #0a1417;
        margin-bottom: 16px;
        &::after {
          content: "*";
          color: #fb646d;
        }
      }

      &__color {
        display: flex;
        align-items: center;

        p {
          margin-right: 10px;
        }
      }

      &__picker {
        position: absolute;
        right: 0;
        top: calc(100% + 5px);

        /deep/ {
          .color-picker {
            width: fit-content;
            height: fit-content;
            padding: 8px;

            &__list {
            }

            &__item {
              margin-right: 5px;
            }
          }
        }
      }

      &__add-color-picker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        padding: 0;
        background: $background-active;
        border-radius: 50%;
        border: 1px dashed $border-default;
        outline: none;

        i {
          color: $border-default;
          font-size: 20px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
  }

  &__button {
    width: 105px;
    height: 44px;
    border-radius: 100px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border: 2px solid #ffffff;

    &_cancel {
      color: #343748;
      background: none;
    }

    &_create {
      width: 114px;
      height: 56px;
      color: $surface-default;
      background: linear-gradient(336.67deg, #4942f8 -13.54%, #6b6dfc 70.48%, #7f87ff 120.89%);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);

      @media (hover: hover) {
        &:hover {
          background: linear-gradient(336.67deg, #443cea -13.54%, #5e60ea 70.48%, #686ed1 120.89%);
          box-shadow: 0 8px 10px rgba(48, 79, 254, 0.14), 0 3px 14px rgba(48, 79, 254, 0.12),
            0 4px 5px rgba(48, 79, 254, 0.2);
        }
      }
    }
  }

  /deep/ {
    .textarea__control {
      width: 100%;
    }
    .select-bg__selected-list {
      max-height: 180px;
      overflow: auto;
      align-items: flex-start;
    }

    .uploader__container {
      left: 5px;
      @media (max-width: $sm) {
        width: 250px;
        height: auto;
        max-height: 500px;
      }
    }
    .input__control,
    .textarea__control,
    .select-bg__placeholder {
      @media (max-width: $xs) {
        font-size: 14px;
      }
    }
  }
}
</style>
