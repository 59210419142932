import ProjectModel, { IProject } from "@/modules/TaskManager/Model/Project/ProjectModel";

interface User {
  id: number;
  user_id: number;
  project_id: number;
  is_owner: boolean;
  created_at: null | string;
  updated_at: null | string;
  deleted_at: null | string;
}

export interface IProjectFull extends IProject {
  members: Array<User>;
  owners: Array<User>;
  child_projects: Array<ProjectModel>;
  parent_name: string | null;
  parent_is_owner: boolean | null;
}

export interface IProjectFullModel {
  projectFull: IProjectFull;
}

export default class ProjectFullModel implements IProjectFullModel {
  private _projectFull: IProjectFull;

  constructor(projectFull: IProjectFull) {
    this._projectFull = projectFull;
  }

  get projectFull() {
    return this._projectFull;
  }

  set projectFull(updateProjectFull: IProjectFull) {
    this._projectFull = updateProjectFull;
  }

  setField(key, value) {
    this._projectFull[key] = value;
  }
}
