





















































import {Component, Prop, Vue} from "vue-property-decorator";
import Select from "@/modules/UiKit/components/Select/Select";
import Button from "@/modules/UiKit/components/buttons/Button.vue";
import TimerInputLimit from "@/modules/TimeTracker/component/TimerInputLimit/TimerInputLimit.vue";
import Label from "@/modules/Isp/components/IspProblem/Label";
import DateFilter from "@/modules/TimeTracker/component/Header/DateFilter.vue";
import DatePickerWidget
  from "@/modules/News/components/NewsCreateAndEdit/NewsCreateAndEditComponent/DateTimePicker.vue";
import moment from "moment";

@Component({
  components: {
    DatePickerWidget,
    DateFilter,
    TimerInputLimit,
    Label,
    Button,
    Select
  }
})
export default class TimeModal extends Vue {
  @Prop({type: Array, default: () => []}) users!: Array<any>;
  @Prop({type: Array, default: () => []}) selectUser!: Array<any>;
  @Prop({type: String, default: ""}) title!: string;
  @Prop({type: Boolean, default: false}) limitSelect!: boolean;
  @Prop({type: String, default: ""}) userLimit!: string;
  @Prop({type: String, default: ""}) weekTime!: string;
  @Prop({type: String, default: ""}) week!: string;
  @Prop({type: String, default: ""}) selectDate!: string;
  @Prop({type: Number, default: 0}) weekNumber!: number;
  @Prop({type: Number, default: 0}) year!: number;
  currentUser: Array<any> = [];
  editValue: any = "";
  isEdit: boolean = false;
  date: string = moment(new Date()).format("YYYY-MM-DD");

  mounted() {
    this.currentUser = this.selectUser;
    this.date = this.selectDate;
  }

  handleEdit() {
    this.isEdit = true;
    this.$nextTick(() => {
      const input = document.querySelector("#timeLimit");
      if (input) {
        // @ts-ignore
        input.focus();
      }
    });
  }

  closeEdit() {
    this.isEdit = false;
    this.editValue = "";
  }

  handleClose() {
    this.$emit("handleClose");
  }

  handleDone() {
    this.$emit("handleDone", this.editValue);
    this.closeEdit();
  }

  handleDate(date) {
    this.$emit("handleDate", moment(date).format("YYYY-MM-DD"));
  }

  handleUser(val) {
    this.currentUser = val;
    this.$emit("handleUser", val);
  }

  created() {
    document.addEventListener("keydown", this.onEscape);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onEscape);
  }

  onEscape(e) {
    if (e.keyCode === 27) {
      this.handleClose();
    }
  }

  get isUser() {
    return this.currentUser.length && this.currentUser[0].id;
  }
}
