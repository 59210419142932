<template>
  <div class="chat-group">
    <h3 class="chat-group__title" @click="handleOpenList" v-if="chat.list.length && isGroupChat">
      {{ chat.title }}
    </h3>
    <ul class="chat-group__list">
      <li class="chat-group__item" v-for="item in chat.list" :key="item.id">
        <ChatItem :chat="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChatItem from "@/modules/Messenger/components/ChatItem/ChatItem";

export default {
  name: "ChatListItem",
  mixins: [],
  props: {
    chat: {
      type: Array,
      default: () => []
    }
  },
  components: { ChatItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleOpenList() {}
  },
  computed: {
    ...mapState("MessengerModule", ["isGroupChat"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.chat-group {
  &__list {
    all: unset;
    list-style: none;
  }
}
</style>
