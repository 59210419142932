<template>
  <div
    class="statistics__black-sphere black-sphere black-sphere_mobile"
    :class="fullList ? 'black-sphere_full' : ''"
    @click.self="$emit('hideModal')"
  >
    <div class="black-sphere__window">
      <div class="black-sphere__header" v-if="!fullList">
        <BlackSphereWidgetNew :blackSphere="setBlackSphere" />
        <i class="icon-cross black-sphere__cross" v-if="!fullList" @click="$emit('hideModal')"></i>
        <button class="black-sphere__button" type="button" @click="fullList = true">
          Все оценки
        </button>
      </div>
      <div class="black-sphere__header black-sphere__header_full" v-if="fullList">
        <i class="icon-sort-arrow" @click="fullList = false"></i>
        <p class="black-sphere__subtitle">Все оценки</p>
        <i class="icon-cross" @click="$emit('hideModal')"></i>
      </div>
      <ul class="black-sphere__list">
        <BlackSphereItem
          v-for="(item, index) in fullList ? fullSphereList : shortSphereList"
          :key="index"
          :item="item"
        />
        <li class="black-sphere__item black-sphere__item_empty" v-if="!fullSphereList.length">
          Нет оценок :(
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BlackSphereItem from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereItem";
import BlackSphereWidgetNew from "@/components/ProfileComponents/ProfilePersonalArea/ProfileWidgets/ProfileStatistics/ProfileBlackSphere/BlackSphereWidget/BlackSphereWidgetNew";
import moment from "moment";
export default {
  name: "BlackSphereModalMobile",
  mixins: [],
  props: {
    blackSphere: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fullSphereList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    shortSphereList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { BlackSphereItem, BlackSphereWidgetNew },
  data() {
    return {
      fullList: false
    };
  },
  mounted() {},
  methods: {},
  computed: {
    setDateAfterChangeBSM() {
      return moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").isAfter(
        moment(new Date("2021-11-01"))
      );
    },
    setDateSameChangeBSM() {
      return (
        moment(this.blackSphere[0].month.split("-").reverse().join("-") + "-01").format(
          "YYYY-MM-DD"
        ) === moment(new Date("2021-11-01")).format("YYYY-MM-DD")
      );
    },
    setBlackSphere() {
      return this.blackSphere.length ? this.blackSphere[0] : null;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.black-sphere {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background-modal;
  z-index: 10000000;

  @media (max-width: $sm) {
    align-items: flex-end;
  }

  &_full {
    .black-sphere__window {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 0;
    }

    .black-sphere__list {
      height: 88vh;
      padding: 0 16px;

      @media screen and (max-width: 568px) and (max-height: 450px) {
        height: 65vh;
      }

      @media screen and (max-width: 320px) {
        height: 80vh;
      }
    }
  }

  &__window {
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
    width: 356px;
    height: 328px;
    padding: 32px;

    @media (max-width: $sm) {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 372px;
      border-radius: 24px 24px 0 0;
    }

    @media screen and (max-width: 568px) and (max-height: 450px) {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding: 16px;
      border-radius: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 32px;

    &_full {
      width: 100%;
      padding: 24px 24px 16px;
      border-bottom: 1px solid $border-disabled;
    }

    .icon-widget {
      width: 100%;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $xxxl) {
        width: 34px;
        height: 34px;
      }
    }

    i {
      font-size: 22px;
      color: $icon-subdued;
      cursor: pointer;

      &:hover {
        color: $icon-hovered;
      }

      &:active {
        color: $icon-pressed;
      }
    }

    .icon-sort-arrow {
      display: none;
      transform: rotate(90deg);
      margin-right: 20px;

      @media (max-width: $sm) {
        display: block;
      }
    }

    .icon-cross {
      margin-left: auto;
    }
  }

  &__icon-widget {
    width: 87%;
    display: flex;
    align-items: center;
  }

  &__cross {
    display: none;
    font-size: 22px;
    color: $icon-subdued;
    cursor: pointer;

    @media (max-width: 568px) and (max-height: 450px) {
      display: block;
    }

    &:hover {
      color: $icon-hovered;
    }

    &:active {
      color: $icon-pressed;
    }
  }

  &__subtitle {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    color: $text-body-dark;

    @media (max-width: $xxxl) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__content {
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: $text-body-muted;

    @media (max-width: $xxxl) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &__button {
    display: none;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 12px;
    line-height: 24px;
    color: $text-default;
    text-align: left;
    margin-top: 24px;

    @media (max-width: $sm) {
      display: block;
    }

    &:hover {
      color: $text-subdued;
    }

    &:active {
      color: $text-subdued;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 200px;
    overflow-y: scroll;

    @media screen and (max-width: 568px) and (max-height: 450px) {
      height: calc(100% - 110px);
    }
  }

  &__item {
    &_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $text-disabled;
      font-size: 14px;
      line-height: 20px;
    }
  }

  /deep/ {
    .black-sphere__tooltip {
      @media (max-width: $sm) {
        white-space: normal;
        width: 190px;
      }

      @media (max-width: $xxs) {
        width: 120px;
      }
    }
  }
}
</style>
