<template>
  <div :class="row ? 'user row' : 'user'">
    <router-link
      :to="{ name: 'UserInfo', params: { user_id: id } }"
      :target="isGlobalMobileMode ? '_self' : '_blank'"
      :key="id"
    >
      <img
        class="user__avatar"
        :src="returnSrcFile(avatar)"
        alt="Avatar"
        :style="{ width: size, height: size }"
      />
    </router-link>
    <p class="user__tooltip" v-if="name.length && showTooltip">
      {{ setName(name) }}
    </p>
    <router-link
      :to="{ name: 'UserInfo', params: { user_id: id } }"
      :target="isGlobalMobileMode ? '_self' : '_blank'"
      :key="id"
      class="user__info"
      v-if="showInfo"
    >
      <div class="user__main-info">
        <p class="user__name">
          {{ setName(name) }}
          <i class="icon-cross" @click.prevent="crossHandler" v-if="showCross"></i>
        </p>
        <p class="user__description">{{ info }}</p>
      </div>
      <div class="user__more-info" v-if="additionalInfo.length">
        <p>
          {{ additionalInfo }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "User",
  mixins: [],
  props: {
    avatar: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: "40px"
    },
    name: {
      type: String,
      default: ""
    },
    info: {
      type: String,
      default: ""
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    row: {
      type: Boolean,
      default: false
    },
    showCross: {
      type: Boolean,
      default: false
    },
    additionalInfo: {
      type: String,
      default: ""
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    setName(name) {
      let newName = name.split(" ");
      return newName[0] + " " + newName[1];
    },
    crossHandler() {
      this.$emit("crossHandler", this.id);
    }
  },
  computed: {
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.user {
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    .user__tooltip {
      transform: scale(1);
      opacity: 1;
    }
  }
  &:hover .icon-cross {
    opacity: 1;
  }
  &__tooltip {
    transition: opacity 0.3s ease;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    bottom: 100%;
    width: max-content;
    padding: 5px 9px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    z-index: 8;
    line-height: 20px;
    color: #ffffff;
    background: #343748;
    border-radius: 2px;
  }
  &__avatar {
    display: block;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: $sm) {
      margin-right: 12px;
      width: 35px;
      height: 35px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    margin: 0 0 4px;
    transition: all 0.1s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    i {
      margin-left: 6px;
      font-size: 18px;
      color: #a7a9c0;
      cursor: pointer;
      transition: 0.3s all ease;
      opacity: 0;
      &:hover {
        color: $icon-highlight;
      }
    }
  }

  &__description {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $text-subdued;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__more-info {
    margin-top: 4px;
    font-size: 12px;
    color: $text-disabled;
  }
}
.row {
  .user__main-info {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .user__avatar {
    margin: 0 8px 0 0;
  }
  .user__name {
    margin: 0 8px 0 0;
  }
}
</style>
