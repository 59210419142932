<template>
  <div class="regulation-page">
    <Widget type="loader" v-if="loading" />
    <div v-if="!getCreateMode && !loading" class="regulation-page__container">
      <div class="regulation-page__header" ref="headerContent">
        <Widget type="bread-crumbs" :crumbs="crumbs" />
      </div>
      <div class="regulation-page__grid">
        <div class="regulation-page__item">
          <div class="regulation-page__item__toolbar">
            <div class="regulation-page__item__tags">
              <Widget
                v-for="tag in regulationData.tags"
                type="tag"
                :text="tag.name"
                :key="tag.id"
              />
            </div>
            <div
              v-if="isAdmin || getUserId === regulationData.creator_user_id"
              class="regulation-page__item__buttons"
            >
              <i class="icon-delete" @click="askDeleteEvent"></i>
              <i class="icon-edit" @click="$store.commit('changeCreateMode', true)"></i>
            </div>
          </div>
          <div class="regulation-page__item__info">
            <div class="regulation-page__item__info__people">
              <div class="regulation-page__item__info__people__img">
                <img :src="returnSrcFile(regulationData.author.avatar)" />
                <img
                  :src="returnSrcFile(co_author.avatar)"
                  v-for="co_author in regulationData.co_authors"
                  :key="co_author.id"
                />
              </div>
              <div class="regulation-page__item__info__people__names">
                <span class="regulation-page__item__info__people__names__author">{{
                  regulationData.author.full_name
                }}</span>
                <div class="regulation-page__item__info__people__names__co-authors">
                  <span
                    class="regulation-page__item__info__people__names__co-author"
                    v-for="(coAuthor, idx) in regulationData.co_authors"
                    :key="coAuthor.id"
                  >
                    {{
                      `${coAuthor.full_name}${
                        idx !== regulationData.co_authors.length - 1 ? ", " : ""
                      }`
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="regulation-page__item__info__wrapper">
              <div class="regulation-page__item__info__likes">
                <LikeComponent
                  :parentLike="regulationData"
                  :likeableType="'regulations'"
                  @updateLikeInfo="updateLink"
                />
              </div>
              <div class="regulation-page__item__info__views">
                <i class="icon-eye"></i>
                <span>{{ regulationData.count_views }}</span>
              </div>
            </div>
            <div class="regulation-page__item__info__title">{{ regulationData.name }}</div>
          </div>
          <div
            ref="pageContent"
            class="regulation-page__item__content"
            v-html="regulationData.content"
          ></div>
        </div>
        <div
          v-if="pageHeaders.length || regulationData.files.length || regulationData.links.length"
          class="regulation-page__apps"
        >
          <div
            v-if="pageHeaders.length"
            class="regulation-page__apps__item regulation-page__apps__table-of-contents"
          >
            <h3>Содержание</h3>
            <div class="regulation-page__apps__table-of-contents__items">
              <div
                v-for="(header, idx) in pageHeaders"
                @click="goScrollContent(header, idx)"
                :key="header"
                :class="idx === activeHeader ? 'active' : ''"
              >
                {{ header.innerText }}
              </div>
            </div>
          </div>
          <div
            v-if="regulationData.files.length || regulationData.links.length"
            class="regulation-page__apps__item regulation-page__apps__docs"
          >
            <h3 v-if="regulationData.files.length">Документы</h3>
            <div v-if="regulationData.files.length" class="regulation-page__apps__docs__files">
              <a
                v-for="file in regulationData.files"
                :href="returnSrcFile(file.link)"
                target="_blank"
                :key="file.id"
                class="regulation-page__apps__docs__files__item"
              >
                <span
                  class="regulation-page__apps__docs__files__item__icon"
                  v-html="setExtensionIcon(file)"
                ></span>
                <span class="regulation-page__apps__docs__files__item__title">{{
                  cutterText(file.label)
                }}</span>
                <span class="regulation-page__apps__docs__files__item__size">{{
                  sizeFloor(file.size)
                }}</span>
              </a>
            </div>
            <div
              v-if="regulationData.files.length && regulationData.links.length"
              class="regulation-page__apps__docs__decoration-line"
            ></div>
            <div v-if="regulationData.links.length" class="regulation-page__apps__docs__links">
              <h4>Ссылки</h4>
              <div
                v-for="link in regulationData.links"
                :key="link.id"
                class="regulation-page__apps__docs__links__item"
              >
                <i class="icon-chain"></i>
                <a target="_blank" :href="link.link">{{ link.label }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getCreateMode" class="regulations-page__edit-container">
      <RegulationEditComponent
        @updateRegulationPage="updateRegulationInfo"
        :regulationData="regulationData"
      />
    </div>
    <div class="button-scroll-top" @click="goToTop" v-show="scrollTopButton">
      <i class="icon-up"></i>
    </div>
  </div>
</template>

<script>
import { HTTPRequest } from "@frontend-modules/fsql";
import { domain, domainStorage } from "@/globalVariables";
import Swal from "sweetalert2";
import axios from "axios";

import Widget from "@/components/Widgets/Widget";
const RegulationEditComponent = () =>
  import("@/components/KnowledgeBaseComponents/Regulation/RegulationEditComponent");
import LikeComponent from "@/components/KnowledgeBaseComponents/LikeComponents/LikeComponent";
import { mapGetters } from "vuex";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "RegulationPage",
  mixins: [],
  props: [],
  components: { LikeComponent, RegulationEditComponent, Widget },
  data() {
    return {
      loading: true,
      activeHeader: null,
      pageHeaders: [],
      headersList: ["h1", "h2", "h3", "h4", "h5", "h6"],
      regulationData: {},
      scrollTopButton: false,
      crumbs: [
        {
          title: "Главная",
          linkName: "Section"
        },
        {
          title: "Документы компании",
          linkName: "Regulations"
        }
      ]
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.getRegulationInfo();
    window.addEventListener("scroll", this.handleScroll, false);
  },
  mounted() {
    this.setView();
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    handleScroll() {
      if (window.scrollY > 1000) {
        this.scrollTopButton = true;
      } else {
        this.scrollTopButton = false;
      }
    },
    setView() {
      httpClient({
        url: `/View/actionCreate`,
        method: "POST",
        data: [
          {
            viewable_id: this.$route.params.regulation_id,
            viewable_type: "regulations"
          }
        ]
      });
    },
    updateLink() {
      const Regulation = new HTTPRequest();
      Regulation.send(
        `${domain}/monolit/Regulation/actionGetItem/${
          this.$route.params["regulation_id"]
        }?_with=${JSON.stringify([
          "categories",
          "tags",
          "creator",
          "likes",
          "author",
          "co_authors",
          "links",
          "files",
          "current_user_like",
          "roles",
          "responsibilities"
        ])}`,
        "GET"
      )
        .then((resp) => {
          this.regulationData = resp.data.data;
          setTimeout(() => {
            this.getHeadersIntoHtml();
          }, 0);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    updateRegulationInfo() {
      this.loading = true;
      this.crumbs.pop();
      this.getRegulationInfo();
    },
    deleteRegulation() {
      httpClient({
        url: `/Regulation/actionDelete/${this.$route.params.regulation_id}`,
        method: "DELETE"
      })
        .then((response) => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Документ успешно удален!"
          });
          this.$router.push({ name: "Regulations" });
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    goScrollContent(header, idx) {
      this.setActiveHeader(idx);
      header.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    getHeadersIntoHtml() {
      this.pageHeaders = [];
      for (let i in this.$refs.pageContent.children) {
        if (
          this.headersList.findIndex((h) => h === this.$refs.pageContent.children[i].localName) !==
          -1
        ) {
          this.pageHeaders.push(this.$refs.pageContent.children[i]);
        }
      }
    },
    getRegulationInfo() {
      const Regulation = new HTTPRequest();
      Regulation.send(
        `${domain}/monolit/Regulation/actionGetItem/${
          this.$route.params["regulation_id"]
        }?_with=${JSON.stringify([
          "categories",
          "tags",
          "creator",
          "likes",
          "author",
          "co_authors",
          "links",
          "files",
          "current_user_like",
          "roles",
          "responsibilities"
        ])}`,
        "GET"
      )
        .then((resp) => {
          this.regulationData = resp.data.data;
          this.crumbs.push({ title: this.regulationData.name, linkName: "RegulationPage" });
          setTimeout(() => {
            this.getHeadersIntoHtml();
          }, 0);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    goToTop() {
      this.$refs.headerContent.scrollIntoView({ block: "center", behavior: "smooth" });
    },
    setExtensionIcon(file) {
      switch (file.extension) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return `<svg width="33" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.625 1.125C3.625 0.644321 4.01932 0.25 4.5 0.25H12.2714L16.625 4.60355V16.875C16.625 17.3557 16.2307 17.75 15.75 17.75H4.5C4.01932 17.75 3.625 17.3557 3.625 16.875V1.125Z" fill="#FAFAFF" stroke="#E7E7FB" stroke-width="0.5"/>
                    <path d="M13.5 4.5H16.875L12.375 0V3.375C12.375 3.99375 12.8812 4.5 13.5 4.5Z" fill="#E7E7FB"/>
                    <path d="M14.625 14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875H1.6875C1.37812 15.1875 1.125 14.9344 1.125 14.625V9C1.125 8.69062 1.37812 8.4375 1.6875 8.4375H14.0625C14.3719 8.4375 14.625 8.69062 14.625 9V14.625Z" fill="#2281E8"/>
                    <path d="M3.25435 13.4995C3.10585 13.4995 2.94385 13.418 2.94385 13.2205V10.6662C2.94385 10.5042 3.10585 10.3872 3.25435 10.3872H4.28429C6.33966 10.3872 6.2941 13.4995 4.32422 13.4995H3.25435ZM3.53785 10.9356V12.9505H4.28429C5.49872 12.9505 5.55216 10.9356 4.28429 10.9356H3.53785Z" fill="white"/>
                    <path d="M8.01552 13.5447C7.18358 13.5807 6.31958 13.0272 6.31958 11.9253C6.31958 10.8188 7.18302 10.2749 8.01552 10.2749C8.80246 10.3148 9.6254 10.8593 9.6254 11.9253C9.6254 12.9918 8.80246 13.5447 8.01552 13.5447ZM7.96602 10.8599C7.46202 10.8599 6.91358 11.2154 6.91358 11.9258C6.91358 12.6323 7.46258 12.9923 7.96602 12.9923C8.48353 12.9923 9.03647 12.6323 9.03647 11.9258C9.03647 11.2148 8.48353 10.8599 7.96602 10.8599Z" fill="white"/>
                    <path d="M10.155 11.9213C10.155 11.0533 10.6995 10.3069 11.734 10.3069C12.1255 10.3069 12.436 10.4239 12.7639 10.7074C12.8854 10.8193 12.8989 11.0173 12.7774 11.1433C12.6559 11.2508 12.4714 11.2378 12.3634 11.1298C12.1795 10.9363 11.9905 10.8829 11.734 10.8829C11.0415 10.8829 10.7091 11.3734 10.7091 11.9218C10.7091 12.4798 11.037 12.9923 11.734 12.9923C11.9905 12.9923 12.229 12.8882 12.436 12.7043C12.5749 12.5963 12.7684 12.6497 12.8494 12.7583C12.9214 12.8573 12.9569 13.0238 12.7999 13.1807C12.4849 13.4738 12.1075 13.5323 11.7334 13.5323C10.6455 13.5323 10.155 12.7892 10.155 11.9213Z" fill="white"/>
                    <path d="M14.0625 15.1875H3.375V15.75H14.0625C14.3719 15.75 14.625 15.4969 14.625 15.1875V14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875Z" fill="#F1F1FF"/>
                  </svg>`;
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return `<svg width="33" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.625 1.125C3.625 0.644321 4.01932 0.25 4.5 0.25H12.2714L16.625 4.60355V16.875C16.625 17.3557 16.2307 17.75 15.75 17.75H4.5C4.01932 17.75 3.625 17.3557 3.625 16.875V1.125Z" fill="#FAFAFF" stroke="#E7E7FB" stroke-width="0.5"/>
                    <path d="M13.5 4.5H16.875L12.375 0V3.375C12.375 3.99375 12.8812 4.5 13.5 4.5Z" fill="#E7E7FB"/>
                    <path d="M14.625 14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875H1.6875C1.37812 15.1875 1.125 14.9344 1.125 14.625V9C1.125 8.69062 1.37812 8.4375 1.6875 8.4375H14.0625C14.3719 8.4375 14.625 8.69062 14.625 9V14.625Z" fill="#F54C4C"/>
                    <path d="M3.70825 10.6574C3.70825 10.5089 3.82525 10.3469 4.01369 10.3469H5.05263C5.63763 10.3469 6.16413 10.7384 6.16413 11.4888C6.16413 12.1998 5.63763 12.5958 5.05263 12.5958H4.30169V13.1898C4.30169 13.3878 4.17625 13.4997 4.01425 13.4997C3.86575 13.4997 3.70881 13.3878 3.70881 13.1898V10.6574H3.70825ZM4.30169 10.9134V12.0339H5.05263C5.35413 12.0339 5.59263 11.7678 5.59263 11.4888C5.59263 11.1744 5.35413 10.9134 5.05263 10.9134H4.30169Z" fill="white"/>
                    <path d="M6.7373 10.6574C6.7373 10.5089 6.85431 10.3469 7.04331 10.3469H8.08224C8.66724 10.3469 9.19374 10.7384 9.19374 11.4888C9.19374 12.1998 8.66724 12.5958 8.08224 12.5958H7.3313V13.1898C7.3313 13.3878 7.20531 13.4997 7.04331 13.4997C6.89481 13.4997 6.7373 13.3878 6.7373 13.1898V10.6574ZM7.33074 10.9134V12.0339H8.08168C8.38318 12.0339 8.62168 11.7678 8.62168 11.4888C8.62168 11.1744 8.38318 10.9134 8.08168 10.9134H7.33074Z" fill="white"/>
                    <path d="M10.6069 10.9497H9.82048C9.42898 10.9497 9.42898 10.3743 9.82048 10.3743H11.9473C12.3478 10.3743 12.3478 10.9497 11.9473 10.9497H11.2009V13.2211C11.2009 13.612 10.6069 13.6216 10.6069 13.2211V10.9497Z" fill="white"/>
                    <path d="M14.0625 15.1875H3.375V15.75H14.0625C14.3719 15.75 14.625 15.4969 14.625 15.1875V14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875Z" fill="#FAFAFF"/>
                  </svg>`;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return `<svg width="33" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.625 1.125C3.625 0.644321 4.01932 0.25 4.5 0.25H12.2714L16.625 4.60355V16.875C16.625 17.3557 16.2307 17.75 15.75 17.75H4.5C4.01932 17.75 3.625 17.3557 3.625 16.875V1.125Z" fill="#FAFAFF" stroke="#E7E7FB" stroke-width="0.5"/>
                    <path d="M13.5 4.5H16.875L12.375 0V3.375C12.375 3.99375 12.8812 4.5 13.5 4.5Z" fill="#E7E7FB"/>
                    <path d="M14.625 14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875H1.6875C1.37812 15.1875 1.125 14.9344 1.125 14.625V9C1.125 8.69062 1.37812 8.4375 1.6875 8.4375H14.0625C14.3719 8.4375 14.625 8.69062 14.625 9V14.625Z" fill="#65BF73"/>
                    <path d="M5.0737 11.4679L5.85614 10.4874C6.09014 10.1814 6.54465 10.5724 6.28814 10.8649C6.01871 11.1844 5.73521 11.5309 5.4652 11.8858L6.38264 13.0283C6.63015 13.3658 6.13571 13.6892 5.89271 13.3562L5.0647 12.2948L4.25076 13.3793C4.02126 13.7072 3.51276 13.3388 3.76926 13.0328L4.6732 11.8858C4.3897 11.5303 4.1152 11.1844 3.84126 10.8649C3.55832 10.5274 4.08426 10.1814 4.29126 10.497L5.0737 11.4679Z" fill="white"/>
                    <path d="M6.9375 10.658C6.9375 10.5095 7.0635 10.3835 7.221 10.3835C7.365 10.3835 7.4865 10.5101 7.4865 10.658V12.9114H8.70994C9.15037 12.9114 9.15994 13.5004 8.70994 13.5004H7.22156C7.06406 13.5004 6.93806 13.3884 6.93806 13.2264V10.658H6.9375Z" fill="white"/>
                    <path d="M9.56345 11.0628C9.66695 10.1898 10.9843 10.0328 11.6053 10.5098C11.9113 10.7568 11.5873 11.1483 11.3173 10.9503C10.9843 10.7393 10.2289 10.6403 10.1344 11.1072C10.0129 11.8452 11.9693 11.4228 11.9378 12.6192C11.9063 13.7616 10.2514 13.7881 9.63039 13.2756C9.48133 13.1541 9.48639 12.9567 9.56739 12.8346C9.68439 12.7182 9.81433 12.6777 9.96789 12.8037C10.3363 13.0551 11.2858 13.2441 11.3528 12.6051C11.2948 11.9403 9.42395 12.3402 9.56345 11.0628Z" fill="white"/>
                    <path d="M14.0625 15.1875H3.375V15.75H14.0625C14.3719 15.75 14.625 15.4969 14.625 15.1875V14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875Z" fill="#FAFAFF"/>
                  </svg>`;
        case "application/pdf":
          return `<svg width="33" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.625 1.125C3.625 0.644321 4.01932 0.25 4.5 0.25H12.2714L16.625 4.60355V16.875C16.625 17.3557 16.2307 17.75 15.75 17.75H4.5C4.01932 17.75 3.625 17.3557 3.625 16.875V1.125Z" fill="#FAFAFF" stroke="#E7E7FB" stroke-width="0.5"/>
                    <path d="M13.5 4.5H16.875L12.375 0V3.375C12.375 3.99375 12.8812 4.5 13.5 4.5Z" fill="#E7E7FB"/>
                    <path d="M14.625 14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875H1.6875C1.37812 15.1875 1.125 14.9344 1.125 14.625V9C1.125 8.69062 1.37812 8.4375 1.6875 8.4375H14.0625C14.3719 8.4375 14.625 8.69062 14.625 9V14.625Z" fill="#F54C4C"/>
                    <path d="M3.57666 10.6574C3.57666 10.5089 3.69366 10.3469 3.8821 10.3469H4.92103C5.50603 10.3469 6.03254 10.7384 6.03254 11.4888C6.03254 12.1998 5.50603 12.5958 4.92103 12.5958H4.1701V13.1898C4.1701 13.3878 4.0441 13.4997 3.8821 13.4997C3.7336 13.4997 3.57666 13.3878 3.57666 13.1898V10.6574ZM4.1701 10.9134V12.0339H4.92103C5.22253 12.0339 5.46103 11.7678 5.46103 11.4888C5.46103 11.1744 5.22253 10.9134 4.92103 10.9134H4.1701Z" fill="white"/>
                    <path d="M6.91328 13.4993C6.76478 13.4993 6.60278 13.4183 6.60278 13.2208V10.666C6.60278 10.5045 6.76478 10.387 6.91328 10.387H7.94322C9.99859 10.387 9.95359 13.4993 7.98372 13.4993H6.91328ZM7.19678 10.936V12.9508H7.94322C9.15765 12.9508 9.21165 10.936 7.94322 10.936H7.19678Z" fill="white"/>
                    <path d="M10.6821 10.9725V11.6875H11.8291C11.9911 11.6875 12.1531 11.8495 12.1531 12.0064C12.1531 12.1549 11.9911 12.2764 11.8291 12.2764H10.6821V13.2208C10.6821 13.3783 10.5702 13.4993 10.4127 13.4993C10.2147 13.4993 10.0938 13.3783 10.0938 13.2208V10.666C10.0938 10.5045 10.2153 10.387 10.4127 10.387H11.9916C12.1896 10.387 12.3066 10.5045 12.3066 10.666C12.3066 10.81 12.1896 10.972 11.9916 10.972H10.6821V10.9725Z" fill="white"/>
                    <path d="M14.0625 15.1875H3.375V15.75H14.0625C14.3719 15.75 14.625 15.4969 14.625 15.1875V14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875Z" fill="#FAFAFF"/>
                  </svg>`;
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return `<svg width="28" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.68159 0H15.3181C16.6941 0 17.8173 1.12324 17.8173 2.49922V15.5008C17.8173 16.8768 16.6941 18 15.3181 18H2.68159C1.30562 18 0.182373 16.8768 0.182373 15.5008V2.47114C0.182373 1.12325 1.30562 0 2.68159 0Z" fill="#8D96EC"/>
                    <path d="M8.3259 11.7658L4.64727 8.05912L0.182373 12.524V13.4788V15.5006C0.182373 16.8766 1.30562 17.9998 2.68159 17.9998H15.3181C16.6941 17.9998 17.8173 16.8766 17.8173 15.5006V13.4788V10.6987L13.6332 6.48657L8.3259 11.7658Z" fill="#DDDDFF"/>
                    <path d="M8.24149 7.52595C9.10998 7.52595 9.81403 6.82189 9.81403 5.9534C9.81403 5.08491 9.10998 4.38086 8.24149 4.38086C7.373 4.38086 6.66895 5.08491 6.66895 5.9534C6.66895 6.82189 7.373 7.52595 8.24149 7.52595Z" fill="#FFCC03"/>
                  </svg>`;
        case "video/mp4":
        case "audio/mpeg":
        case "video/quicktime":
        case "video/3gpp":
        case "video/x-ms-wmv":
        case "video/mpeg":
        case "video/x-flv":
        case "video/x-msvideo":
          return `<svg width="33" height="50" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M16.3594 16.5407V1.45927C16.3594 0.653344 15.7062 3.51563e-05 14.9002 0H1.46244C0.656518 0 0.00317383 0.653344 0.00317383 1.45927V16.5407C0.00317383 17.3467 0.656518 18 1.46244 18H14.9002C15.7062 18 16.3594 17.3466 16.3594 16.5407Z" fill="#F1F1FF"/>
                  <path d="M16.5377 0H14.5378C15.3438 0 15.9971 0.653344 15.9971 1.45927V16.5407C15.9971 17.3467 15.3438 18 14.5378 18H16.5377C17.3436 18 17.9969 17.3467 17.9969 16.5407V1.45927C17.997 0.653344 17.3436 0 16.5377 0V0Z" fill="#F1F1FF"/>
                  <path d="M1.98901 13.4678V2.57566C1.98901 2.27863 2.2298 2.03784 2.52683 2.03784H15.4735C15.7705 2.03784 16.0113 2.27863 16.0113 2.57566V13.4678C16.0113 13.7648 15.7705 14.0056 15.4735 14.0056H2.52683C2.2298 14.0056 1.98901 13.7648 1.98901 13.4678Z" fill="#8D96EC"/>
                  <path d="M15.4734 2.03784H13.4875C13.7846 2.03784 14.0254 2.27863 14.0254 2.57566V13.4678C14.0254 13.7648 13.7846 14.0056 13.4875 14.0056H15.4734C15.7704 14.0056 16.0112 13.7648 16.0112 13.4678V2.57566C16.0112 2.27863 15.7704 2.03784 15.4734 2.03784Z" fill="#8D96EC"/>
                  <path d="M9.00013 11.6861C11.0212 11.6861 12.6597 10.0476 12.6597 8.0265C12.6597 6.00538 11.0212 4.36694 9.00013 4.36694C6.97902 4.36694 5.34058 6.00538 5.34058 8.0265C5.34058 10.0476 6.97902 11.6861 9.00013 11.6861Z" fill="white"/>
                  <path d="M9.00005 4.36694C8.65517 4.36694 8.32146 4.41472 8.00513 4.50391C9.54276 4.93732 10.6698 6.35028 10.6698 8.02654C10.6698 9.70279 9.54276 11.1158 8.00513 11.5492C8.32146 11.6383 8.65517 11.6861 9.00005 11.6861C11.0212 11.6861 12.6596 10.0477 12.6596 8.02657C12.6596 6.0054 11.0212 4.36694 9.00005 4.36694Z" fill="white"/>
                  <path d="M11.0695 7.72801L8.17305 6.05573C7.94327 5.92305 7.65601 6.08889 7.65601 6.35424V9.6988C7.65601 9.96416 7.94327 10.13 8.17305 9.99731L11.0695 8.32503C11.2993 8.19239 11.2993 7.86069 11.0695 7.72801Z" fill="#8D96EC"/>
                  <path d="M11.0696 7.72793L10.6274 7.47266C10.6549 7.65332 10.6698 7.83811 10.6698 8.02644C10.6698 8.21477 10.6549 8.39955 10.6274 8.58022L11.0696 8.32495C11.2993 8.19231 11.2993 7.86061 11.0696 7.72793Z" fill="#8D96EC"/>
                  <path d="M16.3193 16.4102H5.22523C5.07518 16.4102 4.95361 16.2886 4.95361 16.1386C4.95361 15.9885 5.07522 15.8669 5.22523 15.8669H16.3193C16.4694 15.8669 16.5909 15.9885 16.5909 16.1386C16.591 16.2885 16.4694 16.4102 16.3193 16.4102Z" fill="#4E57AA"/>
                  <path d="M2.67508 16.4102H1.68104C1.53099 16.4102 1.40942 16.2886 1.40942 16.1386C1.40942 15.9885 1.53103 15.8669 1.68104 15.8669H2.67508C2.82513 15.8669 2.9467 15.9885 2.9467 16.1386C2.9467 16.2885 2.8251 16.4102 2.67508 16.4102Z" fill="#4E57AA"/>
                  <path d="M3.94545 17.0202C3.7954 17.0202 3.67383 16.8986 3.67383 16.7485V15.5287C3.67383 15.3787 3.79543 15.2571 3.94545 15.2571C4.09549 15.2571 4.21706 15.3787 4.21706 15.5287V16.7485C4.2171 16.8986 4.09549 17.0202 3.94545 17.0202Z" fill="#8D96EC"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="18" height="18" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>`;
        default:
          break;
      }
    },
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + " Тб";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + " Гб";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + " Мб";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + " Кб";
      } else {
        return size + " байт";
      }
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= 10) {
        return text;
      } else return text.substr(0, 8) + "..." + extension[extension.length - 1];
    },
    setActiveHeader(idx) {
      this.activeHeader = idx;
    },
    askDeleteEvent() {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Вы уверены, что хотите удалить памятку?`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteRegulation();
          }
        });
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getDataInfoUser.roles
        ? this.$store.getters.getDataInfoUser.roles.includes("knowledge_base_admin")
        : false;
    },
    getCreateMode() {
      return this.$store.getters.getCreateMode;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.regulation-page {
  width: 100%;
  margin-left: 30px;

  @media (max-width: $lg) {
    margin-left: 0;
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 20%;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__grid {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-start;

    @media (max-width: calc(768px - 1px)) {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 100%;
    min-width: 320px;
    padding: 30px 30px 50px 30px;
    margin-right: 30px;
    border: 1px solid $color-contour;
    border-radius: 10px;
    background-color: $color-white;

    @media (max-width: calc(768px - 1px)) {
      order: 2;
      margin: 0 0 30px;
    }

    @media (max-width: $sm) {
      min-width: 100%;
      padding: 20px 20px 50px 20px;
    }

    &__toolbar {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 500px;

      .tag {
        margin-bottom: 5px;

        @media (max-width: $xs) {
          max-width: 165px;
          word-break: break-word;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      .icon-delete,
      .icon-edit {
        cursor: pointer;
        transition: all 0.2s ease;
        color: $color-interactive;
        font-size: 22px;

        @media screen and (max-width: $sm) {
          font-size: 18px;
        }

        &:not(:last-child) {
          margin-right: 15px;

          @media screen and (max-width: $sm) {
            margin-right: 10px;
          }
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      &__people {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        &__img {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 10px;

          img {
            width: 30px;
            height: 30px;

            object-fit: cover;
            border-radius: 50%;

            &:not(:first-child) {
              margin-left: -15px;
            }
          }
        }

        &__names {
          display: flex;
          flex-direction: column;

          &__author {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: $color-text-primary-1;
          }

          &__co-authors {
            display: flex;
            align-items: center;
          }

          &__co-author {
            margin-right: 5px;

            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: $color-text-secondary;
          }
        }
      }

      &__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        user-select: none;
      }

      &__likes {
        display: flex;
        align-items: center;
        margin-right: 20px;

        svg {
          margin-right: 8px;

          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            fill: $color-button-hover;
            transform: scale(1.1);
          }

          &:active {
            fill: $color-button-click;
            transform: scale(0.9);
          }
        }

        span {
          font-size: 14px;
          line-height: 13px;
          font-weight: 400;
          color: $color-other-1;
        }
      }

      &__views {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        vertical-align: middle;
        font-weight: 400;
        color: $color-other-1;

        .icon-eye {
          margin-right: 8px;
          margin-bottom: 3px;
          font-size: 22px;
        }
      }

      &__title {
        word-break: break-word;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-accent;
      }
    }

    &__content {
      word-break: break-word;
      font-family: "SF", sans-serif;
      font-size: 14px;

      /deep/ {
        h1,
        h2,
        h3,
        h4 {
          margin-bottom: 16px;
          color: $text-default;
        }

        h1 {
          @media (max-width: $sm) {
            font-size: 26px !important;
          }
        }
        h2 {
          @media (max-width: $sm) {
            font-size: 24px !important;
          }
        }
        h3 {
          @media (max-width: $sm) {
            font-size: 20px !important;
          }
        }
        h4 {
          @media (max-width: $sm) {
            font-size: 18px !important;
          }
        }

        p,
        li,
        blockquote {
          margin-bottom: 8px;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: $text-default !important;
        }

        p span {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: $text-default !important;
        }

        blockquote {
          font-style: italic;
          margin: 8px 0 8px 16px;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        table {
          border-collapse: collapse;
        }

        td {
          font-family: "SF", sans-serif;
          border: 1px solid transparent;
          font-size: 14px;
          text-align: center;
          vertical-align: center;
          padding: 4px;
        }

        a {
          color: $text-default;
        }

        a:hover,
        a:focus {
          color: $text-subdued;
        }
      }
    }
  }

  &__apps {
    position: sticky;
    top: 70px;
    display: flex;
    flex-direction: column;
    z-index: 300;

    @media (max-width: calc(768px - 1px)) {
      order: 1;
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      top: 0;
    }

    &__item {
      width: 240px;
      min-width: 196px;
      min-height: 410px;
      padding: 20px;

      @media (max-width: calc(768px - 1px)) {
        max-width: 100%;
        width: 100%;
        min-height: fit-content;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      border: 1px solid $color-contour;
      border-radius: 10px;
      background-color: $color-white;

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-accent;
      }
    }

    &__table-of-contents {
      h3 {
        margin-bottom: 20px;
      }

      &__items {
        display: flex;
        flex-direction: column;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;

          color: $color-text-primary-2;
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;

          cursor: pointer;
          transition: all 0.2s ease;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &.active {
            color: $color-interactive;
          }
        }
      }
    }

    &__docs {
      h3 {
        margin-bottom: 15px;
      }

      &__files {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 10px;
          }

          &__title {
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $color-text-accent;
          }

          &__size {
            margin-top: 3px;
            display: block;
            margin-left: auto;

            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $color-text-primary-2;
            @media screen and (max-width: $xxl) {
              padding-left: 5px;
            }
          }
        }
      }

      &__decoration-line {
        width: 100%;
        height: 1px;
        margin: 20px 0;

        background-color: $color-contour;
      }

      &__links {
        h4 {
          color: $color-text-accent;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-top: 0;
        }

        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .icon-chain {
            margin-right: 10px;
            font-size: 20px;
            color: $color-text-secondary;
          }

          a {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            color: $color-interactive;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;

            transition: all 0.2s ease;

            &:hover {
              color: $color-button-hover;
            }

            &:active {
              color: $color-button-click;
            }
          }
        }
      }
    }
  }

  .button-scroll-top {
    position: fixed;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ddddff;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
  }
  .button-scroll-top:hover {
    box-shadow: 0 4px 5px rgba(221, 221, 255, 0.62);
  }

  .icon-up {
    display: block;
    color: $color-interactive;
    font-size: 20px;
  }
}
</style>
