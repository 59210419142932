












import { Component, Prop, Vue } from "vue-property-decorator";
import WeekItem from "@/aopV2/modules/Calendar/components/CalendarWeekList/WeekItem.vue";

@Component({
  components: { WeekItem }
})
export default class CalendarWeekList extends Vue {
  @Prop({
    default: () => [],
    type: Array
  })
  selectDays!: Array<number>;
  weekdays: Array<{ name: string; index: number }> = [
    { name: "Пн", index: 1 },
    { name: "Вт", index: 2 },
    { name: "Ср", index: 3 },
    { name: "Чт", index: 4 },
    { name: "Пт", index: 5 },
    { name: "Сб", index: 6 },
    { name: "Вс", index: 0 }
  ];

  handleDay(data: { day: { name: string; index: number }; isActive: boolean }) {
    let selected = this.selectDays;
    if (data.isActive) {
      selected = this.selectDays.filter((item) => item !== data.day.index);
    } else {
      selected.push(data.day.index);
    }
    this.$emit("change", selected);
  }
}
