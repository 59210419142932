<template>
  <div class="my-isp" ref="myIsp">
    <IspMyHeader v-if="quarterList.length" />
    <div class="my-isp__header-table">
      <TableHeader :tableHeadList="tableHead" v-if="showTable" />
    </div>
    <div class="my-isp__spinner" v-if="loaderTable">
      <Spinner />
    </div>
    <div class="my-isp__container" v-if="showTable">
      <Table :tableHead="tableHead" :tableData="problemsData" :showEmpty="false">
        <template slot-scope="props">
          <ISPCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
      <h2 class="my-isp__backlog">Бэклог</h2>
      <Table :tableHead="tableHead" :showHead="false" :tableData="backlogProblemsData">
        <template slot-scope="props">
          <ISPCellContent :row="props.row" :cell="props.cell" />
        </template>
      </Table>
    </div>
    <div class="my-isp__container_cards">
      <IspCard v-for="item in problemsData" :key="item.id" :card="item" />
      <h2 class="my-isp__backlog">Бэклог</h2>
      <IspCard v-for="item in backlogProblemsData" :key="item.id" :card="item" />
    </div>
    <div class="my-isp__empty" v-if="!quarterList.length && !loaderQuarter">
      <p class="my-isp__empty-title">Список периодов пуст</p>
      <Button
        v-if="isAdminIsp"
        :type="'white'"
        :icon="'add-task'"
        :title="'Создать период'"
        @handleButton="addQuarter"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import IspMyHeader from "@/modules/Isp/components/IspMy/IspMyHeader";
import Table from "@/modules/Isp/components/Table/Table";
import ISPCellContent from "@/modules/Isp/components/IspComponents/ISPCellContent";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import TableHeader from "@/modules/Isp/components/Table/TableHeader";
import { EventBus } from "@/main";
import Button from "@/modules/UiKit/components/buttons/Button";
import IspCard from "@/modules/Isp/components/IspComponents/IspCard";

export default {
  name: "IspMy",
  mixins: [],
  props: {},
  components: { IspCard, Spinner, IspMyHeader, Table, ISPCellContent, TableHeader, Button },
  data() {
    return {
      tableHead: [
        { name: "Критичность", sort: true, valueSort: "criticality_id", key: "criticality" },
        { name: "Номер", sort: true, valueSort: "id", key: "number" },
        { name: "Проблема", sort: false, key: "problem" },
        { name: "Заказчик", sort: false, key: "customer_user" },
        {
          name: "Ответственный",
          sort: false,
          key: "responsible_user"
        },
        { name: "Прогресс", sort: true, valueSort: "completion_percentage", key: "progress" },
        { name: "Статус", sort: false, key: "status" }
      ]
    };
  },
  beforeDestroy() {
    this.$refs.myIsp.removeEventListener("scroll", () => {
      EventBus.$emit("hideFilters");
    });
  },
  created() {
    this.getCookieValue();
    this.isFilters();
  },
  mounted() {
    this.$refs.myIsp.addEventListener("scroll", () => {
      EventBus.$emit("hideFilters");
    });
    setTimeout(() => {
      this.initializeTable();
    }, 0);
  },
  methods: {
    ...mapActions("IspModule", ["getMyIspProblemsList"]),
    ...mapMutations("IspModule", [
      "SET_MY_ISP_PROBLEM_PARAMS",
      "CLEAR_TABLE_DATA",
      "SET_MY_ISP_CRITICALITY",
      "SET_MY_ISP_STATUS",
      "SET_MY_ISP_ROLES",
      "SET_MY_ISP_GROUPS",
      "SET_MY_ISP_CUSTOMER",
      "SET_MY_ISP_RESPONSIBLE",
      "IS_MY_ISP_FILTERS",
      "SHOW_ADD_AND_EDIT_QUARTER",
      "SET_EDIT_QUARTER"
    ]),
    initializeTable() {
      this.CLEAR_TABLE_DATA();
      this.SET_MY_ISP_PROBLEM_PARAMS(this.myIspParams);
      if (this.currentQuarter) this.getMyIspProblemsList();
    },
    isFilters() {
      this.IS_MY_ISP_FILTERS(
        !!(
          this.myIspRolesValue.length ||
          this.myIspCriticalityValue.length ||
          this.myIspStatusValue.length ||
          this.myIspGroupValue.length ||
          this.myIspResponsibleValue.length ||
          this.myIspCustomersValue.length
        )
      );
    },
    getCookieValue() {
      this.localStorage = window.localStorage;
      const setting = JSON.parse(this.localStorage.getItem("my-isp-table-setting"));
      this.SET_MY_ISP_CRITICALITY(setting.criticality ? setting.criticality : []);
      this.SET_MY_ISP_STATUS(setting.status ? setting.status : []);
      this.SET_MY_ISP_ROLES(setting.roles ? setting.roles : []);
      this.SET_MY_ISP_GROUPS(setting.groups ? setting.groups : []);
      this.SET_MY_ISP_CUSTOMER(setting.customer ? setting.customer : []);
      this.SET_MY_ISP_RESPONSIBLE(setting.responsible ? setting.responsible : []);
    },
    addQuarter() {
      this.SHOW_ADD_AND_EDIT_QUARTER(true);
      this.SET_EDIT_QUARTER(false);
    }
  },
  computed: {
    ...mapGetters(["getEndpoints"]),
    ...mapState("IspModule", [
      "quarterList",
      "currentQuarter",
      "problemsData",
      "backlogProblemsData",
      "problemsMyIspParams",
      "loaderTable",
      "loaderQuarter",
      "valueSortList",
      "valueSort",
      "myIspCriticalityValue",
      "myIspStatusValue",
      "myIspGroupValue",
      "myIspCustomersValue",
      "myIspResponsibleValue",
      "myIspRolesValue"
    ]),
    showTable() {
      return !this.loaderTable && this.quarterList.length;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    myIspParams() {
      return {
        _count: 300,
        _order: JSON.stringify({
          [this.valueSort.value]: this.valueSort.isAsc ? "asc" : "desc"
        }),
        flow_status_id: this.myIspStatusValue.length
          ? JSON.stringify(this.myIspStatusValue.map((item) => item.id))
          : undefined,
        group_id: this.myIspGroupValue.length
          ? JSON.stringify(this.myIspGroupValue.map((item) => item.id))
          : undefined,
        criticality_id: this.myIspCriticalityValue.length
          ? JSON.stringify(this.myIspCriticalityValue.map((item) => item.id))
          : undefined,
        responsible_user_id: this.setResponsibleUsers,
        creator_user_id:
          this.myIspRolesValue.length && this.myIspRolesValue[0].name === "Автор"
            ? this.getUserId
            : undefined,
        customer_user_id: this.setCustomerUsers,
        task_executors:
          this.myIspRolesValue.length && this.myIspRolesValue[0].name === "Исполнитель"
            ? JSON.stringify({ executor_user_id: this.getUserId })
            : undefined,
        viewer_users:
          this.myIspRolesValue.length && this.myIspRolesValue[0].name === "Наблюдатель"
            ? JSON.stringify({ viewer_user_id: this.getUserId })
            : undefined,
        _with: JSON.stringify(["group", "responsible_user", "customer_user", "status"])
      };
    },
    setResponsibleUsers() {
      if (
        this.myIspRolesValue.length &&
        this.myIspRolesValue[0].name === "Ответственный" &&
        this.myIspResponsibleValue.length
      ) {
        return JSON.stringify([
          this.getUserId,
          ...this.myIspResponsibleValue.map((item) => item.id)
        ]);
      } else if (this.myIspRolesValue.length && this.myIspRolesValue[0].name === "Ответственный") {
        return JSON.stringify([this.getUserId]);
      } else if (this.myIspResponsibleValue.length) {
        return JSON.stringify(this.myIspResponsibleValue.map((item) => item.id));
      } else {
        return undefined;
      }
    },
    setCustomerUsers() {
      if (
        this.myIspRolesValue.length &&
        this.myIspRolesValue[0].name === "Заказчик" &&
        this.myIspCustomersValue.length
      ) {
        return JSON.stringify([this.getUserId, ...this.myIspCustomersValue.map((item) => item.id)]);
      } else if (this.myIspRolesValue.length && this.myIspRolesValue[0].name === "Заказчик") {
        return JSON.stringify([this.getUserId]);
      } else if (this.myIspCustomersValue.length) {
        return JSON.stringify(this.myIspCustomersValue.map((item) => item.id));
      } else {
        return undefined;
      }
    },
    isAdminIsp() {
      return this.getEndpoints("ISPPeriod", "actionCreate");
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $text-highlight;
  }
}

::-webkit-scrollbar-track {
  margin-left: 5px;
  background: #fafafe;
  cursor: pointer;
}

.my-isp {
  position: relative;
  width: 100%;
  font-family: "Roboto", sans-serif !important;
  height: 90%;
  overflow-y: scroll;
  padding-right: 16px;

  /deep/ {
    .table {
      width: 100%;
      height: auto;
      min-height: auto;

      .table__row {
        width: 100%;
      }
    }

    .table__head {
      width: 100%;
    }

    .table__cell {
      &:first-child {
        max-width: 180px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: 120px;
      }

      &:nth-child(5) {
        max-width: 140px;
      }

      &:nth-child(6),
      &:last-child {
        max-width: 150px;
      }
    }
  }

  &__header {
    position: relative;
    &-table {
      @media (max-width: $lg) {
        display: none;
      }
    }
  }
  &__container {
    @media (max-width: $lg) {
      display: none;
    }
    &_cards {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }
    }
  }

  &__backlog {
    font-weight: bold;
    font-size: 25px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #343748;
    padding: 24px;
  }

  &__spinner {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0 0;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 350px 0 0;
  }

  &__empty-title {
    width: 100%;
    margin: 0 0 40px;
    font-weight: normal;
    font-size: 36px;
    line-height: 20px;
    text-align: center;
    color: $text-default;
  }
}
</style>
