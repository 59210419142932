

















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal";
import ButtonIcon from "@/aopV2/components/UIComponents/Button/ButtonIcon";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign";
import ActionList from "@/aopV2/components/UIComponents/ActionList/ActionList";
import { IActionItem } from "@/aopV2/components/UIComponents/ActionList/ActionList.vue";
import ChipsItem from "@/aopV2/components/UIComponents/Chips/ChipsItem.vue";
import InputDate from "@/aopV2/components/UIComponents/Input/Date/InputDate.vue";
import moment from "moment";
import InputTime from "@/aopV2/components/UIComponents/Input/Time/InputTime.vue";
import TextLabel from "@/aopV2/components/UIComponents/Input/Label/TextLabel.vue";
import InputSelect from "@/aopV2/components/UIComponents/Input/Select/InputSelect.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew.vue";
import copyToClipboard from "@/helpers/CopyToClipboard";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import UserCardList from "@/aopV2/components/UIComponents/User/UserCardList.vue";
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap.vue";
import { eventToDataAdapter } from "@/aopV2/modules/Calendar/helper/helperFunc.ts";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import FileItem from "@/aopV2/components/UIComponents/File/FileItem";

@Component({
  components: {
    FileItem,
    IcoMoonWrap,
    UserCardList,
    Skeleton,
    MainButtonNew,
    InputSelect,
    TextLabel,
    InputTime,
    InputDate,
    ChipsItem,
    ActionList,
    ButtonNewDesign,
    TextInput,
    ButtonIcon,
    MainModal
  }
})
export default class ViewEvent extends Vue {
  isShowList: boolean = false;
  isShowListInvitation: boolean = false;
  name: string = "";
  weekdays: string[] = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  isFull: boolean = true;
  isDragModal: boolean = true;
  colorList = {
    0: "primary",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10"
  };

  actionListInvitation: Array<IActionItem> = [
    {
      name: "Приду",
      id: 1,
      type: "true"
    },
    {
      name: "Не приду",
      id: 2,
      type: "false"
    },
    {
      name: "Возможно",
      id: 3,
      type: "null"
    }
  ];
  currentItem: IActionItem = { name: "Редактировать", id: 1, type: "edit" };
  selectDate: string = moment(new Date()).format("YYYY-MM-DD");

  mounted() {
    this.$nextTick(() => {
      this.setPositionEvent(this.eventId);
    });
    this.isFull = this.getWidthWindow <= 768;
  }

  positionEvent = { y: 0, x: 0 };

  get eventId() {
    return `#event_${this.$route.query.event_id}`;
  }

  setPositionEvent(id: string = "#event_undefined", width: number = 423, height: number = 380) {
    const event = document.querySelector(id);
    const rect = event!.getBoundingClientRect();
    let y = rect.top - height / 2 + rect.height / 2;
    y = this.windowHeight - y - height > 20 ? y : this.windowHeight - height - 20;
    const x = rect.left - width > 0 ? rect.left - width : rect.left + rect.width;
    this.positionEvent = { y, x };
  }

  get windowHeight() {
    return this.$store.getters["MainLayoutSetting/height"];
  }

  checkFormatData(value) {
    moment.locale("ru");
    return moment(value).format("Do MMMM");
  }

  copyLink() {
    copyToClipboard(this.jitsiLink);
  }

  get eventPassed() {
    return moment(this.currentEvent?.started_at).isBefore(moment());
  }

  get typeButtonPrimary() {
    return this.currentEvent?.is_accepted_invitation !== true && !this.eventPassed
      ? "primary"
      : "disabled";
  }

  get typeButtonPrimaryForFullModal() {
    return !this.eventPassed ? "primary" : "disabled";
  }

  get typeButtonSecondary() {
    return this.currentEvent?.is_accepted_invitation !== null && !this.eventPassed
      ? "secondary"
      : "disabled";
  }

  get typeButtonTriatry() {
    return this.currentEvent?.is_accepted_invitation !== false && !this.eventPassed
      ? "triatry"
      : "disabled";
  }

  get setClassIconResize() {
    return this.isFull ? "icon-close-fullscreen" : "icon-arrow-resize";
  }

  async deleteEvent() {
    ModalModule.toggleLoader(true);
    await CalendarModule2.deleteEventAction();
    ModalModule.toggleLoader(false);
    this.hideModal();
    ModalModule.toggleConfirmation(false);
    CalendarModule2.setJustCurrent(true);
  }

  closeActionList() {
    this.isShowList = false;
  }

  async setCurrentType(type) {
    this.currentItem = type;
    this.closeList();
    if (type.type === "delete") {
      if (this.currentEvent?.repeat) {
        ModalModule.toggleConfirmation(true);

        ModalModule.setConfirmSetting({
          title: "Удалить повторяющееся мероприятие?",
          text: "Вы можете удалить как одно, так и несколько мероприятий.",
          component: () =>
            import(
              "@/aopV2/modules/Calendar/components/Modal/ConfirmationRepeatModal/ConfirmationRepeatModal"
            ),
          cancelButton: "Отменить",
          handleCancel: () => {
            ModalModule.toggleConfirmation(false);
            CalendarModule2.setJustCurrent(true);
          },
          confirmButton: "Удалить",
          handleConfirm: this.deleteEvent
        });
      }

      if (!this.currentEvent?.repeat) {
        ModalModule.toggleLoader(true);
        await CalendarModule2.deleteEventAction();
        ModalModule.toggleLoader(false);

        this.hideModal();
      }
    }
    if (type.type === "copyLink") {
      copyToClipboard(window.location.href);
    }
    if (type.type === "edit") {
      CalendarModule2.setEditEvent(this.currentEvent!.id);
      const data = eventToDataAdapter(
        this.currentEvent!,
        this.eventTypes,
        this.locations,
        this.timezone
      );
      CalendarModule2.setCreateData(data);
      this.$router.push({ name: "CreateEvent" }).finally();
    }
  }

  get isResponsible() {
    return this.getResponsibleUsers
      ? this.getResponsibleUsers.some((item) => item.id === this.$store.getters.getDataInfoUser.id)
      : false;
  }

  get isMember() {
    return this.getMembersUsers
      ? this.getMembersUsers.some((item) => item.id === this.$store.getters.getDataInfoUser.id)
      : false;
  }

  get showButtonInvitation() {
    return this.isMember || this.isResponsible;
  }

  get timezone() {
    return CalendarModule2.timezone;
  }

  get getActionListEvent() {
    return this.isResponsible
      ? [
          {
            name: "Копировать ссылку",
            id: 1,
            type: "copyLink"
          },
          {
            name: "Редактировать",
            id: 2,
            type: "edit"
          },
          {
            name: "Удалить",
            id: 3,
            type: "delete"
          }
        ]
      : [
          {
            name: "Копировать ссылку",
            id: 1,
            type: "copyLink"
          }
        ];
  }

  setCurrentValueInvitation(value) {
    const payload = JSON.parse(value.type);
    this.handleChangeInvitation(payload);
  }

  changeDate(date) {
    this.selectDate = date;
  }

  openFullEventCard() {
    this.isFull = !this.isFull;
    this.isDragModal = !this.isDragModal;
  }

  closeList() {
    this.isShowList = false;
  }

  toggleList() {
    this.isShowList = !this.isShowList;
  }

  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get isMobileWindowSize() {
    return this.getWidthWindow <= 768;
  }

  resizeHandler() {
    this.isFull = this.getWidthWindow <= 768;
  }

  toggleListInvitation() {
    if (!this.eventPassed) {
      this.isShowListInvitation = !this.isShowListInvitation;
    }
  }

  closeListInvitation() {
    this.isShowListInvitation = false;
  }

  setEndingCountUsers(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  }

  get getCountMembers() {
    return `${this.currentEvent?.users?.length} ${this.setEndingCountUsers(
      this.currentEvent?.users?.length,
      "участник",
      "участника",
      "участников"
    )}`;
  }

  closeModal(e) {
    const paths = e.path || e.composedPath();
    const isClose = !paths.some(
      (item) => item.className === "confirmation-modal" || item.className === "modal-employment"
    );
    if (isClose) {
      this.hideModal();
    }
  }

  hideModal() {
    if (this.$route.query.event_id) {
      this.$router.replace({ query: {} });
    }
    CalendarModule2.toggleViewModal(false);
    CalendarModule2.clearCurrentEvent();
  }

  get getJustCurrent() {
    return CalendarModule2.justCurrent;
  }

  async handleChangeInvitation(value: boolean | null) {
    if (this.currentEvent?.is_accepted_invitation !== value && !this.eventPassed) {
      if (this.currentEvent?.repeat) {
        ModalModule.toggleConfirmation(true);

        ModalModule.setConfirmSetting({
          title: "Изменить повторяющееся мероприятие?",
          text: "Вы можете изменить как одно, так и несколько мероприятий.",
          component: () =>
            import(
              "@/aopV2/modules/Calendar/components/Modal/ConfirmationRepeatModal/ConfirmationRepeatModal"
            ),
          cancelButton: "Отменить",
          handleCancel: () => {
            ModalModule.toggleConfirmation(false);
            CalendarModule2.setJustCurrent(true);
          },
          confirmButton: "Изменить",
          handleConfirm: async () => {
            const payload = {
              event_id: this.currentEvent?.id!,
              is_accepted_invitation: value,
              just_current: this.getJustCurrent
            };
            await CalendarModule2.changeInvitation(payload);
            ModalModule.toggleConfirmation(false);
            CalendarModule2.setJustCurrent(true);
          }
        });
      }

      if (!this.currentEvent?.repeat) {
        const payload = {
          event_id: this.currentEvent?.id!,
          is_accepted_invitation: value,
          just_current: true
        };
        await CalendarModule2.changeInvitation(payload);
        ModalModule.toggleConfirmation(false);
        CalendarModule2.setJustCurrent(true);
      }
    }
  }

  get currentEvent() {
    return CalendarModule2.currentEvent;
  }

  get jitsiLink() {
    return this.currentEvent?.jitsi_link
      ? `https://meet.jit.si/${this.currentEvent?.jitsi_link}`
      : null;
  }

  get setStyleClasses() {
    return [`modal__type_${this.color}`];
  }

  get eventTypes() {
    return CalendarModule2.eventType;
  }

  get getEventType() {
    return this.eventTypes.find((item) => item.id === this.currentEvent?.type_id);
  }

  get isPrivateEvent() {
    return this.currentEvent?.is_private;
  }

  get isForAllEvent() {
    return this.currentEvent?.is_for_all;
  }

  get loader() {
    return CalendarModule2.loader;
  }

  get dateStart() {
    return this.currentEvent ? moment(this.currentEvent.started_at).format("DD.MM.YYYY") : "";
  }

  get timeStart() {
    return this.currentEvent ? moment(this.currentEvent.started_at).format(" HH:mm") : "";
  }

  get timeEnd() {
    return this.currentEvent ? moment(this.currentEvent.ended_at).format(" HH:mm") : "";
  }

  get color() {
    return this.getEventType ? this.colorList[this.getEventType.color_number] : null;
  }

  get locations() {
    return CalendarModule2.locations;
  }

  get location() {
    const locations = this.locations.map((item) => item);
    locations.unshift({
      name: "Без кабинета",
      id: 0
    });
    return locations.find((item) =>
      this.currentEvent?.location_id ? item.id === this.currentEvent?.location_id : item.id === 0
    );
  }

  get getResponsibleUsers() {
    return this.currentEvent?.responsible_users.length
      ? this.$store.getters["UsersModule/userList"]
          .filedList(["full_name", "avatar", "id", "is_active", "roles"])
          .filter(
            (item) =>
              item.is_active &&
              this.currentEvent?.responsible_users.map((item) => item.user_id).includes(item.id)
          )
          .map((e: any) => {
            const user = this.currentEvent?.responsible_users?.find(
              (item) => e.id === item.user_id
            );
            return { ...e, is_accepted_invitation: user?.is_accepted_invitation };
          })
      : [];
  }

  get getFirstResponsibleUser() {
    return this.getResponsibleUsers?.length ? this.getResponsibleUsers[0].full_name : "-";
  }

  get getMembersUsers() {
    return this.currentEvent
      ? this.$store.getters["UsersModule/userList"]
          .filedList(["full_name", "avatar", "id", "is_active", "roles"])
          .filter(
            (item) =>
              item.is_active &&
              this.currentEvent?.users?.map((item) => item.user_id).includes(item.id)
          )
          .map((e: any) => {
            const user = this.currentEvent?.users?.find((item) => e.id === item.user_id);
            return { ...e, is_accepted_invitation: user?.is_accepted_invitation };
          })
      : [];
  }

  get countRemainingMembers() {
    return this.getResponsibleUsers?.length - 1 !== 0 ? this.getResponsibleUsers?.length - 1 : null;
  }

  get currentInvitation() {
    return this.actionListInvitation.find(
      (item) => item.type === String(this.currentEvent?.is_accepted_invitation)
    );
  }

  get setEditRepeatType() {
    if (this.currentEvent?.repeat) {
      const repeat = this.currentEvent?.repeat;
      if (repeat.interval_type === "day" && repeat.step === 1) {
        return `Ежедневно`;
      }
      if (repeat.interval_type === "day" && repeat.step > 1) {
        return `Кажд. ${repeat.step} дн.`;
      }
      if (
        repeat.interval_type === "week" &&
        repeat.step === 1 &&
        repeat?.days?.length !== 1 &&
        JSON.stringify(
          repeat?.days?.sort(function (a, b) {
            return a - b;
          })
        ) === JSON.stringify([1, 2, 3, 4, 5])
      ) {
        return `По будням`;
      }
      if (repeat.interval_type === "week" && repeat.step === 1 && repeat?.days?.length === 1) {
        return `Еженедельно`;
      }
      if (repeat.interval_type === "week" && repeat.step === 1) {
        let days = repeat?.days?.map((item) => " " + this.weekdays[item]);
        return `Еженедельно\u00A0- ${days}`;
      }
      if (repeat.interval_type === "week" && repeat.step > 1) {
        let days = repeat?.days?.map((item) => " " + this.weekdays[item]);
        return `Кажд. ${repeat.step} нед. - ${days}`;
      }
      if (repeat.interval_type === "month" && repeat.step === 1) {
        return `Ежемесячно\u00A0- ${
          this.currentEvent?.started_at.split(" ")[0].split("-")[2]
        }-го числа`;
      }

      if (repeat.interval_type === "month" && repeat.step > 1) {
        return `Кажд. ${repeat.step} мес. - ${
          this.currentEvent?.started_at.split(" ")[0].split("-")[2]
        }-го числа`;
      }
      if (repeat.interval_type === "year" && repeat.step === 1) {
        return `Ежегодно\u00A0- ${this.checkFormatData(this.currentEvent?.started_at)}`;
      }
      if (repeat.interval_type === "year" && repeat.step > 1) {
        return `Кажд. ${repeat.step} года (лет) - ${this.checkFormatData(
          this.currentEvent?.started_at
        )}`;
      }
    }
    return "Не повторять";
  }

  @Watch("getWidthWindow") onChangeWidthWindow() {
    this.resizeHandler();
  }
}
