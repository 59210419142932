<template>
  <div class="modal-menu" @click.self="closeModalMenu">
    <div class="modal-menu__window">
      <div class="modal-menu__body">
        <div class="modal-menu__item" @click="showModalChannels">
          <i class="icon-view-day"></i>
          <p>Каналы</p>
        </div>
        <div class="modal-menu__item" @click="showModalNewEmployee">
          <i class="icon-group"></i>
          <p>Новые сотрудники</p>
        </div>
        <div class="modal-menu__item" @click="showModalBirthday">
          <i class="icon-cake"></i>
          <p>Дни рождения</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import * as types from "@/modules/News/store/mutations/mutationTypes";

export default {
  name: "ChannelModalMenu",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("NewsModule", ["getNewsItemInfoAction"]),
    ...mapMutations("NewsModule", [
      types.SHOW_CHANNEL_MODAL_CHANNELS,
      types.SHOW_CHANNEL_MODAL_MENU,
      types.SHOW_NEWS_BIRTHDAY_MODAL,
      types.SHOW_NEW_EMPLOYEE_MODAL
    ]),
    showModalChannels() {
      this.SHOW_CHANNEL_MODAL_MENU(false);
      this.SHOW_CHANNEL_MODAL_CHANNELS(true);
    },
    showModalBirthday() {
      this.SHOW_CHANNEL_MODAL_MENU(false);
      this.SHOW_NEWS_BIRTHDAY_MODAL(true);
    },
    showModalNewEmployee() {
      this.SHOW_CHANNEL_MODAL_MENU(false);
      this.SHOW_NEW_EMPLOYEE_MODAL(true);
    },
    closeModalMenu() {
      this.SHOW_CHANNEL_MODAL_MENU(false);
    }
  },
  computed: {
    ...mapState("NewsModule", [
      "showChannelModalChannels",
      "showNewsBirthdayModal",
      "showNewEmployeeModal"
    ])
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.slideLeft-leave-active,
.slideLeft-enter-active {
  transition: 0.5s;
}
.slideLeft-enter {
  transform: translate(-100%, 0);
}
.slideLeft-leave-to {
  transform: translate(-100%, 0);
}
.modal-menu {
  display: flex;
  position: fixed;
  height: 100%;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  @media screen and (max-width: $sm) and (orientation: landscape) {
    top: 50px;
  }
  &__window {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    width: 350px;
    border-right: 1px solid #eae8f0;
    max-height: 100%;
    background: white;
    overflow-y: auto;
    @media screen and (max-width: $sm) and (orientation: landscape) {
      padding-bottom: 35px;
    }
    @media (max-width: $xs) {
      width: 100%;
      max-height: calc(100% - 60px);
    }
  }

  &__body {
    padding: 26px;
    overflow: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    p {
      font-size: 14px;
      line-height: 20px;
      color: #343748;
    }

    i {
      font-size: 18px;
      margin-right: 8px;
      color: #a7a9c0;
    }
  }
}
</style>
