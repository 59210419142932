<template>
  <div class="stage-tasks">
    <div class="stage-tasks__header">
      <h2 class="stage-tasks__title">Задачи</h2>
      <Button
        type="simple"
        title="Добавить"
        icon="plus"
        @handleButton="addTask"
        v-if="!isStageCompleted"
      />
    </div>
    <div class="stage-tasks__body" v-if="tasks.count !== 0">
      <StageTask v-for="task in tasks.list" :key="task" :task="task" :project="project" />
    </div>
    <div class="stage-tasks__body" v-else>
      <h3 class="stage-tasks__empty">Нет задач</h3>
    </div>
  </div>
</template>

<script>
import Button from "@/modules/UiKit/components/buttons/Button";
import StageTask from "@/modules/TaskManager/components/MyProjects/ShowAndEditStage/StageTask";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";

export default {
  name: "StageTasksBody",
  mixins: [],
  props: {
    tasks: {
      type: Object,
      default: () => {}
    }
  },
  components: { StageTask, Button },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    addTask() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 3,
        currentProjectForCreation: this.project,
        currentStageForCreation: this.stage,
        currentTaskForCreation: null
      });
    }
  },
  computed: {
    project() {
      return ProjectModule.currentProject.projectFull;
    },
    stage() {
      return StageModule.openStage;
    },
    isStageCompleted() {
      return this.stage.is_completed;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.stage-tasks {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    @media (max-width: $sm) {
      padding: 24px 8px;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;

    @media (max-width: $sm) {
      font-size: 24px;
    }
  }

  &__empty {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    align-items: center;
    text-align: center;
  }

  /deep/ {
    .aop-button_icon {
      @media (max-width: $sm) {
        padding: 7px;
      }

      i {
        @media (max-width: $sm) {
          font-size: 22px;
        }
      }
    }
  }
}
</style>
