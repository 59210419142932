<template>
  <div class="curtain-create-problem__container" @mousedown.self="closeCurtainCreateProblem">
    <div class="curtain-create-problem">
      <ConfirmModal
        v-if="confirmClose"
        :title="'Закрыть'"
        :description="'Введенные данные не будут сохранены. Закрыть?'"
        @cancel="cancelClose"
        @accept="acceptClose"
      />
      <div class="curtain-create-problem__header">
        <button
          class="curtain-create-problem__hide-btn"
          type="button"
          @click="closeCurtainCreateProblem"
        >
          <i class="icon-arrow-short"></i>
        </button>

        <h2>Вынести проблему</h2>
      </div>
      <div class="curtain-create-problem__body">
        <div class="curtain-create-problem__title">
          <Input
            placeholder="Максимум 250 символов"
            label="Название проблемы"
            maxlength="250"
            v-model.trim="title"
            :autocomplete="false"
          />
        </div>
        <div class="curtain-create-problem__description">
          <TextArea
            placeholder="Максимум 2000 символов"
            label="Описание проблемы"
            maxlength="2000"
            v-model.trim="description"
            :sizeControl="120"
          />
        </div>
        <div class="curtain-create-problem__expected-result">
          <TextArea
            placeholder="Максимум 1000 символов"
            label="Ожидаемый результат"
            maxlength="1000"
            v-model.trim="expectedResult"
            :sizeControl="120"
          />
        </div>
        <div class="curtain-create-problem__users users">
          <div class="users__label">
            <Label
              :label="'Кто будет решать проблему'"
              :tooltip="'Роли участников при решении задач ISP'"
            />
          </div>
          <div class="users__customer">
            <Label
              :label="'Заказчик'"
              :tooltip="'Сотрудник, который может проверить результат решения проблемы'"
            />
            <Select
              :placeholder="'Выберите сотрудника'"
              :items="allUsers"
              v-model="selectedCustomer"
              :multiple="false"
              :showButtonPanel="false"
            />
          </div>
          <div class="users__team">
            <Label :label="'Команда'" :tooltip="'Отдел, который должен решать проблему'" />
            <Select
              :placeholder="'Выберите команду'"
              :items="allTeams"
              v-model="selectedTeam"
              :multiple="false"
              :showButtonPanel="false"
              :showAvatar="false"
            />
          </div>
          <div class="users__responsible">
            <Label
              :label="'Ответственный'"
              :tooltip="'Сотрудник, который разрабатывает план решения проблемы и принимает решение о составе команды для решения проблемы'"
            />
            <Select
              :placeholder="'Выберите сотрудника'"
              :items="teamMembers"
              v-model="selectedResponsible"
              :multiple="false"
              :showButtonPanel="false"
              :disabled="!selectedTeam.length"
            />
          </div>
        </div>
      </div>
      <div class="curtain-create-problem__footer">
        <ModalButton
          :type="'cancel'"
          :title="'Отменить'"
          @handleButton="closeCurtainCreateProblem"
        />
        <Button :title="'Вынести'" @handleButton="createProblem" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalButton from "@/components/Widgets/Button/ModalButton";
import Button from "@/modules/UiKit/components/buttons/Button";
import Input from "@/modules/UiKit/components/Inputs/Input";
import TextArea from "@/modules/UiKit/components/Inputs/Textarea";
import Select from "@/modules/UiKit/components/Select/Select";
import Label from "@/modules/Isp/components/IspProblem/Label";
import ClickOutside from "vue-click-outside";
import { mapActions, mapMutations, mapState } from "vuex";
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import {
  SET_CURRENT_INFO_PROBLEM,
  SHOW_CURRENT_INFO_PROBLEM
} from "@/modules/Isp/store/mutations/mutationTypes";
import { EventBus } from "@/main";
import router from "@/router";

export default {
  name: "CurtainCreateProblem",
  mixins: [],
  props: {},
  components: { ConfirmModal, ModalButton, Input, Select, TextArea, Label, Button },
  data() {
    return {
      title: "",
      description: "",
      expectedResult: "",
      allTeams: [],
      teamMembers: [],
      selectedTeam: [],
      selectedResponsible: [],
      selectedCustomer: [],
      confirmClose: false
    };
  },
  mounted() {
    this.getAllUsersAction();
    this.getTeamsForCreateAction().then((response) => {
      this.allTeams = response.data.data.items;
    });
  },
  methods: {
    ...mapActions("IspModule", [
      "getTeamsForCreateAction",
      "getAllUsersAction",
      "createProblemAction",
      "setCurrentInfoProblem",
      "getHistoryProblemAction",
      "getProblemCommentsAction",
      "getProblemInfoData",
      "updateProblemsAction",
      "getTeamForCreateAction",
      "getFullDataOnProblem"
    ]),
    ...mapMutations("IspModule", [
      "TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM",
      SHOW_CURRENT_INFO_PROBLEM,
      SET_CURRENT_INFO_PROBLEM
    ]),
    closeCurtainCreateProblem() {
      if (
        this.title ||
        this.description ||
        this.expectedResult ||
        this.selectedTeam.length ||
        this.selectedCustomer.length ||
        this.selectedResponsible.length
      ) {
        this.confirmClose = true;
      } else {
        this.TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM(false);
      }
    },
    createProblem() {
      if (!this.checkValidation()) return false;
      const newProblem = {
        name: this.title,
        group_id: this.selectedTeam[0].id,
        customer_user_id: this.selectedCustomer.length
          ? this.selectedCustomer[0].id
          : this.$store.getters.getDataInfoUser.id,
        expected_result: this.expectedResult,
        responsible_user_id: this.selectedResponsible.length
          ? this.selectedResponsible[0].id
          : this.selectedTeam[0].leader_user_id,
        description: this.description
      };
      const newProblemForCurtain = {
        name: this.title,
        group: this.selectedTeam[0],
        task_executors: [],
        description: this.description,
        expected_result: this.expectedResult,
        responsible_user: this.selectedResponsible.length
          ? this.selectedResponsible[0]
          : this.selectedTeam[0].leader_user,
        customer_user: this.selectedCustomer.length
          ? this.selectedCustomer[0]
          : this.$store.getters.getDataInfoUser,
        criticality: { id: 1 },
        status: {
          color: "#000",
          name: "Создана"
        },
        period_id: null,
        deadline: null,
        attachment_files: []
      };
      this.createProblemAction(newProblem).then((response) => {
        EventBus.$emit("showNotification", {
          type: "success",
          timeout: 5000,
          label: `Проблема успешно создана!`
        });
        this.getProblemInfoData(response.data.data.id).then((resp) => {
          if (this.$route.name === "IspProblem") {
            let routeData = router.resolve({
              name: "IspProblem",
              params: { id: resp.data.data.id }
            });
            window.open(routeData.href, "_blank");
          } else {
            this.setCurrentInfoProblem({ data: { ...newProblemForCurtain, ...resp.data.data } });
            this.getProblemCommentsAction(resp.data.data.id);
            this.getHistoryProblemAction();
            this.SHOW_CURRENT_INFO_PROBLEM(true);
          }
        });
        this.TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM(false);
      });
    },
    checkValidation() {
      if (this.title.length < 1 || this.title.length > 250) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название должно быть не менее 1 и не более 250 символов!`
        });
        return false;
      } else if (this.expectedResult.length < 1 || this.expectedResult.length > 1000) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Поле ожидаемый результат должно быть не менее 1 и не более 1000 символов!`
        });
        return false;
      } else if (!this.selectedTeam.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите команду!`
        });
        return false;
      }
      return true;
    },
    cancelClose() {
      this.confirmClose = false;
    },
    acceptClose() {
      this.TOGGLE_SHOW_CURTAIN_CREATE_PROBLEM(false);
    }
  },
  computed: {
    ...mapState("IspModule", ["allUsers", "currentInfoProblem"])
  },
  watch: {
    selectedTeam(val) {
      this.selectedResponsible = [];
      if (val.length) {
        this.getTeamForCreateAction(val[0].id).then((response) => {
          this.teamMembers = response.data.data.member_users;
        });
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  @media (max-width: $md) {
    font-size: 25px;
  }
}
.curtain-create-problem {
  position: fixed;
  width: 711px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.15);
  @media (max-width: $xxxl) {
    width: 535px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
  &__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }
  &__header {
    display: flex;
    padding: 32px 24px;
  }
  &__hide-btn {
    display: none;
    padding: 0;
    border: none;
    background: transparent;
    outline: none;
    transform: rotate(-90deg);
    margin-right: 15px;

    @media (max-width: $md) {
      display: inline-block;
    }
    i {
      color: $icon-subdued;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover i {
        color: $icon-hovered;
      }
    }

    &:active i {
      color: $icon-pressed;
    }
  }
  &__body {
    padding: 24px;
    height: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    /deep/ {
      input {
        width: 100%;
      }
      textarea {
        width: 100%;
      }
    }
  }
  &__title {
    margin-bottom: 32px;
  }
  &__description {
    margin-bottom: 32px;
  }
  &__expected-result {
    margin-bottom: 56px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;
    /deep/button {
      font-weight: 600;
    }
  }
}
.users {
  &__title {
    font-size: 16px;
    color: #756f86;
    margin-bottom: 24px;
  }
  &__customer {
    margin-bottom: 32px;
    /deep/ {
      label {
        font-size: 16px;
        color: #756f86;
      }
    }
  }
  &__team {
    margin-bottom: 32px;
    /deep/ {
      label {
        font-size: 16px;
        color: #756f86;
      }
    }
  }
  &__responsible {
    /deep/ {
      label {
        font-size: 16px;
        color: #756f86;
      }
    }
  }
}
</style>
