import Centrifuge from "centrifuge";
import { centrifugeUrl } from "./globalVariables";
import httpClientV2 from "@/api/client/httpClientV2";

let centrifuge = new Centrifuge(centrifugeUrl, {
  onPrivateSubscribe: function (ctx, cb) {
    httpClientV2.post("/getPersonalCentrifugoChannel", { ...ctx.data }).then((resp) => {
      cb({
        status: 200,
        data: resp
      });
    });
  }
});

centrifuge.connect();

export default centrifuge;
