class EventObserver {
  constructor() {
    this.observers = [];
  }

  subscribe(type, fn) {
    const subscriber = { type, fn };
    const event = this.observers.find((subscriber) => subscriber.type === subscriber.type);
    if (event === undefined) this.observers.push(subscriber);
  }

  unsubscribe(type) {
    this.observers = this.observers.filter((item) => item.type !== type);
  }
  broadcast(subscriberType, data) {
    const event = this.observers.find((subscriber) => subscriber.type === subscriberType);
    if (event) {
      event.fn(data);
    }
  }
}

export default new EventObserver();
