















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TimerInputLimit extends Vue {
  @Prop({ default: false, type: Boolean }) isFull!: boolean;
  @Prop({ default: "", type: String }) editValue!: string;

  timeRangeMask() {
    return ["##:##"];
  }
}
