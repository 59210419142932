<template>
  <div class="quiz-component">
    <h4 class="quiz-component__title">{{ quiz.name }}</h4>
    <div class="quiz-component__container">
      <QuizComponentItem
        v-for="answer in quiz.answers"
        :key="`${answer.id}`"
        :answer="answer"
        :currentUserAnswer="quiz.current_user_answer"
        :showResult="isQuizOver"
        @updateQuiz="$emit('updateParentQuiz')"
      />
    </div>
    <div class="quiz-component__users-footer">
      <div v-if="quiz.end_datetime" class="quiz-component__users-footer-datetime">
        <span>
          Срок окончания опроса {{ setFormatDate }} {{ setFormatTime(quiz.end_datetime) }}
        </span>
      </div>
      <div class="quiz-component__users-footer-count">
        {{ declOfNum(quiz.count_users, verbsList) + ":" }}
        <span>{{ quiz.count_users }}</span>
        {{ declOfNum(quiz.count_users, memberWords) }}
      </div>
    </div>
  </div>
</template>

<script>
import QuizComponentItem from "@/modules/News/components/NewsItemsComponents/QuizComponentItem";
import formatDateAndTime from "@/helpers/formatDateAndTime";

import moment from "moment";
export default {
  name: "QuizComponent",
  mixins: [formatDateAndTime],
  props: {
    quiz: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { QuizComponentItem },
  data() {
    return {
      memberWords: ["человек", "человека", "человек"],
      verbsList: ["Проголосовал", "Проголосовали", "Проголосовало"]
    };
  },
  mounted() {},
  methods: {
    /**
     * Функция склонения слов
     * @param number
     * @param words
     * @returns {*}
     */
    declOfNum(number, words) {
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
      ];
    },

    setFormatTime(date) {
      let temp = date.split(" ")[1].split(":");
      temp.pop();
      temp = temp.join(":");
      return temp;
    }
  },
  computed: {
    setFormatDate() {
      return this.setDateFormatWithoutTime(this.quiz.end_datetime);
    },
    // Для показа результатов опроса после окончания срока
    isQuizOver() {
      if (!this.quiz.end_datetime) return false;
      return moment(this.quiz.end_datetime).isBefore(new Date());
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.quiz-component {
  margin-bottom: 20px;
  border: 1px solid $secondary;
  border-radius: 8px;
  padding: 16px;
  @media screen and (max-width: $md) {
    padding: 36px 21px;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    overflow-wrap: break-word;
    word-break: break-word;
    color: $text-default;
    padding: 0;
    margin: 0 0 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;

    .quiz-component-item {
      margin-bottom: 16px;
      padding-right: 16px;
      @media screen and (max-width: $md) {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &__users-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-count {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-left: auto;
      color: $text-disabled;
      @media (max-width: $lg) {
        margin-left: 0;
      }
      @media screen and (max-width: 520px) {
        margin-bottom: 8px;
        font-size: 12px;
      }
    }

    &-datetime {
      display: flex;
      align-items: center;
      padding: 4px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $text-disabled;
      @media screen and (max-width: 520px) {
        font-size: 12px;
        //padding: 2px 8px;
      }
      svg {
        margin-right: 6px;
      }

      p {
        margin: 0 5px 0 0;
        padding: 0;
      }

      .date {
        margin-right: 5px;
      }
    }
  }
}
</style>
