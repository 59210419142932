<template>
  <div class="request-table">
    <div class="request-table__header">
      <div class="request-table__col" v-for="(item, index) in headerList" :key="index">
        {{ item }}
      </div>
    </div>
    <AbsenceRequestTableRow
      v-for="(row, index) in absenceVacationRequest"
      :key="index"
      :row="row"
      :confirmFooter="confirmFooter"
    />
    <div class="request-table__row request-table__row_empty" v-if="!absenceVacationRequest.length">
      Нет запросов :(
    </div>
  </div>
</template>

<script>
import AbsenceRequestTableRow from "@/components/ProfileComponents/ProfileVacationRequests/VacationRequests/VacationRequestTable/AbsenceRequestTable/AbsenceRequestTableRow";

export default {
  name: "AbsenceRequestTable",
  mixins: [],
  props: {
    absenceVacationRequest: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { AbsenceRequestTableRow },
  data() {
    return {
      confirmFooter: true,
      headerList: ["Имя", "Тип", "Отдел", "Подтверждающие лица", "Статус", "Действие"]
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.request-table {
  position: relative;

  &__header {
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    margin-bottom: 24px;
    background-color: $bg-surface-light;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: $text-subdued;

    @media (max-width: $lg) {
      display: none;
    }
  }

  &__row_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    color: $text-disabled;
    font-size: 14px;
    line-height: 20px;
  }

  &__col {
    display: flex;
    align-items: center;
    width: 70%;
    margin-right: 10px;

    &:nth-child(1) {
      width: 100%;
    }

    &:last-child {
      width: 25%;
      margin-right: 0;
    }
  }
}
</style>
