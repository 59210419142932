<template>
  <div class="deadline" v-click-outside="closeCalendar">
    <div class="label" v-if="showLabel">
      <label>{{ label }}</label>
    </div>
    <div class="date-picker">
      <div
        class="date-picker__value"
        :class="{
          'date-picker__value_active': showCalendar && isEdit,
          'date-picker__value_disabled': !isEdit
        }"
        @click.stop="handleSwitch"
      >
        <p><span v-if="showPretext">ДО </span>{{ valueDateFormat }}</p>
        <i class="icon-calendar-secondary" v-if="!showCalendar && isEdit"></i>
        <i class="icon-cross" v-if="showCalendar" @click.stop="deleteDate"></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";

export default {
  name: "DeadLineComponent",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showCalendar: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "дата завершения"
    },
    deadline: {
      type: String,
      default: "дата завершения"
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleSwitch() {
      if (this.isEdit) this.$emit("switchCalendar");
    },
    deleteDate() {
      this.$emit("clearCalendar", null);
    },
    closeCalendar(e) {
      if (this.isEdit) {
        const path = e.path || e.composedPath();
        const isCalendar = path.some((item) => {
          return item.className === "date-picker__calendar";
        });
        if (!isCalendar) {
          this.$emit("closeCalendar");
        }
      }
    }
  },
  computed: {
    valueDateFormat() {
      return this.value ? moment(this.value).format("DD.MM.YYYY") : "НЕ ЗАДАНО";
    },
    showPretext() {
      return this.deadline && this.value !== null
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.label {
  position: relative;
  display: flex;
  margin-bottom: 12px;
  width: max-content;
  label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #343748;
    align-items: center;
    text-transform: uppercase;
  }
}
.date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    height: 32px;
    background: #ffffff;
    border: 2px solid #eae8f0;
    border-radius: 8px;
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;

    &_active {
      border: 2px solid #5551f9;
    }
    &_disabled {
      border-radius: 16px;
      display: flex;
      cursor: default;
      p {
        margin-left: auto;
        margin-right: auto;
      }
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #323232;
      margin-top: 2px;
    }
    i {
      color: #a7a9c0;
    }
  }
}
</style>
