<template>
  <div class="tasks__subtasks subtasks">
    <div class="subtasks__header">
      <div class="subtasks__wrap">
        <h2 class="subtasks__title">Все подзадачи</h2>
      </div>
      <Button
        v-if="!isSubTask"
        @handleButton="createSubTask"
        :type="'simple'"
        :icon="'plus'"
        :title="'Добавить'"
      />
    </div>
    <ul class="subtasks__list" v-if="!loader">
      <SubtaskItem
        v-for="item in subtasks"
        :key="item.id"
        :item="item"
        :tasksData="subtasks"
        :totalCount="subtaskCount"
        :isOwner="isOwner"
        :isMember="isMember"
        :isAuthor="isAuthor"
        :isExecutor="isExecutor"
        @handleEdit="handleEdit"
        @paginationEvent="paginationEvent"
      />
      <li class="subtask-item subtask-item_empty" v-if="!subtasks.length">Нет подзадач</li>
    </ul>
    <div class="subtasks__loader" v-if="loader">
      <Spinner />
    </div>
  </div>
</template>

<script>
import SubtaskItem from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/SubtaskItem";
import Button from "@/modules/UiKit/components/buttons/Button";
import TaskManagerModuleV2 from "@/modules/TaskManager/store/TaskManagerModuleV2";
import TaskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";

export default {
  name: "SubtaskList",
  mixins: [],
  props: {
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SubtaskItem,
    Button,
    Spinner
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    createSubTask() {
      TaskManagerModuleV2.openCurtainConstructor({
        currentCreationTypeId: 4,
        currentTaskForCreation: this.currentTask
      });
    },
    handleEdit(body) {
      this.$emit("handleEdit", body);
    },
    paginationEvent() {
      this.$emit("getSubtasksList");
    }
  },
  computed: {
    currentTask() {
      return TaskModule.currentTask;
    },
    isSubTask() {
      return this.currentTask.parent_id;
    },
    subtasks() {
      return TaskModule.subtasksByTask(this.currentTask.id)
        ? TaskModule.subtasksByTask(this.currentTask.id)
        : [];
    },
    subtaskCount() {
      return TaskModule.subtaskCount;
    },
    loader() {
      return TaskModule.subtaskLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.subtasks {
  min-width: 450px;

  @media (max-width: 1740px) {
    min-width: 364px;
    width: 364px;
  }

  @media (max-width: $xl) {
    width: 100%;
    min-width: 100%;
  }

  &__header {
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid $system-grey-four;
    height: 70px;
    border-bottom: 1px solid $system-grey-four;

    @media (max-width: $xl) {
      border-top: 1px solid $system-grey-four;
    }

    @media (max-width: $md) {
      padding: 16px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }

  &__content {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 26px;
    color: #a7a9c0;

    span {
      margin-left: 4px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    height: calc(100vh - 70px);
    border-right: 1px solid $system-grey-four;

    @media (max-width: $xl) {
      overflow-y: auto;
      height: max-content;
      border-right: none;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30% 0 0;
    border-right: 1px solid $system-grey-four;
    height: calc(100vh - 70px);
  }

  /deep/ {
    .subtask-item {
      &_empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 80px 0;
        color: $text-default;
        font-size: 20px;
        line-height: 32px;
      }

      @media (max-width: $xxxl) {
        &_empty {
          padding: 80px 0;
        }
      }

      @media (max-width: $xl) {
        padding: 32px 24px;

        &_empty {
          font-size: 14px;
          line-height: 20px;
          padding: 80px 0;
        }
      }

      @media (max-width: $md) {
        padding: 32px 16px;

        &_empty {
          padding: 40px 0;
        }
      }
    }

    .aop-button {
      margin-left: auto;

      @media (max-width: $sm) {
        width: 40px;
        height: 40px;
        padding: 8px;
      }

      i {
        @media (max-width: $sm) {
          margin: 0;
          font-size: 20px;
        }
      }

      span {
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }
}
</style>
