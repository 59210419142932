import API from "@/api/Api";

class PersonalChat extends API {
  constructor(prefix) {
    super("PersonalChat", prefix);
  }
  actionGetItem(callback, data) {
    this.get("actionGetItem", callback, data);
  }
}

export default PersonalChat;
