<template>
  <div class="view-responsibilities">
    <div class="view-responsibilities__modal">
      <Widget type="loader-alternative" class="loader" v-if="loading" />
      <div v-else class="view-responsibilities__container">
        <div class="header-container">
          <div class="header-container__label">Задачи: {{ data.name }}</div>
          <i class="icon-cross" @click="$root.$emit('showResponsibilitiesCard', null)"></i>
        </div>
        <div class="main-container">
          <div class="responsibilities-item" v-for="item in responsibilityItems" :key="item.id">
            <div class="responsibilities-item__header">
              <div class="responsibilities-item__label">{{ item.name }}</div>
              <div class="responsibilities-item__control-button" v-if="isAdminResponsibility">
                <button
                  class="responsibilities-item__edit"
                  type="button"
                  @click="$root.$emit('button:click_edit-responsibilities', item)"
                >
                  <i class="icon-edit"></i>
                </button>
                <button
                  class="responsibilities-item__delete"
                  type="button"
                  @click="askDeleteResponsibilities(item.id)"
                >
                  <i class="icon-delete"></i>
                </button>
              </div>
            </div>
            <div class="responsibilities-item__members">
              <div class="member-responsible" v-if="item.responsible_user">
                <img
                  :src="returnSrcFile(item.responsible_user.avatar)"
                  width="36"
                  height="36"
                  alt=" "
                />
                <div class="name-bg">
                  <div class="name">{{ item.responsible_user.full_name }}</div>
                  <div class="role">Ответственный</div>
                </div>
              </div>
              <div class="member-assistant" v-if="item.assistant_user">
                <img
                  :src="returnSrcFile(item.assistant_user.avatar)"
                  width="36"
                  height="36"
                  alt=" "
                />
                <div class="name-bg">
                  <div class="name">{{ item.assistant_user.full_name }}</div>
                  <div class="role">Заместитель</div>
                </div>
              </div>
              <div class="member-team">
                <ul class="member-team__list">
                  <div
                    class="member-avatar"
                    v-for="(item, index) in item.users"
                    :key="index"
                    v-if="index < 7"
                  >
                    <img :src="returnSrcFile(item.avatar)" :alt="item.name" />
                  </div>
                  <li v-if="item.users.length > 7" class="command__item">
                    <div class="command__count">+{{ item.users.length - 7 }}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="responsibilities-item__description"
              v-if="item.final_product"
              v-html="setURL(item.final_product)"
            ></div>
            <router-link
              v-if="item.regulation_id"
              class="responsibilities-item__link"
              :to="{ name: 'RegulationPage', params: { regulation_id: item.regulation_id } }"
            >
              <i class="icon-chain"></i>
              {{ item.regulation && item.regulation.name }}
            </router-link>
          </div>
          <div class="empty" v-if="!responsibilityItems.length">Задач не найдено :(</div>
        </div>
        <div class="footer-container" v-if="isAdminResponsibility">
          <ModalButton
            :title="'Добавить задачу'"
            @handleButton="$root.$emit('button:click_add-responsibilities')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain, domainStorage } from "@/globalVariables";
import Swal from "sweetalert2";
import Widget from "@/components/Widgets/Widget";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import UrlMixin from "@/helpers/UrlMixin";
import httpClient from "@/api/client/httpClient";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "delete-button",
    cancelButton: "cancel-button",
    popup: "popup-class",
    title: "title-class",
    content: "content-class",
    actions: "actions-class",
    icon: "icon-class"
  },
  buttonsStyling: false
});

export default {
  name: "ViewResponsibilitiesCard",
  mixins: [UrlMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isAdminResponsibility: {
      type: Boolean
    }
  },
  components: { ModalButton, Widget },
  data() {
    return {
      loading: true,
      responsibilityItems: [],
      isAdmin: false
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_add-responsibilities");
    this.$root.$off("updateResponsibilityInfo");
  },
  mounted() {
    this.$root.$on("button:click_add-responsibilities", () => {
      this.$root.$emit("showCreateChangeResponsibilitiesModal", { type: false, data: this.data });
    });
    this.$root.$on("button:click_edit-responsibilities", (item) => {
      this.$root.$emit("showCreateChangeResponsibilitiesModal", { type: true, data: item });
    });
    this.getResponsibilityInfo();
    this.$root.$on("updateResponsibilityInfo", () => {
      this.getResponsibilityInfo();
    });
  },
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    getResponsibilityInfo() {
      this.loading = true;
      httpClient({
        url: `/Responsibility/actionGetItems`,
        method: "GET",
        params: {
          _count: 300,
          org_structure_item: JSON.stringify([{ "org_structure_items.id": this.data.id }]),
          _with: JSON.stringify([
            "regulation",
            "users",
            "org_structure_item",
            "responsible_user",
            "assistant_user"
          ])
        }
      })
        .then((response) => {
          this.responsibilityItems = response.data.data.items;
          this.loading = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    deleteResponsibilities(id) {
      httpClient({
        url: `/Responsibility/actionDelete/${id}`,
        method: "DELETE"
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Задача удалена!`
          });
          this.getResponsibilityInfo();
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    askDeleteResponsibilities(id) {
      swalWithBootstrapButtons
        .fire({
          title: "Удалить?",
          text: `Точно хотите удалить? Удаленная задача перестанет отображаться в должностных папках сотрудников.`,
          confirmButtonText: "Да"
        })
        .then((result) => {
          if (!result.dismiss) {
            this.deleteResponsibilities(id);
          }
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";

body .swal2-shown /deep/ .swal2-popup {
  height: 225px !important;
}
::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
  overflow-x: auto;
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: $color-contour;
  border-radius: 10px;
  width: 3px;
}

::-webkit-scrollbar-track {
  margin: 0;
}

.loader {
  margin: 235px auto !important;
}

.view-responsibilities {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-left: 10px;
  padding-right: 10px;

  background-color: rgba(184, 184, 203, 0.2);

  &__modal {
    position: relative;
    display: flex;
    max-width: 679px;
    width: 100%;
    height: 600px;
    max-height: 80vh;
    padding: 20px;
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .header-container {
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;

      &__label {
        width: 80%;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: $color-text-accent;
        word-break: break-word;
      }

      .icon-cross {
        color: $color-text-secondary;
        font-size: 20px;
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
          color: $color-button-hover;
        }

        &:active {
          color: $color-button-click;
        }
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;

      .responsibilities-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;

        &__header {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        &__control-button {
          margin-left: 5px;
        }

        &__edit,
        &__delete {
          width: 18px;
          height: 18px;
          padding: 0;
          background: transparent;
          border: none;
          margin-left: 13px;
          outline: none;

          .icon-delete,
          .icon-edit {
            color: $color-other-2;
            font-size: 20px;

            &:hover {
              color: $color-button-hover;
            }

            &:active {
              color: $color-button-click;
            }
          }
        }

        &__label {
          max-width: 90%;
          word-break: break-word;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $color-text-primary-1;
        }

        &__members {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .member-responsible {
            display: flex;
            align-items: center;
            margin-right: 10px;
            width: 200px;
            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              object-fit: cover;
              background-color: $main-white;
              border: 1px solid $color-charts-purple-1;
            }

            .name-bg {
              margin-left: 10px;
              .name {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: $color-text-accent;
              }
              .role {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                color: $color-text-primary-2;
              }
            }
          }

          .member-assistant {
            display: flex;
            align-items: center;
            margin-right: 10px;
            width: 200px;
            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              object-fit: cover;
              background-color: $main-white;
              border: 1px solid $color-charts-purple-1;
            }

            .name-bg {
              margin-left: 10px;
              .name {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: $color-text-accent;
              }
              .role {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                color: $color-text-primary-2;
              }
            }
          }

          .member-team {
            &__list {
              padding: 0;
              margin: 0;
              list-style: none;
              display: flex;

              .member-avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                object-fit: cover;
                background: #6a67ce;
                img {
                  width: 34px;
                  height: 34px;
                  border-radius: 50%;
                  object-fit: cover;
                }
                &:not(:first-child) {
                  margin-left: -15px;
                }
              }

              .command__item:not(:first-child) {
                margin-left: -15px;
              }
              /deep/ .group-card__name {
                right: 0;
                transform: translateX(-70%);
              }
              .command__count {
                position: relative;
                z-index: 6;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-charts-purple-1;
                border: 1px solid $color-charts-purple-1;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                font-feature-settings: "tnum" on, "lnum" on;
                color: #ffffff;
              }
            }
          }
        }

        &__description {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: $color-text-primary-1;
          margin-bottom: 15px;
          white-space: pre-wrap;

          /deep/ {
            a {
              color: $link-default;
            }

            a:hover,
            a:focus {
              color: $link-hovered;
            }
          }
        }

        &__link {
          width: 100%;
          margin-bottom: 25px;
          color: $color-charts-purple-1;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 13px;
          display: flex;
          align-items: center;
          font-feature-settings: "tnum" on, "lnum" on;

          .icon-chain {
            margin-right: 10px;
            color: $color-text-secondary;
            font-size: 23px;
          }

          &:hover {
            color: $color-button-hover;
          }

          &:active {
            color: $color-button-click;
          }
        }
      }

      .responsibilities-item:not(:last-child) {
        border-bottom: 1px solid $color-contour;
      }
    }
    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: transparent;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #b8b8cb;
      padding: 200px;
    }
    .footer-container {
      width: 100%;
      display: flex;
      padding-top: 20px;
      border-top: 1px solid $color-contour;

      /deep/ {
        button {
          width: auto;
        }
      }
    }
  }
}
</style>
