<template>
  <div class="task-comment">
    <div class="task-comment__header">
      <h3 class="task-comment__title">Комментарии</h3>
    </div>
    <div class="task-comment__loader" v-if="loader">
      <Spinner />
    </div>
    <Comments
      v-if="!loader"
      :comments="commentsList"
      :currentComment="currentComment"
      :childList="childList"
      :listData="commentsList"
      :page="commentsPage"
      :totalCount="commentsCount"
      :formLoader="createLoader"
      @createAction="createAction"
      @updateAction="updateAction"
      @deleteAction="deleteAction"
      @setCurrentComment="setCurrentComment"
      @getAnswer="getAnswer"
      @resetAnswer="resetAnswer"
      @paginationEvent="paginationEvent"
      @toggleFormLoader="toggleFormLoader"
    />
  </div>
</template>

<script>
import Comments from "@/modules/UiKit/components/Comments/Comments";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
import NotificationObserver from "@/helpers/UserNotification";
import { EventBus } from "@/main";

export default {
  name: "CommentsBodyTask",
  mixins: [],
  props: [],
  components: { Spinner, Comments },
  data() {
    return {
      loader: false
    };
  },
  mounted() {
    this.loader = true;
    this.getCommentsTask();
  },
  beforeDestroy() {
    this.resetComments();
  },
  methods: {
    getCommentsTask() {
      TaskModule.getCommentsListAction({
        id: this.currentTask.id,
        page: this.commentsPage,
        per_page: 15
      }).then(() => {
        this.loader = false;
        EventBus.$emit("offLoader");
      });
    },
    getAnswer(body) {
      this.resetAnswer(body.id);
      TaskModule.getAnswerCommentsListAction({
        id: this.currentTask.id,
        page: 1,
        per_page: 10,
        comment_id: body.id
      }).then(() => {
        this.loader = false;
        EventBus.$emit("offLoader");
      });
    },
    createAction(val) {
      TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](true);
      if (!this.checkValidationData(val)) return null;
      TaskModule.creteCommentsAction({
        id: this.currentTask.id,
        text: val.text,
        files: val.files,
        parent_id: this.currentComment ? this.currentComment.id : null,
        count_comments: 0,
        created_at: new Date().toISOString(),
        user_id: this.getUserId
      }).then(() => EventBus.$emit("offLoader"));
    },
    updateAction(val) {
      TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](true);
      if (!this.checkUpdateValidationData(val)) return null;
      TaskModule.updateCommentsLisAction({
        id: this.currentTask.id,
        text: val.text,
        files: val.files,
        files_delete: val.files_delete,
        comment_id: this.currentComment ? this.currentComment.id : null,
        parent_id: this.currentComment ? this.currentComment.parent_id : undefined,
        count_comments: this.currentComment ? this.currentComment.count_comments : null
      }).then(() => EventBus.$emit("offLoader"));
    },
    deleteAction(body) {
      TaskModule.deleteCurrentCommentAction({
        id: this.currentTask.id,
        comment_id: body.id,
        parent: body.parent
      }).then(() => this.$emit("getTask"));
      this.setCurrentComment(null);
    },
    checkValidationData(val) {
      if (
        (!val.text || !val.text.trim().length) &&
        !val.files.length &&
        !val.files_delete?.length
      ) {
        NotificationObserver.notification({
          type: "error",
          message: "Введите текст комментария или прикрепите файл!"
        });
        TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](false);
        return false;
      }
      if (val.text && val.text.trim().length > 1024) {
        NotificationObserver.notification({
          type: "error",
          message: "Длинна комментария должна быть не более 1024 символов!"
        });
        TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](false);
        return false;
      }
      return true;
    },
    checkUpdateValidationData(val) {
      if (val.text && val.text.trim().length > 1024) {
        NotificationObserver.notification({
          type: "error",
          message: "Длинна комментария должна быть не более 1024 символов!"
        });
        TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](false);
        return false;
      }
      return true;
    },
    setCurrentComment(val) {
      TaskModule[TaskMutationTypes.SET_CURRENT_COMMENT](val);
    },
    paginationEvent(params) {
      if (params.parent_id) {
        TaskModule.getAnswerCommentsListAction({
          id: this.currentTask.id,
          page: params.page,
          per_page: 10,
          comment_id: params.parent_id
        }).then(() => (this.loader = false));
        this.loader = false;
      } else {
        TaskModule.getCommentsListAction({
          id: this.currentTask.id,
          page: this.commentsPage,
          per_page: 15
        }).then(() => (this.loader = false));
      }
    },
    resetAnswer(id) {
      TaskModule[TaskMutationTypes.RESET_CURRENT_CHILDREN_COMMENT](id);
    },
    resetComments() {
      TaskModule[TaskMutationTypes.SET_CURRENT_COMMENT](null);
      TaskModule[TaskMutationTypes.SET_COMMENTS_COUNT](0);
      TaskModule[TaskMutationTypes.SET_COMMENTS_PAGE](1);
      TaskModule[TaskMutationTypes.RESET_TASK_COMMENTS]();
      TaskModule[TaskMutationTypes.RESET_CHILDREN_COMMENT]();
      TaskModule[TaskModule.TOGGLE_FORM_LOADER](false);
    },
    toggleFormLoader(val) {
      TaskModule[TaskMutationTypes.TOGGLE_FORM_LOADER](val);
    }
  },
  computed: {
    currentTask() {
      return TaskModule.currentTask;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    },
    commentsList() {
      return TaskModule.commentsList;
    },
    commentsPage() {
      return TaskModule.commentsPage;
    },
    commentsCount() {
      return TaskModule.commentsCount;
    },
    currentComment() {
      return TaskModule.currentComment;
    },
    childList() {
      return TaskModule.childCommentsList;
    },
    createLoader() {
      return TaskModule.commentFormLoader;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints.scss";

.task-comment {
  height: 100%;

  &__header {
    padding: 32px 24px;
  }

  &__title {
    margin: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    color: $text-default;
  }

  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0 0;
  }

  /deep/ {
    .comments {
      height: calc(100% - 92px);

      @media (max-width: $md) {
        height: 100%;
      }
      .user__description {
        color: #a7a9c0;
      }
    }
  }
}
</style>
