
































import { Component, Mixins, Prop } from "vue-property-decorator";
import moment from "moment";
import { dateFormat } from "@/aopV2/modules/Calendar/helper/helperFunc";
import EventCardMonth from "@/aopV2/modules/Calendar/components/CalendarEventCard/EventCardMonth.vue";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import FSEventMixin from "@/aopV2/modules/Calendar/helper/FSevent.mixin";

@Component({
  components: { EventCardMonth }
})
export default class CalendarDayMonth extends Mixins(FSEventMixin) {
  @Prop({
    default: () => {},
    type: Object
  })
  day!: any;

  createEvent() {
    if (!this.isShowCreateEvent) {
      const start_time = moment(new Date()).add(1, "hour").format("HH:mm");
      const end_time = moment(new Date()).add(2, "hour").format("HH:mm");
      CalendarModule2.toggleCreateModal(true);
      CalendarModule2.setCreateData({
        ...this.createData,
        selectDate: this.day.date,
        timeStart: start_time,
        timeEnd: end_time,
        selectResponsible: [{ id: this.userId }]
      });
      this.$emit("show");
      if (this.isMobileWindowSize) {
        this.handleFullScreenEvent();
      }
    }
  }

  get getWidthWindow() {
    return this.$store.getters["MainLayoutSetting/width"];
  }

  get isMobileWindowSize() {
    return this.getWidthWindow < 1024;
  }

  get userId() {
    return this.$store.getters.getUserId;
  }

  get createData() {
    return CalendarModule2.createDataDef;
  }

  get isShowCreateEvent() {
    return CalendarModule2.isShowCreateEvent;
  }

  get isShowViewEvent() {
    return CalendarModule2.isShowViewEvent;
  }

  get isNotActive() {
    return this.isShowEventList || this.isShowViewEvent;
  }

  openMoreEvents(e) {
    const rect = this.$el.getBoundingClientRect();
    const rectC = document.querySelector(".calendar")!.getBoundingClientRect();
    const y = rectC.height - 20 > rect.bottom ? rect.top : rect.bottom;
    const x = rectC.width - rect.width > rect.right ? rect.left : rect.right;
    CalendarModule2.toggleEventList({
      x,
      y,
      events: this.eventsSort,
      isShow: true,
      day: this.day.date
    });
  }

  get isShowEventList() {
    return CalendarModule2.eventListSetting ? CalendarModule2.eventListSetting.isShow : false;
  }

  get date() {
    return moment(this.day.date).format("D");
  }

  get isToday() {
    return dateFormat(new Date()) === this.day.date;
  }

  get events() {
    return this.eventsSort.length > 2 ? [this.eventsSort[0], this.eventsSort[1]] : this.eventsSort;
  }

  get isMainLoader() {
    return CalendarModule2.isMainLoader;
  }

  get eventsSort() {
    return this.day.events.sort(
      (a, b) => new Date(a.started_at).getTime() - new Date(b.started_at).getTime()
    );
  }
}
