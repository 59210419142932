import moment from "moment";

export default {
  data() {
    return {
      color: "#FD9A00",
      bgcolor: "#FFF8E5"
    };
  },
  methods: {
    setLocalDate(date) {
      moment.locale("ru");
      return moment(date).format("L");
    },
    setLabelCountDays(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    setStatus(status) {
      switch (status) {
        case true:
          this.color = "#37C597";
          this.bgcolor = "#EBFCF6";
          return "Одобрен";
        case false:
          this.color = "#FB646D";
          this.bgcolor = "#FEF0F0";
          return "Отклонен";
        default:
          this.color = "#FD9A00";
          this.bgcolor = "#FFF8E5";
          return "Отправлен";
      }
    },
    setStatusVacation(status) {
      switch (status) {
        case "pending":
          this.color = "#FD9A00";
          this.bgcolor = "#FFF8E5";
          return "Отправлен";
        case "pendingByVacationAdmin":
          this.color = "#FD9A00";
          this.bgcolor = "#FFF8E5";
          return "Отправлен";
        case "accepted":
          this.color = "#37C597";
          this.bgcolor = "#EBFCF6";
          return "Одобрен";
        case "rejected":
          this.color = "#FB646D";
          this.bgcolor = "#FEF0F0";
          return "Отклонен";
        default:
          this.color = "#FD9A00";
          this.bgcolor = "#FFF8E5";
          return "Отправлен";
      }
    }
  }
};
