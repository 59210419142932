<template>
  <div
    class="chosen-filter"
    :class="[
      modifier.length ? `chosen-filter_${modifier}` : '',
      { 'chosen-filter_disabled': disabled }
    ]"
    v-click-outside="hideModalList"
  >
    <div class="chosen-filter__value">
      <button class="chosen-filter__label" type="button" @click="toggleList">
        <i :class="`icon-${icon}`"></i>
        <i
          :class="`icon-${icon}`"
          :style="'transform: rotate(180deg)'"
          v-if="icon === 'sort-arrow'"
        ></i>
        <span class="chosen-filter__name" v-if="value.length === 1">{{ setValue }}</span>
        <span class="chosen-filter__placeholder" v-if="!value.length || value.length > 1">{{
          placeholder
        }}</span>
        <span class="chosen-filter__length" v-if="showWithMultiple && value.length > 1">{{
          value.length
        }}</span>
      </button>
      <div class="chosen-filter__wrapper" v-if="showList" @click.self="hideModalList">
        <ul class="chosen-filter__list">
          <li class="chosen-filter__input" v-if="search">
            <input type="text" placeholder="Поиск" v-model.trim="searchValue" />
            <i class="icon-search"></i>
          </li>
          <div class="chosen-filter__item-wrap">
            <li
              class="chosen-filter__item"
              @click="chosenAllValue"
              v-if="!multiple && !searchValue.length && allItem && allValue"
            >
              <span
                class="checkbox_custom"
                :class="{
                  checkbox_custom_chosen: choseAllValue,
                  checkbox_custom_multiple: multiple
                }"
              >
                <i class="icon-check" v-if="choseAllValue"></i>
              </span>
              <span>{{ allValue }}</span>
            </li>
            <ChosenFilterItem
              v-for="(item, index) in chosenList"
              :key="item.id"
              :item="item"
              :value="value"
              :chosenList="chosenList"
              :choseAll="choseAll"
              :multiple="multiple"
              :searchValue="searchValue"
              :isLastItem="chosenList.length - 1 === index"
              @choseFilter="choseFilter"
              @reachLastItem="reachLastItem"
            />
            <Spinner v-if="isLoading" :small="true" class="chosen-filter__loader" />
          </div>
          <li class="chosen-filter__item chosen-filter__item_last" v-if="multiple">
            <button class="chosen-filter__button" type="button" @click="deleteAllItem">
              Очистить
            </button>
            <button
              class="chosen-filter__button chosen-filter__button_all"
              type="button"
              @click="chosenAllItem"
            >
              Вce
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ChosenFilterItem from "./ChosenFilterItem";
import { EventBus } from "@/main";
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "ChosenFilter",
  mixins: [],
  model: {
    event: "input"
  },
  props: {
    icon: {
      type: String,
      default: "sort-arrow"
    },
    placeholder: {
      type: String,
      default: ""
    },
    allItem: {
      type: Boolean,
      default: true
    },
    allValue: {
      type: String,
      default: "Все"
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    chosenList: {
      type: Array,
      default: () => []
    },
    unique: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    modifier: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { ChosenFilterItem, Spinner },
  data() {
    return {
      showList: false,
      choseAll: false,
      choseAllValue: false,
      searchValue: ""
    };
  },
  mounted() {
    EventBus.$on("hideFilters", () => {
      this.hideModalList();
      document.activeElement.blur();
    });
  },
  methods: {
    reachLastItem() {
      this.$emit("reachLastItem");
    },
    toggleList() {
      if (this.chosenList.length && !this.disabled) {
        this.showList = !this.showList;
      }
    },
    hideModalList() {
      this.showList = false;
    },
    choseFilter(item) {
      if (!this.value.some((elem) => elem.id === item.id)) {
        if (this.multiple) {
          let oldValue = JSON.parse(JSON.stringify(this.value));
          oldValue.push(item);
          this.$emit("input", oldValue);
          this.updateValue();
        } else {
          this.$emit("input", [item]);
          this.updateValue();
        }
        this.choseAllValue = false;
      } else {
        if (this.multiple) {
          this.removeItem(item);
          this.choseAll = false;
          this.choseAllValue = false;
        } else {
          this.$emit("input", [item]);
          this.updateValue();
          this.choseAll = false;
          this.choseAllValue = false;
        }
      }
    },
    chosenAllItem() {
      if (this.value.length !== this.chosenList.length) {
        this.choseAll = true;
        this.choseAllValue = false;
        this.$emit("input", this.chosenList);
        this.updateValue();
      }
    },
    chosenAllValue() {
      this.choseAllValue = true;
      this.$emit("input", []);
      this.updateValue();
    },
    deleteAllItem() {
      if (this.value.length !== 0) {
        this.choseAll = false;
        this.choseAllValue = false;
        this.$emit("input", []);
        this.updateValue();
      }
    },
    removeItem(val) {
      this.choseAll = false;
      this.choseAllValue = false;
      this.$emit(
        "input",
        this.value.filter((item) => item.id !== val.id)
      );
      this.updateValue();
    },
    updateValue() {
      setTimeout(() => {
        this.$emit("updateInfo");
      }, 500);
    }
  },
  computed: {
    listSortId() {
      return this.chosenList.map((item) => item.id);
    },
    setValue() {
      return this.showAllNoMultiple
        ? "Все"
        : this.value.length
        ? this.value[0]["full_name"]
          ? this.value[0]["full_name"]
          : this.value[0]["label"] || this.value[0]["name"]
        : "";
    },
    showAllNoMultiple() {
      return this.value.length && !this.multiple && this.value.length === this.chosenList.length;
    },
    showWithMultiple() {
      return this.value.length && this.multiple;
    }
  },
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.chosen-filter {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 5px 6px;
  width: max-content;
  max-width: 270px;
  height: 30px;

  border-radius: 4px;

  @media (max-width: $md) {
    padding: 0 6px;
    height: 16px;
  }

  @media (max-width: 767px) {
    width: max-content;
  }

  &_disabled {
    .chosen-filter__label {
      cursor: default;
      opacity: 0.5;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__arrows {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    margin-right: 8px;

    .icon-sort-arrow {
      color: $icon-subdued;
      font-size: 14px;
    }
  }
  .icon-check-all {
    color: $icon-subdued;
    font-size: 14px;
    margin-right: 8px;
  }
  &__label {
    display: flex;
    align-items: center;
    z-index: 10;
    width: max-content;
    padding: 4px;
    border: 0;
    height: 30px;
    background-color: transparent;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $text-disabled;
    margin-right: 16px;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s ease;

    @media (max-width: $md) {
      width: max-content;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $surface-selected-default;
      }
    }

    &:active {
      background-color: $surface-selected-default;
    }

    i {
      font-size: 18px;
      margin-right: 8px;
    }

    span {
      display: block;
      @media (max-width: $md) {
        display: none;
      }
    }
  }

  &__name {
    display: block;
    width: 100%;
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__length {
    display: block;
    margin: 0 0 0 8px;
  }

  &__list {
    position: absolute;
    max-width: 270px;
    width: max-content;
    min-width: 180px;
    top: 30px;
    z-index: 10;
    left: 0;
    list-style: none;
    padding: 8px 0;
    margin: 0;
    background-color: $surface-default;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);
    border-radius: 4px;

    @media (max-width: $sm) {
      position: fixed;
      width: 100%;
      max-width: unset;
      top: unset;
      bottom: 0;
      height: fit-content;
      border-radius: 16px 16px 0 0;
    }

    @media (max-width: 890px) and (orientation: landscape) {
      position: fixed;
      width: 100%;
      max-width: unset;
      top: unset;
      bottom: 0;
      height: fit-content;
      border-radius: 16px 16px 0 0;
    }

    //@media (max-width: $sm) and (orientation: landscape) {
    //  max-height: calc(100% - 50px);
    //}
  }
  &__loader {
    margin: auto;
  }
  &__item-wrap {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;

    @media (max-width: 890px) and (orientation: landscape) {
      height: calc(100% - 100px);
      max-height: 150px;
    }

    @media (max-width: $sm) and (orientation: landscape) {
      height: calc(100% - 100px);
      max-height: 150px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    background: transparent;
    border: none;
    outline: none;

    i {
      margin-right: 8px;
      color: $icon-subdued;
      font-size: 18px;
    }

    @media (hover: hover) {
      &:hover {
        background: $surface-selected-default;
        border-radius: 4px;
      }
    }

    &_last {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px 8px;
      border-top: 1px solid $border-disabled;

      @media (hover: hover) {
        &:hover {
          background: #ffffff;
        }
      }
    }
  }

  &__input {
    position: relative;
    width: 100%;
    padding: 8px 16px;

    i {
      position: absolute;
      top: 10px;
      left: 16px;
      color: $icon-subdued;
    }

    input {
      outline: none;
      border: none;
      width: 100%;
      padding: 0 10px 5px 20px;
      border-bottom: 1.5px solid #b2b2b6;
      transition: 0.3s all ease;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-disabled;
      }
    }
  }

  &__placeholder {
    color: $text-disabled;
  }

  &__wrapper {
    @media (max-width: 890px) and (orientation: landscape) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: $background-modal;
    }
    @media (max-width: $sm) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: $background-modal;
    }
  }

  &__button {
    background: transparent;
    border: none;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-default;
    outline: none;

    &_all {
      color: #5551f9;
    }
  }
}

.checkbox_custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid #d3d2e0;
  box-sizing: border-box;
  margin-right: 8px;
  flex-shrink: 0;
  border-radius: 50%;

  .icon-check {
    margin-right: 0;
    color: #ffffff;
    font-size: 14px;
  }

  &_chosen {
    background-color: $action-primary-accent;
    border: 2px solid $action-primary-accent;
  }

  &_multiple {
    border-radius: 2px;
  }
}
</style>
