



















































import { Component, Mixins, Watch } from "vue-property-decorator";
import TextInput from "@/aopV2/components/UIComponents/Input/Text/TextInput/TextInput.vue";
import draggableModals from "@/aopV2/modules/Calendar/helper/draggableModals";
import CalendarModule2 from "@/aopV2/modules/Calendar/store/calendarModule";
import Skeleton from "@/modules/UiKit/components/Skeleton/Skeleton.vue";
import MainModal from "@/aopV2/components/UIComponents/Modal/MainModal.vue";
import ButtonNewDesign from "@/modules/UiKit/NewDisign/Buttons/ButtonNewDesign.vue";
import ModalModule from "@/aopV2/layout/LayoutMain/Modal";
import NotificationObserver from "@/helpers/UserNotification";

@Component({
  components: { ButtonNewDesign, MainModal, Skeleton, TextInput }
})
export default class CalendarLocation extends Mixins(draggableModals) {
  name: string = "";

  mounted() {
    if (this.location && this.mode === "edit") {
      this.name = this.location.name;
    }
  }

  //methods
  hideModal() {
    this.$emit("hideModal");
  }

  handleCancel() {
    if (this.mode === "create") {
      this.$emit("hideModal");
    } else {
      CalendarModule2.toggleShowLocationModal({ show: true, mode: "show" });
    }
  }

  handleEdit() {
    if (this.location) {
      this.name = this.location.name;
      CalendarModule2.toggleShowLocationModal({ show: true, mode: "edit" });
    }
  }

  handleInput(event) {
    this.name = event.target.value;
  }

  acceptHandler() {
    if (this.mode === "create") {
      this.createLocation();
    } else {
      this.editLocation();
    }
  }

  createLocation() {
    if (!this.checkValidation()) return false;
    CalendarModule2.createLocationAction({
      name: this.name
    });
  }

  deleteLocation() {
    if (this.location)
      CalendarModule2.deleteLocationAction({
        id: this.location?.id
      });
    this.cancelConfirm();
  }

  confirmDeleteGroup() {
    ModalModule.setConfirmSetting({
      title: "Удалить кабинет?",
      text: "Все мероприятия проводимые в этом кабинете будут сохранены.",
      confirmButton: "Удалить",
      cancelButton: "Отменить",
      handleConfirm: () => this.deleteLocation(),
      handleCancel: () => this.cancelConfirm()
    });
    ModalModule.toggleConfirmation(true);
  }

  cancelConfirm() {
    ModalModule.setConfirmSetting(null);
    ModalModule.toggleConfirmation(false);
  }

  editLocation() {
    if (this.location) {
      if (!this.checkValidation()) return false;
      CalendarModule2.editLocationAction({
        id: this.location?.id,
        name: this.name
      });
    }
  }

  checkValidation() {
    if (!this.name.trim().length) {
      NotificationObserver.notification({
        type: "error",
        message: "Введите название кабинета!"
      });
      return false;
    }
    return true;
  }

  // computed getter function
  get location() {
    return CalendarModule2.currentLocation;
  }

  get mode() {
    return CalendarModule2.modeLocationModal;
  }

  get loader() {
    return CalendarModule2.loader;
  }

  get setTitle() {
    return this.mode === "create"
      ? "Создать кабинет"
      : this.mode === "edit"
      ? "Редактировать кабинет"
      : this.location && this.location.name;
  }

  get setAcceptTitle() {
    return this.mode === "create" ? "Создать" : this.mode === "edit" ? "Сохранить" : "";
  }

  get showMode() {
    return this.mode !== "create" && this.mode !== "edit";
  }

  @Watch("mode")
  onResetLocationData() {
    if (this.mode === "create") {
      this.name = "";
      CalendarModule2.setCurrentLocation(null);
    }
  }
}
