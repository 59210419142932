<template>
  <div class="form-group form-group-textarea">
    <label class="form-group__container">
      <span
        v-if="label"
        :class="['form-group__label', disabled ? 'form-group__label_disabled' : '']"
        >{{ label }}{{ required ? " *" : "" }}</span
      >
      <textarea
        :class="['form-group__textarea', value ? 'form-group__textarea_complete' : '']"
        :rows="rows"
        :cols="cols"
        :maxlength="maxlength"
        :minlength="minlength"
        :aria-required="required"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="Change__TextArea"
        @paste="Past__TextArea"
      >
      </textarea>
    </label>
    <small class="invalid-label" v-if="invalid">{{ userText }}</small>
  </div>
</template>

<script>
import ResizeMixin from "@/helpers/ResizeMixin";

export default {
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    defaultValue: {
      type: [String, Number]
    },
    maxlength: {
      type: String
    },
    minlength: {
      type: String
    },
    keyUpEnter: {
      type: Function
    },
    tooltip: {
      type: String
    },
    keyAdd: {
      type: [String, Number]
    },
    cols: {
      type: [String, Number]
    },
    rows: {
      type: [String, Number]
    },
    userDescription: {
      type: String
    },
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: [String, Number]
    }
  },
  name: "Textarea",
  mixins: [ResizeMixin],
  beforeDestroy() {
    this.$root.$off("resetTextareaValue");
  },
  mounted() {
    if (!this.value) {
      this.value = null;
    }
    if (this.editValue) {
      this.value = this.editValue;
    }
    this.$root.$on("resetTextareaValue", () => {
      this.value = null;
    });
  },
  data() {
    return {
      value: "",
      maximum: this.maxlength ? this.maxlength : 255
    };
  },
  methods: {
    Change__TextArea(event) {
      this.value = event.target.value;
      this.mixin_autoResize_resize(event);
      let data = {
        eventName: "Change__TextArea",
        data: {
          event: event,
          value: this.value,
          label: this.label,
          max: this.maxlength,
          min: this.minlength,
          rows: this.rows,
          placeholder: this.placeholder,
          cols: this.cols,
          readonly: this.disabled,
          tooltip: this.tooltip,
          keyAdd: this.keyAdd,
          required: this.required
        }
      };
      this.$emit(`textareaChange`, this.value);
      this.$root.$emit(`textarea:change_${this.unique}`, this.value);
    },
    Past__TextArea(event) {
      this.$emit(`textarea:paste_${this.unique}`, event.clipboardData.files[0]);
    }
  },
  computed: {
    controlLength() {
      let result = Number(this.min) - this.values.length;
      if (Math.sign(result) === -1 || Math.sign(result) === 0) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

@mixin input-box-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
}

.form-group {
  width: 100%;
  margin-bottom: 0;

  &__container {
    display: flex;
    flex-direction: column;
    margin: 0;

    color: $color-text-secondary;
    font-family: inherit;
  }

  &__label {
    margin: 0 0 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #a7a9c0;

    &_disabled {
      color: $color-disabled;
    }
  }

  &__textarea {
    padding: 16px 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #a7a9c0;
    resize: none;
    border: none;
    outline: none;
    transition: all 0.3s ease;
    background: #fafbfc;
    border-radius: 24px;

    &::placeholder {
      color: inherit;
      font-family: inherit;
    }

    &:focus {
      color: $text-default;
    }

    &:disabled {
      color: $color-disabled;
      border-color: $color-disabled;
      background-color: $color-white;
    }

    &_complete {
      color: $color-text-primary-2;

      border-color: $color-other-1;
    }
  }
}
</style>
