import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import Vue from "vue";

const name = "Main";

export enum MainMutationTypes {}

export type importFunction = (src: string | symbol) => Promise<any>;

interface IMainSetting {
  leftPanel: importFunction | null;
  rightPanel: importFunction | null;
  headerSlot: importFunction | null;
  title: string;
  activeTab: string;
  tabs: Array<{ name: string; route: string }>;
  isMainTab: boolean;
  breadcrumbs?: null | Array<{ name: string; route: string }>;
  activeBreadcrumbs?: string;
}

@Module({ namespaced: true })
class MainLayoutSetting extends VuexModule {
  mainSetting: IMainSetting | null = {
    leftPanel: () => import(`@/aopV2/layout/LayoutMain/TestComp`),
    rightPanel: () => import(`@/aopV2/layout/LayoutMain/TestComp`),
    headerSlot: null,
    title: "База знаний",
    tabs: [
      { name: "Tab 1", route: "Tab1" },
      { name: "Tab 2", route: "Tab2" }
    ],
    activeTab: "Tab2",
    isMainTab: true,
    breadcrumbs: null,
    activeBreadcrumbs: ""
  };
  windowHeight: number = window.innerHeight;
  windowWidth: number = window.innerWidth;
  contentHeight: number | null = null;
  contentWidth: number | null = null;
  isDark: boolean = false;
  keyHeader: string | null = null;

  get key() {
    return this.keyHeader;
  }

  get activeTab() {
    return this.mainSetting?.activeTab;
  }

  @Mutation
  setHeaderKey(key) {
    this.keyHeader = key;
  }

  @Mutation
  setWindowSize(size: { height: number; width: number }) {
    this.windowHeight = size.height;
    this.windowWidth = size.width;
  }

  @Mutation
  setContentSize(size: { height: number; width: number }) {
    this.contentHeight = size.height;
    this.contentWidth = size.width;
  }

  @Mutation
  toggleDarkMode() {
    this.isDark = !this.isDark;
  }

  @Mutation
  setSetting(setting: IMainSetting | null) {
    this.mainSetting = setting;
  }

  @Mutation
  updateSettingParams(data: { paramName: string; value: any }) {
    if (this.mainSetting) Vue.set(this.mainSetting, `${data.paramName}`, data.value);
  }

  get setting() {
    return this.mainSetting;
  }

  get height() {
    return this.windowHeight;
  }

  get width() {
    return this.windowWidth;
  }

  get getContentWidth() {
    return this.contentWidth;
  }

  get isDarkMode() {
    return this.isDark;
  }
}

export default MainLayoutSetting;
