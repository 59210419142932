<template>
  <div class="system-message" :id="id">
    {{ source.text }}
  </div>
</template>

<script>
export default {
  name: "SystemMessage",
  mixins: [],
  props: {
    source: {
      type: Object,
      default: () => {}
    },
    id: {
      type: Number,
      default: 0
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.system-message {
  display: flex;
  flex-flow: column;
  cursor: default;
  max-width: 60%;
  width: fit-content;
  padding: 8px 16px;
  margin-bottom: 24px;

  margin-left: auto;
  margin-right: auto;
  word-break: break-word;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(167, 169, 192, 0.3), 0px 3px 10px rgba(167, 169, 192, 0.2);
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
}
</style>
