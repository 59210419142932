import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";

export default {
  mixins: [uploadFilesMixin],
  data() {
    return {
      cover: null,
      documents: null
    };
  },
  methods: {
    handlePast(event) {
      const val = event.clipboardData.files[0];
      const file = {
        file: val,
        name: val.name,
        size: val.size,
        type: val.type,
        fileObject: true,
        postAction: "/upload/post"
      };
      if (this.files.length < 2) {
        this.files.push(file);
        this.uploadFile(this.files);
      }
    },
    dropPast(event) {
      event.preventDefault();
      const val = event.dataTransfer.files[0];
      const file = {
        file: val,
        name: val.name,
        size: val.size,
        type: val.type,
        fileObject: true,
        postAction: "/upload/post"
      };
      if (this.files.length < 2) {
        this.files.push(file);
        this.uploadFile(this.files);
      }
    },
    getUrlFile() {
      this.cover = [];
      this.documents = [];
      this.files.forEach((item) => {
        if (item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png") {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = () => {
            this.cover.push({
              src: reader.result,
              name: item.name,
              id: item.id,
              type: item.type,
              file: item.file
            });
          };
        } else {
          this.documents.push(item);
        }
      });
    }
  }
};
